import React,{Suspense} from "react";
import {
    MDBBox
} from 'mdbreact';
// const AddCustomer=React.lazy(() => import("../../components/customer/addCustomer"));
import AddCustomer from "../../components/customer/addCustomer";

export default class AddCustomerPage extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MDBBox className={"min-vh-100"}>
                    <Suspense fallback={null}>
                        <AddCustomer></AddCustomer>
                    </Suspense>
                </MDBBox>
            </React.Fragment>
        );
    }
}
