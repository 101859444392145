import React from "react";
import {Link, withRouter} from "react-router-dom";
import {
    MDBBox,
    MDBBreadcrumb,
    MDBBreadcrumbItem,
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardHeader, MDBChip, MDBCol,
    MDBContainer,
    MDBDataTable,
    MDBFormInline, MDBInput,
    MDBModal,
    MDBModalBody,
    MDBModalFooter,
    MDBModalHeader
} from 'mdbreact';
import UserStore from "../../stores/userStore";
import {toast} from "react-toastify";
import SimpleBackdrop from "../common/overlay";
import SpecialityService from "../../services/specialityService";
import dataTableConfig from "./dataTable";
import Moments from "moment";

class ListSpecialityGroup extends React.Component {
    async componentDidMount() {
        this.fetchSpecialityGroup();
    }
    fetchSpecialityGroup=async ()=>{
        await this.setState({disableRetreatAfterSorting:true});
        let userData=await UserStore.fetchSessionData();
        this.setState({progressStatus:true});
        let res=await SpecialityService.fetchMasterSpeciality(userData);
        if(res===false){
            this.setState({progressStatus:false});
            toast.error("We are facing some issue. Please try again later.");
        }else{
            this.setState({progressStatus:false,apiResultCame:true,specialityGroups:res});
            let dataTablePayload = {
                columns: dataTableConfig.specialityGroup,
                rows: res.map((row, index) => {
                    let temp={};
                    temp["specialityGroup"]=row.specialityGroup;
                    temp["createdBy"]=row.createdBy;
                    temp["createdAt"]=this.convertToReadbleDate(row.createdAt);
                    temp["delete"]=<a><span onClick={()=>{this.delete(row)}} className={"blue-text btn-link"}>Delete</span></a>;
                    temp["edit"]=<a><span onClick={()=>{this.edit(row)}} className={"blue-text btn-link"}>Edit</span></a>
                    return temp;
                })
            }
            this.setState({data:dataTablePayload});
        }
        await this.setState({disableRetreatAfterSorting:false});
    }
    goToNewSpecialityGroup=()=>{
        this.props.history.push('/add-speciality');
    }
    constructor(props) {
        super(props);
        this.state = {
            progressStatus: false,
            specialityGroups:[],
            data:{},
            apiResultCame:false,
            modal:false,
            specialityGroup:'',
            selectedItem:{},
            modalEdit:false,
            suggestedList:[],
            disableRetreatAfterSorting:false
        }
    }
    delete=(item)=>{
        this.setState({specialityGroup:item.specialityGroup,selectedItem:item});
        this.toggle();
    }
    edit=(item)=>{
        this.setState({specialityGroup:item.specialityGroup,selectedItem:item});
        this.toggleEdit();
    }

    editChip= async (item)=>{
        this.props.history.push('/edit-speciality/'+item._id);
        //await this.setState({specialityGroup:item.specialityGroup,selectedItem:item});
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    }
    deleteSpecialityGroup=async ()=>{
        let userData=await UserStore.fetchSessionData();
        this.setState({progressStatus:true});
        let res=await SpecialityService.deleteSpecialityGroup(this.state.selectedItem._id,userData);
        if(res===false){
            this.setState({progressStatus:false});
            toast.error("We are facing some issue. Please try again later.");
        }else{
            this.toggle();
            this.setState({progressStatus:false});
            toast.success("Speciality group deleted successfully.");
            this.fetchSpecialityGroup();
        }
    }
    convertToReadbleDate = date =>{
        if(date){
            return Moments(date).format("MMM D, YYYY hh:mm A");
        }
    }
    toggleEdit=()=>{
        this.setState({
            modalEdit: !this.state.modalEdit
        });
    }
    editSpecialityGroup=async()=>{
        let userData=await UserStore.fetchSessionData();
        this.setState({progressStatus:true});
        let res=await SpecialityService.editSpecialityGroup(this.state.selectedItem._id,this.state.specialityGroup,userData);
        if(res===false){
            this.setState({progressStatus:false});
            toast.error("We are facing some issue. Please try again later.");
        }else{
            this.toggleEdit();
            this.setState({progressStatus:false,suggestedList:[]});
            toast.success("Speciality group updated successfully.");
            this.fetchSpecialityGroup();
        }
    }
    fetchSuggestion=async()=>{
        try{
            if(this.state.specialityGroup!=='') {
                let userData=await UserStore.fetchSessionData();
                let data = await SpecialityService.lookupSpecialityGroup(this.state.specialityGroup,userData);
                if(data!==false){
                    this.setState({suggestedList:data});
                }
            }
        }catch (e){
            this.setState({suggestedList:[]});
        }
    }
    handleChange=(val)=>{
        this.setState({specialityGroup:val});
    }
    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.progressStatus}/>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>View Specialties</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <MDBContainer className={'w-100 mw-100'}>
                    <MDBBox>
                        <MDBCard className={"mt-2"} style={{"marginTop": "0px"}}>
                            <MDBCardHeader>
                                <span className={"float-left title"}>View Specialties</span>
                                <MDBFormInline className="float-right md-form mr-auto m-0">
                                    <MDBBtn color="default" size="sm" onClick={this.goToNewSpecialityGroup} className="">
                                        Add Speciality
                                    </MDBBtn>
                                </MDBFormInline>
                            </MDBCardHeader>
                            <MDBCardBody>
                                {(this.state.apiResultCame && this.state.specialityGroups.length === 0) &&
                                <div className={"no-data-container"}>No Speciality found.</div>
                                }
                                {this.state.specialityGroups.length > 0 &&
                                <MDBDataTable
                                    striped

                                    data={this.state.data}
                                    materialSearch
                                    noBottomColumns
                                    hover
                                    disableRetreatAfterSorting={this.state.disableRetreatAfterSorting}
                                />
                                }
                            </MDBCardBody>
                        </MDBCard>
                    </MDBBox>
                    <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
                        <MDBModalHeader toggle={this.toggle}>Delete Speciality</MDBModalHeader>
                        <MDBModalBody>
                            Are you sure you want
                            to delete speciality "{this.state.specialityGroup}"?
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn onClick={this.deleteSpecialityGroup}
                                    disabled={this.state.progressStatus}>Delete</MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>
                </MDBContainer>
                <MDBModal isOpen={this.state.modalEdit} toggle={this.toggleEdit}>
                    <MDBModalHeader toggle={this.toggleEdit}>Edit Speciality</MDBModalHeader>
                    <MDBModalBody>
                        <MDBBox>
                            <MDBInput onChange={this.fetchSuggestion} label="Speciality" getValue={this.handleChange} value={this.state.specialityGroup}/>
                        </MDBBox>
                        <MDBBox>
                            {this.state.suggestedList.map((item, idx) => (
                                <span onClick={()=>this.editChip(item)} key={idx}><MDBChip bgColor="light-blue lighten-4" key={idx} waves>{item.specialityGroup}</MDBChip></span>
                            ))}
                        </MDBBox>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn onClick={this.editSpecialityGroup}
                                disabled={this.state.progressStatus}>Update</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
            </React.Fragment>
        );
    }
}

export default withRouter(ListSpecialityGroup)
