import React from "react";
import {
  MDBBtn,
  MDBBox,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
} from "mdbreact";
var Barcode = require("react-barcode");

function ViewBarCodeFun({barCodeModal,toggleBarcode,selectedBarCode}) {
    const toggle = ()=>{
        toggleBarcode(null);
    }

    console.log("BarCode")
  return (
    <>
      <MDBModal isOpen={barCodeModal} toggle={toggle}>
        <MDBModalHeader toggle={toggle}>Bar Code</MDBModalHeader>
        <MDBModalBody>
          {selectedBarCode !== 0 && (
            <Barcode value={selectedBarCode}></Barcode>
          )}
          {selectedBarCode === 0 && (
            <div className={"no-data-container"}>No barcode found.</div>
          )}
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn size={"md"} onClick={toggle}>
            Close
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    </>
  );
}

export default ViewBarCodeFun;
