import React from "react";
import {withRouter} from "react-router-dom";
import {
    MDBBtn, MDBModalHeader, MDBModalBody,
    MDBModalFooter, MDBModal
} from 'mdbreact';
import UserStore from "../../stores/userStore";
import {toast} from "react-toastify";
import PharmacyService from "../../services/pharmacyService";
import config from "../../config";
import SimpleBackdrop from "../common/overlay";
import UpdateManufacturer from "./updateManufacturer";
import MaterialTable from "material-table";
import {CSVLink} from "react-csv";
import Moments from "moment";

const headers = [
    {label: "Manufacturer", key: "manufacturer"},
    {label: "Description", key: "description"},
    {label: "Created By", key: "createdBy"},
    {label: "Image", key: "imageUrl.filePathOriginal"},
    {label: "Is Active?", key: "isActive"}
]

class ListManufacturer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeManufacturerName: "",
            manufacturerList: [],
            progressStatus: false,
            activeManufacturer: {
                imageUrl: {
                    filePathoriginal: ""
                }
            },
            modalStatus: false,
            modalEdit: false,
            userData: {},
        }
    }

    async componentDidMount() {
        let userData = await UserStore.fetchSessionData();
        this.setState({userData})
        await this.fetchManufacturer()
    }
    fetchManufacturer = async (action) => {
        let data = [];
        if (!window.mfg || window.mfg === null || action === "refresh") {
            this.setState({progressStatus: true});
            data = await PharmacyService.fetchManufacturer(this.state.userData);
            window.mfg = data;
        } else {
            data = window.mfg;
        }
        this.setState({progressStatus: false});
        if (data) {
            this.setState({manufacturerList: data});
        } else {
            toast.error("Something went wrong while fetching manufacturer.")
        }
    }
    toggle = (section) => {
        this.setState({["modal" + section]: !this.state["modal" + section]});
    }
    onEditComplete = async () => {
        this.toggle("Edit")
        await this.fetchManufacturer("refresh");
    }
    onEdit = (row) => {
        this.setState({activeManufacturer: row})
        this.toggle("Edit")
    }
    onDownloadImage = (row) => {
        this.setState({activeManufacturer: row})
        this.toggle("LB")
    }
    initChangeStatus = (row) => {
        this.setState({
            activeManufacturer: row,
            statusAction: (row.isActive === "Y") ? "deactivate" : "activate",
        });
        this.toggle("Status");
    }
    updateStatus = async () => {
        this.setState({progressStatus: true});
        let status = (this.state.statusAction === "activate") ? "Y" : "N";
        let res = await PharmacyService.updateManufacturerStatus(this.state.activeManufacturer._id, status, this.state.userData);
        this.setState({progressStatus: false});
        if (res === false) {
            toast.error("Something went wrong while updating the status. Please try again after a while.");
        } else {
            toast.success("Manufacturer status updated successfully.");
            this.toggle("Status")
            await this.fetchManufacturer("refresh");
        }
        this.setState({statusAction: ""});
    }

    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.progressStatus}/>
                <div className={"col-12 row mx-0"}>
                    <div className={'col-md-12 text-right p-0'}>
                        <CSVLink data={this.state.manufacturerList} headers={headers} target="_blank"
                                 filename={"manufacturers_" + Moments(new Date()).format("DD-MM-YYYY") + ".csv"}>
                            <MDBBtn size={'md'} disabled={this.state.progressStatus}><i
                                className="fas fa-download"></i>&nbsp;Export</MDBBtn>
                        </CSVLink>
                        <MDBBtn size={'md'} disabled={this.state.progressStatus} onClick={() => {
                            this.props.history.push("/add-manufacturer")
                        }}>Add</MDBBtn>
                    </div>
                    <MaterialTable
                        columns={[
                            {title: 'Manufacturer', field: 'manufacturer'},
                            {title: 'Description', field: 'description'},
                            {title: 'Created By', field: 'createdBy'},
                            {
                                title: 'Image',
                                field: 'imageUrl.filePathOriginal',
                                render: rowData => <div color="primary" className={"blue-text btn-link cursor-pointer"}
                                                        size="sm"
                                                        onClick={() => {
                                                            this.onDownloadImage(rowData)
                                                        }}>
                                    {rowData.imageUrl.filePathOriginal &&
                                    <span>View Logo</span>
                                    }
                                </div>
                            },
                            {
                                title: '',
                                field: '',
                                render: rowData => <div color="primary" className={"blue-text btn-link cursor-pointer"}
                                                        size="sm"
                                                        onClick={() => this.onEdit(rowData)}>Edit</div>
                            },
                            {
                                title: '',
                                field: '',
                                render: rowData => <div color="primary" className={"blue-text btn-link cursor-pointer"}
                                                        size="sm"
                                                        onClick={() => {
                                                            this.initChangeStatus(rowData)
                                                        }}>{(rowData.isActive === "Y") ? "Deactivate" : "Activate"}</div>
                            }
                        ]}
                        data={this.state.manufacturerList}
                        options={{
                            showTitle: false,
                            pageSize: 10,
                            pageSizeOptions: [10, 50, 100],
                            emptyRowsWhenPaging: false,
                            tableLayout: 'fixed',
                        }}
                    />
                </div>
                <MDBModal isOpen={this.state.modalLB} toggle={() => this.toggle("LB")} size={"lg"}>
                    <MDBModalHeader toggle={() => this.toggle("LB")}></MDBModalHeader>
                    <MDBModalBody className={"float-center"}>
                        <div style={{"textAlign": "center"}}>
                            <img src={config.IMAGE_HOST + this.state.activeManufacturer.imageUrl.filePathOriginal}
                                 className={"float-center"}
                                 style={{maxWidth: "440px", maxHeight: "440px"}} alt={""}/>
                        </div>
                    </MDBModalBody>
                </MDBModal>
                <MDBModal isOpen={this.state.modalStatus} toggle={() => this.toggle("Status")}>
                    <MDBModalHeader toggle={() => this.toggle("Status")}>Update Manufacturer Status</MDBModalHeader>
                    <MDBModalBody>
                        Are you sure you want
                        to {this.state.statusAction} manufacturer {this.state.activeManufacturer.manufacturer}?
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn onClick={this.updateStatus}
                                disabled={this.state.progressStatus}>{this.state.statusAction}</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
                <MDBModal isOpen={this.state.modalEdit} toggle={() => this.toggle("Edit")} size={"fluid"}>
                    <MDBModalHeader toggle={() => this.toggle("Edit")}></MDBModalHeader>
                    <MDBModalBody className={"float-center"}>
                        <UpdateManufacturer loadedOnPopup={true} onEditComplete={this.onEditComplete}
                                            manufacturerId={this.state.activeManufacturer._id}/>
                    </MDBModalBody>
                </MDBModal>
            </React.Fragment>
        );
    }
}

export default withRouter(ListManufacturer)
