import React from "react";
import {Link, withRouter} from "react-router-dom";
import util from "../../utility/utility";
import {
    MDBContainer,
    MDBRow,
    MDBInput,
    MDBFormInline,
    MDBBtn,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBBreadcrumb,
    MDBBreadcrumbItem,
    MDBModal,
    MDBModalHeader,
    MDBModalBody,
    MDBModalFooter,
    MDBChip, MDBBox,
} from 'mdbreact';
import UserStore from "../../stores/userStore";
import {toast} from "react-toastify";
import PharmacyService from "../../services/pharmacyService";
import SimpleBackdrop from "../common/overlay";
import orgConfig from "../../orgConfig";

class EditSupplier extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            supplierApiResultCame:false,
            activeSupplierId:0,
            progressStatus:false,
            businessName:"",
            contactName:"",
            contactNo:"",
            email:"",
            website:"",
            tradeLicence:"",
            foodLicence:"",
            drugLicence:"",
            gst:"",
            description:"",
            businessNameError:false,
            contactNameError:false,
            contactNoError:false,
            emailError:false,
            websiteError:false,
            tradeLicenceError:false,
            foodLicenceError:false,
            drugLicenceError:false,
            gstError:false,
            descriptionError:false,
            address: "",
            street: "",
            pincode:"",
            area: "",
            city: "",
            state: "",
            country: "",
            landmark: "",
            addressError:false,
            streetError:false,
            pincodeError:false,
            areaError:false,
            cityError:false,
            stateError:false,
            countryError:false,
            landmarkError:false,
            modalDelete:false,
            suggestedData:[],
            loadedOnPopup:false,
            supplierId:""
        }
    }
    componentDidMount() {
        if(this.props.loadedOnPopup === true){
            let supplierId = this.props.supplierId;
            this.setState({supplierId:supplierId, loadedOnPopup:true})
            this.fetchSupplierById(supplierId)
        } else {
            let supplierId = this.props.match.params.supplierId;
            this.setState({supplierId:supplierId})
            this.fetchSupplierById(supplierId)
        }
    }
    resetErrors = () => {
        this.setState({ businessNameError:false,
            contactNameError:false, contactNoError:false, emailError:false, websiteError:false,
            tradeLicenceError:false, foodLicenceError:false, drugLicenceError:false, gstError:false,
            descriptionError:false, addressError:false, streetError:false, pincodeError:false,
            areaError:false, cityError:false, stateError:false, countryError:false, landmarkError:false,
        })
    }
    initEdit = (row) =>{
        this.setState({ activeSupplierId:row._id,
            businessName:row.supplierName, contactName:row.contactName, contactNo:row.phone, email:row.email, website:row.website,
            tradeLicence:row.tradeLicense, foodLicence:row.foodLicense, drugLicence:row.drugLicense, gst:row.gst, description:row.description,
            address: row.addressLine1, street: row.addressLine2, pincode:row.pinCode, area: row.area, city: row.city, state: row.state,
            country: row.country, landmark: row.landmark
        })
    }
    fetchSupplierById = async (supplierId) => {
        let userData=await UserStore.fetchSessionData();
        this.setState({progressStatus:true});
        let res = await PharmacyService.fetchSupplierById(supplierId, userData);
        this.setState({supplierApiResultCame:true})
        this.setState({progressStatus:false});
        if(res){
            try{
                this.initEdit(res)
            } catch (e) {
                toast.error("Something went wrong while fetching suppliers.")
            }
        }
    }
    handleChangeCName=(e)=>{
        this.setState({contactName:e.target.value})
    };
    handleChangeBName=async (e)=>{
        this.setState({businessName:e.target.value});

        //suggestion part
        let userData=await UserStore.fetchSessionData();
        let mfgSuggest=await PharmacyService.suggestSupplier(this.state.businessName,userData);
        this.setState({suggestedData:mfgSuggest});
    };
    handleChangeContactNo=(e)=>{
        if(!util.isInt(e.target.value) && e.target.value !== ""){
            return false;
        }
        this.setState({contactNo:e.target.value})
    };
    handleChangeEmail=(e)=>{
        this.setState({email:e.target.value})
    };
    handleTradeLicence =(e)=>{
        this.setState({tradeLicence:e.target.value})
    };
    handleFoodLicence =(e)=>{
        this.setState({foodLicence:e.target.value})
    };
    handleDrugLicence =(e)=>{
        this.setState({drugLicence:e.target.value})
    };
    handleGST=(e)=>{
        this.setState({gst:e.target.value})
    };
    handleWebsite=(e)=>{
        this.setState({website:e.target.value})
    };
    handleDescription=(e)=>{
        this.setState({description:e.target.value})
    };
    handleAddress=(e)=>{
        this.setState({address:e.target.value})
    };
    handleStreet=(e)=>{
        this.setState({street:e.target.value})
    };
    handlePincode=(e)=>{
        if(!util.isInt(e.target.value) && e.target.value !== ""){
            return false;
        }
        this.setState({pincode:e.target.value})
    };
    handleArea=(e)=>{
        this.setState({area:e.target.value})
    };
    handleCity=(e)=>{
        this.setState({city:e.target.value})
    };
    handleState=(e)=>{
        this.setState({state:e.target.value})
    };
    handleCountry=(e)=>{
        this.setState({country:e.target.value})
    };
    handleLandmark=(e)=>{
        this.setState({landmark:e.target.value})
    };
    validateBusinessName = () => {
        if(!this.state.businessName || this.state.businessName.trim() === ""){
            this.setState({"businessNameError": true})
        } else {
            this.setState({"businessNameError": false})
        }
    }
    validateContactName = () => {
        if(!this.state.contactName || this.state.contactName.trim() === ""){
            this.setState({"contactNameError": true})
        } else {
            this.setState({"contactNameError": false})
        }
    }
    validateEmail = () => {
        if(this.state.email && !util.validateEmail(this.state.email)){
            this.setState({"loadingEmail": true, "emailError": true})
        } else {
            this.setState({"loadingEmail": false, "emailError": false})
        }
    }
    validatePhone= () => {
        if(!util.checkPhone(this.state.contactNo)){
            this.setState({ "contactNoError": false})
        } else {
            this.setState({"contactNoError": false})
        }
    }
    validateWebsite = () => {
        if(this.state.website && !util.validateUrl(this.state.website)){
            this.setState({"websiteError": true})
        } else {
            this.setState({ "websiteError": false})
        }
    }
    validateAddress=()=>{
        if(this.state.address.trim() === ''){
            this.setState({addressError: true});
            return true;
        } else {
            this.setState({addressError: false});
        }
    };
    validatePincode=()=>{
        if(!this.state.pincode){
            this.setState({pincodeError: true});
            return true;
        } else {
            this.setState({pincodeError: false});
        }
    };
    validateArea=()=>{
        if(this.state.area === ''){
            return true;
        } else {
            this.setState({areaError: false});
        }
    };
    validateCity=()=>{
        if(this.state.city.trim() === ''){
            this.setState({cityError: true});
            return true;
        } else {
            this.setState({cityError: false});
        }
    };
    validateState=()=>{
        if(this.state.state.trim() === ''){
            this.setState({stateError: true});
            return true;
        } else {
            this.setState({stateError: false});
        }
    };
    validateCountry=()=>{
        if(this.state.country.trim() === ''){
            this.setState({countryError: true});
            return true;
        } else {
            this.setState({countryError: false});
        }
    };
    updateSupplier =async () => {
        let userData=await UserStore.fetchSessionData();
        // Reset all the error first
        this.resetErrors()
        //validations
        this.validateBusinessName();
        this.validateContactName();
        this.validatePhone();
        this.validateEmail();
        this.validateWebsite();
        this.validateAddress();
        this.validateArea();
        this.validatePincode();
        this.validateCity();
        this.validateState();
        this.validateCountry();
        if(this.state.businessNameError || this.state.contactNameError || this.state.contactNoError || this.state.emailError || this.state.websiteError || this.state.addressError || this.state.areaError || this.state.pincodeError || this.state.cityError || this.state.stateError || this.state.countryError){
            return false;
        }
        this.setState({progressStatus:true});
        let status=await PharmacyService.updateSupplier(this.state,userData);
        this.setState({progressStatus:false});
        if(status===false){
            toast.error("Something went wrong.");
            return true;
        }else{
            if(this.state.loadedOnPopup === true){
                toast.success("Supplier updated successfully.");
                this.props.onEditComplete()
            }
        }
    }
    initDelete = () => {
        this.toggleDelete()
    }
    confirmDelete = async () => {
        let userData=await UserStore.fetchSessionData();
        this.setState({progressStatus:true});
        let status=await PharmacyService.deleteSupplier(this.state,userData);
        this.setState({progressStatus:false});
        if(status===false){
            toast.error("Something went wrong.");
            return true;
        }else{
            this.toggleDelete();

            if(this.state.loadedOnPopup === true){
                toast.success("Supplier deleted successfully.");
                this.props.onEditComplete()
            } else {
                this.props.history.push('/view-suppliers');
            }



        }
    }
    toggleDelete = () => {
        this.setState({
            modalDelete: !this.state.modalDelete
        });
    }
    navigate=(item)=>{
        this.props.history.push('/edit-supplier/'+item._id);
    }
     render() {
        return (
            <React.Fragment>
                {this.state.loadedOnPopup === false &&
                <>
                    <SimpleBackdrop status={this.state.progressStatus}/>
                    <MDBBreadcrumb>
                        <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                        <MDBBreadcrumbItem><Link to={'/view-suppliers'}>Suppliers</Link></MDBBreadcrumbItem>
                        <MDBBreadcrumbItem active>Edit Supplier</MDBBreadcrumbItem>
                    </MDBBreadcrumb>
                </>
                }
                <MDBContainer>
                    <MDBCard className={"mt-2"}>
                        <MDBCardHeader>
                            <span className={"float-left title"}>Update Supplier</span>
                        </MDBCardHeader>
                        <MDBCardBody>
                            <MDBRow>
                                <MDBCol>
                                    <MDBInput autoComplete={'off'} style={{"marginTop": "0px"}} onBlur={this.validateBusinessName}
                                              className={this.state.businessNameError ? "border-danger" : "mt-0 mb-0"}
                                              maxLength="100" type='text' label="Business Name *"
                                              onChange={this.handleChangeBName}
                                              value={this.state.businessName}/>
                                    <MDBBox>
                                        {this.state.suggestedData.length > 0 &&
                                        <div>
                                            <h6>Update existing supplier:</h6>
                                            <br/>
                                        </div>
                                        }
                                        {this.state.suggestedData.map((item, idx) => (
                                            <span onClick={()=>{this.navigate(item)}}><MDBChip bgColor="light-blue lighten-4" key={idx} waves>{item.supplierName}</MDBChip></span>
                                        ))}
                                    </MDBBox>
                                </MDBCol>
                                <MDBCol>
                                    <MDBInput autoComplete={'off'} style={{"marginTop": "0px"}} onBlur={this.validateContactName}
                                              className={this.state.contactNameError ? "border-danger" : "mt-0 mb-0"}
                                              maxLength="50" type='text' label="Contact Name *"
                                              onChange={this.handleChangeCName}
                                              value={this.state.contactName}/>
                                </MDBCol>
                                <MDBCol>
                                    <MDBInput onBlur={() => this.validatePhone()}
                                              autoComplete={'off'}
                                              className={this.state.contactNoError ? "border-danger" : ""}
                                              maxLength={orgConfig.PHONE_NUMBER_LENGTH} type='text' label="Phone *"
                                              onChange={this.handleChangeContactNo}
                                              value={this.state.contactNo}/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBInput onBlur={() => this.validateEmail()}
                                              autoComplete={'off'}
                                              className={this.state.emailError ? "border-danger" : ""}
                                              maxLength="50" type='text' label="E-mail"
                                              onChange={this.handleChangeEmail}
                                              value={this.state.email}/>
                                </MDBCol>
                                <MDBCol>
                                    <MDBInput autoComplete={'off'} style={{"marginTop": "0px"}} onBlur={() => this.validateWebsite()}
                                              className={this.state.websiteError ? "border-danger" : "mt-0 mb-0"}
                                              maxLength="50" type='text' label="Website"
                                              onChange={this.handleWebsite}
                                              value={this.state.website}/>
                                </MDBCol>
                                <MDBCol>
                                    <MDBInput autoComplete={'off'} style={{"marginTop": "0px"}}
                                              className={this.state.gstError ? "border-danger" : "mt-0 mb-0"}
                                              maxLength="50" type='text' label="GST/VAT"
                                              onChange={this.handleGST}
                                              value={this.state.gst}/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol size={"6"}>
                                    <MDBInput onBlur={() => this.validateAddress()}
                                              className={this.state.addressError ? "border-danger" : ""} maxLength="50"
                                              type='text' label="Address Line 1 *" onChange={this.handleAddress}
                                              value={this.state.address}/>
                                </MDBCol>
                                <MDBCol size={"6"}>
                                    <MDBInput className={this.state.streetError ? "border-danger" : ""} maxLength="50"
                                              type='text' label="Address Line 2" onChange={this.handleStreet}
                                              value={this.state.street}/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBInput onBlur={() => this.validatePincode()}
                                              className={this.state.pincodeError ? "border-danger" : ""} maxLength="6"
                                              type='text' label="Postal Code *" onChange={this.handlePincode}
                                              value={this.state.pincode}/>
                                </MDBCol>
                                <MDBCol>
                                    <MDBInput onBlur={() => this.validateArea()}
                                              className={this.state.areaError ? "border-danger" : ""} maxLength="35"
                                              type='text' label="Area/Locality" onChange={this.handleArea}
                                              value={this.state.area}/>
                                </MDBCol>
                                <MDBCol>
                                    <MDBInput onBlur={() => this.validateCity()}
                                              className={this.state.cityError ? "border-danger" : ""} maxLength="35"
                                              type='text' label="City *" onChange={this.handleCity}
                                              value={this.state.city}/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBInput onBlur={() => this.validateState()}
                                              className={this.state.stateError ? "border-danger" : ""} maxLength="35"
                                              type='text' label="State *" onChange={this.handleState}
                                              value={this.state.state}/>
                                </MDBCol>
                                <MDBCol>
                                    <MDBInput className={this.state.landmarkError ? "border-danger" : ""} maxLength="50"
                                              type='text' label="Landmark" onChange={this.handleLandmark}
                                              value={this.state.landmark}/>
                                </MDBCol>
                                <MDBCol>
                                    <MDBInput onBlur={() => this.validateCountry()}
                                              className={this.state.countryError ? "border-danger" : ""} maxLength="35"
                                              type='text' label="Country *" onChange={this.handleCountry}
                                              value={this.state.country}/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBInput autoComplete={'off'} style={{"marginTop": "0px"}}
                                              className={this.state.tradeLicenceError ? "border-danger" : "mt-0 mb-0"}
                                              maxLength="100" type='text' label="Trade Licence"
                                              onChange={this.handleTradeLicence}
                                              value={this.state.tradeLicence}/>
                                </MDBCol>
                                <MDBCol>
                                    <MDBInput autoComplete={'off'} style={{"marginTop": "0px"}}
                                              className={this.state.foodLicenceError ? "border-danger" : "mt-0 mb-0"}
                                              maxLength="50" type='text' label="Food Licence"
                                              onChange={this.handleFoodLicence}
                                              value={this.state.foodLicence}/>
                                </MDBCol>
                                <MDBCol>
                                    <MDBInput autoComplete={'off'} style={{"marginTop": "0px"}}
                                              className={this.state.drugLicenceError ? "border-danger" : "mt-0 mb-0"}
                                              maxLength="100" type='text' label="Drug Licence"
                                              onChange={this.handleDrugLicence}
                                              value={this.state.drugLicence}/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBInput autoComplete={'off'} style={{"marginTop": "0px"}}
                                              className={this.state.descriptionError ? "border-danger" : "mt-0 mb-0"}
                                              maxLength="100" type='textarea' label="Description"
                                              onChange={this.handleDescription}
                                              value={this.state.description}/>
                                </MDBCol>
                            </MDBRow>
                            <div className="col-12 p-2 text-center">
                                {this.state.loadedOnPopup === false &&
                                <MDBBtn outline  size="md"
                                        onClick={() => this.props.history.push('/view-suppliers')} className="mr-auto"
                                        disabled={this.state.progressStatus}>
                                    Back
                                </MDBBtn>
                                }
                                <MDBBtn outline size="md" onClick={this.initDelete} className="mr-auto"
                                        disabled={this.state.progressStatus}
                                >Delete
                                </MDBBtn>
                                <MDBBtn  size="md" onClick={this.updateSupplier} className="mr-auto"
                                        disabled={this.state.progressStatus || this.state.businessNameError || this.state.contactNameError || this.state.contactNoError || this.state.emailError || this.state.websiteError || this.state.addressError || this.state.areaError || this.state.pincodeError || this.state.cityError || this.state.stateError || this.state.countryError}
                                >
                                    Save
                                </MDBBtn>
                            </div>
                        </MDBCardBody>
                    </MDBCard>
                    <MDBModal isOpen={this.state.modalDelete} toggle={this.toggleDelete}>
                        <MDBModalHeader toggle={this.toggleDelete}>Delete Supplier</MDBModalHeader>
                        <MDBModalBody>
                            Are you sure you want to delete this supplier?
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn onClick={this.confirmDelete} disabled={this.state.progressStatus}>Delete</MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>
                </MDBContainer>
            </React.Fragment>
        );
    }
}
export default withRouter(EditSupplier);
