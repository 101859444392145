import React from "react";
import {withRouter} from "react-router-dom";
import {
    MDBCard, MDBCardBody, MDBBtn, MDBCardHeader,
    MDBFormInline,  MDBRow, MDBCol,MDBInput,MDBSelect
} from 'mdbreact';

class AddProductV2Comp extends React.Component {

    state = {
        options: [
            {
                text: "Option 1",
                value: "1"
            },
            {
                text: "Option 2",
                value: "2"
            },
            {
                text: "Option 3",
                value: "3"
            }
        ],

    };
    render() {
        return (
            <React.Fragment>

                    <form>
                        <MDBCard className={"mt-4"}>
                            <MDBCardHeader>
                                <span className={"float-left title"}>Add Product</span>
                                <MDBFormInline className="float-right md-form mr-auto m-0">
                                    <MDBBtn outline color="blue-grey" size="sm" onClick={() =>this.props.history.push('/view-products')} className="mr-auto">
                                        Back
                                    </MDBBtn>
                                    <MDBBtn outline color="blue-grey" size="sm"  className="mr-auto">
                                        Save
                                    </MDBBtn>
                                </MDBFormInline>
                            </MDBCardHeader>
                            <MDBCardBody>
                                <MDBRow>
                                    <MDBCol>
                                        <MDBInput label="Product name" size="lg" />
                                    </MDBCol>
                                    <MDBCol>
                                        <MDBSelect
                                            search
                                            options={this.state.options}
                                            selected="Choose your option"
                                            label="Retail Packaging"
                                        />
                                    </MDBCol>
                                    <MDBCol>
                                        <MDBSelect
                                            search
                                            options={this.state.options}
                                            selected="Choose your option"
                                            label="Packaging Unit"
                                        />
                                    </MDBCol>
                                    <MDBCol>
                                        <MDBInput label="Packaging Quantity" size="lg" />
                                    </MDBCol>
                                </MDBRow>
                            </MDBCardBody>
                        </MDBCard>
                    </form>
                <br/>
            </React.Fragment>
        );
    }
}
export default withRouter(AddProductV2Comp);


