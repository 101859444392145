import { Box, Tab } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import React from "react";
import Moments from "moment";
import LandingPage from "./editAddress";
import CalllogEditPage from "./addCallLog";
import { withRouter } from "react-router-dom";
import AddAddressPage from "./addCustomerAddress";
import {
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
} from "mdbreact";
import {
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBCardTitle,
  MDBCol,
  MDBContainer,
  MDBInput,
  MDBRow,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdbreact";
import AuthService from "../../services/authService";
import { useEffect } from "react";
import { toast } from "react-toastify";
import SinglePicDirectUpload from "../../components/common/fileUpload/singlePicDirectUpload";
import config from "../../config";
import UserStore from "../../stores/userStore";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CustomerSearchViewMore = (props) => {
  const [value, setValue] = React.useState("1");
  const [dataFetch, setDataFetch] = React.useState(null);
  const [callLogs, setCallLogs] = React.useState([]);
  const [fetchOrderAnalytics, setFetchOrderAnalytics] = React.useState();
  const [fetchProductAnalytics, setFetchProductAnalytics] = React.useState();
  const [callRecordCount, setCallRecordCount] = React.useState();
  const [productRecordCount, setProductRecordCount] = React.useState();
  const [orderRecordCount, setOrderRecordCount] = React.useState();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [orderCurrentPage, setOrderCurrentPage] = React.useState(1);
  const [productCurrentPage, setProductCurrentPage] = React.useState(1);
  const [userData, setUserData] = React.useState([]);
  const [selectedDate, setSelectedDate] = React.useState();
  const [isEditMode, setEditMode] = React.useState(false);
  const [showLandingPage, setShowLandingPage] = React.useState(false);
  const [showAddAddressPage, setShowAddAddressPage] = React.useState(false);
  const [sendChildData, setSendChildData] = React.useState();
  const [sendErrorProduct, setSendErrorProduct] = React.useState(false);
  const [sendErrorOrder, setSendErrorOrder] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoadingOrder, setIsLoadingOrder] = React.useState(false);
  const [orderTotalAmount, setOrderTotalAmount] = React.useState();
  const [totalBalanceAmount, setTotalBalanceAmount] = React.useState();
  const [emailError, setEmailError] = React.useState(null);
  const [editedData, setEditedData] = React.useState({
    id: "",
    name: "",
    phone: "",
    userType: "",
    email: "",
    gender: "",
    height: "",
    weight: "",
    dob: "",
  });
  const { userId, userType, phone } = props.match.params;

  const fetchCustomerIndividualAllData = async (userId, userType) => {
    setIsLoading(true);
    try {
      const data = await AuthService.fetchSearchcustomerIndividualData(
        userId,
        userType
      );
      setDataFetch(data);

      if (data && data.customerProfile && data.customerProfile.dob) {
        setSelectedDate(formatDate(data.customerProfile.dob));
      } else {
        console.error("Data format is incorrect:", data);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };
  const fetchCustomerIndividualCallLog = async (userId, currentPage) => {
    setIsLoading(true);
    try {
      const data = await AuthService.fetchSearchcustomerCallLogs(
        userId,
        currentPage
      );
      setCallLogs(data.data);
      setCallRecordCount(data.totalRecords);
      setCurrentPage(data.page);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching call logs:", error);
      setCallLogs([]);
      setIsLoading(false);
    }
  };
  const formatDate = (inputDate) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = new Date(inputDate).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  };
  const formatDatewithTime = (inputDate) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true, // Include AM or PM
    };
    const formattedDate = new Date(inputDate).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleDateChange = (newDate) => {
    setSelectedDate(newDate, () => {});
  };

  useEffect(() => {
    fetchCustomerIndividualAllData(userId, userType);
    fetchCustomerIndividualCallLog(userId, currentPage);
    fetchCustomerOrderAnalyticsData(userId, orderCurrentPage);
    fetchCustomerProductAnalyticsData(userId, productCurrentPage);
    fetchUserData();
    if (isEditMode && dataFetch?.customerProfile?.dob) {
      setSelectedDate(new Date(dataFetch.customerProfile.dob));
    }
  }, []);

  const handleEditClick = (e) => {
    e.preventDefault();
    setEditedData({
      name: dataFetch.customerDetails.name,
      phone: dataFetch.customerDetails.phone,
      userType: dataFetch.customerDetails.customerType,
      id: dataFetch.customerDetails._id,
      profilePic: {
        filePathOpt: dataFetch.customerDetails.profilePic.filePathOpt,
        filePathOriginal: dataFetch.customerDetails.profilePic.filePathOriginal,
        filePathThumb: dataFetch.customerDetails.profilePic.filePathThumb,
      },
      email: dataFetch.customerProfile?.email || "",
      gender: dataFetch.customerProfile?.gender || "",
      height: dataFetch.customerProfile?.height || "",
      weight: dataFetch.customerProfile?.weight || "",
      dob: selectedDate || "",
    });

    setEditMode(true);
  };

  const handleSaveEditDetails = async (e) => {
    e.preventDefault();
    try {
      const editedDataWithDate = {
        ...editedData,
        dob: selectedDate, // Use the selected date here
      };
      console.log(editedDataWithDate);

      // if(!editedDataWithDate.gender || !editedDataWithDate.height || !editedDataWithDate.weight){
      //   toast.error("One of height , weight or gender is missing");
      //   return
      // }

      const res = await AuthService.saveEditedData(editedDataWithDate);
      if (res) {
        fetchCustomerIndividualAllData(userId, userType);
        toast.success("Customer details updated successfully.");
      }
      setEditMode(false);
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "email") {
      validateEmail(value);
    }

    setEditedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(email);

    if (!isValid) {
      setEmailError("Enter a valid email address");
    } else {
      setEmailError(null);
    }
  };

  const modelclose = () => {
    setShowLandingPage(false);
    fetchCustomerIndividualCallLog(userId, currentPage);
    fetchCustomerIndividualAllData(userId, userType);
    fetchCustomerOrderAnalyticsData(userId, orderCurrentPage);
    fetchCustomerProductAnalyticsData(userId, productCurrentPage);
  };
  const modelcloseAddAddress = () => {
    setShowAddAddressPage(false);
    fetchCustomerIndividualCallLog(userId, currentPage);
    fetchCustomerIndividualAllData(userId, userType);
    fetchCustomerOrderAnalyticsData(userId, orderCurrentPage);
    fetchCustomerProductAnalyticsData(userId, productCurrentPage);
  };
  const toggleLandingPage = (item) => {
    setSendChildData(item);
    setShowLandingPage((prevShowLandingPage) => !prevShowLandingPage);
  };
  const toggleAddressPage = () => {
    setShowAddAddressPage((prevShowAddAddressPage) => !prevShowAddAddressPage);
  };

  const [profilePic, setProfilePic] = React.useState({
    filePathOpt: "",
    filePathOriginal: "",
    filePathThumb: "",
  });

  const getUploadedFiles = async (files) => {
    if (files.length > 0) {
      const updatedProfilePic = {
        filePathOpt: config.IMAGE_HOST + files[0].filePathOpt,
        filePathOriginal: config.IMAGE_HOST + files[0].filePathOriginal,
        filePathThumb: config.IMAGE_HOST + files[0].filePathThumb,
      };
      setProfilePic(updatedProfilePic);

      // Assuming you have access to dataFetch here
      setEditedData((prevData) => ({
        ...prevData,
        profilePic: updatedProfilePic,
      }));
    }
  };
  const fetchUserData = async () => {
    try {
      let userDatavalues = await UserStore.fetchSessionData();

      if (userDatavalues) {
        setUserData(userDatavalues);
      }
    } catch (error) {
      console.error("facing some error while fetching:", error);
    }
  };
  const fetchCustomerOrderAnalyticsData = async (userId, orderCurrentPage) => {
    setIsLoadingOrder(true);
    try {
      const data = await AuthService.fetchOrderAnalyticsData(
        userId,
        orderCurrentPage
      );
      if (data.code == 2011) {
        setSendErrorOrder(true);
      } else {
        setFetchOrderAnalytics(data.data);
        setOrderTotalAmount(data.totalPurchaseAmount);
        setTotalBalanceAmount(data.totalBalanceAmount);
        setOrderRecordCount(data.totalRecords);
        setOrderCurrentPage(data.currentPage);
      }
      setIsLoadingOrder(false);
    } catch (error) {
      console.error("Error fetching call logs:", error);
      setFetchOrderAnalytics([]);
      setIsLoadingOrder(false);
    }
  };
  const fetchCustomerProductAnalyticsData = async (
    userId,
    productCurrentPage
  ) => {
    setIsLoading(true);
    try {
      const data = await AuthService.fetchProductAnalyticsData(
        userId,
        productCurrentPage
      );
      if (data.code == 2011) {
        setSendErrorProduct(true);
      } else {
        setFetchProductAnalytics(data.data);
        setProductRecordCount(data.totalRecords);
        setProductCurrentPage(data.currentPage);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching call logs:", error);
      setFetchOrderAnalytics([]);
      setIsLoading(false);
    }
  };

  const onChange = async (newPage) => {
    setCurrentPage(newPage);
    await fetchCustomerIndividualCallLog(userId, newPage);
  };

  const onChangeorder = async (newPage) => {
    setOrderCurrentPage(newPage);
    await fetchCustomerOrderAnalyticsData(userId, newPage);
  };
  const onChangeProduct = async (newPage) => {
    setProductCurrentPage(newPage);
    await fetchCustomerProductAnalyticsData(userId, newPage);
  };

  function convertAndTitleCase(inputString) {
    // Replace underscores with spaces and convert to title case
    let titleCaseString = inputString
      .replace(/_/g, " ")
      .toLowerCase()
      .replace(/^\w/, (c) => c.toUpperCase());
    return titleCaseString;
  }

  const startIndex = (currentPage - 1) * 10;
  const orderStartIndex = (orderCurrentPage - 1) * 10;
  const productStartIndex = (productCurrentPage - 1) * 10;

  console.log({ selectedDate });

  return (
    <div>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box
            sx={{
              border: 1,
              borderColor: "#64db25",
              "& .MuiTabs-indicator": {
                backgroundColor: "green",
              },
            }}
          >
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="customer Details" value="1" />
              <Tab label="Addresses" value="2" />
              <Tab label="call log" value="3" />
              <Tab label="Product Analytics" value="4" />
            </TabList>
          </Box>
          <TabPanel value="1">
            {!isLoading ? (
              <>
                {dataFetch && dataFetch.customerDetails && (
                  <div className="main-customer-details-display-div">
                    <div>
                      <div className="show-image-of-customer">
                        {isEditMode ? (
                          <div>
                            {editedData.filePathOpt ? (
                              <img
                                src={
                                  editedData.customerDetails.profilePic
                                    .filePathOpt
                                }
                                alt="Profile Picture"
                              />
                            ) : (
                              <span></span>
                            )}
                            <SinglePicDirectUpload
                              getUploadedFiles={getUploadedFiles}
                            />
                          </div>
                        ) : (
                          <div className="image-of-customer-main">
                            <img
                              src={
                                dataFetch.customerDetails.profilePic
                                  .filePathOriginal
                                  ? dataFetch.customerDetails.profilePic
                                      .filePathOriginal
                                  : "/broken-img.jpg"
                              }
                              alt="Profile Pic"
                              style={{
                                maxWidth: "100%",
                                maxHeight: "100%",
                                width: "auto",
                                height: "auto",
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="details-of-customer">
                      <MDBContainer>
                        <MDBRow>
                          <MDBCol md="6">
                            <form>
                              <div className="row-for-show-customer-Details">
                                <MDBInput
                                  label="Customer Code :"
                                  value={dataFetch.customerDetails.customerCode}
                                  readOnly
                                  className="row-for-show-customer-Details-input"
                                />
                                <MDBInput
                                  label="Customer Type :"
                                  value={
                                    dataFetch.customerDetails.customerType ===
                                    "ONLINE_CUSTOMER"
                                      ? "Online"
                                      : "Offline"
                                  }
                                  readOnly
                                  name="customerType"
                                  onChange={handleInputChange}
                                  className="row-for-show-customer-Details-input"
                                />
                              </div>

                              <div className="row-for-show-customer-Details">
                                <MDBInput
                                  label="Customer Name :"
                                  value={
                                    isEditMode
                                      ? editedData.name
                                      : dataFetch.customerDetails.name
                                  }
                                  readOnly={!isEditMode}
                                  name="name"
                                  onChange={handleInputChange}
                                  className="row-for-show-customer-Details-input"
                                />

                                <MDBInput
                                  label="Customer Verification:"
                                  value={
                                    dataFetch.customerDetails.isVerified ===
                                    false
                                      ? "Unverified"
                                      : "Verified"
                                  }
                                  readOnly
                                  className="row-for-show-customer-Details-input"
                                />
                              </div>
                              <div className="row-for-show-customer-Details">
                                <MDBInput
                                  label="country Code:"
                                  value={dataFetch.customerDetails.countryCode}
                                  readOnly
                                  className="row-for-show-customer-Details-input"
                                />
                                <MDBInput
                                  label="Customer Since:"
                                  value={
                                    Moments(
                                      dataFetch.customerDetails.createdAt
                                    ).format("YYYY-MM-DD")
                                      ? formatDate(
                                          dataFetch.customerDetails.createdAt
                                        )
                                      : ""
                                  }
                                  readOnly
                                  className="row-for-show-customer-Details-input"
                                />
                              </div>
                              {dataFetch.customerDetails.phone && (
                                <div className="row-for-show-customer-Details">
                                  <MDBInput
                                    label="Phone :"
                                    value={
                                      isEditMode
                                        ? editedData.phone
                                        : dataFetch.customerDetails.phone
                                    }
                                    readOnly={!isEditMode}
                                    name="phone"
                                    onChange={handleInputChange}
                                    className="row-for-show-customer-Details-input"
                                  />
                                  <MDBInput
                                    label="Phone No. Verification:"
                                    value={
                                      dataFetch.customerDetails
                                        .isPhoneVerified === false
                                        ? "Unverified"
                                        : "Verified"
                                    }
                                    readOnly
                                    className="row-for-show-customer-Details-input"
                                  />
                                </div>
                              )}
                              <div className="row-for-show-customer-Details">
                                <>
                                  <div>
                                    <MDBInput
                                      label="Email :"
                                      value={
                                        isEditMode
                                          ? editedData.email
                                          : dataFetch.customerProfile
                                          ? dataFetch.customerProfile.email
                                          : ""
                                      }
                                      readOnly={!isEditMode}
                                      name="email"
                                      onChange={handleInputChange}
                                      className="row-for-show-customer-Details-input"
                                    />
                                    {emailError && (
                                      <div className="error-message-for-email-validation">
                                        {emailError}
                                      </div>
                                    )}
                                  </div>
                                  <MDBInput
                                    label="Email Verification :"
                                    value={
                                      dataFetch.customerProfile
                                        ? dataFetch.customerProfile
                                            .isEmailVerified === false
                                          ? "Unverified"
                                          : "Verified"
                                        : ""
                                    }
                                    readOnly
                                    className="row-for-show-customer-Details-input"
                                  />
                                </>
                              </div>
                              {dataFetch.customerDetails
                                .registrationMetaData && (
                                <div className="row-for-show-customer-Details">
                                  <MDBInput
                                    label="IP Address :"
                                    value={
                                      dataFetch.customerDetails
                                        .registrationMetaData.ip
                                    }
                                    readOnly
                                    className="row-for-show-customer-Details-input"
                                  />
                                  <MDBInput
                                    label="Device :"
                                    value={
                                      dataFetch.customerDetails
                                        .registrationMetaData.device
                                    }
                                    readOnly
                                    className="row-for-show-customer-Details-input"
                                  />
                                </div>
                              )}
                              <div className="row-for-show-customer-Details">
                                {isEditMode ? (
                                  <div className="d-flex flex-column mb-4">
                                    <h6 className="font-weight-bold mb-2" style={{color:"#4F4F4F"}}>Date of Birth :</h6>
                                    <ReactDatePicker
                                      selected={selectedDate ? new Date(selectedDate) : null}
                                      onChange={(date) => handleDateChange(date)}
                                      className="custom-datepicker-input"
                                      maxDate={new Date()}
                                      placeholderText="Select DOB"
                                      dateFormat="dd/MM/yyyy"
                                      showYearDropdown
                                      scrollableYearDropdown
                                      yearDropdownItemNumber={100}
                                    />
                                  </div>
                                ) : (
                                  <MDBInput
                                    label="Date of Birth :"
                                    value={
                                      dataFetch.customerProfile !== null
                                        ? Moments(selectedDate).format(
                                            "MMM D, YYYY"
                                          )
                                        : ""
                                    }
                                    readOnly
                                    name="dob"
                                    className="row-for-show-customer-Details-input"
                                  />
                                )}
                                {isEditMode ? (
                                  <div className="row-for-show-customer-Details-input">
                                    <label htmlFor="gender">
                                      Select Gender:
                                    </label>
                                    <select
                                      id="gender"
                                      value={
                                        isEditMode
                                          ? editedData.gender
                                          : dataFetch.customerProfile
                                          ? dataFetch.customerProfile.gender
                                          : ""
                                      }
                                      name="gender"
                                      onChange={handleInputChange}
                                      className="row-for-show-customer-Details-input-select"
                                      required
                                    >
                                      <option value="" disabled>
                                        Select
                                      </option>
                                      <option value="MALE">Male</option>
                                      <option value="FEMALE">Female</option>
                                    </select>
                                  </div>
                                ) : (
                                  <MDBInput
                                    label="gender :"
                                    value={
                                      dataFetch.customerProfile
                                        ? dataFetch.customerProfile.gender
                                        : ""
                                    }
                                    readOnly
                                    name="gender"
                                    onChange={handleInputChange}
                                    className="row-for-show-customer-Details-input"
                                  />
                                )}
                              </div>
                              <div className="row-for-show-customer-Details">
                                <MDBInput
                                  label="Height (cm):"
                                  value={
                                    isEditMode
                                      ? editedData.height
                                      : dataFetch.customerProfile
                                      ? dataFetch.customerProfile.height
                                      : ""
                                  }
                                  readOnly={!isEditMode}
                                  name="height"
                                  onChange={handleInputChange}
                                  className="row-for-show-customer-Details-input"
                                />
                                <MDBInput
                                  label="Weight :"
                                  value={
                                    isEditMode
                                      ? editedData.weight
                                      : dataFetch.customerProfile
                                      ? dataFetch.customerProfile.weight
                                      : ""
                                  }
                                  readOnly={!isEditMode}
                                  name="weight"
                                  onChange={handleInputChange}
                                  className="row-for-show-customer-Details-input"
                                />
                              </div>

                              <div className="button-div-to-save-edit">
                                {!isEditMode ? (
                                  <button
                                    className="button-for-save-edit"
                                    onClick={handleEditClick}
                                  >
                                    Edit
                                  </button>
                                ) : (
                                  <div className="cancel-and-save-div">
                                    <button
                                      className="button-for-save-edit"
                                      onClick={handleSaveEditDetails}
                                    >
                                      Save
                                    </button>
                                    <button
                                      className="button-for-cancel-edit"
                                      onClick={() => setEditMode(false)}
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                )}
                              </div>
                            </form>
                          </MDBCol>
                        </MDBRow>
                      </MDBContainer>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div>
                <i class="fas fa-spinner">Loading...</i>
              </div>
            )}
          </TabPanel>

          <TabPanel value="2">
            <MDBContainer>
              <div className="button-div-in-add-address">
                <div className="display-title-of-address-page">
                  <h5>
                    <b>Customer Addresses</b>
                    {dataFetch && dataFetch.customerDetails && (
                      <b>- {dataFetch.customerDetails.name}</b>
                    )}
                  </h5>
                </div>
                <button
                  className="button-add-Address-customer"
                  onClick={() => toggleAddressPage()}
                >
                  <i class="fas fa-plus"></i>&nbsp;Add Address
                </button>
              </div>
              {!isLoading ? (
                <>
                  {dataFetch && dataFetch.addresses && (
                    <MDBRow>
                      {dataFetch.addresses.map((address, index) => (
                        <MDBCol key={index} md="6">
                          <MDBCard style={{ margin: "8px" }}>
                            <MDBCardBody className="customer-address-form">
                              <div className="edit-with-title-in-address">
                                <MDBCardTitle>
                                  {`Address ${index + 1}`}
                                </MDBCardTitle>
                                <div className="edit-div-for-address">
                                  <button
                                    className="edit-button-addres"
                                    onClick={() => toggleLandingPage(address)}
                                  >
                                    edit
                                  </button>
                                </div>
                              </div>

                              <MDBCardText>
                                <strong>Address Line 1:</strong>
                                {address.addressLine1}
                                <br />
                                <strong>Address Line 2:</strong>
                                {address.addressLine2}
                                <br />
                                <strong>City:</strong> {address.city}
                                <br />
                                <strong>State:</strong> {address.state}
                                <br />
                                <strong>Country:</strong> {address.country}
                                <br />
                                <strong>Pin Code:</strong> {address.pinCode}
                                <br />
                                <strong>Is Default:</strong>
                                {address.isDefault ? " Yes " : " No "}
                                <br />
                                <strong>Name:</strong> {address.name}
                                <br />
                                <strong>Phone:</strong> {address.phone}
                              </MDBCardText>
                            </MDBCardBody>
                          </MDBCard>
                          <div className="popup-for-search-customer">
                            <MDBModal
                              isOpen={showLandingPage}
                              toggle={() => toggleLandingPage(null)}
                              size="fluid"
                            >
                              <MDBModalHeader
                                toggle={() => toggleLandingPage(null)}
                              >
                                Edit Address - {dataFetch.customerDetails.name}
                              </MDBModalHeader>
                              <MDBModalBody>
                                <LandingPage
                                  data={sendChildData}
                                  modelclose={modelclose}
                                />
                              </MDBModalBody>
                            </MDBModal>
                          </div>
                        </MDBCol>
                      ))}
                    </MDBRow>
                  )}
                </>
              ) : (
                <div>
                  <i class="fas fa-spinner">Loading...</i>
                </div>
              )}
            </MDBContainer>

            <div className="popup-for-search-customer">
              <MDBModal
                isOpen={showAddAddressPage}
                toggle={() => toggleAddressPage(null)}
                size="fluid"
              >
                <MDBModalHeader toggle={() => toggleAddressPage(null)}>
                  Add Address -
                  {dataFetch &&
                    dataFetch.customerDetails &&
                    dataFetch.customerDetails.name}
                </MDBModalHeader>
                <MDBModalBody>
                  {dataFetch && dataFetch.customerDetails && (
                    <AddAddressPage
                      customerType={dataFetch.customerDetails.customerType}
                      userId={dataFetch.customerDetails._id}
                      modelcloseAddAddress={modelcloseAddAddress}
                    />
                  )}
                </MDBModalBody>
              </MDBModal>
            </div>
          </TabPanel>
          <TabPanel value="3">
            <div className="last-tab-for-customer-details">
              <div className="add-and-header-call-log">
                {dataFetch && dataFetch.customerDetails && (
                  <div>Call Log - {dataFetch.customerDetails.name}</div>
                )}
                <div>
                  <button
                    className="button-add-call-log-customer"
                    onClick={() => toggleLandingPage(null)}
                  >
                    <i class="fas fa-plus"></i>&nbsp;Add Call
                  </button>
                </div>
              </div>

              <div className="table-of-call-log">
                <MDBTable striped>
                  <MDBTableHead>
                    <tr>
                      <th>SL no.</th>
                      <th>subject</th>
                      <th>summary</th>
                      <th>Customer Care Person name</th>
                      <th>created At</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {!isLoading ? (
                      <>
                        {callLogs &&
                          callLogs.map((call, index) => (
                            <tr key={index}>
                              <td>{startIndex + index + 1}</td>
                              <td>{call.subject}</td>
                              <td>{call.summary}</td>
                              <td>{call.customerCarePersonName}</td>
                              <td>
                                {Moments(call.createdAt).format("YYYY-MM-DD")
                                  ? formatDatewithTime(call.createdAt)
                                  : ""}
                              </td>
                            </tr>
                          ))}
                      </>
                    ) : (
                      <>
                        <i class="fas fa-spinner">Loading...</i>
                      </>
                    )}
                  </MDBTableBody>
                  {callRecordCount > 0 && (
                    <div className="main-div-of-pagination">
                      <div className={"my-5 d-flex total-record-div-of-call"}>
                        Total Records : {callRecordCount}
                      </div>
                      <div className={"my-5 d-flex"} id={"paginate"}>
                        {callRecordCount > 10 && (
                          <Pagination
                            className="ant-pagination"
                            onChange={onChange}
                            current={parseInt(currentPage)}
                            total={parseInt(callRecordCount)}
                            pageSize={10}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </MDBTable>
              </div>
              <div className="popup-for-search-customer">
                <MDBModal
                  isOpen={showLandingPage}
                  toggle={() => toggleLandingPage(null)}
                  size="lg"
                >
                  <MDBModalHeader toggle={() => toggleLandingPage(null)}>
                    Add Call Log -
                    {dataFetch &&
                      dataFetch.customerDetails &&
                      dataFetch.customerDetails.name}
                  </MDBModalHeader>
                  <MDBModalBody>
                    {dataFetch && dataFetch.customerDetails && (
                      <CalllogEditPage
                        modelclose={modelclose}
                        userId={dataFetch.customerDetails._id}
                      />
                    )}
                  </MDBModalBody>
                </MDBModal>
              </div>
            </div>
          </TabPanel>
          <TabPanel value="4">
            <div className="tab-4-main-div">
              <div className="tab-4-main-div-sub-first">
                {dataFetch && dataFetch.customerDetails && (
                  <div>
                    <h5>
                      <b>
                        Customer Orders and Product Analytics -&nbsp;
                        {dataFetch.customerDetails.name}
                      </b>
                    </h5>
                  </div>
                )}
                <div className="title-of-Purchase">
                  {orderTotalAmount && (
                    <>
                      <div>
                        Total Amount Of Purchase : &nbsp;
                        <b>{orderTotalAmount}</b>
                      </div>
                      <div>
                        Total Balance Amount: &nbsp;
                        <b>{totalBalanceAmount}</b>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="tab-4-main-div-sub-second">
                <div className="table-div-tab-4">
                  <div className="add-and-header-for-tab-4">
                    <div>Product Analytics</div>
                    {productRecordCount ? (
                      <div className={"my-5 d-flex total-record-div-of-call"}>
                        Total Records : {productRecordCount}
                      </div>
                    ) : (
                      <div className={"my-5 d-flex total-record-div-of-call"}>
                        Total Records : 0
                      </div>
                    )}
                  </div>
                  {!sendErrorProduct ? (
                    <MDBTable striped>
                      <MDBTableHead>
                        <tr>
                          <th>SL no.</th>
                          <th>Product Name</th>
                          <th>Product Quantity</th>
                        </tr>
                      </MDBTableHead>
                      <MDBTableBody>
                        {!isLoading ? (
                          <>
                            {fetchProductAnalytics &&
                              fetchProductAnalytics.map((Product, index) => (
                                <tr key={index}>
                                  <td>{productStartIndex + index + 1}</td>
                                  <td>{Product.productName}</td>
                                  <td>{Product.totalQuantity}</td>
                                </tr>
                              ))}
                          </>
                        ) : (
                          <div>
                            <i class="fas fa-spinner">Loading...</i>
                          </div>
                        )}
                      </MDBTableBody>
                    </MDBTable>
                  ) : (
                    <div className="error-message-block">
                      The customer has not purchased anything yet.
                    </div>
                  )}
                  <div className=" flex-end-css">
                    <div className={"my-5 d-flex"} id={"paginate"}>
                      {productRecordCount > 10 && (
                        <Pagination
                          className="ant-pagination"
                          onChange={onChangeProduct}
                          current={parseInt(productCurrentPage)}
                          total={parseInt(productRecordCount)}
                          pageSize={10}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="table-div-tab-4">
                  <div className="add-and-header-for-tab-4">
                    <div>Order Analytics</div>
                    {orderRecordCount ? (
                      <div className={"my-5 d-flex total-record-div-of-call"}>
                        Total Records : {orderRecordCount}
                      </div>
                    ) : (
                      <div className={"my-5 d-flex total-record-div-of-call"}>
                        Total Records : 0
                      </div>
                    )}
                  </div>
                  {!sendErrorOrder && fetchOrderAnalytics ? (
                    <MDBTable striped>
                      <MDBTableHead>
                        <tr>
                          <th>SL no.</th>
                          <th>Order Date</th>
                          <th>Order#</th>
                          <th>Total Items</th>
                          <th>Outstanding</th>
                          <th>Order Mode</th>
                          <th>status</th>
                          <th>Payment Mode</th>
                          <th>Total</th>
                        </tr>
                      </MDBTableHead>
                      <MDBTableBody>
                        {!isLoadingOrder ? (
                          <>
                            {fetchOrderAnalytics.map((Orderdata, index) => (
                              <tr key={index}>
                                <td>{orderStartIndex + index + 1}</td>
                                <td>{formatDate(Orderdata.orderDate)}</td>
                                <td>{Orderdata.orderCode}</td>
                                <td>{Orderdata.totalItems}</td>
                                <td>{Orderdata.balanceAmount}</td>
                                <td>
                                  {Orderdata.orderMode === "INPERSON"
                                    ? "Offline"
                                    : "Online"}
                                </td>
                                <td>{convertAndTitleCase(Orderdata.status)}</td>
                                <td>
                                  {convertAndTitleCase(Orderdata.paymentMode)}
                                </td>
                                <td>{Orderdata.orderAmount}</td>
                              </tr>
                            ))}
                          </>
                        ) : (
                          <div>
                            <i class="fas fa-spinner">Loading...</i>
                          </div>
                        )}
                      </MDBTableBody>
                    </MDBTable>
                  ) : (
                    <div className="error-message-block">
                      The customer has not placed any order yet.
                    </div>
                  )}
                  <div className="flex-end-css">
                    <div className={"my-5 d-flex"} id={"paginate"}>
                      {orderRecordCount > 10 && (
                        <Pagination
                          className="ant-pagination"
                          onChange={onChangeorder}
                          current={parseInt(orderCurrentPage)}
                          total={parseInt(orderRecordCount)}
                          pageSize={10}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
};

export default withRouter(CustomerSearchViewMore);
