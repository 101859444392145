import React from "react";
import {Link, withRouter} from "react-router-dom";
import {
    MDBContainer, MDBRow, MDBInput, MDBFormInline, MDBBtn, MDBCol,
    MDBCard, MDBCardBody, MDBCardHeader, MDBBreadcrumb, MDBBreadcrumbItem, MDBChip, MDBBox
} from 'mdbreact';
import UserStore from "../../stores/userStore";
import {toast} from "react-toastify";
import PharmacyService from "../../services/pharmacyService";
import FileUpload from "../common/fileUpload/fileUpload";
import util from "../../utility/utility";
import SimpleBackdrop from "../common/overlay";

class AddManufacturer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            manufacturer:"",
            description:"",
            isActive:"",
            imageUrl:{},
            progressStatus:false,
            progressStatusFileUpload:false,
            manufacturerError:"",
            address: "",
            street: "",
            pincode:"",
            area: "",
            city: "",
            state: "",
            country: "",
            landmark: "",
            resetForm:false,
            isSubcomponent:false,
            suggestedData:[]
        }
    }
    handleDescription=(e)=>{
        this.setState({description:e.target.value})
    };
    handleManufacturer=async (e)=>{
        this.setState({manufacturer:e.target.value});

        //suggestion part
        let userData=await UserStore.fetchSessionData();
        let mfgSuggest=await PharmacyService.suggestMfg(this.state.manufacturer,userData);
        this.setState({suggestedData:mfgSuggest});
    };
    validateManufacturer = () => {
        if(!this.state.manufacturer || this.state.manufacturer.trim() === ""){
            this.setState({"manufacturerError": true})
        } else {
            this.setState({"manufacturerError": false})
        }
    }
    addManufacturer =async () => {
        let userData=await UserStore.fetchSessionData();
        // Reset all the error first
        this.setState({"manufacturerError": false})
        //validations
        this.validateManufacturer();
        if(this.state.manufacturerError){
            return false;
        }
        this.setState({progressStatus:true});
        let status=await PharmacyService.addManufacturer(this.state,userData);
        this.setState({progressStatus:false});
        // This is to send back the parent compponent about the status
        if(this.state.isSubComponent){
            this.props.subComponentActionPerformed(status)
        }
        if(status===false){
            toast.error("Something went wrong.");
            return true;
        }else{
            toast.success("Manufacturer added successfully.");
            this.setState({resetForm:true})
            this.setState({"manufacturer":"", "description":"", "addressLine1":"", "addressLine2":"", "city":"",  "state":"", "country":"", "pincode":"",  "landmark":""})
        }
    }
    fileUploadCallback = (uploadedFileObject) => {
        this.setState({ "imageUrl": uploadedFileObject.path, "progressStatusFileUpload":false})
    }
    initUploadCallback = () => {
        this.setState({"progressStatusFileUpload":true, resetForm:false})
    }
    handleAddress=(e)=>{
        this.setState({address:e.target.value})
    };
    handleStreet=(e)=>{
        this.setState({street:e.target.value})
    };
    handlePincode=(e)=>{
        if(!util.isInt(e.target.value) && e.target.value !== ""){
            return false;
        }
        this.setState({pincode:e.target.value})
    };
    handleArea=(e)=>{
        this.setState({area:e.target.value})
    };
    handleCity=(e)=>{
        this.setState({city:e.target.value})
    };
    handleState=(e)=>{
        this.setState({state:e.target.value})
    };
    handleCountry=(e)=>{
        this.setState({country:e.target.value})
    };
    handleLandmark=(e)=>{
        this.setState({landmark:e.target.value})
    };
    componentDidMount() {
        if(this.props.hasOwnProperty("isSubComponent")){
            this.setState({isSubComponent:true})
        }
    }
    navigate=(item)=>{
        this.props.history.push('/edit-manufacturer/'+item._id);
    }
    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.progressStatus}/>
                {!this.state.isSubComponent &&
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem><Link to={'/view-manufacturers'}>Manufacturers</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Add Manufacturer</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                }
                <MDBContainer>
                    <MDBCard className={"mt-2"}>
                        <MDBCardHeader>
                            <div className={"float-left title"}>Add Manufacturer</div>
                        </MDBCardHeader>
                        <MDBCardBody>
                            <MDBRow>
                                <MDBCol>
                                    <MDBInput autoComplete={'off'} style={{"marginTop": "0px"}} onBlur={this.validateManufacturer}
                                              className={this.state.manufacturerError ? "border-danger" : "mt-0 mb-0"}
                                              maxLength="100" type='text' label="Manufacturer *"
                                              onChange={this.handleManufacturer}
                                              value={this.state.manufacturer}/>
                                    <MDBBox>
                                        {this.state.suggestedData.length > 0 &&
                                        <div>
                                            <h6>Update existing manufacturers:</h6>
                                            <br/>
                                        </div>
                                        }
                                        {this.state.suggestedData.map((item, idx) => (
                                            <span key={idx} onClick={()=>{this.navigate(item)}}><MDBChip bgColor="light-blue lighten-4" waves>{item.manufacturer}</MDBChip></span>
                                        ))}
                                    </MDBBox>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBInput autoComplete={'off'} style={{"marginTop": "0px"}} background={'sm'}
                                              className={this.state.descriptionError ? "border-danger" : "mt-0 mb-0"}
                                              maxLength="200" type='textarea' label="Description"
                                              onChange={this.handleDescription}
                                              value={this.state.description}/>
                                </MDBCol>
                            </MDBRow>

                            <MDBRow>
                                <MDBCol size={"6"}>
                                    <MDBInput className={this.state.addressError ? "border-danger" : ""} maxLength="50"
                                              type='text' label="Address Line 1" onChange={this.handleAddress}
                                              value={this.state.address}/>
                                </MDBCol>
                                <MDBCol size={"6"}>
                                    <MDBInput className={this.state.streetError ? "border-danger" : ""} maxLength="50"
                                              type='text' label="Address Line 2" onChange={this.handleStreet}
                                              value={this.state.street}/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBInput className={this.state.pincodeError ? "border-danger" : ""} maxLength="6"
                                              type='text' label="Postal Code" onChange={this.handlePincode}
                                              value={this.state.pincode}/>
                                </MDBCol>
                                <MDBCol>
                                    <MDBInput className={this.state.areaError ? "border-danger" : ""} maxLength="35"
                                              type='text' label="Area/Locality" onChange={this.handleArea}
                                              value={this.state.area}/>
                                </MDBCol>
                                <MDBCol>
                                    <MDBInput   className={this.state.cityError ? "border-danger" : ""} maxLength="35"
                                              type='text' label="City" onChange={this.handleCity}
                                              value={this.state.city}/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBInput className={this.state.stateError ? "border-danger" : ""} maxLength="35"
                                              type='text' label="State" onChange={this.handleState}
                                              value={this.state.state}/>
                                </MDBCol>
                                <MDBCol>
                                    <MDBInput className={this.state.landmarkError ? "border-danger" : ""} maxLength="50"
                                              type='text' label="Landmark" onChange={this.handleLandmark}
                                              value={this.state.landmark}/>
                                </MDBCol>
                                <MDBCol>
                                    <MDBInput className={this.state.countryError ? "border-danger" : ""} maxLength="35"
                                              type='text' label="Country" onChange={this.handleCountry}
                                              value={this.state.country}/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <FileUpload buttonLabel={"Add Manufacturer Logo"}
                                                initUploadCallback={this.initUploadCallback}
                                                fileUploadCallback={this.fileUploadCallback} nextProps={this.state.resetForm}></FileUpload>
                                </MDBCol>
                            </MDBRow>

                            <div className="m-0 text-center">
                                {!this.state.isSubComponent &&
                                <MDBBtn outline size="md"
                                        onClick={() => this.props.history.push('/view-manufacturers')} className="mr-auto"
                                        disabled={this.state.progressStatus}>
                                    Back
                                </MDBBtn>
                                }
                                <MDBBtn size="md" onClick={this.addManufacturer} className="mr-auto"
                                        disabled={this.state.manufacturerError || this.state.progressStatus || this.state.progressStatusFileUpload}>
                                    Save
                                </MDBBtn>
                            </div>

                        </MDBCardBody>
                    </MDBCard>
                </MDBContainer>
            </React.Fragment>
        );
    }
}
export default withRouter(AddManufacturer)
