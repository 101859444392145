import React from "react";
import {Link} from "react-router-dom";
import {withRouter} from "react-router-dom";
import {
    MDBFormInline,
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBBreadcrumb,
    MDBBreadcrumbItem,
    MDBBox,
    MDBInput,
    MDBCol,
    MDBRow,
    MDBDataTable,
    MDBModalHeader,
    MDBModalBody,
    MDBModal,
    MDBContainer, MDBTableHead, MDBTableBody, MDBTable
} from 'mdbreact';

import Moments from "moment";
import SimpleBackdrop from "../common/overlay";
import UserStore from "../../stores/userStore";
import PharmacyService from "../../services/pharmacyService";
import OrderService from "../../services/orderService";
import {toast} from "react-toastify";
import config from "../../config";
import util from "../../utility/utility";
import orgConfig from "../../orgConfig";


class CreateReturnInward extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            progressStatus:false,
            orderCode:"",
            userData:{},
            orgId:"",
            order:{
                products:[],
                returnedProducts:[],
                customerDetails:{}

            },
            returnedProducts:[],
            totalReturnedAmount:0,
        }
    }

    async componentDidMount ()  {
        let userData=await UserStore.fetchSessionData();
        this.setState({userData: userData})

        if(userData.type === "platformAdmin"){
            this.setState({orgId: userData._id})
        } else if(userData.type === "ORG_ADMIN"){
            this.setState({orgId: userData.orgDetails[0]._id})
        }

        const { id } = this.props.queryString;
        if(id){
            await this.setState({orderCode:id})
            this.fetchOrderByOrderCode();
        }

    }

    convertToReadbleDate = date => {
        return Moments(date).format("MMM D, YYYY");
    }
    handleOrderCode = (val) => {
        this.setState({orderCode: val});
    }

    fetchOrderByOrderCode = async () => {
        if(!this.state.orderCode){
            toast.error("Please enter the order code.")
            return false;
        }
        let data = await OrderService.fetchOrderByOrderCode(this.state);
        if(!data){
            toast.error("Something went wrong while fetching the order.")
        } else {
            this.setState({order:data})

        }

    }

    convertMfgOrExpiry = date =>{
        if(date){
            return Moments(date).format("MMM YYYY");
        } else {
            return "";
        }
    }
    calculateSubTotal = (mrp, quantity, discount, contentQuantity, looseSaleAllowed) => {
        if(!contentQuantity){
            contentQuantity = 1;
        }
        if(mrp > 0){
            if(looseSaleAllowed === "N"){
                contentQuantity = 1 // This could be the minimum sale quantity later
            }
            let subTotal = (mrp/contentQuantity) * quantity;
            if(discount > 0){
                let discountedAmount = subTotal * (discount / 100);
                subTotal = subTotal - discountedAmount
            }
            return subTotal.toFixed("2");
        } else {
            return 0;
        }
    }

    calculateDiscountedAmount = (mrp, quantity, discount, contentQuantity, looseSaleAllowed) => {
        if(!contentQuantity){
            contentQuantity = 1;
        }
        if(mrp > 0){
            if(looseSaleAllowed === "N"){
                contentQuantity = 1 // This could be the minimum sale quantity later
            }
            let subTotal = (mrp/contentQuantity) * quantity;
            if(discount > 0){
                let discountedAmount = subTotal * (discount / 100);
                return discountedAmount.toFixed("2");
            } else {
                return 0
            }
        } else {
            return 0;
        }
    }

    calculateGSTAmountFromMRPAndGSTPercentage = (mrp, gstPercentage, quantity, contentQuantity, looseSaleAllowed) => {
        if(!quantity){
            quantity = 1;
        }
        if(!contentQuantity){
            contentQuantity = 1;
        }
        if(looseSaleAllowed === "N"){
            contentQuantity = 1 // This could be the minimum sale quantity later
        }

        if(mrp > 0){
            if(gstPercentage > 0){
                let basePrice = ((mrp) - ((100+gstPercentage)/100))/contentQuantity

                let gstAmount = basePrice - (basePrice - basePrice*(gstPercentage/100))
                gstAmount = gstAmount * quantity;
                gstAmount =  Math.round(gstAmount * 100) / 100
                return gstAmount.toFixed("2");
            } else {
                return 0;
            }
        } else {
            return 0
        }
    }

    handleQuantity = (index)=>(e)=> {
        if (!util.isInt(e.target.value) && e.target.value !== "") {
            return false;
        }
        let existingData = this.state.returnedProducts;
        // This will prevent from entering quantity bigger than in stock
        if (parseInt(existingData[index].quantity) < parseInt(e.target.value)) {
            return false;
        }
        existingData[index].returnedQuantity = e.target.value;
        this.setState({returnedProduct:existingData})
        this.calculateTotalAmount(existingData)
    }

    selectToReturnItem = (index) => {
        let existingOrder = this.state.order;
        existingOrder.products[index].checked = true
        this.setState({order:existingOrder})

        let returnedProducts = this.state.returnedProducts;
        let item = this.state.order.products[index]
        item.returnedQuantity = item.quantity;
        returnedProducts.push(item)
       // existingOrder.returnedProducts = returnedProducts;
        this.setState({returnedProducts:returnedProducts})
        this.calculateTotalAmount(returnedProducts)

    }

    unselectReturnItem = (index) =>{
        let existingReturnProducts = this.state.returnedProducts;
        let existingOrder = this.state.order;
        for(let i in existingOrder.products){
            if(existingOrder.products[i]._id === existingReturnProducts[index]._id){
                existingOrder.products[i].checked = false;
                this.setState({existingOrder:existingOrder})
            }
        }


        existingReturnProducts.splice(index, 1);
        this.setState({returnedProducts:existingReturnProducts})

        this.calculateTotalAmount(existingReturnProducts)
    }


    calculateTotalAmount = async (products) => {
        // quantity means, quantity of a product user has added

        let totalReturnedAmount = 0;
        for(let item of products ){
            let contentQuantity = item.contentQuantity;
            if(item.looseSaleAllowed === "N"){
                contentQuantity = 1 // This could be the minimum sale quantity later
            }
            let mrp = (item.inventory.mrp / contentQuantity);
            let subTotal = mrp * item.returnedQuantity;
            // if any discount, apply it
            if(item.discount > 0){
                let discountAmount = subTotal * ((item.discount)/100)
                subTotal = subTotal - discountAmount;
            }
            totalReturnedAmount = totalReturnedAmount + subTotal;
        }
        await this.setState({totalReturnedAmount: util.customRound(totalReturnedAmount)})

    }

    initReturnOrder = async () =>{
        let data = await OrderService.returnOfflineOrderInward(this.state);
        if(!data){
            toast.error("Something went wrong while fetching the order.")
        } else {
            this.setState({order:data, orderCode:""})

        }
    }

    orderedAgo = () => {
        if(this.state.order.orderDate){
            let a = Moments();
            let b = Moments(this.state.order.orderDate);
            let days = a.diff(b, 'day');
            if(days === 0){
                return "Today"
            } else if(days > 1){
                return  days + " days ago"
            } else if(days === 1){
                return  days + " day ago"
            }
        }

    }



    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.progressStatus}/>

                <MDBContainer>
                    <MDBCard className={"mt-2"}>
                        <MDBCardHeader>
                            <span className={"float-left"}>Create Return Inward</span>

                        </MDBCardHeader>
                        <MDBCardBody>
                        <MDBRow className={"m-1"}>
                            <MDBCol size={"col-4"}>
                                <MDBInput label="Order Code" value={this.state.orderCode} getValue={this.handleOrderCode}/>

                            </MDBCol>
                            <MDBCol size={"col-2"}>
                                <MDBBtn color="default" size="sm" className={"mt-4"} onClick={this.fetchOrderByOrderCode} >Search</MDBBtn>
                            </MDBCol>
                            <MDBCol>
                                Order Details:
                                {this.state.order.orderDate &&
                                    <div>Order Date: {this.convertToReadbleDate(this.state.order.orderDate)} ({ this.orderedAgo()})</div>
                                }




                                {this.state.order.totalAmount &&
                                <div>Total
                                    Amount: {orgConfig.CURRENCY_SYMBOL + " " + this.state.order.totalAmount}</div>
                                }


                            </MDBCol>
                            <MDBCol>
                                Customer Details:
                                {this.state.order.customerDetails.name &&
                                    <div>Name: {this.state.order.customerDetails.name}</div>
                                }
                                {this.state.order.customerDetails.contact_no &&
                                    <div>Contact: {this.state.order.customerDetails.contact_no}</div>
                                }
                            </MDBCol>
                        </MDBRow>

                        <MDBRow>
                            <MDBCol>
                                <MDBTable small={true}>
                                    <MDBTableHead>
                                        <tr>
                                            <th>Product</th>
                                            <th>Batch No</th>
                                            <th>Type</th>
                                            <th>Expiry Date</th>
                                            <th>MRP</th>
                                            <th>GST (%)</th>
                                            <th>Quantity</th>
                                            <th>Discount (%)</th>
                                            <th>Subtotal</th>
                                            <th>Return</th>

                                        </tr>
                                    </MDBTableHead>
                                    {this.state.order.hasOwnProperty("products") &&
                                    <MDBTableBody>
                                        {this.state.order.products.map((row, index) => (
                                            <tr key={index + "_cart"} className={"pt-1 mt-1"}>
                                                <td>{row.productName}</td>
                                                <td>{row.inventory.batch}</td>
                                                <td>
                                                    {(row.hasOwnProperty("isMedicine") && !row.isMedicine) &&
                                                    <span>General</span>
                                                    }
                                                    {(row.hasOwnProperty("isMedicine") && row.isMedicine) &&
                                                    <span>
                                                    {row.hasOwnProperty("medicineType") &&
                                                    <span
                                                        className={(row.medicineType === "Schedule H" || row.medicineType === "Schedule H1" || row.medicineType === "Schedule X" || row.medicineType === "PRESCRIPTION DRUGS" || row.medicineType === "PRESCRIPTION DRUG") ? "red-text" : "green-text"}>
                                                        {row.medicineType === "PRESCRIPTION DRUGS" &&
                                                        <span>Prescription Drug</span>
                                                        }
                                                        {row.medicineType !== "PRESCRIPTION DRUGS" &&
                                                        <span>{row.medicineType}</span>
                                                        }
                                                    </span>
                                                    }
                                                        {!row.hasOwnProperty("medicineType") &&
                                                        <span>  Not Specified</span>
                                                        }
                                                </span>
                                                    }
                                                    {(!row.hasOwnProperty("isMedicine")) &&
                                                    <span>
                                                    {row.hasOwnProperty("medicineType") &&
                                                    <span
                                                        className={(row.medicineType === "Schedule H" || row.medicineType === "Schedule H1" || row.medicineType === "Schedule X") ? "red-text" : "green-text"}> {row.medicineType}</span>
                                                    }
                                                </span>
                                                    }
                                                </td>
                                                <td>{this.convertMfgOrExpiry(row.inventory.expDate)}</td>
                                                {row.looseSaleAllowed === "Y" &&
                                                <td><span dangerouslySetInnerHTML={{__html: config.CURRENCY_HTML}}></span> {(row.inventory.mrp/row.contentQuantity).toFixed("2")}</td>
                                                }
                                                {row.looseSaleAllowed === "N" &&
                                                <td><span dangerouslySetInnerHTML={{__html: config.CURRENCY_HTML}}></span> {(row.inventory.mrp).toFixed("2")}</td>
                                                }
                                                <td>{row.inventory.gst}</td>
                                                <td>
                                                    {row.quantity}
                                                </td>
                                                <td>
                                                    <div>
                                                        {row.discount}
                                                    </div>
                                                </td>
                                                <td><span dangerouslySetInnerHTML={{__html: config.CURRENCY_HTML}}></span> {this.calculateSubTotal(row.inventory.mrp, row.quantity, row.discount, row.contentQuantity, row.looseSaleAllowed)}</td>


                                                <td className={"ml-5"}>
                                                    {(!row.checked || row.checked === false) &&
                                                    <MDBBtn size={"sm"} style={{"marginTop": "-5px"}}
                                                            onClick={() => this.selectToReturnItem(index)}>Select this
                                                        item to return</MDBBtn>
                                                    }

                                                    {(row.checked || row.checked === true) &&
                                                    <span>Selected this
                                                        item to return</span>
                                                    }
                                                </td>
                                            </tr>
                                        ))}
                                    </MDBTableBody>
                                    }
                                </MDBTable>
                            </MDBCol>
                        </MDBRow>


                        <MDBRow className={"mt-4"}>
                            <MDBCol>Items to be returned:
                                {this.state.returnedProducts.length < 1 && <span> Not yet selected</span>}
                            </MDBCol>
                        </MDBRow>

                        {this.state.returnedProducts.length > 0 &&
                        <MDBRow>
                            <MDBCol>
                                <MDBTable small={true}>
                                    <MDBTableHead>
                                        <tr>
                                            <th>Product</th>
                                            <th>Batch No</th>
                                            <th>Type</th>
                                            <th>Expiry Date</th>
                                            <th>MRP</th>
                                            <th>GST (%)</th>
                                            <th>Quantity</th>
                                            <th>Discount (%)</th>
                                            <th>Subtotal</th>
                                            <th>Return Quantity</th>

                                        </tr>
                                    </MDBTableHead>

                                    <MDBTableBody>
                                        {this.state.returnedProducts.map((row, index) => (
                                            <tr key={index + "_cart"} className={"pt-1 mt-1"}>
                                                <td>{row.productName}</td>
                                                <td>{row.inventory.batch}</td>
                                                <td>
                                                    {(row.hasOwnProperty("isMedicine") && !row.isMedicine) &&
                                                    <span>General</span>
                                                    }
                                                    {(row.hasOwnProperty("isMedicine") && row.isMedicine) &&
                                                    <span>
                                            {row.hasOwnProperty("medicineType") &&
                                            <span
                                                className={(row.medicineType === "Schedule H" || row.medicineType === "Schedule H1" || row.medicineType === "Schedule X" || row.medicineType === "PRESCRIPTION DRUGS" || row.medicineType === "PRESCRIPTION DRUG") ? "red-text" : "green-text"}>
                                                {row.medicineType === "PRESCRIPTION DRUGS" &&
                                                <span>Prescription Drug</span>
                                                }
                                                {row.medicineType !== "PRESCRIPTION DRUGS" &&
                                                <span>{row.medicineType}</span>
                                                }
                                            </span>
                                            }
                                                        {!row.hasOwnProperty("medicineType") &&
                                                        <span>  Not Specified</span>
                                                        }
                                        </span>
                                                    }
                                                    {(!row.hasOwnProperty("isMedicine")) &&
                                                    <span>
                                            {row.hasOwnProperty("medicineType") &&
                                            <span
                                                className={(row.medicineType === "Schedule H" || row.medicineType === "Schedule H1" || row.medicineType === "Schedule X") ? "red-text" : "green-text"}> {row.medicineType}</span>
                                            }
                                        </span>
                                                    }
                                                </td>
                                                <td>{this.convertMfgOrExpiry(row.inventory.expDate)}</td>
                                                {row.looseSaleAllowed === "Y" &&
                                                <td><span
                                                    dangerouslySetInnerHTML={{__html: config.CURRENCY_HTML}}></span> {(row.inventory.mrp / row.contentQuantity).toFixed("2")}
                                                </td>
                                                }
                                                {row.looseSaleAllowed === "N" &&
                                                <td><span
                                                    dangerouslySetInnerHTML={{__html: config.CURRENCY_HTML}}></span> {(row.inventory.mrp).toFixed("2")}
                                                </td>
                                                }
                                                <td>{row.inventory.gst}</td>
                                                <td>
                                                    {row.quantity}
                                                </td>
                                                <td>
                                                    <div>
                                                        {row.discount}
                                                    </div>
                                                </td>
                                                <td><span
                                                    dangerouslySetInnerHTML={{__html: config.CURRENCY_HTML}}></span> {this.calculateSubTotal(row.inventory.mrp, row.returnedQuantity, row.discount, row.contentQuantity, row.looseSaleAllowed)}
                                                </td>



                                                <td>
                                                    <MDBInput style={{"marginTop": "-30px", "width": "60px"}}  onChange={this.handleQuantity(index)} value={this.state.returnedProducts[index].returnedQuantity}
                                                              size={1} outline={true} />
                                                </td>
                                                <td>
                                                    <MDBBtn size={"sm"} style={{"marginTop": "-5px"}}
                                                            onClick={() => this.unselectReturnItem(index)}>Cancel return</MDBBtn>
                                                </td>
                                            </tr>
                                        ))}
                                    </MDBTableBody>

                                </MDBTable>
                            </MDBCol>
                        </MDBRow>
                        }

                            {this.state.returnedProducts.length > 0 &&
                            <MDBRow>
                                <MDBCol size={"8"}>
                                    Total amount To be returned:  <span dangerouslySetInnerHTML={{__html: config.CURRENCY_HTML}}></span> {util.customRound(this.state.totalReturnedAmount)}
                                </MDBCol>
                                <MDBCol>
                                    <MDBBtn className={"float-right"} size={"sm"} onClick={this.initReturnOrder}>Confirm Return</MDBBtn>
                                </MDBCol>
                            </MDBRow>
                            }


                        </MDBCardBody>
                    </MDBCard>

                </MDBContainer>
            </React.Fragment>
        );
    }
}

export default withRouter(CreateReturnInward)
