import React from "react";
import {MDBListGroup, MDBListGroupItem, MDBContainer, MDBBadge, MDBTooltip, MDBBtn, MDBIcon} from "mdbreact";
import UserStore from "../../stores/userStore";
import ReportService from "../../services/reports";
import {Link} from "react-router-dom";

export default class OrderUpdate extends React.Component {
    async componentDidMount() {
        let userData=await UserStore.fetchSessionData();
        let orderData=await ReportService.dailyOrderWidget(userData);
        if(orderData!==false){
            this.setState({data:orderData});
        }
    }
    constructor(props) {
        super(props);
        this.state = {
            data: null
        }
    }
    render() {
        return (
            <React.Fragment>
                <div className={'white rounded-sm p-1 dashboard-widget'}>
                    <h6 className={'d-flex justify-content-between align-items-center pb-0'}><b className={'font-weight-bold'}>Today's Orders</b>
                        <div>
                            <MDBTooltip placement="top">
                                <MDBBtn color="danger" className={'order-tooltip'}></MDBBtn>
                                <div>Cancelled</div>
                            </MDBTooltip>
                            <MDBTooltip placement="top">
                                <MDBBtn color="warning" className={'order-tooltip'}></MDBBtn>
                                <div>Pending</div>
                            </MDBTooltip>
                            <MDBTooltip placement="top">
                                <MDBBtn color="success" className={'order-tooltip'}></MDBBtn>
                                <div>Delivered</div>
                            </MDBTooltip>
                        </div>
                    </h6>
                    <ul className="list-group list-group-flush p-0 border-top">
                        <li className="list-group-item d-flex justify-content-between align-items-center p-1">
                            {/*<Link to={"/view-online-orders"}><span className={'grey-text'}>Online</span></Link>*/}
                            <span className={'grey-text'}>Online</span>
                            <div>
                                <MDBBadge color="danger-color" pill>{this.state.data!==null?this.state.data.online.cancelled:""}</MDBBadge>
                                <MDBBadge className={'ml-1'} color="warning-color" pill>{this.state.data!==null?this.state.data.online.pending:""}</MDBBadge>
                                <MDBBadge className={'ml-2'} color="success-color" pill>{this.state.data!==null?this.state.data.online.delivered:""}</MDBBadge>
                                {/*<Link to={"/view-online-orders"}>*/}
                                {/*    <a className={'white p-2 blue-text z-depth-0'}><MDBIcon className={'blue-text fa-lg'} icon="chevron-right" /></a>*/}
                                {/*</Link>*/}
                            </div>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center p-1">
                            {/*<Link to={"/view-prescription-orders"}><span className={'grey-text'}>Prescription</span></Link>*/}
                            <span className={'grey-text'}>Prescription</span>
                            <div>
                                <MDBBadge color="danger-color" pill>{this.state.data!==null?this.state.data.prescription.cancelled:""}</MDBBadge>
                                <MDBBadge className={'ml-1'} color="warning-color" pill>{this.state.data!==null?this.state.data.prescription.pending:""}</MDBBadge>
                                <MDBBadge className={'ml-2'} color="success-color" pill>{this.state.data!==null?this.state.data.prescription.delivered:""}</MDBBadge>
                                {/*<Link to={"/view-prescription-orders"}>*/}
                                {/*    <a className={'white p-2 blue-text z-depth-0'}><MDBIcon className={'blue-text fa-lg'} icon="chevron-right" /></a>*/}
                                {/*</Link>*/}

                            </div>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center p-1">
                            {/*<Link to={"/view-in-person-and-phone-orders"}><span className={'grey-text'}>In-person</span></Link>*/}
                            <span className={'grey-text'}>In-person</span>
                            <div>
                                <MDBBadge color="success-color" pill>{this.state.data!==null?this.state.data.inPerson:""}</MDBBadge>
                                {/*<Link to={"/view-in-person-and-phone-orders"}>*/}
                                {/*    <a className={'white p-2 blue-text z-depth-0'}><MDBIcon className={'blue-text fa-lg'} icon="chevron-right" /></a>*/}
                                {/*</Link>*/}
                            </div>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center p-1">
                            {/*<Link to={"/view-in-person-and-phone-orders"}><span className={'grey-text'}>Telephonic</span></Link>*/}
                            <span className={'grey-text'}>Telephonic</span>
                            <div>
                                <MDBBadge color="success-color" pill>{this.state.data!==null?this.state.data.telephonic:""}</MDBBadge>
                                {/*<Link to={"/view-in-person-and-phone-orders"}>*/}
                                {/*    <a className={'white p-2 blue-text z-depth-0'}><MDBIcon className={'blue-text fa-lg'} icon="chevron-right" /></a>*/}
                                {/*</Link>*/}
                            </div>
                        </li>
                    </ul>
                </div>
            </React.Fragment>
        );
    }
}


