import React from "react";
import {
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBModalBody,
  MDBRow,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdbreact";
import config from "../../config";
import utility from "../../utility/utility";
import {
  Document,
  Page,
  PDFViewer,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    margin: 10,
  },
  tableRow: { margin: "auto", flexDirection: "row" },
  tableCol1: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol2: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol4: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol: {
    width: "8%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColP3: {
    width: "3%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColP4: {
    width: "4%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColP5: {
    width: "5%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColP6: {
    width: "6%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColP7: {
    width: "7%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColP8: {
    width: "8%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColP9: {
    width: "9%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColP10: {
    width: "10%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColProduct: {
    textAlign: "left",
    width: "18%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColP90: {
    width: "90%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: { margin: "auto", marginTop: 5, marginBottom: 5, fontSize: 9 },
  tableCellRight: {
    textAlign: "right",
    marginTop: 5,
    marginBottom: 5,
    fontSize: 9,
    paddingRight: 1,
  },
  tableCellProduct: {
    marginLeft: 1,
    marginTop: 5,
    marginBottom: 5,
    fontSize: 9,
  },
  tableCellTotal: {
    margin: "auto",
    marginTop: 5,
    marginBottom: 5,
    fontSize: 9,
    position: "absolute",
    right: 20,
  },

  tableCellH1: {
    margin: "auto",
    marginTop: 5,
    marginBottom: 5,
    fontSize: 18,
    fontWeight: "bold",
  },
  tableCellH2: { margin: "auto", marginTop: 5, marginBottom: 5, fontSize: 14 },
  tableCellH3: { margin: "auto", marginTop: 5, marginBottom: 5, fontSize: 12 },
  tableCellH4: { margin: "auto", marginTop: 5, marginBottom: 5, fontSize: 10 },
  tableCellH5: { margin: "auto", marginTop: 5, fontSize: 9 },
  tableCellH6: { margin: "auto", marginTop: 5, fontSize: 8 },
  thankYou: {
    position: "absolute",
    fontSize: 14,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
  },

  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 5,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});
class OrderInvoice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeOrder: props.activeOrder,
      isActiveOrderAPrescriptionDrug: props.isActiveOrderAPrescriptionDrug,
    };
  }
  groupProducts = () => {
    const groupedProducts = this.state.activeOrder.products.reduce(
      (acc, item) => {
        const key = `${item.productName}-${item.inventory.batch}-${item.inventory.pricePerUnit}`;

        if (!acc[key]) {
          acc[key] = {
            productName: item.productName,
            batch: item.inventory.batch,
            expDate: item.inventory.expDate,
            pricePerUnit: item.inventory.pricePerUnit,
            quantity: item.quantity,
            discount: item.discount,
            discountAmount: Number(
              utility.calculateDiscountAmount(
                item.inventory.mrp,
                item.quantity,
                item.discount,
                item.calculatedContentQuantity
              )
            ),
            gst: item.inventory.gst,
            hsn: item.inventory.hsn,
            mrp: item.inventory.mrp,
            gstAmount: Number(
              utility.calculateGSTAmount(
                item.inventory.subtotal,
                item.inventory.gst,
                item.quantity,
                item.calculatedContentQuantity,
                item.discount
              )
            ),
            calculatedContentQuantity: item.calculatedContentQuantity,
            contentQuantity: item.contentQuantity,
            subtotal: item.inventory.subtotal,
            looseSaleAllowed: item.looseSaleAllowed,
            medicineType: item.medicineType,
            doctorDetails: item.doctorDetails,
            patientDetails: item.patientDetails,
          };
        } else {
          acc[key].quantity += item.quantity;
          acc[key].subtotal += item.inventory.subtotal;
          acc[key].gstAmount += Number(
            utility.calculateGSTAmount(
              item.inventory.subtotal,
              item.inventory.gst,
              item.quantity,
              item.calculatedContentQuantity,
              item.discount
            )
          );
          acc[key].discountAmount += Number(
            utility.calculateDiscountAmount(
              item.inventory.mrp,
              item.quantity,
              item.discount,
              item.calculatedContentQuantity
            )
          );
        }

        return acc;
      },
      {}
    );

    const groupedArray = Object.values(groupedProducts);

    return groupedArray;
  };

  render() {
    const numPages = null;
    const setNumPages = null;
    console.log(this.state.activeOrder)
    return (
      <MDBContainer>
        <MDBTable small={true}>
          <MDBTableHead>
            <tr>
              <th>Product</th>
              <th>Batch No</th>
              <th>Type</th>
              <th>Expiry Date</th>
              <th>MRP</th>
              <th>GST (%)</th>
              <th>Quantity</th>
              <th>Discount (%)</th>
              <th>Subtotal</th>
              <th>Patient</th>
              <th>Doctor</th>
            </tr>
          </MDBTableHead>
          {this.state.activeOrder.hasOwnProperty("products") && (
            <MDBTableBody>
              {this.state.activeOrder.products.map((row, index) => (
                <tr key={index + "_cart"} className={"pt-1 mt-1"}>
                  <td>{row.productName}</td>
                  <td>{row.inventory.batch}</td>
                  <td>
                    {row.hasOwnProperty("isMedicine") && !row.isMedicine && (
                      <span>General</span>
                    )}
                    {row.hasOwnProperty("isMedicine") && row.isMedicine && (
                      <span>
                        {row.hasOwnProperty("medicineType") && (
                          <span
                            className={
                              row.medicineType === "Schedule H" ||
                              row.medicineType === "Schedule H1" ||
                              row.medicineType === "Schedule X" ||
                              row.medicineType === "PRESCRIPTION DRUGS" ||
                              row.medicineType === "PRESCRIPTION DRUG"
                                ? "red-text"
                                : "green-text"
                            }
                          >
                            {row.medicineType === "PRESCRIPTION DRUGS" && (
                              <span>Prescription Drug</span>
                            )}
                            {row.medicineType !== "PRESCRIPTION DRUGS" && (
                              <span>{row.medicineType}</span>
                            )}
                          </span>
                        )}
                        {!row.hasOwnProperty("medicineType") && (
                          <span> Not Specified</span>
                        )}
                      </span>
                    )}
                    {!row.hasOwnProperty("isMedicine") && (
                      <span>
                        {row.hasOwnProperty("medicineType") && (
                          <span
                            className={
                              row.medicineType === "Schedule H" ||
                              row.medicineType === "Schedule H1" ||
                              row.medicineType === "Schedule X"
                                ? "red-text"
                                : "green-text"
                            }
                          >
                            {" "}
                            {row.medicineType}
                          </span>
                        )}
                      </span>
                    )}
                  </td>
                  <td>{utility.convertMfgOrExpiry(row.inventory.expDate)}</td>
                  {row.looseSaleAllowed === "Y" && (
                    <td>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: config.CURRENCY_HTML,
                        }}
                      ></span>{" "}
                      {(row.inventory.mrp / row.contentQuantity).toFixed(2)}
                    </td>
                  )}
                  {row.looseSaleAllowed === "N" && (
                    <td>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: config.CURRENCY_HTML,
                        }}
                      ></span>{" "}
                      {row.inventory.mrp.toFixed(2)}
                    </td>
                  )}
                  <td>{row.inventory.gst}</td>
                  <td>{row.quantity}</td>
                  <td>
                    <div>{row.discount}</div>
                  </td>
                  <td>
                    <span
                      dangerouslySetInnerHTML={{ __html: config.CURRENCY_HTML }}
                    ></span>{" "}
                    {utility.calculateSellingPrice(
                      row.inventory.mrp,
                      row.quantity,
                      row.discount,
                      row.calculatedContentQuantity
                    )}
                  </td>
                  <td size={"2"}>
                    {(row.medicineType.trim() === "Schedule X" ||
                      row.medicineType.trim() === "Schedule H" ||
                      row.medicineType.trim() === "Schedule H1" ||
                      row.medicineType.trim() === "PRESCRIPTION DRUG" ||
                      row.medicineType.trim() === "PRESCRIPTION DRUGS") && (
                      <>
                        {row.hasOwnProperty("patientDetails") &&
                          row.patientDetails.hasOwnProperty("name") && (
                            <span>
                              {row.patientDetails.name && (
                                <span>{row.patientDetails.name}</span>
                              )}
                              {!row.patientDetails.name &&
                                row.patientDetails.first_name && (
                                  <span>
                                    {row.patientDetails.first_name +
                                      " " +
                                      row.patientDetails.last_name}{" "}
                                  </span>
                                )}
                            </span>
                          )}
                      </>
                    )}
                  </td>
                  <td size={"2"}>
                    {(row.medicineType.trim() === "Schedule X" ||
                      row.medicineType.trim() === "Schedule H" ||
                      row.medicineType.trim() === "Schedule H1" ||
                      row.medicineType.trim() === "PRESCRIPTION DRUG" ||
                      row.medicineType.trim() === "PRESCRIPTION DRUGS") && (
                      <>
                        {row.hasOwnProperty("doctorDetails") &&
                          row.doctorDetails.hasOwnProperty("name") && (
                            <span>{row.doctorDetails.name}</span>
                          )}
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </MDBTableBody>
          )}
        </MDBTable>
        <MDBRow>
          {this.state.activeOrder.totalAmount && (
            <MDBCol>
              <b>
                Total Amount:{" "}
                <span
                  dangerouslySetInnerHTML={{ __html: config.CURRENCY_HTML }}
                ></span>{" "}
                {this.state.activeOrder.totalAmount.toFixed(2)}
              </b>
            </MDBCol>
          )}
        </MDBRow>

        {this.state.activeOrder.hasOwnProperty("paymentLog") && (
          <MDBRow className={"m-1 mt-3"}>
            Payment History:{" "}
            {this.state.activeOrder.paymentLog.length === 0 && (
              <div className={"no-data-container"}>No payment log found.</div>
            )}
          </MDBRow>
        )}
        {this.state.activeOrder.hasOwnProperty("paymentLog") &&
          this.state.activeOrder.paymentLog.length > 0 && (
            <MDBTable className={"col-6"}>
              <MDBTableHead>
                <tr>
                  <th>#</th>
                  <th>Amount</th>
                  <th>Paid On</th>
                  <th>Payment Mode</th>
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                {this.state.activeOrder.paymentLog.map((row, index) => (
                  <tr key={"paymentlog_" + index}>
                    <td>{index + 1}</td>
                    <td>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: config.CURRENCY_HTML,
                        }}
                      ></span>{" "}
                      {row.amount.toFixed(2)}
                    </td>
                    <td>{utility.convertToReadbleDateTimeUtc(row.paidAt)}</td>
                    <td>{row.paymentMode}</td>
                  </tr>
                ))}
              </MDBTableBody>
            </MDBTable>
          )}

        <MDBRow>
          <MDBCol>
            {this.state.activeOrder.hasOwnProperty("docs") && (
              <ul className="list-group list-group-horizontal">
                {this.state.activeOrder.docs.map((row, index) => (
                  <li key={index} className="list-group-item">
                    {utility.ifFileIsDocument(row) && (
                      <a
                        className={"file-size float-left"}
                        href={utility.secureFilePath(
                          this.state.userData.sessionToken,
                          this.state.userData._id,
                          row
                        )}
                      >
                        <MDBIcon style={{ fontSize: "3rem" }} icon="file" />
                      </a>
                    )}
                    {utility.ifFileIsImage(row) && (
                      <img
                        className={"file-size float-left"}
                        style={{
                          cursor: "pointer",
                          maxWidth: "100px",
                          maxHeight: "100px",
                        }}
                        onClick={() => this.onDownloadImage(row)}
                        src={utility.secureFilePath(
                          this.state.userData.sessionToken,
                          this.state.userData._id,
                          row
                        )}
                      />
                    )}
                    <div style={{ clear: "both" }}></div>
                    <div className={"mb-1"}>Prescription_{index + 1}</div>
                  </li>
                ))}
              </ul>
            )}
          </MDBCol>
        </MDBRow>
        {this.state.activeOrder.hasOwnProperty("docs") && (
          <MDBRow className={"mt-5"}>
            {this.state.activeOrder.docs.map((row, index) => (
              <div style={{ textAlign: "center" }} key={index}>
                {row.hasOwnProperty("filePathOriginal") && (
                  <img
                    alt={""}
                    src={config.IMAGE_HOST + row.filePathOriginal}
                    className={"float-center"}
                    style={{ maxWidth: "240px", maxHeight: "500px" }}
                  />
                )}
              </div>
            ))}
          </MDBRow>
        )}
        <div>
          <PDFViewer width={"100%"} height={"1000"}>
            <Document onLoadSuccess={({ numPages }) => setNumPages(numPages)}>
              <Page
                wrap
                size="A4"
                style={{
                  border: "1 solid #000",
                  backgroundColor: "white",
                  paddingBottom: 25,
                  paddingTop: 15,
                  paddingHorizontal: 2,
                }}
              >
                <View style={styles.table}>
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol1}>
                      <Text style={styles.tableCellH1}>TAX INVOICE</Text>
                    </View>
                  </View>
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol2}>
                      <Text style={styles.tableCellH2}>Positra Pharmacy</Text>
                      <Text style={styles.tableCell}>
                        Phone:  +91-690-070-2871
                      </Text>
                    </View>
                    <View style={styles.tableCol2}>
                      <Text style={styles.tableCell}>
                        Drug licence number: D/OL/KMP/18760 & D/OL/KMP/18761
                      </Text>
                      <Text style={styles.tableCell}>
                        Pharmacist: Sahidul Alam(15080)
                      </Text>
                    </View>
                  </View>
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol4}>
                      <Text style={styles.tableCell}>GSTIN</Text>
                      <Text style={styles.tableCell}>18AAICP6875B1Z5</Text>
                    </View>
                    <View style={styles.tableCol4}>
                      <Text style={styles.tableCell}>Invoice #</Text>
                      <Text style={styles.tableCell}>
                        {this.state.activeOrder.orderCode}
                      </Text>
                    </View>
                    <View style={styles.tableCol4}>
                      <Text style={styles.tableCell}>Invoice Date</Text>
                      <Text style={styles.tableCell}>
                        {utility.convertToReadbleDateTimeUtc(
                          this.state.activeOrder.orderDate
                        )}
                      </Text>
                    </View>
                    <View style={styles.tableCol4}>
                      <Text style={styles.tableCell}>Transaction Mode</Text>
                      <Text style={styles.tableCell}>
                        {this.state.activeOrder.paymentMode}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol1}>
                      <Text style={styles.tableCellH3}>
                        {`Details of Customer ${this.state.activeOrder.products[0]?.doctorDetails?.name ? "& Doctor":""}`}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol1}>
                      {this.state.activeOrder.hasOwnProperty(
                        "customerDetails"
                      ) && (
                        <>
                          <Text
                            style={{
                              fontSize: "12",
                              paddingLeft: 5,
                              marginTop: 5,
                              marginBottom: 5,
                            }}
                          >
                            Patient Name:{" "}
                            {this.state.activeOrder.customerDetails.name}{" "}
                            {this.state.isActiveOrderAPrescriptionDrug}
                          </Text>
                          {this.state.activeOrder.products[0]?.doctorDetails?.name && 
                            <Text
                            style={{
                              fontSize: "12",
                              paddingLeft: 5,
                              marginTop: 5,
                              marginBottom: 5,
                            }}
                          >
                            Doctor Name:{" "}
                            {this.state.activeOrder.products[0]?.doctorDetails?.name}{" "}
                            {this.state.isActiveOrderAPrescriptionDrug}
                          </Text>
                           }
                          
                        </>
                      )}
                    </View>
                  </View>
                  {/* {this.state.isActiveOrderAPrescriptionDrug === true &&
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableCol1}>
                                            <Text style={styles.tableCellH3}>Patient and Doctor Details</Text>
                                        </View>
                                    </View>
                                    }
                                    {this.state.isActiveOrderAPrescriptionDrug === true &&
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableCol4}>
                                            <Text style={styles.tableCellProduct}>Description of Goods</Text>
                                        </View>
                                        <View style={styles.tableCol4}>
                                            <Text style={styles.tableCell}>Type</Text>
                                        </View>
                                        <View style={styles.tableCol4}>
                                            <Text style={styles.tableCell}>Patient Name</Text>
                                        </View>
                                        <View style={styles.tableCol4}>
                                            <Text style={styles.tableCell}>Prescribed By</Text>
                                        </View>
                                    </View>
                                    }
                                    {this.state.isActiveOrderAPrescriptionDrug === true &&
                                    <View>
                                        {this.groupProducts().map((row, index) => (
                                            <>
                                                {((row.hasOwnProperty("doctorDetails")) || row.medicineType.trim() === "Schedule H" || row.medicineType.trim() === "Schedule H1" || row.medicineType.trim() === "Schedule X" || row.medicineType.trim() === "PRESCRIPTION DRUGS" ||  row.medicineType.trim() === "PRESCRIPTION DRUG") &&
                                                <View wrap={false} style={styles.tableRow}>
                                                    <View style={styles.tableCol4}>
                                                        <Text
                                                            style={styles.tableCellProduct}>{row.productName}</Text>
                                                    </View>
                                                    <View style={styles.tableCol4}>
                                                        <Text style={styles.tableCell}>
                                                            {row.medicineType === "PRESCRIPTION DRUGS" &&
                                                            <Text>Prescription Drug</Text>
                                                            }
                                                            {row.medicineType !== "PRESCRIPTION DRUGS" &&
                                                            <Text>{row.medicineType}</Text>
                                                            }
                                                        </Text>
                                                    </View>
                                                    <View style={styles.tableCol4}>
                                                        <Text style={styles.tableCell}>
                                                            {row.patientDetails.name &&
                                                            <Text>{row.patientDetails.name}</Text>
                                                            }
                                                            {(!row.patientDetails.name && row.patientDetails.first_name) &&
                                                            <Text>{row.patientDetails.first_name + " " + row.patientDetails.last_name} </Text>
                                                            }
                                                        </Text>
                                                    </View>
                                                    <View style={styles.tableCol4}>
                                                        <Text style={styles.tableCell}>
                                                            {(row.hasOwnProperty("doctorDetails") && row.doctorDetails.hasOwnProperty("name")) &&
                                                            <Text>{row.doctorDetails.name}</Text>
                                                            }
                                                        </Text>
                                                    </View>
                                                </View>
                                                }
                                            </>
                                        ))}
                                    </View>
                                    } */}
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol1}>
                      <Text style={styles.tableCellH3}>Product Details</Text>
                    </View>
                  </View>

                  <View style={styles.tableRow}>
                    <View style={styles.tableColP4}>
                      <Text style={styles.tableCell}>No.</Text>
                    </View>
                    <View style={styles.tableColProduct}>
                      <Text style={styles.tableCell}>Description of Goods</Text>
                    </View>
                    <View style={styles.tableColP6}>
                      <Text style={styles.tableCell}>HSN</Text>
                    </View>
                    <View style={styles.tableColP7}>
                      <Text style={styles.tableCell}>Expires</Text>
                    </View>
                    <View style={styles.tableColP4}>
                      <Text style={styles.tableCell}>Qty</Text>
                    </View>
                    <View style={styles.tableColP10}>
                      <Text style={styles.tableCell}>Batch</Text>
                    </View>
                    <View style={styles.tableColP10}>
                      <Text style={styles.tableCell}>MRP</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>Discount Rate</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>Discount Amount</Text>
                    </View>
                    <View style={styles.tableColP6}>
                      <Text style={styles.tableCell}>GST %</Text>
                    </View>
                    <View style={styles.tableColP9}>
                      <Text style={styles.tableCell}>GST Amount</Text>
                    </View>
                    <View style={styles.tableColP10}>
                      <Text style={styles.tableCell}>Amount</Text>
                    </View>
                  </View>
                  {this.groupProducts().map((row, index) => (
                    <View wrap={false} style={styles.tableRow} key={index}>
                      <View style={styles.tableColP4}>
                        <Text style={styles.tableCell}>{index + 1}</Text>
                      </View>
                      <View style={styles.tableColProduct}>
                        <Text style={styles.tableCellProduct}>
                          {row.productName}
                        </Text>
                      </View>
                      <View style={styles.tableColP6}>
                        <Text style={styles.tableCell}>{row.hsn}</Text>
                      </View>
                      <View style={styles.tableColP7}>
                        <Text style={styles.tableCell}>
                          {utility.convertMfgOrExpiry(row.expDate)}
                        </Text>
                      </View>

                      <View style={styles.tableColP4}>
                        <Text style={styles.tableCell}>{row.quantity}</Text>
                      </View>
                      <View style={styles.tableColP10}>
                        <Text style={styles.tableCell}>{row.batch}</Text>
                      </View>
                      <View style={styles.tableColP10}>
                        {row.looseSaleAllowed === "Y" && (
                          <Text style={styles.tableCell}>
                            {config.CURRENCY_TEXT}{" "}
                            {(row.mrp / row.contentQuantity).toFixed(2)}
                          </Text>
                        )}
                        {row.looseSaleAllowed === "N" && (
                          <Text style={styles.tableCell}>
                            {config.CURRENCY_TEXT} {row.mrp.toFixed(2)}
                          </Text>
                        )}
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{row.discount}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCellRight}>
                          {config.CURRENCY_TEXT} {row.discountAmount}
                        </Text>
                      </View>
                      <View style={styles.tableColP6}>
                        <Text style={styles.tableCell}>{row.gst}</Text>
                      </View>
                      <View style={styles.tableColP9}>
                        <Text style={styles.tableCellRight}>
                          {config.CURRENCY_TEXT} {row.gstAmount}
                        </Text>
                      </View>
                      <View style={styles.tableColP10}>
                        <Text style={styles.tableCellRight}>
                          {config.CURRENCY_TEXT}{" "}
                          {utility.calculateSellingPrice(
                            row.mrp,
                            row.quantity,
                            row.discount,
                            row.calculatedContentQuantity
                          )}
                        </Text>
                      </View>
                    </View>
                  ))}
                  <View style={styles.tableRow}>
                    <View style={styles.tableColP90}>
                      <Text style={styles.tableCellTotal}>Total</Text>
                    </View>
                    <View style={styles.tableColP10}>
                      {this.state.activeOrder.totalAmount && (
                        <Text style={styles.tableCellRight}>
                          {config.CURRENCY_TEXT}{" "}
                          {this.state.activeOrder.totalAmount.toFixed(2)}
                        </Text>
                      )}
                    </View>
                  </View>
                  <View style={styles.tableRow}>
                    <View style={styles.tableColP90}>
                      <Text style={styles.tableCellTotal}>Flat Discount</Text>
                    </View>
                    <View style={styles.tableColP10}>
                      {this.state.activeOrder.totalAmount && (
                        <Text style={styles.tableCellRight}>
                          {config.CURRENCY_TEXT}{" "}
                          {this.state.activeOrder?.flatDiscount?.toFixed(2) || 0}
                        </Text>
                      )}
                    </View>
                  </View>
                  <View style={styles.tableRow}>
                    <View style={styles.tableColP90}>
                      <Text style={styles.tableCellTotal}>Final Amount</Text>
                    </View>
                    <View style={styles.tableColP10}>
                      {this.state.activeOrder.totalAmount && (
                        <Text style={styles.tableCellRight}>
                          {config.CURRENCY_TEXT}{" "}
                          {(this.state.activeOrder?.totalAmount - (this.state.activeOrder?.flatDiscount ? Number(this.state.activeOrder?.flatDiscount) : 0))?.toFixed(2)}
                        </Text>
                      )}
                    </View>
                  </View>
                </View>
                <View style={{ marginLeft: 10 }}>
                  <Text style={{ fontSize: "9" }}>Notes:</Text>
                  <Text style={{ fontSize: "9" }}>
                    * MRP is inclusive of GST.
                  </Text>
                  <Text style={{ fontSize: "9" }}>
                    * Goods once sold will not be taken back or exchanged.
                  </Text>
                </View>

                <Text style={styles.thankYou}>
                  Thank you for your business.
                </Text>
                <Text
                  style={styles.pageNumber}
                  render={({ pageNumber, totalPages }) =>
                    `${pageNumber} / ${totalPages}`
                  }
                  fixed
                />
              </Page>
            </Document>
          </PDFViewer>
        </div>
      </MDBContainer>
    );
  }
}

export default OrderInvoice;
