import React from "react";
import {withRouter} from "react-router-dom";
import {
    MDBBtn, MDBModalHeader, MDBModalBody, MDBModal
} from 'mdbreact';
import UserStore from "../../stores/userStore";
import {toast} from "react-toastify";
import OrderService from "../../services/orderService";
import SimpleBackdrop from "../common/overlay";
import EditPurchaseNote from "./editPurchaseNote";
import utility from "../../utility/utility";
import Moments from "moment";
import {CSVLink} from "react-csv";
import MaterialTable from "material-table";

const headers = [
    {label: "Created At", key: "createdAt"},
    {label: "Request Id", key: "_id"},
    {label: "Customer Name", key: "customerDetails.name"},
    {label: "Customer Phone", key: "customerPhone"},
    {label: "Purchase Note", key: "purchaseNote"},
    {label: "Expected Quantity", key: "quantity"},
    {label: "Priority", key: "priority"},
    {label: "Request Status", key: "status"}
];

class ListPurchaseNote extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dt: Moments().format("YYYY-MM-DD"),
            orgId: 0,
            userData: {},
            progressStatus: false,
            activePurchaseNote: {},
            modalEdit: false,
            purchaseNotes: []
        }
    }

    async componentDidMount() {
        let userData = await UserStore.fetchSessionData(), orgId = "";
        if (userData.type === "platformAdmin") {
            orgId = userData._id
        } else if (userData.type === "ORG_ADMIN") {
            orgId = userData.orgDetails[0]._id
        }
        this.setState({userData, orgId})
        await this.fetchData(this.state.dt);
    }

    fetchData = async (date) => {
        window.scrollTo(0, 0);
        this.setState({progressStatus: true});
        let data = await OrderService.fetchPurchaseNote(this.state.userData.sessionToken, this.state.orgId, date);
        this.setState({progressStatus: false, dt: date});

        if (data === false) {
            toast.error("Something went wrong while fetching request notes.");
        } else {
            this.setState({purchaseNotes: data})
        }
    }

    toggleEdit = () => {
        this.setState({
            modalEdit: !this.state.modalEdit
        });
    }
    onEditComplete = async () => {
        this.toggleEdit()
        await this.fetchData(this.state.dt);
    }
    onEdit = (row) => {
        this.setState({activePurchaseNote: row})
        this.toggleEdit()
    }
    goToNew = () => {
        this.props.history.push('/add-purchase-note');
    }
    prev = async () => {
        let date = Moments(this.state.dt)
        await this.fetchData(date.subtract(1, "month").format("YYYY-MM-DD"));
    }
    next = async () => {
        if (Moments(this.state.dt).format("DD-MM-YYYY") < Moments().format("DD-MM-YYYY")) {
            await this.fetchData(Moments(this.state.dt).add(1, "month").format("YYYY-MM-DD"));
        }
    }

    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.progressStatus}/>
                <div className={"col-12 row mx-0 p-2"}>
                    <div className={"col-md-6 mx-0 p-0"}>
                        <MDBBtn size={'md'} disabled={this.state.progressStatus} onClick={this.prev}>Prev</MDBBtn>
                        {this.state.dt !== null &&
                        <b>
                            {Moments(this.state.dt).format("MMM, YYYY")}
                        </b>
                        }
                        <MDBBtn size={'md'} disabled={this.state.progressStatus} onClick={this.next}>Next</MDBBtn>
                    </div>
                    <div className={"col-md-6 mx-0 text-right"}>
                        <CSVLink data={this.state.purchaseNotes} headers={headers}
                                 filename={"Purchase_Note_List_" + Moments(this.state.dt).format("DD-MMM-YYYY") + ".csv"}>
                            <MDBBtn size={'md'} disabled={this.state.isLoading}>Download CSV</MDBBtn>
                        </CSVLink>
                        <MDBBtn color="default" size="md" onClick={this.goToNew} className="mr-auto">
                            Add Purchase Note
                        </MDBBtn>
                    </div>
                </div>
                <div className={"col-12 row mx-0 p-0"}>
                    <div className={"col-md-12"}>
                        <MaterialTable title=""
                                       columns={[
                                           {
                                               title: 'Requested On',
                                               field: 'createdAt',
                                               render: rowData => utility.convertToReadbleDate(rowData.createdAt)
                                           },
                                           {
                                               title: 'Customer Name',
                                               field: 'handleName',
                                               render: rowData => <span
                                                   className={"text-capitalize"}>{rowData.customerDetails.name}</span>
                                           },
                                           {title: 'Customer Phone', field: 'customerPhone'},
                                           {title: 'Purchase Note', field: 'purchaseNote'},
                                           {title: 'Quantity', field: 'quantity'},
                                           {title: 'Priority', field: 'priority'},
                                           {title: 'Status', field: 'status'},
                                           {
                                               title: 'Action', field: '', render: rowData => <>
                                                   {rowData.status !== "FULFILLED" &&
                                                   <div color="primary" className={"blue-text btn-link cursor-pointer"}
                                                        size="sm" onClick={() => {
                                                       this.onEdit(rowData)
                                                   }}>Edit</div>}
                                               </>
                                           },
                                           {
                                               title: 'SMS Sent',
                                               field: '',
                                               render: rowData => (rowData.hasOwnProperty("isSMSSent") && rowData.isSMSSent === 'Y') ? "Yes" : "No"
                                           }
                                       ]}
                                       data={this.state.purchaseNotes}
                                       options={{
                                           showTitle: false,
                                           pageSize: 10,       // make initial page size
                                           pageSizeOptions: [10, 50, 100],    // rows selection options
                                           toolbarButtonAlignment: "left",
                                           padding: "dense",
                                       }}
                        />
                    </div>
                </div>
                <MDBModal isOpen={this.state.modalEdit} toggle={this.toggleEdit} size={"lg"}>
                    <MDBModalHeader toggle={this.toggleEdit}></MDBModalHeader>
                    <MDBModalBody className={"float-center"}>
                        <EditPurchaseNote onEditComplete={this.onEditComplete}
                                          purchageNote={this.state.activePurchaseNote}
                                          toggleEdit={this.toggleEdit}/>

                    </MDBModalBody>
                </MDBModal>
            </React.Fragment>
        );
    }
}

export default withRouter(ListPurchaseNote)


