import React from "react";
import {withRouter} from "react-router-dom";
import SimpleBackdrop from "../common/overlay";
import {toast} from "react-toastify";
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBContainer,
    MDBDataTable,
    MDBFormInline, MDBModal,
    MDBModalBody, MDBModalFooter,
    MDBModalHeader,
} from "mdbreact";
import dataTableFloors from "./dataTableFloors";
import OrderService from "../../services/orderService";
import UserStore from "../../stores/userStore";
import AddStorageLocation from "../storageLocation/addStorageLocation";
import EditFloor from "./editFloor";

class ListFloors extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            locId: "",
            userData: {},
            storageLocDetails:[],
            floors:{columns:[], rows:[]},
            progressStatus: false,
            activeItem: null,
            modalEdit: false,
            modalDelete: false,
        }
    }

    async componentDidMount() {
        const {locId} = this.props.match.params;
        this.setState({progressStatus: true})
        let userData = await UserStore.fetchSessionData()
        let storageLocDetails = await OrderService.fetchStorageLocationById(userData.sessionToken, locId)
        if (storageLocDetails) {
            this.setState({storageLocDetails})
        } else {
            toast.error("Something went wrong while getting the storage location details.")
        }
        await this.setState({locId, userData})
        await this.fetchFloors()
        this.setState({progressStatus: false})
    }

    onListRacks = (item) => {
        this.props.history.push('/view-racks/'+this.state.storageLocDetails[0]._id+"/"+item._id);
    }
    onEdit = (item) => {
        this.setState({activeItem: item})
        this.toggle("Edit")
    }
    onDelete = (item) => {
        this.setState({activeItem: item})
        this.toggle("Delete")
    }
    deleteFloor = async () => {
        this.setState({progressStatus: true})
        let res = await OrderService.deleteFloor(this.state.userData.sessionToken, this.state.activeItem._id)
        if (res) {
            toast.success("The floor deleted successfully.")
            await this.refreshPageOnUpdate("Delete")
        } else {
            toast.error("Something went wrong while deleting the floor.")
        }
        this.setState({progressStatus: false})
    }
    fetchFloors = async () => {
        let data = await OrderService.fetchFloors(this.state.userData.sessionToken, this.state.locId)
        if (data) {
            let floors = {
                columns: dataTableFloors.floors,
                rows: data.map((item, index) => {
                    item["listRacks"] =
                        <div color="primary" className={"green-text btn-link cursor-pointer"} size="sm" onClick={() => {
                            this.onListRacks(item)
                        }}>List Racks</div>;
                    item["edit"] =
                        <div color="primary" className={"blue-text btn-link cursor-pointer"} size="sm" onClick={() => {
                            this.onEdit(item)
                        }}>Edit</div>;
                    item["delete"] =
                        <div color="primary" className={"blue-text btn-link cursor-pointer"} size="sm" onClick={() => {
                            this.onDelete(item)
                        }}>Delete</div>;
                    ;
                    return item;
                })
            }
            this.setState({floors})
        }
    }
    toggle = (item) => {
        this.setState({
            ["modal" + item]: !this.state["modal" + item]
        });
    }
    refreshPageOnUpdate = async(item) => {
        this.toggle(item)
        this.setState({progressStatus: true})
        await this.fetchFloors()
        this.setState({progressStatus: false})
    }
    render() {
        const {storageLocDetails} = this.state;
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.progressStatus}/>
                <MDBContainer className={'w-80 mw-80'}>
                    <MDBCard className={"mt-2"}>
                        {storageLocDetails.length > 0 &&
                        <MDBCardHeader>
                            <div className={'col-12 row m-0'}>
                                <div className={'col-md-6 m-0 card text-center border rounded-bottom rounded-top z-depth-1'}>
                                    <div className={"card-header white border-bottom mdb-color lighten-5"}>
                                        <h6>Storage Location Details</h6>
                                    </div>
                                    <div>
                                        <p className={"mt-2"}><h5><strong>{storageLocDetails[0].name}</strong></h5></p>
                                        <p className={"text-capitalize text-small"}>
                                            {storageLocDetails[0].addressLine1}{storageLocDetails[0].addressLine2 &&
                                        <span>,&nbsp;{storageLocDetails[0].addressLine2}</span>}
                                            <br/>
                                            {storageLocDetails[0].city}, {storageLocDetails[0].state}, {storageLocDetails[0].pinCode}
                                            {storageLocDetails[0].pincode}, {storageLocDetails[0].country}<br/>Phone No: {storageLocDetails[0].phone}
                                        </p>
                                    </div>
                                </div>
                                <div className={'col-md-6 m-0'}>
                                    <MDBFormInline className="md-form mr-auto m-0 float-right">
                                        {this.state.locId !== "" && <>
                                        <MDBBtn color="default" size="sm" onClick={()=>{this.props.history.push("/add-floor/"+this.state.locId)}} className="mr-auto">
                                            Add Floors
                                        </MDBBtn>
                                        <MDBBtn color="default" size="sm" outline onClick={()=>this.props.history.goBack()} className="mr-auto">
                                            Back
                                        </MDBBtn>
                                        </>}
                                    </MDBFormInline>
                                </div>
                            </div>
                        </MDBCardHeader>
                        }
                        <MDBCardBody>
                            {(this.state.floors.rows.length === 0 && this.state.storageLocDetails.length > 0) &&
                            <div className={'no-data-container'}>No floors found for the storage location: <strong>{this.state.storageLocDetails[0].name}</strong></div>
                            }
                            {this.state.floors.rows.length > 0 &&
                            <MDBDataTable
                                striped
                                bordered
                                data={this.state.floors}
                                materialSearch
                                noBottomColumns
                                hover
                            />
                            }
                        </MDBCardBody>
                    </MDBCard>
                </MDBContainer>
                <MDBModal isOpen={this.state.modalEdit} toggle={()=>this.toggle("Edit")} size={"lg"}>
                    <MDBModalHeader toggle={()=>this.toggle("Edit")}></MDBModalHeader>
                    <MDBModalBody className={"float-center"}>
                        {this.state.activeItem &&
                        <EditFloor floorDetails={this.state.activeItem} storageLocDetails={this.state.storageLocDetails}
                                            toggleEdit={() => this.refreshPageOnUpdate("Edit")}/>
                        }
                    </MDBModalBody>
                </MDBModal>
                <MDBModal isOpen={this.state.modalDelete} toggle={()=>this.toggle("Delete")}>
                    <MDBModalHeader toggle={()=>this.toggle("Delete")}>Delete Floor</MDBModalHeader>
                    <MDBModalBody className={"float-center"}>
                        <div className={"col-12 row m-0"}>
                            <div className={"col-md-12"}>
                                <p>Are you sure, you want to delete the floor named: </p>
                            </div>
                        </div>
                        {this.state.activeItem &&
                        <div className={"col-12 row m-0 text-center mb-3"}>
                            <div className={"col-md-12"}>
                                <span className={"text-capitalize"}><strong>{this.state.activeItem.floorName}</strong></span>
                            </div>
                        </div>
                        }
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="primary" outline onClick={()=>this.toggle("Delete")} size={"sm"}>Cancel</MDBBtn>
                        <MDBBtn color="default" onClick={this.deleteFloor} size={"sm"}>Delete</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
            </React.Fragment>
        )
    }
}

export default withRouter(ListFloors);