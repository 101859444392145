import React, {Component} from 'react';
import {MDBBreadcrumb, MDBBreadcrumbItem} from "mdbreact";
import {Link} from "react-router-dom";
import RequestReportCmp from "../../../components/report/v2/requestReport";

class RequestReport extends Component {
    render() {
        return (
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem>Reports</MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Report Requests</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <div className={"min-vh-100 p-0 m-0"}>
                    <RequestReportCmp/>
                </div>
            </React.Fragment>
        );
    }
}

export default RequestReport;