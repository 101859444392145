import orgConfig from "../../orgConfig";

const dataTableShipment= {
    shipmentView:[
        {
            label: 'Invoice Date',
            field: 'invoiceDate',
            sort: 'asc',
            width: 300
        },
        {
            label: 'Received On',
            field: 'receivingDate',
            sort: 'asc',
            width: 300
        },
        {
            label: 'Invoice Number',
            field: 'invoiceNumber',
            sort: 'asc',
            width: 300
        },
        {
            label: 'Added On',
            field: 'createdAt',
            sort: 'asc',
            width: 300
        },
        {
            label: 'Shipment#',
            field: 'shipmentCode',
            sort: 'asc',
            width: 300
        },
        {
            label: 'Supplier Name',
            field: 'supplierName',
            sort: 'asc',
            width: 300
        },
        {
            label: 'Received By',
            field: 'receivedByName',
            sort: 'asc',
            width: 100
        },
        {
            label: 'Documents',
            field: 'documentUrlTobeDisplayed',
            width: 100
        },
        {
            label: 'Invoiced Amount('+orgConfig.CURRENCY_SYMBOL+")",
            field: 'handleAmount',
            sort: 'asc',
            width: 100
        },



    ],
    paymentLog:[
        {
            label: 'Payment Date',
            field: 'paymentDate',
            sort: 'asc',
            width: 300
        },
        {
            label: 'Paid Amount('+orgConfig.CURRENCY_SYMBOL+")",
            field: 'paidAmount',
            sort: 'asc',
            width: 300
        },
        {
            label: 'Payment Mode',
            field: 'paymentMode',
            sort: 'asc',
            width: 300
        },
        {
            label: 'Payment Reference',
            field: 'paymentReference',
            sort: 'asc',
            width: 300
        },
        {
            label: 'Entered By',
            field: 'enteredBy',
            sort: 'asc',
            width: 300
        },
        {
            label: 'Entered On',
            field: 'enteredOn',
            sort: 'asc',
            width: 100
        },



    ]
}
export default dataTableShipment;
