import React from "react";
import Moments from "moment";
import UserStore from "../../../stores/userStore";
import PharmacyService from "../../../services/pharmacyService";
import {toast} from "react-toastify";
import {
    MDBBtn,
    MDBCol,
    MDBInput, MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader,
    MDBRow,
} from "mdbreact";
import {CSVLink} from "react-csv";
import MultipleDocsUpload from "../../common/fileUpload/MultipleDocsUpload";
import SimpleBackdrop from "../../common/overlay";
import MaterialTable from "material-table";

const headers = [
    {label: "Internal Stock Id", key: "_id"},
    {label: "Product Name", key: "productName"},
    {label: "Retail Package", key: "retailPackage"},
    {label: "Quantity Unit", key: "quantityUnit"},
    {label: "Content Quantiry", key: "contentQuantity"},
    {label: "Loose Sale Allowed", key: "looseSaleAllowed"},
    {label: "Invoice Number", key: "invoiceNo"},
    {label: "Batch", key: "batch"},
    {label: "Supplier Name", key: "supplierName"},
    {label: "Mfg Date", key: "mfgDateCSV"},
    {label: "Exp Date", key: "expDateCSV"},
    {label: "MRP", key: "mrp"},
    {label: "Price With GST", key: "priceWgst"},
    {label: "Discount", key: "discount"},
    {label: "Stock", key: "quantity"},
    {label: "Gst", key: "gst"},
    {label: "Price With GST", key: "priceWgst"},
    {label: "Price Without GST", "key": "priceNogst"},
    {label: "Expected Reimbursement", key: "reImburseAmt"},
    {label: "Status", key: "processStatus"},
    {label: "Returned", key: "returnQnty"},
    {label: "Destroyed", key: "destroyedQnty"},
    {label: "Final Reimbursement", key: "reimbAmt"},
    {label: "Amount settled", key: "isReimbDone"}
];

export default class ExpiredProducts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dt: Moments().format("YYYY-MM-DD"),
            isLoading: true,
            fullData: [],
            expData: [],
            totalAmount: 0,
            modal: false,
            itemTobeEdited: null,
            returnQnty: "",
            destroyedQnty: 0,
            expComment: "",
            isReturned: true,
            isDestroyed: false,
            docs: "",
            csvData: []
        }
    }

    async componentDidMount() {
        let userData = await UserStore.fetchSessionData();
        this.setState({userData})
        await this.refreshPage()
    }

    refreshPage = async () => {
        this.setState({isLoading: true})
        let data = await PharmacyService.fetchExpiredItems(this.state.userData, this.state.dt);
        if (data === false) {
            toast.error("Something went wrong while fetching the expired items.");
        } else {
            let temp = [];
            let totalAmount = 0;
            for (let item of data) {
                let t = {
                    productName: item.productDetails.productName,
                    batch: item.batch,
                    expDate: Moments(item.expDate),
                    _id: item._id,
                    invoiceNo: item.shipmentDetail.invoiceNumber,
                    supplierName: item.supplierDetail.supplierName,
                    quantity: item.quantity,
                    looseQuantity: item.hasOwnProperty("looseQuantity") ? item.looseQuantity : parseFloat(item.quantity) * parseFloat(item.productDetails.contentQuantity),
                    retailPackage: item.productDetails.retailPackage,
                    quantityUnit: item.productDetails.quantityUnit,
                    mrp: item.mrp,
                    mfgDate: item.mfgDate,
                    priceWgst: parseFloat(item.priceWgst.toFixed(2)),
                    discount: item.discount,
                    expDateCSV: Moments(item.expDate).format("MMM, YYYY"),
                    gst: item.gst,
                    mfgDateCSV: Moments(item.mfgDate).format("MMM, YYYY"),
                    priceNogst: parseFloat(item.priceNogst.toFixed(2)),
                    contentQuantity: item.productDetails.contentQuantity,
                    reImburseAmt: (parseFloat(item.priceNogst) * parseFloat(item.quantity)).toFixed(2),
                    looseSaleAllowed: item.productDetails.looseSaleAllowed,
                    status: item.status,
                    isReturned: item.hasOwnProperty("isReturned") ? item.isReturned : "",
                    isdestroyed: item.hasOwnProperty("isdestroyed") ? item.isdestroyed : "",
                    returnQnty: item.hasOwnProperty("returnQnty") ? item.returnQnty : 0,
                    destroyedQnty: item.hasOwnProperty("destroyedQnty") ? item.destroyedQnty : 0,
                    reimbAmt: item.hasOwnProperty("reimbAmt") ? item.reimbAmt : 0,
                    processStatus: (item.status === "E" || item.status === "C") ? "Processed" : "Not Processed",
                    isReimbDone: item.hasOwnProperty("isReimbReceived") ? item.isReimbReceived : ""
                }
                totalAmount = totalAmount + (parseFloat(t.priceNogst) * parseFloat(t.quantity))
                temp.push(t);
            }
            this.setState({fullData: data, totalAmount, csvData: temp})
        }
        this.setState({isLoading: false})
    }
    prev = async () => {
        let date = Moments(this.state.dt);
        let newDate = date.subtract(1, "month").format("YYYY-MM-DD")
        await this.setState({isLoading: true, dt: newDate});
        await this.refreshPage()
    }
    next = async () => {
        let date = Moments(this.state.dt);
        if (Moments(date).format("DD-MM-YYYY") < Moments().format("DD-MM-YYYY")) {
            let newDate = date.add(1, "month").format("YYYY-MM-DD")
            await this.setState({isLoading: true, dt: newDate});
            await this.refreshPage()
        }
    }
    editExpStock = (item) => {
        if (item !== null) {
            let returnQnty = item.looseSaleAllowed === "Y" ? item.looseQuantity : item.quantity;
            this.setState({
                itemTobeEdited: item,
                returnQnty: returnQnty,
                destroyedQnty: 0,
                expComment: "",
                isReturned: true,
                isDestroyed: false
            });
            this.toggle();
        }
    }
    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    }
    updateExpStatus = async () => {
        //validations
        if (this.state.isDestroyed === false && this.state.isReturned === false) {
            toast.error("Please select expiration status. Mark if the expired products are destroyed or returned.");
            return false;
        }
        let totalSelectedQuantity = parseFloat(this.state.returnQnty) + parseFloat(this.state.destroyedQnty);
        if (this.state.itemTobeEdited.looseSaleAllowed === "Y") {
            if (parseFloat(totalSelectedQuantity) !== parseFloat(this.state.itemTobeEdited.looseQuantity)) {
                toast.error("Quantity does not match");
                return false;
            }
        }
        if (this.state.itemTobeEdited.looseSaleAllowed === "N") {
            if (parseFloat(totalSelectedQuantity) !== parseFloat(this.state.itemTobeEdited.quantity)) {
                toast.error("Quantity does not match");
                return false;
            }
        }
        if (this.state.isDestroyed === true && this.state.expComment.trim() === "") {
            toast.error("Please add a comment.");
            return false;
        }
        //saving the data
        let userData = await UserStore.fetchSessionData();
        let payload = {
            stockId: this.state.itemTobeEdited._id,
            isdestroyed: this.state.isdestroyed === true ? "Y" : "N",
            destroyedQnty: parseFloat(this.state.destroyedQnty),
            isReturned: this.state.isReturned === true ? "Y" : "N",
            returnQnty: parseFloat(this.state.returnQnty),
            expComment: this.state.expComment.trim(),
            expReceipt: this.state.docs
        }
        this.setState({isLoading: true});
        let status = await PharmacyService.updateExpiredItem(userData, payload);
        this.setState({isLoading: false});
        if (status === false) {
            toast.error("We are facing some issue. Please try again later.");
        } else {
            toast.success("Stock expiration status updated successfully.");
            this.setState({isLoading: true});
            await this.refreshPage()
            this.toggle();
        }
    }
    initUploadCallback = () => {
        //this.setState({isLoading:true})
    }
    fileUploadCallback = (files) => {
        this.setState({docs: files, isLoading: false});
    }
    markAsreceived = async (item) => {
        let payload = {
            stockId: item._id
        }
        this.setState({isLoading: true});
        let status = await PharmacyService.updateExpiredPayMent(this.state.userData, payload);
        this.setState({isLoading: false});
        if (status === false) {
            toast.error("We are facing some issue. Please try again later.");
        } else {
            toast.success("Payment status updated successfully.");
            this.setState({isLoading: true});
            await this.refreshPage()
        }
    }
    review = async (item) => {
        alert("You dont have permission to review.");
    }

    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.isLoading}/>
                <div className={"col-12 row mx-0 my-4"}>
                    <div className={'col-md-4 p-0'}>
                        <MDBBtn size={'md'} disabled={this.state.isLoading} onClick={this.prev}>Prev</MDBBtn>
                        {this.state.dt !== null &&
                        <b>
                            {Moments(this.state.dt).format("MMM, YYYY")}
                        </b>
                        }
                        <MDBBtn size={'md'} disabled={this.state.isLoading} onClick={this.next}>Next</MDBBtn>
                    </div>
                    <div className={'col-md-4 p-0 title'}>Total (price with no GST)
                        : {this.state.totalAmount.toFixed(2)}</div>
                    <div className={'col-md-4 text-right p-0'}>
                        <CSVLink data={this.state.csvData} headers={headers}
                                 filename={"stock_exp_" + Moments(this.state.dt).format("MMM, YYYY") + ".csv"}>
                            <MDBBtn size={'md'} disabled={this.state.isLoading}>Download CSV</MDBBtn>
                        </CSVLink>
                    </div>
                </div>
                <MaterialTable
                    columns={[
                        {title: 'Name', field: 'productDetails.productName', width: "5%"},
                        {title: 'Pkg', field: 'productDetails.retailPackage', width: "5%"},
                        {title: 'Pkg Qty', field: 'productDetails.contentQuantity', width: "5%"},
                        {title: 'Unit', field: 'productDetails.quantityUnit', width: "5%"},
                        {title: 'Loose', field: 'productDetails.looseSaleAllowed', width: "5%"},
                        {title: 'Batch', field: 'batch', width: '5%'},
                        {
                            title: 'Mfg',
                            field: 'batch',
                            render: rowData => Moments(rowData.mfgDate).format("MMM, YYYY"),
                            width: "5%"
                        },
                        {
                            title: 'Exp',
                            field: 'expDate',
                            render: rowData => Moments(rowData.expDate).format("MM/YYYY"),
                            width: "5%"
                        },
                        {title: 'Inv', field: 'shipmentDetail.invoiceNumber', width: "5%"},
                        {title: 'Supp', field: 'supplierDetail.supplierName', width: "5%"},
                        {
                            title: 'Qty', field: '',
                            render: rowData => <>
                                Qnty- {Number.isInteger(rowData.quantity) ? rowData.quantity : rowData.quantity.toFixed(2)}<br/>
                                Loose Qnty- {rowData.looseQuantity}
                            </>,
                            width: "5%"
                        },
                        {title: 'MRP', field: 'mrp', render: rowData => <>₹{rowData.mrp.toFixed(2)}</>, width: '5%'},
                        {
                            title: 'GST', field: 'gst', width: "5%"
                        },
                        {
                            title: 'Price-GST',
                            field: 'priceNogst',
                            render: rowData => <>₹{rowData.priceNogst.toFixed(2)}</>,
                            width: "5%"
                        },
                        {
                            title: 'Price+GST',
                            field: 'priceWgst',
                            render: rowData => <>₹{rowData.priceWgst.toFixed(2)}</>,
                            width: "5%"
                        },
                        {
                            title: 'Reimb Amt',
                            field: 'reImburseAmt',
                            render: rowData => <>₹{(parseFloat(rowData.priceNogst) * parseFloat(rowData.quantity)).toFixed(2)}</>,
                            width: "5%"
                        },
                        {
                            title: 'Status', field: '', render: rowData => <div>
                                {rowData.status === "E" &&
                                <>
                                    <div>ReimbAmt:₹{rowData.hasOwnProperty("reimbAmt") ? rowData.reimbAmt.toFixed(2) : 0}</div>
                                    {rowData.isReturned === "Y" &&
                                    <div>
                                        {rowData.hasOwnProperty("returnQnty") ? rowData.returnQnty : 0} rtrnd
                                    </div>
                                    }
                                    {rowData.hasOwnProperty("isdestroyed") && rowData.isdestroyed === "Y" &&
                                    <div>
                                        {rowData.hasOwnProperty("destroyedQnty") ? rowData.destroyedQnty : 0} dstryd
                                    </div>
                                    }
                                    {rowData.hasOwnProperty("returnQnty") && rowData.returnQnty > 0 &&
                                    <div className={"cursor-pointer text-primary"} onClick={() => {
                                        this.markAsreceived(rowData)
                                    }}>Mark as payment received</div>
                                    }
                                </>
                                }
                                {rowData.status === "A" &&
                                <span className={"text-danger"}>Not processed</span>
                                }
                                {rowData.status === "C" &&
                                <>
                                    <div>ReimbAmt:₹{rowData.hasOwnProperty("reimbAmt") ? rowData.reimbAmt.toFixed(2) : 0}</div>
                                    <div className={"text-success"}>Amount Settled</div>
                                </>
                                }
                            </div>, width: "15%"
                        },
                        {
                            title: '', field: '',
                            render: rowData => <>
                                {(rowData.status === "A" || rowData.status === "E") &&
                                <span className={"cursor-pointer text-primary"} onClick={() => {
                                    this.editExpStock(rowData)
                                }}>Update
                                </span>
                                }
                                {rowData.status === "C" &&
                                <span className={"cursor-pointer text-primary"} onClick={() => {
                                    this.review(rowData)
                                }}>
                                    Review
                                </span>
                                }
                            </>,
                            width: "5%"
                        }
                    ]}
                    data={this.state.fullData}
                    options={{
                        showTitle: false,
                        pageSize: 10,
                        pageSizeOptions: [10, 50, 100],
                        emptyRowsWhenPaging: false,
                        tableLayout: 'fixed',
                    }}
                />

                <MDBModal size={"lg"} isOpen={this.state.modal} toggle={this.toggle}>
                    <MDBModalHeader toggle={this.toggle}>Expiry Update</MDBModalHeader>
                    <MDBModalBody>
                        {this.state.itemTobeEdited !== null &&
                        <>
                            <MDBRow>
                                <MDBCol>
                                    <MDBInput type={"checkbox"} label={"Eligible for return"} id="returned"
                                              checked={this.state.isReturned} getValue={(e) => {
                                        this.setState({isReturned: e})
                                    }}/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBInput label={"Quantity"} value={this.state.returnQnty} getValue={(e) => {
                                        this.setState({returnQnty: e})
                                    }}/>
                                </MDBCol>
                                <MDBCol>
                                    {this.state.itemTobeEdited.looseSaleAllowed === "N" &&
                                    <>
                                        {this.state.itemTobeEdited.retailPackage}
                                    </>
                                    }
                                    {this.state.itemTobeEdited.looseSaleAllowed === "Y" &&
                                    <>
                                        {this.state.itemTobeEdited.quantityUnit}
                                    </>
                                    }
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBInput type={"checkbox"} label={"Not eligible for return"} id="destroyed"
                                              checked={this.state.isDestroyed} getValue={(e) => {
                                        this.setState({isDestroyed: e})
                                    }}/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBInput label={"Quantity"} value={this.state.destroyedQnty} getValue={(e) => {
                                        this.setState({destroyedQnty: e})
                                    }}/>
                                </MDBCol>
                                <MDBCol>
                                    {this.state.itemTobeEdited.looseSaleAllowed === "N" &&
                                    <>
                                        {this.state.itemTobeEdited.retailPackage}
                                    </>
                                    }
                                    {this.state.itemTobeEdited.looseSaleAllowed === "Y" &&
                                    <>
                                        {this.state.itemTobeEdited.quantityUnit}
                                    </>
                                    }
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBInput type={"textarea"} label={"Comment"} value={this.state.expComment}
                                              getValue={(e) => {
                                                  this.setState({expComment: e})
                                              }}/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MultipleDocsUpload buttonLabel={"Attach Document"}
                                                        initUploadCallback={this.initUploadCallback}
                                                        fileUploadCallback={this.fileUploadCallback}
                                                        nextProps={this.state.resetForm}></MultipleDocsUpload>
                                </MDBCol>
                            </MDBRow>
                        </>
                        }
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn size={'md'} outline onClick={this.toggle} disabled={this.state.isLoading}>Close</MDBBtn>
                        <MDBBtn size={'md'} onClick={() => {
                            this.updateExpStatus()
                        }} disabled={this.state.isLoading}>Update</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
            </React.Fragment>
        )
    }
}