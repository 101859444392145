import React from "react";
import {MDBBox, MDBBreadcrumb, MDBBreadcrumbItem} from "mdbreact";
import {Link} from "react-router-dom";
import AddBoxCmp from "../../components/boxes/addBox";
class AddBox extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            locId: null
        }
    }
    componentDidMount() {
        let {locId} = this.props.match.params
        let {floorId} = this.props.match.params
        let {rackId} = this.props.match.params
        this.setState({locId, floorId, rackId})
    }
    render() {
        return(
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem><Link to={'/view-storage-locations'}>Storage Location</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem><Link to={'/view-floors/'+this.state.locId}>Floors</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem><Link onClick={'/view-racks/'+this.state.floorId}>Racks</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem><Link onClick={()=>this.props.history.goBack()}>Boxes</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Add Boxes</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <MDBBox className={"min-vh-100"}>
                    <AddBoxCmp queryString={this.props.match.params}/>
                </MDBBox>
            </React.Fragment>
        )
    }
}
export default AddBox;