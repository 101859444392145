import React,{Suspense} from "react";
import {MDBBox, MDBBreadcrumb, MDBBreadcrumbItem} from 'mdbreact';
import ManufacturerList from "../../components/manufacturer/listManfacturer";
import {Link} from "react-router-dom";

export default class ManufacturerListPage extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>View Manufacturers</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <MDBBox className={"min-vh-100"}>
                    <Suspense fallback={null}>
                        <ManufacturerList/>
                    </Suspense>
                </MDBBox>
            </React.Fragment>
        );
    }
}
