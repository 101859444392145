import React, { useState, useEffect } from "react";
import { Card, Table, Modal, Button } from "antd";
import Pagination from "rc-pagination";
import { toast } from "react-toastify";
import UserStore from "../../stores/userStore";
import { CopyOutlined, LinkOutlined } from '@ant-design/icons';
import { MDBBreadcrumb, MDBBreadcrumbItem } from "mdbreact";
import { Link } from "react-router-dom";
import ReportService from "../../services/reportService";

const ReportScheduleList = () => {
  const [reports, setReports] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [statusOptions, setStatusOptions] = useState([
    "ALL", "PENDING", "CREATED", "IN_QUEUE", "RUNNING", "COMPLETED", "STOPPED", "ERROR"
  ]);
  const [selectedStatus, setSelectedStatus] = useState("ALL");
  const [filteredReports, setFilteredReports] = useState([]);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  useEffect(() => {
    fetchData(currentPage, selectedStatus);
  }, [currentPage, selectedStatus]);

  const fetchData = async (page, status) => {
    setIsLoading(true);
    try {
      let userData = await UserStore.fetchSessionData();
      const response = await ReportService.fetchReportSchedule(userData, { pageNo: page, pageSize: 10, status });
      if (!response) {
        throw new Error("We are facing some server issue. Please try again later.");
      }
      setReports(response.data);
      setTotalRecords(response.totalRecords);
      filterReports(status, response.data);
      setIsErrorModalOpen(false);
    } catch (error) {
      setError("Failed to generate report. Please try again.");
      setIsErrorModalOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleReportDownload = async (fileName) => {
    try {
      console.log("FILENAME", fileName);
      let userData = await UserStore.fetchSessionData();
      const response = await ReportService.downloadReportSchedule(userData, { fileName });
      console.log(response);
      if (response && response.URL) {
        downloadFile(response.URL);
      } else {
        setError("Failed to Download the Report file . Please try again later.");
        setIsErrorModalOpen(true);
      }
    } catch (error) {
      console.error("Error downloading report:", error);
    }
  };


  const downloadFile = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = url.split('/').pop();
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const truncateString = (str, maxLen) => {
    if (!str || str.length <= maxLen) return str;
    return str.substr(0, maxLen) + '...';
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        toast.success("Copied to clipboard");
      })
      .catch((error) => {
        console.error("Failed to copy:", error);
        toast.error("Failed to copy to clipboard");
      });
  };

  const handleStatusChange = async (status) => {
    setSelectedStatus(status);
    setCurrentPage(1);
    fetchData(1, status);
  };

  const filterReports = (status, reportData) => {
    if (status === "ALL") {
      setFilteredReports(reportData);
    } else {
      const filtered = reportData.filter(report => report.status === status);
      setFilteredReports(filtered);
    }
  };

  const columns = [
    { title: "ID", dataIndex: "id", key: "id" },
    { title: "Report Name", dataIndex: "reportType", key: "reportType" },
    { title: "Status", dataIndex: "status", key: "status" },
    { title: "Requested By", dataIndex: "requestedBy", key: "requestedBy" },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: startDate => startDate ? new Date(startDate).toLocaleString("en-US", { month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric", hour12: true }) : ""
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      render: endDate => endDate ? new Date(endDate).toLocaleString("en-US", { month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric", hour12: true }) : ""
    },
    {
      title: "Job Start Time",
      dataIndex: "jobStartTime",
      key: "jobStartTime",
      render: jobStartTime => jobStartTime && new Date(jobStartTime).toLocaleString("en-US", { month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric", hour12: true })
    },
    {
      title: "Job End Time",
      dataIndex: "jobEndTime",
      key: "jobEndTime",
      render: jobEndTime => jobEndTime && new Date(jobEndTime).toLocaleString("en-US", { month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric", hour12: true })
    },
    {
      title: "Report Link",
      dataIndex: "fileName",
      key: "fileName",
      render: (fileName) => (
        fileName ? (
          <a href="#" onClick={() => handleReportDownload(fileName)}>
            <LinkOutlined /> Download Report
          </a>
        ) : null
      ),
    },

    {
      title: "Error Message",
      dataIndex: "errorMsg",
      key: "errorMsg",
      render: (errorMsg) => (
        <div>
          {errorMsg && (
            <>
              <span title={errorMsg}>{truncateString(errorMsg, 20)}</span>
              <CopyOutlined
                style={{ marginLeft: '8px', cursor: 'pointer' }}
                onClick={() => handleCopy(errorMsg)}
              />
            </>
          )}
        </div>
      ),
    },
  ];

  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
  };

  return (
    <div>
      <MDBBreadcrumb>
        <MDBBreadcrumbItem><Link to="/dashboard">Dashboard</Link></MDBBreadcrumbItem>
        <MDBBreadcrumbItem active>Report Schedule List</MDBBreadcrumbItem>
      </MDBBreadcrumb>
      <div className={"min-vh-100 p-2 m-0"}>
        <Card>
          <div className={"status-filter"} style={{ display: 'flex', flexDirection: 'row' }}>
            <h4>Status:</h4>
            {statusOptions.map(status => (
              <div key={status} className="form-check mr-3">
                <input
                  type="checkbox"
                  id={status}
                  checked={selectedStatus === status}
                  onChange={() => handleStatusChange(status)}
                  className="form-check-input"
                />
                <label htmlFor={status} className="form-check-label">{status}</label>
              </div>
            ))}
          </div>

          <Table
            columns={columns}
            dataSource={filteredReports}
            pagination={false}
            loading={isLoading}
          />
          {!isLoading && (
            <div
              className={"col-md-12 my-5 d-flex justify-content-center"}
              id={"paginate"}
            >
              <Pagination
                className="ant-pagination"
                onChange={handlePageChange}
                current={currentPage}
                total={totalRecords}
                pageSize={10}
              />
            </div>
          )}
        </Card>
      </div>
      {/* Error Modal */}
      <Modal
        title="Error"
        visible={isErrorModalOpen}
        onCancel={handleCloseErrorModal}
        footer={[
          <Button key="ok" type="primary" onClick={handleCloseErrorModal}>
            OK
          </Button>,
        ]}
      >
        <p>{error}</p>
      </Modal>
    </div>
  );
};

export default ReportScheduleList;
