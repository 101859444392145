import React from "react";
import {Link} from "react-router-dom";
import {withRouter} from "react-router-dom";
import {
    MDBFormInline,
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBBreadcrumb,
    MDBBreadcrumbItem,
    MDBBox,
    MDBInput,
    MDBCol,
    MDBRow,
    MDBDataTable,
    MDBModalHeader,
    MDBModalBody,
    MDBModal,
    MDBContainer
} from 'mdbreact';
import UserStore from "../../stores/userStore";
import {toast} from "react-toastify";
import PharmacyService from "../../services/pharmacyService";
import dataTableInventory from "./dataTableInventory";
import Moments from "moment";
import SimpleBackdrop from "../common/overlay";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import config from "../../config";
import {isEmptyValue} from "enzyme/src/Utils";
import UpdateInventory from "./updateInventory";
import SellingHistoryByProduct from "../report/sellingHistoryByProduct";

class ListInventory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            invList:[],
            productData:[],
            progressStatus:false,
            activeStock:"Y",
            expiredStock:"N",
            zeroQuantityStock:"N",
            defaultVal:"active",
            radio: 1,
            batch:"",
            shipment:"",
            invoice:'',
            modalEdit:false,
            modalProductView:false,
            activeInventory:{},
            activeProduct:{
                _id:""
            },
        }
    }

    goToNewInventory = () => {
        this.props.history.push('/add-stock');
    }

    fetchProduct = async (e) => {
        //filter validation
        if(this.state.activeStock==='N' && this.state.expiredStock==='N' && this.state.zeroQuantityStock==='N'){
            toast.error("Please select a filter.");
            return true;
        }
        let term=e.target.value;
        try {
            if (term !== '' && term !== null && term !== 0 && term !== '0') {
                let userData = await UserStore.fetchSessionData();
                let data=[];
                if(this.state.activeStock==="Y") {
                    data = await PharmacyService.searchProductInv(term, userData);
                }else if(this.state.expiredStock==="Y"){
                    data = await PharmacyService.searchProductInvExp(term, userData);
                }else if(this.state.zeroQuantityStock==="Y"){
                    data = await PharmacyService.searchProductInvZero(term, userData);
                }
                if(data===false){
                    toast.error("Something went wrong.");
                }else{
                    let temp = [];
                    for (let item of data) {
                        let str = {title: item.productName, value: item};
                        temp.push(str);
                    }
                    if(temp.length>0) {
                        this.setState({productData: temp});
                    }
                }
            }
        }catch (e){
            this.setState({productData:[]});
        }
    }
    convertToReadbleDate = date => {
        return Moments(date).format("MMM D, YYYY");
    }

    convertToReadbleMonthYear = date => {
        return Moments(date).format("MMM YYYY");
    }

    convertToReadbleDateTime = date => {
        return Moments(date).format("MMM D, YYYY");
    }
    selectProduct=async (event, values)=>{
        let currency=config.CURRENCY_SYMBOL;
        if((values && values.hasOwnProperty("value") && values.value!==null)) {
            let data=values.value;
            this.setState({selectedValue:data});

            //fetch inventory by id
            let userData = await UserStore.fetchSessionData();
            let ids=[]
            for(let item of data.inventory){
                ids.push(item._id);
            }
            let invData=await PharmacyService.fetchInvMultiple(ids, userData);
            if(invData===false){
                toast.error("Something went wrong.");
            }else {
                let productName = data.productName;
                let mfg='';
                try {
                    mfg = data.mfg.manufacturer;
                }catch (e){
                    mfg='';
                }
                let dataTablePayload = {
                    columns: dataTableInventory.inventory,
                    rows: invData.map((item, index) => {
                        let temp = {};
                        temp["batch"]=item.batch;
                        temp["handleAddedDate"] = this.convertToReadbleDate(item.createdAt);
                        temp["handleInvoiceNumber"] = item.shipmentDetails.invoiceNumber;
                        temp["batch"] = item.batch;
                        temp["handleProductName"] = productName;
                        temp["supplierName"] = item.shipmentDetails.supplierDetails.supplierName;
                        temp["handleMfgExp"] = <span>{this.convertToReadbleMonthYear(item.mfgDate)} / {this.convertToReadbleMonthYear(item.expDate)}</span>;
                        temp["handleMrp"] = currency+item.mrp.toFixed("2");
                        temp["handleDiscount"] = <span>{item.discount.toFixed("2")}%</span>;
                        temp["handlePurchasePrice"] = currency+item.priceWgst.toFixed(2);
                        temp["quantity"]=item.quantityIn;
                        temp["totalQuantity"]=item.quantity;
                        try {
                            temp["handleManufacturer"] = mfg;
                        } catch (e) {
                            temp["handleManufacturer"] = "";
                        }
                        temp["handleEdit"] =
                            <div color="primary" className={"blue-text btn-link cursor-pointer"} size="sm"
                                 onClick={() => {
                                     this.onEdit(item)
                                 }}>Edit</div>;
                        temp["handleActions"] = <div>
                                    <div color="primary" className={"p-1 blue-text btn-link cursor-pointer"} size="sm"
                                          onClick={() => {
                                              this.onEdit(item)
                                          }}>
                                            Edit
                                        </div>

                            <div color="primary" className={"p-1 blue-text btn-link cursor-pointer"} size="sm"
                                  onClick={() => {
                                      this.onProductView(item)
                                  }}>
                                            Transactions
                                        </div>
                        </div>;
                        return temp;
                    })
                };
                this.setState({invList: dataTablePayload});
            }
        }
    }
    toggleEdit = () => {
        this.setState({
            modalEdit: !this.state.modalEdit
        });
    }

    toggleProductView = () => {
        this.setState({
            modalProductView: !this.state.modalProductView
        });
    }

    onEditComplete = (row) => {
        this.toggleEdit()

    }
    // This triggers from MDBDataTable, aim is to redirect to edit page
    onEdit =  (row) => {
        this.setState({activeInventory: row})
        this.toggleEdit()
    }

    onProductView =  (row) => {
        console.log(row)
        this.setState({activeProduct: row.productDetail})
        this.toggleProductView()
    }
    activeStock=(val)=>{
        this.setState({defaultVal:"active"});
        if(val===true){
            this.setState({
                activeStock:"Y",
                expiredStock:"N",
                zeroQuantityStock:"N",
                productData:[]
            });
        }else{
            if(this.state.expiredStock==='Y' && this.state.zeroQuantityStock==='Y') {
                this.setState({
                    activeStock: "N",
                    productData:[]
                });
            }
        }
    }
    expiredStock=(val)=>{
        this.setState({defaultVal:"expired"});
        if(val===true){
            this.setState({
                activeStock:"N",
                expiredStock:"Y",
                zeroQuantityStock:"N",
                productData:[]
            });
        }else{
            if(this.state.activeStock==='Y' && this.state.zeroQuantityStock==='Y') {
                this.setState({
                    expiredStock: "N",
                    productData:[]
                });
            }
        }
    }
    zeroQuantity=(val)=>{
        this.setState({defaultVal:"zero"});
        if(val===true){
            this.setState({
                activeStock:"N",
                expiredStock:"N",
                zeroQuantityStock:"Y",
                productData:[]
            });
        }else{
            if(this.state.activeStock==='Y' && this.state.expiredStock==='Y') {
                this.setState({
                    zeroQuantityStock: "N",
                    productData:[]
                });
            }
        }
    }
    onClick = nr => () => {
        this.setState({
            radio: nr,
            invList:[]
        });
    }
    search=async (val)=>{
        if(parseInt(val) === 2 && this.state.batch.trim() === ""){ return false }
        if(parseInt(val) === 3 && this.state.shipment.trim() === ""){ return false }
        if(parseInt(val) === 4 && this.state.invoice.trim() === ""){ return false }

        let currency=config.CURRENCY_SYMBOL;
        this.setState({invList:[],progressStatus:true});
        let userData = await UserStore.fetchSessionData();
        let searchData=[];
        if(parseInt(val) === 2) {
            searchData = await PharmacyService.searchProductByType(this.state.batch, userData, 'batch');
        }else if(parseInt(val) === 3){
            searchData = await PharmacyService.searchProductByType(this.state.shipment, userData, 'shipment');
            if(!isEmptyValue(searchData)){ searchData=searchData.inventory; }
        }else if(parseInt(val) === 4){
            searchData = await PharmacyService.searchProductByType(this.state.invoice, userData, 'invoice');
            if(!isEmptyValue(searchData)){ searchData=searchData.inventory; }
        }
        if(searchData===false){
            toast.error("We are facing some issue. Please try again later.");
            this.setState({progressStatus:false});
        }else{
            let ids=[]
            if(!isEmptyValue(searchData)){
                for(let item of searchData){
                    ids.push(item._id);
                }
            }
            let invData=await PharmacyService.fetchInvMultiple(ids, userData);
            if(invData===false){
                toast.error("Something went wrong.");
                this.setState({progressStatus:false});
            }else {
                this.setState({progressStatus:false});
                let dataTablePayload = {
                    columns: dataTableInventory.inventory,
                    rows: []
                }
                if(invData.length > 0){
                    dataTablePayload = {
                        columns: dataTableInventory.inventory,
                        rows: invData.map((item, index) => {
                            let temp = {};
                            temp["batch"]=item.batch;
                            temp["handleAddedDate"] = this.convertToReadbleDate(item.createdAt);
                            temp["handleInvoiceNumber"] = item.shipmentDetails.invoiceNumber;
                            temp["batch"] = item.batch;
                            temp["handleProductName"] = item.productDetail.productName;
                            temp["supplierName"] = item.shipmentDetails.supplierDetails.supplierName;
                            temp["handleMfgExp"] = <span>{this.convertToReadbleMonthYear(item.mfgDate)} / {this.convertToReadbleMonthYear(item.expDate)}</span>;
                            temp["handleMrp"] = currency+item.mrp.toFixed("2");
                            temp["handleDiscount"] = <span>{item.discount.toFixed("2")}%</span>;
                            temp["handlePurchasePrice"] = currency+item.priceWgst.toFixed(2);
                            temp["quantity"]=item.quantityIn;
                            temp["totalQuantity"]=item.quantity;
                            try {
                                temp["handleManufacturer"] = item.productDetail.manufacturer[0].manufacturer;
                            } catch (e) {
                                temp["handleManufacturer"] = "";
                            }

                            temp["handleActions"] = <div>
                                    <div color="primary" className={"p-1 blue-text btn-link cursor-pointer"} size="sm"
                                          onClick={() => {
                                              this.onEdit(item)
                                          }}>
                                            Edit
                                        </div>
                                <div color="primary" className={"p-1 blue-text btn-link cursor-pointer"} size="sm"
                                     onClick={() => {
                                         this.onProductView(item)
                                     }}>
                                    Transactions
                                </div>
                            </div>;
                            return temp;
                        })
                    };
                }
                this.setState({invList: dataTablePayload});
            }
        }
    }
    changeBatch=(val)=>{
        this.setState({batch:val})
    }
    changeShipment=(val)=>{
        this.setState({shipment:val});
    }
    changeInvoice=(val)=>{
        this.setState({invoice:val});
    }
    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.progressStatus}/>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Search All Stock</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <MDBBox className={"m-1 p-1"}>
                    <MDBCard className={"mt-2"}>
                        <MDBCardHeader>
                            <span className={"float-left"}>Search All Stock</span>
                            <MDBFormInline className="float-right md-form mr-auto m-0">
                                <MDBBtn color="default" size="sm" onClick={this.goToNewInventory}
                                        className="mr-auto">
                                    Add Stock
                                </MDBBtn>
                            </MDBFormInline>
                        </MDBCardHeader>
                        <MDBCardBody>
                            <MDBRow>
                                <MDBCol >
                                    <MDBInput onClick={this.onClick(1)} checked={this.state.radio===1 ? true : false} label="Product" type="radio" id="radio1" />
                                </MDBCol>
                                <MDBCol >
                                    <MDBInput onClick={this.onClick(2)} checked={this.state.radio===2 ? true : false} label="Batch" type="radio" id="radio2" />
                                </MDBCol>
                                <MDBCol >
                                    <MDBInput onClick={this.onClick(3)} checked={this.state.radio===3 ? true : false} label="Shipment" type="radio" id="radio3" />
                                </MDBCol>
                                <MDBCol >
                                    <MDBInput onClick={this.onClick(4)} checked={this.state.radio===4 ? true : false} label="Invoice" type="radio" id="radio4" />
                                </MDBCol>
                            </MDBRow>
                            <div style={{"width":"25%"}}>
                                {this.state.radio === 1 &&
                                    <Autocomplete
                                        freeSolo
                                        onChange={this.selectProduct}
                                        id="combo-box-demo"
                                        options={this.state.productData}
                                        getOptionLabel={option => option.title}
                                        onInputChange={(e) => {
                                            this.fetchProduct(e)
                                        }}
                                        key={this.state.defaultVal}
                                        selectOnFocus={true}
                                        renderInput={params => (
                                            <TextField
                                                style={this.state.productError ? {"borderBottom": "1px solid RED"} : {}} {...params}
                                                placeholder="Search Product"
                                                margin="normal" fullWidth/>
                                        )}
                                    />
                                }
                                {this.state.radio === 2 &&
                                    <MDBRow>
                                        <MDBCol>
                                            <MDBInput label={'Batch'} getValue={this.changeBatch}/>
                                        </MDBCol>
                                        <MDBCol>
                                            <MDBBtn color="default" size="sm" className={"mt-4"} onClick={()=>{this.search(2)}}>Search</MDBBtn>
                                        </MDBCol>
                                    </MDBRow>
                                }
                                {this.state.radio === 3 &&
                                <MDBRow>
                                    <MDBCol>
                                        <MDBInput label={'Shipment Id'} getValue={this.changeShipment}/>
                                    </MDBCol>
                                    <MDBCol>
                                        <MDBBtn color="default" size="sm" className={"mt-4"} onClick={()=>{this.search(3)}}>Search</MDBBtn>
                                    </MDBCol>
                                </MDBRow>
                                }
                                {this.state.radio === 4 &&
                                <MDBRow>
                                    <MDBCol>
                                        <MDBInput label={'Inventory Id'} getValue={this.changeInvoice}/>
                                    </MDBCol>
                                    <MDBCol>
                                        <MDBBtn color="default" size="sm" className={"mt-4"} onClick={()=>{this.search(4)}}>Search</MDBBtn>
                                    </MDBCol>
                                </MDBRow>
                                }
                                {this.state.radio === 1 &&
                                <div>
                                    <MDBInput label="Active Stock" type="checkbox" id="checkbox_active"
                                              checked={this.state.activeStock === "Y" ? true : false}
                                              getValue={this.activeStock}/>
                                    <MDBInput label="Expired Stock" type="checkbox" id="checkbox_expired"
                                              checked={this.state.expiredStock === "Y" ? true : false}
                                              getValue={this.expiredStock}/>
                                    <MDBInput label="Zero Quantity Stock" type="checkbox" id="checkbox_zero"
                                              checked={this.state.zeroQuantityStock === "Y" ? true : false}
                                              getValue={this.zeroQuantity}/>
                                </div>
                                }
                            </div>
                            <div style={{fontSize:"12px"}}>
                            { this.state.invList.hasOwnProperty("rows") &&
                                // <MDBDataTableV5
                                //     hover
                                //     entriesOptions={[10, 15, 20, 25]}
                                //     entries={10}
                                //     pagesAmount={4}
                                //     data={this.state.invList}
                                //     striped
                                //     barReverse
                                //     materialSearch
                                //     searching={false}
                                // />
                                <MDBDataTable className={"p-0 m-0"}
                                striped

                                data={this.state.invList}
                                materialSearch
                                noBottomColumns
                                hover
                                />
                            }
                            </div>



                        </MDBCardBody>
                    </MDBCard>
                    <MDBModal isOpen={this.state.modalEdit} toggle={this.toggleEdit} size={"fluid"}>
                        <MDBModalHeader toggle={this.toggleEdit}></MDBModalHeader>
                        <MDBModalBody className={"float-center"}>
                            {/*<UpdateInventory loadedOnPopup={true} onEditComplete={this.onEditComplete}  inventoryId={this.state.activeInventory._id}/>*/}
                            Temporarily disabled
                        </MDBModalBody>
                    </MDBModal>

                    <MDBModal isOpen={this.state.modalProductView} toggle={this.toggleProductView} size={"fluid"}>
                        <MDBModalHeader toggle={this.toggleProductView}></MDBModalHeader>
                        <MDBModalBody className={"float-center"}>
                            <SellingHistoryByProduct productId={this.state.activeProduct._id} />
                        </MDBModalBody>
                    </MDBModal>

                </MDBBox>
            </React.Fragment>
        );
    }
}

export default withRouter(ListInventory)
