import React, { useState, useEffect } from "react";
import {
    MDBBox,
    MDBBreadcrumb,
    MDBBreadcrumbItem
} from 'mdbreact';
import { Link } from "react-router-dom";
import { Grid, Typography, Button } from "@material-ui/core";
import config from "../../config";
import TableProduct from "../../newComponents/ProductTable";
import UserStore from "../../stores/userStore";
import TransferStockService from "../../services/transferStockService";
import SimpleBackdrop from "../../components/common/overlay"

const ProductSearching = () => {
    const path = window.location.pathname;
    const parts = path.split("/");
    const id = parts[parts.length - 1];
    const [productData, setProductData] = useState([])
    const [loading, setLoading] = useState(false)
    const [isEmpty, setEmpty] = useState(false)

    useEffect(() => {
        fetchStockData()
    }, [id])

    const fetchStockData = async () => {
        try {
            setLoading(true)
            let user = await UserStore.fetchSessionData();
            const res = await TransferStockService.locationWithProductId(user.sessionToken, id)
            if (res.length) {
                setProductData(res)
                setLoading(false)
            }
            else {
                setProductData([])
                setEmpty(true)
                setLoading(false)
            }

        }
        catch (err) {
            console.log(err)
        }

    }

    return (
        <><SimpleBackdrop status={loading} />
            <MDBBreadcrumb>
                <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                <MDBBreadcrumbItem active>Product search</MDBBreadcrumbItem>
            </MDBBreadcrumb>
            <MDBBox className={"min-vh-100"}>
                <Grid container className="productPageContainer">
                    <Grid item xs={12} className="productTableContainer">
                        <TableProduct
                            data={productData}
                            isEmpty={isEmpty} 
                            productId={id}
                            
                            />
                    </Grid>
                </Grid>
            </MDBBox>
        </>
    );
};

export default ProductSearching;
