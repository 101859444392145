import React from "react";
import {withRouter} from "react-router-dom";
import SimpleBackdrop from "../common/overlay";
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCol,
    MDBContainer,
    MDBRow, MDBBadge,  MDBTable, MDBTableHead, MDBTableBody
} from "mdbreact";
import UserStore from "../../stores/userStore";
import {toast} from "react-toastify";
import ReportService from "../../services/reports";
import utility from "../../utility/utility";

class ViewDeleteHistoryById extends React.Component {
    constructor() {
        super();
        this.state = {
            _id:"",
            deleteHistory:{},
            progressStatus: false,
            type: "all",
            comments:"",
            product: {
                verificationLog:[]
            },
            suggestedData:[],
            tableData: {columns: [], rows: []},

            modalStatus:false,
            activeProductId:0,
            modalEdit:false,
            modalView:false,
            activeProduct:{

            },
            // modalAction:"",
            // disableRetreatAfterSorting:false,
            // verificationStatus:"MANUALLY_VERIFIED",
            // verificationStatuses: [{ text:"Manually Verified", value:"MANUALLY_VERIFIED", checked:true },
            //     { text:"Duplicate", value:"DUPLICATE" },
            //     { text:"Junk", value:"JUNK" },
            //     { text:"Incomplete", value:"INCOMPLETE" },
            //     { text:"Incorrect", value:"INCORRECT" },
            //     { text:"Ready to publish", value:"READY_TO_PUBLISH"}
            // ],
            // modalDelete:false,
            // hideSimilarProduct:false,
        }
    }

    async componentDidMount () {
        let deleteHistory = this.props.deleteHistory;
        await this.setState({_id:deleteHistory._id, productIds:deleteHistory.productIds, loadedOnPopup:true, deleteHistory:deleteHistory})
        this.fetchProductsFromProductIds()

    }

    // getProductsList = async () => {
    //     let userData = await UserStore.fetchSessionData();
    //     this.setState({progressStatus: true});
    //     let data = await ReportService.fetchDuplicateProducts(userData, this.state);
    //     this.setState({progressStatus: false});
    //     return data
    // }
    //
    // toggleView = () => {
    //     this.setState({
    //         modalView: !this.state.modalView
    //     });
    // }
    //
    // handleVerificationStatus = (e) => {
    //     this.setState({verificationStatus:e[0]})
    // }
    //
    // handleComments = (val) => {
    //     this.setState({comments:val})
    // }
    //
    // confirmVerify = async ()=>{
    //     let userData = await UserStore.fetchSessionData();
    //     this.setState({progressStatus: true});
    //     let data = await ReportService.markProduct(userData, this.state);
    //     this.setState({progressStatus: false});
    //     this.props.onEditComplete()
    //
    // }

    fetchProductsFromProductIds=async()=>{
        let userData=await UserStore.fetchSessionData();

        let suggestedData = await ReportService.fetchProductDeleteHistoryById(this.state,userData);
        if(this.state.product.productName!=='') {
            this.setState({suggestedData: suggestedData});
        }
    }

    // toggleDelete = () => {
    //     this.setState({
    //         modalDelete: !this.state.modalDelete
    //     });
    // }

    // initDelete = async (id) =>{
    //     this.setState({activeProductId:id})
    //     this.toggleDelete()
    // }
    // confirmDelete = async () => {
    //     let userData=await UserStore.fetchSessionData();
    //     this.setState({progressStatus:true});
    //     let status=await PharmacyService.deleteProduct({productId:this.state.activeProductId},userData);
    //     this.setState({progressStatus:false});
    //     if(status===false){
    //         toast.error("Something went wrong.");
    //         return true;
    //     }else{
    //         toast.success("Product deleted successfully.");
    //         this.toggleDelete();
    //         if(this.state.loadedOnPopup === true){
    //             this.props.onEditComplete()
    //         } else {
    //             this.props.history.push('/view-products');
    //         }
    //
    //     }
    // }

    // // Edit products
    // toggleView = () => {
    //     this.setState({
    //         modalView: !this.state.modalView
    //     });
    // }

    // toggleEdit = () => {
    //     this.setState({
    //         modalEdit: !this.state.modalEdit
    //     });
    // }
    // onEditComplete = (row) => {
    //     this.toggleEdit()
    //     this.fetchData();
    // }
    // // This triggers from MDBDataTable, aim is to redirect to edit page
    // onEditDesc =  (row) => {
    //     this.setState({activeProduct: row,modalAction:"edit"})
    //     this.toggleEdit()
    // }
    //
    // onEdit=(item)=>{
    //     // this.props.history.push('/product/cms/'+item._id);
    //     this.setState({activeProduct: item, modalAction:"updateConfig"})
    //     this.toggleEdit()
    // }

    onMark=(item)=>{

        // this.props.history.push('/product/cms/'+item._id);
        // this.setState({activeProduct: item, modalAction:"mark"})
        // this.toggleEdit()
    }

    // onUpdateStockAvailability=(item)=>{
    //     // this.props.history.push('/product/cms/'+item._id);
    //     this.setState({activeProduct: item, modalAction:"stockAvailability"})
    //     this.toggleEdit()
    // }
    //
    onView =  (row) => {
        this.props.history.push('/product-details/'+row._id);
        this.toggleView()
    }


    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.progressStatus}/>
                <MDBContainer className={'w-100 mw-100'}>
                    <MDBCard className={"mt-2"}>
                        <MDBCardHeader>
                            <MDBRow >
                                <MDBCol className={'title'}>
                                    View Delete History
                                </MDBCol>
                            </MDBRow>
                        </MDBCardHeader>
                        <MDBCardBody>

                            <MDBRow>
                                <MDBCol>
                                    <span>Deleted By: <b>{this.state.deleteHistory.deletedByUserName}</b></span>
                                    <span> On {utility.convertToReadbleDaySmart2(this.state.deleteHistory.modifiedAt)}
                                    </span>
                                </MDBCol>
                            </MDBRow>

                            <MDBRow className={"mt-5"}>
                                {(this.state.suggestedData.length > 0) &&
                                <MDBCol>
                                    Deleted Products:
                                    <MDBTable className={"col-12"}>
                                        <MDBTableHead>
                                            <tr>
                                                <th>#</th>
                                                <th>Product Name</th>
                                                <th>Medicine Type</th>
                                                <th>Retail Package</th>
                                                <th>Quantity Unit</th>
                                                <th>Content Quantity</th>
                                                <th>MRP</th>
                                                <th>Stock</th>
                                                <th>Mark</th>
                                            </tr>
                                        </MDBTableHead>
                                        <MDBTableBody>
                                            {this.state.suggestedData.map((row, index) => (
                                                <>
                                                    {row._id !== this.state._id &&
                                                    <tr style={{color: (row.productName === this.state.product.productName) ? "red" : ""}}
                                                        key={"duplicateData" + index}>
                                                        <td>{index + 1}</td>
                                                        <td>{row.productName}</td>
                                                        <td><span
                                                            className={utility.validateMedicineType(true, row.medicineType) ? "green-text" : "red-text"}>{row.medicineType}</span>
                                                        </td>
                                                        <td><span
                                                            className={utility.validateRetailPackage(row.retailPackage) ? "green-text" : "red-text"}>{row.retailPackage}</span>
                                                        </td>
                                                        <td><span
                                                            className={utility.validateQuantityUnit(row.quantityUnit) ? "green-text" : "red-text"}>{row.quantityUnit}</span>
                                                        </td>
                                                        <td><span
                                                            className={utility.validateContentQuantity(row.contentQuantity) ? "green-text" : "red-text"}>{row.contentQuantity}</span>
                                                        </td>
                                                        <td><span
                                                            className={utility.validateMrp(row.mrp) ? "green-text" : "red-text"}>{row.mrp}</span>
                                                        </td>
                                                        <td><span
                                                            className={utility.validateStock(row.stock) ? "green-text" : "red-text"}>{row.stock}</span>
                                                        </td>


                                                        <td>
                                                            <div>
                                                                {row.hasOwnProperty("verificationStatus") &&
                                                                <div style={{cursor: "pointer"}}>
                                                                    {row.verificationStatus === "READY_TO_PUBLISH" &&
                                                                    <div style={{color: "green"}}
                                                                         onClick={() => this.onMark(row)}>
                                                                        <div style={{"fontSize": "17px"}}><b>Ready To
                                                                            Publish</b></div>
                                                                        <div
                                                                            style={{"fontSize": "12px"}}>{utility.convertToReadbleDaySmart2(row.verificationLog[row.verificationLog.length - 1].verifiedOn)}</div>
                                                                        <div
                                                                            style={{"fontSize": "12px"}}>{row.verificationLog[row.verificationLog.length - 1].verifiedByUserName}</div>
                                                                    </div>
                                                                    }
                                                                    {row.verificationStatus === "MANUALLY_VERIFIED" &&
                                                                    <div style={{color: "green"}}
                                                                         onClick={() => this.onMark(row)}>
                                                                        <div style={{"fontSize": "17px"}}>
                                                                            <b>Verified</b></div>
                                                                        <div
                                                                            style={{"fontSize": "12px"}}>{utility.convertToReadbleDaySmart2(row.verificationLog[row.verificationLog.length - 1].verifiedOn)}</div>
                                                                        <div
                                                                            style={{"fontSize": "12px"}}>{row.verificationLog[row.verificationLog.length - 1].verifiedByUserName}</div>
                                                                    </div>
                                                                    }
                                                                    {row.verificationStatus === "JUNK" &&
                                                                    <div style={{color: "red"}}
                                                                         onClick={() => this.onMark(row)}>
                                                                        <div style={{"fontSize": "17px"}}><b>Junk</b>
                                                                        </div>
                                                                        <div
                                                                            style={{"fontSize": "12px"}}>{utility.convertToReadbleDaySmart2(row.verificationLog[row.verificationLog.length - 1].verifiedOn)}</div>
                                                                        <div
                                                                            style={{"fontSize": "12px"}}>{row.verificationLog[row.verificationLog.length - 1].verifiedByUserName}</div>
                                                                    </div>
                                                                    }
                                                                    {row.verificationStatus === "DUPLICATE" &&
                                                                    <div style={{color: "orange"}}
                                                                         onClick={() => this.onMark(row)}>
                                                                        <div style={{"fontSize": "17px"}}>
                                                                            <b>Duplicate</b></div>
                                                                        <div
                                                                            style={{"fontSize": "12px"}}>{utility.convertToReadbleDaySmart2(row.verificationLog[row.verificationLog.length - 1].verifiedOn)}</div>
                                                                        <div
                                                                            style={{"fontSize": "12px"}}>{row.verificationLog[row.verificationLog.length - 1].verifiedByUserName}</div>
                                                                    </div>
                                                                    }
                                                                    {row.verificationStatus === "INCOMPLETE" &&
                                                                    <div style={{color: "orange"}}
                                                                         onClick={() => this.onMark(row)}>
                                                                        <div style={{"fontSize": "17px"}}>
                                                                            <b>Incomplete</b></div>
                                                                        <div
                                                                            style={{"fontSize": "12px"}}>{utility.convertToReadbleDaySmart2(row.verificationLog[row.verificationLog.length - 1].verifiedOn)}</div>
                                                                        <div
                                                                            style={{"fontSize": "12px"}}>{row.verificationLog[row.verificationLog.length - 1].verifiedByUserName}</div>
                                                                    </div>
                                                                    }
                                                                    {row.verificationStatus === "INCORRECT" &&
                                                                    <div style={{color: "orange"}}
                                                                         onClick={() => this.onMark(row)}>
                                                                        <div style={{"fontSize": "17px"}}>
                                                                            <b>Incorrect</b></div>
                                                                        <div
                                                                            style={{"fontSize": "12px"}}>{utility.convertToReadbleDaySmart2(row.verificationLog[row.verificationLog.length - 1].verifiedOn)}</div>
                                                                        <div
                                                                            style={{"fontSize": "12px"}}>{row.verificationLog[row.verificationLog.length - 1].verifiedByUserName}</div>
                                                                    </div>
                                                                    }
                                                                </div>
                                                                }

                                                            </div>
                                                        </td>

                                                    </tr>
                                                    }

                                                    {row._id === this.state._id &&
                                                    <tr style={{color: "blue"}} key={"duplicateData" + index}>
                                                        <td><b>{index + 1}</b></td>
                                                        <td><b>{row.productName}</b></td>
                                                        <td><b>{row.medicineType}</b></td>
                                                        <td><b>{row.retailPackage}</b></td>
                                                        <td><b>{row.quantityUnit}</b></td>
                                                        <td><b>{row.contentQuantity}</b></td>
                                                        <td><b>{row.mrp}</b></td>
                                                        <td><b>{row.stock}</b></td>

                                                        <td></td>

                                                    </tr>
                                                    }
                                                </>
                                            ))}
                                        </MDBTableBody>
                                    </MDBTable>


                                </MDBCol>
                                }
                            </MDBRow>

                        </MDBCardBody>
                    </MDBCard>

                </MDBContainer>
            </React.Fragment>
        )
    }
}

export default withRouter(ViewDeleteHistoryById)