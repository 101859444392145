import React from "react";
import {withRouter} from "react-router-dom";
import UserStore from "../../stores/userStore";
import OrderService from "../../services/orderService";
import {
    MDBCol, MDBRow, MDBInput, MDBBtn, MDBContainer, MDBModal, MDBModalHeader,
    MDBModalBody, MDBModalFooter
} from "mdbreact";
import {toast} from "react-toastify";
import config from "../../config";
import SimpleBackdrop from "../../components/common/overlay";
import Moments from "moment";

class AcknowledgeOrder extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            orderId: (props.match.params.orderId) ? props.match.params.orderId : '',
            orderDetails: [],
            customerDetails: [],
            shippingAddress: [],
            billingAddress: [],
            docs: [],
            orderStatus: '',
            statusComment: '',
            statusCommentError: false,
            progressStatus: false,
            modal2: false,
            todayDate: '',
            todayTime: '',
            selectedButtonSet: [],
            orderStatusList: {
                status1: 'CREATED', status2: 'REQUEST ACKNOWLEDGED', status3: 'AWAITING PRESCRIPTIONS',
                status4: 'SENT TO FULFILMENT', status5: 'ORDER CANCELLED'
            },
            btnsForStatusCreated: [{value: 'Acknowledge Order'}, {value: "Cancel Order"}],
            btnsForStatusAcknowledged: [{value: 'Request Prescriptions'}, {value: 'Send to Fulfilment'}, {value: "Cancel Order"}],
            btnsForStatusRequestedPrescriptions: [{value: 'Send to Fulfilment'}, {value: "Cancel Order"}],
            btnsForStatusSentToFulfilment: [{value: "Cancel Order"}],
            btnsForStatusCanceledOrder: [{value: 'Request Prescriptions'}],
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.fetchPrescriptionOrder().then(res => {
            if (res && this._isMounted === true) {
                this.setState({orderDetails: res})
                this.setState({customerDetails: res.customerDetails})
                this.setState({shippingAddress: res.shippingAddress})
                this.setState({billingAddress: res.billingAddress})
                this.setState({docs: res.docs})
                this.setState({orderStatus: res.status})
                this.setButtonsBasedOnStatus(res.status)
            } else {
                toast.error("Something went wrong while fetching data.")
            }
        })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setButtonsBasedOnStatus = (status) => {
        if (status === this.state.orderStatusList.status1) {
            this.setState({selectedButtonSet: this.state.btnsForStatusCreated})
        } else if (status === this.state.orderStatusList.status2) {
            this.setState({selectedButtonSet: this.state.btnsForStatusAcknowledged})
        } else if (status === this.state.orderStatusList.status3) {
            this.setState({selectedButtonSet: this.state.btnsForStatusRequestedPrescriptions})
        } else if (status === this.state.orderStatusList.status4) {
            this.setState({selectedButtonSet: this.state.btnsForStatusSentToFulfilment})
        } else if (status === this.state.orderStatusList.status5) {
            this.setState({selectedButtonSet: this.state.btnsForStatusCanceledOrder})
        }
    }

    fetchPrescriptionOrder = async () => {
        const userData = await UserStore.fetchSessionData();
        this.setState({progressStatus: true})
        const res = await OrderService.fetchPrescriptionOrderById(userData, this.state.orderId);
        this.setState({progressStatus: false})
        return res
    }
    selectStatus = () => {
        let orderStatus = '';
        if (this.state.modalHeader === 'Acknowledge Order') {
            orderStatus = this.state.orderStatusList.status2
        } else if (this.state.modalHeader === 'Request Prescriptions') {
            orderStatus = this.state.orderStatusList.status3
        } else if (this.state.modalHeader === 'Send to Fulfilment') {
            orderStatus = this.state.orderStatusList.status4
        } else if (this.state.modalHeader === 'Cancel Order') {
            orderStatus = this.state.orderStatusList.status5
        }
        return orderStatus
    }
    handleStatusComment = (e) => {
        this.setState({statusComment: e.target.value})
    }
    updateStatus = () => {
        this._isMounted = true;
        if (this.state.statusComment === "") {
            this.setState({statusCommentError: true})
            toast.error("Please enter a comment")
            return
        }
        let orderStatus = this.selectStatus();
        this.setState({orderStatus: orderStatus})
        this.setButtonsBasedOnStatus(orderStatus)
        this.updateStatusService().then(res => {
            if (res && this._isMounted === true) {
                this.props.history.push({'state': orderStatus});
                toast.success(" Order status updated successfully.")
                this.setState({statusCommentError: false})
                window.location.reload();
            } else {
                toast.error("Something went wrong, order couldn't be updated")
            }
            this.setState({modal1: false})
        })
    }
    updateStatusService = async () => {
        const userData = await UserStore.fetchSessionData();
        this.setState({progressStatus: true})
        const res = await OrderService.updatePrescriptionOrderStatus(userData, this.state);
        this.setState({progressStatus: false})
        return res
    }
    validateComment = () => {
        if (this.state.statusComment.trim() === "") {
            this.setState({statusCommentError: true})
        } else {
            this.setState({statusCommentError: false})
        }
    }
    getTodayDateTime = () => {
        const moment = require('moment');
        const today = moment();
        let todayDateTime = []
        let string = {date: '', time: ''}
        string = {date: today.format("MMMM Do YYYY"), time: today.format("LT")}
        todayDateTime.push(string)
        return todayDateTime
    }
    toggle = nr => () => {
        let modalNumber = 'modal' + nr
        this.setState({
            [modalNumber]: !this.state[modalNumber]
        });
    }
    fireUpdateStatus = (status) => {
        let dateTime = this.getTodayDateTime();
        let ackNote = "";
        if (status === 'Acknowledge Order') {
            ackNote = "Order acknowledged on " + dateTime[0].date + " at " + dateTime[0].time + ".";
        } else if (status === 'Request Prescriptions') {
            ackNote = "Awaiting Prescriptions on " + dateTime[0].date + " at " + dateTime[0].time + ".";
        } else if (status === 'Send to Fulfilment') {
            ackNote = "Sent to Fulfilment on " + dateTime[0].date + " at " + dateTime[0].time + ".";
        } else if (status === 'Cancel Order') {
            ackNote = "Order has been cancelled on " + dateTime[0].date + " at " + dateTime[0].time + ".";
        }
        this.setState({statusComment: ackNote, modalHeader: status, modal1: true})
    }

    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.progressStatus}/>
                {this.state.progressStatus === true &&
                <span>Loading order details...</span>
                }
                {this.state.progressStatus === false &&
                <div>
                    <MDBRow>
                        <MDBCol size={"9"}><span><b>Order ID</b>: </span>
                            <span
                                className="text-success font-weight-bold">&nbsp;&nbsp;{this.state.orderDetails.orderCode}</span></MDBCol>
                    </MDBRow>
                    <p/>
                    <MDBRow>
                        <MDBCol size={"4"}>
                            <span><b>Customer Name</b>: </span>
                            <span
                                className="font-weight-bold">&nbsp;&nbsp;{this.state.customerDetails.name}</span><br/>
                            <span><b>Contact No</b>: </span>
                            <span
                                className="bg-dark text-light">&nbsp;&nbsp;{this.state.customerDetails.contactNo}&nbsp;&nbsp;</span><br/>
                            {this.state.customerDetails.email !== "" &&
                            <span><b>Email</b>: &nbsp;&nbsp;{this.state.customerDetails.email}<br/></span>
                            }
                            <span><b>Order Date</b>: </span>
                            <span>&nbsp;&nbsp;{Moments(this.state.orderDetails.createdAt).format("LLL")}</span><br/>
                            <p/>
                            <span><b>Order Status</b>: </span>
                            <span className={"deep-purple-text"}>&nbsp;&nbsp;<b>{this.state.orderStatus}</b></span><br/>
                        </MDBCol>
                        <MDBCol size={"4"}>
                            <span><b>Shipping Address</b>: </span><br/>
                            <span>{this.state.shippingAddress.address}
                                {this.state.shippingAddress.street &&
                                <span>,&nbsp;{this.state.shippingAddress.street}</span>}
                                {this.state.shippingAddress.area &&
                                <span>,&nbsp;{this.state.shippingAddress.area}</span>}
                            </span>
                            <br/>
                            <span>{this.state.shippingAddress.city}, {this.state.shippingAddress.state}</span><br/>
                            <span>{this.state.shippingAddress.pincode}, {this.state.shippingAddress.country}</span><br/>
                            <span>{this.state.shippingAddress.landmark &&
                            <span>{this.state.shippingAddress.landmark}</span>}</span>
                        </MDBCol>
                        <MDBCol size={"4"}><span><b>Billing Address</b>: </span><br/>
                            <span>{this.state.billingAddress.address}
                                {this.state.billingAddress.street &&
                                <span>,&nbsp;{this.state.billingAddress.street}</span>}
                                {this.state.billingAddress.area &&
                                <span>,&nbsp;{this.state.billingAddress.area}</span>}
                    </span>
                            <br/>
                            <span>{this.state.billingAddress.city}, {this.state.billingAddress.state}</span><br/>
                            <span>{this.state.billingAddress.pincode}, {this.state.billingAddress.country}</span><br/>
                            <span>{this.state.billingAddress.landmark &&
                            <span>{this.state.billingAddress.landmark}</span>}</span></MDBCol>
                    </MDBRow>
                    <br/>
                    <MDBRow>
                        <MDBCol size={"8"}>
                            <span><b>Document List</b>: </span><br/>
                            <ul className="list-group list-group-horizontal">
                                {this.state.docs.length === 0 &&
                                <div className={'no-data-container'}>No prescription found</div>
                                }
                                {this.state.docs.length > 0 &&
                                this.state.docs.map((row, index) => (
                                    <li key={index} className="list-group-item">
                                        <a target="_blank" rel="noopener noreferrer" href={config.IMAGE_HOST + row}
                                           alt={"prescription"}><i
                                            className={'fas fa-file-alt fa-3x'}></i><br/>File_{index + 1}</a><br/>
                                    </li>
                                ))}
                            </ul>
                        </MDBCol>
                        <MDBCol size={"3"}>
                            {this.state.selectedButtonSet.map(
                                (object, i) => <MDBBtn obj={object} key={i} color={"default"} size="md"
                                                       onClick={() => this.fireUpdateStatus(object.value)}
                                                       className="btn btn-lg btn-block mt-3"
                                                       disabled={this.state.progressStatus}>
                                    {object.value}
                                </MDBBtn>
                            )}
                        </MDBCol>
                        <MDBCol size={"1"}></MDBCol>
                    </MDBRow>
                </div>
                }
                <MDBContainer>
                    <MDBModal isOpen={this.state.modal1} toggle={this.toggle(1)}>
                        <MDBModalHeader toggle={this.toggle(1)}>{this.state.modalHeader}</MDBModalHeader>
                        <MDBModalBody>
                            <MDBInput autoFocus={true}
                                      className={this.state.statusCommentError === true ? "border-danger" : ""}
                                      label={<h6>Note:</h6>} value={this.state.statusComment}
                                      type={'textarea'} onChange={this.handleStatusComment}
                                      onBlur={this.validateComment}/>
                        </MDBModalBody>
                        <MDBModalFooter className={"border border-0"}>
                            <MDBBtn color={"default"} onClick={this.updateStatus}>Update</MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>
                </MDBContainer>
            </React.Fragment>
        )
    }
}

export default withRouter(AcknowledgeOrder);
