import React from "react";
import {Link, withRouter} from "react-router-dom";
import {
    MDBContainer, MDBRow, MDBInput, MDBBtn, MDBCol, MDBCard, MDBCardBody, MDBCardHeader, MDBFormInline, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter,
} from 'mdbreact';
import UserStore from "../../stores/userStore";
import {toast} from "react-toastify";
import PharmacyService from "../../services/pharmacyService";
import MomentUtils from "@date-io/moment";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import util from "../../utility/utility";
import SimpleBackdrop from "../common/overlay";
import orgConfig from "../../orgConfig";
import AuthService from "../../services/authService";



class UpdateCustomer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            customer:{},
            user:{},
            addresses:[],
            dependents:[],
            isActive:"",
            customerId:"",
            imageUrl:{},
            customerObj:{},
            customerApiResultCame:false,
            progressStatus:false,
            modalDelete:false,
            modalDelDependent:false,
            name:"",
            nameError:false,
            contactNoError:false,
            email:"",
            contactNo:"",
            userNameError:false,
            passwordError:false,
            passwordType:"password",
            dob:new Date(),
            gender:"MALE",
            orgId:0,
            numId:0,
            userDate:{},
            modalDependent: false,
            modal:false,
            modalDeleteAddress:false,
            selectedAddress:{},
            selectedDependent:{},
            initEditAddress:false,
        }
    }
    async componentDidMount() {
        let customerId = ""
        if(this.props.hasOwnProperty("queryString")){
            customerId = this.props.queryString
        }

        if(this.props.hasOwnProperty("customerId")){
            customerId = this.props.customerId
        }


        this.setState({customerId:customerId})

        let userData = await UserStore.fetchSessionData();
        this.setState({userData: userData})
        if(userData.type === "platformAdmin"){
            this.setState({orgId: userData._id})
            this.setState({numId: userData.numId})
        } else if(userData.type === "ORG_ADMIN"){
            this.setState({orgId: userData.orgDetails[0]._id})
            this.setState({numId: userData.orgDetails[0].numId})
        }

        this.fetchCustomerById()
    }
    fetchCustomerById = async () => {
        let userData=await UserStore.fetchSessionData();
        this.setState({progressStatus:true});
        let res = await PharmacyService.fetchCustomerById(this.state, userData);
        console.log(res);
        if(res){
            try{
                this.setState({user:res})
                this.setState({customerApiResultCame:true})
                this.setState({progressStatus:false});
                this.setState({name: res.name, contactNo: res.contactNo, email:res.email, dob:res.dob, gender:res.gender})
                if(!res.dob){
                    this.setState({dob:null})
                }

            } catch (e) {
                toast.error("Something went wrong while fetching customer by id.")
            }
        }
    }


    updateCustomer =async () => {
        let userData=await UserStore.fetchSessionData();
        // Reset all the error first
        this.setState({"customerError": false})
        //validations
        this.setState({progressStatus:true});
        let status=await PharmacyService.updateCustomer(this.state,userData);
        this.setState({progressStatus:false});
        if(status===false){
            toast.error("Something went wrong.");
            return true;
        }else{
            toast.success("Customer updated successfully.");
            this.props.editCustomerCompleted()
        }
    }
    handleStatusChange  = () => {
        let status = (this.state.isActive === "Y") ? "N" : "Y";
        this.setState({
            isActive: status
        });
    }

    handleChangeContactNo=(e)=>{
        if(!util.isInt(e.target.value) && e.target.value !== ""){
            return false;
        }
        this.setState({contactNo:e.target.value})
    };
    handleChangeEmail=(e)=>{
        this.setState({email:e.target.value})
    };
    handleName=(e)=>{
        this.setState({name:e.target.value})
    };
    handleDOB=(e)=>{
        this.setState({dob:e})
    };
    validateName = () => {
        if(!this.state.name || this.state.name.trim() === ""){
            this.setState({nameError: true})
        } else {
            this.setState({nameError: false})
        }
    }
    changeGender = (val) => {
        this.setState({"gender":val});
    };
    validateEmail = () => {
        if(this.state.email && !util.validateEmail(this.state.email)){
            this.setState({"loadingEmail": true, "emailError": true})
        } else {
            this.setState({"loadingEmail": false, "emailError": false})
        }
    }
    validatePhone= () => {
        if(!util.checkPhone(this.state.contactNo)){
            this.setState({ "contactNoError": false})
        } else {
            this.setState({"contactNoError": false})
        }
    }

    intEdit = (row) => {
        console.log("Addressid", row.addressId);
        this.setState({
            'modalAddress': !this.state.modalAddress
        });
        this.setState({"activeAddress": row});
        console.log(this.state)
    }


    initDelete = () => {
        this.toggleDelete()
    }
    confirmDelete = async () => {
        let userData=await UserStore.fetchSessionData();
        this.setState({progressStatus:true});
        let status=await AuthService.deleteCustomer(this.state,userData);
        this.setState({progressStatus:false});
        if(status===false){
            toast.error("Something went wrong.");
            return true;
        }else{
            toast.success("Customer deleted successfully.");
            this.props.deleteCustomerCompleted()
        }
    }
    toggleDelete = () => {
        this.setState({
            modalDelete: !this.state.modalDelete
        });
    }


    render() {
        return (
            <React.Fragment>
                {/*<MDBBreadcrumb>*/}
                {/*    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>*/}
                {/*    <MDBBreadcrumbItem ><Link to={'/view-customers'}>Customers</Link></MDBBreadcrumbItem>*/}
                {/*    <MDBBreadcrumbItem active>Customer</MDBBreadcrumbItem>*/}
                {/*</MDBBreadcrumb>*/}
                <SimpleBackdrop status={(this.state.progressStatus === true || this.state.updateProgress === true)?true:false}/>
                <MDBContainer>
                    <MDBCard className={"mt-2"}>
                        <MDBCardHeader>
                            <span className={"float-left"}>Customer</span>
                            <MDBBtn outline color="blue-grey" size="sm" onClick={this.updateCustomer} className="mr-auto float-right"
                                disabled={this.state.customerError || this.state.progressStatus || this.state.progressStatusFileUpload}>
                                    Save
                                </MDBBtn>

                            <MDBBtn outline color="blue-grey" size="sm" onClick={this.initDelete} className="mr-auto float-right"
                                    disabled={this.state.progressStatus}>
                                Delete
                            </MDBBtn>

                        </MDBCardHeader>
                        <MDBCardBody>
                            <MDBRow>
                                <MDBCol>
                                    <MDBInput autoComplete={'off'} style={{"marginTop": "0px"}} onBlur={this.validateName}
                                              className={this.state.nameError ? "border-danger" : "mt-0 mb-0"}
                                              maxLength="100" type='text' label="Name *"
                                              onChange={this.handleName}
                                              value={this.state.name}/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBInput disabled={true}//onBlur={() => this.validatePhone()}
                                              autoComplete={'off'}
                                              className={this.state.contactNoError ? "border-danger" : ""}
                                              maxLength={orgConfig.PHONE_NUMBER_LENGTH} type='text' label="Phone *"
                                              // onChange={this.handleChangeContactNo}
                                              value={this.state.contactNo}/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBInput onBlur={() => this.validateEmail()}
                                              autoComplete={'off'}
                                              className={this.state.emailError ? "border-danger" : ""}
                                              maxLength="50" type='text' label="E-mail"
                                              onChange={this.handleChangeEmail}
                                              value={this.state.email}/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBFormInline className={"mb-4"}>
                                        <MDBInput onClick={()=>this.changeGender("Male")} checked={this.state.gender === "Male" ? true : false}
                                                  label='Male' type='radio' id='radio1' containerClass='mr-5'/>
                                        <MDBInput onClick={()=>this.changeGender("Female")} checked={this.state.gender === "Female" ? true : false}
                                                  label='Female' type='radio' id='radio2' containerClass='mr-5'/>
                                        <MDBInput onClick={()=>this.changeGender("Other")} checked={this.state.gender === "Other" ? true : false}
                                                  label='Other' type='radio' id='radio3' containerClass='mr-5'/>
                                    </MDBFormInline>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker style={{"marginTop":"5px","marginLeft":"1px", "marginRight":"1px"}}
                                                            format="MMM D, YYYY"
                                                            margin="normal"
                                                            id="receiving_date"
                                                            label="Date of birth"
                                                            value={this.state.dob}
                                                            onChange={this.handleDOB}
                                                            KeyboardButtonProps={{
                                                                "aria-label": "change date",
                                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>

                    <MDBModal isOpen={this.state.modalDelete} toggle={this.toggleDelete}>
                        <MDBModalHeader toggle={this.toggleDelete}>Delete Customer</MDBModalHeader>
                        <MDBModalBody>
                            Are you sure you want to delete this customer?
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn disabled={this.state.progressStatus} color="primary" onClick={this.confirmDelete}>Delete</MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>
                </MDBContainer>
            </React.Fragment>
        );
    }
}
export default withRouter(UpdateCustomer)
