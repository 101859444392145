import React, { useEffect, useRef, useState } from "react";
import {
  Document,
  Page,
  PDFViewer,
  Image,
  StyleSheet,
  Text,
  View,
  Font,
} from "@react-pdf/renderer";
import Barcode from "react-barcode";
import utility from "../../utility/utility";
import positraLogo from "../../images/positrarx_logo.png";
import ecommLogo from "../../images/ecom_logo.png";
import robotoRegular from "../../fonts/Roboto/Roboto-Regular.ttf";
import robotoBold from "../../fonts/Roboto/Roboto-Bold.ttf";
import Moments from "moment";
import delhiveryLogo from "../../images/delhivery_logo.png";
import bluedartLogo from "../../images/blue_dart.png";
import xpressLogo from "../../images/expressBees.png";
import dtdcLogo from "../../images/dtdc_logo.png";
import indianpostLogo from "../../images/India_Post_Logo.png";
import ekartLogo from "../../images/ekart_logo.png";

Font.register({
  family: "Roboto",
  fonts: [
    { src: robotoRegular, fontWeight: "normal" },
    { src: robotoBold, fontWeight: "bold" },
  ],
});

const styles = StyleSheet.create({
  pdfViewer: {
    width: "100%",
    height: "625px",
  },
  page: {
    padding: "10px",
  },
  topHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "16px",
  },
  positraLogo: {
    width: "80px",
    objectFit: "contain",
  },
  deliveryPartnerLogo: {
    width: "40px",
    objectFit: "contain",
  },
  section: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "5px",
  },
  sectionWithBorder: {
    marginBottom: "5px",
    border: 1,
    padding: 3,
  },
  qrCode: {
    width: "80px",
    flex: 1,
    marginTop: 4,
  },
  normalText: {
    fontFamily: "Roboto",
    fontWeight: "normal",
    fontSize: 10,
    marginVertical: 2,
  },
  bolderText: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 10,
    marginVertical: 2,
  },
  firstSectionText: {
    border: 1,
    flex: 2,
    padding: 4,
  },
});

const DownloadLabel = ({ data }) => {
  const [barcodeImage, setBarcodeImage] = useState("");
  const barcodeRef = useRef(null);

  function handlePngDataUrl(pngDataUrl) {
    setBarcodeImage(pngDataUrl);
  }

  useEffect(() => {
    if (barcodeRef.current && data?.trackingId) {
      const svgElement = barcodeRef.current.refs.renderElement;
      if (svgElement) {
        const svgData = new XMLSerializer().serializeToString(svgElement);
        const base64 = btoa(svgData);
        const dataUrl = `data:image/svg+xml;base64,${base64}`;
        utility.convertSvgToPng(dataUrl, handlePngDataUrl);
      }
    }
  }, []);

  return (
    <>
      <div style={{ display: "none" }}>
        {data?.trackingId && (
          <Barcode value={data?.trackingId} ref={barcodeRef} />
        )}
      </div>
      {barcodeImage && (
        <PDFViewer style={styles.pdfViewer}>
          <Document>
            <Page wrap size="A6" style={styles.page}>
              <View style={styles.topHeader}>
                <Image
                  source={positraLogo}
                  style={styles.positraLogo}
                  resizeMode="contain"
                />
                {data?.carrierName === "Delhivery" && (
                  <Image
                    source={delhiveryLogo}
                    style={styles.deliveryPartnerLogo}
                    resizeMode="contain"
                  />
                )}
                {data?.carrierName === "Bluedart" && (
                  <Image
                    source={bluedartLogo}
                    style={styles.deliveryPartnerLogo}
                    resizeMode="contain"
                  />
                )}
                {data?.carrierName === "Ecom Express" && (
                  <Image
                    source={ecommLogo}
                    style={styles.deliveryPartnerLogo}
                    resizeMode="contain"
                  />
                )}
                {data?.carrierName === "Xpressbees" && (
                  <Image
                    source={xpressLogo}
                    style={styles.deliveryPartnerLogo}
                    resizeMode="contain"
                  />
                )}
                {data?.carrierName === "DTDC" && (
                  <Image
                    source={dtdcLogo}
                    style={styles.deliveryPartnerLogo}
                    resizeMode="contain"
                  />
                )}
                {data?.carrierName === "Indian Post" && (
                  <Image
                    source={indianpostLogo}
                    style={styles.deliveryPartnerLogo}
                    resizeMode="contain"
                  />
                )}
                {data?.carrierName === "Ekart" && (
                  <Image
                    source={ekartLogo}
                    style={styles.deliveryPartnerLogo}
                    resizeMode="contain"
                  />
                )}
              </View>
              <View style={styles.section}>
                <View style={styles.firstSectionText}>
                  {/* <Text style={styles.bolderText}>PREPAID (PPD)</Text> */}
                  {(data?.paymentMode === "COD" ||
                    data?.paymentMode === "Pay On Delivery") && (
                    <>
                      <Text style={styles.bolderText}>
                        CASH ON DELIVERY (COD)
                      </Text>
                      <Text style={styles.normalText}>
                        Declared Value: ₹ {data?.totalAmount?.toFixed(2)}
                      </Text>
                      <Text style={styles.normalText}>
                        Collectable Amount: ₹ {data?.totalAmount?.toFixed(2)}
                      </Text>
                    </>
                  )}
                  {(data?.paymentMode === "PREPAID" ||
                    data?.paymentMode === "PREPAY") && (
                    <>
                      <Text style={styles.bolderText}>PREPAID (PPD)</Text>
                      <Text style={styles.normalText}>
                        Declared Value: ₹ {data?.totalAmount?.toFixed(2)}
                      </Text>
                      <Text style={styles.normalText}>
                        Collectable Amount: ₹ 0
                      </Text>
                    </>
                  )}
                </View>
                <Image source={barcodeImage} style={styles.qrCode} />
              </View>
              <View style={styles.sectionWithBorder}>
                <Text style={styles.normalText}>Ship To,</Text>
                <Text style={styles.bolderText}>
                  {data?.shippingAddress?.name && data?.shippingAddress?.name}
                </Text>
                <Text style={styles.normalText}>{data?.shippingAddress?.addressLine1}, {data?.shippingAddress?.addressLine2}</Text>
                <Text
                  style={styles.normalText}
                >{data?.shippingAddress?.city}, {data?.shippingAddress?.pinCode} - {data?.shippingAddress?.state}</Text>
                <Text style={styles.normalText}>
                  Phone Number:{" "}
                  {data?.shippingAddress?.phone}
                </Text>
              </View>
              <View style={styles.sectionWithBorder}>
                <Text style={styles.normalText}>
                  <Text style={styles.bolderText}>Order ID: </Text>
                  {data?.orderCode}
                </Text>
                <Text style={styles.normalText}>
                  <Text style={styles.bolderText}>Order Date: </Text>
                  {Moments(data?.createdAt).format("lll")}
                </Text>
                <Text style={styles.normalText}>
                  <Text style={styles.bolderText}>Invoice Number: </Text>
                  {data?.invoiceId}
                </Text>
              </View>
              <View style={styles.sectionWithBorder}>
                <Text style={styles.normalText}>
                  If undelivered, please return it to,
                </Text>
                <Text style={styles.normalText}>
                  Positra 1st floor, Lakhimandir Market Complex, Beltola
                </Text>
                <Text style={styles.normalText}>
                  Guwahati, Assam - 781028, Phone: 6900702871
                </Text>
              </View>
              <View style={styles.sectionWithBorder}>
                <Text style={styles.bolderText}>
                  This contains medicine, please deliver as soon as possible.
                </Text>
                <Text style={styles.normalText}>Thank you from PositraRx</Text>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      )}
    </>
  );
};

export default DownloadLabel;
