import React,{Suspense} from "react";
import {Link} from "react-router-dom";
import {
    MDBBreadcrumb, MDBBreadcrumbItem, MDBRow
} from 'mdbreact';
//const EditQuantityUnit=React.lazy(() => import("../../components/quantity-unit/editQuantityUnit"));
import EditQuantityUnit from "../../components/quantity-unit/editQuantityUnit";

export default class EditQuantityUnitPage extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem><Link to={'/view-quantity-units'}>Product Quantity Units</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Edit Quantity Units</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <MDBRow className={"min-vh-100"}>
                    <Suspense fallback={null}>
                        <EditQuantityUnit queryString={this.props.match.params}/>
                    </Suspense>
                </MDBRow>
            </React.Fragment>
        );
    }
}


