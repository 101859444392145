import React from "react";
import { MDBBtn,MDBCard } from "mdbreact";

function PaggingFunc({currentPage,totalRecords,pageSize,handlePageChange}) {
  return (
    <MDBCard className="p-2">
      <div className="d-flex justify-content-center mt-3">
        <div className="d-flex" style={{ marginBottom: "10px" }}>
          <MDBBtn
            size={"md"}
            color="default"
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
            className="ml-5 "
          >
            &lt;
          </MDBBtn>

          <div className="ml-2 mr-2 mt-2">
            Page {currentPage} of{" "}
            {Math.ceil(totalRecords / pageSize)}
          </div>

          <MDBBtn
            size={"md"}
            color="default"
            disabled={
              currentPage ===
              Math.ceil(totalRecords / pageSize)
            }
            onClick={() => handlePageChange(currentPage + 1)}
            className="ml-2"
          >
            &gt;
          </MDBBtn>
        </div>
      </div>
    </MDBCard>
  );
}

export default PaggingFunc;
