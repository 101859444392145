import React,{Suspense} from "react";
import {
    MDBBox, MDBBreadcrumb, MDBBreadcrumbItem
} from 'mdbreact';
import {Link} from "react-router-dom";
//const FulfilOrder=React.lazy(() => import("../../components/fulfill-order/newOrder"));
import FulfilOrder from "../../components/fulfill-order/newOrder";

export default class FulFillOrder extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Fulfill Order</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <MDBBox className={"min-vh-100 pt-2 pl-1 pr-1 "}>
                    <Suspense fallback={null}>
                        <FulfilOrder/>
                    </Suspense>
                </MDBBox>
            </React.Fragment>
        );
    }
}
