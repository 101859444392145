import React from "react";
import {MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBContainer, MDBFormInline, MDBInput, MDBSelect} from "mdbreact";
import {toast} from "react-toastify";
import UserStore from "../../stores/userStore";
import MomentUtils from "@date-io/moment";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {withRouter} from "react-router-dom";
import AuthService from "../../services/authService";
import SimpleBackdrop from "../common/overlay";
import OrderService from "../../services/orderService";

class RazorpayTools extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            progressStatus: false,
            taskDescription: "",
            userData: {},
            taskTitle: "",
            orderCode:"",
            paymentreference:"",
            amount:"",
            order:{}
        }
    }

    async componentDidMount() {
        let userData = await UserStore.fetchSessionData();
        this.setState({userData})
    }

    addrazorpay = async () => {
        if (this.state.orderCode.trim() === "") {
            toast.error("Please enter order code.")
            return false
        }
        if (this.state.paymentreference.trim() === "") {
            toast.error("Please enter payment reference.")
            return false
        }
        if (this.state.amount.trim() === "") {
            toast.error("Please enter amount.")
            return false
        }
        let payload = {
            orderCode: this.state.orderCode,
            paymentreference: this.state.paymentreference,
            amount: this.state.amount
        }
        this.setState({progressStatus:true})
        let res = await OrderService.addRazorpayDetails(payload, this.state.userData.sessionToken)
        if (res) {
            toast.success("Razorpay refrenece added.")
        } else {
            toast.error("Something went wrong.")
        }
        this.setState({progressStatus:false})
    }


    viewOrderFromOrderCode = async () => {
        if (this.state.orderCode.trim() === "") {
            toast.error("Please enter order code.")
            return false
        }
        let res = await OrderService.viewOrderFromOrderCode(this.state.orderCode)
        console.log(res)
        this.setState({order:res})
    }

    render() {
        return (
            <React.Fragment>
                {(this.state.progressStatus === true) &&
                <SimpleBackdrop status={this.state.progressStatus}/>
                }
                <MDBContainer className={"my-3"}>
                    <MDBCard>
                        <MDBCardHeader>
                            <div className={"float-left title"}>Razorpay Id mapping.</div>
                        </MDBCardHeader>
                        <MDBCardBody>
                            <div className={"col-12 row m-0 p-0"}>
                                <div className={"col-md-4"}>
                                    <div className={"row"}>
                                        <div className={"col"}>
                                            <MDBInput type="text" label="order Code" outline={true}

                                                      value={this.state.orderCode} onChange={(e) => {
                                                this.setState({orderCode: e.target.value})
                                            }} className={'form-control'}/>
                                            <MDBBtn onClick={this.viewOrderFromOrderCode}>Fetch</MDBBtn>
                                        </div>
                                    </div>

                                    {(this.state.order.hasOwnProperty("orderCode") && !this.state.order.hasOwnProperty("razorpayPaymentDetails")) &&
                                    <div>
                                        <div className={"row"}>
                                            <div className={"col"}>
                                                <MDBInput type="text" label="Razorpay Id" outline={true}
                                                          value={this.state.paymentreference} onChange={(e) => {
                                                    this.setState({paymentreference: e.target.value})
                                                }} className={'form-control'}/>
                                            </div>
                                        </div>
                                        <div className={"row"}>
                                            <div className={"col"}>
                                                <MDBInput type="text" label="Amount" outline={true}
                                                          value={this.state.amount} onChange={(e) => {
                                                    this.setState({amount: e.target.value})
                                                }} className={'form-control'}/>
                                            </div>
                                        </div>

                                        <div className={"row"}>
                                            <div className={"col"}>
                                                <MDBBtn size="md" onClick={this.addrazorpay}
                                                        className="mr-auto"
                                                        disabled={this.state.progressStatus === true}>
                                                    Link Razorpay 
                                                </MDBBtn>
                                            </div>
                                        </div>
                                    </div>
                                    }




                                </div>
                                {this.state.order.hasOwnProperty("orderCode") &&
                                <div className={"col-md-4"}>
                                    <div className={"row"}>
                                        <div className={"col"}>
                                            <h4>Order Details</h4>
                                        </div>
                                    </div>
                                    <div className={"row"}>
                                        <div className={"col"}>
                                            Amount: {this.state.order.amount}
                                        </div>
                                    </div>

                                    <div className={"row"}>
                                        <div className={"col"}>
                                            Total Amount: {this.state.order.totalAmount}
                                        </div>
                                    </div>
                                    <div className={"row"}>
                                        <div className={"col"}>
                                            Shipping Amount: {this.state.order.shippingAmount}
                                        </div>
                                    </div>

                                    <div className={"row"}>
                                        <div className={"col"}>
                                            Balance Amount: {this.state.order.balanceAmount}
                                        </div>
                                    </div>


                                    {this.state.order.hasOwnProperty("isPaid") &&
                                    <div className={"row"}>
                                        <div className={"col"}>
                                            isPaid?: {this.state.order.isPaid}
                                        </div>
                                    </div>
                                    }
                                    {this.state.order.hasOwnProperty("razorpayPaymentDetails") &&
                                    <div className={"row"}>
                                        <div className={"col"}>
                                            isPaymentVerified: {this.state.order.isPaymentVerified}
                                        </div>
                                    </div>
                                    }

                                    {this.state.order.hasOwnProperty("status") &&
                                    <div className={"row"}>
                                        <div className={"col"}>
                                            Status: {this.state.order.status}
                                        </div>
                                    </div>
                                    }


                                </div>
                                }
                                {this.state.order.hasOwnProperty("orderCode") &&
                                <div className={"col-md-4"}>
                                    <div className={"row"}>
                                        <div className={"col"}>
                                            <h4>Razorpay Details</h4>
                                        </div>
                                    </div>
                                    <div className={"row"}>
                                        <div className={"col"}>
                                            Payment mode: {this.state.order.paymentMode}
                                        </div>


                                    </div>
                                    {!this.state.order.hasOwnProperty("razorpayPaymentDetails") &&
                                    <div className={"row"}>
                                        <div className={"col"}>
                                            Razor Pay Id: Not Found
                                        </div>
                                    </div>
                                    }
                                    {this.state.order.hasOwnProperty("razorpayPaymentDetails") &&
                                    <div className={"row"}>
                                        <div className={"col"}>
                                            Razor Pay Id: {this.state.order.razorpayPaymentDetails.id}
                                        </div>
                                    </div>
                                    }
                                    {this.state.order.hasOwnProperty("razorpayPaymentDetails") &&
                                    <div className={"row"}>
                                        <div className={"col"}>
                                            Razor Pay Amount: {this.state.order.razorpayPaymentDetails.amount / 100}
                                        </div>
                                    </div>
                                    }


                                </div>
                                }
                            </div>
                        </MDBCardBody>

                    </MDBCard>
                </MDBContainer>
            </React.Fragment>
        )
    }
}

export default withRouter(RazorpayTools);