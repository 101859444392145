import React from "react";
import {withRouter} from "react-router-dom";
import {
    MDBBtn,
    MDBBox,
    MDBSpinner,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBContainer,
    MDBModal,
    MDBModalHeader,
    MDBModalBody,
    MDBModalFooter,
    MDBInput
} from 'mdbreact';
import UserStore from "../../../stores/userStore";
import {toast} from "react-toastify";
import PharmacyService from "../../../services/pharmacyService";
import Moments from "moment";
var Barcode = require('react-barcode');

class StockSearchV2 extends React.Component {
    async componentDidMount() {
        alert(1);
    }

    render() {
        return (
            <React.Fragment>
                dfgdg
            </React.Fragment>
        )
    }
}
export default withRouter(StockSearchV2);