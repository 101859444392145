import React from "react";
import {Link, withRouter} from "react-router-dom";
import MomentUtils from '@date-io/moment';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
    MDBContainer, MDBRow, MDBInput, MDBFormInline, MDBBtn, MDBCol, MDBSelect, MDBCard, MDBCardBody, MDBCardHeader,
    MDBBreadcrumb, MDBBreadcrumbItem, MDBModalHeader, MDBModalBody, MDBModal
} from 'mdbreact';
import UserStore from "../../stores/userStore";
import {toast} from "react-toastify";
import PharmacyService from "../../services/pharmacyService";

import util from "../../utility/utility";
import SimpleBackdrop from "../common/overlay";
import AddSupplier from "../../components/supplier/newSupplier";

import MultipleDocsUpload from "../common/fileUpload/MultipleDocsUpload";

class AddShipment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalSupplier:false,
            userData:{},
            supplier:{},
            user:{},
            amount:"",
            receivedBy:"",
            receivingDate:new Date(),
            receivingTime:new Date(),
            invoiceDate:new Date(),
            invoiceNumber:"",
            supplyPlace:"",
            reverseCharge:"",
            totalTaxAmount:"",
            cessTaxAmount:"",
            paymentReference:"",
            paymentAmount:"",
            paymentDate:null,
            discountAmount:"",
            comments:"",
            documentUrl:{},
            docs:[],
            isActive:"",
            progressStatus:false,
            progressStatusFileUpload:false,
            selectedDate:new Date(),
            suggestedSupplier:[],
            allSuggestedUser:[],
            suggestedUser:[],
            orgId:0,
            supplierError:false,
            receivedByError:false,
            invoiceTypeError:false,
            invoiceNumberError:false,
            amountError:false,
            totalTaxAmountError:false,
            totalCessTaxAmountError:false,
            discountAmountError:false,
            invoiceDateError:false,

            receivingDateError:false,
            supplyPlaceError:false,
            reverseChargeError:false,
            paidAmountError:false,
            paymentDateError:false,
            paymentModeError:false,
            invoiceType:"GST Invoice",
            paymentMode:"",
            resetForm:false,
            invoiceTypes: [ {
                    text: "GST Invoice",
                    value: "GST Invoice"
                }, {
                    text: "Retail Invoice",
                    value: "Retail Invoice",

                }],
            randomKey:"",
        }
    }
    handleComments=(e)=>{
        this.setState({comments:e.target.value})
    };
    handleReceivingDate=(date)=>{
        this.setState({receivingDate:date})
    };
    handleReceivingTime=(e)=>{
        this.setState({receivingTime:e});
    };
    handleInvoiceDate = (date) => {
        this.setState({invoiceDate:date})
    };
    handleInvoiceNumber=(e)=>{
        this.setState({invoiceNumber:e.target.value})
    };
    handleSupplyPlace=(e)=>{
        this.setState({supplyPlace:e.target.value})
    };
    handleReverseCharge=(e)=>{
        if(!util.isNumericTwoDecimalPartial(e.target.value) && e.target.value !== ""){
            return false;
        }
        this.setState({reverseCharge:e.target.value})
    };
    handleTotalTaxAmount=(e)=>{
        if(!util.isNumericTwoDecimalPartial(e.target.value) && e.target.value !== ""){
            return false;
        }
        this.setState({totalTaxAmount:e.target.value})
    };
    handleTotalCessTaxAmount=(e)=>{
        if(!util.isNumericTwoDecimalPartial(e.target.value) && e.target.value !== ""){
            return false;
        }
        this.setState({cessTaxAmount:e.target.value})
    };
    handlePaymentReference=(e)=>{
        this.setState({paymentReference:e.target.value})
    };
    handleDiscountAmount=(e)=>{
        if(!util.isNumericTwoDecimalPartial(e.target.value) && e.target.value !== ""){
            return false;
        }
        this.setState({discountAmount:e.target.value})
    };
    handleAmount=(e)=>{
        if(!util.isNumericTwoDecimalPartial(e.target.value) && e.target.value !== ""){
            return false;
        }
        this.setState({amount:e.target.value})
    };
    handleInvoiceType = (e) => {
        if (e.length > 0) {
            this.setState({invoiceType:e[0]});
        }
    };
    fetchSupplier = async (e) => {
        try {
            if (e.target.value !== 0 || e.target.value !== '0') {
                let suggestedSupplier = await PharmacyService.searchSupplier(e.target.value, this.state);
                this.setState({allSuggestedData: suggestedSupplier});
                let temp = [];
                for (let item of suggestedSupplier) {
                    let str = {title: item.supplierName, value: item};
                    temp.push(str);
                }
                if(temp.length>0) {
                    this.setState({suggestedSupplier: temp});
                }else{
                    this.setState({suggestedSupplier: [{title: "", value: ''}]});
                }
            }
        }catch (e) {
            this.setState({suggestedSupplier: [{title: "", value: ''}]});
        }
    };
    fetchUser = async (e) => {
        try {
            if (e.target.value !== 0 || e.target.value !== '0') {
                let suggestedUser = await PharmacyService.searchUser(e.target.value, this.state);
                this.setState({allSuggestedUser: suggestedUser});
                let temp = [];
                for (let item of suggestedUser) {
                    let str = {title: item.name, value: item};
                    temp.push(str);
                }
                if(temp.length>0) {
                    this.setState({suggestedUser: temp});
                }else{
                    this.setState({suggestedUser: [{title: "", value: ''}]});
                }
            }
        }catch (e) {
            this.setState({suggestedUser: [{title: "", value: ''}]});
        }
    };
    async componentDidMount() {
        let userData = await UserStore.fetchSessionData();
        this.setState({userData: userData})
        if(userData.type === "platformAdmin"){
            this.setState({orgId: userData._id})
        } else if(userData.type === "ORG_ADMIN"){
            this.setState({orgId: userData.orgDetails[0]._id})
        }
    }
    onChangeSupplier = (event, values) => {
        if(values.value!==null) {
            this.setState({
                supplier: values.value
            }, () => {
                this.setState({supplierError:false})

            });
        }
    }
    onChangeUser = (event, values) => {
        if(values.value!==null) {
            this.setState({
                user: values.value
            }, () => {
                this.setState({receivedByError:false})
            });
        }
    }

    fileUploadCallback = (files) => {
        this.setState({ "documentUrl": files, "progressStatusFileUpload":false})
    }

    initUploadCallback = () => {
        this.setState({"progressStatusFileUpload":true})
    }
    initDeleteDoc = () => {
        this.toggleDelImage();
    }

    //
    // fileUploadCallback = (uploadedFileObject) => {
    //     this.setState({ "documentUrl": uploadedFileObject.path, "progressStatusFileUpload":false})
    // }
    // initUploadCallback = () => {
    //     this.setState({"progressStatusFileUpload":true, resetForm:false})
    // }
    validateSupplier = () => {
        if(!this.state.supplier.hasOwnProperty("_id")){
            this.setState({"supplierError": true})
        } else {
            this.setState({"supplierError": false})
        }
    }
    validateReceivedBy = () => {
        if(!this.state.user.hasOwnProperty("_id")){
            this.setState({"receivedByError": true})
        } else {
            this.setState({"receivedByError": false})
        }
    }
    validateInvoiceNumber = () => {
        if(!this.state.invoiceNumber || !this.state.invoiceNumber.trim()){
            this.setState({"invoiceNumberError": true})
        } else {
            this.setState({"invoiceNumberError": false})
        }
    }
    validateAmount = () => {
        if(!this.state.amount || !util.isNumericTwoDecimalFull(this.state.amount)){
            this.setState({"amountError": true})
        } else {
            this.setState({"amountError": false})
        }
    }
    validateTotalTaxAmount = () => {
        if(this.state.totalTaxAmount && !util.isNumericTwoDecimalFull(this.state.totalTaxAmount)){
            this.setState({"totalTaxAmountError": true})
        } else {
            this.setState({"totalTaxAmountError": false})
        }
    }
    validateTotalCessTaxAmount = () => {
        if(this.state.cessTaxAmount && !util.isNumericTwoDecimalFull(this.state.cessTaxAmount)){
            this.setState({"totalCessTaxAmountError": true})
        } else {
            this.setState({"totalCessTaxAmountError": false})
        }
    }
    validateDiscountAmount = () => {
        if(this.state.discountAmount && !util.isNumericTwoDecimalFull(this.state.discountAmount)){
            this.setState({"discountAmountError": true})
        } else {
            this.setState({"discountAmountError": false})
        }
    }

    resetField = () =>{
        this.setState({
            supplier:{},
            user:{},
            amount:"",
            receivedBy:"",
            receivingDate:new Date(),
            receivingTime:new Date(),
            invoiceDate:new Date(),
            invoiceNumber:"",
            supplyPlace:"",
            reverseCharge:"",
            totalTaxAmount:"",
            cessTaxAmount:"",
            paymentReference:"",
            paymentAmount:"",
            paymentDate:new Date(),
            discountAmount:"",
            comments:"",
            documentUrl:{},
            paymentMode:"",
            invoiceType:"",
        })
    }
    resetError = () => {
        this.setState({
            supplierError:false,
            receivedByError:false,
            invoiceTypeError:false,
            invoiceNumberError:false,
            amountError:false,
            totalTaxAmountError:false,
            totalCessTaxAmountError:false,
            discountAmountError:false,
            invoiceDateError:false,
            receivingDateError:false,
            supplyPlaceError:false,
            reverseChargeError:false,
            paymentDateError:false,
            paymentModeError:false,
        })
    }
    addShipment =async () => {
        let userData=await UserStore.fetchSessionData();
        // Reset all the error first
        this.resetError()
        //validations
        this.validateSupplier();
        this.validateReceivedBy();
        this.validateInvoiceNumber();
        this.validateAmount();
        this.validateTotalTaxAmount();
        this.validateTotalCessTaxAmount();
        this.validateDiscountAmount();

        if(this.state.progressStatus || this.state.progressStatusFileUpload || this.state.supplierError || this.state.receivedByError || this.state.invoiceNumberError || this.state.amountError ||  this.state.totalTaxAmountError || this.state. totalCessTaxAmountError || this.state.discountAmountError){
            return false;
        }
        this.setState({progressStatus:true});
        let status=await PharmacyService.createShipment(this.state,userData);
        this.setState({progressStatus:false});
        if(status===false){
            toast.error("Something went wrong.");
            return true;
        }else{
            this.resetError();
            this.resetField();
            this.resetKey();
            toast.success("Shipment added successfully.");
            this.setState({resetForm:true})
        }
    }
    toggleAddSupplier = () => {
        this.setState({"modalSupplier": !this.state.modalSupplier})
    }
    supplierAdded = (res) => {
        console.log(res)
    }

    resetKey() {
        let randomString = Math.random().toString(36);
        this.setState({
            randomKey: randomString
        });
    }
    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.progressStatus}/>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem ><Link to={'/view-incoming-shipments'}>Incoming Shipment</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Add Incoming Shipment</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <MDBContainer>
                    <MDBCard>
                        <MDBCardHeader>
                            <div className={"float-left title"}>Add Incoming Shipment</div>
                        </MDBCardHeader>
                        <MDBCardBody>
                            <MDBRow className={'my-2'}>
                                <div className={'col-md'}>{this.state.supplierError}
                                    <Autocomplete key={"supplier"+this.state.randomKey}
                                        freeSolo
                                        onChange={this.onChangeSupplier}
                                        id="combo-box-demo"
                                        options={this.state.suggestedSupplier}
                                        getOptionLabel={option => option.title}
                                        onInputChange={(e) => {
                                            this.fetchSupplier(e)
                                        }}
                                        selectOnFocus={true}
                                        renderInput={params => (
                                            <TextField style={ this.state.supplierError ?  {"borderBottom":"1px solid RED"} : {}} {...params} placeholder="Search supplier"
                                                       margin="normal" fullWidth/>
                                        )}
                                    />
                                    <div className={"text-right link-text"}>
                                        <a onClick={this.toggleAddSupplier}>Add Supplier</a>
                                    </div>
                                </div>
                                <div className={'col-md'}>
                                    <Autocomplete key={"user"+this.state.randomKey}
                                        freeSolo
                                        onChange={this.onChangeUser}
                                        id="combo-box-demo"
                                        options={this.state.suggestedUser}
                                        getOptionLabel={option => option.title}
                                        className="header-search"
                                        onInputChange={(e) => {
                                            this.fetchUser(e)
                                        }}
                                        selectOnFocus={true}
                                        renderInput={params => (
                                            <TextField style={ this.state.receivedByError ?  {"borderBottom":"1px solid RED"} : {}} {...params} placeholder="Received By"
                                                       margin="normal" fullWidth/>
                                        )}
                                    />
                                </div>
                                <div className={'col-md'}>
                                    <MDBSelect style={{"marginTop":"5px"}} key={"invoiceType"+this.state.randomKey} getValue={this.handleInvoiceType}
                                        options={this.state.invoiceTypes}
                                        selected=""
                                        label="Invoice Type"
                                    />
                                </div>
                            </MDBRow>
                            <MDBRow className={'my-2'}>
                                <div className={'col-md'}>
                                    <MDBInput className={this.state.invoiceNumberError ? "border-danger" : ""} maxLength="30"
                                              type='text' label="Invoice Number" onChange={this.handleInvoiceNumber}
                                              value={this.state.invoiceNumber} onBlur={this.validateInvoiceNumber}/>
                                </div>
                                <div className={'col-md'}>
                                    <MDBInput className={this.state.amountError ? "border-danger" : ""} maxLength="15"
                                              type='text' label="Invoiced/Payable Amount" onChange={this.handleAmount}
                                              value={this.state.amount} onBlur={this.validateAmount}/>
                                </div>
                                <div className={'col-md'}>
                                    <MDBInput className={this.state.totalTaxAmountError ? "border-danger" : ""} maxLength="15"
                                              type='text' label="Total Tax Amount" onChange={this.handleTotalTaxAmount}
                                              value={this.state.totalTaxAmount} onBlur={this.validateTotalTaxAmount}/>
                                </div>

                                <div className={'col-md'}>
                                    <MDBInput className={this.state.totalCessTaxAmountError ? "border-danger" : ""} maxLength="15"
                                              type='text' label="Cess Tax Amount" onChange={this.handleTotalCessTaxAmount}
                                              value={this.state.cessTaxAmount} onBlur={this.validateTotalCessTaxAmount}/>
                                </div>
                                <div className={'col-md'}>
                                    <MDBInput className={this.state.discountAmountError ? "border-danger" : ""} maxLength="15"
                                              type='text' label="Discount Amount" onChange={this.handleDiscountAmount}
                                              value={this.state.discountAmount} onBlur={this.validateDiscountAmount}/>
                                </div>
                            </MDBRow>
                            <MDBRow className={'my-2'}>
                                <div className={'col-md-4'}>
                                    <MuiPickersUtilsProvider  utils={MomentUtils}>
                                        <KeyboardDatePicker className={"col-12"}
                                            format="MMM DD, YYYY"
                                            margin="normal"
                                            id="invoice_date"
                                            label="Invoice Date"
                                            value={this.state.invoiceDate}
                                            onChange={this.handleInvoiceDate}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div className={'col-md-4'}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker  className={"col-12"}
                                            format="MMM DD, YYYY"
                                            margin="normal"
                                            id="receiving_date"
                                            label="Receiving Date"
                                            value={this.state.receivingDate}
                                            onChange={this.handleReceivingDate}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div className={'col-md-4'}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardTimePicker  className={"col-12"}
                                        margin="normal"
                                        id="time-picker"
                                        label="Receiving Time"
                                        value={this.state.receivingTime}
                                        onChange={this.handleReceivingTime}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change time',
                                        }}
                                    />
                                    </MuiPickersUtilsProvider>
                                </div>
                            </MDBRow>
                            <MDBRow className={'my-2'}>
                                <MDBCol>
                                    <MDBInput   className={this.state.supplyPlaceError ? "border-danger" : ""} maxLength="100"
                                                type='text' label="Receiving Place" onChange={this.handleSupplyPlace}
                                                value={this.state.supplyPlace}/>
                                </MDBCol>
                                <MDBCol>
                                    <MDBInput className={this.state.reverseChargeError ? "border-danger" : ""} maxLength="15"
                                              type='text' label="Reverse Charge" onChange={this.handleReverseCharge}
                                              value={this.state.reverseCharge}/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className={'my-2'}>
                                <MDBCol>
                                    <MDBInput autoComplete={'off'} style={{"marginTop": "0px"}} background={'sm'}
                                              className={this.state.descriptionError ? "border-danger" : "mt-0 mb-0"}
                                              maxLength="200" type='textarea' label="Comments"
                                              onChange={this.handleComments}
                                              value={this.state.comments}/>
                                </MDBCol>
                            </MDBRow>

                            <MDBRow>
                                <MDBCol>
                                    Upload documents
                                    <MultipleDocsUpload key={"document_"+this.state.randomKey} buttonLabel={"Attach Document"} initUploadCallback={this.initUploadCallback} fileUploadCallback={this.fileUploadCallback}  nextProps={this.state.resetForm}></MultipleDocsUpload>
                                </MDBCol>
                            </MDBRow>

                            <div className="m-0 text-center">
                                <MDBBtn outline color="blue-grey" size="md" onClick={() =>this.props.history.push('/view-incoming-shipments')} className="mr-auto" disabled={this.state.progressStatus}>
                                    Back
                                </MDBBtn>
                                <MDBBtn  size="md" onClick={this.addShipment}
                                        disabled={this.state.progressStatus || this.state.progressStatusFileUpload || this.state.supplierError || this.state.receivedByError || this.state.invoiceNumberError || this.state.amountError ||  this.state.totalTaxAmountError || this.state.totalCessTaxAmountError || this.state.discountAmountError}>
                                    Save
                                </MDBBtn>
                            </div>

                        </MDBCardBody>
                        <MDBModal isOpen={this.state.modalSupplier} toggle={this.toggleAddSupplier} size={"lg"}>
                            <MDBModalHeader toggle={this.toggleAddSupplier}>Add Supplier</MDBModalHeader>
                            <MDBModalBody>
                                <AddSupplier subComponentActionPerformed={this.supplierAdded} isSubComponent={true}/>
                            </MDBModalBody>
                        </MDBModal>
                    </MDBCard>
                </MDBContainer>
            </React.Fragment>
        );
    }
}
export default withRouter(AddShipment)
