const dataTableBoxes = {
    boxes:[
        {
            label: 'Box Name',
            field: 'boxName',
            sort: 'asc',
            width: 400
        },
        {
            label: '',
            field: 'edit',
            sort: 'asc',
            width: 50
        },
        {
            label: '',
            field: 'delete',
            sort: 'asc',
            width: 50
        },
    ]
}
export default dataTableBoxes;