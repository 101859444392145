import React from "react";
import SimpleBackdrop from "../common/overlay";
import {
    MDBBtn, MDBChip, MDBInput, MDBSelect, MDBSelectInput, MDBSelectOption, MDBSelectOptions,
    MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBTable, MDBTableHead, MDBTableBody
} from "mdbreact";
import UserStore from "../../stores/userStore";
import InventoryService from "../../services/inventoryService";
import {withRouter} from "react-router-dom"
import {toast} from "react-toastify";
import utility from "../../utility/utility";
import ProductDetailsPreview from "./productDetailsPreview";
import ProductSpecifications from "./ProductSpecifications";
import Utility from "../../utility/utility";
import MultiPicDirectUpload from "../common/fileUpload/multiPicDirectUpload";

class AddProduct extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            progressStatus: false,
            productName: "",
            userData: {},
            suggestedData: [],
            manufacturerList: [], selectedMfg: "",
            brandName: "",
            modalPreview: false,

            packagingQnty: 1,
            retailPackagingList: [], selectedRetailPackaging: "",
            packagingUnitList: [], selectedPackagingUnit: "",
            isLooseSaleAllowed: false,
            minimumLooseSaleQnty: 1,

            foodHabit: "None",
            toyFeatureList: [], selectedToyFeature: [],
            toyCollectionList: [], selectedToyCollection: [],
            toyScentList: [], selectedToyScent: [],
            productUses: "", description: "", howToUse: "",
            maximumQuantity: "", minimumQuantity: "", reorderQuantity: "",
            discount: 0, isTopSelling: "N", overrideMrp: 0, markAsOutOfStock: "N",
            inPersonDelivery: false, inPersonDeliveryInstructions: "",
            ship: false, shippingInstructions: "",
            pickup: false, pickupInstructions: "",
            uploadedFiles: [],
            productCategoryList: [], selectedCategory: [],
            productMaterialList: [], selectedProductMaterial: [],
            lifeStageList: [], selectedLifeStage: [],
            breedSizeList: [], selectedBreedSize: [],
            healthBenefitList: [], selectedHealthBenefit: [],
            sideEffectList: [], selectedSideEffect: [],
            productFormList: [], selectedProductForm: [],
            productFlavorList: [], selectedProductFlavor: [],
            productTextureList: [], selectedProductTexture: [],
            productColorList: [], selectedProductColor: [],
            productIngredientList: [], selectedIngredients: [], keyIngredient: 0,
            ingredientList: [], guaranteedAnaList: [], keyGAT: 0,
            specialDietList: [], selectedSpecialDiet: [],
            tagList: [], highlightList: [],
            productTypeList: [], selectedProductType: "",
            medicineTypeList: [], selectedMedicineType: [],
            modalChooseAction: false, item: null
        }
    }

    async componentDidMount() {
        let userData = await UserStore.fetchSessionData();
        this.setState({userData})
        await this.getProductTypeList();
    }
    getProductTypeList = async () => {
        if (!window.productTypeList || window.productTypeList === null) {
            let productTypeList = await InventoryService.fetchGetData(this.state.userData.sessionToken, "FETCH_PRODUCT_TYPE")
            if (productTypeList) {
                this.setState({productTypeList})
                window.productTypeList = productTypeList
            } else {
                toast.error("Something went wrong while fetching the product type list.")
            }
        } else {
            this.setState({productTypeList: window.productTypeList})
        }
    }
    medicineList = async (e) => {
        if (e.target.value !== "") {
            this.setState({productName: e.target.value})
            let suggestedData = await InventoryService.suggestProduct(e.target.value, this.state.userData);
            if (suggestedData) {
                this.setState({suggestedData})
            }
        } else {
            this.setState({productName: "", suggestedData: []})
        }
    }
    editProduct = (item) => {
        this.props.history.push('/edit-product/' + item._id);
    }
    toggle = item => {
        this.setState({["modal" + item]: !this.state["modal" + item]})
    }
    getMinimumLooseSaleQnty = (val) => {
        if (val !== "" && !utility.isInt(val)) {
            return false
        }
        this.setState({minimumLooseSaleQnty: val})
    }
    resetKeyGAT = () => {
        let randomString = Math.random().toString(36);
        this.setState({keyGAT: randomString});
    }
    getMaximumQuantity = (val) => {
        if (val !== "" && !utility.isInt(val)) {
            return false
        }
        this.setState({maximumQuantity: val})
    }
    getMinimumQuantity = (val) => {
        if (val !== "" && !utility.isInt(val)) {
            return false
        }
        this.setState({minimumQuantity: val})
    }
    getReorderQuantity = (val) => {
        if (val !== "" && !utility.isInt(val)) {
            return false
        }
        this.setState({reorderQuantity: val})
    }
    getDiscount = (val) => {
        if (val !== "" && !utility.isInt(val)) {
            return false
        }
        this.setState({discount: val})
    }
    getUploadedFiles = async (files) => {
        if (files.length === 0) {
            await this.setState({uploadedFiles: []})
        } else {
            await this.setState({uploadedFiles: files})
        }
    }
    getPreparedList = (dataList, fieldName, secondaryFieldName) => {
        let tempList = []
        for (let item of dataList) {
            if (secondaryFieldName !== undefined) {
                tempList.push({
                    text: item[fieldName] + " ( " + item[secondaryFieldName] + " ) ",
                    value: item[fieldName]
                })
            } else {
                tempList.push({text: item[fieldName], value: item[fieldName]})
            }
        }
        return tempList
    }
    getCategoryManufacturerList = (dataList, fieldName) => {
        let tempList = []
        if (fieldName === "categoryName") {
            for (let item of dataList) {
                tempList.push({text: item[fieldName], value: item._id + "_" + item[fieldName]}) //for category
            }
        } else {
            for (let item of dataList) {
                tempList.push({text: item[fieldName], value: item._id}) //for manufacturer
            }
        }
        return tempList
    }
    getList = async (listName, apiName, fieldName, section, secondaryFieldName) => {
        if (this.state[listName].length > 0) {
            return false
        }
        let rawList = [], preparedList = []
        if (!window[listName] || window[listName] === null) {
            let data = await InventoryService.fetchGetData(this.state.userData.sessionToken, apiName)
            if (data) {
                rawList = data
                window[listName] = data
            } else {
                toast.error("Something went wrong while getting the " + section + " list.")
            }
        } else {
            rawList = window[listName]
        }
        if (rawList.length > 0) {
            if (listName === "productCategoryList" || listName === "manufacturerList") {
                preparedList = this.getCategoryManufacturerList(rawList, fieldName)
            } else {
                preparedList = this.getPreparedList(rawList, fieldName, secondaryFieldName)
            }
        }
        this.setState({[listName]: preparedList})
    }
    getGAList = async () => {
        if (this.state.guaranteedAnaList.length > 0) {
            return false
        }
        let rawList = [], tempList = []
        if (!window.guaranteedAnaList || window.guaranteedAnaList === null) {
            let data = await InventoryService.fetchGetData(this.state.userData.sessionToken, "FETCH_GUARANTEED_ANALYSIS")
            if (data) {
                rawList = data
                window.guaranteedAnaList = data
            } else {
                toast.error("Something went wrong while fetching the guaranteed analysis.")
            }
        } else {
            rawList = window.guaranteedAnaList
        }
        if (rawList.length > 0) {
            for (let i = 0; i < rawList.length; i++) {
                tempList.push({
                    text: rawList[i].name,
                    value: i + "_" + rawList[i].name,
                    unit: "",
                    qnty: 0,
                    selected: false
                })
            }
        }
        this.setState({guaranteedAnaList: tempList})
    }
    setGuaranteedAna = (val) => {
        let guaranteedAnaList = this.state.guaranteedAnaList
        for (let item of guaranteedAnaList) {
            item.selected = false
        }
        for (let i = 0; i < val.length; i++) {
            let arrayWithData = val[i].split("_")
            let index = arrayWithData[0]
            guaranteedAnaList[index].selected = true
            guaranteedAnaList[index].displayIndex = i
        }
        for (let item of guaranteedAnaList) {
            if (item.selected === false) {
                item.unit = ""
                item.qnty = 0
            }
        }
        this.setState({guaranteedAnaList})
        this.resetKeyGAT()
    }
    handleGAQnty = (index) => e => {
        let guaranteedAnaList = this.state.guaranteedAnaList
        guaranteedAnaList[index].qnty = e.target.value
        this.setState({guaranteedAnaList})
    }
    handleGAUnit = (index) => e => {
        let guaranteedAnaList = this.state.guaranteedAnaList
        guaranteedAnaList[index].unit = e[0]
        this.setState({guaranteedAnaList})
    }
    getIngredientList = async () => {
        if (this.state.productIngredientList.length > 0) {
            return false
        }
        let rawList = [], tempList = []
        if (!window.productIngredientList || window.productIngredientList === null) {
            let data = await InventoryService.fetchGetData(this.state.userData.sessionToken, "FETCH_INGREDIENT")
            if (data) {
                rawList = data
                window.productIngredientList = data
            } else {
                toast.error("Something went wrong while fetching the ingredient list.")
            }
        } else {
            rawList = window.productIngredientList
        }
        if (rawList.length > 0) {
            for (let i = 0; i < rawList.length; i++) {
                tempList.push({
                    text: rawList[i].name,
                    value: i + "_" + rawList[i].name,
                    unit: "",
                    qnty: 0,
                    selected: false
                })
            }
            this.setState({productIngredientList: tempList})
        }
    }
    setIngredients = (val) => {
        let productIngredientList = this.state.productIngredientList
        for (let item of productIngredientList) {
            item.selected = false
        }
        for (let i = 0; i < val.length; i++) {
            let arrayWithData = val[i].split("_")
            let index = arrayWithData[0]
            productIngredientList[index].selected = true
            productIngredientList[index].displayIndex = i
        }
        for (let item of productIngredientList) {
            if (item.selected === false) {
                item.unit = ""
                item.qnty = 0
            }
        }
        this.setState({productIngredientList})
        this.resetKeyIngredient()
    }
    handleIngredientQnty = (index) => e => {
        let productIngredientList = this.state.productIngredientList
        productIngredientList[index].qnty = e.target.value
        this.setState({productIngredientList})
    }
    handleIngredientUnit = (index) => e => {
        let productIngredientList = this.state.productIngredientList
        productIngredientList[index].unit = e[0]
        this.setState({productIngredientList})
    }
    resetKeyIngredient = () => {
        let randomString = Math.random().toString(36);
        this.setState({keyIngredient: randomString});
    }
    addNewProduct = async () => {
        if (this.state.productName === "") {
            toast.error('Please enter "Product Name".')
            return false
        }
        if (this.state.selectedMfg.length === 0) {
            toast.error('Please select "Manufacturer"')
            return false
        }
        let selectedCategory = []
        if (this.state.selectedCategory.length === 0) {
            toast.error('Please select "Category"')
            return false
        } else {
            for (let item of this.state.selectedCategory) {
                let temp = item.split("_")
                selectedCategory.push({_id: temp[0], name: temp[1]})
            }
        }
        if (this.state.selectedRetailPackaging.length === 0) {
            toast.error('Please select "Retail Packaging"')
            return false
        }
        if (this.state.packagingQnty.length === 0) {
            toast.error('Please enter "Packaging Quantity"')
            return false
        }
        if (this.state.selectedPackagingUnit.length === 0) {
            toast.error('Please select "Packaging Unit"')
            return false
        }
        if (this.state.isLooseSaleAllowed === true && ((this.state.minimumLooseSaleQnty === 0 || this.state.minimumLooseSaleQnty === "") || (this.state.packagingQnty < this.state.minimumLooseSaleQnty))) {
            toast.error('Please enter a valid "Minimum Loose Sale Quantity"')
            return false
        }
        if (this.state.selectedLifeStage.length === 0) {
            toast.error('Please select "Life stage"')
            return false
        }
        if (this.state.productUses.trim() === "" || this.state.productUses.trim().length < 100) {
            toast.error('Please enter minimum 100 characters in "Product Uses"')
            return false
        }
        if (this.state.selectedHealthBenefit.length === 0) {
            toast.error('Please select "Health Benefits"')
            return false
        }
        if (this.state.description.trim() === "" || this.state.description.trim().length < 100) {
            toast.error('Please enter minimum 100 characters in "Product Description"')
            return false
        }
        if (this.state.howToUse.trim() === "" || this.state.howToUse.trim().length < 5) {
            toast.error('Please enter minimum 5 characters in "How To Use"')
            return false
        }
        if (this.state.maximumQuantity === "") {
            toast.error('Please enter "Maximum Quantity" in "Reorder Config" section')
            return false
        }
        if (this.state.minimumQuantity === "") {
            toast.error('Please enter "Minimum Quantity" in "Reorder Config" section')
            return false
        }
        if (this.state.reorderQuantity === "") {
            toast.error('Please enter "Reorder Threshold" in "Reorder Config" section')
            return false
        }
        if (this.state.selectedProductType === "") {
            toast.error('Please select "Product Type"')
            return false
        }
        if (this.state.selectedProductType === "Medicine" && this.state.selectedMedicineType.length === 0) {
            toast.error('Please select "Medicine Type"')
            return false
        }
        if ((this.state.selectedProductType === "Toy" || this.state.selectedProductType === "Supplies" || this.state.selectedProductType === "Accessories" || this.state.selectedProductType === "Device") && this.state.selectedProductMaterial.length === 0) {
            toast.error('Please select "Product Material"')
            return false
        }
        if (this.state.selectedProductType === "Toy" && this.state.selectedToyFeature.length === 0) {
            toast.error('Please select "Toy Feature"')
            return false
        }
        if (this.state.selectedProductType === "Toy" && this.state.selectedToyCollection.length === 0) {
            toast.error('Please select "Toy Collection"')
            return false
        }
        if (this.state.selectedProductType === "Toy" && this.state.selectedToyScent.length === 0) {
            toast.error('Please select "Toy Scent"')
            return false
        }
        let tags = Utility.prepareArray(this.state.tagList),
            highlights = Utility.prepareArray(this.state.highlightList)
        if (highlights.length === 0) {
            toast.error('Please enter "Highlights"')
            return false
        }
        //collect Guaranteed Analysis
        let addedGA = []
        for (let item of this.state.guaranteedAnaList) {
            if (item.selected === true) {
                if (item.qnty > 0 && item.unit !== "") {
                    addedGA.push({name: item.text, quantity: item.qnty, unit: item.unit})
                } else {
                    toast.error("Please enter quantity and unit for the respective Guaranteed Analysis in the list.")
                    return false
                }
            }
        }
        //collect ingredients
        let addedIngredients = []
        for (let item of this.state.productIngredientList) {
            if (item.selected === true) {
               if (this.state.selectedProductType === "Food" || this.state.selectedProductType === "Medicine") {
                   if (item.qnty > 0 && item.unit !== "") {
                       addedIngredients.push({ingredient: item.text, quantity: item.qnty, unit: item.unit})
                   } else {
                       toast.error("Please enter quantity and unit for the respective Ingredient in the list.")
                       return false
                   }
               } else {
                   addedIngredients.push({ingredient: item.text, quantity: item.qnty === "" ? 0 : item.qnty, unit: item.unit})
               }
            }
        }
        if ((this.state.selectedProductType === "Food" || this.state.selectedProductType === "Medicine") && addedIngredients.length === 0) {
            toast.error('Please select ingredients and enter quantity in "Ingredients List With Quantity"')
            return false
        }
        if (this.state.uploadedFiles.length === 0) {
            toast.error('Please add at least one product image.')
            return false
        }
        let payload = {
            productName: this.state.productName,
            mfgId: this.state.selectedMfg[0],
            brand: this.state.brandName,
            categories: selectedCategory,
            specialDiet: this.state.selectedSpecialDiet,
            retailPackage: this.state.selectedRetailPackaging[0],
            contentQuantity: parseFloat(this.state.packagingQnty, 2),
            quantityUnit: this.state.selectedPackagingUnit[0],
            looseSaleAllowed: this.state.isLooseSaleAllowed === true ? "Y" : "N",
            minimumLooseSaleQuantity: parseInt(this.state.minimumLooseSaleQnty),
            productForm: this.state.selectedProductForm.length > 0 ? this.state.selectedProductForm[0] : [],
            flavor: this.state.selectedProductFlavor,
            texture: this.state.selectedProductTexture,
            color: this.state.selectedProductColor,
            lifeStage: this.state.selectedLifeStage,
            breedSize: this.state.selectedBreedSize,
            productUses: this.state.productUses,
            benefits: this.state.selectedHealthBenefit,
            productSideEffects: this.state.selectedSideEffect,
            description: this.state.description,
            howTouse: this.state.howToUse,
            maxQuantity: parseInt(this.state.maximumQuantity),
            minQuantity: parseInt(this.state.minimumQuantity),
            reorderThreshold: parseInt(this.state.reorderQuantity),
            shippingDetails: {
                inPerson: this.state.inPersonDelivery === true ? "Y" : "N",
                ship: this.state.ship === true ? "Y" : "N",
                pickUp: this.state.pickup === true ? "Y" : "N",
                inPersonInstruction: this.state.inPersonDeliveryInstructions,
                shippingInstruction: this.state.shippingInstructions,
                pickupInstruction: this.state.pickupInstructions,
            },
            discount: parseInt(this.state.discount),
            isTopSelling: this.state.isTopSelling[0],
            overideMrp: parseFloat(this.state.overrideMrp, 2),
            markAsoutOfStock: this.state.markAsOutOfStock[0],
            productType: this.state.selectedProductType,
            medicineType: (this.state.selectedProductType !== "Medicine") ? "" : this.state.selectedMedicineType[0],
            materials: this.state.selectedProductMaterial,
            toyFeature: this.state.selectedToyFeature,
            toyCollection: this.state.selectedToyCollection,
            toyScent: this.state.selectedToyScent,
            foodHabit: this.state.foodHabit,
            tags: tags,
            highlights: highlights,
            guaranteedAnalysis: addedGA,
            ingredient: addedIngredients,
            imageUrl: this.state.uploadedFiles,
            masterProductId: ""
        }
        this.setState({progressStatus: true})
        let data = await InventoryService.createProductV3(this.state.userData.sessionToken, payload)
        this.setState({progressStatus: false})
        if (data) {
            toast.success("The product got created successfully.")
            this.props.history.push('/view-products-new');
        } else {
            toast.error("Something went wrong while creating the product.")
        }
    }
    chooseAction = (item) => {
        this.setState({item, modalChooseAction: true})
    }

    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.progressStatus}/>
                <div className={'col-md-11 mx-auto white rounded-sm border p-0'}>
                    <div
                        className={'col-12 p-2 grey lighten-4 border-bottom d-flex justify-content-between align-items-center'}>
                        <b className={'title'}>Add Product</b>
                        {/*<div className={'row mx-0'}>
                            <MDBBtn outline color={"blue"} onClick={() => this.toggle("Preview")}
                                    disabled={this.state.progressStatus}>Preview</MDBBtn>
                            <MDBBtn outline onClick={() => this.props.history.push('/view-products-new')}
                                    disabled={this.state.progressStatus}>Back</MDBBtn>
                            <MDBBtn onClick={this.addNewProduct}
                                    disabled={this.state.progressStatus}>Save</MDBBtn>
                        </div>*/}
                    </div>
                    <div className={"col-12 row m-0 p-0"}>
                        <div className={"col-md-4 px-2"}>
                            <MDBInput id={"add_product_name_se"} background size="sm"  label={"Product Name"}
                                      value={this.state.productName} onChange={this.medicineList}/>
                        </div>
                        <div className={"col-md-4 px-2 "}>
                            <b>Manufacturer</b><br/>
                            <MDBSelect search selected="Select Manufacturer"
                                       options={this.state.manufacturerList}
                                       getValue={(val) => {
                                           this.setState({selectedMfg: val})
                                       }}
                                       className={'col grey-text p-0 font-weight-bold'}
                                       onFocus={() => this.getList("manufacturerList", "FETCH_MFG", "manufacturer", "manufacturer")}
                            />
                        </div>
                        <div className={"col-md-4 px-2 "}>
                          {/*  <b>Brand</b><br/>*/}
                            <MDBInput label={'Brand'}  value={this.state.brandName} getValue={(val) => {
                                this.setState({brandName: val})
                            }}/>
                        </div>
                    </div>
                    {(this.state.suggestedData.length > 0 && this.state.productName !== "") &&
                    <div className={"col-12 row m-0 p-0"}>
                        <div className={"col-12 p-2 mt-1"}>
                            <span className={"my-2 font-weight-bold"}>Update existing products instead:</span><br/>
                            {this.state.suggestedData.map((item, idx) => (
                                <span onClick={() => {
                                    this.chooseAction(item)
                                }} key={idx}><MDBChip bgColor="light-blue lighten-4" waves>{item.productName}</MDBChip></span>
                            ))}
                        </div>
                    </div>
                    }
                    <div className={"col-12 row m-0 p-0"}>
                        <div className={"col-md-6 px-2"}>
                            <b>Category</b><br/>
                            <MDBSelect search selected="Select Category"
                                       options={this.state.productCategoryList}
                                       getValue={(val) => {
                                           this.setState({selectedCategory: val})
                                       }}
                                       className={'col grey-text p-0 font-weight-bold'}
                                       onFocus={() => this.getList("productCategoryList", "FETCH_CATEGORY", "categoryName", "category")}
                                       multiple/>
                        </div>
                        <div className={"col-md-6  px-2"}>
                            <b>Special Diet</b><br/>
                            <MDBSelect search selected="Select Special Diet"
                                       options={this.state.specialDietList}
                                       getValue={(val) => {
                                           this.setState({selectedSpecialDiet: val})
                                       }}
                                       className={' col grey-text p-0 font-weight-bold'}
                                       onFocus={() => this.getList("specialDietList", "FETCH_SPECIAL_DIET", "name", "special diet")}
                                       multiple/>
                        </div>
                    </div>
                </div>
                <div className={'col-md-11 mx-auto white rounded-sm border p-0 mt-2'}>
                    <div
                        className={'col-12 p-2 grey lighten-4 border-bottom d-flex justify-content-between align-items-center'}>
                        <b className={"font-weight-bold"}>Packaging Details</b>
                        <div className={'row mx-0'}>
                            <a role="button" onClick={() => this.toggle("Preview")}
                               className={"text-primary"}>PREVIEW</a>
                        </div>
                    </div>
                    <div className={"col-12 row m-0 p-0"}>
                        <div className={"col p-2"}>
                            <b>Retail Packaging</b><br/>
                            <MDBSelect search selected="Select Retail Packaging"
                                       options={this.state.retailPackagingList}
                                       getValue={(val) => {
                                           this.setState({selectedRetailPackaging: val})
                                       }} className={'col grey-text p-0 font-weight-bold'}
                                       onFocus={() => this.getList("retailPackagingList", "FETCH_RETAIL_PKG", "retailPackage", "retail packaging")}/>
                        </div>
                        <div className={"col p-2"}>
                            {/*<b>Packaging Quantity</b><br/>*/}
                            <MDBInput label={'Packaing Quantity'} background value={this.state.packagingQnty} type={"number"} min={0}
                                      getValue={(val) => {this.setState({packagingQnty: val})}}
                                      size="sm"/>
                        </div>
                        <div className={"col p-2"}>
                            <b>Packaging Unit</b><br/>
                            <MDBSelect search selected="Select Packaging Unit" options={this.state.packagingUnitList}
                                       getValue={(val) => {
                                           this.setState({selectedPackagingUnit: val})
                                       }} className={' col grey-text p-0 font-weight-bold'}
                                       onFocus={() => this.getList("packagingUnitList", "FETCH_UNIT", "quantityUnit", "quantity unit")}/>
                        </div>
                        <div className={"col p-2 font-weight-bold mt-4"}>
                            <MDBInput label="Loose Sale Allowed" type="checkbox" id="checkbox_loose"
                                      checked={this.state.isLooseSaleAllowed} getValue={(val) => {
                                this.setState({isLooseSaleAllowed: val})
                            }}/>
                        </div>
                        {this.state.isLooseSaleAllowed &&
                        <div className={"col p-2"}>
                            <b>Minimum Loose Sale Quantity</b><br/>
                            <MDBInput background value={this.state.minimumLooseSaleQnty}
                                      getValue={this.getMinimumLooseSaleQnty}/>
                        </div>
                        }
                    </div>
                </div>
                <div className={'col-md-11 mx-auto white rounded-sm border p-0 mt-2'}>
                    <div
                        className={'col-12 p-2 grey lighten-4 border-bottom d-flex justify-content-between align-items-center'}>
                        <b className={"font-weight-bold"}>Product Form</b>
                        <div className={'row mx-0'}>
                            <a role="button" onClick={() => this.toggle("Preview")}
                               className={"text-primary"}>PREVIEW</a>
                        </div>
                    </div>
                    <div className={"col-12 row m-0 p-0"}>
                        <div className={"col-3 p-2"}>
                            <b>Form</b><br/>
                            <MDBSelect search selected="Select Product Form"
                                       options={this.state.productFormList}
                                       getValue={(val) => {
                                           this.setState({selectedProductForm: val})
                                       }}
                                       className={' col grey-text p-0 font-weight-bold'}
                                       onFocus={() => this.getList("productFormList", "FETCH_PRODUCT_FORM", "name", "product form")}
                            />
                        </div>
                        <div className={"col-3 p-2"}>
                            <b>Flavor</b><br/>
                            <MDBSelect search selected="Select Product Flavor"
                                       options={this.state.productFlavorList}
                                       getValue={(val) => {
                                           this.setState({selectedProductFlavor: val})
                                       }}
                                       className={'col grey-text p-0 font-weight-bold'}
                                       onFocus={() => this.getList("productFlavorList", "FETCH_FLAVOR", "name", "product flavor")}
                                       multiple
                            />
                        </div>
                        <div className={"col-3 p-2"}>
                            <b>Texture</b><br/>
                            <MDBSelect search selected="Select Product Texture"
                                       options={this.state.productTextureList}
                                       getValue={(val) => {
                                           this.setState({selectedProductTexture: val})
                                       }}
                                       className={'col grey-text p-0 font-weight-bold'}
                                       onFocus={() => this.getList("productTextureList", "FETCH_TEXTURE", "name", "product texture")}
                                       multiple
                            />
                        </div>
                        <div className={"col-3 p-2"}>
                            <b>Color</b><br/>
                            <MDBSelect search selected="Select Product Color"
                                       options={this.state.productColorList}
                                       getValue={(val) => {
                                           this.setState({selectedProductColor: val})
                                       }}
                                       className={'col grey-text p-0 font-weight-bold'}
                                       onFocus={() => this.getList("productColorList", "FETCH_COLOR", "name", "product color")}
                                       multiple
                            />
                        </div>
                    </div>
                </div>
                <div className={'col-md-11 mx-auto white rounded-sm border p-0 mt-2'}>
                    <div
                        className={'col-12 p-2 grey lighten-4 border-bottom d-flex justify-content-between align-items-center'}>
                        <b className={"font-weight-bold"}>Other Details</b>
                        <div className={'row mx-0'}>
                            <a role="button" onClick={() => this.toggle("Preview")}
                               className={"text-primary"}>PREVIEW</a>
                        </div>
                    </div>
                    <div className={"col-12 row m-0 p-0"}>
                        <div className={"col p-2"}>
                            <b>Life Stage</b><br/>
                            <MDBSelect search selected="Select Life Stage"
                                       options={this.state.lifeStageList}
                                       getValue={(val) => {
                                           this.setState({selectedLifeStage: val})
                                       }}
                                       className={'col grey-text p-0 font-weight-bold'}
                                       onFocus={() => this.getList("lifeStageList", "FETCH_LIFE_STAGE", "name", "life stage")}
                                       multiple
                            />
                        </div>
                        <div className={"col p-2"}>
                            {/*<b>Breed Size (Only for pets)</b><br/>*/}
                            {/*<MDBSelect search selected="Select Breed Size"*/}
                            {/*           options={this.state.breedSizeList}*/}
                            {/*           getValue={(val) => {*/}
                            {/*               this.setState({selectedBreedSize: val})*/}
                            {/*           }}*/}
                            {/*           className={' col grey-text p-0 font-weight-bold'}*/}
                            {/*           onFocus={() => this.getList("breedSizeList", "FETCH_BREED_SIZE", "name", "breed size")}*/}
                            {/*           multiple*/}
                            {/*/>*/}
                        </div>
                    </div>
                    <div className={"col-12 row m-0 p-0"}>
                        <div className={"col p-2"}>
                            <MDBInput type="textarea" label="Uses" background size="sm"
                                      rows="6" value={this.state.productUses}
                                      getValue={(val) => {
                                          this.setState({productUses: val});
                                      }}/>
                        </div>
                        <div className={"col p-2"}>
                            <div className={"row p-2 mt-n2"}>
                                <div className={"col-12 p-2"}>
                                    <b>Health Benefits</b><br/>
                                    <MDBSelect search selected="Select Health Benefits"
                                               options={this.state.healthBenefitList}
                                               getValue={(val) => {
                                                   this.setState({selectedHealthBenefit: val})
                                               }}
                                               className={'col grey-text p-0 font-weight-bold'}
                                               onFocus={() => this.getList("healthBenefitList", "FETCH_HEALTH_BENEFITS", "name", "health benefit")}
                                               multiple
                                    /></div>
                                <div className={"col-12 p-2"}>
                                    <b>Side Effects</b><br/>
                                    <MDBSelect search selected="Select Side Effects"
                                               options={this.state.sideEffectList}
                                               getValue={(val) => {
                                                   this.setState({selectedSideEffect: val})
                                               }}
                                               className={' col grey-text p-0 font-weight-bold'}
                                               onFocus={() => this.getList("sideEffectList", "FETCH_SIDE_EFFECTS", "name", "side effect")}
                                               multiple
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"col-12 row m-0 p-0"}>
                        <div className={"col p-2"}>
                            {/*<b>Description</b>*/}
                            <MDBInput type="textarea" background size="sm"
                                      label="Description"
                                      rows="5" value={this.state.description}
                                      getValue={(val) => {
                                          this.setState({description: val});
                                      }}/>
                        </div>
                        <div className={"col p-2"}>
                           {/* <b>How To Use</b><br/>*/}
                            <MDBInput type="textarea" label={"How to use"} background rows="5" size="sm"
                                      value={this.state.howToUse} getValue={(val) => {
                                this.setState({howToUse: val})
                            }}/>
                        </div>
                    </div>
                </div>
                <div className={'col-md-11 mx-auto white rounded-sm border p-0 mt-2'}>
                    <div
                        className={'col-12 p-2 grey lighten-4 border-bottom d-flex justify-content-between align-items-center'}>
                        <b>Reorder Config</b>
                        <div className={'row mx-0'}>
                            <a role="button" onClick={() => this.toggle("Preview")}
                               className={"text-primary"}>PREVIEW</a>
                        </div>
                    </div>
                    <div className={"col-12 row m-0 p-0"}>
                        <div className={"col-sm-4"}>
                           {/* <b>Maximum Quantity</b><br/>*/}
                            <MDBInput label="Maximum Quantity" background value={this.state.maximumQuantity} getValue={this.getMaximumQuantity}
                                      size="sm"/>
                        </div>
                        <div className={"col-sm-4"}>
                            {/*<b>Minimum Quantity</b><br/>*/}
                            <MDBInput label="Minimum Quantity" background value={this.state.minimumQuantity} getValue={this.getMinimumQuantity}
                                      size="sm"/>
                        </div>
                        <div className={"col-sm-4"}>
                           {/* <b>Reorder Threshold</b><br/>*/}
                            <MDBInput label="Reorder Threshold" background value={this.state.reorderQuantity} getValue={this.getReorderQuantity}
                                      size="sm"/>
                        </div>
                    </div>
                </div>
                <div className={'col-md-11 mx-auto white rounded-sm border p-0 mt-2'}>
                    <div
                        className={'col-12 p-2 grey lighten-4 border-bottom d-flex justify-content-between align-items-center'}>
                        <b className={"font-weight-bold"}>Other Config</b>
                        <div className={'row mx-0'}>
                            <a role="button" onClick={() => this.toggle("Preview")}
                               className={"text-primary"}>PREVIEW</a>
                        </div>
                    </div>
                    <div className={"col-12 row m-0 p-0"}>
                        <div className={"col p-2"}>
                            {/*<b>Discount (%)</b><br/>*/}
                            <MDBInput label={'Discount%'} background value={this.state.discount} type={"number"}
                                      getValue={this.getDiscount} size="sm"/>
                        </div>
                        <div className={"col p-2"}>
                            <b>Is Top Selling</b><br/>
                            <MDBSelect getValue={(val) => this.setState({isTopSelling: val})}
                                       className={' col grey-text p-0 font-weight-bold'}>
                                <MDBSelectInput selected={this.state.isTopSelling}/>
                                <MDBSelectOptions>
                                    <MDBSelectOption value="Y" checked={this.state.isTopSelling === "Y"}>Yes</MDBSelectOption>
                                    <MDBSelectOption value="N" checked={this.state.isTopSelling === "N"}>No</MDBSelectOption>
                                </MDBSelectOptions>
                            </MDBSelect>
                        </div>
                        <div className={"col p-2"}>
                            {/*<b>Override MRP</b><br/>*/}
                            <MDBInput label="Override MRP" background value={this.state.overrideMrp} type={"number"} min={0}
                                      getValue={(val) => {
                                          this.setState({overrideMrp: val})
                                      }}
                                      size="sm"/>
                        </div>
                        <div className={"col p-2"}>
                            <b>Mark As Out Of Stock</b><br/>
                            <MDBSelect getValue={(val) => this.setState({markAsOutOfStock: val})}
                                       className={'col grey-text p-0 font-weight-bold'}>
                                <MDBSelectInput selected={"Is out of stock"}/>
                                <MDBSelectOptions>
                                    <MDBSelectOption value="Y" checked={this.state.markAsOutOfStock === "Y"}>Yes</MDBSelectOption>
                                    <MDBSelectOption value="N" checked={this.state.markAsOutOfStock === "N"}>No</MDBSelectOption>
                                </MDBSelectOptions>
                            </MDBSelect>
                        </div>
                    </div>
                </div>
                <div className={'col-md-11 mx-auto white rounded-sm border p-0 mt-2'}>
                    <div
                        className={'col-12 p-2 grey lighten-4 border-bottom d-flex justify-content-between align-items-center'}>
                        <b className={"font-weight-bold"}>Shipping Details</b>
                        <div className={'row mx-0'}>
                            <a role="button" onClick={() => this.toggle("Preview")}
                               className={"text-primary"}>PREVIEW</a>
                        </div>
                    </div>
                    <div className={"col-12 row m-0 p-0"}>
                        <div className={"col p-2 font-weight-bold mt-2"}>
                            <MDBInput label="In-Person Delivery" type="checkbox" id="checkbox_in_person_delivery"
                                      checked={this.state.inPersonDelivery} getValue={(val) => {
                                this.setState({inPersonDelivery: val})
                            }}/>
                        </div>
                        <div className={"col p-2 font-weight-bold mt-2"}>
                            <MDBInput label="Is Shippable ?" type="checkbox" id="checkbox_ship"
                                      checked={this.state.ship} getValue={(val) => {
                                this.setState({ship: val})
                            }}/>
                        </div>
                        <div className={"col p-2 font-weight-bold mt-2"}>
                            <MDBInput label="Pickup" type="checkbox" id="checkbox_pickup"
                                      checked={this.state.pickup} getValue={(val) => {
                                this.setState({pickup: val})
                            }}/>
                        </div>
                    </div>
                    <div className={"col-12 row m-0 p-0"}>
                        <div className={"col p-2"}>
                            {/*<b>In-Person Delivery Instructions</b>*/}
                            <MDBInput type="textarea" background size="sm"
                                      label="In-Person Delivery Instructions"
                                      rows="5" value={this.state.inPersonDeliveryInstructions}
                                      getValue={(val) => {
                                          this.setState({inPersonDeliveryInstructions: val});
                                      }}/>
                        </div>
                        <div className={"col p-2"}>
                            {/*<b>Shipping Instructions</b>*/}
                            <MDBInput type="textarea" background size="sm" label="Shipping Instructions"
                                      rows="5" value={this.state.shippingInstructions}
                                      getValue={(val) => {
                                          this.setState({shippingInstructions: val});
                                      }}/>
                        </div>
                        <div className={"col p-2"}>
                           {/* <b>Pickup Instructions</b>*/}
                            <MDBInput type="textarea" background size="sm"
                                      label="Pickup Instructions"
                                      rows="5" value={this.state.pickupInstructions}
                                      getValue={(val) => {
                                          this.setState({pickupInstructions: val});
                                      }}/>
                        </div>
                    </div>
                </div>
                <div className={'col-md-11 mx-auto white rounded-sm border p-0 mt-2'}>
                    <div
                        className={'col-12 p-2 grey lighten-4 border-bottom d-flex justify-content-between align-items-center'}>
                        <b className={"font-weight-bold"}>Product Type & Materials</b>
                        <div className={'row mx-0'}>
                            <a role="button" onClick={() => this.toggle("Preview")}
                               className={"text-primary"}>PREVIEW</a>
                        </div>
                    </div>
                    <div className={"col-12 row m-0 p-0"}>
                        <div className={"col-6 p-2 d-flex float-left"}>
                            <b>Product Type:</b><br/>
                            {this.state.productTypeList.map((item, index) => (
                                <MDBInput onClick={() => this.setState({selectedProductType: item.name})}
                                          checked={this.state.selectedProductType === item.name} label={item.name}
                                          type="radio"
                                          id={"radioProdType" + index} key={index}/>
                            ))}
                        </div>
                        {this.state.selectedProductType === "Medicine" &&
                        <div className={"col-6 p-2"}>
                            <b>Medicine Type</b><br/>
                            <MDBSelect search selected="Select Medicine Type"
                                       options={this.state.medicineTypeList}
                                       getValue={(val) => {
                                           this.setState({selectedMedicineType: val})
                                       }}
                                       className={'col grey-text p-0 font-weight-bold'}
                                       onFocus={() => this.getList("medicineTypeList", "FETCH_MEDICINE_SCHEDULE", "typeName", "medicine type", "typeDescription")}
                            />
                        </div>
                        }
                        {(this.state.selectedProductType === "Toy" || this.state.selectedProductType === "Supplies" || this.state.selectedProductType === "Accessories" || this.state.selectedProductType === "Device") &&
                        <div className={"col p-2"}>
                            <b>Materials</b><br/>
                            <MDBSelect search selected="Select Product Materials"
                                       options={this.state.productMaterialList}
                                       getValue={(val) => {
                                           this.setState({selectedProductMaterial: val})
                                       }}
                                       className={' col grey-text p-0 font-weight-bold'}
                                       onFocus={() => this.getList("productMaterialList", "FETCH_MATERIAL", "name", "product material")}
                                       multiple
                            />
                        </div>
                        }
                    </div>
                </div>
                {(this.state.selectedProductType === "Toy") &&
                <div className={'col-md-11 mx-auto white rounded-sm border p-0 mt-2'}>
                    <div
                        className={'col-12 p-2 grey lighten-4 border-bottom d-flex justify-content-between align-items-center'}>
                        <b className={"font-weight-bold"}>Toy Details (Only for toys)</b>
                        <div className={'row mx-0'}>
                            <a role="button" onClick={() => this.toggle("Preview")}
                               className={"text-primary"}>PREVIEW</a>
                        </div>
                    </div>
                    <div className={"col-12 row m-0 p-0"}>
                        <div className={"col p-2"}>
                            <b>Toy Feature</b><br/>
                            <MDBSelect search selected="Choose Toy Feature"
                                       options={this.state.toyFeatureList}
                                       getValue={(val) => {
                                           this.setState({selectedToyFeature: val})
                                       }}
                                       className={'col grey-text p-0 font-weight-bold'}
                                       onFocus={() => this.getList("toyFeatureList", "FETCH_TOY_FEATURE", "name", "toy feature")}
                                       multiple
                            />
                        </div>
                        <div className={"col p-2"}>
                            <b>Toy Collection</b><br/>
                            <MDBSelect search selected="Choose Toy Collection"
                                       options={this.state.toyCollectionList}
                                       getValue={(val) => {
                                           this.setState({selectedToyCollection: val})
                                       }}
                                       className={'col grey-text p-0 font-weight-bold'}
                                       onFocus={() => this.getList("toyCollectionList", "FETCH_TOY_COLLECTION", "name", "toy collection")}
                                       multiple
                            />
                        </div>
                        <div className={"col p-2"}>
                            <b>Toy Scent</b><br/>
                            <MDBSelect search selected="Choose Toy Scent"
                                       options={this.state.toyScentList}
                                       getValue={(val) => {
                                           this.setState({selectedToyScent: val})
                                       }}
                                       className={' col grey-text p-0 font-weight-bold'}
                                       onFocus={() => this.getList("toyScentList", "FETCH_PRODUCT_SCENT", "name", "toy scent")}
                                       multiple
                            />
                        </div>
                    </div>
                </div>
                }
                <div className={'col-md-11 mx-auto white rounded-sm border p-0 mt-2'}>
                    <div
                        className={'col-12 p-2 grey lighten-4 border-bottom d-flex justify-content-between align-items-center'}>
                        <b className={"font-weight-bold"}>Food Habit</b>
                        <div className={'row mx-0'}>
                            <a role="button" onClick={() => this.toggle("Preview")}
                               className={"text-primary"}>PREVIEW</a>
                        </div>
                    </div>
                    <div className={"col-12 row m-0 p-0"}>
                        <div className={"col-6 p-2 d-flex float-left"}>
                            <MDBInput onClick={() => this.setState({foodHabit: "None"})}
                                      checked={this.state.foodHabit === "None"} label={"None"} type="radio"
                                      id={"radio_food_habit_none"}/>
                            <MDBInput onClick={() => this.setState({foodHabit: "Veg"})}
                                      checked={this.state.foodHabit === "Veg"} label={"Veg"} type="radio"
                                      id={"radio_food_habit_veg"}/>
                            <MDBInput onClick={() => this.setState({foodHabit: "Non-veg"})}
                                      checked={this.state.foodHabit === "Non-veg"} label={"Non-veg"} type="radio"
                                      id={"radio_food_habit_non-veg"}/>
                        </div>
                    </div>
                </div>
                <div className={'col-md-11 mx-auto white rounded-sm border p-0 mt-2'}>
                    <div
                        className={'col-12 p-2 grey lighten-4 border-bottom d-flex justify-content-between align-items-center'}>
                        <b className={"font-weight-bold"}>Tags & Highlights</b>
                        <div className={'row mx-0'}>
                            <a role="button" onClick={() => this.toggle("Preview")}
                               className={"text-primary"}>PREVIEW</a>
                        </div>
                    </div>
                    <div className={"col-12 row m-0 p-0"}>
                        <div className={"col p-2"}>
                          <b>Tag</b>
                            <ProductSpecifications section={'tag'} getListCallback={(list) => {
                                this.setState({tagList: list})
                            }}/>
                        </div>
                        <div className={"col p-2"}>
                          <b>Highlights</b>
                            <ProductSpecifications section={'highlight'} getListCallback={(list) => {
                                this.setState({highlightList: list})
                            }}/>
                        </div>
                    </div>
                </div>
                <div className={'col-md-11 mx-auto white rounded-sm border p-0 mt-2'}>
                    <div
                        className={'col-12 p-2 grey lighten-4 border-bottom d-flex justify-content-between align-items-center'}>
                        <b className={"font-weight-bold"}>Guaranteed Analysis</b>
                        <div className={'row mx-0'}>
                            <a role="button" onClick={() => this.toggle("Preview")}
                               className={"text-primary"}>PREVIEW</a>
                        </div>
                    </div>
                    {/*<div className={"col-9 row p-0 m-auto"}>*/}
                    {/*    <div className={"col-12 p-2"}>*/}
                    {/*        <b>Guaranteed Analysis</b><br/>*/}
                    {/*        <MDBSelect search selected="Select Guaranteed Analysis"*/}
                    {/*                   options={this.state.guaranteedAnaList} getValue={this.setGuaranteedAna}*/}
                    {/*                   className={'col grey-text p-0 font-weight-bold'}*/}
                    {/*                   onFocus={this.getGAList} multiple/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className={"col-9 row p-0 m-auto"}>
                        <div className={"col-12 p-2"}>
                            <MDBTable bordered striped key={this.state.keyGAT}>
                                <MDBTableHead>
                                    <tr>
                                        <th scope={"col"}>#</th>
                                        <th scope={"col"}>Label</th>
                                        <th scope={"col"}>Value</th>
                                        <th scope={"col"}>Unit</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {this.state.guaranteedAnaList.map((item, index) => (<>
                                            {item.selected === true &&
                                            <tr key={index}>
                                                <th scope={"row"}>{item.displayIndex + 1}</th>
                                                <td width={"400"}><div className={'mt-1'}> {item.text}</div></td>
                                                <td><input background id={"GA" + index} className={"mt-1 form-control"}
                                                              maxLength="5" type="number" min={0}
                                                              onChange={this.handleGAQnty(index)}
                                                              value={item.qnty}/></td>
                                                <td>
                                                    <MDBSelect getValue={this.handleGAUnit(index)}
                                                               className={"p-0 mr-0 ml-0 mb-0 mt-2"}
                                                               onFocus={() => this.getList("packagingUnitList", "FETCH_UNIT", "quantityUnit", "quantity unit")}>
                                                        <MDBSelectInput selected={"Select Unit"}/>
                                                        <MDBSelectOptions search>
                                                            <MDBSelectOption value="" disabled>Select
                                                                Unit</MDBSelectOption>
                                                            {this.state.packagingUnitList.map((itemI, idx) => (
                                                                <MDBSelectOption value={itemI.value} key={idx + "GA"}
                                                                                 selected={this.state.guaranteedAnaList[index].unit == itemI.value}>{itemI.text}</MDBSelectOption>
                                                            ))}
                                                        </MDBSelectOptions>
                                                    </MDBSelect>
                                                </td>
                                            </tr>
                                            }
                                        </>
                                    ))}
                                </MDBTableBody>
                            </MDBTable>
                        </div>
                    </div>
                </div>
                <div className={'col-md-11 mx-auto white rounded-sm border p-0 mt-2'}>
                    <div className={'col-12 p-2 grey lighten-4 border-bottom d-flex justify-content-between align-items-center'}>
                        <b className={"font-weight-bold"}>Ingredients List With Quantity</b>
                        <div className={'row mx-0'}>
                            <a role="button" onClick={() => this.toggle("Preview")}
                               className={"text-primary"}>PREVIEW</a>
                        </div>
                    </div>
                    <div className={"col-9 row p-0 m-auto"}>
                        <div className={"col-12 p-2"}>
                            <b>Ingredients</b><br/>
                            <MDBSelect search selected="Select Ingredients"
                                       options={this.state.productIngredientList}
                                       getValue={this.setIngredients}
                                       className={'col grey-text p-0 font-weight-bold'}
                                       onFocus={this.getIngredientList} multiple/>
                        </div>
                    </div>
                    <div className={"col-9 row p-0 m-auto"}>
                        <div className={"col-12 p-2"}>
                            <MDBTable bordered striped key={this.state.keyIngredient}>
                                <MDBTableHead>
                                    <tr>
                                        <th scope={"col"}>#</th>
                                        <th scope={"col"}>Label</th>
                                        <th scope={"col"}>Value</th>
                                        <th scope={"col"}>Unit</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {this.state.productIngredientList.map((item, index) => (<>
                                            {item.selected === true &&
                                            <tr key={index}>
                                                <th scope={"row"}>{item.displayIndex + 1}</th>
                                                <td width={"400"}><div className={'mt-1'}> {item.text}</div></td>
                                                <td><input background id={"IG" + index} className={"mt-1 form-control"}
                                                              maxLength="5" type="number" min={0}
                                                              onChange={this.handleIngredientQnty(index)}
                                                              value={item.qnty}/></td>
                                                <td>
                                                    <MDBSelect getValue={this.handleIngredientUnit(index)}
                                                               className={"p-0 mr-0 ml-0 mb-0 mt-2"}
                                                               onFocus={() => this.getList("packagingUnitList", "FETCH_UNIT", "quantityUnit", "quantity unit")}>
                                                        <MDBSelectInput selected={"Select Unit"}/>
                                                        <MDBSelectOptions search>
                                                            <MDBSelectOption value="" disabled>Select
                                                                Unit</MDBSelectOption>
                                                            {this.state.packagingUnitList.map((itemI, idx) => (
                                                                <MDBSelectOption value={itemI.value} key={idx + "Ing"}
                                                                                 selected={this.state.productIngredientList[index].unit == itemI.value}>{itemI.text}</MDBSelectOption>
                                                            ))}
                                                        </MDBSelectOptions>
                                                    </MDBSelect>
                                                </td>
                                            </tr>
                                            }
                                        </>
                                    ))}
                                </MDBTableBody>
                            </MDBTable>
                        </div>
                    </div>
                </div>
                <div className={'col-md-11 mx-auto white rounded-sm border p-0 mt-2 add-product-img-button-position-main-box'}>
                    <div className={'col-12 p-2 grey lighten-4 border-bottom d-flex justify-content-between align-items-center'}>
                        <b className={"font-weight-bold"}>Product Images</b>
                    </div>
                    <MultiPicDirectUpload getUploadedFiles={this.getUploadedFiles}/>
                </div>
                <div className={'col-md-11 mx-auto white rounded-sm border p-0 mt-2'}>
                    <div className={'col-12 p-2 grey lighten-4 text-center'}>
                       {/* <b>Add Product</b>*/}
                        <MDBBtn size={'md'} outline onClick={() => this.toggle("Preview")}
                                disabled={this.state.progressStatus}>Preview</MDBBtn>
                        <MDBBtn size={'md'} outline onClick={() => this.props.history.push('/view-products-new')}
                                disabled={this.state.progressStatus}>Back</MDBBtn>
                        <MDBBtn size={'md'} onClick={this.addNewProduct}
                                disabled={this.state.progressStatus}>Save</MDBBtn>
                    </div>
                </div>
                <MDBModal isOpen={this.state.modalPreview} toggle={() => this.toggle("Preview")} size="fluid">
                    <MDBModalHeader toggle={() => this.toggle("Preview")}>Product Details Preview</MDBModalHeader>
                    <MDBModalBody>
                        <ProductDetailsPreview productDetails={this.state}/>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="secondary" onClick={() => this.toggle("Preview")}>Close</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
                <MDBModal isOpen={this.state.modalChooseAction} toggle={() => this.toggle("ChooseAction")}>
                    <MDBModalHeader toggle={() => this.toggle("ChooseAction")}>Choose Action</MDBModalHeader>
                    <MDBModalBody>
                        <div className={"col-md-12 row justify-content-center"}>
                            <button type="button" className="btn btn-primary mr-5"
                                    onClick={() => this.props.history.push('/add-variant/' + this.state.item._id)}>Add
                                Variant
                            </button>
                            <button type="button" className="btn btn-warning mr-n4"
                                    onClick={() => this.props.history.push('/edit-product-new/' + this.state.item._id)}>Edit
                                Product
                            </button>
                        </div>
                    </MDBModalBody>
                </MDBModal>
            </React.Fragment>
        )
    }
}

export default withRouter(AddProduct);