import React, { useEffect, useState } from 'react'
import { toast } from "react-toastify";
import UserStore from '../../stores/userStore';
import Moments from "moment";
import PharmacyService from '../../services/pharmacyService';
import AutoCompleteSearch from '../../newComponents/searchAutoComplete';
import AddStockForm from '../../newComponents/AddStockNew/addstockNewDes';
import { Grid, Typography } from '@material-ui/core';
import ShipSearch from '../../newComponents/searchAutoComplete/indexShipSearch';
import config from '../../config';
import SimpleBackdrop from "../common/overlay";


export default function AddStock(props) {
    let urlParts = window.location.href.split("/");
    const shipmentId = urlParts[urlParts.length - 1]
    const user = UserStore.fetchSessionData();

    const [loading , setLoading] = useState(false)

    const [modalOpen, setModalOpen] = useState(false);
    const [succ, setSucc] = useState(false);
    const [productName, setProductName] = useState("");
    const [productId, setProductId] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [searchTermShip, setSearchTermShip] = useState("");
    const [productData, setProductData] = useState([]);
    const [quantityLabel, setQuantityLabel] = useState("");
    const [quantityPlaceHolder, setQuantityPlaceHolder] = useState("");
    const [isEmptyLoc, setIsEmptyLoc] = useState(false)
    const [isShipmentEmpty, setIsShipmentEmpty] = useState(false)
    const [locations, setLocations] = useState([])
    const [productDetails, setProductDetails] = useState();
    const [shipmentData, setShipmentData] = useState([])
    const [shipmentDetails, setshipmentDetails] = useState()
    const [dynamicFieldName, setDynamicFieldName] = useState("No. of ___")
    const [contentQuantity, setContentQantity] = useState('')
    const [showDiv, setShowdiv] = useState(false)
    const [showDivProduct, setShowdivProduct] = useState(false)
    const [viewDetails, setViewDetails] = useState({})


    useEffect(async () => {
        //   await fetchShipmentDetails();
        fetchStorageLOC()
    }, []);
    const submitForm = async (productInfo, locInfo) => {
        setLoading(true)
        let isEmptyStore = isEmptyStorage(locInfo)
        if (isEmptyStore) {
            setTimeout(() => {
                setIsEmptyLoc(false)
            }, 3000)
            setLoading(false)
            return setIsEmptyLoc(true)
        }
        const { batch, mrp, priceWithoutGST, purchaseDiscount, mfgDate, expDate, quantity, comment, freeQuantity, hsnSac, priceWithGST, gst } = productInfo
        const payload = {
            productId: productDetails?._id,
            batch: batch,
            mrp: parseFloat(mrp),
            priceNoGst: parseFloat(priceWithoutGST),
            gst: parseFloat(gst),
            priceWithGst: parseFloat(priceWithGST),
            discount: parseFloat(purchaseDiscount) || 0,
            hsn: hsnSac,
            mfgDate: Moments(mfgDate, 'MM/YYYY').endOf('month').toISOString(),
            expDate: Moments(expDate, 'MM/YYYY').endOf('month').toISOString(),
            quantity: parseFloat(quantity),
            freeQuantity: parseFloat(freeQuantity),
            commentStorage: "",
            comment: comment,
            shipmentId: shipmentDetails?.[0]?._id,
            storageLocations: locInfo,
        }
        const userData = await UserStore.fetchSessionData();
        if (userData) {
            const status = await PharmacyService.createInventoryV3(userData, [payload]);
            // console.log({ payload, status })
            if (status) {
                // setshipmentDetails([])
                // setProductData([])
                setSucc(true)
                setLoading(false)
            //     setShowdiv(false)
                setContentQantity("")
                setShowdivProduct(false)
                setDynamicFieldName("No. of ___")
                // setshipmentDetails([])
                // setShipmentData([])
                toast.success("Successfully added the Stock")
            }
            else toast.error("Something went wrong !!")
        }
        setLoading(false)


    }



    function isEmptyStorage(arr) {
        for (let i = 0; i < arr.length; i++) {
            const obj = arr[i];
            for (const key in obj) {
                if (obj.hasOwnProperty(key) && obj[key] === "") {
                    return true;
                }
            }
        }
        return false;
    }









    function fetchData(data) {
        if (data !== null) {
            setProductName(data.productName);
            setProductId(data._id);
            setSearchTerm("");
            // setProductData(data);
            let quantityLabel = "No of " + data.retailPackage + " X " + data.contentQuantity + " " + data.quantityUnit
            setQuantityLabel(quantityLabel);
            let quantityPlaceholder = "Enter " + data.retailPackage;
            setQuantityPlaceHolder(quantityPlaceholder);

        }
        setModalOpen(false);
    }
    function handleSearchTerm(e) {
        setSearchTerm(e);
    }
    function handleSearchTermShip(e) {
        setSearchTermShip(e);
    }
    const fetchStorageLOC = async () => {
        let storageInfo = await PharmacyService.fetchStorageLocationV2();
        if (storageInfo === false) {
            toast.error("We are facing some server issue. Please try again later.");
        } else {
            setLocations(storageInfo);
        }
    }
    return (
        <Grid container>
             <SimpleBackdrop status={loading} />
            <Grid item xs={6} style={{ padding: "2em" }}>
                <div>
                    <b>Search Shipment <span className={"mandatory"}>*</span></b>
                    <ShipSearch
                        searchTerm={searchTermShip}
                        handleSearchTerm={handleSearchTermShip}
                        user={user}
                        fetchData={fetchData}
                        setshipmentDetails={setshipmentDetails}
                        setSearchTermShip={setSearchTermShip}
                        clearForm={succ}
                        setClear={setSucc}
                        setShowdiv={setShowdiv}
                        setIsShipmentEmpty={setIsShipmentEmpty}
                        setShipmentData={setShipmentData}
                        shipmentData={shipmentData}

                        setProductViewDetails={setViewDetails}
                        setProductDetails={setProductDetails}
                        setDynamicFieldName={setDynamicFieldName}
                        setContentQantity={setContentQantity}
                        setShowdivProduct={setShowdivProduct}
                        setProductData={setProductData}
                        setSearchTerm = {setSearchTerm}
                    />
                </div>
                <div style={{ marginTop: "1em" }}>
                    <b>Search Product <span className={"mandatory"}>*</span></b>
                    <AutoCompleteSearch
                        searchTerm={searchTerm}
                        handleSearchTerm={handleSearchTerm}
                        user={user}
                        fetchData={fetchData}
                        setProductDetails={setProductDetails}
                        setDynamicFieldName={setDynamicFieldName}
                        setContentQantity={setContentQantity}
                        setShowdiv={setShowdivProduct}
                        showDiv={showDivProduct}
                        setViewDetails={setViewDetails}
                        clearForm={succ}
                        setClear={setSucc} 
                        isShipmentEmpty={isShipmentEmpty}
                        setProductData= {setProductData}
                        productData={productData}
                        />
                </div>

            </Grid>
            <Grid item xs={6} style={{ padding: "1em" }}>
                <div className='detailWrapper'>
                    {showDiv && shipmentDetails?.length > 0 &&
                        <div className='prodInfoBox'>
                            <Typography variant='h6' style={{ marginBottom: ".5em" }}>Shipment Details</Typography>
                            <div>
                                <b>Invoice number</b> : {shipmentDetails?.[0].invoiceNumber}
                            </div>
                            <div>
                                <b>Invoice Date</b> : {Moments(shipmentDetails?.[0].invoiceDate).format("MMM D, YYYY")}
                            </div>
                            <div>
                                <b>Shipment Code</b> : {shipmentDetails?.[0].shipmentCode}
                            </div>
                            <div>
                                <b>Supplier Name</b> : {shipmentDetails?.[0]?.supplierDetails?.supplierName}
                            </div>
                            <div>
                                <b>Total Amount</b> : ₹{parseFloat(shipmentDetails?.[0]?.amount).toFixed(2)}
                            </div>
                            <div>
                                <b>GST Amount</b> : ₹{parseFloat(shipmentDetails?.[0]?.totalTaxAmount).toFixed(2)}
                            </div>
                        </div>}

                    {showDivProduct &&
                        <div className='prodInfoBox'>
                            <Typography variant='h6' style={{ marginBottom: ".5em" }}>Product Details</Typography>
                            <div>
                                <b>Product name</b> : {viewDetails.productName}
                            </div>
                            <div>
                                <b>Content Quantity </b> : {viewDetails?.contentQuantity}
                            </div>
                            <div>
                                <b>MRP</b> : ₹{viewDetails?.mrp}
                            </div>
                            <div>
                                <b>Package type</b> : {viewDetails.retailPackage}
                            </div>
                            <div className='addFormImage'>
                                <img width={"150px"} height={"150px"} src={config.IMAGE_HOST + viewDetails.imageUrl?.[0]?.filePathThumb} />
                            </div>
                        </div>}
                </div>
            </Grid>
            <Grid item xs={12}>
                <div className='addForm'>
                    <AddStockForm
                        key={showDivProduct}
                        productName={productName}
                        submitForm={submitForm}
                        locationInfo={locations}
                        clearForm={succ}
                        shipmentDetails={shipmentDetails}
                        dynamicFieldName={dynamicFieldName}
                        contentQuantity={contentQuantity}
                        isEmptyLoc={isEmptyLoc} />
                </div>
            </Grid>
        </Grid>
    );
}