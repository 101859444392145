import React, { useState, useEffect } from "react";
import {
  MDBBox,
  MDBBtn,
  MDBCol,
  MDBInput,
  MDBRow,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdbreact";
import Moments from "moment";
import AuthService from "../../services/authService";
import { Link } from "react-router-dom";

const SearchCustomer = () => {
  const [term, setTerm] = useState("");
  const [suggestedUser, setSuggestedUser] = useState([]);
  const [dataFetch, setDataFetch] = useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const fetchCustomerData = async (searchTerm) => {
    setIsLoading(true);
    try {
      const data = await AuthService.fetchSearchcustomerData(searchTerm);
      setSuggestedUser(data);
      setDataFetch(true);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  const searchByTerm = async () => {
    const searchTerm = term.trim();

    if (searchTerm === "") {
      setSuggestedUser([]);
    } else {
      fetchCustomerData(searchTerm);
    }
  };

  const formatDate = (inputDate) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = new Date(inputDate).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  };

  function convertAndTitleCase(inputString) {
    // Replace underscores with spaces and convert to title case
    let titleCaseString = inputString
      .replace(/_/g, " ")
      .toLowerCase()
      .replace(/^\w/, (c) => c.toUpperCase());
    return titleCaseString;
  }

  return (
    <React.Fragment>
      <MDBRow className={"mx-0"}>
        <MDBCol size={"3"}>
          <MDBInput
            label={"Customer name/Phone/Order code"}
            value={term}
            onChange={(e) => {
              setTerm(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                searchByTerm();
              }
            }}
          />
        </MDBCol>
        <MDBCol className={"d-flex align-items-center"}>
          <MDBBtn size={"md"} onClick={searchByTerm}>
            Submit
          </MDBBtn>
        </MDBCol>
      </MDBRow>

      <MDBRow className={"mx-0 p-2"}>
        <MDBCol>
          <MDBBox>
            {!isLoading ? (
              <>
                {dataFetch && suggestedUser && suggestedUser.length === 0 ? (
                  <div className="alternate-empty-data">
                    <p>No data to display</p>
                  </div>
                ) : (
                  <>
                    {dataFetch && (
                      <MDBTable>
                        <MDBTableHead>
                          <tr>
                            <th>Customer Code</th>
                            <th>Name</th>
                            <th>Contact No</th>
                            <th>Phone no. Verified</th>
                            <th>Customer Type</th>
                            <th>Member Since</th>
                            <th>Customer Verified</th>
                            <th>IP-Address</th>
                            <th>Device</th>
                            <th>Match</th>
                            <th></th>
                          </tr>
                        </MDBTableHead>

                        <MDBTableBody search="true">
                          {suggestedUser &&
                            suggestedUser.map((item, index) => (
                              <tr key={index}>
                                <td>{item.customerCode}</td>
                                <td>{item.name}</td>
                                <td>
                                  {item.countryCode}&nbsp;{item.phone}
                                </td>
                                <td>
                                  {item.isPhoneVerified === true
                                    ? "Verified"
                                    : ""}
                                </td>
                                <td>
                                  {item.match === "ONLINE_CUSTOMER"
                                    ? "Online customer"
                                    : "Offline customer"}
                                </td>
                                <td>
                                  {Moments(item.createdAt).format("YYYY-MM-DD")
                                    ? formatDate(item.createdAt)
                                    : ""}
                                </td>
                                <td>
                                  {item.isVerified === true ? "Verified" : ""}
                                </td>
                                <td>{item.registrationMetaData?.ip || ""}</td>
                                <td>
                                  {item.registrationMetaData?.device || ""}
                                </td>
                                <td>
                                  {convertAndTitleCase(item.matchKey) || ""}
                                </td>
                                <td>
                                  <Link
                                    to={`/customer-details-viewMore/${item._id}/${item.match}/${item.phone}`}
                                    className="blue-text"
                                  >
                                    View
                                  </Link>
                                </td>
                              </tr>
                            ))}
                        </MDBTableBody>
                      </MDBTable>
                    )}
                  </>
                )}
              </>
            ) : (
              <div><i class="fas fa-spinner">Loading...</i></div>
            )}
          </MDBBox>
        </MDBCol>
      </MDBRow>
    </React.Fragment>
  );
};

export default SearchCustomer;

