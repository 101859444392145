import React, {Suspense} from "react";
import ProcessOrder from "../../components/online-order/processOrder";
export default class ProcessOnlineOrders extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div className="col-lg-12 p-0">
                    <Suspense fallback={null}>
                        <ProcessOrder orderId={this.props.match.params.orderId}/>
                    </Suspense>
                </div>
            </React.Fragment>
        )
    }
}
