import React from "react";
import {Link, withRouter} from "react-router-dom";
import {
    MDBBox,
    MDBBreadcrumb,
    MDBBreadcrumbItem,
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBContainer,
    MDBDataTable,
    MDBFormInline,
    MDBModal,
    MDBModalBody,
    MDBModalFooter,
    MDBModalHeader
} from 'mdbreact';
import UserStore from "../../stores/userStore";
import {toast} from "react-toastify";
import PharmacyService from "../../services/pharmacyService";
import dataTableSuppliers from "../supplier/dataTableSupplier"
import SimpleBackdrop from "../common/overlay";
import UpdateSupplier from "./editSupplier";

class SupplierList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            statusAction: "",
            statusActionBusinessName: "",
            activeSupplierId: 0,
            currentPage: 1,
            pageSize: 0,
            supplierList: [],
            supplierApiResultCame: false,
            progressStatus: false,
            data: {},
            activeSupplier:{},
            modalEdit:false,
            disableRetreatAfterSorting:false
        }
    }

    componentDidMount() {
        this.fetchSupplier()
    }


    toggleEdit = () => {
        this.setState({
            modalEdit: !this.state.modalEdit
        });
    }

    onEditComplete = (row) => {
        this.toggleEdit()
        this.fetchSupplier()
    }

    // This triggers from MDBDataTable, aim is to redirect to edit page
    onEdit = (row) => {
        this.setState({activeSupplier: row})
        this.toggleEdit()
        //this.props.history.push('/edit-incoming-shipment/' + row._id);
    }


    // onEdit = (row) => {
    //     this.props.history.push('/edit-supplier/' + row._id);
    // }
    initChangeStatus = (row) => {
        this.setState({
            activeSupplierId: row._id,
            statusAction: (row.isActive === "Y") ? "deactivate" : "activate",
            statusActionBusinessName: row.supplierName
        });
        this.toggle();
    }
    updateSupplierStatus = async () => {
        let userData = await UserStore.fetchSessionData();
        this.setState({progressStatus: true});
        let status = (this.state.statusAction === "activate") ? "Y" : "N";
        let res = await PharmacyService.updateSupplierStatus(this.state, status, userData);
        this.setState({progressStatus: false});
        this.toggle()
        this.setState({activeSupplierId: 0, statusAction: "", statusActionBusinessName: ""});
        if (res === false) {
            toast.error("Something went wrong.");
            return true;
        } else {
            toast.success("Supplier status updated successfully.");
            this.fetchSupplier();
        }
    }
    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    }
    fetchSupplier = async () => {
        await this.setState({disableRetreatAfterSorting:true});
        let userData = await UserStore.fetchSessionData();
        let res = [];
        if (!window.suppliers || window.suppliers === null) {
            this.setState({progressStatus: true});
            res = await PharmacyService.fetchSupplier(userData, this.state.currentPage, this.state.pageSize);
            window.suppliers = res;
        } else {
            res = window.suppliers;
        }
        this.setState({supplierApiResultCame: true});
        this.setState({progressStatus: false});
        if (res.length > 0) {
            try {
                this.setState({progressStatus: false, supplierList: res});
                let dataTablePayload = {
                    columns: dataTableSuppliers.suppliers,
                    rows: res.map((row, index) => {
                        row["handleAddress"] = <small>
                            {row.addressLine1}{row.addressLine2 &&
                        <span>,&nbsp;{row.addressLine2}</span>
                        }{row.area &&
                        <span>,&nbsp;{row.area}</span>
                        }
                            <br/>
                            {row.city}, {row.state} <br/>
                            {row.pinCode}, {row.country}<br/>
                            {row.landmark &&
                            <span>{row.landmark}<br></br> </span>
                            }
                        </small>;
                        row["handleEdit"] =
                            <div color="primary" className={"blue-text btn-link cursor-pointer"} size="sm"
                                 onClick={() => {
                                     this.onEdit(row)
                                 }}>Edit</div>;
                        row["handleDelete"] =
                            <div color="primary" className={"blue-text btn-link cursor-pointer"} size="sm"
                                 onClick={() => {
                                     this.initChangeStatus(row)
                                 }}>{(row.isActive === "Y") ? "Deactivate" : "Activate"}</div>;
                        return row;
                    })
                };
                this.setState({data: dataTablePayload});
            } catch (e) {
                toast.error("Something went wrong while fetching manufacturer.")
            }
        }
        await this.setState({disableRetreatAfterSorting:false});
        this.syncData();
    }
    syncData = async () => {
        await this.setState({disableRetreatAfterSorting:true});
        let userData = await UserStore.fetchSessionData();
        let res = [];
        res = await PharmacyService.fetchSupplier(userData, this.state.currentPage, this.state.pageSize);
        window.suppliers = res;
        this.setState({supplierApiResultCame: true});
        this.setState({progressStatus: false});
        if (res.length > 0) {
            try {
                this.setState({progressStatus: false, supplierList: res});
                let dataTablePayload = {
                    columns: dataTableSuppliers.suppliers,
                    rows: res.map((row, index) => {
                        row["handleAddress"] = <small>
                            {row.addressLine1}{row.addressLine2 &&
                        <span>,&nbsp;{row.addressLine2}</span>
                        }{row.area &&
                        <span>,&nbsp;{row.area}</span>
                        }
                            <br/>
                            {row.city}, {row.state} <br/>
                            {row.pinCode}, {row.country}<br/>
                            {row.landmark &&
                            <span>{row.landmark}<br></br> </span>
                            }
                        </small>;
                        row["handleEdit"] =
                            <div color="primary" className={"blue-text btn-link cursor-pointer"} size="sm"
                                 onClick={() => {
                                     this.onEdit(row)
                                 }}>Edit</div>;
                        row["handleDelete"] =
                            <div color="primary" className={"blue-text btn-link cursor-pointer"} size="sm"
                                 onClick={() => {
                                     this.initChangeStatus(row)
                                 }}>{(row.isActive === "Y") ? "Deactivate" : "Activate"}</div>;
                        return row;
                    })
                };
                this.setState({data: dataTablePayload});
                await this.setState({disableRetreatAfterSorting:false});
            } catch (e) {
                toast.error("Something went wrong while fetching manufacturer.")
            }
        }
    }
    goToNewSupplier = () => {
        this.props.history.push('/add-supplier');
    }

    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.progressStatus}/>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>View Suppliers</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <MDBContainer className={'w-100 mw-100'}>
                    <MDBBox>
                        <MDBCard className={"mt-2"} style={{"marginTop": "0px"}}>
                            <MDBCardHeader>
                                <span className={"float-left title"}>View Suppliers</span>
                                <MDBFormInline className="float-right mr-auto m-0">
                                    <MDBBtn color="default" size="md" onClick={this.goToNewSupplier} className="">
                                        Add supplier
                                    </MDBBtn>
                                </MDBFormInline>
                            </MDBCardHeader>
                            <MDBCardBody>
                                {(this.state.supplierApiResultCame && this.state.supplierList.length === 0) &&
                                <div className={'no-data-container'}>No Supplier found.</div>
                                }
                                {this.state.supplierList.length > 0 &&
                                // <MDBDataTableV5 hover entriesOptions={[10,15, 20, 25]} entries={10} pagesAmount={4}
                                //     data={this.state.data} striped barReverse materialSearch searchTop searchBottom={false}/>
                                <MDBDataTable
                                    striped

                                    data={this.state.data}
                                    materialSearch
                                    noBottomColumns
                                    hover
                                    disableRetreatAfterSorting={this.state.disableRetreatAfterSorting}
                                />
                                }
                            </MDBCardBody>
                        </MDBCard>
                    </MDBBox>
                    <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
                        <MDBModalHeader toggle={this.toggle}>Update Supplier Status</MDBModalHeader>
                        <MDBModalBody>
                            Are you sure you want
                            to {this.state.statusAction} supplier {this.state.statusActionBusinessName}?
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn onClick={this.updateSupplierStatus}
                                    disabled={this.state.progressStatus}>{this.state.statusAction}</MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>
                    <MDBModal isOpen={this.state.modalEdit} toggle={this.toggleEdit} size={"fluid"}>
                        <MDBModalHeader toggle={this.toggleEdit}></MDBModalHeader>
                        <MDBModalBody className={"float-center"}>
                            <UpdateSupplier loadedOnPopup={true} onEditComplete={this.onEditComplete} supplierId={this.state.activeSupplier._id}/>
                        </MDBModalBody>
                    </MDBModal>

                </MDBContainer>
            </React.Fragment>
        );
    }
}

export default withRouter(SupplierList)
