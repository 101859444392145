import React from "react";
import AddGroupCmp from "../../components/productGrouping/addGroup";
import {MDBBreadcrumb, MDBBreadcrumbItem} from "mdbreact";
import {Link} from "react-router-dom";
class AddGroup extends React.Component {
    render() {
        return(
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem><Link to={'/product-group-list'}>List Groups</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Add Product Group</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <AddGroupCmp/>
            </React.Fragment>
        )
    }
}
export default AddGroup;