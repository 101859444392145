import React from "react";
import {
    MDBBtn,
    MDBBox,
    MDBModal,
    MDBModalHeader,
    MDBModalBody,
    MDBModalFooter
} from 'mdbreact';
var Barcode = require('react-barcode');

export default class ViewBarCode extends React.Component {
    toggle=()=>{
        this.props.toggleBarcode(null);
    }
    render() {
        return(
            <React.Fragment>
                <MDBModal isOpen={this.props.barCodeModal} toggle={this.toggle}>
                    <MDBModalHeader toggle={this.toggle}>Bar Code</MDBModalHeader>
                    <MDBModalBody>
                        {this.props.selectedBarCode !== 0 &&
                        <Barcode value={this.props.selectedBarCode}></Barcode>
                        }
                        {this.props.selectedBarCode === 0 &&
                        <div className={'no-data-container'}>No barcode found.</div>
                        }
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn size={'md'} onClick={this.toggle}>Close</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
            </React.Fragment>
        )
    }
}