import React, { Component } from "react";
import UserStore from "../../stores/userStore";
import SimpleBackdrop from "../common/overlay";
import config from "../../config";
import { toast } from "react-toastify";
import {
  MDBBox,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBInput,
  MDBRow,
  MDBSpinner,
} from "mdbreact";
import PharmacyService from "../../services/pharmacyService";
import CmsService from "../../services/cmsService";

export default class AddMobileBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: {},
      isLoading: false,
      activeBanner: this.props.banner !== null ? this.props.banner : null,
      mobileBannerSpinner: false,
      imageLink: "",
      bannerName: "",
      docsMob: "",
      bannerId: "",
    };
  }

  async componentDidMount() {
    let userData = await UserStore.fetchSessionData();

    this.setState({ userData });

    if (this.state.activeBanner !== null) {
      this.setState({
        bannerId: this.state.activeBanner._id,

        docsMob: this.state.activeBanner.imageLink,
      });
    }
  }

  getBanner = async (event) => {
    const data = new FormData();

    data.append("fileName", event.target.files[0]);

    this.setState({ mobileBannerSpinner: true });
    let fileUpload = await PharmacyService.uploadDoc(data, this.state.userData);
    if (fileUpload) {
      this.setState({ mobileBannerSpinner: false, docsMob: fileUpload.path });
    }
  };

  save = async () => {
    if (this.state.docsMob === "") {
      toast.error("Please upload an image for mobile.");
      return false;
    }
    this.setState({ isLoading: true });
    let status = false;
    if (this.state.activeBanner !== null) {
      let payload = {
        _id: this.state.activeBanner._id,
        imageMob: this.state.docsMob,
      };
      status = await CmsService.saveMobBanner(
        this.state.userData,
        payload,
        "EDIT"
      );
    } else {
      let payload = {
        imageMob: this.state.docsMob,
      };
      status = await CmsService.saveMobBanner(
        this.state.userData,
        payload,
        "ADD"
      );
    }
    this.setState({ isLoading: false });
    if (status === false) {
      toast.error("We are facing some server issue. Please try again later.");
    } else {
      toast.success("Banner configured successfully.");
      this.props.toggle();
      await this.props.refreshPage();
    }
  };
  render() {
    return (
      <React.Fragment>
        <SimpleBackdrop status={this.state.isLoading} />

        <div className="row d-flex justify-content-center mb-5">
          <div className={" col-3 m-2 img-outlet text-center "}>
            {this.state.mobileBannerSpinner === true && <MDBSpinner small />}
            <div className={"banner-type"}>Mobile</div>

            {this.state.docsMob !== "" &&
              this.state.mobileBannerSpinner === false && (
                <img
                  className={"my-4"}
                  src={config.IMAGE_HOST + this.state.docsMob}
                  width="300"
                  height="70"
                />
              )}

            {this.state.docsMob === "" &&
              this.state.mobileBannerSpinner === false && (
                <img
                  className={"my-4"}
                  src={require("../common/img/add_image.svg")}
                />
              )}
            <div className={"text-center"}>
              <MDBInput
                className={"px-2"}
                type={"file"}
                onChange={this.getBanner}
              ></MDBInput>
            </div>
          </div>
        </div>

        <div className={"text-center"}>
          <MDBBtn size={"md"} outline onClick={this.props.toggle}>
            Cancel
          </MDBBtn>
          <MDBBtn
            size={"md"}
            onClick={this.save}
            disabled={this.state.mobileBannerSpinner === true ? true : false}
          >
            Save
          </MDBBtn>
        </div>
      </React.Fragment>
    );
  }
}
