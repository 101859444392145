import React, { useEffect, useState } from "react";
import TransferStockService from "../../services/transferStockService";
import UserStore from "../../stores/userStore";
import { toast } from "react-toastify";
import NewTable from "../../newComponents/NewTable/NewTable";
import { STOCK_TABLE_WITH_LOCATION_TABLE } from "../../utility/constants";
import { formatStockByLocation } from "../../utility/tableUtils";
import SimpleBackdrop from "../../components/common/overlay";
import NewPagination from "../../newComponents/NewPagination/NewPagination";
import FilterStockTable from "./FilterStockTable";
import { Button } from "@material-ui/core";
import RelocateModal from "./RelocateModal";

const RelocateRack = () => {
  const [filter, setFilter] = useState({
    locationId: "",
    floorId: "",
    rackId: "",
    boxId: "",
  });
  const [userData, setUserData] = useState({});
  const [listData, setListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [perPage, setPerPage] = useState(0);
  const [page, setPage] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const filterHandler = async (pageNo) => {
    setPage(pageNo);
    if (filter.locationId && filter.floorId && filter.rackId) {
      let payload;
      if (filter.boxId) {
        payload = {
          locationId: filter.locationId,
          floorId: filter.floorId,
          rackId: filter.rackId,
          boxId: filter.boxId,
        };
      } else {
        payload = {
          locationId: filter.locationId,
          floorId: filter.floorId,
          rackId: filter.rackId,
        };
      }
      setLoading(true);
      const listData = await TransferStockService.fetchStockByLocation(
        payload,
        pageNo + 1,
        userData?.sessionToken
      );
      if (listData) {
        setListData(listData?.data);
        setTotalRecords(listData?.totalRecords);
        setPerPage(listData?.recordPerPage);
      } else {
        toast.error("Something went wrong");
        setListData([]);
        setTotalRecords(0);
        setPerPage(0);
      }
      setLoading(false);
    } else {
      setListData([]);
      setTotalRecords(0);
      setPerPage(0);
    }
  };

  const handlePageChange = (index) => {
    setPage(index);
    filterHandler(index);
  };

  const relocateHandler = async (relocateTo) => {
    if (
      relocateTo.locationId &&
      relocateTo.floorId &&
      relocateTo.rackId &&
      relocateTo.boxId
    ) {
      const payload = {
        locationIdFrom: filter?.locationId,
        floorIdFrom: filter?.floorId,
        rackIdFrom: filter?.rackId,
        ...(filter?.boxId && { boxIdFrom: filter?.boxId }),
        locationIdTo: relocateTo?.locationId,
        floorIdTo: relocateTo?.floorId,
        rackIdTo: relocateTo?.rackId,
        boxIdTo: relocateTo?.boxId,
      };
      setLoading(true);
      const res = await TransferStockService.relocateRack(
        payload,
        userData?.sessionToken
      );
      if (res) {
        toast.success("Rack relocatted succesfully");
        setIsModalOpen(false);
        setListData([]);
        setTotalRecords(0);
        setPerPage(0);
        setPage(0)
        setFilter({
          locationId: "",
          floorId: "",
          rackId: "",
          boxId: "",
        })
      } else {
        toast.error("Something went wrong");
      }
      setLoading(false);
    } else {
      toast.error("Please select location, floor, rack & box");
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      let userData = await UserStore.fetchSessionData();
      setUserData(userData);
    };
    fetchUserData();
  }, []);


  return (
    <>
      <SimpleBackdrop status={loading} />
      <div className="m-4">
        <FilterStockTable
          filter={filter}
          setFilter={setFilter}
          filterHandler={() => filterHandler(0)}
        />
        {listData.length > 0 && (
          <div className="mt-4">
            <h6>Showing Results for: </h6>
            <div className="d-flex items-center pt-3 bg-light">
              <div className="col-md-3 d-flex items-center">
                <div className="mr-3">
                  <p>Location: </p>
                  <p>Floor: </p>
                </div>
                <div>
                  <p>{listData?.[0]?.locationName}</p>
                  <p>{listData?.[0]?.floorName}</p>
                </div>
              </div>
              <div className="col-md-3 d-flex items-center">
                <div className="mr-3">
                  <p>Rack: </p>
                  <p>Box: </p>
                </div>
                <div>
                  <p>{listData?.[0]?.rackName}</p>
                  <p>{listData.every(item => filter.boxId === item.boxId) && listData?.[0]?.boxName}</p>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className={"mt-4 d-flex justify-content-end"}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setIsModalOpen(true)}
            size="small"
            disabled={!(listData.length > 0)}
          >
            Relocate Rack
          </Button>
        </div>

        <div className="mt-2">
          <NewTable
            columns={STOCK_TABLE_WITH_LOCATION_TABLE}
            data={formatStockByLocation(listData)}
            pagination={false}
          />
          {totalRecords / perPage > 1 && (
            <div className="my-3 d-flex flex-row justify-content-end w-100">
              <NewPagination
                page={page}
                pageLimit={perPage}
                totalRecords={totalRecords}
                handleChange={handlePageChange}
                type="primary"
                color="#5374B3"
              />
            </div>
          )}
        </div>
      </div>
      {isModalOpen && (
        <RelocateModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          relocateHandler={relocateHandler}
        />
      )}
    </>
  );
};

export default RelocateRack;
