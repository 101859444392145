const dataTableDoctor= {
    doctor:[
        {
            label: 'Doctor',
            field: 'name',
            sort: 'asc',
            width: 300
        },
        {
            label: 'Speciality',
            field: 'speciality',
            sort: 'asc',
            width: 300
        },
        {
            label: 'Email',
            field: 'email',
            sort: 'asc',
            width: 300
        },
        {
            label: 'Phone',
            field: 'contactNo',
            sort: 'asc',
            width: 100
        },
        {
            label: 'Area',
            field: 'area',
            sort: 'asc',
            width: 100
        },
        {
            label: 'City',
            field: 'city',
            sort: 'asc',
            width: 100
        },
        {
            label: 'State',
            field: 'state',
            sort: 'asc',
            width: 100
        },
        // {
        //     label: 'Doctor Since',
        //     field: 'customerSince',
        //     sort:'disabled',
        //     width: 50
        // },
        {
            label: '',
            field: 'handleEdit',
            sort:'disabled',
            width: 50
        },
        {
            label: '',
            field: 'handleStatus',
            sort:'disabled',
            width: 50
        }
    ]
}
export default dataTableDoctor;
