import React from "react";
import UserStore from "../../stores/userStore";
import OrderService from "../../services/orderService";
import {toast} from "react-toastify";
import SimpleBackdrop from "../common/overlay";
import {
    MDBFormInline, MDBIcon, MDBTable, MDBTableBody, MDBTableHead, MDBModalBody,
    MDBSelect, MDBModal, MDBModalHeader, MDBModalFooter, MDBBtn, MDBInput
} from "mdbreact";
import Moments from "moment";
import GetDateRange from "../common/getDateRange";
import MaterialTable from "material-table";
import {CSVLink} from "react-csv";
import {withRouter} from "react-router-dom";
import ViewOrder from "../online-order/viewOrder";
import utility from "../../utility/utility";
import OrderInvoice from "../order-history/orderInvoice";
import { ro } from "date-fns/locale";

const headers = [
    {label: "Created At", key: "createdAt"},
    {label: "Customer Name", key: "orderData.customerDetails.name"},
    {label: "Phone No", key: "orderData.customerDetails.contactNo"},
    {label: "Rating", key: "rating"},
    {label: "Feedback Message", key: "feedbackMsg"},
    {label: "Order Id", key: "orderCode"}
];

class FeedbackList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: {},
            startDate: new Date(),
            endDate: new Date(),
            feedBackList: [],
            isLoading: false,
            openSMSModal: false,
            searchKeyWord: "",
            paginatedFeedbackList: [],
            rating: "ALL", message: "ALL",
            ratings: [
                {text: "All", value: "ALL", checked: true},
                {text: "Excellent", value: 5},
                {text: "Good", value: 4},
                {text: "Average", value: 3},
                {text: "Bad", value: 2},
                {text: "Poor", value: 1}
            ],
            messageFilter: [
                {text: "All", value: "ALL", checked: true},
                {text: "With comments", value: "WITH_COMMENTS"},
                {text: "Without comments", value: "WITHOUT_COMMENTS"},
            ],
            modalViewCallLog: false, activeFeedback: null, modalAddCallLog: false, summary: "",
            loading: false,
            modalViewOrder: false,
            activeItem: null,
            orderMode: {}, isActiveOrderAPrescriptionDrug: false,
            subjects: [
                {text: "Discount", value: "DISCOUNT"},
                {text: "Payment issue", value: "PAYMENT_ISSUE"},
                {text: "Shipping issue", value: "SHIPPING_ISSUE"},
                {text: "Packaging issue", value: "PACKAGING_ISSUE"},
                {text: "Other issue", value: "OTHER_ISSUE"},
            ],
            selectedSubject:""
        }
    }

    async componentDidMount() {
        let userData = await UserStore.fetchSessionData()
        this.setState({userData})
        await this.fetchFeedbackList();
    }

    fetchFeedbackList = async () => {
        this.setState({isLoading: true})
        let res = await OrderService.fetchFeedback(this.state.userData.sessionToken, Moments(this.state.startDate).format("YYYY-MM-DD"), Moments(this.state.endDate).format("YYYY-MM-DD"), this.state.rating, this.state.message)
        if (res) {
            this.setState({feedBackList: res});
        } else {
            toast.error("Something went wrong while fetching order feedback list.")
        }
        this.setState({isLoading: false})
    }
    getDateRangeCallback = async (startDate, endDate) => {
        if (startDate > endDate) {
            toast.info("Start date is greater than the end date. Please adjust the date range again.")
            return
        }
        let endDateThreeMonthGap = Moments(startDate).add(3, "months");
        if (endDate > endDateThreeMonthGap) {
            toast.error("The date difference can't be more than three months.")
            return
        }
        await this.setState({startDate, endDate})
        await this.fetchFeedbackList();
    }
    getFeedbackWithRating = async (val) => {
        await this.setState({rating: val})
        await this.fetchFeedbackList()
    }
    showRating = (rating) => {
        let R = []
        for (let i = 0; i < rating; i++) {
            R.push(<MDBIcon icon="star"/>)
        }
        return R
    }
    toggle = (section) => {
        this.setState({["modal" + section]: !this.state["modal" + section]})
    }
    setActiveFeedback = (item, section) => {
        this.setState({activeFeedback: item, summary: ""})
        this.toggle(section)
    }
    save = async () => {
        if (this.state.summary.trim() === "") {
            toast.error("Please enter the call summary.")
            return false
        }
        let payload = {
            feedbackId: this.state.activeFeedback._id,
            summary: this.state.summary,
            subject: this.state.selectedSubject
        }
        this.setState({isLoading: true})
        let res = await OrderService.addCallLog(payload, this.state.userData.sessionToken)
        this.setState({isLoading: false})
        if (res) {
            toast.success("Call log added successfully.")
            this.toggle("AddCallLog")
            await this.fetchFeedbackList();
        } else {
            toast.error("Something went wrong while adding call log.")
        }
    }
    getCustomerName = (item) => {
        if (item.hasOwnProperty("orderData") && item.orderData.hasOwnProperty("customerDetails")) {
            return item.orderData.customerDetails.name
        } else if (item.hasOwnProperty("orderData") && item.orderData.hasOwnProperty("createdBy")) {
            return item.orderData.createdBy.name
        }
        return ""
    }
    getPhoneNo = (item) => {
        if (item.hasOwnProperty("orderData") && item.orderData.hasOwnProperty("customerDetails")) {
            return item.orderData.customerDetails.contactNo
        }
        return ""
    }
    getFeedbackWithMessage = async (val) => {
        await this.setState({message: val})
        await this.fetchFeedbackList()
    }
    viewOrder = async (rowData) => {
        let orderData = rowData.orderData
        await this.setState({orderMode: orderData.orderMode})
        if (orderData.orderMode === "USER_CART") {
            let data = await OrderService.fetchOnlineOrderById(this.state.userData.sessionToken, orderData._id);
            if (data) {
                this.setState({activeItem: data, modalViewOrder: true})
            } else {
                toast.error("Something went wrong while fetching online order details.")
            }
        } else {
            this.setState({isActiveOrderAPrescriptionDrug: false})
            for (let i = 0; i < orderData.products.length; i++) {
                if (orderData.products[i].medicineType.trim() === "OTC" || orderData.products[i].medicineType.trim() === "Schedule X" || orderData.products[i].medicineType.trim() === "Schedule H" || orderData.products[i].medicineType.trim() === "Schedule H1" || orderData.products[i].medicineType.trim() === "PRESCRIPTION DRUG" || orderData.products[i].medicineType.trim() === "PRESCRIPTION DRUGS") {
                    this.setState({isActiveOrderAPrescriptionDrug: true})
                }
                orderData.products[i].calculatedContentQuantity = utility.calculateContentQuantity(orderData.products[i].contentQuantity, orderData.products[i].looseSaleAllowed)
            }
            this.setState({activeItem: orderData, modalViewOrder: true})
        }

    }

    sendGoogleSMS = async (rowData) => {
        this.setState({isLoading: true})
        let orderData = rowData.orderData
        let phone = ""
        if (orderData.orderMode === "USER_CART") {
            let data = await OrderService.fetchOnlineOrderById(this.state.userData.sessionToken, orderData._id);
            if (data) {
                //this.setState({activeItem: data, modalViewOrder: true})
                phone = data.customerDetails.phone
            } else {
                toast.error("Something went wrong while fetching online order details.")
            }
        } else {
            this.setState({isActiveOrderAPrescriptionDrug: false})
            for (let i = 0; i < orderData.products.length; i++) {
                if (orderData.products[i].medicineType.trim() === "OTC" || orderData.products[i].medicineType.trim() === "Schedule X" || orderData.products[i].medicineType.trim() === "Schedule H" || orderData.products[i].medicineType.trim() === "Schedule H1" || orderData.products[i].medicineType.trim() === "PRESCRIPTION DRUG" || orderData.products[i].medicineType.trim() === "PRESCRIPTION DRUGS") {
                    this.setState({isActiveOrderAPrescriptionDrug: true})
                }
                orderData.products[i].calculatedContentQuantity = utility.calculateContentQuantity(orderData.products[i].contentQuantity, orderData.products[i].looseSaleAllowed)
            }
            phone = orderData.customerDetails.contactNo
        }

        let res = await OrderService.sendGoogleReviewSMS(phone, rowData._id)
        if(res && res.hasOwnProperty("code")){
            await this.fetchFeedbackList();
            if(res.code == "6920"){
                toast.success("We sent him a google review message previously.")
            } else if(res.code == "6921"){
                toast.success("Google business review SMS sent. ")
               
            }
        }
        this.setState({isLoading: false})
        this.setState({ openSMSModal: false })

    }


    sendGoogleMessageRender = (rowData) => {
        if(rowData.hasOwnProperty("isSMSSent") && rowData.isSMSSent == "Y"){
            return <span className={"text-primary green-text"}
                      >SMS Sent on {utility.convertToReadbleDateTimeUtc( rowData.smsSentAt )} </span>
        } else if(rowData.rating>2) {
            return ( 
            <>
              <MDBBtn className="text-nowrap" color="warning" onClick={() => this.setState({openSMSModal:true})}>Send SMS</MDBBtn>
            <MDBModal isOpen={this.state.openSMSModal} className="sms_modal" backdropClassName="smsbackdrop_overlay">
            <MDBModalHeader>Are You Sure?</MDBModalHeader>
            <MDBModalBody>
                Are you sure you want to send an SMS
            </MDBModalBody>
            <MDBModalFooter>
                <MDBBtn color="default" outline  onClick={() => this.sendGoogleSMS(rowData)}>{this.state.isLoading ? "Please Wait" : "Send SMS"}</MDBBtn>
                <MDBBtn color="default" onClick={() => this.setState({ openSMSModal: false })}>Close</MDBBtn>
            </MDBModalFooter>
        </MDBModal>
        </>
            )
            
            
            
          
        }

    }

    getOrderMode=(rowData)=>{
        let orderData = rowData.orderData
        if(orderData.orderMode === "USER_CART" || orderData.orderMode==="PRESCRIPTION" || orderData.orderMode === "USERCART"){
            return "Online"
        } else {
            return "Offline"
        }
    }
    getSubject=async (val)=>{
        await this.setState({selectedSubject: val[0]})
    }
    formatSubject=(subject)=>{
        if(subject === "DISCOUNT"){
            return "Discount"
        } else if(subject === "PAYMENT_ISSUE"){
            return "Payment Issue"
        } else if(subject === "SHIPPING_ISSUE"){
            return "Shipping Issue"
        } else if(subject === "PACKAGING_ISSUE"){
            return "Packaging Issue"
        } else if(subject === "OTHER_ISSUE"){
            return "Other Issue"
        }
    }
    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.isLoading}/>
                <div className={"col-12 row m-0 pb-2"}>
                    <div className={'col-md-6 p-0'}>
                        <GetDateRange getDateRangeCallback={this.getDateRangeCallback}/>
                    </div>
                    {/*<MDBCol md="3" className={'p-0 my-2'}>*/}
                    {/*    <input className="form-control" type="text"*/}
                    {/*           placeholder="Search by phone number or customer name" aria-label="Search"/>*/}
                    {/*</MDBCol>*/}
                    <div className={'col-md-6 p-0 '}>
                        <div className={'row mx-0 float-right'}>
                            <MDBFormInline className="px-2 my-2">
                                <MDBSelect className={' rounded-top rounded-bottom'} outline={true}
                                           options={this.state.messageFilter}
                                           label="Message"
                                           getValue={(val) => this.getFeedbackWithMessage(val)}
                                />
                            </MDBFormInline>
                            <MDBFormInline className="m-2">
                                <MDBSelect className={' rounded-top rounded-bottom'} outline={true}
                                           options={this.state.ratings}
                                           label="Select Rating"
                                           getValue={(val) => this.getFeedbackWithRating(val)}
                                />
                            </MDBFormInline>
                            <CSVLink data={this.state.feedBackList} headers={headers} target="_blank"
                                     filename={"feedback_list_" + Moments(this.state.startDate).format("MMM, YYYY") + "_" + Moments(this.state.endDate).format("MMM, YYYY") + ".csv"}>
                                <MDBBtn className={'mt-3'} size={'md'} disabled={this.state.isLoading}><i
                                    className="fas fa-download"></i>&nbsp;Export</MDBBtn>
                            </CSVLink>
                        </div>
                    </div>
                </div>
                <div className={"col-12 row p-0 m-0"}>
                    <div className={"col-12"}>
                        <MaterialTable title="Feedbacks"
                                       columns={[
                                           {
                                               title: 'Date',
                                               field: 'createdAt',
                                               render: rowData => Moments(rowData.createdAt).format("MMM DD, YYYY h:mm A")
                                           },
                                           {
                                            title: 'Order Mode',
                                            field: 'orderMode',
                                            render: rowData => this.getOrderMode(rowData)
                                            },
                                           {
                                               title: 'Customer Name',
                                               field: 'name',
                                               render: rowData => this.getCustomerName(rowData)
                                           },
                                           {
                                               title: 'Phone No',
                                               field: 'phoneNo',
                                               render: rowData => this.getPhoneNo(rowData)
                                           },
                                           {
                                               title: 'Rating',
                                               field: 'rating',
                                               render: rowData => this.showRating(rowData.rating)
                                           },
                                           {title: 'Feedback Message', field: 'feedbackMsg'},
                                           {
                                               title: 'Order Id', field: 'orderCode', render: rowData => {
                                                   return rowData.hasOwnProperty("orderData") && rowData.orderData.orderCode ? rowData.orderData.orderCode : ""
                                               }
                                           },
                                           {
                                               title: 'Call logs', field: '', render: rowData => <>
                                                   {rowData.hasOwnProperty("callLog") &&
                                                   <a className={"text-primary"}
                                                      onClick={() => this.setActiveFeedback(rowData, "ViewCallLog")}>View&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</a>
                                                   }
                                                   <a className={"text-primary"}
                                                      onClick={() => this.setActiveFeedback(rowData, "AddCallLog")}>Add</a>
                                               </>
                                           },
                                           {
                                               title: 'Order',
                                               field: '',
                                               render: rowData => <a className={"text-primary"}
                                                                     onClick={() => this.viewOrder(rowData)}>View</a>
                                           },
                                           {
                                               title: 'Google Review SMS',
                                               field: '',
                                               render: rowData => this.sendGoogleMessageRender(rowData)
                                           }
                                       ]}
                                       data={this.state.feedBackList}
                                       options={{
                                           pageSize: 10,
                                           pageSizeOptions: [10, 50, 100],
                                           toolbar: false,
                                           emptyRowsWhenPaging: false,
                                       }}
                        />
                    </div>
                </div>
                <MDBModal isOpen={this.state.modalViewCallLog} toggle={() => this.toggle("ViewCallLog")} size={"lg"}>
                    <MDBModalHeader toggle={() => this.toggle("ViewCallLog")}>Call Logs</MDBModalHeader>
                    <MDBModalBody>
                        {(this.state.activeFeedback && this.state.activeFeedback.hasOwnProperty("callLog") && this.state.activeFeedback.callLog.length > 0) &&
                        <MDBTable>
                            <MDBTableHead>
                                <tr>
                                    <th>#</th>
                                    <th>Created At</th>
                                    <th>Subject</th>
                                    <th>Summary</th>
                                    <th>Created By</th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                {this.state.activeFeedback.callLog.map((row, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{Moments(row.createdAt).format("MMM DD, YYYY h:mm A")}</td>
                                        <td>{row.hasOwnProperty("subject")?this.formatSubject(row.subject):"Other issue"}</td>
                                        <td><p className={"p-1 justify-text-center"}>{row.summary}</p></td>
                                        <td>{row.createdBy.name}</td>
                                    </tr>
                                ))}
                            </MDBTableBody>
                        </MDBTable>
                        }
                    </MDBModalBody>
                </MDBModal>
                <MDBModal isOpen={this.state.modalAddCallLog} toggle={() => this.toggle("AddCallLog")} size="lg">
                    <MDBModalHeader toggle={() => this.toggle("AddCallLog")}>Add call summary</MDBModalHeader>
                    <MDBModalBody>
                        <div className={"row mt-2"}>
                            <div className={"col"}>
                            <MDBSelect className={' rounded-top rounded-bottom'} outline={true}
                                           options={this.state.subjects}
                                           label="Subject"
                                           getValue={(val) => this.getSubject(val)}
                                />
                                <MDBInput type="textarea" label="Summary *" rows="5" outline={true}
                                          value={this.state.summary} onChange={(e) => {
                                    this.setState({summary: e.target.value})
                                }} className={'form-control rounded'} on/>
                            </div>
                        </div>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="default" outline onClick={() => this.toggle("AddCallLog")}>Close</MDBBtn>
                        <MDBBtn color="default" onClick={this.save}>Save</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
                <MDBModal isOpen={this.state.modalViewOrder} toggle={() => this.toggle("ViewOrder")}
                          size={"fluid"} fullHeight={true} position={"top"}>
                    <MDBModalHeader toggle={() => this.toggle("ViewOrder")}>View Order</MDBModalHeader>
                    <MDBModalBody className={'view-order-sticky'}>
                        {this.state.orderMode === "USER_CART" && this.state.activeItem !== null &&
                        <ViewOrder activeItem={this.state.activeItem}/>
                        }
                        {this.state.orderMode === "INPERSON" && this.state.activeItem !== null &&
                        <OrderInvoice activeOrder={this.state.activeItem}
                                      isActiveOrderAPrescriptionDrug={this.state.isActiveOrderAPrescriptionDrug}/>
                        }
                    </MDBModalBody>
                </MDBModal>

               
        
    
       
 
            </React.Fragment>
        )
    }
}

export default withRouter(FeedbackList);