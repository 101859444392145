import React,{ Suspense } from "react";
import {
    MDBInput, MDBBtn, MDBProgress, MDBCol, MDBRow,
} from 'mdbreact';
import "./login.css";
import AuthService from "../../services/authService";
import Cookies from 'universal-cookie';
import { toast } from 'react-toastify';
import orgConfig from "../../orgConfig";
//const Footer=React.lazy(() => import(/* webpackPrefetch: true */ "../../components/common/footer"));
import Footer from "../../components/common/footer";

export default class Login extends React.Component {
    componentDidMount() {
        const cookies = new Cookies();
        cookies.remove('userSession');
        localStorage.removeItem('productList');
        localStorage.removeItem('searchProductTerm');
    }

    constructor(props) {
        super(props);
        this.state = {
            userName:'',
            progressStatus:false,
            password:'',
            isLogggedIn:false,
            userNameError:false,
            passwordError:false,
            passwordType:"password"
        }
    }

    doLogin=async () =>{
        //validations
        if(this.state.userName===''){
            this.setState({"userNameError":true});
            return true;
        } else {
            this.setState({"userNameError":false});
        }
        if(this.state.password===''){
            this.setState({"passwordError":true});
            return true;
        } else {
            this.setState({"passwordError":false});
        }
        this.setState({progressStatus:true});
        let resp=await AuthService.login(this.state.userName,this.state.password);
        localStorage.setItem('role', resp?.type);
        
        this.setState({progressStatus:false});
        if(resp===false){
            toast.error("Invalid user or password.");
        }else{
            this.setState({
                isLoggedIn: true
            })
            this.props.history.push('/dashboard')
        }
    }
    handleChangeUsername=(event)=>{
        this.setState({
            userName: event.target.value
        })
    }

    handleChangePassword=(event)=>{
        this.setState({
            password: event.target.value
        })
    }

    showHide=(e)=>{
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            passwordType: this.state.passwordType === 'password' ? 'text' : 'password'
        })
    }

    handleKeyDown = (event) => {
        if(event.keyCode === 13) {
            this.doLogin();
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.state.progressStatus === true &&
                <span className={'top-stick'}><MDBProgress material preloader color="cyan"/></span>
                }

                <div className="container-fluid p-0">
                    <section className={"aqua-gradient vh-100"}>
                        <div className="mask d-flex justify-content-center align-items-center">
                            <div className="container py-5 my-5">
                                <h3 className="font-weight-bold text-center white-text pb-2">Welcome to {orgConfig.ORG_NAME_LABEL} Admin</h3>
                                <div className="row d-flex align-items-center justify-content-center">
                                    <div className="col-md-6 col-xl-5">
                                        <div className="card">
                                            <div className="card-body z-depth-2 px-4">
                                                <MDBRow>
                                                 <div className={'col-12'}>
                                                <MDBInput id={"username_se"} autoComplete={'off'}
                                                          className={this.state.userNameError ? "border-danger my-4" :"my-4"} maxLength="50"
                                                          type='text' label="Username/Phone" onChange={this.handleChangeUsername}
                                                          value={this.state.userName}/>
                                                </div>
                                                </MDBRow>
                                                <MDBRow>
                                                <div className={'col-12'}>
                                                <MDBInput id={"password_se"} type={this.state.passwordType} onKeyDown={this.handleKeyDown}
                                                          label="Password" maxLength="50"
                                                          className={this.state.passwordError ? "border-danger" : ""}
                                                          onChange={this.handleChangePassword} value={this.state.password}/>
                                                {this.state.password &&
                                                <span style={{"marginTop": "-50px", "position":"absolute", "right": "31px", "cursor": "pointer"}} onClick={this.showHide}
                                                      className={this.state.passwordType === 'text' ? 'fa fa-fw fa-eye-slash field-icon' : 'fa fa-fw fa-eye field-icon'}></span>
                                                }
                                                </div>
                                                </MDBRow>
                                                <MDBCol className={'text-center m-0 p-0 my-3 '}>
                                                    <MDBBtn id={"do_sign_in_se"} disabled={this.state.loading || this.state.progressStatus} className={' btn-block btn-primary'} onClick={this.doLogin}>Sign In</MDBBtn>
                                                </MDBCol>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Suspense fallback={null}>
                    <Footer/>
                </Suspense>
            </React.Fragment>
        );
    }
}


