import React from "react";
import {MDBBadge,MDBIcon } from "mdbreact";
import UserStore from "../../stores/userStore";
import ReportService from "../../services/reports";
import {Link} from "react-router-dom";



export default class StocksUpdate extends React.Component {
    async componentDidMount() {
        let userData=await UserStore.fetchSessionData();
        let stockData=await ReportService.stockWidget(userData);
        if(stockData!==false){
            this.setState({data:stockData});
        }
    }
    constructor(props) {
        super(props);
        this.state = {
            data: null
        }
    }
    render() {
        return (
            <React.Fragment>
                <div className={'white rounded-sm p-1 dashboard-widget'}>
                    <h6 className={'d-flex justify-content-between align-items-center pb-0'}><b className={'font-weight-bold'}>Stock</b></h6>
                    <ul className="list-group list-group-flush p-0 border-top">
                        <li className="list-group-item d-flex justify-content-between align-items-center p-1">
                            {/*<Link to={"/add-supplier"}><span className={'grey-text'}> Out of Stock</span></Link>*/}
                            <span className={'grey-text'}> Out of Stock</span>
                            <div>
                                <MDBBadge color="danger-color" pill>{this.state.data!==null?this.state.data.noStock:""}</MDBBadge>
                                {/*<Link to={"/add-supplier"}>*/}
                                {/*    <a className={'white p-2 blue-text z-depth-0'}><MDBIcon className={'blue-text fa-lg'} icon="chevron-right" /></a>*/}
                                {/*</Link>*/}
                            </div>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center p-1">
                            {/*<Link to={"/add-supplier"}><span className={'grey-text'}>Less than 10</span></Link>*/}
                            <span className={'grey-text'}>Less than 10</span>
                            <div>
                                <MDBBadge className={'ml-1'} color="warning-color" pill>{this.state.data!==null?this.state.data.lowStock:""}</MDBBadge>
                                {/*<Link to={"/add-supplier"}>*/}
                                {/*    <a className={'white p-2 blue-text z-depth-0'}><MDBIcon className={'blue-text fa-lg'} icon="chevron-right" /></a>*/}
                                {/*</Link>*/}
                            </div>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center p-1">
                            {/*<Link to={"/add-supplier"}><span className={'grey-text'}>In Stock</span></Link>*/}
                            <span className={'grey-text'}>In Stock</span>
                            <div>
                                <MDBBadge className={'ml-1'} color="success-color" pill>{this.state.data!==null?this.state.data.greaterThen10:""}</MDBBadge>
                                {/*<Link to={"/add-supplier"}>*/}
                                {/*    <a className={'white p-2 blue-text z-depth-0'}><MDBIcon className={'blue-text fa-lg'} icon="chevron-right" /></a>*/}
                                {/*</Link>*/}
                            </div>
                        </li>
                    </ul>
                </div>
            </React.Fragment>
        );
    }
}


