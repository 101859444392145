import React from "react";
import { MDBBreadcrumb, MDBBreadcrumbItem } from "mdbreact";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import axios from "axios";
import config from "../config";
import UserStore from "../stores/userStore";
import { toast } from "react-toastify";
import { te } from "date-fns/locale";
import TextField from "../newComponents/Textfield";
import NewTable from "../newComponents/NewTable/NewTable";
import { STOCK_TABLE_TEMP_TRANSFER } from "../utility/constants";
import { formatStockByLocationTemp } from "../utility/tableUtils";
import PharmacyService from "../services/pharmacyService";
import { Button } from "@material-ui/core";
import SimpleBackdrop from "../components/common/overlay"

const fetchBarCodeUrl = "/api/v3/stock/fetch-details-by-barcode"
const fetchLocationUrl = "/api/v3/fetchStorageLocations"

class TempTransfer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            barCodes: [],
            tableData: [],
            storageLocations: [],
            barcode: "",
            locIdTo: "66df204ac76a094039b1e730",
            floorTo: "66e2bf75f4a0caefe96cdbbe",
            rackInfo: [],
            userData: "",
            barcodeChangeInfo: [],
            isLoading: false,
            payload: [
                {
                    id: "",
                    locationIdTo: "",
                    floorIdTo: "",
                    rackIdTo: "",
                    boxIdTo: "",
                    quantity: null,
                    productName: ""
                }
            ]
        }
    }
    async componentDidMount() {
        let userData = await UserStore.fetchSessionData();
        if (userData) {
            this.setState({
                userData: userData
            })
        }
        try {
            const headers = {
                headers: { Authorization: userData.sessionToken },
            };
            const response = await axios.get(
                config.APIHOST + fetchLocationUrl,
                headers
            );
            this.setState({ storageLocations: response.data })
        } catch (e) {
            try {
                toast.error(e.response.data.message)
            }
            catch (e) {
                toast.error("Something went wrong")
            }
        }
        this.fetchRack()
    }

    fetchStockByBarCode = async function (val) {
        this.setState({
            barcode: val
        })
        if (val !== "" && val.length > 12) {
            let userData = await UserStore.fetchSessionData();
            let temp = this.state.barCodes;
            temp.push(val);
            this.setState({ barCodes: temp })
            try {
                const headers = {
                    headers: { Authorization: userData.sessionToken },
                };
                const response = await axios.get(
                    config.APIHOST_2 + fetchBarCodeUrl + "?barCode=" + val,
                    headers
                );
                // return response.data;
                let tempTable = this.state.tableData;
                tempTable.push(response.data);
                this.setState({ tableData: tempTable })
                // console.log("bar", this.state.barCodes, this.state.tableData);
                this.setState({
                    barcode: ""
                })
            } catch (e) {
                try {
                    toast.error(e.response.data.message)
                }
                catch (e) {
                    toast.error("Invalid Bar Code")
                }
            }
        }
    }

    // fetchFloor = async (locId) => {
    //     const floorDetails = await PharmacyService.fetchFloorV2("66df204ac76a094039b1e730");
    //     if (floorDetails) {
    //         console.log({floorDetails})
    //         // setFloors(floorDetails);
    //     }
    // };
    fetchRack = async (floorId, index) => {
        const rackDetails = await PharmacyService.fetchRackV2("66e2bf75f4a0caefe96cdbbe");
        if (rackDetails) {
            this.setState({
                rackInfo: rackDetails
            })
            console.log({ rackDetails })
        }
    };
    fetchBox = async (item, index) => {
        console.log({ item, index })
        const tableDataAdd = this.state.tableData
        const boxDetails = await PharmacyService.fetchBoxV2(item?.[0]?._id);
        if (boxDetails) {
            tableDataAdd[index].rackId = item[0]._id
            tableDataAdd[index].boxInfo = boxDetails
            console.log({ boxDetails })
            this.setState({
                tableData: tableDataAdd
            })

            // setBox(boxDetails);
        }
    };
    selectBox = (item, index) => {
        const tableDataAdd = this.state.tableData
        tableDataAdd[index].boxId = item[0]._id
        this.setState({
            tableData: tableDataAdd
        })

        const transformedData = this.state.tableData.map(item => ({
            id: item._id,
            locationIdTo: "66df204ac76a094039b1e730",
            floorIdTo: "66e2bf75f4a0caefe96cdbbe",
            rackIdTo: item.rackId,
            boxIdTo: item.boxId,
            quantity: item.quantity            ,
            productName: item.productName
        }));
        this.setState({
            payload: transformedData
        })
        console.log(transformedData);
    }

    transferData = async () => {
        try{
            this.setState({
                isLoading:true
            })
            const headers = {
                headers: { Authorization: this.state.userData.sessionToken },
            };
            const response = await axios.post(
                config.APIHOST_2 + "/api/v3/stock/endpoint/temp-transfer",
                this.state.payload,
                headers
            );
            console.log({ response })
            if (response.status == 200) {
                toast.success(response?.data?.message)
                this.setState({
                    barcodeChangeInfo: response?.data?.barCodes,
                    isLoading:false,
                    tableData:[]
                })
            }
            else {
                toast.error("Something went wrong .")
                this.setState({
                    isLoading:false
                })
            }
        }
        catch(err){
            toast.error("Something went wrong .")
            this.setState({
                isLoading:false
            }) 
        }
    }

    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.isLoading} />
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Transfer</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <div className={"min-vh-100 p-3 m-0"}>
                    <div className="temp-mg">
                        {/* <input type="textbox" placeholder="Scan Barcode" onChange={(e) => this.fetchStockByBarCode(e.target.value)}></input> */}
                        <TextField type="text" placeholder="Enter barcode" value={this.state.barcode} onChange={(e) => this.fetchStockByBarCode(e.target.value)} />
                    </div>
                    {/* {JSON.stringify(this.state.tableData)}
                    <div className="temp-mg">
                        <table border="1">
                            <th>Product name</th>
                            <th>Batch</th>
                            <th>MRP</th>
                            <th>Quantity</th>
                            <th>To Location</th>
                            <th>To Floor</th>
                            <th>To Rack</th>
                            <th>To Box</th>
                            {this.state.tableData.map((item, index) => (
                                <tr>
                                    <td width={150}>
                                        {item.productName}
                                    </td>
                                    <td>
                                        {item.batch}
                                    </td>
                                    <td>
                                        {item.mrp}
                                    </td>
                                    <td>
                                        {item.quantity}
                                    </td>
                                    <td>
                                        <select id="location-select">
                                            <option value="">Select a location</option>
                                            {this.state.storageLocations.map((item) => (
                                                <option key={item._id} value={item._id}>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </td>
                                    <td>To Floor</td>
                                    <td>To Rack</td>
                                    <td>To Box</td>
                                </tr>

                            ))}
                        </table>
                    </div> */}
                    <NewTable
                        columns={STOCK_TABLE_TEMP_TRANSFER}
                        data={formatStockByLocationTemp(this.state.tableData, this.state.rackInfo, this.fetchBox, this.selectBox)}
                        pagination={false}
                    />
                    <div className="d-flex justify-content-center">
                        <Button style={{ background: "#007bff", color: "#ffffff",marginTop:"2em" }} variant="outlined" onClick={this.transferData}>Transfer</Button>
                    </div>
                    {this.state.barcodeChangeInfo.length >= 1 && <div className="" style={{ marginTop: "1em" }}>
                        <p style={{fontSize:"2em"}}>Barcode Changed :</p><br></br>
                        {this.state.barcodeChangeInfo?.map((item) =>
                            <div className="">
                                <p style={{fontSize:"1.5em !important",margin:"auto",marginTop:".5em"}}>Product name : {item?.productName}</p>
                                <p style={{fontSize:"1.5em !important",margin:"auto",marginTop:".5em"}}>Barcode: {item?.barCode}</p>
                            </div>
                        )}
                    </div>}
                </div>
            </React.Fragment>
        )
    }
}
export default withRouter(TempTransfer);