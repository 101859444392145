import React from "react"
import {
    MDBBtn, MDBModal,
    MDBModalBody,
    MDBModalFooter,
    MDBModalHeader,
    MDBSpinner,
    MDBTable,
    MDBTableBody,
    MDBTableHead
} from "mdbreact";
import Moments from "moment";
class ViewOrders extends React.Component {
    toggleOrder = () => {
        this.props.toggleOrder(null)
    }
    render() {
        return(
            <React.Fragment>
                <MDBModal size={"lg"} isOpen={this.props.orderModal} toggle={()=>{this.toggleOrder(null)}}>
                    <MDBModalHeader toggle={()=>{this.toggleOrder(null)}}>View Associated orders</MDBModalHeader>
                    <MDBModalBody>
                        {this.props.orders===null &&
                        <MDBSpinner/>
                        }
                        {this.props.orders !== null &&
                        <>
                            <MDBTable>
                                <MDBTableHead>
                                    <tr>
                                        <th>#</th>
                                        <th>Batch</th>
                                        <th>Order Code</th>
                                        <th>Order Date</th>
                                        <th>Order Mode</th>
                                        <th>Quantity</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {this.props.orders.length===0 &&
                                    <tr>
                                        <td align={"center"} colSpan={6}>
                                            <div className={'no-data-container'}>No Records found.</div>
                                        </td>
                                    </tr>
                                    }
                                    {this.props.orders.map((row, index) => (
                                        <tr>
                                            <td>{index+1}</td>
                                            <td>{row.batch}</td>
                                            <td>{row.orderCode}</td>
                                            <td>{Moments(row.orderDate).format("MMM D, YYYY")}</td>
                                            <td>{row.orderMode}</td>
                                            <td>{row.quantity}</td>
                                        </tr>
                                    ))}
                                </MDBTableBody>
                            </MDBTable>
                        </>
                        }
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn size={'md'} onClick={()=>{this.toggleOrder(null)}}>Close</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
            </React.Fragment>
        )
    }
}
export default ViewOrders;