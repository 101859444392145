import React,{Suspense} from "react";
import {Link} from "react-router-dom";
import {
    MDBBreadcrumbItem,
    MDBBreadcrumb, MDBBox, MDBTable, MDBTableBody, MDBTableHead, MDBRow, MDBCol,
    MDBContainer, MDBStepper, MDBStep, MDBIcon,   MDBBtn
} from 'mdbreact';
// const OrderHistory=React.lazy(() => import("../../components/order-history/onlineOrders"));
import OrderDetail from "../../components/order-history/subOrderDetails";

export default class SubOrderDetailsPage extends React.Component {

    static async getInitialProps({query}) {
        alert(query.orderId)
        //let med=await ProductService.fetchMedById(query.orderId);
        return {orderId:query.orderId}
    }

    render() {
        return (
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Sub Orders</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <MDBBox>
                    <Suspense fallback={null}>
                        <OrderDetail queryString={this.props.match.params}/>
                    </Suspense>
                </MDBBox>
            </React.Fragment>
        );
    }
}
