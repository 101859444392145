import React from "react";
import {
  MDBBtn,
  MDBIcon,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
  MDBChip,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOptions,
  MDBSelectOption,
  MDBFooter,
} from "mdbreact";
import config from "../../config";
import OrderService from "../../services/orderService";
import UserStore from "../../stores/userStore";
import moment from "moment";
import { toast } from "react-toastify";
import {
  MuiPickersUtilsProvider,
  TimePicker,
  DateTimePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import UploadPrescription from "./uploadPrescription";
import CreateShippingOrder from "../ThirdPartyIntegration/createShippingOrder";
import ThirdPartyService from "../../services/thirdPartyService";

class ManageStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderDetails: props.activeItem,
      orderStatus: "CREATED",
      deliveryType: "SHIP",
      messageAck: "",
      messageApproved: "",
      messageReadyForDelivery: "",
      messageCompleted: "",
      messageRequestDoc: "",
      messageOutForDelivery: "",
      modalAddPrescription: false,
      modalOutForDelivery: false,
      modalCompleted: false,
      radio: "COLLECT_ON_DELIVERY",
      displayPrescriptionList: [],
      loading: false,
      loadingCancel: false,
      prescriptionUploadStatus: "",
      loadingApprove: false,
      selectedDeliveryPersonId: "",
      fromTime: new Date(),
      toTime: new Date().getTime() + 5 * 60 * 60 * 1000,
      orderCompletedOn: new Date(),
      keyQU: "",
      delPersonList: [],
      modalUploadPrescription: false,
      classAcknowledged: "active",
      classApproved: "",
      classReadyForShipping: "",
      classDelivered: "",
      modalCancelOrder: false,
      cancellationNote: "",
      cancelOrderNote: "",
      cancellationNotes: [
        {
          text: "Customer is not reachable",
          value: "Customer is not reachable",
        },
        { text: "Product is out of stock", value: "Product is out of stock" },
        {
          text: "Customer want it urgently",
          value: "Customer want it urgently",
        },
        {
          text: "Customer changed his/her mind",
          value: "Customer changed his/her mind",
        },
        {
          text: "Customer don't need anymore",
          value: "Customer don't need anymore",
        },
        {
          text: "We couldn't deliver on promised date",
          value: "We couldn't deliver on promised date",
        },
        {
          text: "Customer didn't accept the product",
          value: "Customer didn't accept the product",
        },
        { text: "Other", value: "Other" },
      ],
      aggregator: "",
      modalShippingOrder: false,
      updateNimbusToken: "",
      nimbusPayload: {},
      pickrrPayload: {},

      activeEcomStatus: [],
      activeEcomAwb: 0,
      modalEcomShipment: false,
      informCustomerAboutCancellation: false,
    };
  }

  async componentDidMount() {
    let userData = await UserStore.fetchSessionData();
    if (
      this.state.orderDetails.hasOwnProperty("prescriptions") &&
      this.state.orderDetails.prescriptions.length > 0
    ) {
      let displayPrescriptionList = this.processDocsForDisplay(
        this.state.orderDetails.prescriptions
      );
      this.setState({ displayPrescriptionList });
    }
    if (this.state.orderDetails.hasOwnProperty("prescriptionUploadStatus")) {
      this.setState({
        prescriptionUploadStatus:
          this.state.orderDetails.prescriptionUploadStatus,
      });
    }
    this.setState({
      userData,
      orderStatus: this.state.orderDetails.status,
      deliveryType: this.state.orderDetails.deliveryType,
    });
    if (
      this.state.orderDetails.hasOwnProperty("processLog") &&
      this.state.orderDetails.processLog.length > 0
    ) {
      let val = this.getCancelOrderMessagePosition(
        this.state.orderDetails.processLog
      );
      await this.getMessageFromProcessLog(
        null,
        this.state.orderDetails.processLog,
        val
      );
    }
  }

  getCancelOrderMessagePosition = (processLog) => {
    if (processLog.length === 1) {
      return null;
    } else {
      if (
        processLog[processLog.length - 1].status.toUpperCase() === "CANCELLED"
      ) {
        let previousLogStatus = processLog[processLog.length - 2].status;
        if (
          previousLogStatus.toUpperCase() === "ACKNOWLEDGED" ||
          previousLogStatus.toUpperCase() === "REQUESTDOC"
        ) {
          return "Approved";
        } else if (previousLogStatus.toUpperCase() === "APPROVED") {
          return "ReadyForDelivery";
        } else if (
          previousLogStatus.toUpperCase() === "READYFORDELIVERY" ||
          previousLogStatus.toUpperCase() === "OUTFORDELIVERY"
        ) {
          return "Completed";
        }
      }
    }
  };
  // accept={"image/jpeg, image/png, image/gif, image/bmp, .pdf, application/msword, text/plain"}
  processDocsForDisplay = (prescriptions) => {
    let displayDocs = [];
    for (let item of prescriptions) {
      let createdBy = "";
      if (item.hasOwnProperty("createdBy")) {
        createdBy = item.createdBy.name;
      }
      if (item.prescriptionUrl.match(/.(jpg|jpeg|png|gif)$/i)) {
        displayDocs.push({
          path: item.prescriptionUrl,
          type: "image",
          createdBy: createdBy,
        });
      } else {
        displayDocs.push({
          path: item.prescriptionUrl,
          type: "text",
          createdBy: createdBy,
        });
      }
    }
    return displayDocs;
  };

  getMessageFromProcessLog = async (status, processLog, val) => {
    let updatedProcessLog = "";
    if (status !== null) {
      let orderDetails = await OrderService.fetchOnlineOrderById(
        this.state.userData.sessionToken,
        this.state.orderDetails._id
      );
      if (orderDetails) {
        if (
          orderDetails.hasOwnProperty("processLog") &&
          orderDetails.processLog.length > 0
        ) {
          updatedProcessLog = orderDetails.processLog;
          this.setState({ orderDetails });
        }
      } else {
        toast.error("Something went wrong while fetching the order details.");
      }
    }
    if (processLog !== null) {
      updatedProcessLog = processLog;
    }
    let message = "";
    for (let item of updatedProcessLog) {
      switch (item.status) {
        case "ACKNOWLEDGED":
          message =
            `The order has been acknowledged<br />` +
            moment(item.createdAt).format("ll") +
            ` at ` +
            moment(item.createdAt).format("LT") +
            `, ` +
            this.convertToTitleCase(item.createdBy.name);
          this.setState({
            messageAck: message,
            classAcknowledged: "completed",
            classApproved: "active",
          });
          break;
        case "REQUESTDOC":
          message =
            `A request has been sent to the customer to upload valid prescription<br />` +
            moment(item.createdAt).format("ll") +
            ` at ` +
            moment(item.createdAt).format("LT") +
            `, ` +
            this.convertToTitleCase(item.createdBy.name);
          this.setState({
            messageRequestDoc: message,
            classApproved: "active",
          });
          break;
        case "APPROVED":
          message =
            `The order has been approved<br />` +
            moment(item.createdAt).format("ll") +
            ` at ` +
            moment(item.createdAt).format("LT") +
            `, ` +
            this.convertToTitleCase(item.createdBy.name);
          this.setState({
            messageApproved: message,
            classApproved: "completed",
            classReadyForShipping: "active",
          });
          break;
        case "READYFORDELIVERY":
          if (this.state.deliveryType.toUpperCase() === "PICKUP") {
            message =
              `The order is ready for pickup<br />` +
              moment(item.createdAt).format("ll") +
              ` at ` +
              moment(item.createdAt).format("LT") +
              `, ` +
              this.convertToTitleCase(item.createdBy.name);
          } else if (this.state.deliveryType.toUpperCase() === "SHIP") {
            message =
              `The order is ready to be shipped.<br />` +
              moment(item.createdAt).format("ll") +
              ` at ` +
              moment(item.createdAt).format("LT") +
              `, ` +
              this.convertToTitleCase(item.createdBy.name);
          } else if (this.state.deliveryType.toUpperCase() === "DELIVER") {
            message =
              `The order is ready for delivery.<br />` +
              moment(item.createdAt).format("ll") +
              ` at ` +
              moment(item.createdAt).format("LT") +
              `, ` +
              this.convertToTitleCase(item.createdBy.name);
          }
          this.setState({
            messageReadyForDelivery: message,
            classReadyForShipping: "completed",
            classDelivered: "active",
          });
          break;
        case "OUTFORDELIVERY":
          if (this.state.deliveryType.toUpperCase() === "DELIVER") {
            message =
              "The package will be delivered by " +
              this.state.orderDetails.deliveryUserData.name +
              " ( " +
              this.state.orderDetails.deliveryUserData.phone +
              " ) between " +
              item.deliverySlot.start +
              " and " +
              item.deliverySlot.end +
              " on " +
              moment(item.deliverySlot.date).format("ll");
          } else if (this.state.deliveryType.toUpperCase() === "PICKUP") {
            message =
              "The package will be available for pickup between " +
              item.deliverySlot.start +
              " and " +
              item.deliverySlot.end +
              " on " +
              moment(item.deliverySlot.date).format("ll");
          } else {
            message =
              `The package is shipped.<br />` +
              moment(item.createdAt).format("ll") +
              ` at ` +
              moment(item.createdAt).format("LT") +
              `, ` +
              this.convertToTitleCase(item.createdBy.name);
          }
          if (
            item.hasOwnProperty("deliverySlot") &&
            item.deliverySlot.length > 0
          ) {
            this.setState({
              fromTime: item.deliverySlot.start,
              toTime: item.deliverySlot.end,
            });
          }
          this.setState({ messageOutForDelivery: message });
          break;
        case "COMPLETED":
          let date = item.createdAt;
          // TODO: There is some issue with the DeliveryDate, even though it looks as same for mat of createdAt, but while displaying it shows 5:30 hrs late
          if (item.createdBy.name == "System") {
            date = item.deliveryDate;
          }
          message =
            `The order has been delivered on ` +
            moment(date).format("ll") +
            ` at ` +
            moment(date).format("LT");
          this.setState({
            messageCompleted: message,
            classDelivered: "completed",
          });
          break;
        default:
          let note = item.hasOwnProperty("cancellationNote")
            ? `<b>Cancellation Note: </b>` + item.cancellationNote + `<br/>`
            : "";
          message =
            `<span class="text-danger">The order has been cancelled<br />` +
            (item.hasOwnProperty("cancellationNote") && note) +
            moment(item.createdAt).format("ll") +
            ` at ` +
            moment(item.createdAt).format("LT") +
            `, ` +
            this.convertToTitleCase(item.createdBy.name) +
            `</span>`;
          this.setState({ ["message" + val]: message });
      }
    }
  };
  convertToTitleCase = (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  };

  updateStatus = async (status, val) => {
    switch (status) {
      case "ACKNOWLEDGED":
        this.setState({ loading: true });
        let res1 = await OrderService.ackOnlineOrder(
          this.state.userData.sessionToken,
          this.state.orderDetails._id
        );
        if (res1) {
          toast.success("The order has been acknowledged.");
          await this.getMessageFromProcessLog("ACKNOWLEDGED", null, null);
          this.setState({
            orderStatus: "ACKNOWLEDGED",
            classAcknowledged: "completed",
            classApproved: "active",
          });
        } else {
          toast.error("Something went wrong while acknowledging the order.");
        }
        this.setState({ loading: false });
        break;
      case "APPROVED":
        if (this.state.orderDetails.isPrescriptionRequired === "Y") {
          this.toggle("AddPrescription")();
        } else {
          await this.approveOrderRequest();
        }
        break;
      case "REQUESTDOC":
        this.setState({ loading: true });
        let res2 = await OrderService.requestDocument(
          this.state.userData.sessionToken,
          { orderId: this.state.orderDetails._id }
        );
        if (res2) {
          await this.getMessageFromProcessLog("REQUESTDOC", null, null);
          this.setState({ orderStatus: "REQUESTDOC" });
          toast.success("A request to upload prescription has been sent.");
        } else {
          toast.error(
            "Something went wrong while sending a request to upload prescription."
          );
        }
        this.setState({ loading: false });
        break;
      case "READYFORDELIVERY":
        this.setState({ loading: true });
        if (this.state.orderDetails.isOrderFulfilled === false) {
          toast.info(
            "Please fulfil the order before making it ready for delivery."
          );
          this.setState({ loading: false });
          return;
        }
        let res3 = await OrderService.readyForDeliveryOnlineOrder(
          this.state.userData.sessionToken,
          { orderId: this.state.orderDetails._id }
        );
        if (res3) {
          await this.getMessageFromProcessLog("READYFORDELIVERY", null, null);
          this.setState({
            orderStatus: "READYFORDELIVERY",
            classReadyForShipping: "completed",
            classDelivered: "active",
          });
          toast.success("The order is ready for delivery.");
        } else {
          toast.error(
            "Something went wrong while making the order ready for delivery."
          );
        }
        this.setState({ loading: false });
        break;
      case "OUTFORDELIVERY":
        this.setState({ loading: true });
        let payload = {};
        if (
          this.state.deliveryType.toUpperCase() === "DELIVER" ||
          this.state.deliveryType.toUpperCase() === "PICKUP"
        ) {
          payload = {
            orderId: this.state.orderDetails._id,
            deleveryPersonId:
              this.state.deliveryType.toUpperCase() === "PICKUP"
                ? ""
                : this.state.selectedDeliveryPersonId,
            deliverySlot:
              this.state.deliveryType.toUpperCase() === "PICKUP"
                ? {
                    start: moment(this.state.fromTime)
                      .format("h:mm a")
                      .toUpperCase(),
                    end: moment(this.state.toTime)
                      .format("h:mm a")
                      .toUpperCase(),
                    date: new Date(),
                  }
                : {
                    start: this.state.orderDetails.window.start,
                    end: this.state.orderDetails.window.end,
                    date: this.state.orderDetails.window.date,
                  },
          };
        } else {
          payload = {
            orderId: this.state.orderDetails._id,
            deleveryPersonId: "",
            deliverySlot: [],
          };
        }
        let res4 = await OrderService.outForDeliveryOnlineOrder(
          this.state.userData.sessionToken,
          payload
        );
        if (res4) {
          toast.success("The order has been sent out for delivery.");
          await this.getMessageFromProcessLog("OUTFORDELIVERY", null, null);
          this.setState({ orderStatus: status, modalOutForDelivery: false });
        } else {
          toast.error(
            "Something went wrong while trying to send the order out for delivery. For shipping orders, please confirm the availability of Tracking URL."
          );
        }
        this.setState({ loading: false });
        break;
      case "COMPLETED":
        this.setState({ loading: true });
        let res5 = await OrderService.markAsDeliveredOnlineOrder(
          this.state.userData.sessionToken,
          {
            orderId: this.state.orderDetails._id,
            date: this.state.orderCompletedOn,
          }
        );
        if (res5) {
          toast.success("The order has been delivered.");
          await this.getMessageFromProcessLog("COMPLETED", null, null);
          this.setState({
            orderStatus: status,
            modalCompleted: false,
            classDelivered: "completed",
          });
        } else {
          toast.error(
            "Something went wrong while marking the order as delivered."
          );
        }
        this.setState({ loading: false });
        break;
      default:
        if (
          this.state.cancellationNote === "Other" &&
          this.state.cancelOrderNote === ""
        ) {
          toast.error("Please add a cancellation note.");
          return false;
        }
        this.setState({ loadingCancel: true });
        let res = await OrderService.cancelOnlineOrder(
          this.state.userData.sessionToken,
          {
            orderId: this.state.orderDetails._id,
            informCustomer: this.state.informCustomerAboutCancellation,
            cancellationNote:
              this.state.cancellationNote === "Other"
                ? this.state.cancelOrderNote
                : this.state.cancellationNote,
          }
        );
        if (res) {
          await this.getMessageFromProcessLog("CANCELLED", null, val);
          this.setState({ orderStatus: "CANCELLED", modalCancelOrder: false });
          toast.success("The order has been cancelled.");
          window.location.reload();
        } else {
          toast.error("Something went wrong while cancelling the order.");
        }
        this.setState({ loadingCancel: false });
    }
  };

  toggle = (nr) => () => {
    let modalNumber = "modal" + nr;
    this.setState({
      [modalNumber]: !this.state[modalNumber],
    });
  };
  approveOrder = async () => {
    await this.approveOrderRequest();
    this.toggle("AddPrescription")();
  };

  uploadDoc = async (newlyUploadedFile) => {
    let prescriptions = [];
    if (this.state.orderDetails.hasOwnProperty("prescriptions")) {
      prescriptions = this.state.orderDetails.prescriptions;
    }
    prescriptions.push(newlyUploadedFile);
    let payload = {
      orderId: this.state.orderDetails._id,
      docs: prescriptions,
    };
    let res = await OrderService.uploadDocument(
      this.state.userData.sessionToken,
      payload
    );
    if (res) {
      let displayPrescriptionList = this.processDocsForDisplay(prescriptions);
      this.setState({ displayPrescriptionList });
      if (this.state.radio !== "UPLOAD_BY_ADMIN") {
        await this.props.updateOrder();
      }
      return true;
    } else {
      return false;
    }
  };
  approveOrderRequest = async () => {
    let prescriptionUploadStatus = "NOTREQUIRED";
    if (this.state.orderDetails.isPrescriptionRequired === "Y") {
      prescriptionUploadStatus =
        this.state.radio === "COLLECT_ON_DELIVERY"
          ? "COLLECTONDELIVERY"
          : "AVAILABLE";
    }
    this.setState({ loadingApprove: true });
    let result = await OrderService.approveOnlineOrder(
      this.state.userData.sessionToken,
      {
        orderId: this.state.orderDetails._id,
        prescriptionUploadStatus: prescriptionUploadStatus,
      }
    );
    this.setState({ loadingApprove: false });
    if (result) {
      toast.success("The order has been approved.");
      await this.getMessageFromProcessLog("APPROVED", null, null);
      this.setState({
        orderStatus: "APPROVED",
        prescriptionUploadStatus,
        classApproved: "completed",
        classReadyForShipping: "active",
      });
    } else {
      toast.error("Something went wrong while approving the order.");
    }
  };
  disableApproveButton = () => {
    if (
      this.state.radio === "UPLOAD_BY_ADMIN" &&
      this.state.displayPrescriptionList.length > 0
    ) {
      return false;
    }
    if (
      this.state.radio === "COLLECT_ON_DELIVERY" ||
      this.state.radio === "PRESCRIPTION_AVAILABLE"
    ) {
      return false;
    }
    return true;
  };
  selectPrescriptionUploadOption = (nr) => () => {
    this.setState({
      radio: nr,
    });
  };
  handleFromTime = (e) => {
    this.setState({ fromTime: e });
  };
  handleToTime = (e) => {
    this.setState({ toTime: e });
  };
  setDeliveryDetails = async () => {
    let delPersonList = await OrderService.fetchDeliveryPersonDetails(
      this.state.userData.sessionToken
    );
    if (delPersonList) {
      this.setState({ delPersonList, modalOutForDelivery: true });
    } else {
      toast.error(
        "Something went wrong while getting the delivery person list."
      );
    }
  };
  resetKey = () => {
    let randomString = Math.random().toString(36);
    this.setState({
      keyQU: randomString,
    });
  };
  selectDeliveryPerson = (item) => {
    this.setState({ selectedDeliveryPersonId: item[0] });
  };
  handleOrderCompletedOn = (e) => {
    this.setState({ orderCompletedOn: e });
  };
  createImageUrl = (item) => {
    if (item.match(/.(jpg|jpeg|png|gif)$/i)) {
      return (
        <a href={config.IMAGE_HOST + item} target={"_blank"}>
          <img src={config.IMAGE_HOST + item} width={86} height={86} />
        </a>
      );
    } else {
      return (
        <a href={config.IMAGE_HOST + item} target={"_blank"}>
          <MDBIcon size={"6x"} icon="file" />
        </a>
      );
    }
  };
  getUploadedPrescription = async (res) => {
    if (res) {
      await this.uploadDoc(res);
    } else {
      toast.error("Something went wrong while uploading the prescription.");
    }
  };

  initCreateShippingOrder = (aggregator) => {
    if (aggregator == "Ecom Express Direct") {
      if (
        this.state.orderStatus == "CREATED" ||
        this.state.orderStatus == "ACKNOWLEDGED" ||
        this.state.orderStatus == "APPROVED" ||
        this.state.orderStatus == "REQUESTDOC"
      ) {
        toast.error("Please fullfil this order first and try again.");
        return false;
      }
      if (
        this.state.orderStatus == "SHIPPED" ||
        this.state.orderStatus == "OUTFORDELIVERY" ||
        this.state.orderStatus == "COMPLETED"
      ) {
        toast.error("The order has been already shipped.");
        return false;
      }
    }
    this.setState({ modalShippingOrder: true, aggregator: aggregator });
  };

  CreateShippingOrderSuccess = (data) => {
    toast.success("Shipping Order Created");
  };

  CreateShippingOrderFailed = (data) => {
    toast.error("Shipping Order Failed");
  };

  closeShippingOrderModal = () => {
    this.setState({ modalShippingOrder: false, aggregator: "" });
  };

  updateNimbusPayload = (nimbusPayload) => {
    this.setState({ nimbusPayload });
  };

  updateNimbusToken = (nimbusToken) => {
    this.setState({ nimbusToken });
  };

  initEcomOrder = async (awb) => {
    //let res = await ThirdPartyService.trackAwb(awb)
    this.setState({ activeEcomAwb: awb, modalEcomShipment: true });
  };

  cancelEcomOrder = async (awb) => {
    let res = await ThirdPartyService.cancelEcomOrder(awb);
    if (res && res.length > 0 && res[0].hasOwnProperty("success")) {
      if (res[0].success == true) {
        toast.success(awb + " has been cancelled.");
        await this.injectTrackingDetailsToOrder("", "", "", "", "");
      } else if (res[0].success == false) {
        toast.error("Ecom order couldn't be cancelled");
      } else {
        toast.error("Something went wrong");
      }
    } else {
      console.log(res);
    }
  };

  injectTrackingDetailsToOrder = async (
    trackingUrl,
    trackingId,
    carrierName,
    courierLabel,
    aggregator
  ) => {
    let payload = {
      orderId: this.state.orderDetails._id,
      url: trackingUrl,
      urlId: trackingId,
      carrierName: carrierName,
      aggregator: aggregator,
      courierLabel: courierLabel,
      shippingExpense: 0,
    };
    // this.setState({progressStatus: true})
    let status = await OrderService.updateTrackingUrl(
      this.state.userData,
      payload
    );
    this.setState({ progressStatus: false });
    this.setState({ modalEcomShipment: false });
    if (status === false) {
      toast.error("We could not update the tracking URL.");
    } else {
      toast.success("Tracking URL updated successfully.");
    }
  };

  informCustomerToggle = async () => {
    await this.setState({
      informCustomerAboutCancellation:
        !this.state.informCustomerAboutCancellation,
    });
  };

  render() {
    return (
      <React.Fragment>
        <div className={"mb-5"}>
          <div className="row mt-1 mx-0">
            <div className="col-md-12">
              <ul className="stepper stepper-horizontal">
                <li className={this.state.classAcknowledged}>
                  <a href="#!">
                    <span className="circle">
                      <MDBIcon icon="envelope" />
                    </span>
                    <span className="label">Acknowledge Order</span>
                  </a>
                </li>
                <li className={this.state.classApproved}>
                  <a href="#!">
                    <span className="circle">
                      <MDBIcon icon="check" />
                    </span>
                    <span className="label">Approve Order</span>
                  </a>
                </li>
                {this.state.orderDetails.deliveryType === "SHIP" && (
                  <li className={this.state.classApproved}>
                    <a href="#!">
                      <span className="circle">
                        {this.state.orderDetails.hasOwnProperty("trackingId") &&
                          this.state.orderDetails.trackingId && (
                            <MDBIcon icon="check" />
                          )}
                      </span>
                      <span className="label">Create Shipping Order</span>
                    </a>
                  </li>
                )}
                <li className={this.state.classReadyForShipping}>
                  <a href="#!">
                    <span className="circle">
                      <img
                        alt={""}
                        src={require("../../components/common/img/shippment.svg")}
                      />
                    </span>
                    <span className="label">
                      {this.state.deliveryType.toUpperCase() === "SHIP" && (
                        <>Ready For Shipping</>
                      )}
                      {this.state.deliveryType.toUpperCase() === "DELIVER" && (
                        <>Ready For Delivery</>
                      )}
                      {this.state.deliveryType.toUpperCase() === "PICKUP" && (
                        <>Ready For Pickup</>
                      )}
                    </span>
                  </a>
                </li>
                <li className={this.state.classDelivered}>
                  <a href="#!">
                    <span className="circle">
                      <img
                        alt={""}
                        src={require("../../components/common/img/delivered.svg")}
                      />
                    </span>
                    <span className="label">Delivered</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className={"col-12 d-flex justify-content-between row mx-0 p-0"}>
            <div className={""}>
              {this.state.orderStatus === "CREATED" ? (
                <MDBBtn
                  size={"sm"}
                  onClick={() => this.updateStatus("ACKNOWLEDGED")}
                  disabled={
                    this.state.orderStatus !== "CREATED" || this.state.loading
                  }
                >
                  {this.state.loading ? (
                    <div
                      className="spinner-border spinner-border-sm fast"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <>Acknowledge Order</>
                  )}
                </MDBBtn>
              ) : this.state.messageAck !== "" ? (
                <p className={"status-box p-2"}>
                  <MDBIcon icon="angle-right" className={"text-primary"} />{" "}
                  <span
                    dangerouslySetInnerHTML={{ __html: this.state.messageAck }}
                  />
                </p>
              ) : (
                ""
              )}
            </div>
            <div className={""}>
              {/*{(this.state.orderStatus === "APPROVED" || this.state.orderStatus === "REQUESTDOC") &&*/}
              <div className={"col-12 row mx-0 p-0 text-right"}>
                <MDBBtn
                  size={"sm"}
                  block
                  onClick={this.toggle("UploadPrescription")}
                >
                  Upload Doc
                </MDBBtn>
              </div>
              {this.state.displayPrescriptionList.length > 0 && (
                <div className={"col-12 p-0 my-3"}>
                  <ul
                    className="list-group list-group-horizontal-lg text-lg-center"
                    style={{ clear: "both", display: "block", content: "" }}
                  >
                    {this.state.displayPrescriptionList.map((row, index) => (
                      <li
                        key={index}
                        className="list-group-item"
                        style={{ float: "left" }}
                      >
                        {this.createImageUrl(row.path)}
                        {row.createdBy !== "" && (
                          <p className={"text-center mb-0 "}>
                            Uploaded By: <br />
                            {row.createdBy}
                            <br />
                          </p>
                        )}
                        <p className={"d-flex justify-content-center my-1"}>
                          File_{index + 1}
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <div className={"col-12 p-0"} style={{ clear: "both" }}>
                {this.state.messageRequestDoc !== "" && (
                  <p className={"status-box p-2"}>
                    <MDBIcon icon="angle-right" className={"text-primary"} />{" "}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: this.state.messageRequestDoc,
                      }}
                    />
                  </p>
                )}
                {this.state.orderStatus === "ACKNOWLEDGED" ||
                this.state.orderStatus === "REQUESTDOC" ? (
                  <div className={"col-12 p-0 text-right"}>
                    <MDBBtn
                      size={"sm"}
                      onClick={() => this.updateStatus("APPROVED")}
                    >
                      {this.state.loadingApprove ? (
                        <div
                          className="spinner-border spinner-border-sm fast"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        <>Approve Order</>
                      )}
                    </MDBBtn>
                    {this.state.orderDetails.isPrescriptionRequired === "Y" && (
                      <MDBBtn
                        size={"sm"}
                        onClick={() => this.updateStatus("REQUESTDOC")}
                        className="btn btn-lg btn-block mt-3"
                        disabled={this.state.orderStatus === "REQUESTDOC"}
                      >
                        {this.state.loading ? (
                          <div
                            className="spinner-border spinner-border-sm fast"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : (
                          <>Request To Upload Prescription</>
                        )}
                      </MDBBtn>
                    )}
                    <MDBBtn
                      outline
                      size={"sm"}
                      onClick={() => {
                        this.toggle("CancelOrder")();
                        this.setState({ section: "Approved" });
                      }}
                    >
                      {this.state.loadingCancel ? (
                        <div
                          className="spinner-border spinner-border-sm fast"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        <>Cancel Order</>
                      )}
                    </MDBBtn>
                  </div>
                ) : this.state.messageApproved !== "" ? (
                  <p className={"p-2 status-box my-2"}>
                    <MDBIcon icon="angle-right" className={"text-primary"} />{" "}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: this.state.messageApproved,
                      }}
                    />
                  </p>
                ) : (
                  ""
                )}

                {this.state.orderDetails.isPrescriptionRequired === "Y" && (
                  <p className={"text-danger mt-2"}>
                    <MDBIcon icon="file-prescription" /> Prescription Required
                  </p>
                )}
              </div>
            </div>

            {this.state.orderDetails.deliveryType === "SHIP" && (
              <div className={" "}>
                {this.state.orderDetails.hasOwnProperty("trackingId") &&
                  this.state.orderDetails.trackingId && (
                    <div>
                      <div>
                        Courier: {this.state.orderDetails.carrierName}{" "}
                        {this.state.orderDetails.hasOwnProperty("aggregator") &&
                          this.state.orderDetails.aggregator && (
                            <span>({this.state.orderDetails.aggregator})</span>
                          )}
                      </div>
                      <div>
                        AWB:{" "}
                        <a
                          target="_blank"
                          href={this.state.orderDetails.trackingUrl}
                        >
                          {this.state.orderDetails.trackingId}
                        </a>
                        {this.state.orderDetails.aggregator ==
                          "Ecom Express Direct" && (
                          <div className={"cursor-pointer"}>
                            <MDBBtn
                              onClick={() =>
                                this.initEcomOrder(
                                  this.state.orderDetails.trackingId
                                )
                              }
                            >
                              Cancel Ecom Order
                            </MDBBtn>
                          </div>
                        )}
                      </div>
                      {this.state.orderDetails.courierLabel && (
                        <div>
                          Label:{" "}
                          <a
                            target="_blank"
                            href={this.state.orderDetails.courierLabel}
                          >
                            Print
                          </a>
                        </div>
                      )}
                      <div>
                        <div>
                          <MDBBtn
                            outline={true}
                            color={"danger"}
                            onClick={() =>
                              this.initCreateShippingOrder(
                                "Ecom Express Direct"
                              )
                            }
                          >
                            Re create shipping order Ecom Express
                          </MDBBtn>
                        </div>
                        <div>
                          <MDBBtn
                            outline={true}
                            color={"danger"}
                            onClick={() =>
                              this.initCreateShippingOrder("Nimbus")
                            }
                          >
                            {" "}
                            Re create shipping order using Nimbus
                          </MDBBtn>
                        </div>
                        <div>
                          <MDBBtn
                            outline={true}
                            color={"danger"}
                            onClick={() =>
                              this.initCreateShippingOrder("Pickrr")
                            }
                          >
                            Re create shipping order using Pickrr
                          </MDBBtn>
                        </div>
                      </div>
                    </div>
                  )}

                {(!this.state.orderDetails.hasOwnProperty("trackingId") ||
                  !this.state.orderDetails.trackingId) && (
                  <div>
                    <div>
                      <MDBBtn
                        outline={true}
                        onClick={() =>
                          this.initCreateShippingOrder("Ecom Express Direct")
                        }
                      >
                        Create shipping order using Ecom Express
                      </MDBBtn>
                    </div>
                    <div>
                      <MDBBtn
                        outline={true}
                        onClick={() => this.initCreateShippingOrder("Nimbus")}
                      >
                        {" "}
                        Create shipping order using Nimbus
                      </MDBBtn>
                    </div>
                    <div>
                      <MDBBtn
                        outline={true}
                        onClick={() => this.initCreateShippingOrder("Pickrr")}
                      >
                        Create shipping order using Pickrr
                      </MDBBtn>
                    </div>
                  </div>
                )}
              </div>
            )}

            <div className={""}>
              {this.state.orderStatus === "APPROVED" ? (
                <div className={"col-12 p-0 text-right"}>
                  <MDBBtn
                    outline
                    color="primary"
                    size={"sm"}
                    onClick={() => this.updateStatus("READYFORDELIVERY")}
                  >
                    {this.state.loading ? (
                      <div
                        className="spinner-border spinner-border-sm fast"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <> Ready for Delivery</>
                    )}
                  </MDBBtn>
                  <MDBBtn
                    outline
                    color="primary"
                    size={"sm"}
                    onClick={() => {
                      this.toggle("CancelOrder")();
                      this.setState({ section: "ReadyForDelivery" });
                    }}
                  >
                    {this.state.loadingCancel ? (
                      <div
                        className="spinner-border spinner-border-sm fast"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <>Cancel Order</>
                    )}
                  </MDBBtn>
                </div>
              ) : this.state.messageReadyForDelivery !== "" ? (
                <p className={"p-2 status-box my-2"}>
                  <MDBIcon icon="angle-right" className={"text-primary"} />{" "}
                  <span
                    dangerouslySetInnerHTML={{
                      __html: this.state.messageReadyForDelivery,
                    }}
                  />
                </p>
              ) : (
                ""
              )}
            </div>
            <div className={" "}>
              {this.state.prescriptionUploadStatus === "COLLECTONDELIVERY" && (
                <>
                  <MDBChip
                    bgColor="mdb-color lighten-2"
                    text="white"
                    waves
                    className={"block"}
                  >
                    <MDBIcon icon="file-prescription" />{" "}
                    <small>Collect Prescription On Delivery</small>
                  </MDBChip>
                  <br />
                </>
              )}
              {this.state.messageOutForDelivery !== "" && (
                <p className={"p-2 status-box my-2"}>
                  <MDBIcon icon="angle-right" className={"text-primary"} />{" "}
                  <span
                    dangerouslySetInnerHTML={{
                      __html: this.state.messageOutForDelivery,
                    }}
                  />
                </p>
              )}
              {this.state.messageCompleted !== "" && (
                <p className={"p-2 status-box my-2"}>
                  <MDBIcon icon="angle-right" className={"text-primary"} />{" "}
                  <span
                    dangerouslySetInnerHTML={{
                      __html: this.state.messageCompleted,
                    }}
                  />
                </p>
              )}
              {/*If deliveryType=DELIVER or deliveryType=PICKUP*/}
              {this.state.messageCompleted === "" &&
                (this.state.deliveryType.toUpperCase() === "DELIVER" ||
                  this.state.deliveryType.toUpperCase() === "PICKUP") &&
                (this.state.orderStatus === "READYFORDELIVERY" ||
                  this.state.orderStatus === "OUTFORDELIVERY" ||
                  this.state.orderStatus === "DELIVERED") && (
                  <div className={"col-12 text-right p-0"}>
                    <MDBBtn
                      outline
                      color="primary"
                      size={"sm"}
                      onClick={this.setDeliveryDetails}
                      hidden={this.state.orderStatus === "OUTFORDELIVERY"}
                    >
                      {this.state.deliveryType.toUpperCase() === "DELIVER" ? (
                        <span>Set Delivery Details</span>
                      ) : (
                        <span>Set Pickup Details</span>
                      )}
                    </MDBBtn>
                    <MDBBtn
                      outline
                      color="primary"
                      size={"sm"}
                      onClick={this.toggle("Completed")}
                      hidden={this.state.orderStatus === "READYFORDELIVERY"}
                    >
                      Delivered
                    </MDBBtn>
                    <MDBBtn
                      outline
                      color="primary"
                      size={"sm"}
                      onClick={() => {
                        this.toggle("CancelOrder")();
                        this.setState({ section: "Completed" });
                      }}
                    >
                      {this.state.loadingCancel ? (
                        <div
                          className="spinner-border spinner-border-sm fast"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        <>Cancel Order</>
                      )}
                    </MDBBtn>
                  </div>
                )}
              {/*If deliveryType=SHIP*/}
              {this.state.messageCompleted === "" &&
                this.state.deliveryType.toUpperCase() === "SHIP" &&
                (this.state.orderStatus === "READYFORDELIVERY" ||
                  this.state.orderStatus === "OUTFORDELIVERY" ||
                  this.state.orderStatus === "DELIVERED") && (
                  <div className={"col-12 text-right p-0"}>
                    <MDBBtn
                      outline
                      size={"sm"}
                      hidden={this.state.orderStatus === "OUTFORDELIVERY"}
                      onClick={() => this.updateStatus("OUTFORDELIVERY")}
                    >
                      {this.state.loading ? (
                        <div
                          className="spinner-border spinner-border-sm fast"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        <>Shipped</>
                      )}
                    </MDBBtn>
                    <MDBBtn
                      outline
                      size={"sm"}
                      onClick={this.toggle("Completed")}
                      hidden={this.state.orderStatus === "READYFORDELIVERY"}
                    >
                      Delivered
                    </MDBBtn>
                    <MDBBtn
                      outline
                      size={"sm"}
                      onClick={() => {
                        this.toggle("CancelOrder")();
                        this.setState({ section: "Completed" });
                      }}
                    >
                      {this.state.loadingCancel ? (
                        <div
                          className="spinner-border spinner-border-sm fast"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        <>Cancel Order</>
                      )}
                    </MDBBtn>
                  </div>
                )}
            </div>
          </div>
        </div>
        <MDBModal
          isOpen={this.state.modalAddPrescription}
          toggle={() => {
            this.props.updateOrder();
            this.toggle("AddPrescription");
          }}
          fullHeight
          position="right"
        >
          <MDBModalHeader
            toggle={() => {
              this.props.updateOrder();
              this.toggle("AddPrescription");
            }}
          >
            Approve Order
          </MDBModalHeader>
          <MDBModalBody>
            <div className={"col-12 row mx-0 mb-4 p-0"}>
              {this.state.displayPrescriptionList.length > 0 && (
                <div className={"col-12 p-0 mt-3"}>
                  <ul
                    className="list-group list-group-horizontal-lg"
                    style={{ clear: "both", display: "block", content: "" }}
                  >
                    {this.state.displayPrescriptionList.map((row, index) => (
                      <li
                        key={index}
                        className="list-group-item"
                        style={{ float: "left" }}
                      >
                        {this.createImageUrl(row.path)}
                        <div className={"d-flex justify-content-center my-1"}>
                          File_{index + 1}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <div className={"col-12 row mx-0 mb-4 p-0"}>
              <div className={"col-md-12 m-0"}>
                <MDBInput
                  onClick={this.selectPrescriptionUploadOption(
                    "COLLECT_ON_DELIVERY"
                  )}
                  checked={
                    this.state.radio === "COLLECT_ON_DELIVERY" ? true : false
                  }
                  label="Collect prescription on delivery"
                  type="radio"
                  id="radio1"
                />
                <MDBInput
                  onClick={this.selectPrescriptionUploadOption(
                    "UPLOAD_BY_ADMIN"
                  )}
                  checked={
                    this.state.radio === "UPLOAD_BY_ADMIN" ? true : false
                  }
                  label="Upload prescription "
                  type="radio"
                  id="radio2"
                />
                <MDBInput
                  onClick={this.selectPrescriptionUploadOption(
                    "PRESCRIPTION_AVAILABLE"
                  )}
                  checked={
                    this.state.radio === "PRESCRIPTION_AVAILABLE" ? true : false
                  }
                  label="Required Prescription available"
                  type="radio"
                  disabled={this.state.displayPrescriptionList.length === 0}
                  id="radio3"
                  className={"fs-5"}
                />
              </div>
            </div>
            {this.state.radio === "UPLOAD_BY_ADMIN" && (
              <div className={"col-12 row mx-0 mb-4 p-0"}>
                <div className={"col-md-12 m-0 card"}>
                  <UploadPrescription
                    userId={this.state.orderDetails.customerDetails._id}
                    getUploadedPrescription={this.getUploadedPrescription}
                  />
                </div>
              </div>
            )}
          </MDBModalBody>
          <MDBModalFooter>
            <button
              type="button"
              className="btn btn-outline waves-effect btn-sm"
              onClick={() => {
                this.props.updateOrder();
                this.toggle("AddPrescription");
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={this.approveOrder}
              disabled={this.disableApproveButton()}
            >
              {this.state.loadingApprove ? (
                <div
                  className="spinner-border spinner-border-sm fast"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <>Approve Order</>
              )}
            </button>
          </MDBModalFooter>
        </MDBModal>
        <MDBModal
          isOpen={this.state.modalOutForDelivery}
          toggle={this.toggle("OutForDelivery")}
          size="lg"
        >
          <MDBModalHeader toggle={this.toggle("OutForDelivery")}>
            {this.state.deliveryType.toUpperCase() === "PICKUP" ? (
              <p className={"font-weight-bold"}>Ready For Pickup</p>
            ) : (
              <p className={"font-weight-bold"}>Out For Delivery</p>
            )}
          </MDBModalHeader>
          <MDBModalBody>
            <div className={"col-12 row mx-0 mb-4 p-0"}>
              {this.state.deliveryType.toUpperCase() !== "PICKUP" && (
                <div className={"col-md-4 m-0 pt-3"}>
                  <p className={"font-weight-bold"}>Will be delivered by:</p>
                  <div>
                    <MDBSelect
                      key={this.state.keyQU}
                      getValue={this.selectDeliveryPerson}
                    >
                      <MDBSelectInput selected="Select Delivery Person" />
                      <MDBSelectOptions search={true}>
                        <MDBSelectOption value="" disabled selected>
                          Select Delivery Person{" "}
                        </MDBSelectOption>
                        {this.state.delPersonList.map((item, idx) => (
                          <MDBSelectOption
                            value={item._id}
                            key={idx}
                            selected={
                              this.state.selectedDeliveryPersonId === item._id
                            }
                          >
                            {item.name} [
                            <span className={"text-danger"}>
                              {" "}
                              {item.phone}{" "}
                            </span>
                            ]
                          </MDBSelectOption>
                        ))}
                      </MDBSelectOptions>
                    </MDBSelect>
                  </div>
                </div>
              )}
              <div className={"col-md-8 m-auto"}>
                {this.state.deliveryType.toUpperCase() === "PICKUP" ? (
                  <>
                    <p className={"font-weight-bold"}>
                      The package will be available in between:
                    </p>
                    <div className={"col-12 row m-0 p-0"}>
                      <div className={"col-md-6 m-0"}>
                        <MuiPickersUtilsProvider
                          utils={MomentUtils}
                          className={"text-primary"}
                        >
                          <TimePicker
                            showTodayButton
                            todayLabel="now"
                            label="From"
                            value={this.state.fromTime}
                            minutesStep={5}
                            onChange={this.handleFromTime}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                      <div className={"col-md-6 m-0"}>
                        <MuiPickersUtilsProvider
                          utils={MomentUtils}
                          className={"text-primary"}
                        >
                          <TimePicker
                            showTodayButton
                            todayLabel="now"
                            label="To"
                            value={this.state.toTime}
                            minutesStep={5}
                            onChange={this.handleToTime}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <p className={"font-weight-bold"}>Will be delivered:</p>
                    <div className={"col-12 row m-0 p-0"}>
                      <div className={"col-md-6 m-0"}>
                        On{" "}
                        {moment(this.state.orderDetails.window.date).format(
                          "ll"
                        )}{" "}
                      </div>
                      <div className={"col-md-6 m-0"}>
                        between {this.state.orderDetails.window.start} and{" "}
                        {this.state.orderDetails.window.end}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              size={"sm"}
              color="default"
              onClick={this.toggle("OutForDelivery")}
              className={"btn btn-outline-default"}
            >
              Cancel
            </MDBBtn>
            {(this.state.deliveryType.toUpperCase() === "DELIVER" ||
              this.state.deliveryType.toUpperCase() === "SHIP") && (
              <MDBBtn
                size={"sm"}
                color="default"
                onClick={() => this.updateStatus("OUTFORDELIVERY")}
                disabled={this.state.selectedDeliveryPersonId === ""}
              >
                {this.state.loading ? (
                  <div
                    className="spinner-border spinner-border-sm fast"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  <>Save</>
                )}
              </MDBBtn>
            )}
            {this.state.deliveryType.toUpperCase() === "PICKUP" && (
              <MDBBtn
                size={"sm"}
                color="default"
                onClick={() => this.updateStatus("OUTFORDELIVERY")}
              >
                {this.state.loading ? (
                  <div
                    className="spinner-border spinner-border-sm fast"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  <>Save</>
                )}
              </MDBBtn>
            )}
          </MDBModalFooter>
        </MDBModal>
        <MDBModal
          isOpen={this.state.modalCompleted}
          toggle={this.toggle("Completed")}
        >
          <MDBModalHeader toggle={this.toggle("Completed")}></MDBModalHeader>
          <MDBModalBody>
            <div className={"col-12 row mx-0 mb-4 p-0"}>
              <div className={"col-md-6 m-auto"}>
                <MuiPickersUtilsProvider
                  utils={MomentUtils}
                  className={"text-primary"}
                >
                  <DateTimePicker
                    value={this.state.orderCompletedOn}
                    onChange={this.handleOrderCompletedOn}
                    maxDate={new Date()}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>
            <div className={"col-12 row mx-0 mb-4 p-0"}>
              <div className={"col-md-12 m-auto"}>
                <h5>
                  The order has been delivered on{" "}
                  {moment(this.state.orderCompletedOn).format("ll")} at{" "}
                  {moment(this.state.orderCompletedOn).format("LT")}
                </h5>
              </div>
            </div>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              size={"sm"}
              color="default"
              onClick={this.toggle("Completed")}
              className={"btn btn-outline-default"}
            >
              Cancel
            </MDBBtn>
            <MDBBtn
              size={"sm"}
              color="default"
              onClick={() => this.updateStatus("COMPLETED")}
            >
              {this.state.loading ? (
                <div
                  className="spinner-border spinner-border-sm fast"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <>Save</>
              )}
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
        <MDBModal
          isOpen={this.state.modalUploadPrescription}
          toggle={this.toggle("UploadPrescription")}
        >
          <MDBModalHeader toggle={this.toggle("UploadPrescription")}>
            Upload Prescription
          </MDBModalHeader>
          <MDBModalBody>
            <UploadPrescription
              userId={this.state.orderDetails.customerDetails._id}
              getUploadedPrescription={this.getUploadedPrescription}
            />
          </MDBModalBody>
        </MDBModal>
        <MDBModal
          isOpen={this.state.modalCancelOrder}
          toggle={this.toggle("CancelOrder")}
          centered
        >
          <MDBModalHeader toggle={this.toggle("CancelOrder")}>
            Cancel Order
          </MDBModalHeader>
          <MDBModalBody>
            <div className={"col-12 mx-0 p-0 row"}>
              <div className={"col-12 p-2"}>
                <b>Select cancel note</b>
                <MDBSelect
                  getValue={(val) =>
                    this.setState({ cancellationNote: val[0] })
                  }
                >
                  <MDBSelectInput selected="Choose your option" />
                  <MDBSelectOptions>
                    {this.state.cancellationNotes.map((item, index) => (
                      <MDBSelectOption key={index} value={item.value}>
                        {item.text}
                      </MDBSelectOption>
                    ))}
                  </MDBSelectOptions>
                </MDBSelect>
              </div>
              {this.state.cancellationNote === "Other" && (
                <div className={"col-12 p-2"}>
                  <MDBInput
                    type={"textarea"}
                    background
                    size="sm"
                    label={"Cancellation Note"}
                    value={this.state.cancelOrderNote}
                    getValue={(e) => {
                      this.setState({ cancelOrderNote: e });
                    }}
                  />
                </div>
              )}

              <div className={"col-12 p-2"}>
                <MDBInput
                  className={"pr-4"}
                  checked={this.state.informCustomerAboutCancellation}
                  label={"Inform customer through SMS"}
                  type="checkbox"
                  id={"id_cancellation"}
                  getValue={() => this.informCustomerToggle()}
                />
              </div>
            </div>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              size={"sm"}
              color="default"
              onClick={this.toggle("CancelOrder")}
              className={"btn btn-outline-default"}
            >
              Cancel
            </MDBBtn>
            <MDBBtn
              size={"sm"}
              onClick={() => this.updateStatus("CANCELLED", this.state.section)}
            >
              {this.state.loadingCancel ? (
                <div
                  className="spinner-border spinner-border-sm fast"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <>Cancel Order</>
              )}
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal
          isOpen={this.state.modalShippingOrder}
          toggle={this.toggle("ShippingOrder")}
          backdrop={false}
          size={"fluid"}
        >
          <MDBModalHeader toggle={this.toggle("ShippingOrder")}>
            Create Shipping Order
          </MDBModalHeader>
          <MDBModalBody>
            <CreateShippingOrder
              CreateShippingOrderSuccess={this.CreateShippingOrderSuccess}
              CreateShippingOrderFailed={this.CreateShippingOrderFailed}
              closeShippingOrderModal={this.closeShippingOrderModal}
              updateNimbusToken={this.updateNimbusToken}
              nimbusPayload={this.nimbusPayload}
              nimbusToken={this.state.nimbusToken}
              updateNimbusPayload={this.updateNimbusPayload}
              aggregator={this.state.aggregator}
              orderDetails={this.state.orderDetails}
            />
          </MDBModalBody>
        </MDBModal>

        <MDBModal
          isOpen={this.state.modalEcomShipment}
          toggle={this.toggle("EcomShipment")}
        >
          <MDBModalHeader toggle={this.toggle("EcomShipment")}>
            Cancel Ecom Express Shipment Order?
          </MDBModalHeader>
          <MDBModalBody>
            Are you sure you want to cancel the shipment of Ecom Express with
            AWB number {this.state.activeEcomAwb}?
            {this.state.activeEcomStatus.length}
          </MDBModalBody>
          <MDBFooter>
            <MDBBtn
              color={"danger"}
              onClick={() => this.cancelEcomOrder(this.state.activeEcomAwb)}
            >
              Yes
            </MDBBtn>
            <MDBBtn onClick={this.toggle("EcomShipment")}>No</MDBBtn>
          </MDBFooter>
        </MDBModal>
      </React.Fragment>
    );
  }
}

export default ManageStatus;
