import React from "react";
import {MDBBox, MDBBreadcrumb, MDBBreadcrumbItem} from "mdbreact";
import {Link} from "react-router-dom";
import AddStorageLocationCmp from "../../components/storageLocation/addStorageLocation";
class AddStorageLocation extends React.Component {
    render() {
        return(
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem><Link to={'/view-storage-locations'}>Storage Locations</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Add Storage Location</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <MDBBox className={"min-vh-100"}>
                    <AddStorageLocationCmp/>
                </MDBBox>
            </React.Fragment>
        )
    }
}
export default AddStorageLocation;