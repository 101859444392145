import React from "react";
import {Link} from "react-router-dom";
import { withRouter } from "react-router-dom";
import {
    MDBContainer, MDBFormInline, MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBBreadcrumb, MDBBreadcrumbItem,
    MDBModalHeader, MDBModalBody, MDBModalFooter, MDBModal, MDBDataTableV5, MDBDataTable
} from 'mdbreact';
import UserStore from "../../stores/userStore";
import {toast} from "react-toastify";
import dataTableUser from "./dataTableUser";
import config from "../../config";
import AuthService from "../../services/authService";
import SimpleBackdrop from "../common/overlay";

class ListOrgUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: {},
            orgId:"",
            activeOrgUserName:"",
            activeOrgUserId:0,
            orgUserList:[],
            orgUserApiResultCame:false,
            progressStatus:false,
            data:{},
            activeOrgUser:{
                imageUrl:{
                    filePathoriginal:""
                }
            },
            modalStatus:false,
            statusActionName:"",
            disableRetreatAfterSorting:false
        }
    }
    // This methods calls first
    async componentDidMount() {
        let userData=await UserStore.fetchSessionData();
        this.setState({userData: userData})

        const { orgId } = this.props.queryString;
        this.setState({orgId:orgId})
        // We want to make sure that we call the orgUser when this component loads.
        this.fetchOrgUser()
    }
    // This will fetch the orgUser and store the data in state and initiate the MDBDataTable
    fetchOrgUser = async () => {
        await this.setState({disableRetreatAfterSorting:true});
        let userData=await UserStore.fetchSessionData();
        this.setState({progressStatus:true});
        let data = await AuthService.fetchOrgUsers(this.state, userData);
        this.setState({orgUserApiResultCame:true})
        this.setState({progressStatus:false});
        console.log(data);
        if(data){
            try{
                this.setState({progressStatus: false, orgUserList: data});
                let dataTablePayload={
                    columns:dataTableUser.user,
                    rows:data.map((item, index) => {
                        item["handleEdit"]=<div color="primary" className={"blue-text btn-link cursor-pointer"} size="sm" onClick={()=>{this.onEdit(item)}}>Edit</div>;
                        item["handleStatus"]=<div color="primary" className={"blue-text btn-link cursor-pointer"} size="sm" onClick={()=>{this.initChangeStatus(item)}}>{(item.isActive === "Y") ? "Deactivate" : "Activate"}</div>;
                        return item;
                    })
                };
                this.setState({data:dataTablePayload});
                if(data.length > 0){
                    if(data[0].hasOwnProperty("orgDetails")){
                        if(data[0].orgDetails[0].hasOwnProperty("orgName")){
                            this.setState({
                                orgName:data[0].orgDetails[0].orgName
                            })
                        }
                    }
                }
            } catch (e) {
                toast.error("Something went wrong while fetching user.")
            }
        }
        await this.setState({disableRetreatAfterSorting:false});
    }
    // This toggle opens or closes the delete orgUser modal
    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    }
    // This toggle opens or closes the custom lightbox
    toggleLB = () => {
        this.setState({
            modalLB: !this.state.modalLB
        });
    }
    // This triggers from MDBDataTable, aim is to redirect to edit page
    onEdit=(row)=>{
        this.props.history.push('/edit-pharmacy-user/'+this.state.orgId+"/"+row._id);
    }
    // This redirect to new orgUser
    goToNewOrgUser = () =>{
        this.props.history.push('/add-pharmacy-user/'+this.state.orgId);
    }
    initChangeStatus = (row) => {
        this.setState({ activeOrgUserId:row._id, statusAction: (row.isActive === "Y") ? "deactivate" : "activate", statusActionName: row.orgName});
        this.toggleStatus();
    }
    updateStatus = async () => {
        let userData=await UserStore.fetchSessionData();
        this.setState({progressStatus:true});
        let status = (this.state.statusAction === "activate") ? "Y" : "N";
        let res =await AuthService.updateOrgUserStatus(this.state,status,userData);
        this.setState({progressStatus:false});
        this.toggleStatus()
        this.setState({ activeOrgUserId:0, statusAction: "", statusActionName: ""});
        if(res===false){
            toast.error("Something went wrong.");
            return true;
        }else{
            toast.success("User status updated successfully.");
            this.fetchOrgUser();
        }
    }
    toggleStatus = () => {
        this.setState({
            modalStatus: !this.state.modalStatus
        });
    }
    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.progressStatus}/>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>{this.state.orgName} Pharmacy Users</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <MDBContainer className={'w-100 mw-100'}>
                    <MDBCard className={"mt-2"}>
                        <div className={'col-12 p-2 grey lighten-4 border-bottom d-flex justify-content-between align-items-center'}>
                            <b className={"font-weight-bold"}>{this.state.orgName} Staff</b>
                            {(this.state.userData.type === "platformAdmin") &&
                            <MDBBtn onClick={this.goToNewOrgUser}>Add New Staff</MDBBtn>
                            }
                        </div>

                        <MDBCardBody>
                            {(this.state.orgUserApiResultCame && this.state.orgUserList.length < 1) &&
                                <div className={'no-data-container'}>No org user found.</div>
                            }
                            {this.state.orgUserList.length > 0 &&
                            // <MDBDataTableV5
                            //     hover
                            //     entriesOptions={[10,15, 20, 25]}
                            //     entries={10}
                            //     pagesAmount={4}
                            //     data={this.state.data}
                            //     striped
                            //     barReverse
                            //     materialSearch
                            //     searchTop
                            //     searchBottom={false}
                            // />
                            <MDBDataTable
                                striped
                                data={this.state.data}
                                materialSearch
                                noBottomColumns
                                hover
                                disableRetreatAfterSorting={this.state.disableRetreatAfterSorting}

                            />
                            }
                        </MDBCardBody>
                    </MDBCard>
                    <MDBModal isOpen={this.state.modalLB} toggle={this.toggleLB} size={"fluid"}>
                        <MDBModalHeader toggle={this.toggleLB}></MDBModalHeader>
                        <MDBModalBody className={"float-center"}>
                            <div style={{"textAlign":"center"}}>
                                <img src={config.IMAGE_HOST + this.state.activeOrgUser.imageUrl.filePathOriginal} className={"float-center"}
                                     style={{maxWidth: "940px", maxHeight: "500px"}} alt={""} />
                            </div>
                        </MDBModalBody>
                    </MDBModal>
                    <MDBModal isOpen={this.state.modalStatus} toggle={this.toggleStatus}>
                        <MDBModalHeader toggle={this.toggleStatus}>Update User Status</MDBModalHeader>
                        <MDBModalBody>
                            Are you sure you want to {this.state.statusAction} user?
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn onClick={this.updateStatus} disabled={this.state.progressStatus}>{this.state.statusAction}</MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>
                </MDBContainer>
            </React.Fragment>
        );
    }
}
export default withRouter(ListOrgUser)