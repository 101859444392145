import React from "react";
import {
    MDBContainer, MDBRow, MDBBtn, MDBCol, MDBIcon, MDBModal, MDBModalHeader, MDBModalBody, toast
} from 'mdbreact';
import UserStore from "../../../stores/userStore";
import PharmacyService from "../../../services/pharmacyService";
import config from "../../../config";
import Reorder, {reorder} from "react-reorder";
class MultiPicDirectUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            allowedFileSize: 20971520, //20MB
            fileList: props.hasOwnProperty("fileList") ? props.fileList : [],
            loading: false,
            modalLB:false,
            activeImage:"",
        }
    }

    onFileLoad = async (e) => {
        if (e.currentTarget.files.length > 0) {
            for (let fl of e.currentTarget.files) {
                if (this.checkFileSize(fl)) {
                    await this.uploadFiles(fl)
                }
            }
            e.target.value = null
        }
    }

    checkFileSize = (file) => {
        if (file.size > this.state.allowedFileSize) {
            toast.error("File is too big. Maximum upload file size is 20 MB")
            return false
        }
        return true
    }

    uploadFiles = async (file) => {
        let userData=await UserStore.fetchSessionData();
        const data = new FormData()
        data.append('fileName', file);
        this.setState({loading: true})
        let uploadedFile = await PharmacyService.uploadFile(data,userData);
        if (uploadedFile) {
            let fileList = this.state.fileList
            fileList.push(uploadedFile.path)
            await this.setState({fileList})
            await this.uploadedFileCallback(fileList)
        } else {
            toast.error("There is something wrong, please try again after a while.")
        }
        this.setState({loading: false})
    }

    toggleLB = () => {
        this.setState({
            modalLB: !this.state.modalLB
        });
    }

    deleteFile = async (fileIndex) => {
        let fileList = this.state.fileList
        fileList.splice(fileIndex, 1)
        await this.setState({fileList})
        await this.uploadedFileCallback(fileList)
    }

    uploadedFileCallback = async (fileList) => {
        await this.props.getUploadedFiles(fileList)
    }

    onReorder = async (event, previousIndex, nextIndex) => {
        this.setState({
            fileList: reorder(this.state.fileList, previousIndex, nextIndex)
        })
        await this.uploadedFileCallback(this.state.fileList)
    }
    render() {
        return (
            <React.Fragment>
                <div className={'col-md-12'}>
                    <div className="form-group add-product-img-button-position">
                        <input type="file" hidden multiple name="file" accept={"image/jpeg, image/png, image/gif, image/bmp, image/jfif"} ref={input=>this.uploadDoc=input} onChange={this.onFileLoad.bind(this)}/>
                        <a role="button" className={"text-primary"} onClick={() => this.uploadDoc.click()} block rounded disabled={this.state.loading}>
                            {(this.state.loading === false && this.state.fileList.length === 0) &&
                            <span>ADD IMAGE</span>
                            }
                            {(this.state.loading === false && this.state.fileList.length > 0) &&
                            <span>ADD ANOTHER IMAGE</span>
                            }
                            {this.state.loading &&
                                <div className="spinner-border spinner-border-sm fast" role="status"></div>
                            }
                        </a>
                    </div>
                    <div className={this.state.loading || this.state.fileList.length > 0 ? 'add-img-container': ''}>
                        <Reorder
                            reorderId="uploaded-image" // Unique ID that is used internally to track this list (required)
                            reorderGroup="reorder-group" // A group ID that allows items to be dragged between lists of the same group (optional)
                            component="ul" // Tag name or Component to be used for the wrapping element (optional), defaults to 'div'
                            // placeholderClassName="placeholder" // Class name to be applied to placeholder elements (optional), defaults to 'placeholder'
                            draggedClassName="dragged" // Class name to be applied to dragged elements (optional), defaults to 'dragged'
                            lock="vertical" // Lock the dragging direction (optional): vertical, horizontal (do not use with groups)
                            // holdTime={500} // Default hold time before dragging begins (mouse & touch) (optional), defaults to 0
                            // touchHoldTime={500} // Hold time before dragging begins on touch devices (optional), defaults to holdTime
                            // mouseHoldTime={200} // Hold time before dragging begins with mouse (optional), defaults to holdTime
                            onReorder={this.onReorder.bind(this)} // Callback when an item is dropped (you will need this to update your state)
                            autoScroll={true} // Enable auto-scrolling when the pointer is close to the edge of the Reorder component (optional), defaults to true
                        >
                            {this.state.fileList.map((row, index) => (
                                <li key={index} className="list-group-item float-left text-center">
                                    <div className={'add-product-img-list-box'}>
                                        <img src={config.IMAGE_HOST + row.filePathThumb} alt="uploaded doc"
                                             className={'add-product-img-size'}
                                             onClick={()=>{this.setState({activeImage:row.filePathOriginal, modalLB:true})}}/>
                                    </div>

                                    <small className={"my-1"}>File_{index+1}</small>
                                    <button className={'btn btn-outline-light btn-sm p-0 btn-block m-0'}
                                            onClick={() => this.deleteFile(index)} >
                                        <span className={"text-muted"}>Remove</span>
                                    </button>
                                </li>
                            ))}
                        </Reorder>
                    </div>
                    {(this.state.loading === false && this.state.fileList.length === 0) &&
                        <div className={'py-5 text-center red-text'}>
                            No images have been uploaded.
                        </div>
                    }

                    <MDBModal isOpen={this.state.modalLB} toggle={this.toggleLB} size={"lg"}>
                        <MDBModalHeader toggle={this.toggleLB} className={"border-0"}></MDBModalHeader>
                        <MDBModalBody className={"mt-n4"}>
                            <div style={{"textAlign":"center"}}>
                                <img src={config.IMAGE_HOST + this.state.activeImage}
                                     style={{maxWidth: "700px", maxHeight: "700px"}} alt={""}/>
                            </div>
                        </MDBModalBody>
                    </MDBModal>
                </div>
            </React.Fragment>
        );
    }
}
export default MultiPicDirectUpload;