import React from "react";
import {    MDBContainer,  MDBRow, MDBInput, MDBBtn, MDBCardHeader, MDBFormInline, MDBCardBody, MDBCard, MDBCol,MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBIcon} from 'mdbreact';
import "./category.css";
import {toast} from "react-toastify";
import InventoryService from "../../services/inventoryService";
import UserStore from "../../stores/userStore";
import FileUpload from "../common/fileUpload/fileUpload";
import PharmacyService from "../../services/pharmacyService";
import {withRouter} from "react-router-dom";
import config from "../../config";
import SimpleBackdrop from "../common/overlay";

class EditCategoryCmp extends React.Component {
    async componentDidMount() {
        if(this.props.loadedOnPopup === true){
            let categoryId = this.props.categoryId;
            await this.setState({categoryId:categoryId, loadedOnPopup:true})
        } else {
            const { categoryId } = this.props.queryString;
            await this.setState({categoryId:categoryId})
        }
        await this.fetchData();
    }
    fetchData= async ()=>{
        let userData = await UserStore.fetchSessionData();
        this.setState({progressStatus: true});
        let data=await InventoryService.fetchCategoryById(userData,this.state.categoryId);
        this.setState({progressStatus: false});
        if(data===false){
            toast.error("We are facing some issue. Please try again later.");
        }else{
            this.setState({categoryData:data,categoryName:data.categoryName});
            if(data.status==='Y'){
                this.setState({status:true});
            }else{
                this.setState({status:false});
            }
            if(data.featured==='Y'){
                this.setState({featured:true});
            }else{
                this.setState({featured:false});
            }
            if(data.imageUrl.hasOwnProperty("filePathOriginal")){
                this.setState({imageUrl:data.imageUrl})
                this.setState({imageActionNeeded:"Update"})
            } else {
                this.setState({imageActionNeeded:"Add"})
            }
        }
    }
    constructor(props) {
        super(props);
        this.state = {
            categoryId:'',
            progressStatus:false,
            progressStatusFileUpload:false,
            categoryData:{},
            categoryName:'',
            status:false,
            featured:false,
            categoryNameError:false,
            imageUrl:{},
            modalDelImage:false,
            imageActionNeeded:"",
            resetForm:false,
            loadedOnPopup:false
        }
    }
    categoryChange=(val)=>{
        this.setState({categoryName:val});
    }
    updateCategory=async ()=>{
        let userData = await UserStore.fetchSessionData();
        //validations
        if(this.state.categoryName==='' || this.state.categoryName===null){
            this.setState({categoryNameError:true});
            return true;
        } else {
            this.setState({categoryNameError:false});
        }
        this.setState({progressStatus: true});
        let status=this.state.status===true?"Y":"N";
        let featured=this.state.featured===true?"Y":"N";
        let data=await InventoryService.editCategory(this.state,userData,status,featured);
        this.setState({progressStatus: false});
        if(data===false){
            toast.error("We are facing some issue. Please try again later.");
        }else{
            if(this.state.loadedOnPopup === true){
                this.props.onEditComplete()
            } else {
                this.setState({resetForm:true})
                this.fetchData()
            }
            toast.success("Category updated successfully.");
        }
    }
    getStatus=(val)=>{
        this.setState({status:val});
    }
    getFeature=(val)=>{
        this.setState({featured:val});
    }
    validateCategory=()=>{
        if(this.state.categoryName === ""){
            this.setState({categoryNameError:true});
        } else {
            this.setState({categoryNameError:false});
        }
    }
    fileUploadCallback = (uploadedFileObject) => {
        this.setState({ "imageUrl": uploadedFileObject.path, "progressStatusFileUpload":false})
    }
    initUploadCallback = () => {
        this.setState({"progressStatusFileUpload":true, resetForm:false})
    }
    initDelete = () => {
        this.toggleDelete()
    }
    confirmDelete = async () => {
        let userData=await UserStore.fetchSessionData();
        this.setState({progressStatus:true});
        let status=await PharmacyService.deleteCategory(this.state,userData);
        this.setState({progressStatus:false});
        if(status===false){
            toast.error("Something went wrong.");
            return true;
        }else{
            toast.success("Category deleted successfully.");
            this.toggleDelete();
            if(this.state.loadedOnPopup === true){
                this.props.onEditComplete()
            } else {
                this.props.history.push('/view-product-categories');
            }

        }
    }
    toggleDelete = () => {
        this.setState({
            modalDelete: !this.state.modalDelete
        });
    }
    toggleDelImage = () => {
        this.setState({
            modalDelImage: !this.state.modalDelImage
        });
    }
    initDeleteDoc = () => {
        this.toggleDelImage();
    }
    confirmDeleteImage = () => {
        this.setState({"imageUrl": {}})
        this.toggleDelImage();
        this.updateCategory()
    }
    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.progressStatus}/>
                <MDBContainer>
                    <MDBCard className={"mt-2"}>
                        <MDBCardHeader>
                            <span className={"float-left"}>Edit Category</span>
                            <MDBFormInline className="float-right md-form mr-auto m-0">
                                {this.state.loadedOnPopup === false &&
                                <MDBBtn outline color="blue-grey" size="sm"
                                        onClick={() => this.props.history.push('/view-product-categories')}
                                        className="mr-auto" disabled={this.state.progressStatus}>
                                    Back
                                </MDBBtn>
                                }
                                <MDBBtn outline color="blue-grey" size="sm" onClick={this.initDelete} className="mr-auto"
                                        disabled={this.state.progressStatus} >
                                    Delete
                                </MDBBtn>
                                <MDBBtn outline color="blue-grey" size="sm" onClick={this.updateCategory} className="mr-auto"
                                        disabled={this.state.progressStatus || this.state.progressStatusFileUpload}>
                                    Save
                                </MDBBtn>
                            </MDBFormInline>
                        </MDBCardHeader>
                        <MDBCardBody>
                            <MDBRow className="d-flex justify-content-left">
                                <MDBCol>
                                    <MDBInput label="Category name" onBlur={this.validateCategory} className={this.state.categoryNameError ? "border-danger" :""} getValue={this.categoryChange} value={this.state.categoryName} disabled={this.state.progressStatus}/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="d-flex justify-content-left">
                                <MDBCol>
                                    <MDBInput label="Featured" type="checkbox" checked={this.state.featured} id="checkbox2" getValue={this.getFeature} disabled={this.state.progressStatus}/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    {(this.state.imageUrl.hasOwnProperty("filePathThumb") && this.state.imageActionNeeded === "Update") &&
                                    <div>
                                        <img src={config.IMAGE_HOST + this.state.imageUrl.filePathThumb} className={"float-left"} style={{maxWidth: "100px", maxHeight: "100px"}} alt={""} />
                                        <span style={{"cursor":"pointer"}} className={"p-1"} onClick={()=>this.initDeleteDoc()}><MDBIcon icon="trash" /></span>
                                    </div>
                                    }
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    {this.state.imageActionNeeded === "Add" &&
                                    <FileUpload buttonLabel={"Add Category Image"} initUploadCallback={this.initUploadCallback} fileUploadCallback={this.fileUploadCallback}  nextProps={this.state.resetForm}></FileUpload>
                                    }
                                    {this.state.imageActionNeeded === "Update" &&
                                    <FileUpload buttonLabel={"Update Category Image"} initUploadCallback={this.initUploadCallback} fileUploadCallback={this.fileUploadCallback}  nextProps={this.state.resetForm}></FileUpload>
                                    }
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                        <MDBModal isOpen={this.state.modalDelete} toggle={this.toggleDelete}>
                            <MDBModalHeader toggle={this.toggleDelete}>Delete Category</MDBModalHeader>
                            <MDBModalBody>
                                Are you sure you want to delete this category?
                            </MDBModalBody>
                            <MDBModalFooter>
                                <MDBBtn onClick={this.confirmDelete} disabled={this.state.progressStatus}>Delete</MDBBtn>
                            </MDBModalFooter>
                        </MDBModal>
                        <MDBModal isOpen={this.state.modalDelImage} toggle={this.toggleDelImage}>
                            <MDBModalHeader toggle={this.toggleDelImage}>Delete Category Image</MDBModalHeader>
                            <MDBModalBody>
                                Are you sure you want to delete the category image?
                            </MDBModalBody>
                            <MDBModalFooter>
                                <MDBBtn disabled={this.state.progressStatus} color="primary" onClick={this.confirmDeleteImage}>Delete</MDBBtn>
                            </MDBModalFooter>
                        </MDBModal>
                    </MDBCard>
                </MDBContainer>
            </React.Fragment>
        );
    }
}
export default withRouter(EditCategoryCmp)


