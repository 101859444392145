import React from 'react';
import {MDBBtn, MDBCol, MDBContainer, MDBInput, MDBRow, MDBSelect, toast} from "mdbreact";
import AuthService from "../../services/authService";
import UserStore from "../../stores/userStore";
import util from "../../utility/utility";
import orgConfig from "../../orgConfig";
export default  class Dependents extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        userId:"",
        dependentId:"",
        email:"",
        name:"",
        lastName:"",
        relation:"BROTHER",
        contactNo:"",
        contactNoError:false,
        emailError:false,
        lastNameError:false,
        nameError:false,
        relationError:false,
        relations:[ {
                text:"Brother",
                value:"Brother",
                checked:true,
            },{
                text:"Sister",
                value:"Sister",
            },{
                text:"Mother",
                value:"Mother",
            },{
                text:"Father",
                value:"Father",
            },{
                text:"Daughter",
                value:"Daughter",
            },{
                text:"Son",
                value:"Son",
            },{
                text:"Grandfather",
                value:"Grandfather",
            },{
                text:"Grandmother",
                value:"Grandmother",
            },{
                text:"Wife",
                value:"Wife",
            },{
                text:"Husband",
                value:"Husband",
            },{
                text:"Spouse",
                value:"Spouse",
            }, {
                text:"Aunt",
                value:"Aunt",
            },{
                text:"Uncle",
                value:"Uncle",
            },{
                text:"Niece",
                value:"Niece",
            },{
                text:"Nephew",
                value:"Nephew",
            },{
                text:"Cousin Brother",
                value:"Cousin Brother",
            },{
                text:"Cousin Sister",
                value:"Cousin Sister",
            },{
                text:"Friend",
                value:"Friend",
            },
            {
                text:"Brother-in-law",
                value:"Brother-in-law",
            },{
                text:"Sister-in-law",
                value:"Sister-in-law",
            },{
                text:"Mother-in-law",
                value:"Mother-in-law",
            },{
                text:"Father-in-law",
                value:"Father-in-law",
            },{
                text:"Daughter-in-law",
                value:"Daughter-in-law",
            },{
                text:"Son-in-law",
                value:"Son-in-law",
            }
        ]
    }
async componentDidMount() {
        let userData = await UserStore.fetchSessionData();
        this.setState({userData: userData})
        this.setState({user: this.props.user})
        if(userData.type === "platformAdmin"){
            this.setState({orgId: userData._id})
            this.setState({numId: userData.numId})
        } else if(userData.type === "ORG_ADMIN"){
            this.setState({orgId: userData.orgDetails[0]._id})
            this.setState({numId: userData.orgDetails[0].numId})
        }
        try{
            this.setState({"default": true})
        } catch (e) {
            toast.error("Error in setting default address");
        }
    }
    handleContactNo=(e)=>{
        if(!util.isInt(e.target.value) && e.target.value !== ""){
            return false;
        }
        this.setState({contactNo:e.target.value})
    };
    handleChangeEmail=(e)=>{
        this.setState({email:e.target.value})
    };
    handleRelation=(e)=>{
        this.setState({relation:e[0]})
    };
    handleName=(e)=>{
        this.setState({name:e.target.value})
    };
    handleLastName=(e)=>{
        this.setState({lastName:e.target.value})
    };
    validateEmail = () => {
        if(this.state.email && !util.validateEmail(this.state.email)){
            this.setState({"loadingEmail": true, "emailError": true})
        } else {
            this.setState({"loadingEmail": false, "emailError": false})
        }
    }
    validateName = () => {
        if(!this.state.name || this.state.name.trim() === ""){
            this.setState({nameError: true})
        } else {
            this.setState({nameError: false})
        }
    }
    validateLastName = () => {
        if(!this.state.lastName || this.state.lastName.trim() === ""){
            this.setState({nameError: true})
        } else {
            this.setState({nameError: false})
        }
    }
    validatePhone= () => {
        if(this.state.contactNo && !util.checkPhone(this.state.contactNo)){
            this.setState({ "contactNoError": true})
        } else {
            this.setState({"contactNoError": false})
        }
    }
    addDependent=async ()=>{
        if(this.state.name.trim() === ""){
            this.setState({ "nameError": true})
            return false;
        } else {
            this.setState({ "nameError": false})
        }
        // if(this.state.lastName.trim() === ""){
        //     this.setState({ "lastNameError": true})
        //     return false;
        // } else {
        //     this.setState({ "lastNameError": false})
        // }
        if(this.state.contactNo && !util.checkPhone(this.state.contactNo)){
            this.setState({ "contactNoError": true})
            return false;
        } else {
            this.setState({"contactNoError": false})
        }
        if(this.state.email && !util.validateEmail(this.state.email)){
            this.setState({"loadingEmail": true, "emailError": true})
            return false;
        } else {
            this.setState({"loadingEmail": false, "emailError": false})
        }
        window.scrollTo(0, 0)
        this.setState({progressStatus:true});
        let status=await AuthService.addDependent(this.state);
        this.setState({progressStatus:false});
        if(status===false){
            toast.error("Something went wrong.");
        }else{
            this.props.addDependentCompleted(this.state.user.hasOwnProperty("_id") ? this.state.user._id : this.state.user._id );
        }
    }
    render() {
        return (
            <MDBContainer>
                <MDBRow >
                    <MDBCol>
                        <MDBInput onBlur={this.validateName} outline={true} className={this.state.nameError ? "border-danger" :""} maxLength="50"  type='text' label="Name *" onChange={this.handleName} value={this.state.name}/>
                    </MDBCol>
                    {/*<MDBCol>*/}
                    {/*    <MDBInput  onBlur={this.validateLastName}  outline={true} className={this.state.lastNameError ? "border-danger" :""} maxLength="50"  type='text' label="Last Name *" onChange={this.handleLastName} value={this.state.lastName}/>*/}
                    {/*</MDBCol>*/}
                    <MDBCol>
                        <MDBSelect search={true} outline={true} label={"Relation"} options={this.state.relations} getValue={this.handleRelation} />
                    </MDBCol>
                    <MDBCol>{this.state.contactNoError}
                        <MDBInput onBlur={this.validatePhone}  outline={true} className={this.state.contactNoError ? "border-danger" :""} maxLength={orgConfig.PHONE_NUMBER_LENGTH}  type='text' label="Phone" onChange={this.handleContactNo} value={this.state.contactNo}/>
                    </MDBCol>
                    <MDBCol>
                        <MDBInput outline={true} onBlur={this.validateEmail}
                                  autoComplete={'off'}
                                  className={this.state.emailError ? "border-danger" : ""}
                                  maxLength="50" type='text' label="E-mail"
                                  onChange={this.handleChangeEmail}
                                  value={this.state.email}/>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol>
                        <MDBBtn outline color="primary" size={"sm"} className={"float-right"} onClick={this.addDependent}
                                disabled={ this.state.progressStatus}>
                            Save
                        </MDBBtn>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        );
    }
}

