import React from "react";
import {
    MDBBtn,
    MDBBox,
    MDBModal,
    MDBModalHeader,
    MDBModalBody,
    MDBModalFooter, MDBSpinner
} from 'mdbreact';

export default class ViewStorageInfo extends React.Component {
    toggle=()=>{
        this.props.toggleStorage(null);
    }
    render() {
        return(
            <React.Fragment>
                <MDBModal isOpen={this.props.storageModal} toggle={this.toggle}>
                    <MDBModalHeader toggle={this.toggle}>Storage Info</MDBModalHeader>
                    <MDBModalBody>
                        {this.props.location !== null &&
                        <>
                            <MDBBox>
                                Location: {this.props.location.hasOwnProperty("storageLoc") && <>{this.props.location.storageLoc.name}</>}
                            </MDBBox>
                            <MDBBox>
                                Floor: {this.props.location.hasOwnProperty("floor") && <>{this.props.location.floor.floorName}</>}
                            </MDBBox>
                            <MDBBox>
                                Rack: {this.props.location.hasOwnProperty("rack") && <>{this.props.location.rack.rackName}</>}
                            </MDBBox>
                            <MDBBox>
                                Box: {this.props.location.hasOwnProperty("box") && <>{this.props.location.box.boxName}</>}
                            </MDBBox>
                        </>
                        }
                        {this.props.location === null &&
                        <MDBSpinner/>
                        }
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn size={'md'} onClick={this.toggle}>Close</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
            </React.Fragment>
        )
    }
}