const dataTablePrescriptionOrder = {
    prescriptionOrders: [
        {
            label: 'Order Id',
            field: 'orderCode',
            sort: 'asc',
            width: 100
        },
        {
            label: 'Order Date',
            field: 'handleDate',
            sort: 'asc',
            width: 100
        },
        {
            label: 'Customer Name',
            field: 'handleName',
            sort: 'asc',
            width: 100
        },
        {
            label: 'Shipping Address',
            field: 'handleAddress',
            sort: 'disabled',
            width: 300
        },
        {
            label: 'Contact No',
            field: 'handleContactNo',
            sort: 'asc',
            width: 100
        },
        {
            label: 'Order Status',
            field: 'status',
            sort: 'asc',
            width: 50
        },
        {
            label: '',
            field: 'handleProcessOrder',
            sort: 'disabled',
            width: 50
        }
    ]
}
export default dataTablePrescriptionOrder;