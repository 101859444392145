import React,{Suspense} from "react";
import {
    MDBBox
} from 'mdbreact';
import ListSpeciality from "../../components/specility/listSpeciality";

export default class ListSpecialityPage extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MDBBox className={"min-vh-100"}>
                    <Suspense fallback={null}>
                        <ListSpeciality/>
                    </Suspense>
                </MDBBox>
            </React.Fragment>
        );
    }
}
