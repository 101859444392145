import React from "react";
import {
  MDBBtn,
  MDBInput,
  MDBSelect,
  MDBIcon,
  MDBModal, MDBModalHeader, MDBModalBody
} from "mdbreact";
import { withRouter } from "react-router-dom";
import AuthService from "../../services/authService";
import UserStore from "../../stores/userStore";
import utility from "../../utility/utility";
import { toast } from "react-toastify";
import SimpleBackdrop from "../common/overlay";
import OrderService from "../../services/orderService";
import NewTable from "../../newComponents/NewTable/NewTable";
import Moments from "moment";
import NewPagination from "../../newComponents/NewPagination/NewPagination";

class NewOnlineOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      progressStatus: false,
      loadingPincode: false,
      customerName: "",
      customerPhone: "",
      addressList: [],
      userData: {},
      selectedAddressId: "",
      pinCodeOfSelectedAddress: "",
      addressLine1: "",
      addressLine2: "",
      pinCode: "",
      city: "",
      state: "",
      country: "India",
      name: "",
      phoneNumber: "",
      internalComment: "",
      paymentModes: [
        { text: "COD", value: "COD" },
        { text: "PREPAY", value: "PREPAY" },
      ],
      paymentMode: "",
      deliveryTypes: [
        { text: "SHIP", value: "SHIP" },
        { text: "DELIVER", value: "DELIVER" },
        { text: "PICKUP", value: "PICKUP" },
      ],
      deliveryType: "",
      orderSource: "",
      slotDays: "",
      slotDay: "",
      slots: "",
      selectedSLot: "",
      orderSources: [
        { text: "QUICK ORDER", value: "QUICK_ORDER" },
        { text: "CONTACT US", value: "CONTACT_US" },
        { text: "TELEPHONIC", value: "TELEPHONIC" },
        { text: "WEB", value: "WEB" },
        { text: "BOT", value: "BOT" },
        { text: "WHATSAPP", value: "WHATSAPP" },
      ],
      orderList: [], customerUserId: "",
      loadingOrderList: false,
      orderList: [],
      orderTotalAmount: 0,
      totalBalanceAmount: 0,
      orderRecordCount: 0,
      orderCurrentPage: 0,
      modalOrderList: false, modalCallLogs: false,
      perPage: 10,
      orderColumns : [
        { header: "Order Id", width: "5%", accessor: "orderCode"},
        { header: "Date", width: "12%", accessor: "createdAt" },
        { header: "Order Amount", width: "5%", accessor: "totalAmount" },
        { header: "Balance Amount", width: "5%", accessor: "balanceAmount" },
        { header: "Order Mode", width: "5%", accessor: "orderMode" },
        // { header: "Order Status", width: "5%", accessor: "status" },
        { header: "Payment Mode", width: "5%", accessor: "paymentMode" },
        { header: "Products", width: "63%", accessor: "products" },
      ],
      loadingCallLog: false,
      callLogs: [],
      callLogsRecordCount: 0,
      callLogsCurrentpage: 0,
      callLogColumns: [
        { header: "Date", width: "20%", accessor: "createdAt" },
        { header: "Subject", width: "20%", accessor: "subject" },
        { header: "Summary", width: "60%", accessor: "summary" },
      ],
    };
  }

  async componentDidMount() {
    let userData = await UserStore.fetchSessionData();
    this.setState({ userData });
  }
  lookupAddress = async () => {
    if (this.state.customerPhone.length !== 10) {
      toast.error("Please enter a valid phone number for address lookup.");
      return false;
    }
    this.setState({
      customerName: "",
      addressList: [],
      selectedAddressId: "",
      addressLine1: "",
      addressLine2: "",
      pinCode: "",
      city: "",
      state: "",
      country: "India",
      paymentModes: [
        { text: "COD", value: "COD" },
        { text: "PREPAY", value: "PREPAY" },
      ],
      paymentMode: "",
      deliveryTypes: [
        { text: "SHIP", value: "SHIP" },
        { text: "DELIVER", value: "DELIVER" },
        { text: "PICKUP", value: "PICKUP" },
      ],
      deliveryType: "",
      slotDays: "",
      slotDay: "",
      slots: "",
      selectedSLot: "",
      orderList: [],
      orderTotalAmount: 0,
      totalBalanceAmount: 0,
      orderRecordCount: 0,
      orderCurrentPage: 0,
      callLogs: [],
      callLogsRecordCount: 0,
      callLogsCurrentpage: 0,
    });
    this.setState({ progressStatus: true });
    let res = await AuthService.addressLookupByPhone(
      this.state.userData.sessionToken,
      this.state.customerPhone
    );
    this.setState({ progressStatus: false });
    if (res) {
      let userId = res?.address?.[0]?.createdBy?.userId;
      this.setState({ 
        addressList: res?.address,  
        customerName: res?.name,  
        customerUserId: userId
      });
      
      userId && await this.fetchCustomerOrderList(userId, this.state.orderCurrentPage + 1);
      userId && await this.fetchCustomerCallLogs(userId, this.state.callLogsCurrentpage + 1);
    }
  };
  fetchCustomerCallLogs = async (userId, currentPage) => {
    this.setState({loadingCallLog: true});
    try {
      const data = await AuthService.fetchSearchcustomerCallLogs(
        userId,
        currentPage
      );
      this.setState({
        callLogs: data.data,
        callLogsRecordCount: data.totalRecords,
        callLogsCurrentpage: data.page - 1
      });
      this.setState({loadingCallLog: false});
    } catch (error) {
      console.error("Error fetching call logs:", error);
      this.setState({loadingCallLog: false, callLogs: []});
    }
  }
  fetchCustomerOrderList = async (userId, orderCurrentPage) => {
    this.setState({loadingOrderList: true});
    try {
      const data = await AuthService.fetchOrdersByUserId(
        this.state.userData,
        userId,
        orderCurrentPage
      );
      if (data) {
        this.setState({
          orderList: data.data,
          orderTotalAmount: data.totalPurchaseAmount,
          totalBalanceAmount: data.totalBalanceAmount,
          orderRecordCount: data.totalRecords,
          orderCurrentPage: data.currentPage - 1
        });
      }
      this.setState({loadingOrderList: false});
    } catch (error) {
      console.error("Error fetching order list:", error);
      this.setState({loadingOrderList: false, orderList: []});
    }
  };
  formatCallLogsTableData = (data) => {
    const formattedData = data.map((item) => ({
      createdAt: Moments(item?.createdAt).format("lll"),
      subject: item.subject,
      summary: item.summary
    }));
    return formattedData;
  }
  formatTableData = (data) => {
    const formattedData = data.map((item) => ({
      orderCode: item?.orderCode,
      createdAt: item?.createdAt ? Moments(item?.createdAt).format("lll") : "",
      amount: item?.totalAmount?.toFixed(2),
      balanceAmount: item?.balanceAmount,
      orderMode:  item?.orderMode === "INPERSON"
        ? "Offline"
        : "Online",
      // status:
      //   item.status === "OUTFORDELIVERY" && item.deliveryType === "SHIP"
      //     ? "SHIPPED"
      //     : item.status === "READYFORDELIVERY"
      //     ? "PACKED"
      //     : item.status,
      paymentMode: item?.paymentMode,
      products: item?.products?.length > 0 ? (
        <div className="row col-12 p-0 m-0" style={{zIndex: 0}}>
            <div className="row col-12 p-1 m-0 border" style={{background: "#D3D3D3", fontWeight: "bold"}}>
              <div className="col-md-1"></div>
              <div className="col-md-7">Name</div>
              <div className="col-md-2">Price</div>
            <div className="col-md-2">Quantity</div>
          </div> 
          {item.products.map((prod, index) => (
            <div className="row col-12 p-1 m-0 border">
              <div className="col-md-1">{index+1}</div>
              <div className="col-md-7">{prod?.productName}</div>
              <div className="col-md-2">{prod?.maxRetailPrice}</div>
              <div className="col-md-2">{prod?.quantity}</div>
            </div>
          ))}
        </div>
      ) : (<>No Products found.</>)
    }));
    return formattedData;
  };
  getPhone = (val) => {
    if (val !== "" && !utility.isInt(val)) {
      return false;
    }
    this.setState({ customerPhone: val });
  };
  getAddress = (item) => {
    if (this.state.selectedAddressId === item._id) {
      this.setState({
        selectedAddressId: "",
        pinCodeOfSelectedAddress: "",
        slotDays: "",
        slotDay: "",
        slots: "",
        selectedSLot: "",
      });
    } else {
      this.setState({
        selectedAddressId: item._id,
        pinCodeOfSelectedAddress: item.pinCode,
        slotDays: "",
        slotDay: "",
        slots: "",
        selectedSLot: "",
      });
      if (this.state.customerName === "") {
        this.setState({ customerName: item.name });
      }
    }
  };
  getPinCode = (val) => {
    if (val !== "" && !utility.isInt(val)) {
      return false;
    }
    this.setState({ pinCode: val });
  };
  save = async () => {
    if (this.state.customerName === "") {
      toast.error("Please enter customer name.");
      return false;
    }
    if (this.state.customerPhone === "") {
      toast.error("Please enter customer phone number.");
      return false;
    } else {
      if (utility.checkPhone(this.state.customerPhone) === false) {
        toast.error("Please enter a valid phone number.");
        return false;
      }
    }
    if (this.state.selectedAddressId === "") {
      if (this.state.addressLine1 === "") {
        toast.error("Please enter address line 1");
        return false;
      }
      if (this.state.pinCode === "") {
        toast.error("Please enter pin.");
        return false;
      }
      if (this.state.city === "") {
        toast.error("Please enter city.");
        return false;
      }
      if (this.state.state === "") {
        toast.error("Please enter state.");
        return false;
      }
      if (this.state.country === "") {
        toast.error("Please enter country.");
        return false;
      }
    }
    if (this.state.deliveryType === "") {
      toast.error("Please select delivery type.");
      return false;
    }
    if (this.state.paymentMode === "") {
      toast.error("Please select payment mode.");
      return false;
    }
    if (this.state.orderSource === "") {
      toast.error("Please select order source.");
      return false;
    }
    if (this.state.deliveryType[0] === "DELIVER") {
      if (this.state.selectedSLot === "") {
        toast.error("Please select a slot");
        return false;
      }
    }
    let pinCode =
      this.state.pinCodeOfSelectedAddress === ""
        ? this.state.pinCode
        : this.state.pinCodeOfSelectedAddress;
    this.setState({ progressStatus: true });
    let shippingEstimation = await AuthService.checkPinStatus(pinCode);
    this.setState({ progressStatus: false });
    let shippingCharge = "";
    if (shippingEstimation) {
      shippingCharge = shippingEstimation.shippingCharge;
    } else {
      toast.error(
        "Something went wrong while fetching shipping estimation. Please try again after a while."
      );
      return false;
    }

    //creating window
    let window = "";
    if (this.state.deliveryType[0] === "DELIVER") {
      window = {
        date: this.state.selectedSLot.date,
        start: this.state.selectedSLot.slots.start,
        end: this.state.selectedSLot.slots.end,
      };
    }
    let payload = {
      internalComment: this.state.internalComment,
      customerName: this.state.customerName,
      customerPhone: this.state.customerPhone,
      addressId: this.state.selectedAddressId,
      addressLine1: this.state.addressLine1,
      addressLine2: this.state.addressLine2,
      pinCode: this.state.pinCode,
      city: this.state.city,
      state: this.state.state,
      country: this.state.country,
      name: this.state.name,
      phoneNumber:this.state.phoneNumber,
      deliveryType: this.state.deliveryType[0],
      paymentMode: this.state.paymentMode[0],
      shippingEstimation: shippingCharge,
      orderSource: this.state.orderSource[0],
      window: window,
    };
    this.setState({ progressStatus: true });
    let res = await OrderService.createOnlineOrder(
      this.state.userData.sessionToken,
      payload
    );
    this.setState({ progressStatus: false });
    if (res) {
      toast.success("Order created successfully.");
      this.resetForm();
      this.props.history.push("/process-online-order/" + res._id);
    } else {
      toast.error("Something went wrong while creating the order.");
    }
  };
  resetForm = () => {
    this.setState({
      customerName: "",
      customerPhone: "",
      addressList: [],
      selectedAddressId: "",
      addressLine1: "",
      addressLine2: "",
      pinCode: "",
      city: "",
      state: "",
      country: "India",
      paymentModes: [
        { text: "COD", value: "COD" },
        { text: "PREPAY", value: "PREPAY" },
      ],
      paymentMode: "",
      deliveryTypes: [
        { text: "SHIP", value: "SHIP" },
        { text: "DELIVER", value: "DELIVER" },
      ],
      deliveryType: "",
    });
  };
  fetchPinCodeDetails = async () => {
    if (this.state.pinCode === "") {
      toast.error("Please enter a valid pin code.");
      return true;
    }
    this.setState({ loadingPincode: true });
    let res = await AuthService.fetchPinCodeDetails(
      this.state.userData.sessionToken,
      this.state.pinCode
    );
    if (res) {
      let city = "",
        state = "";
      if (res.hasOwnProperty("city")) {
        city = res.city;
        state = utility.getState(res.state);
      }
      if (state === null || state === "" || state === undefined) {
        state = utility.firstLetter(res.state);
      }
      state = utility.firstLetter(res.state);
      this.setState({ city, state });
    } else {
      toast.error("Something went wrong while getting the pin code details.");
    }
    this.setState({ loadingPincode: false });
  };
  checkPickup = async (val) => {
    this.setState({ deliveryType: val });
  };
  checkSlotDate = async () => {
    this.setState({ slots: "", selectedSLot: "" });
    let pinCode = "";
    if (this.state.pinCodeOfSelectedAddress !== "") {
      pinCode = this.state.pinCodeOfSelectedAddress;
    } else {
      pinCode = this.state.pinCode;
    }
    if (pinCode !== "") {
      let data = await AuthService.fetchSlotDate(pinCode);
      if (data) {
        this.setState({ slotDays: data });
      } else {
        toast.error("No delivery slot configured for this pin code.");
      }
    } else {
      toast.error("Please select a address.");
    }
  };
  fetchSlots = async (item) => {
    this.setState({ selectedSLot: "", slots: "" });
    if (this.state.slotDays !== "") {
      this.setState({ slotDay: item });
      let pinCode = "";
      if (this.state.pinCodeOfSelectedAddress !== "") {
        pinCode = this.state.pinCodeOfSelectedAddress;
      } else {
        pinCode = this.state.pinCode;
      }
      let data = await AuthService.fetchSlots(pinCode, item.date);

      if (data !== false) {
        this.setState({ slots: data });
      } else {
        toast.error("No slot has been configured for this pin code.");
      }
    }
  };
  selectSLot = (item) => {
    let temp = {
      date: this.state.slots.date,
      formattedDate: this.state.slots.formattedDate,
      slots: item,
    };
    this.setState({ selectedSLot: temp });
  };
  toggle = (item) => {
    this.setState({
        ["modal" + item]: !this.state["modal" + item]
    });
  }
  orderPageChangeHandler = async (index) => {
    this.setState({orderCurrentPage: index})
    await this.fetchCustomerOrderList(this.state.customerUserId, index + 1)
  };
  callLogsPageChangeHandler = async (index) => {
    this.setState({orderCurrentPage: index})
    await this.fetchCustomerCallLogs(this.state.customerUserId, index + 1)
  };
 
  render() {
    return (
      <React.Fragment>
        <SimpleBackdrop status={this.state.progressStatus} />
        <div className={"col-md-11 mx-auto white rounded-sm border p-0"}>
          <div
            className={
              "col-12 p-2 grey lighten-4 border-bottom d-flex justify-content-between align-items-center"
            }
          >
            <b className={"title"}>Create Order</b>
            {/*<div className={'row mx-0'}>
                            <MDBBtn outline onClick={() => this.props.history.push('/view-online-orders')}
                                    disabled={this.state.progressStatus}>Back</MDBBtn>
                            <MDBBtn onClick={this.save}
                                    disabled={this.state.progressStatus}>Save</MDBBtn>
                        </div>*/}
          </div>
          <div className={"col-12 row m-0 p-0"}>
            <div className={"col-sm-3"}>
              <MDBInput
                id={"customerName"}
                background
                size="sm"
                label="Customer Name"
                value={this.state.customerName}
                onChange={(e) => {
                  this.setState({ customerName: e.target.value });
                }}
              />
            </div>
            <div className={"col-sm-3"}>
              <MDBInput
                id={"customerPhone"}
                background
                size="sm"
                label="Customer Phone"
                value={this.state.customerPhone}
                getValue={this.getPhone}
                maxLength="10"
              />
            </div>
            <div className={"col-sm-6 mt-4"}>
              <MDBBtn
                size={"md"}
                onClick={this.lookupAddress}
                disabled={this.state.customerPhone === ""}
              >
                Lookup Address By Phone
              </MDBBtn>
              {this.state.loadingOrderList === true && 
              (<div class="spinner-border spinner-border-sm" role="status">
              </div>)}
              {(this.state.orderList.length > 0 && this.state.loadingCallLog === false) &&
              <MDBBtn
                size={"md"}
                onClick={() => this.toggle("OrderList")}
                disabled={this.state.orderList.length === 0}
              >
                View Orders
              </MDBBtn>}
              {(this.state.callLogs.length > 0) &&
              <MDBBtn
                size={"md"}
                onClick={() => this.toggle("CallLogs")}
                disabled={this.state.callLogs.length === 0}
              >
                View Call Logs
              </MDBBtn>}
            </div>
          </div>
        </div>
        <div className={"col-md-11 mx-auto white rounded-sm border p-0 mt-2"}>
          <div
            className={
              "col-12 p-2 grey lighten-4 border-bottom d-flex justify-content-between align-items-center"
            }
          >
            <b className={"font-weight-bold"}>Address</b>
          </div>
          <div className={"col-12 row m-0 p-0"}>
            {this.state.addressList.map((item, index) => (
              <div className={"col-4 p-2"} key={index}>
                <MDBInput
                  label={
                    this.state.selectedAddressId === item._id ? (
                      <b className={"text-primary"}>Address {index + 1}</b>
                    ) : (
                      <>Address {index + 1}</>
                    )
                  }
                  type="checkbox"
                  id={"address_" + index}
                  onChange={() => this.getAddress(item)}
                  checked={this.state.selectedAddressId === item._id}
                />
                <br />
                <span className={"text-capitalize"}>
                  <b>{item.name}</b>
                </span>
                <br />
                {item.addressLine1},
                {item.addressLine2 !== "" && <span> {item.addressLine2},</span>}{" "}
                {item.city} <br />
                {item.state} {item.pinCode}, {item.country} <br />
                Phone: {item.phone}
              </div>
            ))}
          </div>
          {this.state.selectedAddressId === "" && (
            <div className={"col-12 row m-0 p-0"}>
              <div className={"col-sm-6 "}>
                {/*<b>Address Line 1 *</b><br/>*/}
                <MDBInput
                  label="Address Line 1 *"
                  id={"addressLine1"}
                  background
                  size="sm"
                  value={this.state.addressLine1}
                  getValue={(val) => {
                    this.setState({ addressLine1: val });
                  }}
                />
              </div>
              <div className={"col-sm-6 "}>
                {/*<b>Address Line 2</b><br/>*/}
                <MDBInput
                  label="Address Line 2"
                  id={"addressLine2"}
                  background
                  size="sm"
                  value={this.state.addressLine2}
                  getValue={(val) => {
                    this.setState({ addressLine2: val });
                  }}
                />
              </div>
            </div>
          )}
          {this.state.selectedAddressId === "" && (
            <div className={"col-12 row m-0 p-0"}>
              <div className={"col-sm-2 "}>
                {/* <b>Pin Code *</b><br/>*/}
                <MDBInput
                  label="Pin Code *"
                  id={"pinCode"}
                  background
                  size="sm"
                  maxLength={"6"}
                  value={this.state.pinCode}
                  getValue={this.getPinCode}
                />
              </div>
              <div className={"col-sm-1 text-primary p-1 ml-n2"}>
                <br />
                <MDBBtn
                  size={"md"}
                  onClick={this.fetchPinCodeDetails}
                  disabled={this.state.loadingPincode}
                >
                  {this.state.loadingPincode === true ? (
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <MDBIcon icon="search" />
                  )}
                </MDBBtn>
              </div>
              <div className={"col-sm-3 "}>
                {/*<b>City *</b><br/>*/}
                <MDBInput
                  label="City *"
                  id={"city"}
                  background
                  size="sm"
                  value={this.state.city}
                  getValue={(val) => {
                    this.setState({ city: val });
                  }}
                />
              </div>
              <div className={"col-sm-3 "}>
                {/*<b>State *</b><br/>*/}
                <MDBInput
                  label="State *"
                  id={"state"}
                  background
                  size="sm"
                  value={this.state.state}
                  getValue={(val) => {
                    this.setState({ state: val });
                  }}
                />
              </div>
              <div className={"col-sm-3 "}>
                {/* <b>Country *</b><br/>*/}
                <MDBInput
                  label="Country *"
                  id={"country"}
                  background
                  size="sm"
                  value={this.state.country}
                  getValue={(val) => {
                    this.setState({ country: val });
                  }}
                />
              </div>
              <div className={"col-sm-3 "}>
                {/* <b>Name *</b><br/>*/}
                <MDBInput
                  label="Name *"
                  id={"address_name"}
                  background
                  size="sm"
                  value={this.state.name}
                  getValue={(val) => {
                    this.setState({ name: val });
                  }}
                />
              </div>
              <div className={"col-sm-3 "}>
                {/* <b>Phone Number *</b><br/>*/}
                <MDBInput
                  label="Phone Number"
                  id={"phone_number"}
                  background
                  size="sm"
                  value={this.state.phoneNumber}
                  getValue={(val) => {
                    this.setState({ phoneNumber: val });
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <div className={"col-md-11 mx-auto white rounded-sm border p-0 mt-2"}>
          <div
            className={
              "col-12 p-2 grey lighten-4 border-bottom d-flex justify-content-between align-items-center"
            }
          >
            <b>Order</b>
          </div>
          <div className={"col-12 row m-0 p-0"}>
            <div className={"col p-2"}>
              <b>Delivery Type *</b>
              <br />
              <MDBSelect
                options={this.state.deliveryTypes}
                selected="Choose delivery type"
                getValue={this.checkPickup}
                className={"col grey-text p-0 font-weight-bold"}
              />
            </div>
            <div className={"col p-2"}>
              <b>Payment Mode *</b>
              <br />
              <MDBSelect
                options={this.state.paymentModes}
                selected="Choose payment type"
                getValue={(val) => {
                  this.setState({ paymentMode: val });
                }}
                className={"col grey-text p-0 font-weight-bold"}
              />
            </div>
          </div>
          <div className={"col-12 row m-0 p-0"}>
            <div className={"col p-2"}>
              <b>Order Source *</b>
              <br />
              <MDBSelect
                options={this.state.orderSources}
                selected="Choose order source"
                getValue={(e) => {
                  this.setState({ orderSource: e });
                }}
                className={"col grey-text p-0 font-weight-bold"}
              />
            </div>
            <div className={"col p-2"}>
              {this.state.deliveryType[0] === "DELIVER" && (
                <>
                  <MDBBtn
                    onClick={() => {
                      this.checkSlotDate();
                    }}
                  >
                    Check slots
                  </MDBBtn>

                  {this.state.slotDays !== "" &&
                    this.state.selectedSLot === "" && (
                      <div>
                        {this.state.slotDays.map((item, index) => (
                          <>
                            <MDBBtn
                              onClick={() => {
                                this.fetchSlots(item);
                              }}
                              key={index}
                            >
                              {item.alias}
                            </MDBBtn>
                            &nbsp; &nbsp;
                          </>
                        ))}
                      </div>
                    )}
                  {this.state.slots !== "" &&
                    this.state.selectedSLot === "" && (
                      <div>
                        {this.state.slots.slots.map((item, index) => (
                          <div key={index}>
                            <MDBBtn
                              onClick={() => {
                                this.selectSLot(item);
                              }}
                            >
                              {item.start} - {item.end}
                            </MDBBtn>
                          </div>
                        ))}
                      </div>
                    )}
                  {this.state.selectedSLot !== "" && (
                    <div>
                      Selected slot - {this.state.selectedSLot.formattedDate} -{" "}
                      {this.state.selectedSLot.slots.start} -{" "}
                      {this.state.selectedSLot.slots.end}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        <div
          className={
            "col-md-11 mx-auto white rounded-bottom border border-top-0 p-0"
          }
        >
          <div class="form-group">
            <label className="m-2" for="medicinesList">
              Internal Comment (Medicines Name, Extra Details Etc.)
            </label>

            <textarea
              className="form-control rounded-0"
              id="medicinesList"
              rows="3"
              value={this.state.internalComment}
              onChange={(e) => {
                this.setState({ internalComment: e.target.value });
              }}
            ></textarea>
          </div>
          <div className={"col-12 p-2 grey lighten-4 text-center"}>
            <b></b>
            <MDBBtn
              outline
              size={"md"}
              onClick={() => this.props.history.push("/view-online-orders")}
              disabled={this.state.progressStatus}
            >
              Back
            </MDBBtn>
            <MDBBtn
              size={"md"}
              onClick={this.save}
              disabled={this.state.progressStatus}
            >
              Continue
            </MDBBtn>
          </div>
        </div>
        
        <MDBModal isOpen={this.state.modalOrderList} toggle={() => this.toggle("OrderList")} size={"lg"}>
            <MDBModalHeader toggle={() => this.toggle("OrderList")}>Order List</MDBModalHeader>
            <MDBModalBody className={"float-center"}>
                <NewTable
                  columns={this.state.orderColumns}
                  data={this.formatTableData(this.state.orderList)}
                  key={this.state.orderList.length}
                  pagination={true}
                  headerBackground={'#E8E8E8'}
                />
                {this.state.orderRecordCount / this.state.perPage > 1 && (
                  <div className="my-3 d-flex flex-row justify-content-end w-100">
                    <NewPagination
                      page={this.state.orderCurrentPage}
                      pageLimit={this.state.perPage}
                      totalRecords={this.state.orderRecordCount}
                      handleChange={this.orderPageChangeHandler}
                      type="primary"
                    />
                  </div>
                )}
            </MDBModalBody>
        </MDBModal>

        <MDBModal isOpen={this.state.modalCallLogs} toggle={() => this.toggle("CallLogs")} size={"lg"}>
            <MDBModalHeader toggle={() => this.toggle("CallLogs")}>Call Logs</MDBModalHeader>
            <MDBModalBody className={"float-center"}>
                <NewTable
                  columns={this.state.callLogColumns}
                  data={this.formatCallLogsTableData(this.state.callLogs)}
                  key={this.state.callLogs.length}
                  pagination={true}
                  headerBackground={'#E8E8E8'}
                />
                {this.state.callLogsRecordCount / this.state.perPage > 1 && (
                  <div className="my-3 d-flex flex-row justify-content-end w-100">
                    <NewPagination
                      page={this.state.orderCurrentPage}
                      pageLimit={this.state.perPage}
                      totalRecords={this.state.callLogsRecordCount}
                      handleChange={this.callLogsPageChangeHandler}
                      type="primary"
                    />
                  </div>
                )}
            </MDBModalBody>
        </MDBModal>
      </React.Fragment>
    );
  }
}

export default withRouter(NewOnlineOrder);
