import React from "react";
import Moments from "moment";
import {toast} from "react-toastify";
import ReportService from "../../services/reports";
import UserStore from "../../stores/userStore";
import SimpleBackdrop from "../../components/common/overlay";
import {withRouter} from "react-router-dom";
import MaterialTable from "material-table";

class CustomerInsights extends React.Component {
    constructor() {
        super();
        this.state = {
            progressStatus: false,
            productTransactions: [],
        }
    }

    async componentDidMount() {
        let userData = await UserStore.fetchSessionData();
        this.setState({progressStatus: true});
        let data = await ReportService.fetchTransactionsForProduct(userData, this.props.productId);
        this.setState({progressStatus: false});
        if (data) {
            this.setState({productTransactions: data})
        } else {
            toast.error("Something went wrong while fetching transactions of products.")
        }
    }

    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.progressStatus}/>
                <MaterialTable title="Selling History"
                               columns={[
                                   {title: 'Order Code', field: 'orderCode'},
                                   {title: 'Order Date', field: 'orderDate', render: rowData => Moments(rowData.order).format('LLL')},
                                   {title: 'Quantity', field: 'products.quantity'},
                                   {title: 'Batch No', field: 'products.inventory.batch'},
                                   {title: 'Customer Name',field: 'customerDetails.name'},
                                   {title: 'Customer Phone', field: 'customerDetails.contactNo'},
                               ]}
                               data={this.state.productTransactions}
                               options={{
                                   padding: "dense",
                                   exportButton: true, exportAllData: true,
                                   toolbarButtonAlignment: "left",
                                   pageSize: 10,
                                   pageSizeOptions: [10, 50, 100],
                                   exportFileName: "SellingHistory_" + Moments(this.state.today).format("DD-MM-YYYY")
                               }}/>
            </React.Fragment>
        )
    }
}

export default withRouter(CustomerInsights)