import axios from "axios";
import config from "../config";

const TransferStockService = {
  fetchDetailsByBarCodeProducts: async function (sessionToken, barCode) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },

      };
      const response = await axios.get(
        config.APIHOST_FOR_SEARCH_CUSTOMER +
        config.FETCH_PRODUCTS_DETAILS_BY_BARCODE
        +
        "?barCode=" +
        barCode,
        headers
      );
      return response.data;
    } catch (e) {
      console.log(e)
      return false;
    }
  },


  fetchLocations: async function (sessionToken) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },

      };
      const response = await axios.get(
        config.APIHOST_FOR_SEARCH_CUSTOMER +
        config.FETCH_LOCATIONS,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  transferProductSave: async function (sessionToken, payload) {
    try {
      const headers = {
        headers: { Authorization: sessionToken }
      };
      const response = await axios.post(
        config.APIHOST_FOR_SEARCH_CUSTOMER + config.SAVE_TRANSFER_PRODUCT, payload, headers)
      return response.data
    } catch (e) {
      return false
    }
  },
  trasferProductList: async function (sessionToken, startDate, endDate, pageSize, page) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },

      };
      console.log("rrrrr", config.APIHOST_FOR_SEARCH_CUSTOMER +
        config.FETCH_TRANSFER_PACKAGE_LIST
        +
        "?page=1")
      const response = await axios.get(
        config.APIHOST_FOR_SEARCH_CUSTOMER +
        config.FETCH_TRANSFER_PACKAGE_LIST + "?startDate=" + startDate + "&" + "endDate=" + endDate
        +
        "&page=" + page + "&pageSize=10"
        ,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  searchPackageByBarCodeProducts: async function (sessionToken, barCode) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },

      };
      if (barCode != "") {
        const response = await axios.get(
          config.APIHOST_FOR_SEARCH_CUSTOMER +
          config.SEARCH_PACKAGE_BY_BARCODE
          +
          "?barCode=" +
          barCode,
          headers
        );
        return response.data;
      }
    } catch (e) {
      return false;
    }
  },
  updateProduct: async function (sessionToken, payload) {
    try {
      const headers = {
        headers: { Authorization: sessionToken }
      };
      const response = await axios.post(
        config.APIHOST_FOR_SEARCH_CUSTOMER + config.STOCK_UPDATE, payload, headers)
      return response.data
    } catch (e) {
      return false
    }
  },
  fetchProductList: async function (sessionToken) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.get(
        config.APIHOST_2 + config.FETCH_TRANSFER_PACKAGE_LIST + "?page=1",
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  // fetchStockList: async function (payload, sessionToken) {
  //   try {
  //     const headers = {
  //       Authorization: sessionToken,
  //     };

  //     const response = await axios.get(
  //       config.APIHOST_2 + config.FETCH_STOCK_LIST,
  //       {
  //         params: payload,
  //         headers,

  //       }
  //     );
  //     console.log(payload);

  //     return response.data;
  //   } catch (error) {
  //     return false;
  //   }
  // },
  fetchStockList: async function (payload, sessionToken) {
    try {
      const headers = {
        Authorization: sessionToken,
      };

      const response = await axios.get(
        config.APIHOST_2 + config.FETCH_STOCK_LIST,
        {
          params: payload,
          headers,
          paramsSerializer: (params) => {
            const serializedParams = [];
            for (const key in params) {
              const value = params[key];
              if (Array.isArray(value)) {
                value.forEach((val) => serializedParams.push(`${key}=${val}`));
              } else {
                serializedParams.push(`${key}=${value}`);
              }
            }
            return serializedParams.join('&');
          },
        }
      );

      console.log(payload);
      return response.data;
    } catch (error) {
      return false;
    }
  }
  ,
  fetchStockByLocation: async function (payload, pageNo, sessionToken) {
    try {
      const headers = {
        Authorization: sessionToken,
      };

      const response = await axios.get(
        config.APIHOST_2 + config.FETCH_STOCK_BY_LOCATION,
        {
          params: { ...payload, pageNo: pageNo },
          headers,
        }
      );
      return response.data;
    } catch (error) {
      return false;
    }
  },
  relocateRack: async function (payload, sessionToken) {
    try {
      const headers = {
        Authorization: sessionToken,
      };

      const response = await axios.post(
        config.APIHOST_2 + config.RELOCATE_RACK,
        payload,
        {
          headers,
        }
      );
      return response.data;
    } catch (error) {
      return false;
    }
  },
  reviewStockList: async function (payload, sessionToken) {
    try {
      const headers = {
        headers: { Authorization: sessionToken }
      };

      const response = await axios.post(config.APIHOST_FOR_SEARCH_CUSTOMER + config.TRANSFER_REVIEW, payload, headers)

      return response.data;
    } catch (error) {
      return false;
    }
  },
  searchStockList: async function (URL, sessionToken, name) {
    try {
      const headers = {
        Authorization: sessionToken,
      };

      // const response = await axios.get( config.APIHOST+"/"+ URL, {  headers } );
      const response = await axios.get(config.APIHOST_2 + "/" + URL + "&searchType=" + name, { headers });
      return response.data;
    } catch (error) {
      return false;
    }
  },
  searchStocksListNew: async function (searchTerm, sessionToken, searchType, pageNo, locationId) {
    try {
      const headers = {
        Authorization: sessionToken,
      };
      const url = `${config.APIHOST_2}/api/v3/stock/endpoint/search-stock?searchTerm=${searchTerm}&searchType=${searchType}&pageNo=${pageNo}&locationIds=${locationId}`;

      const response = await axios.get(url, { headers });
      return response.data;
    } catch (error) {
      console.error("Error in searchStockList:", error);
      return false;
    }
  },
  searchStockListNew: async function (searchTerm, sessionToken, searchType, pageNo) {
    try {
      const headers = {
        Authorization: sessionToken,
      };
      const url = `${config.APIHOST_2}${config.SEARCH_STOCK_ALLBYQUERY}?searchTerm=${encodeURIComponent(searchTerm)}&searchType=${encodeURIComponent(searchType)}&pageNo=${pageNo}`;
      console.log("URL", url);
      const response = await axios.get(url, { headers });
      return response.data;
    } catch (error) {
      console.error("Error in searchStockListNew:", error);
      return false;
    }
  },
  deleteProduct: async function (sessionToken, payload) {
    try {
      const headers = {
        headers: { Authorization: sessionToken }
      };
      const response = await axios.post(config.APIHOST_2 + config.DELETE_STOCK, payload, headers)
      return response.data
    } catch (e) {
      return false
    }
  },
  searchProductList: async function (sessionToken, name) {
    try {
      const headers = {
        Authorization: sessionToken,
      };
      const response = await axios.get(config.APIHOST_2 + "/" + config.SEARCH_PRODUCT_INFO_BARCODE + name, { headers });
      return response.data;
    } catch (error) {
      return false;
    }
  },
  locationWithProductId: async function (sessionToken, productId) {
    try {
      const headers = {
        Authorization: sessionToken,
      };
      const response = await axios.get(config.APIHOST_2 + "/" + config.LOCATION_WITH_PROD_ID + productId, { headers });
      return response.data;
    } catch (error) {
      return false;
    }
  },
  searchProductListwithName: async function (sessionToken, name) {
    try {
      const headers = {
        Authorization: sessionToken,
      };
      const response = await axios.get(config.APIHOST_2 + "/" + config.FETCH_LIST_OF_PRODUCT_BY_NAME + name, { headers });
      return response.data;
    } catch (error) {
      return false;
    }
  },

  searchProductListwithId: async function (sessionToken, name) {
    try {
      const headers = {
        Authorization: sessionToken,
      };
      const response = await axios.get(`https://adminapiv2.positrarx.com/api/v3/product/suggest-prodduct?term=${name}`, { headers });
      return response.data;
    } catch (error) {
      return false;
    }
  },

  searchProductListwithIdV2: async function (name) {
    try {
      const response = await axios.get(`https://api.positrarx.com/api/v2/search?term=${name}&queue=${name?.length}`);
      return response.data;
    } catch (error) {
      return false;
    }
  },

  fetchStockByBarcodeNew: async function (barCode, pageNo, sessionToken) {
    console.log({ sessionToken })
    try {
      const headers = {
        Authorization: sessionToken,
      };

      const response = await axios.get(
        config.APIHOST_2 + config.FETCH_STOCK_BY_BARCODE_NEW + barCode + "&pageNo=" + pageNo, { headers }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  },
  barcodeRelocate: async function (sessionToken, payload) {
    try {
      const headers = {
        headers: { Authorization: sessionToken }
      };
      const response = await axios.post(config.APIHOST_2 + config.RELOCATE_BY_BAR, payload, headers)
      return response.data
    } catch (e) {
      return false
    }
  }
};


export default TransferStockService;
