import React from "react";
import {Link,withRouter} from "react-router-dom";
import {
    MDBContainer,
    MDBRow,
    MDBCardHeader,
    MDBCardBody,
    MDBCard,
    MDBCol,
    MDBCardTitle,
    MDBCardText,
    MDBListGroup,
    MDBListGroupItem,
    MDBTable, MDBTableHead, MDBTableBody, MDBBtn, MDBBtnGroup, MDBModalBody
} from 'mdbreact';
import UserStore from "../../stores/userStore";
import Moment from 'react-moment';
import config from "../../config";
import SimpleBackdrop from "../../components/common/overlay";
import InventoryService from "../../services/inventoryService"
import utility from "../../utility/utility";
import UpdateProduct from "../../pages/product/editPkgDetails";
import UpdateProductConfig from "../../pages/product/cms";
import VerifyProduct from "./verifyProduct";
import StockAvailability from "./stockAvailability";
import SimilarProducts from "./similarProducts";

class ProductDetailsReport extends React.Component {
    async componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({progressStatus: true});

        if(this.props.loadedOnPopup === true){
            let productId = this.props.productId;
            await this.setState({productId:productId, loadedOnPopup:true})
            await this.fetchData();
        } else {
            const { productId } = this.props.match.params;
            this.setState({productId:productId});
            await this.fetchData();
        }

        this.setState({progressStatus: false});
    }
    showHighlightedImage = (index) => {
        this.setState({selectedImageIndex:index})
    }

    fetchData=async ()=>{
        window.scrollTo(0, 0);
        let userData = await UserStore.fetchSessionData();
        let data = await InventoryService.fetchProductById(userData,this.state.productId);
        //let data = this.props.product
        this.setState({productData: data,editedCategories:data.categories});
        try{
            this.setState({amount:data.discount});
        }catch (e) {
            console.log("No discount field added.");
        }
        try{
            this.setState({maxQnty:data.maxQuantity});
        }catch (e) {
            this.setState({maxQnty:0});
        }
        try{
            this.setState({minQnty:data.minQuantity});
        }catch (e) {
            this.setState({minQnty:0});
        }
        try{
            this.setState({reorderQnty:data.reorderThreshold});
        }catch (e) {
            this.setState({reorderQnty:0});
        }
        try{
            this.setState({pickupInstruction:data.shippingDetails.pickupInstruction,shippingInstruction:data.shippingDetails.shippingInstruction,inPersonInstruction:data.shippingDetails.inPersonInstruction,inPerson:data.shippingDetails.inPerson, ship:data.shippingDetails.ship, pickup:data.shippingDetails.pickUp});
        }catch (e) {
            this.setState({inPerson:'N', ship:'N', pickup:"N"});
        }
        this.setState({topSelling:data.topSelling});
        this.setState({mrp:data.maxRetailPrice});
        this.setState({outOfStock:data.outOfStock});
        try {
            if(data.imageUrl.length > 0) {
                this.setState({existingImageUrl: data.imageUrl});
                let lb = []
                for(let i = 0; i < data.imageUrl.length; i++){
                    let item = {
                        src: config.IMAGE_HOST+data.imageUrl[i].filePathOriginal
                    }
                    lb.push(item);
                }
                this.setState({"lightBoxImages":lb, "imageActionNeeded":"Update"})
            } else {
                this.setState({existingImageUrl:[]});
                this.setState({"imageActionNeeded":"Add"})
            }
        } catch (e) {
            this.setState({existingImageUrl:[]});
            this.setState({"imageActionNeeded":"Add"})
        }
    }
    constructor(props) {
        super(props);
        this.state = {
            productId:'',
            activeItem:'1',
            progressStatus:false,
            productData:{categories:[], imageUrl: []},
            categories:[],
            editedCategories:[],
            updateProgress:false,
            imageUrl:[],
            existingImageUrl:[],
            progressStatusFileUpload:false,
            imageActionNeeded:"",
            finishedSaving:"init",
            deleteMode:false,
            activeImage:{
                index:0,
                thumb:""
            },
            modalDelImage:false,
            modalDelete:false,
            selectedImageIndex:0,
            amount:0,
            topSelling:'N',
            mrp:0,
            outOfStock:'',
            maxQnty:0,
            minQnty:0,
            reorderQnty:0,
            inPerson:'N',
            ship:'N',
            pickup:"N",
            inPersonInstruction:"",
            shippingInstruction:"",
            pickupInstruction:"",
            loadedOnPopup:false,

            // variables for
            mode: "View"
        }
    }

    setMode = function(mode){
        this.setState({mode: mode})
    }

    onEditComplete = (row) => {
        // this.toggleEdit()
        // this.fetchData();
    }

    render() {
        return (
            <React.Fragment>

                < SimpleBackdrop status={(this.state.progressStatus === true || this.state.updateProgress === true)?true:false}/>
                {this.state.progressStatus === false &&
                <MDBContainer>
                    <MDBCard className={"mt-2"}>
                        <MDBCardHeader>
                            <span className={"float-left"}>{this.state.productData.productName}</span>

                            <div className={"float-right"}>
                                <MDBBtnGroup size="sm">
                                    <MDBBtn
                                        onClick={() => this.setMode('View')}
                                        disabled={this.state.mode === "View"}
                                        color={this.state.mode === "View" ? "default" : ""}>View</MDBBtn>
                                    <MDBBtn
                                        onClick={() => this.setMode('Edit')}
                                        disabled={this.state.mode === "Edit"}
                                        color={this.state.mode === "Edit" ? "default" : ""}>Edit</MDBBtn>
                                    <MDBBtn
                                        onClick={() => this.setMode('Config')}
                                        disabled={this.state.mode === "Config"}
                                        color={this.state.mode === "Config" ? "default" : ""}>Update Config</MDBBtn>
                                    <MDBBtn
                                        onClick={() => this.setMode('Mark')}
                                        disabled={this.state.mode === "Mark"}
                                        color={this.state.mode === "Mark" ? "default" : ""}>Mark</MDBBtn>
                                    <MDBBtn
                                        onClick={() => this.setMode('StockAvailability')}
                                        disabled={this.state.mode === "StockAvailability"}
                                        color={this.state.mode === "StockAvailability" ? "default" : ""}>Stock Availability</MDBBtn>
                                    <MDBBtn
                                        onClick={() => this.setMode('SimilarProducts')}
                                        disabled={this.state.mode === "SimilarProducts"}
                                        color={this.state.mode === "SimilarProducts" ? "default" : ""}>Similar Products</MDBBtn>
                                </MDBBtnGroup>
                            </div>
                        </MDBCardHeader>
                        <MDBCardBody>

                            {this.state.mode === "View" &&
                                <MDBContainer>
                                <MDBRow>
                                    <MDBCol>
                                        <MDBTable className={"col-12"}>
                                            <MDBTableHead>
                                                <tr>
                                                    <th>Medicine Type</th>
                                                    <th>Retail Package</th>
                                                    <th>Quantity Unit</th>
                                                    <th>Content Quantity</th>
                                                    <th>MRP</th>
                                                    <th>Stock</th>
                                                    <th>Stock Availability</th>
                                                    <th>Mark</th>
                                                </tr>
                                            </MDBTableHead>
                                            <MDBTableBody>
                                                <tr>
                                                    <td><span
                                                        className={utility.validateMedicineType(true, this.state.productData.medicineType) ? "green-text" : "red-text"}><b>{this.state.productData.medicineType}</b></span>
                                                    </td>
                                                    <td><b><span
                                                        className={utility.validateRetailPackage(this.state.productData.retailPackage) ? "green-text" : "red-text"}><b>{this.state.productData.retailPackage}</b></span></b>
                                                    </td>
                                                    <td><b><span
                                                        className={utility.validateQuantityUnit(this.state.productData.quantityUnit) ? "green-text" : "red-text"}><b>{this.state.productData.quantityUnit}</b></span></b>
                                                    </td>
                                                    <td><b><span
                                                        className={utility.validateContentQuantity(this.state.productData.contentQuantity) ? "green-text" : "red-text"}><b>{this.state.productData.contentQuantity}</b></span></b>
                                                    </td>
                                                    <td><b><span
                                                        className={utility.validateMrp(this.state.productData.mrp) ? "green-text" : "red-text"}><b>{this.state.productData.mrp}</b></span></b>
                                                    </td>
                                                    <td><b><span
                                                        className={utility.validateStock(this.state.productData.stock) ? "green-text" : "red-text"}><b>{this.state.productData.stock}</b></span></b>
                                                    </td>
                                                    <td><b><span>{this.state.productData.stockAvailability}</span></b>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            {this.state.productData.hasOwnProperty("verificationStatus") &&
                                                            <div style={{cursor: "pointer"}}>
                                                                {this.state.productData.verificationStatus === "READY_TO_PUBLISH" &&
                                                                <div style={{color: "green"}}
                                                                     onClick={() => this.onMark(this.state.productData)}>
                                                                    <div style={{"fontSize": "17px"}}><b>Ready To
                                                                        Publish</b></div>
                                                                    <div
                                                                        style={{"fontSize": "12px"}}>{utility.convertToReadbleDaySmart2(this.state.productData.verificationLog[this.state.productData.verificationLog.length - 1].verifiedOn)}</div>
                                                                    <div
                                                                        style={{"fontSize": "12px"}}>{this.state.productData.verificationLog[this.state.productData.verificationLog.length - 1].verifiedByUserName}</div>
                                                                </div>
                                                                }
                                                                {this.state.productData.verificationStatus === "MANUALLY_VERIFIED" &&
                                                                <div style={{color: "green"}}
                                                                     onClick={() => this.onMark(this.state.productData)}>
                                                                    <div style={{"fontSize": "17px"}}><b>Verified</b>
                                                                    </div>
                                                                    <div
                                                                        style={{"fontSize": "12px"}}>{utility.convertToReadbleDaySmart2(this.state.productData.verificationLog[this.state.productData.verificationLog.length - 1].verifiedOn)}</div>
                                                                    <div
                                                                        style={{"fontSize": "12px"}}>{this.state.productData.verificationLog[this.state.productData.verificationLog.length - 1].verifiedByUserName}</div>
                                                                </div>
                                                                }
                                                                {this.state.productData.verificationStatus === "JUNK" &&
                                                                <div style={{color: "red"}}
                                                                     onClick={() => this.onMark(this.state.productData)}>
                                                                    <div style={{"fontSize": "17px"}}><b>Junk</b></div>
                                                                    <div
                                                                        style={{"fontSize": "12px"}}>{utility.convertToReadbleDaySmart2(this.state.productData.verificationLog[this.state.productData.verificationLog.length - 1].verifiedOn)}</div>
                                                                    <div
                                                                        style={{"fontSize": "12px"}}>{this.state.productData.verificationLog[this.state.productData.verificationLog.length - 1].verifiedByUserName}</div>
                                                                </div>
                                                                }
                                                                {this.state.productData.verificationStatus === "DUPLICATE" &&
                                                                <div style={{color: "orange"}}
                                                                     onClick={() => this.onMark(this.state.productData)}>
                                                                    <div style={{"fontSize": "17px"}}><b>Duplicate</b>
                                                                    </div>
                                                                    <div
                                                                        style={{"fontSize": "12px"}}>{utility.convertToReadbleDaySmart2(this.state.productData.verificationLog[this.state.productData.verificationLog.length - 1].verifiedOn)}</div>
                                                                    <div
                                                                        style={{"fontSize": "12px"}}>{this.state.productData.verificationLog[this.state.productData.verificationLog.length - 1].verifiedByUserName}</div>
                                                                </div>
                                                                }
                                                                {this.state.productData.verificationStatus === "INCOMPLETE" &&
                                                                <div style={{color: "orange"}}
                                                                     onClick={() => this.onMark(this.state.productData)}>
                                                                    <div style={{"fontSize": "17px"}}><b>Incomplete</b>
                                                                    </div>
                                                                    <div
                                                                        style={{"fontSize": "12px"}}>{utility.convertToReadbleDaySmart2(this.state.productData.verificationLog[this.state.productData.verificationLog.length - 1].verifiedOn)}</div>
                                                                    <div
                                                                        style={{"fontSize": "12px"}}>{this.state.productData.verificationLog[this.state.productData.verificationLog.length - 1].verifiedByUserName}</div>
                                                                </div>
                                                                }
                                                                {this.state.productData.verificationStatus === "INCORRECT" &&
                                                                <div style={{color: "orange"}}
                                                                     onClick={() => this.onMark(this.state.productData)}>
                                                                    <div style={{"fontSize": "17px"}}><b>Incorrect</b>
                                                                    </div>
                                                                    <div
                                                                        style={{"fontSize": "12px"}}>{utility.convertToReadbleDaySmart2(this.state.productData.verificationLog[this.state.productData.verificationLog.length - 1].verifiedOn)}</div>
                                                                    <div
                                                                        style={{"fontSize": "12px"}}>{this.state.productData.verificationLog[this.state.productData.verificationLog.length - 1].verifiedByUserName}</div>
                                                                </div>
                                                                }
                                                            </div>
                                                            }
                                                        </div>
                                                    </td>

                                                </tr>
                                            </MDBTableBody>
                                        </MDBTable>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className={"mb-3"}>
                                    <MDBCol>
                                        <MDBCard>
                                            <MDBCardBody style={{maxHeight: "10rem", overflowY: "scroll"}}>
                                                <MDBCardTitle>Description</MDBCardTitle>
                                                <MDBCardText>
                                                    <span
                                                        dangerouslySetInnerHTML={{__html: this.state.productData.description}}></span>
                                                    {(!this.state.productData.description || this.state.productData.description.length === 0) &&
                                                    <span className={"red-text"}>Empty</span>
                                                    }
                                                </MDBCardText>
                                            </MDBCardBody>
                                        </MDBCard>
                                    </MDBCol>

                                    <MDBCol>
                                        <MDBCard>
                                            <MDBCardBody className="" style={{maxHeight: "10rem", overflowY: "scroll"}}>
                                                <MDBCardTitle>Highlights</MDBCardTitle>
                                                <MDBCardText>
                                                    <span
                                                        dangerouslySetInnerHTML={{__html: this.state.productData.highlight}}></span>
                                                    {(!this.state.productData.highlight || this.state.productData.highlight.length === 0) &&
                                                    <span className={"red-text"}>Empty</span>
                                                    }
                                                </MDBCardText>
                                            </MDBCardBody>
                                        </MDBCard>
                                    </MDBCol>
                                </MDBRow>

                                <MDBRow className={"mb-3"}>
                                    <MDBCol>
                                        <MDBCard>
                                            <MDBCardBody style={{height: "10rem", overflowY: "scroll"}}>
                                                <MDBCardTitle>Side effects</MDBCardTitle>
                                                <MDBCardText>
                                                    <span
                                                        dangerouslySetInnerHTML={{__html: this.state.productData.productSideEffects}}/>
                                                    {(!this.state.productData.productSideEffects || this.state.productData.productSideEffects.length === 0) &&
                                                    <span className={"red-text"}>Empty</span>
                                                    }
                                                </MDBCardText>
                                            </MDBCardBody>
                                        </MDBCard>
                                    </MDBCol>
                                    <MDBCol>
                                        <MDBCard>
                                            <MDBCardBody style={{height: "10rem", overflowY: "scroll"}}>
                                                <MDBCardTitle>Uses</MDBCardTitle>
                                                <MDBCardText>
                                                    <span
                                                        dangerouslySetInnerHTML={{__html: this.state.productData.productUses}}></span>
                                                    {(!this.state.productData.productUses || this.state.productData.productUses.length === 0) &&
                                                    <span className={"red-text"}>Empty</span>
                                                    }
                                                </MDBCardText>
                                            </MDBCardBody>
                                        </MDBCard>
                                    </MDBCol>
                                </MDBRow>

                                <MDBRow className={"mb-3"}>
                                    <MDBCol>
                                        <MDBCard>
                                            <MDBCardBody style={{height: "10rem", overflowY: "scroll"}}>
                                                <MDBCardTitle>How to use</MDBCardTitle>
                                                <MDBCardText>
                                                    <span
                                                        dangerouslySetInnerHTML={{__html: this.state.productData.howTouse}}></span>
                                                    {!this.state.productData.howTouse &&
                                                    <span className={"red-text"}>Empty</span>
                                                    }
                                                </MDBCardText>
                                            </MDBCardBody>
                                        </MDBCard>
                                    </MDBCol>
                                    <MDBCol>
                                        <MDBCard>
                                            <MDBCardBody style={{height: "10rem", overflowY: "scroll"}}>
                                                <MDBCardTitle>Ingredients</MDBCardTitle>
                                                <MDBCardText>
                                                    <span
                                                        dangerouslySetInnerHTML={{__html: this.state.productData.productIngredients}}/>
                                                    {!this.state.productData.productIngredients &&
                                                    <span className={"red-text"}>Empty</span>
                                                    }

                                                </MDBCardText>
                                            </MDBCardBody>
                                        </MDBCard>
                                    </MDBCol>

                                </MDBRow>

                                <MDBRow>
                                    <MDBCol>
                                        <div>
                                            <span><b>Categories</b>: </span>
                                            <span>
                                            {this.state.productData.categories.length < 1 &&
                                            <span>Not yet linked</span>
                                            }
                                                {this.state.productData.categories.map((item, idx) => (
                                                    <div key={idx}>{item.categoryName}</div>
                                                ))}
                                        </span>
                                        </div>
                                        <div>
                                            <span><b>Status</b>: </span>
                                            <span>
                                            {this.state.productData.isActive === 'Y' ? "Active" : 'Disabled'}
                                        </span>
                                        </div>

                                        <div>
                                            <span><b>Is it top selling?</b>: </span>
                                            <span>
                                            {this.state.productData.topSelling === 'Y' ? "Yes" : 'No'}
                                        </span>
                                        </div>
                                        <div>
                                            <span><b>Created By</b>: </span>
                                            <span>
                                            {this.state.productData.createdBy}
                                        </span>
                                        </div>
                                        <div>
                                            <span><b>Created on</b>: </span>
                                            <span>
                                            <Moment format="MMM D, YYYY" withTitle>
                                                {this.state.productData.createdAt}
                                            </Moment>
                                        </span>
                                        </div>

                                    </MDBCol>
                                    <MDBCol>
                                        <MDBRow>
                                            <MDBCol size={"2"}>
                                                {this.state.existingImageUrl.map((row, index) => (
                                                    <div className={"float-left m-1"}>
                                                        {((row.filePathThumb.indexOf(".png") > 0) || (row.filePathThumb.indexOf(".jpg") > 0) || (row.filePathThumb.indexOf(".jpeg") > 0)) &&
                                                        <div
                                                            className={(this.state.selectedImageIndex === index) ? "border-info center" : "center"}
                                                            style={{
                                                                "border": "2px solid #CCC",
                                                                width: "45px",
                                                                height: "45px"
                                                            }}>
                                                            <img onClick={() => this.showHighlightedImage(index)}
                                                                 src={config.IMAGE_HOST + row.filePathThumb}
                                                                 className={"float-left"}
                                                                 style={{maxWidth: "40px", maxHeight: "40px"}}
                                                                 alt={""}/>
                                                        </div>
                                                        }
                                                    </div>
                                                ))}
                                            </MDBCol>
                                            <MDBCol size={"6"}>
                                                {(this.state.existingImageUrl.length > 0) &&
                                                <img
                                                    src={config.IMAGE_HOST + this.state.existingImageUrl[this.state.selectedImageIndex].filePathOriginal}
                                                    className={"float-left"}
                                                    style={{maxWidth: "400px", maxHeight: "400px"}} alt={""}/>
                                                }
                                            </MDBCol>

                                        </MDBRow>
                                    </MDBCol>
                                </MDBRow>


                            </MDBContainer>
                            }

                            {this.state.mode === "Edit" &&
                            <UpdateProduct loadedOnPopup={true} onEditComplete={this.onEditComplete}
                                           productId={this.state.productId}/>
                            }

                            {this.state.mode === "Config" &&
                            <UpdateProductConfig loadedOnPopup={true} onEditComplete={this.onEditComplete}
                                                 productId={this.state.productId}/>
                            }
                            {this.state.mode === "SimilarProducts" &&
                            <SimilarProducts loadedOnPopup={true} onEditComplete={this.onEditComplete}
                                           product={this.state.productData}/>
                            }
                            {this.state.mode === "Mark" &&
                            <VerifyProduct loadedOnPopup={true} hideSimilarProduct={true} onEditComplete={this.onEditComplete}
                                           product={this.state.productData}/>
                            }
                            {this.state.mode === "StockAvailability" &&
                            <StockAvailability loadedOnPopup={true} onEditComplete={this.onEditComplete}
                                               product={this.state.productData}/>
                            }


                        </MDBCardBody>
                    </MDBCard>
                </MDBContainer>
                }
            </React.Fragment>
        );
    }
}
export default withRouter(ProductDetailsReport);
