import React from "react";
import {
  MDBBtn,
  MDBBox,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBSpinner,
} from "mdbreact";

function ViewStorageInfoFun({ storageModal, toggleStorage, location }) {
  const toggle = () => {
    toggleStorage(null);
  };

  console.log("Orddder")
  return (
    <>
      <MDBModal isOpen={storageModal} toggle={toggle}>
        <MDBModalHeader toggle={toggle}>Storage Info</MDBModalHeader>
        <MDBModalBody>
          {location !== null && (
            <>
              <MDBBox>
                Location:{" "}
                {location.hasOwnProperty("storageLoc") && (
                  <>{location.storageLoc.name}</>
                )}
              </MDBBox>
              <MDBBox>
                Floor:{" "}
                {location.hasOwnProperty("floor") && (
                  <>{location.floor.floorName}</>
                )}
              </MDBBox>
              <MDBBox>
                Rack:{" "}
                {location.hasOwnProperty("rack") && (
                  <>{location.rack.rackName}</>
                )}
              </MDBBox>
              <MDBBox>
                Box:{" "}
                {location.hasOwnProperty("box") && <>{location.box.boxName}</>}
              </MDBBox>
            </>
          )}
          {location === null && <MDBSpinner />}
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn size={"md"} onClick={toggle}>
            Close
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    </>
  );
}

export default ViewStorageInfoFun;
