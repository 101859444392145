import axios from "axios";
import config from "../config";

const ReportService= {
    fetchMonthlySalesWidget: async function (userData) {
        try {            const headers = {
                headers: {Authorization: userData.sessionToken}
            };
            const response = await axios.get(config.APIHOST + config.WEEKLY_SALES_WIDGET+"?orgId="+userData.org[0]._id, headers);
            return response.data
        } catch (e) {
            return false;
        }
    },
    fetchMonthlyCashCreditWidget: async function (userData) {
        try { const headers = {
            headers: {Authorization: userData.sessionToken}
        };
            const response = await axios.get(config.APIHOST + config.CASH_CREDIT_WIDGET+"?orgId="+userData.org[0]._id, headers);
            return response.data
        } catch (e) {
            return false;
        }
    },
    fetchMonthlyOrderPie: async function (userData) {
        try { const headers = {
            headers: {Authorization: userData.sessionToken}
        };
            const response = await axios.get(config.APIHOST + config.MONTHLY_ORDER_PIE_WIDGET+"?orgId="+userData.org[0]._id, headers);
            return response.data
        } catch (e) {
            return false;
        }
    },
    fetchMonthlyCostWidget: async function (userData) {
        try {            const headers = {
            headers: {Authorization: userData.sessionToken}
        };
            const response = await axios.get(config.APIHOST + config.MONTHLY_COSTS+"?orgId="+userData.org[0]._id, headers);
            return response.data
        } catch (e) {
            return false;
        }
    },
    fetchMonthlyShipmentsWidget: async function (userData) {
        try {            const headers = {
            headers: {Authorization: userData.sessionToken}
        };
            const response = await axios.get(config.APIHOST + config.MONTHLY_SHIPMENT_WIDGET+"?orgId="+userData.org[0]._id, headers);
            return response.data
        } catch (e) {
            return false;
        }
    },
    fetchMonthlyExpiredWidget: async function (userData) {
        try {
            const headers = {
            headers: {Authorization: userData.sessionToken}
        };
            const response = await axios.get(config.APIHOST + config.MONTHLY_EXPIRED_WIDGET+"?orgId="+userData.org[0]._id, headers);
            return response.data
        } catch (e) {
            return false;
        }
    },
    fetchStockDetails: async function (userData) {
        try {
            const headers = {
            headers: {Authorization: userData.sessionToken}
        };
            const response = await axios.get(config.APIHOST + config.STOCK_COUNT+"?orgId="+userData.org[0]._id, headers);
            return response.data
        } catch (e) {
            return false;
        }
    },
    fetchSalesData: async function (userData, startDate, endDate) {
        try {
            const headers = {
                headers: {Authorization: userData.sessionToken}
            };
            const response = await axios.get(config.APIHOST + config.CUSTOM_SALES_REPORT+
                "?orgId="+userData.org[0]._id+"&startDate="+startDate+"&endDate="+endDate, headers);
            return response.data
        } catch (e) {
            return false;
        }
    },
    fetchSalesIntelligence: async function (userData, startDate, endDate) {
        try {
            const headers = {
                headers: {Authorization: userData.sessionToken}
            };
            const response = await axios.get(config.APIHOST + config.FETCH_SALES_INTELLIGENCE+
                "?orgId="+userData.org[0]._id+"&startDate="+startDate+"&endDate="+endDate, headers);
            return response.data
        } catch (e) {
            return false;
        }
    },
    fetchCustomerInsights: async function (userData, contactNo) {
        try {
            const headers = {
                headers: {Authorization: userData.sessionToken}
            };
            const response = await axios.get(config.APIHOST + config.FETCH_CUSTOMER_INSIGHTS+
                "?orgId="+userData.org[0]._id+"&phone="+contactNo, headers);
            return response.data
        } catch (e) {
            return false;
        }
    },

    updateStockAvailability: async function (userData, data) {
        try {
            const headers = {
                headers: {Authorization: userData.sessionToken}
            };
            let payload = {
                stockAvailability:data.stockAvailability,
                productId:data._id
            }

            const response = await axios.post(config.APIHOST + config.UPDATE_STOCK_AVAILABILITY, payload, headers);
            return response.data
        } catch (e) {
            return false;
        }
    },


    markProduct: async function (userData, data) {
        try {
            const headers = {
                headers: {Authorization: userData.sessionToken}
            };
            let payload = {
                comments:data.comments,
                verificationStatus: data.verificationStatus,
                verifiedByUserId:userData._id,
                verifiedByUserName:userData.name,
                orgId:userData.org[0]._id,
                productId:data._id
            }

            const response = await axios.post(config.APIHOST + config.MARK_PRODUCT, payload, headers);
            return response.data
        } catch (e) {
            return false;
        }
    },

    fetchDuplicateProducts: async function (userData, data) {
        try {
            const headers = {
                headers: {Authorization: userData.sessionToken}
            };
            const response = await axios.get(config.APIHOST + config.FETCH_DUPLICATE_PRODUCTS+
                "?orgId="+userData.org[0]._id, headers);
            return response.data
        } catch (e) {
            return false;
        }
    },

    fetchTransactionsForProduct: async function (userData, productId) {
        try {
            const headers = {
                headers: {Authorization: userData.sessionToken}
            };
            const response = await axios.get(config.APIHOST + config.FETCH_PRODUCTS_TRANSACTION+
                "?orgId="+userData.org[0]._id+"&productId="+productId, headers);
            return response.data
        } catch (e) {
            return false;
        }
    },


    fetchProductWithCompleteData: async function(userData,page,pageSize,type, data){
        try{
            const headers = {
                headers: { Authorization: userData.sessionToken}
            };

            let filterString = "";
            filterString = filterString + "&productName="+data.productName
            filterString = filterString + "&medicineType="+data.medicineType
            filterString = filterString + "&retailPackage="+data.retailPackage
            filterString = filterString + "&quantityUnit="+data.quantityUnit
            filterString = filterString + "&contentQuantity="+data.contentQuantity
            filterString = filterString + "&mrp="+data.mrp
            filterString = filterString + "&stock="+data.stock
            filterString = filterString + "&description="+data.description
            filterString = filterString + "&manufacturer="+data.manufacturer
            filterString = filterString + "&highlights="+data.highlights
            filterString = filterString + "&tags="+data.tags
            filterString = filterString + "&howToUse="+data.howToUse
            filterString = filterString + "&ingredients="+data.ingredients
            filterString = filterString + "&sideEffects="+data.sideEffects
            filterString = filterString + "&productUses="+data.productUses
            filterString = filterString + "&images="+data.images

            filterString = filterString + "&productNameMissing="+data.productNameMissing
            filterString = filterString + "&medicineTypeMissing="+data.medicineTypeMissing
            filterString = filterString + "&retailPackageMissing="+data.retailPackageMissing
            filterString = filterString + "&quantityUnitMissing="+data.quantityUnitMissing
            filterString = filterString + "&contentQuantityMissing="+data.contentQuantityMissing
            filterString = filterString + "&mrpMissing="+data.mrpMissing
            filterString = filterString + "&stockMissing="+data.stockMissing
            filterString = filterString + "&descriptionMissing="+data.descriptionMissing
            filterString = filterString + "&manufacturerMissing="+data.manufacturerMissing
            filterString = filterString + "&highlightsMissing="+data.highlightsMissing
            filterString = filterString + "&tagsMissing="+data.tagsMissing
            filterString = filterString + "&howToUseMissing="+data.howToUseMissing
            filterString = filterString + "&ingredientsMissing="+data.ingredientsMissing
            filterString = filterString + "&sideEffectsMissing="+data.sideEffectsMissing
            filterString = filterString + "&productUsesMissing="+data.productUsesMissing
            filterString = filterString + "&imagesMissing="+data.imagesMissing

            const response = await axios.get(config.APIHOST+config.FETCH_LIST_OF_PRODUCTS_WITH_COMPLETE_DATA+"?page="+page+"&pageSize="+pageSize+"&type=all"+filterString,headers);
            return response.data
        } catch (e) {
            return false;
        }
    },

    fetchProductWithMarkedFilter: async function(userData, filter){
        try{
            const headers = {
                headers: { Authorization: userData.sessionToken}
            };

            let filterString = "";
            filterString = filterString + "?filter="+filter
            const response = await axios.get(config.APIHOST+config.FETCH_PRODUCTS_WITH_MARKED_FILTER+filterString,headers);
            return response.data
        } catch (e) {
            return false;
        }
    },


    deleteProductsInBulk:  async function (userData, data) {
        try {
            const headers = {
                headers: {Authorization: userData.sessionToken}
            };
            let payload = {
                productIds:data.toBeDeleted,
                deletedByUserId:userData._id,
                deletedByUserName:userData.name,
                orgId:userData.org[0]._id
            }

            const response = await axios.post(config.APIHOST + config.DELETE_PRODUCTS_IN_BULK, payload, headers);
            return response.data
        } catch (e) {
            return false;
        }
    },

    fetchProductDeleteHistory: async function(userData){
        try{
            const headers = {
                headers: { Authorization: userData.sessionToken}
            };
            const response = await axios.get(config.APIHOST+config.FETCH_PRODUCT_DELETE_HISTORY,headers);
            return response.data
        } catch (e) {
            return false;
        }
    },

    recoverDeletedProducts:  async function (userData, deleteHistoryId, productIds) {
        try {
            const headers = {
                headers: {Authorization: userData.sessionToken}
            };
            let payload = {
                productIds:productIds,
                deleteHistoryId:deleteHistoryId,
                orgId:userData.org[0]._id
            }

            const response = await axios.post(config.APIHOST + config.RECOVER_DELETED_PRODUCTS_IN_BULK, payload, headers);
            return response.data
        } catch (e) {
            return false;
        }
    },

    fetchProductDeleteHistoryById: async function(data, userData){
        try{
            const headers = {
                headers: { Authorization: userData.sessionToken}
            };

            let filterString = "";
            filterString = filterString + "?id="+data._id

            const response = await axios.get(config.APIHOST+config.FETCH_PRODUCT_DELETE_HISTORY_BY_ID+filterString,headers);
            return response.data
        } catch (e) {
            return false;
        }
    },
    fetchProductInsights: async function (userData, phone) {
        try {
            const headers = {
                headers: {Authorization: userData.sessionToken}
            };
            const response = await axios.get(config.APIHOST + config.FETCH_PRODUCT_INSIGHTS+
                "?orgId="+userData.org[0]._id+"&phone="+phone, headers);
            return response.data
        } catch (e) {
            return false;
        }
    },
    dailyOrderWidget: async function (userData) {
        try {            const headers = {
            headers: {Authorization: userData.sessionToken}
        };
            const response = await axios.get(config.APIHOST + config.ORDER_WIDGET, headers);
            return response.data
        } catch (e) {
            return false;
        }
    },
    stockWidget: async function (userData) {
        try {            const headers = {
            headers: {Authorization: userData.sessionToken}
        };
            const response = await axios.get(config.APIHOST + config.STOCK_WIDGET, headers);
            return response.data
        } catch (e) {
            return false;
        }
    },
    customerWidget: async function (userData) {
        try {            const headers = {
            headers: {Authorization: userData.sessionToken}
        };
            const response = await axios.get(config.APIHOST + config.CUSTOMER_WIDGET, headers);
            return response.data
        } catch (e) {
            return false;
        }
    },
    fetchCreditReport: async function (userData) {
      //  try {
            const headers = {
            headers: {Authorization: userData.sessionToken}
        };
            const response = await axios.get(config.APIHOST + config.FETCH_CREDIT_REPORT, headers);
            return response.data
        //} catch (e) {
            return false;
        //}
    },
    productSalesAnalysis: async function (sessionToken) {
        try{
            const headers = {
                headers: {Authorization: sessionToken}
            };
            const response = await axios.get(config.APIHOST + config.FETCH_PRODUCT_SALES_ANALYSIS, headers);
            return response.data
        } catch (e) {
            return false;
        }
    },
    requestCreateReport: async function (sessionToken, payload) {
        try {
            const headers = {
                headers: {Authorization: sessionToken}
            }
            const response = await axios.post(config.APIHOST + config.REQUEST_CREATE_REPORT, payload, headers)
            return response.data
        } catch (e) {
            return false;
        }
    },
    fetchRequestReportList: async function (sessionToken) {
        try {
            const headers = {
                headers: {Authorization: sessionToken}
            }
            const response = await axios.get(config.APIHOST + config.FETCH_REQUEST_REPORT_LIST, headers)
            return response.data
        } catch (e) {
            return false;
        }
    },
    deleteReportRequest: async function (sessionToken, _id) {
        try {
            const headers = {
                headers: {Authorization: sessionToken}
            }
            const response = await axios.post(config.APIHOST + config.DELETE_REPORT_REQUEST, {_id: _id} ,headers)
            return response.data
        } catch (e) {
            return false;
        }
    }
}
export default ReportService;
