import React from "react";
import InventoryService from "../../services/inventoryService";
import { toast } from "react-toastify";
import UserStore from "../../stores/userStore";
import Moments from "moment";
import MaterialTable from "material-table";
import SimpleBackdrop from "../common/overlay";

class AuditLog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productLog: [],
      loading: true,
      today: new Date(),
    };
  }

  async componentDidMount() {
    let userData = await UserStore.fetchSessionData();
    let res = await InventoryService.getProductLogById(
      userData.sessionToken,
      this.props.productId
    );
    if (res) {
      this.setState({ auditLog: res });
    } else {
      toast.error("Something went wrong while fetching the audit log.");
    }
    this.setState({ loading: false });
  }

  render() {
    return (
      <React.Fragment>
        <SimpleBackdrop status={this.state.loading} />
        <div className={"col-12 row m-0 p-2"}>
          <div className={"col-12"}>
            <MaterialTable
              title="Audit Log"
              columns={[
                { title: "Action", field: "action" },
                { title: "User name", field: "user.name" },
                {
                  title: "Timestamp",
                  field: "timestamp",
                  render: (rowData) => Moments(rowData.timestamp).format("LLL"),
                },
              ]}
              data={this.state.auditLog}
              options={{
                padding: "dense",
                exportButton: true,
                exportAllData: true,
                toolbarButtonAlignment: "left",
                pageSize: 10,
                pageSizeOptions: [10, 50, 100],
                exportFileName:
                  "AuditLog_" + Moments(this.state.today).format("DD-MM-YYYY"),
              }}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AuditLog;
