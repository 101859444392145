import React, { useState, useEffect } from "react";
import { Modal, Button, Card } from "antd";
import { Link } from "react-router-dom";
import { Table } from "antd";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import { toast } from "react-toastify";
import UserStore from "../../../stores/userStore";
import { CopyOutlined, LinkOutlined } from '@ant-design/icons';
import { MDBBreadcrumb, MDBBreadcrumbItem } from "mdbreact";
import ReportService from "../../../services/reportService";

const JobHistory = () => {
  const [jobHistory, setJobHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [statusOptions, setStatusOptions] = useState([
    "ALL", "PENDING", "CREATED", "IN_QUEUE", "RUNNING", "COMPLETED", "STOPPED", "ERROR"
  ]);
  const [selectedStatus, setSelectedStatus] = useState("ALL");
  const [filteredJobHistory, setFilteredJobHistory] = useState([]);
  const [error, setError] = useState(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  useEffect(() => {
    fetchJobHistory();
  }, [currentPage, selectedStatus]);

  const fetchJobHistory = async () => {
    setIsLoading(true);
    try {
      let userData = await UserStore.fetchSessionData();
      const jobsData = await ReportService.fetchJobHistory(userData, { pageNo: currentPage, pageSize: 10, status: selectedStatus });
      if (!jobsData) {
        toast.error("We are facing some server issue. Please try again later.");
      } else {
        setJobHistory(jobsData.data);
        setTotalRecords(jobsData.totalRecords);
        filterJobHistory(selectedStatus, jobsData.data);
      }
    } catch (error) {
      console.error("Error fetching job history:", error);
      setError("Failed to fetch job history");
      setIsErrorModalOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleJobDownload = async (fileName) => {
    try {
      console.log("FILENAME", fileName);
      let userData = await UserStore.fetchSessionData();
      const response = await ReportService.downloadJobHistory(userData, { fileName });
      console.log(response);
      if (response && response.URL) {
        downloadFile(response.URL);
      } else {
        setError("Failed to Download the Report file . Please try again later.");
        setIsErrorModalOpen(true);
      }
    } catch (error) {
      console.error("Error downloading report:", error);
    }
  };


  const downloadFile = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = url.split('/').pop();
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const truncateString = (str, maxLen) => {
    if (!str || str.length <= maxLen) return str;
    return str.substr(0, maxLen) + '...';
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        toast.success("Copied to clipboard");
      })
      .catch((error) => {
        console.error("Failed to copy:", error);
        toast.error("Failed to copy to clipboard");
      });
  };

  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
  };

  const handleStatusChange = (status) => {
    setSelectedStatus(status);
    setCurrentPage(1);
  };

  const filterJobHistory = (status, jobData) => {
    if (status === "ALL") {
      setFilteredJobHistory(jobData);
    } else {
      const filtered = jobData.filter(job => job.status === status);
      setFilteredJobHistory(filtered);
    }
  };

  const columns = [
    { title: "S.NO", dataIndex: "id", key: "id" },
    { title: "Job Type", dataIndex: "jobType", key: "jobType" },
    { title: "Status", dataIndex: "status", key: "status" },
    {
      title: "Start Time",
      dataIndex: "startTime",
      key: "startTime",
      render: startTime => startTime && new Date(startTime).toLocaleString("en-US", { month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric", hour12: true })
    },
    {
      title: "End Time",
      dataIndex: "endTime",
      key: "endTime",
      render: endTime => endTime && new Date(endTime).toLocaleString("en-US", { month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric", hour12: true })
    },
    {
      title: "Report Link",
      dataIndex: "fileName",
      key: "fileName",
      render: (fileName) => (
        fileName ? (
          <a href="#" onClick={() => handleJobDownload(fileName)}>
            <LinkOutlined /> Download File
          </a>
        ) : null
      ),
    }, {
      title: "Error Message",
      dataIndex: "errorMsg",
      key: "errorMsg",
      render: (errorMsg) => (
        <div>
          {errorMsg && (
            <>
              <span title={errorMsg}>{truncateString(errorMsg, 20)}</span>
              <CopyOutlined
                style={{ marginLeft: '8px', cursor: 'pointer' }}
                onClick={() => handleCopy(errorMsg)}
              />
            </>
          )}
        </div>
      ),
    }
  ];

  return (
    <div>
      <MDBBreadcrumb>
        <MDBBreadcrumbItem><Link to="/dashboard">Dashboard</Link></MDBBreadcrumbItem>
        <MDBBreadcrumbItem active>Job History List</MDBBreadcrumbItem>
      </MDBBreadcrumb>
      <div className={"min-vh-100 p-2 m-0"}>
        <Card>
          <div className={"status-filter"} style={{ display: 'flex', flexDirection: 'row' }}>
            <h4>Status:</h4>
            {statusOptions.map(status => (
              <div key={status} className="form-check mr-3">
                <input
                  type="checkbox"
                  id={status}
                  checked={selectedStatus === status}
                  onChange={() => handleStatusChange(status)}
                  className="form-check-input"
                />
                <label htmlFor={status} className="form-check-label">{status}</label>
              </div>
            ))}
          </div>

          <Table
            columns={columns}
            dataSource={filteredJobHistory}
            pagination={false}
            loading={isLoading}
          />
          {!isLoading && (
            <div
              className={"col-md-12 my-5 d-flex justify-content-center"}
              id={"paginate"}
            >
              <Pagination
                className="ant-pagination"
                onChange={handlePageChange}
                current={currentPage}
                total={totalRecords}
                pageSize={10}
              />
            </div>
          )}
        </Card>
      </div>
      <Modal
        title="Error"
        visible={isErrorModalOpen}
        onCancel={handleCloseErrorModal}
        footer={[
          <Button key="ok" type="primary" onClick={handleCloseErrorModal}>
            OK
          </Button>,
        ]}
      >
        <p>{error}</p>
      </Modal>
    </div>
  );
};

export default JobHistory;
