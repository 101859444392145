import React from "react";
import {
    MDBBtn,
    MDBInput,
    MDBModal,
    MDBModalBody,
    MDBModalFooter,
    MDBModalHeader,
    MDBSelect,
    MDBSelectInput,
    MDBSelectOption,
    MDBSelectOptions,
} from "mdbreact";
import moment from "moment";
import {toast} from "react-toastify";
import {withRouter} from "react-router-dom";
import AuthService from "../../services/authService";
import UserStore from "../../stores/userStore";
import SimpleBackdrop from "../common/overlay";
import MaterialTable from 'material-table';
import GetDateRange from "../common/getDateRange";
import Moments from "moment";
import OrderService from "../../services/orderService";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

class TaskList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            taskList: [],
            modalStatus: false,
            userData: {}, comment: "", activeItem: null,
            startDate: new Date(),
            endDate: new Date(),
            completedList: [], createdList: [], assigneeList: [], assignedTo: "ALL",
            modalAssignTask: false, taskAssignedTo: "", keyQU: "", teamList: [],
            modalRescheduleTask: false, resolutionDate: new Date()
        }
    }

    async componentDidMount() {
        let userData = await UserStore.fetchSessionData();
        this.setState({userData})
        this.setState({isLoading: true})
        await this.getCashierList()
        this.setState({isLoading: false})
    }

    getDateRangeCallback = async (startDate, endDate) => {
        if (startDate > endDate) {
            toast.info("Start date is greater than the end date. Please adjust the date range again.")
            return
        }
        let endDateThreeMonthGap = Moments(startDate).add(3, "months");
        if (endDate > endDateThreeMonthGap) {
            toast.error("The date difference can't be more than three months.")
            return
        }
        await this.setState({startDate, endDate, isLoading: true})
        await this.fetchData()
    }
    fetchData = async () => {
        let res = await AuthService.fetchTaskList(this.state.userData.sessionToken, Moments(this.state.startDate).format("YYYY-MM-DD"), Moments(this.state.endDate).format("YYYY-MM-DD"), this.state.assignedTo)
        if (res) {
            let completedList = res[0].COMPLETED, createdList = res[0].CREATED
            await this.setState({taskList: res, completedList, createdList});
        } else {
            toast.error("Something went wrong while fetching task list.")
        }
        this.setState({isLoading: false})
    }
    toggle = (section) => {
        this.setState({["modal" + section]: !this.state["modal" + section], comment: "", activeItem: null})
    }
    getTaskKey = (item) => {
        if (item.hasOwnProperty("keyName") && item.keyName !== "") {
            return item.keyName
        } else if (item.hasOwnProperty("keyPhone") && item.keyPhone !== "") {
            return item.keyPhone
        }
        return ""
    }
    setActiveItem = async (item, section) => {
        this.toggle(section)
        this.resetKey()
        await this.setState({activeItem: item})
    }
    updateStatus = async () => {
        let payload = {
            taskId: this.state.activeItem._id,
            comment: this.state.comment
        }
        this.setState({isLoading: true})
        let res = await AuthService.markTaskAsComplete(payload, this.state.userData.sessionToken)
        if (res) {
            toast.success("The status has been updated as COMPLETED.")
            this.toggle("Status")
            await this.fetchData()
        } else {
            toast.error("Something went wrong while updating the status.")
        }
    }
    getCashierList = async () => {
        let res = await OrderService.fetchDeliveryPersonDetails(this.state.userData.sessionToken);
        if (res === false) {
            toast.error("Something went wrong while fetching the assignee list.");
        } else {
            let tempArray = [{
                text: "None", value: "ALL", checked: true
            }];
            for (let d of res) {
                let temp = {
                    text: d.name,
                    value: d._id,
                    checked: false
                }
                tempArray.push(temp);
            }
            this.setState({assigneeList: tempArray, teamList: res});
        }
    }
    assignedTo = async (val) => {
        this.setState({isLoading: true})
        await this.setState({assignedTo: val[0]})
        await this.fetchData()
    }
    resetKey = () => {
        let randomString = Math.random().toString(36);
        this.setState({
            keyQU: randomString,
        });
    }
    assignTask = async () => {
        if (this.state.taskAssignedTo === "") {
            toast.error("Please select an assignee.")
            return false
        }
        let payload = {
            taskId: this.state.activeItem._id,
            userName: this.state.taskAssignedTo.userName,
            userId: this.state.taskAssignedTo._id,
            name: this.state.taskAssignedTo.name,
        }
        this.setState({isLoading: true})
        let res = await AuthService.assignTask(payload, this.state.userData.sessionToken)
        if (res === false) {
            toast.error("Something went wrong while assigning the task.")
        } else {
            this.toggle("AssignTask")
            await this.fetchData()
        }
    }
    reScheduleTask = async () => {
        if (this.state.resolutionDate <= new Date()) {
            toast.error("Please select a future date.")
            return false
        }
        let payload = {
            taskId: this.state.activeItem._id,
            resolutionDate: this.state.resolutionDate
        }
        this.setState({isLoading: true})
        let res = await AuthService.rescheduleTask(payload, this.state.userData.sessionToken)
        if (res) {
            this.toggle("RescheduleTask")
            await this.fetchData()
        } else {
            toast.error("Something went wrong while updating the resolution date.")
        }
    }

    render() {
        return (
            <React.Fragment>
                {(this.state.isLoading === true) &&
                <SimpleBackdrop status={this.state.isLoading}/>
                }
                <div className={"col-12 row mx-0 my-4"}>
                    <div className={"col-md-2 p-0"}>
                        <MDBSelect outline={true} search
                                   label={"Select Assignee"} getValue={(val) => this.assignedTo(val)}
                                   options={this.state.assigneeList}></MDBSelect>
                    </div>
                    <div className={'col-md-2 text-left p-0'}>
                        <MDBBtn size={'md'} disabled={this.state.progressStatus} outline
                                onClick={() => this.assignedTo([this.state.userData._id])}>Assigned To Me</MDBBtn>
                    </div>
                    <div className={'col-md-2 text-right p-0'}>
                        <MDBBtn size={'md'} disabled={this.state.progressStatus} onClick={() => {
                            this.props.history.push("/add-task")
                        }}>Add Task</MDBBtn>
                    </div>
                    <div className={'col-md-6 p-0'}>
                        <GetDateRange getDateRangeCallback={this.getDateRangeCallback}/>
                    </div>
                </div>
                <div className={"col-12 row p-0 m-0"}>
                    <div className={"col-7"}>
                        <MaterialTable title="Created"
                                       columns={[
                                           {title: 'Created By', field: 'createdBy'},
                                           {title: 'Task Type', field: 'type'},
                                           {title: 'Task Description', field: 'description'},
                                           {
                                               title: 'Task Key',
                                               field: 'keyName',
                                               render: rowData => this.getTaskKey(rowData)
                                           },
                                           {title: 'Assigned To', field: 'name'},
                                           {
                                               title: 'Created On',
                                               field: 'createdAt',
                                               render: rowData => moment.utc(rowData.createdAt).local().format("DD-MMM-YY h:mm a")
                                           },
                                           {
                                               title: 'Resolution Date',
                                               field: 'resolutionDate',
                                               render: rowData => moment(rowData.resolutionDate).format("ll")
                                           },
                                           {
                                               title: 'Status',
                                               field: 'status',
                                               render: rowData => <>
                                                   <span color="primary"
                                                         className={"blue-text btn-link cursor-pointer font-weight-normal"}
                                                         onClick={() => this.setActiveItem(rowData, 'Status')}>Update Status</span>
                                                   <br/>
                                                   <span color="primary"
                                                         className={"purple-text btn-link cursor-pointer font-weight-normal"}
                                                         onClick={() => this.setActiveItem(rowData, 'AssignTask')}>Assign To</span>
                                                   <br/>
                                                   <span color="primary"
                                                         className={"green-text btn-link cursor-pointer font-weight-normal"}
                                                         onClick={() => this.setActiveItem(rowData, 'RescheduleTask')}>RescheduleTask</span>
                                               </>
                                           }
                                       ]}
                                       data={this.state.createdList}
                                       options={{
                                           pageSize: 10,       // make initial page size
                                           pageSizeOptions: [10, 50, 100],    // rows selection options
                                           toolbarButtonAlignment: "left",
                                           tableLayout: "fixed",
                                           rowStyle: (rowData) => {
                                               const style = {};
                                               if (rowData.resolutionDate < Moments(new Date()).format("YYYY-MM-DD")) {
                                                   style.backgroundColor = '#EBC3C3'; //"E68A8A"
                                                   style.color = 'white';
                                               }
                                               return style;
                                           },
                                       }}/>
                    </div>
                    <div className={"col-5"}>
                        <MaterialTable title="Completed"
                                       columns={[
                                           {title: 'Task Type', field: 'type'},
                                           {title: 'Task Description', field: 'description'},
                                           {
                                               title: 'Task Key',
                                               field: 'keyName',
                                               render: rowData => this.getTaskKey(rowData)
                                           },
                                           {title: 'Resolved By', field: 'resolvedBy.name'},
                                           {
                                               title: 'Resolved On', field: 'resolvedAt',
                                               render: rowData => moment(rowData.resolvedAt).format("ll")
                                           }
                                       ]}
                                       data={this.state.completedList}
                                       options={{
                                           pageSize: 10,       // make initial page size
                                           pageSizeOptions: [10, 50, 100],    // rows selection options
                                           toolbarButtonAlignment: "left",
                                           tableLayout: "fixed"
                                       }}/>
                    </div>
                </div>
                <MDBModal isOpen={this.state.modalStatus} toggle={() => this.toggle("Status")} size="lg">
                    <MDBModalHeader toggle={() => this.toggle("Status")}>Update Status</MDBModalHeader>
                    <MDBModalBody>
                        <MDBInput type="textarea" label="Add Comment" rows="5" outline={true}
                                  value={this.state.comment} onChange={(e) => {
                            this.setState({comment: e.target.value})
                        }} className={'form-control'}/>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button type="button" className="btn btn-outline-success"
                                onClick={() => this.toggle('Status')}>Cancel
                        </button>
                        <button type="button" className="btn btn-success" onClick={this.updateStatus}>Completed</button>
                    </MDBModalFooter>
                </MDBModal>
                <MDBModal isOpen={this.state.modalAssignTask} toggle={() => this.toggle("AssignTask")}>
                    <MDBModalHeader toggle={() => this.toggle("AssignTask")}>Assign Task</MDBModalHeader>
                    <MDBModalBody>
                        <div className={"col-12 row mx-0 p-2"}>
                            <div className={"col-12"}>
                                {this.state.activeItem !== null &&
                                <p className={"font-weight-bold"}>
                                    This task,<br/><br/>
                                    <b>Type:</b> {this.state.activeItem.type} <br/>
                                    <b>Key</b>: {this.state.activeItem.keyName} <br/>
                                    <b>Description:</b> {this.state.activeItem.description} <br/><br/>
                                    will be assigned to:
                                </p>
                                }
                                <div>
                                    <MDBSelect key={this.state.keyQU}
                                               getValue={val => this.setState({taskAssignedTo: val[0]})}>
                                        <MDBSelectInput selected="Select Assignee"/>
                                        <MDBSelectOptions search={true}>
                                            <MDBSelectOption value="" disabled selected>Select
                                                assignee</MDBSelectOption>
                                            {this.state.teamList.map((item, idx) => (
                                                <MDBSelectOption value={item} key={idx}
                                                                 selected={this.state.assignedTo === item._id}>{item.name} [<span
                                                    className={"text-danger"}> {item.userName} </span>]</MDBSelectOption>
                                            ))}
                                        </MDBSelectOptions>
                                    </MDBSelect>
                                </div>
                            </div>
                        </div>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn outline onClick={() => {
                            this.toggle("AssignTask")
                        }}>Cancel</MDBBtn>
                        <MDBBtn onClick={() => this.assignTask()}>Save</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
                <MDBModal isOpen={this.state.modalRescheduleTask} toggle={() => this.toggle("RescheduleTask")}
                          size="lg">
                    <MDBModalHeader toggle={() => this.toggle("RescheduleTask")}>Reschedule Task</MDBModalHeader>
                    <MDBModalBody>
                        <div className={"col-12 row mx-0 mb-4 p-0"}>
                            <div className={"col-md-6 m-auto"}>Select a new resolution date:</div>
                            <div className={"col-md-6 m-auto"}>
                                <MuiPickersUtilsProvider utils={MomentUtils} className={"text-primary"}>
                                    <DatePicker
                                        value={this.state.resolutionDate}
                                        onChange={(e) => this.setState({resolutionDate: e})}
                                        minDate={new Date()}
                                        format="YYYY-MM-DD"
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button type="button" className="btn btn-outline-success"
                                onClick={() => this.toggle('RescheduleTask')}>Cancel
                        </button>
                        <button type="button" className="btn btn-success" onClick={this.reScheduleTask}>Save</button>
                    </MDBModalFooter>
                </MDBModal>
            </React.Fragment>
        )
    }
}

export default withRouter(TaskList);