import React,{Suspense} from "react";
import {Link} from "react-router-dom";
import {
    MDBBreadcrumbItem,
    MDBBreadcrumb,MDBBox
} from 'mdbreact';
//const ProductRequest=React.lazy(() => import("../../components/productRequest/listProductRequest"));
import ProductRequest from "../../components/productRequest/listProductRequest";

export default class ProductRequestPage extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Purchase Request</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <MDBBox className={"min-vh-100"}>
                    <Suspense fallback={null}>
                        <ProductRequest/>
                    </Suspense>
                </MDBBox>
            </React.Fragment>
        );
    }
}


