import React from "react";
import {withRouter} from "react-router-dom";
import SimpleBackdrop from "../common/overlay";
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCol,
    MDBContainer,
    MDBDataTable,
    MDBInput, MDBModal, MDBModalBody, MDBModalHeader,
    MDBRow, MDBBadge, MDBBtnGroup, MDBTableHead, MDBTableBody, MDBTable
} from "mdbreact";
import UserStore from "../../stores/userStore";
import {toast} from "react-toastify";
import dataTableConfig from "./dataTableProducts";
import ReportService from "../../services/reports";
import utility from "../../utility/utility";
import ProductView from "../product/productView";
import config from "../../config";
import ViewDeleteHistoryById from "./viewDeleteHistoryById";

class DeleteHistory extends React.Component {
    constructor() {
        super();
        this.state = {
            progressStatus: false,
            deleteHistory:[],
            modalView:false,
            activeItem:{}
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    toggleView = () => {
        this.setState({
            modalView: !this.state.modalView
        });
    }

    fetchData = () =>{
        this.getDeleteHistory().then(res => {
            if (res === false) {
                toast.error("We are facing some issue, please try again later.")
            } else {
                this.setState({deleteHistory: res})

            }
        })
    }

    getDeleteHistory = async () => {
        let userData = await UserStore.fetchSessionData();
        this.setState({progressStatus: true});
        let data = await ReportService.fetchProductDeleteHistory(userData);
        this.setState({progressStatus: false});
        return data
    }

    recoverDeletedProducts = async (deleteHistoryId, productIds) => {
        let userData = await UserStore.fetchSessionData();
        this.setState({progressStatus: true});
        let data = await ReportService.recoverDeletedProducts(userData, deleteHistoryId, productIds);
        this.setState({progressStatus: false});
        this.fetchData()
    }

    onView = async (deleteHistory) => {
        this.setState({activeItem:deleteHistory})
        this.toggleView()
    }

    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.progressStatus}/>
                <MDBContainer className={'w-100 mw-100'}>
                    <MDBRow>
                        <MDBCol>
                            {this.state.deleteHistory.length > 0 &&
                            <MDBTable className={"col-12"}>
                                <MDBTableHead>
                                    <tr>
                                        <th>#</th>
                                        <th>Number Of Products Deleted</th>
                                        <th>Deleted By</th>
                                        <th>Deleted On</th>
                                        <th>View Products</th>
                                        <th>Recover</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {this.state.deleteHistory.map((row, index) => (
                                        <>
                                            {row._id !== this.state._id &&
                                            <tr key={"history" + index}>
                                                <td>{index + 1}</td>
                                                <td>{row.productIds.length}</td>
                                                <td>{row.deletedByUserName}</td>
                                                <td>
                                                    {utility.convertToReadbleDaySmart2(row.modifiedAt)}
                                                </td>
                                                <td>
                                                    <MDBBtn size={"sm"} onClick={() => this.onView(row)}>View</MDBBtn>
                                                </td>
                                                <td>
                                                    {row.status === "DELETED" &&
                                                    <MDBBtn size={"sm"}
                                                            onClick={() => this.recoverDeletedProducts(row._id, row.productIds)}>Recover</MDBBtn>
                                                    }
                                                    {row.status === "RECOVERED" &&
                                                    <span className={"green-text"}><b>Product Recovered</b></span>
                                                    }

                                                </td>
                                            </tr>
                                            }
                                        </>
                                    ))}
                                </MDBTableBody>
                            </MDBTable>

                            }
                        </MDBCol>
                    </MDBRow>

                    <MDBModal isOpen={this.state.modalView} toggle={this.toggleView} size={"fluid"}>
                        <MDBModalHeader toggle={this.toggleView}></MDBModalHeader>
                        <MDBModalBody className={"float-center"}>
                            <ViewDeleteHistoryById loadedOnPopup={true} deleteHistory={this.state.activeItem} />
                        </MDBModalBody>
                    </MDBModal>

                </MDBContainer>
            </React.Fragment>
        )
    }
}

export default withRouter(DeleteHistory)