import React, { useEffect, useState } from "react";
import NewTable from "../../newComponents/NewTable/NewTable";
import GetDateRange from "../../components/common/getDateRange";
import { toast } from "react-toastify";
import Moments from "moment";
import UserStore from "../../stores/userStore";
import OrderService from "../../services/orderService";
import SimpleBackdrop from "../../components/common/overlay";
import NewPagination from "../../newComponents/NewPagination/NewPagination";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import {
  STATUS_DROPDOWN_ITEMS_UNFULFILLED,
  UNFULFILLED_TABLE_COLUMNS,
} from "../../utility/constants";
import ViewOrderModal from "./ViewOrderModal";
import { formatUnfulfilledOrderTable } from "../../utility/tableUtils";

const UnfulfilledOrders = (props) => {
  //These data come only if we go to process order page from the modal here and click back.
  const {
    startDatePreserved,
    endDatePreserved,
    modePreserved,
    pagePreserved,
    filterPreserved,
    searchTermPreserved,
    showSearchValuePreserved,
    orderAmountPreserved,
  } = props.location.state || {};

  const [dateData, setDateData] = useState({
    startDate: Moments(startDatePreserved) || Moments(),
    endDate: Moments(endDatePreserved) || Moments(),
    mode: modePreserved || "Day",
  });

  const [isDataAvailable, setIsDataAvailable] = useState(false);
  const [userData, setUserData] = useState(null);
  const [listData, setListData] = useState([]);
  const [progressStatus, setProgressStatus] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [perPage, setPerPage] = useState(0);
  const [page, setPage] = useState(pagePreserved || 0);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [filter, setFilter] = useState(
    filterPreserved || {
      status: STATUS_DROPDOWN_ITEMS_UNFULFILLED,
    }
  );
  const [searchTerm, setSearchTerm] = useState(searchTermPreserved || "");
  const [showSearchValues, setShowSearchValues] = useState(
    showSearchValuePreserved || false
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeRow, setActiveRow] = useState("");
  const [orderAmount, setOrderAmount] = useState(orderAmountPreserved || false);

  const fetchData = async (startDate, endDate, pageNo) => {
    setProgressStatus(true);
    let data = await OrderService.fetchUnfulfilledOrders(
      userData.sessionToken,
      Moments(startDate).format("YYYY-MM-DD"),
      Moments(endDate).format("YYYY-MM-DD"),
      pageNo + 1 || page + 1,
      sortBy,
      sortOrder,
      filter.status,
      orderAmount
    );
    setProgressStatus(false);
    if (data) {
      setListData(data.data);
      setTotalRecords(data?.totalRecords);
      setPerPage(data?.recordPerPage);
    } else {
      setListData([]);
      setTotalRecords(0);
      setPerPage(0);
    }
  };

  const modalOpenHandler = (id) => {
    setActiveRow(id);
    setIsModalOpen(true);
  };

  const getDateRangeCallback = async (startDate, endDate) => {
    if (showSearchValues) {
       toast.error("Please clear the search field first", {
        toastId:"date-toast"
      });
    }
    if (!showSearchValues) {
      setPage(0);
    }
    if (startDate > endDate) {
      toast.info(
        "Start date is greater than the end date. Please adjust the date range again."
      );
      return;
    }
    setDateData((prev) => ({
      ...prev,
      startDate: Moments(startDate),
      endDate: Moments(endDate),
    }));
  };

  const updateModeFromChild = (childData) => {
    if (showSearchValues) {
       toast.error("Please clear the search field first",{
        toastId:"date-toast"
      });
    }
    if (!showSearchValues) {
      setPage(0);
    }
    setDateData((prev) => ({
      ...prev,
      mode: childData,
    }));
  };

  const pageChangeHandler = (index) => {
    setPage(index);
    if (showSearchValues) {
      searchHandler(index);
    }
  };
  const customSortHandler = (item) => {
    if (showSearchValues) {
       toast.error("Please clear the search field first",{
        toastId:"sort-toast"
      });
    }
    if (!showSearchValues) {
      setPage(0);
    }
    if (item !== sortBy) {
      setSortBy(item);
      setSortOrder("ASC");
    } else {
      if (sortOrder === "") {
        setSortOrder("ASC");
      } else if (sortOrder === "ASC") {
        setSortOrder("DESC");
      } else {
        setSortOrder("");
      }
    }
  };

  const handleStatusChange = (event) => {
    if (showSearchValues) {
       toast.error("Please clear the search field first",{
        toastId:"status-toast"
      });
    }
    const value = event.target.value;
    if (!showSearchValues) {
      setPage(0);
    }
    setFilter((prev) => ({
      ...prev,
      status: typeof value === "string" ? value.split(",") : value,
    }));
  };

  const searchHandler = async (page) => {
    if (searchTerm.length == 0) {
      toast.error("Please enter a search value",{
        toastId:"search-toast"
      });
    } else {
      setProgressStatus(true);
      let data = await OrderService.fetchSearchedUnfulfilledOrders(
        userData.sessionToken,
        searchTerm,
        page + 1
      );
      setProgressStatus(false);
      if (data) {
        setShowSearchValues(true);
        setPage(page);
        setListData(data?.data);
        setTotalRecords(data?.totalRecords);
        setPerPage(data?.recordPerPage);
      } else {
        setShowSearchValues(true);
        setListData([]);
        setTotalRecords(0);
        setPerPage(0);
      }
    }
  };

  const orderAmountHandler = () => {
    if (showSearchValues) {
       toast.error("Please clear the search field first",{
        toastId:"order-toast"
      });
    }
    setPage(0);
    setOrderAmount(!orderAmount);
  };

  const clearSearchHandler = () => {
    setShowSearchValues(false);
    setPage(0);
    setSearchTerm("");
    fetchData(dateData.startDate, dateData.endDate, 0);
  };

  useEffect(() => {
    const MountData = async () => {
      if (!isDataAvailable) {
        let userData = await UserStore.fetchSessionData();
        setIsDataAvailable(true);
        setUserData(userData);
      }
    };
    MountData();
  }, []);

  useEffect(() => {
    if (userData && !showSearchValues) {
      fetchData(dateData.startDate, dateData.endDate);
    }
  }, [
    userData,
    sortOrder,
    dateData.startDate,
    dateData.endDate,
    filter.status,
    page,
    orderAmount,
  ]);

  useEffect(() => {
    if (showSearchValues && userData) {
      searchHandler(page);
    }
  }, [userData]);

  return (
    <>
      <SimpleBackdrop status={progressStatus} />
      <div className="col-12 row mx-0 order-box mt-4">
        <div className={"col-md-12 p-0 mb-4"}>
          <GetDateRange
            key={dateData.startDate}
            getDateRangeCallback={getDateRangeCallback}
            startDatePreserved={dateData.startDate}
            endDatePreserved={dateData.endDate}
            mode={dateData.mode}
            sendModeToParent={updateModeFromChild}
          />
        </div>
        <div className="d-flex justify-content-between w-100 align-items-end">
          <div className={"p-0 d-flex flex-column mr-2 mb-3 w-50"}>
            <div className={"col-md-4 p-0 mr-3"}>
              <FormControl fullWidth>
                <InputLabel id="status-label">Select Status</InputLabel>
                <Select
                  labelId="status-label"
                  id="status-select"
                  multiple
                  value={filter.status}
                  label="Status"
                  onChange={handleStatusChange}
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <em>Select Status</em>;
                    }
                    return selected.join(", ");
                  }}
                >
                  {STATUS_DROPDOWN_ITEMS_UNFULFILLED.map((item, index) => (
                    <MenuItem value={item} key={index}>
                      <Checkbox checked={filter.status.indexOf(item) > -1} />
                      <ListItemText primary={item} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="d-flex flex-column align-items-end">
            <div>
              <FormControlLabel
                label="Is order amount zero"
                control={
                  <Checkbox
                    checked={orderAmount}
                    onChange={orderAmountHandler}
                  />
                }
              />
            </div>
            <div className="d-flex align-items-end mb-3">
              <div className="mr-2">
                <TextField
                  id="unfulfilled-search"
                  variant="outlined"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Search here"
                />
              </div>
              <Button
                variant="contained"
                color={showSearchValues ? "secondary" : "primary"}
                onClick={
                  showSearchValues
                    ? clearSearchHandler
                    : () => {
                        searchHandler(0);
                      }
                }
                size="large"
              >
                {showSearchValues ? "Clear" : "Search"}
              </Button>
            </div>
          </div>
        </div>

        <NewTable
          columns={UNFULFILLED_TABLE_COLUMNS}
          data={formatUnfulfilledOrderTable(listData, modalOpenHandler)}
          pagination={false}
          customSortHandler={customSortHandler}
        />
        {totalRecords / perPage > 1 && (
          <div className="my-3 d-flex flex-row justify-content-end w-100">
            <NewPagination
              page={page}
              pageLimit={perPage}
              totalRecords={totalRecords}
              handleChange={pageChangeHandler}
              type="primary"
              color="#5374B3"
            />
          </div>
        )}
      </div>
      {activeRow && (
        <ViewOrderModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          rowId={activeRow}
          userData={userData}
          dateData={dateData}
          page={page}
          filter={filter}
          searchTerm={searchTerm}
          showSearchValues={showSearchValues}
          orderAmount={orderAmount}
        />
      )}
    </>
  );
};

export default UnfulfilledOrders;
