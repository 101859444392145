import React, { Suspense } from "react";
import {
  MDBBox,
  MDBBreadcrumb,
  MDBBreadcrumbItem
} from 'mdbreact';
import TrackStocklist from "../../components/inventory/v3/trackStocklist";
import { Link } from "react-router-dom";

const TrackStock = () => {
  return (
    <React.Fragment>
      <MDBBreadcrumb>
        <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
        <MDBBreadcrumbItem active>Track Stocks</MDBBreadcrumbItem>
      </MDBBreadcrumb>
      <MDBBox className={"min-vh-100"}>
        <TrackStocklist/>
      </MDBBox>
    </React.Fragment>
  );
};

export default TrackStock;

