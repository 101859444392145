import React from "react";
import { Link } from "react-router-dom";
import {
  MDBBox,
  MDBRow,
  MDBInput,
  MDBCol,
  MDBBtn,
  MDBSpinner,
  MDBTableHead,
  MDBTable,
  MDBTableBody,
  MDBCard,
} from "mdbreact";
import PharmacyService from "../../services/pharmacyService";
import UserStore from "../../stores/userStore";
import { delay } from "underscore";
import { toast } from "react-toastify";
import Moments from "moment";
import ViewBarCode from "../inventory/v3/viewBarCode";
import SelectLocationCmp from "./selectLocationCmp";
import Barcode from "react-barcode";

// const delay = (ms) => new Promise((res) => setTimeout(res, ms));
export default class ReceiveStockCmp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      barCode: "",
      productData: [],
      barCodeModal: false,
      selectedBarCode: "0",
      selectedId: "",
      userData: [],
      location: null,
      stockDetails: [],
      reviewModal: false,
      orders: null,
      batch: "",
      invoiceId: "",
      term: "",
      stockId: null,
      dataFromChild: [],
      locationToDetails: {},
      searchRadio: 1,
      isDataFromChild: false,
      floorDetails: {
        "60d07c4c353ce60289058c6b": [],
        "66df204ac76a094039b1e730": []
      }
    };
  }
  async componentDidMount() {
    let userData = await UserStore.fetchSessionData();
    this.setState({ userData });
    document.getElementById("barCode").focus();
    if (userData) {
      Promise.all([
        this.fetchFloor("60d07c4c353ce60289058c6b", userData), this.fetchFloor("66df204ac76a094039b1e730", userData)
      ])
        .then(() => {
          // setLoading(false);
        })
        .catch((error) => {
          console.log({ error });
          // setLoading(false);
        });
    }
  }

  onClick = (radioVal) => {
    this.setState({ searchRadio: radioVal });
  };
  searchByBarCode = async () => {
    await delay(500);
    if (this.state.barCode !== "" && this.state.isLoading === false) {
      this.setState({ isLoading: true });
      let data = await PharmacyService.fetchDetailsofreceivedStock(
        this.state.userData,
        this.state.barCode
      );
      this.setState({ isLoading: false });
      if (data.status === false) {
        toast.error(
          data?.error?.response?.data?.message ?? "We are facing some issue. Please contact your administrator."
        );
      } else {
        this.setState({ productData: data });
      }
    }
  };

  handleChildData = (data, index) => {
    let products = [...this.state.productData];
    const item = { locationData: data };
    const item2 = { ...products[index] };
    const merged = { ...item, ...item2 };

    products[index] = merged;
    console.log(products);

    const allBoxesFilled = products.every(
      (item) =>
        item.locationData &&
        item.locationData.floorId &&
        item.locationData.rackId &&
        item.locationData.boxId
    );
    this.setState({ productData: products, isDataFromChild: allBoxesFilled });
  };

  addNewProduct = async () => {
    let payload = {
      packageBarCode: this.state.barCode,
      data: this.state.productData.map((item) => ({
        id: item._id,
        inventoryId: item.inventoryDetails._id,
        // quantity: item.inventoryDetails.quantity,
        quantity: 1,
        locId: item.locationToDetails._id,
        floorId: item.locationData.floorId,
        rackId: item.locationData.rackId,
        boxId: item.locationData.boxId,
      })),
    };

    let data = await PharmacyService.saveReceivedStock(
      this.state.userData.sessionToken,
      payload
    );
    if (data) {
      toast.success("The product received successfully.");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      toast.error("Something went wrong while receiving the product.");
    }
  };
  formatDate = (dateString) => {
    const formattedDate = Moments.utc(dateString).format("MMMM YYYY");
    return formattedDate;
  };

  fetchFloor = async (locId, userData) => {
    let data = this.state.floorDetails
    let floorInfo = await PharmacyService.fetchFloorV3(locId, userData);
    let temp = []
    floorInfo.map((item) => {
      temp.push({ text: item.floorName, value: item });
    })
    data[floorInfo?.[0]?.storageLocId] = temp
    this.setState({
      floorDetails: data
    })
  }
  render() {
    return (
      <>
        {this.state.isLoading === true && (
          <div className={"PanelFloat"}>
            <MDBSpinner />
          </div>
        )}
        <MDBRow>
          <MDBCol className={"m-3"}>
            <label>
              <input
                type="radio"
                style={{ display: "none" }}
                onClick={() => {
                  this.onClick(1);
                }}
                checked={this.state.searchRadio === 1 ? true : false}
                id="radio1"
              />
              Search by barcode
            </label>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol className={"m-3"}>
            <MDBRow>
              <MDBCol size={"3"}>
                <MDBInput
                  label={"Scan barcode"}
                  value={this.state.barCode}
                  getValue={(e) => {
                    this.setState({ barCode: e });
                  }}
                  id={"barCode"}
                />
              </MDBCol>
              <MDBCol className={"d-flex align-items-center"}>
                <MDBBtn
                  size={"md"}
                  onClick={() => {
                    this.searchByBarCode(); // Trigger barcode search here
                  }}
                  disabled={this.state.isLoading}
                >
                  Submit
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>

        <MDBBox>
          <>
            <MDBCard
              style={{
                marginInline: "5px",
                marginTop: "10px",
                // overflowX: "auto",
              }}
            >
              <MDBTable
                striped
                style={{
                  marginTop: "30px",
                  borderCollapse: "collapse",
                  marginBottom: 0,
                }}
              >
                <MDBTableHead width="50px" style={{ zIndex: 99 }}>
                  <tr>
                    <th style={{ textAlign: "center" }}>Barcode</th>
                    <th style={{ textAlign: "center" }}>Product Name</th>
                    <th style={{ textAlign: "center" }}>Batch</th>
                    <th style={{ textAlign: "center" }}>priceNogst</th>
                    <th style={{ textAlign: "center" }}>priceWgst</th>
                    <th style={{ textAlign: "center" }}>mfgDate</th>
                    <th style={{ textAlign: "center" }}>expDate</th>
                    <th style={{ textAlign: "center" }}>quantity</th>
                    <th style={{ textAlign: "center" }}>From Location</th>
                    <th style={{ textAlign: "center" }}>To Location</th>
                    <th style={{ textAlign: "center" }}>Floor</th>
                    <th style={{ textAlign: "center" }}>Rack</th>
                    <th style={{ textAlign: "center" }}>Box</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody search="true">
                  {this.state.productData.map((item, index) => (
                    <tr key={index} className={""}>
                      <td style={{ textAlign: "center" }}>
                        <MDBBox
                          className="d-flex align-items-center"
                          style={{ width: "12em", height: "8em" }}
                        >
                          <Barcode
                            value={item?.inventoryDetails.barCode}
                          ></Barcode>
                        </MDBBox>
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {item.productName}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {item.inventoryDetails.batch}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {item.inventoryDetails.priceNogst}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {item.inventoryDetails.priceWgst}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {this.formatDate(item.inventoryDetails.mfgDate)}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {this.formatDate(item.inventoryDetails.expDate)}
                      </td>
                      {/* <td>{item.inventoryDetails.quantity}</td> */}
                      <td style={{ textAlign: "center" }}>1</td>
                      <td style={{ textAlign: "center" }}>
                        {item.storageLocationFromDetails.name}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {item.locationToDetails.name}
                      </td>
                      <SelectLocationCmp
                        locationId={item.locationToDetails._id}
                        floorDetails={this.state.floorDetails[item.locationToDetails._id]}
                        onDataFromChild={(data) => {
                          this.handleChildData(data, index);
                        }}
                      />
                    </tr>
                  ))}
                </MDBTableBody>
              </MDBTable>
              {this.state.productData.length === 0 &&
                this.state.isLoading === false && (
                  <div className={"no-data-container"}>No stock found.</div>
                )}
            </MDBCard>
          </>
        </MDBBox>
        <MDBCol className={"d-flex "}>
          <div className="save-receive-stock-data">
            <MDBBtn
              search="true"
              size={"md"}
              disabled={this.state.isLoading || !this.state.isDataFromChild}
              onClick={this.addNewProduct}
            >
              Save
            </MDBBtn>
          </div>
        </MDBCol>
      </>
    );
  }
}
