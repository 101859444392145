import React,{Suspense} from "react";
import {Link} from "react-router-dom";
import {
    MDBBreadcrumbItem,
    MDBBreadcrumb, MDBBox,
} from 'mdbreact';
// const ManageCategoryCmp=React.lazy(() => import("../../components/categories/manageCategory"));
import ManageCategoryCmp from "../../components/categories/manageCategory";


export default class ManageCategory extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Product Categories</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <MDBBox className={"min-vh-100"}>
                    <Suspense fallback={null}>
                        <ManageCategoryCmp/>
                    </Suspense>
                </MDBBox>
            </React.Fragment>
        );
    }
}


