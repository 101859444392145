import axios from "axios";
import config from "../config";

const CmsService = {
  saveBanner: async function (userData, payload, operation) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      if (operation === "ADD") {
        const response = await axios.post(
          config.APIHOST + config.ADD_BANNER,
          payload,
          headers
        );
        return response.data;
      } else {
        const response = await axios.post(
          config.APIHOST + config.EDIT_BANNER,
          payload,
          headers
        );
        return response.data;
      }
    } catch (e) {
      return false;
    }
  },
  fetchBanners: async function (userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST + config.FETCH_BANNERS,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  deleteBanner: async function (userData, payload) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.post(
        config.APIHOST + config.DELETE_BANNER,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  activateBanner: async function (userData, payload) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.post(
        config.APIHOST + config.ACTIVATE_BANNER,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  deActivateBanner: async function (userData, payload) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.post(
        config.APIHOST + config.DEACTIVATE_BANNER,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  //---------------- mobile banner config----------------

  saveMobBanner: async function (userData, payload, operation) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      if (operation === "ADD") {
        const response = await axios.post(
          config.APIHOST + config.UPLOAD_MOBILE_BANNER,
          payload,
          headers
        );
        return response.data;
      } else {
        const response = await axios.post(
          config.APIHOST + config.EDIT_MOBILE_BANNER,
          payload,
          headers
        );
        return response.data;
      }
    } catch (e) {
      return false;
    }
  },

  fetchMobBanners: async function (userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST + config.FETCH_MOBILE_BANNER,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  deleteMobBanner: async function (userData, id) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      let payload = {
        _id: id,
      };
      const response = await axios.post(
        config.APIHOST + config.DELETE_MOBILE_BANNER,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
};
export default CmsService;
