import React,{Suspense} from "react";
import {
     MDBBox
} from 'mdbreact';
//const SupplierList=React.lazy(() => import("../../components/supplier/listSupplier"));
import SupplierList from "../../components/supplier/listSupplier";


export default class SupplierListPage extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MDBBox className={"min-vh-100"}>
                    <Suspense fallback={null}>
                        <SupplierList/>
                    </Suspense>
                </MDBBox>
            </React.Fragment>
        );
    }
}