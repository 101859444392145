const dataTableStorageLocation= {
    storageLocations:[
        {
            label: 'Location Name',
            field: 'name',
            sort: 'asc',
            width: 150
        },
        {
            label: 'Address',
            field: 'handleAddress',
            sort: 'asc',
            width: 200
        },
        {
            label: '',
            field: 'listFloors',
            sort:'disabled',
            width: 50
        },
        {
            label: '',
            field: 'delete',
            sort:'disabled',
            width: 50
        },
        {
            label: '',
            field: 'edit',
            sort:'disabled',
            width: 50
        }
    ]
}
export default dataTableStorageLocation;