import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {withStyles} from '@material-ui/core/styles';

const useStyles = theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
});

class SimpleBackdrop extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <div>
                    <Backdrop className={classes.backdrop} open={this.props.status} onClick={null}>
                        <CircularProgress color="inherit"/>
                    </Backdrop>
                </div>
            </React.Fragment>
        )
    }
}
export default withStyles(useStyles)(SimpleBackdrop);
