import React from "react";
import Moments from "moment";
import {toast} from "react-toastify";
import UserStore from "../../../stores/userStore";
import ReportService from "../../../services/reports";
import SimpleBackdrop from "../../common/overlay";
import {withRouter} from "react-router-dom";
import MaterialTable from "material-table";
import utility from "../../../utility/utility.js"

class CreditReportCmp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            progressStatus: true,
            totalPending: 0,
            data: [],
            csvData: [],
            today: new Date(),
            columns: [
                {title: 'Order Code', field: 'orderCode'},
                {title: 'Customer Name', field: 'customerName'},
                {title: 'Customer Phone', field: 'customerPhone'},
                {
                    title: 'Order Date', field: 'orderDate', render: rowData => {
                        return (utility.convertToReadbleDateTime(rowData.orderDate))
                    }
                },
                {title: 'Payment Mode', field: 'paymentMode'},
                {title: 'Status', field: 'status'},
                {
                    title: 'Total Amount', field: 'totalAmount', render: rowData => {
                        return ("₹" + parseFloat(rowData.totalAmount).toFixed(2))
                    }
                },
                {
                    title: 'Paid Amount', field: 'paidAmount', render: rowData => {
                        return ("₹" + parseFloat(rowData.paidAmount).toFixed(2))
                    }
                },
                {
                    title: 'Balance Amount', field: 'balanceAmount', render: rowData => {
                        return ("₹" + parseFloat(rowData.balanceAmount).toFixed(2))
                    }
                }
            ],
            tableData: []
        }
    }
    async componentDidMount() {
        let userData = await UserStore.fetchSessionData();
        let data = await ReportService.fetchCreditReport(userData);
        if (data === false) {
            toast.error("We are facing some issue. Please try again later.");
            this.setState({progressStatus: false});
        } else {
            let totalPendingBalance = 0;
            
            for(let item of data){
                console.log(item)
                item.customerName = utility.FirstLetterEachWord(item.customerName)
                item.balanceAmount = parseFloat(item.balanceAmount).toFixed(2)
                item.totalAmount = parseFloat(item.totalAmount).toFixed(2)
                item.paidAmount = parseFloat(item.paidAmount).toFixed(2)
                item.orderDate = utility.convertToReadbleDateTime(item.orderDate)
            }


            this.setState({data: data, tableData: data, progressStatus: false});
            for (let item of data) {
                let temp = parseFloat(item.balanceAmount);
                totalPendingBalance = totalPendingBalance + temp;
            }
            this.setState({totalPending: totalPendingBalance})
        }
    }

    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.progressStatus}/>
                <div className={"col-12 row p-0 m-0"}>
                    <div className={"col-12 mb-3"}>
                        <b>Total Pending: ₹{parseFloat(this.state.totalPending).toFixed(2)}</b>
                    </div>
                    <div className={"col-12"}>
                        <MaterialTable title="Customer credit report"
                                       columns={[
                                           {title: 'Order Code', field: 'orderCode'},


                                           {
                                              title: 'Customer Name', field: 'customerName', render: rowData => {
                                                  return (utility.FirstLetterEachWord(rowData.customerName))
                                              }
                                           },

                                           {title: 'Customer Phone', field: 'customerPhone'},
                                           {
                                               title: 'Order Date', field: 'orderDate', render: rowData => {
                                                   return (utility.convertToReadbleDateTime(rowData.orderDate))
                                               }
                                           },
                                           {title: 'Payment Mode', field: 'paymentMode'},
                                           {title: 'Status', field: 'status'},
                                           {
                                               title: 'Total Amount', field: 'totalAmount', render: rowData => {
                                                   return ("₹" + parseFloat(rowData.totalAmount).toFixed(2))
                                               }
                                           },
                                           {
                                               title: 'Paid Amount', field: 'paidAmount', render: rowData => {
                                                   return ("₹" + parseFloat(rowData.paidAmount).toFixed(2))
                                               }
                                           },
                                           {
                                               title: 'Balance Amount', field: 'balanceAmount', render: rowData => {
                                                   return ("₹" + parseFloat(rowData.balanceAmount).toFixed(2))
                                               }
                                           }
                                       ]}
                                       data={this.state.data}
                                       options={{
                                           toolbarButtonAlignment: "left",
                                           exportButton: true, exportAllData: true,
                                           pageSize: 10,
                                           pageSizeOptions:[10,50,100],
                                           exportFileName: "Customer_credit_report__" + Moments(this.state.today).format("DD-MM-YYYY")
                                       }}
                        />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(CreditReportCmp)