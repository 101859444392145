import React from "react"
import {MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBBtn} from "mdbreact"
import Moments from "moment";
import PharmacyService from "../../../services/pharmacyService";
import UserStore from "../../../stores/userStore";
import {toast} from "react-toastify";

class DeleteInventory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }
    deleteInv = async () => {
        let userData = await UserStore.fetchSessionData();
        if (this.props.stock !== null) {
            let payload = {
                stockId: this.props.stock._id
            }
            this.setState({loading: true})
            let res = await PharmacyService.deleteFromStockReset(userData.sessionToken, payload)
            if (res) {
                toast.success("Stock deleted successfully.")
                this.props.refreshPage()
                this.props.toggleInv(null)
            } else {
                toast.error("Something went wrong while deleting the stock.")
            }
            this.setState({loading: false})
        }
    }

    render() {
        return (
            <React.Fragment>
                <MDBModal isOpen={this.props.deleteInventory} toggle={() => this.props.toggleInv(null)} size="lg">
                    <MDBModalHeader toggle={() => this.props.toggleInv(null)}>Delete Inventory</MDBModalHeader>
                    <MDBModalBody>
                        {this.props.stock !== null && <>
                            Do you want delete this inventory? <br/><br/>
                            Invoice: {this.props.stock.shipmentDetail.invoiceNumber} <br/>
                            Batch: {this.props.stock.batch} <br/>
                            Product Name: {this.props.stock.productDetails.productName}<br/>
                            Mfg Date: {Moments(this.props.stock.mfgDate).format("MMM DD, YYYY")}<br/>
                            Exp Date: {Moments(this.props.stock.expDate).format("MMM DD, YYYY")} <br/>
                            Mrp: {this.props.stock.mrp.toFixed(2)}
                            </>
                        }
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="secondary" outline onClick={() => this.props.toggleInv(null)}>Cancel</MDBBtn>
                        <MDBBtn color="primary" onClick={this.deleteInv} disabled={this.state.loading === true}>
                            {this.state.loading === true ?
                                (<div className="spinner-border spinner-border-sm" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>) :
                                <>Delete</>
                            }
                        </MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
            </React.Fragment>
        )
    }
}

export default DeleteInventory;