import React, { useEffect, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Button } from "@material-ui/core";
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';

const StyledTableCell = withStyles((theme) => ({
    head: {
        // backgroundColor: theme.palette.common.black,
        backgroundColor: "#4285f4 !important",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});

export default function TableProductRequest({ data, editProduct, deleteProduct, fromOnlineOrder=false }) {
    const classes = useStyles();
    const [totalAmount, setTotalAmont] = useState(0)

    const HEADERS = (fromOnlineOrder === true) ? 
       ( [
            { id: "productName", label: "Product Name" },
            { id: "priceWgst", label: "Price with GST", },
            { id: "discount", label: "Discount", },
            { id: "purchasePriceAfterDiscount", label: "Discounted Price" },
            { id: "quantity", label: "Quantity" },
            { id: "totalAmount", label: "Amount" },
        ] ): 
       ( [
            { id: "productName", label: "Product Name" },
            { id: "priceWgst", label: "Price with GST", },
            { id: "discount", label: "Discount", },
            { id: "purchasePriceAfterDiscount", label: "Discounted Price" },
            { id: "quantity", label: "Quantity" },
            { id: "totalAmount", label: "Amount" },
            { id: "Actions", label: "Actions" }
        ]);

    const getTotal = () => {
        let accumulation = data.reduce((total, item) => parseFloat(total) + parseFloat(item.totalAmount), 0)
        setTotalAmont(accumulation?.toFixed(2))
    }

    useEffect(() => {
        getTotal([...data])
    }, [data])
    return (
        <>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            {HEADERS.map((header) => (
                                <StyledTableCell
                                    key={header.id}
                                >
                                    {header.label}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.map((row, key) => (
                            <StyledTableRow key={key}>
                                <StyledTableCell align="left">
                                    {row?.productName}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    {row?.priceWgst ? `₹${row?.priceWgst}` :"Not Available"}
                                </StyledTableCell>
                                <StyledTableCell align="left">{row?.discount} %</StyledTableCell>
                                <StyledTableCell align="left">₹{(row?.purchasePriceAfterDiscount).toFixed("2")}</StyledTableCell>
                                <StyledTableCell align="left">
                                    {row?.quantity}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    ₹{(row?.totalAmount).toFixed("2")}
                                </StyledTableCell>
                                {!fromOnlineOrder &&
                                <StyledTableCell align="left" style={{ display: "flex", gap: "1em" }}>
                                    <Button color="primary" variant="outlined" onClick={() => editProduct(key)}>Edit</Button>
                                    <Button color="error" style={{ background: "red", color: "#ffffff" }} variant="outlined" onClick={() => deleteProduct(key)}>Remove</Button>
                                </StyledTableCell>}
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <MDBContainer>
                <MDBRow className={"mx-12 mt-12"}>
                    <MDBCol className={"col-12"} style={{ display: "flex", justifyContent: "flex-end", padding: "1em" }}>
                        <div>
                            <b>Total Amount : ₹{totalAmount}</b>
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </>
    );
}
