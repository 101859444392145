import axios from "axios";
import config from "../../config";

const StockServiceV3 = {
    getShipmentByIdV3: async function (shipmentId, userData) {
        try {
            const headers = {
                headers: {Authorization: userData.sessionToken},
            };
            const response = await axios.get(
                config.APIHOST +
                config.FETCH_SHIPMENT_BY_ID +
                "?_id=" +
                shipmentId +
                "&orgId=" +
                userData.orgId,
                headers
            );
            return response.data;
        } catch (e) {
            return false;
        }
    },
    searchProductV3: async function (q, userData) {
        try {
            const headers = {
                headers: { Authorization: userData.sessionToken },
            };
            const response = await axios.get(
                config.APIHOST + config.SEARCH_PRODUCT + "?term=" + q,
                headers
            );
            return response.data;
        } catch (e) {
            return false;
        }
    },
};
export default StockServiceV3;
