import React, { useState } from 'react'
import { FormControl, Select, InputLabel } from '@material-ui/core';
import { MDBBtn, MDBInput, MDBContainer, MDBRow, MDBCol } from "mdbreact";

const SearchStock = ({ OPTIONS, CONFIG, handleSearchProd,isProduct=false ,isDropDown=true }) => {
    const [selected, setSelected] = useState({
        selectedBox: OPTIONS[0],
        name: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setSelected({
            ...selected,
            [name]: value,
        });
    };

    const handleSubmit = async () => {
        const urlInfo = CONFIG.SEARCH_STOCK_INFO.filter((item) => item.label == selected.selectedBox);
        const generateUrl = `${urlInfo[0].url}=${selected.name}`
        isProduct ? handleSearchProd(selected.name,selected.selectedBox): handleSearchProd(generateUrl, selected.selectedBox)
    }

    const handleClear = () => {
        setSelected({
            selectedBox: OPTIONS[0],
            name: ''
        })
        handleSearchProd('clear')
    }
    return (
        <MDBContainer>
            <MDBRow>
                <MDBCol md="8">
                    <div className='d-flex justify-content-space-between align-items-center'> <div style={{ padding: ".5em" }}>
                            <FormControl style={{ minWidth: 200 }} >
                                <Select
                                    name={`selectedBox`}
                                    value={selected.selectedBox}
                                    onChange={handleChange}
                                    style={{ minWidth: 200 }}
                                >
                                    {OPTIONS.map((item, key) =>
                                        <option value={item} style={{ padding: ".5em", cursor: "pointer" }}>Search by {item}</option>
                                    )}
                                </Select>
                            </FormControl>
                        </div>

                        <div style={{ padding: ".5em" }}>
                            <MDBInput
                                placeholder={`Enter ${selected.selectedBox}`}
                                type="text"
                                name="name"
                                value={selected.name}
                                onChange={handleChange} />
                        </div>
                        <div style={{ padding: ".5em" }}>
                            <MDBBtn size='lg' style={{ background: "#5374B3 !important" }} onClick={handleSubmit} disabled={selected.name.length == 0} >Submit</MDBBtn>
                        </div>
                        <div style={{ padding: ".5em" }}>
                            <MDBBtn size='lg' onClick={handleClear} disabled={selected.name.length == 0} >Clear</MDBBtn>
                        </div>
                    </div>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    )
}

export default SearchStock
