import React from "react";
import {
  MDBSpinner,
  MDBBox,
  MDBCard,
  MDBTreeview,
  MDBTreeviewList,
  MDBTreeviewItem,
  MDBBtn,
  MDBCol,
  MDBRow,
  MDBInput,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOptions,
  MDBSelectOption,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
} from "mdbreact";
import PharmacyService from "../../services/pharmacyService";
import UserStore from "../../stores/userStore";
import { toast } from "react-toastify";
import Moments from "moment";
import config from "../../config";
import utility from "../../utility/utility";

let Barcode = require("react-barcode");

class ResetInventory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: [],
      orgId: "",
      stockDetails: [],
      loading: false,
      shipmentDetails: null,
      productDetails: null,
      floorKey: 1,
      rackKey: 1,
      boxKey: 1,
      storageKey: 1,
    };
  }

  async componentDidMount() {
    if (this.props.loadedOnPopup === true) {
      let orgId = "";
      this.setState({ loading: true });
      let userData = await UserStore.fetchSessionData();
      if (userData.type === "platformAdmin") {
        orgId = userData._id;
      } else if (userData.type === "ORG_ADMIN") {
        orgId = userData.orgDetails[0]._id;
      }
      let stockDetails = await PharmacyService.fetchInventoryByIdV3(
        userData.sessionToken,
        this.props.stockId,
        orgId
      );
      if (stockDetails) {
        stockDetails.mfgdate = Moments(stockDetails.mfgDate).format("MM/YYYY");
        stockDetails.expdate = Moments(stockDetails.expDate).format("MM/YYYY");
        let diff = this.calculateExpiringAfterMonth(stockDetails.expdate);
        stockDetails.expiringAfterMonth = Math.floor(diff);
        stockDetails.packageQnty = stockDetails.quantity;
        //calculate quantity
        if (
          stockDetails.productDetails.looseSaleAllowed === "Y" &&
          stockDetails.hasOwnProperty("looseQuantity")
        ) {
          stockDetails["looseQuantity"] = parseInt(stockDetails.looseQuantity);
        } else if (
          stockDetails.productDetails.looseSaleAllowed === "Y" &&
          !stockDetails.hasOwnProperty("looseQuantity")
        ) {
          stockDetails["looseQuantity"] =
            parseInt(stockDetails.quantity) *
            parseInt(stockDetails.productDetails.contentQuantity);
        } else if (stockDetails.productDetails.looseSaleAllowed === "N") {
          stockDetails["quantity"] = parseInt(stockDetails.quantity);
        }
        if (
          stockDetails.hasOwnProperty("barCode") === false ||
          stockDetails.barCode === "" ||
          stockDetails.barCode === null
        ) {
          stockDetails["barCode"] = new Date().getTime() + 122;
        }
        this.setState({ stockDetails });
        await this.fetchStorageLoc();
        await this.fetchFloor();
        await this.fetchRack();
        await this.fetchBox();
        this.setState({
          shipmentDetails: stockDetails.shipmentDetails,
          productDetails: stockDetails.productDetails,
        });
      } else {
        toast.error("Something went wrong while getting the stock details.");
      }
      this.setState({ loading: false, userData });
    }
  }

  handleChange =  (e, key) => {
    let stockDetails = this.state.stockDetails;
    if (
      (key === "mrp" ||
        key === "priceNogst" ||
        key === "gst" ||
        key === "priceWgst" ||
        key === "discount") &&
      e !== ""
    ) {
      if (e.substr(e.length - 1) !== ".") {
        if (utility.isCurrency(e) === false) {
          return false;
        }
      }
    }
    if (key === "discount") {
      if (parseFloat(e) > 100) {
        return false;
      }
    }
    if (key === "priceWgst" || key === "expiringAfterMonth") {
      return false;
    }
    if (key === "mfgdate" || key === "expdate") {
      if (stockDetails[key].length < e.length) {
        if (e.length === 2) {
          e = e + "/";
        }
      }
      if (e.length > 7) {
        return false;
      }
    }
    if (key === "packageQnty") {
      if (utility.isFloat(e)) {
        if (stockDetails.productDetails.looseSaleAllowed === "Y") {
          stockDetails["quantity"] =
            parseInt(e) * parseInt(stockDetails.productDetails.contentQuantity);
        } else if (stockDetails.productDetails.looseSaleAllowed === "N") {
          stockDetails["quantity"] = parseInt(e);
        }
      } else {
        if (e !== "") {
          toast.error("Please enter valid quantity.");
          return false;
        } else {
          stockDetails["quantity"] = "";
        }
      }
    }
    if (key === "looseQuantity") {
      if (e !== "" && !utility.isInt(e)) {
        return;
      }
    }

    stockDetails[key] = e;

    if (key === "freeQuantity") {
      stockDetails["freeQuantity"] = parseInt(e);
    }

    //calculating manipulated fields
    if (stockDetails.gst !== "" && stockDetails.priceNogst !== "") {
      stockDetails.priceWgst = utility.calculateGst(
        stockDetails.priceNogst,
        stockDetails.gst
      );
    }
    //calculation expiration
    if (stockDetails.expdate !== "") {
      let diff = this.calculateExpiringAfterMonth(stockDetails.expdate);
      if (diff < 0) {
        toast.error("Please enter valid Mfg date and Exp date.");
        stockDetails.expdate = "";
      } else {
        if (!isNaN(diff)) {
          stockDetails.expiringAfterMonth = Math.floor(diff);
        }
      }
    }
    this.setState({ stockDetails });
  };
  calculateExpiringAfterMonth = (expdate) => {
    let today = Moments();
    let tempExp = "27/" + expdate;
    let exp = Moments(tempExp, "DD/MM/YYYY", true);
    let diff = exp.diff(today, "months", true);
    return diff;
  };
  validateDate = (field) => {
    let stockDetails = this.state.stockDetails;
    let check = false;
    if (field === "Mfg") {
      if (stockDetails.mfgdate === "") {
        return true;
      }
      let tempMfg = "01/" + stockDetails.mfgdate;
      check = Moments(tempMfg, "DD/MM/YYYY", true);
    } else {
      if (stockDetails.expdate === "") {
        return true;
      }
      let tempExp = "27/" + stockDetails.expdate;
      check = Moments(tempExp, "DD/MM/YYYY", true);
    }
    if (check.isValid() === false) {
      toast.error("Please enter valid " + field + " date.");
      if (field === "Mfg") {
        stockDetails.mfgdate = "";
      } else {
        stockDetails.expdate = "";
      }
      this.setState({ stockDetails });
    }
  };
  fetchStorageLoc = async () => {
    let temp = [];
    let storageInfo = await PharmacyService.fetchStorageLocationV2();
    if (storageInfo === false) {
      toast.error("Something went wrong while fetching the storage location");
    } else {
      for (let item of storageInfo) {
        temp.push({ text: item.name, value: item });
      }
    }
    let stockDetails = this.state.stockDetails;
    stockDetails.locSuggestion = temp;
    this.setState({ stockDetails });
  };

  selectValStorageLoc = (e) => {
    if (e.length > 0) {
      let stockDetails = this.state.stockDetails;
      if (stockDetails.locId !== e[0]._id) {
        stockDetails.locId = e[0]._id;
        stockDetails.floorId = "";
        stockDetails.floorSuggestion = [];
        stockDetails.rackId = "";
        stockDetails.rackSuggestions = [];
        stockDetails.boxId = "";
        stockDetails.boxSuggestions = [];
        this.setState({
          floorKey: this.state.floorKey + 1,
          rackKey: this.state.rackKey + 1,
          boxKey: this.state.boxKey + 1,
          stockDetails,
        });
      }
    }
  };
  fetchFloor = async () => {
    let temp = [];
    if (
      this.state.stockDetails.hasOwnProperty("locId") &&
      this.state.stockDetails.locId !== ""
    ) {
      let floorInfo = await PharmacyService.fetchFloorV2(
        this.state.stockDetails.locId
      );
      if (floorInfo === false) {
        toast.error("Something went wrong while fetching the floor details");
      } else {
        if (floorInfo.length > 0) {
          for (let item of floorInfo) {
            temp.push({ text: item.floorName, value: item });
          }
        } else {
          toast.error("Floor is not configured for this location.");
        }
      }
    }
    let stockDetails = this.state.stockDetails;
    stockDetails.floorSuggestion = temp;
    this.setState({ stockDetails });
  };
  selectValFloor = (e) => {
    if (e.length > 0) {
      let stockDetails = this.state.stockDetails;
      if (stockDetails.floorId !== e[0]._id) {
        stockDetails.floorId = e[0]._id;
        stockDetails.rackId = "";
        stockDetails.rackSuggestions = [];
        stockDetails.boxId = "";
        stockDetails.boxSuggestions = [];
        this.setState({
          rackKey: this.state.rackKey + 1,
          boxKey: this.state.boxKey + 1,
          stockDetails,
        });
      }
    }
  };
  fetchRack = async () => {
    let temp = [];
    if (
      this.state.stockDetails.hasOwnProperty("floorId") &&
      this.state.stockDetails.floorId !== ""
    ) {
      let rackInfo = await PharmacyService.fetchRackV2(
        this.state.stockDetails.floorId
      );
      if (rackInfo === false) {
        toast.error("We are facing some server issue. Please try again later.");
      } else {
        if (rackInfo.length > 0) {
          for (let item of rackInfo) {
            temp.push({ text: item.rackName, value: item });
          }
        } else {
          toast.error("Rack configuration not found for this floor.");
        }
      }
    }
    let stockDetails = this.state.stockDetails;
    stockDetails.rackSuggestions = temp;
    this.setState({ stockDetails });
  };
  selectValRack = (e) => {
    if (e.length > 0) {
      let stockDetail = this.state.stockDetails;
      if (stockDetail.rackId !== e[0]._id) {
        stockDetail.rackId = e[0]._id;
        stockDetail.boxSuggestions = [];
        stockDetail.selectedBox = "";
        this.setState({ boxKey: this.state.boxKey + 1, stockDetail });
      }
    }
  };
  fetchBox = async () => {
    let temp = [];
    if (
      this.state.stockDetails.hasOwnProperty("rackId") &&
      this.state.stockDetails.rackId !== ""
    ) {
      let boxInfo = await PharmacyService.fetchBoxV2(
        this.state.stockDetails.rackId
      );
      if (boxInfo === false) {
        toast.error("We are facing some server issue. Please try again later.");
      } else {
        if (boxInfo.length > 0) {
          for (let item of boxInfo) {
            temp.push({ text: item.boxName, value: item });
          }
        } else {
          toast.error("Box configuration not found for this rack");
        }
      }
    }
    let stockDetails = this.state.stockDetails;
    stockDetails.boxSuggestions = temp;
    this.setState({ stockDetails });
  };
  selectValBox = (e) => {
    if (e.length > 0) {
      let stockDetail = this.state.stockDetails;
      stockDetail.boxId = e[0]._id;
      this.setState({ stockDetail });
    }
  };
  resetBarCode = () => {
    let stockDetails = this.state.stockDetails;
    stockDetails["barCode"] = new Date().getTime() + 122;
    this.setState({ stockDetails });
  };
  toCopy = (barCode) => {
    navigator.clipboard.writeText(barCode.toString().trim());
    toast.success(
      "Barcode is copied successfully. Copied barcode is- " +
        barCode.toString().trim()
    );
  };
  save = async () => {
    let temp = {};
    let stockDetails = this.state.stockDetails;
    if (stockDetails.batch === "") {
      toast.error("Please enter batch no.");
      return;
    } else {
      temp.batch = stockDetails.batch;
    }
    if (stockDetails.mrp === null || stockDetails.mrp === "") {
      toast.error("Please enter mrp.");
      return;
    } else {
      temp.mrp = parseFloat(stockDetails.mrp);
    }
    if (stockDetails.priceNogst === "" || stockDetails.priceNogst === null) {
      toast.error("Please enter price with no GST.");
      return;
    } else {
      temp.priceNoGst = parseFloat(stockDetails.priceNogst);
    }
    if (stockDetails.gst === "" || stockDetails.gst === null) {
      toast.error("Please enter GST.");
      return;
    } else {
      temp.gst = parseFloat(stockDetails.gst);
    }

    temp.priceWithGst = parseFloat(stockDetails.priceWgst);

    if (stockDetails.discount === "" || stockDetails.discount === null) {
      temp.discount = 0;
    } else {
      temp.discount = parseFloat(stockDetails.discount);
    }
    if (stockDetails.hsn === "" || stockDetails.hsn === null) {
      toast.error("Please enter hsn.");
      return;
    } else {
      temp.hsn = stockDetails.hsn;
    }

    if (stockDetails.mfgdate === "") {
      toast.error("Please enter Mfg Date.");
      return;
    } else {
      let tempMfg = "01/" + stockDetails.mfgdate;
      temp.mfgDate = Moments(tempMfg, "DD/MM/YYYY", true);
    }

    if (stockDetails.expdate === "") {
      toast.error("Please enter Exp Date.");
      return;
    } else {
      let tempExp = "27/" + stockDetails.expdate;
      temp.expDate = Moments(tempExp, "DD/MM/YYYY", true);
    }

    if (
      stockDetails.isLooseSaleAllowed === "N" &&
      (stockDetails.packageQnty === "" || stockDetails.packageQnty === null)
    ) {
      toast.error("Please enter package quantity.");
      return;
    } else {
      temp.quantity = parseFloat(stockDetails.packageQnty);
    }

    if (
      stockDetails.isLooseSaleAllowed === "Y" &&
      stockDetails.looseQuantity === ""
    ) {
      toast.error("Please enter loose quantity.");
      return;
    } else {
      temp.looseQuantity = parseInt(stockDetails.looseQuantity);
    }

    temp.freeQuantity = parseInt(stockDetails.freeQuantity);

    if (stockDetails.locId === "" || stockDetails.locId === null) {
      toast.error("Please enter storage location.");
      return;
    } else {
      temp.locId = stockDetails.locId;
    }

    if (stockDetails.floorId === "" || stockDetails.floorId === null) {
      toast.error("Please enter floor.");
      return;
    } else {
      temp.floorId = stockDetails.floorId;
    }

    if (stockDetails.rackId === "" || stockDetails.rackId === null) {
      toast.error("Please enter rack.");
      return;
    } else {
      temp.rackId = stockDetails.rackId;
    }

    if (stockDetails.boxId === "" || stockDetails.boxId === null) {
      toast.error("Please enter box.");
      return;
    } else {
      temp.boxId = stockDetails.boxId;
    }

    if (stockDetails.barCode === "" || stockDetails.barCode === null) {
      toast.error("Please enter barcode");
      return;
    } else {
      temp.barCode = stockDetails.barCode;
    }
    temp.comment =
      stockDetails.hasOwnProperty("comment") === true
        ? stockDetails.comment
        : "";
    temp.commentStorage =
      stockDetails.hasOwnProperty("commentStorage") === true
        ? stockDetails.commentStorage
        : "";
    temp.productId = stockDetails.productId;
    temp.shipmentId = stockDetails.shipmentId;
    temp._id = stockDetails._id;
    this.setState({ loading: true });
    let res = await PharmacyService.resetInventoryV3(
      this.state.userData.sessionToken,
      temp
    );
    if (res === false) {
      toast.error("Something went wrong while resetting the stock.");
    } else {
      toast.success("Stock reset successful.");
      this.props.toggleResetInvModal(null, "saved");
    }
    this.setState({ loading: false });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.loading === true && <MDBSpinner />}
        {this.state.stockDetails !== null && this.state.loading === false && (
          <MDBBox>
            <MDBCard className={"m-2 p-2"}>
              {this.state.shipmentDetails !== null && (
                <MDBRow>
                  <MDBCol>
                    {this.state.shipmentDetails !== null && (
                      <>
                        <MDBBox>
                          Shipment code:{" "}
                          {this.state.shipmentDetails.shipmentCode}
                        </MDBBox>
                        <MDBBox>
                          Invoice no: {this.state.shipmentDetails.invoiceNumber}
                        </MDBBox>
                        <MDBTreeview
                          theme="animated"
                          className={"tree-no-border"}
                        >
                          <MDBTreeviewList
                            icon="money"
                            title="Details"
                            far
                            open
                          >
                            <MDBTreeviewItem
                              icon={false}
                              title={
                                "Invoice date: " +
                                Moments(
                                  this.state.shipmentDetails.invoiceDate
                                ).format("DD/MM/YYYY")
                              }
                              far
                            ></MDBTreeviewItem>
                            <MDBTreeviewItem
                              icon={false}
                              title={
                                "Receiving date: " +
                                Moments(
                                  this.state.shipmentDetails.receivingDate
                                ).format("DD/MM/YYYY")
                              }
                              far
                            ></MDBTreeviewItem>
                            <MDBTreeviewItem
                              icon={false}
                              title={
                                "Total: " +
                                this.state.shipmentDetails.amount.toFixed(2)
                              }
                              far
                            ></MDBTreeviewItem>
                            <MDBTreeviewItem
                              icon={false}
                              title={
                                "Discount: " +
                                this.state.shipmentDetails.discountAmount.toFixed(
                                  2
                                )
                              }
                              far
                            ></MDBTreeviewItem>
                            <MDBTreeviewItem
                              icon={false}
                              title={
                                "Total tax: " +
                                this.state.shipmentDetails.totalTaxAmount
                              }
                              far
                            ></MDBTreeviewItem>
                            <MDBTreeviewItem
                              icon={false}
                              title={
                                "Receiving place: " +
                                this.state.shipmentDetails.supplyPlace
                              }
                              far
                            ></MDBTreeviewItem>
                            <MDBTreeviewItem
                              icon={false}
                              title={
                                "Supplier: " +
                                this.state.shipmentDetails.supplierDetails
                                  .supplierName
                              }
                              far
                            ></MDBTreeviewItem>
                            <MDBTreeviewItem
                              icon={false}
                              title={
                                "Entered by: " +
                                this.state.shipmentDetails.createdByDetails.name
                              }
                              far
                            ></MDBTreeviewItem>
                            <MDBTreeviewItem
                              icon={false}
                              title={
                                "Received by: " +
                                this.state.shipmentDetails.receivedByDetails
                                  .name
                              }
                              far
                            ></MDBTreeviewItem>
                            <MDBTreeviewList
                              icon={false}
                              title="Payment log"
                              far
                              open
                            >
                              {this.state.shipmentDetails.paymentLog.map(
                                (log, idx) => (
                                  <>
                                    <MDBCard className={"mt-2"}>
                                      <MDBTreeviewItem
                                        icon={false}
                                        title={
                                          "Amount: " +
                                          log.paymentAmount.toFixed(2)
                                        }
                                        far
                                      />
                                      <MDBTreeviewItem
                                        icon={false}
                                        title={
                                          "Payment mode: " + log.paymentMode
                                        }
                                        far
                                      />
                                      <MDBTreeviewItem
                                        icon={false}
                                        title={
                                          "Payment reference: " +
                                          log.paymentReference
                                        }
                                        far
                                      />
                                      <MDBTreeviewItem
                                        icon={false}
                                        title={
                                          "Payment date: " +
                                          Moments(log.paymentDate).format(
                                            "DD/MM/YYYY"
                                          )
                                        }
                                        far
                                      />
                                      <MDBTreeviewItem
                                        icon={false}
                                        title={
                                          "Entered by: " + log.enteredBy.name
                                        }
                                        far
                                      />
                                    </MDBCard>
                                  </>
                                )
                              )}
                            </MDBTreeviewList>
                          </MDBTreeviewList>
                        </MDBTreeview>
                      </>
                    )}
                  </MDBCol>
                  <MDBCol></MDBCol>
                  <MDBCol>
                    <MDBBtn
                      className={"float-right"}
                      onClick={() => {
                        this.save();
                      }}
                    >
                      Save
                    </MDBBtn>
                    <MDBBtn
                      className={"float-right"}
                      onClick={() => this.props.toggleResetInvModal(null)}
                    >
                      Close
                    </MDBBtn>
                  </MDBCol>
                </MDBRow>
              )}
            </MDBCard>
            <MDBCard className={"m-2 p-1"}>
              {this.state.productDetails !== null && (
                <>
                  <div
                    className={
                      "col-md-4 row m-auto p-0 d-flex justify-content-center"
                    }
                  >
                    <div className={"col-md-12 mx-0 p-0"}>
                      <MDBCard className={"p-2"}>
                        <MDBRow>
                          <MDBCol>
                            <MDBBox>
                              Product name:{" "}
                              {this.state.productDetails.productName}
                            </MDBBox>
                            <MDBBox>
                              <MDBTreeview
                                theme="animated"
                                className={"tree-no-border"}
                              >
                                <MDBTreeviewList
                                  icon="false"
                                  title="Details"
                                  far
                                  open
                                >
                                  <MDBTreeviewItem
                                    icon={false}
                                    title={
                                      "Retail packaging: " +
                                      this.state.productDetails.retailPackage
                                    }
                                    far
                                  />
                                  <MDBTreeviewItem
                                    icon={false}
                                    title={
                                      "Content quantity: " +
                                      this.state.productDetails.contentQuantity
                                    }
                                    far
                                  />
                                  <MDBTreeviewItem
                                    icon={false}
                                    title={
                                      "Quantity unit: " +
                                      this.state.productDetails.quantityUnit
                                    }
                                    far
                                  />
                                  <MDBTreeviewItem
                                    icon={false}
                                    title={
                                      "MRP: " +
                                      this.state.productDetails.mrp.toFixed(2)
                                    }
                                    far
                                  />
                                  <MDBTreeviewItem
                                    icon={false}
                                    title={
                                      "Stock: " +
                                      this.state.productDetails.stock
                                    }
                                    far
                                  />
                                  <MDBTreeviewItem
                                    icon={false}
                                    title={
                                      "Mfg: " +
                                      this.state.productDetails.manufacturer[0]
                                        .manufacturer
                                    }
                                    far
                                  />
                                  <MDBTreeviewItem
                                    icon={false}
                                    title={
                                      "Schedule: " +
                                      this.state.productDetails.medicineType
                                    }
                                    far
                                  />
                                  <MDBTreeviewItem
                                    icon={false}
                                    title={
                                      "Loose sale: " +
                                      this.state.productDetails.looseSaleAllowed
                                    }
                                    far
                                  />
                                  <MDBTreeviewItem
                                    icon={false}
                                    title={
                                      "Reorder threshold : " +
                                      this.state.productDetails.reorderThreshold
                                    }
                                    far
                                  />
                                </MDBTreeviewList>
                              </MDBTreeview>
                            </MDBBox>
                          </MDBCol>
                          <MDBCol>
                            {this.state.productDetails.imageUrl !== "" &&
                              this.state.productDetails.imageUrl.length > 0 && (
                                <MDBBox>
                                  <img
                                    src={
                                      config.IMAGE_HOST +
                                      this.state.productDetails.imageUrl[0]
                                        .filePathThumb
                                    }
                                  />
                                </MDBBox>
                              )}
                          </MDBCol>
                        </MDBRow>
                      </MDBCard>
                    </div>
                  </div>
                  <div className={"col-md-12 row mx-0 p-1"}>
                    <div className={"col m-1 p-1"}>
                      <MDBInput
                        label={"Batch"}
                        value={this.state.stockDetails.batch}
                        getValue={(e) => {
                          this.handleChange(e, "batch");
                        }}
                        maxLength={50}
                      />
                    </div>
                    <div className={"col m-1 p-1"}>
                      <MDBInput
                        label={"MRP"}
                        value={this.state.stockDetails.mrp}
                        getValue={(e) => {
                          this.handleChange(e, "mrp");
                        }}
                        maxLength={50}
                      />
                    </div>
                    <div className={"col m-1 p-1"}>
                      <MDBInput
                        label={"Price (w/o GST)"}
                        value={this.state.stockDetails.priceNogst}
                        getValue={(e) => {
                          this.handleChange(e, "priceNogst");
                        }}
                        maxLength={50}
                      />
                    </div>
                    <div className={"col m-1 p-1"}>
                      <MDBInput
                        label={"GST %"}
                        value={this.state.stockDetails.gst}
                        getValue={(e) => {
                          this.handleChange(e, "gst");
                        }}
                        maxLength={50}
                      />
                    </div>
                    <div className={"col m-1 p-1"}>
                      <MDBInput
                        label={"Price (w/ GST)"}
                        value={this.state.stockDetails.priceWgst}
                        getValue={(e) => {
                          this.handleChange(e, "priceWgst");
                        }}
                        maxLength={50}
                      />
                    </div>
                    <div className={"col m-1 p-1"}>
                      <MDBInput
                        label={"Purchase discount %"}
                        value={this.state.stockDetails.discount}
                        getValue={(e) => {
                          this.handleChange(e, "discount");
                        }}
                        maxLength={50}
                      />
                    </div>
                    <div className={"col m-1 p-1"}>
                      <MDBInput
                        label={"HSN/SAC"}
                        value={this.state.stockDetails.hsn}
                        getValue={(e) => {
                          this.handleChange(e, "hsn");
                        }}
                        maxLength={50}
                      />
                    </div>
                  </div>
                  <div className={"col-md-12 row mx-0 p-1"}>
                    <div className={"col m-1 p-1"}>
                      <MDBInput
                        label={"Mfg date (MM/YYYY)"}
                        value={this.state.stockDetails.mfgdate}
                        getValue={(e) => {
                          this.handleChange(e, "mfgdate");
                        }}
                        maxLength={50}
                        onBlur={() => {
                          this.validateDate("Mfg");
                        }}
                      />
                    </div>
                    <div className={"col m-1 p-1"}>
                      <MDBInput
                        label={"Exp date (MM/YYYY)"}
                        value={this.state.stockDetails.expdate}
                        getValue={(e) => {
                          this.handleChange(e, "expdate");
                        }}
                        maxLength={50}
                        onBlur={() => {
                          this.validateDate("Exp");
                        }}
                      />
                    </div>
                    <div className={"col m-1 p-1"}>
                      <MDBInput
                        label={"Exp after months"}
                        value={this.state.stockDetails.expiringAfterMonth}
                        getValue={(e) => {
                          this.handleChange(e, "expiringAfterMonth");
                        }}
                        maxLength={50}
                      />
                    </div>
                    <div className={"col m-1 p-1"}>
                      <MDBInput
                        label={"Quantity in Strips"}
                        value={this.state.stockDetails.productDetails.quantity}
                        getValue={(e) => {
                          this.handleChange(e, "quantity");
                        }}
                        maxLength={50}
                      />
                    </div>
                    <div className={"col m-1 p-1"}>
                      <MDBInput
                        label={"Quantity"}
                        value={this.state.stockDetails.productDetails.quantity}
                        getValue={(e) => {
                          this.handleChange(e, "quantity");
                        }}
                        maxLength={50}
                      />
                    </div>
                    {this.state.productDetails.looseSaleAllowed === "N" ? (
                      <>
                        <div className={"col m-1 p-1"}>
                          <MDBInput
                            value={this.state.stockDetails.packageQnty}
                            label={
                              "No of " +
                              this.state.productDetails.retailPackage +
                              " X " +
                              this.state.productDetails.contentQuantity +
                              " " +
                              this.state.productDetails.quantityUnit
                            }
                            maxLength={50}
                            getValue={(e) => {
                              this.handleChange(e, "packageQnty");
                            }}
                          />
                        </div>
                        <div className={"col m-1 p-1"}>
                          <MDBInput
                            label={"Quantity"}
                            value={this.state.stockDetails.quantity}
                            key={this.state.stockDetails.quantity}
                          />
                        </div>
                      </>
                    ) : (
                      <div className={"col m-1 p-1"}>
                        <MDBInput
                          label={"Loose Quantity"}
                          value={this.state.stockDetails.looseQuantity}
                          getValue={(e) =>
                            this.handleChange(e, "looseQuantity")
                          }
                          maxLength={50}
                        />
                      </div>
                    )}

                    <div className={"col m-1 p-1"}>
                      <MDBInput
                        label={"Free Quantity"}
                        value={this.state.stockDetails.freeQuantity}
                        getValue={(e) => this.handleChange(e, "freeQuantity")}
                        key={this.state.stockDetails.freeQuantity}
                      />
                    </div>
                  </div>
                  <div className={"col-md-12 row mx-0 p-2"}>
                    <div className={"col m-1 p-1"}>
                      <MDBInput
                        type={"textarea"}
                        label={"Comment related to storage"}
                        value={this.state.stockDetails.commentStorage}
                        getValue={(e) => {
                          this.handleChange(e, "commentStorage");
                        }}
                      />
                    </div>

                    <div className={"col m-1 p-1"}>
                      <MDBInput
                        type={"textarea"}
                        label={"Comment"}
                        value={this.state.stockDetails.comment}
                        getValue={(e) => {
                          this.handleChange(e, "comment");
                        }}
                      />
                    </div>
                    <div className={"col m-1 p-1"}></div>
                  </div>
                  <div className="table-for-reset-stock">
                    <MDBTable className="main-table-reset-stock">
                      <MDBTableHead>
                        <tr>
                          <th>sl no.</th>
                          <th>storage location</th>
                          <th>Floor</th>
                          <th>Rack</th>
                          <th>Box</th>
                          <th>BarCode</th>
                        </tr>
                      </MDBTableHead>
                      <MDBTableBody search={true}>
                        {Array.from({
                          length:
                            this.state.stockDetails.productDetails.quantity,
                        }).map((_, index) => (
                          <tr key={index}>
                            <td>{index+1}</td>
                            <td>
                              <MDBSelect
                                key={this.state.storageKey}
                                label="Select storage location"
                                getValue={(e) => {
                                  this.selectValStorageLoc(e);
                                }}
                              >
                                <MDBSelectInput selected="Choose your option" />
                                <MDBSelectOptions>
                                  {this.state.stockDetails.hasOwnProperty(
                                    "locSuggestion"
                                  ) &&
                                    this.state.stockDetails.locSuggestion.map(
                                      (loc, idx) => (
                                        <MDBSelectOption
                                          key={idx}
                                          value={loc.value}
                                          selected={
                                            loc.value._id ===
                                            this.state.stockDetails.locId
                                          }
                                        >
                                          {loc.text}
                                        </MDBSelectOption>
                                      )
                                    )}
                                </MDBSelectOptions>
                              </MDBSelect>
                            </td>
                            <td>
                              <MDBSelect
                                key={this.state.floorKey}
                                label="Select floor"
                                onFocus={this.fetchFloor}
                                getValue={(e) => {
                                  this.selectValFloor(e);
                                }}
                              >
                                <MDBSelectInput selected="Choose your option" />
                                <MDBSelectOptions>
                                  {this.state.stockDetails.hasOwnProperty(
                                    "floorSuggestion"
                                  ) &&
                                    this.state.stockDetails.floorSuggestion.map(
                                      (floor, idx) => (
                                        <MDBSelectOption
                                          key={idx}
                                          value={floor.value}
                                          selected={
                                            floor.value._id ===
                                            this.state.stockDetails.floorId
                                          }
                                        >
                                          {floor.text}
                                        </MDBSelectOption>
                                      )
                                    )}
                                </MDBSelectOptions>
                              </MDBSelect>
                            </td>
                            <td>
                              <MDBSelect
                                key={this.state.rackKey}
                                label="Select Rack"
                                onFocus={() => {
                                  this.fetchRack();
                                }}
                                getValue={(e) => {
                                  this.selectValRack(e);
                                }}
                              >
                                <MDBSelectInput selected="Choose your option" />
                                <MDBSelectOptions>
                                  {this.state.stockDetails.hasOwnProperty(
                                    "rackSuggestions"
                                  ) &&
                                    this.state.stockDetails.rackSuggestions.map(
                                      (rack, idx) => (
                                        <MDBSelectOption
                                          key={idx}
                                          value={rack.value}
                                          selected={
                                            rack.value._id ===
                                            this.state.stockDetails.rackId
                                          }
                                        >
                                          {rack.text}
                                        </MDBSelectOption>
                                      )
                                    )}
                                </MDBSelectOptions>
                              </MDBSelect>
                            </td>
                            <td>
                              <MDBSelect
                                key={this.state.boxKey}
                                label="Select Box"
                                onFocus={() => {
                                  this.fetchBox();
                                }}
                                getValue={(e) => {
                                  this.selectValBox(e);
                                }}
                              >
                                <MDBSelectInput selected="Choose your option" />
                                <MDBSelectOptions>
                                  {this.state.stockDetails.hasOwnProperty(
                                    "boxSuggestions"
                                  ) &&
                                    this.state.stockDetails.boxSuggestions.map(
                                      (box, idx) => (
                                        <MDBSelectOption
                                          key={idx}
                                          value={box.value}
                                          selected={
                                            box.value._id ===
                                            this.state.stockDetails.boxId
                                          }
                                        >
                                          {box.text}
                                        </MDBSelectOption>
                                      )
                                    )}
                                </MDBSelectOptions>
                              </MDBSelect>
                            </td>
                            <td>
                              <div className={"col m-1 p-1"}>
                                <MDBBox ref={(el) => (this.componentRef = el)}>
                                  <Barcode
                                    value={this.state.stockDetails.barCode}
                                  ></Barcode>
                                </MDBBox>
                                <MDBInput
                                  value={this.state.stockDetails.barCode}
                                  label={"Bar code"}
                                  getValue={(e) => {
                                    this.handleChange(e, "barCode");
                                  }}
                                  maxLength={30}
                                />
                                <MDBBtn
                                  onClick={() => {
                                    this.resetBarCode();
                                  }}
                                  size={"sm"}
                                >
                                  Reset
                                </MDBBtn>
                                <MDBBtn
                                  onClick={() => {
                                    this.toCopy(
                                      this.state.stockDetails.barCode
                                    );
                                  }}
                                  size={"sm"}
                                >
                                  Copy
                                </MDBBtn>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </MDBTableBody>
                    </MDBTable>
                  </div>
                </>
              )}
            </MDBCard>
          </MDBBox>
        )}
      </React.Fragment>
    );
  }
}

export default ResetInventory;
