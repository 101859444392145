import React, {Suspense} from "react";
import {MDBBreadcrumb, MDBBreadcrumbItem} from "mdbreact";
import DuplicateProductsComponent from "../../components/report/duplicateProducts";
import {Link} from "react-router-dom";

export default class DuplicateProducts extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem><Link to={'/download-reports'}>Reports</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Duplicates Products</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <Suspense fallback={null}>
                    <DuplicateProductsComponent/>
                </Suspense>
            </React.Fragment>
        )
    }
}