import axios from "axios";
import config from "../config";
import Cookies from "universal-cookie";
import utility from "../utility/utility";
import Moments from "moment";
import { toast } from "react-toastify";
const axiosConfUrlEnc = {
  headers: {
    "Content-Type": "application/json",
  },
};
const oldJavaSessionToken = "Pe44ac35f-Y20fa-T43a0-Hb5e8-Odd702-N87b5949";

const AuthService = {
  login: async function (userName, password) {
    try {
      let payload = {
        userName: userName,
        password: password,
      };
      const response = await axios.post(config.APIHOST + config.LOGIN, payload);
      const cookies = new Cookies();
      cookies.set("userSession", response.data, { path: "/", sameSite: true });
      return response.data;
    } catch (e) {
      return false;
    }
  },
  logOut: async function (userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.post(
        config.APIHOST + config.LOGOUT,
        {},
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  addOrg: async function (data, userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      let payload = {
        orgName: data.orgUser,
        orgEmail: data.email,
        orgPhone: data.contactNo,
        addressLine1: data.address,
        addressLine2: data.street,
        area: data.area,
        pinCode: data.pincode,
        city: data.city,
        state: data.state,
        country: data.country,
        landmark: data.landmark,
        imageUrl: data.imageUrl,
      };
      const response = await axios.post(
        config.APIHOST + config.ADD_ORG,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  createOrgUser: async function (data, userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      let payload = {
        name: data.name,
        userName: data.userName,
        password: data.password,
        email: data.email,
        phone: data.contactNo,
        orgId: data.orgId,
        type: data.type,
        permissions: data.permissions,
      };
      const response = await axios.post(
        config.APIHOST + config.CREATE_ORG_USER,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  saveEditedData: async function (payload) {
    try {
      const response = await axios.post(
        config.APIHOST_FOR_SEARCH_CUSTOMER + config.SAVE_EDIT_CUSTOMER_DETAILS,
        payload
      );
      return response.data;
    } catch (e) {
      toast.error(e.response.data.message);
      return false;
    }
  },
  saveEditedAddressData: async function (payload) {
    try {
      const response = await axios.post(
        config.APIHOST_FOR_SEARCH_CUSTOMER +
          config.SAVE_EDIT_CUSTOMER_ADDRESS_DETAILS,
        payload
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  saveResetStock: async function (payload,userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.post(
        config.APIHOST_FOR_SEARCH_CUSTOMER +
          config.SAVE_RESET_STOCK,
        payload,headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  saveEditedStockData: async function (payload, userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.post(
        config.APIHOST_FOR_SEARCH_CUSTOMER + config.SAVE_EDIT_STOCK,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  saveResetStockData: async function (id, userData) {
    try {
      const payload = {
        id: id,
      };
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.post(
        config.APIHOST_FOR_SEARCH_CUSTOMER + config.SAVE_RESET_STOCK,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  fetchOrgUsers: async function (data, userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST + config.FETCH_ORG_USERS + "?orgId=" + data.orgId,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  fetchOrgs: async function (userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST + config.FETCH_ORGS,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  fetchOrgById: async function (id, userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST + config.FETCH_ORG_BY_ID + "?_id=" + id,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  fetchUserById: async function (data, userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST +
          config.FETCH_ORG_USER_BY_ID +
          "?_id=" +
          data.orgUserId +
          "&orgId=" +
          data.orgId,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  updateOrgUser: async function (data, userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      let payload = {
        _id: data.orgUserId,
        orgName: data.orgName,
        orgEmail: data.email,
        orgPhone: data.contactNo,
        imageUrl: data.imageUrl,
        isActive: data.isActive,
        addressLine1: data.address,
        addressLine2: data.street,
        area: data.area,
        pinCode: data.pincode,
        city: data.city,
        state: data.state,
        country: data.country,
        landmark: data.landmark,
      };
      const response = await axios.post(
        config.APIHOST + config.UPDATE_ORG,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  deleteOrgUser: async function (data, userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      let payload = {
        _id: data.orgUserId,
        orgId: data.orgId,
      };
      const response = await axios.post(
        config.APIHOST + config.DELETE_ORG_USER,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  deleteOrg: async function (data, userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      let payload = {
        _id: data.orgUserId,
      };
      const response = await axios.post(
        config.APIHOST + config.DELETE_ORG,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  updateOrgStatus: async function (data, status, userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      let payload = {
        _id: data.activeOrgUserId,
        isActive: status,
      };
      const response = await axios.post(
        config.APIHOST + config.UPDATE_ORG_STATUS,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  updateOrgUserStatus: async function (data, status, userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      let payload = {
        _id: data.activeOrgUserId,
        isActive: status,
        orgId: data.orgId, // API is expecting, though this is not required
      };
      const response = await axios.post(
        config.APIHOST + config.UPDATE_ORG_USER_STATUS,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  updateUser: async function (data, userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      let payload = {
        name: data.name,
        userName: data.userName,
        _id: data.orgUserId,
        email: data.email,
        phone: data.contactNo,
        orgId: data.orgId,
        type: data.type,
        permissions: data.permissions,
      };
      const response = await axios.post(
        config.APIHOST + config.UPDATE_ORG_USER,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  createCustomer: async function (data) {
    try {
      const headers = {
        headers: { Authorization: data.userData.sessionToken },
      };
      let payload = {
        name: data.name,
        email: data.email,
        phone: data.contactNo,
        dob: data.dob,
        orgId: data.orgId,
        gender: data.gender,
      };

      const response = await axios.post(
        config.APIHOST + config.CREATE_CUSTOMER,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  suggestCustomer: async function (searchParam, data) {
    try {
      const headers = {
        headers: { Authorization: data.userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST +
          config.SUGGEST_CUSTOMER +
          "?term=" +
          searchParam +
          "&sessionToken=" +
          data.userData.sessionToken,
        headers
      );
      return response.data;
    } catch (e) {
      return null;
    }
  },
  suggestCustomerNew: async function (searchParam, sessionToken) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.get(
        config.APIHOST + config.SUGGEST_CUSTOMER + "?term=" + searchParam,
        headers
      );
      return response.data;
    } catch (e) {
      return null;
    }
  },

  searchCustomer: async function (searchParam, data) {
    try {
      const response = await axios.get(
        config.APIHOST +
          config.SEARCH_CUSTOMER_PYTHON +
          "?sessionToken=" +
          oldJavaSessionToken +
          "&term=" +
          searchParam +
          "&providerId=" +
          data.numId +
          "&web=true"
      );
      return response.data;
    } catch (e) {
      return null;
    }
  },

  searchCustomerJava: async function (searchParam, data) {
    try {
      const response = await axios.get(
        config.JAVA_HOST +
          config.SEARCH_CUSTOMER_JAVA +
          "?sessionToken=" +
          oldJavaSessionToken +
          "&searchParam=" +
          searchParam +
          "&providerId=" +
          data.numId +
          "&web=true"
      );
      return response.data;
    } catch (e) {
      return null;
    }
  },

  searchProviderNew: async function (searchParam, data) {
    try {
      const response = await axios.get(
        config.APIHOST + config.SEARCH_PROVIDER_PYTHON + "?term=" + searchParam
      );
      return response.data;
    } catch (e) {
      return null;
    }
  },

  searchProvider: async function (searchParam, data) {
    try {
      const response = await axios.get(
        config.JAVA_HOST +
          config.SEARCH_PROVIDER_HOSPITAL_JAVA +
          "?sessionToken=" +
          oldJavaSessionToken +
          "&searchParam=" +
          searchParam +
          "&hospitalId=" +
          data.numId +
          "&web=true"
      );
      return response.data;
    } catch (e) {
      return null;
    }
  },

  register: async function (data) {
    try {
      let payload = {
        client: "WEB",
        registrationType: "PROVIDER",
        needOtp: true,
        name: data.name,
        gender: data.gender,
        dob: data.dob ? Moments(data.dob).format("YYYY-MM-DD") : "",
        createdBy: data.numId,
        hospitalId: data.numId,
        hospitalType: "PHARMACY",
        doctorId: 0,
        contactNo: data.contactNo,
      };
      if (data.email && utility.validateEmail(data.email)) {
        payload.email = data.email;
      }
      const response = await axios.post(
        config.JAVA_HOST + config.SIGNUP,
        payload,
        axiosConfUrlEnc
      ); //, config.axiosConfUrlJson
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  },

  associateUser: async function (data, userData) {
    const headers = {
      headers: { Authorization: userData.sessionToken },
    };
    try {
      let payload = {
        userId: data.userId,
      };

      const response = await axios.post(
        config.APIHOST + config.ASSOCIATE_CUSTOMER,
        payload,
        headers
      ); //, config.axiosConfUrlJson
      return response.data;
    } catch (e) {
      return null;
    }
  },

  deleteCustomer: async function (data, userData) {
    const headers = {
      headers: { Authorization: userData.sessionToken },
    };
    try {
      let payload = {
        _id: data.user._id,
      };

      const response = await axios.post(
        config.APIHOST + config.DELETE_CUSTOMER,
        payload,
        headers
      ); //, config.axiosConfUrlJson
      return response.data;
    } catch (e) {
      return null;
    }
  },

  deleteDependent: async function (data, userData) {
    const headers = {
      headers: { Authorization: userData.sessionToken },
    };
    try {
      let payload = {
        _id: data.selectedDependent._id,
      };
      const response = await axios.post(
        config.APIHOST + config.DELETE_DEPENDENT,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return null;
    }
  },

  deleteCustomerAddress: async function (data, userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      let payload = {
        addressId: data.selectedAddress._id,
      };

      const response = await axios.post(
        config.APIHOST + config.DELETE_ADDRESS_PYTHON,
        payload,
        headers
      ); //, config.axiosConfUrlJson
      return response.data;
    } catch (e) {
      return null;
    }
  },

  fetchDependentsByUserId: async function (data, customerId) {
    try {
      const headers = {
        headers: { Authorization: data.userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST +
          config.FETCH_DEPENDENTS_BY_USER_ID +
          "?customerId=" +
          customerId,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  addDependent: async function (data) {
    const headers = {
      headers: { Authorization: data.userData.sessionToken },
    };
    //try {
    let payload = {
      name: data.name,
      customerId: data.user._id,
      relation: data.relation,
      phone: utility.checkPhone(data.contactNo) ? data.contactNo : "",
      email: utility.validateEmail(data.email) ? data.email : "",
      dob: "",
    };
    const response = await axios.post(
      config.APIHOST + config.ADD_DEPENDENT,
      payload,
      headers
    );
    return response.data;
    //} catch (e) {
    //  return null;
    //}
  },

  checkIfPhoneExist: async function (contactNo, type) {
    try {
      const response = await axios.get(
        config.APIHOST +
          config.CHECK_PHONE +
          "?phone=" +
          contactNo +
          "&type=" +
          type
      );
      return response.data;
    } catch (e) {
      return null;
    }
  },

  checkIfEmailExist: async function (email, type) {
    try {
      const response = await axios.get(
        config.APIHOST +
          config.CHECK_EMAIL +
          "?email=" +
          email +
          "&type=" +
          type
      );
      return response.data;
    } catch (e) {
      return null;
    }
  },

  addDoctor: async function (data) {
    const headers = {
      headers: { Authorization: data.userData.sessionToken },
    };
    let specialityTemp = [];
    if (data.speciality.length > 0) {
      specialityTemp = data.speciality.toString().split(":");
    }
    let payload = {
      name: data.name,
      email: data.email,
      address: data.address,
      street: data.street,
      pincode: data.pincode,
      area: data.area,
      city: data.city,
      state: data.state,
      country: data.country,
      landmark: data.landmark,
      altPhone: data.altPhone,
      contactNo: data.contactNo,
      orgId: data.orgId,
      regNo: data.regNo,
      speciality: specialityTemp[1] ? specialityTemp[1] : "",
      specialityId: specialityTemp[0] ? specialityTemp[0] : "",
      doctorSince: data.doctorSince,
    };

    const response = await axios.post(
      config.APIHOST + config.CREATE_DOCTOR + "?web=true",
      payload,
      headers
    );
    return response;

    // if(addressId){
    //     payload.addressId=addressId;
    //     const response = await axios.post(config.APIHOST+config.EDIT_ADDRESS_PYTHON+"?web=true", payload, headers);
    //     return response
    // } else {
    //     const response = await axios.post(config.APIHOST+config.CREATE_CUSTOMER_ADDRESS+"?web=true", payload, headers);
    //     return response
    // }

    // } catch (e) {
    //     return null;
    // }
  },

  updateUserAddress: async function (
    customerId,
    addressId,
    defaultAddress,
    name,
    address,
    street,
    pincode,
    area,
    city,
    state,
    country,
    landmark,
    altPhone,
    contactNo,
    data
  ) {
    try {
      const headers = {
        headers: { Authorization: data.userData.sessionToken },
      };
      let payload = {
        name: name,
        addressLine1: address,
        addressLine2: street,
        pinCode: pincode,
        area: area,
        city: city,
        state: state,
        country: country,
        landmark: landmark,
        phone: contactNo,
        addressType: "HOME",
        customerId: customerId,
      };
      // name=data["name"]
      // phone=data["phone"]
      // addressLine1=data["addressLine1"]
      // customerId=data["customerId"]
      // addressLine2=data["addressLine2"]
      // pinCode=data["pinCode"]
      // city=data["city"]
      // state=data["state"]
      // country=data["country"]
      // landmark=data["landmark"]
      // area=data["area"]
      // addressType=data["addressType"]
      // addressId=data["addressId"]
      if (addressId) {
        payload.addressId = addressId;
        const response = await axios.post(
          config.APIHOST + config.EDIT_ADDRESS_PYTHON + "?web=true",
          payload,
          headers
        );
        return response;
      } else {
        const response = await axios.post(
          config.APIHOST + config.CREATE_CUSTOMER_ADDRESS + "?web=true",
          payload,
          headers
        );
        return response;
      }
    } catch (e) {
      return null;
    }
  },

  deleteAddress: async function (addressId, userData) {
    try {
      //const response = await axios.get(config.JAVA_HOST+config.DELETE_ADDRESS+"?sessionToken="+sessionToken+"&web=true&id="+addressId);
      const response = await axios.get(
        config.JAVA_HOST + config.DELETE_ADDRESS + "?web=true&id=" + addressId,
        userData
      );
      return response;
    } catch (e) {
      return null;
    }
  },
  changePassword: async function (userData, password) {
    const headers = {
      headers: { Authorization: userData.sessionToken },
    };
    try {
      let payload = {
        _id: userData._id,
        password: password,
      };
      const response = await axios.post(
        config.APIHOST + config.CHANGE_PASSWORD,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return null;
    }
  },
  editDoctor: async function (data) {
    const headers = {
      headers: { Authorization: data.userData.sessionToken },
    };
    let specialityTemp = [];
    if (data.speciality.length > 0) {
      specialityTemp = data.speciality.toString().split(":");
    }
    let payload = {
      name: data.name,
      email: data.email,
      address: data.address,
      street: data.street,
      pincode: data.pincode,
      area: data.area,
      city: data.city,
      state: data.state,
      country: data.country,
      landmark: data.landmark,
      altPhone: data.altPhone,
      contactNo: data.contactNo,
      orgId: data.orgId,
      regNo: data.regNo,
      speciality: specialityTemp[1] ? specialityTemp[1] : "",
      specialityId: specialityTemp[0] ? specialityTemp[0] : "",
      doctorSince: data.doctorSince,
      _id: data.doctorId,
    };

    const response = await axios.post(
      config.APIHOST + config.EDIT_DOCTOR + "?web=true",
      payload,
      headers
    );
    return response;
  },

  deleteDoctor: async function (data) {
    const headers = {
      headers: { Authorization: data.userData.sessionToken },
    };
    let payload = {
      _id: data.doctorId,
      orgId: data.orgId,
    };

    const response = await axios.post(
      config.APIHOST + config.DELETE_DOCTOR + "?web=true",
      payload,
      headers
    );
    return response;
  },

  fetchDoctorById: async function (_id, userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST +
          config.FETCH_DOCTOR_BY_ID +
          "?_id=" +
          _id +
          "&orgId=" +
          userData.org[0]._id,
        headers
      );
      return response.data;
    } catch (e) {
      return null;
    }
  },
  createCustomerv3: async function (
    name,
    contactNo,
    gender,
    dob,
    email,
    userData
  ) {
    const headers = {
      headers: { Authorization: userData.sessionToken },
    };
    try {
      let payload = {
        name: name,
        contactNo: contactNo,
        gender: gender,
        dob: dob,
        email: email,
      };

      const response = await axios.post(
        config.APIHOST + config.ADD_CUSTOMER_V3,
        payload,
        headers
      ); //, config.axiosConfUrlJson
      return response.data;
    } catch (e) {
      return null;
    }
  },
  fetchTaskList: async function (sessionToken, startDate, endDate, assignedTo) {
    const headers = {
      headers: { Authorization: sessionToken },
    };
    try {
      const response = await axios.get(
        config.APIHOST +
          config.FETCH_TASK_LIST +
          "?startDate=" +
          startDate +
          "&endDate=" +
          endDate +
          "&assignedTo=" +
          assignedTo,
        headers
      );
      return response.data;
    } catch (e) {
      return null;
    }
  },
  assignTask: async function (payload, sessionToken) {
    const headers = {
      headers: { Authorization: sessionToken },
    };
    try {
      const response = await axios.post(
        config.APIHOST + config.ASSIGN_TASK,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return null;
    }
  },
  markTaskAsComplete: async function (payload, sessionToken) {
    const headers = {
      headers: { Authorization: sessionToken },
    };
    try {
      const response = await axios.post(
        config.APIHOST + config.MARK_TASK_AS_COMPLETE,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return null;
    }
  },
  addTask: async function (payload, sessionToken) {
    const headers = {
      headers: { Authorization: sessionToken },
    };
    try {
      const response = await axios.post(
        config.APIHOST + config.ADD_TASK,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return null;
    }
  },
  rescheduleTask: async function (payload, sessionToken) {
    const headers = {
      headers: { Authorization: sessionToken },
    };
    try {
      const response = await axios.post(
        config.APIHOST + config.RESCHEDULE_TASK,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return null;
    }
  },
  addCustomerCallLog: async function (payload, sessionToken) {
    const headers = {
      headers: { Authorization: sessionToken },
    };
    try {
      const response = await axios.post(
        config.APIHOST + config.ADD_CUSTOMER_CALL_LOG,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return null;
    }
  },
  fetchCustomerCallLog: async function (customerId, sessionToken) {
    const headers = {
      headers: { Authorization: sessionToken },
    };
    try {
      const response = await axios.get(
        config.APIHOST +
          config.FETCH_CUSTOMER_CALL_LOG +
          "?customerId=" +
          customerId,
        headers
      );
      return response.data;
    } catch (e) {
      return null;
    }
  },
  updateCustomerCallLog: async function (payload, sessionToken) {
    const headers = {
      headers: { Authorization: sessionToken },
    };
    try {
      const response = await axios.post(
        config.APIHOST + config.UPDATE_CUSTOMER_CALL_LOG,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return null;
    }
  },
  addressLookupByPhone: async function (sessionToken, phone) {
    const headers = {
      headers: { Authorization: sessionToken },
    };
    try {
      const response = await axios.get(
        config.APIHOST + config.ADDRESS_LOOKUP_BY_PHONE + "?phone=" + phone,
        headers
      );
      return response.data;
    } catch (e) {
      return null;
    }
  },
  fetchPinCodeDetails: async function (sessionToken, pinCode) {
    const headers = {
      headers: { Authorization: sessionToken },
    };
    try {
      const response = await axios.get(
        config.APIHOST + config.FETCH_PINCODE_DETAILS + "?pinCode=" + pinCode,
        headers
      );
      return response.data;
    } catch (e) {
      return null;
    }
  },
  checkPinStatus: async function (pinCode) {
    try {
      const response = await axios.get(
        config.WEBAPI_HOST +
          config.CHECK_PIN_STATUS +
          "?pinCodeTo=" +
          pinCode +
          "&pinCodeFrom=781028&countryCode=India"
      );
      return response.data;
    } catch (e) {
      return null;
    }
  },
  fetchSlotDate: async function (pinCode) {
    try {
      const response = await axios.get(
        config.WEBAPI_HOST + config.SLOT_DATE + "?pin=" + pinCode
      );
      return response.data;
    } catch (e) {
      return null;
    }
  },
  fetchSlots: async function (pinCode, date) {
    try {
      const response = await axios.get(
        config.WEBAPI_HOST +
          config.FETCH_SLOTS +
          "?pin=" +
          pinCode +
          "&date=" +
          date
      );
      return response.data;
    } catch (e) {
      return null;
    }
  },
  fetchSearchcustomerIndividualData: async function (userId, userType) {
    try {
      const response = await axios.get(
        config.APIHOST_FOR_SEARCH_CUSTOMER +
          config.FETCH_CUSTOMER_INDIVIDUAL_DATA_BY_SEARCH +
          "?userId=" +
          userId +
          "&userType=" +
          userType
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  fetchProductIndividualAllData: async function (dataId, userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST_FOR_SEARCH_CUSTOMER +
          config.FETCH_PRODUCT_BY_SEARCH_ID +
          "?id=" +
          dataId,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  fetchSearchcustomerCallLogs: async function (userId, page) {
    try {
      const response = await axios.get(
        config.APIHOST_FOR_SEARCH_CUSTOMER +
          config.FETCH_CUSTOMER_INDIVIDUAL_CALLLOGS_BY_SEARCH +
          "?userId=" +
          userId +
          "&page=" +
          page
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  saveAddCallLog: async function (payload) {
    try {
      const response = await axios.post(
        config.APIHOST_FOR_SEARCH_CUSTOMER + config.ADD_CALL_LOG_CUSTOMER,
        payload
      );
      return response.data;
    } catch (e) {
      return null;
    }
  },
  fetchSearchcustomerData: async function (searchTerm) {
    try {
      const response = await axios.get(
        config.APIHOST_FOR_SEARCH_CUSTOMER +
          config.FETCH_CUSTOMER_DATA_BY_SEARCH +
          "?searchTerm=" +
          searchTerm
      );
      return response.data;
    } catch (e) {
      return null;
    }
  },
  fetchOrderAnalyticsData: async function (userId, page) {
    try {
      const response = await axios.get(
        config.APIHOST_FOR_SEARCH_CUSTOMER +
          config.FETCH_CUSTOMER_ANALYTICS_ORDER +
          "?userId=" +
          userId +
          "&page=" +
          page
      );

      return response.data;
    } catch (e) {
      return false;
    }
  },
  fetchProductAnalyticsData: async function (userId, page) {
    try {
      const response = await axios.get(
        config.APIHOST_FOR_SEARCH_CUSTOMER +
          config.FETCH_CUSTOMER_ANALYTICS_PRODUCT +
          "?userId=" +
          userId +
          "&page=" +
          page
      );

      return response.data;
    } catch (e) {
      if (e.response.data && e.response.data.code === 2011) {
        return e.response.data;
      }
      return false;
    }
  },
  saveAddAddress: async function (payload) {
    try {
      const response = await axios.post(
        config.APIHOST_FOR_SEARCH_CUSTOMER + config.ADD_ADDRESS_CUSTOMER,
        payload
      );
      return response.data;
    } catch (e) {
      return null;
    }
  },
  fetchOrdersByUserId: async function (userData, userId, pageNo) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST_2 +
          config.FETCH_ORDERS_BY_USERID +
          "?userId=" +
          userId +
          "&pageNo=" +
          pageNo,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
};
export default AuthService;
