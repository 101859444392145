import React from "react"
import UserStore from "../../stores/userStore";
import InventoryService from "../../services/inventoryService";
import {toast} from "react-toastify";
import utility from "../../utility/utility";
import SimpleBackdrop from "../common/overlay";
import {withRouter} from "react-router-dom"
import {
    MDBBtn,
    MDBInput, MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader,
    MDBSelect,
    MDBSelectInput,
    MDBSelectOption,
    MDBSelectOptions,
    MDBTable, MDBTableBody,
    MDBTableHead
} from "mdbreact";
import ProductSpecifications from "./ProductSpecifications";
import MultiPicDirectUpload from "../common/fileUpload/multiPicDirectUpload";
import ProductDetailsPreview from "./productDetailsPreview";

class EditProductNew extends React.Component {
    async componentDidMount() {
        window.scrollTo(0, 0)
        let userData = await UserStore.fetchSessionData();
        const {id} = this.props.match.params;
        let productId = ""
        if (this.props.hasOwnProperty("productId")) {
            productId = this.props.productId
        } else {
            productId = id
        }
        await this.setState({id: productId, userData});
        this.setState({progressStatus: true})
        await this.fetchData()
        this.setState({progressStatus: false})
    }

    constructor(props) {
        super(props);
        this.state = {
            progressStatus: false,
            productName: "",
            userData: {},
            suggestedData: [],
            manufacturerList: [], selectedMfg: [],
            brandName: "",
            modalPreview: false,

            packagingQnty: 1,
            retailPackagingList: [], selectedRetailPackaging: "",
            packagingUnitList: [], selectedPackagingUnit: "",
            isLooseSaleAllowed: false,
            minimumLooseSaleQnty: 1,

            foodHabit: "None",
            toyFeatureList: [], selectedToyFeature: [],
            toyCollectionList: [], selectedToyCollection: [],
            toyScentList: [], selectedToyScent: [],
            productUses: "", description: "", howToUse: "",
            maximumQuantity: "", minimumQuantity: "", reorderQuantity: "",
            discount: 0, isTopSelling: "N", overrideMrp: 0, markAsOutOfStock: "N",
            inPersonDelivery: false, inPersonDeliveryInstructions: "",
            ship: false, shippingInstructions: "",
            pickup: false, pickupInstructions: "",
            uploadedFiles: [],
            productCategoryList: [], selectedCategory: [],
            productMaterialList: [], selectedProductMaterial: [],
            lifeStageList: [], selectedLifeStage: [],
            breedSizeList: [], selectedBreedSize: [],
            healthBenefitList: [], selectedHealthBenefit: [],
            sideEffectList: [], selectedSideEffect: [],
            productFormList: [], selectedProductForm: [],
            productFlavorList: [], selectedProductFlavor: [],
            productTextureList: [], selectedProductTexture: [],
            productColorList: [], selectedProductColor: [],
            productIngredientList: [], keyIngredient: 0,
            ingredientList: [], guaranteedAnaList: [], keyGAT: 0,
            specialDietList: [], selectedSpecialDiet: [],
            tags: [], tagList: [], tagEdited: false,
            highlight: [], highlightList: [], highlightEdited: false,
            productTypeList: [], selectedProductType: "",
            medicineTypeList: [], selectedMedicineType: [],
            id: "",
            productDetails: [],
            isOutOfStockList: [{text: "Yes", value: "Y"}, {text: "No", value: "N"}],
            isTopSellingList: [{text: "Yes", value: "Y"}, {text: "No", value: "N"}],
        }
    }
    getProductUses = (productUses) => {
        if (Array.isArray(productUses) === true) {
            if (productUses.length > 0) {
                return productUses[0]
            } else {
                return ""
            }
        } else {
            return productUses
        }
    }
    fetchData = async () => {
        if (this.state.id !== "") {
            let data = await InventoryService.fetchProductById(this.state.userData, this.state.id)
            if (data) {
                await this.setState({
                    productName: data.productName,
                    selectedMfg: data.hasOwnProperty("manufacturer") && data.manufacturer.length > 0 ? [data.manufacturer[0]._id] : [],
                    selectedCategory: data.hasOwnProperty("categories") ? this.getCategoryArray(data.categories) : [],
                    selectedSpecialDiet: data.hasOwnProperty("specialDiet") ? data.specialDiet : [], //TODO
                    selectedRetailPackaging: data.hasOwnProperty("retailPackage") && data.retailPackage !== "" ? [data.retailPackage] : [],
                    selectedPackagingUnit: data.hasOwnProperty("quantityUnit") ? data.quantityUnit : "",
                    selectedProductForm: data.hasOwnProperty("productForm") ? data.productForm : [],
                    selectedProductFlavor: data.hasOwnProperty("flavor") ? data.flavor : [],
                    selectedProductTexture: data.hasOwnProperty("texture") ? data.texture : [],
                    selectedProductColor: data.hasOwnProperty("color") ? data.color : [],
                    selectedLifeStage: data.hasOwnProperty("lifeStage") ? data.lifeStage : [],
                    selectedBreedSize: data.hasOwnProperty("breedSize") ? data.breedSize : [],
                    selectedHealthBenefit: data.hasOwnProperty("benefits") ? data.benefits : [],
                    selectedSideEffect: data.hasOwnProperty("productSideEffects") ? data.productSideEffects : [],
                    selectedProductType: data.hasOwnProperty("productType") ? data.productType : "",
                    selectedMedicineType: data.hasOwnProperty("medicineType") ? data.medicineType : [],
                    isLooseSaleAllowed: data.looseSaleAllowed === "Y" ? true : false,
                    packagingQnty: data.hasOwnProperty("contentQuantity") ? data.contentQuantity : "",
                    minimumLooseSaleQnty: (data.hasOwnProperty("minimumLooseSaleQuantity") && data.looseSaleAllowed === "Y") ? parseInt(data.minimumLooseSaleQuantity) : 1,
                    howToUse: data.hasOwnProperty("howTouse") ? data.howTouse : "",
                    description: data.hasOwnProperty("description") ? data.description : "",
                    maximumQuantity: data.hasOwnProperty("maxQuantity") ? data.maxQuantity : "",
                    minimumQuantity: data.hasOwnProperty("minQuantity") ? data.minQuantity : "",
                    reorderQuantity: data.hasOwnProperty("reorderThreshold") ? data.reorderThreshold : "",
                    productUses: data.hasOwnProperty("productUses") ? this.getProductUses(data.productUses) : "",
                    discount: data.hasOwnProperty("discount") ? data.discount : 0,
                    isTopSelling: data.hasOwnProperty("isTopSelling") ? data.isTopSelling : "N",
                    overrideMrp: data.hasOwnProperty("overideMrp") ? data.overideMrp : 0,
                    markAsOutOfStock: data.hasOwnProperty("markAsoutOfStock") ? data.markAsoutOfStock : "N",
                    foodHabit: data.hasOwnProperty("foodHabit") ? data.foodHabit : "None",
                    tags: data.hasOwnProperty("tags") ? data.tags : [],
                    tagList: data.hasOwnProperty("tags") ? this.prepareList(data.tags) : [],
                    highlight: data.hasOwnProperty("highlight") ? data.highlight : [],
                    highlightList: data.hasOwnProperty("highlight") ? this.prepareList(data.highlight) : [],
                    uploadedFiles: (data.hasOwnProperty("imageUrl") && data.imageUrl !== null && data.imageUrl.length > 0) ? data.imageUrl : [],
                    brandName: data.hasOwnProperty("brand") ? data.brand : "",
                    productDetails: data,
                })
                //the following code is to avoid an MDBSelect error
                let isOutOfStockList =  this.setYesNo(this.state.isOutOfStockList, this.state.markAsOutOfStock)
                let isTopSellingList =  this.setYesNo(this.state.isTopSellingList, this.state.isTopSelling)
                this.setState({isOutOfStockList, isTopSellingList})

                if (data.hasOwnProperty("shippingDetails")) {
                    this.setState({
                        inPersonDelivery: (data.shippingDetails.hasOwnProperty("inPerson") && data.shippingDetails.inPerson === "Y") ? true : false,
                        inPersonDeliveryInstructions: data.shippingDetails.hasOwnProperty("inPersonInstruction") ? data.shippingDetails.inPersonInstruction : "",
                        ship: (data.shippingDetails.hasOwnProperty("ship") && data.shippingDetails.ship) === "Y" ? true : false,
                        shippingInstructions: data.shippingDetails.hasOwnProperty("shippingInstruction") ? data.shippingDetails.shippingInstruction : "",
                        pickup: (data.shippingDetails.hasOwnProperty("pickUp") && data.shippingDetails.pickUp === "Y") ? true : false,
                        pickupInstructions: data.shippingDetails.hasOwnProperty("pickupInstruction") ? data.shippingDetails.pickupInstruction : "",
                    })
                }
                await this.getGAList()
                await this.getIngredientList()
                await this.getList("manufacturerList", "FETCH_MFG", "manufacturer", "manufacturer", "", data.manufacturerId)
                await this.getList("productCategoryList", "FETCH_CATEGORY", "categoryName", "category", "", data.categories)
                await this.getList("specialDietList", "FETCH_SPECIAL_DIET", "name", "special diet", "", data.hasOwnProperty("specialDiet") === true ? data.specialDiet : "")
                await this.getList("retailPackagingList", "FETCH_RETAIL_PKG", "retailPackage", "retail packaging", "", data.hasOwnProperty("retailPackage") === true ? data.retailPackage : "")
                await this.getList("packagingUnitList", "FETCH_UNIT", "quantityUnit", "quantity unit", "", data.hasOwnProperty("quantityUnit") === true ? data.quantityUnit : "")
                await this.getList("productFormList", "FETCH_PRODUCT_FORM", "name", "product form", "", data.hasOwnProperty("productForm") === true ? data.productForm : "")
                await this.getList("productFlavorList", "FETCH_FLAVOR", "name", "product flavor", "", data.hasOwnProperty("flavor") === true ? data.flavor : "")
                await this.getList("productTextureList", "FETCH_TEXTURE", "name", "product texture", "", data.hasOwnProperty("texture") === true ? data.texture : "")
                await this.getList("productColorList", "FETCH_COLOR", "name", "product color", "", data.hasOwnProperty("color") === true ? data.color : "")
                await this.getList("lifeStageList", "FETCH_LIFE_STAGE", "name", "life stage", "", data.hasOwnProperty("lifeStage") === true ? data.lifeStage : "")
                await this.getList("breedSizeList", "FETCH_BREED_SIZE", "name", "breed size", "", data.hasOwnProperty("breedSize") === true ? data.breedSize : "")
                await this.getList("healthBenefitList", "FETCH_HEALTH_BENEFITS", "name", "health benefit", "", data.hasOwnProperty("benefits") === true ? data.benefits : "")
                await this.getList("sideEffectList", "FETCH_SIDE_EFFECTS", "name", "side effect", "", data.hasOwnProperty("productSideEffects") === true ? data.productSideEffects : "")
                await this.getList("productMaterialList", "FETCH_MATERIAL", "name", "product material", "", data.hasOwnProperty("materials") === true ? data.materials : "")
                await this.getList("medicineTypeList", "FETCH_MEDICINE_SCHEDULE", "typeName", "medicine type", "typeDescription", data.hasOwnProperty("medicineType") === true ? data.medicineType : "")
                await this.getList("toyFeatureList", "FETCH_TOY_FEATURE", "name", "toy feature", "", data.hasOwnProperty("toyFeature") === true ? data.toyFeature : "")
                await this.getList("toyCollectionList", "FETCH_TOY_COLLECTION", "name", "toy collection", "", data.hasOwnProperty("toyCollection") === true ? data.toyCollection : "")
                await this.getList("toyScentList", "FETCH_PRODUCT_SCENT", "name", "toy scent", "", data.hasOwnProperty("toyScent") === true ? data.toyScent : "")
                await this.getProductTypeList();
            } else {
                toast.error("Something went wrong while fetching the product data.")
            }
        }
    }
    setYesNo = (list, value) => {
        for (let item of list) {
            if (item.value === value) {
                item.checked = true
            }
        }
        return list
    }
    prepareList = (list) => {
        let tempList = []
        for (let item of list) {
            tempList.push({value: item})
        }
        return tempList
    }
    getProductTypeList = async () => {
        if (!window.productTypeList || window.productTypeList === null) {
            let productTypeList = await InventoryService.fetchGetData(this.state.userData.sessionToken, "FETCH_PRODUCT_TYPE")
            if (productTypeList) {
                this.setState({productTypeList})
                window.productTypeList = productTypeList
            } else {
                toast.error("Something went wrong while fetching the product type list.")
            }
        } else {
            this.setState({productTypeList: window.productTypeList})
        }
    }
    getCategoryArray = (categoryList) => {
        let temp = []
        for (let item of categoryList) {
            temp.push(item._id + "_" + item.categoryName)
        }
        return temp
    }
    toggle = item => {
        this.setState({["modal" + item]: !this.state["modal" + item]})
    }
    getMinimumLooseSaleQnty = (val) => {
        if (val !== "" && !utility.isInt(val)) {
            return false
        }
        this.setState({minimumLooseSaleQnty: val})
    }
    resetKeyGAT = () => {
        let randomString = Math.random().toString(36);
        this.setState({keyGAT: randomString});
    }
    getMaximumQuantity = (val) => {
        if (val !== "" && !utility.isInt(val)) {
            return false
        }
        this.setState({maximumQuantity: val})
    }
    getMinimumQuantity = (val) => {
        if (val !== "" && !utility.isInt(val)) {
            return false
        }
        this.setState({minimumQuantity: val})
    }
    getReorderQuantity = (val) => {
        if (val !== "" && !utility.isInt(val)) {
            return false
        }
        this.setState({reorderQuantity: val})
    }
    getDiscount = (val) => {
        if (val !== "" && !utility.isInt(val)) {
            return false
        }
        this.setState({discount: val})
    }
    getUploadedFiles = async (files) => {
        if (files.length === 0) {
            await this.setState({uploadedFiles: []})
        } else {
            await this.setState({uploadedFiles: files})
        }
    }

    getPreparedList = (dataList, fieldName, secondaryFieldName, value) => {
        let tempList = []
        //secondary field is only for medicineType, it's a string
        if (secondaryFieldName !== "") {
            for (let item of dataList) {
                if (item[fieldName] === value) {
                    tempList.push({
                        text: item[fieldName] + " ( " + item[secondaryFieldName] + " ) ",
                        value: item[fieldName],
                        checked: true
                    })
                } else {
                    tempList.push({
                        text: item[fieldName] + " ( " + item[secondaryFieldName] + " ) ", value: item[fieldName]
                    })
                }
            }
        } else {
            //If "value" contains an array
            if (value !== undefined && Array.isArray(value) === true) {
                for (let item of dataList) {
                    if (value.indexOf(item[fieldName]) > -1) {
                        tempList.push({text: item[fieldName], value: item[fieldName], checked: true})
                    } else {
                        tempList.push({text: item[fieldName], value: item[fieldName]})
                    }
                }
            } else {
                //If 'value' is a string
                for (let item of dataList) {
                    if (item[fieldName] === value) {
                        tempList.push({text: item[fieldName], value: item[fieldName], checked: true})
                    } else {
                        tempList.push({text: item[fieldName], value: item[fieldName]})
                    }
                }
            }
        }
        return tempList
    }
    getCategoryManufacturerList = (dataList, fieldName, value) => {
        let tempList = []
        //for category
        //categories contains an array with detailed data, so looping through value
        if (fieldName === "categoryName") {
            for (let item of dataList) {
                tempList.push({text: item[fieldName], value: item._id + "_" + item[fieldName]})
            }
            for (let item1 of value) {
                for (let i of tempList) {
                    if (i.text === item1.categoryName) {
                        i.checked = true
                    }
                }
            }
        } else {
            //for manufacturer
            //manufacturer contains single value
            for (let item of dataList) {
                if (item._id === value) {
                    tempList.push({text: item[fieldName], value: item._id, checked: true})
                } else {
                    tempList.push({text: item[fieldName], value: item._id})
                }
            }
        }
        return tempList
    }
    getList = async (listName, apiName, fieldName, section, secondaryFieldName, value) => {
        let rawList = [], preparedList = []
        if (!window[listName] || window[listName] === null) {
            let data = await InventoryService.fetchGetData(this.state.userData.sessionToken, apiName)
            if (data) {
                rawList = data
                window[listName] = data
            } else {
                toast.error("Something went wrong while getting the " + section + " list.")
            }
        } else {
            rawList = window[listName]
        }
        if (rawList.length > 0) {
            if (listName === "productCategoryList" || listName === "manufacturerList") {
                preparedList = this.getCategoryManufacturerList(rawList, fieldName, value)
            } else {
                preparedList = this.getPreparedList(rawList, fieldName, secondaryFieldName, value)
            }
        }
        this.setState({[listName]: preparedList})
    }
    getGAList = async () => {
        let rawList = [], tempList = []
        if (!window.guaranteedAnaList || window.guaranteedAnaList === null) {
            let data = await InventoryService.fetchGetData(this.state.userData.sessionToken, "FETCH_GUARANTEED_ANALYSIS")
            if (data) {
                rawList = data
                window.guaranteedAnaList = data
            } else {
                toast.error("Something went wrong while fetching the guaranteed analysis.")
            }
        } else {
            rawList = window.guaranteedAnaList
        }
        if (rawList.length > 0) {
            for (let i = 0; i < rawList.length; i++) {
                tempList.push({
                    text: rawList[i].name,
                    value: i + "_" + rawList[i].name,
                    unit: "",
                    qnty: 0,
                    selected: false
                })
            }
            //to populate the guaranteed analysis
            if (this.state.productDetails.hasOwnProperty("guaranteedAnalysis")) {
                for (let l = 0; l < this.state.productDetails.guaranteedAnalysis.length; l++) {
                    for (let i = 0; i < tempList.length; i++) {
                        if (this.state.productDetails.guaranteedAnalysis[l].name === tempList[i].text) {
                            tempList[i].displayIndex = l
                            tempList[i].selected = true
                            tempList[i].qnty = this.state.productDetails.guaranteedAnalysis[l].quantity
                            tempList[i].unit = this.state.productDetails.guaranteedAnalysis[l].unit
                            tempList[i].checked = true
                        }
                    }
                }
            }
        }
        this.setState({guaranteedAnaList: tempList})
    }
    setGuaranteedAna = (val) => {
        let guaranteedAnaList = this.state.guaranteedAnaList
        for (let item of guaranteedAnaList) {
            item.selected = false
        }
        for (let i = 0; i < val.length; i++) {
            let arrayWithData = val[i].split("_")
            let index = arrayWithData[0]
            guaranteedAnaList[index].selected = true
            guaranteedAnaList[index].displayIndex = i
        }
        for (let item of guaranteedAnaList) {
            if (item.selected === false) {
                item.unit = ""
                item.qnty = 0
            }
        }
        this.setState({guaranteedAnaList})
        this.resetKeyGAT()
    }
    handleGAQnty = (index) => e => {
        let guaranteedAnaList = this.state.guaranteedAnaList
        guaranteedAnaList[index].qnty = e.target.value
        this.setState({guaranteedAnaList})
    }
    handleGAUnit = (index) => e => {
        let guaranteedAnaList = this.state.guaranteedAnaList
        guaranteedAnaList[index].unit = e[0]
        this.setState({guaranteedAnaList})
    }
    getIngredientList = async () => {
        let rawList = [], tempList = []
        if (!window.productIngredientList || window.productIngredientList === null) {
            let data = await InventoryService.fetchGetData(this.state.userData.sessionToken, "FETCH_INGREDIENT")
            if (data) {
                rawList = data
                window.productIngredientList = data
            } else {
                toast.error("Something went wrong while fetching the ingredient list.")
            }
        } else {
            rawList = window.productIngredientList
        }
        if (rawList.length > 0) {
            for (let i = 0; i < rawList.length; i++) {
                tempList.push({
                    text: rawList[i].name,
                    value: i + "_" + rawList[i].name,
                    unit: "",
                    qnty: 0,
                    selected: false
                })
            }
            //If ingredient list is available, populate the list
            if (this.state.productDetails.hasOwnProperty("productIngredients")) {
                for (let l = 0; l < this.state.productDetails.productIngredients.length; l++) {
                    for (let i = 0; i < tempList.length; i++) {
                        if (this.state.productDetails.productIngredients[l].ingredient === tempList[i].text) {
                            tempList[i].displayIndex = l
                            tempList[i].selected = true
                            tempList[i].qnty = this.state.productDetails.productIngredients[l].quantity
                            tempList[i].unit = this.state.productDetails.productIngredients[l].unit
                            tempList[i].checked = true
                        }
                    }
                }
            }
            this.setState({productIngredientList: tempList})
        }
    }
    setIngredients = (val) => {
        let productIngredientList = this.state.productIngredientList
        for (let item of productIngredientList) {
            item.selected = false
        }
        for (let i = 0; i < val.length; i++) {
            let arrayWithData = val[i].split("_")
            let index = arrayWithData[0]
            productIngredientList[index].selected = true
            productIngredientList[index].displayIndex = i
        }
        for (let item of productIngredientList) {
            if (item.selected === false) {
                item.unit = ""
                item.qnty = 0
            }
        }
        this.setState({productIngredientList})
        this.resetKeyIngredient()
    }
    handleIngredientQnty = (index) => e => {
        let productIngredientList = this.state.productIngredientList
        productIngredientList[index].qnty = e.target.value
        this.setState({productIngredientList})
    }
    handleIngredientUnit = (index) => e => {
        let productIngredientList = this.state.productIngredientList
        productIngredientList[index].unit = e[0]
        this.setState({productIngredientList})
    }
    resetKeyIngredient = () => {
        let randomString = Math.random().toString(36);
        this.setState({keyIngredient: randomString});
    }
    editProduct = async () => {
        if (this.state.productName === "") {
            toast.error('Please enter "Product Name".')
            return false
        }
        if (this.state.selectedMfg.length === 0) {
            toast.error('Please select "Manufacturer"')
            return false
        }
        let selectedCategory = []
        if (this.state.selectedCategory.length === 0) {
            toast.error('Please select "Category"')
            return false
        } else {
            for (let item of this.state.selectedCategory) {
                let temp = item.split("_")
                selectedCategory.push({_id: temp[0], name: temp[1]})
            }
        }
        if (this.state.selectedRetailPackaging.length === 0) {
            toast.error('Please select "Retail Packaging"')
            return false
        }
        if (this.state.packagingQnty.length === 0) {
            toast.error('Please enter "Packaging Quantity"')
            return false
        }
        if (this.state.selectedPackagingUnit.length === 0) {
            toast.error('Please select "Packaging Unit"')
            return false
        }
        if (this.state.isLooseSaleAllowed === true && ((this.state.minimumLooseSaleQnty === 0 || this.state.minimumLooseSaleQnty === "") || (this.state.packagingQnty < this.state.minimumLooseSaleQnty))) {
            toast.error('Please enter a valid "Minimum Loose Sale Quantity"')
            return false
        }
        if (this.state.selectedLifeStage.length === 0) {
            toast.error('Please select "Life stage"')
            return false
        }
        if (this.state.productUses.trim() === "" || this.state.productUses.trim().length < 100) {
            toast.error('Please enter minimum 100 characters in "Product Uses"')
            return false
        }
        if (this.state.selectedHealthBenefit.length === 0) {
            toast.error('Please select "Health Benefits"')
            return false
        }
        if (this.state.description.trim() === "" || this.state.description.trim().length < 100) {
            toast.error('Please enter minimum 100 characters in "Product Description"')
            return false
        }
        if (this.state.howToUse.trim() === "" || this.state.howToUse.trim().length < 5) {
            toast.error('Please enter minimum 5 characters in "How To Use"')
            return false
        }
        if (this.state.maximumQuantity === "") {
            toast.error('Please enter "Maximum Quantity" in "Reorder Config" section')
            return false
        }
        if (this.state.minimumQuantity === "") {
            toast.error('Please enter "Minimum Quantity" in "Reorder Config" section')
            return false
        }
        if (this.state.reorderQuantity === "") {
            toast.error('Please enter "Reorder Threshold" in "Reorder Config" section')
            return false
        }
        if (this.state.selectedProductType === "") {
            toast.error('Please select "Product Type"')
            return false
        }
        if (this.state.selectedProductType === "Medicine" && this.state.selectedMedicineType.length === 0) {
            toast.error('Please select "Medicine Type"')
            return false
        }
        if ((this.state.selectedProductType === "Toy" || this.state.selectedProductType === "Supplies" || this.state.selectedProductType === "Accessories" || this.state.selectedProductType === "Device") && this.state.selectedProductMaterial.length === 0) {
            toast.error('Please select "Product Material"')
            return false
        }
        if (this.state.selectedProductType === "Toy" && this.state.selectedToyFeature.length === 0) {
            toast.error('Please select "Toy Feature"')
            return false
        }
        if (this.state.selectedProductType === "Toy" && this.state.selectedToyCollection.length === 0) {
            toast.error('Please select "Toy Collection"')
            return
        }
        if (this.state.selectedProductType === "Toy" && this.state.selectedToyScent.length === 0) {
            toast.error('Please select "Toy Scent"')
            return false
        }
        let tags = (this.state.tagEdited === true) ? utility.prepareArray(this.state.tagList) : this.state.tags
        let highlights = (this.state.highlightEdited === true) ? utility.prepareArray(this.state.highlightList) : this.state.highlight
        if (highlights.length === 0) {
            toast.error('Please enter "Highlights"')
            return false
        }
        //collect Guaranteed Analysis
        let addedGA = []
        for (let item of this.state.guaranteedAnaList) {
            if (item.selected === true) {
                if (item.qnty > 0 && item.unit !== "") {
                    addedGA.push({name: item.text, quantity: item.qnty, unit: item.unit})
                } else {
                    toast.error("Please enter quantity and unit for the respective Guaranteed Analysis in the list.")
                    return false
                }
            }
        }
        //collect ingredients
        let addedIngredients = []
        for (let item of this.state.productIngredientList) {
            if (item.selected === true) {
                if (this.state.selectedProductType === "Food" || this.state.selectedProductType === "Medicine") {
                    if (item.qnty > 0 && item.unit !== "") {
                        addedIngredients.push({ingredient: item.text, quantity: item.qnty, unit: item.unit})
                    } else {
                        toast.error("Please enter quantity and unit for the respective Ingredient in the list.")
                        return false
                    }
                } else {
                    addedIngredients.push({ingredient: item.text, quantity: item.qnty === "" ? 0 : item.qnty, unit: item.unit})
                }
            }
        }
        if ((this.state.selectedProductType === "Food" || this.state.selectedProductType === "Medicine") && addedIngredients.length === 0) {
            toast.error('Please select ingredients and enter quantity in "Ingredients List With Quantity"')
            return false
        }
        if (this.state.uploadedFiles.length === 0) {
            toast.error('Please add at least one product image.')
            return false
        }
        let payload = {
            productName: this.state.productName,
            mfgId: this.state.selectedMfg[0],
            brand: this.state.brandName,
            categories: selectedCategory,
            specialDiet: this.state.selectedSpecialDiet,
            retailPackage: this.state.selectedRetailPackaging[0],
            contentQuantity: parseFloat(this.state.packagingQnty, 2),
            quantityUnit: this.state.selectedPackagingUnit[0],
            looseSaleAllowed: this.state.isLooseSaleAllowed === true ? "Y" : "N",
            minimumLooseSaleQuantity: parseInt(this.state.minimumLooseSaleQnty),
            productForm: this.state.selectedProductForm.length > 0 ? this.state.selectedProductForm[0] : [],
            flavor: this.state.selectedProductFlavor,
            texture: this.state.selectedProductTexture,
            color: this.state.selectedProductColor,
            lifeStage: this.state.selectedLifeStage,
            breedSize: this.state.selectedBreedSize,
            productUses: this.state.productUses,
            benefits: this.state.selectedHealthBenefit,
            productSideEffects: this.state.selectedSideEffect,
            description: this.state.description,
            howTouse: this.state.howToUse,
            maxQuantity: parseInt(this.state.maximumQuantity),
            minQuantity: parseInt(this.state.minimumQuantity),
            reorderThreshold: parseInt(this.state.reorderQuantity),
            shippingDetails: {
                inPerson: this.state.inPersonDelivery === true ? "Y" : "N",
                ship: this.state.ship === true ? "Y" : "N",
                pickUp: this.state.pickup === true ? "Y" : "N",
                inPersonInstruction: this.state.inPersonDeliveryInstructions,
                shippingInstruction: this.state.shippingInstructions,
                pickupInstruction: this.state.pickupInstructions,
            },
            discount: parseInt(this.state.discount),
            isTopSelling: this.state.isTopSelling[0],
            overideMrp: parseFloat(this.state.overrideMrp, 2),
            markAsoutOfStock: this.state.markAsOutOfStock[0],
            productType: this.state.selectedProductType,
            medicineType: (this.state.selectedProductType !== "Medicine") ? "" : this.state.selectedMedicineType[0],
            materials: this.state.selectedProductMaterial,
            toyFeature: this.state.selectedToyFeature,
            toyCollection: this.state.selectedToyCollection,
            toyScent: this.state.selectedToyScent,
            foodHabit: this.state.foodHabit,
            tags: tags,
            highlights: highlights,
            guaranteedAnalysis: addedGA,
            ingredient: addedIngredients,
            imageUrl: this.state.uploadedFiles,
            masterProductId: this.state.productDetails.hasOwnProperty("masterProductId") && this.state.productDetails.masterProductId !== "" ? this.state.productDetails.masterProductId : "",
            _id: this.state.id
        }
        this.setState({progressStatus: true})
        let data = await InventoryService.editProductV3(this.state.userData.sessionToken, payload)
        this.setState({progressStatus: false})
        if (data) {
            toast.success("The product is updated successfully.")
            if (this.props.hasOwnProperty("productId")) {
                this.props.onEditComplete()
            } else {
                this.props.history.push('/view-products-new');
            }
        } else {
            toast.error("Something went wrong while updating the product.")
        }
    }
    getTagListCallback = (list) => {
        this.setState({tagList: list, tagEdited: true})
    }
    getHighlightListCallback = (list) => {
        this.setState({highlightList: list, highlightEdited: true})
    }
    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.progressStatus}/>

                <div className={'col-md-11 mx-auto white rounded-sm border p-0'}>
                    <div
                        className={'col-12 p-2 grey lighten-4 border-bottom d-flex justify-content-between align-items-center'}>
                        <b className={'title'}>Edit Product</b>
                    </div>
                    <div className={"col-12 row m-0 p-0"}>
                        <div className={"col-4 p-2"}>
                            <MDBInput id={"add_product_name_se"} background size="sm" label={'Product Name'}
                                      value={this.state.productName}
                                      getValue={(val) => this.setState({productName: val})}/>
                        </div>
                        <div className={"col-4 p-2"}>
                            <b>Manufacturer</b><br/>
                            <MDBSelect search selected="Select Manufacturer"
                                       options={this.state.manufacturerList}
                                       getValue={(val) => {
                                           this.setState({selectedMfg: val})
                                       }}
                                       className={'col grey-text p-0 font-weight-bold'}
                            />
                        </div>
                        <div className={"col-4 p-2"}>
                            <MDBInput background size="sm" label={'Brand'} value={this.state.brandName} getValue={(val) => {
                                this.setState({brandName: val})
                            }}/>
                        </div>
                    </div>
                    <div className={"col-12 row m-0 p-0"}>
                        <div className={"col p-2"}>
                            <b>Category</b><br/>
                            <MDBSelect search selected="Select Category"
                                       options={this.state.productCategoryList}
                                       getValue={(val) => {
                                           this.setState({selectedCategory: val})
                                       }}
                                       className={' col grey-text p-0 font-weight-bold'}
                                       multiple/>
                        </div>
                        <div className={"col p-2"}>
                            <b>Special Diet</b><br/>
                            <MDBSelect search selected="Select Special Diet"
                                       options={this.state.specialDietList}
                                       getValue={(val) => {
                                           this.setState({selectedSpecialDiet: val})
                                       }}
                                       className={' col grey-text p-0 font-weight-bold'}
                                       multiple/>
                        </div>
                    </div>
                </div>
                <div className={'col-md-11 mx-auto white rounded-sm border p-0 mt-2'}>
                    <div
                        className={'col-12 p-2 grey lighten-4 border-bottom d-flex justify-content-between align-items-center'}>
                        <b className={"font-weight-bold"}>Packaging Details</b>
                        <div className={'row mx-0'}>
                            <a role="button" onClick={() => this.toggle("Preview")}
                               className={"text-primary"}>PREVIEW</a>
                        </div>
                    </div>
                    <div className={"col-12 row m-0 p-0"}>
                        <div className={"col p-2"}>
                            <b>Retail Packaging</b><br/>
                            <MDBSelect search selected="Select Retail Packaging"
                                       options={this.state.retailPackagingList}
                                       getValue={(val) => {
                                           this.setState({selectedRetailPackaging: val})
                                       }} className={' col grey-text p-0 font-weight-bold'}
                            />
                        </div>
                        <div className={"col p-2"}>
                            <MDBInput background value={this.state.packagingQnty} type={"number"} min={0} label={'Packaging Quantity'}
                                      getValue={(val) => {this.setState({packagingQnty: val})}}
                                      size="sm"/>
                        </div>
                        <div className={"col p-2"}>
                            <b>Packaging Unit</b><br/>
                            <MDBSelect search selected="Select Packaging Unit"
                                       options={this.state.packagingUnitList}
                                       getValue={(val) => {
                                           this.setState({selectedPackagingUnit: val})
                                       }} className={' col grey-text p-0 font-weight-bold'}
                            />
                        </div>
                        <div className={"col p-2 font-weight-bold mt-4"}>
                            <MDBInput label="Loose Sale Allowed" type="checkbox" id="checkbox_loose"
                                      checked={this.state.isLooseSaleAllowed} getValue={(val) => {
                                this.setState({isLooseSaleAllowed: val})
                            }}/>
                        </div>
                        {this.state.isLooseSaleAllowed &&
                        <div className={"col p-2"}>
                            <MDBInput label='Minimum Loose Sale Quantity' background value={this.state.minimumLooseSaleQnty}
                                      getValue={this.getMinimumLooseSaleQnty}/>
                        </div>
                        }
                    </div>
                </div>
                <div className={'col-md-11 mx-auto white rounded-sm border p-0 mt-2'}>
                    <div
                        className={'col-12 p-2 grey lighten-4 border-bottom d-flex justify-content-between align-items-center'}>
                        <b className={"font-weight-bold"}>Product Form</b>
                        <div className={'row mx-0'}>
                            <a role="button" onClick={() => this.toggle("Preview")}
                               className={"text-primary"}>PREVIEW</a>
                        </div>
                    </div>
                    <div className={"col-12 row m-0 p-0"}>
                        <div className={"col-3 p-2"}>
                            <b>Form</b><br/>
                            <MDBSelect search selected="Select Product Form"
                                       options={this.state.productFormList}
                                       getValue={(val) => {
                                           this.setState({selectedProductForm: val})
                                       }}
                                       className={' col grey-text p-0 font-weight-bold'}
                            />
                        </div>
                        <div className={"col-3 p-2"}>
                            <b>Flavor</b><br/>
                            <MDBSelect search selected="Select Product Flavor"
                                       options={this.state.productFlavorList}
                                       getValue={(val) => {
                                           this.setState({selectedProductFlavor: val})
                                       }}
                                       className={' col grey-text p-0 font-weight-bold'}
                                       multiple
                            />
                        </div>
                        <div className={"col-3 p-2"}>
                            <b>Texture</b><br/>
                            <MDBSelect search selected="Select Product Texture"
                                       options={this.state.productTextureList}
                                       getValue={(val) => {
                                           this.setState({selectedProductTexture: val})
                                       }}
                                       className={' col grey-text p-0 font-weight-bold'}
                                       multiple
                            />
                        </div>
                        <div className={"col-3 p-2"}>
                            <b>Color</b><br/>
                            <MDBSelect search selected="Select Product Color"
                                       options={this.state.productColorList}
                                       getValue={(val) => {
                                           this.setState({selectedProductColor: val})
                                       }}
                                       className={' col grey-text p-0 font-weight-bold'}
                                       multiple
                            />
                        </div>
                    </div>
                </div>
                <div className={'col-md-11 mx-auto white rounded-sm border p-0 mt-2'}>
                    <div
                        className={'col-12 p-2 grey lighten-4 border-bottom d-flex justify-content-between align-items-center'}>
                        <b className={"font-weight-bold"}>Other Details</b>
                        <div className={'row mx-0'}>
                            <a role="button" onClick={() => this.toggle("Preview")}
                               className={"text-primary"}>PREVIEW</a>
                        </div>
                    </div>
                    <div className={"col-12 row m-0 p-0"}>
                        <div className={"col p-2"}>
                            <b>Life Stage</b><br/>
                            <MDBSelect search selected="Select Life Stage"
                                       options={this.state.lifeStageList}
                                       getValue={(val) => {
                                           this.setState({selectedLifeStage: val})
                                       }}
                                       className={' col grey-text p-0 font-weight-bold'}
                                       multiple
                            />
                        </div>
                        <div className={"col p-2"}>
                            {/*<b>Breed Size (Only for pets)</b><br/>*/}
                            {/*<MDBSelect search selected="Select Breed Size"*/}
                            {/*           options={this.state.breedSizeList}*/}
                            {/*           getValue={(val) => {*/}
                            {/*               this.setState({selectedBreedSize: val})*/}
                            {/*           }}*/}
                            {/*           className={'col grey-text p-0 font-weight-bold'}*/}
                            {/*           multiple*/}
                            {/*/>*/}
                        </div>
                    </div>
                    <div className={"col-12 row m-0 p-0"}>
                        <div className={"col p-2"}>
                            <MDBInput type="textarea" background size="sm" label={'Uses'}
                                      rows="6" value={this.state.productUses}
                                      getValue={(val) => {
                                          this.setState({productUses: val});
                                      }}/>
                        </div>
                        <div className={"col p-2"}>
                            <div className={"row p-2 mt-n2"}>
                                <div className={"col-12 p-2"}>
                                    <b>Health Benefits</b><br/>
                                    <MDBSelect search selected="Select Health Benefits"
                                               options={this.state.healthBenefitList}
                                               getValue={(val) => {
                                                   this.setState({selectedHealthBenefit: val})
                                               }}
                                               className={' col grey-text p-0 font-weight-bold'}
                                               multiple
                                    /></div>
                                <div className={"col-12 p-2"}>
                                    <b>Side Effects</b><br/>
                                    <MDBSelect search selected="Select Side Effects"
                                               options={this.state.sideEffectList}
                                               getValue={(val) => {
                                                   this.setState({selectedSideEffect: val})
                                               }}
                                               className={' col grey-text p-0 font-weight-bold'}
                                               multiple
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"col-12 row m-0 p-0"}>
                        <div className={"col p-2"}>
                            <MDBInput type="textarea" background size="sm" label={'Description'}
                                      rows="5" value={this.state.description}
                                      getValue={(val) => {
                                          this.setState({description: val});
                                      }}/>
                        </div>
                        <div className={"col p-2"}>
                            <MDBInput type="textarea" background rows="5" size="sm" label={'How to Use'}
                                      value={this.state.howToUse} getValue={(val) => {
                                this.setState({howToUse: val})
                            }}/>
                        </div>
                    </div>
                </div>
                <div className={'col-md-11 mx-auto white rounded-sm border p-0 mt-2'}>
                    <div
                        className={'col-12 p-2 grey lighten-4 border-bottom d-flex justify-content-between align-items-center'}>
                        <b>Reorder Config</b>
                        <div className={'row mx-0'}>
                            <a role="button" onClick={() => this.toggle("Preview")}
                               className={"text-primary"}>PREVIEW</a>
                        </div>
                    </div>
                    <div className={"col-12 row m-0 p-0"}>
                        <div className={"col p-2"}>
                            <MDBInput background value={this.state.maximumQuantity} label={'Maximum Quantity'}
                                      getValue={this.getMaximumQuantity}
                                      size="sm"/>
                        </div>
                        <div className={"col p-2"}>
                            <MDBInput background value={this.state.minimumQuantity} label={'Minimum Quantity'}
                                      getValue={this.getMinimumQuantity}
                                      size="sm"/>
                        </div>
                        <div className={"col p-2"}>
                            <MDBInput background value={this.state.reorderQuantity} label={'Reorder Threshold'}
                                      getValue={this.getReorderQuantity}
                                      size="sm"/>
                        </div>
                    </div>
                </div>
                <div className={'col-md-11 mx-auto white rounded-sm border p-0 mt-2'}>
                    <div
                        className={'col-12 p-2 grey lighten-4 border-bottom d-flex justify-content-between align-items-center'}>
                        <b className={"font-weight-bold"}>Other Config</b>
                        <div className={'row mx-0'}>
                            <a role="button" onClick={() => this.toggle("Preview")}
                               className={"text-primary"}>PREVIEW</a>
                        </div>
                    </div>
                    <div className={"col-12 row m-0 p-0"}>
                        <div className={"col p-2"}>
                            <MDBInput label={'Discount (%) '} background value={this.state.discount} getValue={this.getDiscount} size="sm"/>
                        </div>
                        <div className={"col p-2"}>
                            <b>Is Top Selling</b><br/>
                            <MDBSelect selected={this.state.isTopSelling === "Y" ? "Yes" : "No"}
                                       options={this.state.isTopSellingList}
                                       getValue={(val) => {this.setState({isTopSelling: val})}}
                                       className={'col grey-text p-0 font-weight-bold'}
                            />
                        </div>
                        <div className={"col p-2"}>
                            <MDBInput label={'Override MRP'} background value={this.state.overrideMrp} type={"number"} min={0}
                                      getValue={(val) => {
                                          this.setState({overrideMrp: val})
                                      }}
                                      size="sm"/>
                        </div>
                        <div className={"col p-2"}>
                            <b>Mark As Out Of Stock</b><br/>
                            <MDBSelect selected={this.state.markAsOutOfStock === "Y" ? "Yes" : "No"}
                                options={this.state.isOutOfStockList}
                                getValue={(val) => {this.setState({markAsOutOfStock: val})}}
                                className={' col grey-text p-0 font-weight-bold'}
                            />
                        </div>
                    </div>
                </div>
                <div className={'col-md-11 mx-auto white rounded-sm border p-0 mt-2'}>
                    <div
                        className={'col-12 p-2 grey lighten-4 border-bottom d-flex justify-content-between align-items-center'}>
                        <b className={"font-weight-bold"}>Shipping Details</b>
                        <div className={'row mx-0'}>
                            <a role="button" onClick={() => this.toggle("Preview")}
                               className={"text-primary"}>PREVIEW</a>
                        </div>
                    </div>
                    <div className={"col-12 row m-0 p-0"}>
                        <div className={"col p-2 font-weight-bold mt-2"}>
                            <MDBInput label="In-Person Delivery" type="checkbox" id="checkbox_in_person_delivery"
                                      checked={this.state.inPersonDelivery} getValue={(val) => {
                                this.setState({inPersonDelivery: val})
                            }}/>
                        </div>
                        <div className={"col p-2 font-weight-bold mt-2"}>
                            <MDBInput label="Is Shippable ?" type="checkbox" id="checkbox_ship"
                                      checked={this.state.ship} getValue={(val) => {
                                this.setState({ship: val})
                            }}/>
                        </div>
                        <div className={"col p-2 font-weight-bold mt-2"}>
                            <MDBInput label="Pickup" type="checkbox" id="checkbox_pickup"
                                      checked={this.state.pickup} getValue={(val) => {
                                this.setState({pickup: val})
                            }}/>
                        </div>
                    </div>
                    <div className={"col-12 row m-0 p-0"}>
                        <div className={"col p-2"}>
                            <MDBInput label={'In-Person Delivery Instructions'} type="textarea" background size="sm"
                                      rows="5" value={this.state.inPersonDeliveryInstructions}
                                      getValue={(val) => {
                                          this.setState({inPersonDeliveryInstructions: val});
                                      }}/>
                        </div>
                        <div className={"col p-2"}>
                            <MDBInput label={'Shipping Instructions'} type="textarea" background size="sm"
                                      rows="5" value={this.state.shippingInstructions}
                                      getValue={(val) => {
                                          this.setState({shippingInstructions: val});
                                      }}/>
                        </div>
                        <div className={"col p-2"}>
                            <MDBInput type="textarea" background size="sm" label={'Pickup Instructions'}
                                      rows="5" value={this.state.pickupInstructions}
                                      getValue={(val) => {
                                          this.setState({pickupInstructions: val});
                                      }}/>
                        </div>
                    </div>
                </div>
                <div className={'col-md-11 mx-auto white rounded-sm border p-0 mt-2'}>
                    <div
                        className={'col-12 p-2 grey lighten-4 border-bottom d-flex justify-content-between align-items-center'}>
                        <b className={"font-weight-bold"}>Product Type & Materials</b>
                        <div className={'row mx-0'}>
                            <a role="button" onClick={() => this.toggle("Preview")}
                               className={"text-primary"}>PREVIEW</a>
                        </div>
                    </div>
                    <div className={"col-12 row m-0 p-0"}>
                        <div className={"col-6 p-2 d-flex float-left"}>
                            <b>Product Type:</b><br/>
                            {this.state.productTypeList.map((item, index) => (
                                <MDBInput onClick={() => this.setState({selectedProductType: item.name})}
                                          checked={this.state.selectedProductType === item.name} label={item.name}
                                          type="radio"
                                          id={"radioProdType" + index} key={index}/>
                            ))}
                        </div>
                        {this.state.selectedProductType === "Medicine" &&
                        <div className={"col-6 p-2"}>
                            <b>Medicine Type</b><br/>
                            <MDBSelect search selected="Select Medicine Type"
                                       options={this.state.medicineTypeList}
                                       getValue={(val) => {
                                           this.setState({selectedMedicineType: val})
                                       }}
                                       className={' col grey-text p-0 font-weight-bold'}
                            />
                        </div>
                        }
                        {(this.state.selectedProductType === "Toy" || this.state.selectedProductType === "Supplies" || this.state.selectedProductType === "Accessories" || this.state.selectedProductType === "Device") &&
                        <div className={"col p-2"}>
                            <b>Materials</b><br/>
                            <MDBSelect search selected="Select Product Materials"
                                       options={this.state.productMaterialList}
                                       getValue={(val) => {
                                           this.setState({selectedProductMaterial: val})
                                       }}
                                       className={' col grey-text p-0 font-weight-bold'}
                                       multiple
                            />
                        </div>
                        }
                    </div>
                </div>
                {(this.state.selectedProductType === "Toy") &&
                <div className={'col-md-11 mx-auto white rounded-sm border p-0 mt-2'}>
                    <div
                        className={'col-12 p-2 grey lighten-4 border-bottom d-flex justify-content-between align-items-center'}>
                        <b className={"font-weight-bold"}>Toy Details (Only for toys)</b>
                        <div className={'row mx-0'}>
                            <a role="button" onClick={() => this.toggle("Preview")}
                               className={"text-primary"}>PREVIEW</a>
                        </div>
                    </div>
                    <div className={"col-12 row m-0 p-0"}>
                        <div className={"col p-2"}>
                            <b>Toy Feature</b><br/>
                            <MDBSelect search selected="Choose Toy Feature"
                                       options={this.state.toyFeatureList}
                                       getValue={(val) => {
                                           this.setState({selectedToyFeature: val})
                                       }}
                                       className={' col grey-text p-0 font-weight-bold'}
                                       multiple
                            />
                        </div>
                        <div className={"col p-2"}>
                            <b>Toy Collection</b><br/>
                            <MDBSelect search selected="Choose Toy Collection"
                                       options={this.state.toyCollectionList}
                                       getValue={(val) => {
                                           this.setState({selectedToyCollection: val})
                                       }}
                                       className={' col grey-text p-0 font-weight-bold'}
                                       multiple
                            />
                        </div>
                        <div className={"col p-2"}>
                            <b>Toy Scent</b><br/>
                            <MDBSelect search selected="Choose Toy Scent"
                                       options={this.state.toyScentList}
                                       getValue={(val) => {
                                           this.setState({selectedToyScent: val})
                                       }}
                                       className={' col grey-text p-0 font-weight-bold'}
                                       multiple
                            />
                        </div>
                    </div>
                </div>
                }
                <div className={'col-md-11 mx-auto white rounded-sm border p-0 mt-2'}>
                    <div
                        className={'col-12 p-2 grey lighten-4 border-bottom d-flex justify-content-between align-items-center'}>
                        <b className={"font-weight-bold"}>Food Habit</b>
                        <div className={'row mx-0'}>
                            <a role="button" onClick={() => this.toggle("Preview")}
                               className={"text-primary"}>PREVIEW</a>
                        </div>
                    </div>
                    <div className={"col-12 row m-0 p-0"}>
                        <div className={"col-6 p-2 d-flex float-left"}>
                            <MDBInput onClick={() => this.setState({foodHabit: "None"})}
                                      checked={this.state.foodHabit === "None"} label={"None"} type="radio"
                                      id={"radio_food_habit_none"}/>
                            <MDBInput onClick={() => this.setState({foodHabit: "Veg"})}
                                      checked={this.state.foodHabit === "Veg"} label={"Veg"} type="radio"
                                      id={"radio_food_habit_veg"}/>
                            <MDBInput onClick={() => this.setState({foodHabit: "Non-veg"})}
                                      checked={this.state.foodHabit === "Non-veg"} label={"Non-veg"} type="radio"
                                      id={"radio_food_habit_non-veg"}/>
                        </div>
                    </div>
                </div>
                {this.state.progressStatus === false && <>
                    <div className={'col-md-11 mx-auto white rounded-sm border p-0 mt-2'}>
                        <div
                            className={'col-12 p-2 grey lighten-4 border-bottom d-flex justify-content-between align-items-center'}>
                            <b className={"font-weight-bold"}>Tags & Highlights</b>
                            <div className={'row mx-0'}>
                                <a role="button" onClick={() => this.toggle("Preview")}
                                   className={"text-primary"}>PREVIEW</a>
                            </div>
                        </div>
                        <div className={"col-12 row m-0 p-0"}>
                            <div className={"col p-2"}>
                                <b>Tags</b><br/>
                                <ProductSpecifications section={'tag'}
                                                       getListCallback={this.getTagListCallback}
                                                       sendListCallback={this.state.tags}/>
                            </div>
                            <div className={"col p-2"}>
                                <b>Highlights</b><br/>
                                <ProductSpecifications section={'highlight'}
                                                       getListCallback={this.getHighlightListCallback}
                                                       sendListCallback={this.state.highlight}/>
                            </div>
                        </div>
                    </div>
                    <div className={'col-md-11 mx-auto white rounded-sm border p-0 mt-2'}>
                        <div
                            className={'col-12 p-2 grey lighten-4 border-bottom d-flex justify-content-between align-items-center'}>
                            <b className={"font-weight-bold"}>Guaranteed Analysis</b>
                            <div className={'row mx-0'}>
                                <a role="button" onClick={() => this.toggle("Preview")}
                                   className={"text-primary"}>PREVIEW</a>
                            </div>
                        </div>
                        {/*<div className={"col-9 row p-0 m-auto"}>*/}
                        {/*    <div className={"col-12 p-2"}>*/}
                        {/*        <b>Guaranteed Analysis</b><br/>*/}
                        {/*        <MDBSelect search selected="Select Guaranteed Analysis"*/}
                        {/*                   options={this.state.guaranteedAnaList} getValue={this.setGuaranteedAna}*/}
                        {/*                   className={' col grey-text p-0 font-weight-bold'}*/}
                        {/*                   multiple/>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className={"col-9 row p-0 m-auto"}>
                            <div className={"col-12 p-2"}>
                                <MDBTable bordered striped key={this.state.keyGAT}>
                                    <MDBTableHead>
                                        <tr>
                                            <th scope={"col"}>#</th>
                                            <th scope={"col"}>Label</th>
                                            <th scope={"col"}>Value</th>
                                            <th scope={"col"}>Unit</th>
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                        {this.state.guaranteedAnaList.map((item, index) => (<>
                                                {item.selected === true &&
                                                <tr key={index}>
                                                    <th scope={"row"}>{item.displayIndex + 1}</th>
                                                    <td width={"400"}><div className={'mt-1'}>{item.text}</div></td>
                                                    <td><input background id={"GA" + index} className={"mt-1 form-control"}
                                                                  maxLength="5" type="number" min={0}
                                                                  onChange={this.handleGAQnty(index)}
                                                                  value={item.qnty}/></td>
                                                    <td>
                                                        <MDBSelect getValue={this.handleGAUnit(index)}
                                                                   className={"p-0 mr-0 ml-0 mb-0 mt-2"}
                                                            >
                                                            <MDBSelectInput selected={"Select Unit"}/>
                                                            <MDBSelectOptions search>
                                                                <MDBSelectOption value="" disabled>Select
                                                                    Unit</MDBSelectOption>
                                                                {this.state.packagingUnitList.map((itemI, idx) => (
                                                                    <MDBSelectOption value={itemI.value}
                                                                                     key={idx + "GA"}
                                                                                     selected={this.state.guaranteedAnaList[index].unit == itemI.value}>{itemI.text}</MDBSelectOption>
                                                                ))}
                                                            </MDBSelectOptions>
                                                        </MDBSelect>
                                                    </td>
                                                </tr>
                                                }
                                            </>
                                        ))}
                                    </MDBTableBody>
                                </MDBTable>
                            </div>
                        </div>
                    </div>
                    <div className={'col-md-11 mx-auto white rounded-sm border p-0 mt-2'}>
                        <div
                            className={'col-12 p-2 grey lighten-4 border-bottom d-flex justify-content-between align-items-center'}>
                            <b className={"font-weight-bold"}>Ingredients List With Quantity</b>
                            <div className={'row mx-0'}>
                                <a role="button" onClick={() => this.toggle("Preview")}
                                   className={"text-primary"}>PREVIEW</a>
                            </div>
                        </div>
                        <div className={"col-9 row p-0 m-auto"}>
                            <div className={"col-12 p-2"}>
                                <b>Ingredients</b><br/>
                                <MDBSelect search selected="Select Ingredients"
                                           options={this.state.productIngredientList}
                                           getValue={this.setIngredients}
                                           className={' col grey-text p-0 font-weight-bold'}
                                           multiple/>
                            </div>
                        </div>
                        <div className={"col-9 row p-0 m-auto"}>
                            <div className={"col-12 p-2"}>
                                <MDBTable bordered striped key={this.state.keyIngredient}>
                                    <MDBTableHead>
                                        <tr>
                                            <th scope={"col"}>#</th>
                                            <th scope={"col"}>Label</th>
                                            <th scope={"col"}>Value</th>
                                            <th scope={"col"}>Unit</th>
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                        {this.state.productIngredientList.map((item, index) => (<>
                                                {item.selected === true &&
                                                <tr key={index}>
                                                    <th scope={"row"}>{item.displayIndex + 1}</th>
                                                    <td width={"400"}><div className={'mt-1'}>{item.text}</div></td>
                                                    <td><input background id={"IG" + index} className={"mt-1 form-control"}
                                                                  maxLength="5" type="number" min={0}
                                                                  onChange={this.handleIngredientQnty(index)}
                                                                  value={item.qnty}/></td>
                                                    <td>
                                                        <MDBSelect getValue={this.handleIngredientUnit(index)}
                                                                   className={"p-0 mr-0 ml-0 mb-0 mt-2"}
                                                        >
                                                            <MDBSelectInput selected={"Select Unit"}/>
                                                            <MDBSelectOptions search>
                                                                <MDBSelectOption value="" disabled>Select
                                                                    Unit</MDBSelectOption>
                                                                {this.state.packagingUnitList.map((itemI, idx) => (
                                                                    <MDBSelectOption value={itemI.value}
                                                                                     key={idx + "Ing"}
                                                                                     selected={this.state.productIngredientList[index].unit == itemI.value}>{itemI.text}</MDBSelectOption>
                                                                ))}
                                                            </MDBSelectOptions>
                                                        </MDBSelect>
                                                    </td>
                                                </tr>
                                                }
                                            </>
                                        ))}
                                    </MDBTableBody>
                                </MDBTable>
                            </div>
                        </div>
                    </div>
                    <div className={'col-md-11 mx-auto white rounded-sm border p-0 mt-2 add-product-img-button-position-main-box'}>
                        <div className={'col-12 p-2 grey lighten-4 border-bottom d-flex justify-content-between align-items-center'}>
                            <b className={"font-weight-bold"}>Product Images</b>
                        </div>
                        {this.state.uploadedFiles !== null ?
                            <MultiPicDirectUpload getUploadedFiles={this.getUploadedFiles}
                                                  fileList={this.state.uploadedFiles}/> :
                            <MultiPicDirectUpload getUploadedFiles={this.getUploadedFiles}/>
                        }
                    </div>
                    {/*<div className={'col-md-11 mx-auto white rounded-sm border p-0'}>*/}
                      {/*  <div className={'col-12 p-2 grey lighten-4 d-flex justify-content-between align-items-center'}>*/}
                            <div className={'col-12 text-center p-2'}>
                                <MDBBtn outline size={'md'} onClick={() => this.toggle("Preview")}
                                        disabled={this.state.progressStatus}>Preview</MDBBtn>
                                <MDBBtn outline  size={'md'} onClick={() => this.props.onEditComplete()}
                                        disabled={this.state.progressStatus}>Close</MDBBtn>
                                <MDBBtn  size={'md'} onClick={this.editProduct}
                                         disabled={this.state.progressStatus}>Save</MDBBtn>
                            </div>
                           {/* <div className={'row mx-0'}>
                                <MDBBtn size={'md'} outline onClick={this.props.close}
                                        disabled={this.state.progressStatus}>Close</MDBBtn>
                                <MDBBtn size={'md'} onClick={this.editProduct}
                                        disabled={this.state.progressStatus}>Save</MDBBtn>
                            </div>*/}
                      {/*  </div>*/}
                {/*    </div>*/}
                </>}

                <MDBModal isOpen={this.state.modalPreview} toggle={() => this.toggle("Preview")} size="fluid">
                    <MDBModalHeader toggle={() => this.toggle("Preview")}>Product Details Preview</MDBModalHeader>
                    <MDBModalBody>
                        <ProductDetailsPreview productDetails={this.state}/>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="secondary" onClick={this.props.close}>Close</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
            </React.Fragment>
        )
    }
}

export default withRouter(EditProductNew);