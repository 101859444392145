import React from "react";
import {
    MDBIcon, toast
} from 'mdbreact';

import UserStore from "../../../stores/userStore";
import PharmacyService from "../../../services/pharmacyService";

class SingleDocDirectUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            allowedFileSize: 20971520, //20MB
            fileList: [],
            loading: false,
        }
    }

    onFileLoad = async (e) => {
        if (e.currentTarget.files.length > 0) {
            let file = e.currentTarget.files[0];
            if (this.checkFileSize) {
                await this.uploadFiles(file)
            }
            e.target.value = null
        }
    }

    checkFileSize = (file) => {
        if (file.size > this.state.allowedFileSize) {
            toast.error("File is too big. Maximum upload file size is 20 MB")
            return false
        }
        return true
    }

    uploadFiles = async (file) => {
        let userData = await UserStore.fetchSessionData();
        const data = new FormData()
        data.append('fileName', file);
        this.setState({loading: true})
        let uploadedFile = await PharmacyService.uploadDoc(data, userData);
        if (uploadedFile) {
            let fileList = []
            fileList.push({path: uploadedFile.path, type: file.type})
            await this.setState({fileList})
            await this.uploadedFileCallback(fileList)
        } else {
            toast.error("There is something wrong, please try again after a while.")
        }
        this.setState({loading: false})
    }

    toggleLB = () => {
        this.setState({
            modalLB: !this.state.modalLB
        });
    }

    uploadedFileCallback = async (fileList) => {
        let uploadedFiles = []
        for (let i = 0; i < fileList.length; i++) {
            uploadedFiles.push(fileList[i].path)
        }
        await this.props.getUploadedFiles(uploadedFiles)
    }

    render() {
        return (
            <React.Fragment>
                <div className={'col-12 p-0'}>
                    <div className={'col-12 p-0 text-muted row mx-0'}>
                        <b>{this.props.title}</b>
                        <button className="btn btn-primary btn-outline-blue" onClick={() => this.uploadDoc.click()}
                                disabled={this.state.loading}>
                            {this.state.loading === true ?
                                <>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    &nbsp;Uploading..
                                </> : <><MDBIcon icon="paperclip"/> Upload</>
                            }
                        </button>
                        <input type="file" hidden name="file"
                               accept={"image/jpeg, image/png, image/gif, image/bmp, .pdf, application/msword, text/plain"}
                               ref={input => this.uploadDoc = input}
                               onChange={this.onFileLoad.bind(this)}/>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default SingleDocDirectUpload;