import React, { useState, useEffect } from "react";
import { Breadcrumb, Card, Table, Pagination, Modal, Button } from "antd";
import { Link } from "react-router-dom";
import "rc-pagination/assets/index.css";
import { toast } from "react-toastify";
import UserStore from "../../stores/userStore";
import { MDBBreadcrumb, MDBBreadcrumbItem } from 'mdbreact';
import ReportService from "../../services/reportService";

const ReportList = () => {
    const [reports, setReports] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [userData, setUserData] = useState({});
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedReport, setSelectedReport] = useState(null);
    const [editedName, setEditedName] = useState("");
    const [editedDescription, setEditedDescription] = useState("");
    const [isGenerateModalOpen, setIsGenerateModalOpen] = useState(false);
    const [generateReportData, setGenerateReportData] = useState({
        reportId: "",
        jobType: "",
        requestedBy: "",
        startDate: "",
        endDate: ""
    });
    const [error, setError] = useState(null);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [touchedFields, setTouchedFields] = useState({
        startDate: false,
        endDate: false
    });

    useEffect(() => {
        const fetchSessionData = async () => {
            let userData = await UserStore.fetchSessionData();
            setUserData(userData);
        };
        fetchSessionData();
    }, []);

    const fetchData = async () => {
        setIsLoading(true);
        try {
            let userData = await UserStore.fetchSessionData();
            let reportdata = await ReportService.fetchAllReports(userData, { pageNo: currentPage, pageSize: 10 });
            setReports(reportdata.data);
            setTotalRecords(reportdata.totalRecords);
            setIsErrorModalOpen(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setError("Failed to fetch data. Please try again later.");
            setIsErrorModalOpen(true);
        } finally {
            setIsLoading(false);
        }
    };
    const handleCancel = () => {
        setTouchedFields({
            startDate: false,
            endDate: false
        });
        handleGenerateModalClose();
    };

    useEffect(() => {
        fetchData();
    }, [currentPage]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const columns = [
        { title: "ID", dataIndex: "id", key: "id" },
        { title: "Name", dataIndex: "name", key: "name" },
        { title: "Description", dataIndex: "description", key: "description" },
        {
            title: "Action",
            dataIndex: "status",
            key: "status",
            render: (_, record) => (
                <div style={{ display: "flex", gap: "10px" }}>
                    <Button onClick={() => handleEdit(record)}>Edit</Button>
                    <Button onClick={() => handleDelete(record.id, record.name)}>Delete</Button>
                    <Button onClick={() => handleGenerateModalOpen(record)}>Generate Report</Button>
                </div>
            ),
        },
    ];

    const handleEdit = (record) => {
        setSelectedReport(record);
        setEditedName(record.name);
        setEditedDescription(record.description);
        setIsEditModalOpen(true);
    };

    const handleSubmitEdit = async () => {
        console.log("test");
        try {
            let requestbody = {
                name: editedName,
                description: editedDescription,
                id: selectedReport.id,
            }

            let userData = await UserStore.fetchSessionData();
            const isUpdated = await ReportService.updateReport(userData, requestbody);
            console.log(isUpdated);
            if (isUpdated) {
                setSelectedReport(null);
                setIsEditModalOpen(false);
                setCurrentPage(1);
                fetchData();
            } else {
                throw new Error("Failed to update report details");
            }
        } catch (error) {
            console.error(error.message);
            setError("Names cannot be updated ,Please try again.");
            setIsErrorModalOpen(true);
        }
    };

    const handleDelete = async (reportId, name) => {
        try {
            console.log(reportId, name);
            let userData = await UserStore.fetchSessionData();
            const response = await ReportService.deleteReport(userData, reportId, name);

            if (response) {
                console.log("Report schedule deleted successfully");
                setCurrentPage(1);
                fetchData();
            } else {
                throw new Error(
                    "Failed to delete report schedule. Report may be referenced in reportScheduleTable."
                );
            }
        } catch (error) {
            console.error("Error deleting report schedule:", error);
            setError(
                "Failed to delete report schedule. Report may be referenced in reportScheduleTable."
            );
            // setError("Failed to delete report. Please try again.");
            setIsErrorModalOpen(true);
        }
    };

    const handleCloseEdit = () => {
        setSelectedReport(null);
        setEditedName("");
        setEditedDescription("");
        setIsEditModalOpen(false);
    };

    const handleGenerateModalOpen = (record) => {
        setIsGenerateModalOpen(true);
        setGenerateReportData({
            reportId: record.id,
            jobType: record.name,
            requestedBy: "",
            startDate: "",
            endDate: ""
        });
    };

    const handleGenerateModalClose = () => {
        setIsGenerateModalOpen(false);
    };

    const handleGenerateReport = async () => {
        console.log("test");
        if (!generateReportData.startDate) {
            setTouchedFields({ ...touchedFields, startDate: true });
            return;
        }

        if (!generateReportData.endDate) {
            setTouchedFields({ ...touchedFields, endDate: true });
            return;
        }

        try {
            const formattedStartDate = new Date(generateReportData.startDate).toISOString();
            const formattedEndDate = new Date(generateReportData.endDate).toISOString();

            const requestBody = {
                reportType: generateReportData.jobType,
                startDate: formattedStartDate,
                endDate: formattedEndDate,
            };

            const userData = await UserStore.fetchSessionData();
            const response = await ReportService.generatereport(userData, requestBody);

            if (response.ok) {
                console.log("Report generated successfully");
            }
        } catch (error) {
            console.error("Error generating report:", error);
            setError("Failed to generate report. Please try again.");
            setIsErrorModalOpen(true);
        } finally {
            setGenerateReportData({
                reportId: "",
                jobType: "",
                requestedBy: "",
                startDate: "",
                endDate: ""
            });
            setIsGenerateModalOpen(false);
        }
    };


    const handleCloseErrorModal = () => {
        setIsErrorModalOpen(false);
    };

    return (
        <div>
            <div>
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <MDBBreadcrumb>
                        <MDBBreadcrumbItem><Link to="/dashboard">Dashboard</Link></MDBBreadcrumbItem>
                        <MDBBreadcrumbItem active>Report List</MDBBreadcrumbItem>
                    </MDBBreadcrumb>
                    <div>
                        <Link to="/reports/create-report">
                            <button type="button" className="btn Ripple-parent btn-md btn-default" size="md">Add
                                Report</button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="min-vh-100 p-2 m-0">
                <Card>
                    <Table
                        columns={columns}
                        dataSource={reports}
                        pagination={false}
                        loading={isLoading}
                    />
                    {!isLoading && (
                        <div className="col-md-12 my-5 d-flex justify-content-center" id="paginate">
                            <Pagination
                                className="ant-pagination"
                                onChange={handlePageChange}
                                current={currentPage}
                                total={totalRecords}
                                pageSize={10}
                            />
                        </div>
                    )}
                </Card>
            </div>

            <Modal
                title="Edit Report"
                visible={isEditModalOpen}
                onOk={handleSubmitEdit}
                onCancel={handleCloseEdit}
            >
                <form onSubmit={handleSubmitEdit}>
                    <div className="form-group">
                        <label htmlFor="editedName">Name:</label>
                        <input
                            type="text"
                            className="form-control"
                            id="editedName"
                            value={editedName}
                            onChange={(e) => setEditedName(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="editedDescription">Description:</label>
                        <input
                            type="text"
                            className="form-control"
                            id="editedDescription"
                            value={editedDescription}
                            onChange={(e) => setEditedDescription(e.target.value)}
                        />
                    </div>
                </form>
            </Modal>

            <Modal
                title="Generate Report"
                visible={isGenerateModalOpen}
                onOk={handleGenerateReport}
                onCancel={handleCancel}
            >
                <form onSubmit={handleGenerateReport}>

                    <div className="form-group">
                        <label htmlFor="jobType">Job Type *</label>
                        <input
                            type="text"
                            className="form-control"
                            id="jobType"
                            value={generateReportData.jobType}
                            onChange={(e) => setGenerateReportData({ ...generateReportData, jobType: e.target.value })}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="startDate">Start Date *</label>
                        <input
                            type="datetime-local"
                            className="form-control"
                            id="startDate"
                            value={generateReportData.startDate}
                            onChange={(e) => setGenerateReportData({ ...generateReportData, startDate: e.target.value })}
                            required
                        />
                        {touchedFields.startDate && !generateReportData.startDate && (
                            <span className="error-message">Start Date is required.</span>
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="endDate">End Date *</label>
                        <input
                            type="datetime-local"
                            className="form-control"
                            id="endDate"
                            value={generateReportData.endDate}
                            onChange={(e) => setGenerateReportData({ ...generateReportData, endDate: e.target.value })}
                            required
                        />
                        {touchedFields.endDate && !generateReportData.endDate && (
                            <span className="error-message">End Date is required.</span>
                        )}
                    </div>
                </form>
            </Modal>

            {/* Error Modal */}
            <Modal
                title="Error"
                visible={isErrorModalOpen}
                onCancel={handleCloseErrorModal}
                footer={[
                    <Button key="ok" type="primary" onClick={handleCloseErrorModal}>
                        OK
                    </Button>,
                ]}
            >
                <p>{error}</p>
            </Modal>
        </div>
    );
};

export default ReportList;

