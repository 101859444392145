import React from "react";
import {
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBContainer,
  MDBCarousel,
  MDBCarouselInner,
  MDBCarouselItem,
  MDBView,
  MDBIcon,
} from "mdbreact";
import config from "../../config";
import utility from "../../utility/utility";
import { withRouter } from "react-router-dom";
import SimpleBackdrop from "../common/overlay";
import moment from "moment";

class ViewOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: props.hasOwnProperty("activeItem") ? props.activeItem : null,
      modalLargeView: false,
      activeDoc: null,
      modalMedicineImage: false,
      medImageCollection: [],
      paymentModes: { COD: "Cash On Delivery", PREPAY: "PAID" },
      displayPrescriptionList: [],
    };
    console.log(this.state);
  }
  componentDidMount() {
    if (
      this.state.order.hasOwnProperty("prescriptions") &&
      this.state.order.prescriptions.length > 0
    ) {
      let displayPrescriptionList = this.processDocsForDisplay(
        this.state.order.prescriptions
      );
      this.setState({ displayPrescriptionList });
    }
  }
  processDocsForDisplay = (prescriptions) => {
    let displayDocs = [];
    for (let item of prescriptions) {
      let createdBy = "";
      if (item.hasOwnProperty("createdBy")) {
        createdBy = item.createdBy.name;
      }
      if (item.prescriptionUrl.match(/.(jpg|jpeg|png|gif)$/i)) {
        displayDocs.push({
          path: item.prescriptionUrl,
          type: "image",
          createdBy: createdBy,
        });
      } else {
        displayDocs.push({
          path: item.prescriptionUrl,
          type: "text",
          createdBy: createdBy,
        });
      }
    }
    return displayDocs;
  };
  calculateSubTotal = (mrp, quantity, discount) => {
    let subtotal = mrp * quantity - (mrp * quantity * discount) / 100;
    return subtotal.toFixed(2);
  };
  toggle = (val) => () => {
    this.setState({ ["modal" + val]: !this.state["modal" + val] });
  };
  getAddedQnty = (item) => {
    let addedQnty = 0;
    if (item.hasOwnProperty("inventory")) {
      for (let i of item.inventory) {
        addedQnty = addedQnty + i.quantityOut;
      }
      return addedQnty;
    }
    return item.quantity;
  };
  getSubtotal = (item) => {
    let subtotal = 0;
    if (item.hasOwnProperty("inventory")) {
      for (let i of item.inventory) {
        subtotal = subtotal + i.mrp * i.quantityOut;
      }
      subtotal = subtotal - subtotal * (item.discount / 100);
    } else {
      subtotal =
        item.maxRetailPrice * item.quantity -
        (item.maxRetailPrice * item.quantity * item.discount) / 100;
    }
    return subtotal.toFixed(2);
  };
  createImageUrl = (item) => {
    if (item.match(/.(jpg|jpeg|png|gif)$/i)) {
      return (
        <a href={config.IMAGE_HOST + item} target={"_blank"}>
          <img src={config.IMAGE_HOST + item} width={86} height={86} download />
        </a>
      );
    } else {
      return (
        <a href={config.UPLOADED_DOC_HOST + item} target={"_blank"} download>
          <MDBIcon size={"6x"} icon="file" />
        </a>
      );
    }
  };
  cancelledBy = (order) => {
    try {
      let name = "";
      for (let item of order.processLog) {
        if (item.status === "CANCELLED") {
          name = "by " + item.createdBy.name;
        }
      }
      return name;
    } catch (e) {
      return "";
    }
  };
  orderLogFormat = {
    ACKNOWLEDGED: "ACKNOWLEDGED",
    APPROVED: "APPROVED",
    READYFORDELIVERY: "READY FOR DELIVERY",
    OUTFORDELIVERY: "OUT FOR DELIVERY",
    COMPLETED: "COMPLETED",
  };
  textFormatter(input) {
    if (input.includes("_")) {
      let words = input
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1));
      return words.join(" ");
    } else {
      return input;
    }
  }

  render() {
    const { order } = this.state;
    return (
      <React.Fragment>
        <SimpleBackdrop status={this.state.progressStatus} />
        {this.state.progressStatus === true && (
          <span>Loading order details...</span>
        )}
        {this.state.order !== null && (
          <>
            <div className="col-12 p-2 row mx-0 online-order-text sticky-view-order-detail white cash-box">
              <section>
                <h6 className="font-weight-bold title">Order details</h6>
                <p>
                  Order Id:{" "}
                  <span className={"float-right font-weight-bold"}>
                    {order.orderCode}{" "}
                  </span>
                  <br />
                  Order Placed On:{" "}
                  <span className={"float-right font-weight-bold"}>
                    {utility.convertToReadbleDateTimeUtc(order.createdAt)}
                  </span>
                  <br />
                  Delivery Type:
                  <span className={"float-right font-weight-bold"}>
                    {" "}
                    {order.deliveryType}
                  </span>{" "}
                  <br />
                  Order Status:{" "}
                  <span className={"float-right font-weight-bold"}>
                    {" "}
                    {order.status === "OUTFORDELIVERY" &&
                    order.deliveryType === "SHIP"
                      ? "SHIPPED"
                      : order.status}{" "}
                    <span className={"text-capitalize"}>
                      {this.cancelledBy(order)}
                    </span>
                  </span>{" "}
                  <br />
                  {order.hasOwnProperty("cancellationNote") &&
                    order.cancellationNote !== "" && (
                      <>
                        Cancellation Note:{" "}
                        <span className={"float-right font-weight-bold"}>
                          {order.cancellationNote}
                        </span>
                        <br />
                      </>
                    )}
                  Payment Mode:{" "}
                  <span className={"float-right font-weight-bold"}>
                    {order.paymentMode}
                  </span>{" "}
                  <br />
                  Payment Status:{" "}
                  <span className={"float-right font-weight-bold"}>
                    {order.isPaid == "N" ? (
                      <span style={{ color: "red" }}>NOT PAID</span>
                    ) : (
                      <span style={{ color: "green" }}>PAID</span>
                    )}
                  </span>{" "}
                  <br />
                  {order.isPaid !== "N" && (
                    <span>
                      {" "}
                      Paid amount:{" "}
                      <span className={"float-right font-weight-bold"}>
                        {order.paidAmount}
                      </span>{" "}
                      <br />
                    </span>
                  )}
                  Order Source:{" "}
                  <span className={"float-right font-weight-bold"}>
                    {order.hasOwnProperty("orderSource")
                      ? order.orderSource
                      : "WEB"}
                  </span>{" "}
                  <br />
                  {order.isPrescriptionRequired === "Y" && (
                    <span className={"text-danger"}>
                      <MDBIcon icon="file-prescription" />{" "}
                      <b>Prescription Required</b>
                    </span>
                  )}
                  {this.state.displayPrescriptionList.length > 0 && (
                    <div className={"col-12 p-0 my-3"}>
                      <ul
                        className="list-group list-group-horizontal-lg text-lg-center"
                        style={{ clear: "both", display: "block", content: "" }}
                      >
                        {this.state.displayPrescriptionList.map(
                          (row, index) => (
                            <li
                              key={index}
                              className="list-group-item"
                              style={{ float: "left" }}
                            >
                              {this.createImageUrl(row.path)}
                              {row.createdBy !== "" && (
                                <div className={"text-center"}>
                                  Uploaded By: <br />
                                  {row.createdBy}
                                  <br />
                                </div>
                              )}
                              <div
                                className={"d-flex justify-content-center my-1"}
                              >
                                File_{index + 1}
                              </div>
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  )}
                </p>
              </section>
              <section>
                <h6 className="font-weight-bold title">Customer Details</h6>
                <p>
                  Name:{" "}
                  <span
                    className={"text-capitalize float-right font-weight-bold"}
                  >
                    {order.customerDetails.name}
                  </span>{" "}
                  <br />
                  Phone Number:{" "}
                  <span className={"float-right font-weight-bold"}>
                    {order.shippingAddress.phone}
                  </span>{" "}
                  <br />
                  {order.deliveryType == "PICKUP" ||
                    (order.deliveryType == "DELIVER" && (
                      <div>
                        Delivery date:
                        <span className={"float-right font-weight-bold"}>
                          {moment(order.window.date).format("ll")}
                        </span>
                        <br />
                        Delivery slot:
                        <span className={"float-right font-weight-bold"}>
                          {" "}
                          {order.window.start}-{order.window.end}
                        </span>
                        <br />
                      </div>
                    ))}
                  Order Remarks:
                  <span className={"float-right font-weight-bold"}>
                    {" "}
                    {order.comments.trim() === "" ? "None" : order.comments}
                  </span>
                </p>
              </section>
              <section>
                <h6 className="font-weight-bold title">Shipping Address</h6>
                <p>
                  {order.hasOwnProperty("shippingAddress") &&
                  order.shippingAddress !== "" ? (
                    <>
                      <span className={"text-capitalize"}>
                        <b>{order.shippingAddress.name}</b>
                      </span>
                      <br />
                      {order.shippingAddress.addressLine1}
                      {order.shippingAddress.addressLine2 && (
                        <span>,&nbsp;{order.shippingAddress.addressLine2}</span>
                      )}
                      <br />
                      {order.shippingAddress.city},{" "}
                      {order.shippingAddress.state},{" "}
                      {order.shippingAddress.pinCode},{" "}
                      {order.shippingAddress.country}
                      <br />
                      {order.shippingAddress.phone !== "" && (
                        <>Phone No: {order.shippingAddress.phone}</>
                      )}
                    </>
                  ) : (
                    <div className={"no-data-container"}>
                      No shipping address found.
                    </div>
                  )}
                </p>
              </section>
              <section>
                <h6 className="font-weight-bold title">Pickup Address</h6>
                <p>
                  {order.hasOwnProperty("pickUpAddress") &&
                  order.pickUpAddress !== "" ? (
                    <>
                      <span className={"text-capitalize"}>
                        {order.pickUpAddress.name}
                      </span>{" "}
                      <br />
                      {order.pickUpAddress.point.addressLine1}
                      {order.pickUpAddress.point.addressLine2 && (
                        <span>
                          ,&nbsp;{order.pickUpAddress.point.addressLine2}
                        </span>
                      )}
                      <br />
                      {order.pickUpAddress.point.city},{" "}
                      {order.pickUpAddress.point.state},{" "}
                      {order.pickUpAddress.point.pin},{" "}
                      {order.pickUpAddress.point.country}
                      <br />
                      Phone No: {order.pickUpAddress.phone}
                    </>
                  ) : (
                    <div className={"no-data-container"}>
                      No pickup address found.
                    </div>
                  )}
                </p>
              </section>
              <section>
                <h6 className="font-weight-bold title">Order Logs</h6>
                <p>
                  {order.hasOwnProperty("processLog") &&
                  order.processLog.length > 0 ? (
                    <>
                      {order.processLog.map((item, index) => (
                        <div key={index}>
                          <div className="my-2">
                            <p>
                              STATUS:{" "}
                              <span className={"float-right font-weight-bold"}>
                                {this.orderLogFormat[item.status]}{" "}
                              </span>
                              <br />
                              Order Created:{" "}
                              <span className={"float-right font-weight-bold"}>
                                {moment(item.createdAt).format(
                                  "MMM Do YYYY, h:mm a"
                                )}
                              </span>
                              <br />
                              Created By:
                              <span className={"float-right font-weight-bold"}>
                                {" "}
                                {this.textFormatter(item?.createdBy?.name)}
                              </span>{" "}
                              <br />
                            </p>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <div className={"no-data-container"}>No Logs found.</div>
                  )}
                </p>
              </section>
            </div>

            <table
              className={"table-view-order-sticky table-striped col-12 p-0"}
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th>View Medicine</th>
                  <th>Product</th>
                  <th>Type</th>
                  <th>MRP</th>
                  <th>Batch - MRP</th>
                  <th>Quantity</th>
                  <th>Discount (%)</th>
                  <th>Subtotal</th>
                </tr>
              </thead>
              <tbody>
                {order.cartData.map((row, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td scope="row">
                      {row.hasOwnProperty("imageUrl") &&
                      row.imageUrl.length > 0 ? (
                        <div
                          className={"cursor-pointer"}
                          onClick={() => {
                            this.setState({
                              modalMedicineImage: true,
                              medImageCollection: row.imageUrl,
                            });
                          }}
                        >
                          <img
                            src={
                              config.IMAGE_HOST + row.imageUrl[0].filePathThumb
                            }
                            style={{ width: "50px", height: "50px" }}
                            alt={"Med Image"}
                          />
                        </div>
                      ) : (
                        <div>
                          <img
                            src={"/broken-img.jpg"}
                            style={{ width: "60px", height: "60px" }}
                            alt={"Med Image"}
                          />
                        </div>
                      )}
                    </td>
                    <td>{row.productName}</td>
                    <td>
                      {row.hasOwnProperty("isMedicine") && !row.isMedicine && (
                        <span>General</span>
                      )}
                      {row.hasOwnProperty("isMedicine") && row.isMedicine && (
                        <span>
                          {row.hasOwnProperty("medicineType") && (
                            <span
                              className={
                                row.medicineType === "Schedule H" ||
                                row.medicineType === "Schedule H1" ||
                                row.medicineType === "Schedule X" ||
                                row.medicineType === "PRESCRIPTION DRUGS" ||
                                row.medicineType === "PRESCRIPTION DRUG"
                                  ? "red-text"
                                  : "green-text"
                              }
                            >
                              {row.medicineType === "PRESCRIPTION DRUGS" && (
                                <span>Prescription Drug</span>
                              )}
                              {row.medicineType !== "PRESCRIPTION DRUGS" && (
                                <span>{row.medicineType}</span>
                              )}
                            </span>
                          )}
                          {!row.hasOwnProperty("medicineType") && (
                            <span> Not Specified</span>
                          )}
                        </span>
                      )}
                      {!row.hasOwnProperty("isMedicine") &&
                        row.hasOwnProperty("medicineType") && (
                          <span
                            className={
                              row.medicineType === "Schedule H" ||
                              row.medicineType === "Schedule H1" ||
                              row.medicineType === "Schedule X"
                                ? "red-text"
                                : "green-text"
                            }
                          >
                            {" "}
                            {row.medicineType}
                          </span>
                        )}
                    </td>
                    <td>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: config.CURRENCY_HTML,
                        }}
                      ></span>
                      {row.maxRetailPrice.toFixed(2)}
                    </td>
                    <td className={"text-left"}>
                      {row.hasOwnProperty("inventory") ? (
                        <>
                          {row.inventory.map((item, index1) => (
                            <small key={index1}>
                              <b>
                                {item.batch} -{" "}
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: config.CURRENCY_HTML,
                                  }}
                                ></span>{" "}
                                {item.mrp}
                              </b>
                            </small>
                          ))}
                        </>
                      ) : (
                        "N/A"
                      )}
                    </td>
                    <td>{this.getAddedQnty(row)}</td>
                    <td>{row.discount}</td>
                    <td>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: config.CURRENCY_HTML,
                        }}
                      ></span>
                      {this.getSubtotal(row)}
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="8" className={"text-right"}>
                    Total Amount
                  </td>
                  <td className="text-right">
                    <span
                      dangerouslySetInnerHTML={{ __html: config.CURRENCY_HTML }}
                    ></span>{" "}
                    {order.amount.toFixed(2)}
                  </td>
                </tr>
              </tfoot>
            </table>

            <div className="col-12 p-0 table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th className="font-weight-bold">Paid Amount</th>
                    <th className="font-weight-bold">Paid On</th>
                    <th className="font-weight-bold">Payment Mode</th>
                    <th className="font-weight-bold">Balance Amount</th>
                    <th className="font-weight-bold">Shipping Charge</th>
                    {order.isOrderFulfilled === true && (
                      <th className="font-weight-bold">Flat Discount</th>
                    )}
                    {order.hasOwnProperty("couponData") &&
                      order.couponData !== "" && (
                        <th className="font-weight-bold">Coupon Discount</th>
                      )}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: config.CURRENCY_HTML,
                        }}
                      ></span>{" "}
                      {order.paidAmount.toFixed(2)}
                    </td>
                    <td>
                      {order.paidOn !== ""
                        ? utility.convertToReadbleDateTimeUtc(order.paidOn)
                        : "N/A"}
                    </td>
                    <td>{order.paymentMode}</td>
                    <td>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: config.CURRENCY_HTML,
                        }}
                      ></span>{" "}
                      {(order.totalAmount - order.paidAmount).toFixed(2)}
                    </td>
                    <td>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: config.CURRENCY_HTML,
                        }}
                      ></span>{" "}
                      {order.shippingAmount.toFixed(2)}
                    </td>
                    {order.isOrderFulfilled === true && (
                      <td>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: config.CURRENCY_HTML,
                          }}
                        ></span>{" "}
                        {order.flatDiscount.toFixed(2)}
                      </td>
                    )}
                    {order.hasOwnProperty("couponData") &&
                      order.couponData !== "" && (
                        <td>
                          {order.couponData.value}
                          {order.couponData.discountType === "PERCENTAGE"
                            ? "%"
                            : "INR"}
                        </td>
                      )}
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )}

        <MDBModal
          isOpen={this.state.modalLargeView}
          toggle={this.toggle("LargeView")}
          size={"lg"}
        >
          <MDBModalHeader
            toggle={this.toggle("LargeView")}
            className={"border-0"}
          ></MDBModalHeader>
          <MDBModalBody className={"mt-n4"}>
            <div style={{ textAlign: "center" }}>
              <img
                src={this.state.activeDoc}
                style={{ maxWidth: "700px", maxHeight: "700px" }}
                alt={""}
              />
            </div>
          </MDBModalBody>
        </MDBModal>
        <MDBModal
          isOpen={this.state.modalMedicineImage}
          toggle={this.toggle("MedicineImage")}
          size={"lg"}
        >
          <MDBModalHeader
            toggle={this.toggle("MedicineImage")}
            className={"border-0"}
          ></MDBModalHeader>
          <MDBModalBody className={"mt-n4"}>
            <div className={"col-12 row mx-0"}>
              <MDBContainer>
                <MDBCarousel
                  activeItem={1}
                  length={this.state.medImageCollection.length}
                  showControls={true}
                  showIndicators={false}
                  className="z-depth-1"
                  slide
                >
                  <MDBCarouselInner>
                    {this.state.medImageCollection.map((item, index) => (
                      <MDBCarouselItem itemId={index + 1}>
                        <MDBView>
                          <img
                            className="d-block w-100"
                            src={config.IMAGE_HOST + item.filePathOpt}
                            alt={index + " slide"}
                          />
                        </MDBView>
                      </MDBCarouselItem>
                    ))}
                  </MDBCarouselInner>
                </MDBCarousel>
              </MDBContainer>
            </div>
          </MDBModalBody>
        </MDBModal>
      </React.Fragment>
    );
  }
}

export default withRouter(ViewOrder);
