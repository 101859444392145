import React, { Component } from "react";
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBCard,
  MDBCardBody,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdbreact";
import { Link } from "react-router-dom";
import MaterialTable from "material-table";
import PharmacyService from "../../services/pharmacyService";
import UserStore from "../../stores/userStore";
import { toast } from "react-toastify";
import SimpleBackdrop from "../../components/common/overlay";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";

class OutOfStock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      page: 1,
      list_visit_outOfStock: [],
      total_page: "",
      current_page: "",
      totalRecords: "",
      userData: {},
    };
  }

  async componentDidMount() {
    let userData = await UserStore.fetchSessionData();
    this.setState({ userData });
    await this.refreshPage(this.state.page);
  }

  refreshPage = async (page) => {
    this.setState({ isLoading: true });
    let list = await PharmacyService.fetchListVisitOutOfStock(
      page,
      this.state.userData
    );

    this.setState({ isLoading: false });
    if (list) {
      this.setState({
        list_visit_outOfStock: list.data,
        total_page: list.totalPages,
        totalRecords: list.totalRecords,
        current_page: list.currentPage,
      });
    } else {
      toast.error("We are facing some server issue. Please try again later.");
    }
  };
  onChange = async (page) => {
    this.setState({
      page: page,
      isLoading: true,
    });
    await this.refreshPage(page);
  };
  render() {
    return (
      <React.Fragment>
        <SimpleBackdrop status={this.state.isLoading} />
        <MDBBreadcrumb>
          <MDBBreadcrumbItem>
            <Link to={"/dashboard"}>Dashboard</Link>
          </MDBBreadcrumbItem>
          <MDBBreadcrumbItem active>
            List of Visit Out of Stock Product
          </MDBBreadcrumbItem>
        </MDBBreadcrumb>
        <div className={"min-vh-100 p-2 m-0"}>
          <MDBCard>
            <MDBCardBody>
              <MDBTable className="table table-hover px-2">
                <MDBTableHead>
                  <tr className="text-left" height="50">
                    <th className="align-middle ">Sl</th>

                    <th className="align-middle text-left">Product Name</th>
                    <th className="align-middle text-left">User Agent</th>
                     <th className="align-middle text-left">Total Visits</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  {this.state.list_visit_outOfStock.map((item, index) => {
                    return (
                      <tr key={index} className="text-left">
                        <td className="">{index + 1}</td>
                        <td className="">{item.productName} </td>
                        <td>{item.userAgent} </td>
                        <td>{item.totalHits} </td>
                        {/* <td>{item.userId}</td> */}
                      </tr>
                    );
                  })}
                </MDBTableBody>
              </MDBTable>
              {this.state.isLoading === false && (
                <div
                  className={"col-md-12 my-5 d-flex justify-content-center"}
                  id={"paginate"}
                >
                  <Pagination
                    className="ant-pagination"
                    onChange={this.onChange}
                    current={parseInt(this.state.page)}
                    total={parseInt(this.state.totalRecords)}
                    pageSize={10}
                  />
                </div>
              )}
            </MDBCardBody>
          </MDBCard>
        </div>
      </React.Fragment>
    );
  }
}
export default OutOfStock;
