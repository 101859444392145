import React,{Suspense} from "react";
import {
    MDBBox, MDBBreadcrumb, MDBBreadcrumbItem
} from 'mdbreact';
//const ShipmentsList=React.lazy(() => import("../../components/shipments/listShipments"));
import ShipmentsList from "../../components/shipments/listShipments";
import {Link} from "react-router-dom";

export default class ShipmentsListPage extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MDBBox className={"min-vh-100"}>
                    <MDBBreadcrumb>
                        <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                        <MDBBreadcrumbItem active>View Incoming Shipments</MDBBreadcrumbItem>
                    </MDBBreadcrumb>
                    <Suspense fallback={null}>
                        <ShipmentsList/>
                    </Suspense>
                </MDBBox>
            </React.Fragment>
        );
    }
}
