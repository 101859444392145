import React, { useCallback, useEffect, useRef, useState } from 'react'
import styles from './index.module.css'
import downArrow from '../../images/arrow_down.png'
import upArrow from '../../images/arrow_up.png'
import close_icon from '../../images/close.png'



const AutocompleteMeds = ({ options, fetchOptions, onSelect , onChange, label, name , selectedVal=false ,currentProduct }) => {
  console.log({ options })
  const [data, setData] = useState([]);
  const [inputVal, setInputVal] = useState(currentProduct.productName?? "");
  const [isOpen, setIsOpen] = useState(false);
  const [isSelected, setIsSelected] = useState(false)
  const wrapperRef = useRef(null);

  const inputChangeHandler = (e) => {
    setInputVal(e.target.value)
    onChange(e.target.value)
    setIsOpen(true)
  }

  const selectItemHandler = (val, item) => {
    setInputVal(val)
    onSelect(val, item)
    setIsSelected(true)
    setIsOpen(false)
  }

  const unSelectHandler = () => {
    onSelect("")
    setIsSelected(false)
    setInputVal("")
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(()=>{
    if(selectedVal){
      setInputVal("")
      setIsSelected(false)
    }
  },[selectedVal])

  useEffect(()=>{
      setInputVal(currentProduct?.productName)
  },[currentProduct?.productName])

  const highlightText = (input, fullText, item) => {
    const index = fullText?.toLowerCase()?.indexOf(input?.toLowerCase());
    if (index === -1) return (
      <div>
        <span style={{ paddingRight: ".2em" }}>{fullText}</span>
        <span style={{ fontWeight: 700, background: '#ded8d8', padding: ".1em", borderRadius: "4px" }}>
        </span>
      </div>
    )
    return (
      <div>
        <span>{fullText.slice(0, index)}</span>
        <span style={{ fontWeight: 700, color: '#6366F1' }}>{fullText.slice(index, index + input.length)}</span>
        <span style={{ paddingRight: ".2em" }}>{fullText.slice(index + input.length)}</span>
      </div>
    );
  }


  return (
    <div className={styles.container} ref={wrapperRef}>
      <div className={styles.inputHead}>
        <input type="text" onClick={() => setIsOpen(true)} placeholder={`${label ? label : "Enter here"}`} name={name} value={inputVal} className={styles.input} onChange={inputChangeHandler} />
        <div className={styles.icons}>
          {isSelected && <img src={close_icon} className={styles.cross_icon} onClick={unSelectHandler} alt="" />}
          <img onClick={() => setIsOpen(!isOpen)} src={isOpen ? upArrow : downArrow} alt="" />
        </div>
      </div>
      <div className={`${styles.autocompleteBody} ${isOpen ? styles.open : styles.closed}`}>
        {options?.length > 0 ? options?.map((item, index) => (
          <div className={styles.autocompleteItem} key={index} onClick={() => selectItemHandler(item?.title, item)}>{highlightText(inputVal, item?.title, item)}</div>
        )) : <span className={styles.not_found}>No option found!</span>}
      </div>
    </div>
  )
}

export default AutocompleteMeds