import { MDBBtn, MDBCol, MDBContainer, MDBInput, MDBRow } from "mdbreact";
import React, { useEffect, useState } from "react";
import AuthService from "../../services/authService";
import { toast } from "react-toastify";

const AddAddressPopUp = ({ customerType, userId, modelcloseAddAddress }) => {
  const [addAddress, setAddAddress] = useState({
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    country: "",
    pinCode: "",
    isDefault: false,
    name: "",
    phone: "",
    userId: userId,
    customerType: customerType,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAddAddress((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleInputChangeCheckbox = () => {
    setAddAddress((prevData) => ({
      ...prevData,
      isDefault: !prevData.isDefault,
    }));
  };

  const handleSaveAddAddress = async (e) => {
    e.preventDefault(); // Prevents the form from submitting in the traditional way
    try {
      const res = await AuthService.saveAddAddress(addAddress);
      if (res) {
        toast.success("Address added successfully.");
        modelcloseAddAddress();
      }
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  return (
    <div className="main-div-edit-address-parent">
      <MDBContainer className="container-of-edit-address">
        <form
          className="form-class-edit-address"
          onSubmit={handleSaveAddAddress}
        >
          <div className="main-div-of-edit-address">
            <div className="input-divs-of-edit-address">
              <MDBInput
                label="Address Line 1:"
                value={addAddress.addressLine1}
                name="addressLine1"
                onChange={handleInputChange}
                className="row-for-edit-address-input"
              />
              <MDBInput
                label="Address Line 2:"
                value={addAddress.addressLine2}
                name="addressLine2"
                onChange={handleInputChange}
                className="row-for-edit-address-input"
              />
            </div>
            <div className="input-divs-of-edit-address">
              <MDBInput
                label="City:"
                value={addAddress.city}
                name="city"
                onChange={handleInputChange}
                className="row-for-edit-address-input"
              />
              <MDBInput
                label="State:"
                value={addAddress.state}
                name="state"
                onChange={handleInputChange}
                className="row-for-edit-address-input"
              />
            </div>
            <div className="input-divs-of-edit-address">
              <MDBInput
                label="Country:"
                value={addAddress.country}
                name="country"
                onChange={handleInputChange}
                className="row-for-edit-address-input"
              />
              <MDBInput
                label="Pin Code:"
                value={addAddress.pinCode}
                name="pinCode"
                onChange={handleInputChange}
                className="row-for-edit-address-input"
              />
            </div>
            <div className="input-divs-of-edit-address">
              <MDBInput
                label="Name:"
                value={addAddress.name}
                name="name"
                onChange={handleInputChange}
                className="row-for-edit-address-input"
              />
              <MDBInput
                label="Phone:"
                value={addAddress.phone}
                name="phone"
                onChange={handleInputChange}
                className="row-for-edit-address-input"
              />
            </div>
            <div>
              <div className="checkbox-label">
                <div className="mdb-checkbox-address">
                  <MDBInput
                    type="checkbox"
                    checked={addAddress.isDefault}
                    onChange={handleInputChangeCheckbox}
                    id="defaultCheckbox"
                  />
                </div>
                <div className="input-checkbox-address">
                  <label htmlFor="defaultCheckbox">Is Default</label>
                </div>
              </div>
            </div>
          </div>
          <div className="button-divs-of-edit-address">
            <MDBBtn color="primary" type="submit">
              Add
            </MDBBtn>
          </div>
        </form>
      </MDBContainer>
    </div>
  );
};

export default AddAddressPopUp;
