import React from "react";
import TaskListCmp from "../../components/task-manager/taskList";
import {MDBBreadcrumb, MDBBreadcrumbItem} from "mdbreact";
import {Link} from "react-router-dom";
class TaskList extends React.Component {
    render() {
        return(
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Task Manager</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <TaskListCmp/>
            </React.Fragment>
        )
    }
}
export default TaskList;