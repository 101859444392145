import React,{Suspense} from "react";
import {Link} from "react-router-dom";
import {
    MDBBreadcrumbItem,
    MDBBreadcrumb,MDBBox
} from 'mdbreact';
//const OrderHistory=React.lazy(() => import("../../components/order-history/subOrdersList"));
import OrderHistory from "../../components/order-history/subOrdersList";

export default class OrderHistoryPage extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Online Orders</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <MDBBox className={"min-vh-100"}>
                    <Suspense fallback={null}>
                        <OrderHistory/>
                    </Suspense>
                </MDBBox>
            </React.Fragment>
        );
    }
}
