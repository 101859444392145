import React,{Suspense} from "react";
import {
    MDBBox
} from 'mdbreact';
//const AddSupplier=React.lazy(() => import("../../components/supplier/newSupplier"));
import AddSupplier from "../../components/supplier/newSupplier";

export default class AddSupplierPage extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MDBBox className={"min-vh-100"}>
                    <Suspense fallback={null}>
                        <AddSupplier/>
                    </Suspense>
                </MDBBox>
            </React.Fragment>
        );
    }
}
