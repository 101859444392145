const dataTableConfig= {
    category:[
        {
            label: 'Category Name',
            field: 'categoryName',
            sort: 'asc',
            width: 150
        },
        {
            label: 'Featured',
            field: 'featured',
            sort: 'asc',
            width: 200
        },
        {
            label: '',
            field: 'handleImage',
            sort:'disabled',
            width: 100
        },
        {
            label: 'Created By',
            field: 'createdBy',
            sort: 'asc',
            width: 100
        },
        {
            label: '',
            field: 'handle',
            sort:'disabled',
            width: 100
        },
        {
            label: '',
            field: 'handleStatus',
            sort:'disabled',
            width: 100
        }
    ],
    product:[
        {
            label: 'Product Name',
            field: 'productName',
            sort: 'disabled',
            width: 300
        },
        {
            label: 'Top Selling',
            field: 'topSelling',
            sort: 'asc',
            width: 300
        },
        {
            label: 'Retail Package',
            field: 'retailPackage',
            sort: 'asc',
            width: 300
        },
        {
            label: 'Packaging Unit',
            field: 'quantityUnit',
            sort: 'asc',
            width: 300
        },
        {
            label: 'Medicine Type',
            field: 'medicineType',
            sort: 'asc',
            width: 300
        },
        {
            label: 'Created On',
            field: 'createdAt',
            sort: 'asc',
            width: 300
        },
        {
            label: 'Created By',
            field: 'createdBy',
            sort: 'asc',
            width: 300
        },
        {
            label: 'Dispensing Status ',
            field: 'dispStatus',
            width: 100
        },
        // {
        //     label: 'Completion Status',
        //     field: 'confStatus',
        //     width: 100
        // },
        {
            label: '',
            field: 'handle',
            sort:'disabled',
            width: 100
        },
        {
            label: '',
            field: 'handleStatus',
            sort:'disabled',
            width: 100
        },
        {
            label: '',
            field: 'handleEdit',
            sort:'disabled',
            width: 100
        }
    ]
}
export default dataTableConfig;
