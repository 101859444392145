import React from "react";
import {
    MDBContainer, MDBRow, MDBBtn, MDBCol, MDBModalHeader, MDBModalBody, MDBModal
} from 'mdbreact';
import UserStore from "../../../stores/userStore";
import PharmacyService from "../../../services/pharmacyService";
import config from "../../../config";
import utility from "../../../utility/utility";
import {toast} from "react-toastify";

class FileUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            manufacturer:"",
            description:"",
            isActive:"",
            imageUrl:"",
            progressStatus:false,
            manufacturerError:"",
            buttonLabel: (this.props.buttonLabel) ? this.props.buttonLabel : "Add Image",
            multiImage:(this.props.multiImage) ? this.props.multiImage : false,
            filesList:[],
            order:{
                attachmentUrl: [],
            },
            flag:false,
            totalCount: 0,
            orderItems:[],
            selectedFile: null,
            fileUploadedResObj:{},
            theInputKey:"",
            modalLB:false,
            activeImage:"",
        }
        this.uploadPrescription = React.createRef();
    }
    componentWillReceiveProps({nextProps}) {
        this.setState({...this.state,nextProps})
        if(nextProps === true){
            this.setState({
                fileUploadedResObj: {},
                selectedFile:null
            })
        }
    }
    resetFileInput() {
        let randomString = Math.random().toString(36);
        this.setState({
            theInputKey: randomString
        });
    }
    fileUploadCallback = (data) =>{
        this.props.fileUploadCallback(data)
    }
    onChangeHandler=event=>{
        console.log(event.target.files[0])
        if(!utility.checkAllowedImageType(event.target.files[0])){
            toast.error("Allowed file types are JPG/PNG/GIF/BMP.");
            this.onClickHandlerCancel()
            return false;
        }
        if(!utility.checkMaxAllowedFileSize(event.target.files[0])){
            toast.error("File too big. Maximum file upload size is 20 MB");
            this.onClickHandlerCancel()
            return false;
        }

        this.setState({
            selectedFile: event.target.files[0],
            loaded: 0,
        })
    }
    onClickHandlerCancel = () => {
        this.setState({
            selectedFile: null,
        })
    }
    onClickHandler = async () => {
        if(this.state.selectedFile === "None"){
            this.onClickHandlerCancel()
            return false;
        }
        let userData=await UserStore.fetchSessionData();
        const data = new FormData()
        this.setState({loading:true, progressStatus:true});
        data.append('fileName', this.state.selectedFile);
        this.props.initUploadCallback();
        let fileUpload = await PharmacyService.uploadFile(data,userData);
        this.setState({loading:false, progressStatus:false});
        if(fileUpload){
            this.setState({"fileUploadedResObj": fileUpload})
            this.resetFileInput()
            this.fileUploadCallback(fileUpload)
        } else {
            console.log("Else")
        }
    }
    onInitFileHandler  = async () => {
        this.setState({"selectedFile":"None"})
        this.uploadPrescription.current.click();
    }


    onDownloadImage = (row) => {
        console.log(row);
        this.setState({activeImage: row})
        this.toggleLB()
    }

    // This toggle opens or closes the custom lightbox
    toggleLB = () => {
        this.setState({
            modalLB: !this.state.modalLB
        });
    }

    render() {
        return (
            <React.Fragment>
                <MDBContainer >
                    <form method="post" action="#" id="#">
                        <div className="form-group files">
                            <input key={this.state.theInputKey || '' } type="file" style={{"display": "none"}} name="file" ref={this.uploadPrescription} onChange={this.onChangeHandler}/>
                            {(!this.state.selectedFile || this.state.fileUploadedResObj.hasOwnProperty("path")) &&
                            <MDBBtn color="default" size={"sm"} onClick={this.onInitFileHandler}>
                                <span>{this.state.buttonLabel}</span>
                            </MDBBtn>
                            }
                            {(this.state.selectedFile && !this.state.fileUploadedResObj.hasOwnProperty("path")) &&
                            <div><MDBBtn outline className="btn btn-outline" size={"sm"} onClick={this.onClickHandlerCancel}>Cancel</MDBBtn><MDBBtn size={"sm"} outline={true} className="btn btn-primary " onClick={this.onClickHandler}>Upload</MDBBtn>
                                {this.state.loading === true &&
                                <span> <img  style={{"width":"4rem"}} src={require('../../../components/common/img/loader_new.gif')} alt={"loader..."} /></span>
                                }
                                {this.state.selectedFile.name}</div>
                            }
                        </div>
                    </form>
                    <MDBRow>
                        <MDBCol>
                                {this.state.fileUploadedResObj.hasOwnProperty("path") &&
                                <div className={"float-left m-1"}>
                                    <img src={config.IMAGE_HOST + this.state.fileUploadedResObj.path.filePathOriginal} style={{cursor:"pointer"}}  onClick={()=>this.onDownloadImage(this.state.fileUploadedResObj.path.filePathOriginal)} className={"float-left"} style={{width: "100px", height: "100px"}} alt={""} />
                                </div>
                            }
                        </MDBCol>
                    </MDBRow>

                    <MDBModal isOpen={this.state.modalLB} toggle={this.toggleLB} size={"lg"}>
                        <MDBModalHeader toggle={this.toggleLB}></MDBModalHeader>
                        <MDBModalBody className={"float-center"}>
                            <div style={{"textAlign":"center"}}>
                                <img src={config.IMAGE_HOST + this.state.activeImage} className={"float-center"}
                                     style={{maxWidth: "440px", maxHeight: "440px"}} alt={""}/>
                            </div>
                        </MDBModalBody>
                    </MDBModal>

                </MDBContainer>
            </React.Fragment>
        );
    }
}
export default FileUpload