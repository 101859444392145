import React from "react";
import {MDBBreadcrumb, MDBBreadcrumbItem} from "mdbreact";
import {Link} from "react-router-dom";
import ViewCustomerCmp from "../../components/customer/viewCustomer";
export default class ViewCustomer extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>View Call Summary</MDBBreadcrumbItem>
                </MDBBreadcrumb>
               <ViewCustomerCmp/>
            </React.Fragment>
        );
    }
}
