import React,{Suspense} from "react";
import {
    MDBBox
} from 'mdbreact';
//const MyInventory=React.lazy(() => import("../../components/inventory/listInventory"));
import MyInventory from  "../../components/inventory/listInventory";

export default class AllStockV2 extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MDBBox className={"min-vh-100"}>
                    {/*<Suspense fallback={null}>*/}
                        asdasd
                    {/*</Suspense>*/}
                </MDBBox>
            </React.Fragment>
        );
    }
}
