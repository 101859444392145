import React from "react";
import {Link} from "react-router-dom";
import orgConfig from "../../orgConfig";
import {
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarNav,
    MDBNavItem,
    MDBNavbarToggler,
    MDBCollapse,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem
} from 'mdbreact';
import "./common.css";
import "./mega-search-style.css";
import {withRouter} from "react-router-dom";
import AuthService from "../../services/authService";
import UserStore from "../../stores/userStore";
import CallIcon from "../../images/call-icon.svg";
import LocationIcon from "../../images/location-icon.svg";


class MegaSearchBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            userData: {}
        };
    };

    async componentDidMount() {
        let userData = await UserStore.fetchSessionData();

        this.setState({userData: userData})
        console.log(this.state.userData)
    }


    toggleCollapse = () => {
        this.setState({isOpen: !this.state.isOpen});
    }
    logOut = async () => {
        let userData = await UserStore.fetchSessionData();
        AuthService.logOut(userData);
        this.props.history.push('/');
    }

    render() {
        return (
            <div className={'col-12 p-0'}>
                <div className={'col-12 d-flex'}>
                    <div className="search-header-select" tabIndex="1">
                        <input className="search-header-input" name="selectors" type="radio" id="opt1" checked />
                        <label htmlFor="opt1" className="search-header-option">Ingredient</label>

                        <input className="search-header-input" name="selectors" type="radio" id="opt2" />
                        <label htmlFor="opt2" className="search-header-option">Customer</label>
                    </div>
                    <div className={'col p-0'}>
                        <input type={'search'} className={'form-control'} placeholder={'Search by Ingredient'} />
                    </div>
                </div>

                {/*Indregient Search*/}
                <div className={'d-none header-search-result row mx-0'}>
                    <div className={'search-tab-box'}>
                        <button className={'search-tab-active-btn'}>Paracetamol</button>
                        <button className={'search-tab-btn'}>Paracetamol + Propyphenazone I.P. + 1</button>
                        <button className={'search-tab-btn'}>Paracetamol + Caffeine (50mg)</button>
                    </div>
                    <div className={'search-result-list col'}>
                        <div className={'header'}>Medicines</div>
                        <ul className={'medicine-list'}>
                            <li>
                                <div className={'col-12 p-0 row mx-0'}>
                                    <div className="grey-text title">Medicine Name 1</div>
                                    <span className="ml-auto badge success-color badge-success-color badge-pill ml-2">0</span>
                                </div>

                                <div className={'col-12 p-0 d-flex'}>
                                    Paracetamol (650mg)
                                </div>
                            </li>
                            <li>
                                <div className={'col-12 p-0 row mx-0'}>
                                    <div className="grey-text title">Medicine Name 2</div>
                                    <span className="ml-auto badge success-color badge-success-color badge-pill ml-2">0</span>
                                </div>

                                <div className={'col-12 p-0 d-flex'}>
                                    Paracetamol (1500mg)
                                </div>
                            </li>
                            <li>
                                <div className={'col-12 p-0 row mx-0'}>
                                    <div className="grey-text title">Medicine Name 3</div>
                                    <span className="ml-auto badge success-color badge-success-color badge-pill ml-2">0</span>
                                </div>

                                <div className={'col-12 p-0 d-flex'}>
                                    Paracetamol (1000mg)
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                {/*Customer Search*/}
                <div className={'d-none header-search-result'}>
                    <div className={'search-result-list'}>
                        <div className={'header'}>Patients</div>
                        <ul className={'patient-more-list'}>
                            <li className={'col-md-6'}>
                                <div className={'search-inner-box'}>
                                    <div className={'col-md-12 p-0 d-flex'}>
                                        <div className={'image'}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="138" height="178" viewBox="0 0 138 178">
                                                <g id="Group_1" data-name="Group 1" transform="translate(-76 -58)">
                                                    <rect id="Rectangle_1" data-name="Rectangle 1" width="138" height="178" transform="translate(76 58)" fill="#e3e3e3"/>
                                                    <path id="Icon_awesome-user" data-name="Icon awesome-user" d="M49.355,56.406a28.2,28.2,0,1,0-28.2-28.2A28.2,28.2,0,0,0,49.355,56.406ZM69.1,63.456h-3.68a38.355,38.355,0,0,1-32.125,0h-3.68A29.621,29.621,0,0,0,0,93.069v9.166a10.579,10.579,0,0,0,10.576,10.576H88.134A10.579,10.579,0,0,0,98.71,102.235V93.069A29.621,29.621,0,0,0,69.1,63.456Z" transform="translate(95.645 90.594)" fill="#a8a8a8"/>
                                                </g>
                                            </svg>
                                        </div>
                                        <div>
                                            <div className="grey-text title">Patient Name 1</div>
                                            <div className={'font-small text-muted'}><img src={CallIcon} className={'search-header-icon-sm'}/> 93849-39484</div>
                                            <div className={'d-flex'}><img src={LocationIcon} className={'search-header-icon-sm'}/> <div>Lakhi Mandir, Beltola, Guwahati - 781028</div></div>
                                        </div>
                                    </div>
                                    <div className={'col-md-12 p-0'}>
                                        <div className={'header'}>Orders</div>
                                        <ul className={'extra-search-result-list'}>
                                            <li className={'d-flex'}>
                                                <div className="grey-text title">Id - 172922</div>
                                                <div className={'ml-auto font-small text-muted'}>
                                                    Ordered - 12-03-2022  |  Delivered - 20-03-2022
                                                </div>
                                            </li>
                                            <li className={'d-flex'}>
                                                <div className="grey-text title">Id - 172922</div>
                                                <div className={'ml-auto font-small text-muted'}>
                                                    Ordered - 12-03-2022  |  Delivered - 20-03-2022
                                                </div>
                                            </li>
                                            <li className={'d-flex'}>
                                                <div className="grey-text title">Id - 172922</div>
                                                <div className={'ml-auto font-small text-muted'}>
                                                    Ordered - 12-03-2022  |  Delivered - 20-03-2022
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                            <li className={'col-md-6'}>
                                <div className={'search-inner-box'}>
                                    <div className={'col-md-12 p-0 d-flex'}>
                                        <div className={'image'}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="138" height="178" viewBox="0 0 138 178">
                                                <g id="Group_1" data-name="Group 1" transform="translate(-76 -58)">
                                                    <rect id="Rectangle_1" data-name="Rectangle 1" width="138" height="178" transform="translate(76 58)" fill="#e3e3e3"/>
                                                    <path id="Icon_awesome-user" data-name="Icon awesome-user" d="M49.355,56.406a28.2,28.2,0,1,0-28.2-28.2A28.2,28.2,0,0,0,49.355,56.406ZM69.1,63.456h-3.68a38.355,38.355,0,0,1-32.125,0h-3.68A29.621,29.621,0,0,0,0,93.069v9.166a10.579,10.579,0,0,0,10.576,10.576H88.134A10.579,10.579,0,0,0,98.71,102.235V93.069A29.621,29.621,0,0,0,69.1,63.456Z" transform="translate(95.645 90.594)" fill="#a8a8a8"/>
                                                </g>
                                            </svg>
                                        </div>
                                        <div>
                                            <div className="grey-text title">Patient Name 1</div>
                                            <div className={'font-small text-muted'}><img src={CallIcon} className={'search-header-icon-sm'}/> 93849-39484</div>
                                            <div className={'d-flex'}><img src={LocationIcon} className={'search-header-icon-sm'}/> <div>Lakhi Mandir, Beltola, Guwahati - 781028</div></div>
                                        </div>
                                    </div>
                                    <div className={'col-md-12 p-0'}>
                                        <div className={'header'}>Orders</div>
                                        <ul className={'extra-search-result-list'}>
                                            <li className={'d-flex'}>
                                                <div className="grey-text title">Id - 172922</div>
                                                <div className={'ml-auto font-small text-muted'}>
                                                    Ordered - 12-03-2022  |  Delivered - 20-03-2022
                                                </div>
                                            </li>
                                            <li className={'d-flex'}>
                                                <div className="grey-text title">Id - 172922</div>
                                                <div className={'ml-auto font-small text-muted'}>
                                                    Ordered - 12-03-2022  |  Delivered - 20-03-2022
                                                </div>
                                            </li>
                                            <li className={'d-flex'}>
                                                <div className="grey-text title">Id - 172922</div>
                                                <div className={'ml-auto font-small text-muted'}>
                                                    Ordered - 12-03-2022  |  Delivered - 20-03-2022
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                            <li className={'col-md-6'}>
                                <div className={'search-inner-box'}>
                                    <div className={'col-md-12 p-0 d-flex'}>
                                        <div className={'image'}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="138" height="178" viewBox="0 0 138 178">
                                                <g id="Group_1" data-name="Group 1" transform="translate(-76 -58)">
                                                    <rect id="Rectangle_1" data-name="Rectangle 1" width="138" height="178" transform="translate(76 58)" fill="#e3e3e3"/>
                                                    <path id="Icon_awesome-user" data-name="Icon awesome-user" d="M49.355,56.406a28.2,28.2,0,1,0-28.2-28.2A28.2,28.2,0,0,0,49.355,56.406ZM69.1,63.456h-3.68a38.355,38.355,0,0,1-32.125,0h-3.68A29.621,29.621,0,0,0,0,93.069v9.166a10.579,10.579,0,0,0,10.576,10.576H88.134A10.579,10.579,0,0,0,98.71,102.235V93.069A29.621,29.621,0,0,0,69.1,63.456Z" transform="translate(95.645 90.594)" fill="#a8a8a8"/>
                                                </g>
                                            </svg>
                                        </div>
                                        <div>
                                            <div className="grey-text title">Patient Name 1</div>
                                            <div className={'font-small text-muted'}><img src={CallIcon} className={'search-header-icon-sm'}/> 93849-39484</div>
                                            <div className={'d-flex'}><img src={LocationIcon} className={'search-header-icon-sm'}/> <div>Lakhi Mandir, Beltola, Guwahati - 781028</div></div>
                                        </div>
                                    </div>
                                    <div className={'col-md-12 p-0'}>
                                        <div className={'header'}>Orders</div>
                                        <ul className={'extra-search-result-list'}>
                                            <li className={'d-flex'}>
                                                <div className="grey-text title">Id - 172922</div>
                                                <div className={'ml-auto font-small text-muted'}>
                                                    Ordered - 12-03-2022  |  Delivered - 20-03-2022
                                                </div>
                                            </li>
                                            <li className={'d-flex'}>
                                                <div className="grey-text title">Id - 172922</div>
                                                <div className={'ml-auto font-small text-muted'}>
                                                    Ordered - 12-03-2022  |  Delivered - 20-03-2022
                                                </div>
                                            </li>
                                            <li className={'d-flex'}>
                                                <div className="grey-text title">Id - 172922</div>
                                                <div className={'ml-auto font-small text-muted'}>
                                                    Ordered - 12-03-2022  |  Delivered - 20-03-2022
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(MegaSearchBox);



