const dataTableConfig= {
    category:[
        {
            label: 'Category Name',
            field: 'categoryName',
            sort: 'asc',
            width: 150
        },
        {
            label: 'Featured',
            field: 'featured',
            sort: 'asc',
            width: 200
        },
        {
            label: '',
            field: 'handleImage',
            sort:'disabled',
            width: 100
        },
        {
            label: 'Created By',
            field: 'createdBy',
            sort: 'asc',
            width: 100
        },
        {
            label: '',
            field: 'handle',
            sort:'disabled',
            width: 100
        },
        {
            label: '',
            field: 'handleStatus',
            sort:'disabled',
            width: 100
        }
    ],


    product:[

        {
            label: 'Product Name',
            field: 'productName',
            sort: 'disabled',
            width: 300
        },
        {
            label: 'Retail Package',
            field: 'retailPackage',
            sort: 'asc',
            width: 300
        },
        {
            label: 'Packaging Unit',
            field: 'quantityUnit',
            sort: 'asc',
            width: 300
        },
        {
            label: 'Medicine Type',
            field: 'medicineType',
            sort: 'asc',
            width: 300
        },
        {
            label: 'Content Quantity',
            field: 'contentQuantity',
            sort:'disabled',
            width: 100
        },
        {
            label: 'Stock',
            field: 'stock',
            sort:'disabled',
            width: 100
        },
        {
            label: 'MRP',
            field: 'mrp',
            sort:'disabled',
            width: 100
        },
        {
            label: 'Images',
            field: 'images',
            sort:'disabled',
            width: 100
        },

        // {
        //     label: 'Created On',
        //     field: 'handleCreatedAt',
        //     sort: 'asc',
        //     width: 300
        // },
        // {
        //     label: 'Created By',
        //     field: 'createdBy',
        //     sort: 'asc',
        //     width: 300
        // },

        {
            label:"Stock Availability",
            field:"handleStockAvailability",
            width: 100,
            sort:'asc'
        },
        {
            label:"Mark",
            field:"handleVerification",
            width: 100,
            sort:'disabled'
        },
        {
            label: 'Product Details',
            field: 'viewProductDetails',
            sort: 'disabled',
        },
        // {
        //     label: '',
        //     field: 'handleAction',
        //     width: 100,
        //     sort:'disabled'
        // },
        // {
        //     label: 'Completion Status',
        //     field: 'confStatus',
        //     width: 100
        // },

    ],
    duplicateProducts:[
        {
            label: 'Product Name',
            field: 'productName',
            sort: 'disabled',
            width: 300
        },
        {
            label: 'View',
            field: 'action',
            sort: 'disabled',
            width: 300
        },
        // {
        //     label: 'Completion Status',
        //     field: 'confStatus',
        //     width: 100
        // },

    ],
    assignProduct:[

        {
            label: 'Product Name',
            field: 'productName',
            sort: 'disabled',
            width: 300
        },
        {
            label: 'R Package',
            field: 'retailPackage',
            sort: 'asc',
            width: 300
        },
        {
            label: 'P Unit',
            field: 'quantityUnit',
            sort: 'asc',
            width: 300
        },
        {
            label: 'Medicine Type',
            field: 'medicineTypeFormatted',
            sort: 'asc',
            width: 300
        },
        {
            label: 'CQ',
            field: 'contentQuantity',
            sort:'disabled',
            width: 100
        },
        {
            label: 'Stock',
            field: 'stock',
            sort:'disabled',
            width: 100
        },
        {
            label: 'MRP',
            field: 'mrp',
            sort:'disabled',
            width: 100
        },
        // {
        //     label: 'Images',
        //     field: 'images',
        //     sort:'disabled',
        //     width: 100
        // },

        // {
        //     label: 'Created On',
        //     field: 'handleCreatedAt',
        //     sort: 'asc',
        //     width: 300
        // },
        // {
        //     label: 'Created By',
        //     field: 'createdBy',
        //     sort: 'asc',
        //     width: 300
        // },

        // {
        //     label:"Stock Availability",
        //     field:"handleStockAvailability",
        //     width: 100,
        //     sort:'asc'
        // },
        // {
        //     label:"Mark",
        //     field:"handleVerification",
        //     width: 100,
        //     sort:'disabled'
        // },
        {
            label: 'Product Details',
            field: 'viewProductDetails',
            sort: 'disabled',
        },

    ],
}
export default dataTableConfig;
