import React from "react";
import {
    MDBBox,
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCol, MDBIcon,
    MDBInput, MDBModal, MDBModalBody, MDBModalHeader,
    MDBRow,
    MDBSelect,
    MDBTable, MDBTableBody,
    MDBTableHead
} from "mdbreact"
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import OrderService from "../../services/orderService";
import { toast } from "react-toastify";
import UserStore from "../../stores/userStore";
import AuthService from "../../services/authService";
import config from "../../config";
import PharmacyService from "../../services/pharmacyService";
import moment from "moment";
import util from "../../utility/utility";
import { withRouter } from "react-router-dom";
import AddDoctor from "../common/AddDoctor";
import OfflineOrderInvoice from "./offlineOrderInvoice";
import ReactToPrint from "react-to-print";
import SimpleBackdrop from "../common/overlay";
import Selectlocation from "../location/selectLocation";
import AddProductRequest from "../online-order/AddProductRequest";
import AddSubscription from "../ViewProductReqModal";

const delay = ms => new Promise(res => setTimeout(res, ms));

class FulfilOfflineOrder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentModeList: [
                { text: "Cash", value: "CASH", checked: true },
                { text: "Credit", value: "CREDIT" },
                { text: "Card", value: "CARD" },
                { text: "Netbanking", value: "NETBANKING" },
                { text: "Wallet", value: "WALLET" },
                { text: "Mix Mode", value: "MIXMODE" }
            ],
            paymentMode: "CASH",
            orderModeList: [{ text: "In person", value: "INPERSON", checked: true }],
            orderStatusList: [
                { text: "Created", value: "CREATED", checked: true },
                { text: "Returned", value: "RETURNED" },
                { text: "Canceled", value: "CANCELED" },
                { text: "Delivered", value: "DELIVERED" }],
            orderMode: "INPERSON",
            orderDate: new Date(),
            cashier: null,
            searchedProviderText: "", allSuggestedDoctors: [], doctorDetails: {}, doctorName: "",
            searchedCustomerText: "", allSuggestedUser: [], patientDetails: {},
            cart: [],
            barCode: "", productData: [],
            isLoading: false, loading: false,
            suggestedProducts: [],
            totalAmount: 0,
            flatDiscount: 0,
            amountCollected: 0, changeReturn: 0, creditAmount: 0, paidAmount: 0,
            orgId: "",
            modalProvider: false,
            docs: [],
            modalInvoice: false,
            orderResponse: null,
            searchedProductText: "",
            allSuggestedProvider: {},
            searchedProductList: [], responseArray: [],
            mixPaymentModeList: [
                { text: "Cash", value: "CASH", checked: false },
                // { text: "Credit", value: "CREDIT", checked: false },
                { text: "Card", value: "CARD", checked: false },
                { text: "Netbanking", value: "NETBANKING", checked: false },
                { text: "Wallet", value: "WALLET", checked: false }
            ],
            mixmodePyamentBreakdown: {
                Cash: null,
                // Credit: null,
                Card: null,
                Netbanking: null,
                Wallet: null,
            },
            isMixModeSelected: false,
            selectedLocation: !localStorage.getItem("pharmacyLocation") ? config.DEFAULT_PHARMACY_LOCATION : localStorage.getItem("pharmacyLocation"),
            modalPurchaseRequest: false,
            product: null,
            modalAddSubscription: false,
        }
    }

    async componentDidMount() {
        let userData = await UserStore.fetchSessionData();
        this.setState({ userData: userData })
        if (userData.type === "platformAdmin") {
            this.setState({ orgId: userData._id })
        } else if (userData.type === "ORG_ADMIN") {
            this.setState({ orgId: userData.orgDetails[0]._id })
        }
        await this.getCashierList();
        await this.fetchStorageLocations();
    }

    fetchStorageLocations = async () => {
        try {
            let res = await PharmacyService.fetchStorageLocationV2();
            if (res) {
                let tempArray = [];
                for (let d of res) {
                    let temp = {
                        text: d.name,
                        value: d._id,
                        checked: false
                    }
                    tempArray.push(temp);
                }
                this.setState({ locations: tempArray });
            }
        } catch (e) {
            console.log("Could not fetch the locations.")
        }
    }

    fetchProduct = async (e) => {
        this.setState({ searchedProductList: [] })
        try {
            if (e.target.value !== 0 || e.target.value !== '0') {

                this.setState({ searchedProductText: e.target.value })
                if (e.target.value.length < 2) {
                    return false;
                }
                let responseArray = this.state.responseArray
                let suggestedProduct = await PharmacyService.searchProductOrder(e.target.value, this.state);
                responseArray.push(suggestedProduct)
                await this.setState({ responseArray })
                for (let i = 0; i < this.state.responseArray.length; i++) {
                    let count = 0;
                    for (let j = 0; j < this.state.responseArray[i].length; j++) {
                        if (responseArray[i][j].productName.toUpperCase().indexOf(e.target.value.toUpperCase()) > -1) {
                            ++count;
                        }
                    }
                    if (count === this.state.responseArray[i].length) {
                        let allSuggestedProduct = responseArray[i]
                        this.setState({ searchedProductList: [] })
                        let existingProduct = []
                        for (let product of allSuggestedProduct) {
                            for (let inventoryItem of product.inventory) {
                                let target0 = {}
                                let newProductObj = Object.assign(target0, product);
                                delete newProductObj.inventory;
                                let target = {}
                                newProductObj.inventory = Object.assign(target, inventoryItem);
                                existingProduct.push(newProductObj)
                            }
                        }
                        this.setState({
                            searchedProductList: existingProduct, responseArray: []
                        })
                        break;
                    }
                }
            }
        } catch (e) {
            this.setState({ suggestedProduct: [{ title: "", value: '' }] });
        }
    };

    calculateUnit = (addedQuantity, item) => {
        try {
            // rp is retail package in lowercase
            let rp = item.retailPackage.toLowerCase();
            // rpu is the retail package unit, like strip, will assign the rp to rpu initially
            let rpu = rp;
            // cq is the content Quantity "10"
            let cq = item.contentQuantity;
            // qu quantity unit ex. capsule, tablets
            let qu = item.quantityUnit.toLowerCase();
            let quu = qu;
            // make sure to add a "s" in the end to pluralize it for these cases
            if (rp === "strip" || rp === "bottle" || rp === "packet" || rp === "box" || rp === "sachet" || rp === "syringe" || rp === "mask" || rp === "pair" || rp === "tube" || rp === "device") {
                // if the user select more than one unit of the item, than make sure to pluralize it
                if (addedQuantity > 1) {
                    if (rp === "box") {
                        rpu = item.retailPackage.toLowerCase() + "es";
                    } else {
                        rpu = item.retailPackage.toLowerCase() + "s";
                    }
                } else {
                    rpu = item.retailPackage.toLowerCase();
                }
            }
            // make sure to pluralize the qunaitity unit by adding "s" in the end for these cases
            if ((qu === "tablet" || qu === "capsule" || qu === "respule" || qu === "syringe" || qu === "sachet" || qu === "kilogram" || qu === "tube" || qu === "device" || qu === "gram") && (item.contentQuantity > 1)) {
                quu = (qu + "s").toLowerCase();
            }
            // there are some exceptions
            else if ((qu === "gm") && (item.contentQuantity > 1)) {
                quu = "grams"
            } else if ((qu === "kilo") && (item.contentQuantity > 1)) {
                quu = "kilograms"
            }
            return rpu + " of " + cq + " " + quu;
        } catch (e) {
            return ""
        }
    }

    addItemToBarCode = async (barCode) => {
        await this.setState({ barCode })
        //this.closeSearch()
        this.searchByBarCode()
    }


    getCashierList = async () => {
        let res = await OrderService.fetchDeliveryPersonDetails(this.state.userData.sessionToken);
        if (res === false) {
            toast.error("Something went wrong while fetching the cashier list.");
        } else {
            let tempArray = [];
            for (let d of res) {
                let temp = {
                    text: d.name,
                    value: d._id,
                    checked: false
                }
                tempArray.push(temp);
            }
            this.setState({ cashierList: tempArray });
        }
    }
    handlePaymentMode = async (e) => {
        await this.setState({ paymentMode: e[0] })
        if (e[0] === "WALLET" || e[0] === "NETBANKING" || e[0] === "CARD") {
            this.setState({
                paidAmount: this.state.totalAmount,
                creditAmount: 0,
                changeReturn: 0,
                isMixModeSelected: false,
                mixmodePyamentBreakdown: {
                    Cash: null,
                    Card: null,
                    Netbanking: null,
                    Wallet: null,
                }
            })
        }
        else if (e[0] == "MIXMODE") {
            this.setState({ isMixModeSelected: true })
        }

        else {
            this.refreshAmountCollected()
            this.setState({
                isMixModeSelected: false,
                mixmodePyamentBreakdown: {
                    Cash: null,
                    Card: null,
                    Netbanking: null,
                    Wallet: null,
                }
            })
        }
    }
    fetchProvider = (searchText) => async (e) => {
        try {
            if (e.target.value !== 0 || e.target.value !== '0') {
                if (searchText === 'forAll') {
                    this.setState({ searchedProviderText: e.target.value })
                } else {
                    this.setState({ searchedProviderText: '' })
                }
                let suggestedProvider = await AuthService.searchProviderNew(e.target.value);
                this.setState({ allSuggestedDoctors: suggestedProvider });
            }
        } catch (e) {
            this.setState({ allSuggestedDoctors: [] });
        }
    };
    handleCustomerSearch = async (e) => {
        this.setState({ searchedCustomerText: e.target.value })
        if (e.target.value.length < 2) {
            return false;
        }
        let resUser = await AuthService.suggestCustomerNew(e.target.value, this.state.userData.sessionToken);
        let suggestedUser = [];
        for (let item of resUser) {
            if ((item.hasOwnProperty("name") && item.name) || (item.hasOwnProperty("first_name") && item.first_name)) {
                suggestedUser.push(item)
            }
        }
        this.setState({ allSuggestedUser: suggestedUser });
    }
    searchByBarCode = async () => {
        await delay(500);
        if (this.state.barCode !== "") {
            if (this.state.isLoading === false) {
                this.setState({ isLoading: true });
                let data = await PharmacyService.fetchProductByBarCode(this.state.userData, this.state.barCode);
                this.setState({ isLoading: false });
                if (data === false) {
                    toast.error("We are facing some issue. Please contact your administrator.");
                } else {
                    if (data.length > 0) {
                        let cart = this.state.cart
                        data[0].pricePerUnit = this.getPerUnitPrice(data[0].mrp, data[0].productDetails.looseSaleAllowed, data[0].productDetails.contentQuantity)
                        data[0].appliedDiscount = 0;
                        //If the same barcode is scanned more than once
                        for (let item of cart) {
                            if (item.barCode === this.state.barCode) {
                                if (item.productDetails.looseSaleAllowed === "Y") {
                                    item.addedQnty = item.addedQnty + item.productDetails.contentQuantity
                                } else {
                                    item.addedQnty = item.addedQnty + 1
                                }
                                await this.calculateTotalAmount(cart)
                                this.setState({ cart, barCode: "" })
                                return true
                            }
                        }
                        //If added to the cart for the first time
                        data[0].addedQnty = (data[0].productDetails.looseSaleAllowed === "Y") ? data[0].productDetails.contentQuantity : 1;
                        cart.push(data[0])
                        await this.calculateTotalAmount(cart)
                        this.setState({ cart, barCode: "" });
                    } else {
                        toast.error("Opps!! Item out of stock.");
                    }
                }
            }
        }
    }
    calculateTotalAmount = async (cart) => {
        let total = 0, subtotal = 0, discount = 0;
        let newCart = []
        for (let i = 0; i < cart.length; i++) {
            subtotal = cart[i].addedQnty * cart[i].pricePerUnit
            discount = subtotal * ((cart[i].appliedDiscount) / 100)
            subtotal = subtotal - discount
            cart[i].subtotal = subtotal
            newCart.push(cart[i])
            total = total + subtotal
        }
        await this.setState({ cart: newCart, totalAmount: total })
        this.refreshAmountCollected()
    }
    getPerUnitPrice = (mrp, looseSaleAllowed, contentQuantity) => {
        let pricePerUnit = 0
        if (looseSaleAllowed === "Y") {
            pricePerUnit = mrp / contentQuantity
        } else {
            pricePerUnit = mrp
        }
        return pricePerUnit
    }
    removeFromCart = async (index, item) => {
        let cart = this.state.cart;
        if (cart[index].productId === item.productId) {
            cart.splice(index, 1);
            this.setState({ cart })
        }
        await this.calculateTotalAmount(this.state.carts)
    }
    handleQuantity = (index) => async (e) => {
        if (e.target.value !== "" && !util.isInt(e.target.value)) {
            return false;
        }
        let cart = this.state.cart, availableQnty = 0
        /*Check stock availability*/
        availableQnty = (cart[index].productDetails.looseSaleAllowed === "Y") ? cart[index].looseQuantity : cart[index].quantity;

        //If sufficient quantity is unavailable
        cart[index].addedQnty = (e.target.value > availableQnty) ? availableQnty : e.target.value;

        this.setState({ cart })
        await this.calculateTotalAmount(cart)
    };
    handleDiscount = (index) => async (e) => {
        if (!util.isNumericTwoDecimalPartial(e.target.value) && e.target.value !== "") {
            return false;
        }
        let cart = this.state.cart;
        cart[index].appliedDiscount = e.target.value
        this.setState({ cart })
        await this.calculateTotalAmount(cart)
    }
    removeFromCart = async (index) => {
        let cart = this.state.cart;
        cart.splice(index, 1);
        this.setState({ cart })
        await this.calculateTotalAmount(this.state.cart)
    }
    // handleAmountCollected = async (e) => {
    //     let amountCollected = 0, changeReturn = 0, creditAmount = 0, paidAmount = 0;
    //     if (!util.isNumericTwoDecimalPartial(e.target.value) && e.target.value !== "") {
    //         return false;
    //     }
    //     amountCollected = e.target.value
    //     await this.setState({ amountCollected })
    //     this.refreshAmountCollected()
    // }

    handleAmountCollected = async (e) => {
        let amountCollected = parseFloat(e.target.value) || 0;
        if (!util.isNumericTwoDecimalPartial(e.target.value) && e.target.value !== "") {
            return false;
        }

        let { totalAmount, flatDiscount,paymentMode } = this.state;
        let paidAmount = totalAmount - flatDiscount;
        let changeReturn = 0, creditAmount = 0;

        if (amountCollected > paidAmount) {
            changeReturn = amountCollected - paidAmount;
        } else {
            creditAmount = paidAmount - amountCollected;
        }
        if((paymentMode=="CASH") || paymentMode=="CREDIT"){
            paidAmount = amountCollected
        }

        await this.setState({ amountCollected, paidAmount, changeReturn, creditAmount });
    };

    handleFlatDiscountChange = (e) => {
        let flatDiscount = parseFloat(e.target.value) || 0;
        this.setState({ flatDiscount }, this.refreshAmountCollected);
    };

    // refreshAmountCollected = () => {
    //     let changeReturn = 0, creditAmount = 0, paidAmount = 0;
    //     if (this.state.paymentMode === "WALLET" || this.state.paymentMode === "CARD" || this.state.paymentMode === "NETBANKING") {
    //         paidAmount = this.state.totalAmount
    //     } else if (this.state.amountCollected === 0 || this.state.amountCollected === "" || this.state.amountCollected === undefined) {
    //         creditAmount = 0
    //         changeReturn = 0
    //         paidAmount = 0
    //     } else if (this.state.amountCollected > this.state.totalAmount) {
    //         creditAmount = 0
    //         changeReturn = this.state.amountCollected - this.state.totalAmount
    //         paidAmount = this.state.totalAmount
    //     } else {
    //         changeReturn = 0
    //         creditAmount = this.state.totalAmount - this.state.amountCollected
    //         paidAmount = this.state.amountCollected
    //     }
    //     this.setState({ changeReturn, creditAmount, paidAmount })
    // }

    refreshAmountCollected = () => {
        let { totalAmount, amountCollected, flatDiscount ,paymentMode } = this.state;
        let paidAmount = totalAmount - flatDiscount;
        let changeReturn = 0, creditAmount = 0;

        if (amountCollected > paidAmount) {
            changeReturn = amountCollected - paidAmount;
        } else {
            creditAmount = paidAmount - amountCollected;
        }
        if((paymentMode=="CASH") || paymentMode=="CREDIT"){
            paidAmount = amountCollected
        }

        this.setState({ paidAmount, changeReturn, creditAmount });
    };

    toggle = (name) => {
        this.setState({ ["modal" + name]: !this.state["modal" + name] })
    }
    addDoctorCompleted = () => {
        toast.success("Doctor Added.");
        this.toggle("Provider")
    }
    getBalanceAmount = () => {
        let balanceAmount = 0
        if (this.state.paymentMode === "CARD" || this.state.paymentMode === "NETBANKING" || this.state.paymentMode === "WALLET") {
            balanceAmount = 0
        } else if (this.state.paymentMode === "CREDIT" && this.state.amountCollected === 0) {
            balanceAmount = this.state.totalAmount
        } else if (this.state.creditAmount > 0) {
            balanceAmount = this.state.creditAmount
        }
        return balanceAmount
    }
    placeOrder = async () => {
        if (!this.state.selectedLocation || this.state.selectedLocation === null) {
            toast.error("Please select location.")
            return false
        }
        if (!this.state.cashier || this.state.cashier === null) {
            toast.error("Please select cashier.")
            return false
        }
        if (this.state.totalAmount === 0) {
            toast.error("Please add quantity for the added products to the cart.")
            return false
        }
        if (!this.state.patientDetails.hasOwnProperty("name") || (this.state.patientDetails.hasOwnProperty("name") && this.state.patientDetails.name === "")) {
            toast.error("Please enter customer details.")
            return false
        }
        if (this.state.paidAmount === 0 && this.state.paymentMode === "CASH") {
            toast.error("Paid amount is equal to 0. If this is a credit order, please change the payment mode to 'CREDIT'.");
            return false;
        } else if ((this.state.totalAmount - this.state.paidAmount-this.state.flatDiscount) >= 1 && this.state.paymentMode === "CASH") {
            toast.error("Paid amount is lesser than total amount. If this is a partial payment, please change the payment mode to 'CREDIT'.");
            return false;
        }
        const totalAmount = Object.values(this.state.mixmodePyamentBreakdown)
            .filter(value => value !== null || value !== null)
            .reduce((acc, value) => acc + value, 0);

        if (totalAmount !== this.state.paidAmount && this.state.isMixModeSelected) {
            toast.error("Mix mode amount breakdown mismatch with total amount")
            return
        }



        let newCart = []
        for (let product of this.state.cart) {
            let item = {};
            if (product.addedQnty > 0) {
                item._id = product.productId;
                item.quantity = parseInt(product.addedQnty);
                item.contentQuantity = product.productDetails.contentQuantity;
                item.numberOfSellableUnits = product.productDetails.numberOfSellableUnits;
                item.looseSaleAllowed = product.productDetails.looseSaleAllowed;
                item.minimumLooseSaleQuantity = product.productDetails.minimumLooseSaleQuantity;
                item.discount = parseFloat(product.appliedDiscount);
                item.inventory = product
                item.patientDetails = this.state.patientDetails
                item.doctorDetails = this.state.doctorDetails;
                item.productName = product.productDetails.productName;
                item.medicineType = product.productDetails.medicineType;
                item.isMedicine = product.productDetails.isMedicine;
                newCart.push(item)
            }
        }
        if (this.state.cart.length > 0 && newCart.length === 0) {
            toast.error("Please add quantity for the items added to the cart.")
            return false
        }
        let payload = {
            orgId: this.state.orgId,
            orderMode: this.state.orderMode,
            paymentMode: this.state.paymentMode,
            paidAmount: parseFloat(util.customRound(this.state.paidAmount)),
            balanceAmount: this.getBalanceAmount(),
            totalAmount: parseFloat(this.state.totalAmount),
            customerDetails: this.state.patientDetails,
            docs: this.state.docs,
            orderDate: this.state.orderDate,
            products: newCart,
            casierId: this.state.cashier,
            mixmodePyamentBreakdown: this.state.mixmodePyamentBreakdown,
            isMixModeSelected: this.state.isMixModeSelected,
            flatDiscount : this.state.flatDiscount,
            location: this.state.selectedLocation,
        }
        this.setState({ loading: true })
        let res = await OrderService.createOfflineOrderNew(payload, this.state.userData.sessionToken)
        this.setState({ loading: false })
        if (res) {
            this.setState({ orderResponse: res })
            toast.success("The order created successfully.")
            this.refreshPage()
            this.toggle("Invoice")
        }
    }
    refreshPage = async () => {
        this.setState({
            paymentModeList: [
                { text: "Cash", value: "CASH", checked: true },
                { text: "Credit", value: "CREDIT" },
                { text: "Card", value: "CARD" },
                { text: "Netbanking", value: "NETBANKING" },
                { text: "Wallet", value: "WALLET" },
                { text: "Mix Mode", value: "MIXMODE" }
            ],
            paymentMode: "CASH",
            orderModeList: [{ text: "In person", value: "INPERSON", checked: true },
            { text: "Telephonic", value: "TELEPHONIC" }],
            orderStatusList: [
                { text: "Created", value: "CREATED", checked: true },
                { text: "Returned", value: "RETURNED" },
                { text: "Canceled", value: "CANCELED" },
                { text: "Delivered", value: "DELIVERED" }],
            orderMode: "INPERSON",
            orderDate: new Date(),
            searchedProviderText: "", allSuggestedDoctors: [], doctorDetails: {},
            searchedCustomerText: "", allSuggestedUser: [], patientDetails: {},
            cart: [],
            barCode: "", productData: [],
            isLoading: false,
            suggestedProducts: [],
            totalAmount: 0,
            amountCollected: 0, changeReturn: 0, creditAmount: 0, paidAmount: 0,
            modalProvider: false,
            docs: [],
            modalInvoice: false,
            mixPaymentModeList: [
                { text: "Cash", value: "CASH", checked: false },
                { text: "Card", value: "CARD", checked: false },
                { text: "Netbanking", value: "NETBANKING", checked: false },
                { text: "Wallet", value: "WALLET", checked: false }
            ],
            mixmodePyamentBreakdown: {
                Cash: null,
                Card: null,
                Netbanking: null,
                Wallet: null,
            },
            isMixModeSelected: false,
            // cashier: null,
            flatDiscount:0,
            // selectedLocation: null,
        })
    }
    selectMixmode = (index) => {
        let data = this.state.mixPaymentModeList
        data[index].checked = !data[index].checked
        this.setState({
            mixPaymentModeList: data
        });
    }
    mixModePayInput = (e, name) => {
        const { value } = e.target;
        this.setState((prevState) => ({
            mixmodePyamentBreakdown: {
                ...prevState.mixmodePyamentBreakdown,
                [name]: parseInt(value)
            }
        }));
    }

    selectLocationCallback = (selectedLocation) => {
        this.setState({selectedLocation});
    }
    addPurchaseRequest = (item, index) => {
        let productDetails = 
            {   productName: item?.productDetails?.productName,
                maxRetailPrice: item?.mrp,
                discount: item?.discount,
                quantity: item?.quantity,
                quantityError: false,
                _id: item.productId,
                productNameError: false,
            };
        this.setState({product: productDetails});
        this.toggle("PurchaseRequest");
    }
    addSubscription = async (item) => {
        await this.setState({
            product: { 
                _id: item?.productId, 
                productName: item?.productDetails?.productName  
            }
        });
        this.toggle("AddSubscription");
    }
    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.loading} />
                <MDBBox>
                    <div className={"row mx-0"}>
                        <div className={"col-md-3 mx-0 p-3 border rounded"}>
                            <div className={"col-12 mx-0 p-0 row"}>
                                <Selectlocation 
                                    selectLocationCallback={this.selectLocationCallback} 
                                    selectedLocation={this.state.selectedLocation}
                                />
                                <div className={"col-12 p-2  mt-1 mb-4"}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <DatePicker format="MMM D, YYYY" className={'form-control mt-n3'}
                                            label="Order Date" value={this.state.orderDate}
                                            onChange={(val) => {
                                                this.setState({ orderDate: val })
                                            }}
                                            disabled
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div className={"col-12 p-2 mb-1 mt-3 "}>
                                    <MDBSelect outline={true}
                                        label={"Select Cashier"} getValue={(val) => {
                                            this.setState({ cashier: val[0] })
                                        }}
                                        key={this.state.cashier}
                                        options={this.state.cashierList}></MDBSelect>
                                </div>
                                <div className={"col-12 p-2 "}>
                                    <MDBSelect outline={true}
                                        label={"Order Mode"} getValue={(val) => {
                                            this.setState({ orderMode: val[0] })
                                        }}
                                        options={this.state.orderModeList}></MDBSelect>
                                </div>
                                <div className={"col-12 p-2 my-2"}>
                                    <MDBSelect outline={true}
                                        label={"Payment Mode"} getValue={this.handlePaymentMode}
                                        options={this.state.paymentModeList}></MDBSelect>
                                </div>
                                <div className={"col-12 p-2 "}>
                                    <div className="checkbox-label">
                                        {this.state.isMixModeSelected && this.state.mixPaymentModeList.map((item, key) =>
                                            <>
                                                <div className="mdb-checkbox-address">
                                                    <MDBInput
                                                        type="checkbox"
                                                        checked={item.checked}
                                                        onChange={() => { this.selectMixmode(key) }}
                                                        id={key}
                                                    />
                                                </div>
                                                <div className="input-checkbox-address">
                                                    <label htmlFor="defaultCheckbox">{item.text}</label>
                                                </div>
                                            </>

                                        )}
                                    </div>
                                </div>
                                <div className={"col-12 px-2 "}>
                                    <input type={'search'} placeholder="Search doctor by name, phone or e-mail"
                                        className={'mt-0 form-control'}
                                        maxLength="100"
                                        onChange={this.fetchProvider('forAll')}
                                        value={this.state.searchedProviderText} />
                                </div>
                                {(this.state.allSuggestedDoctors.length > 0 && this.state.searchedProviderText) &&
                                    <div className={"col-12 my-1"}>
                                        <div className="card  position-absolute mb-0 pb-0" style={{ zIndex: 100 }}>
                                            <ul className="list-group list-group-flush scroll-list">
                                                {this.state.allSuggestedDoctors.map((row, index) => (
                                                    <li key={index} className="list-group-item">
                                                        <MDBRow style={{ "fontSize": "13px" }}>
                                                            <MDBCol size={"7"}>
                                                                {row.hasOwnProperty("name") && row.name &&
                                                                    <div>{row.name}</div>
                                                                }
                                                                {row.hasOwnProperty("contactNo") && row.contactNo &&
                                                                    <div>{row.contactNo}</div>
                                                                }
                                                            </MDBCol>
                                                            <MDBCol size={"3"}>
                                                                <MDBBtn color={"default"} size={"sm"}
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            searchedProviderText: "",
                                                                            doctorDetails: row,
                                                                            doctorName: row?.name
                                                                        })
                                                                    }}>Select</MDBBtn>
                                                            </MDBCol>
                                                        </MDBRow>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                }
                                <div className={"col-12 p-0 mt-n3 text-right"}>
                                    <MDBBtn onClick={() => {
                                        this.toggle("Provider")
                                    }} size={"sm"} className={"text-primary mt-3"} flat>New Doctor</MDBBtn>
                                </div>
                                {(this.state.doctorDetails.hasOwnProperty("name")) &&
                                    <div className={"col-12 my-1"}>
                                        <MDBCard style={{ "width": "100%" }} className={"p-0"}>
                                            <MDBCardBody>
                                                <div className={"row mx-0 p-0"}>
                                                    <div className={"col-10"}>
                                                        <div>Doctor Name: {this.state.doctorDetails.name}</div>
                                                        {this.state.doctorDetails.contactNo !== "" &&
                                                            <div>Doctor Phone: {this.state.doctorDetails.contactNo}</div>
                                                        }
                                                    </div>
                                                    <div className={"col-1 float-right"}>
                                                        <span onClick={() => {
                                                            this.setState({ doctorDetails: {} })
                                                        }} className={"blue-text"}
                                                            style={{ "cursor": "pointer" }}>X</span>
                                                    </div>
                                                </div>
                                            </MDBCardBody>
                                        </MDBCard>
                                    </div>
                                }
                                <div className={"col-12 px-2"}>
                                    <input type={'search'} placeholder="Search customer by name, phone or e-mail"
                                        className={'form-control'}
                                        maxLength="50"
                                        onChange={this.handleCustomerSearch}
                                        value={this.state.searchedCustomerText} />
                                </div>

                                {(this.state.allSuggestedUser.length > 0 && this.state.searchedCustomerText) &&
                                    <div className={"col-12 my-1"}>
                                        <div className="card  position-absolute mb-0 pb-0" style={{ zIndex: 100 }}>
                                            <ul className="list-group list-group-flush scroll-list">
                                                {this.state.allSuggestedUser.map((row, index) => (
                                                    <li key={index} className="list-group-item">
                                                        <MDBRow style={{ "fontSize": "13px" }}>
                                                            <MDBCol size={"2"}>
                                                                {(row.hasOwnProperty("profilePic") && row.profilePic) ?
                                                                    <img width={"40"} alt={""}
                                                                        src={config.JAVA_IMAGE_HOST + row.profilePic} /> :
                                                                    <img width={"40"} alt={""}
                                                                        src={require('../../components/common/img/profile-pic-user.png')} />

                                                                }
                                                            </MDBCol>
                                                            <MDBCol size={"6"}>
                                                                {(row.hasOwnProperty("name") && row.name) &&
                                                                    <div>{row.name}</div>
                                                                }
                                                                {row.hasOwnProperty("contactNo") && row.contactNo &&
                                                                    <div>{row.contactNo}</div>
                                                                }
                                                            </MDBCol>
                                                            <MDBCol size={"3"}>
                                                                <MDBBtn size={"sm"} onClick={() => {
                                                                    this.setState({
                                                                        patientDetails: row,
                                                                        searchedCustomerText: ""
                                                                    })
                                                                }}>Select</MDBBtn>
                                                            </MDBCol>
                                                        </MDBRow>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                }
                                <div className={"col-12 p-0 mt-n3 text-right"}>
                                    <MDBBtn onClick={() => {
                                        this.props.history.push("/add-customer")
                                    }} size={"sm"} className={"text-primary mt-3"} flat>New Customer</MDBBtn>
                                </div>
                                {this.state.patientDetails.hasOwnProperty("name") &&
                                    <div className={"col-12  my-1"}>
                                        <MDBCard style={{ "width": "100%" }}>
                                            <MDBCardBody>
                                                <div className={"row mx-0 p-0"}>
                                                    <div className={"col-10"}>
                                                        <div>Customer Name: {this.state.patientDetails.name}</div>
                                                        {this.state.patientDetails.contactNo !== "" &&
                                                            <div>Customer Phone: {this.state.patientDetails.contactNo}</div>
                                                        }
                                                    </div>
                                                    <div className={"col-1 float-right"}>
                                                        <span onClick={() => {
                                                            this.setState({ patientDetails: {} })
                                                        }} className={"blue-text"}
                                                            style={{ "cursor": "pointer" }}>X</span>
                                                    </div>
                                                </div>
                                            </MDBCardBody>
                                        </MDBCard>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className={"col-md-9 mx-0 p-3 border rounded"}>
                            <div className={"col-12 mx-0 p-0 row mb-3 d-flex align-items-center"}>
                                <div className={"col-auto mx-0 p-0 text-center"}>
                                    <img src={"/barCode_1.png"} className={"float-center"}
                                        style={{ maxWidth: "140px", maxHeight: "80px" }} alt={""} />
                                    <div><b>BARCODE</b></div>
                                </div>
                                <div className={"col-md-3 mx-0 my-2"}>
                                    <MDBInput label="Scan barcode" outline={true} value={this.state.barCode}
                                        getValue={(e) => {
                                            this.setState({ barCode: e })
                                        }} onChange={() => this.searchByBarCode()} id={"barCode"} />
                                </div>




                                <div className={"col mx-0 p-0"}></div>
                            </div>
                            <div className={"col-12 mx-0 p-0 row card"}>
                                <div className={"col-12 mx-0 p-0"}>
                                    <MDBTable small={true}>
                                        <MDBTableHead>
                                            <tr>
                                                <th>Product</th>
                                                <th>Batch</th>
                                                <th>Type</th>
                                                <th>Expiry date</th>
                                                <th>MRP per unit</th>
                                                <th>GST (%)</th>
                                                <th>Quantity</th>
                                                <th>Discount (%)</th>
                                                <th>Subtotal</th>
                                                <th>Remove</th>
                                                <th>Action</th>
                                            </tr>
                                        </MDBTableHead>
                                        <MDBTableBody>
                                            {this.state.cart.map((row, index) => (
                                                <tr key={index + "_cart"} className={"pt-1 mt-1"}>
                                                    <td>{row.productDetails.productName}</td>
                                                    <td>{row.batch}</td>
                                                    <td>{row.productDetails.medicineType}</td>
                                                    <td>{moment(row.expDate).format("MMM YYYY")}</td>
                                                    <td>{row.pricePerUnit.toFixed(2)}</td>
                                                    <td>{row.gst}</td>
                                                    <td>
                                                        <input className={'form-control'} style={{ "width": "5rem" }} outline={true} maxLength="5"
                                                            type='text' onChange={this.handleQuantity(index)}
                                                            value={row.addedQnty} />
                                                    </td>
                                                    <td>
                                                        <input className={'form-control'} style={{ "width": "5rem" }} outline={true} maxLength="5"
                                                            type='text' onChange={this.handleDiscount(index)}
                                                            value={row.appliedDiscount} />
                                                    </td>
                                                    <td>{row.subtotal.toFixed(2)}</td>
                                                    <td>
                                                        <div style={{ "cursor": "pointer" }}
                                                            onClick={() => this.removeFromCart(index)}><MDBIcon
                                                                icon="trash" />
                                                        </div> 
                                                    </td>
                                                    <td>
                                                        <button
                                                            size="sm"
                                                            type="button"
                                                            className="btn btn-sm btn-secondary"
                                                            data-toggle="tooltip"
                                                            data-placement="top"
                                                            title="Add Purchase Request"
                                                            onClick={() => this.addPurchaseRequest(row, index)}
                                                        >
                                                            Add Purchase Rqst
                                                        </button><br/>
                                                        <button
                                                            size="sm"
                                                            type="button"
                                                            className="btn btn-sm btn-info"
                                                            data-toggle="tooltip"
                                                            data-placement="top"
                                                            title="Add Subscrition"
                                                            onClick={() => this.addSubscription(row, index)}
                                                        >
                                                            Add Subscrition
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </MDBTableBody>
                                    </MDBTable>
                                </div>
                            </div>
                            <div className={"col-12 mx-0 p-0 row my-2"}>
                                <div className={"col-9 mx-0 p-0"}></div>
                                <div className={"col-3 mx-0 p-0 float-left"}>
                                    <span className="mt-3"><b>Total Amount: <span
                                        dangerouslySetInnerHTML={{ __html: config.CURRENCY_HTML }}></span> {util.customRound(this.state.totalAmount)}</b></span>
                                </div>
                            </div>

                            <div className={"col-12 mx-0 p-0 row my-3"}>
                                {this.state.cart.length > 0 && <>
                                    <div className={"col-4 mx-0"}>
                                        {(this.state.paymentMode === "CASH" || this.state.paymentMode === "CREDIT") &&
                                            <MDBInput label={"Amount Collected"}
                                                value={this.state.amountCollected}
                                                onChange={this.handleAmountCollected}></MDBInput>
                                        }
                                    </div>
                                    <div className={"col-4 mx-0"}>
                                        {(this.state.paymentMode === "CASH" || this.state.paymentMode === "CREDIT") && <>
                                            {this.state.creditAmount > 0 &&
                                                <MDBInput label={"Credit Amount"}
                                                    value={util.customRound(this.state.creditAmount)}
                                                    disabled></MDBInput>
                                            }
                                            {this.state.changeReturn > 0 &&
                                                <MDBInput label={"Change Return"} value={this.state.changeReturn.toFixed(2)}
                                                    disabled></MDBInput>
                                            }
                                        </>}
                                    </div>
                                    <div className={"col-4 mx-0"}>
                                        <MDBInput label={"Flat Discount"} outline={true}
                                            value={this.state.flatDiscount}
                                            onChange={this.handleFlatDiscountChange} />
                                    </div>

                                    <div className={"col-4 mx-0"}>
                                        <MDBInput label={"Paid Amount"} value={util.customRound(this.state.paidAmount)}
                                            disabled></MDBInput>
                                    </div>
                                    {this.state.isMixModeSelected && this.state.mixPaymentModeList.map((item, key) => {
                                        if (item.checked) {
                                            return <>
                                                <div className={"col-4 mx-0"}>
                                                    <MDBInput
                                                        label={item.text}
                                                        type="number"
                                                        value={this.state.mixmodePyamentBreakdown[item.text]}
                                                        onChange={(e) => this.mixModePayInput(e, item.text)}
                                                    ></MDBInput>
                                                </div>
                                            </>
                                        }
                                    })}
                                </>
                                }
                            </div>

                            <div className={"col-12 mx-0 p-0 row my-1"}>
                                <div className={"col-12 mx-0 d-flex justify-content-center"}>
                                    <MDBBtn size={'md'} disabled={this.state.cart.length === 0} color="primary"
                                        className="float-right mr-2 ml-2" onClick={this.placeOrder}>
                                        Place order
                                    </MDBBtn>
                                </div>
                            </div>
                        </div>
                    </div>

                </MDBBox>
                <MDBModal isOpen={this.state.modalProvider} toggle={() => this.toggle("Provider")} centered size={"lg"}>
                    <MDBModalHeader toggle={() => this.toggle("Provider")}>Doctor</MDBModalHeader>
                    <MDBModalBody>
                        <MDBBox>
                            <AddDoctor addDoctorCompleted={this.addDoctorCompleted} />
                        </MDBBox>
                    </MDBModalBody>
                </MDBModal>
                <MDBModal isOpen={this.state.modalInvoice} toggle={() => this.toggle("Invoice")} size="lg">
                    <MDBModalHeader toggle={() => this.toggle("Invoice")}>Invoice</MDBModalHeader>
                    <MDBModalBody>
                        <div className={'text-right'}>
                            <ReactToPrint
                                trigger={() => {
                                    return <a href="#" className="primary-text">
                                        Print the Invoice</a>;
                                }}
                                content={() => this.componentRef}
                            />
                        </div>
                        {this.state.orderResponse !== null &&
                            <OfflineOrderInvoice ref={el => this.componentRef = el}
                                orderDetails={this.state.orderResponse} doctorName = {this.state.doctorName} />
                        }
                    </MDBModalBody>
                </MDBModal>
                <MDBModal isOpen={this.state.modalPurchaseRequest} toggle={() => this.toggle("PurchaseRequest")} size="fluid"
                    full-height position="top">
                    <MDBModalHeader toggle={() => this.toggle("PurchaseRequest")}>
                        Add Purchase Request
                    </MDBModalHeader>
                    <MDBModalBody>
                        <AddProductRequest 
                            product={this.state.product} 
                            togglePurchaseRqst={() => this.toggle("PurchaseRequest")}
                        />
                    </MDBModalBody>
                </MDBModal>
                <AddSubscription 
                    isProductOpen={this.state.modalAddSubscription} 
                    toggleModal={() => this.setState({modalAddSubscription: false})} 
                    userData={this.state.userData} 
                    product={this.state.product}
                    customerDetails={Object.keys(this.state.patientDetails)?.length > 0 ? this.state.patientDetails : null}
                />
            </React.Fragment>
        )
    }
}

export default withRouter(FulfilOfflineOrder);