import React from "react";
import {withRouter} from "react-router-dom";
import UserStore from "../../stores/userStore";
import OrderService from "../../services/orderService";
import AuthService from "../../services/authService";
import EditProductShortcutComp from "../product/editProductShortcut";
import {
    MDBBtn, MDBCol, MDBInput, MDBModal, MDBModalBody, MDBModalHeader, MDBRow, MDBSelect,
    MDBIcon, MDBTable, MDBTableHead, MDBTableBody, MDBBox
} from "mdbreact";
import {toast} from "react-toastify";
import Dependents from "../Dependents/Dependents";
import AddDoctor from "../common/AddDoctor";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import PharmacyService from "../../services/pharmacyService";
import config from "../../config";
import util from "../../utility/utility";

class FulfillPrescriptionOrder extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            orderId: (props.match.params.orderId) ? props.match.params.orderId : '',
            orderDetails: [],
            customerDetails: [],
            userData: [],
            dependentList: [],
            dependentName: '',
            selectedDependentId: '',
            selectedDependentData: {},
            dependentsData: [],
            modalDependent: false,
            selectedDoctorData: {},
            allSuggestedProvider: {},
            doctorName: '',
            doctorContNo: '',
            selectedPaymentMode: 'CASH',
            searchedProviderText: '',
            fulfilOrderDate: new Date(),
            paymentModes: [{text: "Cash", value: "CASH", checked: true}, {text: "Card", value: "CARD"},
                {text: "Netbanking", value: "NETBANKING"}],
            searchedProductText: '',
            searchedProductList: [],
            carts: [],
            totalAmount: 0,
            prescriptionMedicineDetected: true,
            searchProviderInputCart: '',
            modalEditProductShortcut: false,
            productShortcutTobeEdited: {},
            disablePlaceOrderButton:false,
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.getUserData().then(userData => {
            this.setState({userData: userData})
            if (userData) {
                this.fetchPrescriptionOrder(userData).then(resOrd => {
                    if (resOrd && this._isMounted === true) {
                        this.setState({orderDetails: resOrd})
                        this.setState({customerDetails: resOrd.customerDetails})
                        if (this.state.customerDetails.userId && this.state.customerDetails.userId !== '') {
                            this.fetchDependentsByUserId(this.state).then(resDep => {
                                if (resDep && this._isMounted === true) {
                                    if (resDep.length > 0) {
                                        this.setState({dependentsData: resDep})
                                        this.populateDependentDropdown(resDep)
                                    }
                                } else {
                                    toast.error("Something went wrong while fetching dependent list.")
                                }
                            })
                        } else {
                            toast.error("UserId undefined.")
                        }
                    } else {
                        toast.error("Something went wrong while fetching order details.")
                    }
                })
            } else {
                toast.error("Something went wrong while fetching user data.")
            }
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    formatDate = (date, section) => {
        if (section === "FULFILL_ORDER") {
            return moment(date).format("llll")
        }
        if (section === "MFG_EXPIRY_DATE") {
            return moment(date).format("MMM YYYY");
        }
        return ""
    }
    getUserData = async () => {
        let userData = await UserStore.fetchSessionData();
        return userData
    }
    fetchPrescriptionOrder = async (userData) => {
        this.setState({progressStatus: true})
        const res = await OrderService.fetchPrescriptionOrderById(userData, this.state.orderId);
        this.setState({progressStatus: false})
        return res
    }
    /*dependent*/
    fetchDependentsByUserId = async (userData) => {
        this.setState({progressStatus: true})
        let res = await AuthService.fetchDependentsByUserId(userData, this.state.customerDetails.userId);
        this.setState({progressStatus: false})
        return res
    }
    toggleDependent = () => {
        this.setState({modalDependent: !this.state.modalDependent});
    }
    getSelectedDependentData = (depId, depData) => {
        for (let i = 0; i < depData.length; i++) {
            if (depData[i].dependent_id === depId) {
                return depData[i]
            }
        }
        return {}
    }
    handleDependent = (e) => {
        if(!e[0]) {return false}
        let nameAndDependentId = e[0].split("/")
        this.setState({dependentName: nameAndDependentId[0], selectedDependentId: nameAndDependentId[1]})
        let res = this.getSelectedDependentData(parseInt(nameAndDependentId[1]), this.state.dependentsData)
        this.setState({selectedDependentData: res})
        if (this.state.carts.length > 0) {
            this.setState({progressStatus: true})
            for (let i = 0; i < this.state.carts.length; i++) {
                this.handleDependentChange(i, res)
            }
            this.setState({progressStatus: false})
        }
    }
    addDependentCompleted = () => {
        this.fetchDependentsByUserId(this.state).then(res => {
            this.populateDependentDropdown(res)
        })
        this.toggleDependent();
    }
    populateDependentDropdown = (data) => {
        let name = '';
        let dependentList = []
        let string = {value: '', text: ''}
        if (data.length > 1) {
            for (let row of data) {
                name = row.name ? row.name : row.first_name + " " + row.last_name
                if (row.dependent_id === this.state.orderDetails.dependentId) {
                    string = {
                        value: name + " (" + row.relation + ")/" + row.dependent_id,
                        text: name + " (" + row.relation + ")",
                        checked: true
                    }
                } else {
                    string = {
                        value: name + " (" + row.relation + ")/" + row.dependent_id,
                        text: name + " (" + row.relation + ")"
                    }
                }
                dependentList.push(string)
            }
        } else {
            name = data[0].name ? data[0].name : data[0].first_name + " " + data[0].last_name
            string = {
                value: name + " (" + data[0].relation + ")/" + data[0].dependent_id,
                text: name + " (" + data[0].relation + ")",
                checked: true
            }
            dependentList.push(string)
        }
        this.setState({dependentList: dependentList})
    }
    /*payment mode*/
    handlePaymentMode = (e) => {
        if(!e[0]) {return false}
        this.setState({selectedPaymentMode: e[0]})
    }
    /*provider*/
    fetchProvider = (searchText) => async (e) => {
        try {
            if (e.target.value !== 0 || e.target.value !== '0') {
                if (searchText === 'forAll') {
                    this.setState({searchProviderInputCart: ''})
                    this.setState({searchedProviderText: e.target.value})
                } else {
                    this.setState({searchProviderInputCart: e.target.value})
                    this.setState({searchedProviderText: ''})
                }
                let suggestedProvider = await AuthService.searchProviderNew(e.target.value, this.state);
                this.setState({allSuggestedProvider: suggestedProvider});
            }
        } catch (e) {
            this.setState({allSuggestedProvider: []});
        }
    };
    selectDoctor = (row) => {
        this.setState({doctorName: row.name, doctorContNo: row.contactNo, selectedDoctorData: row,
            searchedProviderText: ""})
        if (this.state.carts.length > 0) {
            this.setState({progressStatus: true})
            for (let i = 0; i < this.state.carts.length; i++) {
                this.selectDoctorCart(i, row)
            }
            this.setState({progressStatus: false})
        }
    }
    toggleProvider = () => {
        this.setState({
            modalProvider: !this.state.modalProvider
        });
    }
    addDoctorCompleted = () => {
        toast.success("Doctor Added.");
        this.toggleProvider()
    }
    /*cart operations*/
    initChangeProvider = (index) => {
        let existingData = this.state.carts;
        existingData[index].showChangeProviderOptions = true;
        existingData[index].showChangePatientOptions = false;
        this.setState({carts: existingData})
    };
    closeChangeProvider = (index) => {
        let existingData = this.state.carts;
        existingData[index].showChangeProviderOptions = false;
        this.setState({carts: existingData, searchProviderInputCart: ""})
    };
    selectDoctorCart = (index, providerData) => {
        let existingCarts = this.state.carts;
        existingCarts[index].doctorDetails = providerData
        existingCarts[index].showChangeProviderOptions = false;
        this.setState({carts: existingCarts, searchProviderInputCart: ""});
    }

    initChangePatient = (index) => {
        let existingData = this.state.carts;
        existingData[index].showChangePatientOptions = true;
        existingData[index].showChangeProviderOptions = false;
        this.setState({carts: existingData})
    };
    closeChangePatient = (index) => {
        let existingData = this.state.carts;
        existingData[index].showChangePatientOptions = false;
        this.setState({carts: existingData})
    };
    handleDependentChange = (index, itemDL) => {
        let existingCarts = this.state.carts;
        existingCarts[index].selectedDependent = itemDL
        existingCarts[index].showChangePatientOptions = false;
        this.setState({carts: existingCarts});
    }

    handleQuantity = (index) => (e) => {
        if (e.target.value !== "" && !util.isInt(e.target.value)) {
            return false;
        }
        let existingData = this.state.carts;
        /*Check stock availability*/
        if (parseInt(existingData[index].inventory.quantity) < parseInt(e.target.value)) {
            existingData[index].quantity = parseInt(existingData[index].inventory.quantity)
        }else{
            existingData[index].quantity = e.target.value
        }
        //skipping loose sale quantity check, it creates trouble when loose sale quantity is more than 1
        this.setState({carts: existingData})
        this.calculateTotalAmount(this.state.carts)
    };
    handleDiscount = (index) => (e) => {
        if (!util.isNumericTwoDecimalPartial(e.target.value) && e.target.value !== "") {
            return false;
        }
        let existingData = this.state.carts;
        let discountPercent = e.target.value
        existingData[index].discount = discountPercent
        this.setState({carts: existingData})
        this.calculateTotalAmount(this.state.carts)
    }
    removeFromCart = async (index, item) => {
        let existingCarts = this.state.carts;
        if (existingCarts[index].productId === item.productId) {
            existingCarts.splice(index, 1);
            this.setState({"carts": existingCarts})
        }
        this.calculateTotalAmount(this.state.carts)
    }

    toggleEditProductShortcut = () => {
        this.setState({
            modalEditProductShortcut: !this.state.modalEditProductShortcut
        });
    }
    editProductCompleted = () => {
        this.toggleEditProductShortcut();
    }
    editProductShortcut = (row) => {
        this.setState({productShortcutTobeEdited: row})
        this.toggleEditProductShortcut()
    }

    fetchProduct = async (e) => {
        try {
            if (e.target.value !== 0 || e.target.value !== '0') {
                this.setState({searchedProductText: e.target.value})
                if (e.target.value.length < 2) {
                    return false;
                }
                let suggestedProduct = await PharmacyService.searchProductOrder(e.target.value, this.state);
                if (suggestedProduct === false) {
                    toast.error("No internet.")
                }
                let filteredProducts = []
                /*1.Filtering empty inventory products and
                  2.Some products have multirow inventory data, making it single row array*/
                for (let product of suggestedProduct) {
                    for (let inventoryItem of product.inventory) {
                        let newProductObj = Object.assign({}, product);
                        delete newProductObj.inventory;
                        newProductObj.inventory = Object.assign({}, inventoryItem);
                        filteredProducts.push(newProductObj)
                    }
                }
                this.setState({searchedProductList: filteredProducts})
            }
        } catch (e) {
            this.setState({searchedProductList: []})
        }
    };
    closeSearch = () => {
        this.setState({medicine: "", searchedProductList: []})
    }
    addItemToCartLocal = (addedProduct) => {
        // Check if the item already exist
        for (let i = 0; i < this.state.carts.length; i++) {
            if (addedProduct.inventory._id === this.state.carts[i].inventory._id) {
                let newCart = this.state.carts
                if(parseInt(newCart[i].quantity) === parseInt(newCart[i].inventory.quantity)){
                    this.setState({searchedProductText: ""})
                    return false
                }
                newCart[i].quantity = parseInt(newCart[i].quantity) + 1
                this.setState({carts: newCart})
                this.calculateTotalAmount(this.state.carts)
                this.setState({searchedProductText: ""})
                return false;
            }
        }
        let newItem = addedProduct;
        /*check if loose sale allowed, if not quantity set to 1*/
        (newItem.looseSaleAllowed === "Y") ? newItem.quantity = parseInt(newItem.minimumLooseSaleQuantity) : newItem.quantity = 1 ;
        /*check availability of minimum loose sale quantity*/
        if ((newItem.looseSaleAllowed === "Y") && (newItem.minimumLooseSaleQuantity > newItem.inventory.quantity)) {
            toast.info("Minimum loose sale quantity unavailable.")
            return false
        }
        if (newItem.contentQuantity > 0) {
            if (newItem.looseSaleAllowed === "Y" && newItem.contentQuantity > 1) {
                newItem.subTotal = newItem.inventory.mrp / newItem.contentQuantity
                newItem.perUnitPrice = newItem.inventory.mrp / newItem.contentQuantity
            } else {
                newItem.subTotal = newItem.inventory.mrp
                newItem.perUnitPrice = newItem.inventory.mrp
            }
        } else {
            toast.error("Invalid product details. Please update the data and then search and add to cart.")
            return false;
        }
        newItem.selectedDependent = this.state.selectedDependentData;
        newItem.customerData = this.state.customerDetails;
        newItem.doctorDetails = this.state.selectedDoctorData;
        newItem.showChangePatientOptions = false
        newItem.showChangeProviderOptions = false;
        newItem.inventoryData = {_id: addedProduct._id}; // This is to satisfy the api
        newItem.medicineTypeClass = this.isPrescriptionMandatory(addedProduct)
        this.state.carts.push(newItem);
        this.calculateTotalAmount(this.state.carts)
        this.setState({searchedProductText: ""})
    }
    isPrescriptionMandatory = (addedProduct) => {
        let medicineTypeClass = {type: '', class: ''};
        if (addedProduct.hasOwnProperty("productName")) {
            if (addedProduct.hasOwnProperty("isMedicine") && addedProduct.isMedicine) {
                if (addedProduct.hasOwnProperty('medicineType')) {
                    if (addedProduct.medicineType.trim() === "Schedule H" || addedProduct.medicineType.trim() === "Schedule H1" || addedProduct.medicineType.trim() === "Schedule X" || addedProduct.medicineType.trim() === "PRESCRIPTION DRUGS") {
                        medicineTypeClass.class = 'red-text'
                        toast.info("This medicine is a prescription drug and prescription is mandatory.")
                        this.setState({prescriptionMedicineDetected: true})
                    } else {
                        medicineTypeClass.class = 'green-text'
                    }
                    medicineTypeClass.type = addedProduct.medicineType
                } else {
                    medicineTypeClass.type = 'NOT SPECIFIED'
                }
            } else {
                medicineTypeClass.type = 'GENERAL'
            }
        }
        return medicineTypeClass
    }
    calculateTotalAmount = (carts) => {
        let total = 0, subTotal = 0, discount = 0;
        let newCart = []
        for (let i = 0; i < carts.length; i++) {
            subTotal = carts[i].quantity * carts[i].perUnitPrice
            discount = subTotal * ((carts[i].discount) / 100)
            subTotal = subTotal - discount
            carts[i].subTotal = subTotal
            newCart.push(carts[i])
            total = total + subTotal
        }
        this.setState({carts: newCart, totalAmount: total})
    }
    calculateUnit = (item) => {
        try {
            // cq is the content Quantity "10"
            let cq = item.contentQuantity;
            // qu quantity unit ex. capsule, tablets
            let qu = item.quantityUnit.toLowerCase();
            let quu = qu;
            // make sure to pluralize the qunaitity unit by adding "s" in the end for these cases
            if ((qu === "tablet" || qu === "capsule" || qu === "respule" || qu === "syringe" || qu === "sachet" || qu === "kilogram" || qu === "tube" || qu === "device" || qu === "gram") && (item.contentQuantity > 1)) {
                quu = (qu + "s").toLowerCase();
            }
            // there are some exceptions
            else if (qu === "gm" && item.contentQuantity > 1) {
                quu = "grams"
            } else if (qu === "kilo" && item.contentQuantity > 1) {
                quu = "kilograms"
            }
            return cq + " " + quu;
        } catch (e) {
            return ""
        }
    }

    /*datepicker*/
    handleFulfilOrderDate = (e) => {
        this.setState({fulfilOrderDate: e})
    };
    placePrescriptionOrder = () => {
        let productList = []
        for(let i=0; i<this.state.carts.length; i++) {
            let newRow = this.arrangeProductList(this.state.carts[i])
            productList.push(newRow)
        }
        let payload = this.preparePayload(productList)
        this.processPrescriptionOrderService(payload).then((ordRes) => {
            if(ordRes) {
                toast.success("Placed order successfully requesting for payment.")
                this.setState({disablePlaceOrderButton: true})
            }else{
                toast.error("Something went wrong while placing order.")
            }
        })
    }
    arrangeProductList = (row) => {
        let product = {}
        product._id = row._id
        product.quantity = row.quantity.toString()
        product.contentQuantity = row.contentQuantity
        product.numberOfSellableUnits = row.numberOfSellableUnits
        product.looseSaleAllowed = row.looseSaleAllowed
        product.minimumLooseSaleQuantity = row.minimumLooseSaleQuantity
        product.discount = row.discount.toString()
        product.inventory = row.inventory
        product.patientDetails = row.selectedDependent
        product.doctorDetails = row.doctorDetails
        product.productName =  row.productName
        product.medicineType = row.medicineType
        product.isMedicine = row.isMedicine
        return product
    }
    preparePayload = (productList) => {
        let data = {orgId:'', _id:'', products:'', totalAmount: ''}
        data.orgId = this.state.userData.org[0]._id
        data._id = this.state.orderDetails._id
        data.products = productList
        data.totalAmount = this.state.totalAmount
        return data
    }
    processPrescriptionOrderService = async (data) => {
        this.setState({progressStatus: true})
        let res = await OrderService.processPrescriptionOrder(this.state.userData, data);
        this.setState({progressStatus: false})
        return res
    }
    render() {
        return (
            <React.Fragment>
                <MDBBox>
                    <MDBRow>
                        <MDBCol size={"5"} className={'white border rounded p-3'}>
                            <div>
                                <span><b>Order Id</b>: </span>
                                <span>&nbsp;&nbsp;{this.state.orderDetails.orderCode}&nbsp;&nbsp;</span><br/>
                                <span><b>Customer Name</b>: </span>
                                <span
                                    className="font-weight-bold">&nbsp;&nbsp;{this.state.customerDetails.name}</span><br/>
                                <span><b>Contact No</b>: </span>
                                <span>&nbsp;&nbsp;{this.state.customerDetails.contactNo}&nbsp;&nbsp;</span><br/>
                                <span><b>Patient</b>: </span>
                                <span>&nbsp;&nbsp;{this.state.dependentName}&nbsp;&nbsp;</span><br/><p/>
                                <span><b>Payment Mode</b>: </span>
                                <span>&nbsp;&nbsp;{this.state.selectedPaymentMode}&nbsp;&nbsp;</span><br/>
                                <span><b>Doctor Name</b>: </span>
                                <span>&nbsp;&nbsp;{this.state.doctorName}&nbsp;&nbsp;</span><br/>
                                <span><b>Doctor Contact No</b>: </span>
                                <span>&nbsp;&nbsp;{this.state.doctorContNo}&nbsp;&nbsp;</span><br/>
                                <span><b>Order Fulfilment Date</b>: </span>
                                <span>&nbsp;&nbsp;{this.formatDate(this.state.fulfilOrderDate, 'FULFILL_ORDER')}&nbsp;&nbsp;</span><br/>
                            </div>
                        </MDBCol>
                        <MDBCol size={"7"}>
                            <MDBRow>
                                <MDBCol size={"7"}>
                                    <MDBSelect disabled={this.state.dependentList.length === 1} outline={true}
                                               label={"Dependent List"} getValue={this.handleDependent}
                                               options={this.state.dependentList} className={"mt-0"}></MDBSelect>
                                </MDBCol>
                                <MDBCol size={"4"}><MDBBtn size={"sm"} color={"default"} onClick={this.toggleDependent}
                                                           className={"btn btn-lg btn-block mt-0"}>Add
                                    Dependent</MDBBtn></MDBCol>
                                <MDBCol size={"1"}></MDBCol>
                            </MDBRow>
                            <MDBRow className={"mt-n4"}>
                                <MDBCol size={"7"}>
                                    <MDBInput label="Search doctor by name, phone or e-mail" outline={true}
                                              maxLength="100"
                                              type='text' onChange={this.fetchProvider('forAll')}
                                              value={this.state.searchedProviderText}/>
                                    {(this.state.allSuggestedProvider.length > 0 && this.state.searchedProviderText) &&
                                    <div className="card mt-n4 position-absolute mb-0 pb-0" style={{zIndex: 100}}>
                                        <ul className="list-group list-group-flush">
                                            {this.state.allSuggestedProvider.map((row, index) => (
                                                <li key={index} className="list-group-item">
                                                    <MDBRow style={{"fontSize": "13px"}}>
                                                        <MDBCol size={"7"}>
                                                            {row.hasOwnProperty("name") && row.name &&
                                                            <div>{row.name}</div>
                                                            }
                                                            {row.hasOwnProperty("contactNo") && row.contactNo &&
                                                            <div>{row.contactNo}</div>
                                                            }
                                                        </MDBCol>
                                                        <MDBCol size={"3"}>
                                                            <MDBBtn color={"default"} size={"sm"}
                                                                    onClick={() => this.selectDoctor(row)}>Select</MDBBtn>
                                                        </MDBCol>
                                                    </MDBRow>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    }
                                </MDBCol>
                                <MDBCol size={"4"}><MDBBtn onClick={this.toggleProvider} size={"sm"} color={"default"}
                                                           className={"btn btn-lg btn-block mt-4"}>New
                                    Doctor</MDBBtn></MDBCol>
                                <MDBCol size={"1"}></MDBCol>
                            </MDBRow>
                            <MDBRow className={"mt-n4"}>
                                <MDBCol size={"7"}>
                                    <MDBSelect outline={true} label={"Payment Mode"} getValue={this.handlePaymentMode}
                                               options={this.state.paymentModes}></MDBSelect>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol size={"7"}>
                                    <MuiPickersUtilsProvider utils={MomentUtils} className={"text-primary"}>
                                        <KeyboardDatePicker
                                            autoOk
                                            inputVariant="outlined"
                                            className={"mt-0"}
                                            variant="inline"
                                            format="MM/DD/YYYY"
                                            margin="normal"
                                            id="date-picker-inline"
                                            label="Order Fulfil Date"
                                            value={this.state.fulfilOrderDate}
                                            onChange={this.handleFulfilOrderDate}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol className={"mt-n2 ml-n3"}>
                            <MDBInput label="Search Product" outline={true} maxLength="100"
                                      type='text' onChange={this.fetchProduct}
                                      value={this.state.searchedProductText} className={"border-primary"}/>
                            {(this.state.searchedProductList.length > 0 && this.state.searchedProductText) &&
                            <div className="card bg-light position-absolute mt-n4" style={{"zIndex": 1, "width":"90%"}}>
                                <ul className="list-group list-group-flush" style={{"width":"100%"}}>
                                    {this.state.searchedProductList.map((row, index) => (
                                        <li key={index} className="list-group-item mb-1 rounded">
                                            <MDBRow style={{"fontSize": "13px"}}>
                                                <MDBCol size={"2"}>
                                                    {(row.hasOwnProperty("imageUrl") && row.imageUrl.length > 0) &&
                                                    <img width={"100"} alt={""}
                                                         src={config.IMAGE_HOST + row.imageUrl[0].filePathThumb}/>
                                                    }
                                                    {((row.hasOwnProperty("imageUrl") && row.imageUrl.length === 0)) &&
                                                    <img alt={""} width={"100"}
                                                         src={require('../../components/common/img/broken-img.jpg')}/>
                                                    }
                                                </MDBCol>
                                                <MDBCol size={"5"}><span
                                                    className={"text-uppercase h6"}><b>{row.productName} ({this.calculateUnit(row)})</b></span>
                                                    <div>Mfg: {this.formatDate(row.inventory.mfgDate, "MFG_EXPIRY_DATE")} -
                                                        Exp: {this.formatDate(row.inventory.expDate, 'MFG_EXPIRY_DATE')}</div>
                                                    {(row.hasOwnProperty("mfg")) &&
                                                    <div>Manufacturer:&nbsp;<span>{row.mfg.manufacturer}</span></div>
                                                    }
                                                    <div>Loose Sale Allowed?: {row.looseSaleAllowed}</div>
                                                    {row.looseSaleAllowed === "Y" &&
                                                    <div>Minimum Sale
                                                        Quantity: {row.minimumLooseSaleQuantity} {row.quantityUnit}</div>
                                                    }
                                                    <div className={"blue-text"}
                                                         style={{"cursor": "pointer"}}
                                                         onClick={() => this.editProductShortcut(row)}>Quick Edit</div>
                                                </MDBCol>
                                                <MDBCol size={"2"}>
                                                    {row.outOfStock === "N" &&
                                                    <div><span className={"green-text"}>In Stock</span></div>
                                                    }
                                                    {row.outOfStock === "Y" &&
                                                    <div><span className={"red-text"}>Out of Stock</span></div>
                                                    }
                                                    <div>MRP: <span
                                                        dangerouslySetInnerHTML={{__html: config.CURRENCY_HTML}}></span> {row.inventory.mrp.toFixed(2)}
                                                    </div>
                                                    <div>Qty: {row.inventory.quantity}</div>
                                                    <div>Batch: {row.inventory.batch}</div>
                                                </MDBCol>
                                                <MDBCol size={"3"}>
                                                    <MDBBtn color={"default"} size={"sm"}
                                                            onClick={() => this.addItemToCartLocal(row)}>Add To
                                                        Cart</MDBBtn>
                                                    <span style={{"cursor": "pointer"}} className={"p-1 mt-3"}
                                                          onClick={this.closeSearch}><MDBIcon
                                                        icon="times-circle"/></span>
                                                </MDBCol>
                                            </MDBRow>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            }

                        </MDBCol>
                    </MDBRow>
                    <MDBRow className={'white border rounded mt-n2'}>
                        <MDBCol>
                            <MDBTable small={true}>
                                <MDBTableHead>
                                    <tr>
                                        <th>Product</th>
                                        <th>Batch</th>
                                        <th>Type</th>
                                        <th>Expiry date</th>
                                        <th>MRP per unit</th>
                                        <th>GST (%)</th>
                                        <th>Quantity</th>
                                        <th>Discount (%)</th>
                                        <th>Subtotal</th>
                                        {this.state.prescriptionMedicineDetected &&
                                        <th>Patient</th>
                                        }
                                        {this.state.prescriptionMedicineDetected &&
                                        <th>Doctor</th>
                                        }
                                        <th>Action</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {this.state.carts.map((row, index) => (
                                        <tr key={index + "_cart"} className={"pt-1 mt-1"}>
                                            <td>{row.productName}</td>
                                            <td>{row.inventory.batch}</td>
                                            <td><span
                                                className={row.medicineTypeClass.class}>{row.medicineTypeClass.type}</span>
                                            </td>
                                            <td>{this.formatDate(row.inventory.expDate, "MFG_EXPIRY_DATE")}</td>
                                            <td>{row.perUnitPrice.toFixed(2)}</td>
                                            <td>{row.inventory.gst}</td>
                                            <td>
                                                <div className={"mt-n4"}>
                                                    <MDBInput style={{"width": "5rem"}} outline={true} maxLength="5"
                                                              type='text' onChange={this.handleQuantity(index)}
                                                              value={this.state.carts[index].quantity} placeholder={"Qty:"+this.state.carts[index].inventory.quantity}/>
                                                </div>
                                            </td>
                                            <td>
                                                <div className={"mt-n4"}>
                                                    <MDBInput style={{"width": "5rem"}} outline={true} maxLength="5"
                                                              type='text' onChange={this.handleDiscount(index)}
                                                              value={this.state.carts[index].discount}/>
                                                </div>
                                            </td>
                                            <td>{row.subTotal.toFixed("2")}</td>

                                            {this.state.prescriptionMedicineDetected &&
                                            <td size={"2"}>
                                                {row.medicineTypeClass.class && row.medicineTypeClass.class === 'red-text' &&
                                                <div>
                                                    {((this.state.carts[index].selectedDependent.hasOwnProperty("name") && this.state.carts[index].selectedDependent.name) || (this.state.carts[index].selectedDependent.hasOwnProperty("first_name") && this.state.carts[index].selectedDependent.first_name)) &&
                                                    <div>
                                                        {(this.state.carts[index].selectedDependent.first_name) ? (this.state.carts[index].selectedDependent.first_name + " " + this.state.carts[index].selectedDependent.last_name) : (this.state.carts[index].selectedDependent.name)}
                                                        {!this.state.carts[index].showChangePatientOptions &&
                                                        <div className={"blue-text cursor-on p-1"}
                                                             style={{cursor: "pointer"}}
                                                             onClick={() => this.initChangePatient(index)}><b>Change</b>
                                                        </div>
                                                        }
                                                        {this.state.carts[index].showChangePatientOptions &&
                                                        <div className={"blue-text cursor-on p-1"}
                                                             style={{cursor: "pointer"}}
                                                             onClick={() => this.closeChangePatient(index)}><b>Close</b>
                                                        </div>
                                                        }
                                                    </div>}
                                                    {this.state.carts[index].showChangePatientOptions &&
                                                    <div className={"card"} style={{
                                                        position: "absolute", "zIndex": 1,
                                                        maxHeight: "10rem", overflowY: "scroll"
                                                    }}>
                                                        {this.state.dependentsData.map((itemDL, indexDL) => (
                                                            <MDBInput key={indexDL}
                                                                      onClick={() => this.handleDependentChange(index, itemDL)}
                                                                      checked={this.state.selectedDependentData.dependent_id === itemDL.dependent_id ? true : false}
                                                                      label={(itemDL.first_name ? (itemDL.first_name + " " + itemDL.last_name) : (itemDL.name))}
                                                                      type='radio' id={'DL_' + indexDL}
                                                                      containerClass='mr-5'/>

                                                        ))}
                                                    </div>
                                                    }
                                                </div>
                                                }
                                                {row.medicineTypeClass.class && row.medicineTypeClass.class === 'green-text' &&
                                                <span>N/A</span>
                                                }
                                            </td>
                                            }
                                            {this.state.prescriptionMedicineDetected &&
                                            <td size={"2"}>
                                                {row.medicineTypeClass.class && row.medicineTypeClass.class === 'red-text' &&
                                                <div>
                                                    {row.doctorDetails.name}
                                                    {(!this.state.carts[index].showChangeProviderOptions && row.doctorDetails.name) &&
                                                    <div className={"blue-text cursor-on p-1"}
                                                         style={{cursor: "pointer"}}
                                                         onClick={() => this.initChangeProvider(index)}><b>Change</b>
                                                    </div>
                                                    }
                                                    {this.state.carts[index].showChangeProviderOptions &&
                                                    <div className={"blue-text cursor-on p-1"}
                                                         style={{cursor: "pointer"}}
                                                         onClick={() => this.closeChangeProvider(index)}><b>Close</b>
                                                    </div>
                                                    }
                                                    {this.state.carts[index].showChangeProviderOptions &&
                                                    <div>
                                                        <MDBInput label="Search Doctor" outline={true}
                                                                  type='text' onChange={this.fetchProvider('forCart')}
                                                                  value={this.state.searchProviderInputCart}
                                                                  className={"mt-n2 border-primary"}/>
                                                        {(this.state.allSuggestedProvider.length > 0 && this.state.searchProviderInputCart) &&
                                                        <div className="card mt-n2 ml-n5 position-absolute pb-0 mb-0"
                                                             style={{zIndex: 1}}>
                                                            <ul className="list-group list-group-flush">
                                                                {this.state.allSuggestedProvider.map((rowPr, indexPr) => (
                                                                    <li key={indexPr} className="list-group-item">
                                                                        <MDBRow style={{"fontSize": "13px"}}>
                                                                            <MDBCol size={"7"}>
                                                                                {rowPr.hasOwnProperty("name") && rowPr.name &&
                                                                                <div>{rowPr.name}</div>
                                                                                }
                                                                                {rowPr.hasOwnProperty("contactNo") && rowPr.contactNo &&
                                                                                <div>{rowPr.contactNo}</div>
                                                                                }
                                                                            </MDBCol>
                                                                            <MDBCol size={"5"}>
                                                                                <MDBBtn color={"default"} size={"sm"}
                                                                                        className={"p-1"}
                                                                                        onClick={() => this.selectDoctorCart(index, rowPr)}>Change</MDBBtn>
                                                                            </MDBCol>
                                                                        </MDBRow>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                        }
                                                    </div>
                                                    }
                                                </div>
                                                }
                                                {row.medicineTypeClass.class && row.medicineTypeClass.class === 'green-text' &&
                                                <span>N/A</span>
                                                }
                                            </td>
                                            }
                                            <td>
                                                <div style={{"cursor": "pointer"}}
                                                     onClick={() => this.removeFromCart(index, row)}><MDBIcon
                                                    icon="trash"/>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </MDBTableBody>
                            </MDBTable>
                        </MDBCol>
                    </MDBRow>
                    {this.state.carts.length > 0 &&
                    <MDBRow>
                        <MDBCol>
                            <MDBBtn disabled={this.state.totalAmount < 1 || this.state.disablePlaceOrderButton} color="default"
                                    className="float-right mr-2 ml-2" onClick={this.placePrescriptionOrder}>
                                Place order
                            </MDBBtn>
                            <span className="float-right mt-3"><b>Total Amount: <span
                                dangerouslySetInnerHTML={{__html: config.CURRENCY_HTML}}></span> {this.state.totalAmount.toFixed("2")}</b></span>
                        </MDBCol>
                    </MDBRow>
                    }
                    <MDBModal isOpen={this.state.modalDependent} toggle={this.toggleDependent} size={"fluid"}>
                        <MDBModalHeader toggle={this.toggleDependent}>Family & Friends</MDBModalHeader>
                        <MDBModalBody>
                            <MDBRow>
                                <MDBCol className={"p-3"}>
                                    <Dependents user={this.state.customerDetails}
                                                addDependentCompleted={this.addDependentCompleted}/>
                                </MDBCol>
                            </MDBRow>
                        </MDBModalBody>
                    </MDBModal>
                    <MDBModal isOpen={this.state.modalProvider} toggle={this.toggleProvider} size={"lg"}>
                        <MDBModalHeader toggle={this.toggleProvider}>Doctor</MDBModalHeader>
                        <MDBModalBody>
                            <MDBRow>
                                <AddDoctor addDoctorCompleted={this.addDoctorCompleted}/>
                            </MDBRow>
                        </MDBModalBody>
                    </MDBModal>
                    <MDBModal isOpen={this.state.modalEditProductShortcut} toggle={this.toggleEditProductShortcut}
                              size={"fluid"}>
                        <MDBModalHeader toggle={this.toggleEditProductShortcut}>Edit Product</MDBModalHeader>
                        <MDBModalBody>
                            <MDBRow>
                                <MDBCol className={"p-3"}>
                                    <EditProductShortcutComp user={this.state.customerDetails}
                                                             editProductCompleted={this.editProductCompleted}
                                                             product={this.state.productShortcutTobeEdited}/>
                                </MDBCol>
                            </MDBRow>
                        </MDBModalBody>
                    </MDBModal>
                </MDBBox>
            </React.Fragment>
        )
    }
}
export default withRouter(FulfillPrescriptionOrder);
