import { MDBBtn, MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader } from "mdbreact";
import React, { useState } from "react";
import FilterStockTable from "./FilterStockTable";

const RelocateModal = ({isModalOpen,setIsModalOpen,relocateHandler}) => {

    const [relocateTo, setRelocateTo] = useState({
        locationId: "",
        floorId: "",
        rackId: "",
        boxId: "",
      })

  
  return (
    <MDBModal
      isOpen={isModalOpen}
      toggle={() => setIsModalOpen(false)}
      size="lg"
    >
      <MDBModalHeader toggle={() => setIsModalOpen(false)}>
        Relocate Rack
      </MDBModalHeader>
      <MDBModalBody className={"float-center"}>
        <h4>Transfer the selected Rack to: </h4>
        <div className="mt-4 mb-4">
            <FilterStockTable filter={relocateTo} setFilter={setRelocateTo} isBoxRequired = {true}/>
        </div>
      </MDBModalBody>
      <MDBModalFooter>
        <MDBBtn
          color="primary"
          outline
          onClick={() => setIsModalOpen(false)}
          size={"sm"}
        >
          Cancel
        </MDBBtn>
        <MDBBtn color="default" onClick={()=>relocateHandler(relocateTo)} size={"sm"}>
          Relocate
        </MDBBtn>
      </MDBModalFooter>
    </MDBModal>
  );
};

export default RelocateModal;
