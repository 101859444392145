import React from "react";
import {
    MDBBreadcrumb,
    MDBBreadcrumbItem,MDBCol
} from 'mdbreact';
import InventoryService from "../../services/inventoryService";
import UserStore from "../../stores/userStore";
import PharmacyService from "../../services/pharmacyService";
import MonthlySalesWidget from "../../components/widgets/monthlySales";
import MonthlyShipmentsWidget from "../../components/widgets/monthlyShipments";
import MonthlyExpiredWidget from "../../components/widgets/monthlyExpired";
import CashCredit from "../../components/widgets/cashCredit";
import OrderUpdate from "../../components/widgets/orderUpdate";
import StocksUpdate from "../../components/widgets/stocksUpdate";
import CustomerDetails from "../../components/widgets/customerDetails";
import OrderDetails from "../../components/widgets/orderDetails";

export default class Dashboard extends React.Component {
    async componentDidMount() {
        let userData=await UserStore.fetchSessionData();

        // let data = await InventoryService.fetchProduct(userData, 0, 0, this.state.type);
        // //window.productList = data;
        // localStorage.setItem('products', JSON.stringify(data));

        //
        let res = await PharmacyService.fetchSupplier(userData, 0, 0);
        window.suppliers=res;
        //
        let cat = await InventoryService.fetchCategories(userData, 'all');
        window.category=cat;
        //
        let mfg = await PharmacyService.fetchManufacturer(userData);
        window.mfg=mfg;
        //
        let retailPkgs = await PharmacyService.fetchRetailPackages(userData, 0, 0, 'all');
        window.retailPkgs=retailPkgs;
        //
        let qntyUnits = await PharmacyService.fetchQuantityUnits(userData, 0, 0, 'all');
        window.qntyUnits=qntyUnits;

        // let shipment = await PharmacyService.fetchShipmentsCache(userData);
        // window.shipment=shipment;

        let guaranteedAnaList = await InventoryService.fetchGetData(userData.sessionToken, "FETCH_GUARANTEED_ANALYSIS")
        window.guaranteedAnaList = guaranteedAnaList

        let productIngredientList = await InventoryService.fetchGetData(userData.sessionToken, "FETCH_INGREDIENT")
        window.productIngredientList = productIngredientList

        let manufacturerList = await InventoryService.fetchGetData(userData.sessionToken, "FETCH_MFG")
        window.manufacturerList = manufacturerList

        let productCategoryList = await InventoryService.fetchGetData(userData.sessionToken, "FETCH_CATEGORY")
        window.productCategoryList = productCategoryList

        let specialDietList = await InventoryService.fetchGetData(userData.sessionToken, "FETCH_SPECIAL_DIET")
        window.specialDietList = specialDietList

        let retailPackagingList = await InventoryService.fetchGetData(userData.sessionToken, "FETCH_RETAIL_PKG")
        window.retailPackagingList = retailPackagingList

        let packagingUnitList = await InventoryService.fetchGetData(userData.sessionToken, "FETCH_UNIT")
        window.packagingUnitList = packagingUnitList

        let productFormList = await InventoryService.fetchGetData(userData.sessionToken, "FETCH_PRODUCT_FORM")
        window.productFormList = productFormList

        let productFlavorList = await InventoryService.fetchGetData(userData.sessionToken, "FETCH_FLAVOR")
        window.productFlavorList = productFlavorList

        let productTextureList = await InventoryService.fetchGetData(userData.sessionToken, "FETCH_TEXTURE")
        window.productTextureList = productTextureList

        let productColorList = await InventoryService.fetchGetData(userData.sessionToken, "FETCH_COLOR")
        window.productColorList = productColorList

        let lifeStageList = await InventoryService.fetchGetData(userData.sessionToken, "FETCH_LIFE_STAGE")
        window.lifeStageList = lifeStageList

        let breedSizeList = await InventoryService.fetchGetData(userData.sessionToken, "FETCH_BREED_SIZE")
        window.breedSizeList = breedSizeList

        let healthBenefitList = await InventoryService.fetchGetData(userData.sessionToken, "FETCH_HEALTH_BENEFITS")
        window.healthBenefitList = healthBenefitList

        let sideEffectList = await InventoryService.fetchGetData(userData.sessionToken, "FETCH_SIDE_EFFECTS")
        window.sideEffectList = sideEffectList

        let productMaterialList = await InventoryService.fetchGetData(userData.sessionToken, "FETCH_MATERIAL")
        window.productMaterialList = productMaterialList

        let medicineTypeList = await InventoryService.fetchGetData(userData.sessionToken, "FETCH_MEDICINE_SCHEDULE")
        window.medicineTypeList = medicineTypeList

        let toyFeatureList = await InventoryService.fetchGetData(userData.sessionToken, "FETCH_TOY_FEATURE")
        window.toyFeatureList = toyFeatureList

        let toyCollectionList = await InventoryService.fetchGetData(userData.sessionToken, "FETCH_TOY_COLLECTION")
        window.toyCollectionList = toyCollectionList

        let toyScentList = await InventoryService.fetchGetData(userData.sessionToken, "FETCH_PRODUCT_SCENT")
        window.toyScentList = toyScentList

        let productTypeList = await InventoryService.fetchGetData(userData.sessionToken, "FETCH_PRODUCT_TYPE")
        window.productTypeList = productTypeList

    }

    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        const role = localStorage.getItem('role')
        return (
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem active>Dashboard</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <div className={"col-12 grey lighten-5 min-vh-100"}>
                    {/*<h5 className={'font-weight-bold px-4 py-2'}>At a glance</h5>*/}
                    {/*<div className={'col-12 row mx-0'}>*/}
                    {/*    <div className={'col-md-3 p-2'}>*/}
                    {/*        <OrderUpdate/>*/}
                    {/*    </div>*/}
                    {/*    <div className={'col-md-3 p-2'}>*/}
                    {/*        <StocksUpdate/>*/}
                    {/*    </div>*/}
                    {/*    <div className={'col-md-6 p-2'}>*/}
                    {/*        <CustomerDetails/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<hr className={'my-5'}/>*/}
                    {/*<h5 className={'font-weight-bold px-4 py-2'}>Infographics</h5>*/}
                    {role ==='ORG_ADMIN' && <div className={'col-12 row mx-0'}>
                        {/*<MDBCol className={'col-md-6 p-2'}><MonthlySalesWidget/></MDBCol>
                        <MDBCol className={'col-md-6 p-2'}><MonthlyShipmentsWidget/></MDBCol>
                        <MDBCol className={'col-md-6 p-2'}><MonthlyExpiredWidget/></MDBCol>
                        <MDBCol className={'col-md-6 p-2'}><CashCredit/></MDBCol>
                        <MDBCol className={'col-md-6 p-2'}><OrderDetails/></MDBCol>*/}
                    </div>}
                    
                </div>
            </React.Fragment>
        );
    }
}


