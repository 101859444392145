import React from "react";
import OrderService from "../../services/orderService";
import UserStore from "../../stores/userStore";
import { toast } from "react-toastify";
import SimpleBackdrop from "../common/overlay";
import Moments from "moment";
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
} from "mdbreact";
import NewTable from "../../newComponents/NewTable/NewTable";
import NewPagination from "../../newComponents/NewPagination/NewPagination";
import ViewProductsRequsetModal from "../ViewProductReqModal";
 
 
 
const columns = [
  { header: "Created At", width: "5%", accessor: "createdAt", sortable: true },
  { header: "Product Name", width: "8%", accessor: "productName" },
  { header: "Customer Name", width: "8%", accessor: "userName" },
  { header: "Frequency", width: "5%", accessor: "frequency" },
  {
    header: "Quantity",
    width: "5%",
    accessor: "quantity",
  },
  {
    header: "Next Shipment",
    width: "5%",
    accessor: "nextShipment",
  },
  { header: "Status", width: "5%", accessor: "status" },
  { header: "Action", width: "5%", accessor: "action" },
];
 
class ListSubscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: {},
      subscriptionList: [],
      isLoading: true,
      activeItem: null,
      modalDelete: false,
      sortBy: "",
      sortOrder: "",
      perPage: 0,
      totalRecords: 0,
      page: 0,
      isModalOpen: false,
    };
  }
  async componentDidMount() {
    let userData = await UserStore.fetchSessionData();
    this.setState({ userData });
    await this.fetchData();
  }
  fetchData = async () => {
    this.setState({ isLoading: true });
    let res = await OrderService.fetchSubscriptionListNew(
      this.state.userData.sessionToken,
      this.state.sortBy,
      this.state.sortOrder,
      this.state.page + 1
    );
    this.setState({ isLoading: false });
    if (res) {
      this.setState({ subscriptionList: res.data });
      this.setState({ totalRecords: res.totalRecords });
      this.setState({ perPage: res.recordPerPage });
    } else {
      this.setState({ subscriptionList: [] });
      toast.error("Something went wrong while fetching subscriptions.");
    }
  };
  toggle = (val) => {
    this.setState({ ["modal" + val]: !this.state["modal" + val] });
  };
  delete = async () => {
    this.setState({ isLoading: true });
    let res = await OrderService.deleteSubscription(
      this.state.userData.sessionToken,
      { _id: this.state.activeItem.id }
    );
    if (res) {
      toast.success("Subscription deleted successfully.");
      this.toggle("Delete");
      await this.fetchData();
    } else {
      toast.error("Something went wrong while deleting the subscription.");
    }
    this.setState({ isLoading: false });
  };
  deleteHandler = (item) => {
    this.setState({modalDelete: true})
    this.setState({activeItem: item})
  }

  formatTableData = (data) => {
    const formattedData = data.map((item) => ({
      createdAt: Moments(item?.createdAt).format("lll"),
      productName: item?.productName,
      userName: item?.userName,
      frequency: item?.frequency,
      quantity: item?.quantity,
      nextShipment: Moments(item?.nextShipment).format("lll"),
      status: item?.status,
      action: (
        <span className="text-danger cursor-pointer" onClick={()=>this.deleteHandler(item)}>Delete</span>
      )
    }));
    return formattedData;
  };
  customSortHandler = (item) => {
    if (item !== this.state.sortBy) {
      this.setState({ sortBy: item });
      this.setState({ sortOrder: "ASC" });
    } else {
      if (this.state.sortOrder === "") {
        this.setState({ sortOrder: "ASC" });
      } else if (this.state.sortOrder === "ASC") {
        this.setState({ sortOrder: "DESC" });
      } else {
        this.setState({ sortOrder: "" });
      }
    }
  };
  pageChangeHandler = (index) => {
    this.setState({ page: index });
  };
  toggleModal = (isOpen) => {
    this.setState({
        isModalOpen: !isOpen
    })
}
  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.sortBy !== this.state.sortBy ||
      prevState.sortOrder !== this.state.sortOrder ||
      prevState.page !== this.state.page
    ) {
      this.fetchData();
    }
  }
 
  render() {
    return (
      <React.Fragment>
        <SimpleBackdrop status={this.state.isLoading} />
        <div className="col-12">
                    <div className="d-flex justify-content-end">
                        <MDBBtn size={'md'} onClick={() => this.toggleModal(this.state.isModalOpen)}>Refill Product</MDBBtn>
                    </div>
                </div>
        <div className={"col-12"}>
          <NewTable
            columns={columns}
            data={this.formatTableData(this.state.subscriptionList)}
            pagination={false}
            customSortHandler={this.customSortHandler}
          />
        </div>
        {this.state.totalRecords / this.state.perPage > 1 && (
          <div className="my-3 d-flex flex-row justify-content-end w-100">
            <NewPagination
              page={this.state.page}
              pageLimit={this.state.perPage}
              totalRecords={this.state.totalRecords}
              handleChange={this.pageChangeHandler}
              type="primary"
              color="#5374B3"
            />
          </div>
        )}
        <ViewProductsRequsetModal isProductOpen={this.state.isModalOpen} toggleModal={this.toggleModal} userData={this.state.userData} fetchData={this.fetchData} />
        {this.state.modalDelete && 
          <MDBModal isOpen={this.state.modalDelete} toggle={() => this.setState({modalDelete:false})} size="md">
          <MDBModalHeader toggle={() => this.setState({modalDelete:false})}>Delete Box</MDBModalHeader>
          <MDBModalBody className={"float-center"}>
  
              Are you sure, you want to the subscription?
  
          </MDBModalBody>
          <MDBModalFooter>
              <MDBBtn color="primary" outline onClick={() => this.setState({modalDelete:false})}
                      size={"sm"}>Close</MDBBtn>
              <MDBBtn color="danger" onClick={this.delete} size={"sm"}>Delete</MDBBtn>
          </MDBModalFooter>
      </MDBModal>

        
        }
      </React.Fragment>
    );
  }
 
}
 
export default ListSubscription;
 