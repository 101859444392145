import React from "react";
import {MDBBreadcrumb, MDBBreadcrumbItem} from "mdbreact";
import {Link} from "react-router-dom";
import BannedMedicine from "../../components/product/bannedMedicine";
class BannedMedicinePage extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Banned Product</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <BannedMedicine/>
            </React.Fragment>
        );
    }
}
export default BannedMedicinePage;