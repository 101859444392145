import React from "react";
import {MDBBadge, MDBTooltip, MDBBtn} from "mdbreact";
import UserStore from "../../stores/userStore";
import ReportService from "../../services/reports";
import {Link} from "react-router-dom";

export default class CustomerDetails extends React.Component {
    async componentDidMount() {
        let userData=await UserStore.fetchSessionData();
        let orderData=await ReportService.customerWidget(userData);
        if(orderData!==false){
            this.setState({data:orderData});
        }
    }
    constructor(props) {
        super(props);
        this.state = {
            data: null
        }
    }
    render() {
        return (
            <React.Fragment>
                <div className={'white rounded-sm p-1'}>
                    <h6 className={'d-flex justify-content-between align-items-center pb-0'}><b className={'font-weight-bold'}>Customers</b>
                        <div>
                            <MDBTooltip placement="top">
                                <MDBBtn color="danger" className={'order-tooltip'}></MDBBtn>
                                <div>Not Added</div>
                            </MDBTooltip>
                            <MDBTooltip placement="top">
                                <MDBBtn color="success" className={'order-tooltip'}></MDBBtn>
                                <div>Added</div>
                            </MDBTooltip>
                        </div>
                    </h6>
                    <ul className="list-group list-group-flush p-0 border-top">
                        <li className="list-group-item d-flex justify-content-between align-items-center p-1 border-bottom-0 grey lighten-4">
                            {/*<Link to={"/view-online-orders"}><span className={'grey-text'}><b>Registered Today</b></span></Link>*/}
                            <span className={'grey-text'}><b>Registered Today</b></span>
                            <b className={'font-weight-bold grey-text'}>{this.state.data!==null?this.state.data.today.newCustomer:""} Added</b>
                        </li>
                        <div className={'col-12 p-0 row mx-0 font-13'}>
                            <div className={'col p-1'}>
                                <div className={'ml-2'}>Phone
                                    <MDBBadge className={'ml-2'} color="danger-color" pill>{this.state.data!==null?this.state.data.today.noPhone:""}</MDBBadge>
                                    <MDBBadge className={'ml-2'} color="success-color" pill>{this.state.data!==null?this.state.data.today.phonePresent:""}</MDBBadge>
                                </div>
                            </div>
                            <div className={'col p-1 border-left border-right'}>
                                <div className={'ml-2 p-1'}>DOB
                                    <MDBBadge className={'ml-2'} color="danger-color" pill>{this.state.data!==null?this.state.data.today.noBirthday:""}</MDBBadge>
                                    <MDBBadge className={'ml-2'} color="success-color" pill>{this.state.data!==null?this.state.data.today.birthDayPresent:""}</MDBBadge>
                                </div>
                            </div>
                            <div className={'col p-1'}>
                                <div className={'ml-2'}>Addresses
                                    <MDBBadge className={'ml-2'} color="danger-color" pill>{this.state.data!==null?this.state.data.today.noAddress:""}</MDBBadge>
                                    <MDBBadge className={'ml-2'} color="success-color" pill>{this.state.data!==null?this.state.data.today.addressPresent:""}</MDBBadge>
                                </div>
                            </div>
                        </div>
                        <li className="list-group-item d-flex justify-content-between align-items-center p-1 border-bottom-0 grey lighten-4">
                            {/*<Link to={"/view-online-orders"}><span className={'grey-text'}><b>Existing</b></span></Link>*/}
                            <span className={'grey-text'}><b>Existing</b></span>
                            <b className={'font-weight-bold grey-text'}>{this.state.data!==null?this.state.data.all.all:""} Available</b>
                        </li>
                        <div className={'col-12 p-0 row mx-0 font-13'}>
                            <div className={'col p-1'}>
                                <div className={'ml-2 p-1'}>Phone
                                    <MDBBadge className={'ml-2'} color="danger-color" pill>{this.state.data!==null?this.state.data.all.noPhone:""}</MDBBadge>
                                    <MDBBadge className={'ml-2'} color="success-color" pill>{this.state.data!==null?this.state.data.all.phonePresent:""}</MDBBadge>
                                </div>
                            </div>
                            <div className={'col p-1 border-left border-right'}>
                                <div className={'ml-2 p-1'}>DOB
                                    <MDBBadge className={'ml-2'} color="danger-color" pill>{this.state.data!==null?this.state.data.all.noBirthday:""}</MDBBadge>
                                    <MDBBadge className={'ml-2'} color="success-color" pill>{this.state.data!==null?this.state.data.all.birthDayPresent:""}</MDBBadge>
                                </div>
                            </div>
                            <div className={'col p-1'}>
                                <div className={'ml-2 p-1'}>Addresses
                                    <MDBBadge className={'ml-2'} color="danger-color" pill>{this.state.data!==null?this.state.data.all.noAddress:""}</MDBBadge>
                                    <MDBBadge className={'ml-2'} color="success-color" pill>{this.state.data!==null?this.state.data.all.addressPresent:""}</MDBBadge>
                                </div>
                            </div>
                        </div>
                    </ul>
                </div>
            </React.Fragment>
        );
    }
}


