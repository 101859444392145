import React,{Suspense} from "react";
import {
    MDBBox
} from 'mdbreact';
import EditSpecialityGroup from "../../components/specialityGroup/editSpecialityGroup";

export default class EditSpecialityGroupPage extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MDBBox className={"min-vh-100"}>
                    <Suspense fallback={null}>
                        <EditSpecialityGroup queryString={this.props.match.params}/>
                    </Suspense>
                </MDBBox>
            </React.Fragment>
        );
    }
}
