import React, {Suspense} from "react";
import {MDBBox, MDBBreadcrumb, MDBBreadcrumbItem} from "mdbreact";
import {Link} from "react-router-dom";
import ListRacksCmp from "../../components/racks/listRacks";
import {withRouter} from "react-router-dom";
class ListRacks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            locId: null
        }
    }

    componentDidMount() {
        let {locId} = this.props.match.params
        this.setState({locId})
    }

    render() {
        return(
            <React.Fragment>
                {this.state.locId &&
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem><Link to={'/view-storage-locations'}>Storage Location</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem><Link to={'/view-floors/'+this.state.locId}>Floors</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Racks</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                }
                <MDBBox className={"min-vh-100"}>
                    <Suspense fallback={null}>
                        <ListRacksCmp queryString={this.props.match.params}/>
                    </Suspense>
                </MDBBox>
            </React.Fragment>
        )
    }
}
export default withRouter(ListRacks);