import orgConfig from "../../orgConfig";
const dataTableOrderHistory= {
    subOrders:[
        {
            label: 'Action',
            field: 'action',
            sort: 'disabled',
            width: 300
        },
        {
            label: 'Created On',
            field: 'createdAt',
            sort: 'asc',
            width: 300
        },

        // {
        //     label: 'Order Date',
        //     field: 'orderDate',
        //     sort: 'asc',
        //     width: 300
        // },

        {
            label: 'Order #',
            field: 'orderCode',
            sort: 'asc',
            width: 300
        },
        {
            label: 'Customer Name',
            field: 'customerName',
            sort: 'asc',
            width: 300
        },
        {
            label: 'Customer Phone',
            field: 'customerPhone',
            sort: 'asc',
            width: 100
        },
        {
            label: 'Total Items',
            field: 'totalItems',
            sort: 'asc',
            width: 100
        },

        {
            label: 'Status',
            field: 'status',
            sort: 'asc',
            width: 100
        },

        {
            label: 'Shipping Address',
            field: 'shipping',
            sort:'disabled',
            width: 50
        },

        {
            label: 'Total',
            field:'totalAmount',
            sort:'disabled',
            width: 50
        }
    ]
}
export default dataTableOrderHistory;