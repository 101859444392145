import React from "react";
import {withRouter} from "react-router-dom";
import SimpleBackdrop from "../common/overlay";
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCol,
    MDBContainer,
    MDBDataTable,
    MDBInput, MDBModal, MDBModalBody, MDBModalHeader,
    MDBRow, MDBBadge
} from "mdbreact";
import UserStore from "../../stores/userStore";
import {toast} from "react-toastify";
import dataTableConfig from "./dataTableProducts";
import ReportService from "../../services/reports";
import utility from "../../utility/utility";
import UpdateProductConfig from "../../pages/product/cms";
import UpdateProduct from "../../pages/product/editPkgDetails";
import VerifyProduct from "./verifyProduct";
import ProductView from "../product/productView";
import StockAvailability from "./stockAvailability";
import config from "../../config";

class ListManuallyMarkedDuplicate extends React.Component {
    constructor() {
        super();
        this.state = {
            progressStatus: false,
            type: "all",
            products: [],
            tableData: {columns: [], rows: []},
            missingField:false,

            productName : true,
            medicineType : true,
            retailPackage : true,
            quantityUnit : true,
            contentQuantity: true,
            mrp:true,
            stock: true,
            description:false,
            manufacturer:false,
            highlights:false,
            tags:false,
            howToUse:false,
            ingredients:false,
            sideEffects:false,
            productUses:false,
            images:false,


            productNameMissing : false,
            medicineTypeMissing : false,
            retailPackageMissing : false,
            quantityUnitMissing : false,
            contentQuantityMissing: false,
            mrpMissing:false,
            stockMissing: false,
            descriptionMissing:false,
            manufacturerMissing:false,
            highlightsMissing:false,
            tagsMissing:false,
            howToUseMissing:false,
            ingredientsMissing:false,
            sideEffectsMissing:false,
            productUsesMissing:false,
            imagesMissing:false,

            modalStatus:false,
            activeProductId:0,
            modalEdit:false,
            modalView:false,
            activeProduct:{

            },
            modalAction:"",
            disableRetreatAfterSorting:false,
            toBeDeleted:[],
            key:""
        }
    }

    componentDidMount() {
        this.fetchData()
    }



    handleDelete = async (index, item) =>{
        await this.setState({key: index})
        let toBeDeleted = this.state.toBeDeleted;
        let newIndex = -1;
        newIndex = toBeDeleted.indexOf(item._id)
        if(newIndex >= 0){
            toBeDeleted.splice(newIndex, 1)
            await this.setState({toBeDeleted: toBeDeleted})
        } else {
            toBeDeleted.push(item._id);
            await this.setState({toBeDeleted: toBeDeleted})
        }

    }

    calculateCheck = function(id) {
        console.log(id)
        return this.state.toBeDeleted.indexOf(id) >= 0 ? true : false
    }

    fetchData = () =>{
        this.getProductsList().then(res => {
            if (res === false) {
                toast.error("We are facing some issue, please try again later.")
            } else {
                this.setState({products: res})
                let temp = this.state.products,
                    dataTablePayload = {
                        columns: dataTableConfig.product,
                        rows:this.state.products.map((item, index) => {
                            item["retailPackage"]=<span className={utility.validateRetailPackage(item.retailPackage) ? "green-text":"red-text"}>{item.retailPackage}</span>;
                            item["quantityUnit"]=<span className={utility.validateQuantityUnit(item.quantityUnit) ? "green-text":"red-text"}>{item.quantityUnit}</span>;
                            item["medicineType"]=<span className={utility.validateMedicineType(true, item.medicineType) ? "green-text":"red-text"}>{item.medicineType}</span>;
                            item["contentQuantity"]=<span className={utility.validateContentQuantity( item.contentQuantity) ? "green-text":"red-text"}>{item.contentQuantity}</span>;
                            item["mrp"]=<span className={utility.validateMrp( item.mrp) ? "green-text":"red-text"}>{item.mrp}</span>;
                            item["stock"]=<span className={utility.validateStock( item.stock) ? "green-text":"red-text"}>{item.stock}</span>;


                            // item["handleCreatedAt"]=utility.convertToReadbleDate(item.createdAt);
                            item["images"]=<div>
                                {item.imageUrl.map((row, index) => (
                                    <div className={"float-left m-1"}>

                                        {(((row.filePathThumb.indexOf(".png") > 0) || (row.filePathThumb.indexOf(".jpg") > 0) || (row.filePathThumb.indexOf(".jpeg") > 0)) && index < 3) &&
                                        <div className={(this.state.selectedImageIndex === index) ? "border-info center" : "center"} style={{"border":"2px solid #CCC",width: "45px", height: "45px"}}>
                                            <img src={config.IMAGE_HOST + row.filePathThumb} className={"float-left"} style={{maxWidth: "40px", maxHeight: "40px"}} alt={""}/>
                                        </div>
                                        }
                                        {index > 2 &&
                                        <div
                                            className={(this.state.selectedImageIndex === index) ? "border-info center" : "center"}
                                            style={{"border": "2px solid #CCC", width: "45px", height: "45px"}}>
                                            <div style={{"fontSize":"20px", "margin":"5px", "paddingLeft":"5px"}} ><b> +{item.imageUrl.length - 3}</b></div>
                                        </div>
                                        }

                                    </div>
                                ))}
                            </div>;

                            item["handleStockAvailability"]=<div>
                                {item.hasOwnProperty("stockAvailability") &&
                                <div style={{cursor:"pointer"}}>
                                    {item.stockAvailability === "DEFAULT" &&
                                    <div style={{color:"green"}} onClick={()=>this.onUpdateStockAvailability(item)} >
                                        <div style={{"fontSize":"17px"}}><b>Default</b></div>

                                    </div>
                                    }
                                    {item.stockAvailability === "ALWAYS_AVAILABLE" &&
                                    <div style={{color:"green"}} onClick={()=>this.onUpdateStockAvailability(item)} >
                                        <div style={{"fontSize":"17px"}}><b>Always Available</b></div>

                                    </div>
                                    }
                                    {item.stockAvailability === "EASILY_AVAILABLE" &&
                                    <div style={{color:"green"}} onClick={()=>this.onUpdateStockAvailability(item)} >
                                        <div style={{"fontSize":"17px"}}><b>Easily Available</b></div>

                                    </div>
                                    }
                                    {item.stockAvailability === "DIFFICULT_TO_PROCURE" &&
                                    <div style={{color:"orange"}} onClick={()=>this.onUpdateStockAvailability(item)} >
                                        <div style={{"fontSize":"17px"}}><b>Difficult to Procure</b></div>

                                    </div>
                                    }

                                    {item.verificationStatus === "TEMPORARILY_UNAVAILABLE" &&
                                    <div style={{color:"red"}} onClick={()=>this.onUpdateStockAvailability(item)} >
                                        <div style={{"fontSize":"17px"}}><b>Temporarily Unavailable</b></div>

                                    </div>
                                    }
                                    {item.verificationStatus === "PERMANENTLY_UNAVAILABLE" &&
                                    <div style={{color:"red"}} onClick={()=>this.onUpdateStockAvailability(item)} >
                                        <div style={{"fontSize":"17px"}}><b>Permanently Unavailable</b></div>

                                    </div>
                                    }
                                </div>
                                }
                                {!item.hasOwnProperty("stockAvailability") &&
                                <div><span color="primary" className={"blue-text btn-link cursor-pointer"} size="sm"
                                           onClick={() => {
                                               this.onUpdateStockAvailability(item)
                                           }}>Update Stock</span></div>
                                }
                            </div>
                            item["check"]=<MDBInput type="checkbox" id={"id_"+index} getValue={()=>this.handleDelete(index, item)}/>


                            item["handleVerification"]=<div >

                                {item.hasOwnProperty("verificationStatus") &&
                                <div style={{cursor:"pointer"}}>
                                    {item.verificationStatus === "READY_TO_PUBLISH" &&
                                    <div style={{color:"green"}} onClick={()=>this.onMark(item)} >
                                        <div style={{"fontSize":"17px"}}><b>Ready To Publish</b></div>
                                        <div style={{"fontSize":"12px"}}>{utility.convertToReadbleDaySmart2(item.verificationLog[item.verificationLog.length -1].verifiedOn)}</div>
                                        <div style={{"fontSize":"12px"}}>{item.verificationLog[item.verificationLog.length -1].verifiedByUserName}</div>
                                    </div>
                                    }
                                    {item.verificationStatus === "MANUALLY_VERIFIED" &&
                                    <div style={{color:"green"}} onClick={()=>this.onMark(item)} >
                                        <div style={{"fontSize":"17px"}}><b>Verified</b></div>
                                        <div style={{"fontSize":"12px"}}>{utility.convertToReadbleDaySmart2(item.verificationLog[item.verificationLog.length -1].verifiedOn)}</div>
                                        <div style={{"fontSize":"12px"}}>{item.verificationLog[item.verificationLog.length -1].verifiedByUserName}</div>
                                    </div>
                                    }
                                    {item.verificationStatus === "JUNK" &&
                                    <div style={{color:"red"}} onClick={()=>this.onMark(item)} >
                                        <div style={{"fontSize":"17px"}}><b>Junk</b></div>
                                        <div style={{"fontSize":"12px"}}>{utility.convertToReadbleDaySmart2(item.verificationLog[item.verificationLog.length -1].verifiedOn)}</div>
                                        <div style={{"fontSize":"12px"}}>{item.verificationLog[item.verificationLog.length -1].verifiedByUserName}</div>
                                    </div>
                                    }
                                    {item.verificationStatus === "DUPLICATE" &&
                                    <div style={{color:"orange"}} onClick={()=>this.onMark(item)} >
                                        <div style={{"fontSize":"17px"}}><b>Duplicate</b></div>
                                        <div style={{"fontSize":"12px"}}>{utility.convertToReadbleDaySmart2(item.verificationLog[item.verificationLog.length -1].verifiedOn)}</div>
                                        <div style={{"fontSize":"12px"}}>{item.verificationLog[item.verificationLog.length -1].verifiedByUserName}</div>
                                    </div>
                                    }
                                    {item.verificationStatus === "INCOMPLETE" &&
                                    <div style={{color:"orange"}} onClick={()=>this.onMark(item)} >
                                        <div style={{"fontSize":"17px"}}><b>Incomplete</b></div>
                                        <div style={{"fontSize":"12px"}}>{utility.convertToReadbleDaySmart2(item.verificationLog[item.verificationLog.length -1].verifiedOn)}</div>
                                        <div style={{"fontSize":"12px"}}>{item.verificationLog[item.verificationLog.length -1].verifiedByUserName}</div>
                                    </div>
                                    }
                                    {item.verificationStatus === "INCORRECT" &&
                                    <div style={{color:"orange"}} onClick={()=>this.onMark(item)} >
                                        <div style={{"fontSize":"17px"}}><b>Incorrect</b></div>
                                        <div style={{"fontSize":"12px"}}>{utility.convertToReadbleDaySmart2(item.verificationLog[item.verificationLog.length -1].verifiedOn)}</div>
                                        <div style={{"fontSize":"12px"}}>{item.verificationLog[item.verificationLog.length -1].verifiedByUserName}</div>
                                    </div>
                                    }
                                </div>
                                }
                                {!item.hasOwnProperty("verificationStatus") &&
                                <div><span color="primary" className={"blue-text btn-link cursor-pointer"} size="sm"
                                           onClick={() => {
                                               this.onMark(item)
                                           }}>Mark</span></div>
                                }
                            </div>
                            item["handleAction"] = <div>
                                                <span color="primary" className={"blue-text btn-link cursor-pointer"} size="sm"
                                                      onClick={() => {
                                                          this.onView(item)
                                                      }}> View </span>
                                <span color="primary" className={"blue-text btn-link cursor-pointer"} size="sm"
                                      onClick={() => {
                                          this.onEditDesc(item)
                                      }}> | Edit</span>

                                <span color="primary" className={"blue-text btn-link cursor-pointer"} size="sm"
                                      onClick={() => {
                                          this.onEdit(item)
                                      }}> | Config</span>


                            </div>;

                            return item;
                        })

                        // rows: this.state.products
                    }
                this.setState({tableData: dataTablePayload})
            }
        })
    }

    getProductsList = async () => {
        let userData = await UserStore.fetchSessionData();
        this.setState({progressStatus: true});
        let data = await ReportService.fetchProductWithMarkedFilter(userData, "DUPLICATE");
        this.setState({progressStatus: false});
        return data
    }

    toggleView = () => {
        this.setState({
            modalView: !this.state.modalView
        });
    }

    toggleEdit = () => {
        this.setState({
            modalEdit: !this.state.modalEdit
        });
    }
    onEditComplete = (row) => {
        this.toggleEdit()
        this.fetchData();
    }
    // This triggers from MDBDataTable, aim is to redirect to edit page
    onEditDesc =  (row) => {
        this.setState({activeProduct: row,modalAction:"edit"})
        this.toggleEdit()
    }

    onEdit=(item)=>{
        // this.props.history.push('/product/cms/'+item._id);
        this.setState({activeProduct: item, modalAction:"updateConfig"})
        this.toggleEdit()
    }

    onMark=(item)=>{
        // this.props.history.push('/product/cms/'+item._id);
        this.setState({activeProduct: item, modalAction:"mark"})
        this.toggleEdit()
    }

    onUpdateStockAvailability=(item)=>{
        // this.props.history.push('/product/cms/'+item._id);
        this.setState({activeProduct: item, modalAction:"stockAvailability"})
        this.toggleEdit()
    }

    onView =  (row) => {
        this.setState({activeProduct: row})
        this.toggleView()
    }

    deleteSelectedRecords = async () => {
        let userData = await UserStore.fetchSessionData();
        this.setState({progressStatus: true});
        let data = await ReportService.deleteProductsInBulk(userData, this.state);
        this.setState({progressStatus: false});
        return data
    }



    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.progressStatus}/>
                <MDBContainer className={'w-100 mw-100'}>

                    {this.state.toBeDeleted.length === 1 &&
                        <MDBBtn color={"danger"} onClick={this.deleteSelectedRecords}>Delete {this.state.toBeDeleted.length} selected record</MDBBtn>
                    }
                    {this.state.toBeDeleted.length > 1 &&
                        <MDBBtn color={"danger"} onClick={this.deleteSelectedRecords}>Delete these {this.state.toBeDeleted.length} selected records</MDBBtn>
                    }

                    {this.state.products.length > 0 &&
                    <MDBDataTable
                        striped

                        data={this.state.tableData}
                        materialSearch
                        noBottomColumns
                        hover
                        disableRetreatAfterSorting={false}
                        //disableRetreatAfterSorting={this.state.disableRetreatAfterSorting}
                    />
                    }


                    <MDBModal isOpen={this.state.modalEdit} toggle={this.toggleEdit} size={"fluid"}>
                        <MDBModalHeader toggle={this.toggleEdit}></MDBModalHeader>
                        <MDBModalBody className={"float-center"}>
                            {this.state.modalAction === "updateConfig" &&
                            <UpdateProductConfig loadedOnPopup={true} onEditComplete={this.onEditComplete}
                                                 productId={this.state.activeProduct._id}/>
                            }
                            {this.state.modalAction === "edit" &&
                            <UpdateProduct loadedOnPopup={true} onEditComplete={this.onEditComplete}
                                           productId={this.state.activeProduct._id}/>
                            }
                            {this.state.modalAction === "mark" &&
                            <VerifyProduct loadedOnPopup={true} onEditComplete={this.onEditComplete}
                                           product={this.state.activeProduct}/>
                            }
                            {this.state.modalAction === "stockAvailability" &&
                            <StockAvailability loadedOnPopup={true} onEditComplete={this.onEditComplete}
                                               product={this.state.activeProduct}/>
                            }

                        </MDBModalBody>
                    </MDBModal>

                    <MDBModal isOpen={this.state.modalView} toggle={this.toggleView} size={"fluid"}>
                        <MDBModalHeader toggle={this.toggleView}></MDBModalHeader>
                        <MDBModalBody className={"float-center"}>
                            <ProductView loadedOnPopup={true}
                                         productId={this.state.activeProduct._id} product={this.state.activeProduct}/>
                        </MDBModalBody>
                    </MDBModal>
                    <MDBModal isOpen={this.state.modalLB} toggle={this.toggleLB} size={"lg"}>
                        <MDBModalHeader toggle={this.toggleLB}></MDBModalHeader>
                        <MDBModalBody className={"float-center"}>
                            <div style={{"textAlign":"center"}}>
                                {/*<img src={config.IMAGE_HOST + this.state.activeImageUrl.filePathOriginal} className={"float-center"}*/}
                                {/*     style={{maxWidth: "440px", maxHeight: "440px"}} alt={""}/>*/}
                            </div>
                        </MDBModalBody>
                    </MDBModal>
                </MDBContainer>
            </React.Fragment>
        )
    }
}

export default withRouter(ListManuallyMarkedDuplicate)