import React from "react";
import {MDBBox, MDBBreadcrumb, MDBBreadcrumbItem} from "mdbreact";
import {Link} from "react-router-dom";
import AddProduct from "../../components/product/addProduct";

class AddProduct_new extends React.Component {
    render() {
        return(
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem><Link to={'/view-products-new'}>Products</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Add Product</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <MDBBox className={"min-vh-100"}>
                    <AddProduct/>
                </MDBBox>
            </React.Fragment>
        )
    }
}
export default AddProduct_new;