import React from "react";
import {Link,withRouter} from "react-router-dom";
import {
    MDBContainer, MDBRow, MDBInput, MDBFormInline, MDBBtn, MDBCol,
    MDBCard, MDBCardBody, MDBCardHeader, MDBBreadcrumb, MDBBreadcrumbItem, MDBChip
} from 'mdbreact';
import UserStore from "../../stores/userStore";
import {toast} from "react-toastify";
import SimpleBackdrop from "../common/overlay";
import SpecialityService from "../../services/specialityService";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

class AddSpeciality extends React.Component {
    validateSpeciality=()=>{
        if(this.state.speciality.trim()===''){
            this.setState({specialityError:true});
        }else{
            this.setState({specialityError:false});
        }
    }
    handleChange=(val)=>{
        this.setState({speciality:val});
    }
    addSpeciality=async ()=>{
        //validations
        if(this.state.speciality.trim()===''){
            this.setState({specialityError:true});
            return false;
        }
        if(this.state.masterSpecialityGroup===''){
            this.setState({specialityGroupError:true});
            return false;
        }

        let userData=await UserStore.fetchSessionData();
        this.setState({progressStatus:true});
        let data=await SpecialityService.addSpeciality(this.state.speciality.trim(),this.state.masterSpecialityGroup.value._id,userData);
        if(data===false){
            this.setState({progressStatus:false});
            toast.error("Speciality group already exists.");
        }else{
            this.setState({progressStatus:false,speciality:'',masterSpecialityGroup:'',specialityError:false,specialityGroupError:false});
            toast.success("Speciality added successfully.");
            let count=this.state.keyCount;
            this.setState({keyCount:count+1});
        }
    }
    constructor(props) {
        super(props);
        this.state = {
            suggestedSpecialityGroup:[],
            speciality:"",
            masterSpecialityGroup:'',
            specialityError:false,
            specialityGroupError:false,
            progressStatus:false,
            suggestedList:[],
            keyCount:1
        }
    }
    fetchSuggestion=async()=>{
        try{
            if(this.state.speciality!=='') {
                let userData=await UserStore.fetchSessionData();
                let data = await SpecialityService.lookupSpeciality(this.state.speciality,userData);
                if(data!==false){
                    this.setState({suggestedList:data});
                }
            }
        }catch (e){
            this.setState({suggestedList:[]});
        }
    }
    fetchSpecialityGroup=async (e)=>{
        let term=e.target.value;
        if(term!=='') {
            let userData=await UserStore.fetchSessionData();
            let specialityGroup = await SpecialityService.lookupSpecialityGroup(term,userData);
            if(specialityGroup===false){
                toast.error("We are facing some issue. Please try again later.");
            }else{
                let temp = [];
                for (let item of specialityGroup) {
                    let str = {title: item.specialityGroup, value: item};
                    temp.push(str);
                }
                this.setState({suggestedSpecialityGroup:temp});
            }
        }
    }
    onChangeSpecialityGroup=(event, values)=>{
        this.setState({masterSpecialityGroup:values});
    }
    navigate=(item)=>{

    }
    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.progressStatus}/>
                {!this.state.isSubComponent &&
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem><Link to={'/view-suppliers'}>Speciality</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Add Speciality</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                }
                <MDBContainer>
                    <MDBCard className={"mt-2"}>
                        <MDBCardHeader>
                            <span className={"float-left"}>New Speciality</span>
                            <MDBFormInline className="float-right md-form mr-auto m-0">
                                {!this.state.isSubComponent &&
                                <MDBBtn outline color="blue-grey" size="sm"
                                        onClick={() => this.props.history.push('/view-speciality')} className="mr-auto"
                                        disabled={this.state.progressStatus}>
                                    Back
                                </MDBBtn>
                                }
                                <MDBBtn outline color="blue-grey" size="sm" onClick={this.addSpeciality} className="mr-auto"
                                        disabled={this.state.progressStatus || this.state.specialityError}>
                                    Save
                                </MDBBtn>
                            </MDBFormInline>
                        </MDBCardHeader>
                        <MDBCardBody>
                            <MDBRow className="d-flex justify-content-center">
                                <MDBCol>
                                    <MDBInput onChange={this.fetchSuggestion} label="Speciality" onBlur={this.validateSpeciality} className={this.state.specialityError ? "border-danger" :""} getValue={this.handleChange} value={this.state.speciality}/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                {this.state.suggestedList.map((item, idx) => (
                                    <span key={idx} onClick={()=>{this.navigate(item)}}><MDBChip bgColor="light-blue lighten-4" key={idx} waves>{item.speciality}</MDBChip></span>
                                ))}
                            </MDBRow>
                            <MDBRow className={'w-70'}>
                                <Autocomplete
                                    key={this.state.keyCount}
                                    freeSolo
                                    onChange={this.onChangeSpecialityGroup}
                                    id="combo-box-speciality"
                                    options={this.state.suggestedSpecialityGroup}
                                    getOptionLabel={option => option.title}
                                    onInputChange={(e) => {
                                        this.fetchSpecialityGroup(e)
                                    }}
                                    selectOnFocus={true}
                                    renderInput={params => (
                                        <TextField style={ this.state.specialityGroupError ?  {"borderBottom":"1px solid RED"} : {}} {...params} placeholder="Search Speciality"
                                                   margin="normal" fullWidth/>
                                    )}
                                />
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                </MDBContainer>
            </React.Fragment>
        );
    }
}
export default withRouter(AddSpeciality);
