import React from "react";
import {withRouter} from "react-router-dom";
import {
    MDBContainer, MDBCard, MDBCardBody, MDBCardHeader, MDBBox, MDBDataTableV5
} from 'mdbreact';
import UserStore from "../../stores/userStore";
import OrderService from "../../services/orderService";
import dataTablePrescriptionOrder from "../online-prescription-order/dataTablePrescriptionOrder"
import {toast} from "react-toastify";
import SimpleBackdrop from "../common/overlay";
import Moments from "moment";

class ListPrescriptionOrder extends React.Component {

    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            orderList: [],
            progressStatus: false,
            data: {}
        }
    }
    componentDidMount() {
        this._isMounted = true;
        this.fetchPrescriptionOrder().then(res => {
            if (res && this._isMounted === true) {
                if (res.length > 0) {
                    this.populateDataIntoTable(res)
                }
                this.setState({orderList: res});
            } else {
                toast.error("Something went wrong while fetching data.")
            }
        })
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    processOrder = (orderId, status) => {
        this.props.history.push('/process-prescription-order/'+orderId);
        this.props.history.push({'state':status});
    }
    fetchPrescriptionOrder = async () => {
        const userData = await UserStore.fetchSessionData();
        this.setState({progressStatus: true})
        const res = await OrderService.fetchPrescriptionOrders(userData);
        this.setState({progressStatus: false})
        return res
    }
    populateDataIntoTable = (res) => {
        let dataTablePayload = {
            columns: dataTablePrescriptionOrder.prescriptionOrders,
            rows: res.map((row, index) => {
                row["handleAddress"] = <small>
                    {row.shippingAddress.address} {row.shippingAddress.street &&
                <span>,&nbsp;{row.shippingAddress.street}</span>}
                    {row.shippingAddress.area && <span>,&nbsp;{row.shippingAddress.area}</span>}
                    <br/>
                    {row.shippingAddress.city}, {row.shippingAddress.state} <br/>
                    {row.shippingAddress.pincode}, {row.shippingAddress.country}<br/>
                    {row.shippingAddress.landmark &&
                    <span>{row.shippingAddress.landmark}<br></br> </span>
                    }
                </small>;
                row["handleName"] = row.customerDetails.name;
                row["handleDate"] = Moments(row.createdAt).format("MMM D, YYYY HH:mm")
                row["handleContactNo"] = row.customerDetails.contactNo;
                row["handleProcessOrder"] =
                    <div color="primary" className={"blue-text btn-link cursor-pointer"} size="sm" onClick={() => {
                        this.processOrder(row._id, row.status)
                    }}>Process Order</div>;
                return row;
            })
        };
        this.setState({data: dataTablePayload});
    }

    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.progressStatus}/>
                <MDBContainer>
                    <MDBBox>
                        <MDBCard className={"mt-2"} style={{"marginTop": "0px"}}>
                            <MDBCardHeader>
                                <span className={"float-left"}>View Prescription Orders</span>
                            </MDBCardHeader>
                            <MDBCardBody>
                                {(this.state.progressStatus === true) &&
                                <div className={"center"}>Loading orders...</div>
                                }
                                {(this.state.progressStatus === false) && (this.state.orderList.length === 0) &&
                                <div className={"no-data-container"}>No Order found.</div>
                                }
                                {(this.state.progressStatus === false) && this.state.orderList.length > 0 &&
                                <MDBDataTableV5 hover entriesOptions={[10, 15, 20, 25]} entries={10} pagesAmount={4}
                                                data={this.state.data} striped barReverse materialSearch searchTop
                                                searchBottom={false}
                                />
                                }
                            </MDBCardBody>
                        </MDBCard>
                    </MDBBox>
                </MDBContainer>
            </React.Fragment>
        );
    }
}

export default withRouter(ListPrescriptionOrder)
