import React from "react";
import { Link } from "react-router-dom";
import orgConfig from "../../orgConfig";
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavbarToggler,
  MDBCollapse,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from "mdbreact";
import "./common.css";
import { withRouter } from "react-router-dom";
import AuthService from "../../services/authService";
import UserStore from "../../stores/userStore";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      userData: {},
    };
  }

  async componentDidMount() {
    let userData = await UserStore.fetchSessionData();

    this.setState({ userData: userData });
    console.log(this.state.userData);
  }

  toggleCollapse = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
  logOut = async () => {
    let userData = await UserStore.fetchSessionData();
    AuthService.logOut(userData);
    this.props.history.push("/");
  };

  render() {
    return (
      <MDBNavbar expand="md" className={"sub-heading-styling"}>
        <MDBNavbarToggler
          className={"sub-header-toggler"}
          onClick={this.toggleCollapse}
        >
          <div className={"sub-header-toggle-icon"}>&#9776;</div>
        </MDBNavbarToggler>
        <MDBCollapse
          id="navbarCollapse3"
          isOpen={this.state.isOpen}
          navbar
          className={"sub-heading"}
        >
          <MDBNavbarNav left>
            <MDBNavItem>
              <Link to={"/dashboard"}>Dashboard</Link>
            </MDBNavItem>
            {orgConfig.LEVEL1_MENUS.indexOf("ORDERS") >= 0 && (
              <MDBNavItem>
                <MDBDropdown>
                  <MDBDropdownToggle nav caret>
                    Orders
                  </MDBDropdownToggle>
                  <MDBDropdownMenu className={"sub-header-menu"}>
                    <div className={"sub-menu-box"}>
                      <Link to={"/fulfil-offline-order"}>
                        Fulfill Order-Barcode
                      </Link>
                      <Link to={"/view-in-person-orders"}>
                        {" "}
                        View In-person Orders
                      </Link>
                    </div>
                    <div className={"sub-menu-box"}>
                      <div className={"title"}>Online</div>
                      <Link to={"/view-online-orders"}>
                        {" "}
                        List Online Orders
                      </Link>
                      <Link to={"/new-online-order"}> New Online Order</Link>
                      <Link to={"/unfulfilled-orders"}>Unfulfilled Orders</Link>
                    </div>
                    <div className={"sub-menu-box"}>
                      <div className={"title"}>Feedback</div>
                      <Link to={"/feedback"}> Customer feedback</Link>
                    </div>
                    <div className={"sub-menu-box"}>
                      <div className={"title"}>Subscription</div>
                      <Link to={"/view-subscriptions"}>
                        {" "}
                        List Subscriptions
                      </Link>
                    </div>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavItem>
            )}

            <MDBNavItem>
              <Link to={"/cashbook"}>Cashbook</Link>
            </MDBNavItem>
            <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownToggle nav caret>
                  Inventory
                </MDBDropdownToggle>
                <MDBDropdownMenu className={"sub-header-menu"}>
                  {orgConfig.LEVEL1_MENUS.indexOf("PRODUCTS") >= 0 && (
                    <div className={"sub-menu-box"}>
                      <div className={"title"}>Products</div>
                      <Link to={"/view-products-new"}>View Products</Link>
                      <Link to={"/search-product"}>Search Products New</Link>
                      <Link to={"/add-product-new"}>Add Product</Link>
                      <Link to={"/banned-products"}>Restrict online sale</Link>
                    </div>
                  )}
                  {orgConfig.LEVEL1_MENUS.indexOf("SHIPMENTS") >= 0 && (
                    <div className={"sub-menu-box"}>
                      <div className={"title"}>Shipments</div>
                      <Link
                        id="nav_add_manufacturer_se"
                        to={"/add-incoming-shipment"}
                      >
                        Add Incoming Shipment
                      </Link>
                      <Link to={"/view-incoming-shipments"}>
                        View Incoming Shipments
                      </Link>
                    </div>
                  )}
                  {orgConfig.LEVEL1_MENUS.indexOf("MANUFACTURERS") >= 0 && (
                    <div className={"sub-menu-box"}>
                      <div className={"title"}>Manufacturers</div>
                      <Link
                        id="nav_add_manufacturer_se"
                        to={"/add-manufacturer"}
                      >
                        Add Manufacturer
                      </Link>
                      <Link to={"/View-manufacturers"}>View Manufacturers</Link>
                    </div>
                  )}
                  {orgConfig.LEVEL1_MENUS.indexOf("SUPPLIERS") >= 0 && (
                    <div className={"sub-menu-box"}>
                      <div className={"title"}>Suppliers</div>
                      <Link to={"/add-supplier"}>Add Supplier</Link>
                      <Link to={"/view-suppliers"}>View Suppliers</Link>
                    </div>
                  )}
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavItem>
            {orgConfig.LEVEL1_MENUS.indexOf("STOCK") >= 0 && (
              <MDBNavItem>
                <MDBDropdown>
                  <MDBDropdownToggle nav caret>
                    Stock
                  </MDBDropdownToggle>
                  <MDBDropdownMenu className={"sub-header-menu"}>
                    <div className={"sub-menu-box col-md-4"}>
                      {/* <Link to={"/searchProductv3"}>Search</Link> */}
                      <Link id="nav_add_inventory_se" to={"/add-stock"}>
                        Add Stock
                      </Link>
                      {/* <Link to={"/fetch-stock"}>View Stock</Link> */}
                      <Link to={"/fetch-stock"}>View Stock</Link>
                      <Link to={"/search-stock-new/:page?"}>Search Stock</Link>
                      {/*<Link to={"/reset-stock"}>Reset Stock</Link>*/}
                      {/* <Link to={"/view-stock"}>View Stock</Link> */}
                      <Link to={"/expired-products"}>Expired Products</Link>
                      <Link to={"/track-stock"}>Transfer Stock</Link>
                      {/* <Link to={"/product-details"}>Product Details</Link> */}
                      <Link to={"/relocate-rack"}>Relocate Rack</Link>
                      <Link to={"/relocate-rack-by-barcode"}>Relocate Rack by Barcode</Link>
                      <Link to={"/transfers"}>Transfer stock [ temporary ]</Link>
                    </div>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavItem>
            )}
            {orgConfig.LEVEL1_MENUS.indexOf("PURCHASE_REQUESTS") >= 0 && (
              <MDBNavItem>
                <MDBDropdown>
                  <MDBDropdownToggle nav caret>
                    Purchase Requests
                  </MDBDropdownToggle>
                  <MDBDropdownMenu className={"sub-header-menu"}>
                    <div className={"sub-menu-box"}>
                      <div className={"title"}>Requests</div>
                      <Link to={"/add-purchase-request"}>
                        Add Purchase Request
                      </Link>
                      <Link to={"/view-purchase-requests"}>
                        View Purchase Requests
                      </Link>
                    </div>
                    <div className={"sub-menu-box"}>
                      <div className={"title"}>Notes</div>
                      <Link to={"/add-purchase-note"}>Add Purchase Note</Link>
                      <Link to={"/view-purchase-notes"}>
                        View Purchase Notes
                      </Link>
                    </div>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavItem>
            )}
            {orgConfig.LEVEL1_MENUS.indexOf("REPORT") >= 0 && (
              <MDBNavItem>
                <MDBDropdown>
                  <MDBDropdownToggle nav caret>
                    <span className="mr-2">Reports</span>
                  </MDBDropdownToggle>
                  <MDBDropdownMenu className={"sub-header-menu"}>
                    <div className={"sub-menu-box"}>
                      {/* <Link to={"/download-reports"}>
                            Sales/Purchase Reports
                          </Link> */}
                      <Link to={"/sales-insights"}>Sales Insights</Link>
                      {/* <Link to={"/filtered-products"}>Filtered Products</Link> */}
                      {/* <Link to={"/duplicate-products"}>Duplicate Products</Link> */}
                      {/* <Link to={"/delete-history"}>Delete History</Link> */}
                      {/* <Link to={"/my-task"}>Data Cleansing Tasks</Link> */}
                      <Link to={"/reports/credit-report"}>Credit Report</Link>
                      {/* <Link to={"/reports/product-sales-analysis"}>
                            Product Sales Analysis
                          </Link>
                          <Link to={"/reports/report-requests"}>
                            Report Requests
                          </Link> */}
                    </div>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavItem>
            )}

            <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownToggle nav caret>
                  Users
                </MDBDropdownToggle>
                <MDBDropdownMenu className={"sub-header-menu"}>
                  {orgConfig.LEVEL1_MENUS.indexOf("CUSTOMERS") >= 0 && (
                    <div className={"sub-menu-box col-md-4"}>
                      <div className={"title"}>Customers</div>
                      <Link to={"/add-customer"}>Add Customer</Link>
                      <Link to={"/search-customers"}>Search Customer</Link>
                      {/* <Link to={"/view-customers"}>View Customers</Link> */}
                      <Link to={"/view-call-summary"}>View Call Summary</Link>
                    </div>
                  )}
                  {orgConfig.LEVEL1_MENUS.indexOf("DOCTORS") >= 0 && (
                    <div className={"sub-menu-box col-md-4"}>
                      <div className={"title"}>Doctors</div>
                      {this.state.userData.type === "platformAdmin" && (
                        <Link to={"/add-doctor"}>Add Doctor</Link>
                      )}
                      <Link to={"/view-doctors"}>View Doctors</Link>
                    </div>
                  )}
                  {this.state.userData.type === "platformAdmin" &&
                    orgConfig.LEVEL1_MENUS.indexOf("ORGANIZATIONS") >= 0 && (
                      <div className={"sub-menu-box col-md-4"}>
                        <div className={"title"}>Organizations</div>
                        {this.state.userData.type === "platformAdmin" && (
                          <Link to={"/create-organization"}>
                            Create Organization
                          </Link>
                        )}
                        <Link to={"/organizations"}>Organizations</Link>
                      </div>
                    )}

                  {this.state.userData.type === "ORG_ADMIN" &&
                    this.state.userData.orgDetails.length > 0 &&
                    orgConfig.LEVEL1_MENUS.indexOf("PHARMACY_USERS") >= 0 && (
                      <div className={"sub-menu-box col-md-4"}>
                        <div className={"title"}>Staff</div>
                        <Link
                          id="nav_add_quantity_unit"
                          to={
                            "/add-pharmacy-user/" +
                            this.state.userData.orgDetails[0]._id
                          }
                        >
                          Add Staff
                        </Link>
                        <Link
                          to={
                            "/view-pharmacy-users/" +
                            this.state.userData.orgDetails[0]._id
                          }
                        >
                          View Staff
                        </Link>
                      </div>
                    )}
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavItem>


            <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownToggle nav caret>
                  Task Manager
                </MDBDropdownToggle>
                <MDBDropdownMenu className={"sub-header-menu"}>
                  <div className={"sub-menu-box"}>
                    <Link to={"/task-list"}>Task List</Link>
                    <Link to={"/add-task"}>Add Task</Link>
                  </div>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavItem>
            <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownToggle nav caret>
                  Storage
                </MDBDropdownToggle>
                <MDBDropdownMenu className={"sub-header-menu"}>
                  <div className={"sub-menu-box"}>
                    <Link to={"/view-storage-locations"}>Locations</Link>
                  </div>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavItem>
            <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownToggle nav caret>
                  Product Grouping
                </MDBDropdownToggle>
                <MDBDropdownMenu className={"sub-header-menu"}>
                  <div className={"sub-menu-box col-md-4"}>
                    <div className={"title"}>By Similar Products</div>
                    <Link to={"/product-group-list"}>Group List</Link>
                    <Link to={"/add-product-group"}>Add Group</Link>
                  </div>
                  <div className={"sub-menu-box col-md-4"}>
                    <div className={"title"}>By Category</div>
                    <Link to={"/product-list-by-category"}>Group List</Link>
                    <Link to={"/add-product-to-category"}>Add Product</Link>
                  </div>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavItem>
            <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownToggle nav caret>
                  Offers
                </MDBDropdownToggle>
                <MDBDropdownMenu className={"sub-header-menu"}>
                  <div className={"sub-menu-box"}>
                    <Link to={"/coupons/list"}>Coupons</Link>
                  </div>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavItem>

            <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownToggle nav caret>
                  CMS
                </MDBDropdownToggle>
                <MDBDropdownMenu className={"sub-header-menu"}>
                  <div className={"sub-menu-box"}>
                    <Link to={"/cms/banner"}>Banner Configuration</Link>
                    <Link to={"/cms/mobile-banner"}>
                      Mobile Banner Configuration
                    </Link>
                  </div>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavItem>
            <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownToggle nav caret>
                  Shipping
                </MDBDropdownToggle>
                <MDBDropdownMenu className={"sub-header-menu"}>
                  <div className={"sub-menu-box"}>
                    <Link to={"/shipping-performance"}>
                      Shipping Performance
                    </Link>
                  </div>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavItem>

            <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownToggle nav caret>
                  Razorpay Payments
                </MDBDropdownToggle>
                <MDBDropdownMenu className={"sub-header-menu"}>
                  <div className={"sub-menu-box"}>
                    <Link to={"/razorpay-tools"}>Razorpay Tools</Link>
                  </div>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavItem>

            <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownToggle nav caret>
                  Medicine Substitute
                </MDBDropdownToggle>
                <MDBDropdownMenu className={"sub-header-menu"}>
                  <div className={"sub-menu-box mx-3"}>
                    <Link to={"/add-substitute-medicine"}>Add Substitute</Link>
                    <Link to={"/view-substitute-medicine"}>
                      View Substitute
                    </Link>
                  </div>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavItem>

            {/* <MDBNavItem>
                <MDBDropdown>
                  <MDBDropdownToggle nav caret>
                    Report V1
                  </MDBDropdownToggle>
                  <MDBDropdownMenu className={"sub-header-menu"}>
                    {orgConfig.LEVEL1_MENUS.indexOf("REPORT_V2") >= 0 && (
                        <div className={"sub-menu-box col-md-4"}>
                          <div className={"title"}>Reports</div>
                          <Link to={"/reports/report-list"}> Create Report </Link> 
                          <Link to={"/reports/reportschedule-report"}>Report Schedule List</Link>
                          <Link to={"/reports/jobcreation-report"}> Jobs  </Link>
                          <Link to={"/reports/jobexecutionhistory-report"}>Job History List</Link>                                                   
                        </div>
                    )}
                  
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavItem> */}

            {/*<MDBNavItem>*/}
            {/*  <MDBDropdown>*/}
            {/*    <MDBDropdownToggle nav caret>*/}
            {/*      Product Visits*/}
            {/*    </MDBDropdownToggle>*/}
            {/*    <MDBDropdownMenu className={"sub-header-menu"}>*/}
            {/*      <div className={"sub-menu-box"}>*/}
            {/*        <Link to={"/list-out-of-stock"}>*/}
            {/*          Out of Stock Product Visits*/}
            {/*        </Link>*/}
            {/*      </div>*/}
            {/*    </MDBDropdownMenu>*/}
            {/*  </MDBDropdown>*/}
            {/*</MDBNavItem>*/}
            {orgConfig.LEVEL1_MENUS.indexOf("ORGANIZATIONS") >= 0 && (
              <MDBNavItem>
                <MDBDropdown>
                  <MDBDropdownToggle nav caret>
                    More
                  </MDBDropdownToggle>
                  <MDBDropdownMenu className={"sub-header-menu"}>
                    {orgConfig.LEVEL1_MENUS.indexOf("PRODUCT_CATEGORIES") >=
                      0 && (
                        <div className={"sub-menu-box col-md-4"}>
                          <div className={"title"}>Product Categories</div>
                          <Link to={"/add-product-category"}>
                            Add Product Category
                          </Link>
                          <Link to={"/view-product-categories"}>
                            View Product Categories
                          </Link>
                        </div>
                      )}
                    {orgConfig.LEVEL1_MENUS.indexOf("SPECIALITY_GROUP") >=
                      0 && (
                        <div className={"sub-menu-box col-md-4"}>
                          <div className={"title"}>Specialties</div>
                          <Link
                            id="nav_add_quantity_unit"
                            to={"/add-speciality"}
                          >
                            Add Speciality
                          </Link>
                          <Link to={"/view-specialties"}>View Specialties</Link>
                        </div>
                      )}
                    {orgConfig.LEVEL1_MENUS.indexOf("RETAIL_PACKAGING") >=
                      0 && (
                        <div className={"sub-menu-box col-md-4"}>
                          <div className={"title"}>
                            Product Retail Packaging{" "}
                          </div>
                          <Link
                            id="nav_add_retail_package"
                            to={"/add-retail-packaging"}
                          >
                            Add Retail Packaging
                          </Link>
                          <Link to={"/view-retail-packaging"}>
                            View Retail Packaging
                          </Link>
                        </div>
                      )}

                    {orgConfig.LEVEL1_MENUS.indexOf("QUANTITY_UNITS") >=
                      0 && (
                        <div className={"sub-menu-box col-md-4"}>
                          <div className={"title"}>Product Quantity Units </div>
                          <Link
                            id="nav_add_quantity_unit"
                            to={"/add-quantity-unit"}
                          >
                            Add Quantity Unit
                          </Link>
                          <Link to={"/view-quantity-units"}>
                            View Quantity Units
                          </Link>
                        </div>
                      )}
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavItem>
            )}
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBNavbar>
    );
  }
}

export default withRouter(Header);
