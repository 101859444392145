import React from "react";
import {withRouter} from "react-router-dom";
import {
    MDBContainer, MDBRow, MDBInput, MDBBtn, MDBCardHeader, MDBFormInline, MDBCardBody, MDBCard, MDBCol
} from 'mdbreact';
import {toast} from "react-toastify";
import UserStore from "../../stores/userStore";
import AuthService from "../../services/authService";
import SimpleBackdrop from "../common/overlay";
import util from "../../utility/utility";

class ChangePasswordCmp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            password:'',
            confirmPassword:"",
            progressStatus:false,
            passwordError:false,
            confirmPasswordError:false,
            passwordType:"password",
            confirmPasswordType:"password",
            passwordPolicy: false,
            confirmPasswordPolicy: false,
        }
    }

    changePassword=async ()=>{
        window.scrollTo(0, 0)
        let userData=await UserStore.fetchSessionData();
        if(this.state.password ===''||this.state.password === null){
            this.setState({passwordError:true});
            return true;
        } else {
            this.setState({passwordError:false});
        }
        if(this.state.confirmPassword===''|| this.state.confirmPassword===null){
            this.setState({confirmPasswordError:true});
            return true;
        }else{
            this.setState({confirmPasswordError:false});
        }
        if(this.state.password!==this.state.confirmPassword){
            this.setState({confirmPasswordError:true});
            return true;
        }
        this.setState({progressStatus:true});
        let status=await AuthService.changePassword(userData,this.state.password);
        this.setState({progressStatus:false});
        if(status===false){
            toast.error("We are facing some internal issue. Please try again later.");
        }else{
            this.setState({password:"", confirmPassword:""});
            toast.success("Password updated successfully.");
        }
    }
    validateNewPassword = () => {
        this.setPasswordPolicy(false);
        if(!util.checkPassword(this.state.password)){
            this.setState({"passwordError":true})
        } else {
            this.setState({"passwordError":false})
        }
        if(this.state.confirmPassword && this.state.confirmPassword !== this.state.password) {
            this.setState({"passwordError":false})
            this.setState({"confirmPasswordError":true})
        } else {
            this.setState({"confirmPasswordError":false})
        }
    }
    setPasswordPolicy =(val)=>{
        this.setState({"passwordPolicy": val})
    }
    showHide(val){
        if(val==="NewPassword"){
            this.setState({ "passwordType": (this.state.passwordType === 'password') ? 'text' : 'password' })
        }
        if(val==="ConfirmPassword"){
            this.setState({ "confirmPasswordType": (this.state.confirmPasswordType === 'password') ? 'text' : 'password' })
        }
    }
    handleChangePassword=(e)=>{
        this.setState({password: e.target.value})
    }
    validateConfirmPassword = () => {
        this.setConfirmPasswordPolicy(false)
        if(this.state.password !== this.state.confirmPassword){
            this.setState({"confirmPasswordError":true})
        } else {
            this.setState({"confirmPasswordError":false})
        }
    }
    handleChangeConfirmPassword=(e)=>{
        this.setState({confirmPassword: e.target.value})
    }
    setConfirmPasswordPolicy =(val)=>{
        this.setState({"confirmPasswordPolicy": val})
    }
    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.progressStatus}/>
                <MDBContainer>
                    <MDBCard className={"mt-2"}>
                        <MDBCardHeader>
                            <span className={"float-left title"}>Change Password</span>
                        </MDBCardHeader>
                        <MDBCardBody>
                            <MDBRow className="d-flex justify-content-center">
                                <MDBCol size={"9"}>
                                    <MDBInput onBlur={this.validateNewPassword}
                                              autoComplete={'off'} onFocus={() => this.setPasswordPolicy(true)}
                                              className={this.state.passwordError ? "border-danger" : ""}
                                              maxLength="40" type={this.state.passwordType} label="New Password"
                                              onChange={this.handleChangePassword}
                                              value={this.state.password}/>
                                    {(this.state.password) &&
                                    <span style={{"marginTop": "-50px", "position":"absolute", "right": "15px", "cursor": "pointer"}} onClick={()=>this.showHide("NewPassword")}
                                          className={this.state.passwordType === 'text' ? 'fa fa-fw fa-eye-slash field-icon' : 'fa fa-fw fa-eye field-icon'}></span>
                                    }
                                    {(this.state.passwordPolicy) &&

                                    <div className="show popover" style={{"top": "5rem", "marginLeft": "5rem"}}>
                                        <div className=""><h3 data-test="popover-header" className="popover-header">Password Policy</h3>
                                            <div data-test="popover-body" className="popover-body">
                                                <div className={ util.passwordLenth(this.state.password) ? "green-text" : "red-text"}>1. At least six characters</div>
                                                <div className={ util.passwordLetter(this.state.password) ? "green-text" : "red-text"}>2. At least one letter</div>
                                                <div className={ util.passwordDigit(this.state.password) ? "green-text" : "red-text"}>3. At least one digit</div>
                                            </div>
                                        </div>
                                        <span x-arrow="" className="popover_arrow"></span>
                                    </div>
                                    }
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="d-flex justify-content-center">
                                <MDBCol size={"9"}>
                                <MDBInput autoComplete={'off'} onBlur={this.validateConfirmPassword}
                                          className={this.state.confirmPasswordError ? "border-danger" : ""}
                                          maxLength="40" type={this.state.confirmPasswordType} label="Confirm Password"
                                          onChange={this.handleChangeConfirmPassword} onFocus={() => this.setConfirmPasswordPolicy(true)}
                                          value={this.state.confirmPassword}/>
                                {(this.state.confirmPassword) &&
                                <span style={{"marginTop": "-50px", "position":"absolute", "right": "15px", "cursor": "pointer"}} onClick={()=>this.showHide("ConfirmPassword")}
                                      className={this.state.confirmPasswordType === 'text' ? 'fa fa-fw fa-eye-slash field-icon' : 'fa fa-fw fa-eye field-icon'}></span>
                                }
                                {(this.state.confirmPasswordPolicy) &&
                                <div className="show popover" style={{"top": "5rem", "marginLeft": "5rem"}}>
                                    <div className=""><h3 data-test="popover-header" className="popover-header">Password Policy</h3>
                                        <div data-test="popover-body" className="popover-body">
                                            <div className={ util.passwordLenth(this.state.confirmPassword) ? "green-text" : "red-text"}>1. At least six characters</div>
                                            <div className={ util.passwordLetter(this.state.confirmPassword) ? "green-text" : "red-text"}>2. At least one letter</div>
                                            <div className={ util.passwordDigit(this.state.confirmPassword) ? "green-text" : "red-text"}>3. At least one digit</div>
                                        </div>
                                    </div>
                                    <span x-arrow="" className="popover_arrow"></span>
                                </div>
                                }
                                </MDBCol>
                            </MDBRow>
                            <div className="text-center p-2 col-12">
                                <MDBBtn  size="md"  onClick={this.changePassword}
                                        disabled={this.state.passwordError || this.state.confirmPasswordError || this.state.progressStatus}>
                                    Save
                                </MDBBtn>
                            </div>
                        </MDBCardBody>
                    </MDBCard>
                </MDBContainer>
            </React.Fragment>
        );
    }
}
export default withRouter(ChangePasswordCmp)


