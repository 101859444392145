import React from "react";
import UserStore from "../../stores/userStore";
import {toast} from "react-toastify";
import SimpleBackdrop from "../common/overlay";
import {
    MDBBtn, MDBCard, MDBCardBody, MDBTabPane, MDBTabContent,
    MDBModal, MDBModalHeader, MDBModalBody
} from "mdbreact";
import CmsService from "../../services/cmsService";
import config from "../../config";
import AddBanner from "./addBanner";
import Moment from "moment";
import {CSVLink} from "react-csv";
import Moments from "moment";

const headers = [
    {label: "Banner Name", key: "bannerName"},
    {label: "Banner Text", key: "bannerText"},
    {label: "Banner Link", key: "bannerLink"},
    {label: "MRP", key: "mrp"},
    {label: "Discount", key: "discount"},
    {label: "Web banner image", key: "image"},
    {label: "Tab banner image", key: "imageTab"},
    {label: "Mobile banner image", key: "imageMob"},
    {label: "Is Active", key: "isActive"},
    {label: "Is Deleted", key: "isDeleted"},
];

class CmsCmp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTabView: 'active',
            userData: {},
            banners: [], bannerName: "", bannerText: "", bannerLink: "",
            docs: "", docsTab: "", docsMob: "",
            mrp: "", discount: "",
            webBannerSpinner: false, tabBannerSpinner: false, mobileBannerSpinner: false,
            modalAddBanner: false, activeBanner: null
        }
    }

    async componentDidMount() {
        let userData = await UserStore.fetchSessionData();
        this.setState({userData});
        await this.refreshPage()
    }

    refreshPage = async () => {
        this.setState({isLoading: true})
        let banners = await CmsService.fetchBanners(this.state.userData);
        this.setState({isLoading: false})
        if (banners === false) {
            toast.error("We are facing some server issue. Please try again later.");
        } else {
            this.setState({banners});
        }
    }
    toggle = nr => () => {
        let modalNumber = 'modal' + nr;
        this.setState({
            [modalNumber]: !this.state[modalNumber]
        });
    }
    delete = async (item) => {
        this.setState({isLoading: true})
        let status = await CmsService.deleteBanner(this.state.userData, {_id: item._id});
        this.setState({isLoading: false})
        if (status !== false) {
            toast.success("Banner configuration deleted successfully.");
            await this.refreshPage()
        } else {
            toast.error("We are facing some server issue. Please try again later.");
        }
    }
    deactivate = async (row) => {
        this.setState({isLoading: true})
        let status = await CmsService.deActivateBanner(this.state.userData, {_id: row._id});
        this.setState({isLoading: false})
        if (status === false) {
            toast.error("We are facing some server issue. Please try again later.");
        } else {
            toast.success("Banner deactivated successfully.");
            await this.refreshPage()
        }
    }
    activate = async (row) => {
        this.setState({isLoading: true})
        let status = await CmsService.activateBanner(this.state.userData, {_id: row._id});
        this.setState({isLoading: false})
        if (status === false) {
            toast.error("We are facing some server issue. Please try again later.");
        } else {
            toast.success("Banner activated successfully.");
            await this.refreshPage()
        }
    }
    activeTabChange = tab => e => {
        if (this.state.activeTabView !== tab) {
            this.setState({
                activeTabView: tab
            });
        }
    };
    editBanner = (item) => {
        this.setState({activeBanner: item})
        this.toggle("AddBanner")()
    }
    addBanner = () => {
        this.setState({activeBanner: null})
        this.toggle("AddBanner")()
    }

    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.isLoading}/>
                <div className={'px-4'}>
                    <div className={'float-right'}>
                        <MDBBtn size={'md'} onClick={this.addBanner}> <i
                            className="fas fa-plus-circle"></i> Add banner</MDBBtn>
                        <CSVLink data={this.state.banners} headers={headers}
                                 filename={"Banners_" + Moments(new Date()).format("DD-MM-YYYY") + ".csv"}>
                            <MDBBtn size={'md'}  disabled={this.state.isLoading}><i
                                className="fas fa-download"></i> &nbsp;Export</MDBBtn>
                        </CSVLink>
                    </div>
                    <div className={'d-flex'}>
                        <a className={this.state.activeTabView === "active" ? "header-tab-btn active" : "header-tab-btn"}
                           onClick={this.activeTabChange("active")} role="tab">
                            <div className={'col-12'}>Active</div>
                        </a>
                        <a className={this.state.activeTabView === "deactive" ? "header-tab-btn active" : "header-tab-btn"}
                           onClick={this.activeTabChange("deactive")} role="tab">
                            <div className={'col-12'}>Deactive</div>
                        </a>
                    </div>
                </div>


                <div className={'white'}>
                    <MDBTabContent activeItem={this.state.activeTabView}>
                        <MDBTabPane tabId="active" role="tabpanel">
                            {this.state.banners.map((item, index) => (
                                <div key={index}>
                                    {item.isActive === "Y" &&
                                    <MDBCard className={'mx-3 mb-3'}>
                                        <MDBCardBody className={'p-0'}>
                                            <div className={'main-title p-2'}>{index + 1}{" " + item.bannerName}</div>
                                            <div className={'row col-12 mx-0 my-2 p-0'}>
                                                <div className={'col-3 img-outlet text-center p-3 mx-2'}>
                                                    <div className={'banner-type'}> Website </div>
                                                    <img className={'mt-4'}
                                                        src={config.IMAGE_HOST + item.image}
                                                        width="300" height="70"/>
                                                </div>
                                                <div className={'col-3 img-outlet text-center p-3 mx-2'}>
                                                    <div className={'banner-type'}> Tablet </div>
                                                    <img className={'mt-4'}
                                                        src={config.IMAGE_HOST + item.imageTab}
                                                        width="250" height="60"/>
                                                </div>
                                                <div className={'col-3 img-outlet text-center p-3 mx-2'}>
                                                    <div className={'banner-type'}> Mobile </div>
                                                    <img className={'mt-4'}
                                                        src={config.IMAGE_HOST + item.imageMob}
                                                        width="150" height="60"/>
                                                </div>
                                            </div>
                                            <div className={'row m-1'}>
                                                <div className={'col-md-3 col-6'}>Banner Title</div>
                                                <div className={'col font-weight-bold'}>{item.bannerName}</div>
                                            </div>
                                            <div className={'row m-1'}>
                                                <div className={'col-md-3 col-6'}>Banner Text</div>
                                                <div className={'col font-weight-bold'}>{item.bannerText}</div>
                                            </div>
                                            <div className={'row m-1'}>
                                                <div className={'col-md-3 col-6'}>Banner Link</div>
                                                <div
                                                    className={'col font-weight-bold'}>{item.bannerLink}
                                                </div>
                                            </div>
                                            <div className={'row m-1'}>
                                                <div className={'col-md-3 col-6'}>Date</div>
                                                <div
                                                    className={'col font-weight-bold'}>{Moment(item.createdAt).format("DD/MM/YYYY")}</div>
                                            </div>
                                            <div className={'row m-1'}>
                                                <div className={'col-md-3 col-6'}>MRP</div>
                                                <div className={'col font-weight-bold'}>{item.mrp}</div>
                                            </div>
                                            <div className={'row m-1'}>
                                                <div className={'col-md-3 col-6'}>Discount</div>
                                                <div className={'col font-weight-bold'}>{item.discount}</div>
                                            </div>
                                            <div className={'float-right'}>
                                                <MDBBtn size={'md'} outline href="#"
                                                        onClick={() => this.deactivate(item)}>Deactivate</MDBBtn>
                                                <MDBBtn size={'md'} outline href="#"
                                                        onClick={() => this.editBanner(item)}>Edit</MDBBtn>
                                                <MDBBtn size={'md'} outline href="#"
                                                        onClick={() => this.delete(item)}>Delete</MDBBtn>
                                            </div>
                                        </MDBCardBody>
                                    </MDBCard>
                                    }
                                </div>
                            ))}
                        </MDBTabPane>
                        <MDBTabPane tabId="deactive" role="tabpanel">
                            {this.state.banners.map((item, index) => (
                                <div key={index}>
                                    {item.isActive === "N" &&
                                    <MDBCard className={'mx-3 mb-3'}>
                                        <MDBCardBody className={'p-0'}>
                                            <div className={'main-title p-2'}>{index + 1}{" " + item.bannerName}</div>
                                            <div className={'row col-12 mx-0 my-2 p-0'}>
                                                <div className={'col-3 img-outlet text-center p-3 mx-2'}>
                                                    <div  className={'banner-type'}>Website</div>
                                                    <img className={'mt-4'}
                                                        src={config.IMAGE_HOST + item.image}
                                                        width="300" height="70"/>
                                                </div>
                                                <div className={'col-3 img-outlet text-center p-3 mx-2'}>
                                                    <div  className={'banner-type'}>Tablet</div>
                                                    <img className={'mt-4'}
                                                        src={config.IMAGE_HOST + item.imageTab}
                                                        width="250" height="60"/>
                                                </div>
                                                <div className={'col-3 img-outlet text-center p-3 mx-2'}>
                                                    <div className={'banner-type'}>Mobile</div>
                                                    <img className={'mt-4'}
                                                        src={config.IMAGE_HOST + item.imageMob}
                                                        width="150" height="60"/>
                                                </div>
                                            </div>
                                            <div className={'row m-1'}>
                                                <div className={'col-md-3 col-6'}>Banner Title</div>
                                                <div className={'col font-weight-bold'}>{item.bannerName}</div>
                                            </div>
                                            <div className={'row m-1'}>
                                                <div className={'col-md-3 col-6'}>Banner Text</div>
                                                <div className={'col font-weight-bold'}>{item.bannerText}</div>
                                            </div>
                                            <div className={'row m-1'}>
                                                <div className={'col-md-3 col-6'}>Banner Link</div>
                                                <div
                                                    className={'col font-weight-bold'}>{item.bannerLink}
                                                </div>
                                            </div>
                                            <div className={'row m-1'}>
                                                <div className={'col-md-3 col-6'}>Date</div>
                                                <div
                                                    className={'col font-weight-bold'}>{Moment(item.createdAt).format("DD/MM/YYYY")}</div>
                                            </div>
                                            <div className={'row m-1'}>
                                                <div className={'col-md-3 col-6'}>MRP</div>
                                                <div className={'col font-weight-bold'}>{item.mrp}</div>
                                            </div>
                                            <div className={'row m-1'}>
                                                <div className={'col-md-3 col-6'}>Discount</div>
                                                <div className={'col font-weight-bold'}>{item.discount}</div>
                                            </div>
                                            <div className={'float-right'}>
                                                <MDBBtn size={'md'} outline href="#"
                                                        onClick={() => this.activate(item)}>Activate</MDBBtn>
                                                <MDBBtn size={'md'} outline href="#"
                                                        onClick={() => this.editBanner(item)}>Edit</MDBBtn>
                                                <MDBBtn size={'md'} outline href="#"
                                                        onClick={() => this.delete(item)}>Delete</MDBBtn>
                                            </div>
                                        </MDBCardBody>
                                    </MDBCard>
                                    }
                                </div>
                            ))}
                        </MDBTabPane>
                    </MDBTabContent>
                </div>
                <MDBModal isOpen={this.state.modalAddBanner} toggle={this.toggle("AddBanner")} size="fluid">
                    <MDBModalHeader
                        toggle={this.toggle("AddBanner")}>{this.state.activeBanner !== null ? "Edit banner" : "Add new banner"}</MDBModalHeader>
                    <MDBModalBody>
                        <AddBanner toggle={this.toggle("AddBanner")} refreshPage={this.refreshPage}
                                   banner={this.state.activeBanner}/>
                    </MDBModalBody>
                </MDBModal>
            </React.Fragment>
        )
    }
}

export default CmsCmp;