import React from "react";
import MaterialTable from "material-table";
import PaggingFunc from "../../../newComponents/Pagination/PaggingFunc";

const TransferStockTable = ({
  packageTableData = [],
  columnData = [],
  pageSize = 10,
  currentPage,
  totalRecords,
  handlePageChangePar,
}) => {
  const handlePageChange = (newPage) => {
    handlePageChangePar(newPage);
  };

  return (
    <div>
      <MaterialTable
        columns={columnData}
        data={packageTableData}
        options={{
          search: false,
          showTitle: false,
          paging: false,
          emptyRowsWhenPaging: false,
          tableLayout: "fixed",
          rowStyle: (rowData) => {
            const style = {};
            if (rowData.stockStatus === "Expired") {
              style.backgroundColor = "#FF7F7F";
              style.color = "white";
            } else if (rowData.quantity <= 0) {
              style.backgroundColor = "#ffffba";
            }
            return style;
          },
          debounceInterval: 800,
        }}
      />
      {packageTableData && packageTableData.length > 0 && (
        <PaggingFunc
          currentPage={currentPage}
          totalRecords={totalRecords}
          pageSize={pageSize}
          handlePageChange={handlePageChange}
        />
      )}
    </div>
  );
};

export default TransferStockTable;
