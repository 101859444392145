import axios from "axios";
import config from "../config";

const ReportService = {

    fetchJob: async function (userData, payload) {
        try {
            const headers = {
                headers: { Authorization: userData.sessionToken },
            };
            const queryParams = new URLSearchParams(payload).toString();
            const url = `${config.APIHOST_3}${config.FETCHALLJOBS}?${queryParams}`;

            console.log(url)
            const response = await axios.get(
                url,
                headers
            );
            return response.data;
        } catch (e) {
            return false;
        }
    },

    publishJob: async function (userData, payload) {
        try {
            const headers = {
                headers: { Authorization: userData.sessionToken },
            };
            const response = await axios.post(
                config.APIHOST_3 + config.PUBLISH_JOB,
                payload,
                headers
            );

            return response.data;
        } catch (e) {
            console.log(e);
            return false;
        }
    },

    fetchJobHistory: async function (userData, payload) {
        try {
            const headers = {
                headers: { Authorization: userData.sessionToken },
            };
            const queryParams = new URLSearchParams(payload).toString();
            const url = `${config.APIHOST_3}${config.FETCH_JOB_HISTORY}?${queryParams}`;

            //   const response = await axios.get(config.APIHOST_3 + config.FETCH_JOB_HISTORY + queryParams , headers );
            const response = await axios.get(url, headers);

            return response.data;
        } catch (e) {
            return false;
        }
    },



    fetchReportSchedule: async function (userData, payload) {
        try {
            const headers = {
                headers: { Authorization: userData.sessionToken },
            };
            const queryParams = new URLSearchParams(payload).toString();
            const url = `${config.APIHOST_3}${config.FETCH_REPORT_SCHEDULE}?${queryParams}`;
            console.log(url);

            const response = await axios.get(url, headers);
            return response.data;
        } catch (e) {
            return false;
        }
    },

    downloadReportSchedule: async function (userData, payload) {
        try {
            const headers = {
                Authorization: userData.sessionToken,
            };
            // Ensure fileName is not double encoded
            const url = `${config.APIHOST_3}${config.DOWNLOAD_REPORT}?fileName=${payload.fileName}`;
            console.log(url);

            const response = await axios.get(url, { headers });
            console.log(response);
            return response.data;
        } catch (e) {
            return false;
        }
    },

    downloadJobHistory: async function (userData, payload) {
        try {
            const headers = {
                Authorization: userData.sessionToken,
            };
            // Ensure fileName is not double encoded
            const url = `${config.APIHOST_3}${config.DOWNLOAD_JOBS}?fileName=${payload.fileName}`;
            console.log(url);

            const response = await axios.get(url, { headers });
            console.log(response);
            return response.data;
        } catch (e) {
            return false;
        }
    },


    fetchAllReports: async (userData, payload) => {
        try {
            const headers = {
                headers: { Authorization: userData.sessionToken },
            };
            const queryParams = new URLSearchParams(payload).toString();
            const url = `${config.APIHOST_3}${config.FETCH_ALL_REPORTS}?${queryParams}`;

            const response = await axios.get(url, headers);
            return response.data;
        } catch (e) {
            return false;
        }
    },

    updateReport: async (userData, requestbody) => {
        try {
            let payload = {
                "name": requestbody.name,
                "description": requestbody.description,
                "id": requestbody.id
            }
            let headers = {
                headers: { Authorization: userData.sessionToken },
            }

            const response = await axios.put(config.APIHOST_3 + config.UPDATE_REPORT, payload, headers);
            return response.data;
        } catch (error) {
            throw new Error("Failed to update report: " + error.message);
        }
    },


    deleteReport: async (userData, reportId, name) => {
        try {
            let headers = {
                headers: { Authorization: userData.sessionToken },
            };
            const response = await axios.delete(`${config.APIHOST_3}${config.DELETE_REPORT}?id=${reportId}&name=${name}`, headers);
    
            return response.data;
        } catch (error) {
            throw new Error("Failed to delete report: " + error.message);
        }
    },    


    generatereport: async function (userData, requestbody) {
        try {
            let payload = {                
                reportType: requestbody.reportType,
                startDate: requestbody.startDate,
                endDate: requestbody.endDate,
            }
            const headers = {
                headers: { Authorization: userData.sessionToken },
            };
            const response = await axios.post(
                config.APIHOST_3 + config.GENERATE_REPORT,
                payload,
                headers
            );

            return response.data;
        } catch (e) {
            return false;
        }
    },

    createreport: async function (userData, requestbody) {
        try {
            let payload = {
                name: requestbody.name,
                description: requestbody.description,                
            }
            const headers = {
                headers: { Authorization: userData.sessionToken },
            };
            const response = await axios.post(
                config.APIHOST_3 + config.CREATE_REPORT,
                payload,
                headers
            );

            return response.data;
        } catch (e) {
            return false;
        }
    },



};

export default ReportService;
