import React from "react";
import {Link, withRouter} from "react-router-dom";
import {
    MDBContainer, MDBFormInline, MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBBreadcrumb, MDBBreadcrumbItem,
    MDBModalHeader, MDBModalBody, MDBModal, MDBDataTable
} from 'mdbreact';
import UserStore from "../../stores/userStore";
import {toast} from "react-toastify";
import PharmacyService from "../../services/pharmacyService";
import dataTableDoctor from "./dataTable";
import config from "../../config";
import Moments from "moment"
import SimpleBackdrop from "../common/overlay";
import UpdateDoctor from "../../components/doctors/editDoctorCmp"
import orgConfig from "../../orgConfig";


class ListDoctorsCmp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: {},
            disableRetreatAfterSorting:false,
            activeCustomerName:"",
            activeCustomerId:0,
            customerList:[],
            customerApiResultCame:false,
            progressStatus:false,
            data:{},
            activeCustomer:{
                imageUrl:{
                    filePathoriginal:""
                }
            },
            activeDoctor:{},
            modalStatus:false,
            statusActionName:"",
            modalEdit:false,
            }
    }
    // This methods calls first
    async componentDidMount() {
        let userData=await UserStore.fetchSessionData();
        this.setState({userData: userData})
        // We want to make sure that we call the customer when this component loads.
        this.fetchDoctor()
    }
    // This will fetch the customer and store the data in state and initiate the MDBDataTable
    fetchDoctor = async () => {
        await this.setState({disableRetreatAfterSorting:true});
        let userData=await UserStore.fetchSessionData();
        this.setState({progressStatus:true});
        let data = await PharmacyService.fetchDoctor(userData);
        this.setState({customerApiResultCame:true})
        this.setState({progressStatus:false});
        if(data){
            try{
                this.setState({progressStatus: false, customerList: data});
                let dataTablePayload={
                    columns:dataTableDoctor.doctor,
                    rows:data.map((item, index) => {
                        // item["customerSince"]=this.convertToReadbleDate(item.memberSince)
                        item["handleEdit"]=<div color="primary" size="sm">
                            <span className={"blue-text btn-link cursor-pointer"}  onClick={()=>{this.onView(item)}}>Edit</span>
                        </div>;
                        return item;
                    })
                };
                this.setState({data:dataTablePayload});
            } catch (e) {
                toast.error("Something went wrong while fetching customer.")
            }
        }
        await this.setState({disableRetreatAfterSorting:false});
    }
    convertToReadbleDate = date =>{
        return Moments(date).format("MMM D, YYYY");
    }
    // This toggle opens or closes the delete customer modal
    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    }
    // This toggle opens or closes the custom lightbox
    toggleLB = () => {
        this.setState({
            modalLB: !this.state.modalLB
        });
    }

    toggleEdit = () => {
        this.setState({
            modalEdit: !this.state.modalEdit
        });
    }
    onEditComplete = (row) => {
        this.toggleEdit()
        this.fetchDoctor()

    }
    // This triggers from MDBDataTable, aim is to redirect to edit page
    onEdit =  (row) => {
        this.setState({activeDoctor: row})
        this.toggleEdit()
    }
    // This triggers from MDBDataTable, aim is to redirect to edit page
    // onEdit=(row)=>{
    //     this.props.history.push('/edit-customer/'+row._id);
    // }
    onView=(row)=>{
        this.setState({activeDoctor: row})
        this.toggleEdit()
        //this.props.history.push('/edit-doctor/'+row._id);
    }
    onDownloadImage=(row)=>{
        console.log(row);
        this.setState({"activeDoctor":row})
        this.toggleLB()
    }
    // This redirect to new customer
    goToNewCustomer = () =>{
        this.props.history.push('/add-doctor/');
    }

    render() {
        return (
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>View Doctors</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <SimpleBackdrop status={(this.state.progressStatus === true || this.state.updateProgress === true)?true:false}/>
                <MDBContainer className={'w-100 mw-100'}>
                    <MDBCard className={"mt-2"}>
                        <div className={'col-12 p-2 grey lighten-4 border-bottom d-flex justify-content-between align-items-center'}>
                            <b className={"font-weight-bold title"}>View Doctors</b>
                            {(this.state.userData.type === "platformAdmin") &&
                            <MDBBtn size={'md'} onClick={this.goToNewCustomer}>Add Doctor</MDBBtn>
                            }
                        </div>
                        <MDBCardBody>
                            {(this.state.customerApiResultCame && this.state.customerList.length < 1) &&
                            <div className={'no-data-container'}>No doctor found</div>
                            }
                            {this.state.customerList.length > 0 &&
                            <MDBDataTable
                                striped

                                data={this.state.data}
                                materialSearch
                                noBottomColumns
                                hover
                                disableRetreatAfterSorting={this.state.disableRetreatAfterSorting}
                            />
                            }
                        </MDBCardBody>
                    </MDBCard>
                    <MDBModal isOpen={this.state.modalLB} toggle={this.toggleLB} size={"lg"}>
                        <MDBModalHeader toggle={this.toggleLB}></MDBModalHeader>
                        <MDBModalBody className={"float-center"}>
                            <div style={{"textAlign":"center"}}>
                                <img src={config.IMAGE_HOST + this.state.activeCustomer.imageUrl.filePathOriginal} className={"float-center"}
                                     style={{maxWidth: "440px", maxHeight: "440px"}} alt={""}/>
                            </div>
                        </MDBModalBody>
                    </MDBModal>

                    <MDBModal isOpen={this.state.modalEdit} toggle={this.toggleEdit} size={"fluid"}>
                        <MDBModalHeader toggle={this.toggleEdit}></MDBModalHeader>
                        <MDBModalBody className={"float-center"}>
                            <UpdateDoctor loadedOnPopup={true} onEditComplete={this.onEditComplete}  doctorId={this.state.activeDoctor._id}/>
                        </MDBModalBody>
                    </MDBModal>

                </MDBContainer>
            </React.Fragment>
        );
    }
}
export default withRouter(ListDoctorsCmp)
