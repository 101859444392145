import React, {Suspense} from "react";
import FulfilOfflineOrderCmp from "../../components/fulfill-order/fulfilOfflineOrder";
import {MDBBreadcrumb, MDBBreadcrumbItem} from "mdbreact";
import {Link} from "react-router-dom";
class FulfilOfflineOrder extends React.Component {
    render() {
        return(
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Fulfill Order New</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <Suspense fallback={null}>
                    <FulfilOfflineOrderCmp/>
                </Suspense>
            </React.Fragment>
        )
    }
}
export default FulfilOfflineOrder;