import React from "react";
import {MDBBreadcrumb, MDBBreadcrumbItem} from "mdbreact";
import {Link} from "react-router-dom";
import {withRouter} from "react-router-dom";
import CouponV2 from "../components/offers/listCoupons";

class Coupons extends React.Component {
    render() {
        return(
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Coupons</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <div className={"min-vh-100 p-0 m-0"}>
                    <CouponV2/>
                </div>
            </React.Fragment>
        )
    }
}
export default withRouter(Coupons);