import React from "react";
import UserStore from "../../stores/userStore";
import {toast} from "react-toastify";
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCol,
    MDBContainer,
    MDBFormInline, MDBInput,
    MDBProgress,
    MDBRow
} from "mdbreact";
import OrderService from "../../services/orderService";
class EditRack extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            rackDetails: this.props.rackDetails,
            floorDetails: this.props.floorDetails,
            userData: {},
            rackName: this.props.rackDetails.rackName,
            rackNameError: false
        }
    }

    async componentDidMount() {
        let userData = await UserStore.fetchSessionData()
        this.setState({userData})
    }
    updateRack = async() => {
        if (this.state.rackName === "") {
            toast.error("Please enter the rack name.")
            this.setState({rackNameError: true})
            return
        }
        let payload = {
            rackId: this.state.rackDetails._id,
            rackName: this.state.rackName
        }
        this.setState({progressStatus: true})
        let res = await OrderService.editRack(this.state.userData.sessionToken, payload)
        if (res) {
            toast.success("Rack name updated successfully.")
            this.props.toggleEdit()
        } else {
            toast.error("Something went wrong while updating the rack.")
        }
        this.setState({progressStatus: false})
    }
    validateRackName = () => {
        (this.state.rackName === "") ? this.setState({rackNameError: true}) : this.setState({rackNameError: false})
    }
    render() {
        return(
            <React.Fragment>
                <MDBContainer>
                    <MDBCard className={"mt-2"}>
                        <MDBCardHeader>
                            <span className={"float-left"}>Edit Rack</span>
                            <MDBFormInline className="float-right md-form mr-auto m-0">
                                <MDBBtn outline color="blue-grey" size="sm" onClick={this.updateRack}
                                        className="mr-auto"
                                        disabled={this.state.progressStatus}>
                                    Save
                                </MDBBtn>
                            </MDBFormInline>
                        </MDBCardHeader>
                        {this.state.progressStatus === true &&
                        <span className={'top-stick'}><MDBProgress material preloader color="cyan"/></span>
                        }
                        <MDBCardBody>
                            <MDBRow className={"my-1 p-3"}>
                                <MDBCol className={"mt-n4"}>
                                    <MDBInput id={"name"} label="Floor Name" disabled value={this.state.floorDetails[0].floorName} maxLength={"40"}/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className={"p-3"}>
                                <MDBCol className={"mt-n4"}>
                                    <MDBInput id={"rackName"} label={"Rack Name *"} value={this.state.rackName} getValue={(val) => {this.setState({rackName: val})}}
                                              className={this.state.rackNameError ? "border-danger" : ""} maxLength={"40"} onBlur={this.validateRackName}/>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                </MDBContainer>
            </React.Fragment>
        )
    }
}
export default EditRack;