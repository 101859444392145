import React,{Suspense} from "react";
import {Link} from "react-router-dom";
import {
    MDBBreadcrumbItem,
    MDBBreadcrumb,
} from 'mdbreact';
//const ProductSearchCmp=React.lazy(() => import("../../components/product/productSearch"));
import ProductSearchCmp from "../../components/product/productSearch";

export default class ProductSearch extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Products Search</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <Suspense fallback={null}>
                    <ProductSearchCmp/>
                </Suspense>
            </React.Fragment>
        );
    }
}
