import React from "react";
import {Link, withRouter} from "react-router-dom";
import {
    MDBBox,
    MDBBreadcrumb,
    MDBBreadcrumbItem,
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardHeader, MDBChip, MDBCol,
    MDBContainer,
    MDBDataTable,
    MDBFormInline, MDBInput,
    MDBModal,
    MDBModalBody,
    MDBModalFooter,
    MDBModalHeader
} from 'mdbreact';
import UserStore from "../../stores/userStore";
import {toast} from "react-toastify";
import SimpleBackdrop from "../common/overlay";
import SpecialityService from "../../services/specialityService";
import dataTableConfig from "./dataTable";
import Moments from "moment";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

class ListSpeciality extends React.Component {
    async componentDidMount() {
        this.fetchSpeciality();
    }
    fetchSpeciality=async ()=>{
        await this.setState({disableRetreatAfterSorting:true});
        let userData=await UserStore.fetchSessionData();
        this.setState({progressStatus:true});
        let res=await SpecialityService.fetchSpeciality(userData);
        if(res===false){
            this.setState({progressStatus:false});
            toast.error("We are facing some issue. Please try again later.");
        }else{
            this.setState({progressStatus:false,apiResultCame:true,specialitys:res});
            let dataTablePayload = {
                columns: dataTableConfig.specialityGroup,
                rows: res.map((row, index) => {
                    let temp={};
                    temp["speciality"]=row.speciality;
                    temp["specialityGroup"]=row.specialityGroup.specialityGroup;
                    temp["createdBy"]=row.createdBy;
                    temp["createdAt"]=this.convertToReadbleDate(row.createdAt);
                    temp["delete"]=<a><span onClick={()=>{this.delete(row)}} className={"blue-text btn-link"}>Delete</span></a>;
                    temp["edit"]=<a><span onClick={()=>{this.edit(row)}} className={"blue-text btn-link"}>Edit</span></a>
                    return temp;
                })
            }
            this.setState({data:dataTablePayload});
        }
        await this.setState({disableRetreatAfterSorting:false});
    }
    goToNewSpeciality=()=>{
        this.props.history.push('/add-speciality');
    }
    constructor(props) {
        super(props);
        this.state = {
            progressStatus: false,
            specialitys:[],
            data:{},
            apiResultCame:false,
            modal:false,
            speciality:'',
            selectedItem:{},
            modalEdit:false,
            suggestedList:[],
            suggestedSpecialityGroup:[],
            masterSpecialityGroup:'',
            disableRetreatAfterSorting:false
        }
    }
    delete=(item)=>{
        this.setState({speciality:item.speciality,selectedItem:item});
        this.toggle();
    }
    edit=(item)=>{
        this.setState({speciality:item.speciality,selectedItem:item});
        this.toggleEdit();
    }
    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    }
    deleteSpeciality=async ()=>{
        let userData=await UserStore.fetchSessionData();
        this.setState({progressStatus:true});
        let res=await SpecialityService.deleteSpeciality(this.state.selectedItem._id,userData);
        if(res===false){
            this.setState({progressStatus:false});
            toast.error("We are facing some issue. Please try again later.");
        }else{
            this.toggle();
            this.setState({progressStatus:false});
            toast.success("Speciality deleted successfully.");
            this.fetchSpeciality();
        }
    }
    convertToReadbleDate = date =>{
        if(date){
            return Moments(date).format("MMM D, YYYY hh:mm A");
        }
    }
    toggleEdit=()=>{
        this.setState({
            modalEdit: !this.state.modalEdit
        });
    }
    editSpeciality=async()=>{
        let userData=await UserStore.fetchSessionData();
        this.setState({progressStatus:true});
        let specialityGroupId='';
        try{
            let specialityGroupId=this.state.masterSpecialityGroup.value._id;
        }catch (e){
            specialityGroupId='';
        }
        let res=await SpecialityService.editSpeciality(this.state.selectedItem._id,this.state.speciality,userData,specialityGroupId);
        if(res===false){
            this.setState({progressStatus:false});
            toast.error("We are facing some issue. Please try again later.");
        }else{
            this.toggleEdit();
            this.setState({progressStatus:false});
            toast.success("Speciality group updated successfully.");
            this.fetchSpeciality();
        }
    }
    fetchSuggestion=async()=>{
        try{
            if(this.state.specialityGroup!=='') {
                let userData=await UserStore.fetchSessionData();
                let data = await SpecialityService.lookupSpeciality(this.state.speciality,userData);
                if(data!==false){
                    this.setState({suggestedList:data});
                }
            }
        }catch (e){
            this.setState({suggestedList:[]});
        }
    }
    handleChange=(val)=>{
        this.setState({speciality:val});
    }
    fetchSpecialityGroup=async (e)=>{
        let term=e.target.value;
        if(term!=='') {
            let userData=await UserStore.fetchSessionData();
            let specialityGroup = await SpecialityService.lookupSpecialityGroup(term,userData);
            if(specialityGroup===false){
                toast.error("We are facing some issue. Please try again later.");
            }else{
                let temp = [];
                for (let item of specialityGroup) {
                    let str = {title: item.specialityGroup, value: item};
                    temp.push(str);
                }
                this.setState({suggestedSpecialityGroup:temp});
            }
        }
    }
    onChangeSpecialityGroup=(event, values)=>{
        this.setState({masterSpecialityGroup:values});
    }
    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.progressStatus}/>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>View Speciality</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <MDBContainer className={'w-100 mw-100'}>
                    <MDBBox>
                        <MDBCard className={"mt-2"} style={{"marginTop": "0px"}}>
                            <MDBCardHeader>
                                <span className={"float-left"}>View Speciality</span>
                                <MDBFormInline className="float-right md-form mr-auto m-0">
                                    <MDBBtn color="default" size="sm" onClick={this.goToNewSpeciality} className="">
                                        Add Speciality
                                    </MDBBtn>
                                </MDBFormInline>
                            </MDBCardHeader>
                            <MDBCardBody>
                                {(this.state.apiResultCame && this.state.specialitys.length === 0) &&
                                <div className={"no-data-container"}>No Speciality found.</div>
                                }
                                {this.state.specialitys.length > 0 &&
                                <MDBDataTable
                                    striped

                                    data={this.state.data}
                                    materialSearch
                                    noBottomColumns
                                    hover
                                    disableRetreatAfterSorting={this.state.disableRetreatAfterSorting}
                                />
                                }
                            </MDBCardBody>
                        </MDBCard>
                    </MDBBox>
                    <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
                        <MDBModalHeader toggle={this.toggle}>Delete Speciality</MDBModalHeader>
                        <MDBModalBody>
                            Are you sure you want
                            to delete speciality "{this.state.speciality}"?
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn onClick={this.deleteSpeciality}
                                    disabled={this.state.progressStatus}>Delete</MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>
                </MDBContainer>
                <MDBModal isOpen={this.state.modalEdit} toggle={this.toggleEdit}>
                    <MDBModalHeader toggle={this.toggleEdit}>Edit Speciality Group</MDBModalHeader>
                    <MDBModalBody>
                        <MDBBox>
                            <MDBInput onChange={this.fetchSuggestion} label="Speciality" getValue={this.handleChange} value={this.state.speciality}/>
                        </MDBBox>
                        <MDBBox>
                            {this.state.suggestedList.map((item, idx) => (
                                <span key={idx}><MDBChip bgColor="light-blue lighten-4" key={idx} waves>{item.speciality}</MDBChip></span>
                            ))}
                        </MDBBox>
                        <MDBBox>
                            Speciality Group: {this.state.selectedItem.hasOwnProperty("specialityGroup")?this.state.selectedItem.specialityGroup.specialityGroup:''}
                        </MDBBox>
                        <MDBBox>
                            <Autocomplete
                                key={this.state.keyCount}
                                freeSolo
                                onChange={this.onChangeSpecialityGroup}
                                id="combo-box-speciality"
                                options={this.state.suggestedSpecialityGroup}
                                getOptionLabel={option => option.title}
                                onInputChange={(e) => {
                                    this.fetchSpecialityGroup(e)
                                }}
                                selectOnFocus={true}
                                renderInput={params => (
                                    <TextField style={ this.state.specialityGroupError ?  {"borderBottom":"1px solid RED"} : {}} {...params} placeholder="Search Manufacturer"
                                               margin="normal" fullWidth/>
                                )}
                            />
                        </MDBBox>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn onClick={this.editSpeciality}
                                disabled={this.state.progressStatus}>Update</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
            </React.Fragment>
        );
    }
}

export default withRouter(ListSpeciality)
