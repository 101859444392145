import React from "react";
import {withRouter} from "react-router-dom";
import SimpleBackdrop from "../common/overlay";
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCol,
    MDBContainer,
    MDBDataTable,
    MDBInput, MDBModal, MDBModalBody, MDBModalHeader,
    MDBRow, MDBBadge, MDBSelect, MDBTable, MDBTableHead, MDBTableBody
} from "mdbreact";
import UserStore from "../../stores/userStore";
import {toast} from "react-toastify";
import dataTableConfig from "./dataTableProducts";
import ReportService from "../../services/reports";
import utility from "../../utility/utility";
import UpdateProductConfig from "../../pages/product/cms";
import UpdateProduct from "../../pages/product/editPkgDetails";
import ProductView from "../product/productView";
import config from "../../config";
import InventoryService from "../../services/inventoryService";

class VerifyProduct extends React.Component {
    constructor() {
        super();
        this.state = {
            _id:"",
            progressStatus: false,
            type: "all",
            comments:"",
            product: {
                verificationLog:[]
            },
            suggestedData:[],
            tableData: {columns: [], rows: []},

            modalStatus:false,
            activeProductId:0,
            modalEdit:false,
            modalView:false,
            activeProduct:{

            },
            modalAction:"",
            disableRetreatAfterSorting:false,


            stockAvailability:"DEFAULT",
            StockAvailabilityOptions: [{ text:"Default", value:"DEFAULT", checked:true },
                { text:"Always Available", value:"ALWAYS_AVAILABLE" },
                { text:"Easily Available", value:"EASILY_AVAILABLE" },
                { text:"Difficult to Procure", value:"DIFFICULT_TO_PROCURE" },
                { text:"Temporarily Unavailable", value:"TEMPORARILY_UNAVAILABLE" },
                { text:"Permanently Unavailable", value:"PERMANENTLY_UNAVAILABLE"}
            ],
        }
    }

    async componentDidMount () {
        let product = this.props.product;
        await this.setState({_id:product._id, product:product, loadedOnPopup:true})
        if(product.hasOwnProperty("stockAvailability")){
            this.setState({stockAvailability:product.stockAvailability})
        }
    }


    toggleView = () => {
        this.setState({
            modalView: !this.state.modalView
        });
    }



    handleStockAvailability = (e) => {
        this.setState({stockAvailability:e[0]})
    }



    confirmVerify = async ()=>{
        let userData = await UserStore.fetchSessionData();
        this.setState({progressStatus: true});
        let data = await ReportService.updateStockAvailability(userData, this.state);
        this.setState({progressStatus: false});
        this.props.onEditComplete()

    }



    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.progressStatus}/>
                <MDBContainer className={'w-100 mw-100'}>
                    <MDBCard className={"mt-2"}>
                        <MDBCardHeader>
                            <MDBRow >
                                <MDBCol>
                                    Update Stock Availability of Product
                                </MDBCol>
                            </MDBRow>
                        </MDBCardHeader>
                        <MDBCardBody>

                            {this.state.stockAvailability}


                            <MDBSelect outline={true} label={"Stock Availability"} getValue={this.handleStockAvailability} options={this.state.StockAvailabilityOptions}></MDBSelect>


                            <MDBBtn onClick={this.confirmVerify}>Update</MDBBtn>

                        </MDBCardBody>
                    </MDBCard>

                </MDBContainer>
            </React.Fragment>
        )
    }
}

export default withRouter(VerifyProduct)