const dataTableRacks = {
    racks:[
        {
            label: 'Rack Name',
            field: 'rackName',
            sort: 'asc',
            width: 400
        },
        {
            label: '',
            field: 'listBoxes',
            sort: 'asc',
            width: 50
        },
        {
            label: '',
            field: 'edit',
            sort: 'asc',
            width: 50
        },
        {
            label: '',
            field: 'delete',
            sort: 'asc',
            width: 50
        },
    ]
}
export default dataTableRacks;