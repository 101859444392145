import React,{Suspense} from "react";
import {
    MDBBox
} from 'mdbreact';
// const EditDoctor=React.lazy(() => import("../../components/doctors/editDoctorCmp"));
import EditDoctor from "../../components/doctors/editDoctorCmp";

export default class EditDoctorPage extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MDBBox className={"min-vh-100"}>
                    <Suspense fallback={null}>
                        <EditDoctor></EditDoctor>
                    </Suspense>
                </MDBBox>
            </React.Fragment>
        );
    }
}
