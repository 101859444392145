export const UNFULFILLED_TABLE_COLUMNS = [
  { header: "Order Id", width: "5%", accessor: "orderCode" },
  { header: "Date", width: "8%", accessor: "createdAt", sortable: true },
  { header: "Shipping Address", width: "14%", accessor: "shippingAddress" },
  { header: "Customer Name", width: "8%", accessor: "name" },
  { header: "Phone No", width: "5%", accessor: "phoneNumber" },
  {
    header: "Delivery Type",
    width: "5%",
    accessor: "deliveryType",
    sortable: true,
  },
  {
    header: "Payment Mode",
    width: "5%",
    accessor: "paymentMode",
    sortable: true,
  },
  { header: "Order Status", width: "5%", accessor: "status", sortable: true },
  { header: "Order Amount", width: "5%", accessor: "amount", sortable: true },
  { header: "Order Source", width: "5%", accessor: "orderSource" },
  { header: "Is Paid?", width: "5%", accessor: "isPaid", sortable: true },
];

export const PRODUCT_TABLE_COLUMNS = [
  { header: "Sl No.", width: "5%", accessor: "slno" },
  { header: "View Medicine", width: "8%", accessor: "medicine" },
  { header: "Product", width: "14%", accessor: "product" },
  { header: "Type", width: "8%", accessor: "type" },
  { header: "MRP", width: "5%", accessor: "mrp" },
  {
    header: "Batch - MRP",
    width: "5%",
    accessor: "batchMrp",
  },
  {
    header: "Quantity",
    width: "5%",
    accessor: "quantity",
  },
  { header: "Discount (%)", width: "5%", accessor: "discount" },
  { header: "Subtotal", width: "5%", accessor: "subTotal" },
];

export const UNFULFILLED_PAYMENT_TABLE_COLUMNS = [
  { header: "Paid Amount", width: "8%", accessor: "paidAmount" },
  { header: "Paid On", width: "14%", accessor: "paidOn" },
  { header: "Payment Mode", width: "8%", accessor: "paymentMode" },
  { header: "Balance Amount", width: "5%", accessor: "balanceAmount" },
  {
    header: "Shipping Charge",
    width: "5%",
    accessor: "shippingCharge",
  },
  {
    header: "Coupon Code",
    width: "5%",
    accessor: "couponCode",
  },
];

export const STATUS_DROPDOWN_ITEMS_UNFULFILLED = [
  "CREATED",
  "ACKNOWLEDGED",
  "APPROVED",
  "READYFORDELIVERY",
  "OUTFORDELIVERY",
];

export const ORDER_LOG_FORMAT = {
  ACKNOWLEDGED: "ACKNOWLEDGED",
  APPROVED: "APPROVED",
  READYFORDELIVERY: "READY FOR DELIVERY",
  OUTFORDELIVERY: "OUT FOR DELIVERY",
  COMPLETED: "COMPLETED",
};

export const STOCK_TABLE_WITH_LOCATION_TABLE = [
  { header: "Barcode", width: "3%", accessor: "barCode" },
  { header: "Product Name", width: "10%", accessor: "productName" },
  { header: "Location", width: "5%", accessor: "locationName" },
  { header: "Floor", width: "5%", accessor: "floorName" },
  { header: "Rack", width: "5%", accessor: "rackName" },
  { header: "Box", width: "5%", accessor: "boxName" },
  { header: "Quantity", width: "5%", accessor: "quantity"},
  { header: "MRP", width: "5%", accessor: "mrp"},
  { header: "Batch", width: "5%", accessor: "batch" },
];

export const STOCK_TABLE_TEMP_TRANSFER = [
  // { header: "Barcode", width: "3%", accessor: "barCode" },
  { header: "Product Name", width: "10%", accessor: "productName" },
  { header: "To Location", width: "5%", accessor: "locationName" },
  { header: "To Floor", width: "5%", accessor: "floorName" },
  { header: "To Rack", width: "5%", accessor: "rackName" },
  { header: "To Box", width: "5%", accessor: "boxName" },
  { header: "Quantity", width: "5%", accessor: "quantity"},
  { header: "MRP", width: "5%", accessor: "mrp"},
  { header: "Batch", width: "5%", accessor: "batch" },
];

