import React from "react";
import { MDBBreadcrumb, MDBBreadcrumbItem } from "mdbreact";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import MobileBannerCmp from "../../components/cms/mobileBannerCmp";

class MobileBanner extends React.Component {
  render() {
    return (
      <React.Fragment>
        <MDBBreadcrumb>
          <MDBBreadcrumbItem>
            <Link to={"/dashboard"}>Dashboard</Link>
          </MDBBreadcrumbItem>
          <MDBBreadcrumbItem active>
            Mobile Banner Configuration
          </MDBBreadcrumbItem>
        </MDBBreadcrumb>
        <div className={"min-vh-100 p-0 m-0"}>
          <MobileBannerCmp />
        </div>
      </React.Fragment>
    );
  }
}
export default withRouter(MobileBanner);
