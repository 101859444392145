const dataTableQuantityUnit= {
    quantityUnit:[
        {
            label: 'Quantity Unit',
            field: 'quantityUnit',
            sort: 'asc',
            width: 300
        },
        {
            label: 'Created By',
            field: 'createdBy',
            sort: 'asc',
            width: 100
        },
        {
            label: '',
            field: 'handleEdit',
            sort:'disabled',
            width: 50
        },
        {
            label: '',
            field:'handleStatus',
            sort:'disabled',
            width: 50
        }
    ]
}
export default dataTableQuantityUnit;