import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "./NewTable.module.css";
import sortArrow from "../../images/sort_arrows.png";
import NewPagination from "../NewPagination/NewPagination";

const NewTable = ({
  columns,
  data,
  headerBackground,
  headerColor,
  textAlign,
  customSortHandler,
  pagination,
  pageSize = 10,
}) => {
  const [tableData, setTableData] = useState(data);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const [page, setPage] = useState(0);
  const pageChangeHandler = (index) => {
    setPage(index);
  };

  const sortHandler = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }

    const sortedData = [...tableData].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    setTableData(sortedData);
    setSortConfig({ key, direction });
  };

  useEffect(() => {
    setTableData(data);
  }, [data]);
  return (
    <>
      <>
        <table className={styles.table} key={tableData}>
          <thead>
            <tr>
              {columns.map((column, index) => (
                <th
                  key={index}
                  style={{
                    width: column.width,
                    background: `${headerBackground}!important`,
                    color: headerColor,
                  }}
                  className={styles.table_head}
                >
                  <div
                    className={styles.header}
                    style={{ justifyContent: textAlign }}
                  >
                    <span>{column.header}</span>
                    {column.sortable && (
                      <button
                        onClick={
                          customSortHandler
                            ? () => customSortHandler(column.accessor)
                            : () => sortHandler(column.accessor)
                        }
                        className={styles.sortBtn}
                      >
                        <img
                          src={sortArrow}
                          style={{ filter: "invert(1)" }}
                          alt=""
                        />
                      </button>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData &&
              tableData?.length > 0 &&
              tableData
                ?.slice(
                  pagination ? page * pageSize : 0,
                  pagination ? (page + 1) * pageSize : tableData?.length
                )
                ?.map((row, rowIndex) => (
                  <tr key={rowIndex} className={styles.table_row}>
                    {Object.keys(row).map((key, index) => (
                      <td
                        key={index}
                        style={{ textAlign: textAlign }}
                        className={styles.table_data}
                      >
                        {row[key]}
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
          {data.length === 0 && (
            <p className="text-center p-4 w-100 border">No records found!</p>
          )}
          {(data.length > pageSize && pagination) && (
            <div className="my-3 d-flex flex-row justify-content-end w-100">
              <NewPagination
                page={page}
                pageLimit={pageSize}
                totalRecords={data.length}
                handleChange={pageChangeHandler}
                type="primary"
              />
            </div>
          )}
        </>
    </>
  );
};

NewTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string.isRequired,
      accessor: PropTypes.string.isRequired,
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default NewTable;
