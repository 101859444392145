import React from "react";
import {
    MDBRow, MDBCol, MDBInput, MDBBtn, MDBBox, MDBTable, MDBTableHead, MDBTableBody, MDBIcon, MDBSpinner, MDBModal,
    MDBModalHeader, MDBModalBody, MDBModalFooter
} from "mdbreact";
import PharmacyService from "../../services/pharmacyService";
import {toast} from "react-toastify";
import UserStore from "../../stores/userStore";
import Moments from "moment";
import SimpleBackdrop from "../common/overlay";
import InventoryService from "../../services/inventoryService";
import {withRouter} from "react-router-dom";

class AddGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            term: "",
            isLoading: false,
            userData: [],
            searchResult: [], searchResultSimProduct: [],
            addedItems: [], simItemList: [],
            masterItem: null,
            modalAddGroup: false,
            simProductTerm: "",
            groupName: ""
        }
    }

    async componentDidMount() {
        let userData = await UserStore.fetchSessionData();
        this.setState({userData})
    }

    handleEnter = (section) => async (e) => {
        if (section === "SimProd" && e.keyCode === 13) {
            await this.searchBySimTerm()
        }
        if (section === "Term" && e.keyCode === 13) {
            await this.searchByTerm()
        }
    }
    searchByTerm = async () => {
        if (this.state.term !== "") {
            this.setState({isLoading: true});
            let searchResult = await PharmacyService.fetchProductByName(this.state.userData.sessionToken, this.state.term);
            this.setState({isLoading: false});
            if (searchResult === false) {
                toast.error("Something went wrong while fetching the data by product name.");
            } else {
                this.setState({searchResult});
            }
        }
    }
    selectAsMaster = (item) => {
        let simItemList = []
        simItemList.push(item)
        this.setState({masterItem: item, simItemList})
        this.toggle("AddGroup")
    }
    checkAvailability = (item) => {
        for (let i of this.state.simItemList) {
            if (i.productDetails.productName === item.productDetails.productName) {
                return true
            }
        }
        return false
    }
    addGroup = async () => {
        if (this.state.simItemList.length > 1) {
            if (this.state.groupName === "") {
                toast.error("Please enter the group name.")
                return false
            }

            let productIds = []
            for (let item of this.state.simItemList) {
                productIds.push(item.productDetails._id)
            }
            let payload = {
                productIds: productIds,
                groupName: this.state.groupName.toUpperCase()
            }
            this.setState({isLoading: true});
            let res = await InventoryService.addProductGroup(this.state.userData.sessionToken, payload)
            this.setState({isLoading: false});
            if (res) {
                toast.success("The product group added successfully.")
                this.toggle("AddGroup")
                this.props.history.push("/product-group-list")
            } else {
                toast.error("Something went wrong while adding the product group.")
            }
        }
    }
    toggle = (section) => {
        if (section === "AddGroup") {
            this.setState({simProductTerm: "", searchResultSimProduct: []})
        }
        this.setState({["modal" + section] : !this.state["modal" + section]})
    }
    searchBySimTerm = async () => {
        if (this.state.simProductTerm !== "") {
            this.setState({isLoading: true});
            let result = await PharmacyService.fetchProductByName(this.state.userData.sessionToken, this.state.simProductTerm);
            this.setState({isLoading: false});
            if (result === false) {
                toast.error("Something went wrong while fetching the data by product name.");
            } else {
                this.setState({searchResultSimProduct: result});
            }
        }
    }
    addToList = (item) => {
        let simItemList = this.state.simItemList
        if (this.checkAvailability(item) === true) {
            toast.error("Added item is already available in the final product list.")
            return false
        }
        simItemList.push(item)
        this.setState({simItemList})
    }
    removeItem = (index) => {
        let simItemList = this.state.simItemList
        simItemList.splice(index, 1)
        this.setState({simItemList})
    }
    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.isLoading}/>
                <MDBRow className={"mx-0"}>
                    <MDBCol size={"3"}>
                        <MDBInput label={"Product Name"} value={this.state.term} getValue={(e) => {
                            this.setState({term: e})
                        }} onKeyDown={this.handleEnter("Term")}/>
                    </MDBCol>
                    <MDBCol className={'d-flex align-items-center'}>
                        <MDBBtn size={'md'} onClick={() => {
                            this.searchByTerm()
                        }} disabled={this.state.isLoading}>Submit</MDBBtn>
                    </MDBCol>
                </MDBRow>
                <MDBRow className={"mx-0 p-2"}>
                    <MDBCol>
                        <MDBBox>
                            {(this.state.searchResult.length > 0) &&
                            <MDBTable>
                                <MDBTableHead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Product</th>
                                        <th>Packaging</th>
                                        <th>Mrp (₹)</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody search="true">
                                    {this.state.searchResult.map((item, index) => (
                                        <tr key={index} className={""}>
                                            <td>{Moments(item.createdAt).format("MMM DD, YYYY")}</td>
                                            <td>{item.productDetails.productName}</td>
                                            <td> 1 {item.productDetails.retailPackage} of {item.productDetails.contentQuantity} {item.productDetails.quantityUnit}</td>
                                            <td>{item.mrp.toFixed(2)}</td>
                                            <td>
                                                <a className={'primary-text'}
                                                   data-toggle="tooltip" data-placement="top"
                                                   onClick={() => this.selectAsMaster(item)}>
                                                    Select As Master</a>
                                            </td>
                                            <td></td>
                                        </tr>
                                    ))}
                                </MDBTableBody>
                            </MDBTable>
                            }
                        </MDBBox>
                    </MDBCol>
                </MDBRow>
                {this.state.masterItem !== null &&
                <MDBModal isOpen={this.state.modalAddGroup} toggle={() => this.toggle("AddGroup")} size="fluid">
                    <MDBModalHeader toggle={() => this.toggle("AddGroup")}><h5>Add products similar
                        to <b>{this.state.masterItem.productDetails.productName}</b></h5></MDBModalHeader>
                    <MDBModalBody>
                        <>
                            <MDBRow className={"mx-0"}>
                                <MDBCol className={"col-md-4"}>
                                    <MDBInput label={"Search"} value={this.state.simProductTerm} getValue={(e) => {
                                        this.setState({simProductTerm: e})
                                    }} onKeyDown={this.handleEnter("SimProd")}/>
                                </MDBCol>
                                <MDBCol className={"col-md-2 d-flex align-items-center"}>
                                    <MDBBtn size={'md'} onClick={() => this.searchBySimTerm()}
                                            disabled={this.state.isLoading}>Submit</MDBBtn>
                                </MDBCol>
                                <MDBCol className={"col-md-6"}>
                                    <MDBInput label={"Group Name *"} size="sm" value={this.state.groupName} maxLength="200" getValue={(val)=>this.setState({groupName: val})}/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className={"p-2"}>
                                <MDBCol>
                                    <MDBBox>
                                        <h6 className={"text-center font-weight-bold"}>PRODUCT SEARCH LIST</h6>
                                        <MDBTable>
                                            <MDBTableHead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Product</th>
                                                    <th>Packaging</th>
                                                    <th>Mrp (₹)</th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </MDBTableHead>
                                            {(this.state.searchResultSimProduct.length > 0) &&
                                            <MDBTableBody search="true">
                                                {this.state.searchResultSimProduct.map((item, index) => (
                                                    <tr key={index} className={""}>
                                                        <td>{Moments(item.createdAt).format("MMM DD, YYYY")}</td>
                                                        <td>{item.productDetails.productName}</td>
                                                        <td> 1 {item.productDetails.retailPackage} of {item.productDetails.contentQuantity} {item.productDetails.quantityUnit}</td>
                                                        <td>{item.mrp.toFixed(2)}</td>
                                                        <td>
                                                            <a className={'primary-text'}
                                                               data-toggle="tooltip" data-placement="top"
                                                               onClick={() => this.addToList(item)}>
                                                                Select</a>
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                ))}
                                            </MDBTableBody>
                                            }
                                            {(this.state.searchResultSimProduct.length === 0) &&
                                            <p>No products to display</p>
                                            }
                                        </MDBTable>
                                    </MDBBox>
                                </MDBCol>
                                <MDBCol>
                                    <MDBBox>
                                        <h6 className={"text-center font-weight-bold"}>THE FINAL PRODUCT LIST</h6>
                                        <MDBTable>
                                            <MDBTableHead>
                                                <tr>
                                                    <th></th>
                                                    <th>Date</th>
                                                    <th>Product</th>
                                                    <th>Packaging</th>
                                                    <th>Mrp (₹)</th>
                                                    <th></th>
                                                </tr>
                                            </MDBTableHead>
                                            {(this.state.simItemList.length > 0) &&
                                            <MDBTableBody search="true">
                                                {this.state.simItemList.map((item, index) => (
                                                    <tr key={index} className={""}>
                                                        <td>{index + 1}</td>
                                                        <td>{Moments(item.createdAt).format("MMM DD, YYYY")}</td>
                                                        <td>{item.productDetails.productName}</td>
                                                        <td> 1 {item.productDetails.retailPackage} of {item.productDetails.contentQuantity} {item.productDetails.quantityUnit}</td>
                                                        <td>{item.mrp.toFixed(2)}</td>
                                                        <td>
                                                            {index !== 0 &&
                                                            <div style={{"cursor": "pointer"}}
                                                                 onClick={() => this.removeItem(index)}><MDBIcon
                                                                icon="trash"/>
                                                            </div>
                                                            }
                                                        </td>
                                                    </tr>
                                                ))}
                                            </MDBTableBody>
                                            }
                                        </MDBTable>
                                    </MDBBox>
                                </MDBCol>
                            </MDBRow>
                        </>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn size={'md'} outline onClick={() => this.toggle("AddGroup")}>Close</MDBBtn>
                        <MDBBtn size={'md'}  onClick={() => {
                            this.addGroup()
                        }} disabled={this.state.simItemList.length === 1}>Create</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
                }
            </React.Fragment>
        )
    }
}

export default withRouter(AddGroup);