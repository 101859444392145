import React from "react";
import { toast } from "react-toastify";
import { MDBCol, MDBInput, MDBRow } from "mdbreact";
class ProductSpecifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemList: [],
      item: "",
      enableEditItem: false,
      editIndexItem: "",
      editItemList:
        props.hasOwnProperty("sendListCallback") &&
        props.sendListCallback.length > 0
          ? props.sendListCallback
          : [],
    };
  }
  componentDidMount() {
    if (this.state.editItemList.length > 0) {
      let itemList = this.prepareArrayForEdit(this.state.editItemList);
      this.setState({ itemList: itemList });
    }
  }

  handleItemKeyDown = (e) => {
    if (e.keyCode === 13 && this.state.item.trim() !== "") {
      let checkDup = this.checkDuplicate(
        this.state.item.trim(),
        this.state.itemList
      );
      if (checkDup === true) {
        toast.info(
          "Specified " + this.props.section + " already available in the list."
        );
        this.setState({ item: "", enableEditItem: false, editIndexItem: "" });
        return false;
      }
      if (
        this.state.enableEditItem === true &&
        this.state.editIndexItem !== ""
      ) {
        let newItemList = this.editListItem(
          this.state.itemList,
          this.state.editIndexItem,
          this.state.item
        );
        this.setState({
          itemList: newItemList,
          item: "",
          enableEditItem: false,
          editIndexItem: "",
        });
        this.getListCallback(this.state.itemList);
        return false;
      }
      this.state.itemList.push({ value: this.state.item.trim() });
      this.setState({ item: "" });
      this.getListCallback(this.state.itemList);
    }
  };
  editItem = (index, row) => {
    this.setState({
      enableEditItem: true,
      editIndexItem: index,
      item: row.value,
    });
  };
  deleteItem = (index) => {
    if (this.state.enableEditItem === true) {
      return false;
    }
    let newList = this.state.itemList;
    newList.splice(index, 1);
    this.setState({ itemList: newList });
    this.getListCallback(this.state.itemList);
  };
  editListItem = (list, index, modifiedItem) => {
    let newList = list;
    newList[index].value = modifiedItem;
    return newList;
  };
  checkDuplicate = (item, itemList) => {
    for (let i = 0; i < itemList.length; i++) {
      if (itemList[i].value === item) {
        return true;
      }
    }
    return false;
  };
  getListCallback = () => {
    this.props.getListCallback(this.state.itemList);
  };
  prepareArrayForEdit = (list) => {
    let modifiedList = [];
    for (let i = 0; i < list.length; i++) {
      modifiedList.push({ value: list[i] });
    }
    return modifiedList;
  };
  render() {
    return (
      <React.Fragment>
        <div className={"col-12 p-0"}>
          <ul className="list-group product-lists-edit">
            {this.state.itemList.length > 0 &&
              this.state.itemList.map((row, index) => (
                <li
                  key={index}
                  className="list-group-item"
                  style={{ maxWidth: "43rem" }}
                >
                  <MDBRow>
                    <MDBCol size={"11"}>
                      <span
                        onClick={() => this.editItem(index, row)}
                        style={{ cursor: "pointer" }}
                        className={
                          this.state.editIndexItem === index
                            ? ""
                            : "text-primary"
                        }
                      >
                        {row.value}
                      </span>
                    </MDBCol>
                    <MDBCol>
                      <i
                        className="fas fa-times fa-sm"
                        style={{ cursor: "pointer" }}
                        onClick={() => this.deleteItem(index)}
                      ></i>{" "}
                    </MDBCol>
                  </MDBRow>
                </li>
              ))}
          </ul>
          <MDBInput
            type="text"
            value={this.state.item}
            onKeyDown={this.handleItemKeyDown}
            background
            size="sm"
            className={"mt-0"}
            getValue={(val) => {
              this.setState({ item: val });
            }}
          />
          <div>
            Please write {this.props.section}s one by one and press enter.
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default ProductSpecifications;
