import React from "react";
import {
    MDBBox, MDBBtn, MDBCol, MDBInput, MDBRow, MDBSpinner, MDBTable, MDBTableBody, MDBTableHead,
    MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBSelect
} from "mdbreact";
import utility from "../../utility/utility";
import Moments from "moment";
import UserStore from "../../stores/userStore";
import PharmacyService from "../../services/pharmacyService";
import {toast} from "react-toastify";
import InventoryService from "../../services/inventoryService";

const delay = ms => new Promise(res => setTimeout(res, ms));

class AddProductToCategory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchRadio: 1,
            barCode: "",
            invoiceId: "",
            productId: "",
            term: "",
            productData: [],
            isLoading: false,
            activeProduct: null,
            categoryList: [],
            selectedCategory: [], modalAddProduct: false,
        }
    }

    async componentDidMount() {
        let userData = await UserStore.fetchSessionData();
        this.setState({userData})
        let categoryList = await this.getCategories()
        this.prepareList(categoryList)
    }
    getCategories = async () => {
        let categoryList = []
        if (window.productCategoryList && window.productCategoryList !== null) {
            categoryList = window.productCategoryList
        } else {
            categoryList = await InventoryService.fetchGetData(this.state.userData.sessionToken, "FETCH_CATEGORY") //ignoring else case
            window.productCategoryList = categoryList
        }
        return categoryList
    }
    prepareList = (productCategoryList) => {
        let tempList = []
        for (let item of productCategoryList) {
            tempList.push({text: item.categoryName, value: item._id + "_" + item.categoryName})
        }
        this.setState({categoryList: tempList})
    }
    onClick = (radioVal) => {
        this.setState({searchRadio: radioVal});
    }
    searchByBarCode = async () => {
        await delay(500);
        if (this.state.barCode !== "" && this.state.isLoading === false) {
            this.setState({isLoading: true});
            let data = await PharmacyService.fetchProductByBarCodeProducts(this.state.userData.sessionToken, this.state.barCode);
            this.setState({isLoading: false});
            if (data === false) {
                toast.error("Something went wrong while fetching the data by barCode number.");
            } else {
                this.setState({productData: data});
            }
        }
    }
    searchByTerm = async () => {
        if (this.state.term !== "") {
            this.setState({isLoading: true});
            let data = await PharmacyService.fetchProductByName(this.state.userData.sessionToken, this.state.term);
            this.setState({isLoading: false});
            if (data === false) {
                toast.error("Something went wrong while fetching the data by product name.");
            } else {
                this.setState({productData: data});
            }
        }
    }
    handleEnter = async (e) => {
        if (e.keyCode === 13) {
            await this.refreshPage()
        }
    }
    refreshPage = async () => {
        if (this.state.searchRadio === 1 && this.state.barCode !== "") {
            await this.searchByBarCode()
        } else if (this.state.searchRadio === 4 && this.state.term !== "") {
            await this.searchByTerm()
        }
    }
    addProduct = async (item) => {
        this.setState({activeProduct: item, selectedCategory: []})
        let categoryList = await this.getCategories() //to refresh the category list
        this.prepareList(categoryList)
        this.toggle("AddProduct")
    }
    toggle = (section) => {
        this.setState({["modal" + section]: !this.state["modal" + section]})
    }
    save = async () => {
        if (this.state.selectedCategory.length === 0) {
            toast.error("Please select a category.")
            return false
        }
        let cat = this.state.selectedCategory[0].split("_")
        let payload = {
            _id: this.state.activeProduct.productDetails._id,
            category: {_id: cat[0], name: cat[1]}
        }
        let res = await InventoryService.addProductToCategory(this.state.userData.sessionToken, payload)
        if (res) {
            toast.success("Product added to the category successfully.")
            this.toggle("AddProduct")
        } else {
            toast.error("Something went wrong while adding product to a category.")
        }
    }

    render() {
        return (
            <React.Fragment>
                {(this.state.isLoading === true) &&
                <div className={"PanelFloat"}>
                    <MDBSpinner/>
                </div>
                }
                <MDBBox className={"min-vh-100 mx-5 mb-5"}>
                    <MDBRow>
                        <MDBInput onClick={() => {
                            this.onClick(1)
                        }} checked={this.state.searchRadio === 1 ? true : false} label="Search by barcode"
                                  type="radio" id="radio1"/>
                        <MDBInput onClick={() => {
                            this.onClick(4)
                        }} checked={this.state.searchRadio === 4 ? true : false} label="Search by product name"
                                  type="radio" id="radio4"/>
                    </MDBRow>
                    <MDBRow>
                        {this.state.searchRadio === 1 &&
                        <MDBCol className={"m-2"}>
                            <MDBRow>
                                <div className={'col-md-3'}>
                                    <MDBInput label={"Scan barcode"} value={this.state.barCode} getValue={(e) => {
                                        this.setState({barCode: e})
                                    }} onChange={() => {
                                        this.searchByBarCode()
                                    }} id={"barCode"}/>
                                </div>
                                <MDBCol className={'d-flex align-items-center'}>
                                    <MDBBtn size={'md'} onClick={() => {
                                        this.searchByBarCode()
                                    }} disabled={this.state.isLoading}>Submit</MDBBtn>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        }
                        {this.state.searchRadio === 4 &&
                        <MDBCol className={"m-2"}>
                            <MDBRow>
                                <MDBCol size={3}>
                                    <MDBInput label={"Product Name"} value={this.state.term} getValue={(e) => {
                                        this.setState({term: e})
                                    }} onKeyDown={this.handleEnter}/>
                                </MDBCol>
                                <MDBCol className={'d-flex align-items-center'}>
                                    <MDBBtn size={'md'} onClick={() => {
                                        this.searchByTerm()
                                    }} disabled={this.state.isLoading}>Submit</MDBBtn>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        }
                    </MDBRow>
                    <MDBBox>
                        {(this.state.productData.length > 0) &&
                        <MDBTable>
                            <MDBTableHead>
                                <tr>
                                    <th>Product Name</th>
                                    <th>Retail Package</th>
                                    <th>Package Quantity</th>
                                    <th>Packaging Unit</th>
                                    <th>Loose</th>
                                    <th>Loose Sale Qty</th>
                                    <th>Medicine Type</th>
                                    <th>Created On</th>
                                    <th></th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody search={true}>
                                {this.state.productData.map((item, index) => (
                                    <tr key={index}>
                                        <td className={"table-no-gap"}>{item.productDetails.productName}</td>
                                        <td className={"table-no-gap"}>{item.productDetails.retailPackage}</td>
                                        <td className={"table-no-gap"}>{item.productDetails.contentQuantity}</td>
                                        <td className={"table-no-gap"}>{item.productDetails.quantityUnit}</td>
                                        <td className={"table-no-gap"}>{item.productDetails.looseSaleAllowed}</td>
                                        <td className={"table-no-gap"}>{item.productDetails.minimumLooseSaleQuantity}</td>
                                        <td className={"table-no-gap"}>{utility.getMedicineType(item)}</td>
                                        <td className={"table-no-gap"}>{Moments(item.createdAt).format("MMM D, YYYY hh:mm A")}</td>
                                        <td><span className={"text-primary cursor-pointer"} onClick={() => {
                                            this.addProduct(item)
                                        }}>Add</span></td>
                                    </tr>
                                ))}
                            </MDBTableBody>
                        </MDBTable>
                        }
                        {(this.state.productData.length === 0 && this.state.isLoading === false && (this.state.barCode !== '' ||
                            this.state.batch !== '' || this.state.invoiceId !== '' || this.state.term !== '')) &&
                        <div className={"no-data-container"}>No product found.</div>
                        }
                    </MDBBox>
                </MDBBox>
                {this.state.activeProduct !== null &&
                <MDBModal isOpen={this.state.modalAddProduct} toggle={() => this.toggle("AddProduct")} size="lg">
                    <MDBModalHeader toggle={() => this.toggle("AddProduct")}>Add product</MDBModalHeader>
                    <MDBModalBody>
                        <div className={"row"}>
                            <div className={"col"}><b>Product name</b><br/>
                            {this.state.activeProduct.productDetails.productName}
                            </div>
                            <div className={"col"}>
                                <b>Category</b><br/>
                                <MDBSelect search selected="Select Category"
                                           options={this.state.categoryList}
                                           getValue={(val)=>{this.setState({selectedCategory: val})}}
                                           className={' col grey-text p-0 font-weight-bold'}
                                />
                            </div>
                        </div>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn size={'md'} outline onClick={() => this.toggle("AddProduct")}>Close</MDBBtn>
                        <MDBBtn size={'md'}  disabled={this.state.selectedCategory.length === 0} onClick={this.save}>Save</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
                }
            </React.Fragment>
        );
    }
}

export default AddProductToCategory;