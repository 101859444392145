import React from "react";
import {withRouter} from "react-router-dom";
import SimpleBackdrop from "../common/overlay";
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCol,
    MDBContainer,
    MDBDataTable,
    MDBInput, MDBModal, MDBModalBody, MDBModalHeader,
    MDBRow, MDBBadge, MDBSelect, MDBTable, MDBTableHead, MDBTableBody, MDBModalFooter
} from "mdbreact";
import UserStore from "../../stores/userStore";
import {toast} from "react-toastify";
import ReportService from "../../services/reports";
import utility from "../../utility/utility";
import SimilarProducts from "./similarProducts";

class VerifyProduct extends React.Component {
    constructor() {
        super();
        this.state = {
            _id:"",
            progressStatus: false,
            type: "all",
            comments:"",
            product: {
                verificationLog:[]
            },
            suggestedData:[],
            tableData: {columns: [], rows: []},

            modalStatus:false,
            activeProductId:0,
            modalEdit:false,
            modalView:false,
            activeProduct:{

            },
            modalAction:"",
            disableRetreatAfterSorting:false,
            verificationStatus:"MANUALLY_VERIFIED",
            verificationStatuses: [{ text:"Manually Verified", value:"MANUALLY_VERIFIED", checked:true },
                { text:"Duplicate", value:"DUPLICATE" },
                { text:"Junk", value:"JUNK" },
                { text:"Incomplete", value:"INCOMPLETE" },
                { text:"Incorrect", value:"INCORRECT" },
                { text:"Ready to publish", value:"READY_TO_PUBLISH"}
            ],
            modalDelete:false,
            hideSimilarProduct:false,
        }
    }

    async componentDidMount () {
        let product = this.props.product;
        let hideSimilarProduct = this.props.hasOwnProperty("hideSimilarProduct") ? this.props.hideSimilarProduct : false;
        await this.setState({_id:product._id, product:product, loadedOnPopup:true, hideSimilarProduct:hideSimilarProduct})

    }

    handleVerificationStatus = (e) => {
        this.setState({verificationStatus:e[0]})
    }

    handleComments = (val) => {
        this.setState({comments:val})
    }

    confirmVerify = async ()=>{
        let userData = await UserStore.fetchSessionData();
        this.setState({progressStatus: true});
        let data = await ReportService.markProduct(userData, this.state);
        this.setState({progressStatus: false});
        this.props.onEditComplete()

    }

    // This triggers from MDBDataTable, aim is to redirect to edit page
    onEditDesc =  (row) => {
        this.setState({activeProduct: row,modalAction:"edit"})
        this.toggleEdit()
    }

    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.progressStatus}/>
                <MDBContainer className={'w-100 mw-100'}>
                    <MDBCard className={"mt-2"}>
                        <MDBCardHeader>
                            <MDBRow >
                                <MDBCol className={'title'}>
                                    Verify Product
                                </MDBCol>
                            </MDBRow>
                        </MDBCardHeader>
                        <MDBCardBody>

                            {this.state._id &&
                            <>
                                <MDBRow>
                                    <MDBCol>
                                        {(this.state.product.hasOwnProperty("verificationLog") && this.state.product.verificationLog.length > 1) &&
                                        <MDBTable className={"col-12"}>
                                            <MDBTableHead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Status</th>
                                                    <th>Marked On</th>
                                                    <th>Marked By</th>
                                                    <th>Comments</th>
                                                </tr>
                                            </MDBTableHead>
                                            <MDBTableBody>
                                                {this.state.product.verificationLog.map((row, index) => (
                                                    <tr key={"verificationLog" + index}>
                                                        <td>{index + 1}</td>
                                                        <td>{row.verificationStatus}</td>
                                                        <td>{utility.convertToReadbleDate(row.verifiedOn)}</td>
                                                        <td>{row.verifiedByUserName}</td>
                                                        <td>{row.comments}</td>
                                                    </tr>
                                                ))}
                                            </MDBTableBody>
                                        </MDBTable>
                                        }
                                    </MDBCol>
                                </MDBRow>

                                <MDBSelect outline={true} label={"Verification Status"}
                                           getValue={this.handleVerificationStatus}
                                           options={this.state.verificationStatuses}></MDBSelect>
                                <MDBInput type={"textarea"} value={this.state.comments} getValue={this.handleComments}/>
                                <MDBBtn size={"sm"} style={{"float": "right"}}
                                        onClick={this.confirmVerify}>Mark</MDBBtn>
                            </>
                            }

                            {(this.state.hideSimilarProduct === false && this.state.product.hasOwnProperty("productName")) &&
                            <SimilarProducts loadedOnPopup={true} onEditComplete={this.onEditComplete}
                                             product={this.state.product}/>
                            }
                        </MDBCardBody>
                    </MDBCard>
                </MDBContainer>
            </React.Fragment>
        )
    }
}

export default withRouter(VerifyProduct)