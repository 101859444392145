import React from "react";
import {
    MDBSelect
} from "mdbreact";
import PharmacyService from "../../services/pharmacyService";
import config from "../../config";

class Selectlocation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            locations: [],
            selectedLocation: this.props.selectedLocation,
            key: 1,
        }
    }
    async componentDidMount() {
        await this.fetchStorageLocations();
        if (!localStorage.getItem("pharmacyLocation")) {
            localStorage.setItem("pharmacyLocation", config.DEFAULT_PHARMACY_LOCATION);
        }
    }

    fetchStorageLocations = async () => {
        try {
            let res = await PharmacyService.fetchStorageLocationV2();
            if (res) {
                let tempArray = [];
                for (let d of res) {
                    let temp = {
                        text: d.name,
                        value: d._id,
                        checked: false
                    }
                    if (d._id === this.state.selectedLocation) {
                        temp.checked = true;
                    }
                    tempArray.push(temp);
                }
                this.setState({ locations: tempArray, key: this.state.key + 1 });
            }
        } catch (e) {
            console.log("Could not fetch the locations.");
        }
    }

    selectLocationCallback = (locId) => {
        if (!locId) {
            //Fixing mdbReact issue
            let locations = this.state.locations;
            for (let d of locations) {
                if (d.value === this.state.selectedLocation) {
                    d.checked = true;
                }
            }
            this.setState({ locations, key: this.state.key + 1 });
        } else {
            localStorage.setItem("pharmacyLocation", locId);
            this.setState({ selectedLocation: locId })
            this.props.selectLocationCallback(locId);
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className={"col-12 p-2 my-1"}>
                    <MDBSelect outline={true} key={this.state.key}
                        label={this.props.label && this.props.label !== "" ? this.props.label : "Select Location"}
                        getValue={(val) => this.selectLocationCallback(val[0])}
                        options={this.state.locations}
                        disabled={this.props.readOnly ? this.props.readOnly : false}>
                    </MDBSelect>
                </div>
            </React.Fragment>
        )
    }

}
export default Selectlocation;

