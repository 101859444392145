import React from "react";
import UserStore from "../../stores/userStore";
import { Pie } from "react-chartjs-2";
import ReportService from "../../services/reports";

export default class CashCredit extends React.Component {
    componentDidMount() {
        this.fetchData();
    }
    fetchData=async ()=>{
        let userData=await UserStore.fetchSessionData();
        //widgets data
        let cashCredit=await ReportService.fetchMonthlyCashCreditWidget(userData);

        let dataPie= {
            labels: ["Cash/Card", "Credit"],
                datasets: [
                {
                    data: cashCredit,
                    backgroundColor: [
                        "#46BFBD",
                        "#F7464A"
                    ],
                    hoverBackgroundColor: [
                        "#5AD3D1",
                        "#FF5A5E"
                    ]
                }
            ]
        }
        this.setState({dataPie:dataPie});
    }
    constructor(props) {
        super(props);
        this.state = {
            dataPie:{}
        }
    }
    render() {
        return (
            <React.Fragment>
                {/*<Bar data={this.state.dataBar} options={this.state.barChartOptions} />*/}
                <div className={'white rounded-sm p-2'}>
                    <Pie data={this.state.dataPie} options={{ responsive: true }} />
                </div>

            </React.Fragment>
        );
    }
}


