import React, { useState } from 'react';
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBBtn, MDBBox } from 'mdbreact';
import { toast } from "react-toastify";
import TransferStockService from "../../../services/transferStockService";

const EditPackage = ({ payload, handleClose, sessionToken, deliveryPersonal }) => {
    const [selectedStatus, setSelectedStatus] = useState(payload.status);
    const [selectedDelivery, setSelectedDelivery] = useState(deliveryPersonal[0]);

    const handleSelectChange = (value) => {
        setSelectedStatus(value);
    };
    const handleSelectDelivery = (selectedObj) => {
        setSelectedDelivery(selectedObj)
    }
    const handleSubmit = async () => {
        let payloadData = {
            barCode: payload.barCode,
            status: selectedStatus,
            userId: selectedDelivery._id
        }
        let updateStatus = await TransferStockService.updateProduct(sessionToken, payloadData)
        if (updateStatus) {
            handleClose()
            toast.success("Status Sucessfully changed");
        }
    };

    return (
        <div className="d-flex  justify-content-space-around align-items-center">
            <MDBBox className="d-flex justify-content-space-around align-items-center">
                <p className="fs-2 font-weight-bold" style={{ marginTop: "1.2em" }}>PACKAGE STATUS :</p>
                <MDBDropdown>
                    <MDBDropdownToggle caret color="primary" style={{ background: '#5374B3 !important', color: 'white' }}>
                        {selectedStatus == 'IN_TRANSIT' ? 'IN TRANSIT' : selectedStatus}
                    </MDBDropdownToggle>
                    <MDBDropdownMenu>
                        <MDBDropdownItem onClick={() => handleSelectChange('IN_TRANSIT')}>IN TRANSIT</MDBDropdownItem>
                        <MDBDropdownItem onClick={() => handleSelectChange('PACKED')}>PACKED</MDBDropdownItem>
                        <MDBDropdownItem onClick={() => handleSelectChange('RECEIVED')}>RECEIVED</MDBDropdownItem>
                    </MDBDropdownMenu>
                </MDBDropdown>
            </MDBBox>

            <MDBBox className="d-flex justify-content-space-around align-items-center">
                <p className="fs-2 font-weight-bold" style={{ marginTop: "1.2em" }}>PICKED UP BY:</p>
                <MDBDropdown>
                    <MDBDropdownToggle caret color="primary" style={{ background: '#5374B3 !important', color: 'white' }}>
                        {selectedDelivery.name}
                    </MDBDropdownToggle>
                    <MDBDropdownMenu style={{ height: "19em", overflow: "scroll" }}>
                        {deliveryPersonal?.map((item, key) =>
                            <MDBDropdownItem onClick={() => handleSelectDelivery(item)}>{item?.name}</MDBDropdownItem>
                        )}
                    </MDBDropdownMenu>
                </MDBDropdown>
            </MDBBox>

            <MDBBtn size='lg' style={{ marginLeft: "3em" }} onClick={handleSubmit}>SUBMIT</MDBBtn>
        </div>
    );
};

export default EditPackage;
