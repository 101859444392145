import React from "react";
import {withRouter} from "react-router-dom";
import {
    MDBBtn,
    MDBModalHeader,
    MDBModalBody,
    MDBModalFooter,
    MDBModal,
    MDBDataTableV5,
    MDBBox,
    MDBRow,
    MDBCol,
    MDBIcon,
    MDBInput,
    MDBSelect, MDBTable, MDBTableHead, MDBTableBody
} from 'mdbreact';
import UserStore from "../../stores/userStore";
import {toast} from "react-toastify";
import PharmacyService from "../../services/pharmacyService";
import dataTableShipment from "./dataTableShipments";
import config from "../../config";
import Moments from "moment";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import UpdateShipment from "./updateShipments";
import SimpleBackdrop from "../common/overlay";
import util from "../../utility/utility";
import GetDateRange from "../common/getDateRange";
import orgConfig from "../../orgConfig";
import MaterialTable from "material-table";
import {CSVLink} from "react-csv";
import ReportService from "../../services/reports";
import PaymentDocsUpload from "../common/fileUpload/PaymentDocsUpload"; 


const headers = [
    {label: 'Invoice Date', key: 'invoiceDate'},
    {label: 'Received On', key: 'receivingDate'},
    {label: 'Invoice Number', key: 'invoiceNumber'},
    {label: 'Added On', key: 'createdAt'},
    {label: 'Shipment#', key: 'shipmentCode'},
    {label: 'Supplier Name', key: 'supplierDetails.supplierName'},
    {label: 'Received By', key: 'receivedByDetails.name'},
    {label: 'Invoiced Amount(' + orgConfig.CURRENCY_SYMBOL + ')', key: 'amount'},
    
]

class ListShipment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orgId: "",
            shipmentList: [],
            progressStatus: false,
            activeShipment: {
                documentUrl: {
                    filePathoriginal: ""
                }
            },
            modalShipmentDetails: false,
            modalLB: false,
            activeShipmentPaymentData: {},
            activeShipmentHasPaymentLog: false,
            startDate: new Date(),
            endDate: new Date(),
            activeImage: "",
            modalEdit: false,
            totalFilteredTransactions: 0,
            totalAmount: 0,
            totalFilteredTransactionsAmount: 0,
            shipmentReviewComment: "",
            modalReview: false,
            modalAfterReview: false,
            modalAddPayment: false,
            paymentReference: "",
            paymentAmount: "",
            paymentDate: null,
            paymentMode: "Cash",
            modalViewPaymentLog: false,
            progressStatusFileUpload:false,
            documentUrl:{},
            resetForm:false,
            randomKey:"",
            paymentTypes: [
                {
                    checked: true,
                    text: "Cash",
                    value: "Cash"
                },
                {
                    text: "Card",
                    value: "Card"
                },
                {
                    text: "Cheque",
                    value: "Cheque"
                },
                {
                    text: "Bank Transfer",
                    value: "Bank Transfer"
                }
            ],
        }
    }

    // This methods calls first
    async componentDidMount() {
        let userData = await UserStore.fetchSessionData();
        this.setState({userData})
        if (userData.type === "platformAdmin") {
            this.setState({orgId: userData._id})
        } else if (userData.type === "ORG_ADMIN") {
            this.setState({orgId: userData.orgDetails[0]._id})
        }
        // We want to make sure that we call the shipment when this component loads.
        let startDate = Moments().format("YYYY-MM-DD");
        let endDate = Moments().format("YYYY-MM-DD");
        await this.setState({startDate, endDate})
        await this.fetchShipments()
    }

    convertToReadbleDate = date => {
        return Moments(date).format("MMM D, YYYY");
    }
    convertToReadbleDateTime = date => {
        return Moments(date).format("MMM D, YYYY");
    }
    // This will fetch the shipment and store the data in state and initiate the MDBDataTable
    fetchShipments = async () => {
        let data = [];
        this.setState({progressStatus: true});
        data = await PharmacyService.fetchShipments(this.state, Moments(this.state.startDate).format("YYYY-MM-DD"), Moments(this.state.endDate).format("YYYY-MM-DD"));
        this.setState({progressStatus: false});
        if (data) {
            try {
                this.setState({shipmentList: data});
                let subtotal = 0, totalShipment = 0;
                for (let shipment of this.state.shipmentList) {
                    subtotal = subtotal + shipment.amount;
                    totalShipment++;
                }
                this.setState({totalFilteredTransactions: totalShipment, totalFilteredTransactionsAmount: subtotal})
            } catch (e) {
                toast.error("Something went wrong while fetching shipment.")
            }
        }
    }

    onEditComplete = async () => {
        this.toggle("Edit")
        await this.fetchShipments()
    }
    onEdit = (row) => {
        this.setState({activeShipment: row})
        this.toggle("Edit")
        //this.props.history.push('/edit-incoming-shipment/' + row._id);
    }
    onDownloadImage = (row) => {
        this.setState({activeImage: row})
        this.toggle("LB")
    }
    toggle = (section) => {
        if(section=="ReviewReconsider"){
            this.setState({
                shipmentReviewComment: "",
                modalReview: true,
                modalAfterReview:false
            })
        }
        else{
        this.setState({shipmentReviewComment: ""})
        this.setState({["modal" + section]: !this.state["modal" + section]})
        }
    }
    review = async (action) => {
        let payload = {
            shipmentId: this.state.activeShipment._id,
            comment: this.state.shipmentReviewComment
        }
        this.setState({progressStatus: true})
        if (action === "Approve") {
            let res = await PharmacyService.approveShipment(payload, this.state.userData.sessionToken)
            if (res) {
                toast.success("Approved shipment successfully.")
                this.toggle("Review")
                await this.fetchShipments()
            } else {
                toast.error("Something went wrong while approving the shipment.")
            }
        } else {
            let res = await PharmacyService.rejectShipment(payload, this.state.userData.sessionToken)
            if (res) {
                toast.success("Rejected shipment successfully.")
                this.toggle("Review")
                await this.fetchShipments()
            } else {
                toast.error("Something went wrong while rejecting the shipment.")
            }
        }
        this.setState({progressStatus: false})
    }
    reviewStatus = (item) => {
        if (item.hasOwnProperty("revieweDetails")) {
            return <span onClick={() => this.openModal("AfterReview", item)}>{item.revieweDetails.isApproved === "Y" ?
                <span className={"text-success font-weight-bold"}>Approved</span> :
                <span className={"text-danger font-weight-bold"}>Rejected</span>}</span>
        } else {
            return <span onClick={() => this.openModal("Review", item)}>Review</span>
        }
    }
    openModal = (action, item) => {
        this.setState({["modal" + action]: true, activeShipment: item})
    }
    handlePaymentAmount = (e) => {
        if (!util.isNumericTwoDecimalPartial(e.target.value) && e.target.value !== "") {
            return false;
        }
        this.setState({paymentAmount: e.target.value})
    };
    handlePaymentMode = (e) => {
        if (e.length > 0) {
            this.setState({paymentMode: e[0]});
        }
    };
    savePayment = async () => {
        if (!this.state.paymentDate || this.state.paymentDate === null) {
            toast.error("Please add the payment date.")
            return false
        }
        if (this.state.paymentAmount === "" || (this.state.paymentAmount !== "" && !util.isNumericTwoDecimalFull(this.state.paymentAmount))) {
            toast.error("Please add the payment amount.")
            return false
        }

        let payload = {
            "_id": this.state.activeShipment._id,
            "paymentAmount": this.state.paymentAmount,
            "paymentMode": this.state.paymentMode,
            "paymentDate": this.state.paymentDate,
            "paymentReference": this.state.paymentReference,
            "documentUrl": this.state.documentUrl
            
        }
        this.setState({progressStatus: true})
        let res = await PharmacyService.addShipmentPayment(payload, this.state.userData.sessionToken)
        this.setState({progressStatus: false})
        if (res) {
            toast.success("Payment added successfully.")
            this.toggle("AddPayment")
            await this.fetchShipments()
            this.setState({paymentAmount: "", paymentDate: null, paymentReference: ""})
        } else {
            toast.error("Something went wrong while adding payment details.")
        }
    }
    getDateRangeCallback = async (startDate, endDate) => {
        if (startDate > endDate) {
            toast.info("Start date is greater than the end date. Please adjust the date range again.")
            return
        }
        await this.setState({startDate, endDate})
        let endDateThreeMonthGap = Moments(startDate).add(2, "months");
        if (endDate > endDateThreeMonthGap) {
            let payload = {
                startDate: Moments(startDate).format("YYYY-MM-DD"),
                endDate: Moments(endDate).format("YYYY-MM-DD"),
                section: "SHIPMENT_LIST"
            }
            //Create a record request
            this.setState({progressStatus: true})
            let res = await ReportService.requestCreateReport(this.state.userData.sessionToken, payload)
            this.setState({progressStatus: false})
            if (res) {
                toast.success("A request has been raised to create a report for the specified time duration with the name, "
                    + "SHIPMENT_LIST_" + Moments(startDate).format("YYYY-MM-DD") + "_" + Moments(endDate).format("YYYY-MM-DD") + "_" + res._id + ".csv")
            } else {
                toast.error("Something went wrong while requesting the report for the duration " + Moments(startDate).format("YYYY-MM-DD") + " and "
                    + Moments(endDate).format("YYYY-MM-DD"))
            }
            return false
        }
        await this.fetchShipments()
    }
    onView = (row) => {
        this.setState({activeShipment: row})
        let data = [];
        data.push(row);
        if (data) {
            try {
                // this.setState({shipmentList: data});
                let dataTablePayload = {
                    columns: dataTableShipment.shipmentView,
                    rows: data.map((item, index) => {
                        item["receivingDate"] = <span>{this.convertToReadbleDate(item.receivingDate)}</span>;
                        item["createdAt"] = <span>{this.convertToReadbleDateTime(item.createdAt)}</span>;
                        item["handleAmount"] = parseFloat(item.amount.toFixed(2));
                        item["receivedByName"] = <span>{item.receivedByDetails.name}</span>;
                        item["supplierName"] = <span>{item.supplierDetails.supplierName}</span>;
                        item["documentUrlTobeDisplayed"] = item?.documentUrl.length ?
                        item?.documentUrl?.map((item2,key2)=>{
                            return <span>
                                 <a href={config.IMAGE_HOST+item2} target="_blank" style={{color:"blue"}}> View Document {key2+1} </a>
                            </span>
                        }) :  <span> 0 Document</span>;
                        return item;
                    })
                };
                this.setState({activeShipmentData: dataTablePayload});
            } catch (e) {
                toast.error("Something went wrong.")
            }
        }
        let paymentData = row.paymentLog
        this.setState({activeShipmentHasPaymentLog: false})
        if (paymentData) {
            try {
                if (paymentData.length > 0) {
                    let amt = 0
                    for (let i = 0; i < paymentData.length; i++) {
                        amt = amt + parseFloat(paymentData[i].paymentAmount);
                    }
                    if (amt > 0) {
                        this.setState({activeShipmentHasPaymentLog: true})
                    }
                }
                //this.setState({shipmentList: data});
                let dataTablePayloadPayment = {
                    columns: dataTableShipment.paymentLog,
                    rows: paymentData.map((item, index) => {
                        item["paymentDate"] = <span>{this.convertToReadbleDate(item.paymentDate)}</span>;
                        item["paidAmount"] = <span>{item.paymentAmount.toFixed(2)}</span>;
                        item["enteredOn"] = <span>{this.convertToReadbleDateTime(item.enteredOn)}</span>;
                        item["enteredBy"] = <span>{item.enteredBy.name}</span>;
                        return item;
                    })
                };
                this.setState({activeShipmentPaymentData: dataTablePayloadPayment});
            } catch (e) {
                toast.error("Something went wrong.")
            }
        }
        this.toggle("ShipmentDetails");
    }

    initUploadCallback = () => {
        this.setState({"progressStatusFileUpload":true})
    }

    fileUploadCallback = (files) => {
        this.setState({ "documentUrl": files, "progressStatusFileUpload":false})
    }
    resetKey() {
        let randomString = Math.random().toString(36);
        this.setState({
            randomKey: randomString
        });
    }
    
    render() {
        return (
            <React.Fragment>
                <MDBBox>
                    <SimpleBackdrop status={this.state.progressStatus}/>
                    <div className={"col-12 row mx-0 my-4"}>
                        <div className={'col-md-6 p-0'}>
                            <GetDateRange getDateRangeCallback={this.getDateRangeCallback}/>
                        </div>
                        <div className={'col-md-2 p-0 title'}>Total Shipments: {this.state.totalFilteredTransactions}</div>
                        <div className={'col-md-2 p-0 title'}>Total Amount: <span dangerouslySetInnerHTML={{__html: config.CURRENCY_HTML}}></span> {this.state.totalFilteredTransactionsAmount.toFixed("2")}</div>
                        <div className={'col-md-2 text-right p-0'}>
                            <CSVLink data={this.state.shipmentList} headers={headers} target="_blank"
                                     filename={"shipment_list_" + Moments(this.state.startDate).format("DD-MM-YYYY") + "_" + Moments(this.state.endDate).format("DD-MM-YYYY") + ".csv"}>
                                <MDBBtn size={'md'} disabled={this.state.progressStatus}><i
                                    className="fas fa-download"></i>&nbsp;Export</MDBBtn>
                            </CSVLink>
                            <MDBBtn size={'md'} disabled={this.state.progressStatus} onClick={() => {
                                this.props.history.push("/add-incoming-shipment")
                            }}>Add</MDBBtn>
                        </div>
                    </div>
                    <div className={'col-12'}>
                        <MaterialTable
                            columns={[
                                {
                                    title: 'Invoice Date',
                                    field: 'invoiceDate',
                                    render: rowData => Moments(rowData.invoiceDate).format("lll"),
                                    width: '5%'
                                },
                                {
                                    title: 'Received On',
                                    field: 'receivingDate',
                                    render: rowData => Moments(rowData.receivingDate).format("lll"),
                                    width: '5%'
                                },
                                {
                                    title: 'Invoice Number',
                                    field: 'invoiceNumber',
                                    width: '5%'
                                },
                                {
                                    title: 'Added On',
                                    field: 'createdAt',
                                    render: rowData => Moments(rowData).format("MMM D, YYYY"),
                                    width: '5%'
                                },
                                {
                                    title: 'Shipment#',
                                    field: 'shipmentCode',
                                    width: '5%'
                                },
                                {
                                    title: 'Supplier Name',
                                    field: 'supplierDetails.supplierName',
                                    width: '5%'
                                },
                                {
                                    title: 'Received By',
                                    field: 'receivedByDetails.name',
                                    width: '5%'
                                },
                                {
                                    title: 'Invoiced Amount(' + orgConfig.CURRENCY_SYMBOL + ')',
                                    field: 'amount',
                                    render: rowData => parseFloat(rowData.amount.toFixed(2)),
                                    width: '5%'
                                },
                                {
                                    title: 'Payment',
                                    field: '',
                                    render: rowData => <>
                                        {rowData.hasOwnProperty("paymentLog") && rowData.paymentLog.length > 0 &&
                                        <span color="primary"
                                              className={"p-1 blue-text btn-link cursor-pointer"} size="sm"
                                              onClick={() => {
                                                  this.openModal("ViewPaymentLog", rowData)
                                              }}>View</span>
                                        } <span color="primary"
                                                className={"p-1 blue-text btn-link cursor-pointer"} size="sm"
                                                onClick={() => {
                                                    this.openModal("AddPayment", rowData)
                                                }}>Add</span>
                                    </>,
                                    width: '5%'
                                },
                                {
                                    title: 'Document(s)',
                                    field: 'documentUrl',
                                    render: rowData => rowData?.documentUrl?.length ?<span color="primary"
                                    className={"font-weight-bold"}><span style={{fontSize:"1.2em"}}>
                                        {rowData?.documentUrl?.length}</span> <MDBIcon fas icon="book" /></span>:
                                    <span>No documents</span>,
                                    width: '5%'
                                },
                                {
                                    title: 'Review',
                                    field: '',
                                    render: rowData => <span color="primary"
                                                             className={"blue-text btn-link cursor-pointer font-weight-normal"}>{this.reviewStatus(rowData)}</span>,
                                    width: '5%'
                                },
                                {
                                    title: 'Actions',
                                    field: 'handleActions',
                                    render: rowData =>
                                        <div>
                                            <span color="primary"
                                                  className={"p-1 blue-text btn-link cursor-pointer"} size="sm"
                                                  onClick={() => {
                                                      this.onView(rowData)
                                                  }}>
                                                View
                                            </span>
                                            <span color="primary"
                                                  className={"p-1 blue-text btn-link cursor-pointer"} size="sm"
                                                  onClick={() => {
                                                      this.onEdit(rowData)
                                                  }}>
                                                        Edit
                                                    </span>
                                        </div>,
                                    width: '5%'
                                },
                            ]}
                            data={this.state.shipmentList}
                            options={{
                                showTitle: false,
                                pageSize: 10,
                                pageSizeOptions: [10, 50, 100],
                                emptyRowsWhenPaging: false,
                            }}
                        />
                    </div>
                    <MDBModal isOpen={this.state.modalLB} toggle={()=>this.toggle("LB")} size={"fluid"}>
                        <MDBModalHeader toggle={()=>this.toggle("LB")}></MDBModalHeader>
                        <MDBModalBody className={"float-center"}>
                            <div style={{"textAlign": "center"}}>
                                <img src={config.IMAGE_HOST + this.state.activeShipment.documentUrl.filePathOriginal}
                                     className={"float-center"}
                                     style={{maxWidth: "940px", maxHeight: "500px"}} alt={""}/>
                            </div>
                        </MDBModalBody>
                    </MDBModal>
                    <MDBModal isOpen={this.state.modalShipmentDetails} toggle={()=>this.toggle("ShipmentDetails")}
                              size={"fluid"}>
                        <MDBModalHeader toggle={()=>this.toggle("ShipmentDetails")}></MDBModalHeader>
                        <MDBModalBody className={"float-center"}>
                            {this.state.shipmentList.length > 0 &&
                            <MDBDataTableV5
                                hover
                                displayEntries={false}
                                data={this.state.activeShipmentData}
                                striped
                                info={false}
                                searching={false}
                                paging={false}
                                fullPagination={false}
                                materialSearch={false}
                            />
                            }
                            {this.state.activeShipmentHasPaymentLog &&
                            <MDBDataTableV5
                                hover
                                entriesOptions={[10, 15, 20, 25]}
                                entries={10}
                                pagesAmount={4}
                                data={this.state.activeShipmentPaymentData}
                                striped
                                searchLabel={"Search Payments"}
                                barReverse
                                materialSearch
                                searchTop
                                searchBottom={false}
                            />
                            }
                            {/* {this.state.activeShipment.hasOwnProperty("documentUrl") &&
                            <div style={{"textAlign": "center"}}>
                                {this.state.activeShipment.documentUrl.hasOwnProperty("filePathOriginal") &&
                                <img src={config.IMAGE_HOST + this.state.activeShipment.documentUrl.filePathOriginal}
                                     className={"float-center"}
                                     style={{maxWidth: "940px", maxHeight: "500px"}} alt={""}/>
                                }
                            </div>
                            }

                            {(this.state.activeShipment.hasOwnProperty("documentUrl") && this.state.activeShipment.documentUrl.length > 0) &&
                            <>
                                {this.state.activeShipment.documentUrl.map((row, index) => (
                                    <span style={{"textAlign": "left"}} key={index + "_img"}>
                                    {((this.state.activeShipment.documentUrl[index].indexOf(".pdf") > 0) || (this.state.activeShipment.documentUrl[index].indexOf(".PDF") > 0)) &&
                                    <a className={'file-size mr-3'} title={"Click to download"}
                                       href={config.UPLOADED_DOC_HOST + this.state.userData.sessionToken + "/" + this.state.userData._id + "/" + this.state.activeShipment.documentUrl[index]}><MDBIcon
                                        style={{fontSize: "3rem"}} icon="file"/></a>
                                    }
                                        {((this.state.activeShipment.documentUrl[index].indexOf(".png") > 0) || (this.state.activeShipment.documentUrl[index].indexOf(".PNG") > 0) || (this.state.activeShipment.documentUrl[index].indexOf(".jpeg") > 0) || (this.state.activeShipment.documentUrl[index].indexOf(".JPEG") > 0) || (this.state.activeShipment.documentUrl[index].indexOf(".jpg") > 0) || (this.state.activeShipment.documentUrl[index].indexOf(".JPG") > 0)) &&
                                        <img onClick={() => this.onDownloadImage(row)} style={{cursor: "pointer"}}
                                             className={'file-size mr-3'} width={"100"}
                                             src={config.UPLOADED_DOC_HOST + this.state.userData.sessionToken + "/" + this.state.userData._id + "/" + this.state.activeShipment.documentUrl[index]}/>
                                        }
                                </span>
                                ))}
                            </>
                            } */}
                        </MDBModalBody>
                        <MDBModal isOpen={this.state.modalLB} toggle={()=>this.toggle("LB")} size={"lg"}>
                            <MDBModalHeader toggle={()=>this.toggle("LB")}></MDBModalHeader>
                            <MDBModalBody className={"float-center"}>
                                <div style={{"textAlign": "center"}}>
                                    <img src={config.IMAGE_HOST + this.state.activeImage} className={"float-center"}
                                         style={{maxWidth: "440px", maxHeight: "440px"}} alt={""}/>
                                </div>
                            </MDBModalBody>
                        </MDBModal>
                    </MDBModal>
                    <MDBModal isOpen={this.state.modalEdit} toggle={()=>this.toggle("Edit")} size={"fluid"}>
                        <MDBModalHeader toggle={()=>this.toggle("Edit")}></MDBModalHeader>
                        <MDBModalBody className={"float-center"}>
                            <UpdateShipment loadedOnPopup={true} onEditComplete={this.onEditComplete}
                                            shipmentId={this.state.activeShipment._id}/>
                        </MDBModalBody>
                    </MDBModal>
                    <MDBModal size={"lg"} isOpen={this.state.modalReview} toggle={() => {
                        this.toggle("Review")
                    }}>
                        <MDBModalHeader toggle={() => {
                            this.toggle("Review")
                        }}>Shipment Review</MDBModalHeader>
                        <MDBModalBody>
                            <MDBBox>
                                <span>Check if the invoice is attached ?</span><br/>
                                <span>Check if the invoice image is clear enough to read out the content ?</span>
                                <br/><br/><br/>
                                <MDBInput value={this.state.shipmentReviewComment} type={"textarea"}
                                          label={"Review comment if any"} getValue={(e) => {
                                    this.setState({shipmentReviewComment: e})
                                }}></MDBInput>
                            </MDBBox>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn onClick={() => {
                                this.toggle("Review")
                            }}>Close</MDBBtn>
                            <MDBBtn onClick={() => {
                                this.review("Reject")
                            }}>Reject</MDBBtn>
                            <MDBBtn onClick={() => {
                                this.review("Approve")
                            }}>Approve</MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>
                    <MDBModal isOpen={this.state.modalAfterReview} toggle={() => {
                        this.toggle("AfterReview")
                    }}>
                        <MDBModalHeader toggle={() => {
                            this.toggle("AfterReview")
                        }}>Review Details</MDBModalHeader>
                        <MDBModalBody>
                            {(this.state.activeShipment !== null && this.state.activeShipment.hasOwnProperty("revieweDetails")) &&
                            <>
                                <div className={"my-2"}>
                                    Reviewed By: {this.state.activeShipment.revieweDetails.reviewedBy}
                                </div>
                                <div className={"my-2"}>
                                    Review
                                    Status: {this.state.activeShipment.revieweDetails.isApproved === "Y" ? "Approved" : "Rejected"}
                                </div>
                                <div className={"my-2"}>
                                    Review Comment: {this.state.activeShipment.revieweDetails.comment}
                                </div>
                                <div className={"my-2"}>
                                    Reviewed
                                    At: {Moments(this.state.activeShipment.revieweDetails.reviewedAt).format("MMM DD, YYYY")}
                                </div>
                            </>
                            }
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn onClick={() => {
                                this.toggle("AfterReview")
                            }}>Close</MDBBtn>
                                                        <MDBBtn onClick={() => {
                                this.toggle("ReviewReconsider")
                            }}>Review</MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>
                    <MDBModal isOpen={this.state.modalAddPayment} toggle={() => {
                        this.toggle("AddPayment")
                    }} size={"lg"}>
                        <MDBModalHeader toggle={() => {
                            this.toggle("AddPayment")
                        }}>Add Payment</MDBModalHeader>
                        <MDBModalBody>
                            {(this.state.activeShipment.hasOwnProperty("paymentLog")) &&
                            <div>
                            <MDBRow>
                                <MDBCol>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            format="MMM DD, YYYY"
                                            margin="normal"
                                            id="date-picker-inline"
                                            label="Paid On"
                                            value={this.state.paymentDate}
                                            onChange={(date) => {
                                                this.setState({paymentDate: date})
                                            }}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </MDBCol>
                                <MDBCol>
                                    <MDBInput maxLength="15" type='text' label="Paid Amount"
                                              onChange={this.handlePaymentAmount}
                                              value={this.state.paymentAmount}/>
                                </MDBCol>
                                <MDBCol>
                                    <MDBInput className={this.state.paymentReferenceError ? "border-danger" : ""}
                                              maxLength="200"
                                              type='text' label="Payment Reference" onChange={(e) => {
                                        this.setState({paymentReference: e.target.value})
                                    }}
                                              value={this.state.paymentReference}/>
                                </MDBCol>
                                <MDBCol>
                                    <MDBSelect key={"paymentMode" + this.state.randomKey}
                                               getValue={this.handlePaymentMode}
                                               options={this.state.paymentTypes}
                                               className={"form-control rounded-top rounded-bottom"}
                                               label="Payment Mode" outline={true}
                                    />
                                </MDBCol>
                               
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                               <span className="mx-1">Add Payment</span> 
                                    <PaymentDocsUpload key={"document_"+this.state.randomKey} buttonLabel={"Add Payment"} 
                                    initUploadCallback={this.initUploadCallback} 
                                    fileUploadCallback={this.fileUploadCallback} 
                                     nextProps={this.state.resetForm}
                                     ></PaymentDocsUpload>
                                
                                </MDBCol>
                            </MDBRow>
                            </div>
                           
                            }
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn outline color="secondary" onClick={() => {
                                this.toggle("AddPayment")
                            }}>Cancel</MDBBtn>
                            <MDBBtn onClick={this.savePayment}>Save</MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>
                    <MDBModal size={"lg"} isOpen={this.state.modalViewPaymentLog} toggle={() => {
                        this.toggle("ViewPaymentLog")
                    }}>
                        <MDBModalHeader toggle={() => {
                            this.toggle("ViewPaymentLog")
                        }}>Payment Logs</MDBModalHeader>
                        <MDBModalBody>
                            <MDBTable>
                                <MDBTableHead>
                                    <tr>
                                        <th>#</th>
                                        <th>Entered On</th>
                                        <th>Payment Date</th>
                                        <th>Payment Amount</th>
                                        <th>Payment Reference</th>
                                        <th>Payment Mode</th>
                                        <th>Document</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {this.state.activeShipment.hasOwnProperty("paymentLog") && this.state.activeShipment.paymentLog.map((row, index) => (
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{this.convertToReadbleDateTime(row.enteredOn)}</td>
                                            <td>{this.convertToReadbleDate(row.paymentDate)}</td>
                                            <td>{parseFloat(row.paymentAmount.toFixed(2))}</td>
                                            <td>{row.paymentReference}</td>
                                            <td>{row.paymentMode}</td>
                                            <td>{ row?.documentUrl?.length ? <a href={config.IMAGE_HOST+ row.documentUrl[0]} target="_blank" style={{color:"blue"}}> View Document </a> :"No document"}</td>
                                        </tr>
                                    ))}
                                </MDBTableBody>
                            </MDBTable>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn onClick={() => {
                                this.toggle("ViewPaymentLog")
                            }}>Close</MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>
                </MDBBox>
            </React.Fragment>
        );
    }
}

export default withRouter(ListShipment)
