import React from "react";
import {MDBBtn, MDBCol, MDBIcon, MDBInput, MDBRow} from "mdbreact";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import SingleDocDirectUpload from "../common/fileUpload/singleDocDirectUpload";
import {toast} from "react-toastify";
import PharmacyService from "../../services/pharmacyService";
import config from "../../config";
import UserStore from "../../stores/userStore";
class UploadPrescription extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            prescriptionName: "",
            patientName: "",
            prescribingDoctorName: "",
            prescriptionDate: new Date(),
            uploadedFiles: null,
            loadingUploadDoc: false
        }
    }
    getUploadedFiles = async (files) => {
        console.log(files)
        if (files.length === 0) {
            await this.setState({uploadedFiles: null})
        } else {
            await this.setState({uploadedFiles: files})
        }
    }
    savePrescription = async () => {
        let userData = await UserStore.fetchSessionData();
        if (this.state.uploadedFiles === null) {
            toast.error("Please upload a prescription.")
            return false
        }
        let payload = {
            prescriptionDate: this.state.prescriptionDate,
            prescribingDoctorName: this.state.prescribingDoctorName,
            patientName: this.state.patientName,
            prescriptionName: this.state.prescriptionName,
            prescriptionUrl: this.state.uploadedFiles[0],
            userId: this.props.userId
        }
        this.setState({loadingUploadDoc: true})
        let res = await PharmacyService.uploadPrescriptionToAccount(payload, userData.sessionToken)
        await this.props.getUploadedPrescription(res)
        this.setState({loadingUploadDoc: false})
    }
    createImageUrl = (item) => {
        if (item.match(/.(jpg|jpeg|png|gif)$/i)) {
            return <a href={config.IMAGE_HOST + item} target={"_blank"}><img src={config.IMAGE_HOST + item}
                                                                             width={86} height={86}/></a>
        } else {
            return <a href={config.IMAGE_HOST + item} target={"_blank"}><MDBIcon size={"6x"} icon="file"/></a>
        }
    }
    reset = () => {
        this.setState({
            prescriptionName: "",
            patientName: "",
            prescribingDoctorName: "",
            prescriptionDate: new Date(),
            uploadedFiles: null,
            loadingUploadDoc: false
        })
    }
    render() {
        return (
            <React.Fragment>
                <div className={"row px-2 p-2"}>
                    <div className={"col-12 p-1"}>
                        <MDBInput label={"Prescription name"} value={this.state.prescriptionName}
                                  getValue={(e) => {
                                      this.setState({prescriptionName: e})
                                  }}/>
                    </div>
                    <div className={"col-12 p-1"}>
                        <MDBInput label={"Patient name"} value={this.state.patientName} getValue={(e) => {
                            this.setState({patientName: e})
                        }}/>
                    </div>
                    <div className={"col-12 p-1"}>
                        <MDBInput label={"Prescribing doctor name"} value={this.state.prescribingDoctorName}
                                  getValue={(e) => {
                                      this.setState({prescribingDoctorName: e})
                                  }}/>
                    </div>
                    <div className={"col-12 p-1"}>
                        <MuiPickersUtilsProvider utils={MomentUtils} className={"text-primary mt-4"}>
                            <DatePicker
                                autoOk
                                value={this.state.prescriptionDate}
                                onChange={(dt) => {
                                    this.setState({prescriptionDate: (dt)})
                                }}
                                maxDate={new Date()}
                                label="Prescription Date"
                                format={"YYYY/MM/DD"}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                    <div className={"col-12 p-2"}>
                        <SingleDocDirectUpload getUploadedFiles={this.getUploadedFiles}
                                               title={"Upload prescription"}/>
                        {this.state.uploadedFiles !== null &&
                        <MDBRow>
                            <MDBCol>
                                {this.createImageUrl(this.state.uploadedFiles[0])}
                            </MDBCol>
                        </MDBRow>
                        }
                    </div>
                    <div className={"col-12 p-1 text-center"}>
                        <MDBBtn outline onClick={this.reset} outline disabled={this.state.uploadedFiles === null || this.state.loadingUploadDoc}>Reset</MDBBtn>
                        <MDBBtn onClick={this.savePrescription} disabled={this.state.loadingUploadDoc}>Attach Prescription To Order</MDBBtn>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default UploadPrescription;