import React from "react";
import {withRouter} from "react-router-dom";
import {
    MDBContainer,
    MDBRow,
    MDBInput,
    MDBBtn,
    MDBCardHeader,
    MDBFormInline,
    MDBCardBody,
    MDBCard,
    MDBCol,
    MDBSelect,
    MDBSelectInput,
    MDBSelectOptions,
    MDBSelectOption,
} from 'mdbreact';
import InventoryService from "../../services/inventoryService";
import { toast } from 'react-toastify';
import UserStore from "../../stores/userStore";
import PharmacyService from "../../services/pharmacyService";
import util from "../../utility/utility";

class EditProductShortcutComp extends React.Component {
    async componentDidMount() {
        this.fetchData()
    }
    fetchData = async() => {
        window.scrollTo(0, 0);
        let userData = await UserStore.fetchSessionData();
        let data=[];
        if(!window.qntyUnits || window.qntyUnits===null) {
            this.setState({progressStatus: true});
            data = await PharmacyService.fetchQuantityUnits(userData, 0, 0, "all");
            window.qntyUnits=data;
            this.setState({masterQuantityUnits:data})
        }else{
            data=window.qntyUnits;
            this.setState({masterQuantityUnits:data})
        }
        let data2=[];
        if(!window.retailPkgs || window.retailPkgs===null) {
            this.setState({progressStatus: true});
            data2 = await PharmacyService.fetchRetailPackages(userData, 0, 0, "all");
            window.retailPkgs=data2;
            this.setState({masterRetailPackages:data2})
        }else{
            data2=window.retailPkgs;
            this.setState({masterRetailPackages:data2})
        }
    }
    constructor(props) {
        super(props);
        this.state = {
            _id:this.props.product._id,
            isMedicine:this.props.product.isMedicine,
            medicineType:this.props.product.medicineType,
            productName:this.props.product.productName,
            retailPackage:this.props.product.retailPackage,
            quantityUnit:this.props.product.quantityUnit,
            contentQuantity:this.props.product.contentQuantity,
            minimumLooseSaleQuantity:this.props.product.minimumLooseSaleQuantity,
            looseSaleAllowed:this.props.product.looseSaleAllowed,
            masterRetailPackages:[],
            masterQuantityUnits:[],
            progressStatus:false,
        }
    }
    addMed =async () => {
        window.scrollTo(0, 0);
        let userData=await UserStore.fetchSessionData();
        this.setState({progressStatus:true});
        let status=await InventoryService.updateProductShortcut(this.state, userData);
        this.setState({progressStatus:false});
        if(status===false){
            toast.error("We are facing some issue. Please try again later.");
            return true;
        }else{
            this.setState({resetForm:true})
            toast.success("Product edited successfully.");
            window.productList=null;
            this.props.editProductCompleted();
        }
    }
    medName=(event)=>{
        this.setState({
            productName: event.target.value
        })
    }
    retailPackageChange=(val)=>{
        this.setState({retailPackage: val[0]});
    }
    contentQuantityChange=(val)=>{
        if((!util.isInt(val) && val !== "") || (val > 10000)){
            return false;
        }
        this.setState({contentQuantity:val});
    }
    quantityUnitChange=(val)=>{
        this.setState({quantityUnit:val[0]});
    }
    minimumLooseSaleQuantityChange=(val)=>{
        if((!util.isInt(val) && val !== "") || (val > 1000)){
            return false;
        }
        this.setState({minimumLooseSaleQuantity:val});
    }
    looseSaleAllowedChange=(val)=>{
        if(val===true){
            this.setState({looseSaleAllowed:"Y"});
        }else{
            this.setState({looseSaleAllowed:"N",minimumLooseSaleQuantity:0});
        }
    }
    quantityUnit=(val)=>{
        this.setState({selectedMedType:val[0]});
    }
    render() {
        return (
            <React.Fragment>
                <MDBContainer>
                    <MDBCard className={"mt-2"}>
                        <MDBCardHeader>
                            <span className={"float-left"}>Edit Product {this.state.productName}</span>
                            <MDBFormInline className="float-right md-form mr-auto m-0">
                                <MDBBtn outline color="blue-grey" size="sm" onClick={this.addMed} className="mr-auto"
                                       >
                                    Update
                                </MDBBtn>
                            </MDBFormInline>
                        </MDBCardHeader>
                        <MDBCardBody>
                            <MDBRow>
                                <MDBCol size={"6"} className={"pt-2 mt-2"}>Retail Packaging</MDBCol>
                                <MDBCol style={{marginTop:"-20px"}}>
                                    <MDBSelect getValue={this.retailPackageChange}>
                                        <MDBSelectInput selected="Select Retail Packaging"/>
                                        <MDBSelectOptions>
                                            <MDBSelectOption value="" disabled>Select Retail Packaging</MDBSelectOption>
                                            {this.state.masterRetailPackages.map((item, idx) => (
                                                <MDBSelectOption value={item.retailPackage} key={idx}
                                                                 selected={this.state.retailPackage === item.retailPackage ? true : false}>{item.retailPackage}</MDBSelectOption>
                                            ))}
                                        </MDBSelectOptions>
                                    </MDBSelect>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol size={"6"} className={"pt-2 mt-2"}>Packaging Unit</MDBCol>
                                <MDBCol style={{marginTop:"-20px"}}>
                                    <MDBSelect getValue={this.quantityUnitChange}>
                                        <MDBSelectInput selected="Select Packaging Unit"/>
                                        <MDBSelectOptions>
                                            <MDBSelectOption value="" disabled selected>Select Packaging
                                                Unit</MDBSelectOption>
                                            {this.state.masterQuantityUnits.map((item, idx) => (
                                                <MDBSelectOption value={item.quantityUnit} key={idx}
                                                                 selected={this.state.quantityUnit === item.quantityUnit ? true : false}>{item.quantityUnit}</MDBSelectOption>
                                            ))}
                                        </MDBSelectOptions>
                                    </MDBSelect>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol size={"6"} className={"pt-2 mt-2"}>Packaging Quantity</MDBCol>
                                <MDBCol style={{marginTop:"-20px"}}>
                                    <MDBInput label="Packaging Quantity" value={this.state.contentQuantity}
                                              getValue={this.contentQuantityChange}/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol size={"6"} className={"pt-2 mt-2"}>Loose Sale Allowed</MDBCol>
                                <MDBCol>
                                    <br/><MDBInput label=" " type="checkbox" id="checkbox_loose" checked={this.state.looseSaleAllowed === "Y"?true:false} getValue={this.looseSaleAllowedChange}/>
                                </MDBCol>
                            </MDBRow>
                            {this.state.looseSaleAllowed === "Y" &&
                            <MDBRow>
                                <MDBCol size={"6"} className={"pt-2 mt-2"}>Minimum Loose Sale Quantity</MDBCol>
                                <MDBCol>
                                    <MDBInput id={"sellable_units_se"} label="Minimum Loose Sale Quantity"
                                              type={"number"} value={this.state.minimumLooseSaleQuantity}
                                              getValue={this.minimumLooseSaleQuantityChange}/>
                                </MDBCol>
                            </MDBRow>
                            }
                        </MDBCardBody>
                    </MDBCard>
                </MDBContainer>
                <br/>
            </React.Fragment>
        );
    }
}
export default withRouter(EditProductShortcutComp);


