import React from "react";
import {MDBTable, MDBTableBody, MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBView} from "mdbreact"
import config from "../../config";
class ProductDetailsPreview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            productDtls: props.productDetails
        }
    }
    componentDidMount() {
        let productDtls = this.state.productDtls
        productDtls.categoryList = []
        for (let item of productDtls.selectedCategory) {
            let temp = item.split("_")
            productDtls.categoryList.push(temp[1])
        }
        this.setState({productDtls})
    }

    render() {
        return (
            <React.Fragment>
                <div className="container z-depth-1 my-1 p-1">
                    <MDBTable striped>
                        <MDBTableBody>
                            {this.state.productDtls.uploadedFiles !== null &&
                            <tr>
                                <td colSpan={"2"} className={"border grey"} align={"center"}>
                                    {this.state.productDtls.uploadedFiles.length > 0 &&
                                    <MDBCarousel
                                        activeItem={1}
                                        length={this.state.productDtls.uploadedFiles.length}
                                        showControls={true}
                                        showIndicators={true}
                                        className="z-depth-1"
                                        slide={false}
                                    >
                                        <MDBCarouselInner>
                                            {this.state.productDtls.uploadedFiles.map((img, idx) => (
                                                <MDBCarouselItem itemId={idx + 1}
                                                                 className={'provider-detail-carousel'} key={idx}>
                                                    <MDBView>
                                                        <img
                                                            className="d-block img"
                                                            src={config.IMAGE_HOST + img.filePathOriginal}
                                                            alt="First slide"
                                                            style={{maxWidth: "700px", maxHeight: "700px"}}
                                                        />
                                                    </MDBView>
                                                </MDBCarouselItem>
                                            ))}
                                        </MDBCarouselInner>
                                    </MDBCarousel>
                                    }
                                </td>
                            </tr>
                            }
                            <tr>
                                <td colSpan={"2"} className={"text-center font-weight-bold text-light bg-secondary"}>Product Name</td>
                            </tr>
                            <tr>
                                <td width={250} className={"font-weight-bold"}>Product Name</td>
                                <td>{this.state.productDtls.productName}</td>
                            </tr>
                            <tr>
                                <td className={"font-weight-bold"}>Manufacturer</td>
                                <td>{this.state.productDtls.selectedMfg[0]}</td>
                            </tr>
                            <tr>
                                <td className={"font-weight-bold"}>Brand</td>
                                <td>{this.state.productDtls.brandName}</td>
                            </tr>
                            {this.state.productDtls.categoryList &&
                            <tr>
                                <td className={"font-weight-bold"}>Category</td>
                                <td>{this.state.productDtls.categoryList.map((item, index) => (
                                    <span className={"text-capitalize"} key={index}>{index + 1}. {item} </span>
                                ))}</td>
                            </tr>
                            }
                            <tr>
                                <td className={"font-weight-bold"}>Special Diet</td>
                                <td>{this.state.productDtls.selectedSpecialDiet.map((item, index) => (
                                    <span className={"text-capitalize"} key={index}>{index + 1}. {item} </span>
                                ))}</td>
                            </tr>
                            <tr>
                                <td colSpan={"2"} className={"text-center font-weight-bold text-light bg-dark"}>Packaging Details</td>
                            </tr>
                            <tr>
                                <td className={"font-weight-bold"}>Retail Packaging</td>
                                <td>{this.state.productDtls.selectedRetailPackaging}</td>
                            </tr>
                            <tr>
                                <td className={"font-weight-bold"}>Packaging Quantity</td>
                                <td>{this.state.productDtls.packagingQnty}</td>
                            </tr>
                            <tr>
                                <td className={"font-weight-bold"}>Packaging Unit</td>
                                <td>{this.state.productDtls.selectedPackagingUnit}</td>
                            </tr>
                            <tr>
                                <td className={"font-weight-bold"}>Loose Sale Allowed</td>
                                <td>{this.state.productDtls.isLooseSaleAllowed === true ? "Yes" : "No"}</td>
                            </tr>
                            <tr>
                                <td className={"font-weight-bold"}>Minimum Loose Sale Quantity</td>
                                <td>{this.state.productDtls.minimumLooseSaleQnty}</td>
                            </tr>
                            <tr>
                                <td colSpan={"2"} className={"text-center font-weight-bold text-light bg-secondary"}>Product Form</td>
                            </tr>
                            <tr>
                                <td className={"font-weight-bold"}>Form</td>
                                <td>{this.state.productDtls.selectedProductForm.map((item, index) => (
                                    <span className={"text-capitalize"} key={index}>{index + 1}. {item} </span>
                                ))}</td>
                            </tr>
                            <tr>
                                <td className={"font-weight-bold"}>Flavor</td>
                                <td>{this.state.productDtls.selectedProductFlavor.map((item, index) => (
                                    <span className={"text-capitalize"} key={index}>{index + 1}. {item} </span>
                                ))}</td>
                            </tr>
                            <tr>
                                <td className={"font-weight-bold"}>Texture</td>
                                <td>{this.state.productDtls.selectedProductTexture.map((item, index) => (
                                    <span className={"text-capitalize"} key={index}>{index + 1}. {item} </span>
                                ))}</td>
                            </tr>
                            <tr>
                                <td className={"font-weight-bold"}>Colour</td>
                                <td>{this.state.productDtls.selectedProductColor.map((item, index) => (
                                    <span className={"text-capitalize"} key={index}>{index + 1}. {item} </span>
                                ))}</td>
                            </tr>
                            <tr>
                                <td colSpan={"2"} className={"text-center font-weight-bold text-light bg-dark"}>Other Details</td>
                            </tr>
                            <tr>
                                <td className={"font-weight-bold"}>Life Stage</td>
                                <td>{this.state.productDtls.selectedLifeStage.map((item, index) => (
                                    <span className={"text-capitalize"} key={index}>{index + 1}. {item} </span>
                                ))}</td>
                            </tr>
                            <tr>
                                <td className={"font-weight-bold"}>Breed Size (Only for pets)</td>
                                <td>{this.state.productDtls.selectedBreedSize.map((item, index) => (
                                    <span className={"text-capitalize"} key={index}>{index + 1}. {item} </span>
                                ))}</td>
                            </tr>
                            <tr>
                                <td className={"font-weight-bold"}>Uses</td>
                                <td>{this.state.productDtls.productUses}</td>
                            </tr>
                            <tr>
                                <td className={"font-weight-bold"}>Health Benefits</td>
                                <td>{this.state.productDtls.selectedHealthBenefit.map((item, index) => (
                                    <span className={"text-capitalize"} key={index}>{index + 1}. {item} </span>
                                ))}</td>
                            </tr>
                            <tr>
                                <td className={"font-weight-bold"}>Side Effects</td>
                                <td>{this.state.productDtls.selectedSideEffect.map((item, index) => (
                                    <span className={"text-capitalize"} key={index}>{index + 1}. {item} </span>
                                ))}</td>
                            </tr>
                            <tr>
                                <td className={"font-weight-bold"}>Description</td>
                                <td>{this.state.productDtls.description}</td>
                            </tr>
                            <tr>
                                <td className={"font-weight-bold"}>How To Use</td>
                                <td>{this.state.productDtls.howToUse}</td>
                            </tr>
                            <tr>
                                <td colSpan={"2"} className={"text-center font-weight-bold text-light bg-secondary"}>Reorder Config</td>
                            </tr>
                            <tr>
                                <td className={"font-weight-bold"}>Maximum Quantity</td>
                                <td>{this.state.productDtls.maximumQuantity}</td>
                            </tr>
                            <tr>
                                <td className={"font-weight-bold"}>Minimum Quantity</td>
                                <td>{this.state.productDtls.minimumQuantity}</td>
                            </tr>
                            <tr>
                                <td className={"font-weight-bold"}>Reorder Threshold</td>
                                <td>{this.state.productDtls.reorderQuantity}</td>
                            </tr>
                            <tr>
                                <td colSpan={"2"} className={"text-center font-weight-bold text-light bg-dark"}>Other Config</td>
                            </tr>
                            <tr>
                                <td className={"font-weight-bold"}>Discount (%)</td>
                                <td>{this.state.productDtls.discount}</td>
                            </tr>
                            <tr>
                                <td className={"font-weight-bold"}>Is Top Selling</td>
                                <td>{this.state.productDtls.isTopSelling}</td>
                            </tr>
                            <tr>
                                <td className={"font-weight-bold"}>Mark As Out Of Stock</td>
                                <td>{this.state.productDtls.markAsOutOfStock}</td>
                            </tr>
                            <tr>
                                <td colSpan={"2"} className={"text-center font-weight-bold text-light bg-secondary"}>Shipping Details</td>
                            </tr>
                            <tr>
                                <td className={"font-weight-bold"}>In-Person Delivery</td>
                                <td>{this.state.productDtls.inPersonDelivery === true ? "Yes" : "No"}</td>
                            </tr>
                            <tr>
                                <td className={"font-weight-bold"}>In-Person Delivery Instructions</td>
                                <td>{this.state.productDtls.inPersonDeliveryInstructions}</td>
                            </tr>
                            <tr>
                                <td className={"font-weight-bold"}>Is Shippable ?</td>
                                <td>{this.state.productDtls.ship === true ? "Yes" : "No"}</td>
                            </tr>
                            <tr>
                                <td className={"font-weight-bold"}>In-Person Delivery Instructions</td>
                                <td>{this.state.productDtls.shippingInstructions}</td>
                            </tr>
                            <tr>
                                <td className={"font-weight-bold"}>Pickup</td>
                                <td>{this.state.productDtls.pickup === true ? "Yes" : "No"}</td>
                            </tr>
                            <tr>
                                <td className={"font-weight-bold"}>Pickup Instructions</td>
                                <td>{this.state.productDtls.pickupInstructions}</td>
                            </tr>
                            <tr>
                                <td colSpan={"2"} className={"text-center font-weight-bold text-light bg-dark"}>Product Type & Materials</td>
                            </tr>
                            <tr>
                                <td className={"font-weight-bold"}>Product Type</td>
                                <td>{this.state.productDtls.selectedProductType}</td>
                            </tr>
                            {this.state.productDtls.selectedProductType === "Medicine" &&
                            <tr>
                                <td className={"font-weight-bold"}>Medicine Type</td>
                                <td>{this.state.productDtls.selectedMedicineType}</td>
                            </tr>
                            }
                            {(this.state.productDtls.selectedProductType === "Toy" || this.state.productDtls.selectedProductType === "Supplies" || this.state.productDtls.selectedProductType === "Accessories") &&
                            <tr>
                                <td className={"font-weight-bold"}>Materials</td>
                                <td>{this.state.productDtls.selectedProductMaterial.map((item, index) => (
                                    <span className={"text-capitalize"} key={index}>{index + 1}. {item} </span>
                                ))}</td>
                            </tr>
                            }
                            {this.state.productDtls.selectedProductType === "Toy" &&
                                <>
                                    <tr>
                                        <td colSpan={"2"} className={"text-center font-weight-bold text-light bg-secondary"}>Toy Details (Only for toys)</td>
                                    </tr>
                                    <tr>
                                        <td className={"font-weight-bold"}>Toy Feature</td>
                                        <td>{this.state.productDtls.selectedToyFeature.map((item, index) => (
                                            <span className={"text-capitalize"} key={index}>{index + 1}. {item} </span>
                                        ))}</td>
                                    </tr>
                                    <tr>
                                        <td className={"font-weight-bold"}>Toy Collection</td>
                                        <td>{this.state.productDtls.selectedToyCollection.map((item, index) => (
                                            <span className={"text-capitalize"} key={index}>{index + 1}. {item} </span>
                                        ))}</td>
                                    </tr>
                                    <tr>
                                        <td className={"font-weight-bold"}>Toy Scent</td>
                                        <td>{this.state.productDtls.selectedToyScent.map((item, index) => (
                                            <span className={"text-capitalize"} key={index}>{index + 1}. {item} </span>
                                        ))}</td>
                                    </tr>
                                </>
                            }
                            <tr>
                                <td className={"font-weight-bold"}>Food Habit</td>
                                <td>{this.state.productDtls.foodHabit}</td>
                            </tr>
                            <tr>
                                <td colSpan={"2"} className={"text-center font-weight-bold text-light bg-secondary"}>Tags & Highlights</td>
                            </tr>
                            <tr>
                                <td className={"font-weight-bold"}>Tags</td>
                                <td>{this.state.productDtls.tagList.map((item, index) => (
                                    <span className={"text-capitalize"} key={index}>{index + 1}) {item.value} </span>
                                ))}</td>
                            </tr>
                            <tr>
                                <td className={"font-weight-bold"}>Highlights</td>
                                <td>{this.state.productDtls.highlightList.map((item, index) => (
                                    <span className={"text-capitalize"} key={index}>{index + 1}. {item.value} </span>
                                ))}</td>
                            </tr>
                            <tr>
                                <td colSpan={"2"} className={"text-center font-weight-bold text-light bg-dark"}>Guaranteed Analysis</td>
                            </tr>
                            <tr>
                                <td className={"font-weight-bold"}>Guaranteed Analysis</td>
                                <td>{this.state.productDtls.guaranteedAnaList.map((item, index) => (<>
                                    {item.selected === true &&
                                    <span className={"text-capitalize"} key={index}>{index}) {item.text}: {item.qnty} {item.unit}<br/></span>
                                    }
                                </>))}</td>
                            </tr>
                            <tr>
                                <td className={"font-weight-bold"}>Ingredient List</td>
                                <td>{this.state.productDtls.productIngredientList.map((item, index) => (<>
                                    {item.selected === true &&
                                        <span className={"text-capitalize"} key={index}>{item.displayIndex + 1}) {item.text}: {item.qnty} {item.unit}<br/></span>
                                    }
                                </>))}
                                </td>
                            </tr>
                        </MDBTableBody>
                    </MDBTable>
                </div>
            </React.Fragment>
        );
    }
}
export default ProductDetailsPreview;