import React from "react";
import {MDBBreadcrumb, MDBBreadcrumbItem} from "mdbreact";
import {Link} from "react-router-dom";
import AddProductsByCategoryCmp from "../../components/productGrouping/addProductToCategory";
class AddProductToCategory extends React.Component {
    render() {
        return(
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem><Link to={'/product-list-by-category'}>List Product By Category</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Add Product To Category</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <AddProductsByCategoryCmp/>
            </React.Fragment>
        )
    }
}
export default AddProductToCategory;