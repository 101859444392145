import React from "react";
import { withRouter } from "react-router-dom";
import {
    MDBContainer, MDBBreadcrumb, MDBStepper, MDBStep,
    MDBBreadcrumbItem,
    MDBBox,
    MDBProgress,
    MDBDataTableV5,
    MDBCardHeader,
    MDBFormInline,
    MDBBtn,
    MDBCardBody,
    MDBCard,
    MDBModalHeader,
    MDBModalBody,
    MDBModal,
    MDBModalFooter,
    MDBTableHead,
    MDBTableBody,
    MDBIcon, MDBTable, MDBRow, MDBCol, MDBInput
} from 'mdbreact';
import UserStore from "../../stores/userStore";
import {toast} from "react-toastify";
import dataTableConfig from "../order-history/dataTableOrderHistory";
import PharmacyService from "../../services/pharmacyService";
import OrderService from "../../services/orderService";
import Moments from "moment";
import config from "../../config";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
//import ReactPdf from "../../components/common/pdf/reactPdf"
import utility from "../../utility/utility";

import {Page, Text, View, Document, StyleSheet, PDFDownloadLink} from '@react-pdf/renderer';
import {TableCell, DataTableCell, Table, TableHeader, TableBody, TableBorder, TableBodyProps, TableCellProps} from '@david.kucsai/react-pdf-table'
import { PDFViewer } from '@react-pdf/renderer';
import ReactDOM from "react-dom";
const numPages = null
const  setNumPages = null
const pageNumber = 1
const setPageNumber = 1

function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
}

const styles1 = StyleSheet.create({
    page: { backgroundColor: 'tomato' },
    section: { textAlign: 'center', margin: 30 }
});


const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#E4E4E4'
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },


    table: { display: "table", width: "auto", borderStyle: "solid", borderWidth: 1, borderRightWidth: 0, borderBottomWidth: 0 },
    tableRow: { margin: "auto", flexDirection: "row" },
    tableCol1: { width: "100%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 },
    tableCol2: { width: "50%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 },
    tableCol4: { width: "25%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 },
    tableCol: { width: "8%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 },
    tableColProduct: { width: "12%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 },
    tableCell: { margin: "auto", marginTop: 5, fontSize: 10 },

    tableCellH1: { margin: "auto", marginTop: 5,  marginBottom: 5, fontSize: 18 },
    tableCellH2: { margin: "auto", marginTop: 5, fontSize: 14 },
    tableCellH3: { margin: "auto", marginTop: 5, marginBottom: 5, fontSize: 12 },
    tableCellH4: { margin: "auto", marginTop: 5, fontSize: 10 },
    tableCellH5: { margin: "auto", marginTop: 5, fontSize: 9 },
    tableCellH6: { margin: "auto", marginTop: 5, fontSize: 8 },


});








export default class OrderHistoryPage extends React.Component {
    constructor(props) {
        const ref = React.createRef()
        super(props);
        this.uploadPrescription = React.createRef();
        this.pdfInvoice = React.createRef();
        this.state = {
            selectedProviderAddress : "1st Floor, Lakhi Mandir Complex, Beltola, Guwahati, Assam, 781028.  03612302334",

            ready:false,
            apiResultCame:false,
            orderStatusLog:[
                {status:"ORDER_RECEIVED", timestamp:"", description:""},
                {status:"REROUTED", timestamp:"", description:""},
                {status:"ACKNOWLEDGED", timestamp:"", description:""},
                {status:"ASKED_TO_PAY", timestamp:"", description:""},
                {status:"ASKED_TO_UPLOAD_PRESCRIPTION", timestamp:"", description:""},
                {status:"ASKED_TO_UPLOAD_PRESCRIPTION", timestamp:"", description:""},
            ],
            order:{
                attachmentUrl: [],
                customerDetails:{},
                doc:[],
                docs:[],
                shippingAddress:{},
                subOrders:[],
            },
            orderId:"",

            totalCount: 0,
            orderItems:[],
            orderItemsExtraInfo:[],
            selectedFile: null,
            loading: false,
            modalDeletePrescription: false,
            currentPath: "",
            deleting: false,
            prescriptionNeeded:false,
            uploadingFile:false,
            theInputKey:"",
            modalCommonAction:false,
        }
    }



    resetFileInput() {
        let randomString = Math.random().toString(36);
        this.setState({
            theInputKey: randomString
        });
    }


    async componentDidMount() {
        let userData = await UserStore.fetchSessionData();
        this.setState({userData: userData})

        if(userData.type === "platformAdmin"){
            this.setState({orgId: userData._id})
        } else if(userData.type === "ORG_ADMIN"){
            this.setState({orgId: userData.orgDetails[0]._id})
        }

        const { orderId } = this.props.queryString;
        this.setState({orderId:orderId})



        this.fetchOrderById(orderId)
        let img=[];
        for(let i =0; i<this.state.order.attachmentUrl.length; i++){
            if(this.state.order.attachmentUrl[i].indexOf(".png") > 0 || this.state.order.attachmentUrl[i].indexOf(".jpg") > 0 || this.state.order.attachmentUrl[i].indexOf(".jpeg") > 0){
                img.push({src:config.IMAGE_HOST+this.state.order.attachmentUrl[i], alt:"img "+i});
            }
        }
        this.setState({images: img})
    }

    fetchOrderById = async(orderId) => {
        this.setState({loading:true, apiResultCame:false})
        let order =await OrderService.fetchSubOrderDetail(this.state);
        this.setState({loading:false, apiResultCame:true})
        if(!order){
            toast.error("Something went wrong while fetching orders.")
        } else  {
            this.setState({"order": order})


            console.log("Orders:")
            console.log(order)

            // console.log("orderItemsExtraInfo:")
            // console.log(this.state.orderItemsExtraInfo)
        }
    }

    retryPayment = (paymentGatewayOrderId) => {
        this.openCheckout(paymentGatewayOrderId)
    }





    toggle = nr => () => {
        let modalNumber = 'modal' + nr
        this.setState({
            [modalNumber]: !this.state[modalNumber]
        });

    }

    initDeleteDoc = (path) => {
        this.setState({"currentPath": path})
        let modalNumber = "modalDeletePrescription";
        this.setState({
            [modalNumber]: !this.state[modalNumber]
        });
    }

    openCheckout = (paymentGatewayOrderId) => {

    }

    subTotal = (mrp, quantity, discount) => {
        let amount = mrp * quantity;
        if(discount > 0){
            amount = amount - (amount*(discount/100))
        }
        return amount;
    }

    convertToReadbleDate = date =>{
        if(date){
            return Moments.utc(date).local().format("MMM D, YYYY hh:mm A");
        }
    }
    convertToReadbleDateUnix = date =>{
        return Moments.unix(date).format("MMM D, YYYY hh:mm A");
    }

    onChangeHandler=event=>{
        console.log(event.target.files[0])

        this.setState({
            selectedFile: event.target.files[0],
            loaded: 0,
        })
    }



    linkUploadedFileToOrderId = async (path) =>{

    }

    onClickHandlerCancel = () => {
        this.setState({
            selectedFile: null,
        })
    }

    onClickHandler = async () => {

    }

    onInitFileHandler  = async () => {
        this.uploadPrescription.current.click();
    }


    // This toggle opens or closes the delete shipment modal
    toggleOrderDetails = () => {
        this.setState({
            modalOrderDetails: !this.state.modalOrderDetails
        });
    }
    convertMfgOrExpiry = date =>{
        if(date){
            return Moments(date).format("MMM YYYY");
        } else {
            return "";
        }
    }

    cancelOrder = () => {
        this.updateOrderStatus("CANCELED", "")
    }

    updateOrderStatus = async (status, description) => {
        let payload = {
            status:status,
            description:description,
        }
        let order =await OrderService.updateOrderStatus(this.state, payload);
        if(order){
            let existingOrder = this.state.order;
            existingOrder.status = status;
            this.setState({order:existingOrder})
        }
        alert(this.state.order.status)
    }


    // Order Actions
    initCancel = () => {
        let modalNumber = "modalCommonAction";
        this.setState({
            [modalNumber]: !this.state[modalNumber]
        });
    }

    acknowledgeOrder = () => {
        this.updateOrderStatus("ACKNOWLEDGED", "")
    }

    askToUploadPrescription = () => {
        this.updateOrderStatus("ASKED_TO_UPLOAD_PRESCRIPTION", "")
    }

    pharmacistApproved = () => {
        this.updateOrderStatus("PHARMACIST_APPROVED", "")
    }

    processCartItem = () => {
        this.updateOrderStatus("CART_ITEM_PROCESSED", "")
    }

    askToPay = () => {
        this.updateOrderStatus("ASKED_TO_PAY", "")
    }


    downloadInvoice() {
        this.setState((prevState) => ({
            ready: false
        }), () => {     // THIS IS THE HACK
            setTimeout(() => {
                this.setState({ ready: true });
            }, 1);
        });
    }


    render() {

        const { ready } = this.state;

        const MyDocument = (
            <Document>
                <Page size="A4" style={{border: '1 solid #000', backgroundColor: 'white'}}>
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol1}>
                                <Text style={styles.tableCellH1}>Tax Invoice</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol2}>
                                <Text style={styles.tableCellH2}>Positra Pharmacy</Text>
                            </View>
                            <View style={styles.tableCol2}>
                                <Text style={styles.tableCell}>{this.state.selectedProviderAddress}</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol4}>
                                <Text style={styles.tableCell}>GSTIN</Text>
                                <Text style={styles.tableCell}>18AAICP6875B1Z5</Text>
                            </View>
                            <View style={styles.tableCol4}>
                                <Text style={styles.tableCell}>Invoice #</Text>
                                <Text style={styles.tableCell}>{this.state.order.orderCode}</Text>
                            </View>
                            <View style={styles.tableCol4}>
                                <Text style={styles.tableCell}>Order Date</Text>
                                <Text style={styles.tableCell}>{this.state.order.orderDate}</Text>
                            </View>
                            <View style={styles.tableCol4}>
                                <Text style={styles.tableCell}>Transaction Mode</Text>
                                <Text style={styles.tableCell}>{this.state.order.paymentMode}</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol1}>
                                <Text style={styles.tableCellH3}>Details of Customer</Text>
                            </View>
                        </View>

                        <View style={styles.tableRow}>
                            <View style={styles.tableCol1}>
                                {this.state.order.hasOwnProperty("customerDetails") &&

                                <Text style={{fontSize: "12"}}>
                                    Name: {this.state.order.customerDetails.name}
                                </Text>
                                }
                                <Text style={{fontSize:"12"}}>
                                    Prescribed By:
                                </Text>
                            </View>
                        </View>

                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>No</Text>
                            </View>
                            <View style={styles.tableColProduct}>
                                <Text style={styles.tableCell}>Product</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>HSN</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Expires</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Qty</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Batch</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>MRP</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Discount %</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Discount Amount</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>GST %</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>GST Amount</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Subtotal</Text>
                            </View>
                        </View>
                        {this.state.order.subOrders.map((row, index) => (
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {index+1}
                                </Text>
                            </View>
                            <View style={styles.tableColProduct}>
                                <Text style={styles.tableCell}>{row.productDetails.productName}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{row.hsn}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{this.convertMfgOrExpiry(row.expDate)}</Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {index+1}
                                </Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{row.quantity}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{row.batch}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{row.mrp}</Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {row.discount}
                                </Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{row.discount}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{row.gst}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{row.subTotal}</Text>
                            </View>

                        </View>
                            ))}

                        <View style={styles.tableRow}>
                            <View style={styles.tableCol1}>
                                <Text style={{fontSize:"14", marginTop:"5", marginBottom:"5"}}>
                                    Total Amount: Rs. {this.state.order.totalAmount}
                                </Text>
                            </View>
                        </View>
                    </View>

                    <View style={{"paddingTop":10, width:"98%", position:"absolute", bottom:30, marginLeft:5, marginRight:5,  borderTop:"1 solid #000", }}>
                        <Text style={{fontSize: "9"}}>
                            Notes:
                        </Text>
                        <Text style={{fontSize:"9"}}>
                            * MRP is inclusive of GST.
                        </Text>
                        <Text style={{fontSize:"9"}}>
                            * Goods once sold will not be taken back or exchanged.
                        </Text>
                        <Text style={{fontSize:"9"}}>
                            * Thank you for your business.
                        </Text>
                    </View>
                </Page>
            </Document>
        );


        return (
            <React.Fragment>
                <MDBContainer >




                    {this.state.loading === true &&
                    <MDBRow className={"text-center"}>
                        <MDBCol>
                            <span><img style={{"width": "4rem"}} src={require('../../components/common/img/loader_new.gif')}/></span>
                        </MDBCol>
                    </MDBRow>
                    }
                    {this.state.loading === false &&
                    <MDBRow>
                        <MDBCol size={"4"}>
                            <strong>Order Id: {this.state.order.orderCode}</strong><br></br>

                            <div className={"float-left"}>
                                Order Date: {this.convertToReadbleDate(this.state.order.createdAt)}
                            </div>
                            <br></br>
                            {(this.state.order.hasOwnProperty("razorpayPaymentDetails") && this.state.order.razorpayPaymentDetails.status === "captured" ) &&
                            <div>
                                <div className={'green-text pl-0 pb-2 mb-2 '}>
                                    <MDBIcon icon="check-circle"/> <strong>Order Placed</strong>

                                </div>
                                <div>
                                    <b>Payment Details</b><br/>
                                    {this.state.order.razorpayPaymentDetails.method === "netbanking" &&
                                    <span>Payment method: {this.state.order.razorpayPaymentDetails.bank &&
                                    <span>{this.state.order.razorpayPaymentDetails.bank}</span>} {this.state.order.razorpayPaymentDetails.method}</span>
                                    }

                                    {this.state.order.razorpayPaymentDetails.method === "card" &&
                                    <span>Payment method: {this.state.order.razorpayPaymentDetails.hasOwnProperty("card") &&
                                    <span>{this.state.order.razorpayPaymentDetails.card.issuer} {this.state.order.razorpayPaymentDetails.card.network} {this.state.order.razorpayPaymentDetails.card.type} {this.state.order.razorpayPaymentDetails.method} **** {this.state.order.razorpayPaymentDetails.card.last4}</span>} </span>
                                    }

                                    {this.state.order.razorpayPaymentDetails.method === "upi" &&
                                    <span>Payment method: {this.state.order.razorpayPaymentDetails.method} {this.state.order.razorpayPaymentDetails.vpa &&
                                    <span>{this.state.order.razorpayPaymentDetails.vpa}</span>} </span>
                                    }

                                    <br/>
                                    Payment Time: {this.convertToReadbleDateUnix(this.state.order.razorpayPaymentDetails.created_at)}<br/>
                                    Amount Paid: {this.state.order.razorpayPaymentDetails.currency !== "INR" && <span>{this.state.order.razorpayPaymentDetails.currency}</span> }{this.state.order.razorpayPaymentDetails.currency === "INR" && <span>&#8377;</span> }{(this.state.order.razorpayPaymentDetails.amount/100).toFixed("2")}<br/>

                                </div>
                            </div>
                            }




                            {(!this.state.order.hasOwnProperty("razorpayPaymentDetails")  || (this.state.order.hasOwnProperty("razorpayPaymentDetails") && this.state.order.razorpayPaymentDetails.status === "created")  ) &&
                            <div className={'float-left red-text pl-0 pb-2 mb-2'}>
                                <MDBIcon icon="hourglass-half"/> <strong>Payment Pending</strong>
                                {(this.state.order.hasOwnProperty("totalAmount") && this.state.order.totalAmount > 0 ) &&
                                <div>
                                <span color={"primary"} style={{"color": "blue", "cursor": "pointer"}}
                                      className={"btn-link float-left"}
                                      onClick={() => this.retryPayment(this.state.order.razorPayOrderDetails.id)}>Retry payment</span>

                                    <br></br>
                                </div>
                                }
                            </div>
                            }






                        </MDBCol>
                        {(this.state.order.shippingAddress.hasOwnProperty("name") && this.state.order.deliveryType === "SHIP") &&
                        <MDBCol size={"4"}>
                            <strong>Shipping Address</strong><br></br>
                            {this.state.order.shippingAddress.name}<br></br>
                            {utility.stringifyAddress(this.state.order.shippingAddress)}<br></br>
                            {this.state.order.shippingAddress.contactNo}
                        </MDBCol>
                        }

                        {(this.state.order.deliveryType === "PICKUP") &&
                        <MDBCol size={"4"}>
                            Customer will pick up from store.
                        </MDBCol>
                        }

                        {(this.state.order.shippingAddress.hasOwnProperty("name") ) &&
                        <MDBCol size={"4"}>
                            <strong>Billing Address</strong><br></br>
                            {this.state.order.shippingAddress.name}<br></br>
                            {utility.stringifyAddress(this.state.order.shippingAddress)}<br></br>
                            {this.state.order.shippingAddress.contactNo}
                        </MDBCol>
                        }

                    </MDBRow>
                    }


                    {(this.state.prescriptionNeeded || this.state.order.orderMode === "PRESCRIPTION") &&
                    <MDBRow className={"mt-4"}>
                        <MDBCol>
                            <form method="post" action="#" id="#">
                                <div className="form-group files">
                                    {(this.state.prescriptionNeeded && this.state.order.docs.length === 0) &&
                                    <div><label className={"red-text"}><strong>One or more medications in your order need supporting prescriptions. </strong></label></div>
                                    }
                                    {(this.state.prescriptionNeeded && this.state.order.docs.length > 0) &&
                                    <div><label><strong>Supporting documents attached: {this.state.order.docs.length} </strong></label></div>
                                    }

                                    <input key={this.state.theInputKey || '' } type="file" style={{"display": "none"}} name="file" ref={this.uploadPrescription} onChange={this.onChangeHandler}/>
                                    {!this.state.selectedFile &&
                                    <MDBBtn className="btn btn-default " onClick={this.onInitFileHandler}>
                                        {this.state.order.docs.length === 0 &&
                                        <span>Add Prescription</span>
                                        }
                                        {this.state.order.docs.length > 0 &&
                                        <span>Add Another Prescription</span>
                                        }


                                    </MDBBtn>
                                    }
                                    {this.state.selectedFile &&
                                    <div><MDBBtn className="btn btn-sm btn-blue-grey" onClick={this.onClickHandlerCancel}>Cancel</MDBBtn><MDBBtn className="btn-sm " onClick={this.onClickHandler}>Upload</MDBBtn> {this.state.selectedFile.name}</div>
                                    }
                                    {this.state.uploadingFile === true &&
                                    <span> <img  style={{"width":"4rem"}} src={require('../../components/common/img/loader_new.gif')}/></span>
                                    }
                                </div>

                            </form>
                        </MDBCol>
                        <MDBCol>
                            {this.state.order.comments &&
                            <div>
                                <h5>Your Message to pharmacist</h5>
                                <h6 style={{"font-style":"italic"}}><strong> {this.state.order.comments}</strong></h6>
                            </div>
                            }
                        </MDBCol>
                    </MDBRow>
                    }
                    <MDBRow>
                        <MDBCol>
                            {this.state.order.docs.map((row, index) => (
                                <div key={index+"_doc"} className={"float-left m-1"}>
                                    {((row.indexOf(".png") > 0) || (row.indexOf(".jpg") > 0) || (row.indexOf(".jpeg") > 0)) &&
                                    <img src={config.IMAGE_HOST + row} className={"float-left"} style={{width: "100px", height: "100px"}}/>
                                    }

                                    {((row.indexOf(".pdf") > 0) || (row.indexOf(".PDF") > 0) || (row.indexOf(".doc") > 0) || (row.indexOf(".docx") > 0) || (row.indexOf(".txt") > 0) ) &&
                                    <div className={"float-left"} style={{width: "100px", height: "100px"}}><a href={config.IMAGE_HOST + row}>
                                        <MDBIcon icon="file" size="5x"/>
                                    </a></div>
                                    }
                                    <span style={{"cursor":"pointer"}} onClick={()=>this.initDeleteDoc(row)}><MDBIcon icon="trash" /></span>
                                </div>


                            ))}
                        </MDBCol>
                    </MDBRow>






                    {this.state.order.orderMode === "USER_CART" &&
                    <MDBRow className={"mt-3"}>
                        <MDBCol size={"12"}>
                            <MDBTable >
                                <MDBTableHead>
                                    <tr>
                                        <th>Sl. No.</th>
                                        <th>Product Name</th>
                                        <th>Prescription</th>
                                        <th>Amount</th>
                                        <th>Discount(%)</th>
                                        <th>Quantity</th>
                                        <th>Subtotal</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {this.state.order.subOrders.map((row, index) => (
                                        <tr key={index+"_id"}>
                                            <td>{parseInt(index) + 1}</td>
                                            <td>{row.productDetails.productName}</td>
                                            <td>
                                                {(row.productDetails.medicineType === "PRESCRIPTION DRUGS" || row.productDetails.medicineType === "Schedule H" || row.productDetails.medicineType === "Schedule H1" || row.productDetails.medicineType === "Schedule X") &&
                                                <span>Prescription Required   <a onClick={this.onInitFileHandler}
                                                                                 style={{"color": "blue"}}
                                                                                 className={"btn-link"}>Add Now</a></span>
                                                }
                                                {row.productDetails.medicineType === "OTC" &&
                                                <span>OTC</span>
                                                }

                                            </td>

                                            <td>&#8377;{row.productDetails.maxRetailPrice.toFixed(2)}</td>
                                            <td>{row.discount.toFixed(2)}</td>


                                            <td>{row.quantity}</td>


                                            <td>   &#8377;{this.subTotal(row.productDetails.maxRetailPrice, row.quantity, row.discount).toFixed("2")}</td>


                                        </tr>
                                    ))}
                                    <tr>

                                        <td colSpan={6}><strong>Total Amount</strong></td>
                                        <td><strong>&#8377;{this.state.order.totalAmount.toFixed("2")}</strong></td>
                                    </tr>
                                </MDBTableBody>
                            </MDBTable>
                        </MDBCol>
                    </MDBRow>
                    }


                    <MDBBox>
                    <MDBStepper vertical>
                        <MDBStep className="completed">
                            <a href="#!">
                                <span className="circle">1</span>
                                <span className="label">Acknowledgement</span>
                            </a>
                            <div className="ml-5 step-content grey lighten-4">
                                {(this.state.order.status === "ACKNOWLEDGED" || this.state.order.status === "PHARMACIST_APPROVED" || this.state.order.status === "ASKED_TO_UPLOAD_PRESCRIPTION" || this.state.order.status === "ASKED_TO_PAY") &&
                                    <p> Acknowledged </p>
                                }
                                {(this.state.order.status === "CREATED" || this.state.order.status === "PAID" && (this.state.order.status !== "CANCELLED")) &&
                                <p>
                                    <MDBBtn onClick={this.acknowledgeOrder}>Acknowledge</MDBBtn>
                                </p>
                                }
                            </div>
                        </MDBStep>
                        <MDBStep className="completed">
                            <a href="#!">
                                <span className="circle">2</span>
                                <span className="label">Approval</span>
                            </a>
                            <div className="ml-5 step-content grey lighten-4">
                                {(this.state.order.status === "CANCELLED") &&
                                <p> Cancelled </p>
                                }
                                {(this.state.order.status === "ACKNOWLEDGED" || this.state.order.status === "PAID" && (this.state.order.status !== "CANCELLED")) &&
                                <p>
                                    <MDBBtn onClick={this.initCancel}>Cancel Order</MDBBtn>
                                </p>
                                }
                            </div>
                        </MDBStep>
                        <MDBStep className="completed">
                            <a href="#!">
                                <span className="circle">3</span>
                                <span className="label">Routing</span>
                            </a>
                            <div className="ml-5 step-content grey lighten-4">
                                <p>Currently routing is automatic</p>
                            </div>
                        </MDBStep>
                        <MDBStep className="active">
                            <a href="#!">
                                <span className="circle">4</span>
                                <span className="label">Pharmacist Review</span>
                            </a>
                            <div className="ml-5 step-content grey lighten-4">


                                {(this.state.order.status === "ASKED_TO_UPLOAD_PRESCRIPTION") &&
                                <p> Asked to upload prescription by pharmacist </p>
                                }
                                {(this.state.order.status === "PHARMACIST_APPROVED") &&
                                <p> Pharmacist Approved </p>
                                }
                                {(this.state.order.status === "ACKNOWLEDGED" || this.state.order.status === "PAID" && (this.state.order.status !== "CANCELLED")) &&
                                <p>
                                    <MDBBtn onClick={this.askToUploadPrescription}>Ask to upload prescription</MDBBtn>
                                </p>
                                }

                                {(this.state.order.status === "ACKNOWLEDGED" || this.state.order.status === "ASKED_TO_UPLOAD_PRESCRIPTION" || this.state.order.status === "PAID" && (this.state.order.status !== "CANCELLED")) &&
                                <p>
                                    <MDBBtn onClick={this.pharmacistApproved}>Approve</MDBBtn>
                                </p>
                                }

                                {(this.state.order.status === "ACKNOWLEDGED" || this.state.order.status === "ASKED_TO_UPLOAD_PRESCRIPTION" || this.state.order.status === "PHARMACIST_APPROVED" || this.state.order.status === "PAID" && (this.state.order.status !== "CANCELLED")) &&
                                <p>
                                    {(this.state.order.status === "PHARMACIST_APPROVED") &&
                                    <MDBBtn onClick={this.processCartItem}>Process Order</MDBBtn>
                                    }
                                </p>
                                }

                            </div>
                        </MDBStep>
                        <MDBStep >
                            <a href="#!">
                                <span className="circle">5</span>
                                <span className="label">Payments</span>
                            </a>
                            <div className="ml-5 step-content grey lighten-4">
                                {(this.state.order.status === "ASKED_TO_PAY") &&
                                <p> Asked to complete the payment </p>
                                }

                                {(this.state.order.status === "CART_ITEM_PROCESSED" && (this.state.order.status !== "CANCELLED")) &&
                                <p>
                                    <MDBBtn onClick={this.askToPay}>Ask to pay</MDBBtn>
                                </p>
                                }
                            </div>
                        </MDBStep>
                        <MDBStep >
                            <a href="#!">
                                <span className="circle">6</span>
                                <span className="label">Delivery Assignment</span>
                            </a>
                        </MDBStep>
                        <MDBStep>
                            <a href="#!">
                                <span className="circle">7</span>
                                <span className="label">Status Tracking</span>
                            </a>
                        </MDBStep>
                        <MDBStep >
                            <a href="#!">
                                <span className="circle">8</span>
                                <span className="label">Fulfillment</span>
                            </a>
                        </MDBStep>


                    </MDBStepper>
                </MDBBox>


                    <div>
                        {ready && (
                            <PDFDownloadLink document={MyDocument} fileName="PDF">
                                {
                                    ({ blob, url, loading, error }) => (loading ? 'Loading document...' :
                                            <MDBBtn onClick={() => (this.setState({ ready: false }))}>
                                                download invoice
                                            </MDBBtn>
                                    )
                                }
                            </PDFDownloadLink>
                        )}
                        {!ready && (
                            <MDBBtn onClick={() => this.downloadInvoice()}>
                                generate invoice
                            </MDBBtn>
                        )}
                    </div>












                    {/*{this.state.order.orderType === "PRESCRIPTION" &&*/}
                    {/*<MDBRow className={"mb-5"}>*/}
                    {/*    <MDBCol>*/}
                    {/*        We have received your prescription. Our pharmacist will contact you to discuss availability and price.*/}
                    {/*    </MDBCol>*/}
                    {/*</MDBRow>*/}
                    {/*}*/}



                    <MDBModal isOpen={this.state.modalCommonAction} toggle={this.toggle("CommonAction")}  size="lg">
                        <MDBModalHeader toggle={this.toggle("CommonAction")} className={'black-text'}>Cancel Order</MDBModalHeader>
                        <MDBModalBody>
                            <p>
                                Are you sure you want to cancel this order?
                            </p>
                            <p>
                               <MDBInput placeholder={"Reason"} type={"textarea"}/>
                            </p>

                        </MDBModalBody>
                        <MDBModalFooter>
                            {this.state.deleting === true &&
                            <span> <img  style={{"width":"4rem"}} src={require('../../components/common/img/loader_new.gif')}/></span>
                            }
                            <MDBBtn  className={"btn btn-sm"} color={"btn-outline-info"} onClick={this.toggle("CommonAction")}>Cancel</MDBBtn>
                            <MDBBtn  disabled={this.state.deleting} className={"btn btn-sm"} onClick={()=>this.cancelOrder()}>Delete</MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>


                    {/*<MDBModal isOpen={this.state.modalDeletePrescription} toggle={this.toggle("DeletePrescription")}  size="lg">*/}
                    {/*    <MDBModalHeader toggle={this.toggle("DeletePrescription")} className={'black-text'}>Delete Prescription</MDBModalHeader>*/}
                    {/*    <MDBModalBody>*/}
                    {/*        <p>*/}
                    {/*            Are you sure you want to delete this prescription?*/}
                    {/*        </p>*/}
                    {/*        <p>*/}
                    {/*            {((this.state.currentPath.indexOf(".png") > 0) || (this.state.currentPath.indexOf(".jpg") > 0) || (this.state.currentPath.indexOf(".jpeg") > 0)) &&*/}
                    {/*            <img src={config.IMAGE_HOST + this.state.currentPath} className={"float-left"}*/}
                    {/*                 style={{width: "100px", height: "100px"}}/>*/}
                    {/*            }*/}
                    {/*        </p>*/}

                    {/*    </MDBModalBody>*/}
                    {/*    <MDBModalFooter>*/}
                    {/*        {this.state.deleting === true &&*/}
                    {/*        <span> <img  style={{"width":"4rem"}} src={require('../../components/common/img/loader_new.gif')}/></span>*/}
                    {/*        }*/}
                    {/*        <MDBBtn  className={"btn btn-sm"} color={"btn-outline-info"} onClick={this.toggle("DeletePrescription")}>Cancel</MDBBtn>*/}
                    {/*        <MDBBtn  disabled={this.state.deleting} className={"btn btn-sm"} onClick={()=>this.confirmDeleteDocument()}>Delete</MDBBtn>*/}
                    {/*    </MDBModalFooter>*/}
                    {/*</MDBModal>*/}

                </MDBContainer>
            </React.Fragment>
        );
    }



}



