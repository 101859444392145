import React from "react";
import ProductSalesAnalysisCmp from "../../../components/report/v2/productSalesAnalysis";
import {MDBBreadcrumb, MDBBreadcrumbItem} from "mdbreact";
import {Link} from "react-router-dom";

export default class ProductSalesAnalysis extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem>Reports</MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Product Sales Analysis</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <div className={"min-vh-100 p-0 m-0"}>
                    <ProductSalesAnalysisCmp/>
                </div>
            </React.Fragment>
        )
    }
}