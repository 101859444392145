import React from "react";
import {MDBBox, MDBBreadcrumb, MDBBreadcrumbItem} from "mdbreact";
import {Link} from "react-router-dom";
import EditProductNew from "../../components/product/editProductNew";

class EditProduct_New extends React.Component {
    render() {
        return(
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem><Link to={'/view-products'}>Products</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Edit Product</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <MDBBox className={"min-vh-100"}>
                    <EditProductNew queryString={this.props.match.params}/>
                </MDBBox>
            </React.Fragment>
        )
    }
}
export default EditProduct_New;