import React from "react";
import UserStore from "../../stores/userStore";
import {toast} from "react-toastify";
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCol,
    MDBContainer,
    MDBFormInline, MDBInput,
    MDBProgress,
    MDBRow
} from "mdbreact";
import OrderService from "../../services/orderService";

class EditBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            boxDetails: this.props.boxDetails,
            rackDetails: this.props.rackDetails,
            userData: {},
            boxName: this.props.boxDetails.boxName,
            boxNameError: false
        }
    }

    async componentDidMount() {
        let userData = await UserStore.fetchSessionData()
        this.setState({userData})
    }

    updateBox = async () => {
        if (this.state.boxName === "") {
            toast.error("Please enter the box name.")
            this.setState({boxNameError: true})
            return
        }
        let payload = {
            boxId: this.state.boxDetails._id,
            boxName: this.state.boxName
        }
        this.setState({progressStatus: true})
        let res = await OrderService.editBox(this.state.userData.sessionToken, payload)
        if (res) {
            toast.success("Box name updated successfully.")
            this.props.toggleEdit()
        } else {
            toast.error("Something went wrong while updating the box.")
        }
        this.setState({progressStatus: false})
    }
    validateBoxName = () => {
        (this.state.boxName === "") ? this.setState({boxNameError: true}) : this.setState({boxNameError: false})
    }

    render() {
        return (
            <React.Fragment>
                <MDBContainer>
                    <MDBCard className={"mt-2"}>
                        <MDBCardHeader>
                            <span className={"float-left"}>Edit Box</span>
                            <MDBFormInline className="float-right md-form mr-auto m-0">
                                <MDBBtn outline color="blue-grey" size="sm" onClick={this.updateBox}
                                        className="mr-auto"
                                        disabled={this.state.progressStatus}>
                                    Save
                                </MDBBtn>
                            </MDBFormInline>
                        </MDBCardHeader>
                        {this.state.progressStatus === true &&
                        <span className={'top-stick'}><MDBProgress material preloader color="cyan"/></span>
                        }
                        <MDBCardBody>
                            <MDBRow className={"my-1 p-3"}>
                                <MDBCol className={"mt-n4"}>
                                    <MDBInput id={"name"} label="Rack Name" disabled
                                              value={this.state.rackDetails[0].rackName} maxLength={"40"}/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className={"p-3"}>
                                <MDBCol className={"mt-n4"}>
                                    <MDBInput id={"boxName"} label={"Box Name *"} value={this.state.boxName}
                                              getValue={(val) => {
                                                  this.setState({boxName: val})
                                              }}
                                              className={this.state.boxNameError ? "border-danger" : ""}
                                              maxLength={"40"} onBlur={this.validateBoxName}/>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                </MDBContainer>
            </React.Fragment>
        )
    }
}

export default EditBox;