import React from "react";
import OrderService from "../../services/orderService";
import UserStore from "../../stores/userStore";
import {toast} from "react-toastify";
import {Table} from "antd";
import Moments from "moment";
import SimpleBackdrop from "../common/overlay";
class CustomerOrders extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: [],
            columns: [
                {
                    title: 'Order Code',
                    dataIndex: 'orderCode',
                    key: 'orderCode'
                },
                {
                    title: 'Order Date',
                    dataIndex: 'orderDate',
                    key: 'orderDate'
                },
            ],
            tableData: [],
            isLoading: false,
        }
    }
    async componentDidMount() {
        this.setState({isLoading: true})
        let userData = await UserStore.fetchSessionData();
        let res = await OrderService.fetchCustomerOrders(userData.sessionToken, this.props.activeItem._id)
        this.setState({isLoading: false})
        if (res) {
            let tableData = []
            for (let item of res) {
                tableData.push({
                    key: item._id,
                    orderCode: item.orderCode,
                    orderDate: Moments(item.orderDate).format("ll"),
                })
            }
            this.setState({orders: res, tableData: res})
        } else {
            toast.error("Something went wrong while fetching the orders associated to the customer.")
        }
    }

    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.isLoading}/>
                <div>
                    <Table columns={this.state.columns} dataSource={this.state.tableData}/>
                </div>
            </React.Fragment>
        );
    }
}
export default CustomerOrders;