import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  Typography,
  TextareaAutosize,
  Button,
  MenuItem,
  Select,
  InputLabel,
  Checkbox,
  FormGroup,
  FormControlLabel
} from "@material-ui/core";
import DynamicShip from "../../components/v3/dynamicShip";
import PharmacyService from "../../services/pharmacyService";
import Moments from "moment";
import { toast } from "react-toastify";

const AddStockForm = ({
  productName,
  submitForm,
  locationInfo,
  clearForm,
  shipmentDetails,
  dynamicFieldName,
  contentQuantity,
  isEmptyLoc,
}) => {
  const [locations, setLocations] = useState([]);
  const [storageData, setStorageData] = useState([
    {
      id: 0,
      productName: productName,
      storageLocation: locationInfo,
      storageFloor: [],
      storageRack: [],
      storageBox: [],
      selectedLoc: "",
      selectedFloor: "",
      selectedRack: "",
      selectedBox: "",
      barcode: `${new Date().getTime() + 123}`,
      locInfoToSend: {
        locId: "",
        floorId: "",
        rackId: "",
        boxId: "",
        barCode: `${new Date().getTime() + 123}`,
        quantityIn:1
      },
    },
  ]);
  const [formData, setFormData] = useState({
    batch: "",
    mrp: "",
    priceWithoutGST: "",
    priceWithGST: "",
    purchaseDiscount: "",
    hsnSac: "",
    mfgDate: "",
    gst: "",
    expDate: "",
    expAfterMonths: "",
    numberOfBottles: 1,
    quantity: contentQuantity,
    freeQuantity: 0,
    comment: "",
    totalQuantity: 1,
  });
  const [errorMsgs, setErroMsgs] = useState([]);
  const [errorDisplay, setErrorDisplay] = useState(false);
  const [generateSingleBarcode, setGenerateSingleBarcode] = useState(false);

  const handleChange = (event) => {
    setErrorDisplay(false);
    const { name, value, checked } = event.target;

    if (name === "generateSingleBarcode") {
      setGenerateSingleBarcode(checked);
      formGenarator(formData.numberOfBottles, checked);
    } else {
      if (name === "numberOfBottles" && parseFloat(value) <= 50) {
        if(parseFloat(formData.freeQuantity)+parseFloat(value) >50){
          return toast.error("Maximum of total rows can't be more than 50")
        }
        if( parseFloat(value)==0) return
          let input = value.replace(/[^0-9]/g, "");
          const quantityTot = parseValueToInt(input) + parseValueToInt(formData.freeQuantity);
          handleQuantForm(input, name, quantityTot);
      } else if (name === "freeQuantity" && parseFloat(value) < 40) {
        if(parseFloat(formData.numberOfBottles)+parseFloat(value) >50){
          return toast.error("Maximum of total rows can't be more than 50")
        }
        if (!isNaN(value) && value !== "") {
          let input = value.replace(/[^0-9]/g, "");
          const quantityTot = parseValueToInt(formData.numberOfBottles) + parseValueToInt(input);
          handleQuantForm(input, name, quantityTot);
        }
      } else if (name === "priceWithoutGST" || name === "gst") {
        handleAmount(value, name);
      } else {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    }
  };

  const handleQuantForm = (value, name, quantTotal) => {
    setFormData({
      ...formData,
      totalQuantity: quantTotal,
      quantity: quantTotal * contentQuantity,
      [name]: value,
    });
    formGenarator(quantTotal, generateSingleBarcode);
  };

  const handleAmount = (value, name) => {
    let gstPriceTotal;

    if (name === "priceWithoutGST") {
      const priceWithoutGST = parseFloat(value);
      const gstPercent = parseFloat(formData.gst);
      gstPriceTotal = priceWithoutGST + priceWithoutGST * (gstPercent / 100);
    } else {
      const gstPercent = parseFloat(value);
      const priceWithoutGST = parseFloat(formData.priceWithoutGST);
      gstPriceTotal = priceWithoutGST + priceWithoutGST * (gstPercent / 100);
    }

    setFormData({
      ...formData,
      [name]: value,
      priceWithGST: !isNaN(gstPriceTotal) ? gstPriceTotal.toFixed(2) : "",
    });
  };


  const formGenarator = (value, generateSingle) => {
    const timeStamp = new Date().getTime() + 122;
    let formDataFill = [];

    if (generateSingle) {
      formDataFill.push({
        id: 0,
        productName: productName,
        storageLocation: locations,
        storageFloor: [],
        storageRack: [],
        storageBox: [],
        selectedLoc: "",
        selectedFloor: "",
        selectedRack: "",
        selectedBox: "",
        barcode: `${timeStamp}`,
        locInfoToSend: {
          locId: "",
          floorId: "",
          rackId: "",
          boxId: "",
          barCode: `${timeStamp}`,
          quantityIn : parseInt(formData?.numberOfBottles)
        },
      });
    } else if(!isNaN(value)) {
      for (let i = 0; i < value; i++) {
        formDataFill.push({
          id: i,
          productName: productName,
          storageLocation: locations,
          storageFloor: [],
          storageRack: [],
          storageBox: [],
          selectedLoc: "",
          selectedFloor: "",
          selectedRack: "",
          selectedBox: "",
          barcode: `${timeStamp + i}`,
          locInfoToSend: {
            locId: "",
            floorId: "",
            rackId: "",
            boxId: "",
            barCode: `${timeStamp + i}`,
            quantityIn :1
          },
        });
      }
    }
    setStorageData(formDataFill);
  };

console.log("length",storageData?.length)
  const fetchStorageLOC = async () => {
    let storageInfo = await PharmacyService.fetchStorageLocationV2();
    if (storageInfo) {
      setLocations(storageInfo);
    }
  };

  useEffect(() => {
    fetchStorageLOC();
    if (clearForm) {
      setFormData({
        batch: "",
        mrp: "",
        priceWithoutGST: "",
        priceWithGST: "",
        purchaseDiscount: "",
        hsnSac: "",
        mfgDate: "",
        gst: "",
        expDate: "",
        expAfterMonths: "",
        numberOfBottles: 1,
        quantity: "",
        freeQuantity: 0,
        comment: "",
        totalQuantity: 1,
      });
    }
  }, [clearForm]);
  console.log({storageData})

  useEffect(() => {
    if (formData.mfgDate.length > 6 && formData.expDate.length > 6) {
      let res = calculateMonthDifference(formData.mfgDate, formData.expDate);
      // console.log({ res })
    } else {
      setFormData({
        ...formData,
        expAfterMonths: "",
      });
    }
  }, [formData.mfgDate, formData.expDate]);

  const parseValueToInt = (value) => {
    return parseInt(value, 10);
  };

  const calculateMonthDifference = (firstDate, SecondDate) => {
    const mfgMoment = Moments(firstDate, "MM/YYYY", true);
    const expMoment = Moments(SecondDate, "MM/YYYY", true);
    const currentDate = Moments(Date.now());
    // if (!mfgMoment.isValid() || !expMoment.isValid()) {
    //     return { valid: false, monthsDifference: null };
    // }
    // if (expMoment.isBefore(mfgMoment)) {
    //     return { valid: false, monthsDifference: null };
    // }

    const monthsDifference = expMoment.diff(currentDate, "months");
    setFormData({
      ...formData,
      expAfterMonths: monthsDifference + 1,
    });
    return { valid: true, monthsDifference };
  };

  const gatherPayload = (dataTable) => {
    let storageInfoToSend = [];
    let val = dataTable.map((item, key) => {
      storageInfoToSend.push(item.locInfoToSend);
    });
    const emptyFields = [];
    Object.keys(formData).forEach((key) => {
      const value = formData[key];

      if (
        value === undefined ||
        value === null ||
        (typeof value === "string" && value.trim() === "") ||
        (typeof value === "string" && parseFloat(value) < 0)
      ) {
        if (key !== "comment" && key !== "purchaseDiscount") {
          emptyFields.push({
            name: key,
            text: `Enter a valid ${key}`,
          });
        }
      }
      if (key == "mfgDate" || key == "expDate") {
        const mfgMoment = Moments(formData.mfgDate, "MM/YYYY", true);
        const expMoment = Moments(formData.expDate, "MM/YYYY", true);
        if (!mfgMoment.isValid() || !expMoment.isValid()) {
          emptyFields.push(
            {
              name: key,
              text: `Invalid date format.`,
            },
            {
              name: key,
              text: `Invalid date format.`,
            }
          );
        }
        if (mfgMoment.isAfter(expMoment)) {
          emptyFields.push({
            name: key,
            text: `mfg date can't be greater than expiry date.`,
          });
        }
        const currentDate = Moments(Date.now());
        if (expMoment.isBefore(currentDate)) {
          emptyFields.push({
            name: "expDate",
            text: `expiry date can't be from the past dates.`,
          });
        }
      }
      if (key == "mrp" || key == "priceWithoutGST") {
        if (
          parseValueToInt(formData.mrp) <
          parseValueToInt(formData.priceWithoutGST)
        ) {
          emptyFields.push({
            name: "mrp",
            text: `MRP can't be lesser than Price without GST .`,
          });
        }
      }
      if (key == "gst") {
        if (parseValueToInt(formData.gst) > 28) {
          emptyFields.push({
            name: "gst",
            text: `GST cant be greater than 28% !!`,
          });
        }
      }
      if (key == "purchaseDiscount" && formData.purchaseDiscount.length > 0) {
        if (
          parseValueToInt(formData.purchaseDiscount) > 100 ||
          parseValueToInt(formData.purchaseDiscount) < 1
        ) {
          emptyFields.push({
            name: "purchaseDiscount",
            text: `Discount percentage must be in the range of [1-100]%.`,
          });
        }
      }
      if (key == "hsnSac") {
        if (formData.hsnSac.length < 6 || formData.hsnSac.length > 9) {
          emptyFields.push({
            name: "hsnSac",
            text: `HSN/SAC length must be between 6 to 9.`,
          });
        }
      }
    });
    let filterMessages = Object.values(
      emptyFields.reduce((unique, item) => {
        if (!unique[item.name]) {
          unique[item.name] = item;
        }
        return unique;
      }, {})
    );

    const desiredOrder = [
      "batch",
      "mrp",
      "priceWithoutGST",
      "gst",
      "priceWithGST",
      "mfgDate",
      "expDate",
      "purchaseDiscount",
      "hsnSac",
      "numberOfBottles",
      "freeQuantity",
    ];
    const orderedArray = desiredOrder.map((name) =>
      filterMessages.find((obj) => obj.name === name)
    );

    if (filterMessages?.length > 0) {
      setErroMsgs(orderedArray);
      setErrorDisplay(true);
    } else submitForm(formData, storageInfoToSend);
  };

  useEffect(() => {
    setFormData({
      ...formData,
      quantity: contentQuantity,
    });
  }, [contentQuantity]);

  return (
    <Grid container className="formDesign">
      <form>
        <Grid container>
          <Grid item xs={3} className="fieldsInside">
            <TextField
              label={
                <span>
                  Batch <span className={"mandatory"}>*</span>
                </span>
              }
              name="batch"
              value={formData.batch}
              onChange={handleChange}
              placeholder="Enter batch..."
              fullWidth
            // required
            />
            <div>
              {errorDisplay && (
                <Typography
                  variant="body"
                  style={{ color: "red", fontStyle: "italic" }}
                >
                  {errorMsgs[0]?.text}
                </Typography>
              )}
            </div>
          </Grid>
          <Grid item xs={3} className="fieldsInside">
            <TextField
              label={
                <span>
                  MRP <span className={"mandatory"}>*</span>
                </span>
              }
              name="mrp"
              value={formData.mrp}
              onChange={handleChange}
              placeholder="Enter MRP..."
              type="number"
              fullWidth
            // required
            />
            <div>
              {errorDisplay && (
                <Typography
                  variant="body"
                  style={{ color: "red", fontStyle: "italic" }}
                >
                  {errorMsgs[1]?.text}
                </Typography>
              )}
            </div>
          </Grid>
          <Grid item xs={3} className="fieldsInside">
            <TextField
              label={
                <span>
                  Price (w/o GST) <span className={"mandatory"}>*</span>
                </span>
              }
              name="priceWithoutGST"
              value={formData.priceWithoutGST}
              onChange={handleChange}
              placeholder="Enter price without GST..."
              type="number"
              fullWidth
            // required
            />
            <div>
              {errorDisplay && (
                <Typography
                  variant="body"
                  style={{ color: "red", fontStyle: "italic" }}
                >
                  {errorMsgs[2]?.text}
                </Typography>
              )}
            </div>
          </Grid>
          <Grid item xs={3} className="fieldsInside">
            <InputLabel htmlFor="gst-select">
              GST <span className={"mandatory"}>*</span>
            </InputLabel>
            <Select
              style={{
                border: "1px solid #3f51b5",
                borderRadius: "4px",
                marginTop: "-6px",
              }}
              name="gst"
              value={formData.gst}
              onChange={handleChange}
              fullWidth
            >
              <MenuItem value={0}>0%</MenuItem>
              <MenuItem value={5}>5%</MenuItem>
              <MenuItem value={9}>9%</MenuItem>
              <MenuItem value={12}>12%</MenuItem>
              <MenuItem value={15}>15%</MenuItem>
              <MenuItem value={18}>18%</MenuItem>
              <MenuItem value={28}>28%</MenuItem>
            </Select>
            <div>
              {errorDisplay && (
                <Typography
                  variant="body"
                  style={{ color: "red", fontStyle: "italic" }}
                >
                  {errorMsgs[3]?.text}
                </Typography>
              )}
            </div>
          </Grid>
          <Grid item xs={3} className="fieldsInside">
            <TextField
              label="Price (w/ GST)"
              name="priceWithGST"
              value={formData.priceWithGST}
              onChange={handleChange}
              placeholder="Enter price with GST..."
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={3} className="fieldsInside">
            <TextField
              label="Purchase discount(%)"
              name="purchaseDiscount"
              value={formData.purchaseDiscount}
              onChange={handleChange}
              placeholder="Enter purchase discount..."
              type="number"
              fullWidth
            />
            <div>
              {errorDisplay && (
                <Typography
                  variant="body"
                  style={{ color: "red", fontStyle: "italic" }}
                >
                  {errorMsgs[7]?.text}
                </Typography>
              )}
            </div>
          </Grid>
          <Grid item xs={3} className="fieldsInside">
            <TextField
              label={
                <span>
                  HSN/SAC <span className={"mandatory"}>*</span>
                </span>
              }
              name="hsnSac"
              type="number"
              value={formData.hsnSac}
              onChange={handleChange}
              placeholder="Enter HSN/SAC..."
              fullWidth
            />
            <div>
              {errorDisplay && (
                <Typography
                  variant="body"
                  style={{ color: "red", fontStyle: "italic" }}
                >
                  {errorMsgs[8]?.text}
                </Typography>
              )}
            </div>
          </Grid>
          <Grid item xs={3} className="fieldsInside">
            <TextField
              label={
                <span>
                  Mfg date (MM/YYYY) <span className={"mandatory"}>*</span>
                </span>
              }
              name="mfgDate"
              value={formData.mfgDate}
              onChange={handleChange}
              placeholder="Enter manufacturing date..."
              fullWidth
            // required
            />
            <div>
              {errorDisplay && (
                <Typography
                  variant="body"
                  style={{ color: "red", fontStyle: "italic" }}
                >
                  {errorMsgs[5]?.text}
                </Typography>
              )}
            </div>
          </Grid>
          <Grid item xs={3} className="fieldsInside">
            <TextField
              label={
                <span>
                  Exp date (MM/YYYY) <span className={"mandatory"}>*</span>
                </span>
              }
              name="expDate"
              value={formData.expDate}
              onChange={handleChange}
              placeholder="Enter expiry date..."
              fullWidth
            // required
            />
            <div>
              {errorDisplay && (
                <Typography
                  variant="body"
                  style={{ color: "red", fontStyle: "italic" }}
                >
                  {errorMsgs[6]?.text}
                </Typography>
              )}
            </div>
          </Grid>
          <Grid item xs={3} className="fieldsInside">
            <TextField
              label="Exp After Months"
              name="expAfterMonths"
              value={formData.expAfterMonths}
              onChange={handleChange}
              placeholder="Enter expiration after months..."
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={3} className="fieldsInside">
            <TextField
              label={
                <span>
                  {dynamicFieldName} <span className={"mandatory"}>*</span>
                </span>
              }
              name="numberOfBottles"
              type="number"
              value={formData.numberOfBottles}
              onChange={handleChange}
              placeholder="Enter number of bottles..."
              InputProps={{ inputProps: { min: 0, max: 20 } }}
              fullWidth
            // required
            />
            <div>
              {errorDisplay && (
                <Typography
                  variant="body"
                  style={{ color: "red", fontStyle: "italic" }}
                >
                  {errorMsgs[9]?.text}
                </Typography>
              )}
            </div>
          </Grid>
          <Grid item xs={3} className="fieldsInside">
            <TextField
              label="Quantity"
              name="quantity"
              type="number"
              value={
                parseValueToInt(formData.numberOfBottles) *
                  parseValueToInt(contentQuantity) >
                  0
                  ? parseValueToInt(formData.numberOfBottles) *
                  parseValueToInt(contentQuantity)
                  : ""
              }
              onChange={handleChange}
              placeholder="Enter quantity..."
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={3} className="fieldsInside">
            <TextField
              label="Free Quantity"
              name="freeQuantity"
              type="number"
              value={formData.freeQuantity}
              onChange={handleChange}
              placeholder="Enter free quantity..."
              // inputProps={{ min: 1 }}
              fullWidth
            />
            <div>
              {errorDisplay && (
                <Typography
                  variant="body"
                  style={{ color: "red", fontStyle: "italic" }}
                >
                  {errorMsgs[10]?.text}
                </Typography>
              )}
            </div>
          </Grid>
          <Grid item xs={6} className="fieldsInside">
            <TextField
              label="Comment"
              name="comment"
              value={formData.comment}
              onChange={handleChange}
              placeholder="Enter comment..."
              type="textarea"
              multiline
              rows={6}
              fullWidth
            />
          </Grid>
          <Grid item xs={3} className="fieldsInside">
            <FormControlLabel
              control={
                <Checkbox
                  checked={generateSingleBarcode}
                  onChange={handleChange}
                  name="generateSingleBarcode"
                />
              }
              label="Generate Single Barcode"
            />
          </Grid>
          <Grid item xs={12}>
            {formData.totalQuantity > 0 && (
              <DynamicShip
                key={formData.totalQuantity}
                formData={storageData}
                productName={productName}
                gatherPayload={gatherPayload}
                errorDisplay={errorDisplay}
                shipmentDetails={shipmentDetails}
                isEmptyLoc={isEmptyLoc}
                locationInfo={locationInfo}
              />
            )}
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default AddStockForm;