import React from "react";
import {withRouter} from "react-router-dom";
import {
    MDBContainer, MDBRow, MDBInput, MDBBtn, MDBCardHeader, MDBFormInline, MDBCardBody, MDBCard, MDBCol
} from 'mdbreact';
import {toast} from "react-toastify";
import UserStore from "../../stores/userStore";
import PharmacyService from "../../services/pharmacyService";
import SimpleBackdrop from "../common/overlay";

class AddRetailPackage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            retailPackage:'',
            progressStatus:false,
            progressStatusFileUpload:false,
            retailPackageNameError:false,
            isSubComponent:false,
        }
    }
    retailPackageChange=(val)=>{
        this.setState({retailPackage:val.toLowerCase()});
    }
    addRetailPackage=async ()=>{
        window.scrollTo(0, 0)
        let userData=await UserStore.fetchSessionData();
        //validations
        if(this.state.retailPackage ==='' || this.state.retailPackage === null){
            this.setState({retailPackageNameError:true});
            return true;
        } else {
            this.setState({retailPackageNameError:false});
        }
        this.setState({progressStatus:true});
        let status=await PharmacyService.addRetailPackage(this.state,userData);
        this.setState({progressStatus:false});
        // This is to send back the parent compponent about the status
        if(this.state.isSubComponent){
            this.props.subComponentActionPerformed(status)
        }
        if(status===false){
            toast.error("Retail package already exists.");
        }else{
            this.setState({retailPackage:""});
            toast.success("Retail Package added successfully.");
        }
    }
    validateRetailPackage=()=>{
        if(this.state.retailPackage === ""){
            this.setState({retailPackageNameError:true});
        } else {
            this.setState({retailPackageNameError:false});
        }
    }
    componentDidMount() {
        if(this.props.hasOwnProperty("isSubComponent")){
            this.setState({isSubComponent:true})
        }
    }
    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.progressStatus}/>
                <MDBContainer>
                    <MDBCard className={"mt-2"}>
                        <MDBCardHeader>
                            <span className={"float-left title"}>Add Retail Packaging Unit</span>
                        </MDBCardHeader>
                        <MDBCardBody>
                            <MDBRow className="d-flex justify-content-center">
                                <MDBCol>
                                    <MDBInput label="Retail Package e.g. Strips, Bottle" onBlur={this.validateRetailPackage} className={this.state.retailPackageNameError ? "border-danger" :""} getValue={this.retailPackageChange} value={this.state.retailPackage}/>
                                </MDBCol>
                            </MDBRow>
                            <div className={'text-center'}>
                                {!this.state.isSubComponent &&
                                <MDBBtn outline size={'md'}
                                        onClick={() => this.props.history.push('/view-retail-packaging')} className="mr-auto"
                                        disabled={this.state.progressStatus}>
                                    Back
                                </MDBBtn>
                                }
                                <MDBBtn size={'md'} onClick={this.addRetailPackage} className="mr-auto"
                                        disabled={this.state.retailPackageNameError || this.state.progressStatus}>
                                    Save
                                </MDBBtn>
                            </div>
                        </MDBCardBody>
                    </MDBCard>
                </MDBContainer>
            </React.Fragment>
        );
    }
}
export default withRouter(AddRetailPackage)


