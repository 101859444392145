const dataTableOrgUser= {
    user:[
        {
            label: 'Name',
            field: 'name',
            sort: 'asc',
            width: 300
        },
        {
            label: 'Username',
            field: 'userName',
            sort: 'asc',
            width: 300
        },
        {
            label: 'Email',
            field: 'email',
            sort: 'asc',
            width: 300
        },
        {
            label: 'Phone',
            field: 'phone',
            sort: 'asc',
            width: 300
        },
        {
            label: 'Created By',
            field: 'createdBy',
            sort: 'asc',
            width: 100
        },

        {
            label: '',
            field: 'handleEdit',
            sort:'disabled',
            width: 50
        },
        {
            label: '',
            field: 'handleStatus',
            sort:'disabled',
            width: 50
        }
    ]
}
export default dataTableOrgUser;