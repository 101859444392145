import React,{Suspense} from "react";
import {
    MDBBox
} from 'mdbreact';
//const UpdateInventory=React.lazy(() => import("../../components/inventory/updateInventory"));
import UpdateInventory from "../../components/inventory/updateInventory";

export default class UpdateInventoryPage extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MDBBox className={"min-vh-100"}>
                    <Suspense fallback={null}>
                        <UpdateInventory  queryString={this.props.match.params}/>
                    </Suspense>
                </MDBBox>
            </React.Fragment>
        );
    }
}
