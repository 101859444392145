import React, { useState, useEffect } from "react";
import { Card, Table, Pagination, Button, Modal } from "antd";
import { toast } from "react-toastify";
import { MDBBreadcrumb, MDBBreadcrumbItem } from "mdbreact";
import { Link } from "react-router-dom/cjs/react-router-dom";
import ReportService from "../../../services/reportService";
import UserStore from "../../../stores/userStore";

const JobsCreation = () => {
    const [jobs, setJobs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

    useEffect(() => {
        fetchJobs();
    }, [currentPage]);

    const fetchJobs = async () => {
        setIsLoading(true);
        try {
            let userData = await UserStore.fetchSessionData();
            let jobsData = await ReportService.fetchJob(userData, { pageNo: currentPage, pageSize: 10 });
            console.log(jobsData)
            if (jobsData === false) {
                toast.error("We are facing some server issue. Please try again later.");
            } else {
                setJobs(jobsData.data);
                setTotalRecords(jobsData.totalRecords);
            }
        } catch (error) {
            console.error("Error fetching jobs:", error);
            setError("Failed to fetch jobs");
            setIsErrorModalOpen(true);
        } finally {
            setIsLoading(false);
        }
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleGenerateJobHistory = async (record) => {
        try {
            let userData = await UserStore.fetchSessionData();
            const response = await ReportService.publishJob(userData, { jobType: record.jobType });

            if (response) {
                toast.success("Job history generated successfully");
            } else {
                throw new Error("Failed to generate job history");
            }
        } catch (error) {
            console.error("Error generating job history:", error);
            setError("Failed to generate job history");
            setIsErrorModalOpen(true);
        }
    };

    const handleCloseErrorModal = () => {
        setIsErrorModalOpen(false);
    };

    const columns = [
        { title: "ID", dataIndex: "id", key: "id" },
        { title: "Job Type", dataIndex: "jobType", key: "jobType" },
        {
            title: "Action",
            dataIndex: "status",
            key: "status",
            render: (_, record) => (
                <div style={{ display: "flex", gap: "10px" }}>
                    <Button onClick={() => handleGenerateJobHistory(record)}>Run</Button>
                </div>
            ),
        },
    ];

    return (
        <div>
            <MDBBreadcrumb>
                <MDBBreadcrumbItem><Link to="/dashboard">Dashboard</Link></MDBBreadcrumbItem>
                <MDBBreadcrumbItem active>Create Job History </MDBBreadcrumbItem>
            </MDBBreadcrumb>
            <div className={"min-vh-100 p-2 m-0"}>
                <Card>
                    <Table
                        columns={columns}
                        dataSource={jobs}
                        pagination={false}
                        loading={isLoading}
                    />
                    {!isLoading && (
                        <div
                            className={"col-md-12 my-5 d-flex justify-content-center"}
                            id={"paginate"}
                        >
                            <Pagination
                                className="ant-pagination"
                                onChange={handlePageChange}
                                current={currentPage}
                                total={totalRecords}
                                pageSize={10}
                            />
                        </div>
                    )}
                </Card>
            </div>
            <Modal
                title="Error"
                visible={isErrorModalOpen}
                onCancel={handleCloseErrorModal}
                footer={[
                    <Button key="close" onClick={handleCloseErrorModal}>Close</Button>
                ]}
            >
                <p>{error}</p>
            </Modal>
        </div>
    );
};

export default JobsCreation;
