import React from "react";
import {MDBBox, MDBBreadcrumb, MDBBreadcrumbItem} from "mdbreact";
import {Link} from "react-router-dom";
import AddFloorCmp from "../../components/floors/addFloor";
class AddFloor extends React.Component{
   render() {
        return(
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem><Link to={'/view-storage-locations'}>Storage Location</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem><Link onClick={()=>this.props.history.goBack()}>Floors</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Add Floor</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <MDBBox className={"min-vh-100"}>
                    <AddFloorCmp queryString={this.props.match.params}/>
                </MDBBox>
            </React.Fragment>
        )
    }
}
export default AddFloor;