import React from "react";
import {
    MDBBox,
    MDBBtn,
    MDBRow,
    MDBCol,
    MDBIcon,
    MDBModal,
    MDBModalHeader,
    MDBModalBody,
    MDBModalFooter,
    MDBSelect,
    MDBSelectInput,
    MDBSelectOptions,
    MDBSelectOption,
    MDBInput,
} from "mdbreact";
import {toast} from 'react-toastify';
import {withRouter} from "react-router-dom";
import PharmacyService from "../../services/pharmacyService";
import UserStore from "../../stores/userStore";
import Moments from "moment";
import MultipleDocsUpload from "../common/fileUpload/MultipleDocsUpload";
import config from "../../config";
import MaterialTable from "material-table";
import SimpleBackdrop from "../common/overlay";
import {CSVLink} from "react-csv";
import GetDateRange from "../common/getDateRange";
import Selectlocation from "../location/selectLocation";

const headers = [
    {label: "Created At", key: "createdAt"},
    {label: "Type", key: "type"},
    {label: "Amount", key: "amount"},
    {label: "Payment Mode", key: "paymentMode"},
    {label: "Reference/Order/Invoice", key: "ref"},
    {label: "Entry Type", key: "entryType"},
    {label: "Dealer/Supplier/Customer name", key: "entryType"},
];

class PaymentCmp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date: Moments(),
            userData: {},
            modal: false,
            supplier: [],
            dealer: "",
            paymentMode: "",
            amount: "",
            ref: "",
            comment: "",
            records: [],
            totalCash: 0,
            debitTotal: 0,
            creditTotal: 0,
            creditCashTotal: 0,
            creditCardTotal: 0,
            creditNetbankingTotal: 0,
            creditWalletTotal: 0,
            debitCashTotal: 0,
            debitCardTotal: 0,
            debitNetbankingTotal: 0,
            debitWallerTotal: 0,
            modalBank: false,
            amountTobeTransfered: "",
            isLoading: false,
            docs: "",
            selectedLocation: !localStorage.getItem("pharmacyLocation") ? config.DEFAULT_PHARMACY_LOCATION : localStorage.getItem("pharmacyLocation"),
        }
    }

    async componentDidMount() {
        let userData = await UserStore.fetchSessionData(),
            date = Moments();
        this.setState({date, isLoading: true, userData});
        await this.refreshPage(date,date)
        let supplier = await PharmacyService.fetchGeneralSupplier(userData);
        if (supplier === false) {
            toast.error("We are facing some issue while fetching suppliers.");
        } else {
            this.setState({supplier});
        }
    }

    refreshPage = async (startDate,endDate) => {
        this.setState({isLoading: true});
        let records = await PharmacyService.fetchInwardEntried(
            this.state.userData, 
            Moments(startDate).format("DD-MM-YYYY"),
            Moments(endDate).format("DD-MM-YYYY"), 
            this.state.selectedLocation);
        this.setState({
            records: records.ledger,
            totalCash: records.totalCash,
            debitTotal: records.debitTotal,
            creditTotal: records.creditTotal,
            creditCashTotal: records.creditCashTotal,
            creditCardTotal: records.creditCardTotal,
            creditNetbankingTotal: records.creditNetbankingTotal,
            creditWalletTotal: records.creditWalletTotal,
            debitCashTotal: records.debitCashTotal,
            debitCardTotal: records.debitCardTotal,
            debitNetbankingTotal: records.debitNetbankingTotal,
            debitWallerTotal: records.debitWallerTotal,
            isLoading: false
        });
    }
    getInvoice = (rowData) => {
        return (rowData.hasOwnProperty("docs") && rowData.docs != "") ? <a href={config.IMAGE_HOST + rowData.docs} target={"_blank"}>Download</a> : ""
    }
    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    }
    addNew = () => {
        this.toggle();
    }
    getDealer = (val) => {
        this.setState({dealer: val[0]});
    }
    getPaymentMode = (val) => {
        this.setState({paymentMode: val[0]});
    }
    save = async () => {
        //validations
        if (!this.state.selectedLocation || this.state.selectedLocation === "") {
            toast.error("Location not found.");
            return false;
        }
        if (this.state.dealer === "") {
            toast.error("Please select dealer/party.");
            return false;
        }
        if (this.state.paymentMode === "") {
            toast.error("Please select payment mode.");
            return false;
        }
        if (isNaN(this.state.amount)) {
            toast.error("Please enter amount.");
            return false;
        }
        if (this.state.ref === "") {
            toast.error("Please enter reference no.");
            return false;
        }
        let payload = {
            dealer: this.state.dealer,
            paymentMode: this.state.paymentMode,
            amount: parseFloat(this.state.amount),
            ref: this.state.ref,
            comment: this.state.comment,
            docs: this.state.docs,
            location: this.state.selectedLocation
        };
        this.setState({isLoading: true});
        let data = await PharmacyService.addCashBookEntry(this.state.userData, payload);
        if (data === false) {
            toast.error("We are facing some issue. Please try again later.");
        } else {
            toast.success("Payment entry added successfully.");
            this.setState({comment: "", amount: "", dealer: "", paymentMode: "", ref: "", modal: false});
            await this.refreshPage(this.state.date,this.state.date);
        }
    }
    // prev = async () => {
    //     let dt = this.state.date.subtract(1, "days");
    //     this.setState({date: dt})
    //     await this.refreshPage(dt)
    // }
    // next = async () => {
    //     //temporary formatting just to compare dates ignoring time
    //     if (Moments(this.state.date).format("DD-MM-YYYY") < Moments().format("DD-MM-YYYY")) {
    //         let dt = this.state.date.add(1, "days");
    //         this.setState({date: dt});
    //         await this.refreshPage(dt)
    //     }
    // }
    depositToBankToggle = () => {
        this.setState({
            modalBank: !this.state.modalBank
        });
    }
    trasferToBank = async () => {
        //validations
        if (isNaN(this.state.amountTobeTransfered)) {
            toast.error("Please enter amount.");
            return false;
        }
        if (this.state.totalCash < parseFloat(this.state.amountTobeTransfered)) {
            toast.error("Please enter correct amount.");
            return false;
        }
        let payload = {
            amount: this.state.amountTobeTransfered
        };
        this.setState({isLoading: true})
        let status = await PharmacyService.addToBank(this.state.userData, payload);
        if (status === false) {
            toast.error("We are facing some issue. Please try again later.");
        } else {
            toast.success("Updated successfully.");
            this.setState({comment: "", amount: "", dealer: "", paymentMode: "", ref: "", modalBank: false});
            await this.refreshPage(this.state.date,this.state.date);
        }
    }
    initUploadCallback = () => {
        //this.setState({isLoading:true})
    }
    fileUploadCallback = (files) => {
        this.setState({docs: files, isLoading: false});
    }
    getDateRangeCallback = async (startDate, endDate) => {
        await this.refreshPage(startDate,endDate);
    }
    selectLocationCallback = async (selectedLocation) => {
        await this.setState({selectedLocation});
        await this.refreshPage(this.state.date,this.state.date);
    }
    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.isLoading}/>
                <div className={"col-3 row mx-0"}>
                    <Selectlocation 
                        selectLocationCallback={this.selectLocationCallback} 
                        selectedLocation={this.state.selectedLocation}
                    />
                </div>
                <MDBRow className={'text-center mx-0'}>
                    <div className={'col-4 border-right border-grey'}>
                        <div className="d-flex justify-content-center align-item-center">
                            <div>
                                <img src={require('../../components/common/img/counter.svg')}/> &nbsp;
                            </div>
                            <div className="text-left">
                                <b> Cash in counter</b>
                                <div style={{color:"#008FFE"}}> ₹ {this.state.totalCash.toFixed(2)}</div>
                                <MDBBtn size={'md'} onClick={() => {
                                    this.depositToBankToggle()
                                }} disabled={this.state.isLoading}>Deposit to bank</MDBBtn>
                            </div>
                        </div>
                    </div>
                    <div className={'col-4 border-right border-grey'}>
                        <div className={'d-flex justify-content-center align-item-center'}>
                            <div>
                            <img src={require('../common/img/inward.svg')}/> &nbsp;
                            </div>
                            <div className="text-left">
                              <b>Total Inward/Credit</b>
                                <div style={{color:"#51892C"}}>₹ {this.state.debitTotal.toFixed(2)}</div>
                            </div>
                        </div>
                    </div>
                    <div className={'col-4 border-right border-grey'}>
                        <div className={'d-flex justify-content-center align-item-center'}>
                            <div>
                            <img src={require('../common/img/outward.svg')}/> &nbsp;
                            </div>
                            <div className="text-left">
                                <b>Total Outward/Debit</b>
                              <div style={{color:"#FF0000"}}> ₹ {this.state.creditTotal.toFixed(2)}</div>
                            </div>
                        </div>
                    </div>
                </MDBRow>
                <div className={'col-12 row mx-0  cash-box'}>
                    <section>
                        <div className={'title'}>
                            <img src={require('../common/img/cash.svg')}/> &nbsp;
                            Credit in Cash
                        </div>
                        <div>Total Outward/Credit in Cash : <span className={'float-right'}> ₹ {this.state.creditCashTotal.toFixed(2)}</span></div>
                        <div>Total Inward/Credit in Cash :  <span className={'float-right'}> ₹ {this.state.debitCashTotal.toFixed(2)}</span></div>
                    </section>
                    <section>
                        <div className={'title'}>
                            <img src={require('../common/img/credit.svg')}/> &nbsp;
                            Credit in Card</div>
                            <div> Total Outward/Credit in Card : <span className={'float-right'}> ₹ {this.state.creditCardTotal.toFixed(2)} </span> </div>
                                <div>Total Inward/Credit in Card : <span className={'float-right'}>₹ {this.state.debitCardTotal.toFixed(2)} </span> </div>
                    </section>
                    <section>
                        <div className={'title'}>
                            <img src={require('../common/img/netbanking.svg')}/> &nbsp;
                            Credit in Netbanking</div>
                            <div> Total Outward/Credit in Netbanking : <span className={'float-right'}> ₹ {this.state.creditNetbankingTotal.toFixed(2)} </span></div>
                                <div>Total Inward/Credit in Netbanking : <span className={'float-right'}>  ₹ {this.state.debitNetbankingTotal.toFixed(2)} </span></div>
                    </section>
                    <section>
                        <div className={'title'}>
                            <img src={require('../common/img/wallet.svg')}/> &nbsp;
                            Credit in Wallet</div>
                            <div>Total Outward/Credit in Wallet : <span className={'float-right'}> ₹ {this.state.creditWalletTotal.toFixed(2)} </span> </div>
                                <div>Total Inward/Credit in Wallet : <span className={'float-right'}> ₹ {this.state.debitWallerTotal.toFixed(2)} </span> </div>
                    </section>
                </div>

                <MDBRow className={'mx-0 my-4'}>
                    <MDBCol>
                        <GetDateRange getDateRangeCallback={this.getDateRangeCallback} />
                        {/*<MDBBtn size={'sm'} onClick={() => {*/}
                        {/*    this.prev()*/}
                        {/*}} disabled={this.state.isLoading}><MDBIcon icon="chevron-left" /></MDBBtn>*/}
                        {/*<span>{this.state.date.format("MMM D, YYYY")}</span>*/}
                        {/*<MDBBtn size={'sm'}  onClick={() => {*/}
                        {/*    this.next()*/}
                        {/*}} disabled={this.state.isLoading}><MDBIcon icon="chevron-right" /></MDBBtn>*/}
                    </MDBCol>
                    <div  className={'text-right row mx-0 pr-3'}>
                        <CSVLink data={this.state.records} headers={headers}
                                 filename={"Cashbook_" + Moments(this.state.date).format("DD-MM-YYYY") + ".csv"}>
                            <MDBBtn size={'md'} disabled={this.state.isLoading}><i className="fas fa-download"></i>&nbsp;Export</MDBBtn>
                        </CSVLink>
                        <MDBBtn size={'md'} onClick={this.addNew} disabled={this.state.isLoading}>Add</MDBBtn>
                    </div>
                </MDBRow>
                <MDBBox className={'col-12'}>
                    <MaterialTable title="Cashbook"
                                   columns={[
                                       {title: 'No', field: '', render: rowData => rowData.tableData.id + 1},
                                       {
                                           title: 'Date',
                                           field: 'createdAt',
                                           render: rowData => Moments(rowData.createdAt).format("DD/MM/YYYY")
                                       },
                                       {
                                           title: 'Type',
                                           field: 'type',
                                           render: rowData => rowData.type === "IN" ? "Inward/Credit" : "Outward/Debit"
                                       },
                                       {
                                           title: 'Amount',
                                           field: 'amount',
                                           render: rowData => "₹ " + (rowData.amount).toFixed(2)
                                       },
                                       {
                                           title: 'Payment Mode',
                                           field: 'paymentMode',
                                           render: rowData => rowData.paymentMode.toUpperCase()
                                       },
                                       {title: 'Reference/Order/Invoice', field: 'ref'},
                                       {title: 'Entry Type', field: 'entryType'},
                                       {title: 'Dealer/Supplier/Customer name', field: 'entryType'},
                                       {title: 'Invoice', field: '', render: rowData => this.getInvoice(rowData)}
                                   ]}
                                   data={this.state.records}
                                   options={{
                                       showTitle: false,
                                       pageSize: 10,
                                       pageSizeOptions:[10,50,100],
                                       padding: "dense",
                                   }}/>
                </MDBBox>
                <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="lg">
                    <MDBModalHeader toggle={this.toggle}>Add new expense</MDBModalHeader>
                    <MDBModalBody>
                        <MDBRow>
                            <div className="col-12 p-1 ml-1">
                                <Selectlocation 
                                    selectLocationCallback={this.selectLocationCallback} 
                                    selectedLocation={this.state.selectedLocation}
                                    label={"Location *"} readOnly={true}
                                />
                            </div>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol>
                                <b>Dealer/Party *</b>
                                <MDBSelect getValue={this.getDealer}>
                                    <MDBSelectInput selected="Choose Dealer/Party"/>
                                    <MDBSelectOptions search={true}>
                                        <MDBSelectOption disabled>Choose Dealer/Party</MDBSelectOption>
                                        {this.state.supplier.map((item, idx) => (
                                            <MDBSelectOption value={item._id}
                                                             key={idx}>{item.supplierName}</MDBSelectOption>
                                        ))}
                                    </MDBSelectOptions>
                                </MDBSelect>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol>
                                <b>Payment Mode *</b>
                                <MDBSelect  getValue={this.getPaymentMode}>
                                    <MDBSelectInput selected="Choose Payment Mode"/>
                                    <MDBSelectOptions search={true}>
                                        <MDBSelectOption disabled>Choose Payment Mode</MDBSelectOption>
                                        <MDBSelectOption value="Cash">Cash</MDBSelectOption>
                                        <MDBSelectOption value="Netbanking">Netbanking</MDBSelectOption>
                                        <MDBSelectOption value="Card">Card</MDBSelectOption>
                                        <MDBSelectOption value="Credit">Credit</MDBSelectOption>
                                    </MDBSelectOptions>
                                </MDBSelect>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol>
                                <MDBInput label={"Amount *"} value={this.state.amount} getValue={(e) => {
                                    this.setState({amount: e})
                                }}/>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol>
                                <MDBInput label={"Reference No *"} value={this.state.ref} getValue={(e) => {
                                    this.setState({ref: e})
                                }}/>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol>
                                <MDBInput label={"Comment"} type={"textarea"} value={this.state.comment}
                                          getValue={(e) => {
                                              this.setState({comment: e})
                                          }}/>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol>
                                <MultipleDocsUpload buttonLabel={"Attach Document"}
                                                    initUploadCallback={this.initUploadCallback}
                                                    fileUploadCallback={this.fileUploadCallback}
                                                    nextProps={this.state.resetForm}></MultipleDocsUpload>
                            </MDBCol>
                        </MDBRow>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn size={'md'} outline onClick={this.toggle} disabled={this.state.isLoading}>Close</MDBBtn>
                        <MDBBtn size={'md'} onClick={() => {
                            this.save()
                        }} disabled={this.state.isLoading}>Save</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>

                <MDBModal isOpen={this.state.modalBank} toggle={this.toggle} size={"lg"}>
                    <MDBModalHeader toggle={this.depositToBankToggle}>Transfer to Bank</MDBModalHeader>
                    <MDBModalBody>
                        <MDBInput label={"Amount to be deposited"} getValue={(e) => {
                            this.setState({amountTobeTransfered: e})
                        }}/>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn onClick={this.depositToBankToggle} disabled={this.state.isLoading}>Close</MDBBtn>
                        <MDBBtn onClick={() => {
                            this.trasferToBank()
                        }} disabled={this.state.isLoading}>Save</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
            </React.Fragment>
        )
    }
}

export default withRouter(PaymentCmp);