import React from 'react';
import {
    MDBModal,
    MDBModalHeader,
    MDBModalBody,
    MDBModalFooter,
    MDBBtn,
    MDBBox,
} from 'mdbreact';
const arr = ["Location: Positra Floor 1 Floor: Ground Floor Rack: R3 Box: B1"]

const StoargeComponent = ({ isStoargeOpen = false, setStorageOpen, reviewObject, storageInfo }) => {
    const toggleReview = () => {
        setStorageOpen(false);
    };


    return (
        <MDBModal isOpen={isStoargeOpen} toggle={() => toggleReview(null)} size="lg">
            <MDBModalHeader toggle={() => toggleReview(null)}>STORAGE DETAILS</MDBModalHeader>
            <MDBModalBody>
                <>
                    {storageInfo.map(item =>
                        <>  <MDBBox>{item}</MDBBox><br /></>)}
                </>
            </MDBModalBody>
            <MDBModalFooter>
                <MDBBtn outline size="md" onClick={() => setStorageOpen(false)}>
                    Close
                </MDBBtn>
            </MDBModalFooter>
        </MDBModal>
    );
};

export default StoargeComponent;
