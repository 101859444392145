import React from "react";
import ReportService from "../../../services/reports";
import {toast} from "react-toastify";
import SimpleBackdrop from "../../common/overlay";
import Moments from "moment";
import UserStore from "../../../stores/userStore";
import MaterialTable from "material-table";

class ProductSalesAnalysis extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            progressStatus: false,
            minOrderDate: new Date(),
            maxOrderDate: new Date(),
            today: new Date(),
            months: 0,
            tableData: [],
        }
    }

    async componentDidMount() {
        let userData = await UserStore.fetchSessionData();
        this.setState({progressStatus: true})
        let res = await ReportService.productSalesAnalysis(userData.sessionToken)
        if (res) {
            this.processData(res)
            if (res.minOrderDate !== "" && res.maxOrderDate !== "" && res.durationInMonths !== "") {
                this.setState({
                    minOrderDate: res.minOrderDate,
                    maxOrderDate: res.maxOrderDate,
                    months: res.durationInMonths,
                })
            }
        } else {
            toast.error("Something went wrong while fetching the product sales analysis data.")
        }
        this.setState({progressStatus: false})
    }

    processData = (data) => {
        let tableData = []
        for (let item of data.productData) {
            let totalQuantitySold = item.totalQuantity
            if (item.isLooseSaleAllowed === "Y") {
                totalQuantitySold = item.totalQuantity / item.contentQuantity
            }
            let averageQuantitySold = (totalQuantitySold / data.durationInMonths),
                requiredAmount = (averageQuantitySold * item.mrp).toFixed(2)
            if (item._id !== null) {
                tableData.push({
                    key: item._id,
                    productName: item.productName,
                    averageQuantitySold: averageQuantitySold.toFixed(2),
                    looseSaleAllowed: item.looseSaleAllowed,
                    retailPackage: item.retailPackage,
                    contentQuantity: item.contentQuantity,
                    quantityUnit: item.quantityUnit,
                    mrp: item.mrp,
                    stock: item.stock.toFixed(2),
                    totalAmount: requiredAmount
                })
            }
        }
        this.setState({data: tableData})
    }

    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.progressStatus}/>
                <div className={"col-12 row p-0 m-0"}>
                    <div className={"col-12 mb-3"}>
                        Average sales report between <u><b>{Moments(this.state.minOrderDate).format("MMMM Do YYYY")}</b></u> to <u><b>{Moments(this.state.maxOrderDate).format("MMMM Do YYYY")}</b></u>
                        <br/>Time duration: {this.state.months} months
                    </div>
                    <div className={"col-12"}>
                        <MaterialTable title="Product Sales Analysis" columns={[
                            {title: 'Product Name', field: 'productName'},
                            {title: 'Average Quantity Sold Per Month', field: 'averageQuantitySold'},
                            {title: 'Retail Package', field: 'retailPackage'},
                            {title: 'Content Quantity', field: 'contentQuantity'},
                            {title: 'Quantity Unit', field: 'quantityUnit'},
                            {title: 'Loose Sale Allowed', field: 'looseSaleAllowed'},
                            {title: 'Available Stock', field: 'stock'},
                            {title: 'MRP in ₹ (Approx)', field: 'mrp'},
                            {title: 'Required Amount in ₹ (Approx)', field: 'totalAmount'}
                        ]}
                                       data={this.state.data}
                                       options={{
                                           toolbarButtonAlignment: "left",
                                           exportButton: true, exportAllData: true,
                                           pageSize: 10,
                                           pageSizeOptions: [10, 50, 100],
                                           exportFileName: "Product_sales_analysis_report_" + Moments(this.state.today).format("DD-MM-YYYY")
                                       }}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ProductSalesAnalysis;