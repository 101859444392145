import React,{Suspense} from "react";
import {Link} from "react-router-dom";
import {
    MDBBreadcrumbItem,
    MDBBreadcrumb, MDBBox,
} from 'mdbreact';
import SalesInsights from "../../components/report/salesIntelligence"

export default class SalesInsightPage extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem><Link to={'/download-reports'}>Reports</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Sales Insights</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <MDBBox className={"min-vh-100"}>
                    <Suspense fallback={null}>
                        <SalesInsights/>
                    </Suspense>
                </MDBBox>
            </React.Fragment>
        );
    }
}