import React from "react";
import {withRouter} from "react-router-dom";
import {
    MDBBox, MDBBtn, MDBModalHeader, MDBModalBody, MDBModal, MDBModalFooter,
    MDBTableHead, MDBTableBody, MDBTable, MDBRow, MDBCol, MDBInput, MDBSelect
} from 'mdbreact';
import UserStore from "../../stores/userStore";
import {toast} from "react-toastify";
import OrderService from "../../services/orderService";
import Moments from "moment";
import config from "../../config";
import utility from "../../utility/utility";
import SimpleBackdrop from "../common/overlay";
import EditOrder from "./editOrder";
import GetDateRange from "../common/getDateRange";
import OrderInvoice from "./orderInvoice";
import orgConfig from "../../orgConfig";
import MaterialTable from "material-table";
import {CSVLink} from "react-csv";
import ReportService from "../../services/reports";
import Selectlocation from "../location/selectLocation";

const headers = [
    {label: "Order Date", key: "createdAt"},
    {label: "Order Id", key: "orderCode"},
    {label: "Customer Name", key: "customerDetails.name"},
    {label: "Customer Phone", key: "customerDetails.contactNo"},
    {label: "Total Items", key: "products.length"},
    {label: "Outstanding", key: "balanceAmount"},
    {label: 'Type', key: 'orderMode'},
    {label: 'Initial Payment Mode', key: 'paymentMode'},
    {label: 'Total', key: 'totalAmount'},
]

class ManageOrderHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedProviderAddress: "1st Floor, Lakhi Mandir Complex, Beltola, Guwahati, Assam, 781028.  Call: 03612302334",
            orgId: 0,
            userData: {},
            progressStatus: false,
            type: "Today",
            page: 1,
            pageSize: 10,
            orders: [],
            data: {},
            modal: false,
            modalOrderDetails: false,
            activeOrder: {
                cart: [{cartData: []}], docs: [], products: [], paymentLog: []
            },
            isActiveOrderAPrescriptionDrug: false,
            orderToBeDeleted: {},
            modalDeleteOrder: false,
            modalCancelOrder: false,
            orderToBePaid: {
                paymentLog: []
            },
            modalPayOutstanding: false,
            startDate: new Date(),
            endDate: new Date(),
            totalFilteredTransactions: 0,
            totalFilteredTransactionsAmount: 0,
            totalFilteredCreditTransactions: 0,
            totalFilteredTransactionsAmountPending: 0,
            totalAmount: 0,
            amountCollected: 0,
            changeReturn: 0,
            paymentModes: [{text: "Cash", value: "CASH", checked: true}, {
                text: "Card",
                value: "CARD"
            }, {text: "Netbanking", value: "NETBANKING"}, {text: "Wallet", value: "WALLET"}],
            paymentMode: "CASH",
            modalEditOrder: false,
            modalLB: false,
            activeImage: "",
            disableRetreatAfterSorting: false,
            modalPaymentLog: false,
            totalOnlineTransactionAmount: 0,
            totalCashTransactionAmount: 0,
            totalCreditAmount: 0,

            totalCashTransactions: 0,
            totalOnlineTransactions: 0,
            totalTelephonicOrders: 0,
            totalPayOnDelivery: 0,
            totalPayOnDeliveryCash: 0,
            TotalPayOnDeliveryOnline: 0,
            TotalPayOnDeliveryCredit: 0,
            selectedLocation: !localStorage.getItem("pharmacyLocation") ? config.DEFAULT_PHARMACY_LOCATION : localStorage.getItem("pharmacyLocation"),
        }
    }

    async componentDidMount() {
        let userData = await UserStore.fetchSessionData();
        this.setState({userData: userData})
        if (userData.type === "platformAdmin") {
            this.setState({orgId: userData._id})
        } else if (userData.type === "ORG_ADMIN") {
            this.setState({orgId: userData.orgDetails[0]._id})
        }
        await this.fetchData(Moments(this.state.startDate).format("YYYY-MM-DD"), Moments(this.state.endDate).format("YYYY-MM-DD"));
    }

    fetchData = async (startDate, endDate) => {
        await this.setState({disableRetreatAfterSorting: true});
        window.scrollTo(0, 0);
        this.setState({progressStatus: true});
        let data = await OrderService.fetchOfflineOrder(this.state, startDate, endDate);
        this.setState({progressStatus: false});
        if (data === false) {
            toast.error("We are facing some issue. Please try again later.");
        } else {
            this.setState({orders: data});
            let subtotal = 0, totalOrder = 0, creditOrders = 0, pendingAmount = 0, creditAmount = 0,
                totalCashTransactions = 0, cashAmount = 0, totalOnlineTransactions = 0, cardAmount = 0,
                netbankingAmount = 0, walletAmount = 0,
                totalTelephonicOrders = 0, totalPayOnDelivery = 0, totalPayOnDeliveryCash = 0,
                TotalPayOnDeliveryOnline = 0, TotalPayOnDeliveryCredit = 0;
            for (let order of this.state.orders) {
                //calculation for total amount
                subtotal = subtotal + order.totalAmount;
                //calculation for total order
                totalOrder++;
                //calculation for total cash, card, netbanking, wallet amount
                if (order.paymentMode === "CASH") {
                    ++totalCashTransactions;
                    cashAmount = cashAmount + order.totalAmount;
                } else if (order.paymentMode === "CARD") {
                    ++totalOnlineTransactions;
                    cardAmount = cardAmount + order.totalAmount;
                } else if (order.paymentMode === "NETBANKING") {
                    ++totalOnlineTransactions;
                    netbankingAmount = netbankingAmount + order.totalAmount
                } else if (order.paymentMode === "WALLET") {
                    ++totalOnlineTransactions;
                    walletAmount = walletAmount + order.totalAmount;
                } else if (order.paymentMode === "CREDIT") {
                    ++creditOrders;
                    creditAmount = creditAmount + order.totalAmount
                    pendingAmount = pendingAmount + order.balanceAmount
                }
                //calculation for pay on delivery
                if (order.orderMode === "TELEPHONIC") {
                    ++totalTelephonicOrders;
                    totalPayOnDelivery = totalPayOnDelivery + order.totalAmount
                    if (order.paymentMode === "CARD" || order.paymentMode === "NETBANKING" || order.paymentMode === "WALLET") {
                        TotalPayOnDeliveryOnline = TotalPayOnDeliveryOnline + order.totalAmount
                    } else if (order.paymentMode === "CREDIT") {
                        TotalPayOnDeliveryCredit = TotalPayOnDeliveryCredit + order.totalAmount
                    } else if (order.paymentMode === "CASH") {
                        totalPayOnDeliveryCash = totalPayOnDeliveryCash + order.totalAmount
                    }
                }
            }
            this.setState({
                totalFilteredTransactions: totalOrder,
                totalFilteredTransactionsAmount: subtotal,

                totalOnlineTransactions,
                totalOnlineTransactionAmount: cardAmount + netbankingAmount + walletAmount,

                totalCashTransactions,
                totalCashTransactionAmount: cashAmount,

                totalFilteredCreditTransactions: creditOrders,
                totalFilteredTransactionsAmountPending: pendingAmount,
                totalCreditAmount: creditAmount,

                totalTelephonicOrders,
                totalPayOnDelivery,
                totalPayOnDeliveryCash,
                TotalPayOnDeliveryOnline,
                TotalPayOnDeliveryCredit,
            })
            await this.setState({disableRetreatAfterSorting: false});
        }
    }

    onEdit = (item) => {
        this.setState({activeOrder: item})
        this.toggleEditOrder()
    }

    toggleEditOrder = () => {
        this.setState({
            modalEditOrder: !this.state.modalEditOrder
        });
    }

    onEditComplete = () => {
        this.toggleEditOrder()
    }

    onView = (item, request) => {
        this.setState({isActiveOrderAPrescriptionDrug: false})
        let newOrder = item
        for (let i = 0; i < newOrder.products.length; i++) {
            if (newOrder.products[i].medicineType.trim() === "OTC" || newOrder.products[i].medicineType.trim() === "Schedule X" || newOrder.products[i].medicineType.trim() === "Schedule H" || newOrder.products[i].medicineType.trim() === "Schedule H1" || newOrder.products[i].medicineType.trim() === "PRESCRIPTION DRUG" || newOrder.products[i].medicineType.trim() === "PRESCRIPTION DRUGS") {
                this.setState({isActiveOrderAPrescriptionDrug: true})
            }
            newOrder.products[i].calculatedContentQuantity = utility.calculateContentQuantity(newOrder.products[i].contentQuantity, newOrder.products[i].looseSaleAllowed)
        }
        this.setState({activeOrder: newOrder})
        if (request === "paymentLog") {
            this.togglePaymentLog()
        } else {
            this.toggleOrderDetails()
        }
    }

    initDelete = (item) => {
        this.setState({orderToBeDeleted: item})
        this.toggleDeleteOrder()
    }

    initCancel = (item) => {
        this.setState({orderToBeDeleted: item})
        this.toggleCancelOrder()
    }

    initPayOutstanding = (item) => {
        this.setState({orderToBePaid: item})
        this.togglePayOutstanding()

    }

    confirmDelete = async () => {
        this.setState({progressStatus: true})
        let data = await OrderService.deleteOfflineOrder(this.state);
        this.setState({progressStatus: false})
        if (data) {
            toast.success("Order deleted.")
            this.toggleDeleteOrder()
            this.fetchData(Moments(this.state.startDate).format("YYYY-MM-DD"), Moments(this.state.endDate).format("YYYY-MM-DD"))
        } else {
            toast.success("Something went wrong.")
        }
    }

    confirmCancel = async () => {
        this.setState({progressStatus: true})
        let data = await OrderService.cancelOfflineOrder(this.state);
        this.setState({progressStatus: false})
        if (data) {
            toast.success("Order cancelled.")
            this.toggleCancelOrder()
            this.fetchData(Moments(this.state.startDate).format("YYYY-MM-DD"), Moments(this.state.endDate).format("YYYY-MM-DD"))
        } else {
            toast.success("Something went wrong.")
        }
    }

    confirmPayOutstanding = async () => {
        if (!this.state.paidAmount || this.state.paidAmount <= 0) {
            toast.error("Please enter the paid amount.")
            return false;
        }
        this.setState({progressStatus: true})
        let data = await OrderService.payOutStanding(this.state);
        this.setState({progressStatus: false})
        if (data) {
            toast.success("Order updated.")
            this.togglePayOutstanding()
            this.fetchData(Moments(this.state.startDate).format("YYYY-MM-DD"), Moments(this.state.endDate).format("YYYY-MM-DD"))
            await this.setState({paidAmount: 0, amountCollected: 0, changeReturn: 0})
        } else {
            toast.error("Something went wrong.")
        }
    }

    // This toggle opens or closes the delete shipment modal
    toggleOrderDetails = () => {
        this.setState({
            modalOrderDetails: !this.state.modalOrderDetails
        });
    }

    toggleDeleteOrder = () => {
        this.setState({
            modalDeleteOrder: !this.state.modalDeleteOrder
        });
    }

    toggleCancelOrder = () => {
        this.setState({
            modalCancelOrder: !this.state.modalCancelOrder
        });
    }

    togglePayOutstanding = () => {
        this.setState({
            modalPayOutstanding: !this.state.modalPayOutstanding
        });
    }

    handlePaidAmount = async (e) => {
        if (!utility.isNumericTwoDecimalPartial(e.target.value) && e.target.value !== "") {
            return false;
        }
        await this.setState({paidAmount: e.target.value})
        if (this.state.amountCollected > 0) {
            this.setState({changeReturn: (this.state.amountCollected - this.state.paidAmount)})
        }
    };

    handleAmountCollected = (e) => {
        if (!utility.isNumericTwoDecimalPartial(e.target.value) && e.target.value !== "") {
            return false;
        }
        this.setState({amountCollected: e.target.value})
        let amountCollected = e.target.value;
        let balanceAmount = this.state.orderToBePaid.balanceAmount
        if (amountCollected && amountCollected >= 0) {
            let ra = amountCollected - balanceAmount;
            if (ra > 0) {
                this.setState({changeReturn: ra.toFixed("2")})
            } else {
                this.setState({changeReturn: 0})
            }
        } else {
            this.setState({changeReturn: 0})
        }
        if (amountCollected >= balanceAmount) {
            this.setState({paidAmount: balanceAmount.toFixed("2")})
        } else {
            if (amountCollected > 0) {
                this.setState({paidAmount: parseFloat(amountCollected).toFixed("2")})
            }
        }
    };

    handleChangeReturn = (e) => {
        if (!utility.isNumericTwoDecimalPartial(e.target.value) && e.target.value !== "") {
            return false;
        }
        this.setState({changeReturn: e.target.value})
    };

    handlePaymentMode = (e) => {
        this.setState({paymentMode: e[0]})
        if (e[0] === "WALLET" || e[0] === "NETBANKING" || e[0] === "CARD") {
            this.setState({paidAmount: this.state.totalAmount})
        }
    }

    onDownloadImage = (row) => {
        this.setState({activeImage: row})
        this.toggleLB()
    }

    // This toggle opens or closes the custom lightbox
    toggleLB = () => {
        this.setState({
            modalLB: !this.state.modalLB
        });
    }

    getDateRangeCallback = async (startDate, endDate) => {
        if (startDate > endDate) {
            toast.info("Start date is greater than the end date. Please adjust the date range again.")
            return
        }
        this.setState({startDate: startDate, endDate: endDate})
        let endDateThreeMonthGap = Moments(startDate).add(2, "months");
        if (endDate > endDateThreeMonthGap) {
            let payload = {
                startDate: Moments(startDate).format("YYYY-MM-DD"),
                endDate: Moments(endDate).format("YYYY-MM-DD"),
                section: "OFFLINE_ORDER"
            }
            //Create a record request
            this.setState({progressStatus: true})
            let res = await ReportService.requestCreateReport(this.state.userData.sessionToken, payload)
            this.setState({progressStatus: false})
            if (res) {
                toast.success("A request has been raised to create a report for the specified time duration with the name, "
                    + "OFFLINE_ORDER_" + Moments(startDate).format("YYYY-MM-DD") + "_" + Moments(endDate).format("YYYY-MM-DD") + "_" + res._id + ".csv")
            } else {
                toast.error("Something went wrong while requesting the report for the duration " + Moments(startDate).format("YYYY-MM-DD") + " and "
                    + Moments(endDate).format("YYYY-MM-DD"))
            }
            return false
        }
        await this.fetchData(Moments(startDate).format("YYYY-MM-DD"), Moments(endDate).format("YYYY-MM-DD"))
    }
    togglePaymentLog = () => {
        this.setState({modalPaymentLog: !this.state.modalPaymentLog})
    }
    selectLocationCallback = async (selectedLocation) => {
        await this.setState({selectedLocation});
        await this.fetchData(Moments(this.state.startDate).format("YYYY-MM-DD"), Moments(this.state.endDate).format("YYYY-MM-DD"));
    }

    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.progressStatus}/>
                <MDBBox>
                    <div className={"mt-n4"}>
                        <div>
                            <div className={"col-3 row mx-0"}>
                                <Selectlocation 
                                    selectLocationCallback={this.selectLocationCallback} 
                                    selectedLocation={this.state.selectedLocation}
                                />
                            </div>
                            <div className={"col-12 row mx-0 order-box"}>
                                <section>
                                    <div className={'title'}>Total Orders</div>
                                    <div className={'order'}
                                         style={{color: "#74B353"}}>{this.state.totalFilteredTransactions}</div>
                                    <div>Total Amount: <span className={'float-right'}><span
                                        dangerouslySetInnerHTML={{__html: config.CURRENCY_HTML}}></span> {this.state.totalFilteredTransactionsAmount.toFixed("2")}</span>
                                    </div>
                                </section>
                                <section>
                                    <div className={'title'}>Total Cash Orders</div>
                                    <div className={'order'}
                                         style={{color: "#0094FF"}}>{this.state.totalCashTransactions}</div>
                                    <div>Total Cash Amount:<span className={'float-right'}> <span
                                        dangerouslySetInnerHTML={{__html: config.CURRENCY_HTML}}></span> {this.state.totalCashTransactionAmount.toFixed("2")}</span>
                                    </div>
                                </section>
                                <section>
                                    <div className={'title'}>Total Online Payment Orders</div>
                                    <div className={'order'}
                                         style={{color: "#0094FF"}}>{this.state.totalOnlineTransactions}</div>
                                    <div>Card + Netbanking + Wallet: <span className={'float-right'}> <span
                                        dangerouslySetInnerHTML={{__html: config.CURRENCY_HTML}}></span> {this.state.totalOnlineTransactionAmount.toFixed("2")}</span>
                                    </div>
                                </section>
                                <section>
                                    <div className={'title'}>Total Pay On Delivery Orders</div>
                                    <div className={'order'}
                                         style={{color: "#FF008A"}}>{this.state.totalTelephonicOrders}</div>
                                    <div>Total Amount: <span className={'float-right'}> <span
                                        dangerouslySetInnerHTML={{__html: config.CURRENCY_HTML}}></span> {this.state.totalPayOnDelivery.toFixed("2")}</span>
                                    </div>
                                    <div>Cash: <span className={'float-right'}> <span
                                        dangerouslySetInnerHTML={{__html: config.CURRENCY_HTML}}></span> {this.state.totalPayOnDeliveryCash.toFixed("2")}</span>
                                    </div>
                                    <div>Card + Netbanking + Wallet: <span className={'float-right'}><span
                                        dangerouslySetInnerHTML={{__html: config.CURRENCY_HTML}}></span> {this.state.TotalPayOnDeliveryOnline.toFixed("2")}</span>
                                    </div>
                                    <div>On Credit: <span className={'float-right'}> <span
                                        dangerouslySetInnerHTML={{__html: config.CURRENCY_HTML}}></span> {this.state.TotalPayOnDeliveryCredit.toFixed("2")}</span>
                                    </div>
                                </section>
                                <section>
                                    <div className={'title'}>Orders on Credit</div>
                                    <div className={'order'}
                                         style={{color: "#0094FF"}}>{this.state.totalFilteredCreditTransactions}</div>
                                    <div>Credit Amount: <span className={'float-right'}> <span
                                        dangerouslySetInnerHTML={{__html: config.CURRENCY_HTML}}></span> {this.state.totalCreditAmount.toFixed("2")}</span>
                                    </div>
                                    <div>Credit Pending: <span className={'float-right'}><span
                                        dangerouslySetInnerHTML={{__html: config.CURRENCY_HTML}}></span> {this.state.totalFilteredTransactionsAmountPending.toFixed("2")}</span>
                                    </div>
                                </section>
                            </div>
                        </div>
                        <div>
                            <div className={"col-12 row mx-0 my-4"}>
                                <div className={'col-md-9 p-0'}>
                                    <GetDateRange getDateRangeCallback={this.getDateRangeCallback}/>
                                </div>
                                <div className={'col-md-3 text-right p-0'}>
                                    <CSVLink data={this.state.orders} headers={headers} target="_blank"
                                             filename={"Offline_orders_" + Moments(this.state.startDate).format("MMM, YYYY") + "_" + Moments(this.state.endDate).format("MMM, YYYY") + ".csv"}>
                                        <MDBBtn size={'md'} disabled={this.state.progressStatus}><i
                                            className="fas fa-download"></i>&nbsp;Export</MDBBtn>
                                    </CSVLink>
                                </div>
                            </div>
                            <MaterialTable
                                columns={[
                                    {
                                        title: "Action", field: "", render: rowData =>
                                            <div>
                                                <span color="primary" className={"blue-text btn-link cursor-pointer"}
                                                      size="sm" onClick={() => {
                                                    this.onView(rowData)
                                                }}>View </span>
                                                {rowData.orderStatus === "COMPLETED" &&
                                                <>
                                                    <span color="primary"
                                                          className={"blue-text btn-link cursor-pointer"} size="sm"
                                                          onClick={() => {
                                                              this.onEdit(rowData)
                                                          }}> | Edit</span>
                                                    <span color="primary"
                                                          className={"blue-text btn-link cursor-pointer"} size="sm"
                                                          onClick={() => {
                                                              this.initDelete(rowData)
                                                          }}> | Delete</span>
                                                    {(rowData.balanceAmount > 0.09 && rowData.isPaymentPending === "Y") &&
                                                    <span color="default"
                                                          className={"blue-text btn-link cursor-pointer"} size="sm"
                                                          onClick={() => {
                                                              this.initPayOutstanding(rowData)
                                                          }}> | Receive Payment</span>
                                                    }
                                                    {(rowData.balanceAmount > 0 && rowData.balanceAmount < 1 && rowData.isPaymentPending === "Y") &&
                                                    <span color="default"
                                                          className={"blue-text btn-link cursor-pointer"} size="sm"
                                                          onClick={() => {
                                                              this.initPayOutstanding(rowData)
                                                          }}> | Mark as paid</span>
                                                    }
                                                </>
                                                }
                                            </div>
                                    },
                                    {
                                        title: 'Order Date',
                                        field: 'orderDate',
                                        render: rowData => utility.convertToReadbleDateTimeUtc(rowData.orderDate)
                                    },
                                    {title: 'Order #', field: 'orderCode'},
                                    {title: 'Customer Name', field: 'customerDetails.name'},
                                    {
                                        title: 'Customer Phone',
                                        field: 'customerDetails.contactNo',
                                        render: rowData => rowData.customerDetails.contact_no ? rowData.customerDetails.contact_no : rowData.customerDetails.contactNo
                                    },
                                    {
                                        title: 'Total Items',
                                        field: 'products',
                                        render: rowData => rowData.hasOwnProperty("products") ? rowData.products.length : "NA"
                                    },
                                    {
                                        title: 'Outstanding(' + orgConfig.CURRENCY_SYMBOL + ")",
                                        field: 'balanceAmount',
                                        render: rowData => parseFloat(rowData.balanceAmount.toFixed(2))
                                    },
                                    {title: 'Type', field: 'orderMode'},
                                    {title: 'Initial Payment Mode', field: 'paymentMode'},
                                    {
                                        title: 'Payment History',
                                        field: '',
                                        render: rowData => <div color="primary"
                                                                className={"blue-text btn-link cursor-pointer"}
                                                                size="sm"
                                                                onClick={() => this.onView(rowData, "paymentLog")}>{rowData.hasOwnProperty("paymentLog") && rowData.paymentLog.length > 0 ? "View" : ""}</div>
                                    },
                                    {
                                        title: 'Total(' + orgConfig.CURRENCY_SYMBOL + ")",
                                        field: 'totalAmount',
                                        render: rowData => parseFloat(rowData.totalAmount.toFixed(2))
                                    }
                                ]}
                                data={this.state.orders}
                                options={{
                                    showTitle: false,
                                    pageSize: 10,
                                    pageSizeOptions: [10, 50, 100],
                                    emptyRowsWhenPaging: false,
                                }}
                            />
                        </div>
                    </div>
                    <MDBModal isOpen={this.state.modalOrderDetails} toggle={this.toggleOrderDetails} size={"fluid"}>
                        <MDBModalHeader toggle={this.toggleOrderDetails}></MDBModalHeader>
                        <MDBModalBody className={"float-center"}>
                            <OrderInvoice activeOrder={this.state.activeOrder}
                                          isActiveOrderAPrescriptionDrug={this.state.isActiveOrderAPrescriptionDrug}/>
                        </MDBModalBody>
                    </MDBModal>
                    <MDBModal isOpen={this.state.modalDeleteOrder} toggle={this.toggleDeleteOrder}>
                        <MDBModalHeader toggle={this.toggleDeleteOrder}>Delete Order</MDBModalHeader>
                        <MDBModalBody>
                            <div className={"mb-3"}>
                                Are you sure you want to delete this order?
                            </div>
                            <div>
                                Order#: {this.state.orderToBeDeleted.orderCode}
                            </div>
                            {this.state.orderToBeDeleted.hasOwnProperty("totalAmount") &&
                            <div>
                                Total Amount: <span
                                dangerouslySetInnerHTML={{__html: config.CURRENCY_HTML}}></span> {this.state.orderToBeDeleted.totalAmount}
                            </div>
                            }
                            {this.state.orderToBeDeleted.hasOwnProperty("orderDate") &&
                            <div>
                                Order Date: {utility.convertToReadbleDateTimeUtc(this.state.orderToBeDeleted.orderDate)}
                            </div>
                            }
                            {(this.state.orderToBeDeleted.hasOwnProperty("customerDetails") && this.state.orderToBeDeleted.customerDetails.hasOwnProperty("name")) &&
                            <div>
                                Customer Name: {this.state.orderToBeDeleted.customerDetails.name}
                            </div>
                            }
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn disabled={this.state.progressStatus} color="primary"
                                    onClick={this.confirmDelete}>Delete</MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>
                    <MDBModal isOpen={this.state.modalCancelOrder} toggle={this.toggleCancelOrder}>
                        <MDBModalHeader toggle={this.toggleCancelOrder}>Cancel Order</MDBModalHeader>
                        <MDBModalBody>
                            <div className={"mb-3"}>
                                Are you sure you want to cancel this order?
                            </div>
                            <div>
                                Order#: {this.state.orderToBeDeleted.orderCode}
                            </div>
                            {this.state.orderToBeDeleted.hasOwnProperty("totalAmount") &&
                            <div>
                                Total Amount: <span
                                dangerouslySetInnerHTML={{__html: config.CURRENCY_HTML}}></span> {this.state.orderToBeDeleted.totalAmount}
                            </div>
                            }
                            {this.state.orderToBeDeleted.hasOwnProperty("orderDate") &&
                            <div>
                                Order Date: {utility.convertToReadbleDateTimeUtc(this.state.orderToBeDeleted.orderDate)}
                            </div>
                            }
                            {(this.state.orderToBeDeleted.hasOwnProperty("customerDetails") && this.state.orderToBeDeleted.customerDetails.hasOwnProperty("name")) &&
                            <div>
                                Customer Name: {this.state.orderToBeDeleted.customerDetails.name}
                            </div>
                            }
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn disabled={this.state.progressStatus} color="primary"
                                    onClick={this.confirmCancel}>Cancel</MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>
                    <MDBModal isOpen={this.state.modalPayOutstanding} toggle={this.togglePayOutstanding}>
                        <MDBModalHeader toggle={this.togglePayOutstanding}>Pay Outstanding</MDBModalHeader>
                        <MDBModalBody>
                            <div className={'grey-bg p-2 mb-2'}>

                            <div className={"mb-3 title"}>
                                Order Details:
                            </div>
                            <p className={'my-1'}>
                                Order#
                                <span className={'float-right font-weight-bold'}>{this.state.orderToBePaid.orderCode}</span>
                            </p>

                            {this.state.orderToBePaid.hasOwnProperty("orderDate") &&
                            <p className={'my-1'}>
                                Order Date
                                <span className={'float-right font-weight-bold'}>
                                    {utility.convertToReadbleDateTimeUtc(this.state.orderToBePaid.orderDate)}
                                </span>
                            </p>
                            }
                            {(this.state.orderToBePaid.hasOwnProperty("customerDetails") && this.state.orderToBePaid.customerDetails.hasOwnProperty("name")) &&
                            <p className={'my-1'}>
                                Customer Name
                                <span className={'float-right font-weight-bold'}>
                                    {this.state.orderToBePaid.customerDetails.name}
                                </span>
                            </p>
                            }
                            {this.state.orderToBePaid.hasOwnProperty("totalAmount") &&
                            <p className={'my-1'}>
                                Total Amount:
                                <span className={'float-right font-weight-bold'}>
                                <span
                                dangerouslySetInnerHTML={{__html: config.CURRENCY_HTML}}></span> {this.state.orderToBePaid.totalAmount}
                                </span>

                            </p>
                            }
                            {this.state.orderToBePaid.hasOwnProperty("balanceAmount") &&
                            <p className={'my-1'}>
                                Outstanding Amount:
                                <span className={'float-right font-weight-bold'}>
                                <span
                                dangerouslySetInnerHTML={{__html: config.CURRENCY_HTML}}></span> {this.state.orderToBePaid.balanceAmount.toFixed("2")}
                                </span>
                            </p>
                            }

                            </div>
                            {this.state.orderToBePaid.paymentLog.length > 0 &&
                            <MDBTable className={"col-12"}>
                                <MDBTableHead>
                                    <tr>
                                        <th>#</th>
                                        <th>Amount</th>
                                        <th>Paid On</th>
                                        <th>Payment Mode</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {this.state.orderToBePaid.paymentLog.map((row, index) => (
                                        <tr key={"orderTobePaid" + index}>
                                            <td>{index + 1}</td>
                                            <td><span
                                                dangerouslySetInnerHTML={{__html: config.CURRENCY_HTML}}></span> {row.amount.toFixed("2")}
                                            </td>
                                            <td>{utility.convertToReadbleDateTimeUtc(row.paidAt)}</td>
                                            <td>{row.paymentMode}</td>
                                        </tr>
                                    ))}
                                </MDBTableBody>
                            </MDBTable>
                            }
                            <div className={'pt-4 pb-2'}>
                            <MDBSelect outline={true} label={"Payment Mode"} getValue={this.handlePaymentMode}
                                       options={this.state.paymentModes}></MDBSelect>
                            </div>
                            <MDBRow>
                                <MDBCol size={"4"}>
                                    <MDBInput label={"Amount Collected"} value={this.state.amountCollected}
                                              onChange={this.handleAmountCollected}></MDBInput>
                                </MDBCol>
                                <MDBCol size={"4"}>
                                    <MDBInput label={"Change Returned"} value={this.state.changeReturn}
                                              onChange={this.handleChangeReturn}></MDBInput> </MDBCol>
                                <MDBCol size={"4"}>
                                    <MDBInput label={"Amount Paid"} value={this.state.paidAmount}
                                              onChange={this.handlePaidAmount}></MDBInput>
                                </MDBCol>
                            </MDBRow>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn size={'md'} disabled={this.state.progressStatus} color="default"
                                    onClick={this.confirmPayOutstanding}>Update Payment</MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>
                    <MDBModal isOpen={this.state.modalEditOrder} toggle={this.toggleEditOrder} size={"fluid"}>
                        <MDBModalHeader toggle={this.toggleEditOrder}></MDBModalHeader>
                        <MDBModalBody className={"float-center"}>
                            <EditOrder activeOrder={this.state.activeOrder}
                                       onActionComplete={this.onEditComplete}></EditOrder>
                        </MDBModalBody>
                    </MDBModal>
                    <MDBModal isOpen={this.state.modalLB} toggle={this.toggleLB} size={"lg"}>
                        <MDBModalHeader toggle={this.toggleLB}></MDBModalHeader>
                        <MDBModalBody className={"float-center"}>
                            <div style={{"textAlign": "center"}}>
                                <img src={config.IMAGE_HOST + this.state.activeImage} className={"float-center"}
                                     style={{maxWidth: "440px", maxHeight: "440px"}} alt={""}/>
                            </div>
                        </MDBModalBody>
                    </MDBModal>
                    <MDBModal isOpen={this.state.modalPaymentLog} toggle={this.togglePaymentLog} size={"lg"}>
                        <MDBModalHeader toggle={this.togglePaymentLog}></MDBModalHeader>
                        <MDBModalBody>
                            {this.state.activeOrder.hasOwnProperty("paymentLog") &&
                            <MDBRow className={"mx-0"}>
                                <b>Payment History :</b> {this.state.activeOrder.paymentLog.length === 0 &&
                            <span>&nbsp;&nbsp;No payment log found.</span>}
                            </MDBRow>
                            }
                            {(this.state.activeOrder.hasOwnProperty("paymentLog") && this.state.activeOrder.paymentLog.length > 0) &&
                            <MDBTable>
                                <MDBTableHead>
                                    <tr>
                                        <th>#</th>
                                        <th>Amount</th>
                                        <th>Paid On</th>
                                        <th>Payment Mode</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {this.state.activeOrder.paymentLog.map((row, index) => (
                                        <tr key={"paymentlog_" + index}>
                                            <td>{index + 1}</td>
                                            <td><span
                                                dangerouslySetInnerHTML={{__html: config.CURRENCY_HTML}}></span> {row.amount.toFixed(2)}
                                            </td>
                                            <td>{utility.convertToReadbleDateTimeUtc(row.paidAt)}</td>
                                            <td>{row.paymentMode}</td>
                                        </tr>
                                    ))}
                                </MDBTableBody>
                            </MDBTable>
                            }
                        </MDBModalBody>
                    </MDBModal>
                </MDBBox>
            </React.Fragment>
        );
    }
}

export default withRouter(ManageOrderHistory)


