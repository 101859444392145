import React, {Suspense} from "react";
import {MDBBox, MDBBreadcrumb, MDBBreadcrumbItem} from "mdbreact";
import {Link} from "react-router-dom";
import {withRouter} from "react-router-dom";
import ListBoxesCmp from "../../components/boxes/listBoxes";
class ListBoxes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            locId: null,
            floorId: null
        }
    }

    componentDidMount() {
        let {locId} = this.props.match.params
        let {floorId} = this.props.match.params
        this.setState({locId, floorId})
    }

    render() {
        return(
            <React.Fragment>
                {this.state.locId &&
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem><Link to={'/view-storage-locations'}>Storage Location</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem><Link to={'/view-floors/'+this.state.locId}>Floors</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem><Link to={'/view-racks/'+this.state.locId+'/'+this.state.floorId}>Racks</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Boxes</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                }
                <MDBBox className={"min-vh-100"}>
                    <Suspense fallback={null}>
                        <ListBoxesCmp queryString={this.props.match.params}/>
                    </Suspense>
                </MDBBox>
            </React.Fragment>
        )
    }
}
export default withRouter(ListBoxes);