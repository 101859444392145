import React from "react"
import {MDBBox, MDBBtn, MDBInput, MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader, MDBSpinner} from "mdbreact";
import Moments from "moment";
import UserStore from "../../../stores/userStore";
import PharmacyService from "../../../services/pharmacyService";
import {toast} from "react-toastify";

class ViewReview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stockReviewComment: "",
            loading: false
        }
    }
    rejectReview = async () => {
        this.setState({loading: true})
        let userData = await UserStore.fetchSessionData();
        let status = await PharmacyService.rejectStockV3(userData, this.props.stockDetails._id, this.state.stockReviewComment);
        this.setState({loading: false})
        if (status === false) {
            toast.error("We are facing some server issue. Please contact your system admin for further details.");
        } else {
            toast.success("Stock successfully rejected.");
            this.setState({stockReviewComment: ""})
            //refresh the search
            this.props.refreshPage()
            this.props.toggleReview(null)
        }
    }
    acceptReview = async () => {
        this.setState({loading: true});
        let userData = await UserStore.fetchSessionData();
        let status = await PharmacyService.approveStockV3(userData, this.props.stockDetails._id, this.state.stockReviewComment);
        this.setState({loading: false});
        if (status === false) {
            toast.error("We are facing some server issue. Please contact your system admin for further details.");
        } else {
            toast.success("Stock successfully approved.");
            this.setState({stockReviewComment: ""})
            //refresh the search
            this.props.refreshPage()
            this.props.toggleReview(null)
        }
    }

    render() {
        return (
            <React.Fragment>
                <MDBModal size={"lg"} isOpen={this.props.reviewModal} toggle={() => {
                    this.props.toggleReview(null)
                }}>
                    <MDBModalHeader toggle={() => {this.props.toggleReview(null)}}>
                        {(this.props.stockDetails.hasOwnProperty("revieweDetails")) ? <>Review Details</> : <>Review Checklist</>}
                    </MDBModalHeader>
                    {(this.props.stockDetails.hasOwnProperty("revieweDetails")) &&
                    <>
                        <MDBModalBody>
                            <div>
                                Reviewed By: {this.props.stockDetails.revieweDetails.reviewedBy}
                            </div>
                            <div>
                                Review
                                Status: {this.props.stockDetails.revieweDetails.isApproved === "Y" ? "Accepted" : "Rejected"}
                            </div>
                            <div>
                                Reviewed
                                At: {Moments(this.props.stockDetails.revieweDetails.reviewedTime).format("MMM DD, YYYY")}
                            </div>
                            <div>
                                Comment: {this.props.stockDetails.revieweDetails.comment !== "" ? <>{this.props.stockDetails.revieweDetails.comment}</> : "No comment found."}
                            </div>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn onClick={() => {
                                this.props.toggleReview(null)
                            }} disabled={this.state.loading}>Close</MDBBtn>
                        </MDBModalFooter>
                    </>
                    }
                    {(!this.props.stockDetails.hasOwnProperty("revieweDetails")) &&
                    <>
                        {this.state.loading && <MDBModalBody><MDBSpinner/></MDBModalBody>}
                        {!this.state.loading &&
                        <MDBModalBody>
                            <MDBBox>
                                Check if product name is right?
                            </MDBBox>
                            <MDBBox>
                                Check if batch is right?
                            </MDBBox>
                            <MDBBox>
                                Check if invoice no is right?
                            </MDBBox>
                            <MDBBox>
                                Check if mfg date is right?
                            </MDBBox>
                            <MDBBox>
                                Check if exp date is right?
                            </MDBBox>
                            <MDBBox>
                                Check if MRP is right?
                            </MDBBox>
                            <MDBBox>
                                Check if Purchase price is right?
                            </MDBBox>
                            <MDBBox>
                                Check if Purchase Quantity is right?
                            </MDBBox>
                            <MDBBox>
                                Check if Barcode is right?
                            </MDBBox>
                            <MDBBox>
                                Check if storage information is right?
                            </MDBBox>
                            <MDBBox>
                                <br/><br/>
                                <MDBInput value={this.state.stockReviewComment} type={"textarea"}
                                          label={"Review comment if any"} getValue={(e) => {
                                    this.setState({stockReviewComment: e})
                                }}></MDBInput>
                            </MDBBox>
                        </MDBModalBody>
                        }
                        <MDBModalFooter>
                            <MDBBtn onClick={() => {
                                this.props.toggleReview(null)
                            }} disabled={this.state.loading}>Close</MDBBtn>
                            <MDBBtn onClick={this.rejectReview} disabled={this.state.loading}>Reject</MDBBtn>
                            <MDBBtn onClick={this.acceptReview} disabled={this.state.loading}>Accept</MDBBtn>
                        </MDBModalFooter>
                    </>
                    }
                </MDBModal>
            </React.Fragment>
        )
    }
}

export default ViewReview;