import React, { useState } from 'react';
import {
    MDBModal,
    MDBModalHeader,
    MDBModalBody,
    MDBModalFooter,
    MDBBtn,
    MDBBox,
    MDBInput,
} from 'mdbreact';

const ReviewComponent = ({ reviewModalBox, setReviewModalBox ,reviewObject, reviewImplement }) => {
    const [stockReviewComment, setStockReviewComment] = useState('');

    const toggleReview = () => {
        setReviewModalBox(false);
    };

    const acceptReview = () => {
        reviewImplement(reviewObject.id,stockReviewComment,"Accept")
    };

    const rejectReview = () => {
        reviewImplement(reviewObject.id,stockReviewComment,"Reject")
    };

    return (
        <MDBModal isOpen={reviewModalBox} toggle={() => toggleReview(null)} size="lg">
            <MDBModalHeader toggle={() => toggleReview(null)}>Review Checklist</MDBModalHeader>
            <MDBModalBody>
                <>
                    <MDBBox>Check if product name is right?</MDBBox>
                    <MDBBox>Check if batch is right?</MDBBox>
                    <MDBBox>Check if invoice no is right?</MDBBox>
                    <MDBBox>Check if mfg date is right?</MDBBox>
                    <MDBBox>Check if exp date is right?</MDBBox>
                    <MDBBox>Check if MRP is right?</MDBBox>
                    <MDBBox>Check if Purchase price is right?</MDBBox>
                    <MDBBox>Check if Purchase Quantity is right?</MDBBox>
                    <MDBBox>Check if Barcode is right?</MDBBox>
                    <MDBBox>Check if storage information is right?</MDBBox>
                    <MDBBox>
                        <br />
                        <br />
                        <MDBInput
                            value={stockReviewComment}
                            type="textarea"
                            label="Review comment if any"
                            onChange={(e) => setStockReviewComment(e.target.value)}
                        />
                    </MDBBox>
                </>
            </MDBModalBody>
            <MDBModalFooter>
                <MDBBtn outline size="md" onClick={() => setReviewModalBox(false) && setStockReviewComment('')}>
                    Close
                </MDBBtn>
                <MDBBtn outline size="md" onClick={rejectReview}>
                    Reject
                </MDBBtn>
                <MDBBtn size="md" onClick={acceptReview}>
                    Accept
                </MDBBtn>
            </MDBModalFooter>
        </MDBModal>
    );
};

export default ReviewComponent;
