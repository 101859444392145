import {
  MDBBox,
  MDBBtn,
  MDBCard,
  MDBCol,
  MDBInput,
  MDBRow,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOption,
  MDBSelectOptions,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBTreeview,
  MDBTreeviewItem,
  MDBTreeviewList,
} from "mdbreact";

import React, { useEffect, useState, useRef } from "react";

import UserStore from "../../stores/userStore";

import { toast } from "react-toastify";

import PharmacyService from "../../services/pharmacyService";

import config from "../../config";

import utility from "../../utility/utility";

import Moments from "moment";

import Barcode from "react-barcode";

import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";

import { Link } from "react-router-dom";

import "./add-error.css";

export default function Add() {
  const [shipmentSearchValue, setShipmentSearchValue] = useState("");

  const [suggestedShipment, setSuggestedShipment] = useState([]);
  const [selectedShipment, setSelectedShipment] = useState(null);

  const [productSearchTerm, setProductSearchTerm] = useState("");

  const [suggestedProduct, setSuggestedProduct] = useState([]);

  const [userData, setUserData] = useState({});

  const [selectedProduct, setSelectedProduct] = useState(null);

  const [productId, setProductId] = useState("");

  const [batch, setBatch] = useState("");

  const [isBatchValid, setIsBatchValid] = useState(true);

  const [mrpValue, setMrpValue] = useState("");

  const [isMrpValid, setIsMrpValid] = useState(true);

  const [priceNoGstValue, setPriceNoGstValue] = useState("");

  const [isPriceNoGstValid, setIsPriceNoGstValid] = useState(true);

  const [gstValue, setGstValue] = useState("");

  const [isGstValid, setIsGstValid] = useState(true);

  const [priceWithGstValue, setPriceWithGstValue] = useState("");

  const [isPriceWithGstValid, setIsPriceWithGstValid] = useState(true);

  const [purchaseDiscountValue, setPurchaseDiscountValue] = useState("");

  const [isPurchaseDiscountValid, setIsPurchaseDiscountValid] = useState(true);

  const [hsnSac, setHsnSac] = useState("");

  const [isHsnSacValid, setIsHsnSacValid] = useState(true);

  const [mfgDate, setMfgDate] = useState("");

  const [isMfgDateValid, setIsMfgDateValid] = useState(true);

  const [expdate, setExpdate] = useState("");

  const [isExpDateValid, setIsExpDateValid] = useState(true);

  const [expiringAfterMonth, setExpiringAfterMonth] = useState("");

  const [packageQnty, setPackageQnty] = useState("");

  const [quantity, setQuantity] = useState("");

  const [freeQuantity, setFreeQuantity] = useState(0);

  const [totalQuantity, setTotalQuantity] = useState("");

  const [commentStorage, setCommentStorage] = useState("");

  const [comment, setComment] = useState("");

  const [locSuggestion, setLocSuggestion] = useState([]);

  const [selectedLoc, setSelectedLoc] = useState([]);

  const [floorSuggestion, setFloorSuggestion] = useState([]);

  const [selectedFloor, setSelectedFloor] = useState([]);

  const [rackSuggestions, setRackSuggestions] = useState([]);

  const [selectedRack, setSelectedRack] = useState([]);

  const [boxSuggestions, setBoxSuggestions] = useState([]);

  const [selectedBox, setSelectedBox] = useState([]);
  const [barCode, setBarCode] = useState([]);

  const [tableData, setTableData] = useState([]);
  const [shipmentId, setShipmentId] = useState("");
  const StorageLocation = "locId";

  const StorageFloor = "floorId";

  const StorageRack = "rackId";

  const StorageBox = "boxId";
  const BarcodeValue = "barCode";

  useEffect(() => {
    getUserData();

    fetchStorageLOC();
  }, []);
  useEffect(() => {
    // for (let i = 0; i < totalQuantity; i++) {
    //   console.log(i)
    //   generateBarcodeData(i);
    // }
    generateBarcodes1();
  }, [totalQuantity]);
  const generateBarcodes1 = async () => {
    for (let i = 0; i < totalQuantity; i++) {
      await generateBarcodeData(i);
    }
  };
  const negativeValueTimer = useRef(null);
  const negativeValueTimerPriceNoGst = useRef(null);
  const overLimitTimerGst = useRef(null);

  const getUserData = async () => {
    let user = await UserStore.fetchSessionData();

    setUserData(user);
  };
  const searchByTerm = async (value) => {
    let product = await PharmacyService.searchProductV2(value, userData);

    if (product === false) {
      toast.error("We are facing some server issue. Please try again later.");
    } else {
      setSuggestedProduct(product);
    }
  };
  const selectProduct = (product, index) => {
    setSelectedProduct(product);

    setProductId(product._id);

    setProductSearchTerm("");

    setSuggestedProduct([]);
  };
  const selectShipment = (item) => {
    setSelectedShipment(item);

    setShipmentId(item._id);

    setShipmentSearchValue("");

    setSuggestedShipment([]);
  };
  const handleChange = (e, key) => {
    const value = e.target.value;
    if (
      (key === "mrp" ||
        key === "priceNoGst" ||
        key === "gst" ||
        key === "priceWithGst" ||
        key === "purchaseDiscount") &&
      value !== ""
    ) {
      if (value.substr(value.length - 1) !== ".") {
        if (utility.isCurrency(value) === false) {
          return false;
        } else {
          if (key === "mrp") {
            setMrpValue(value);
          }

          if (key === "priceNoGst") {
            setPriceNoGstValue(value);

            if (gstValue == "") {
              setPriceWithGstValue("");
            } else {
              setPriceWithGstValue(utility.calculateGst(value, gstValue));
            }
          }

          if (key === "gst") {
            setGstValue(value);
            if (priceNoGstValue == "") {
              setPriceWithGstValue("");
            } else {
              setPriceWithGstValue(
                utility.calculateGst(priceNoGstValue, value)
              );
            }
          }

          if (key === "purchaseDiscount") {
            setPurchaseDiscountValue(value);
          }
        }
      }
    } else if (value === "") {
      if (key === "mrp") {
        setMrpValue("");
      }

      if (key === "priceNoGst") {
        setPriceNoGstValue("");

        setPriceWithGstValue("");
      }

      if (key === "gst") {
        setGstValue("");

        setPriceWithGstValue("");
      }

      if (key === "purchaseDiscount") {
        setPurchaseDiscountValue("");
      }
    }
    if (key === "purchaseDiscount") {
      if (parseFloat(value) > 100) {
        return false;
      }
    }

    if (key === "priceWithGst" || key === "expiringAfterMonth") {
      return false;
    }
    if (key === "mfgDate" && value.length <= 7) {
      if (value.length === 2 && !value.includes("/")) {
        setMfgDate(value + "/");

        return;
      }

      setMfgDate(value);
    }
    if (key === "mfgDate" && expdate !== "") {
      const mfg = Moments(value, "MM/YYYY", true);
      const exp = Moments(expdate, "MM/YYYY", true);

      if (mfg.isValid() && exp.isValid() && mfg.isAfter(exp)) {
        toast.error("Mfg date cannot be greater than Exp date");

        setMfgDate("");
      }
    }
    if (key === "expdate" && value.length <= 7) {
      if (value.length === 2 && expdate.length < value.length) {
        setExpdate(value + "/");

        return;
      }
      setExpdate(value);

      calculationExpiration(value);
    }
    if (key === "expdate" && mfgDate !== "") {
      const mfg = Moments(mfgDate, "MM/YYYY", true);
      const exp = Moments(value, "MM/YYYY", true);

      if (mfg.isValid() && exp.isValid() && mfg.isAfter(exp)) {
        toast.error("Mfg date cannot be greater than Exp date");

        setExpdate("");

        setExpiringAfterMonth("");
      }
    }
    if (key === "packageQnty") {
      if (value != "") {
        if (utility.isFloat(value)) {
          if (selectedProduct.looseSaleAllowed === "Y") {
            setQuantity(
              parseInt(value) * parseInt(selectedProduct.contentQuantity)
            );

            setPackageQnty(parseInt(value));

            setTotalQuantity(parseInt(value) + freeQuantity);
          } else if (selectedProduct.looseSaleAllowed === "N") {
            setQuantity(parseInt(value));

            setPackageQnty(parseInt(value));

            setTotalQuantity(parseInt(value) + freeQuantity);
          }
        } else {
          if (value !== "") {
            toast.error("Please enter valid quantity.");

            return false;
          } else {
            setQuantity("");

            setPackageQnty("");

            setTotalQuantity("");
          }
        }
      } else {
        setQuantity("");

        setPackageQnty("");

        setTotalQuantity(freeQuantity);
      }
    }
    if (key === "freePackageQnty") {
      if (value != "") {
        if (utility.isFloat(value)) {
          if (selectedProduct.looseSaleAllowed === "Y") {
            setFreeQuantity(parseInt(value));

            setTotalQuantity(parseInt(value) + packageQnty);
          } else if (selectedProduct.looseSaleAllowed === "N") {
            setFreeQuantity(parseInt(value));

            setTotalQuantity(parseInt(value) + packageQnty);
          }
        } else if (value === "") {
          setFreeQuantity("");
        } else {
          setFreeQuantity("");
        }
      } else {
        setFreeQuantity("");

        setTotalQuantity(packageQnty);
      }
    }
  };
  const validateDate = (field) => {
    let check = false;

    if (field === "Mfg") {
      if (mfgDate === "") {
        return true;
      }

      let tempMfg = "01/" + mfgDate;

      check = Moments(tempMfg, "DD/MM/YYYY", true);
    } else {
      if (expdate === "") {
        return true;
      }

      let tempExp = "27/" + expdate;

      check = Moments(tempExp, "DD/MM/YYYY", true);
    }

    if (check.isValid() === false) {
      toast.error("Please enter valid " + field + " date.");

      if (field === "Mfg") {
        setMfgDate("");
      } else {
        setExpdate("");
      }
    }
  };
  const getValueWithGst = (value) => {
    setGstValue(value);

    setPriceWithGstValue(utility.calculateGst(priceNoGstValue, value));
  };
  //calculation expiration
  const calculationExpiration = (value) => {
    if (expdate !== "" && value.length == 7) {
      let today = Moments();

      let tempExp = "27/" + value;

      let exp = Moments(tempExp, "DD/MM/YYYY", true);

      let diff = exp.diff(today, "months", true);

      if (diff < 0) {
        toast.error("Please enter valid Mfg date and Exp date.");

        setExpdate("");
      } else {
        if (!isNaN(diff)) {
          setExpiringAfterMonth(Math.floor(diff));
        }
      }
    } else {
      setExpiringAfterMonth("");
    }
  };
  const fetchStorageLOC = async () => {
    let storageInfo = await PharmacyService.fetchStorageLocationV2();

    if (storageInfo === false) {
      toast.error("We are facing some server issue. Please try again later.");
    } else {
      setLocSuggestion(storageInfo);
    }
  };
  const handleSelectStorageLoc = (e, index) => {
    console.log(index);
    const updatedSelectedLoc = [...selectedLoc];

    updatedSelectedLoc[index] = e.target.value;
    setSelectedLoc(updatedSelectedLoc);

    setSelectedFloor((prev) => {
      const newSelectedFloor = [...prev];

      newSelectedFloor[index] = "";

      return newSelectedFloor;
    });
    setSelectedRack((prev) => {
      const newSelectedRack = [...prev];

      newSelectedRack[index] = "";

      return newSelectedRack;
    });
    setSelectedBox((prev) => {
      const newSelectedBox = [...prev];

      newSelectedBox[index] = "";

      return newSelectedBox;
    });
    setFloorSuggestion((prev) => {
      const newFloorSuggestion = prev.map((innerArray, idx) =>
        idx === index ? [] : innerArray
      );

      return newFloorSuggestion;
    });
    setRackSuggestions((prev) => {
      const newRackSuggestion = prev.map((innerArray, idx) =>
        idx === index ? [] : innerArray
      );

      return newRackSuggestion;
    });
    setBoxSuggestions((prev) => {
      const newBoxSuggestion = prev.map((innerArray, idx) =>
        idx === index ? [] : innerArray
      );

      return newBoxSuggestion;
    });
    fetchFloor(e.target.value, index);
    setTableData((prevData) => {
      const newData = [...prevData];

      newData[index] = {
        ...newData[index],

        [StorageLocation]: e.target.value,

        [StorageFloor]: "",

        [StorageRack]: "",

        [StorageBox]: "",
      };

      return newData;
    });
  };
  const handleSelectFloor = (e, index) => {
    const updatedSelectedFloor = [...selectedFloor];

    updatedSelectedFloor[index] = e.target.value;
    setSelectedFloor(updatedSelectedFloor);

    setSelectedRack((prev) => {
      const newSelectedRack = [...prev];

      newSelectedRack[index] = "";

      return newSelectedRack;
    });
    setSelectedBox((prev) => {
      const newSelectedBox = [...prev];

      newSelectedBox[index] = "";

      return newSelectedBox;
    });
    setRackSuggestions((prev) => {
      const newRackSuggestion = prev.map((innerArray, idx) =>
        idx === index ? [] : innerArray
      );

      return newRackSuggestion;
    });
    setBoxSuggestions((prev) => {
      const newBoxSuggestion = prev.map((innerArray, idx) =>
        idx === index ? [] : innerArray
      );

      return newBoxSuggestion;
    });
    fetchRack(e.target.value, index);
    setTableData((prevData) => {
      const newData = [...prevData];

      newData[index] = {
        ...newData[index],

        [StorageFloor]: e.target.value,
      };

      return newData;
    });
  };
  const handleSelectRack = (e, index) => {
    const updatedSelectedRack = [...selectedRack];
    setSelectedRack(updatedSelectedRack);

    setSelectedBox((prev) => {
      const newSelectedBox = [...prev];

      newSelectedBox[index] = "";

      return newSelectedBox;
    });
    setBoxSuggestions((prev) => {
      const newBoxSuggestion = prev.map((innerArray, idx) =>
        idx === index ? [] : innerArray
      );

      return newBoxSuggestion;
    });
    fetchBox(e.target.value, index);
    setTableData((prevData) => {
      const newData = [...prevData];

      newData[index] = {
        ...newData[index],

        [StorageRack]: e.target.value,
      };

      return newData;
    });
  };
  const handleSelectBox = (e, index) => {
    const updatedSelectedBox = [...selectedBox];

    updatedSelectedBox[index] = e.target.value;
    setSelectedBox(updatedSelectedBox);

    setTableData((prevData) => {
      const newData = [...prevData];

      newData[index] = {
        ...newData[index],

        [StorageBox]: e.target.value,
      };

      return newData;
    });
  };
  const fetchFloor = async (value, index) => {
    console.log(44, value);

    let floorInfo = await PharmacyService.fetchFloorV2(value);

    if (floorInfo === false) {
      toast.error("We are facing some server issue. Please try again later.");
    } else {
      if (floorInfo.length > 0) {
        const updatedFloorSuggestion = [...floorSuggestion];

        updatedFloorSuggestion[index] = floorInfo;

        setFloorSuggestion(updatedFloorSuggestion);
      } else {
        toast.error("Floor is not configured for this location.");
      }
    }
  };
  const fetchRack = async (floorValue, index) => {
    let rackInfo = await PharmacyService.fetchRackV2(floorValue);

    if (rackInfo === false) {
      toast.error("We are facing some server issue. Please try again later.");
    } else {
      if (rackInfo.length > 0) {
        const updatedRackSuggestions = [...rackSuggestions];

        updatedRackSuggestions[index] = rackInfo;

        setRackSuggestions(updatedRackSuggestions);
      } else {
        toast.error("Rack configuration not found for this floor.");
      }
    }
  };
  const fetchBox = async (rackValue, index) => {
    let boxInfo = await PharmacyService.fetchBoxV2(rackValue);

    if (boxInfo === false) {
      toast.error("We are facing some server issue. Please try again later.");
    } else {
      if (boxInfo.length > 0) {
        const updatedBoxSuggestions = [...boxSuggestions];

        updatedBoxSuggestions[index] = boxInfo;

        setBoxSuggestions(updatedBoxSuggestions);
      } else {
        toast.error("Box configuration not found for this rack");
      }
    }
  };
  const generateBarcodeData = (index) => {
    const timestamp = new Date().getTime() + 122 + index;

    // const updatedBarcodes = [...barCode];

    // console.log("barcode",barCode)

    // updatedBarcodes[index] = timestamp.toString();

    // console.log("updated barcode",updatedBarcodes)

    // setBarCode(updatedBarcodes);

    setBarCode((prevBarCodes) => {
      const updatedBarcodes = [...prevBarCodes];

      updatedBarcodes[index] = timestamp.toString();

      return updatedBarcodes;
    });

    setTableData((prevData) => {
      console.log("prevdata", prevData);

      const newData = [...prevData];

      newData[index] = {
        ...newData[index],

        [BarcodeValue]: timestamp.toString(),
      };

      console.log("newdata", newData);

      return newData;
    });

    console.log(`Barcode generated for index ${index}: ${timestamp}`);

    return timestamp.toString();
  };
  const toCopy = (barCode) => {
    navigator.clipboard.writeText(barCode.toString().trim());

    toast.success(
      "Barcode is copied successfully. Copied barcode is- " +
        barCode.toString().trim()
    );
  };
  const searchShipment = async (term) => {
    console.log(term);

    if (term !== "") {
      let suggestedShipment = await PharmacyService.searchShipmentV2(
        term,

        userData
      );

      if (suggestedShipment === false) {
        toast.error("We are facing some issue please try again later.");
      } else {
        setSuggestedShipment(suggestedShipment);
      }
    }
  };
  const save = async () => {
    //validations

    if (selectedShipment === null || selectedShipment === "") {
      toast.error("Please select a shipment.");

      return false;
    }

    let status = true;

    let index = "";
    let count = 1;

    if (selectedProduct === null || selectedProduct === "") {
      status = false;

      index = count;

      toast.error("Select a product.");

      return false;
    }
    if (batch === "") {
      status = false;

      index = count;

      toast.error("Enter a valid Batch.");

      return false;
    }

    if (mrpValue === "") {
      status = false;

      index = count;

      toast.error("Enter a valid Mrp.");

      return false;
    }

    if (priceNoGstValue === "") {
      status = false;

      index = count;

      toast.error("Enter a valid price with no gst.");

      return false;
    }

    if (gstValue === "") {
      status = false;

      index = count;

      toast.error("Enter a valid Gst value.");

      return false;
    }

    if (priceWithGstValue === "") {
      status = false;

      index = count;

      return false;
    }

    if (purchaseDiscountValue === "") {
      setPurchaseDiscountValue(0);
    } else {
      setPurchaseDiscountValue(parseFloat(purchaseDiscountValue));
    }
    if (hsnSac === "") {
      status = false;

      index = count;

      toast.error("Enter a valid hsnSac.");

      return false;
    }
    if (mfgDate === "") {
      status = false;

      index = count;

      toast.error("Enter a valid mfgdate.");

      return false;
    }

    if (expdate === "") {
      status = false;

      index = count;

      toast.error("Enter a valid expdate.");

      return false;
    }
    const tempMfg = "01/" + mfgDate;

    const mfg = Moments(tempMfg, "DD/MM/YYYY", true);

    const formattedMfgDate = mfg.isValid() ? mfg.format("MM/YYYY") : "";

    const tempExp = "27/" + expdate;

    const exp = Moments(tempExp, "DD/MM/YYYY", true);
    const formattedExpDate = exp.isValid() ? exp.format("MM/YYYY") : "";

    setMfgDate(formattedMfgDate);
    setExpdate(formattedExpDate);

    if (packageQnty === "") {
      status = false;

      index = count;

      toast.error("Enter a valid packageQnty.");
    }
    if (commentStorage === "") {
      status = false;

      index = count;

      toast.error("Enter a valid commentStorage.");
    }
    if (comment === "") {
      status = false;

      index = count;

      toast.error("Enter a valid comment.");
    }
    if (shipmentId === "") {
      status = false;

      index = count;

      return false;
    }
    const allObjectsContainValidKeys =
      tableData.length === totalQuantity &&
      tableData.every((obj) => {
        const keys = Object.keys(obj);
        const hasValidKeys =
          keys.length === 5 &&
          keys.includes("locId") &&
          keys.includes("floorId") &&
          keys.includes("rackId") &&
          keys.includes("boxId") &&
          keys.includes("barCode");
        const allValuesAreValid = Object.values(obj).every(
          (value) => value.trim() !== ""
        );
        return hasValidKeys && allValuesAreValid;
      });

    if (allObjectsContainValidKeys) {
      if (status === false) {
        toast.error("Wrong stock entry for record. Please check the values.");

        return false;
      } else {
        let userData = await UserStore.fetchSessionData();

        let payload = [
          {
            productId: productId,

            batch: batch,

            mrp: parseFloat(mrpValue),

            priceNoGst: parseFloat(priceNoGstValue),

            gst: parseFloat(gstValue),

            priceWithGst: parseFloat(priceWithGstValue),

            discount: parseFloat(purchaseDiscountValue),

            hsn: hsnSac,

            mfgDate: Moments(tempMfg, "DD/MM/YYYY", true),

            expDate: Moments(tempExp, "DD/MM/YYYY", true),

            quantity: parseFloat(packageQnty),

            freeQuantity: parseFloat(freeQuantity),

            commentStorage: commentStorage,

            comment: comment,

            shipmentId: shipmentId,

            storageLocations: tableData,
          },
        ];

        let status = await PharmacyService.createInventoryV3(userData, payload);

        if (status === false) {
          toast.error(
            "We are facing some issue. Please check your input values and try again. If inputs are valid please change the barcode."
          );
        } else {
          toast.success("Stock added successfully.");
          setShipmentSearchValue("");

          setSuggestedShipment([]);
          setSelectedShipment(null);

          setProductSearchTerm("");

          setSuggestedProduct([]);

          setSelectedProduct(null);

          setProductId("");

          setBatch("");

          setMrpValue("");

          setPriceNoGstValue("");

          setGstValue("");

          setPriceWithGstValue("");

          setPurchaseDiscountValue("");

          setHsnSac("");

          setMfgDate("");

          setExpdate("");

          setExpiringAfterMonth("");

          setPackageQnty("");

          setQuantity("");

          setFreeQuantity(0);

          setTotalQuantity("");

          setCommentStorage("");

          setComment("");

          setSelectedLoc([]);

          setFloorSuggestion([]);

          setSelectedFloor([]);

          setRackSuggestions([]);

          setSelectedRack([]);

          setBoxSuggestions([]);

          setSelectedBox([]);

          setBarCode([]);

          setTableData([]);

          setShipmentId("");
        }
      }
    } else {
      toast.error("Select all the required fields.");
    }
  };

  const handleChangesValidation = (e, field) => {
    const value = e.target.value;

    const fieldActions = {
      priceNoGst: [setPriceNoGstValue, validatePriceNoGst],

      mrp: [setMrpValue, validateMrp],

      gst: [setGstValue, validateGst],

      priceWithGst: [setPriceWithGstValue, validatePriceWithGst],

      purchaseDiscount: [setPurchaseDiscountValue, validatePurchaseDiscount],

      mfgDate: [setMfgDate, validateMfgDate],

      expdate: [setExpdate, validateExpDate],
    };

    if (field in fieldActions) {
      const [setter, validator] = fieldActions[field];

      setter(value);

      validator(value);
    }
  };
  const validateBatch = (value) => {
    setIsBatchValid(value.trim() !== "");
  };
  // const validateMrp = (value) => {
  //   setIsMrpValid(value.trim() !== "");
  // };

  const validateMrp = (value) => {
    const mrpValue = parseFloat(value);
    const isNotEmpty = value.trim() !== "";
    const isNonNegative = mrpValue >= 0;
    if (negativeValueTimer.current) {
      clearTimeout(negativeValueTimer.current);
      negativeValueTimer.current = null;
    }
    if (isNotEmpty && !isNaN(mrpValue) && !isNonNegative) {
      negativeValueTimer.current = setTimeout(() => {
        alert("MRP should not be negative");
        setIsMrpValid(false);
      }, 800);
    } else {
      setIsMrpValid(isNotEmpty && isNonNegative);
    }
  };
  const validatePriceNoGst = (value) => {
    const priceNoGstValue = parseFloat(value);
    const mrpValues = parseFloat(mrpValue);
    const isNotEmpty = value.trim() !== "";
    const isNonNegative = priceNoGstValue >= 0;
    const isLessThanOrEqualToMrp = priceNoGstValue <= mrpValue;

    if (negativeValueTimerPriceNoGst.current) {
      clearTimeout(negativeValueTimerPriceNoGst.current);
      negativeValueTimerPriceNoGst.current = null;
    }

    if (isNotEmpty && !isNaN(priceNoGstValue) && !isNonNegative) {
      negativeValueTimerPriceNoGst.current = setTimeout(() => {
        alert("Price (w/o GST) should not be negative");
        setIsPriceNoGstValid(false);
      }, 800);
    } else if (!isNaN(priceNoGstValue) && !isLessThanOrEqualToMrp) {
      negativeValueTimerPriceNoGst.current = setTimeout(() => {
        alert("Cannot enter Price (w/o GST) more than MRP Price");
        setIsPriceNoGstValid(false);
      }, 800);
    } else {
      setIsPriceNoGstValid(
        isNotEmpty && isNonNegative && isLessThanOrEqualToMrp
      );
    }
  };

  const validateGst = (value) => {
    const gstValue = parseFloat(value);
    const isNotEmpty = value.trim() !== "";
    const isNotMoreThan = gstValue <= 18;
    const isNonNegative = gstValue >= 0;

    if (overLimitTimerGst.current) {
      clearTimeout(overLimitTimerGst.current);
      overLimitTimerGst.current = null;
    }

    if (isNotEmpty && !isNaN(gstValue)) {
      if (!isNotMoreThan) {
        overLimitTimerGst.current = setTimeout(() => {
          alert("GST should be not more than 18%");
          setIsGstValid(false);
        }, 800);
      } else if (!isNonNegative) {
        overLimitTimerGst.current = setTimeout(() => {
          alert("Negative values are not allowed for GST");
          setIsGstValid(false);
        }, 800);
      } else {
        setIsGstValid(true);
      }
    } else {
      setIsGstValid(false);
    }
  };

  const validatePriceWithGst = (value) => {
    setIsPriceWithGstValid(value.trim() !== "");
  };
  // const validatePurchaseDiscount = (value) => {
  //   setIsPurchaseDiscountValid(value.trim() !== "");
  // };
  const validatePurchaseDiscount = (value) => {
    const discountValue = parseFloat(value);
    const isNotEmpty = value.trim() !== "";
    const isWithinRange = discountValue >= 0 && discountValue <= 100;
    if (isNotEmpty && !isNaN(discountValue)) {
      if (!isWithinRange) {
        alert("The maximum discount is from 1 to 100%, Not more than that");
        setIsPurchaseDiscountValid(false);
      } else {
        setIsPurchaseDiscountValid(true);
      }
    } else {
      setIsPurchaseDiscountValid(false);
    }
  };

  const validateHsnSac = (value) => {
    setIsHsnSacValid(value.trim() !== "");
  };

  const validateMfgDate = (value) => {
    setIsMfgDateValid(value.trim() !== "");
  };

  const validateExpDate = (value) => {
    setIsExpDateValid(value.trim() !== "");
  };

  return (
    <>
      <div className={"col-10 mx-auto"}>
        <MDBCard className={"mx-2 mb-2 p-3"}>
          <MDBRow>
            <div className={"col-md-6"}>
              <MDBInput
                value={shipmentSearchValue}
                label={"Enter shipment code or invoice code"}
                size={"lg"}
                onChange={(e) => {
                  setShipmentSearchValue(e.target.value);

                  searchShipment(e.target.value);
                }}
              />
              <MDBBox
                className={
                  "autosuggest-dropdown" +
                  (suggestedShipment.length === 0 ? "d-none" : "")
                }
              >
                {suggestedShipment.map((item, index) => (
                  <MDBBox
                    className={" p-3 mb-2 border border-light"}
                    key={index}
                  >
                    <MDBBox>Shipment code: {item.shipmentCode}</MDBBox>

                    <MDBBox>Invoice no: {item.invoiceNumber}</MDBBox>

                    <MDBBox>
                      <MDBTreeview
                        theme="animated"
                        className={"tree-no-border"}
                      >
                        <MDBTreeviewList icon="money" title="Details" far open>
                          <MDBTreeviewItem
                            title={
                              "Invoice date: " +
                              Moments(item.invoiceDate).format("DD/MM/YYYY")
                            }
                            far
                          ></MDBTreeviewItem>

                          <MDBTreeviewItem
                            title={
                              "Receiving date: " +
                              Moments(item.receivingDate).format("DD/MM/YYYY")
                            }
                            far
                          ></MDBTreeviewItem>

                          <MDBTreeviewItem
                            title={"Total: " + item.amount.toFixed(2)}
                            far
                          ></MDBTreeviewItem>

                          <MDBTreeviewItem
                            title={
                              "Discount: " + item.discountAmount.toFixed(2)
                            }
                            far
                          ></MDBTreeviewItem>

                          <MDBTreeviewItem
                            title={"Total tax: " + item.totalTaxAmount}
                            far
                          ></MDBTreeviewItem>

                          <MDBTreeviewItem
                            title={"Receiving place: " + item.supplyPlace}
                            far
                          ></MDBTreeviewItem>

                          <MDBTreeviewItem
                            title={
                              "Supplier: " + item.supplierDetails.supplierName
                            }
                            far
                          ></MDBTreeviewItem>

                          <MDBTreeviewItem
                            title={"Entered by: " + item.createdByDetails.name}
                            far
                          ></MDBTreeviewItem>

                          <MDBTreeviewItem
                            title={
                              "Received by: " + item.receivedByDetails.name
                            }
                            far
                          ></MDBTreeviewItem>
                        </MDBTreeviewList>
                      </MDBTreeview>
                    </MDBBox>

                    <MDBBox>
                      <MDBBtn
                        size={"sm"}
                        onClick={() => {
                          selectShipment(item);
                        }}
                      >
                        Select
                      </MDBBtn>
                    </MDBBox>
                  </MDBBox>
                ))}
              </MDBBox>
            </div>

            <MDBCol>
              {selectedShipment !== null && (
                <>
                  <MDBBox>
                    Shipment code: {selectedShipment.shipmentCode}
                  </MDBBox>

                  <MDBBox>Invoice no: {selectedShipment.invoiceNumber}</MDBBox>

                  <MDBTreeview theme="animated" className={"tree-no-border"}>
                    <MDBTreeviewList icon="money" title="Details" far open>
                      <MDBTreeviewItem
                        title={
                          "Invoice date: " +
                          Moments(selectedShipment.invoiceDate).format(
                            "DD/MM/YYYY"
                          )
                        }
                        far
                      ></MDBTreeviewItem>

                      <MDBTreeviewItem
                        title={
                          "Receiving date: " +
                          Moments(selectedShipment.receivingDate).format(
                            "DD/MM/YYYY"
                          )
                        }
                        far
                      ></MDBTreeviewItem>

                      <MDBTreeviewItem
                        title={"Total: " + selectedShipment.amount.toFixed(2)}
                        far
                      ></MDBTreeviewItem>

                      <MDBTreeviewItem
                        title={
                          "Discount: " +
                          selectedShipment.discountAmount.toFixed(2)
                        }
                        far
                      ></MDBTreeviewItem>

                      <MDBTreeviewItem
                        title={"Total tax: " + selectedShipment.totalTaxAmount}
                        far
                      ></MDBTreeviewItem>

                      <MDBTreeviewItem
                        title={
                          "Receiving place: " + selectedShipment.supplyPlace
                        }
                        far
                      ></MDBTreeviewItem>

                      <MDBTreeviewItem
                        title={
                          "Supplier: " +
                          selectedShipment.supplierDetails.supplierName
                        }
                        far
                      ></MDBTreeviewItem>

                      <MDBTreeviewItem
                        title={
                          "Entered by: " +
                          selectedShipment.createdByDetails.name
                        }
                        far
                      ></MDBTreeviewItem>

                      <MDBTreeviewItem
                        title={
                          "Received by: " +
                          selectedShipment.receivedByDetails.name
                        }
                        far
                      ></MDBTreeviewItem>

                      <MDBTreeviewList title="Payment log" far open>
                        {selectedShipment.paymentLog.map((log, idx) => (
                          <>
                            <MDBCard className={"mt-2"}>
                              <MDBTreeviewItem
                                title={
                                  "Amount: " + log.paymentAmount.toFixed(2)
                                }
                                far
                              />

                              <MDBTreeviewItem
                                title={"Payment mode: " + log.paymentMode}
                                far
                              />

                              <MDBTreeviewItem
                                title={
                                  "Payment reference: " + log.paymentReference
                                }
                                far
                              />

                              <MDBTreeviewItem
                                title={
                                  "Payment date: " +
                                  Moments(log.paymentDate).format("DD/MM/YYYY")
                                }
                                far
                              />

                              <MDBTreeviewItem
                                title={"Entered by: " + log.enteredBy.name}
                                far
                              />
                            </MDBCard>
                          </>
                        ))}
                      </MDBTreeviewList>
                    </MDBTreeviewList>
                  </MDBTreeview>
                </>
              )}
            </MDBCol>
          </MDBRow>
        </MDBCard>
      </div>
      <div className={"col-10 mx-auto"}>
        <MDBCard className={"m-2 p-3"} key="">
          <MDBRow>
            <div className={"col-md"}>
              <MDBInput
                value={productSearchTerm}
                onChange={(e) => {
                  setProductSearchTerm(e.target.value);

                  searchByTerm(e.target.value);
                }}
                label={
                  <>
                    Product <span className="required-asterisk">*</span>
                  </>
                }
                className={"inv-input-width"}
                maxLength={50}
              />
              <MDBBox
                className={
                  "autosuggest-dropdown" +
                  (suggestedProduct.length === 0 ? "d-none" : "")
                }
              >
                {suggestedProduct.map((items, idx) => (
                  <MDBBox className={"p-3 mb-2 border border-light"} key={idx}>
                    <MDBBox>Product name: {items.productName}</MDBBox>

                    <MDBBox>Retail packaging: {items.retailPackage}</MDBBox>

                    <MDBBox>Content quantity: {items.contentQuantity}</MDBBox>

                    <MDBBox>Quantity unit: {items.quantityUnit}</MDBBox>

                    <MDBBox>MRP: {items.mrp.toFixed(2)}</MDBBox>

                    <MDBBox>Stock: {items.stock}</MDBBox>

                    <MDBBox>Mfg: {items.mfg.manufacturer}</MDBBox>

                    <MDBBox>
                      <MDBBtn
                        size={"sm"}
                        onClick={() => {
                          selectProduct(items, idx);
                        }}
                      >
                        Select
                      </MDBBtn>
                    </MDBBox>
                  </MDBBox>
                ))}
              </MDBBox>
            </div>

            <MDBCol>
              {selectedProduct !== null && (
                <MDBCard className={"p-2"}>
                  <MDBRow>
                    <MDBCol>
                      <MDBBox>
                        Product name: {selectedProduct.productName}
                      </MDBBox>

                      <MDBBox>
                        <MDBTreeview
                          theme="animated"
                          className={"tree-no-border"}
                        >
                          <MDBTreeviewList title="Details" far open>
                            <MDBTreeviewItem
                              title={
                                "Retail packaging: " +
                                selectedProduct.retailPackage
                              }
                              far
                            />

                            <MDBTreeviewItem
                              title={
                                "Content quantity: " +
                                selectedProduct.contentQuantity
                              }
                              far
                            />

                            <MDBTreeviewItem
                              title={
                                "Quantity unit: " + selectedProduct.quantityUnit
                              }
                              far
                            />

                            <MDBTreeviewItem
                              title={"MRP: " + selectedProduct.mrp.toFixed(2)}
                              far
                            />

                            <MDBTreeviewItem
                              title={"Stock: " + selectedProduct.stock}
                              far
                            />

                            <MDBTreeviewItem
                              title={"Mfg: " + selectedProduct.mfg.manufacturer}
                              far
                            />

                            <MDBTreeviewItem
                              title={
                                "Schedule: " + selectedProduct.medicineType
                              }
                              far
                            />

                            <MDBTreeviewItem
                              title={
                                "Loose sale: " +
                                selectedProduct.looseSaleAllowed
                              }
                              far
                            />

                            <MDBTreeviewItem
                              title={
                                "Reorder threshold : " +
                                selectedProduct.reorderThreshold
                              }
                              far
                            />
                          </MDBTreeviewList>
                        </MDBTreeview>
                      </MDBBox>
                    </MDBCol>

                    <MDBCol>
                      {selectedProduct.imageUrl !== "" &&
                        selectedProduct.imageUrl.length > 0 && (
                          <MDBBox>
                            <img
                              src={
                                config.IMAGE_HOST +
                                selectedProduct.imageUrl[0].filePathThumb
                              }
                            />
                          </MDBBox>
                        )}
                    </MDBCol>
                  </MDBRow>
                </MDBCard>
              )}
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <div className={"col-md"}>
              <MDBInput
                label={
                  <>
                    Batch <span className="required-asterisk">*</span>
                  </>
                }
                value={batch}
                onChange={(e) => {
                  setBatch(e.target.value);

                  validateBatch(e.target.value);
                }}
                onBlur={() => validateBatch(batch)}
                maxLength={50}
                className={!isBatchValid ? "invalid-input" : ""}
              />

              {!isBatchValid && (
                <div className="error-message">Batch cannot be empty</div>
              )}
            </div>

            <div className={"col-md"}>
              <MDBInput
                label={
                  <>
                    MRP <span className="required-asterisk">*</span>
                  </>
                }
                value={mrpValue}
                onChange={(e) => handleChangesValidation(e, "mrp")}
                onBlur={() => validateMrp(mrpValue)}
                maxLength={50}
                className={!isMrpValid ? "invalid-input" : ""}
              />

              {!isMrpValid && (
                <div className="error-message">MRP cannot be empty</div>
              )}
            </div>

            <div>
              <MDBInput
                label={
                  <>
                    Price(w/o GST) <span className="required-asterisk">*</span>
                  </>
                }
                value={priceNoGstValue}
                onChange={(e) => {
                  handleChangesValidation(e, "priceNoGst");

                  handleChange(e, "priceNoGst");
                }}
                onBlur={() => validatePriceNoGst(priceNoGstValue)}
                maxLength={50}
                className={!isPriceNoGstValid ? "invalid-input" : ""}
              />

              {!isPriceNoGstValid && (
                <div className="error-message">Price cannot be empty</div>
              )}
            </div>

            <div className={"col-md"}>
              <MDBInput
                label={
                  <>
                    GST % <span className="required-asterisk">*</span>
                  </>
                }
                value={gstValue}
                onChange={(e) => handleChange(e, "gst")}
                onBlur={() => validateGst(gstValue)}
                maxLength={50}
                className={!isGstValid ? "invalid-input" : ""}
              />

              {!isGstValid && (
                <div className="error-message">GST cannot be empty</div>
              )}
            </div>

            <div className={"col-md"}>
              <MDBInput
                label={
                  <>
                    Price(w/ GST) <span className="required-asterisk">*</span>
                  </>
                }
                value={priceWithGstValue}
                onChange={(e) => handleChange(e, "priceWithGst")}
                onBlur={() => validatePriceWithGst(priceWithGstValue)}
                maxLength={50}
                className={!isPriceWithGstValid ? "invalid-input" : ""}
                disabled
              />

              {!isPriceWithGstValid && (
                <div className="error-message">
                  Price with GST cannot be empty
                </div>
              )}
            </div>

            <div className={"col-md"}>
              <MDBInput
                label={
                  <>
                    Purchase Discount %
                    <span className="required-asterisk">*</span>
                  </>
                }
                value={purchaseDiscountValue}
                onChange={(e) => handleChange(e, "purchaseDiscount")}
                onBlur={() => validatePurchaseDiscount(purchaseDiscountValue)}
                maxLength={50}
                className={!isPurchaseDiscountValid ? "invalid-input" : ""}
              />

              {!isPurchaseDiscountValid && (
                <div className="error-message">
                  Purchase discount cannot be empty
                </div>
              )}
            </div>

            <div className={"col-md"}>
              <MDBInput
                label={
                  <>
                    HSN/SAC <span className="required-asterisk">*</span>
                  </>
                }
                value={hsnSac}
                onChange={(e) => {
                  setHsnSac(e.target.value);

                  validateHsnSac(e.target.value);
                }}
                onBlur={() => validateHsnSac(hsnSac)}
                maxLength={50}
                className={!isHsnSacValid ? "invalid-input" : ""}
              />

              {!isHsnSacValid && (
                <div className="error-message">HSN/SAC cannot be empty</div>
              )}
            </div>
          </MDBRow>

          <MDBRow>
            <div className={"col-md"}>
              <MDBInput
                label={
                  <>
                    Mfg Date (MM/YYYY)
                    <span className="required-asterisk">*</span>
                  </>
                }
                value={mfgDate}
                onChange={(e) => {
                  handleChange(e, "mfgDate");

                  validateMfgDate(e.target.value);
                }}
                onBlur={() => validateMfgDate(mfgDate)}
                maxLength={50}
                className={!isMfgDateValid ? "invalid-input" : ""}
              />

              {!isMfgDateValid && (
                <div className="error-message">
                  Manufacturing date cannot be empty
                </div>
              )}
            </div>

            <div className={"col-md"}>
              <MDBInput
                label={
                  <>
                    Exp Date (MM/YYYY)
                    <span className="required-asterisk">*</span>
                  </>
                }
                value={expdate}
                onChange={(e) => {
                  handleChange(e, "expdate");

                  validateExpDate(e.target.value);
                }}
                onBlur={() => validateExpDate(expdate)}
                maxLength={50}
                className={!isExpDateValid ? "invalid-input" : ""}
              />

              {!isExpDateValid && (
                <div className="error-message">
                  Expiration date cannot be empty
                </div>
              )}
            </div>

            <div className={"col-md"}>
              <MDBInput
                label={
                  <>
                    Exp After Months{" "}
                    <span className="required-asterisk">*</span>
                  </>
                }
                value={expiringAfterMonth}
                getValue={(e) => {
                  handleChange(e, "expiringAfterMonth");
                }}
                maxLength={50}
                disabled
              />
            </div>

            <div className={"col-md"}>
              {selectedProduct !== null && (
                <>
                  <MDBInput
                    value={packageQnty}
                    label={
                      "No of " +
                      selectedProduct.retailPackage +
                      " X " +
                      selectedProduct.contentQuantity +
                      " " +
                      selectedProduct.quantityUnit
                    }
                    onChange={(e) => {
                      handleChange(e, "packageQnty");
                    }}
                    maxLength={50}
                  />
                </>
              )}
            </div>

            <div className={"col-md"}>
              <MDBInput
                label={
                  <>
                    Quantity <span className="required-asterisk">*</span>
                  </>
                }
                value={quantity}
                key={quantity}
                disabled={!selectedProduct}
              />
            </div>

            <div className={"col-md"}>
              <MDBInput
                label={
                  <>
                    Free Quantity <span className="required-asterisk">*</span>
                  </>
                }
                value={freeQuantity}
                onChange={(e) => {
                  handleChange(e, "freePackageQnty");
                }}
                disabled={!selectedProduct}
              />

              <span>Total Free items: {freeQuantity ? freeQuantity : 0}</span>
            </div>
          </MDBRow>
          <MDBRow>
            <div className={"col-md"}>
              <MDBInput
                type={"textarea"}
                background
                size="sm"
                label={"Comment related to storage"}
                value={commentStorage}
                onChange={(e) => {
                  setCommentStorage(e.target.value);
                }}
              />
            </div>
            <div className={"col-md"}>
              <MDBInput
                type={"textarea"}
                background
                size="sm"
                label={"Comment"}
                value={comment}
                onChange={(e) => {
                  setComment(e.target.value);
                }}
              />
            </div>
          </MDBRow>
          <MDBRow className="justify-content-center">
            {selectedProduct !== null && (
              <MDBTable className="mx-auto" bordered>
                <MDBTableHead>
                  <tr>
                    <th>Product Name</th>

                    <th>Select storage location</th>

                    <th>Select floor</th>

                    <th>Select Rack</th>

                    <th>Select Box</th>

                    <th>Barcode</th>
                  </tr>
                </MDBTableHead>

                {selectedProduct !== null && (
                  <MDBTableBody search={true}>
                    {Array.from({ length: totalQuantity }, (_, index) => (
                      <tr key={index}>
                        <td className="text-center align-middle">
                          {selectedProduct.productName}
                        </td>

                        <td className="text-center align-middle">
                          <FormControl
                            sx={{ m: 1, minWidth: 120 }}
                            size="small"
                          >
                            <Select
                              labelId="Storage-location"
                              id="StorageLocation"
                              value={selectedLoc[index]}
                              label="Choose your option"
                              onChange={(e) => {
                                handleSelectStorageLoc(e, index);
                              }}
                              style={{ width: "160px" }}
                            >
                              {locSuggestion.map((loc, idx) => (
                                <MenuItem key={idx} value={loc._id}>
                                  {loc.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </td>

                        <td className="text-center align-middle">
                          <FormControl
                            sx={{ m: 1, minWidth: 120 }}
                            size="small"
                          >
                            <Select
                              labelId="Storage-Floor"
                              id="StorageFloor"
                              value={selectedFloor[index]}
                              label="Choose your option"
                              onChange={(e) => {
                                handleSelectFloor(e, index);
                              }}
                              style={{ width: "160px" }}
                            >
                              {floorSuggestion[index] ? (
                                floorSuggestion[index].map((floor, idx) => (
                                  <MenuItem key={idx} value={floor._id}>
                                    {floor.floorName}
                                  </MenuItem>
                                ))
                              ) : (
                                <MenuItem value="" disabled>
                                  No floors available
                                </MenuItem>
                              )}
                            </Select>
                          </FormControl>
                        </td>

                        <td className="text-center align-middle">
                          <FormControl
                            sx={{ m: 1, minWidth: 120 }}
                            size="small"
                          >
                            <Select
                              labelId="Storage-Rack"
                              id="Storagerack"
                              value={selectedRack[index]}
                              label="Choose your option"
                              onChange={(e) => {
                                handleSelectRack(e, index);
                              }}
                              style={{ width: "160px" }}
                            >
                              {rackSuggestions[index] ? (
                                rackSuggestions[index].map((rack, idx) => (
                                  <MenuItem key={idx} value={rack._id}>
                                    {rack.rackName}
                                  </MenuItem>
                                ))
                              ) : (
                                <MenuItem value="" disabled>
                                  No rack available
                                </MenuItem>
                              )}
                            </Select>
                          </FormControl>
                        </td>

                        <td className="text-center align-middle">
                          <FormControl
                            sx={{ m: 1, minWidth: 120 }}
                            size="small"
                          >
                            <Select
                              labelId="Storage-Box"
                              id="StorageBox"
                              value={selectedBox[index]}
                              label="Choose your option"
                              onChange={(e) => {
                                handleSelectBox(e, index);
                              }}
                              style={{ width: "160px" }}
                            >
                              {boxSuggestions[index] ? (
                                boxSuggestions[index].map((box, idx) => (
                                  <MenuItem key={idx} value={box._id}>
                                    {box.boxName}
                                  </MenuItem>
                                ))
                              ) : (
                                <MenuItem value="" disabled>
                                  No box available
                                </MenuItem>
                              )}
                            </Select>
                          </FormControl>
                        </td>
                        <td>
                          <div className="">
                            <MDBInput
                              value={barCode[index]}
                              maxLength={30}
                              disabled
                            />

                            {/* <MDBBtn

                                onClick={() => {

                                  generateBarcodeData(index);

                                }}

                                size={"sm"}

                              >

                                Generate Barcode

                              </MDBBtn> */}

                            <MDBBtn
                              onClick={() => {
                                toCopy(barCode[index]);
                              }}
                              size={"sm"}
                            >
                              Copy
                            </MDBBtn>
                          </div>

                          <div className="">
                            <MDBBox ref={(el) => (this.componentRef = el)}>
                              {barCode[index] !== undefined ? (
                                <Barcode
                                  key={index}
                                  value={barCode[index]}
                                ></Barcode>
                              ) : (
                                "No Data"
                              )}
                            </MDBBox>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </MDBTableBody>
                )}
              </MDBTable>
            )}
          </MDBRow>
          <div className="m-0 text-center">
            <Link to={"/fetch-stock"}>
              {" "}
              <MDBBtn size={"md"} outline>
                Back
              </MDBBtn>
            </Link>

            <MDBBtn
              size={"md"}
              onClick={() => {
                save();
              }}
            >
              Save
            </MDBBtn>
          </div>
        </MDBCard>
      </div>
    </>
  );
}
