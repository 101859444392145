import React, { useEffect, useRef, useState } from "react";
import config from "../../config";
import utility from "../../utility/utility";
import { MDBBtn, MDBCol, MDBIcon, MDBInput, MDBRow } from "mdbreact";
import AuthService from "../../services/authService";
import UserStore from "../../stores/userStore";

const EditInvoice = ({
  data: order,
  taxableAmounts,
  totalCgst,
  totalDiscount,
  subTotal,
  serialNoArray,
}) => {
  const [userData,setUserData] = useState([])
  const [suggestedUser, setSuggestedUser] = useState([])
  const [searchedCustomerText, setSearchedCustomerText] = useState(order?.customerDetails?.name)
  const [previousSelectedCustomer, setPreviousSelectedCustomer] = useState(order?.customerDetails?.name)
  const [showDoctor, setShowDoctor] = useState([]);
  const [searchedProviderText, setSearchedProviderText] = useState("");
  const [allSuggestedProvider, setAllSuggestedProvider] = useState({});

  const [customerInfo, setCustomerInfo] = useState({
    name: order?.customerDetails?.name || "",
    phone: order?.shippingAddress?.phone || "",
  });
  const [shippingAddress, setShippingAddress] = useState({
    addressLine1: order?.shippingAddress?.addressLine1 || "",
    addressLine2: order?.shippingAddress?.addressLine2 || "",
    name: order?.shippingAddress?.name || "",
    city: order?.shippingAddress?.city || "",
    state: order?.shippingAddress?.state || "",
    pincode: order?.shippingAddress?.pinCode || "",
    country: order?.shippingAddress?.country || "",
    phone: order?.shippingAddress?.phone || "",
  });
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  const [medicineInfo, setMedicineInfo] = useState([...order?.cartData] || []);

  const customerInfoChangeHandler = (e) => {
    setCustomerInfo((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const shippingAddressChangeHandler = (e) => {
    setShippingAddress((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const editFormHandler = (e) => {
    e.preventDefault();
    const payload = {
      customer_info: customerInfo,
      shipping_address: shippingAddress,
      medicine_info: medicineInfo,
    };
    console.log(payload);
  };

  const getSerialNo = (index, index1) => {
    for (let item of serialNoArray) {
      if (index === item.index && index1 === item.index1) {
        return item.serialNo;
      }
    }
  };

  const getAmount = (mrp, disc) => {
    return mrp - (mrp * disc) / 100;
  };

  const fetchProvider = async (e, searchText) => {
    try {
      if (e.target.value !== 0 || e.target.value !== "0") {
        if (searchText === "forAll") {
          setSearchedProviderText(e.target.value);
        } else {
          setSearchedProviderText("");
        }

        let suggestedProvider = await AuthService.searchProviderNew(
          e.target.value
        );
        setAllSuggestedProvider(suggestedProvider);
      }
    } catch (e) {
      console.log(e);
      setAllSuggestedProvider([]);
    }
  };

  const selectDoctor = (row, index) => {
    const updatedMedicineInfo = [...medicineInfo];
    updatedMedicineInfo[index] = {
      ...updatedMedicineInfo[index],
      doctor_info: row,
    };
    setMedicineInfo(updatedMedicineInfo);
    setSearchedProviderText("");
  };

  const removeSelectedDoctor = (index) => {
    const updatedMedicineInfo = [...medicineInfo];
    updatedMedicineInfo[index] = {
      ...updatedMedicineInfo[index],
      doctor_info: "",
    };
    setMedicineInfo(updatedMedicineInfo);
  };

  const handleCustomerSearch = async (e) => {
    setIsDropdownVisible(false)
    setSearchedCustomerText(e.target.value)
    if(e.target.value.length>1){
      setIsDropdownVisible(true)
      let resUser = await AuthService.suggestCustomerNew(e.target.value, userData.sessionToken);
      let suggestedUser = [];
      for (let item of resUser) {
          if ((item.hasOwnProperty("name") && item.name) || (item.hasOwnProperty("first_name") && item.first_name)) {
              suggestedUser.push(item)
          }
      }
      setSuggestedUser(suggestedUser);
    }
   
}

const customerSelectHandler = (customer) => {
  setCustomerInfo((prev) => ({
    ...prev,
    name: customer.name,
    phone: customer.contactNo
  }));
  setSearchedCustomerText(customer.name)
  setPreviousSelectedCustomer(customer.name)
  setSuggestedUser([])
}
const customerBlurHandler = () => {
  if(suggestedUser.length!==0){
    setSearchedCustomerText(previousSelectedCustomer)
  }
}

useEffect(()=>{
  async function fetchData(){
    let userData = await UserStore.fetchSessionData();
    setUserData(userData)
  }

  fetchData()

},[])

useEffect(() => {
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownVisible(false);
    }
  };
  document.addEventListener('mousedown', handleClickOutside);
  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };
}, []);

  return (
    <div>
      <div className={"col-12 row mx-0 text-center"}>
        <div className={"col-md-12 m-auto"}>
          <h4>
            {order.status == "CANCELLED" ? (
              <label style={{ color: "red" }}>
                Order Has Been Cancelled : <b>Invalid Invoice</b>
              </label>
            ) : (
              "Edit Tax Invoice"
            )}
          </h4>
        </div>
      </div>
      <div className={"col-12 row mx-0 my-2"}>
        <div className={"col-4 m-0 p-0"}>
          <img src={"/positra_rx_logo.svg"} width={"150px"} />
          <p className={"mt-2"}>
            1st Floor
            <br />
            Lakhimandir Market Complex, Beltola <br />
            Guwahati, Assam, 781028, India <br />
            Drug licence number: D/OL/KMP/18760 & D/OL/KMP/18761
            <br />
            Pharmacist: Sahidul Alam(15080)
          </p>
        </div>
        {order?.deliveryType?.toUpperCase() !== "PICKUP" && (
          <div className="col-4">
            <div className="row">
              <div className="col-lg-10 col-md-11 col-11">
                <h6 className="font-weight-bold">Shipping From</h6>
                <p>
                  1st Floor
                  <br />
                  Lakhimandir Market Complex, Beltola <br />
                  Guwahati, Assam, 781028, India <br />
                </p>
              </div>
            </div>
          </div>
        )}
        <div className={"col-4 m-0 p-0 text-left"}>
          <p>
            <b>Invoice Date:</b>{" "}
            {order.hasOwnProperty("invoiceDate")
              ? utility.convertToReadbleDateTimeUtc(order?.invoiceDate)
              : ""}
            <br />
            <b>GSTIN :</b> <span>18AAICP6875B1Z5</span> <br />
            <b>Invoice #:</b>{" "}
            <span className={"text-uppercase"}>
              {order.hasOwnProperty("invoiceId") ? order?.invoiceId : ""}
            </span>
            <br />
            <b>Transaction Mode :</b>{" "}
            {order.paymentMode === "COD" ? "CASH" : "Online Payment"} <br />
            <b>Order Id:</b>{" "}
            <span className={"text-uppercase"}>{order?.orderCode}</span>
          </p>
        </div>
      </div>
      {/* EDIT FORM */}
      <form className="col-12">
        {/* CUSTOMER DETAILS EDIT */}
        <div className={" white rounded-sm border p-0"}>
          <div
            className={
              "col-12 p-2 grey lighten-4 border-bottom d-flex justify-content-between align-items-center mb-2"
            }
          >
            <b className={"title"}>Customer Details</b>
          </div>
          <div className={"col-12 row m-0 p-0"}>
            <div className={"col-sm-4"}>
              <MDBInput
                id={"name"}
                label="Name"
                name="name"
                value={searchedCustomerText}
                maxLength={"40"}
                onChange={handleCustomerSearch}
                onBlur={customerBlurHandler}
              />
              {suggestedUser.length > 0 && isDropdownVisible && (
                  <div className={"col-12"}  ref={dropdownRef}>
                    <div
                      className="card  position-absolute mb-0 pb-0"
                      style={{ zIndex: 100 }}
                    >
                      <ul className="list-group list-group-flush scroll-list">
                        {suggestedUser.map((row, index) => (
                          <li key={index} className="list-group-item">
                            <MDBRow style={{ fontSize: "13px" }}>
                              <MDBCol size={"2"}>
                                {row.hasOwnProperty("profilePic") &&
                                row.profilePic ? (
                                  <img
                                    width={"40"}
                                    alt={""}
                                    src={
                                      config.JAVA_IMAGE_HOST + row.profilePic
                                    }
                                  />
                                ) : (
                                  <img
                                    width={"40"}
                                    alt={""}
                                    src={require("../../components/common/img/profile-pic-user.png")}
                                  />
                                )}
                              </MDBCol>
                              <MDBCol size={"6"}>
                                {row.hasOwnProperty("name") && row.name && (
                                  <div>{row.name}</div>
                                )}
                                {row.hasOwnProperty("contactNo") &&
                                  row.contactNo && <div>{row.contactNo}</div>}
                              </MDBCol>
                              <MDBCol size={"3"}>
                                <MDBBtn
                                  size={"sm"}
                                  onClick={() => customerSelectHandler(row)}
                                >
                                  Select
                                </MDBBtn>
                              </MDBCol>
                            </MDBRow>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
            </div>
            <div className={"col-sm-4"}>
              <MDBInput
                id={"phone"}
                label="Phone"
                name="phone"
                outline
                value={customerInfo?.phone}
                type="number"
                maxLength={"40"}
                onChange={customerInfoChangeHandler}
              />
            </div>
          </div>
        </div>

        {/* SHIPPING TO EDIT */}
        <div className={"white rounded-sm border mt-2"}>
          <div
            className={
              "col-12 p-2 grey lighten-4 border-bottom d-flex justify-content-between align-items-center mb-2"
            }
          >
            <b className={"font-weight-bold"}>Shipping To Address</b>
          </div>
          <div className={"col-12 row m-0 p-0"}>
            <div className={"col-sm-6"}>
              <MDBInput
                id={"address_line_one"}
                label="Address Line One"
                name="addressLine1"
                outline
                value={shippingAddress?.addressLine1}
                onChange={shippingAddressChangeHandler}
                maxLength={"40"}
              />
            </div>
            <div className={"col-sm-6 "}>
              <MDBInput
                id={"address_line_two"}
                label="Address Line Two"
                outline
                name="addressLine2"
                value={shippingAddress?.addressLine2}
                onChange={shippingAddressChangeHandler}
                maxLength={"40"}
              />
            </div>
          </div>
          <div className={"col-12 row m-0 p-0"}>
            <div className={"col-3"}>
              <MDBInput
                id={"shipping_name"}
                label="Name"
                name="name"
                value={shippingAddress?.name}
                onChange={shippingAddressChangeHandler}
                maxLength={"40"}
              />
            </div>
            <div className={"col-sm-3"}>
              <MDBInput
                id={"city"}
                label="City"
                name="city"
                outline
                value={shippingAddress?.city}
                onChange={shippingAddressChangeHandler}
                maxLength={"40"}
              />
            </div>
            <div className={"col-sm-3 "}>
              <MDBInput
                id={"state"}
                label="State"
                name="state"
                outline
                value={shippingAddress?.state}
                onChange={shippingAddressChangeHandler}
                maxLength={"40"}
              />
            </div>
            <div className={"col-sm-3 "}>
              <MDBInput
                id={"pincode"}
                label="PINCODE"
                name="pincode"
                outline
                value={shippingAddress?.pincode}
                onChange={shippingAddressChangeHandler}
                maxLength={"40"}
              />
            </div>
            <div className={"col-sm-3 "}>
              <MDBInput
                id={"country"}
                label="Country"
                name="country"
                outline
                value={shippingAddress?.country}
                onChange={shippingAddressChangeHandler}
                maxLength={"40"}
              />
            </div>
            <div className={"col-sm-3"}>
              <MDBInput
                id={"phone"}
                label="Phone"
                name="phone"
                outline
                value={shippingAddress?.phone}
                onChange={shippingAddressChangeHandler}
                type="number"
                maxLength={"40"}
              />
            </div>
          </div>
        </div>

        <section>
          <div className="table-responsive p-3">
            <table className="table table-sm" cellSpacing="0">
              <thead>
                <tr>
                  <th className="font-weight-bold">Sl.</th>
                  <th className="px-0 font-weight-bold">
                    <strong>Product Description</strong>
                  </th>
                  <th className="px-0 font-weight-bold">
                    <strong>Select Doctor</strong>
                  </th>
                  <th className="px-0 font-weight-bold">
                    <strong>HSN</strong>
                  </th>
                  <th className="px-0 font-weight-bold">
                    <strong>Batch</strong>
                  </th>
                  <th className="px-0 font-weight-bold">
                    <strong>Exp.</strong>
                  </th>
                  <th className="px-0 font-weight-bold">
                    <strong>
                      M.R.P (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: config.CURRENCY_HTML,
                        }}
                      ></span>
                      )
                    </strong>
                  </th>
                  <th className="px-0 font-weight-bold">
                    <strong>Qty.</strong>
                  </th>
                  <th className="px-0 font-weight-bold">
                    <strong>Disc. %</strong>
                  </th>
                  <th className="px-0 font-weight-bold">
                    <strong>GST %</strong>
                  </th>
                  <th className="px-0 font-weight-bold">
                    <strong>
                      Amount (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: config.CURRENCY_HTML,
                        }}
                      ></span>
                      )
                    </strong>
                  </th>
                </tr>
              </thead>
              <tbody className={"text-dark"}>
                {medicineInfo?.map(
                  (row, indexMain) =>
                    row.hasOwnProperty("inventory") &&
                    row.inventory.map((item, index1) => (
                      <tr key={indexMain + "_" + index1}>
                        <td>{getSerialNo(indexMain, index1)}</td>
                        <td className={"px-0"}>{row.productName}</td>
                        <td className={"px-0"}>
                          {showDoctor.includes(indexMain + "_" + index1) &&
                          !row?.doctor_info ? (
                            <div className="d-flex align-items-center gap-2">
                              <button
                                type="button"
                                className="btn btn-md btn-default"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="close"
                                onClick={() =>
                                  setShowDoctor((prevIndexes) =>
                                    prevIndexes.filter((i) => i !== indexMain + "_" + index1)
                                  )
                                }
                              >
                                cancel
                              </button>
                              <div className="col-md-8">
                                <input
                                  placeholder="Search doctor by name, phone or e-mail"
                                  outline={true}
                                  className={"mt-0 form-control w-100"}
                                  maxLength="100"
                                  type="text"
                                  onChange={(e) => fetchProvider(e, "forAll")}
                                  value={searchedProviderText}
                                />
                                {allSuggestedProvider.length > 0 &&
                                  searchedProviderText && (
                                    <ul className="list-group list-group-flush doctor-list-dropdown border">
                                      {allSuggestedProvider.map(
                                        (item, index) => (
                                          <li
                                            key={index}
                                            className="font-12 list-group-item d-flex justify-content-between align-items-center"
                                          >
                                            <div>
                                              {item.hasOwnProperty("name") &&
                                                item.name && (
                                                  <b>
                                                    {item.name}
                                                    <br />
                                                  </b>
                                                )}
                                              {item.hasOwnProperty(
                                                "contactNo"
                                              ) &&
                                                item.contactNo && (
                                                  <span>{item.contactNo}</span>
                                                )}
                                            </div>
                                            <div>
                                              <a
                                                className={"primary-text"}
                                                onClick={() =>
                                                  selectDoctor(item, indexMain)
                                                }
                                              >
                                                Select
                                              </a>
                                            </div>
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  )}
                              </div>
                            </div>
                          ) : (
                            <>
                              {row?.doctor_info ? (
                                <div
                                  className={
                                    "rounded grey lighten-5 d-flex justify-content-between col-md-10 align-items-center"
                                  }
                                >
                                  <div>
                                    <b>Doctor Name:</b> {row?.doctor_info?.name}{" "}
                                    <br />
                                    <b>Doctor Phone:</b>{" "}
                                    {row?.doctor_info?.contactNo}
                                  </div>
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-default"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Remove Doctor"
                                    onClick={() =>
                                      removeSelectedDoctor(
                                        indexMain + "_" + index1
                                      )
                                    }
                                  >
                                    remove
                                  </button>
                                </div>
                              ) : (
                                <MDBBtn
                                  color="success"
                                  onClick={() =>
                                    setShowDoctor([
                                      ...showDoctor,
                                      indexMain + "_" + index1,
                                    ])
                                  }
                                >
                                  Add new Doctor
                                </MDBBtn>
                              )}
                            </>
                          )}
                        </td>
                        <td className={"px-0"}>{item.hsn}</td>
                        <td className={"px-0"}>{item.batch}</td>
                        <td className={"px-0"}>
                          {utility.convertMfgOrExpiry(item.expDate)}
                        </td>
                        <td className={"px-0"}>{item.mrp.toFixed(2)}</td>
                        <td className={"px-0"}>
                          <span className="qty">{item.quantityOut}</span>
                        </td>
                        <td className={"px-0"}>{row.discount}</td>
                        <td className={"px-0"}>{item.gst}</td>
                        <td className={"px-0"}>
                          {(
                            getAmount(item.mrp, row.discount) * item.quantityOut
                          ).toFixed(2)}
                        </td>
                      </tr>
                    ))
                )}
              </tbody>
            </table>
            <table className="table table-sm mt-n3" cellSpacing="0">
              <thead>
                <tr>
                  <th>
                    <strong>SGST</strong>
                  </th>
                  <th>
                    <strong>CGST</strong>
                  </th>
                  <th></th>
                  <th></th>
                  <th>
                    <strong>TOTALS</strong>
                  </th>
                </tr>
              </thead>
              <tbody className={"text-dark"}>
                <tr>
                  <td style={{ paddingLeft: "0px" }}>
                    <table
                      className={"table table-sm table-borderless mb-n2 mt-n1"}
                      cellSpacing={"0"}
                    >
                      <thead>
                        <tr>
                          <th className={"text-left"}>GST Rate %</th>
                          <th className={"text-center"}>
                            Taxable (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: config.CURRENCY_HTML,
                              }}
                            ></span>
                            )
                          </th>
                          <th className={"text-right"}>
                            Tax (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: config.CURRENCY_HTML,
                              }}
                            ></span>
                            )
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {taxableAmounts.map((i, index) => (
                          <tr key={index}>
                            <td className={"text-left"}>{i.gst}</td>
                            <td className={"text-center"}>
                              {i.taxable?.toFixed(2)}
                            </td>
                            <td className={"text-right"}>
                              {i.tax?.toFixed(2)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </td>
                  <td>
                    <table
                      className={"table table-sm table-borderless mb-n2 mt-n1"}
                      cellSpacing={"0"}
                    >
                      <thead className={"grey lighten-4"}>
                        <tr>
                          <th className={"text-left"}>GST Rate %</th>
                          <th className={"text-center"}>
                            Taxable (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: config.CURRENCY_HTML,
                              }}
                            ></span>
                            )
                          </th>
                          <th className={"text-right"}>
                            Tax (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: config.CURRENCY_HTML,
                              }}
                            ></span>
                            )
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {taxableAmounts.map((i, index) => (
                          <tr key={index}>
                            <td className={"text-left"}>{i.gst}</td>
                            <td className={"text-center"}>
                              {i.taxable?.toFixed(2)}
                            </td>
                            <td className={"text-right"}>
                              {i.tax?.toFixed(2)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </td>
                  <td colSpan={"2"} className={"border-0"}></td>
                  <td style={{ paddingRight: "0px" }}>
                    <table
                      className={"table table-sm table-borderless mt-n2 mb-n2"}
                      cellSpacing={"0"}
                    >
                      <tbody>
                        <tr>
                          <td className={"text-left border-right-0"}>
                            <strong>SUB TOTAL</strong>
                            <br />
                            <strong>Discount</strong>
                            <br />
                            {/*<strong>SGST</strong><br/>*/}
                            {/*<strong>CGST</strong><br/>*/}
                            <strong>Shipping</strong>
                            <br />
                            {order.hasOwnProperty("couponDiscount") &&
                              order.couponDiscount !== 0 && (
                                <>
                                  <strong>Coupon Discount</strong>
                                  <br />
                                </>
                              )}
                            <strong>Additional Discount</strong>
                            <br />
                          </td>
                          <td className={"text-right border-left-0"}>
                            {(
                              parseFloat(subTotal) +
                              parseFloat(totalCgst) +
                              parseFloat(totalCgst)
                            )?.toFixed(2)}
                            <br />
                            {totalDiscount?.toFixed(2)}
                            <br />
                            {/*{this.state.totalCgst.toFixed(2)}<br/>*/}
                            {/*{this.state.totalCgst.toFixed(2)}<br/>*/}
                            {order?.shippingAmount.toFixed(2)}
                            <br />
                            {order.hasOwnProperty("couponDiscount") &&
                              order.couponDiscount !== 0 && (
                                <>
                                  {order?.couponDiscount?.toFixed(2)} <br />
                                </>
                              )}
                            {order?.flatDiscount?.toFixed(2)}
                            <br />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className={"float-left"}>
                      <strong>Total</strong>
                    </span>
                    <span className={"float-right"}>
                      <strong>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: config.CURRENCY_HTML,
                          }}
                        ></span>{" "}
                        {totalCgst?.toFixed(2)}
                      </strong>
                    </span>
                  </td>
                  <td>
                    <span className={"float-left"}>
                      <strong>Total</strong>
                    </span>
                    <span className={"float-right"}>
                      <strong>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: config.CURRENCY_HTML,
                          }}
                        ></span>{" "}
                        {totalCgst?.toFixed(2)}
                      </strong>
                    </span>
                  </td>
                  <td colSpan={"2"} className={"border-0"}></td>
                  <td>
                    <span className={"float-left"}>
                      <h5>Grand Total</h5>
                    </span>
                    <span className={"float-right"}>
                      <h5>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: config.CURRENCY_HTML,
                          }}
                        ></span>{" "}
                        {order?.totalAmount?.toFixed(2)}
                      </h5>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
        <div className="d-flex justify-content-end mt-2">
          <MDBBtn onClick={editFormHandler} type="submit">
            Submit
          </MDBBtn>
        </div>
      </form>
      {/* EDIT FORM ENDS */}
    </div>
  );
};

export default EditInvoice;
