import React from "react";
import {
  MDBBtn,
  MDBCol,
  MDBIcon,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBRow,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOption,
  MDBSelectOptions,
} from "mdbreact";
import AuthService from "../../services/authService";
import AddDoctor from "../common/AddDoctor";
import { toast } from "react-toastify";
import config from "../../config";
import moment from "moment";
import UserStore from "../../stores/userStore";
import utility from "../../utility/utility";
import OrderService from "../../services/orderService";
import util from "../../utility/utility";
import PharmacyService from "../../services/pharmacyService";
import AddProductRequest from "./AddProductRequest";
import AddSubscription from "../ViewProductReqModal";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

class FulfilOnlineOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: props.hasOwnProperty("activeItem") ? props.activeItem : null,
      userData: [],
      searchedProviderText: "",
      allSuggestedProvider: {},
      modalProvider: false,
      doctorName: "",
      doctorContNo: "",
      selectedDoctorData: {},
      fulfilOrderDate: new Date(),
      modalBatch: false,
      activeBatchList: [],
      activeMedicine: {},
      actualAmount: 0,
      activeMedicineIndex: null,
      addedInventoryCount: 0,
      totalQuantityAdded: 0,
      loading: false,
      searchedProductList: [],
      responseArray: [],
      flatDiscount: 0,
      flatDiscountType: "INR",
      flatDiscountAmount: 0,
      finalAmount: 0,
      selectedMedDetails: [],
      couponDiscount: 0,
      couponData: null,
      shippingAmount: 0, //Rs
      shippingChargeConfig: null,
      codChargesApplied: false,
      prepayDiscountApplied: false,
      modalConvertOrder: false,
      amountWithShipping: 0,
      paymentModeToBe: "",
      codApplicable: false,
      removeCodCharges: false,
      searchBarcode: "",
      barcodeData: [],
      paymentModeList: [
        { text: "Cash", value: "CASH", checked: true },
        { text: "Credit", value: "CREDIT" },
        { text: "Card", value: "CARD" },
        { text: "Netbanking", value: "NETBANKING" },
        { text: "Wallet", value: "WALLET" },
      ],
      pymntMode: "CASH",
      internalComment: "",
      barCodeQueue:false,
      modalPurchaseRequest: false,
      product: null,
      modalAddSubscription: false,
      customerDetails: {},
    };
  }

  async componentDidMount() {
    let userData = await UserStore.fetchSessionData();
    this.setState({ userData });
    if (this.state.order) {
      this.setState({
        shippingChargeConfig: this.state.order.shippingEstimation,
      });
    }
    if (this.state.order.hasOwnProperty("flatDiscount")) {
      this.setState({
        flatDiscount: this.state.order.flatDiscount,
        flatDiscountAmount: this.state.order.flatDiscount,
      });
    }
    if (
      this.state.order.hasOwnProperty("couponData") &&
      this.state.order.couponData !== ""
    ) {
      this.setState({ couponData: this.state.order.couponData });
    }
    if (
      this.state.order.hasOwnProperty("isOrderFulfilled") &&
      this.state.order.isOrderFulfilled === true
    ) {
      await this.getActualAmountIfOrderFulfilled();
    }
    if (this.state.order.hasOwnProperty("customerDetails")) {
      this.setState({customerDetails: this.state.order.customerDetails});
    }
    this.setState({ internalComment: this.state.order.internalComment });
  }

  getShippingAmount = () => {
    if (this.state.shippingChargeConfig) {
      if (
        this.state.order.deliveryType === "SHIP" &&
        this.state.actualAmount <= this.state.shippingChargeConfig.shippingLimit
      ) {
        return this.state.shippingChargeConfig.shipping;
      }
      return 0;
    }
    return 0;
  };
  fetchProvider = (searchText) => async (e) => {
    try {
      if (e.target.value !== 0 || e.target.value !== "0") {
        if (searchText === "forAll") {
          this.setState({ searchedProviderText: e.target.value });
        } else {
          this.setState({ searchedProviderText: "" });
        }
        let suggestedProvider = await AuthService.searchProviderNew(
          e.target.value,
          this.state
        );
        this.setState({ allSuggestedProvider: suggestedProvider });
      }
    } catch (e) {
      this.setState({ allSuggestedProvider: [] });
    }
  };
  addDoctorCompleted = () => {
    toast.success("Doctor Added.");
    this.toggle("Provider")();
  };
  selectDoctor = (row) => {
    this.setState({
      doctorName: row.name,
      doctorContNo: row.contactNo,
      selectedDoctorData: row,
      searchedProviderText: "",
    });
  };
  removeSelectedDoctor = () => {
    this.setState({
      doctorName: "",
      doctorContNo: "",
      selectedDoctorData: {},
    });
  };
  getBatchList = () => {
    this.toggle("Batch")();
  };
  toggle = (nr) => () => {
    let modalNumber = "modal" + nr;
    this.setState({
      [modalNumber]: !this.state[modalNumber],
    });
  };
  handleBatch = (index) => {
    let activeBatchList = this.state.activeBatchList;
    activeBatchList[index].selected = !activeBatchList[index].selected;
    this.setState({ activeBatchList });
  };
  //handling + and - button operation in select bach page
  handleAddedQnty = (index, action) => {
    let activeBatchList = this.state.activeBatchList,
      temp = 0;
    if (action === "plus") {
      temp = activeBatchList[index].addedQnty + 1;
    } else {
      if (activeBatchList[index].addedQnty > 0) {
        temp = activeBatchList[index].addedQnty - 1;
      }
    }
    if (parseInt(activeBatchList[index].avaialableQnty) < temp) {
      return false;
    }
    activeBatchList[index].addedQnty = temp;
    if (temp > 0) {
      activeBatchList[index].selected = true;
    } else {
      activeBatchList[index].selected = false;
    }
    this.setState({ activeBatchList });
  };
  getTotalQuantityAdded = () => {
    let totalAddedQnty = 0;
    for (let item of this.state.activeBatchList) {
      if (item.selected === true && item.addedQnty > 0) {
        totalAddedQnty = totalAddedQnty + parseInt(item.addedQnty);
      }
    }
    return totalAddedQnty;
  };
  selectBatches = async (item, index) => {
    this.setState({ activeMedicine: item, activeMedicineIndex: index });
    let data = [];
    data = await OrderService.fetchInventoryById(
      this.state.userData.sessionToken,
      item._id
    );
    if (data === false) {
      toast.error("Something went wrong while getting the batch details");
      this.setState({ activeBatchList: [] });
    } else {
      //removing 0 quantity inventory
      let filteredProducts = [];
      for (let item of data) {
        let tempInv = [];
        for (let inv of item.inventory) {
          if (parseFloat(inv.quantity) >= 1) {
            inv.quantity = Math.floor(parseFloat(inv.quantity).toFixed(2));
            tempInv.push(inv);
          }
        }
        item.inventory = tempInv;
        filteredProducts.push(item);
      }
      data = filteredProducts;
      try {
        if (data.length > 0) {
          this.setState({ selectedMedDetails: data[0] });
          let activeBatchList = data[0].inventory;
          //if already batches has been selected
          if (this.state.activeMedicine.hasOwnProperty("inventory")) {
            for (let item1 of this.state.activeMedicine.inventory) {
              for (let item2 of activeBatchList) {
                //Selecting the inventories which were selected during order fulfilment
                if (item1._id === item2._id) {
                  item2.selected = true;
                  item2.addedQnty = item1.hasOwnProperty("quantity")
                    ? item1.quantity
                    : item1.quantityOut;
                }
                item2.avaialableQnty = Math.floor(item2.quantity);
              }
            }
            //Initializing the inventories which were not selected during order fulfilment
            for (let row of activeBatchList) {
              if (!row.hasOwnProperty("selected")) {
                row.selected = false;
                row.addedQnty = 0;
              }
            }
          } else {
            for (let item of activeBatchList) {
              item.selected = false;
              item.addedQnty = 0;
              item.avaialableQnty = Math.floor(item.quantity);
            }
          }
          this.setState({ activeBatchList });
        }
      } catch (e) {
        this.setState({ activeBatchList: [] });
        toast.error(
          "Something went wrong while getting the batch details of the product."
        );
      }
    }
    this.toggle("Batch")();
  };
  addBatchDetails = async () => {
    let totalAddedQnty = this.getTotalQuantityAdded();
    let order = this.state.order,
      inventory = [],
      subtotal = 0;
    for (let item of this.state.activeBatchList) {
      if (item.selected === true && item.addedQnty > 0) {
        inventory.push({
          _id: item._id,
          quantity: parseInt(item.addedQnty),
          productId: this.state.activeMedicine._id,
          batch: item.batch,
          mrp: item.mrp,
        });
        subtotal = subtotal + parseInt(item.addedQnty) * item.mrp;
      }
    }
    order.cartData[this.state.activeMedicineIndex].addedQnty = totalAddedQnty;
    order.cartData[this.state.activeMedicineIndex].inventory = inventory;
    order.cartData[this.state.activeMedicineIndex].subtotal = subtotal;
    order.cartData[this.state.activeMedicineIndex].subtotalFixed = subtotal; //**item.subtotalFixed
    await this.calculateActualAmount();
    this.setState({ order, activeBatchList: [], activeMedicineIndex: null });
    this.toggle("Batch")();
  };
  calculateActualAmount = async () => {
    //using **item.subtotalFixed, already calculated during batch selection, (to avoid for loop)
    let order = this.state.order,
      actualAmount = 0,
      finalAmount = 0,
      couponDiscount = 0;
    for (let item of order.cartData) {
      if (item.subtotal) {
        let subtotal = item.subtotalFixed;
        item.subtotal = subtotal - subtotal * (item.discount / 100);
        actualAmount = actualAmount + parseFloat(item.subtotal);
      }
    }
    await this.setState({ order, actualAmount });
    await this.calculateFinalAmount();
  };
  calculateFinalAmount = () => {
    let couponDiscount = 0,
      finalAmount = 0,
      shippingAmount = 0,
      codChargesApplied = false,
      prepayDiscountApplied = false;

    if (this.state.couponData !== null) {
      couponDiscount = this.getCouponDiscount();
    }
    if (this.state.order.deliveryType === "SHIP") {
      shippingAmount = this.getShippingAmount();
    }
    //shippingAmount added
    finalAmount = this.state.actualAmount + shippingAmount;
    // Check if eligible for COD charges or PREPAY discount
    if (
      this.state.order.paymentMode === "COD" &&
      this.state.order.deliveryType === "SHIP" &&
      this.state.actualAmount <= this.state.shippingChargeConfig.codLimit
    ) {
      finalAmount = finalAmount + this.state.shippingChargeConfig.cod;
      codChargesApplied = true;
    } else if (
      this.state.order.paymentMode === "PREAPY " &&
      this.state.order.isPaid === "Y" &&
      finalAmount >= this.state.shippingChargeConfig.prepayDiscountLimit
    ) {
      prepayDiscountApplied = true;
    }
    finalAmount = finalAmount - couponDiscount - this.state.flatDiscountAmount;
    this.setState({ finalAmount, codChargesApplied, prepayDiscountApplied });
  };
  calculateFinalShippingCharges = () => {
    let shippingCharge = this.getShippingAmount();
    if (this.state.codChargesApplied === true) {
      shippingCharge = shippingCharge + this.state.shippingChargeConfig.cod;
    }
    return shippingCharge;
  };
  getFulfilOrderPayload = () => {
    console.log(this.state.selectedDoctorData)
    let payload = {
      orderId: this.state.order._id,
      flatDiscount:
        this.state.flatDiscountAmount === ""
          ? 0
          : this.state.flatDiscountAmount,
      cartData: [],
      shippingAmount: this.calculateFinalShippingCharges(),
      // doctorInfo: {
      //   doctorName: this.state?.selectedDoctorData?.name,
      //   doctorId: this.state?.selectedDoctorData?._id
      // }
    };
    for (let item of this.state.order.cartData) {
      let product = {
        _id: item._id,
        maxRetailPrice: item.maxRetailPrice,
        discount: parseInt(item.discount),
        quantity: item.quantity,
        inventory: [],
      };
      for (let row of item.inventory) {
        let qnty = row.hasOwnProperty("quantity")
          ? row.quantity
          : row.quantityOut;
        product.inventory.push({ _id: row._id, quantity: qnty });
      }
      payload.cartData.push(product);
    }
    return payload;
  };
  getActualAmountIfOrderFulfilled = async () => {
    try {
      let actualAmount = 0,
          order = this.state.order;
      for (let item of order.cartData) {
        let subTotal = 0;
        for (let item1 of item.inventory) {
          subTotal = subTotal + item1.mrp * item1.quantityOut;
        }
        subTotal = subTotal - subTotal * (item.discount / 100);
        actualAmount = actualAmount + subTotal;
        item.subtotal = subTotal;
        item.subtotalFixed = subTotal;
      }
      await this.setState({actualAmount, order});
      this.calculateFinalAmount();
    }catch (e) {}
  };
  fulfilOrder = async () => {

    this.setState({ loading: true });
    let payload =  this.getFulfilOrderPayload();
    let res = await OrderService.fulfilOnlineOrder(
      this.state.userData.sessionToken,
      payload
    );
    if (res) {
      this.setState({ progressStatus: true });
      let order = await OrderService.fetchOnlineOrderById(
        this.state.userData.sessionToken,
        this.state.order._id
      );
      this.setState({ progressStatus: false });
      if (order) {
        this.setState({ order });
        await this.props.updateOrder();
      } else {
        toast.error("Something went wrong while fetching the order details.");
      }
      toast.success("The order has been fulfilled.");
    } else {
      toast.error("Something went wrong while fulfilling the order.");
    }
    this.setState({ loading: false });
  };
  disableFulfilOrderBtn = () => {
    let addedInv = 0;
    if (this.state.order.cartData.length === 0) {
      return true;
    }
    for (let item of this.state.order.cartData) {
      if (item.hasOwnProperty("inventory") && item.inventory.length > 0) {
        ++addedInv;
      }
    }
    if (this.state.order.status === "CANCELLED") {
      return true;
    } else if (addedInv < this.state.order.cartData.length) {
      return true;
    }
    return false;
  };
  handleDiscount = (index) => (e) => {
    if (e.target.value !== "" && !utility.isFloat(e.target.value)) {
      return false;
    }
    let order = this.state.order;
    order.cartData[index].discount = e.target.value;
    this.setState({ order });
    this.calculateActualAmount();
  };
  fetchProduct = async (e) => {
    this.setState({ searchedProductList: [] });
    try {
      if (e.target.value !== 0 || e.target.value !== "0") {
        this.setState({ searchedProductText: e.target.value });
        if (e.target.value.length < 2) {
          return false;
        }
        let responseArray = this.state.responseArray;
        let suggestedProduct = await PharmacyService.searchProductOrder(
          e.target.value,
          this.state
        );
        //removing 0 quantity inventory
        let filteredProducts = [];
        for (let item of suggestedProduct) {
          let tempInv = [];
          for (let inv of item.inventory) {
            if (parseFloat(inv.quantity) >= 1) {
              inv.quantity = Math.floor(parseFloat(inv.quantity).toFixed(2));
              tempInv.push(inv);
            }
          }
          item.inventory = tempInv;
          filteredProducts.push(item);
        }
        suggestedProduct = filteredProducts;
        responseArray.push(suggestedProduct);
        await this.setState({ responseArray });
        for (let i = 0; i < this.state.responseArray.length; i++) {
          let count = 0;
          for (let j = 0; j < this.state.responseArray[i].length; j++) {
            if (
              responseArray[i][j].productName
                .toUpperCase()
                .indexOf(e.target.value.toUpperCase()) > -1
            ) {
              ++count;
            }
          }
          if (count === this.state.responseArray[i].length) {
            let allSuggestedProduct = responseArray[i];
            this.setState({ searchedProductList: [] });
            let existingProduct = [];
            for (let product of allSuggestedProduct) {
              for (let inventoryItem of product.inventory) {
                let target0 = {};
                let newProductObj = Object.assign(target0, product);
                delete newProductObj.inventory;
                let target = {};
                newProductObj.inventory = Object.assign(target, inventoryItem);
                existingProduct.push(newProductObj);
              }
            }
            this.setState({
              searchedProductList: existingProduct,
              responseArray: [],
            });
            break;
          }
        }
      }
    } catch (e) {
      this.setState({ suggestedProduct: [{ title: "", value: "" }] });
    }
  };
  calculateUnit = (addedQuantity, item) => {
    try {
      // rp is retail package in lowercase
      let rp = item.retailPackage.toLowerCase();
      // rpu is the retail package unit, like strip, will assign the rp to rpu initially
      let rpu = rp;
      // cq is the content Quantity "10"
      let cq = item.contentQuantity;
      // qu quantity unit ex. capsule, tablets
      let qu = item.quantityUnit.toLowerCase();
      let quu = qu;
      // make sure to add a "s" in the end to pluralize it for these cases
      if (
        rp === "strip" ||
        rp === "bottle" ||
        rp === "packet" ||
        rp === "box" ||
        rp === "sachet" ||
        rp === "syringe" ||
        rp === "mask" ||
        rp === "pair" ||
        rp === "tube" ||
        rp === "device"
      ) {
        // if the user select more than one unit of the item, than make sure to pluralize it
        if (addedQuantity > 1) {
          if (rp === "box") {
            rpu = item.retailPackage.toLowerCase() + "es";
          } else {
            rpu = item.retailPackage.toLowerCase() + "s";
          }
        } else {
          rpu = item.retailPackage.toLowerCase();
        }
      }
      // make sure to pluralize the qunaitity unit by adding "s" in the end for these cases
      if (
        (qu === "tablet" ||
          qu === "capsule" ||
          qu === "respule" ||
          qu === "syringe" ||
          qu === "sachet" ||
          qu === "kilogram" ||
          qu === "tube" ||
          qu === "device" ||
          qu === "gram") &&
        item.contentQuantity > 1
      ) {
        quu = (qu + "s").toLowerCase();
      }
      // there are some exceptions
      else if (qu === "gm" && item.contentQuantity > 1) {
        quu = "grams";
      } else if (qu === "kilo" && item.contentQuantity > 1) {
        quu = "kilograms";
      }
      return rpu + " of " + cq + " " + quu;
    } catch (e) {
      return "";
    }
  };
  addItemToCartLocal = async (item) => {
    let order = this.state.order;
    for (let row of order.cartData) {
      if (row._id === item._id) {
        toast.error("Selected product already added to the cart.");
        return;
      }
    }
    let itemToBeAdded = this.getProductDetailsBeforeAddingToCart(item);
    order.cartData.push(itemToBeAdded);
    await this.setState({ order });
    this.closeSearch();
  };
  getProductDetailsBeforeAddingToCart = (addedItem) => {
    let item = {
      discount: addedItem.discount,
      imageUrl: addedItem.imageUrl,
      isMedicine: addedItem.isMedicine,
      maxRetailPrice: addedItem.inventory.mrp,
      medicineType: addedItem.medicineType,
      productName: addedItem.productName,
      quantity: 1,
      addedQnty: 0,
      userId: addedItem.userId,
      _id: addedItem._id,
    };
    return item;
  };
  closeSearch = (list) => {
    if (list === "doctor") {
      this.setState({ allSuggestedProvider: [] });
    } else {
      this.setState({ searchedProductList: [], searchedProductText: "" });
    }
  };
  deleteItemFromCart = async (index) => {
    let order = this.state.order;
    order.cartData.splice(index, 1);
    await this.setState({ order });
    await this.calculateActualAmount();
  };
  getSubtotal = (item) => {
    let subtotal = 0;
    if (item.hasOwnProperty("inventory")) {
      for (let i of item.inventory) {
        subtotal = subtotal + i.mrp * i.quantityOut;
      }
      subtotal = subtotal - subtotal * (item.discount / 100);
    }
    return subtotal.toFixed(2);
  };
  handleFlatDiscount = async (e) => {
    if (e.target.value !== "" && !utility.isFloat(e.target.value)) {
      return false;
    }
    this.setState({ flatDiscount: e.target.value });
    await this.calculateFlatDiscount(
      this.state.flatDiscountType,
      e.target.value
    );
  };
  getAddedQnty = (item) => {
    let addedQnty = 0;
    if (item.hasOwnProperty("inventory")) {
      for (let i of item.inventory) {
        addedQnty = addedQnty + i.quantityOut;
      }
      return addedQnty;
    }
    return addedQnty;
  };
  getFlatDiscountType = async (e) => {
    await this.setState({ flatDiscountType: e[0] });
    await this.calculateFlatDiscount(e[0], this.state.flatDiscount);
  };
  calculateFlatDiscount = async (type, discount) => {
    let flatDiscountAmount = 0,
      couponDiscount = 0;
    if (type === "INR") {
      flatDiscountAmount = discount;
    } else {
      flatDiscountAmount = (this.state.actualAmount * (discount / 100)).toFixed(
        2
      );
    }
    await this.setState({ flatDiscountAmount });
    await this.calculateFinalAmount();
  };
  getCouponDiscount = () => {
    let couponDiscount = 0;
    if (this.state.couponData.discountType === "PERCENTAGE") {
      couponDiscount = (
        this.state.actualAmount *
        (this.state.couponData.value / 100)
      ).toFixed(2);
    } else {
      couponDiscount = this.state.couponData.value;
    }
    return couponDiscount;
  };
  handleConvertOrder = () => {
    let paymentModeToBe =
      this.state.order.paymentMode === "COD" ? "PREPAY" : "COD";
    this.setState({ paymentModeToBe });
    this.toggle("ConvertOrder")();
  };
  convertOrder = async () => {
    this.setState({ loading: true });
    let payload = {};
    if (this.state.paymentModeToBe === "PREPAY") {
      payload = {
        orderId: this.state.order._id,
        paymentMode: this.state.paymentModeToBe,
        paymentTransactionMode: this.state.pymntMode,
      };
    } else {
      payload = {
        orderId: this.state.order._id,
        paymentMode: this.state.paymentModeToBe,
      };
    }
    let res = await OrderService.convertOnlineOrder(
      payload,
      this.state.userData.sessionToken
    );
    if (res) {
      toast.success("Order converted successfully.");
      this.toggle("ConvertOrder")();
      await this.props.updateOrder();
    } else {
      toast.error("Something went wrong while converting the order.");
    }
    this.setState({ loading: false });
  };

  // barcode search---
  fetchBarcode = async (e) => {
    await this.setState({searchBarcode: e.target.value});
    await delay(500);
    if (this.state.searchBarcode !== "") {
      if (this.state.barCodeQueue === false) {
        this.setState({barCodeQueue: true});
        let data = await PharmacyService.fetchStockDetailsV2(this.state.userData, this.state.searchBarcode);
        if (data) {
          let cartData = this.state.order.cartData;
          let discount = (parseFloat(data["discount"]) / 100) * parseFloat(data["mrp"]);
          let subTotal = parseFloat(data["mrp"]) - discount;
          let temp = {
            "_id": data.productId,
            "discount": parseFloat(data.discount),
            "imageUrl": data.imageUrl,
            "inventory": [
              {
                "_id": data.id,
                "batch": data.batch,
                "expDate": data.expDate,
                "gst": parseFloat(data.gst),
                "hsn": data.hsn,
                "mrp": parseFloat(data.mrp),
                "quantityOut": 1
              }
            ],
            "isMedicine": data.isMedicine,
            "maxRetailPrice": parseFloat(data.mrp),
            "medicineType": data.medicineType,
            "productName": data.productName,
            "quantity": 1,
            "subtotal": subTotal,
            "subtotalFixed": subTotal.toFixed(2)
          }
          cartData.push(temp)
          let tempData = this.state.order;
          tempData["cartData"] = cartData;
          this.setState({order: tempData});
          await this.getActualAmountIfOrderFulfilled();
        } else {
          toast.error("Stock not available in the system.");
        }
        this.setState({searchBarcode: ""});
        this.setState({barCodeQueue: false});
      }
    }
  };

  addBarcodeItemToCartLocal = async (item) => {
    let order = this.state.order;
    for (let row of order.cartData) {
      if (row._id === item.productDetails._id) {
        toast.error("Selected product already added to the cart.");
        return;
      }
    }

    let itemToBeAdded = this.getBarCodeDetailsBeforeAddTpCart(item);
    order.cartData.push(itemToBeAdded);
    await this.setState({ order });
    this.closeBarcodeSearch();
  };

  getBarCodeDetailsBeforeAddTpCart = (addedItem) => {
    let item = {
      discount: addedItem.discount,
      imageUrl: addedItem.productDetails.imageUrl,
      isMedicine: addedItem.productDetails.isMedicine,
      maxRetailPrice: addedItem.mrp,
      medicineType: addedItem.productDetails.medicineType,
      productName: addedItem.productDetails.productName,
      quantity: 1,
      addedQnty: 0,
      userId: addedItem.userId,
      _id: addedItem.productDetails._id,
    };
    return item;
  };

  closeBarcodeSearch = () => {
    this.setState({ barcodeData: [], searchBarcode: "" });
  };
  updateInternalComment = async () => {
    let payload = {
      orderId: this.state.order._id,
      comment: this.state.internalComment,
    };
    let status = PharmacyService.updateInternalComment(
      this.state.userData.sessionToken,
      payload
    );
    if (status === false) {
      toast.error("Comment has not been updated due to some technical error.");
    } else {
      toast.success("Comment has been updated successfully.");
    }
  };
  addPurchaseRequest = (item, index) => {
    this.setState({product: item});
    this.toggle("PurchaseRequest")();
  }
  addSubscription = (item, index) => {
    this.setState({product: item});
    this.toggle("AddSubscription")();
  }
  render() {
    const { order } = this.state;
    return (
      <React.Fragment>
        <div className={"col-12 p-0 fullfill-order-sticky my-3"}>
          {order !== null && (
            <div
              className={"col-12 row mx-0 p-0 white sticky-view-order-detail"}
            >
              <section>
                <h6 className="font-weight-bold mb-2 title">Order Details</h6>
                <p>
                  <b>Order ID:</b> {order.orderCode} <br />
                  <b>Order Date:</b>{" "}
                  {utility.convertToReadbleDateTimeUtc(order.createdAt)}
                </p>
              </section>
              <div className="col-md-3">
                <input
                  placeholder="Search doctor by name, phone or e-mail"
                  outline={true}
                  className={"mt-0 form-control"}
                  maxLength="100"
                  type="text"
                  onChange={this.fetchProvider("forAll")}
                  value={this.state.searchedProviderText}
                />
                {this.state.allSuggestedProvider.length > 0 &&
                  this.state.searchedProviderText && (
                    <ul className="list-group list-group-flush doctor-list-dropdown border">
                      {this.state.allSuggestedProvider.map((row, index) => (
                        <li
                          key={index}
                          className="font-12 list-group-item d-flex justify-content-between align-items-center"
                        >
                          <div>
                            {row.hasOwnProperty("name") && row.name && (
                              <b>
                                {row.name}
                                <br />
                              </b>
                            )}
                            {row.hasOwnProperty("contactNo") &&
                              row.contactNo && <span>{row.contactNo}</span>}
                          </div>
                          <div>
                            <a
                              className={"primary-text"}
                              onClick={() => this.selectDoctor(row)}
                            >
                              Select
                            </a>
                            &nbsp;&nbsp;
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => this.closeSearch("doctor")}
                            >
                              <MDBIcon icon="times-circle" />
                            </span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                <div className={"col-12 p-0 text-right"}>
                  <a
                    className={"primary-text"}
                    onClick={this.toggle("Provider")}
                  >
                    Add New Doctor
                  </a>
                </div>
                {this.state.doctorName !== "" && (
                  <div
                    className={
                      "rounded grey lighten-5 d-flex justify-content-between align-items-center my-2"
                    }
                  >
                    <div>
                      <b>Doctor Name:</b> {this.state.doctorName} <br />
                      <b>Doctor Phone:</b> {this.state.doctorContNo}
                    </div>
                    <button
                      type="button"
                      className="btn btn-sm btn-default"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Remove Doctor"
                      onClick={this.removeSelectedDoctor}
                    >
                      X
                    </button>
                  </div>
                )}
              </div>
              <div className={"col-md-3 vr"}>
                <input
                  type="text"
                  placeholder="Search Barcode"
                  className={"form-control"}
                  maxLength="20"
                  outline={true}
                  value={this.state.searchBarcode}
                  onChange={this.fetchBarcode}
                />

                {this.state.barcodeData.length > 0 &&
                  this.state.searchBarcode !== "" && (
                    <ul className="list-group list-group-flush  border">
                      {this.state.barcodeData.map((item, index) => (
                        <li key={index} className="list-group-item">
                          <div
                            className={"col-12 row mx-0 p-0"}
                            style={{ fontSize: "12px" }}
                          >
                            <div className={"col-3 m-0 p-0"}>
                              {item.productDetails.hasOwnProperty("imageUrl") &&
                                item.productDetails.imageUrl.length > 0 && (
                                  <>
                                    <div>
                                      <img
                                        width={"100"}
                                        alt={""}
                                        src={
                                          config.IMAGE_HOST +
                                          item.productDetails.imageUrl[0]
                                            .filePathThumb
                                        }
                                      />
                                    </div>
                                  </>
                                )}
                              {item.productDetails.hasOwnProperty("imageUrl") &&
                                item.productDetails.imageUrl.length === 0 && (
                                  <img
                                    alt={""}
                                    width={"100"}
                                    src={require("../../components/common/img/broken-img.jpg")}
                                  />
                                )}
                            </div>

                            <div className={"col-4 m-0 pl-3"}>
                              <h6>
                                <strong>
                                  {item.productDetails.productName}
                                </strong>
                              </h6>
                              {/*<div>Mfg: {utility.convertMfgOrExpiry(row.inventory.mfgDate)} - <b>Exp: {utility.convertMfgOrExpiry(row.inventory.expDate)}</b></div>*/}
                              <div>
                                <b>
                                  Exp:{" "}
                                  {utility.convertMfgOrExpiry(item.expDate)}
                                </b>
                              </div>
                              <div>
                                Contains: 1{" "}
                                {this.calculateUnit(1, item.productDetails)}
                              </div>
                            </div>
                            <div className={"col-2 m-0 p-0 text-left"}>
                              {item.productDetails.markAsoutOfStock === "N" && (
                                <div>
                                  <span className={"green-text"}>In Stock</span>
                                </div>
                              )}
                              {item.productDetails.markAsoutOfStock === "Y" && (
                                <div>
                                  <span className={"red-text"}>
                                    Out of Stock
                                  </span>
                                </div>
                              )}
                              <div>
                                MRP:
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: config.CURRENCY_HTML,
                                  }}
                                ></span>
                                {util.customRound(item.mrp)}
                              </div>
                              <div>Qty: {item.productDetails.quantity}</div>
                            </div>

                            <div className={"col-3 m-0 p-0 text-right"}>
                              <button
                                type="button"
                                className="btn btn-primary btn-sm"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Add"
                                onClick={() =>
                                  this.addBarcodeItemToCartLocal(item)
                                }
                              >
                                Add
                              </button>
                              <span
                                style={{ cursor: "pointer" }}
                                className={"p-2 mt-3"}
                                onClick={this.closeBarcodeSearch}
                              >
                                <MDBIcon icon="times-circle" />
                              </span>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
              </div>
              {(this.state.order.status === "CREATED" ||
                this.state.order.status === "ACKNOWLEDGED" ||
                this.state.order.status === "APPROVED" ||
                this.state.order.status === "REQUESTDOC" ||
                this.state.order.status === "READYFORDELIVERY") && (
                <div className={"col-md-1"}>
                  <a
                    className={"primary-text"}
                    onClick={() => this.handleConvertOrder()}
                  >
                    Convert Order
                  </a>
                </div>
              )}
            </div>
          )}
          {this.state.order.hasOwnProperty("internalComment") === true && (
            <div>
              <h5>
                <b>Internal Comment:</b>
              </h5>
              <textarea
                className="form-control rounded-0"
                rows="3"
                value={this.state.internalComment}
                onChange={(e) => {
                  this.setState({ internalComment: e.target.value });
                }}
              ></textarea>
              <div>
                <MDBBtn
                  onClick={() => {
                    this.updateInternalComment();
                  }}
                >
                  Update Comment
                </MDBBtn>
              </div>
            </div>
          )}
          <div className="col-12 p-0 table-responsive">
            <table className="table table-striped col-12 p-0">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Product View</th>
                  <th>Product Name</th>
                  <th>Type</th>
                  <th>MRP</th>
                  <th>Batch - MRP</th>
                  <th>Ordered Qty</th>
                  <th>Added Qty</th>
                  <th>Discount(%)</th>
                  <th>Subtotal</th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {order.cartData.map((row, index) => (
                  <tr key={index}>
                    <td>
                      <strong>{index + 1}</strong>
                    </td>
                    <td>
                      {row.hasOwnProperty("imageUrl") &&
                      row.imageUrl.length > 0 ? (
                        <div>
                          <img
                            src={
                              config.IMAGE_HOST + row.imageUrl[0].filePathThumb
                            }
                            style={{ width: "100px", height: "100px" }}
                            alt={"Med Image"}
                          />
                        </div>
                      ) : (
                        <div>
                          <img
                            src={"/broken-img.jpg"}
                            style={{ width: "100px", height: "100px" }}
                            alt={"Med Image"}
                          />
                        </div>
                      )}
                    </td>
                    <td>
                      <strong>{row.productName}</strong>
                    </td>
                    <td>
                      <strong>
                        {row.hasOwnProperty("isMedicine") &&
                          !row.isMedicine && <span>General</span>}
                        {row.hasOwnProperty("isMedicine") && row.isMedicine && (
                          <span>
                            {row.hasOwnProperty("medicineType") && (
                              <span
                                className={
                                  row.medicineType === "Schedule H" ||
                                  row.medicineType === "Schedule H1" ||
                                  row.medicineType === "Schedule X" ||
                                  row.medicineType === "PRESCRIPTION DRUGS" ||
                                  row.medicineType === "PRESCRIPTION DRUG"
                                    ? "red-text"
                                    : "green-text"
                                }
                              >
                                {row.medicineType === "PRESCRIPTION DRUGS" && (
                                  <span>Prescription Drug</span>
                                )}
                                {row.medicineType !== "PRESCRIPTION DRUGS" && (
                                  <span>{row.medicineType}</span>
                                )}
                              </span>
                            )}
                            {!row.hasOwnProperty("medicineType") && (
                              <span> Not Specified</span>
                            )}
                          </span>
                        )}
                        {!row.hasOwnProperty("isMedicine") &&
                          row.hasOwnProperty("medicineType") && (
                            <span
                              className={
                                row.medicineType === "Schedule H" ||
                                row.medicineType === "Schedule H1" ||
                                row.medicineType === "Schedule X"
                                  ? "red-text"
                                  : "green-text"
                              }
                            >
                              {" "}
                              {row.medicineType}
                            </span>
                          )}
                      </strong>
                    </td>
                    <td>
                      <strong>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: config.CURRENCY_HTML,
                          }}
                        ></span>{" "}
                        {row.maxRetailPrice.toFixed(2)}
                      </strong>
                    </td>
                    <td className={"text-right"}>
                      {row.hasOwnProperty("inventory") ? (
                        <>
                          {row.inventory.map((item, index1) => (
                            <small key={index1}>
                              <b>
                                {item.batch} -{" "}
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: config.CURRENCY_HTML,
                                  }}
                                ></span>{" "}
                                {item.mrp}
                              </b>
                              <br />
                            </small>
                          ))}
                        </>
                      ) : (
                        "N/A"
                      )}
                    </td>
                    <td>{row.quantity}</td>
                    <td>
                      {row.hasOwnProperty("addedQnty")
                        ? row.addedQnty
                        : this.getAddedQnty(row)}
                    </td>
                    <td>
                      <input
                        className={"form-control"}
                        style={{ width: "5rem" }}
                        outline={true}
                        maxLength="5"
                        type="text"
                        onChange={this.handleDiscount(index)}
                        value={row.discount}
                      />
                    </td>
                    <td>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: config.CURRENCY_HTML,
                        }}
                      ></span>{" "}
                      {row.hasOwnProperty("subtotal")
                        ? row.subtotal.toFixed(2)
                        : this.getSubtotal(row)}
                    </td>
                    <td>
                      <button
                        size="sm"
                        type="button"
                        className="btn btn-sm btn-primary"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Select Batch"
                        onClick={() => this.selectBatches(row, index)}
                      >
                       {row.hasOwnProperty("inventory") &&
                        row.inventory.length > 0
                          ? "Batch Added"
                          : "Select Batch"}
                      </button>
                    </td>
                    <td>
                      <button
                        size="sm"
                        type="button"
                        className="btn btn-sm btn-secondary"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Add Purchase Request"
                        onClick={() => this.addPurchaseRequest(row, index)}
                      >
                        Add Purchase Rqst
                      </button><br/>
                      <button
                        size="sm"
                        type="button"
                        className="btn btn-sm btn-info"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Add Subscrition"
                        onClick={() => this.addSubscription(row, index)}
                      >
                        Add Subscrition
                      </button>
                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-sm btn-primary"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Remove Doctor"
                        onClick={() => this.deleteItemFromCart(index)}
                      >
                        X
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr className={"text-right p-2"}>
                  <td colSpan="11">Total Amount</td>
                  <td>
                    <h6>
                      <strong>
                        {" "}
                        ={" "}
                        <span
                          dangerouslySetInnerHTML={{
                            __html: config.CURRENCY_HTML,
                          }}
                        ></span>{" "}
                        {this.state.actualAmount.toFixed(2)}
                      </strong>
                    </h6>
                  </td>
                </tr>
                <tr className={"text-right p-2"}>
                  {this.state.codChargesApplied === true &&
                  this.state.shippingChargeConfig.cod > 0 ? (
                    <>
                      <td colSpan="8">COD Charges</td>
                      <td>
                        <h6>
                          <strong>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: config.CURRENCY_HTML,
                              }}
                            ></span>{" "}
                            {this.state.shippingChargeConfig.cod.toFixed(2)}
                          </strong>
                        </h6>
                      </td>
                      <td> + Shipping Amount</td>
                      <td>
                        <h6>
                          <strong>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: config.CURRENCY_HTML,
                              }}
                            ></span>{" "}
                            {this.getShippingAmount().toFixed(2)}
                          </strong>
                        </h6>
                      </td>
                      <td>
                        <h6>
                          <strong>
                            {" "}
                            ={" "}
                            <span
                              dangerouslySetInnerHTML={{
                                __html: config.CURRENCY_HTML,
                              }}
                            ></span>{" "}
                            {this.calculateFinalShippingCharges().toFixed(2)}
                          </strong>
                        </h6>
                      </td>
                    </>
                  ) : (
                    <>
                      <td colSpan="11">Shipping Amount</td>
                      <td>
                        <h6>
                          <strong>
                            {" "}
                            ={" "}
                            <span
                              dangerouslySetInnerHTML={{
                                __html: config.CURRENCY_HTML,
                              }}
                            ></span>{" "}
                            {this.getShippingAmount().toFixed(2)}
                          </strong>
                        </h6>
                      </td>
                    </>
                  )}
                </tr>
                {this.state.couponData !== null && (
                  <tr className={"text-right"}>
                    <td colSpan="10">Coupon discount</td>
                    <td>
                      {this.state.couponData.value}{" "}
                      {this.state.couponData.discountType === "PERCENTAGE"
                        ? "%"
                        : "INR"}
                    </td>
                    <td>
                      <h6>
                        <strong>
                          {" "}
                          ={" "}
                          <span
                            dangerouslySetInnerHTML={{
                              __html: config.CURRENCY_HTML,
                            }}
                          ></span>
                          {this.getCouponDiscount()}
                        </strong>
                      </h6>
                    </td>
                  </tr>
                )}
                <tr className={"text-right"}>
                  <td colSpan="7"></td>
                  {this.state.prepayDiscountApplied === true &&
                  this.state.shippingChargeConfig.prepayDiscount > 0 ? (
                    <td colSpan="2" className={"text-success "}>
                      <h6>
                        <strong>
                          Eligible for PREPAY discount <br />{" "}
                          <span
                            dangerouslySetInnerHTML={{
                              __html: config.CURRENCY_HTML,
                            }}
                          ></span>{" "}
                          {this.state.shippingChargeConfig.prepayDiscount.toFixed(
                            2
                          )}
                        </strong>
                      </h6>
                    </td>
                  ) : (
                    <td colSpan="2"></td>
                  )}
                  {/*<td>Flat Discount</td>*/}
                  <td className={"text-left"}>
                    <b className={"mb-2"}>Flat Discount</b>
                    <input
                      style={{ width: "5rem" }}
                      maxLength="10"
                      outline={true}
                      className={"form-control my-1"}
                      type="number"
                      onChange={this.handleFlatDiscount}
                      min={0}
                      value={this.state.flatDiscount}
                    />
                  </td>
                  {/*<td>Discount Type</td>*/}
                  <td className={"text-left"}>
                    <b>Discount Type</b>
                    <MDBSelect getValue={this.getFlatDiscountType}>
                      <MDBSelectInput selected="Discount Type" />
                      <MDBSelectOptions>
                        <MDBSelectOption
                          value="%"
                          selected={this.state.flatDiscountType === "%"}
                        >
                          %
                        </MDBSelectOption>
                        <MDBSelectOption
                          value="INR"
                          selected={this.state.flatDiscountType === "INR"}
                        >
                          INR
                        </MDBSelectOption>
                      </MDBSelectOptions>
                    </MDBSelect>
                  </td>
                  <td>
                    <h6>
                      <strong>
                        {" "}
                        ={" "}
                        <span
                          dangerouslySetInnerHTML={{
                            __html: config.CURRENCY_HTML,
                          }}
                        ></span>{" "}
                        {this.state.flatDiscountAmount}
                      </strong>
                    </h6>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          <div
            className={
              "col-12 sticky-bottom border-top d-flex justify-content-between align-items-center"
            }
          >
            <div className={"row m-0 d-flex font-weight-bold"}>
              <div className={"d-block p-2 border-right"}>
                <div>Paid Amount</div>
                <div>
                  <span
                    dangerouslySetInnerHTML={{ __html: config.CURRENCY_HTML }}
                  ></span>{" "}
                  {this.state.order.paidAmount.toFixed(2)}
                </div>
              </div>
              <div className={"d-block p-2 border-right"}>
                <div>Balance Amount</div>
                <div>
                  <span
                    dangerouslySetInnerHTML={{ __html: config.CURRENCY_HTML }}
                  ></span>
                  <div>
                    {" "}
                    {(
                      this.state.finalAmount - this.state.order.paidAmount
                    ).toFixed(2)}
                  </div>
                </div>
              </div>
              {this.state.order.isOrderFulfilled === true && (
                <div className={"d-block p-2"}>
                  <div>Final Amount</div>
                  <div>
                    <span
                      dangerouslySetInnerHTML={{ __html: config.CURRENCY_HTML }}
                    ></span>
                    <div> {this.state.finalAmount.toFixed(2)}</div>
                  </div>
                </div>
              )}
            </div>
            <MDBBtn
              disabled={this.disableFulfilOrderBtn()}
              onClick={this.fulfilOrder}
            >
              {this.state.loading ? (
                <div
                  className="spinner-border spinner-border-sm fast"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <>
                  {order.isOrderFulfilled === true && <>Re-fulfill Order</>}
                  {order.isOrderFulfilled === false && <>Fulfill Order</>}
                  <i className="fas fa-angle-right right"></i>
                </>
              )}
            </MDBBtn>
          </div>
        </div>

        <MDBModal
          isOpen={this.state.modalProvider}
          toggle={this.toggle("Provider")}
          size="fluid"
          full-height
          position="top"
        >
          <MDBModalHeader toggle={this.toggle("Provider")}>
            Doctor
          </MDBModalHeader>
          <MDBModalBody>
            {/* <MDBRow> */}
            <AddDoctor addDoctorCompleted={this.addDoctorCompleted} />
            {/* </MDBRow> */}
          </MDBModalBody>
        </MDBModal>
        <MDBModal
          isOpen={this.state.modalBatch}
          toggle={this.toggle("Batch")}
          size="fluid"
          full-height
          position="top"
        >
          <MDBModalHeader toggle={this.toggle("Batch")}>
            Select Batch No <span className={"mandatory"}><b>(Please select the product by matching it's bar code)</b></span>
          </MDBModalHeader>
          <MDBModalBody>
            <div className={"col-12 row mx-0"}>
              <div className={"col-md-12 m-auto"}>
                {this.state.activeBatchList.length !== 0 && (
                  <div className="d-flex justify-content-center">
                    <div className="col-4 mb-4">
                      <div className="grey-bg text-center rounded-bottom rounded-top p-2">
                        <div className="border-bottom">
                          <h6 className="title my-1 text-capitalize">
                            Ordered{" "}
                            {this.state.selectedMedDetails.hasOwnProperty(
                              "retailPackage"
                            ) &&
                            this.state.selectedMedDetails.retailPackage.toUpperCase() ===
                              "BOX"
                              ? "Boxes"
                              : this.state.selectedMedDetails.retailPackage +
                                "s"}
                          </h6>
                        </div>
                        <div>
                          <h1 className="h1">
                            {this.state.activeMedicine.quantity}
                          </h1>
                        </div>
                      </div>
                    </div>
                    <div className="col-4 mb-4  ">
                      <div className="grey-bg text-center rounded-bottom rounded-top p-2">
                        <div className="border-bottom">
                          <h6 className="title my-1 text-capitalize">
                            Added{" "}
                            {this.state.selectedMedDetails.hasOwnProperty(
                              "retailPackage"
                            ) &&
                            this.state.selectedMedDetails.retailPackage.toUpperCase() ===
                              "BOX"
                              ? "Boxes"
                              : this.state.selectedMedDetails.retailPackage +
                                "s"}
                          </h6>
                        </div>
                        <div>
                          <h1 className="h1">{this.getTotalQuantityAdded()}</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className={"col-12 row mx-0 text-right p-0"}>
                  <div className={"col-md-12 mx-0 mt-n3 p-0"}>
                    <span className="badge bg-warning black-text">
                      {this.calculateUnit(1, this.state.selectedMedDetails)}
                    </span>
                  </div>
                </div>
                <section className="dark-grey-text">
                  <div className="table-responsive">
                    <table className="table product-table mb-0">
                      <thead className="mdb-color lighten-5">
                        <tr>
                          <th></th>
                          <th className="font-weight-bold">Bar Code</th>
                          <th className="font-weight-bold">
                            <strong>Batch</strong>
                          </th>
                          <th className="font-weight-bold">
                            <strong>MRP</strong>
                          </th>
                          <th className="font-weight-bold">
                            <strong>Available Qty</strong>
                          </th>
                          <th className="font-weight-bold text-capitalize">
                            <strong>
                              Available{" "}
                              {this.state.selectedMedDetails.hasOwnProperty(
                                "retailPackage"
                              ) &&
                              this.state.selectedMedDetails.retailPackage.toUpperCase() ===
                                "BOX"
                                ? "Boxes"
                                : this.state.selectedMedDetails.retailPackage +
                                  "s"}
                            </strong>
                          </th>
                          <th className="font-weight-bold">
                            <strong>Expiry Date</strong>
                          </th>
                          <th className="font-weight-bold">
                            <strong>Added Qnty</strong>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.activeBatchList.length === 0 && (
                          <tr>
                            <td colSpan={"5"}>
                              <div className={"no-data-container"}>
                                No batch details found.
                              </div>
                            </td>
                          </tr>
                        )}
                        {this.state.activeBatchList.map((item, index) => (
                          <tr key={index}>
                            <td className={"d-flex align-items-center"}>
                              <MDBInput
                                checked={item.selected}
                                type="checkbox"
                                id={"checkbox" + index}
                                onChange={() => this.handleBatch(index)}
                                className={"m-0 p-0"}
                              />
                            </td>
                            <td>{item.barCode}</td>
                            <td>
                              <strong>{item.batch}</strong>
                            </td>
                            <td>
                              <strong>{item.mrp}</strong>
                            </td>
                            <td>
                              <strong>{Math.floor(item.quantity)}</strong>
                            </td>
                            <td>
                              <strong>{item.avaialableQnty}</strong>
                            </td>
                            <td>
                              <strong>
                                {moment(item.expDate).format("MMM YYYY")}
                              </strong>
                            </td>
                            <td>
                              <div className={"d-flex text-center"}>
                                <div className="input-group-button">
                                  <MDBBtn
                                    className={"btn-outline-grey"}
                                    outline
                                    type="button"
                                    size={"sm"}
                                    data-quantity="minus"
                                    data-field="quantity"
                                    onClick={() =>
                                      this.handleAddedQnty(index, "minus")
                                    }
                                  >
                                    <i
                                      className="fa fa-minus"
                                      aria-hidden="true"
                                    ></i>
                                  </MDBBtn>
                                </div>
                                <input
                                  className={"form-control"}
                                  style={{ width: "5rem" }}
                                  outline={true}
                                  maxLength="5"
                                  type="text"
                                  disabled
                                  value={item.addedQnty}
                                />
                                <div className="input-group-button">
                                  <MDBBtn
                                    className={"btn-outline-grey"}
                                    type="button"
                                    outline
                                    size={"sm"}
                                    data-quantity="plus"
                                    data-field="quantity"
                                    onClick={() =>
                                      this.handleAddedQnty(index, "plus")
                                    }
                                  >
                                    <i
                                      className="fa fa-plus"
                                      aria-hidden="true"
                                    ></i>
                                  </MDBBtn>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </section>
                <div className={"text-center"}>
                  <button
                    type="button"
                    className="btn btn-sm btn-default"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Add Batch Details"
                    onClick={this.addBatchDetails}
                  >
                    Add Batch Details
                  </button>
                </div>
              </div>
            </div>
          </MDBModalBody>
        </MDBModal>
        <MDBModal
          isOpen={this.state.modalConvertOrder}
          toggle={this.toggle("ConvertOrder")}
          position="top"
        >
          <MDBModalHeader toggle={this.toggle("ConvertOrder")}>
            Convert Order
          </MDBModalHeader>
          <MDBModalBody>
            <div className={"col-md-12 row m-0 p-0"}>
              <div className={"col-md-12"}>
                {this.state.paymentModeToBe === "COD" && (
                  <h5>
                    The order will be converted to <b>COD</b> order.
                  </h5>
                )}
                {this.state.paymentModeToBe === "PREPAY" && (
                  <h5>
                    The order will be converted to <b>PREPAY</b> order. <br />
                    <br />
                    <br />
                    <MDBSelect
                      outline={true}
                      label={"Payment Mode"}
                      getValue={(e) => this.setState({ pymntMode: e[0] })}
                      options={this.state.paymentModeList}
                    ></MDBSelect>
                  </h5>
                )}
              </div>
            </div>
          </MDBModalBody>
          <MDBModalFooter>
            <button
              className={"btn btn-outline-light btn-sm p-0 m-0"}
              onClick={this.toggle("ConvertOrder")}
            >
              <span className={"text-muted"}>Cancel</span>
            </button>
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={this.convertOrder}
            >
              Update
            </button>
          </MDBModalFooter>
        </MDBModal>
        <MDBModal isOpen={this.state.modalPurchaseRequest} toggle={this.toggle("PurchaseRequest")}      
          size="fluid"
          full-height
          position="top"
        >
          <MDBModalHeader toggle={this.toggle("PurchaseRequest")}>
            Add Purchase Request
          </MDBModalHeader>
          <MDBModalBody>
            <AddProductRequest 
              product={this.state.product} 
              togglePurchaseRqst={this.toggle("PurchaseRequest")}
            />
          </MDBModalBody>
        </MDBModal>
        <AddSubscription 
          isProductOpen={this.state.modalAddSubscription} 
          toggleModal={() => this.setState({modalAddSubscription: false})} 
          userData={this.state.userData} 
          product={this.state.product}
          customerDetails={this.state.customerDetails}
        />
      </React.Fragment>
    );
  }
}

export default FulfilOnlineOrder;
