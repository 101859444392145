import React, { useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Box } from "@material-ui/core";
import Barcode from "react-barcode";
import { Typography } from "antd";
import Moments from "moment";
import ViewProductsModal from "../../components/ViewProductsModal/ViewProductsModal";
import { TableSortLabel } from "@material-ui/core";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function TableProduct({ data, isEmpty, productId }) {
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const HEADERS = [
    { id: "barCode", label: "Barcode", sortable: true},
    { id: "productName", label: "Product Name" , sortable: true },
    { id: "mfgDate", label: "Date", sortable: true },
    { id: "mrp", label: "MRP", sortable: true },
    { id: "discount", label: "Discount", sortable: true },
    { id: "discount_price", label: "Discounted Price", sortable: true },
    { id: "locName", label: "Location", sortable: false },
    { id: "floorName", label: "Floor", sortable: false },
    { id: "rackName", label: "Rack", sortable: false },
    { id: "boxName", label: "Box", sortable: false },
    { id: "quantity", label: "Quantity", sortable: true },
    { id: "expDate", label: "Expiry Date", sortable: true },
    { id: "action", label: "Action", sortable: false },
  ];

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = React.useMemo(() => {
    return data.slice().sort((a, b) => {
      if (orderBy === "mfgDate" || orderBy === "expDate") {
        return order === "desc"
          ? Moments(b[orderBy]).diff(Moments(a[orderBy]))
          : Moments(a[orderBy]).diff(Moments(b[orderBy]));
      } else if (orderBy) {
        return order === "desc"
          ? b[orderBy] < a[orderBy]
            ? -1
            : 1
          : a[orderBy] < b[orderBy]
          ? -1
          : 1;
      } else {
        return 0;
      }
    });
  }, [data, order, orderBy]);

  const calculateDiscount = (mrp , dicountPercent =0) => {
    let discountedPrice =  mrp * (1- dicountPercent/100) ?? 0 
    return discountedPrice?.toFixed(2)
  }

  return (
    <>
      {data.length > 0 ? (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                {HEADERS.map((header) => (
                  <StyledTableCell
                    key={header.id}
                    sortDirection={orderBy === header.id ? order : false}
                  >
                    {(header.sortable === true ) ? (
                      <TableSortLabel
                        active={orderBy === header.id}
                        direction={orderBy === header.id ? order : "asc"}
                        onClick={() => handleRequestSort(header.id)}
                      >
                        {header.label}
                      </TableSortLabel>
                    ) : (
                      header.label
                    )}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData?.map((row, key) => (
                <StyledTableRow key={key}>
                  <StyledTableCell align="left">
                    <Box
                      className="d-flex align-items-center"
                      style={{ width: "10em", height: "6em" }}
                    >
                      <Barcode value={row?.barCode}></Barcode>
                    </Box>
                  </StyledTableCell>

                  <StyledTableCell align="left">
                    {row.productName}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {Moments(row.mfgDate).format("DD-MMM-YYYY")}
                  </StyledTableCell>
                  <StyledTableCell align="left">{row.mrp}</StyledTableCell>
                  <StyledTableCell align="left">{row?.discount}</StyledTableCell>
                  <StyledTableCell align="left">{calculateDiscount(row.mrp,row?.discount)}</StyledTableCell>
                  <StyledTableCell align="left">{row.locName}</StyledTableCell>
                  <StyledTableCell align="left">
                    {row.floorName}
                  </StyledTableCell>
                  <StyledTableCell align="left">{row.rackName}</StyledTableCell>
                  <StyledTableCell align="left">{row.boxName}</StyledTableCell>
                  <StyledTableCell align="left">
                    {Math.floor(row.quantity)}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {Moments(row.expDate).format("DD-MMM-YYYY")}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <ViewProductsModal productId={productId} />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        isEmpty && (
          <div className="notabData">
            <Typography variant="body1" align="center" className="noTabText">
              No Product Available .
            </Typography>
          </div>
        )
      )}
    </>
  );
}
