import React,{Suspense} from "react";
import {Link} from "react-router-dom";
import { MDBBreadcrumbItem, MDBBreadcrumb, MDBBox } from 'mdbreact';
import ListPurchaseNoteComp from "../../components/productRequest/listPurchaseNote";

export default class ListPurchaseNote extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Purchase Notes</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <MDBBox className={"min-vh-100"}>
                    <Suspense fallback={null}>
                        <ListPurchaseNoteComp/>
                    </Suspense>
                </MDBBox>
            </React.Fragment>
        );
    }
}