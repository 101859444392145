import React from "react";
import {MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBContainer, MDBFormInline, MDBInput, MDBSelect} from "mdbreact";
import {toast} from "react-toastify";
import UserStore from "../../stores/userStore";
import MomentUtils from "@date-io/moment";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {withRouter} from "react-router-dom";
import AuthService from "../../services/authService";
import SimpleBackdrop from "../common/overlay";

class AddTask extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            progressStatus: false,
            taskDescription: "",
            userData: {},
            assigneeList: [{text: "DATAENTRY", value: "DATAENTRY", checked: true}, {text: "STOCKS", value: "STOCKS"}, {text: "FINANCE", value: "FINANCE"}],
            assignedTo: "DATAENTRY",
            modalStatus: false,
            resolutionDate: new Date(),
            taskTitle: "",
        }
    }

    async componentDidMount() {
        let userData = await UserStore.fetchSessionData();
        this.setState({userData})
    }

    addTaskFunc = async () => {
        if (this.state.taskTitle.trim() === "") {
            toast.error("Please enter the task title.")
            return false
        }
        if (this.state.taskDescription.trim() === "") {
            toast.error("Please enter the task description.")
            return false
        }
        let payload = {
            description: this.state.taskDescription,
            assignedTo: this.state.assignedTo,
            taskTitle: this.state.taskTitle
        }
        this.setState({progressStatus:true})
        let res = await AuthService.addTask(payload, this.state.userData.sessionToken)
        if (res) {
            toast.success("The new task has been added.")
            this.props.history.push("/task-list")
        } else {
            toast.error("Something went wrong while adding the task.")
        }
        this.setState({progressStatus:false})
    }

    render() {
        return (
            <React.Fragment>
                {(this.state.progressStatus === true) &&
                <SimpleBackdrop status={this.state.progressStatus}/>
                }
                <MDBContainer className={"my-3"}>
                    <MDBCard>
                        <MDBCardHeader>
                            <div className={"float-left title"}>Add Purchase Note</div>
                        </MDBCardHeader>
                        <MDBCardBody>
                            <div className={"col-12 row m-0 p-0"}>
                                <div className={"col-md-8"}>
                                    <div className={"row"}>
                                        <div className={"col"}>
                                            <MDBInput type="text" label="Task Title *" outline={true}
                                                      value={this.state.taskTitle} onChange={(e) => {
                                                this.setState({taskTitle: e.target.value})
                                            }} className={'form-control'}/>
                                        </div>
                                    </div>
                                    <div className={"row mt-2"}>
                                        <div className={"col"}>
                                            <MDBInput type="textarea" label="Task Description *" rows="5" outline={true}
                                                      value={this.state.taskDescription} onChange={(e) => {
                                                this.setState({taskDescription: e.target.value})
                                            }} className={'form-control rounded'}/>
                                        </div>
                                    </div>
                                </div>
                                <div className={"col-md-4"}>
                                    <div className={"row"}>
                                        <div className={"col"}>
                                            <b>Assign to</b>
                                            <MDBSelect outline={true} getValue={(val) => {
                                                this.setState({assignedTo: val[0]})
                                            }}
                                                       options={this.state.assigneeList}></MDBSelect>
                                        </div>
                                    </div>
                                    {/*<div className={"row"}>*/}
                                    {/*    <div className={"col my-4"}>*/}
                                    {/*        <MuiPickersUtilsProvider utils={MomentUtils}>*/}
                                    {/*            <DatePicker format="MMM D, YYYY" className={'form-control mt-n3'}*/}
                                    {/*                        label="Resolution Date" value={this.state.resolutionDate}*/}
                                    {/*                        onChange={(val) => {*/}
                                    {/*                            this.setState({resolutionDate: val})*/}
                                    {/*                        }}*/}
                                    {/*                        disablePast*/}
                                    {/*            />*/}
                                    {/*        </MuiPickersUtilsProvider>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </MDBCardBody>
                        <div className="m-0 text-center">
                            <MDBBtn outline  size="md"
                                    onClick={() => this.props.history.push('/task-list')}
                                    className="mr-auto" disabled={this.state.progressStatus}>
                                Back
                            </MDBBtn>
                            <MDBBtn size="md" onClick={this.addTaskFunc}
                                    className="mr-auto"
                                    disabled={this.state.progressStatus===true}>
                                Save
                            </MDBBtn>
                        </div>
                    </MDBCard>
                </MDBContainer>
            </React.Fragment>
        )
    }
}

export default withRouter(AddTask);