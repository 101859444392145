import React from "react";
import {withRouter} from "react-router-dom";
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCol,
    MDBContainer,
    MDBFormInline, MDBIcon,
    MDBInput,
    MDBProgress, MDBRow
} from "mdbreact";
import OrderService from "../../services/orderService";
import {toast} from "react-toastify";
import UserStore from "../../stores/userStore";

class AddRack extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            locId: "", //not necessary, but can't avoid
            floorId: "",
            progressStatus: false,
            floorDetails: null,
            userData: {},
            racks: [{rackName: "", rackNameError: false}]
        }
    }
    async componentDidMount() {
        this.setState({progressStatus: true})
        let userData = await UserStore.fetchSessionData()
        const {locId} = this.props.match.params
        const {floorId} = this.props.match.params
        this.setState({locId, floorId, userData})

        let floorDetails = await OrderService.fetchFloorById(userData.sessionToken, floorId)
        if (floorDetails) {
            this.setState({floorDetails: floorDetails[0]})
        } else {
            toast.error("Something went wrong while fetching the floor details.")
        }
        this.setState({progressStatus: false})
    }
    addRacks = async () => {
        let filteredRacks = []
        for (let item of this.state.racks) {
            if (item.rackName.trim() !== "") {
                filteredRacks.push(item)
            }
        }
        if (filteredRacks.length === 0) {
            filteredRacks.push(
                {rackName: "", rackNameError: true}
            )
            this.setState({racks: filteredRacks})
            toast.error("Please enter at least one rack name.")
            return
        }
        this.setState({racks: filteredRacks})
        let payLoad = {
            floorId: this.state.floorId,
            racks: filteredRacks
        }
        this.setState({progressStatus: true})
        let res = await OrderService.addRacks(this.state.userData.sessionToken, payLoad)
        if (res) {
            toast.success("Racks added successfully to the floor named: " + this.state.floorDetails.floorName)
            this.props.history.goBack()
        } else {
            toast.error("Something went wrong, while adding the racks to the floor named: " + this.state.floorDetails.floorName)
        }
        this.setState({progressStatus: false})
    }
    validateRackName = (index) => {
        let racks = this.state.racks
        if (racks[index].rackName === "") {
            racks[index].rackNameError = true
        } else {
            racks[index].rackNameError = false
        }
        this.setState({racks})
    }
    addRack = () => {
        let racks = this.state.racks
        racks.push({rackName: "", rackNameError: false})
        this.setState({racks})
    }
    deleteRack = (index) => {
        let racks = this.state.racks
        racks.splice(index, 1)
        this.setState({racks})
    }
    handleRackName = index => e => {
        let racks = this.state.racks
        racks[index].rackName = e.target.value
        this.setState({racks})
    }
    render() {
        return(
            <React.Fragment>
                <MDBContainer className={'w-50 mw-50'}>
                    <MDBCard className={"mt-2"}>
                        <MDBCardHeader>
                            <span className={"float-left"}>Add Racks</span>
                            <MDBFormInline className="float-right md-form mr-auto m-0">
                                <MDBBtn outline color="blue-grey" size="sm"
                                        onClick={()=>this.props.history.goBack()}
                                        className="mr-auto"
                                        disabled={this.state.progressStatus}>
                                    Back
                                </MDBBtn>
                                <MDBBtn outline color="blue-grey" size="sm" onClick={this.addRacks}
                                        className="mr-auto"
                                        disabled={this.state.progressStatus}>
                                    Save
                                </MDBBtn>
                            </MDBFormInline>
                        </MDBCardHeader>
                        {this.state.progressStatus === true &&
                        <span className={'top-stick'}><MDBProgress material preloader color="cyan"/></span>
                        }
                        <MDBCardBody>
                            {this.state.floorDetails && <>
                                <MDBRow className={"my-1 p-3"}>
                                    <MDBCol className={"mt-n4"}>
                                        <MDBInput id={"name"} label="Floor Name" disabled value={this.state.floorDetails.floorName} maxLength={"40"}/>
                                    </MDBCol>
                                </MDBRow>

                                {this.state.racks.map((item, index) => (
                                    <MDBRow className={"p-3"}>
                                        <MDBCol size={"10"} className={"mt-n4"}>
                                            <MDBInput id={"rackName"} label={"Rack Name "+(index+1)+" *"} value={this.state.racks[index].rackName} onChange={this.handleRackName(index)}
                                                      className={this.state.racks[index].rackNameError ? "border-danger" : ""} maxLength={"40"} onBlur={()=>this.validateRackName(index)}/>
                                        </MDBCol>
                                        <MDBCol size={"2"}>
                                            {index === 0 ?
                                                (
                                                    <MDBIcon size={"2x"} icon="plus-circle" onClick={this.addRack}/>
                                                ) : (
                                                    <MDBIcon icon="trash" onClick={()=>this.deleteRack(index)}/>
                                                )}
                                        </MDBCol>
                                    </MDBRow>
                                ))}
                            </>}
                        </MDBCardBody>
                    </MDBCard>
                </MDBContainer>
            </React.Fragment>
        )
    }
}
export default withRouter(AddRack);