import axios from "axios";
import config from "../config";

const InventoryService = {
    createProduct: async function (isLoose, sellableUnit, isDrug, tags, selectedMedType, selectedMfg, data, pkgQnty, pakgValSel, qntyUnitSel, productName, userData, highlight, description, howTouse, productIngredients, productSideEffects, productUses) {
        try {
            const headers = {
                headers: {Authorization: userData.sessionToken}
            };
            let payload = {
                isMedicine: isDrug,
                highlight: highlight,
                description: description,
                howTouse: howTouse,
                productIngredients: productIngredients,
                productSideEffects: productSideEffects,
                productUses: productUses,
                productName: productName,
                retailPackage: pakgValSel,
                quantityUnit: qntyUnitSel,
                contentQuantity: pkgQnty,
                imageUrl: data.imageUrl,
                manufacturerId: selectedMfg.value._id,
                medicineType: selectedMedType,
                tags: tags,
                minimumLooseSaleQuantity: sellableUnit,
                looseSaleAllowed: isLoose
            };
            const response = await axios.post(config.APIHOST + config.ADD_PRODUCT, payload, headers);
            return response.data
        } catch (e) {
            return false;
        }
    },
    createProductV3: async function (sessionToken, payload) {
        try {
            const headers = {
                headers: {Authorization: sessionToken}
            };
            const response = await axios.post(config.APIHOST + config.ADD_PRODUCT_V3, payload, headers)
            return response.data
        } catch (e) {
            return false
        }
    },
    editProductV3: async function (sessionToken, payload) {
        try {
            const headers = {
                headers: {Authorization: sessionToken}
            };
            const response = await axios.post(config.APIHOST + config.EDIT_PRODUCT_V3, payload, headers)
            return response.data
        } catch (e) {
            return false
        }
    },

    editProductImageAloneV3: async function (sessionToken, payload) {
        try {
            const headers = {
                headers: {Authorization: sessionToken}
            };
            const response = await axios.post(config.APIHOST + config.EDIT_PRODUCT_IMAGE_ALONE, payload, headers)
            return response.data
        } catch (e) {
            return false
        }
    },



    syncMrpAndStockByProductIdV3: async function (sessionToken, payload) {
        try {
            const headers = {
                headers: {Authorization: sessionToken}
            };
            const response = await axios.post(config.APIHOST + config.MRP_AND_STOCK_SYNC_BY_PRODUCT_ID, payload, headers)
            return response.data
        } catch (e) {
            return false
        }
    },

    deleteProductV3: async function (sessionToken, payload) {
        try {
            const headers = {
                headers: {Authorization: sessionToken}
            };
            const response = await axios.post(config.APIHOST + config.DELETE_PRODUCT_V3, payload, headers)
            return response.data
        } catch (e) {
            return false
        }
    },
    fetchProduct: async function (userData, page, pageSize, type) {
        try {
            const headers = {
                headers: {Authorization: userData.sessionToken}
            };
            const response = await axios.get(config.APIHOST + config.FETCH_PRODUCTS + "?page=" + page + "&pageSize=" + pageSize + "&type=all", headers);
            return response.data
        } catch (e) {
            return false;
        }
    },
    fetchProductById: async function (userData, productId) {
        console.log({userData,productId})
        try {
            const headers = {
                headers: {Authorization: userData.sessionToken}
            };
            const response = await axios.get(config.APIHOST + config.FETCH_PRODUCT_BY_ID + "?_id=" + productId, headers);
            return response.data
        } catch (e) {
            return false;
        }
    },

    fetchProductByIngredient: async function (userData, ingredients) {
        try {
            const headers = {
                headers: {Authorization: userData.sessionToken}
            };

            let payload = {
                ingredients: ingredients
            }
            const response = await axios.post(config.APIHOST + config.FETCH_PRODUCTS_BY_INGREDIENTS, payload, headers);
            return response.data
        } catch (e) {
            return false;
        }
    },
    approveProduct: async function (sessionToken, payload) {
        try {
            const headers = {
                headers: {Authorization: sessionToken}
            };
            const response = await axios.post(config.APIHOST + config.APPROVE_PRODUCT, payload, headers);
            return response.data
        } catch (e) {
            return false;
        }
    },
    rejectProduct: async function (sessionToken, payload) {
        try {
            const headers = {
                headers: {Authorization: sessionToken}
            };
            const response = await axios.post(config.APIHOST + config.REJECT_PRODUCT, payload, headers);
            return response.data
        } catch (e) {
            return false;
        }
    },
    fetAllCategories: async function (userData) {
        try {
            const headers = {
                headers: {Authorization: userData.sessionToken}
            };
            const response = await axios.get(config.APIHOST + config.FETCH_ALL_CATEGORIES, headers);
            return response.data
        } catch (e) {
            return false;
        }
    },
    updateCategories: async function (productId, userData, categories) {
        try {
            const headers = {
                headers: {Authorization: userData.sessionToken}
            };
            let temp = [];
            for (let item of categories) {
                temp.push(item._id);
            }
            let payload = {
                productId: productId,
                categoryIds: temp
            };
            const response = await axios.post(config.APIHOST + config.UPDATE_CATEGORIES, payload, headers);
            return response.data
        } catch (e) {
            return false;
        }
    },

    updateProductImage: async function (data, userData, newFiles) {
        try {
            const headers = {
                headers: {Authorization: userData.sessionToken}
            };
            let consolidatedImages = [];
            for (let i of newFiles) {
                consolidatedImages.push(i)
            }
            console.log(data.existingImageUrl)
            for (let item of data.existingImageUrl) {
                consolidatedImages.push(item)
            }
            let payload = {
                imageUrl: consolidatedImages,
                _id: data.hasOwnProperty("_id") ? data._id : data.productId,
            };
            const response = await axios.post(config.APIHOST + config.UPDATE_PRODUCT_IMAGE, payload, headers);
            return response.data
        } catch (e) {
            return false;
        }
    },


    addCategory: async function (data, userData) {
        try {
            const headers = {
                headers: {Authorization: userData.sessionToken}
            };
            let payload = {
                categoryName: data.categoryName,
                imageUrl: data.imageUrl
            };
            const response = await axios.post(config.APIHOST + config.ADD_CATEGORY, payload, headers);
            return response.data
        } catch (e) {
            return false;
        }
    },
    fetchCategories: async function (userData, type) {
        try {
            const headers = {
                headers: {Authorization: userData.sessionToken}
            };
            const response = await axios.get(config.APIHOST + config.FETCH_CATEGORIES + "?page=0&pageSize=0&type=" + type, headers);
            return response.data
        } catch (e) {
            return false;
        }
    },
    fetchCategoryById: async function (userData, _id) {
        try {
            const headers = {
                headers: {Authorization: userData.sessionToken}
            };
            const response = await axios.get(config.APIHOST + config.FETCH_CATEGORY_BY_ID + "?_id=" + _id, headers);
            return response.data
        } catch (e) {
            return false;
        }
    },
    editCategory: async function (data, userData, status, featured) {
        try {
            const headers = {
                headers: {Authorization: userData.sessionToken}
            };
            let payload = {
                categoryName: data.categoryName,
                imageUrl: data.imageUrl,
                _id: data.categoryId,
                status: status,
                featured: featured
            };
            const response = await axios.post(config.APIHOST + config.EDIT_CATEGORY_BY_ID, payload, headers);
            return response.data
        } catch (e) {
            return false;
        }
    },
    fetchAllRetailPackage: async function (userData) {
        try {
            const headers = {
                headers: {Authorization: userData.sessionToken}
            };
            const response = await axios.get(config.APIHOST + config.FETCH_ALL_RETAIL_PACKAGE + "?page=0&pageSize=0&type=all", headers);
            return response.data
        } catch (e) {
            return false;
        }
    },
    fetchAllQuantityUnit: async function (userData) {
        try {
            const headers = {
                headers: {Authorization: userData.sessionToken}
            };
            const response = await axios.get(config.APIHOST + config.FETCH_ALL_QUANTITY_UNIT + "?page=0&pageSize=0&type=all", headers);
            return response.data
        } catch (e) {
            return false;
        }
    },
    lookupManufacturer: async function (term) {
        try {
            const response = await axios.get(config.APIHOST + config.LOOKUP_MANUFACTURER + "?term=" + term);
            return response.data
        } catch (e) {
            return false;
        }
    },
    lookupMedType: async function (term) {
        try {
            const response = await axios.get(config.APIHOST + config.LOOKUP_MEDICINETYPE + "?term=" + term);
            return response.data
        } catch (e) {
            return false;
        }
    },
    fetchAllMedicineType: async function (userData) {
        try {
            const headers = {
                headers: {Authorization: userData.sessionToken}
            };
            const response = await axios.get(config.APIHOST + config.FETCHALL_MEDICINE_TYPE, headers);
            return response.data
        } catch (e) {
            return false;
        }
    },
    updateProduct: async function (isLoose, sellableUnit, _id, isDrug, tags, selectedMedType, selectedMfgId, data, pkgQnty, pakgValSel, qntyUnitSel, productName, userData, highlight, description, howTouse, productIngredients, productSideEffects, productUses) {
        try {
            const headers = {
                headers: {Authorization: userData.sessionToken}
            };
            let payload = {
                isMedicine: isDrug,
                highlight: highlight,
                description: description,
                howTouse: howTouse,
                productIngredients: productIngredients,
                productSideEffects: productSideEffects,
                productUses: productUses,
                productName: productName,
                retailPackage: pakgValSel,
                quantityUnit: qntyUnitSel,
                contentQuantity: pkgQnty,
                _id: _id,
                manufacturerId: selectedMfgId,
                medicineType: selectedMedType,
                tags: tags,
                minimumLooseSaleQuantity: sellableUnit,
                looseSaleAllowed: isLoose
            };
            const response = await axios.post(config.APIHOST + config.UPDATE_PRODUCT, payload, headers);
            return response.data
        } catch (e) {
            return false;
        }
    },

    updateProductShortcut: async function (data, userData) {
        try {
            const headers = {
                headers: {Authorization: userData.sessionToken}
            };
            let payload = {
                isMedicine: data.isMedicine,
                retailPackage: data.retailPackage,
                quantityUnit: data.quantityUnit,
                contentQuantity: data.contentQuantity,
                _id: data._id,
                medicineType: data.medicineType,
                minimumLooseSaleQuantity: data.minimumLooseSaleQuantity,
                looseSaleAllowed: data.looseSaleAllowed
            };
            console.log(payload)
            const response = await axios.post(config.APIHOST + config.UPDATE_PRODUCT_SHORTCUT, payload, headers);
            return response.data
        } catch (e) {
            return false;
        }
    },

    fetchEditData: async function (userData, id) {
        try {
            const headers = {
                headers: {Authorization: userData.sessionToken}
            };
            const response = await axios.get(config.APIHOST + config.FETCH_EDIT_DATA + "?_id=" + id, headers);
            return response.data
        } catch (e) {
            return false;
        }
    },
    suggestProduct: async function (term, userData) {
        try {
            const headers = {
                headers: {Authorization: userData.sessionToken}
            };
            const response = await axios.get(config.APIHOST + config.SUGGEST_PRODUCT + "?term=" + term, headers);
            return response.data
        } catch (e) {
            return false;
        }
    },
    fetchProductPaginated: async function (userData, page, pageSize, search) {
        try {
            const headers = {
                headers: {Authorization: userData.sessionToken}
            };
            const response = await axios.get(config.APIHOST + config.FETCH_PRODUCTS_PAGINATED + "?page=" + page + "&pageSize=" + pageSize + "&term=" + search, headers);
            return response.data
        } catch (e) {
            return false;
        }
    },

    updateMedicineType: async function (userData, productId, type) {
        try {
            const headers = {
                headers: {Authorization: userData.sessionToken}
            };
            let payload = {
                productId: productId,
                type: type
            }
            const response = await axios.post(config.APIHOST + "/api/v3/updateMedicineType", payload, headers);
            return response.data
        } catch (e) {
            return false;
        }
        //

    },
    fetchGetData: async function (sessionToken, api) {
        try {
            const headers = {
                headers: {Authorization: sessionToken}
            };
            const response = await axios.get(config.APIHOST + config[api], headers);
            return response.data
        } catch (e) {
            return false;
        }
    },
    addNewProduct: async function (sessionToken, payload) {
        try {
            const headers = {
                headers: {Authorization: sessionToken}
            };
            const response = await axios.post(config.APIHOST + config.FETCH_PRODUCTS_PAGINATED, payload, headers);
            return response.data
        } catch (e) {
            return false;
        }
    },
    getProductLogById: async function (sessionToken, productId) {
        try {
            const headers = {
                headers: {Authorization: sessionToken}
            };
            const response = await axios.get(config.APIHOST + config.GET_PRODUCT_LOG + "?id=" + productId, headers);
            return response.data
        } catch (e) {
            return false;
        }
    },
    addProductGroup: async function (sessionToken, payload) {
        try {
            const headers = {
                headers: {Authorization: sessionToken}
            };
            const response = await axios.post(config.APIHOST + config.ADD_PRODUCT_GROUP, payload, headers);
            return response.data
        } catch (e) {
            return false;
        }
    },
    editProductGroup: async function (sessionToken, payload) {
        try {
            const headers = {
                headers: {Authorization: sessionToken}
            };
            const response = await axios.post(config.APIHOST + config.EDIT_PRODUCT_GROUP, payload, headers);
            return response.data
        } catch (e) {
            return false;
        }
    },
    fetchProductGroups: async function () {
        try {
            const response = await axios.get(config.APIHOST + config.FETCH_PRODUCT_GROUPS);
            return response.data
        } catch (e) {
            return false;
        }
    },
    deleteProductGroup: async function (sessionToken, groupId) {
        const headers = {
            headers: {Authorization: sessionToken}
        };
        try {
            const response = await axios.get(config.APIHOST + config.DELETE_PRODUCT_GROUP + "?groupId=" + groupId, headers);
            return response.data
        } catch (e) {
            return false;
        }
    },
    fetchProductsByCategoryById: async function (categoryId) {
        try {
            const response = await axios.get(config.APIHOST + config.FETCH_PRODUCTS_BY_CATEGORY_ID + "?_id=" + categoryId);
            return response.data
        } catch (e) {
            return false;
        }
    },
    disassociateProductFromCategory: async function (sessionToken, productId, categoryId) {
        try {
            const headers = {
                headers: {Authorization: sessionToken}
            };
            const response = await axios.get(config.APIHOST + config.DISASSOCIATE_PRODUCT_FROM_CATEGORY + "?_id=" + productId + "&categoryId=" + categoryId, headers);
            return response.data
        } catch (e) {
            return false;
        }
    },
    addProductToCategory: async function (sessionToken, payload) {
        try {
            const headers = {
                headers: {Authorization: sessionToken}
            };
            const response = await axios.post(config.APIHOST + config.ADD_PRODUCT_TO_CATEGORY, payload, headers);
            return response.data
        } catch (e) {
            return false;
        }
    },
    addQuickTips: async function (sessionToken, _id, payload) {
        try {
            const headers = {
                headers: {Authorization: sessionToken}
            };
            const response = await axios.post(config.APIHOST + config.ADD_QUICK_TIPS + "?_id=" + _id, payload, headers);
            return response.data
        } catch (e) {
            return false;
        }
    },
    updateDiscount: async function (sessionToken, payload) {
        try {
            const headers = {
                headers: {Authorization: sessionToken}
            };
            const response = await axios.post(config.APIHOST + config.UPDATE_DISCOUNT, payload, headers);
            return response.data
        } catch (e) {
            return false;
        }
    }
}
export default InventoryService;
