import React,{Suspense} from "react";
import {
    MDBBox
} from 'mdbreact';
//const AddOrgUser=React.lazy(() => import("../../components/org-user/addOrgUser"));
import AddOrgUser from "../../components/org-user/addOrgUser";

export default class AddOrgUserPage extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MDBBox className={"min-vh-100"}>
                    <Suspense fallback={null}>
                        <AddOrgUser></AddOrgUser>
                    </Suspense>
                </MDBBox>
            </React.Fragment>
        );
    }
}
