import React,{Suspense} from "react";
import {Link} from "react-router-dom";
import {
    MDBBreadcrumbItem, MDBBreadcrumb, MDBRow
} from 'mdbreact';
//const AddProductRequest=React.lazy(() => import("../../components/productRequest/addProductRequest"));
import AddProductRequest from "../../components/productRequest/addProductRequest";

export default class AddProductRequestPage extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem><Link to={'/view-purchase-requests'}>Purchase Requests</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Add Purchase Request</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <MDBRow className={"min-vh-100"}>
                    <Suspense fallback={null}>
                        <AddProductRequest/>
                    </Suspense>
                </MDBRow>
            </React.Fragment>
        );
    }
}


