import { MDBBtn, MDBCol, MDBContainer, MDBInput, MDBRow } from "mdbreact";
import React, { useEffect, useState } from "react";
import AuthService from "../../services/authService";
import { toast } from "react-toastify";
import UserStore from "../../stores/userStore";

const AddCallLogPopUp = ({ modelclose , userId}) => {

  const [addData, setAddData] = useState({
    subject: "",
    summary: "",
    userId: userId,
    customerCarePersonID: "",
  });  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAddData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSaveAddCalllog = async (e) => {
    e.preventDefault();
    try {
      const res = await AuthService.saveAddCallLog(addData);
      if (res) {
        toast.success("Call Log added successfully.");
        modelclose();
      }
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const fetchUserData= async()=>{
    try{
      let userData = await UserStore.fetchSessionData();
      if (userData) {
        setAddData((prevData) => ({
          ...prevData,
          customerCarePersonID: userData._id,
        }));
      }
    } catch (error) {
      console.error("facing some error while fetching:", error);
    }
  }

  useEffect(() => {
    fetchUserData();
  }, []);

  return (
    <div className="main-div-edit-address-parent">
      <MDBContainer className="container-of-edit-address">
        <MDBRow>
          <MDBCol md="6">
            <form className="form-class-edit-address" onSubmit={handleSaveAddCalllog}>
              <div className="main-div-of-edit-address">
                <div className="input-divs-of-edit-address">
                  <MDBInput
                    label="subject:"
                    value={addData.subject}
                    name="subject"
                    onChange={handleInputChange}
                    className="row-for-edit-address-input"
                  />
                </div>
                <div className="input-divs-of-edit-address">
                <MDBInput
                    label="summary:"
                    value={addData.summary}
                    name="summary"
                    onChange={handleInputChange}
                    className="row-for-edit-address-input"
                  />
                </div>
              </div>

              <div className="button-divs-of-edit-address">
                <MDBBtn color="primary" type="submit">
                  Add Call
                </MDBBtn>
              </div>
            </form>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
};

export default AddCallLogPopUp;
