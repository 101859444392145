import React from "react";
import UserStore from "../../stores/userStore";
import OrderService from "../../services/orderService";
import {toast} from "react-toastify";
import dataTableStorageLocation from "./dataTableStorageLocation";
import SimpleBackdrop from "../common/overlay";
import {
    MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBContainer, MDBDataTable,
    MDBFormInline, MDBModal, MDBModalBody, MDBModalHeader,MDBModalFooter
} from "mdbreact";
import {withRouter} from "react-router-dom";

import AddStorageLocation from "./addStorageLocation";

class ListStorageLocation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            storageLocationList: {columns: [], rows: []},
            progressStatus: false,
            modalEdit: false,
            activeItem: null,
            userData: {},
        }
    }
    async componentDidMount() {
        let userData = await UserStore.fetchSessionData()
        this.setState({userData})
        await this.fetchData(userData.sessionToken)
    }
    onEdit = (item) => {
        this.setState({activeItem: item})
        this.toggle("Edit")
    }
    onDelete = (item) => {
        this.setState({activeItem: item})
        this.toggle("Delete")
    }
    deleteStorageLocation = async () => {
        this.setState({progressStatus: true})
        let res = await OrderService.deleteStorageLocation(this.state.userData.sessionToken, this.state.activeItem._id)
        if (res) {
            toast.success("The storage location deleted successfully.")
            await this.refreshPageOnUpdate("Delete")
        } else {
            toast.error("Something went wrong while deleting the storage location.")
        }
        this.setState({progressStatus: false})
    }
    onListFloor = (item) => {
        this.props.history.push('/view-floors/'+item._id);
    }
    fetchData = async (sessionToken) => {
        this.setState({progressStatus: true})
        let data = await OrderService.fetchStorageLocation(sessionToken)
        if (data) {
            let storageLocationList = {
                columns: dataTableStorageLocation.storageLocations,
                rows: data.map((item, index) => {
                    item["handleAddress"] = <span className={"text-capitalize"}>
                        {item.addressLine1}{item.addressLine2 &&
                    <span>,&nbsp;{item.addressLine2}</span>}
                        <br/>
                        {item.city}, {item.state}, {item.pinCode}
                        {item.pincode}, {item.country}<br/>
                    Phone No: {item.phone}
                    </span>;
                    item["listFloors"] =
                        <div color="primary" className={"green-text btn-link cursor-pointer"} size="sm" onClick={() => {
                            this.onListFloor(item)
                        }}>List Floors</div>;
                    item["edit"] =
                        <div color="primary" className={"blue-text btn-link cursor-pointer"} size="sm" onClick={() => {
                            this.onEdit(item)
                        }}>Edit</div>;
                    item["delete"] =
                        <div color="primary" className={"blue-text btn-link cursor-pointer"} size="sm" onClick={() => {
                            this.onDelete(item)
                        }}>Delete</div>;
                    ;
                    return item;
                })
            };
            this.setState({storageLocationList})
        } else {
            toast.error("Something went wrong while fetching the storage location details.")
        }
        this.setState({progressStatus: false})
    }
    toggle = (item) => {
        this.setState({
            ["modal" + item]: !this.state["modal" + item]
        });
    }
    refreshPageOnUpdate = async(item) => {
        this.toggle(item)
        await this.fetchData(this.state.userData.sessionToken)
    }
    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.progressStatus}/>
                <MDBContainer className={'w-80 mw-80'}>
                    <MDBCard className={"mt-2"}>
                        <MDBCardHeader>
                            <span className={"float-left title"}>Storage Locations</span>
                            <div className="float-right  mr-auto m-0">
                                <MDBBtn color="default" size="sm" onClick={()=>{this.props.history.push("/add-storage-location")}} className="mr-auto">
                                    Add Storage Location
                                </MDBBtn>
                            </div>
                        </MDBCardHeader>
                        <MDBCardBody>
                            {(this.state.storageLocationList.rows.length === 0) &&
                            <div className={"no-data-container"}>No Storage Location found.</div>
                            }
                            {this.state.storageLocationList.rows.length > 0 &&
                            <MDBDataTable
                                striped

                                data={this.state.storageLocationList}
                                materialSearch
                                noBottomColumns
                                hover
                            />
                            }
                        </MDBCardBody>
                    </MDBCard>
                </MDBContainer>
                <MDBModal isOpen={this.state.modalEdit} toggle={()=>this.toggle("Edit")} size={"fluid"}>
                    <MDBModalHeader toggle={()=>this.toggle("Edit")}></MDBModalHeader>
                    <MDBModalBody className={"float-center"}>
                        {this.state.activeItem &&
                        <AddStorageLocation storageLocation={this.state.activeItem}
                                            toggleEdit={() => this.refreshPageOnUpdate("Edit")}/>
                        }
                    </MDBModalBody>
                </MDBModal>
                <MDBModal isOpen={this.state.modalDelete} toggle={()=>this.toggle("Delete")}>
                    <MDBModalHeader toggle={()=>this.toggle("Delete")}>Delete Storage Location</MDBModalHeader>
                    <MDBModalBody className={"float-center"}>
                        <div className={"col-12 row m-0"}>
                            <div className={"col-md-12"}>
                                <p>Are you sure, you want to delete the storage location named: </p>
                            </div>
                        </div>
                        {this.state.activeItem &&
                        <div className={"col-12 row m-0 text-center mb-3"}>
                            <div className={"col-md-12"}>
                                <span className={"text-capitalize"}><strong>{this.state.activeItem.name}</strong></span>
                            </div>
                        </div>
                        }
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="primary" outline onClick={()=>this.toggle("Delete")} size={"sm"}>Cancel</MDBBtn>
                        <MDBBtn color="default" onClick={this.deleteStorageLocation} size={"sm"}>Delete</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
            </React.Fragment>
        )
    }
}

export default withRouter(ListStorageLocation);