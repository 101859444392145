import React from "react";
import {
    MDBContainer, MDBRow, MDBBtn, MDBCol, MDBIcon,
} from 'mdbreact';
import UserStore from "../../../stores/userStore";
import PharmacyService from "../../../services/pharmacyService";
import config from "../../../config";

class AdvancedDocUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            manufacturer:"",
            description:"",
            isActive:"",
            imageUrl:"",
            progressStatus:false,
            manufacturerError:"",
            buttonLabel: (this.props.buttonLabel) ? this.props.buttonLabel : "Add Image",
            multiImage:(this.props.multiImage) ? this.props.multiImage : false,
            //
            filesList:[],
            totalCount: 0,
            orderItems:[],
            selectedFile: null,
            fileUploadedResObj:{},
        }
        this.uploadPrescription = React.createRef();
    }

    fileUploadCallback = (data) =>{
        this.props.fileUploadCallback(data)
    }

    onChangeHandler=event=>{
        console.log(event.target.files[0])

        this.setState({
            selectedFile: event.target.files[0],
            loaded: 0,
        })
    }

    onClickHandlerCancel = () => {
        this.setState({
            selectedFile: null,
        })
    }

    onClickHandler = async () => {
        if(this.state.selectedFile === "None"){
            this.onClickHandlerCancel()
            return false;
        }
        let userData=await UserStore.fetchSessionData();
        const data = new FormData()
        this.setState({loading:true, progressStatus:true});
        data.append('fileName', this.state.selectedFile);
        this.props.initUploadCallback();
        let fileUpload = await PharmacyService.uploadFile(data,userData);
        this.setState({loading:false, progressStatus:false, selectedFile: null});
        if(fileUpload){
            this.setState({"fileUploadedResObj": fileUpload})
            let files= this.state.filesList;
            files.push(fileUpload.path)
            this.setState({filesList: files})
            this.fileUploadCallback(files)
        }
    }

    onInitFileHandler  = async () => {
        this.setState({"selectedFile":"None"})
        this.uploadPrescription.current.click();

    }

    initDeleteDoc = (index) => {
        let existingList = this.state.filesList;
        existingList.splice(index, 1);
        this.setState({"filesList": existingList})
    }

    updateProductImage = ()=>{
        this.props.updateProductImage(this.state.filesList)
        this.setState({filesList:[]})
    }
    showImg=(row)=>{
        let url=config.IMAGE_HOST+row.filePathOriginal;
        let img = '<img src="'+url+'" alt="">';
        let popup = window.open();
        popup.document.write(img);
        //popup.print();
    }
    render() {
        console.log("finishedSaving", this.props.finishedSavingFile)
        return (
            <React.Fragment>
                <MDBContainer >
                    <form method="post" action="#" id="#">
                        <div className="form-group files">
                            <input type="file" style={{"display": "none"}} name="file" ref={this.uploadPrescription} onChange={this.onChangeHandler}/>
                            {!this.state.selectedFile &&
                            <MDBBtn size={"sm"} color={"default"} onClick={this.onInitFileHandler}>
                                {this.state.filesList.length === 0 &&
                                <span>Add Prescription</span>
                                }
                                {this.state.filesList.length > 0 &&
                                <span>Add Another Prescription</span>
                                }
                            </MDBBtn>
                            }
                            {this.state.selectedFile &&
                            <div><MDBBtn outline={true} size={"sm"} className="btn btn-outline" onClick={this.onClickHandlerCancel}>Cancel</MDBBtn><MDBBtn size={"sm"} outline={true} className="btn btn-primary " onClick={this.onClickHandler}>Upload</MDBBtn>
                                {this.state.loading === true &&
                                <span> <img  style={{"width":"4rem"}} src={require('../../../components/common/img/loader_new.gif')} alt={"loading..."}/></span>
                                }
                                {this.state.selectedFile.name}</div>
                            }
                        </div>

                    </form>


                    <MDBRow>
                        <MDBCol>

                            {this.state.filesList.map((row, index) => (
                                <div className={"float-left m-1"} key={index}>

                                    {((row.filePathThumb.indexOf(".png") > 0) || (row.filePathThumb.indexOf(".jpg") > 0) || (row.filePathThumb.indexOf(".jpeg") > 0)) &&
                                    <img src={config.IMAGE_HOST + row.filePathThumb} className={"float-left"} style={{cursor:"pointer",width: "100px", height: "100px"}} alt={""} onClick={()=>{this.showImg(row)}}/>
                                    }
                                    <span style={{"cursor":"pointer"}} onClick={()=>this.initDeleteDoc(index)}><MDBIcon icon="trash" /></span>
                                </div>

                            ))}
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </React.Fragment>
        );
    }
}
export default AdvancedDocUpload