import { MDBBtn, MDBCol, MDBContainer, MDBInput, MDBRow } from "mdbreact";
import React, { useState } from "react";
import AuthService from "../../services/authService";
import { toast } from "react-toastify";

const EditAddressPopUp = ({ data, modelclose }) => {
  const [editedAddress, setEditedAddress] = useState({ ...data });
  const [checkbox, setCheckbox] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setEditedAddress((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSaveEditAddress = async (e) => {
    let payload = {
      id: editedAddress.id,
      addressLine1: editedAddress.addressLine1,
      addressLine2: editedAddress.addressLine2,
      city: editedAddress.city,
      state: editedAddress.state,
      country: editedAddress.country,
      pinCode: editedAddress.pinCode,
      isDefault: editedAddress.isDefault,
      name: editedAddress.name,
      phone: editedAddress.phone,
    };
    try {
      const res = await AuthService.saveEditedAddressData(payload);
      if (res) {
        toast.success("Address updated Successesfully.");
        modelclose();
      }
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };
  const handleSave = () => {
    const updatedAddress = {
      ...editedAddress,
      isDefault: editedAddress.isDefault,
    };
    setEditedAddress({ ...data });
    handleSaveEditAddress();
  };
  const handleInputChangecheckbox = (e) => {
    setCheckbox(!checkbox);
    setEditedAddress({ ...editedAddress, isDefault: !editedAddress.isDefault });
  };

  return (
    <div className="main-div-edit-address-parent">
      <MDBContainer className="container-of-edit-address">
        {/* <MDBRow>
          <MDBCol md="6"> */}
        <form className="form-class-edit-address">
          <div className="main-div-of-edit-address">
            <div className="input-divs-of-edit-address">
              <MDBInput
                label="Address Line 1:"
                value={editedAddress.addressLine1}
                name="addressLine1"
                onChange={handleInputChange}
                className="row-for-edit-address-input"
              />
              <MDBInput
                label="Address Line 2:"
                value={editedAddress.addressLine2}
                name="addressLine2"
                onChange={handleInputChange}
                className="row-for-edit-address-input"
              />
            </div>
            <div className="input-divs-of-edit-address">
              <MDBInput
                label="City:"
                value={editedAddress.city}
                name="city"
                onChange={handleInputChange}
                className="row-for-edit-address-input"
              />
              <MDBInput
                label="State:"
                value={editedAddress.state}
                name="state"
                onChange={handleInputChange}
                className="row-for-edit-address-input"
              />
            </div>
            <div className="input-divs-of-edit-address">
              <MDBInput
                label="Country:"
                value={editedAddress.country}
                name="country"
                onChange={handleInputChange}
                className="row-for-edit-address-input"
              />
              <MDBInput
                label="Pin Code:"
                value={editedAddress.pinCode}
                name="pinCode"
                onChange={handleInputChange}
                className="row-for-edit-address-input"
              />
            </div>
            <div className="input-divs-of-edit-address">
              <MDBInput
                label="Name:"
                value={editedAddress.name}
                name="name"
                onChange={handleInputChange}
                className="row-for-edit-address-input"
              />
              <MDBInput
                label="Phone:"
                value={editedAddress.phone}
                name="phone"
                onChange={handleInputChange}
                className="row-for-edit-address-input"
              />
            </div>
            {/* <div>
              <div className="checkbox-label">
                <div className="mdb-checkbox-address">
                  <MDBInput
                    type="checkbox"
                    checked={editedAddress.isDefault}
                    name="isDefault"
                    // getValue={handleInputChangecheckbox}
                  />
                </div>
                <div  className="input-checkbox-address">
                  <label>
                    <input
                      className="input-checkbox"
                      type="checkbox"
                      checked={editedAddress.isDefault}
                      onChange={handleInputChangecheckbox}
                    />
                    Is Default
                  </label>
                </div>
              </div>
            </div> */}
            <div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="customCheck1" checked={editedAddress.isDefault} onChange={handleInputChangecheckbox} />
                <label class="form-check-label" for="customCheck1" onClick={handleInputChangecheckbox}>Is Default</label>
              </div>
            </div>
          </div>
          {/* <MDBInput
                type="checkbox"
                id="defaultCheckbox"
                checked={editedAddress.isDefault}
                onChange={() =>
                  handleInputChangecheckbox(!editedAddress.isDefault)
                }
              />
              <label htmlFor="defaultCheckbox">Is Default</label> */}
          {/* <div className={"col p-2 font-weight-bold mt-2"}>
                            <MDBInput label="Pickup" type="checkbox" id="checkbox_pickup"
                                      checked={checkbox} getValue={(val) => {
                               setCheckbox(val)
                            }}/>
                        </div> */}
          <div className="button-divs-of-edit-address">
            <MDBBtn color="primary" onClick={handleSave}>
              Update
            </MDBBtn>
          </div>
        </form>
        {/* </MDBCol>
        </MDBRow> */}
      </MDBContainer>
    </div>
  );
};

export default EditAddressPopUp;
