import React from "react";
import {
    MDBContainer, MDBRow, MDBInput, MDBBtn, MDBCardHeader, MDBFormInline, MDBCardBody, MDBCard, MDBCol
} from 'mdbreact';
import {toast} from "react-toastify";
import UserStore from "../../stores/userStore";
import PharmacyService from "../../services/pharmacyService";
import SimpleBackdrop from "../common/overlay";
import {withRouter} from "react-router-dom";

class AddQuantityUnit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            quantityUnit:'',
            progressStatus:false,
            progressStatusFileUpload:false,
            quantityUnitNameError:false
        }
    }
    quantityUnitChange=(val)=>{
        this.setState({quantityUnit:val.toLowerCase()});
    }
    addQuantityUnit=async ()=>{
        window.scrollTo(0, 0)
        let userData=await UserStore.fetchSessionData();
        //validations
        if(this.state.quantityUnit ==='' || this.state.quantityUnit === null){
            this.setState({quantityUnitNameError:true});
            return true;
        } else {
            this.setState({quantityUnitNameError:false});
        }
        this.setState({progressStatus:true});
        let status=await PharmacyService.addQuantityUnit(this.state,userData);
        this.setState({progressStatus:false});
        // This is to send back the parent compponent about the status
        if(this.state.isSubComponent){
            this.props.subComponentActionPerformed(status)
        }
        if(status===false){
            toast.error("Quantity unit already exists.");
        }else{
            this.setState({quantityUnit:""});
            toast.success("Quantity unit added successfully.");
        }
    }
    validateQuantityUnit=()=>{
        if(this.state.quantityUnit === ""){
            this.setState({quantityUnitNameError:true});
        } else {
            this.setState({quantityUnitNameError:false});
        }
    }
    componentDidMount() {
        if(this.props.hasOwnProperty("isSubComponent")){
            this.setState({isSubComponent:true})
        }
    }
    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.progressStatus}/>
                <MDBContainer>
                    <MDBCard className={"mt-2"}>
                        <MDBCardHeader>
                            <span className={"float-left title"}>Add Quantity Unit</span>
                        </MDBCardHeader>
                        <MDBCardBody>
                            <MDBRow className="d-flex justify-content-center">
                                <MDBCol>
                                    <MDBInput label="Quantity Unit e.g. ml, kg" onBlur={this.validateQuantityUnit} className={this.state.quantityUnitNameError ? "border-danger" :""} getValue={this.quantityUnitChange} value={this.state.quantityUnit}/>
                                </MDBCol>
                            </MDBRow>
                            <div className="text-center">
                                {!this.state.isSubComponent &&
                                <MDBBtn size={'md'} outline
                                        onClick={() => this.props.history.push('/view-quantity-units')} className="mr-auto"
                                        disabled={this.state.progressStatus}>
                                    Back
                                </MDBBtn>
                                }
                                <MDBBtn size={'md'} onClick={this.addQuantityUnit} className="mr-auto"
                                        disabled={this.state.quantityUnitNameError || this.state.progressStatus}>
                                    Save
                                </MDBBtn>
                            </div>
                        </MDBCardBody>
                    </MDBCard>
                </MDBContainer>
            </React.Fragment>
        );
    }
}
export default withRouter(AddQuantityUnit);


