import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PharmacyService from "../../services/pharmacyService";

const FilterStockTable = ({ filter, setFilter, filterHandler,isBoxRequired = false }) => {
  const [locations, setLocations] = useState([]);
  const [floors, setFloors] = useState([]);
  const [rack, setRack] = useState([]);
  const [box, setBox] = useState([]);

  const fetchStorageLOC = async () => {
    let storageInfo = await PharmacyService.fetchStorageLocationV2();
    if (storageInfo) {
      setLocations(storageInfo);
    }
  };
  const fetchFloor = async (locId) => {
    const floorDetails = await PharmacyService.fetchFloorV2(locId);
    if (floorDetails) {
      setFloors(floorDetails);
    }
  };
  const fetchRack = async (floorId, index) => {
    const rackDetails = await PharmacyService.fetchRackV2(floorId);
    if (rackDetails) {
      setRack(rackDetails);
    }
  };
  const fetchBox = async (rackId, index) => {
    const boxDetails = await PharmacyService.fetchBoxV2(rackId);
    if (boxDetails) {
      setBox(boxDetails);
    }
  };

  const handleLocChange = (e) => {
    setFilter((prev) => ({
      locationId: e.target.value,
      floorId: "",
      rackId: "",
      boxId: "",
    }));

    fetchFloor(e.target.value);
  };

  const handleFloorChange = (e) => {
    setFilter((prev) => ({
      ...prev,
      floorId: e.target.value,
      rackId: "",
      boxId: "",
    }));

    fetchRack(e.target.value);
  };
  const handleRackChange = (e) => {
    setFilter((prev) => ({
      ...prev,
      rackId: e.target.value,
      boxId: "",
    }));

    fetchBox(e.target.value);
  };
  const handleBoxChange = (e) => {
    setFilter((prev) => ({
      ...prev,
      boxId: e.target.value,
    }));
  };

  useEffect(() => {
    fetchStorageLOC();
  }, []);

  return (
    <div className="filterGrid">
      <div className={"p-0 mr-3 mb-2"}>
        <FormControl fullWidth>
          <InputLabel id="status-label">
            Select Location <span className="text-danger">*</span>
          </InputLabel>
          <Select
            labelId="status-label"
            id="status-select"
            value={filter.locationId}
            label="Select Location"
            onChange={handleLocChange}
            displayEmpty
            placeholder="Select Location"
            renderValue={(selected) => {
              if (!selected) {
                return (
                  <span style={{ color: "#9e9e9e" }}>Select Location</span>
                );
              }
              return locations.find((item) => item._id === selected)?.name;
            }}
          >
            {locations.map((item) => (
              <MenuItem value={item._id} key={item._id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className={"p-0 mr-3 mb-2"}>
        <FormControl fullWidth>
          <InputLabel id="status-label">
            Select Floor <span className="text-danger">*</span>
          </InputLabel>
          <Select
            labelId="status-label"
            id="status-select"
            value={filter.floorId}
            label="Select Floor"
            onChange={handleFloorChange}
            displayEmpty
            placeholder="Select Floor"
            disabled={filter.locationId === ""}
            renderValue={(selected) => {
              if (!selected) {
                return <span style={{ color: "#9e9e9e" }}>Select Floor</span>;
              }
              return floors.find((item) => item._id === selected)?.floorName;
            }}
          >
            {floors.map((item) => (
              <MenuItem value={item._id} key={item._id}>
                {item.floorName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className={"p-0 mr-3 mb-2"}>
        <FormControl fullWidth>
          <InputLabel id="status-label">
            Select Rack <span className="text-danger">*</span>
          </InputLabel>
          <Select
            labelId="status-label"
            id="status-select"
            value={filter.rackId}
            label="Select Rack"
            onChange={handleRackChange}
            displayEmpty
            placeholder="Select Rack"
            disabled={filter.floorId === ""}
            renderValue={(selected) => {
              if (!selected) {
                return <span style={{ color: "#9e9e9e" }}>Select Rack</span>;
              }
              return rack.find((item) => item._id === selected)?.rackName;
            }}
          >
            {rack.map((item) => (
              <MenuItem value={item._id} key={item._id}>
                {item.rackName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className={"p-0 mr-3 mb-2"}>
        <FormControl fullWidth>
          <InputLabel id="status-label">Select Box {isBoxRequired && <span className="text-danger">*</span>}</InputLabel>
          <Select
            labelId="status-label"
            id="status-select"
            value={filter.boxId}
            label="Select Box"
            onChange={handleBoxChange}
            displayEmpty
            placeholder="Select Box"
            disabled={filter.rackId === ""}
            renderValue={(selected) => {
              if (!selected) {
                return <span style={{ color: "#9e9e9e" }}>Select Box</span>;
              }
              return box.find((item) => item._id === selected)?.boxName;
            }}
          >
            {box.map((item) => (
              <MenuItem value={item._id} key={item._id}>
                {item.boxName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      {filterHandler && (
        <div className={"p-0 mr-3 mt-3"}>
          <Button
            variant="contained"
            color="primary"
            onClick={filterHandler}
            size="large"
            disabled={!(filter.locationId && filter.floorId && filter.rackId)}
          >
            Submit
          </Button>
        </div>
      )}
    </div>
  );
};

export default FilterStockTable;
