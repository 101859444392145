import React from "react";
import Moments from "moment";
import { MDBBtn, MDBBtnGroup, MDBCol, MDBRow } from "mdbreact";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import utility from "../../utility/utility";

class GetDateRange extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: this.props.mode || "Day",
      startDate: this.props.startDatePreserved || Moments(),
      endDate: this.props.endDatePreserved || Moments(),
      dayStartDate: this.props.startDatePreserved || Moments(),
      dayEndDate: this.props.endDatePreserved || Moments(),
      weekStartDate: this.props.startDatePreserved || Moments().startOf("week"),
      weekEndDate: this.props.endDatePreserved || Moments().endOf("week"),
      monthStartDate:
        this.props.startDatePreserved || Moments().startOf("month"),
      monthEndDate: this.props.endDatePreserved || Moments().endOf("month"),
    };
  }

  setDateRange = (mode, startDate, endDate) => {
    this.setState({ mode: mode });
    if (this.props.sendModeToParent) {
      this.props.sendModeToParent(mode);
    }

    this.getDateRangeCallback(startDate, endDate);
  };
  getDateRangeCallback = (startDate, endDate) => {
    this.props.getDateRangeCallback(startDate, endDate);
  };
  handleStartDate = (e) => {
    this.setState({ startDate: new Date(e) });
  };
  handleEndDate = (e) => {
    this.setState({ endDate: new Date(e) });
  };
  prevDay = () => {
    let nextStartDate = Moments(this.state.dayStartDate).subtract(1, "days");
    this.setDateRange("Day", nextStartDate, nextStartDate);
    this.setState({ dayStartDate: nextStartDate, dayEndDate: nextStartDate });
  };
  prevWeek = () => {
    let nextStartDate = Moments(this.state.weekStartDate).subtract(1, "weeks");
    let nextEndDate = Moments(this.state.weekEndDate).subtract(1, "weeks");
    this.setDateRange("Week", nextStartDate, nextEndDate);
    this.setState({ weekStartDate: nextStartDate, weekEndDate: nextEndDate });
  };
  prevMonth = () => {
    const currentDate = Moments();
    this.setState({ mode: "Month" });
    let nextStartDate = Moments(this.state.monthStartDate)
      .subtract(1, "months")
      .startOf("month")
      .format("YYYY-MM-DD");
    let nextEndDate = Moments(this.state.monthEndDate)
      .subtract(1, "months")
      .endOf("month")
      .format("YYYY-MM-DD");
    this.setDateRange("Month", nextStartDate, nextEndDate);
    this.setState({ monthStartDate: nextStartDate, monthEndDate: nextEndDate });
  };
  nextDay = () => {
    let nextStartDate = Moments(this.state.dayStartDate).add(1, "days");
    if (nextStartDate <= new Date()) {
      this.setDateRange("Day", nextStartDate, nextStartDate);
      this.setState({ dayStartDate: nextStartDate, dayEndDate: nextStartDate });
    }
  };
  nextWeek = () => {
    let nextStartDate = Moments(this.state.weekStartDate).add(1, "weeks");
    let nextEndDate = Moments(this.state.weekEndDate).add(1, "weeks");
    if (nextStartDate <= new Date()) {
      this.setDateRange("Week", nextStartDate, nextEndDate);
      this.setState({ weekStartDate: nextStartDate, weekEndDate: nextEndDate });
    }
  };
  nextMonth = () => {
    let nextStartDate = Moments(this.state.monthStartDate).add(1, "months");
    let nextEndDate = Moments(nextStartDate).endOf("month");
    if (nextStartDate <= new Date()) {
      this.setDateRange("Month", nextStartDate, nextEndDate);
      this.setState({
        monthStartDate: nextStartDate,
        monthEndDate: nextEndDate,
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <MDBRow className={"mx-0"}>
          <MDBBtnGroup size="sm">
            <MDBBtn
              onClick={() =>
                this.setDateRange(
                  "Day",
                  Moments(),
                  Moments()
                )
              }
              className={this.state.mode == "day" ? "default-btn" : ""}
              color={
                this.state.mode === "Day"
                  ? "btn-md btn-default"
                  : "btn-md btn-outline-default"
              }
            >
              Day
            </MDBBtn>
            <MDBBtn
              onClick={() =>
                this.setDateRange(
                  "Week",
                  Moments().startOf("week"),
                  Moments().endOf("week")
                )
              }
              className={this.state.mode === "Week" ? "default-btn" : ""}
              color={
                this.state.mode === "Week"
                  ? "btn-md btn-default"
                  : "btn-md btn-outline-default"
              }
            >
              Week
            </MDBBtn> 
            <MDBBtn
              onClick={() =>
                this.setDateRange(
                  "Month",
                  Moments().startOf("month"),
                  Moments().endOf("month"),
                )
              }
              className={this.state.mode === "Month" ? "default-btn" : ""}
              color={
                this.state.mode === "Month"
                  ? "btn-md btn-default"
                  : "btn-md btn-outline-default"
              }
            >
              Month
            </MDBBtn>
            <MDBBtn
              onClick={() =>
                this.setDateRange(
                  "Custom",
                  this.state.startDate,
                  this.state.endDate
                )
              }
              className={this.state.mode === "Custom" ? "default-btn" : ""}
              color={
                this.state.mode === "Custom"
                  ? "btn-md btn-default"
                  : "btn-md btn-outline-default"
              }
            >
              Custom
            </MDBBtn>
          </MDBBtnGroup>

          {this.state.mode === "Custom" && (
            <div
              style={{ marginTop: "-15px" }}
              className={"col-md-8 float-left"}
            >
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  style={{
                    marginLeft: "1px",
                    marginRight: "1px",
                    width: "11rem",
                  }}
                  format="MMM D, YYYY"
                  maxDate={new Date()}
                  margin="normal"
                  id="startDate"
                  label="Start Date"
                  value={this.state.startDate}
                  onChange={this.handleStartDate}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>

              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  style={{
                    marginLeft: "1px",
                    marginRight: "1px",
                    width: "11rem",
                  }}
                  format="MMM D, YYYY"
                  maxDate={new Date()}
                  margin="normal"
                  id="endDate"
                  label="End Date"
                  value={this.state.endDate}
                  onChange={this.handleEndDate}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
              <MDBBtn
                onClick={() =>
                  this.setDateRange(
                    "Custom",
                    this.state.startDate,
                    this.state.endDate
                  )
                }
                style={{
                  marginTop: "20px",
                  paddingLeft: "1px",
                  paddingRight: "1px",
                  width: "3rem",
                }}
                size="md"
                color={"default"}
              >
                Go
              </MDBBtn>
            </div>
          )}
          {this.state.mode === "Day" && (
            <div>
              <MDBBtn
                size={"md"}
                onClick={() => this.prevDay()}
                color="default"
              >
                &lt;
              </MDBBtn>
              <span>
                {Moments(this.state.dayStartDate).format("MMM D, YYYY")}
              </span>
              <MDBBtn
                size={"md"}
                onClick={() => this.nextDay()}
                color="default"
              >
                &gt;
              </MDBBtn>
            </div>
          )}
          {this.state.mode === "Week" && (
            <div>
              <MDBBtn
                size={"md"}
                onClick={() => this.prevWeek()}
                color="default"
              >
                &lt;
              </MDBBtn>
              <span>
                {" "}
                {Moments(this.state.weekStartDate).format("MMM D, YYYY")}
              </span>{" "}
              -{" "}
              <span>
                {" "}
                {Moments(this.state.weekEndDate).format("MMM D, YYYY")}
              </span>
              <MDBBtn
                size={"md"}
                onClick={() => this.nextWeek()}
                color="default"
              >
                &gt;
              </MDBBtn>
            </div>
          )}
          {this.state.mode === "Month" && (
            <div>
              <MDBBtn
                size={"md"}
                onClick={() => this.prevMonth()}
                color="default"
              >
                &lt;
              </MDBBtn>
              <span>
                {" "}
                {Moments(this.state.monthStartDate).format("MMM YYYY")}
              </span>
              <MDBBtn
                size={"md"}
                onClick={() => this.nextMonth()}
                color="default"
              >
                &gt;
              </MDBBtn>
            </div>
          )}
        </MDBRow>
      </React.Fragment>
    );
  }
}

export default GetDateRange;
