import React, {Suspense} from "react";
import {MDBBreadcrumb, MDBBreadcrumbItem} from "mdbreact";
import {Link} from "react-router-dom";
import MyTask from "../../components/report/myTask";

export default class CleanDataPage extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>My Task</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <Suspense fallback={null}>
                    <MyTask/>
                </Suspense>
            </React.Fragment>
        )
    }
}