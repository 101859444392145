import React, {Suspense} from "react";
import {MDBBox, MDBBreadcrumb, MDBBreadcrumbItem} from "mdbreact";
import {Link} from "react-router-dom";
import ListFloorsCmp from "../../components/floors/listFloors";
class ListFloors extends React.Component {
    render() {
        return(
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem><Link to={'/view-storage-locations'}>Storage Location</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Floors</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <MDBBox className={"min-vh-100"}>
                    <Suspense fallback={null}>
                        <ListFloorsCmp queryString={this.props.match.params}/>
                    </Suspense>
                </MDBBox>
            </React.Fragment>
        )
    }
}
export default ListFloors;