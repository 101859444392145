import React, { useState } from 'react';
import { Button, Grid, IconButton, TextField, Typography } from '@material-ui/core'
import PharmacyService from '../../services/pharmacyService';
import SearchIcon from '@material-ui/icons/Search';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteIcon from '@material-ui/icons/Delete';

const ShipSearch = ({
    searchTerm,
    handleSearchTerm,
    user,
    fetchData,
    setshipmentDetails,
    setSearchTermShip,
    setShipmentDetailView,
    clearForm,
    setClear,
    setShowdiv,
    setIsShipmentEmpty,
    setShipmentData = ()=>{},
    shipmentData = [],

    setProductViewDetails={},
    setProductDetails=[],
    setDynamicFieldName="No. of ___",
    setContentQantity='',
    setShowdivProduct=false,
    setProductData = () => {},
    setSearchTerm
}) => {
    const [selected, setSelected] = useState(false);
    const [selectedText, setSelectedText] = useState("Select");
    const [isLoading, setLoading] = useState(false)

    const searchProductVal = async () => {
        try {
            if (/\S/.test(searchTerm)){
            setLoading(true)
            setClear(false)
            setShowdiv(false)
            if (shipmentData?.length >= 1) {
                setShipmentData([])
            }
            let product = await PharmacyService.searchShipmentV2(searchTerm, user);
            if (product) {
                setSelected(false)
                setSelectedText("Select")
                setLoading(false)
                setShipmentData(product)
                // setIsShipmentEmpty(true)
            }
        }
            // console.log({ product })
        }
        catch (err) {
            setLoading(false)
            console.log(err)
        }
    }

    const selectProduct = (data) => {
        // console.log({data})
        const productSelected = shipmentData.filter(item => item?.supplierDetails.supplierName == data.supplierDetails.supplierName)
        setShipmentData([data])
        setSelected(true)
        setSelectedText("Selected")
        setshipmentDetails([data])
        setSearchTermShip("")
        setShowdiv(true);
        setIsShipmentEmpty(true)
    }

    const clearShip = () =>{
        setshipmentDetails([]) 
        setShipmentData([])
        setShowdiv(false)

        setProductViewDetails({})
        setProductDetails([])
        setDynamicFieldName("No. of ___")
        setContentQantity('')
        setShowdivProduct(false)
        setShipmentData([])
        setProductData([])
        setSearchTerm("")
    }
    
    return (
        <Grid container>
            <Grid item xs={6}>
                <div className='autoComDiv'>
                    <TextField
                        variant="outlined"
                        style={{ marginRight: '8px', width: "350px" }}
                        value={searchTerm}
                        onChange={(e) => handleSearchTerm(e.target.value)}
                    />
                    <IconButton onClick={searchTerm.length > 0 && searchProductVal}
                        style={{ backgroundColor: '#3f51b5', borderRadius: '4px', color: "white" }} disabled={!searchTerm.length > 0}>
                        <SearchIcon />
                    </IconButton>
                </div>

            </Grid>
            <Grid item xs={6} style={{ padding: "0 1em" }}>
                <div className='scrollableDiv'>
                    {shipmentData.length ? shipmentData.map((item, key) =>
                        <div className='optionMenu'>
                            <div>
                                <Typography variant="body1"><b>{item?.supplierDetails.supplierName}</b></Typography>
                                <div>
                                    {/* <Typography variant='body1' className='view'>view details</Typography> */}
                                </div>
                            </div>
                            <div className='d-flex justify-content-end align-items-center flex-direction-row'>
                            <Button variant='contained' color='primary' onClick={() => selectProduct(item)} disabled={selected}
                            >{selectedText}</Button>
                            <DeleteIcon style={{color:shipmentData.length==1 ?"red":"grey",cursor:"pointer"}} onClick={ shipmentData.length==1 && clearShip}/>
                            </div>
                        </div>
                    ) :
                        isLoading  ?
                            <div className='loader'>
                                <CircularProgress />
                            </ div> : <div className='emptyScrollDiv'>
                                <Typography variant='h6' align='center'><b>Shipment is Empty !!</b></Typography>
                            </div>
                    }
                </div>
            </Grid>
        </Grid>
    );
};

export default ShipSearch;

