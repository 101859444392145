import React,{Suspense} from "react";
import {
    MDBBox
} from 'mdbreact';
// const AddDoctor=React.lazy(() => import("../../components/doctors/addDoctorCmp"));
import AddDoctor from "../../components/doctors/addDoctorCmp";

export default class AddDoctorPage extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MDBBox className={"min-vh-100"}>
                    <Suspense fallback={null}>
                        <AddDoctor></AddDoctor>
                    </Suspense>
                </MDBBox>
            </React.Fragment>
        );
    }
}
