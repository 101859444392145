import React from "react";
import {
    MDBContainer, MDBRow, MDBBtn, MDBCol, MDBIcon, MDBModalHeader, MDBModalBody, MDBModal,
} from 'mdbreact';
import UserStore from "../../../stores/userStore";
import PharmacyService from "../../../services/pharmacyService";
import config from "../../../config";
import utility from "../../../utility/utility";
import {toast} from "react-toastify";

class AddProductImage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            manufacturer:"",
            description:"",
            isActive:"",
            imageUrl:"",
            progressStatus:false,
            manufacturerError:"",
            buttonLabel: (this.props.buttonLabel) ? this.props.buttonLabel : "Add Image",
            multiImage:(this.props.multiImage) ? this.props.multiImage : false,
            //
            filesList:[],
            totalCount: 0,
            orderItems:[],
            selectedFile: null,
            fileUploadedResObj:{},
            theInputKey:"",

            modalLB:false,
            activeImage:"",
        }
        this.uploadPrescription = React.createRef();
    }



    fileUploadCallback = (data) =>{
        this.props.fileUploadCallback(data)
    }

    onChangeHandler=event=>{
        console.log(event.target.files[0])
        if(!utility.checkAllowedImageType(event.target.files[0])){
            toast.error("Allowed file types are JPG/PNG/GIF/BMP.");
            this.onClickHandlerCancel()
            return false;
        }
        if(!utility.checkMaxAllowedFileSize(event.target.files[0])){
            toast.error("File too big. Maximum file upload size is 20 MB");
            this.onClickHandlerCancel()
            return false;
        }

        this.setState({
            selectedFile: event.target.files[0],
            loaded: 0,
        })
    }

    onClickHandlerCancel = () => {
        this.setState({
            selectedFile: null,
        })
    }

    onClickHandler = async () => {
        if(this.state.selectedFile === "None"){
            this.onClickHandlerCancel()
            return false;
        }
        let userData=await UserStore.fetchSessionData();
        const data = new FormData()
        this.setState({loading:true, progressStatus:true});
        data.append('fileName', this.state.selectedFile);
        this.props.initUploadCallback();
        let fileUpload = await PharmacyService.uploadFile(data,userData);
        this.setState({loading:false, progressStatus:false, selectedFile: null});
        if(fileUpload){
            this.setState({"fileUploadedResObj": fileUpload})
            let files= this.state.filesList;
            files.push(fileUpload.path)
            this.setState({filesList: files})
            this.resetFileInput()
            this.fileUploadCallback(files)
        }
    }

    onInitFileHandler  = async () => {
        this.setState({"selectedFile":"None"})
        this.uploadPrescription.current.click();

    }

    initDeleteDoc = (index) => {
        let existingList = this.state.filesList;
        existingList.splice(index, 1);
        this.setState({"filesList": existingList})
    }

    updateProductImage = ()=>{
        this.props.updateProductImage(this.state.filesList)
        this.setState({filesList:[]})
    }

    resetFileInput() {
        let randomString = Math.random().toString(36);
        this.setState({
            theInputKey: randomString
        });
    }

    componentWillReceiveProps({nextProps}) {
        this.setState({...this.state,nextProps})
        if(nextProps === true){
            this.setState({
                filesList: [],
                selectedFile:null,
                fileUploadedResObj:{},
            })
        }
    }

    onDownloadImage = (row) => {
        console.log(row);
        this.setState({activeImage: row})
        this.toggleLB()
    }

    // This toggle opens or closes the custom lightbox
    toggleLB = () => {
        this.setState({
            modalLB: !this.state.modalLB
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className={'row mx-0 p-0'} >
                    {this.state.filesList.map((row, index) => (
                        <div className={"m-3"} key={index}>
                            {((row.filePathThumb.indexOf(".png") > 0) || (row.filePathThumb.indexOf(".jpg") > 0) || (row.filePathThumb.indexOf(".jpeg") > 0)) &&
                            <img alt={""} src={config.IMAGE_HOST + row.filePathThumb} style={{cursor:"pointer"}}   onClick={()=>this.onDownloadImage(row.filePathOriginal)}  className={"float-left"} style={{width: "100px", height: "100px"}}/>
                            }
                            <span style={{"cursor":"pointer"}} onClick={()=>this.initDeleteDoc(index)}><MDBIcon icon="trash" /></span>
                        </div>
                    ))}
                    <form method="post" action="#" id="#">
                        <div className="form-group files mt-3">
                            <input key={this.state.theInputKey || '' } type="file" style={{"display": "none"}} name="file" ref={this.uploadPrescription} onChange={this.onChangeHandler}/>
                            {!this.state.selectedFile &&
                            <a className={'add-image-btn'} onClick={this.onInitFileHandler}>
                                <MDBIcon icon="camera" size={'2x'}/><br/>
                                Add Image
                            </a>
                            }
                            {this.state.selectedFile &&
                            <div>
                                <MDBBtn outline size={"sm"} className="btn " onClick={this.onClickHandlerCancel}>Cancel</MDBBtn>
                                <MDBBtn size={"sm"} onClick={this.onClickHandler}>Upload</MDBBtn>
                                {this.state.loading === true &&
                                <span> <img alt={""} style={{"width":"4rem"}} src={require('../../../components/common/img/loader_new.gif')}/></span>
                                }
                                {this.state.selectedFile.name}
                            </div>
                            }
                        </div>

                    </form>

                    <MDBModal isOpen={this.state.modalLB} toggle={this.toggleLB} size={"lg"}>
                        <MDBModalHeader toggle={this.toggleLB}></MDBModalHeader>
                        <MDBModalBody className={"float-center"}>
                            <div style={{"textAlign":"center"}}>
                                <img src={config.IMAGE_HOST + this.state.activeImage} className={"float-center"}
                                     style={{maxWidth: "440px", maxHeight: "440px"}} alt={""}/>
                            </div>
                        </MDBModalBody>
                    </MDBModal>

                </div>
            </React.Fragment>
        );
    }
}
export default AddProductImage