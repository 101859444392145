import React,{Suspense} from "react";
import {Link} from "react-router-dom";
import {
    MDBBreadcrumbItem, MDBBreadcrumb, MDBRow
} from 'mdbreact';
import CreateReturnInward from "../../components/return/createReturnInward";


export default class CreateReturnInwardPage extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem><Link to={'/view-return-inwards'}>View Return Inwards</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Create Return Inward</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <MDBRow className={"min-vh-100"}>
                    <Suspense fallback={null}>
                        <CreateReturnInward queryString={this.props.match.params} />
                    </Suspense>
                </MDBRow>
            </React.Fragment>
        );
    }
}