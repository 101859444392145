const dataTableOrgUser= {
    orgUser:[
        {
            label: 'Organization Name',
            field: 'orgName',
            sort: 'asc',
            width: 300
        },
        {
            label: 'Created By',
            field: 'createdBy',
            sort: 'asc',
            width: 100
        },
        {
            label: 'Image',
            field: 'handleImage',
            sort:'disabled',
            width: 50
        },
        {
            label: '',
            field: 'handleEdit',
            sort:'disabled',
            width: 50
        },
        {
            label: '',
            field: 'handleAddUser',
            sort:'disabled',
            width: 50
        },
        {
            label: '',
            field: 'handleUsers',
            sort:'disabled',
            width: 50
        },
        {
            label: '',
            field: 'handleStatus',
            sort:'disabled',
            width: 50
        }
    ]
}
export default dataTableOrgUser;