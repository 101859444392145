import React from "react";
import UserStore from "../../stores/userStore";
import {Pie} from "react-chartjs-2";
import ReportService from "../../services/reports";

export default class OrderDetails extends React.Component {
    async componentDidMount() {
        await this.fetchData();
    }

    fetchData = async () => {
        let userData = await UserStore.fetchSessionData();
        //widgets data
        let orders = await ReportService.fetchMonthlyOrderPie(userData);
        // TELEPHONIC, QUICK_ORDER, CONTACT_US, WEB, BOT, WHATSAPP
        let dataPie = {
            labels: ["Telephonic", "Subscription", "Quick Order", "Contact Us", "Web", "Bot", "Whatsapp"],
            // labels: ["Telephonic"],
            datasets: [
                {
                    data: orders,
                    backgroundColor: [
                        "#9253B3",
                        "#ffb74d",
                        "#E22C2C",
                        "#525252",
                        "#74B353",
                        "#348df1",
                        "#128C7E"
                    ],
                    hoverBackgroundColor: [
                        "#9253B3",
                        "#ffb74d",
                        "#E22C2C",
                        "#525252",
                        "#74B353",
                        "#348df1",
                        "#128C7E"
                    ]
                }
            ]
        }
        this.setState({dataPie: dataPie});
    }

    constructor(props) {
        super(props);
        this.state = {
            dataPie: {}
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className={'white rounded-sm p-2'}>
                    <Pie data={this.state.dataPie} options={{responsive: true}}/>
                </div>
            </React.Fragment>
        );
    }
}


