import React from "react";
import {Link, withRouter} from "react-router-dom";
import {
    MDBContainer, MDBRow, MDBInput, MDBFormInline, MDBBtn, MDBCol,
    MDBCard, MDBCardBody, MDBCardHeader, MDBBreadcrumb, MDBBreadcrumbItem
} from 'mdbreact';
import UserStore from "../../stores/userStore";
import {toast} from "react-toastify";
import util from "../../utility/utility";
import AuthService from "../../services/authService";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import SimpleBackdrop from "../common/overlay";
import orgConfig from "../../orgConfig";
import utility from "../../utility/utility";
import Moments from "moment";

class AddCustomer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name:"", description:"", isActive:"", progressStatus:false, nameError:false, contactNoError:false,
            email:"", contactNo:"", userNameError:false, passwordError:false, passwordType:"password",
            dob:null, gender:"Male",
        }
    }
    handleChangeContactNo=(e)=>{
        if(!util.isInt(e.target.value) && e.target.value !== ""){
            return false;
        }
        this.setState({contactNo:e.target.value})
    };
    handleChangeEmail=(e)=>{
        this.setState({email:e.target.value})
    };
    handleName=(e)=>{
        this.setState({name:e.target.value})
    };
    handleDOB=(e)=>{
        this.setState({dob:e})
    };
    validateName = () => {
        if(!this.state.name || this.state.name.trim() === ""){
            this.setState({nameError: true})
        } else {
            this.setState({nameError: false})
        }
    }
    changeGender = (val) => {
        this.setState({"gender":val});
    };
    validateEmail = () => {
        if(this.state.email && !util.validateEmail(this.state.email)){
            this.setState({"loadingEmail": true, "emailError": true})
        } else {
            this.setState({"loadingEmail": false, "emailError": false})
        }
    }
    validatePhone= () => {
        if(!util.checkPhone(this.state.contactNo)){
            this.setState({ "contactNoError": true})
        } else {
            this.setState({"contactNoError": false})
        }
    }
    associateUser = async () => {
        let userData=await UserStore.fetchSessionData();
        let res=await AuthService.associateUser(this.state, userData);
        console.log(res)
    }

    resetState = () => {
        this.setState({
            name:"", description:"", isActive:"", progressStatus:false, nameError:false, contactNoError:false,
            email:"", contactNo:"", userNameError:false, passwordError:false, passwordType:"password",
            dob:new Date(), gender:"Male",
        })
    }

    addUser =async () => {
        if(!this.state.name || this.state.name.trim() === ""){
            this.setState({"nameError": true})
            return false
        }
        if(!util.checkPhone(this.state.contactNo)){
            this.setState({ "contactNoError": true})
            return false
        }
        if(this.state.email!=="") {
            if(!utility.validateEmail(this.state.email)){
                toast.error("Please enter valid email.");
                return false
            }
        }
        let dob="";
        if(this.state.dob!=="") {
                dob=Moments(this.state.dob).format("YYYY-MM-DD")
        }
        this.setState({progressStatus:true});
        let userData=await UserStore.fetchSessionData();
        let res=await AuthService.createCustomerv3(this.state.name,this.state.contactNo,this.state.gender,dob,this.state.email,userData);
        // let res=await AuthService.register(this.state);
        this.setState({progressStatus:false});
        if(res===false){
            toast.error("Phone no already exists.");
            return true;
        }else {
            toast.success("Customer created successfully.");
            this.resetState()
        }

        //     console.log(res)
        //     if(res.hasOwnProperty("code")){
        //         if(res.code === "10020"){
        //             toast.error("User already exist.")
        //             return false;
        //         } else {
        //             toast.error("Something went wrong.")
        //             return false;
        //         }
        //     }
        //     if (res.hasOwnProperty("patients")){
        //         this.setState({userId:res.patients[0].userId, dependentId: res.patients[0].dependentId })
        //         let user = {
        //             user_id: res.patients[0].userId,
        //             email: (res.patients[0].email) ? res.patients[0].email :"",
        //             dependent_id: (res.patients[0].dependentId) ? res.patients[0].dependentId :"",
        //             contact_no: (res.patients[0].contactNo) ? res.patients[0].contactNo :"",
        //             name: res.patients[0].name,
        //             gender:res.patients[0].gender,
        //         }
        //         console.log(user)
        //         this.setState({user:user })
        //         console.log(this.state.user)
        //     }
        //     toast.success("User added successfully.");
        //     this.associateUser()
        //     this.resetState()
        //}
    }

    render() {
        return (
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem ><Link to={'/view-customers'}>Customers</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Add Customer</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <SimpleBackdrop status={(this.state.progressStatus === true || this.state.updateProgress === true)?true:false}/>
                <MDBContainer>
                    <MDBCard className={"mt-2"}>
                        <MDBCardHeader>
                            <div className={"float-left title"}>Add Customer</div>
                        </MDBCardHeader>
                        <MDBCardBody>
                                <MDBRow>
                                    <MDBCol>
                                        <MDBInput autoComplete={'off'} style={{"marginTop": "0px"}} onBlur={this.validateName}
                                                  className={this.state.nameError ? "border-danger" : "mt-0 mb-0"}
                                                  maxLength="100" type='text' label="Name *"
                                                  onChange={this.handleName}
                                                  value={this.state.name}/>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol>
                                        <MDBInput onBlur={() => this.validatePhone()}
                                                  autoComplete={'off'}
                                                  className={this.state.contactNoError ? "border-danger" : ""}
                                                  maxLength={orgConfig.PHONE_NUMBER_LENGTH} type='text' label="Phone *"
                                                  onChange={this.handleChangeContactNo}
                                                  value={this.state.contactNo}/>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol>
                                        <MDBInput onBlur={() => this.validateEmail()}
                                                  autoComplete={'off'}
                                                  className={this.state.emailError ? "border-danger" : ""}
                                                  maxLength="50" type='text' label="E-mail"
                                                  onChange={this.handleChangeEmail}
                                                  value={this.state.email}/>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol>
                                        <MDBFormInline className={"mb-4"}>
                                            <MDBInput onClick={()=>this.changeGender("Male")} checked={this.state.gender === "Male" ? true : false}
                                                      label='Male' type='radio' id='radio1' containerClass='mr-5'/>
                                            <MDBInput onClick={()=>this.changeGender("Female")} checked={this.state.gender === "Female" ? true : false}
                                                      label='Female' type='radio' id='radio2' containerClass='mr-5'/>
                                            <MDBInput onClick={()=>this.changeGender("Other")} checked={this.state.gender === "Other" ? true : false}
                                                      label='Other' type='radio' id='radio3' containerClass='mr-5'/>
                                        </MDBFormInline>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol>
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <KeyboardDatePicker style={{"marginTop":"5px","marginLeft":"1px", "marginRight":"1px"}}
                                                                format="MMM D, YYYY"
                                                                margin="normal"
                                                                id="receiving_date"
                                                                label="Date of birth"
                                                                value={this.state.dob}
                                                                onChange={this.handleDOB}
                                                                KeyboardButtonProps={{
                                                                    "aria-label": "change date",
                                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </MDBCol>
                                </MDBRow>
                        </MDBCardBody>
                        <div className="m-0 text-center">
                            <MDBBtn outline  size="md" onClick={() =>this.props.history.push('/view-customers')} className="mr-auto" disabled={this.state.progressStatus}>
                                Back
                            </MDBBtn>
                            <MDBBtn  size="md" onClick={this.addUser} className="mr-auto"
                                    disabled={this.state.nameError || this.state.progressStatus || this.state.contactNoError}>
                                Save
                            </MDBBtn>
                        </div>
                    </MDBCard>
                </MDBContainer>
            </React.Fragment>
        );
    }
}
export default withRouter(AddCustomer)
