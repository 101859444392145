import React,{Suspense} from "react";
import {
    MDBBox
} from 'mdbreact';
//const OnlineOrderDetail=React.lazy(() => import("../../components/fulfill-order/order-detail"));
import OnlineOrderDetail from "../../components/fulfill-order/order-detail";

export default class FulFillOrderDetail extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MDBBox>
                    <Suspense fallback={null}>
                        <OnlineOrderDetail/>
                    </Suspense>
                </MDBBox>
            </React.Fragment>
        );
    }
}
