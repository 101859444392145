import React from "react";
import {Link, withRouter} from "react-router-dom";
import MomentUtils from '@date-io/moment';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import {
    MDBContainer,
    MDBRow,
    MDBInput,
    MDBFormInline,
    MDBBtn,
    MDBCol,
    MDBSelect,
    MDBListGroup,
    MDBListGroupItem,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBBreadcrumb,
    MDBBreadcrumbItem,
    MDBModalHeader,
    MDBModalBody,
    MDBModalFooter,
    MDBModal,
    MDBIcon,
} from 'mdbreact';
import UserStore from "../../stores/userStore";
import {toast} from "react-toastify";
import PharmacyService from "../../services/pharmacyService";
import FileUpload from "../common/fileUpload/fileUpload";
import util from "../../utility/utility";
import config from "../../config";
import Moments from "moment";
import SimpleBackdrop from "../common/overlay";
import AdvancedDocUpload from "../common/fileUpload/advanceDocUpload";
import MultipleDocsUpload from "../common/fileUpload/MultipleDocsUpload";
import utility from "../../utility/utility";
import UpdateManufacturer from "../manufacturer/updateManufacturer";

class UpdateShipment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeImage:"",
            userData:{},
            shipment:{},
            supplier:{},
            user:{},
            amount:"",
            receivedBy:"",
            receivingDate:null,
            receivingTime:null,
            invoiceDate:null,
            invoiceNumber:"",
            supplyPlace:"",
            reverseCharge:"",
            totalTaxAmount:"",
            cessTaxAmount:"",
            discountAmount:"",
            comments:"",
            documentUrl:{},
            docs:[],
            isActive:"",
            progressStatus:false,
            progressStatusFileUpload:false,
            selectedDate:new Date(),
            suggestedSupplier:[],
            allSuggestedUser:[],
            suggestedUser:[],
            orgId:0,
            supplierError:false,
            receivedByError:false,
            invoiceTypeError:false,
            invoiceNumberError:false,
            amountError:false,
            totalTaxAmountError:false,
            totalCessTaxAmountError:false,
            discountAmountError:false,
            invoiceDateError:false,
            receivingDateError:false,
            supplyPlaceError:false,
            reverseChargeError:false,
            resetForm:false,
            modalDelete:false,
            shipmentApiResultCame:false,
            invoiceType:"GST Invoice",

            invoiceTypes: [ {
                    text: "GST Invoice",
                    value: "GST Invoice"
                },{
                    text: "Retail Invoice",
                    value: "Retail Invoice"
                }
            ],

            modalLB:false,
            fileToBeDeleted:"",
            modalDeleteDocument:false,
            refreshFileComponentKey:"1",
            loadedOnPopup:false,
        }
    }
    handleComments=(e)=>{
        this.setState({comments:e.target.value})
    };
    handleReceivingDate=(date)=>{
        this.setState({receivingDate:date})
    };
    handleReceivingTime=(e)=>{
        this.setState({receivingTime:e});
    };
    handleInvoiceDate = (date) => {
        this.setState({invoiceDate:date})
    };
    handleInvoiceNumber=(e)=>{
        this.setState({invoiceNumber:e.target.value})
    };
    handleSupplyPlace=(e)=>{
        this.setState({supplyPlace:e.target.value})
    };
    handleReverseCharge=(e)=>{
        if(!util.isNumericTwoDecimalPartial(e.target.value) && e.target.value !== ""){
            return false;
        }
        this.setState({reverseCharge:e.target.value})
    };
    handleTotalTaxAmount=(e)=>{
        if(!util.isNumericTwoDecimalPartial(e.target.value) && e.target.value !== ""){
            return false;
        }
        this.setState({totalTaxAmount:e.target.value})
    };
    handleTotalCessTaxAmount=(e)=>{
        if(!util.isNumericTwoDecimalPartial(e.target.value) && e.target.value !== ""){
            return false;
        }
        this.setState({cessTaxAmount:e.target.value})
    };
    handleDiscountAmount=(e)=>{
        if(!util.isNumericTwoDecimalPartial(e.target.value) && e.target.value !== ""){
            return false;
        }
        this.setState({discountAmount:e.target.value})
    };
    handleAmount=(e)=>{
        if(!util.isNumericTwoDecimalPartial(e.target.value) && e.target.value !== ""){
            return false;
        }
        this.setState({amount:e.target.value})
    };
    handleInvoiceType = (e) => {
        if (e.length > 0) {
            this.setState({invoiceType:e[0]});
        }
    };
    fetchShipmentById = async () => {
        this.setState({progressStatus:true});
        let res = await PharmacyService.fetchShipmentById(this.state);
        this.setState({shipmentApiResultCame:true})
        this.setState({progressStatus:false});
        if(res){
            try{
                let supplier = [];
                supplier.push(res.supplierDetails)
                this.setState({suggestedSupplier:supplier})
                this.setState({shipment:res})
                this.setState({
                    supplier:res.supplierDetails,
                    user:res.receivedByDetails,
                    amount:res.amount,
                    receivedBy:"",
                    receivingDate:new Date(res.receivingDate),
                    receivingTime:new Date(res.receivingTime),
                    invoiceDate:new Date(res.invoiceDate),
                    invoiceNumber:res.invoiceNumber,
                    supplyPlace:res.supplyPlace,
                    reverseCharge:res.reverseCharge,
                    totalTaxAmount:res.totalTaxAmount,
                    cessTaxAmount:res.cess  === "" ? 0 : res.cess,
                    discountAmount:res.discountAmount,
                    comments:res.comments,
                    documentUrl:res.documentUrl,
                })


                if(res.documentUrl.hasOwnProperty("filePathOriginal")){
                    this.setState({"imageActionNeeded":"Update"})
                } else {
                    this.setState({"imageActionNeeded":"Add"})
                }
            } catch (e) {
                toast.error("Something went wrong while fetching manufacturer by id.")
            }
        }
    }
    async componentDidMount() {
        let userData = await UserStore.fetchSessionData();
        this.setState({userData: userData})
        if(userData.type === "platformAdmin"){
            this.setState({orgId: userData._id})
        } else if(userData.type === "ORG_ADMIN"){
            this.setState({orgId: userData.orgDetails[0]._id})
        }

        if(this.props.loadedOnPopup === true){
           let shipmentId = this.props.shipmentId;
            this.setState({shipmentId:shipmentId, loadedOnPopup:true})
        } else {
            const { shipmentId } = this.props.queryString;
            this.setState({shipmentId:shipmentId})
        }

        this.fetchShipmentById()
    }
    onChangeSupplier = (event, values) => {
        if(values.value!==null) {
            this.setState({
                supplier: values.value
            }, () => {
                this.setState({supplierError:false})
            });
        }
    }

    onDownloadImage = (row) => {
        this.setState({activeImage: row})
        this.toggleLB()
    }

    // This toggle opens or closes the custom lightbox
    toggleLB = () => {
        this.setState({
            modalLB: !this.state.modalLB
        });
    }

    // fileUploadCallback = (uploadedFileObject) => {
    //     this.setState({ "documentUrl": uploadedFileObject.path, "progressStatusFileUpload":false})
    // }
    // initUploadCallback = () => {
    //     this.setState({"progressStatusFileUpload":true, resetForm:false})
    // }

    fileUploadCallback = (files) => {
        this.setState({ "docs": files, "progressStatusFileUpload":false})
        this.updateShipmentDocument()
    }

    initUploadCallback = () => {
        this.setState({"progressStatusFileUpload":true})
    }

    initDeleteDoc = (row) => {

        this.setState({fileToBeDeleted:row})
        this.toggleDeleteDocument()
    }

    toggleDeleteDocument = () => {
        this.setState({
            modalDeleteDocument: !this.state.modalDeleteDocument
        });
    }

    confirmDeleteDocument = async () =>{
        //let status=await PharmacyService.deleteFile(this.state);
        let index = this.state.documentUrl.indexOf(this.state.fileToBeDeleted);
        let newDocumentUrl = this.state.documentUrl
        newDocumentUrl.splice(index, 1)
        this.setState({documentUrl:newDocumentUrl})
        this.updateShipmentDocument()
        this.toggleDeleteDocument()

    }

    updateShipmentDocument = async () => {
        let status=await PharmacyService.updateShipmentDocument(this.state);
        this.fetchShipmentById()
        this.setState({docs:[],refreshFileComponentKey:Math.random().toString(36)})
    }

    validateSupplier = () => {
        if(!this.state.supplier.hasOwnProperty("_id")){
            this.setState({"supplierError": true})
        } else {
            this.setState({"supplierError": false})
        }
    }
    validateReceivedBy = () => {
        if(!this.state.user.hasOwnProperty("_id")){
            this.setState({"receivedByError": true})
        } else {
            this.setState({"receivedByError": false})
        }
    }
    validateInvoiceNumber = () => {
        if(!this.state.invoiceNumber || !this.state.invoiceNumber.trim()){
            this.setState({"invoiceNumberError": true})
        } else {
            this.setState({"invoiceNumberError": false})
        }
    }
    validateAmount = () => {
        if(!this.state.amount || !util.isNumericTwoDecimalFull(this.state.amount)){
            this.setState({"amountError": true})
        } else {
            this.setState({"amountError": false})
        }
    }
    validateTotalTaxAmount = () => {
        if(this.state.totalTaxAmount && !util.isNumericTwoDecimalFull(this.state.totalTaxAmount)){
            this.setState({"totalTaxAmountError": true})
        } else {
            this.setState({"totalTaxAmountError": false})
        }
    }
    validateTotalCessTaxAmount = () => {
        if(this.state.cessTaxAmount && !util.isNumericTwoDecimalFull(this.state.cessTaxAmount)){
            this.setState({"totalCessTaxAmountError": true})
        } else {
            this.setState({"totalCessTaxAmountError": false})
        }
    }
    validateDiscountAmount = () => {
        if(this.state.discountAmount && !util.isNumericTwoDecimalFull(this.state.discountAmount)){
            this.setState({"discountAmountError": true})
        } else {
            this.setState({"discountAmountError": false})
        }
    }

    resetField = () =>{
        this.setState({
            supplier:{},
            user:{},
            amount:"",
            receivedBy:"",
            receivingDate:new Date(),
            receivingTime:new Date(),
            invoiceDate:new Date(),
            invoiceNumber:"",
            supplyPlace:"",
            reverseCharge:"",
            totalTaxAmount:"",
            cessTaxAmount:"",
            discountAmount:"",
            comments:"",
            documentUrl:{},
        })
    }
    resetError = () => {
        this.setState({
            supplierError:false,
            receivedByError:false,
            invoiceTypeError:false,
            invoiceNumberError:false,
            amountError:false,
            totalTaxAmountError:false,
            totalCessTaxAmountError:false,
            discountAmountError:false,
            invoiceDateError:false,
            receivingDateError:false,
            supplyPlaceError:false,
            reverseChargeError:false,
            resetForm:false,
        })
    }
    updateShipment =async () => {
        let userData=await UserStore.fetchSessionData();
        // Reset all the error first
        this.resetError()
        this.validateInvoiceNumber();
        this.validateAmount();
        this.validateTotalTaxAmount();
        this.validateTotalCessTaxAmount();
        this.validateDiscountAmount();

        if(this.state.progressStatus || this.state.progressStatusFileUpload || this.state.supplierError || this.state.receivedByError || this.state.invoiceNumberError || this.state.amountError ||  this.state.totalTaxAmountError ||  this.state.totalCessTaxAmountError || this.state.discountAmountError){
            return false;
        }
        this.setState({progressStatus:true});
        let status=await PharmacyService.updateShipment(this.state,userData);
        this.setState({progressStatus:false});
        if(status===false){
            toast.error("Something went wrong.");
            return true;
        }else{
            toast.success("Shipment updated successfully.");
            this.setState({resetForm:true})
            if(this.state.loadedOnPopup === true){
                this.props.onEditComplete()
            } else {
                this.fetchShipmentById()
            }
        }
    }

    toggleDelete = () => {
        this.setState({
            modalDelete: !this.state.modalDelete
        });
    }
    confirmDelete = async () => {
        let userData=await UserStore.fetchSessionData();
        this.setState({progressStatus:true});
        let status=await PharmacyService.deleteShipment(this.state.shipmentId,this.state.orgId,userData);
        this.setState({progressStatus:false});
        if(status===false){
            toast.error("Something went wrong.");
            return true;
        }else{
            if(this.state.loadedOnPopup === true){
                toast.success("Shipment deleted successfully.");
                this.toggleDelete();
                this.props.onEditComplete()
            } else {
                this.fetchShipmentById()
                this.props.history.push('/view-incoming-shipments');
            }
        }
    }

    onDownloadImage=(row)=>{
        this.setState({"activeImage":row})
        this.toggleLB()
    }

    render() {
        return (
            <React.Fragment>
                {this.state.loadedOnPopup === false &&
                <>
                    <SimpleBackdrop status={this.state.progressStatus}/>
                    <MDBBreadcrumb>
                        <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                        <MDBBreadcrumbItem ><Link to={'/view-incoming-shipments'}>Shipments</Link></MDBBreadcrumbItem>
                        <MDBBreadcrumbItem active>Edit Incoming Shipment</MDBBreadcrumbItem>
                    </MDBBreadcrumb>
                </>
                }
                <MDBContainer>
                    <MDBCard className={"mt-2"}>
                        <MDBCardHeader>
                            <span className={"float-left title"}>Update Incoming Shipment</span>
                        </MDBCardHeader>
                        <MDBCardBody>
                            <MDBRow className={"mb-3"}>
                                <MDBCol>
                                    {this.state.supplier.hasOwnProperty("supplierName") &&
                                        <div>Supplier:{this.state.supplier.supplierName}</div>
                                    }
                                    {this.state.shipment.hasOwnProperty("shipmentCode") &&
                                    <div>Shipment#:{this.state.shipment.shipmentCode}</div>
                                    }
                                </MDBCol>
                                <MDBCol>
                                    {this.state.user.name &&
                                    <span>Received By:{this.state.user.name}</span>
                                    }
                                </MDBCol>
                                {this.state.shipment.hasOwnProperty("invoiceType") &&
                                    <MDBCol>Invoice Type:{this.state.shipment.invoiceType}</MDBCol>
                                }
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBInput className={this.state.invoiceNumberError ? "border-danger" : ""} maxLength="30"
                                              type='text' label="Invoice Number" onChange={this.handleInvoiceNumber}
                                              value={this.state.invoiceNumber} onBlur={this.validateInvoiceNumber}/>
                                </MDBCol>
                                <MDBCol>
                                    <MDBInput className={this.state.amountError ? "border-danger" : ""} maxLength="15"
                                              type='text' label="Invoiced/Payable Amount" onChange={this.handleAmount}
                                              value={this.state.amount} onBlur={this.validateAmount}/>
                                </MDBCol>
                                <MDBCol>
                                    <MDBInput className={this.state.totalTaxAmountError ? "border-danger" : ""} maxLength="15"
                                              type='text' label="Total Tax Amount" onChange={this.handleTotalTaxAmount}
                                              value={this.state.totalTaxAmount} onBlur={this.validateTotalTaxAmount}/>
                                </MDBCol>
                                <div className={'col-md'}>
                                    <MDBInput className={this.state.totalCessTaxAmountError ? "border-danger" : ""} maxLength="15"
                                              type='text' label="Cess Tax Amount" onChange={this.handleTotalCessTaxAmount}
                                              value={this.state.cessTaxAmount} onBlur={this.validateTotalCessTaxAmount}/>
                                </div>
                                <MDBCol>
                                    <MDBInput className={this.state.discountAmountError ? "border-danger" : ""} maxLength="15"
                                              type='text' label="Discount Amount" onChange={this.handleDiscountAmount}
                                              value={this.state.discountAmount} onBlur={this.validateDiscountAmount}/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            format="MMM DD, YYYY"
                                            margin="normal"
                                            id="invoice_date"
                                            label="Invoice Date"
                                            value={this.state.invoiceDate}
                                            onChange={this.handleInvoiceDate}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </MDBCol>
                                <MDBCol>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker

                                            format="MMM DD, YYYY"
                                            margin="normal"
                                            id="receiving_date"
                                            label="Receiving Date"
                                            value={this.state.receivingDate}
                                            onChange={this.handleReceivingDate}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </MDBCol>
                                <MDBCol>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardTimePicker
                                            margin="normal"
                                            id="time-picker"
                                            label="Receiving Time"
                                            value={this.state.receivingTime}
                                            onChange={this.handleReceivingTime}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change time',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBInput   className={this.state.supplyPlaceError ? "border-danger" : ""} maxLength="100"
                                                type='text' label="Supply Place" onChange={this.handleSupplyPlace}
                                                value={this.state.supplyPlace}/>
                                </MDBCol>
                                <MDBCol>
                                    <MDBInput className={this.state.reverseChargeError ? "border-danger" : ""} maxLength="15"
                                              type='text' label="Reverse Charge" onChange={this.handleReverseCharge}
                                              value={this.state.reverseCharge}/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBInput autoComplete={'off'} style={{"marginTop": "0px"}}
                                              className={this.state.descriptionError ? "border-danger" : "mt-0 mb-0"}
                                              maxLength="200" type='textarea' label="Comments"
                                              onChange={this.handleComments}
                                              value={this.state.comments}/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>

                                <MDBCol>

                                    {(this.state.documentUrl) &&
                                    <div>
                                        {/* <img src={config.IMAGE_HOST + this.state.documentUrl.filePathThumb} className={"float-left"} style={{maxWidth: "100px", maxHeight: "100px"}} alt={""}/> */}
                                    </div>
                                    }

                                    {((this.state.documentUrl && this.state.documentUrl.length > 0)) &&
                                        <ul className="list-group list-group-horizontal">
                                        {this.state.documentUrl.map((row, index) => (
                                            <li key={index} className="list-group-item">
                                                {(utility.ifFileIsDocument(row)) &&
                                                <a className={'file-size float-left'}
                                                   href={utility.secureFilePath(this.state.userData.sessionToken, this.state.userData._id, row)}><MDBIcon
                                                    style={{"fontSize": "3rem"}} icon="file"/></a>
                                                }
                                                {(utility.ifFileIsImage(row)) &&
                                                <img className={'file-size float-left img-fluid'}
                                                     style={{cursor: "pointer", maxWidth: "100px", maxHeight: "100px"}}
                                                     onClick={() => this.onDownloadImage(row)}
                                                     alt={""}
                                                     src={config.IMAGE_HOST + row}
                                                    //  src={utility.secureFilePath(this.state.userData.sessionToken, this.state.userData._id, row)}
                                                     />
                                                }
                                                <span className={"ml-1 mb-2 float-left"}
                                                      style={{"cursor": "pointer", marginBottom: "-20px"}}
                                                      onClick={() => this.initDeleteDoc(row)}><MDBIcon icon="trash"/></span>
                                                <div style={{clear: "both"}}></div>
                                                <div className={"mb-1"}>File_{index + 1}</div>
                                            </li>
                                        ))}
                                    </ul>
                                    }

                                </MDBCol>

                            </MDBRow>
                            <MDBRow>
                                <MDBCol key={this.state.refreshFileComponentKey}>
                                    {/*{this.state.imageActionNeeded === "Add" &&*/}
                                    {/*<FileUpload buttonLabel={"Attach Document"} initUploadCallback={this.initUploadCallback}*/}
                                    {/*            fileUploadCallback={this.fileUploadCallback} nextProps={this.state.resetForm}></FileUpload>*/}
                                    {/*}*/}
                                    {/*{this.state.imageActionNeeded === "Update" &&*/}
                                    {/*<FileUpload buttonLabel={"Update Document"} initUploadCallback={this.initUploadCallback}*/}
                                    {/*            fileUploadCallback={this.fileUploadCallback} nextProps={this.state.resetForm}></FileUpload>*/}
                                    {/*}*/}

                                    <MultipleDocsUpload buttonLabel={"Attach Document"} initUploadCallback={this.initUploadCallback} fileUploadCallback={this.fileUploadCallback}  nextProps={this.state.resetForm}></MultipleDocsUpload>


                                </MDBCol>
                            </MDBRow>
                            <div className="text-center md-form  m-0 col-12">
                                {this.state.loadedOnPopup === false &&
                                <MDBBtn outline  size="md"
                                        onClick={() => this.props.history.push('/view-incoming-shipments')}
                                        className="mr-auto" disabled={this.state.progressStatus}>
                                    Back
                                </MDBBtn>
                                }
                                <MDBBtn outline size="md" onClick={this.toggleDelete}
                                        disabled={this.state.progressStatus}
                                >
                                    Delete
                                </MDBBtn>
                                <MDBBtn  size="md" onClick={this.updateShipment}
                                        disabled={this.state.progressStatus || this.state.progressStatusFileUpload || this.state.supplierError || this.state.receivedByError || this.state.invoiceNumberError || this.state.amountError ||  this.state.totalTaxAmountError || this.state.discountAmountError}>
                                    Save
                                </MDBBtn>
                            </div>
                        </MDBCardBody>
                    </MDBCard>
                    <MDBModal isOpen={this.state.modalDelete} toggle={this.toggleDelete}>
                        <MDBModalHeader toggle={this.toggleDelete}>Delete Shipment</MDBModalHeader>
                        <MDBModalBody>
                            Are you sure you want to delete this shipment?
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn disabled={this.state.progressStatus} color="primary" onClick={this.confirmDelete}>Delete</MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>

                    <MDBModal isOpen={this.state.modalDeleteDocument} toggle={this.toggleDeleteDocument}>
                        <MDBModalHeader toggle={this.toggleDeleteDocument}>Delete Shipment Document</MDBModalHeader>
                        <MDBModalBody>
                            Are you sure you want to delete this document?

                            <div className={"mt-3"}>
                            {((this.state.fileToBeDeleted.indexOf(".pdf") > 0) || (this.state.fileToBeDeleted.indexOf(".PDF") > 0)) &&
                            <a className={'file-size mr-1'} title={"Click to download"} download={true}  href={config.UPLOADED_DOC_HOST+this.state.userData.sessionToken+"/"+this.state.userData._id+"/"+ this.state.fileToBeDeleted}><MDBIcon style={{fontSize:"3rem"}} icon="file"/></a>
                            }
                            {((this.state.fileToBeDeleted.indexOf(".png") > 0) || (this.state.fileToBeDeleted.indexOf(".PNG") > 0) || (this.state.fileToBeDeleted.indexOf(".jpeg") > 0) || (this.state.fileToBeDeleted.indexOf(".JPEG") > 0) || (this.state.fileToBeDeleted.indexOf(".jpg") > 0) || (this.state.fileToBeDeleted.indexOf(".JPG") > 0)) &&
                            <img onClick={()=>this.onDownloadImage(this.state.fileToBeDeleted)} style={{cursor:"pointer"}}  className={'file-size mr-1'} width={"100"}  src={config.UPLOADED_DOC_HOST+this.state.userData.sessionToken+"/"+this.state.userData._id+"/"+ this.state.fileToBeDeleted}/>
                            }
                            </div>
                            {this.state.documentUrl.length > 0 &&
                            <div>
                                {this.state.documentUrl.indexOf(this.state.fileToBeDeleted) >= 0 &&
                                <span>
                                    File_{this.state.documentUrl.indexOf(this.state.fileToBeDeleted)+1}
                                </span>
                                }
                            </div>
                            }


                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn disabled={this.state.progressStatus} color="primary" onClick={this.confirmDeleteDocument}>Delete</MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>

                    <MDBModal isOpen={this.state.modalLB} toggle={this.toggleLB} size={"lg"}>
                        <MDBModalHeader toggle={this.toggleLB}></MDBModalHeader>
                        <MDBModalBody className={"float-center"}>
                            <div style={{"textAlign":"center"}}>
                                <img src={config.IMAGE_HOST + this.state.activeImage} className={"float-center"}
                                     style={{maxWidth: "440px", maxHeight: "440px"}} alt={""}/>
                            </div>
                        </MDBModalBody>
                    </MDBModal>



                </MDBContainer>
            </React.Fragment>
        );
    }
}
export default withRouter(UpdateShipment)
