import React from "react";
import {
    MDBTable,
    MDBTableBody,
    MDBTableHead,
    MDBBox,
    MDBBreadcrumbItem,
    MDBBreadcrumb,
    MDBBtn,
    MDBModal,
    MDBModalBody,
    MDBModalHeader,
    MDBModalFooter,
    MDBRow, MDBCol, MDBInput, MDBSelect, MDBSelectInput, MDBSelectOptions, MDBSelectOption
} from "mdbreact";
import Moments from "moment";
import OrderService from "../../../services/orderService";
import UserStore from "../../../stores/userStore";
import {toast} from "react-toastify";
import SimpleBackdrop from "../../common/overlay";

let Barcode = require('react-barcode');

class OrderListV2 extends React.Component {
    async componentDidMount() {
        let userData = await UserStore.fetchSessionData();
        this.setState({userData:userData});
        let dtNow = Moments();
        let start = dtNow.format("YYYY-MM-DD");
        this.setState({start: start, end: start});
        let data = await OrderService.fetchAllOrders(userData.sessionToken, start, start);
        if (data === false) {
            toast.error("We are facing some server issue. Please try again later.");
        } else {
            let temp = [];
            for (let t of data) {
                t["cusView"] = false
                temp.push(t);
            }
            this.setState({data: temp, isLoading: false});
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            userData: null,
            data: [],
            isLoading: true,
            modalCancel: false,
            itemToBeCancelled: null,
            start: "",
            end: "",
            modalEdit: false,
            itemToBeEdited: null,
            editAmountCollected: 0,
            editBalanceAmount:0,
            editPaymentMode: "",
            filterButton:"day",
            cancelComment:"",
            searchTerm:"",
            modalReturn:false,
            itemToBeReturned:null,
            modalCustomerInfo:false,
            customerInfo:null,
            productInfo:null,
            modalProductInfo:false
        }
    }

    showCustomerDetails = (item) => {
        if(this.state.modalCustomerInfo===false){
            this.setState({modalCustomerInfo:true,customerInfo:item.customerDetails});
        }else{
            this.setState({modalCustomerInfo:false,customerInfo:null});
        }
    }
    cancelOrder = async () => {
        if (this.state.itemToBeCancelled._id !== "") {
            if(this.state.cancelComment===""){
                toast.error("Please add a comment.");
                return false;
            }
            let userData = await UserStore.fetchSessionData();
            this.setState({isLoading: true});
            let status = await OrderService.cancelOrderV3(userData, this.state.itemToBeCancelled._id,this.state.cancelComment);
            if (status === false) {
                this.setState({isLoading: false});
                toast.error("We are facing some server issue. Please try again later.");
            } else {
                this.setState({modalCancel: false});
                toast.success("Order cancelled successfully.");
                let temp = [];
                for (let dt of this.state.data) {
                    if (dt._id === this.state.itemToBeCancelled._id) {
                        let item = this.state.itemToBeCancelled;
                        item.status = "CANCELLED";
                        temp.push(item);
                    } else {
                        temp.push(dt);
                    }
                }
                this.setState({data: temp, isLoading: false, itemToBeCancelled: null});
            }
        } else {
            toast.error("We are facing some issue. Please try again later.");
        }
    }


    toggleCancel = (item) => {
        if (this.state.modalCancel === false) {
            this.setState({modalCancel: true, itemToBeCancelled: item,cancelComment:""});
        } else if (this.state.modalCancel === true) {
            this.setState({modalCancel: false,itemToBeCancelled: null,cancelComment:""});
        }
    }
    toggleEdit = (item) => {
        if (this.state.modalEdit === false) {
            this.setState({modalEdit: true, itemToBeEdited: item,editAmountCollected:item.paidAmount,editPaymentMode:item.paymentMode,editBalanceAmount:item.balanceAmount});
        } else if (this.state.modalEdit === true) {
            this.setState({modalEdit: false});
        }
    }
    editOrder =async () => {
        let collectedAmount= this.state.editAmountCollected.toString();
        if( !collectedAmount.match(/^[0-9]*([.,][0-9]+)?$/) ) {
            toast.error("Invalid amount.");
            return false;
        }
        let balance=parseFloat(this.state.itemToBeEdited.totalAmount)-parseFloat(this.state.editAmountCollected);
        if(balance<0){
            toast.error("Invalid amount.");
            return false;
        }
        this.setState({editBalanceAmount:balance});
        let userData = await UserStore.fetchSessionData();
        let status=await OrderService.editOrderV3(userData,parseFloat(this.state.editAmountCollected),this.state.editPaymentMode[0],this.state.itemToBeEdited._id);
        if(status===false){
            toast.error("We are facing some issue. Please try again later.");
        }else{
            this.setState({modalEdit: false});
            toast.success("Order updated successfully.");
            let temp = [];
            for (let dt of this.state.data) {
                if (dt._id === this.state.itemToBeEdited._id) {
                    let item = this.state.itemToBeEdited;
                    item.paidAmount = parseFloat(this.state.editAmountCollected);
                    item.balanceAmount=parseFloat(this.state.totalAmount)-parseFloat(this.state.editAmountCollected);
                    temp.push(item);
                } else {
                    temp.push(dt);
                }
            }
           this.setState({data: temp, isLoading: false, itemToBeEdited: null,editAmountCollected:0,editBalanceAmount:0,editPaymentMode:""});
        }
    }

    chageTimeSpan=async (val)=>{
        this.setState({filterButton:val});
        let dateObj=Moments();
        if(val==="day"){
            let start=dateObj.format("YYYY-MM-DD");
            let end=dateObj.format("YYYY-MM-DD");
            this.setState({start:start,end:end});
        }else if(val==="week"){
            let start=dateObj.startOf("isoWeek").format("YYYY-MM-DD");
            let end=dateObj.endOf("isoWeek").format("YYYY-MM-DD");
            this.setState({start:start,end:end});
        }else if(val==="month"){
            let start=dateObj.startOf("month").format("YYYY-MM-DD");
            let end=dateObj.endOf("month").format("YYYY-MM-DD");
            this.setState({start:start,end:end});
        }
        let userData = await UserStore.fetchSessionData();
        this.setState({userData:userData,isLoading: true});
        let data = await OrderService.fetchAllOrders(userData.sessionToken, this.state.start,this.state.end);
        if (data === false) {
            toast.error("We are facing some server issue. Please try again later.");
        } else {
            let temp = [];
            for (let t of data) {
                t["cusView"] = false
                temp.push(t);
            }
            this.setState({data: temp, isLoading: false});
        }
    }
    prev=async ()=>{
        if(this.state.filterButton==="day"){
            let start=Moments(this.state.start).subtract(1,"days").format("YYYY-MM-DD");
            let end=Moments(this.state.start).subtract(1,"days").format("YYYY-MM-DD");
            this.setState({start:start,end:end});
        }else if(this.state.filterButton==="week"){
            let temp=Moments(this.state.start).subtract(1,"weeks");
            let start=temp.startOf("isoWeek").format("YYYY-MM-DD")
            let end=temp.endOf("isoWeek").format("YYYY-MM-DD");
            this.setState({start:start,end:end});
        }else if(this.state.filterButton==="month"){
            let temp=Moments(this.state.start).subtract(1,"months");
            let start=temp.startOf("month").format("YYYY-MM-DD")
            let end=temp.endOf("month").format("YYYY-MM-DD");
            this.setState({start:start,end:end});
        }
        let userData = await UserStore.fetchSessionData();
        this.setState({userData:userData,isLoading: true});
        let data = await OrderService.fetchAllOrders(userData.sessionToken, this.state.start,this.state.end);
        if (data === false) {
            toast.error("We are facing some server issue. Please try again later.");
        } else {
            let temp = [];
            for (let t of data) {
                t["cusView"] = false
                temp.push(t);
            }
            this.setState({data: temp, isLoading: false});
        }
    }
    next=async ()=>{
        if(this.state.filterButton==="day"){
            let start=Moments(this.state.start).add(1,"days").format("YYYY-MM-DD");
            let end=Moments(this.state.start).add(1,"days").format("YYYY-MM-DD");
            this.setState({start:start,end:end});
        }else if(this.state.filterButton==="week"){
            let temp=Moments(this.state.start).add(1,"weeks");
            let start=temp.startOf("isoWeek").format("YYYY-MM-DD")
            let end=temp.endOf("isoWeek").format("YYYY-MM-DD");
            this.setState({start:start,end:end});
        }else if(this.state.filterButton==="month"){
            let temp=Moments(this.state.start).add(1,"months");
            let start=temp.startOf("month").format("YYYY-MM-DD")
            let end=temp.endOf("month").format("YYYY-MM-DD");
            this.setState({start:start,end:end});
        }
        let userData = await UserStore.fetchSessionData();
        this.setState({userData:userData,isLoading: true});
        let data = await OrderService.fetchAllOrders(userData.sessionToken, this.state.start,this.state.end);
        if (data === false) {
            toast.error("We are facing some server issue. Please try again later.");
        } else {
            let temp = [];
            for (let t of data) {
                t["cusView"] = false
                temp.push(t);
            }
            this.setState({data: temp, isLoading: false});
        }
    }
    doSearch=async ()=>{
        if(this.state.searchTerm!=="") {
            let userData = await UserStore.fetchSessionData();
            this.setState({userData:userData,isLoading: true});
            let data=await OrderService.searchOrder(userData.sessionToken,this.state.searchTerm.trim());
            if (data === false) {
                toast.error("We are facing some server issue. Please try again later.");
            } else {
                let temp = [];
                for (let t of data) {
                    t["cusView"] = false
                    temp.push(t);
                }
                this.setState({data: temp, isLoading: false,filterButton:""});
            }
        }
    }
    toggleReturn=(item)=>{
        if (this.state.modalReturn === false) {
            let temp=[];
            for(let d of item.products){
                d["tobeReturned"]="";
                d["returnAmount"]="";
                temp.push(d);
            }
            item.products=temp;
            this.setState({modalReturn: true, itemToBeReturned: item});
        } else if (this.state.modalReturn === true) {
            this.setState({modalReturn: false,itemToBeReturned: null});
        }
    }
    handleQuantityChange=(val,item,index)=>{
        let temp=[];
        let count=0;
        for(let d of this.state.itemToBeReturned.products){
            if(d._id===item._id && count===parseInt(index)){
                d.tobeReturned=val;
                temp.push(d);
            }else{
                temp.push(d);
            }
            count=count+1;
        }
        let t=this.state.itemToBeReturned;
        t.products=temp;
        this.setState({itemToBeReturned:t});
    }
    handleReturnPrice=(val,item,index)=>{
        let temp=[];
        let count=0;
        for(let d of this.state.itemToBeReturned.products){
            if(d._id===item._id && count===parseInt(index)){
                d.returnAmount=val;
                temp.push(d);
            }else{
                temp.push(d);
            }
            count=count+1;
        }
        let t=this.state.itemToBeReturned;
        t.products=temp;
        this.setState({itemToBeReturned:t});
    }
    returnItems=async ()=>{
        let userData = await UserStore.fetchSessionData();
        let payload={
            orderId:this.state.itemToBeReturned._id,
            products:this.state.itemToBeReturned.products
        };
        console.log("payload",payload);
        //let data=await OrderService.returnItem(userData.sessionToken,this.state.searchTerm.trim());
    }
    showProducts=(item)=>{
        if(this.state.modalProductInfo===false){
            this.setState({modalProductInfo:true,productInfo:item.products});
        }else{
            this.setState({modalProductInfo:false,productInfo:null});
        }
    }
    render() {
        const style = {display: "inline-block", maxWidth: "50vh"};
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.isLoading}/>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem>Orders</MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>List</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <MDBBox>
                    <MDBRow>
                        <MDBCol>
                            <MDBInput label={"Order code"} getValue={(e)=>{this.setState({searchTerm:e})}}/>
                        </MDBCol>
                        <MDBCol>
                            <MDBBtn onClick={this.doSearch}>Search</MDBBtn>
                        </MDBCol>
                    </MDBRow>
                </MDBBox>
                <MDBBox>
                    <MDBBtn onClick={this.prev} disabled={this.state.filterButton===""?true:false}>Prev</MDBBtn>
                    {this.state.filterButton === "day" &&
                        <span>{Moments(this.state.start).format("ll")}</span>
                    }
                    {this.state.filterButton === "week" &&
                        <span>{Moments(this.state.start).format("ll")} - {Moments(this.state.end).format("ll")}</span>
                    }
                    {this.state.filterButton === "month" &&
                        <span>{Moments(this.state.start).format("MMM, YYYY")}</span>
                    }
                    <MDBBtn onClick={this.next} disabled={this.state.filterButton===""?true:false}>Next</MDBBtn>
                </MDBBox>
                <MDBBox>
                    <MDBBtn className={this.state.filterButton == 'day' ? "default-btn" : "" } onClick={()=>{this.chageTimeSpan("day")}}>Day</MDBBtn>
                    <MDBBtn disabled={this.state.filterButton==="week"?true:false} onClick={()=>{this.chageTimeSpan("week")}}>Week</MDBBtn>
                    <MDBBtn disabled={this.state.filterButton==="month"?true:false} onClick={()=>{this.chageTimeSpan("month")}}>Month</MDBBtn>
                </MDBBox>
                <MDBBox>
                    {this.state.isLoading === false &&
                    <MDBTable>
                        <MDBTableHead>
                            <tr>
                                <th>#</th>
                                <th>Order Code</th>
                                <th>Order Date</th>
                                <th>Order Type</th>
                                <th>Total Amount</th>
                                <th>Amount Paid</th>
                                <th>Payment Mode</th>
                                <th>Pending Amount</th>
                                <th>Order Status</th>
                                <th>Comment</th>
                                <th>Customer Details</th>
                                <th>Product Details</th>
                                <th></th>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            {this.state.data.length > 0 &&
                            <>
                                {this.state.data.map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.orderCode}</td>
                                        <td>{Moments(item.orderDate).format("DD-MM-YYYY")} at {Moments(item.orderDate).format("hh:mm A")}</td>
                                        <td>{item.orderMode}</td>
                                        <td>₹{item.totalAmount.toFixed(2)}</td>
                                        <td>₹{item.paidAmount.toFixed(2)}</td>
                                        <td>{item.paymentMode}</td>
                                        <td>₹{(parseFloat(item.totalAmount) - parseFloat(item.paidAmount)).toFixed(2)}</td>
                                        <td>{item.status}</td>
                                        <td>{item.comment}</td>
                                        <td>
                                            <MDBBox onClick={() => {
                                                this.showCustomerDetails(item)
                                            }}><span className={'cursor-pointer text-primary'}>View</span></MDBBox>
                                        </td>
                                        <td>
                                            <MDBBox onClick={()=>{this.showProducts(item)}}>
                                                <span className={'cursor-pointer text-primary'}>Products</span>
                                            </MDBBox>
                                        </td>
                                        <td>
                                            {item.status !== "CANCELLED" &&
                                            <MDBBox>
                                                <MDBBtn onClick={() => {
                                                    this.toggleCancel(item)
                                                }}>Cancel</MDBBtn>
                                            </MDBBox>
                                            }
                                            <MDBBox>
                                                <MDBBtn onClick={() => {
                                                    this.toggleEdit(item)
                                                }}>Edit</MDBBtn>
                                            </MDBBox>
                                            {item.status !== "CANCELLED" &&
                                            <MDBBox>
                                                <MDBBtn onClick={()=>{this.toggleReturn(item)}}>Return</MDBBtn>
                                            </MDBBox>
                                            }
                                        </td>
                                    </tr>
                                ))}
                            </>
                            }
                            {this.state.data.length === 0 &&
                            <tr>
                                <td colSpan={10}>
                                    <div className={'no-data-container'}>No Records Found</div>
                                </td>
                            </tr>
                            }
                        </MDBTableBody>
                    </MDBTable>
                    }
                </MDBBox>

                {/*Modals*/}
                {/*Cancel modal*/}
                <MDBModal isOpen={this.state.modalCancel} toggle={this.toggleCancel}>
                    <MDBModalHeader toggle={this.toggleCancel}>Cancel order</MDBModalHeader>
                    <MDBModalBody>
                        {this.state.itemToBeCancelled !== null &&
                        <>
                            <MDBBox className={"font-weight-bold"}>Are you sure you want to cancel this order?</MDBBox>
                            <br/>
                            <MDBBox>
                                <span>Order code: </span>
                                <span>{this.state.itemToBeCancelled.orderCode}</span>
                            </MDBBox>
                            <MDBBox>
                                <span>Total amount: </span>
                                <span>₹{(parseFloat(this.state.itemToBeCancelled.totalAmount)).toFixed(2)}</span>
                            </MDBBox>
                            <MDBBox>
                                <span>Order date: </span>
                                <span>{Moments(this.state.itemToBeCancelled.orderDate).format("DD-MM-YYYY")} at {Moments(this.state.itemToBeCancelled.orderDate).format("hh:mm A")}</span>
                            </MDBBox>
                            <MDBBox>
                                <span>Customer name: </span>
                                <span>{this.state.itemToBeCancelled.customerDetails.name}</span>
                            </MDBBox>
                            <MDBBox>
                                <span>Customer contact no: </span>
                                <span>{this.state.itemToBeCancelled.customerDetails.contactNo}</span>
                            </MDBBox>
                            <MDBBox>
                                <MDBInput label="Comment" value={this.state.cancelComment} getValue={(e)=>{this.setState({cancelComment:e})}}/>
                            </MDBBox>
                        </>
                        }
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn onClick={this.toggleCancel}>Close</MDBBtn>
                        <MDBBtn onClick={this.cancelOrder}>Cancel</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>

                {/* Edit modal */}
                <MDBModal size={'lg'} isOpen={this.state.modalEdit} toggle={this.toggleEdit}>
                    <MDBModalHeader toggle={this.toggleEdit}>Edit order</MDBModalHeader>
                    <MDBModalBody>
                        {this.state.itemToBeEdited !== null &&
                        <>
                            <MDBBox>
                                <span>Order code: </span><span>{this.state.itemToBeEdited.orderCode}</span>
                            </MDBBox>
                            <MDBBox>
                                <span>Order date: </span>
                                <span>{Moments(this.state.itemToBeEdited.orderDate).format("DD-MM-YYYY")} at {Moments(this.state.itemToBeEdited.orderDate).format("hh:mm A")}</span>
                            </MDBBox>
                            <MDBBox>
                                <span>Customer name: </span>
                                <span>{this.state.itemToBeEdited.customerDetails.name}</span>
                            </MDBBox>
                            <MDBBox>
                                <span>Customer contact no: </span>
                                <span>{this.state.itemToBeEdited.customerDetails.contactNo}</span>
                            </MDBBox>
                            <MDBBox>
                                <span>Order status: </span>
                                <span>{this.state.itemToBeEdited.status}</span>
                            </MDBBox>
                            <MDBBox>
                                <span>Order mode: </span>
                                <span>{this.state.itemToBeEdited.orderMode}</span>
                            </MDBBox>
                            <MDBBox>
                                <span>Total amount: </span>
                                <span>₹{parseFloat(this.state.itemToBeEdited.totalAmount).toFixed(2)}</span>
                            </MDBBox>
                            <MDBBox>
                                <MDBBox>
                                    <span>Products: </span>
                                </MDBBox>
                                <MDBRow>
                                    <MDBCol></MDBCol>
                                    <MDBCol>Product</MDBCol>
                                    <MDBCol>Quantity</MDBCol>
                                    <MDBCol>Discount</MDBCol>
                                    <MDBCol>Batch</MDBCol>
                                    <MDBCol>Bar code</MDBCol>
                                </MDBRow>
                                {this.state.itemToBeEdited.products.map((item, index) => (
                                    <MDBBox key={index}>
                                        <MDBRow>
                                            <MDBCol>{index + 1}</MDBCol>
                                            <MDBCol>{item.productName}</MDBCol>
                                            <MDBCol>{item.quantity}</MDBCol>
                                            <MDBCol>{item.discount}</MDBCol>
                                            <MDBCol>{item.inventory.batch}</MDBCol>
                                            <MDBCol>
                                                {item.inventory.barCode}
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBBox>
                                ))}
                            </MDBBox>
                            <MDBBox>
                                    <span>
                                        <MDBInput label="Amount collected (₹)" value={this.state.editAmountCollected} getValue={(e)=>{this.setState({editAmountCollected:e})}}/>
                                    </span>
                            </MDBBox>
                            <MDBBox>
                                <MDBInput label="Balance amount (₹)" value={this.state.editBalanceAmount} disabled={true}/>
                            </MDBBox>
                            <MDBBox>
                                <MDBSelect label="Payment mode" getValue={(e)=>{this.setState({editPaymentMode:e})}}>
                                    <MDBSelectInput selected="Choose payment mode"/>
                                    <MDBSelectOptions>
                                        <MDBSelectOption disabled>Choose payment mode</MDBSelectOption>
                                        <MDBSelectOption value="Cash" selected={this.state.itemToBeEdited.paymentMode.toLowerCase()==="cash"?true:false}>Cash</MDBSelectOption>
                                        <MDBSelectOption value="Credit" selected={this.state.itemToBeEdited.paymentMode.toLowerCase()==="credit"?true:false}>Credit</MDBSelectOption>
                                        <MDBSelectOption value="Card" selected={this.state.itemToBeEdited.paymentMode.toLowerCase()==="card"?true:false}>Card</MDBSelectOption>
                                        <MDBSelectOption value="Netbanking" selected={this.state.itemToBeEdited.paymentMode.toLowerCase()==="netbanking"?true:false}>Netbanking</MDBSelectOption>
                                        <MDBSelectOption value="Wallet" selected={this.state.itemToBeEdited.paymentMode.toLowerCase()==="wallet"?true:false}>Wallet</MDBSelectOption>
                                    </MDBSelectOptions>
                                </MDBSelect>
                            </MDBBox>
                        </>
                        }
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn onClick={this.toggleEdit}>Close</MDBBtn>
                        <MDBBtn onClick={this.editOrder}>Save</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>

                {/* rerun modal */}
                <MDBModal size={'lg'} isOpen={this.state.modalReturn} toggle={this.toggleReturn}>
                    <MDBModalHeader toggle={this.toggleReturn}>Return</MDBModalHeader>
                    <MDBModalBody>
                            {this.state.itemToBeReturned !== null &&
                            <>
                                <MDBBox>
                                    <span>Order code: </span><span>{this.state.itemToBeReturned.orderCode}</span>
                                </MDBBox>
                                <MDBBox>
                                    <span>Order date: </span>
                                    <span>{Moments(this.state.itemToBeReturned.orderDate).format("DD-MM-YYYY")} at {Moments(this.state.itemToBeReturned.orderDate).format("hh:mm A")}</span>
                                </MDBBox>
                                <MDBBox>
                                    <span>Customer name: </span>
                                    <span>{this.state.itemToBeReturned.customerDetails.name}</span>
                                </MDBBox>
                                <MDBBox>
                                    <span>Customer contact no: </span>
                                    <span>{this.state.itemToBeReturned.customerDetails.contactNo}</span>
                                </MDBBox>
                                <MDBBox>
                                    <span>Order status: </span>
                                    <span>{this.state.itemToBeReturned.status}</span>
                                </MDBBox>
                                <MDBBox>
                                    <span>Order mode: </span>
                                    <span>{this.state.itemToBeReturned.orderMode}</span>
                                </MDBBox>
                                <MDBBox>
                                    <span>Total amount: </span>
                                    <span>₹{parseFloat(this.state.itemToBeReturned.totalAmount).toFixed(2)}</span>
                                </MDBBox>
                                <MDBBox>
                                    <MDBTable>
                                        <MDBTableHead>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Quantity</th>
                                            <th>Amount</th>
                                            <th>Discount</th>
                                            <th>Content qnty</th>
                                            <th>Loose sale</th>
                                            <th>Return qnty</th>
                                            <th>Return amt</th>
                                        </MDBTableHead>
                                        <MDBTableBody>
                                        {this.state.itemToBeReturned.products.map((item, index) => (
                                            <tr key={index}>
                                                <td>1</td>
                                                <td>{item.productName}</td>
                                                <td>{item.quantity}</td>
                                                <td>Amount</td>
                                                <td>{item.discount}</td>
                                                <td>{item.contentQuantity}</td>
                                                <td>{item.looseSaleAllowed}</td>
                                                <td>
                                                    <MDBInput label={""} value={item.tobeReturned} getValue={(e)=>{this.handleQuantityChange(e,item,index)}}/>
                                                </td>
                                                <td>
                                                    <MDBInput label={""} value={item.returnAmount} getValue={(e)=>{this.handleReturnPrice(e,item,index)}}/>
                                                </td>
                                            </tr>
                                        ))}
                                        </MDBTableBody>
                                    </MDBTable>
                                </MDBBox>
                            </>
                            }
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn onClick={this.toggleReturn}>Close</MDBBtn>
                        <MDBBtn onClick={this.returnItems}>Retuen</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>

                {/*customer modal*/}
                <MDBModal isOpen={this.state.modalCustomerInfo} toggle={this.showCustomerDetails}>
                    <MDBModalHeader toggle={this.showCustomerDetails}>Customer Details</MDBModalHeader>
                    <MDBModalBody>
                        {this.state.customerInfo !== null &&
                            <>
                                <MDBBox>
                                    Name: {this.state.customerInfo.name}
                                </MDBBox>
                                <MDBBox>
                                    Phone: {this.state.customerInfo.contactNo}
                                </MDBBox>
                                <MDBBox>
                                    Email: {this.state.customerInfo.email}
                                </MDBBox>
                                <MDBBox>
                                    Gender: {this.state.customerInfo.gender}
                                </MDBBox>
                                <MDBBox>
                                    Date of Birth: {this.state.customerInfo.dob!==""?Moments(this.state.customerInfo.dob).format("DD-MM-YYYY"):""}
                                </MDBBox>
                                <MDBBox>
                                    Member Since: {this.state.customerInfo.memberSince!==""?Moments(this.state.customerInfo.memberSince).format("DD-MM-YYYY"):""}
                                </MDBBox>
                            </>
                        }
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn onClick={this.showCustomerDetails}>Close</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>

                {/*product modal*/}
                <MDBModal isOpen={this.state.modalProductInfo} toggle={this.showProducts} size={"lg"}>
                    <MDBModalHeader toggle={this.showProducts}>Product Details</MDBModalHeader>
                    <MDBModalBody>
                        {this.state.productInfo!==null &&
                            <>
                                <MDBTable>
                                    <MDBTableHead>
                                        <th>#</th>
                                        <th>Product Name</th>
                                        <th>Quantity</th>
                                        <th>Medicine Type</th>
                                        <th>Loose Sale Allowed</th>
                                        <th>Discount</th>
                                        <th>Inventory</th>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                        {this.state.productInfo.map((item, index) => (
                                            <tr key={index}>
                                                <td>{index+1}</td>
                                                <td>{item.productName}</td>
                                                <td>{item.quantity}</td>
                                                <td>{item.medicineType}</td>
                                                <td>{item.looseSaleAllowed}</td>
                                                <td>{item.discount}</td>
                                                <td>
                                                    <MDBBox>Added Qnty: {item.inventory.addedQnty}</MDBBox>
                                                    <MDBBox>Batch: {item.inventory.batch}</MDBBox>
                                                    <MDBBox>Bar Code: {item.inventory.barCode}</MDBBox>
                                                </td>
                                            </tr>
                                        ))}
                                    </MDBTableBody>
                                </MDBTable>
                            </>
                        }
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn onClick={this.showProducts}>Close</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
            </React.Fragment>
        )
    }
}

export default OrderListV2;
