import {CSVLink} from "react-csv";
import React from "react";

class DownloadReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props.data,
            fileName: props.fieldName,
            headers: props.headers,
        }
    }

    render() {
        return (
            <CSVLink data={this.props.data} headers={this.props.headers} filename={this.props.fileName}
                     className="btn btn-default" target="_blank"> Download Report
            </CSVLink>
        )
    }
}

export default DownloadReport