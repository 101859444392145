import Cookies from 'universal-cookie';

const UserStore= {
    fetchSession:function () {
        try {
            const cookies = new Cookies();
            let userData = cookies.get('userSession');
            if(userData){
                return true;
            }else{
                return false;
            }
        }catch (e) {
            return false;
        }
    },
    fetchSessionData:function () {
        try {
            const cookies = new Cookies();
            let userData = cookies.get('userSession');
            if(userData){
                return userData;
            }else{
                return false;
            }
        }catch (e) {
            return false;
        }
    }
}
export default UserStore;