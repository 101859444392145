import React from "react";
import {withRouter} from "react-router-dom";
import SimpleBackdrop from "../common/overlay";
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCol,
    MDBContainer,
    MDBDataTable,
    MDBInput, MDBModal, MDBModalBody, MDBModalHeader,
    MDBRow, MDBBadge, MDBBtnGroup
} from "mdbreact";
import UserStore from "../../stores/userStore";
import {toast} from "react-toastify";
import dataTableConfig from "./dataTableProducts";
import ReportService from "../../services/reports";
import utility from "../../utility/utility";
import UpdateProductConfig from "../../pages/product/cms";
import UpdateProduct from "../../pages/product/editPkgDetails";
import ProductView from "../product/productView";
import config from "../../config";
import VerifyProduct from "./verifyProduct";
import ListManuallyMarkedDuplicate from "./listManuallyMarkedDuplicate";

class DuplicateProducts extends React.Component {
    constructor() {
        super();
        this.state = {
            activeTab:"EXACT_DUPLICATE",
            progressStatus: false,
            type: "all",
            products: [],
            tableData: {columns: [], rows: []},


            modalStatus:false,
            activeProductId:0,
            modalEdit:false,
            modalView:false,
            activeProduct:{

            },
            modalAction:"",
            disableRetreatAfterSorting:false
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () =>{
        this.getProductsList().then(res => {
            if (res === false) {
                toast.error("We are facing some issue, please try again later.")
            } else {
                this.setState({products: res})
                let temp = this.state.products,
                    dataTablePayload = {
                        columns: dataTableConfig.duplicateProducts,
                        rows:this.state.products.map((item, index) => {

                            item["action"] = <div><span color="primary" className={"blue-text btn-link cursor-pointer"} size="sm"
                                                        onClick={() => {
                                                            this.onMark(item)
                                                        }}>View Duplicates</span></div>;

                            return item;

                        })

                        // rows: this.state.products
                    }
                this.setState({tableData: dataTablePayload})
            }
        })
    }

    getProductsList = async () => {
        let userData = await UserStore.fetchSessionData();
        this.setState({progressStatus: true});
        let data = await ReportService.fetchDuplicateProducts(userData, this.state);
        this.setState({progressStatus: false});
        return data
    }

    toggleView = () => {
        this.setState({
            modalView: !this.state.modalView
        });
    }

    toggleEdit = () => {
        this.setState({
            modalEdit: !this.state.modalEdit
        });
    }
    onEditComplete = (row) => {
        this.toggleEdit()
        this.fetchData();
    }
    // This triggers from MDBDataTable, aim is to redirect to edit page
    onEditDesc =  (row) => {
        this.setState({activeProduct: row,modalAction:"edit"})
        this.toggleEdit()
    }

    onEdit=(item)=>{
        // this.props.history.push('/product/cms/'+item._id);
        this.setState({activeProduct: item, modalAction:"updateConfig"})
        this.toggleEdit()
    }

    onView =  (row) => {
        this.setState({activeProduct: row})
        this.toggleView()
    }

    onMark=(item)=>{
        // this.props.history.push('/product/cms/'+item._id);
        this.setState({activeProduct: item, modalAction:"mark"})
        this.toggleEdit()
    }

    setTab = (mode) => {
        this.setState({"activeTab": mode})
    }

    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.progressStatus}/>
                <MDBContainer className={'w-100 mw-100'}>
                    <MDBRow>
                        <MDBCol>
                            <MDBBtnGroup size="sm">
                                <MDBBtn
                                    onClick={() => this.setTab('EXACT_DUPLICATE')}
                                    disabled={this.state.activeTab === "Day"}
                                    color={this.state.activeTab === "EXACT_DUPLICATE" ? "default" : ""}>Exact Duplicate</MDBBtn>
                                <MDBBtn
                                    onClick={() => this.setTab('MARKED')}
                                    disabled={this.state.activeTab === "Week"}
                                    color={this.state.activeTab === "MARKED" ? "default" : ""}>Marked Data</MDBBtn>

                            </MDBBtnGroup>

                        </MDBCol>
                    </MDBRow>
                    {this.state.activeTab === "EXACT_DUPLICATE" &&
                    <MDBCard className={"mt-2"}>
                        <MDBCardHeader>
                            <MDBRow>
                                <MDBCol className={'title'}>
                                    Duplicate Products
                                </MDBCol>
                            </MDBRow>
                        </MDBCardHeader>
                        <MDBCardBody>

                            {this.state.products.length > 0 &&
                            <MDBDataTable
                                striped

                                data={this.state.tableData}
                                materialSearch
                                noBottomColumns
                                hover
                                //disableRetreatAfterSorting={this.state.disableRetreatAfterSorting}
                            />
                            }
                        </MDBCardBody>
                    </MDBCard>
                    }


                    {this.state.activeTab === "MARKED" &&
                    <MDBCard className={"mt-2"}>
                        <MDBCardHeader>
                            <MDBRow>
                                <MDBCol>
                                    Marked Products
                                </MDBCol>
                            </MDBRow>
                        </MDBCardHeader>
                        <MDBCardBody>
                            <ListManuallyMarkedDuplicate></ListManuallyMarkedDuplicate>
                        </MDBCardBody>
                    </MDBCard>
                    }



                    <MDBModal isOpen={this.state.modalEdit} toggle={this.toggleEdit} size={"fluid"}>
                        <MDBModalHeader toggle={this.toggleEdit}></MDBModalHeader>
                        <MDBModalBody className={"float-center"}>
                            {this.state.modalAction === "updateConfig" &&
                            <UpdateProductConfig loadedOnPopup={true} onEditComplete={this.onEditComplete}
                                                 productId={this.state.activeProduct._id}/>
                            }
                            {this.state.modalAction === "edit" &&
                            <UpdateProduct loadedOnPopup={true} onEditComplete={this.onEditComplete}
                                           productId={this.state.activeProduct._id}/>
                            }

                            {this.state.modalAction === "mark" &&
                            <VerifyProduct loadedOnPopup={true} onEditComplete={this.onEditComplete}
                                           product={this.state.activeProduct}/>
                            }
                        </MDBModalBody>
                    </MDBModal>

                    <MDBModal isOpen={this.state.modalView} toggle={this.toggleView} size={"fluid"}>
                        <MDBModalHeader toggle={this.toggleView}></MDBModalHeader>
                        <MDBModalBody className={"float-center"}>
                            <ProductView loadedOnPopup={true}
                                         productId={this.state.activeProduct._id} product={this.state.activeProduct}/>
                        </MDBModalBody>
                    </MDBModal>
                    <MDBModal isOpen={this.state.modalLB} toggle={this.toggleLB} size={"lg"}>
                        <MDBModalHeader toggle={this.toggleLB}></MDBModalHeader>
                        <MDBModalBody className={"float-center"}>
                            <div style={{"textAlign":"center"}}>
                                {/*<img src={config.IMAGE_HOST + this.state.activeImageUrl.filePathOriginal} className={"float-center"}*/}
                                {/*     style={{maxWidth: "440px", maxHeight: "440px"}} alt={""}/>*/}
                            </div>
                        </MDBModalBody>
                    </MDBModal>
                </MDBContainer>
            </React.Fragment>
        )
    }
}

export default withRouter(DuplicateProducts)