import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { Button } from "@material-ui/core";
import TransferStockService from "../../../services/transferStockService";
import SearchStock from "../../../newComponents/searchDropdown/searchStock";
import NewTable from "../../../newComponents/NewTable/NewTable";
import NewPagination from "../../../newComponents/NewPagination/NewPagination";
import config from "../../../config";
import SimpleBackdrop from "../../common/overlay";
import UserStore from "../../../stores/userStore";
import Barcode from "react-barcode";
import Moments from "moment";

const columns = [
  { header: "Barcode", width: "10%", accessor: "barCode" },
  { header: "Date", width: "8%", accessor: "createdAt", sortable: true },
  { header: "Batch", width: "5%", accessor: "batch" },
  { header: "Invoice Number", width: "5%", accessor: "invoiceNumber" },
  { header: "Product", width: "10%", accessor: "productName" },
  { header: "Supplier Name", width: "10%", accessor: "supplierName" },
  { header: "Qty", width: "5%", accessor: "quantity" },
  { header: "MRP", width: "5%", accessor: "mrp" },
  { header: "Disc(%)", width: "5%", accessor: "discount" },
  { header: "Discounted Price", width: "8%", accessor: "discountedPrice" },
  { header: "Mfg", width: "5%", accessor: "mfgDate" },
  { header: "Exp", width: "5%", accessor: "expDate" },
  { header: "Status", width: "10%", accessor: "status" },
  { header: "Entered By", width: "8%", accessor: "createdBy" },
  { header: "Location", width: "8%", accessor: "locName" },
  { header: "Floor Name", width: "8%", accessor: "floorName" },
  { header: "Rack", width: "8%", accessor: "rackName" },
  { header: "Box", width: "8%", accessor: "boxName" }
];

function SearchStockComponent(props) {
  const [searchProductOpt, setSearchProductOpt] = useState(config.SEARCH_DROPDOWN);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagging, setPagging] = useState({
    currentPage: 1,
    totalRecords: 0,
    pageSize: 10,
  });
  const [userData, setUserData] = useState({});
  const [searchParams, setSearchParams] = useState({
    searchTerm: "",
    searchType: "",
  });
  const [searchInput, setSearchInput] = useState("");

  const handleSearchProd = async (searchTerm, searchType) => {
    try {
      setLoading(true);
      let res;
      if (searchTerm === "clear") {
        setSearchParams({ searchTerm: "", searchType: "" });
        setData([]);
      } else {
        res = await TransferStockService.searchStockListNew(
          searchTerm,
          userData.sessionToken,
          searchType,
          1
        );
        updateUrlWithPagination(1);
        setSearchParams({ searchTerm, searchType });
      }
      if (res && res.data) {
        setData(res.data);
        setPagging((prev) => ({
          ...prev,
          currentPage: res.currentPage || 1,
          totalRecords: res.totalRecords || res.data.length,
          pageSize: res.recordPerPage || 10,
        }));
        setSearchInput("");
      } else {
        setData([]);
        setPagging((prev) => ({
          ...prev,
          currentPage: 1,
          totalRecords: 0,
          pageSize: 10,
        }));
      }
    } catch (error) {
      console.error("Error in handleSearchProd:", error);
      toast.error("An error occurred while searching. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const buildQueryString = (params) => {
    const queryString = Object.keys(params)
      .map((key) => {
        if (Array.isArray(params[key]) && params[key].length > 0) {
          return params[key]
            .map((item) => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`)
            .join('&');
        } else if (params[key]) {
          return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
        }
        return null;
      })
      .filter(Boolean)
      .join('&');

    return queryString ? `?${queryString}` : '';
  };

  const updateUrlWithPagination = (page) => {
    const queryParams = {
      page,
    };

    const queryStringified = buildQueryString(queryParams);
    props.history.push(`/search-stock-new${queryStringified}`);
  };


  const handlePageChange = async (newPage) => {
    const pageNumber = newPage + 1;
    setPagging((prev) => ({ ...prev, currentPage: pageNumber }));
    updateUrlWithPagination(pageNumber);

    try {
      setLoading(true);
      const res = await TransferStockService.searchStockListNew(
        searchParams.searchTerm,
        userData.sessionToken,
        searchParams.searchType,
        pageNumber
      );
      if (res && res.data) {
        setData(res.data);
        setPagging((prev) => ({
          ...prev,
          totalRecords: res.totalRecords || res.data.length,
          pageSize: res.recordPerPage || 10,
        }));
      } else {
        setData([]);
        setPagging((prev) => ({
          ...prev,
          currentPage: 1,
          totalRecords: 0,
          pageSize: 10,
        }));
      }
    } catch (error) {
      console.error("Error in handlePageChange:", error);
      toast.error("An error occurred while fetching data. Please try again.");
    } finally {
      setLoading(false);
    }
  };


  const discountedPriceCalculate = (price, dicountPercent) => {
    let newPrice = price * (1 - dicountPercent / 100)
    return newPrice?.toFixed(2) ?? "NA"
  }

  const formatTableData = (data) => {
    return data.map((item) => ({
      barCode: (
        <div
          className="d-flex align-items-center"
          style={{ width: "12em", height: "8em" }}
        >
          <Barcode value={item?.barCode} />
        </div>
      ),
      createdAt: Moments(item.createdAt).format("DD-MMM-YYYY"),
      batch: item.batch,
      invoiceNumber: item.invoiceNumber,
      productName: item.productName,
      supplierName: item.supplierName,
      quantity: item.quantity.toFixed(0),
      mrp: item.mrp,
      discount: item.discount,
      discountedPrice: discountedPriceCalculate(item.mrp, item.discount),
      mfgDate: Moments(item.mfgDate).format("MMM YYYY"),
      expDate: Moments(item.expDate).format("MMM YYYY"),
      status: item.isReviewed ? "Reviewed" : "Not Reviewed",
      createdBy: item.createdBy,
      locName: item.locName,
      floorName: item.floorName,
      rackName: item.rackName,
      boxName: item.boxName,
    }));
  };
  useEffect(() => {
    const fetchUserData = async () => {

      let user = await UserStore.fetchSessionData();
      setUserData(user);

    };

    fetchUserData();
  }, []);

  return (
    <>
      <SimpleBackdrop status={loading} />
      <SearchStock
        OPTIONS={searchProductOpt}
        CONFIG={config}
        handleSearchProd={handleSearchProd}
        isProduct={true}
        inputValue={searchInput}
        onInputChange={(e) => setSearchInput(e.target.value)}
      />
      <div className="col-12 row mx-0 order-box mt-4">
        <NewTable
          columns={columns}
          data={formatTableData(data)}
          pagination={false}
        />
        {data && data.length > 0 && (
          <div className="my-3 d-flex flex-row justify-content-end w-100">
            <NewPagination
              page={pagging.currentPage - 1}
              pageLimit={pagging.pageSize}
              totalRecords={pagging.totalRecords}
              handleChange={handlePageChange}
              type="primary"
            />
          </div>
        )}
      </div>
    </>
  );
}

export default SearchStockComponent;
