import React from "react";
import {Link,withRouter} from "react-router-dom";
import {
    MDBContainer, MDBRow, MDBBreadcrumb, MDBBreadcrumbItem, MDBInput, MDBBtn, MDBCardHeader, MDBFormInline, MDBCardBody,
    MDBCard, MDBCol, MDBSelect, MDBSelectInput, MDBSelectOptions, MDBSelectOption, MDBChipsInput, MDBIcon,
    MDBModalHeader, MDBModalBody, MDBModalFooter, MDBModal, MDBChip
} from 'mdbreact';
import "./product.css";
import InventoryService from "../../services/inventoryService";
import { toast } from 'react-toastify';
import UserStore from "../../stores/userStore";
import SimpleBackdrop from "../../components/common/overlay";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import config from "../../config";
import AdvancedFileUpload from "../../components/common/fileUpload/advanceFileUpload";
import AddRetailPackage from "../../components/retail-package/addRetailPackage";
import AddQuantityUnit from "../../components/quantity-unit/addQuantityUnit";
import AddManufacturer from "../../components/manufacturer/addManufacturer";
import ProductSpecifications from "../../components/product/ProductSpecifications";
import Utility from "../../utility/utility";
import AddProductImage from "../../components/common/fileUpload/addProductImage";

class EditProduct extends React.Component {
    async componentDidMount() {
        let userData=await UserStore.fetchSessionData();
        this.setState({userData: userData})

        window.scrollTo(0, 0);
        if(this.props.loadedOnPopup === true){
            let productId = this.props.productId;
            await this.setState({_id:productId, loadedOnPopup:true})
            await this.fetchData();
        } else {
            const { id } = this.props.match.params;
            await this.setState({_id:id});
            await this.fetchData();
        }
        this.fetchData()


    }
    fetchData = async() => {
        this.setState({progressStatus:true});
        // const { id } = this.props.match.params;
        let userData=await UserStore.fetchSessionData();
        let editData=await InventoryService.fetchEditData(userData,this.state._id);
        this.setState({retailPkgs: editData.retailPkg, qntyUnits: editData.quantyUnit, medType: editData.medicineType});
        this.setState({
            // _id:id,
            productName:editData.productData.productName,
            pakgValSel:editData.productData.retailPackage,
            qntyUnitSel:editData.productData.quantityUnit,
            pkgQnty:editData.productData.contentQuantity,
            selectedMfgTemp:editData.productData.manufacturer[0]?editData.productData.manufacturer[0]:'',
            isDrug:editData.productData.isMedicine,
            productUses:editData.productData.productUses.length>0?editData.productData.productUses:[],
            productSideEffects:editData.productData.productSideEffects.length>0?editData.productData.productSideEffects:[],
            tags:editData.productData.tags,
            selectedMedType:editData.productData.medicineType,
            description:editData.productData.description,
            highlight:editData.productData.highlight.length>0?editData.productData.highlight:[],
            howTouse: editData.productData.howTouse.length>0?editData.productData.howTouse:[],
            productIngredients:editData.productData.productIngredients.length>0?editData.productData.productIngredients:[],
            minimumLooseSaleQuantity:editData.productData.minimumLooseSaleQuantity ? editData.productData.minimumLooseSaleQuantity :0,
            looseSaleAllowed:editData.productData.looseSaleAllowed
        });
        try {
            if(editData.hasOwnProperty("productData") && editData.productData.hasOwnProperty("imageUrl")  && editData.productData.imageUrl.length > 0) {
                this.setState({existingImageUrl: editData.productData.imageUrl});
                let lb = []
                for(let i = 0; i < editData.productData.imageUrl.length; i++){
                    let item = {
                        src: config.IMAGE_HOST+editData.productData.imageUrl[i].filePathOriginal
                    }
                    lb.push(item);
                }
                this.setState({"lightBoxImages":lb, "imageActionNeeded":"Update"})
            } else {
                this.setState({existingImageUrl:[]});
                this.setState({"imageActionNeeded":"Add"})
            }
        } catch (e) {
            this.setState({existingImageUrl:[]});
            this.setState({"imageActionNeeded":"Add"})
        }
        this.setState({ progressStatus: false});
    }
    constructor(props) {
        super(props);
        this.state = {
            _id:'',
            userData: {},
            highlight:[],
            description:"",
            howTouse:"",
            productIngredients:[],
            productSideEffects:[],
            productUses:[],
            productName:"",
            progressStatus:false,
            retailPkgs:[],
            qntyUnits:[],
            pkgQnty:'',
            pakgValSel:'',
            qntyUnitSel:'',
            imageUrl:[],
            suggestedManufacturer:[],
            mfgError:false,
            selectedMfg:'',
            suggestedMedicineType:[],
            selectedMedType:'',
            isDrug:false,
            medType:[],
            tags:[],
            selectedMfgTemp:'',
            existingImageUrl:[],
            modalDelImage:false,
            activeImage:{
                index:0,
                thumb:""
            },
            resetForm:false,
            modalRetailPackaging:false,
            modalPackagingUnit:false,
            modalManufacturer:false,
            minimumLooseSaleQuantity:0,
            looseSaleAllowed:'N',
            keyRP:"",
            keyQU:"",

            modalLB:false,
            activePreviewImage:"",
            loadedOnPopup:false,
            tagList:[], tagEdited:false,
            highlightList:[], highlightEdited:false,
            sideEffectList:[], sideEffectEdited:false,
            productUseList:[], productUseEdited:false,
            ingredientList:[], ingredientEdited:false
        }
    }
    addMed =async () => {
        window.scrollTo(0, 0);
        let userData=await UserStore.fetchSessionData();
        let productIngredients = (this.state.ingredientEdited === true) ? Utility.prepareArray(this.state.ingredientList) : this.state.productIngredients,
            tags = (this.state.tagEdited === true) ? Utility.prepareArray(this.state.tagList) : this.state.tags,
            highlight = (this.state.highlightEdited === true) ? Utility.prepareArray(this.state.highlightList) : this.state.highlight,
            productSideEffects = (this.state.sideEffectEdited === true) ? Utility.prepareArray(this.state.sideEffectList) : this.state.productSideEffects,
            productUses = (this.state.productUseEdited === true) ? Utility.prepareArray(this.state.productUseList) : this.state.productUses;
        this.setState({productIngredients:productIngredients, tags:tags, highlight:highlight,
            productSideEffects:productSideEffects, productUses:productUses})

        //validations
        if(this.state.pakgValSel==='' || this.state.pakgValSel===null){
            toast.error("Please select retail packaging.");
            return true;
        }
        if(this.state.qntyUnitSel==='' || this.state.qntyUnitSel===null){
            toast.error("Please select packaging unit.");
            return true;
        }
        if(this.state.productName==='' || this.state.productName===null){
            toast.error("Please enter valid product name.");
            return true;
        }
        if(this.state.highlight==='' || this.state.highlight===null || this.state.highlight==='Highlight'){
            toast.error("Please enter valid highlight.");
            return true;
        }
        if(this.state.description==='' || this.state.description===null || this.state.description==='Description'){
            toast.error("Please enter valid description.");
            return true;
        }
        if(this.state.howTouse==='' || this.state.howTouse===null || this.state.howTouse==='How to Use'){
            toast.error("Please enter valid how to use.");
            return true;
        }
        if(this.state.productIngredients==='' || this.state.productIngredients===null || this.state.productIngredients==='Ingredients'){
            toast.error("Please enter valid ingredients.");
            return true;
        }
        if(this.state.productSideEffects==='' || this.state.productSideEffects===null || this.state.productSideEffects==='Side Effects'){
            toast.error("Please enter valid side effects.");
            return true;
        }
        if(this.state.productUses==='' || this.state.productUses===null || this.state.productUses==='Product Uses'){
            toast.error("Please enter valid product uses.");
            return true;
        }
        if(isNaN(parseFloat(this.state.pkgQnty))) {
            toast.error("Please enter valid packaging quantity.");
            return true;
        }
        if(this.state.isDrug) {
            if (this.state.selectedMedType === '' || this.state.selectedMedType === null) {
                toast.error("Please enter valid medicine type.");
                return true;
            }
        }else{
            this.setState({selectedMedType:''});
        }
        let mfgId='';
        if(this.state.selectedMfg!==''){
            mfgId=this.state.selectedMfg.value._id
        }else{
            mfgId=this.state.selectedMfgTemp._id;
        }

        if(this.state.looseSaleAllowed === "Y" && (isNaN(parseFloat(this.state.minimumLooseSaleQuantity)) || this.state.minimumLooseSaleQuantity < 1)) {
            toast.error("Please enter minimum loose sale quantity.");
            return true;
        }
        this.setState({progressStatus:true});
        let status=await InventoryService.updateProduct(this.state.looseSaleAllowed,this.state.minimumLooseSaleQuantity,this.state._id,this.state.isDrug,this.state.tags,this.state.selectedMedType,mfgId,this.state,this.state.pkgQnty,this.state.pakgValSel[0],this.state.qntyUnitSel[0],this.state.productName,userData,this.state.highlight,this.state.description,this.state.howTouse,this.state.productIngredients,this.state.productSideEffects,this.state.productUses);
        this.setState({progressStatus:false});
        if(status===false){
            toast.error("We are facing some issue. Please try again later.");
            return true;
        }else{
            this.setState({resetForm:true})
            toast.success("Product edited successfully.");
            window.productList=null;
            if(this.state.loadedOnPopup === true){
                this.props.onEditComplete()
            }
        }
    }
    medName=(event)=>{
        this.setState({
            productName: event.target.value
        })
    }
    pkgQnty=(val)=>{
        this.setState({pkgQnty: val});
    }
    pakgValChange=(val)=>{
        this.setState({pakgValSel:val});
    }
    qntyUnitsChange=(val)=>{
        this.setState({qntyUnitSel:val});
    }

    fileUploadCallback = (files) => {
        this.setState({ "imageUrl": files, "progressStatusFileUpload":false})
        this.updateProductImage(files)
    }
    initUploadCallback = () => {
        this.setState({"progressStatusFileUpload":true})
    }
    onChangeManufacturer= (event, values)=>{
        this.setState({selectedMfg:values,selectedMfgTemp:''});
    }
    fetchManufacturer = async (e) => {
        if(e.target.value!=='') {
            let manufacturer = await InventoryService.lookupManufacturer(e.target.value);
            if(manufacturer===false){
                toast.error("We are facing some issue. Please try again later.");
            }else{
                let temp = [];
                for (let item of manufacturer) {
                    let str = {title: item.manufacturer, value: item};
                    temp.push(str);
                }
                this.setState({suggestedManufacturer:temp});
            }
        }
    }
    isDrug=()=>{
        this.setState({
            isDrug: !this.state.isDrug
        });
    }
    qntyTypeChange=(val)=>{
        this.setState({selectedMedType:val[0]});
    }
    getChips=(val)=>{
        this.setState({tags:val});
    }
    toggleDelImage = () => {
        this.setState({
            modalDelImage: !this.state.modalDelImage
        });
    }
    updateProductImage = async (files)=>{
        let userData=await UserStore.fetchSessionData();
        this.setState({updateProgress: true});
        let status=await InventoryService.updateProductImage(this.state,userData,files);
        this.setState({updateProgress: false});
        if(status===false){
            toast.error("We are facing some issue. Please try again later.");
        } else {
            toast.success("Image updated.")
            await this.setState({finishedSavingFile:"done"})
            //this.fetchData()
        }

        try {
            let temp = this.state.existingImageUrl;
            temp.push(files[0]);
            this.setState({existingImageUrl: temp});
        }catch (e){

        }
    }
    initUploadCallback = () => {
        this.setState({"progressStatusFileUpload":true})
    }
    initDeleteDoc = (index) => {
        let activeImage = {
            index: index,
            thumb: this.state.existingImageUrl[index].filePathThumb
        }
        this.setState({activeImage:activeImage})
        this.toggleDelImage();
    }
    confirmDeleteImage = async() => {
        let existingList = this.state.existingImageUrl;
        existingList.splice(this.state.activeImage.index, 1);
        this.setState({existingImageUrl: []})
        this.toggleDelImage();

        let userData=await UserStore.fetchSessionData();
        this.setState({updateProgress: true});
        let status=await InventoryService.updateProductImage(this.state,userData,existingList);
        this.setState({existingImageUrl: existingList})
        this.setState({updateProgress: false});
        if(status===false){
            toast.error("We are facing some issue. Please try again later.");
        } else {
            toast.success("Image updated.")
            await this.setState({finishedSavingFile:"done"})
            //this.fetchData()
        }
    }
    toggleAddRetailPackaging = () => {
        this.setState({"modalRetailPackaging": !this.state.modalRetailPackaging})
    }
    toggleAddPackagingUnit = () => {
        this.setState({"modalPackagingUnit": !this.state.modalPackagingUnit})
    }
    toggleAddManufacturer= () => {
        this.setState({"modalManufacturer": !this.state.modalManufacturer})
    }
    retailPackageAdded = (res) => {
        this.toggleAddRetailPackaging();
        this.state.retailPkgs.push(res)
        this.resetKey()
    }
    packagingUnitAdded = (res) => {
        this.toggleAddPackagingUnit()
        this.state.qntyUnits.push(res)
        this.resetKey()
    }
    manufacturerAdded = (res) => {
        this.toggleAddManufacturer();
    }
    handleSellableUnit=(val)=>{
        this.setState({minimumLooseSaleQuantity:val});
    }
    looseSaleAllowedSell=(val)=>{
        if(val===true){
            this.setState({"looseSaleAllowed":"Y"});
        }else{
            this.setState({"looseSaleAllowed":"N"});
        }
    }
    calIsLoose=()=>{
        if(this.state.looseSaleAllowed==='Y'){
            return true;
        }else{
            return false;
        }
    }
    resetKey() {
        let randomString = Math.random().toString(36);
        this.setState({
            keyRP: randomString, keyQU: randomString,
        });
    }

    onDownloadImage = (row) => {
        console.log(row);
        this.setState({activePreviewImage: row})
        this.toggleLB()
    }

    // This toggle opens or closes the custom lightbox
    toggleLB = () => {
        this.setState({
            modalLB: !this.state.modalLB
        });
    }
    getIngredientListCallback = (list) => {
        this.setState({ingredientList:list, ingredientEdited:true})
    }
    getTagListCallback = (list) => {
        this.setState({tagList:list, tagEdited:true})
    }
    getHighlightListCallback = (list) => {
        this.setState({highlightList:list, highlightEdited:true})
    }
    getSideEffectListCallback = (list) => {
        this.setState({sideEffectList:list, sideEffectEdited:true})
    }
    getProductUseListCallback = (list) => {
        this.setState({productUseList:list, productUseEdited:true})
    }
    render() {
        return (
            <React.Fragment>
                {this.state.loadedOnPopup === false &&
                <>
                    <MDBBreadcrumb>
                        <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                        <MDBBreadcrumbItem><Link to={'/view-products'}>Products</Link></MDBBreadcrumbItem>
                        <MDBBreadcrumbItem active>Edit Product</MDBBreadcrumbItem>
                    </MDBBreadcrumb>
                </>
                }
                <SimpleBackdrop status={this.state.progressStatus}/>
                {this.state.progressStatus === false &&
                <div className={'col-md-10 m-auto border rounded-sm p-0 white'}>
                    <div className={'col-12 p-2 grey lighten-4 border-bottom d-flex justify-content-between align-items-center'}>
                        <b className={"font-weight-bold"}>Edit Product</b>
                        <div className={'row mx-0'}>
                            {this.state.loadedOnPopup === false &&
                            <MDBBtn outline onClick={() => this.props.history.goBack()} className={'my-0'}
                                    disabled={this.state.progressStatus}>Back
                            </MDBBtn>
                            }
                            <MDBBtn onClick={this.addMed} className={'my-0'}
                                    disabled={this.state.progressStatus}>Update</MDBBtn>
                        </div>
                    </div>
                    <table className={'table table-bordered p-3'}>
                        <tr>
                            <td className={'font-weight-bold'}>Product Name
                                <MDBInput id={"add_product_name_se"}
                                          background size={'sm'}
                                          value={this.state.productName} onChange={this.medName}/></td>
                            <td colSpan={'2'}>
                                Manufacturer
                                <div>{this.state.selectedMfgTemp.manufacturer}</div>
                                <div className="input-group mb-3">
                                    <Autocomplete className={'col p-0'}
                                                  freeSolo
                                                  onChange={this.onChangeManufacturer}
                                                  id="combo-box-demo"
                                                  options={this.state.suggestedManufacturer}
                                                  getOptionLabel={option => option.title}
                                                  onInputChange={(e) => {
                                                      this.fetchManufacturer(e)
                                                  }}
                                                  selectOnFocus={true}
                                                  renderInput={params => (
                                                      <TextField
                                                          style={this.state.mfgError ? {"borderBottom": "1px solid red"} : {}} {...params}
                                                          placeholder="Search Manufacturer"/>
                                                  )}
                                    />
                                    {(this.state.userData.type === "platformAdmin") &&
                                    <div className="input-group-append">
                                        <MDBBtn className={'btn-sm m-0'} onClick={this.toggleAddManufacturer} alt="Add Manufacturer">
                                            <MDBIcon icon="plus" />
                                        </MDBBtn>
                                    </div>
                                    }
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className={'font-weight-bold'}>
                                <b className={'font-weight-bold'}>Retail Packaging</b><br/>
                                <div className="input-group mb-3">
                                    <MDBSelect key={this.state.keyRP} getValue={this.pakgValChange}
                                               className={'form-control col grey-text p-0 font-weight-bold'}>
                                        <MDBSelectInput selected={this.state.pakgValSel ? this.state.pakgValSel : "Select Retail Packaging"}/>
                                        <MDBSelectOptions>
                                            {this.state.retailPkgs.map((item, idx) => (
                                                <MDBSelectOption value={item.retailPackage} key={idx}
                                                     selected={this.state.pakgValSel === item.retailPackage ? true : false}>
                                                    {item.retailPackage}
                                                </MDBSelectOption>
                                            ))}
                                        </MDBSelectOptions>
                                    </MDBSelect>
                                    {(this.state.userData.type === "platformAdmin") &&
                                    <div className="input-group-append">
                                        <MDBBtn className={'btn-sm m-0'} onClick={this.toggleAddRetailPackaging} alt="Add Retail Packaging">
                                            <MDBIcon icon="plus" />
                                        </MDBBtn>
                                    </div>
                                    }
                                </div>
                            </td>
                            <td>
                                <b className={'font-weight-bold'}>Packaging Quantity</b><br/>
                                <MDBInput background size="sm" value={this.state.pkgQnty} getValue={this.pkgQnty}/>
                            </td>
                            <td>
                                <b className={'font-weight-bold'}>Packaging Unit</b><br/>
                                <div className="input-group mb-3">
                                    <MDBSelect key={this.state.keyQU} getValue={this.qntyUnitsChange}
                                               className={'form-control col grey-text p-0 font-weight-bold'}>
                                        <MDBSelectInput selected={this.state.qntyUnitSel ? this.state.qntyUnitSel : "Select Packaging Unit"}/>
                                        <MDBSelectOptions>
                                            {this.state.qntyUnits.map((item, idx) => (
                                                <MDBSelectOption value={item.quantityUnit} key={idx}
                                                                 selected={this.state.qntyUnitSel === item.quantityUnit ? true : false}>{item.quantityUnit}</MDBSelectOption>
                                            ))}
                                        </MDBSelectOptions>
                                    </MDBSelect>
                                    {(this.state.userData.type === "platformAdmin") &&
                                    <div className="input-group-append">
                                        <MDBBtn className={'btn-sm m-0'} onClick={this.toggleAddPackagingUnit} alt="Add Packaging Unit">
                                            <MDBIcon icon="plus" />
                                        </MDBBtn>
                                    </div>
                                    }
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={'3'}>
                                <div className={'col-12 row mx-0 p-0'}>
                                    <div className={'col-md-3 pl-0'}>
                                        <b className={'font-weight-bold'}>Is Medicine</b>
                                        <div className={'row ml-n1 mr-0 p-0'}>
                                            <MDBInput onClick={this.isDrug} checked={!this.state.isDrug}
                                                      label="General" type="radio" id="radio3" />
                                            <MDBInput onClick={this.isDrug} checked={this.state.isDrug}
                                                      label="Medicine" type="radio" id="radio2" />
                                        </div>
                                    </div>
                                    {this.state.isDrug === true &&
                                    <div className={'col-md-3 pl-0'}>
                                        <b className={'font-weight-bold'}>Medicine Type</b>
                                        <div className="input-group mb-3">
                                            <MDBSelect getValue={this.qntyTypeChange} className={'form-control'}>
                                                <MDBSelectInput selected="Select Medicine Type"/>
                                                <MDBSelectOptions>
                                                    <MDBSelectOption value="" disabled selected>Select Medicine
                                                        Type</MDBSelectOption>
                                                    {this.state.medType.map((item, idx) => (
                                                        <MDBSelectOption value={item.typeName}
                                                                         key={idx}
                                                                         selected={this.state.selectedMedType === item.typeName ? true : false}>{item.typeName + " (" + item.typeDescription + ")"}</MDBSelectOption>
                                                    ))}
                                                </MDBSelectOptions>
                                            </MDBSelect>
                                        </div>
                                    </div>
                                    }
                                    <div className={'col-md-3 pl-0'}>
                                        <b className={'font-weight-bold'}>Loose Sale Allowed</b>
                                        <MDBInput label=" " type="checkbox" id="checkbox_loose"
                                                  checked={this.calIsLoose()} getValue={this.looseSaleAllowedSell}/>
                                    </div>
                                    {this.state.looseSaleAllowed === 'Y' &&
                                    <div className={'col-md-3 font-weight-bold pl-0'}>
                                        <b className={'font-weight-bold'}>Minimum Loose Sale Quantity</b>
                                        <MDBInput id={"sellable_units_se"} background size="sm" type={"number"}
                                                  value={this.state.minimumLooseSaleQuantity}
                                                  getValue={this.handleSellableUnit}/>
                                    </div>
                                    }
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className={'font-weight-bold'}>Tags</td>
                            <td colSpan={'2'}>
                                <ProductSpecifications section={'tag'}
                                    getListCallback={this.getTagListCallback}
                                    sendListCallback={this.state.tags}/>
                            </td>
                        </tr>
                        <tr>
                            <td className={'font-weight-bold'}>Description</td>
                            <td colSpan={'2'}>
                                <MDBInput type="textarea" background size="sm"
                                          rows="5" value={this.state.description}
                                          getValue={(val)=>{this.setState({description:val});}}/>
                            </td>
                        </tr>
                        <tr>
                            <td className={'font-weight-bold'}>Highlights</td>
                            <td colSpan={'2'}>
                                <ProductSpecifications section={'highlight'} getListCallback={this.getHighlightListCallback} sendListCallback={this.state.highlight}/></td>
                        </tr>
                        <tr>
                            <td className={'font-weight-bold'}>How to Use</td>
                            <td colSpan={'2'}>
                                <MDBInput type="textarea" background size="sm"
                                          rows="5" value={this.state.howTouse}
                                          getValue={(val)=>{this.setState({howTouse:val});}}/>
                            </td>
                        </tr>
                        <tr>
                            <td className={'font-weight-bold'}>Ingredients</td>
                            <td colSpan={'2'}>
                                <ProductSpecifications section={'ingredient'} getListCallback={this.getIngredientListCallback} sendListCallback={this.state.productIngredients}/>
                            </td>
                        </tr>
                        <tr>
                            <td className={'font-weight-bold'}>Side Effects</td>
                            <td colSpan={'2'}>
                                <ProductSpecifications section={'side effect'} getListCallback={this.getSideEffectListCallback} sendListCallback={this.state.productSideEffects}/>
                            </td>
                        </tr>
                        <tr>
                            <td className={'font-weight-bold'}>Product Uses</td>
                            <td colSpan={'2'}>
                                <ProductSpecifications section={'product use'} getListCallback={this.getProductUseListCallback} sendListCallback={this.state.productUses}/>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={'3'}>
                                <div className={'col-12 add-img-min-height row mx-0 border amber lighten-5 rounded-sm mt-2'}>
                                    {this.state.existingImageUrl.map((row, index) => (
                                        <div className={"m-3"} key={index}>
                                            {((row.filePathThumb.indexOf(".png") > 0) || (row.filePathThumb.indexOf(".jpg") > 0) || (row.filePathThumb.indexOf(".jpeg") > 0)) &&
                                            <img src={config.IMAGE_HOST + row.filePathThumb} style={{cursor:"pointer"}}  onClick={()=>this.onDownloadImage(row.filePathOriginal)} className={"float-left"} style={{maxWidth: "100px", maxHeight: "100px"}} alt={""}/>
                                            }
                                            <span style={{"cursor":"pointer"}} className={"p-1"} onClick={()=>this.initDeleteDoc(index)}><MDBIcon icon="trash" /></span>
                                        </div>
                                    ))}

                                    <AdvancedFileUpload updateProductImage={this.updateProductImage}
                                                        buttonLabel={"Add Product Image"} initUploadCallback={this.initUploadCallback}
                                                        fileUploadCallback={this.fileUploadCallback}
                                                        finishedSavingFile={this.state.finishedSaving}></AdvancedFileUpload>
                                </div>
                            </td>
                        </tr>

                    </table>

                    <div className={'col-12 p-2 grey lighten-4 border-top d-flex justify-content-between align-items-center'}>
                        <b className={"font-weight-bold"}>Edit Product</b>
                        <div className={'row mx-0'}>
                            {this.state.loadedOnPopup === false &&
                            <MDBBtn outline onClick={() => this.props.history.goBack()} className={'my-0'}
                                    disabled={this.state.progressStatus}>Back
                            </MDBBtn>
                            }
                            <MDBBtn onClick={this.addMed} className={'my-0'}
                                    disabled={this.state.progressStatus}>Update</MDBBtn>
                        </div>
                    </div>
                </div>
                }
                <MDBModal isOpen={this.state.modalDelImage} toggle={this.toggleDelImage}>
                    <MDBModalHeader toggle={this.toggleDelImage}>Delete Image</MDBModalHeader>
                    <MDBModalBody>
                        Are you sure you want to delete this image?
                        {this.state.existingImageUrl.length > 0 &&
                        <p>
                            <img
                                src={config.IMAGE_HOST + this.state.activeImage.thumb}
                                className={"float-left"} style={{maxWidth: "100px", maxHeight: "100px"}} alt={""}/>
                        </p>
                        }
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn disabled={this.state.progressStatus} color="primary" onClick={this.confirmDeleteImage}>Delete</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
                <MDBModal isOpen={this.state.modalRetailPackaging} toggle={this.toggleAddRetailPackaging} size={"lg"}>
                    <MDBModalHeader toggle={this.toggleAddRetailPackaging}>Add Retail Packaging</MDBModalHeader>
                    <MDBModalBody>
                        <AddRetailPackage subComponentActionPerformed={this.retailPackageAdded} isSubComponent={true}/>
                    </MDBModalBody>
                </MDBModal>
                <MDBModal isOpen={this.state.modalPackagingUnit} toggle={this.toggleAddPackagingUnit} size={"lg"}>
                    <MDBModalHeader toggle={this.toggleAddPackagingUnit}>Add Packaging Unit</MDBModalHeader>
                    <MDBModalBody>
                        <AddQuantityUnit subComponentActionPerformed={this.packagingUnitAdded}  isSubComponent={true}/>
                    </MDBModalBody>
                </MDBModal>
                <MDBModal isOpen={this.state.modalManufacturer} toggle={this.toggleAddManufacturer} size={"lg"}>
                    <MDBModalHeader toggle={this.toggleAddManufacturer}>Add Manufacturer</MDBModalHeader>
                    <MDBModalBody>
                        <AddManufacturer subComponentActionPerformed={this.manufacturerAdded} isSubComponent={true}/>
                    </MDBModalBody>
                </MDBModal>
                <MDBModal isOpen={this.state.modalLB} toggle={this.toggleLB} size={"lg"}>
                    <MDBModalHeader toggle={this.toggleLB}></MDBModalHeader>
                    <MDBModalBody className={"float-center"}>
                        <div style={{"textAlign":"center"}}>
                            <img src={config.IMAGE_HOST + this.state.activePreviewImage} className={"float-center"}
                                 style={{maxWidth: "440px", maxHeight: "440px"}} alt={""}/>
                        </div>
                    </MDBModalBody>
                </MDBModal>
                <br/>
            </React.Fragment>
        );
    }
}
export default withRouter(EditProduct);


