import axios from "axios";
import config from "../config";
import Moments from "moment";

const PharmacyService = {
  fetchSupplier: async function (userData, page, pageSize) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST +
          config.FETCH_SUPPLIERS +
          "?page=" +
          page +
          "&pageSize=" +
          pageSize +
          "&type=all",
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  fetchSupplierById: async function (id, userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST + config.FETCH_SUPPLIER_BY_ID + "?_id=" + id,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  fetchManufacturer: async function (userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST + config.FETCH_MANUFACTURERS,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  fetchManufacturerById: async function (id, userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST + config.FETCH_MANUFACTURER_BY_ID + "?_id=" + id,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  addManufacturer: async function (data, userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      let payload = {
        manufacturer: data.manufacturer,
        description: data.description,
        imageUrl: data.imageUrl,
        addressLine1: data.address,
        addressLine2: data.street,
        area: data.area,
        pinCode: data.pincode,
        city: data.city,
        state: data.state,
        country: data.country,
        landmark: data.landmark,
      };
      const response = await axios.post(
        config.APIHOST + config.ADD_MANUFACTURERS,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  updateManufacturer: async function (data, userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      let payload = {
        _id: data.manufacturerId,
        manufacturer: data.manufacturer,
        description: data.description,
        imageUrl: data.imageUrl,
        isActive: data.isActive,
        addressLine1: data.address,
        addressLine2: data.street,
        area: data.area,
        pinCode: data.pincode,
        city: data.city,
        state: data.state,
        country: data.country,
        landmark: data.landmark,
      };
      const response = await axios.post(
        config.APIHOST + config.UPDATE_MANUFACTURERS,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  deleteManufacturer: async function (data, userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      let payload = {
        _id: data.manufacturerId,
      };
      const response = await axios.post(
        config.APIHOST + config.DELETE_MANUFACTURER,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  addSupplier: async function (data, userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      let payload = {
        supplierName: data.businessName,
        contactName: data.contactName,
        phone: data.contactNo,
        email: data.email,
        website: data.website,
        addressLine1: data.address,
        addressLine2: data.street,
        area: data.area,
        pinCode: data.pincode,
        city: data.city,
        state: data.state,
        country: data.country,
        landmark: data.landmark,
        tradeLicense: data.tradeLicence,
        foodLicense: data.foodLicence,
        drugLicense: data.drugLicence,
        gst: data.gst,
        description: data.description,
      };

      const response = await axios.post(
        config.APIHOST + config.ADD_SUPPLIER,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  updateSupplier: async function (data, userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      let payload = {
        _id: data.activeSupplierId,
        supplierName: data.businessName,
        contactName: data.contactName,
        phone: data.contactNo,
        email: data.email,
        website: data.website,
        addressLine1: data.address,
        addressLine2: data.street,
        area: data.area,
        pinCode: data.pincode,
        city: data.city,
        state: data.state,
        country: data.country,
        landmark: data.landmark,
        tradeLicense: data.tradeLicence,
        foodLicense: data.foodLicence,
        drugLicense: data.drugLicence,
        gst: data.gst,
        description: data.description,
      };
      const response = await axios.post(
        config.APIHOST + config.EDIT_SUPPLIER,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  updateSupplierStatus: async function (data, status, userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      let payload = {
        _id: data.activeSupplierId,
        status: status,
      };
      const response = await axios.post(
        config.APIHOST + config.UPDATE_SUPPLIER_STATUS,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  uploadFile: async function (data, userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.post(
        config.APIHOST + config.UPLOAD_IMAGE,
        data,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  uploadDoc: async function (data, userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.post(
        config.APIHOST + config.UPLOAD_DOC,
        data,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  uploadDocAnt: async function (data, userData, uploadingStatus) {
    try {
      const response = await axios.post(
        config.APIHOST + config.UPLOAD_DOC,
        data,
        uploadingStatus
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  uploadPrescriptionToAccount: async function (data, sessionToken) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.post(
        config.APIHOST + config.UPLOAD_PRESCRIPTION_TO_ACCOUNT,
        data,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  updateCustomer: async function (data, userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      let payload = {
        userId: data.user._id,
        email: data.email,
        dependentId: data.user.hasOwnProperty("dependentId")
          ? data.user.dependentId
          : "",
        contactNo: data.contactNo,
        dob: data.dob ? Moments(data.dob).format("YYYY-MM-DD") : null,
        relation: data.user.relation,
        gender: data.gender,
        profilePic: data.user.profilePic,
        name: data.name,
        _id: data.user._id,
      };

      const response = await axios.post(
        config.APIHOST + config.UPDATE_CUSTOMER,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  fetchCustomer: async function (userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST + config.FETCH_ALL_CUSTOMERS,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  fetchDoctor: async function (userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST + config.FETCH_DOCTORS + "?orgId=" + userData.org[0]._id,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  fetchAllAddress: async function (userId, userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST + config.FETCH_ALL_ADDRESSES + "?customerId=" + userId,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  fetchCustomerById: async function (data, userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST +
          config.FETCH_CUSTOMER_BY_ID_v3 +
          "?_id=" +
          data.customerId,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  fetchRetailPackages: async function (userData, page, pageSize, type) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST +
          config.FETCH_RETAIL_PACKAGES +
          "?page=" +
          page +
          "&pageSize=" +
          pageSize +
          "&type=" +
          type,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  fetchRetailPackageById: async function (id, userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST + config.FETCH_RETAIL_PACKAGE_BY_ID + "?_id=" + id,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  addRetailPackage: async function (data, userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      let payload = {
        retailPackage: data.retailPackage,
      };
      const response = await axios.post(
        config.APIHOST + config.ADD_RETAIL_PACKAGE,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  updateRetailPackage: async function (data, userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      let status = data.status === true ? "Y" : "N";
      let payload = {
        _id: data.retailPackageId,
        status: status,
        retailPackage: data.retailPackage,
      };
      const response = await axios.post(
        config.APIHOST + config.UPDATE_RETAIL_PACKAGE,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  deleteRetailPackage: async function (data, userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      let payload = {
        _id: data.retailPackageId,
      };
      const response = await axios.post(
        config.APIHOST + config.DELETE_RETAIL_PACKAGE,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  fetchQuantityUnits: async function (userData, page, pageSize, type) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST +
          config.FETCH_QUANTITY_UNITS +
          "?page=" +
          page +
          "&pageSize=" +
          pageSize +
          "&type=" +
          type,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  fetchQuantityUnitById: async function (id, userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST + config.FETCH_QUANTITY_UNIT_BY_ID + "?_id=" + id,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  addQuantityUnit: async function (data, userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      let payload = {
        quantityUnit: data.quantityUnit,
      };
      const response = await axios.post(
        config.APIHOST + config.ADD_QUANTITY_UNIT,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  deleteQuantityUnit: async function (data, userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      let payload = {
        _id: data.quantityUnitId,
      };
      const response = await axios.post(
        config.APIHOST + config.DELETE_QUANTITY_UNIT,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  updateQuantityUnit: async function (data, userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      let status = data.status === true ? "Y" : "N";
      let payload = {
        _id: data.quantityUnitId,
        status: status,
        quantityUnit: data.quantityUnit,
      };
      const response = await axios.post(
        config.APIHOST + config.UPDATE_QUANTITY_UNIT,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  updateManufacturerStatus: async function (id, status, userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      let payload = {
        _id: id,
        status: status,
      };
      const response = await axios.post(
        config.APIHOST + config.UPDATE_MANUFACTURER_STATUS,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  updateProductStatus: async function (data, status, userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      let payload = {
        _id: data.activeProductId,
        status: status,
      };
      const response = await axios.post(
        config.APIHOST + config.UPDATE_PRODUCT_STATUS,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  updateProductStatusNew: async function (
    activeProductId,
    status,
    sessionToken
  ) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      let payload = {
        _id: activeProductId,
        status: status,
      };
      const response = await axios.post(
        config.APIHOST + config.UPDATE_PRODUCT_STATUS,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  deleteProduct: async function (data, userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      let payload = {
        _id: data.productId,
      };
      const response = await axios.post(
        config.APIHOST + config.DELETE_PRODUCT,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  deleteCategory: async function (data, userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      let payload = {
        _id: data.categoryId,
      };
      const response = await axios.post(
        config.APIHOST + config.DELETE_CATEGORY,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  deleteSupplier: async function (data, userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      let payload = {
        _id: data.activeSupplierId,
      };
      const response = await axios.post(
        config.APIHOST + config.DELETE_SUPPLIER,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  updateCategoryStatus: async function (data, status, userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      let payload = {
        _id: data.activeCategoryId,
        status: status,
      };
      const response = await axios.post(
        config.APIHOST + config.UPDATE_CATEGORY_STATUS,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  updateQuantityUnitStatus: async function (data, status, userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      let payload = {
        _id: data.activeQuantityUnitId,
        status: status,
      };
      const response = await axios.post(
        config.APIHOST + config.UPDATE_QUANTITY_UNIT_STATUS,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  updateRetailPackageStatus: async function (data, status, userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      let payload = {
        _id: data.activeRetailPackageId,
        status: status,
      };
      const response = await axios.post(
        config.APIHOST + config.UPDATE_RETAIL_PACKAGE_STATUS,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  searchSupplier: async function (q, data) {
    try {
      const headers = {
        headers: { Authorization: data.userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST + config.SEARCH_SUPPLIER + "?term=" + q,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  searchUser: async function (q, data) {
    try {
      const headers = {
        headers: { Authorization: data.userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST +
          config.SEARCH_USER +
          "?term=" +
          q +
          "&orgId=" +
          data.orgId,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  searchCustomer: async function (q, data) {
    try {
      const headers = {
        headers: { Authorization: data.userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST + config.SEARCH_CUSTOMER + "?term=" + q,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  searchShipment: async function (q, data) {
    try {
      const headers = {
        headers: { Authorization: data.userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST +
          config.SEARCH_SHIPMENT +
          "?term=" +
          q +
          "&orgId=" +
          data.orgId,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  searchProduct: async function (q, data) {
    try {
      const headers = {
        headers: { Authorization: data.userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST + config.SEARCH_PRODUCT + "?term=" + q,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  searchProductOrder: async function (q, data) {
    try {
      const headers = {
        headers: { Authorization: data.userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST + config.LOOKUP_MED_ORDER + "?term=" + q,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  searchProductQ: async function (q, data) {
    try {
      const response = await axios.get(
        config.APIHOST + config.LOOKUP_PRODUCT_Q + "?term=" + q
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  fetchShipments: async function (data, startDate, endDate) {
    try {
      const headers = {
        headers: { Authorization: data.userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST +
          config.FETCH_SHIPMENTS +
          "?orgId=" +
          data.orgId +
          "&startDate=" +
          startDate +
          "&endDate=" +
          endDate,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  fetchShipmentById: async function (data) {
    try {
      const headers = {
        headers: { Authorization: data.userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST +
          config.FETCH_SHIPMENT_BY_ID +
          "?orgId=" +
          data.orgId +
          "&_id=" +
          data.shipmentId,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  createShipment: async function (data, userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      let time = Moments(data.receivingTime)
        .format("YYYY-MM-DDTHH:mm")
        .toString();
      let payload = {
        supplier: data.supplier,
        amount: data.amount === "" ? 0 : data.amount,
        receivedBy: data.user,
        invoiceType: data.invoiceType,
        receivingDate: data.receivingDate,
        receivingTime: time,
        invoiceDate: data.invoiceDate,
        invoiceNumber: data.invoiceNumber,
        supplyPlace: data.supplyPlace,
        reverseCharge: data.reverseCharge === "" ? 0 : data.reverseCharge,
        totalTaxAmount: data.totalTaxAmount === "" ? 0 : data.totalTaxAmount,
        paymentMode: data.paymentMode,
        paymentReference: data.paymentReference,
        paymentAmount: data.paymentAmount === "" ? 0 : data.paymentAmount,
        paymentDate: data.paymentDate,
        discountAmount: data.discountAmount === "" ? 0 : data.discountAmount,
        comments: data.comments,
        documentUrl: data.documentUrl,
        orgId: data.orgId,
        cess: data.cessTaxAmount === "" ? 0 : data.cessTaxAmount,

      };

      const response = await axios.post(
        config.APIHOST + config.CREATE_SHIPMENT,
        payload,
        headers
      );
      // console.log(payload);

      return response.data;
    } catch (e) {
      return false;
    }
  },

  deleteFile: async function (data) {
    try {
      const headers = {
        headers: { Authorization: data.userData.sessionToken },
      };
      let payload = {
        fileName: data.fileToBeDeleted,
      };
      const response = await axios.post(
        config.APIHOST + config.DELETE_FILE,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  updateShipmentDocument: async function (data) {
    try {
      const headers = {
        headers: { Authorization: data.userData.sessionToken },
      };

      // documentUrl has old obj format documents
      let mergedDocuments = [];
      if (data.documentUrl.length > 0) {
        for (let item of data.documentUrl) {
          if (item) {
            mergedDocuments.push(item);
          }
        }
        for (let doc of data.docs) {
          if (doc) {
            mergedDocuments.push(doc);
          }
        }
      } else {
        mergedDocuments = data.docs;
      }

      let payload = {
        _id: data.shipmentId,
        documentUrl: mergedDocuments,
        orgId: data.orgId,
      };

      const response = await axios.post(
        config.APIHOST + config.UPDATE_SHIPMENT_DOCUMENT,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  updateShipment: async function (data, userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      let time = Moments(data.receivingTime)
        .format("YYYY-MM-DDTHH:mm")
        .toString();

      // documentUrl has old obj format documents
      let mergedDocuments = [];
      if (data.documentUrl.length > 0) {
        mergedDocuments = mergedDocuments.concat(data.documentUrl, data.docs);
      } else {
        mergedDocuments = data.docs;
      }

      let payload = {
        _id: data.shipmentId,
        supplier: data.supplier,
        amount: data.amount === "" ? 0 : data.amount,
        receivedBy: data.user,
        invoiceType: data.invoiceType,
        receivingDate: data.receivingDate,
        receivingTime: time,
        invoiceDate: data.invoiceDate,
        invoiceNumber: data.invoiceNumber,
        supplyPlace: data.supplyPlace,
        reverseCharge: data.reverseCharge === "" ? 0 : data.reverseCharge,
        totalTaxAmount: data.totalTaxAmount === "" ? 0 : data.totalTaxAmount,
        discountAmount: data.discountAmount === "" ? 0 : data.discountAmount,
        comments: data.comments,
        documentUrl: mergedDocuments,
        orgId: data.orgId,
        cess: data.cessTaxAmount ==="" ? 0 : data.cessTaxAmount,

      };
      const response = await axios.post(
        config.APIHOST + config.EDIT_SHIPMENT,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  fetchMyInventory: async function (data, starDate, endDate) {
    try {
      const headers = {
        headers: { Authorization: data.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST +
          config.FETCH_MY_INVENTORY +
          "?orgId=" +
          data.org[0]._id +
          "&startDate=" +
          Moments(starDate).format("YYYY-MM-DD") +
          "&endDate=" +
          Moments(endDate).format("YYYY-MM-DD"),
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  fetchInventoryGSTForReport: async function (data, starDate, endDate) {
    try {
      const headers = {
        headers: { Authorization: data.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST +
          config.FETCH_INVENTORY_GST_DATA +
          "?orgId=" +
          data.org[0]._id +
          "&startDate=" +
          Moments(starDate).format("YYYY-MM-DD") +
          "&endDate=" +
          Moments(endDate).format("YYYY-MM-DD"),
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  fetchInventoryById: async function (data) {
    try {
      const headers = {
        headers: { Authorization: data.userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST +
          config.FETCH_INVENTORY_BY_ID +
          "?_id=" +
          data.inventoryId +
          "&orgId=" +
          data.orgId,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  updateInventory: async function (data) {
    const headers = {
      headers: { Authorization: data.userData.sessionToken },
    };
    let payload = {
      _id: data.inventoryId,
      productId: data.product._id,
      batch: data.batch,
      mrp: data.mrp,
      gst: data.gst,
      priceNogst: data.priceNogst,
      priceWgst: data.priceNogst,
      discount: data.discount,
      hsn: data.hsn,
      mfgDate: data.mfgDate,
      expDate: data.expDate,
      expAfterMonth: data.expAfterMonth,
      quantity: data.quantity,
      freeQuantity: data.freeQuantity,
      shipmentId: data.shipment._id,
    };

    const response = await axios.post(
      config.APIHOST + config.EDIT_INVENTORY,
      payload,
      headers
    );
    return response.data;
  },

  createInventory: async function (data) {
    try {
      const headers = {
        headers: { Authorization: data.userData.sessionToken },
      };
      let payload = {
        inventoryData: data.inventoryData,
        orgId: data.orgId,
        shipmentId: data.shipment._id,
      };
      const response = await axios.post(
        config.APIHOST + config.CREATE_INVENTORY,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  deleteInventory: async function (data) {
    try {
      const headers = {
        headers: { Authorization: data.userData.sessionToken },
      };
      let payload = {
        _id: data.inventoryId,
        isDeleted: "Y",
      };
      const response = await axios.post(
        config.APIHOST + config.DELETE_INVENTORY,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  updateDiscount: async function (
    outOfStock,
    mrp,
    topSelling,
    amount,
    _id,
    userData
  ) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      let amt = 0;
      if (amount) {
        amt = amount;
      }
      let payload = {
        _id: _id,
        percentage: amt,
        topSelling: topSelling[0],
        maxRetailPrice: parseFloat(mrp),
        outOfStock: outOfStock[0],
      };
      const response = await axios.post(
        config.APIHOST + config.UPDATE_MED_DISCOUNT,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  updateQuanty: async function (_id, maxQnty, minQnty, reorderQnty, userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      let payload = {
        _id: _id,
        maxQuantity: maxQnty,
        reorderThreshold: reorderQnty,
        minQuantity: minQnty,
      };
      const response = await axios.post(
        config.APIHOST + config.UPDATE_REORDER,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  updateProductShipping: async function (
    inPersonInstruction,
    shippingInstruction,
    pickupInstruction,
    _id,
    inPerson,
    ship,
    pickup,
    userData
  ) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      let payload = {
        _id: _id,
        shippingDetails: {
          inPerson: inPerson,
          ship: ship,
          pickUp: pickup,
          inPersonInstruction: inPersonInstruction,
          shippingInstruction: shippingInstruction,
          pickupInstruction: pickupInstruction,
        },
      };
      const response = await axios.post(
        config.APIHOST + config.UPDATE_PRODUCT_SHIPPING,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  searchProductInv: async function (q, data) {
    try {
      const headers = {
        headers: { Authorization: data.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST + config.SEARCH_PRODUCT_INV + "?term=" + q,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  searchProductInvExp: async function (q, data) {
    try {
      const headers = {
        headers: { Authorization: data.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST + config.SEARCH_PRODUCT_INV_EXP + "?term=" + q,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  searchProductInvZero: async function (q, data) {
    try {
      const headers = {
        headers: { Authorization: data.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST + config.SEARCH_PRODUCT_INV_ZERO + "?term=" + q,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  fetchInvMultiple: async function (ids, userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      let payload = {
        orgId: userData.org[0]._id,
        _ids: ids,
      };
      const response = await axios.post(
        config.APIHOST + config.FETCH_INV_BY_IDS,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  fetchShipmentsCache: async function (data) {
    try {
      const headers = {
        headers: { Authorization: data.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST +
          config.FETCH_SHIPMENTS +
          "?orgId=" +
          data.org[0]._id +
          "&startDate=" +
          Moments().format("YYYY-MM-DD") +
          "&endDate=" +
          Moments().format("YYYY-MM-DD"),
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  sendSMS: async function (id, userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      let payload = {
        orgId: userData.org[0]._id,
        _id: id,
      };
      const response = await axios.post(
        config.APIHOST + config.SEND_REQUEST_SMS,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  deleteShipment: async function (shipmentId, orgId, userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      let payload = {
        _id: shipmentId,
        orgId: orgId,
      };
      const response = await axios.post(
        config.APIHOST + config.DELETE_SHIPMENT,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  searchProductByType: async function (term, userData, type) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST +
          config.OPTIONAL_STOCK_SEARCH +
          "?term=" +
          term +
          "&searchBy=" +
          type,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  suggestMfg: async function (term, userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST + config.SUGGEST_MFG + "?term=" + term,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  suggestSupplier: async function (term, userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST + config.SUGGEST_SUPPLIER + "?term=" + term,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  //v2 calls
  searchShipmentV2: async function (q, userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST +
          config.SEARCH_SHIPMENT +
          "?term=" +
          q +
          "&orgId=" +
          userData.org[0]._id,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  searchProductV2: async function (q, userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST + config.SEARCH_PRODUCT + "?term=" + q,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  fetchStorageLocationV2: async function () {
    try {
      const response = await axios.get(config.APIHOST + config.FETCH_STORE_LOC);
      return response.data;
    } catch (e) {
      return false;
    }
  },
  fetchFloorV2: async function (locId) {
    try {
      const response = await axios.get(
        config.APIHOST + config.FETCH_FLOOR + "?locId=" + locId
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  fetchFloorV3: async function (locId,userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST_FOR_SEARCH_CUSTOMER + config.FETCH_FLOOR_NEW + "?storageLocId=" + locId,headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  fetchRackV2: async function (floorId) {
    try {
      const response = await axios.get(
        config.APIHOST + config.FETCH_RACK + "?floorId=" + floorId
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  fetchRackV3: async function (floorId,userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST_FOR_SEARCH_CUSTOMER + config.FETCH_RACK_NEW + "?floorId=" + floorId,headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  fetchBoxV2: async function (rackId) {
    try {
      const response = await axios.get(
        config.APIHOST + config.FETCH_BOX + "?rackId=" + rackId
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  fetchBoxV3: async function (rackId,userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST_FOR_SEARCH_CUSTOMER + config.FETCH_BOX_NEW + "?rackId=" + rackId,headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  createInventoryV3: async function (userData, data) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      let payload = data[0];

      console.log(payload)
      const response = await axios.post(
        config.APIHOST_FOR_SEARCH_CUSTOMER + config.ADD_STOCK_V4,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  resetInventoryV3: async function (sessionToken, payload) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.post(
        config.APIHOST + config.RESET_STOCK_V3,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  fetchStockV3: async function (userData, startDate, endDate) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST +
          config.FETCH_INVENTORY_V3 +
          "?startDate=" +
          startDate +
          "&endDate=" +
          endDate,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  fetchStoreLocById: async function (userData, stockId) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST +
          config.FETCH_STORE_LOC_BY_STOCK_ID +
          "?stockId=" +
          stockId,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  approveStockV3: async function (userData, stockId, comment) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      let payload = {
        stockId: stockId,
        comment: comment,
      };
      const response = await axios.post(
        config.APIHOST + config.APPROVE_STOCK,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  rejectStockV3: async function (userData, stockId, comment) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      let payload = {
        stockId: stockId,
        comment: comment,
      };
      const response = await axios.post(
        config.APIHOST + config.REJECT_STOCK,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  fetchOrdersByStockId: async function (userData, stockId) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST + config.FETCH_ORDER_BY_STOCK_ID + "?stockId=" + stockId,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  fetchProductByBarCode: async function (userData, barCode) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST +
          config.FETCH_PRODUCTS_BY_BARCODE +
          "?barCode=" +
          barCode,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  fetchDetailsofreceivedStock: async function (userData, barCode) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST_2 +
          config.FETCH_RECEIVE_STOCK +
          "?barCode=" +
          barCode,
        headers
      );
      return response.data;
    } catch (e) {
      return {
        status:false,
        error: e
      };
    }
  },
  saveReceivedStock: async function (sessionToken, payload) {
    try {
        const headers = {
            headers: {Authorization: sessionToken}
        };
        const response = await axios.post(config.APIHOST_2 + config.SAVE_RECEIVED_STOCK, payload, headers)
        return response.data
    } catch (e) {
        return false
    }
},
  fetchProductByBarCodeProducts: async function (sessionToken, barCode) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.get(
        config.APIHOST +
          config.FETCH_PRODUCTS_BY_BARCODE_PRODUCTS +
          "?barCode=" +
          barCode,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  fetchInventoryByIdV3: async function (sessionToken, inventoryId, orgId) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.get(
        config.APIHOST +
          config.FETCH_INVENTORY_BY_ID +
          "?_id=" +
          inventoryId +
          "&orgId=" +
          orgId,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  fetchProductByBatch: async function (sessionToken, batch) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.get(
        config.APIHOST + config.FETCH_PRODUCTS_BY_BATCH + "?batch=" + batch,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  fetchProductByInvoiceId: async function (sessionToken, invoiceId) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.get(
        config.APIHOST +
          config.FETCH_PRODUCTS_BY_INVOICE_ID +
          "?invoiceId=" +
          invoiceId,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  fetchProductByTerm: async function (sessionToken, term) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.get(
        config.APIHOST + config.FETCH_PRODUCT_BY_TERM + "?term=" + term,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  fetchProductByName: async function (sessionToken, name) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.get(
        config.APIHOST + config.FETCH_PRODUCT_BY_NAME + "?name=" + name,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  deleteFromStockReset: async function (sessionToken, payload) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.post(
        config.APIHOST + config.DELETE_FROM_STOCK_RESET,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  fetchExpiredItems: async function (userData, date) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST + config.FETCH_EXPIRED_PRODUCT + "?date=" + date,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  updateExpiredItem: async function (userData, payload) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.post(
        config.APIHOST + config.UPDATE_EXP_STATE,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  updateExpiredPayMent: async function (userData, payload) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.post(
        config.APIHOST + config.MARK_AS_PAYMENT_RECEIVED,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  fetchBannedProducts: async function (userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST + config.FETCH_BANNED_PRODUCTS,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  updateBannedProduct: async function (userData, payload) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.post(
        config.APIHOST + config.UPDATE_BANNED_PRODUCTS,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  fetchProductByTermAll: async function (sessionToken, term) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.get(
        config.APIHOST + config.SEARCH_RODUCT + "?term=" + term,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  addCashBookEntry: async function (userData, payload) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.post(
        config.APIHOST + config.ADD_PAYMENT,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  fetchInwardEntried: async function (userData, startDate, endDate, location) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST + config.FETCH_INWARD_CASH + "?startDate=" + startDate+"&endDate="+endDate + "&location=" + location,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  fetchGeneralSupplier: async function (userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST + config.FETCH_GENERAL_SUPPLIERS,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  addToBank: async function (userData, payload) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.post(
        config.APIHOST + config.ADD_TO_BANK,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  approveShipment: async function (payload, sessionToken) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.post(
        config.APIHOST + config.APPROVE_SHIPMENT,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  rejectShipment: async function (payload, sessionToken) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.post(
        config.APIHOST + config.REJECT_SHIPMENT,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  addShipmentPayment: async function (payload, sessionToken) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.post(
        config.APIHOST + config.ADD_PAYMENT_SHIPMENT,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  addCoupon: async function (payload, sessionToken) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.post(
        config.APIHOST + config.ADD_COUPON,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  fetchCoupon: async function (userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST + config.FETCH_COUPONS,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  deleteCoupon: async function (payload, userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.post(
        config.APIHOST + config.DELETE_COUPON,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  fetchCustomerCoupons: async function (userId, sessionToken) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.get(
        config.APIHOST + config.FETCH_CUSTOMER_COUPONS + "?userId=" + userId,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  // Add substitute Product----

  addSubstituteProduct: async function (masterId, substituteId, sessionToken) {
    const headers = {
      headers: { Authorization: sessionToken },
    };
    let payload = {
      masterProductId: masterId,
      substituteId: substituteId,
    };

    let url = config.APIHOST + config.ADD_SUBSTITUTE_PRODUCT;
    const response = await axios.post(url, payload, headers);
    return response.data;
  },

  // fetch substitute product-----

  fetchSubstituteProduct: async function (masterId, sessionToken) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.get(
        config.APIHOST + config.FETCH_SUBSTITUTE_PRODUCT + masterId,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  // delete existing substitute medicine-----
  deleteExistingSubstitute: async function (id, sessionToken) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };

      let payload = {
        _id: id,
      };
      const response = await axios.post(
        config.APIHOST + config.DELETE_SUBSTITUTE_PRODUCT,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  // delete master from list of substitution----

  deleteMaster: async function (id, sessionToken) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };

      let payload = {
        _id: id,
      };
      const response = await axios.post(
        config.APIHOST + config.DELETE_MASTER_PRODUCT,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  // fetch all  substitute product-----

  fetchAllSubstituteProduct: async function (page, sessionToken) {
    try {
      const headers = {
        headers: { Authorization: sessionToken.sessionToken },
      };

      const response = await axios.get(
        config.APIHOST + config.FETCH_ALL_SUBSTITUTE_LIST + "?page=" + page,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  // list of visit out of stock

  fetchListVisitOutOfStock: async function (page, sessionToken) {
    try {
      const headers = {
        headers: { Authorization: sessionToken.sessionToken },
      };

      const response = await axios.get(
        config.APIHOST + config.VISIT_OUT_OF_STOCK + "?page=" + page,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  updateInternalComment: async function (sessionToken, payload) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.post(
          config.APIHOST + config.UPDATE_INTERNAL_COMMENT,
          payload,
          headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  resetStock: async function (userData, id, quantity) {
   
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      let payload = {
        id: id,
        quantity: quantity,
      };
      const response = await axios.post(
        config.APIHOST_2 +"/" + config.RESET_STOCK,
        payload,
        headers
      );
       return response;
    } catch (e) {
      return false;
    }
  },

  fetchStockDetailsV2: async function (userData, barCode) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.get(config.APIHOST_2+config.FETCH_STOCK_BYBARCODE_FOR_FULFILMENT_V2+"?barCode="+barCode.toString(), headers);
      return response.data;
    } catch (e) {
      return false;
    }
  },

};
export default PharmacyService;
