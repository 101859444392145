import React,{Suspense} from "react";
import {Link} from "react-router-dom";
import { withRouter } from "react-router-dom";
import {
    MDBBreadcrumbItem,
    MDBBreadcrumb, MDBBox,
} from 'mdbreact';
import ProductDetails from "../../components/report/productDetailsReport"
export default class ProductDetailsPage extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem><Link to={'/filtered-products'}>Filtered Products</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Product Details</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <MDBBox className={"min-vh-100"}>
                    <Suspense fallback={null}>
                        <ProductDetails  queryString={this.props.match.params}/>
                    </Suspense>
                </MDBBox>
            </React.Fragment>
        );
    }
}