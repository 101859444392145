import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import UserStore from "../stores/userStore";

export default class PrivateRoute extends React.Component {
  render() {
    const { ...restProps } = this.props;
    if (UserStore.fetchSession()) return <Route {...restProps} />;
    return <Redirect to="/" />;
  }
}
