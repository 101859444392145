import React from "react";
import {MDBTable, MDBTableHead, MDBTableBody} from "mdbreact";
import config from "../../config";
import utility from "../../utility/utility";
class OfflineOrderInvoice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orderDetails: this.props.orderDetails,
            totalDiscount: 0
        }
    }
    componentDidMount() {
        this.totalDiscountAmount()
    }
    calculateGSTAmount = (mrp, gst) => {
        if(mrp > 0 && gst > 0) {
            //GST Amount = mrp – [mrp x {100/(100+GST%)}], Base price = mrp – GST Amount.
            let totalGstAmount = mrp - (mrp * (100 / (100 + gst)))
            return totalGstAmount.toFixed(2)
        }
        return 0
    }
    totalDiscountAmount = () => {
        let totalDiscount  = 0
        for (let item of this.state.orderDetails.products) {
            totalDiscount = totalDiscount + ((item.quantity * item.inventory.pricePerUnit) * item.discount / 100)
        }
        this.setState({totalDiscount})
    }

    groupProducts = () => {
        const groupedProducts = this.state.orderDetails.products.reduce((acc, item) => {
            const key = `${item.productName}-${item.inventory.batch}-${item.inventory.pricePerUnit}`;
            
            if (!acc[key]) {
              acc[key] = { 
                productName: item.productName,
                batch: item.inventory.batch,
                expDate: item.inventory.expDate,
                pricePerUnit: item.inventory.pricePerUnit,
                quantity: item.quantity,
                discount: item.discount,
                gst: item.inventory.gst,
                gstAmount: Number(this.calculateGSTAmount(item.inventory.subtotal, item.inventory.gst)),
                subtotal: item.inventory.subtotal,
              };
            } else {
              acc[key].quantity += item.quantity;
              acc[key].subtotal += item.inventory.subtotal;
              acc[key].gstAmount += Number(this.calculateGSTAmount(item.inventory.subtotal, item.inventory.gst));
            }
            
            return acc;
          }, {});
          
          const groupedArray = Object.values(groupedProducts);

          return groupedArray
          
    }

    render() {

        return(
            <React.Fragment>
                <div className="z-depth-1 p-3 my-2 myclass">
                    <div className={"col-12 row mx-0 text-center"}>
                        <div className={"col-md-12 m-auto"}><h4>Tax Invoice</h4></div>
                    </div>
                    <div className={"col-12 row mx-0 my-2"}>
                        <div className={"col-9 m-0 p-0"}>
                            <img src={"/positra_rx_logo.svg"} width={'150px'}/>
                            <p className={"mt-2"}>
                                <b>Address:</b> 1st Floor<br/>
                                Lakhimandir Market Complex, Beltola <br/>
                                Guwahati, Assam, 781028, India <br/>
                                <b>Phone: </b>6900702871
                            </p>
                            <p className={"mt-2"}>
                            {this.props?.doctorName && <>
                                    <b>Doctor Name:</b> <span>{this.props?.doctorName}</span>
                                </>
                                } <br/>
                                <b>Customer Name :</b> {this.state.orderDetails.customerDetails.name} <br/>
                                {this.state.orderDetails.customerDetails.hasOwnProperty("contactNo") && <>
                                    <b>Contact No :</b> <span>{this.state.orderDetails.customerDetails.contactNo}</span>
                                </>
                                }
                            </p>
                        </div>
                        <div className={"col-3 m-0 p-0 text-left"}>
                            <p>
                                <b>Invoice Date:</b> {utility.convertToReadbleDateTimeUtc(this.state.orderDetails.orderDate)}
                                <br/>
                                <b>GSTIN :</b> <span>18AAICP6875B1Z5</span> <br/>
                                <b>FSSAI :</b> <span>10321001000639</span> <br/>
                                <b>Invoice #:</b> <span
                                className={"text-uppercase"}>{this.state.orderDetails.hasOwnProperty("invoiceId") ? this.state.orderDetails.invoiceId : ""}</span>
                                <br/>
                                <b>Transaction Mode :</b> {this.state.orderDetails.paymentMode} <br/>
                                <b>Order Id:</b> <span className={"text-uppercase"}>{this.state.orderDetails.orderCode}</span>
                            </p>
                        </div>
                    </div>
                    <MDBTable striped>
                        <MDBTableHead>
                            <tr>
                                <th className="font-weight-bold">Sl.</th>
                                <th className="font-weight-bold">
                                    <strong>Product Description</strong>
                                </th>
                                <th className="font-weight-bold">
                                    <strong>Batch</strong>
                                </th>
                                <th className="font-weight-bold">
                                    <strong>Exp.</strong>
                                </th>
                                <th className="font-weight-bold">
                                    <strong>M.R.P (<span
                                        dangerouslySetInnerHTML={{__html: config.CURRENCY_HTML}}></span>)</strong>
                                </th>
                                <th className="font-weight-bold">
                                    <strong>Qty.</strong>
                                </th>
                                <th className="font-weight-bold">
                                    <strong>Disc. %</strong>
                                </th>
                                <th className="font-weight-bold">
                                    <strong>GST %</strong>
                                </th>
                                <th className="font-weight-bold">
                                    <strong>GST Amount</strong>
                                </th>
                                <th className="font-weight-bold">
                                    <strong>Amount (<span
                                        dangerouslySetInnerHTML={{__html: config.CURRENCY_HTML}}></span>)</strong>
                                </th>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            {this.groupProducts().map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.productName}</td>
                                    <td>{item.batch}</td>
                                    <td>{utility.convertMfgOrExpiry(item.expDate)}</td>
                                    <td>{item.pricePerUnit.toFixed(2)}</td>
                                    <td>{item.quantity}</td>
                                    <td>{item.discount}</td>
                                    <td>{item.gst}</td>
                                    <td>{item.gstAmount.toFixed(2)}</td>
                                    <td>{item.subtotal.toFixed(2)}</td>
                                </tr>
                            ))}
                            <tr>
                                <td colSpan={"8"} className={"text-right"}>Total Amount: </td>
                                <td colSpan={"4"} className={"text-right"}>{this.state.orderDetails?.totalAmount?.toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td colSpan={"8"} className={"text-right"}>Flat Discount: </td>
                                <td colSpan={"4"} className={"text-right"}>{this.state.orderDetails?.flatDiscount?.toFixed(2) ?? 0}</td>
                            </tr>
                            <tr>
                                <td colSpan={"8"} className={"text-right"}>Final Amount: </td>
                                <td colSpan={"4"} className={"text-right"}>{(this.state.orderDetails?.totalAmount - (this.state.orderDetails?.flatDiscount ? this.state.orderDetails?.flatDiscount : 0))?.toFixed(2)}</td>
                            </tr>
                        </MDBTableBody>
                    </MDBTable>
                    {this.state.totalDiscount > 0 &&
                    <div className={"text-center"}><h6>* You saved <span
                        dangerouslySetInnerHTML={{__html: config.CURRENCY_HTML}}></span> {(this.state?.totalDiscount).toFixed(2)} on
                        this order.</h6></div>
                    }
                </div>
            </React.Fragment>
        )
    }
}
export default OfflineOrderInvoice;