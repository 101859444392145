import React, { useState } from 'react';
import { MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBContainer, MDBInput, MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader } from 'mdbreact';
import ReportService from '../../../services/reportService';
import UserStore from '../../../stores/userStore';

const CreateReport = ({ history }) => {
    const [formData, setFormData] = useState({
        description: '',
        id: '',
        name: ''
    });

    const [error, setError] = useState(null);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            let userData = await UserStore.fetchSessionData();
            const response = await ReportService.createreport(userData, formData);
            console.log(response);

            if (!response) {
                throw new Error('Failed to submit form data');
            }

            console.log('Form data submitted successfully:', formData);
            history.push('/reports/report-list');
        } catch (error) {
            console.error('Error submitting form data:', error);
            setError('Failed to submit form data');
            setIsErrorModalOpen(true);
        }
    };

    const handleCloseErrorModal = () => {
        setIsErrorModalOpen(false);
    };

    return (
        <React.Fragment>
            <MDBContainer className="my-3">
                <MDBCard>
                    <MDBCardHeader>
                        <div className="float-left title">Create Report</div>
                    </MDBCardHeader>
                    <MDBCardBody>
                        <form onSubmit={handleSubmit}>

                            <MDBInput
                                autoComplete="off"
                                name="name"
                                maxLength="50"
                                type="text"
                                label="Name *"
                                onChange={handleChange}
                                value={formData.name}
                                outline
                                required
                            />
                            <MDBInput
                                autoComplete="off"
                                name="description"
                                maxLength="100"
                                type="text"
                                label="Description *"
                                onChange={handleChange}
                                value={formData.description}
                                outline
                                required
                            />

                            <div className="m-0 text-center">
                                <MDBBtn
                                    outline
                                    size="md"
                                    onClick={() => history.push('/reports/report-list')}
                                    className="mr-auto"
                                >
                                    Back
                                </MDBBtn>
                                <MDBBtn size="md" type="submit">
                                    Save
                                </MDBBtn>
                            </div>
                        </form>
                    </MDBCardBody>
                </MDBCard>
            </MDBContainer>
            <MDBModal isOpen={isErrorModalOpen} toggle={handleCloseErrorModal}>
                <MDBModalHeader toggle={handleCloseErrorModal}>Error</MDBModalHeader>
                <MDBModalBody>
                    {error}
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn  size="md" onClick={handleCloseErrorModal}>Close</MDBBtn>
                </MDBModalFooter>
            </MDBModal>
        </React.Fragment>
    );
};

export default CreateReport;
