import React from "react";
import DownloadReport from "../../components/common/downloadReport";
import {MDBBox, MDBBreadcrumb, MDBBreadcrumbItem, MDBCol, MDBRow, MDBSelect} from "mdbreact";
import {Link} from "react-router-dom";
import Moments from "moment";
import {toast} from "react-toastify";
import ReportService from "../../services/reports";
import PharmacyService from "../../services/pharmacyService";
import UserStore from "../../stores/userStore";
import SimpleBackdrop from "../../components/common/overlay";
import GetDateRange from "../../components/common/getDateRange";
import utility from "../../utility/utility";

class DownloadReports extends React.Component {
    constructor() {
        super();
        this.state = {
            data: [],
            headers: [],
            progressStatus: false,
            startDate: Moments(),
            endDate: Moments(),
            reportCategories: [{text: "Sales Report", value: "SALES", checked: true}, {
                text: "Purchase Report",
                value: "PURCHASE"
            },
            {
                text: "Purchase Report For GST",
                value: "PURCHASEGST"
            }
        ],
            selectedCategory: 'SALES',
        }
    }

    async componentDidMount() {
        this.getDateRangeCallback(this.state.startDate, this.state.endDate)
        let headers = this.getSalesReportHeaders()
        this.setState({headers: headers})
    }

    getDateRangeCallback = (startDate, endDate) => {
        if (startDate > endDate) {
            toast.info("Start date is greater than the end date. Please adjust the date range again.")
            return
        }
        this.getData(startDate, endDate, this.state.selectedCategory).then(res => {
            if (res === false) {
                toast.error("We are facing some issue. Please try again later.");
            } else {
                let customData = [], headers = []
                if(this.state.selectedCategory === 'SALES') {
                    customData = this.formatSalesData(res)
                    headers = this.getSalesReportHeaders()
                }
                if(this.state.selectedCategory === 'PURCHASE') {
                    customData = this.formatPurchaseData(res)
                    headers = this.getPurchaseReportHeaders()
                }
                if(this.state.selectedCategory === 'PURCHASEGST') {
                    customData = this.formatPurchaseGSTData(res)
                    headers = this.getPurchaseGSTReportHeaders()
                }
                this.setState({
                    data: customData,
                    fileName: this.state.selectedCategory+"_REPORT_" + Moments(startDate).format("YYYY-MM-DD") + "_" + Moments(endDate).format("YYYY-MM-DD") + ".csv",
                    headers: headers,
                    startDate: startDate,
                    endDate: endDate
                })
            }
        })
    }
    getData = async (startDate, endDate) => {
        let userData = await UserStore.fetchSessionData();
        let data = []
        this.setState({progressStatus: true});
        if (this.state.selectedCategory === 'SALES') {
            data = await ReportService.fetchSalesData(userData, Moments(startDate).format("YYYY-MM-DD"), Moments(endDate).format("YYYY-MM-DD"));
            this.setState({progressStatus: false});
            return data
        }
        if (this.state.selectedCategory === 'PURCHASE') {
            data = await PharmacyService.fetchMyInventory(userData, startDate, endDate);
            this.setState({progressStatus: false});
            return data
        }
        if (this.state.selectedCategory === 'PURCHASEGST') {
            data = await PharmacyService.fetchInventoryGSTForReport(userData, startDate, endDate);
            this.setState({progressStatus: false});
            return data
        }

        this.setState({progressStatus: false});
        return data
    }
    
    getSalesReportHeaders = () => {
        const headers = [
            {label: 'Order No.', key: 'orderCode'},
            {label: 'Selling Date', key: 'orderDate'}, //format: 01 Aug 2020
            {label: 'Product Name', key: 'products.productName'},
            {label: 'Batch No', key: 'products.inventory.batch'},
            {label: 'Mfg.', key: 'products.inventory.mfgDate'}, //format: Aug 2020
            {label: 'Exp.', key: 'products.inventory.expDate'}, //format: Aug 2020
            {label: 'MRP', key: 'products.inventory.mrp'},
            {label: 'Unit Price', key: 'products.inventory.mrpPerUnit'}, //calculated (MRP/ContentQuantity)
            {label: 'Quantity', key: 'products.quantity'},
            {label: 'GST %', key: 'products.inventory.gst'},
            {label: 'GST Amount', key: 'products.inventory.gstAmount'}, //calculated
            {label: 'Discount %', key: 'products.discount'},
            {label: 'Discount Amount', key: 'products.discountAmount'}, //calculated
            {label: 'Sub Total', key: 'subTotal'}, //calculated (Unit Price * Quantity)
            {label: 'Purchase Price', key: 'purchasePrice'}, //calculated
            {label: 'Selling Price', key: 'sellingPrice'}, //calculated (SubTotal - DiscountAmount)
            {label: 'HSN', key: 'products.inventory.hsn'},
            {label: 'Status', key: 'status'},
            {label: 'Initial Payment Mode', key: 'paymentMode'}
        ];
        return headers
    }
    getPurchaseReportHeaders = () => {
        const headers = [
            {label: 'Received Date', key: 'shipmentDetails.receivingDate'},
            {label: 'Staff', key: 'shipmentDetails.receivedByDetails.name'},
            {label: 'Batch No', key: 'batch'},
            {label: 'Product Name', key: 'productDetails.productName'},
            {label: 'MRP', key: 'productDetails.mrp'},
            {label: 'Purchase Price', key: 'priceWgst'},
            {label: 'Quantity', key: 'quantityIn'},
            {label: 'Content Quantity', key: 'productDetails.contentQuantity'},
            {label: 'Total Quantity Left', key: 'quantity'},
            {label: 'GST', key: 'gst'},
            {label: 'HSN/SAC', key: 'hsn'},
            {label: 'Mfg. Date', key: 'mfgDate'}, //format: Aug 2020
            {label: 'Exp. Date', key: 'expDate'}, //format: Aug 2020
            {label: 'Supplier Name', key: 'shipmentDetails.supplierDetails.supplierName'},
        ];
        return headers
    }
    getPurchaseGSTReportHeaders = () => {
        const headers = [
            {label: 'Invoice No', key: 'invoiceNumber'},
            {label: 'Invoice Date', key: 'invoiceDate'},
            {label: 'Distributer Name', key: 'shopName'},
            {label: 'Address', key: 'placeOfSupply'},
            {label: 'GST No', key: 'GSTNumber'},
            {label: 'Amount for 5% GST', key: 'gst_5.0_value'},
            {label: 'Tax for 5% GST', key: 'gst_5.0_tax'},
            {label: 'Amount for 12% GST', key: 'gst_12.0_value'},
            {label: 'Tax for 12% GST', key: 'gst_12.0_tax'},
            {label: 'Amount for 18% GST', key: 'gst_18.0_value'},
            {label: 'Tax for 18% GST', key: 'gst_18.0_tax'},
            {label: 'Total Amount', key: 'totalAmount'}
        ];
        return headers
    }

    formatSalesData = (salesData) => {
        let newData = salesData
        for (let i = 0; i < salesData.length; i++) {
            let contentQuantity = (salesData[i].products.looseSaleAllowed === "N") ? 1 : salesData[i].products.contentQuantity;
            if (salesData[i].products.hasOwnProperty("inventory") && salesData[i].products.inventory !== null) {
                newData[i].products.inventory.mrpPerUnit = utility.calculatePerUnitPrice(newData[i].products.inventory.mrp, contentQuantity)
                newData[i].products.inventory.gstAmount = utility.calculateGSTAmount(salesData[i].products.inventory.mrp,
                    salesData[i].products.inventory.gst, salesData[i].products.quantity, contentQuantity, salesData[i].products.discount)
                newData[i].products.discountAmount = utility.calculateDiscountAmount(salesData[i].products.inventory.mrp, salesData[i].products.quantity,
                    salesData[i].products.discount, contentQuantity)
                newData[i].subTotal = utility.calculateSubTotal(salesData[i].products.inventory.mrp, contentQuantity, salesData[i].products.quantity)
                newData[i].sellingPrice = utility.calculateSellingPrice(salesData[i].products.inventory.mrp, salesData[i].products.quantity,
                    salesData[i].products.discount, contentQuantity)
                newData[i].products.inventory.mfgDate = Moments(salesData[i].products.inventory.mfgDate).format("MMM YYYY")
                newData[i].products.inventory.expDate = Moments(salesData[i].products.inventory.expDate).format("MMM YYYY")
                newData[i].purchasePrice = utility.calculatePurchasePrice(salesData[i].products.inventory.priceWgst, contentQuantity, salesData[i].products.quantity)
            } else {
                return []
            }
            newData[i].orderDate = Moments(salesData[i].orderDate).format("DD MMM YYYY")
        }
        return newData
    }
    formatPurchaseData = (purchaseData) => {
        let newData = purchaseData
        for (let i = 0; i < purchaseData.length; i++) {
            newData[i].shipmentDetails.receivingDate = Moments(purchaseData[i].shipmentDetails.receivingDate).format("MMM DD, YYYY")
            newData[i].mfgDate = Moments(purchaseData[i].mfgDate).format("MMM YYYY")
            newData[i].expDate = Moments(purchaseData[i].expDate).format("MMM YYYY")
        }
        return newData
    }
    formatPurchaseGSTData = (purchaseGSTData) => {
        let newData = purchaseGSTData
        for (let i = 0; i < purchaseGSTData.length; i++) {
            newData[i].invoiceDate = Moments(purchaseGSTData[i].invoiceDate).format("DD MMM YYYY")
        }
        return newData
    }
    handleReportCategory = (e) => {
        if (!e[0]) {
            return false
        }
        this.setState({selectedCategory: e[0]})
        this.getReportOnCategoryChange(e[0])
    }
    getReportOnCategoryChange = (category) => {
        let headers = []
        this.getData(this.state.startDate, this.state.endDate, category).then(res => {
            if (res === false) {
                toast.error("We are facing some issue. Please try again later.");
            } else {
                let customData = []
                if(category === 'SALES') {
                    customData = this.formatSalesData(res)
                    headers = this.getSalesReportHeaders()
                }
                if(category === 'PURCHASE') {
                    customData = this.formatPurchaseData(res)
                    headers = this.getPurchaseReportHeaders()
                }
                if(category === 'PURCHASEGST') {
                    customData = this.formatPurchaseGSTData(res)
                    headers = this.getPurchaseGSTReportHeaders()
                }
                this.setState({
                    data: customData,
                    fileName: category +"_REPORT_"+ Moments(this.state.startDate).format("YYYY-MM-DD") + "_" + Moments(this.state.endDate).format("YYYY-MM-DD") + ".csv",
                    headers: headers
                })
            }
        })
    }

    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.progressStatus}/>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Sales/Purchase Reports</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <MDBBox className={"ml-3 mt-4"}>
                    <MDBRow className={"ml-1 mt-1 d-flex flex-column"}>
                        <div className={"col-md-3 ml-1"}>
                            <MDBSelect outline={true} label={"Select Category"} getValue={this.handleReportCategory}
                                       options={this.state.reportCategories}></MDBSelect>
                        </div>
                        <MDBCol>
                            <GetDateRange getDateRangeCallback={this.getDateRangeCallback}/>
                        </MDBCol>
                        {(this.state.data.length > 0) && !this.state.progressStatus && (
                            <MDBCol className={"mt-5"}>
                                <span><h6>&nbsp;<b>Note:</b> Download <b>{this.state.selectedCategory} REPORT</b> for the date range, from <b>{utility.convertToReadbleDate(this.state.startDate)}</b> to <b>{utility.convertToReadbleDate(this.state.endDate)}</b>.</h6></span>
                            </MDBCol>
                        )}
                        {(this.state.data.length > 0) && !this.state.progressStatus &&
                        <MDBCol>
                            <DownloadReport data={this.state.data} fileName={this.state.fileName}
                                            headers={this.state.headers}/>
                        </MDBCol>
                        }
                        {this.state.data.length === 0 && !this.state.progressStatus && (
                            <MDBCol className={"mt-5"}>
                                <h6><b>Note:</b> No <b>{this.state.selectedCategory} RECORD</b> found for the date
                                    range,
                                    from <b>{utility.convertToReadbleDate(this.state.startDate)}</b> to <b>{utility.convertToReadbleDate(this.state.endDate)}</b>
                                </h6>
                            </MDBCol>
                        )}
                    </MDBRow>
                </MDBBox>
            </React.Fragment>
        )
    }
}

export default DownloadReports