import React, { useState, useRef } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBBtn,
  MDBCol,
  MDBIcon,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  toast,
} from 'mdbreact';
import UserStore from "../../../stores/userStore";
import PharmacyService from "../../../services/pharmacyService";
import config from "../../../config";
import Reorder, { reorder } from "react-reorder";

const SinglePicDirectUpload = (props) => {
  const [allowedFileSize] = useState(20971520); // 20MB
  const [fileList, setFileList] = useState(props.fileList || []);
  const [loading, setLoading] = useState(false);
  const [modalLB, setModalLB] = useState(false);
  const [activeImage, setActiveImage] = useState("");

  const uploadDoc = useRef(null);

  const onFileLoad = async (e) => {
    if (e.currentTarget.files.length > 0) {
      for (let fl of e.currentTarget.files) {
        if (checkFileSize(fl)) {
          await uploadFiles(fl);
        }
      }
      e.target.value = null;
    }
  };

  const checkFileSize = (file) => {
    if (file.size > allowedFileSize) {
      toast.error("File is too big. Maximum upload file size is 20 MB");
      return false;
    }
    return true;
  };

  const uploadFiles = async (file) => {
    let userData = await UserStore.fetchSessionData();
    const data = new FormData();
    data.append('fileName', file);
    setLoading(true);
    let uploadedFile = await PharmacyService.uploadFile(data, userData);
    if (uploadedFile) {
      let updatedFileList = [...fileList, uploadedFile.path];
      setFileList(updatedFileList);
      uploadedFileCallback(updatedFileList);
    } else {
      toast.error("There is something wrong, please try again after a while.");
    }
    setLoading(false);
  };

  const toggleLB = () => {
    setModalLB(!modalLB);
  };

  const uploadedFileCallback = async (fileList) => {
    await props.getUploadedFiles(fileList);
  };

  const onReorder = async (event, previousIndex, nextIndex) => {
    setFileList(reorder(fileList, previousIndex, nextIndex));
    await uploadedFileCallback(fileList);
  };

  return (
    <React.Fragment>
      <div className={'col-md-12'}>
        <div className=" add-img-position-in-customer-profile">
         
          <a
            role="button"
            className={"text-primary"}
            onClick={() => uploadDoc.current.click()}
            block
            rounded
            disabled={loading}
          >
            {loading === false && fileList.length === 0 && <span className="add-photo-icon"> <i class="fas fa-plus"></i>&nbsp;ADD IMAGE</span>}
            {loading && <div className="spinner-border spinner-border-sm fast" role="status"></div>}
          </a>
          <input
            type="file"
            hidden
            name="file"
            accept={"image/jpeg, image/png, image/gif, image/bmp, image/jfif"}
            ref={uploadDoc}
            onChange={onFileLoad}
          />
        </div>
        <div className={loading || fileList.length > 0 ? 'add-img-container' : ''}>
          <Reorder
            reorderId="uploaded-image"
            reorderGroup="reorder-group"
            component="ul"
            draggedClassName="dragged"
            lock="vertical"
            onReorder={onReorder}
            autoScroll={true}
          >
            
            {fileList.map((row, index) => (
              <div>
                <div className="image-size-in-profile">
                  <img
                    src={config.IMAGE_HOST + row.filePathThumb}
                    alt="uploaded doc"
                    className={'add-profile-img-size'}
                    onClick={() => {
                      setActiveImage(row.filePathOriginal);
                      setModalLB(true);
                    }}
                  />
                </div>
              </div>
            ))}
          </Reorder>
        </div>
        {loading === false && fileList.length === 0 && (
          <div className={'py-5 text-center red-text'}></div>
        )}

        <MDBModal isOpen={modalLB} toggle={toggleLB} size={"lg"}>
          <MDBModalHeader toggle={toggleLB} className={"border-0"}></MDBModalHeader>
          <MDBModalBody className={"mt-n4"}>
            <div style={{ textAlign: "center" }}>
              <img
                src={config.IMAGE_HOST + activeImage}
                style={{ maxWidth: "800px", maxHeight: "700px" }}
                alt={""}
              />
            </div>
          </MDBModalBody>
        </MDBModal>
      </div>
    </React.Fragment>
  );
};

export default SinglePicDirectUpload;
