import React, { useState } from "react";
import {
  MDBBox,
  MDBBtn,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBSpinner,
} from "mdbreact";
import Moments from "moment";
import UserStore from "../../../stores/userStore";
import PharmacyService from "../../../services/pharmacyService";
import { toast } from "react-toastify";

function ViewReviewFun({
  toggleReview,
  stockDetails,
  reviewModal,
  refreshPage,
}) {
  console.log("View Review");
  const [state, setState] = useState({
    stockReviewComment: "",
    loading: false,
  });

  const rejectReview = async () => {
    setState((prevState) => ({
      ...prevState,
      loading: true,
    }));

    let userData = await UserStore.fetchSessionData();
    let status = await PharmacyService.rejectStockV3(
      userData,
      stockDetails._id,
      state.stockReviewComment
    );
    setState((prevState) => ({ ...prevState, loading: false }));
    if (status === false) {
      toast.error(
        "We are facing some server issue. Please contact your system admin for further details."
      );
    } else {
      toast.success("Stock successfully rejected.");
      setState((prevState) => ({ ...prevState, stockReviewComment: "" }));
      //refresh the search
      refreshPage();
      toggleReview(null);
    }
  };
  const acceptReview = async () => {
    setState((prevState) => ({ ...prevState, loading: true }));
    let userData = await UserStore.fetchSessionData();
    let status = await PharmacyService.approveStockV3(
      userData,
      stockDetails._id,
      state.stockReviewComment
    );
    setState((prevState) => ({ ...prevState, loading: false }));
    if (status === false) {
      toast.error(
        "We are facing some server issue. Please contact your system admin for further details."
      );
    } else {
      toast.success("Stock successfully approved.");
      setState((prevState) => ({ ...prevState, stockReviewComment: "" }));
      //refresh the search
      refreshPage();
      toggleReview(null);
    }
  };

  return (
    <>
      <MDBModal
        size={"lg"}
        isOpen={reviewModal}
        toggle={() => {
          toggleReview(null);
        }}
      >
        <MDBModalHeader
          toggle={() => {
            toggleReview(null);
          }}
        >
          {stockDetails.hasOwnProperty("revieweDetails") ? (
            <>Review Details</>
          ) : (
            <>Review Checklist</>
          )}
        </MDBModalHeader>
        {stockDetails.hasOwnProperty("revieweDetails") && (
          <>
            <MDBModalBody>
              <div>Reviewed By: {stockDetails.revieweDetails.reviewedBy}</div>
              <div>
                Review Status:{" "}
                {stockDetails.revieweDetails.isApproved === "Y"
                  ? "Accepted"
                  : "Rejected"}
              </div>
              <div>
                Reviewed At:{" "}
                {Moments(stockDetails.revieweDetails.reviewedTime).format(
                  "MMM DD, YYYY"
                )}
              </div>
              <div>
                Comment:{" "}
                {stockDetails.revieweDetails.comment !== "" ? (
                  <>{stockDetails.revieweDetails.comment}</>
                ) : (
                  "No comment found."
                )}
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                onClick={() => {
                  toggleReview(null);
                }}
                disabled={state.loading}
              >
                Close
              </MDBBtn>
            </MDBModalFooter>
          </>
        )}
        {!stockDetails.hasOwnProperty("revieweDetails") && (
          <>
            {state.loading && (
              <MDBModalBody>
                <MDBSpinner />
              </MDBModalBody>
            )}
            {!state.loading && (
              <MDBModalBody>
                <MDBBox>Check if product name is right?</MDBBox>
                <MDBBox>Check if batch is right?</MDBBox>
                <MDBBox>Check if invoice no is right?</MDBBox>
                <MDBBox>Check if mfg date is right?</MDBBox>
                <MDBBox>Check if exp date is right?</MDBBox>
                <MDBBox>Check if MRP is right?</MDBBox>
                <MDBBox>Check if Purchase price is right?</MDBBox>
                <MDBBox>Check if Purchase Quantity is right?</MDBBox>
                <MDBBox>Check if Barcode is right?</MDBBox>
                <MDBBox>Check if storage information is right?</MDBBox>
                <MDBBox>
                  <br />
                  <br />
                  <MDBInput
                    value={state.stockReviewComment}
                    type={"textarea"}
                    label={"Review comment if any"}
                    getValue={(e) => {
                      setState(...state, { stockReviewComment: e });
                    }}
                  ></MDBInput>
                </MDBBox>
              </MDBModalBody>
            )}
            <MDBModalFooter>
              <MDBBtn
                onClick={() => {
                  toggleReview(null);
                }}
                disabled={state.loading}
              >
                Close
              </MDBBtn>
              <MDBBtn onClick={rejectReview} disabled={state.loading}>
                Reject
              </MDBBtn>
              <MDBBtn onClick={acceptReview} disabled={state.loading}>
                Accept
              </MDBBtn>
            </MDBModalFooter>
          </>
        )}
      </MDBModal>
    </>
  );
}

export default ViewReviewFun;
