import React,{Suspense} from "react";
import {
    MDBBox, MDBBreadcrumb, MDBBreadcrumbItem
} from 'mdbreact';
import ResetInventoryListCmp from "../../components/inventory/resetInventoryList";
import {Link} from "react-router-dom";

export default class ResetInventoryList extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Reset Stock</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <MDBBox className={"min-vh-100"}>
                    <Suspense fallback={null}>
                        <ResetInventoryListCmp/>
                    </Suspense>
                </MDBBox>
            </React.Fragment>
        );
    }
}
