import React,{Suspense} from "react";
import {Link} from "react-router-dom";
import {
    MDBBreadcrumbItem, MDBBreadcrumb, MDBRow
} from 'mdbreact';
import ViewReturnInwards from "../../components/return/viewReturnInwards";


export default class ViewReturnInwardPage extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem><span>View Return Inwards</span></MDBBreadcrumbItem>

                </MDBBreadcrumb>
                <MDBRow className={"min-vh-100"}>
                    <Suspense fallback={null}>
                        <ViewReturnInwards/>
                    </Suspense>
                </MDBRow>
            </React.Fragment>
        );
    }
}