import React from "react";
import AuthService from "../../services/authService";
import {toast} from "react-toastify";
import {Button, Table} from "antd";
import UserStore from "../../stores/userStore";
import SimpleBackdrop from "../common/overlay";
import Moments from "moment";
import {MDBBtn, MDBInput, MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader} from "mdbreact";

export default class ListCallLog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {
                    title: 'Date',
                    dataIndex: 'createdAt',
                    key: 'createdAt'
                },
                {
                    title: 'Summary',
                    dataIndex: 'summary',
                    key: 'summary'
                },
                {
                    title: 'Caller',
                    dataIndex: 'createdBy',
                    key: 'createdBy'
                },
                {
                    title: 'Operations',
                    dataIndex: 'operations',
                }
            ],
            feedbackList: [], isLoading: false,
            modalAddCallLog: false, summary: "",
            userData: {}, activeItem: null,
        }
    }

    async componentDidMount() {
        let userData = await UserStore.fetchSessionData();
        this.setState({userData})
        await this.refreshPage()
    }

    refreshPage = async () => {
        this.setState({isLoading: true})
        let res = await AuthService.fetchCustomerCallLog(this.props.activeItem._id, this.state.userData.sessionToken)
        this.setState({isLoading: false})
        if (res) {
            let feedbackList = []
            for (let item of res) {
                feedbackList.push({
                    key: item._id,
                    createdAt: Moments(item.createdAt).format("MMM Do YYYY, h:mm a"),
                    createdBy: item.createdBy.name,
                    summary: item.summary,
                    operations: <a className={"pointer-cursor text-primary"}
                                   onClick={() => this.editCustomer(item)}>Edit</a>
                })
            }
            this.setState({feedbackList})
        } else {
            toast.error("Something went wrong while fetching the customer feedback list.")
        }
    }
    editCustomer = (item) => {
        this.setState({summary: item.summary, activeItem: item})
        this.toggle("AddCallLog")
    }
    toggle = (section) => {
        this.setState({['modal' + section]: !this.state['modal' + section]})
    }
    saveSummary = async () => {
        if (this.state.summary === "") {
            toast.error("Please enter a summary of the call.")
            return false
        }
        let payload = {
            summary: this.state.summary,
            customerId: this.props.activeItem._id,
        }
        this.setState({isLoading: true})
        let res = await AuthService.addCustomerCallLog(payload, this.state.userData.sessionToken)
        this.setState({isLoading: false})
        if (res) {
            this.toggle("AddCallLog")
            this.setState({summary: ""})
            await this.refreshPage()
        } else {
            toast.error("Something went wrong while adding the customer call summary.")
        }
    }
    updateSummary = async () => {
        if (this.state.summary === "") {
            toast.error("Please enter a summary of the call.")
            return false
        }
        let payload = {
            summary: this.state.summary,
            _id: this.state.activeItem._id
        }
        this.setState({isLoading: true})
        let res = await AuthService.updateCustomerCallLog(payload, this.state.userData.sessionToken)
        this.setState({isLoading: false})
        if (res) {
            this.toggle("AddCallLog")
            this.setState({activeItem: null, summary: ""})
            await this.refreshPage()
        } else {
            toast.error("Something went wrong while updating the customer call summary.")
        }
    }

    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.isLoading}/>
                <div className={"row mb-2"}>
                    <div className={"col"}>
                        <Button type="primary" ghost onClick={() => this.toggle('AddCallLog')}>Add Summary</Button>
                    </div>
                </div>
                <div>
                    <Table columns={this.state.columns} dataSource={this.state.feedbackList}/>
                </div>
                <MDBModal isOpen={this.state.modalAddCallLog} toggle={() => this.toggle("AddCallLog")} size={"lg"}>
                    <MDBModalHeader toggle={() => this.toggle("AddCallLog")}>
                        {this.state.activeItem === null ? <>Add call summary</> : <>Edit call summary</>}
                    </MDBModalHeader>
                    <MDBModalBody>
                        <div className={"row mt-2"}>
                            <div className={"col"}>
                                <MDBInput type="textarea" label="Summary *" rows="10" outline={true}
                                          value={this.state.summary} onChange={(e) => {
                                    this.setState({summary: e.target.value})
                                }} className={'form-control rounded'} on/>
                            </div>
                        </div>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="default" outline onClick={() => this.toggle("AddCallLog")}>Close</MDBBtn>
                        {this.state.activeItem === null ?
                            <MDBBtn color="default" onClick={this.saveSummary}>Save</MDBBtn> :
                            <MDBBtn color="default" onClick={this.updateSummary}>Save</MDBBtn>
                        }
                    </MDBModalFooter>
                </MDBModal>
            </React.Fragment>
        );
    }
}