import React from "react";
import {withRouter} from "react-router-dom";
import UserStore from "../../stores/userStore";
import OrderService from "../../services/orderService";
import {toast} from "react-toastify";
import SimpleBackdrop from "../common/overlay";
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBContainer,
    MDBDataTable,
    MDBFormInline,
    MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader
} from "mdbreact";

import dataTableRacks from "./dataTableRacks";
import EditRack from "./editRack";

class ListRacks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            locId: null,
            floorId: null,
            storageLocDetails: [],
            floorDetails: [],
            progressStatus: false,
            racks: {columns: [], rows: []},
            modalEdit: false, modalDelete: false,
            activeItem: null,
            userData: {},
        }
    }

    async componentDidMount() {
        let {locId} = this.props.match.params
        let {floorId} = this.props.match.params
        this.setState({locId, floorId, progressStatus: true})
        let userData = await UserStore.fetchSessionData()
        this.setState({userData})
        let storageLocDetails = await OrderService.fetchStorageLocationById(userData.sessionToken, locId)
        if (storageLocDetails) {
            this.setState({storageLocDetails})
        } else {
            toast.error("Something went wrong while getting the storage location details.")
        }
        let floorDetails = await OrderService.fetchFloorById(userData.sessionToken, floorId)
        if (floorDetails) {
            this.setState({floorDetails})
        } else {
            toast.error("Something went wrong while getting the floor details.")
        }
        await this.fetchRacks()
        this.setState({progressStatus: false})

    }

    onListBoxes = (item) => {
        this.props.history.push('/view-boxes/' + this.state.storageLocDetails[0]._id + "/" + this.state.floorDetails[0]._id + "/" + item._id);
    }
    onEdit = (item) => {
        this.setState({activeItem: item})
        this.toggle("Edit")
    }
    onDelete = (item) => {
        this.setState({activeItem: item})
        this.toggle("Delete")
    }
    fetchRacks = async () => {
        let data = await OrderService.fetchRacks(this.state.userData.sessionToken, this.state.floorId)
        if (data) {
            let racks = {
                columns: dataTableRacks.racks,
                rows: data.map((item, index) => {
                    item["listBoxes"] =
                        <div color="primary" className={"green-text btn-link cursor-pointer"} size="sm" onClick={() => {
                            this.onListBoxes(item)
                        }}>List Boxes</div>;
                    item["edit"] =
                        <div color="primary" className={"blue-text btn-link cursor-pointer"} size="sm" onClick={() => {
                            this.onEdit(item)
                        }}>Edit</div>;
                    item["delete"] =
                        <div color="primary" className={"blue-text btn-link cursor-pointer"} size="sm" onClick={() => {
                            this.onDelete(item)
                        }}>Delete</div>;
                    ;
                    return item;
                })
            }
            this.setState({racks})
        }
    }
    refreshPageOnUpdate = async (item) => {
        this.toggle(item)
        this.setState({progressStatus: true})
        await this.fetchRacks()
        this.setState({progressStatus: false})
    }
    toggle = (item) => {
        this.setState({
            ["modal" + item]: !this.state["modal" + item]
        });
    }
    deleteRack = async () => {
        this.setState({progressStatus: true})
        let res = await OrderService.deleteRack(this.state.userData.sessionToken, this.state.activeItem._id)
        if (res) {
            toast.success("The rack deleted successfully.")
            await this.refreshPageOnUpdate("Delete")
        } else {
            toast.error("Something went wrong while deleting the rack.")
        }
        this.setState({progressStatus: false})
    }

    render() {
        const {storageLocDetails, floorDetails} = this.state
        return (
            <div>
                <React.Fragment>
                    <SimpleBackdrop status={this.state.progressStatus}/>
                    <MDBContainer className={'w-80 mw-80'}>
                        <MDBCard className={"mt-2"}>
                            {storageLocDetails.length > 0 && floorDetails.length > 0 &&
                            <MDBCardHeader>
                                <div className={'col-12 row m-0'}>
                                    <div
                                        className={'col-md-4 m-0 card text-center border rounded-bottom rounded-top z-depth-1'}>
                                        <div className={"card-header white border-bottom mdb-color lighten-5"}>
                                            <h6>Storage Location Details</h6>
                                        </div>
                                        <div>
                                            <p className={"mt-2"}><h5><strong>{storageLocDetails[0].name}</strong></h5>
                                            </p>
                                            <p className={"text-capitalize text-small"}>
                                                {storageLocDetails[0].addressLine1}{storageLocDetails[0].addressLine2 &&
                                            <span>,&nbsp;{storageLocDetails[0].addressLine2}</span>}
                                                <br/>
                                                {storageLocDetails[0].city}, {storageLocDetails[0].state}, {storageLocDetails[0].pinCode}
                                                {storageLocDetails[0].pincode}, {storageLocDetails[0].country}<br/>Phone
                                                No: {storageLocDetails[0].phone}
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className={'col-md-4 m-0 card text-center border rounded-bottom rounded-top z-depth-1'}>
                                        <div className={"card-header white border-bottom mdb-color lighten-5"}>
                                            <h6>Floor Name</h6>
                                        </div>
                                        <div>
                                            <p className={"mt-2"}><h5><strong>{floorDetails[0].floorName}</strong></h5>
                                            </p>
                                        </div>
                                    </div>
                                    <div className={'col-md-4 m-0'}>
                                        <MDBFormInline className="md-form mr-auto m-0 float-right">
                                            {this.state.locId !== "" && <>
                                                <MDBBtn color="default" size="sm" onClick={() => {
                                                    this.props.history.push("/add-racks/" + this.state.locId + "/" + this.state.floorId)
                                                }} className="mr-auto">
                                                    Add Racks
                                                </MDBBtn>
                                                <MDBBtn color="default" size="sm" outline
                                                        onClick={() => this.props.history.goBack()} className="mr-auto">
                                                    Back
                                                </MDBBtn>
                                            </>}
                                        </MDBFormInline>
                                    </div>
                                </div>
                            </MDBCardHeader>
                            }
                            <MDBCardBody>
                                {(this.state.racks.rows.length === 0 && this.state.floorDetails.length > 0) &&
                                <div className={"no-data-container"}>No racks found for the floor
                                    named: <strong>{this.state.floorDetails[0].floorName}</strong></div>
                                }
                                {this.state.racks.rows.length > 0 &&
                                <MDBDataTable
                                    striped
                                    bordered
                                    data={this.state.racks}
                                    materialSearch
                                    noBottomColumns
                                    hover
                                />
                                }
                            </MDBCardBody>
                        </MDBCard>
                    </MDBContainer>
                    <MDBModal isOpen={this.state.modalEdit} toggle={() => this.toggle("Edit")} size={"lg"}>
                        <MDBModalHeader toggle={() => this.toggle("Edit")}></MDBModalHeader>
                        <MDBModalBody className={"float-center"}>
                            {this.state.activeItem &&
                            <EditRack rackDetails={this.state.activeItem} floorDetails={this.state.floorDetails}
                                      toggleEdit={() => this.refreshPageOnUpdate("Edit")}/>
                            }
                        </MDBModalBody>
                    </MDBModal>
                    <MDBModal isOpen={this.state.modalDelete} toggle={() => this.toggle("Delete")}>
                        <MDBModalHeader toggle={() => this.toggle("Delete")}>Delete Rack</MDBModalHeader>
                        <MDBModalBody className={"float-center"}>
                            <div className={"col-12 row m-0"}>
                                <div className={"col-md-12"}>
                                    <p>Are you sure, you want to delete the rack named: </p>
                                </div>
                            </div>
                            {this.state.activeItem &&
                            <div className={"col-12 row m-0 text-center mb-3"}>
                                <div className={"col-md-12"}>
                                    <span
                                        className={"text-capitalize"}><strong>{this.state.activeItem.rackName}</strong></span>
                                </div>
                            </div>
                            }
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color="primary" outline onClick={() => this.toggle("Delete")}
                                    size={"sm"}>Cancel</MDBBtn>
                            <MDBBtn color="default" onClick={this.deleteRack} size={"sm"}>Delete</MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>
                </React.Fragment>
            </div>
        );
    }
}

export default withRouter(ListRacks);