import React from "react";
import { MDBSelect } from "mdbreact";
import PharmacyService from "../../../services/pharmacyService";
import config from "../../../config";

class Selectlocation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            locations: [],
            selectedLocation: this.props.selectedLocation || localStorage.getItem("pharmacyLocation") || config.DEFAULT_PHARMACY_LOCATION,
            key: 1,
        }
    }

    async componentDidMount() {
        if (!localStorage.getItem("pharmacyLocation")) {
            localStorage.setItem("pharmacyLocation", config.DEFAULT_PHARMACY_LOCATION);
        }
        await this.fetchStorageLocations();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.locations.length !== this.state.locations.length) {
            this.forceSelection();
        }
    }

    forceSelection = () => {
        const { selectedLocation, locations } = this.state;
        const selectedOption = locations.find(loc => loc.value === selectedLocation) || locations[0];
        if (selectedOption) {
            this.selectLocationCallback(selectedOption.value);
        }
    }

    fetchStorageLocations = async () => {
        try {
            let res = await PharmacyService.fetchStorageLocationV2();
            if (res) {
                let tempArray = [
                    {
                        text: "All",
                        value: "all",
                        checked: this.state.selectedLocation === "all"
                    }
                ];
                for (let d of res) {
                    let temp = {
                        text: d.name,
                        value: d._id,
                        checked: d._id === this.state.selectedLocation
                    }
                    tempArray.push(temp);
                }
                this.setState({ locations: tempArray, key: this.state.key + 1 }, this.forceSelection);
            }
        } catch (e) {
            console.log("Could not fetch the locations.");
        }
    }

    selectLocationCallback = (locId) => {
        if (!locId) {
            return;
        }

        if (locId === "all") {
            const allLocations = this.state.locations
                .filter(loc => loc.value !== "all")
                .map(loc => loc.value);
            localStorage.setItem("pharmacyLocation", JSON.stringify(allLocations));
            this.setState({ selectedLocation: "all" });
            this.props.selectLocationCallback(allLocations);
        } else {
            localStorage.setItem("pharmacyLocation", locId);
            this.setState({ selectedLocation: locId });
            this.props.selectLocationCallback(locId);
        }

        const updatedLocations = this.state.locations.map(loc => ({
            ...loc,
            checked: loc.value === locId
        }));
        this.setState({ locations: updatedLocations, key: this.state.key + 1 });
    }

    render() {
        return (
            <React.Fragment>
                <div className={"col-12 p-2 my-1"}>
                    <MDBSelect
                        outline={true}
                        key={this.state.key}
                        label={this.props.label && this.props.label !== "" ? this.props.label : "Select Location"}
                        getValue={(val) => this.selectLocationCallback(val[0])}
                        options={this.state.locations}
                        selected={""}
                        disabled={this.props.readOnly ? this.props.readOnly : false}
                    />
                </div>
            </React.Fragment>
        )
    }
}

export default Selectlocation;