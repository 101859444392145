import React from "react";
import { Link } from "react-router-dom";
import orgConfig from "../../orgConfig";
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavbarToggler,
  MDBCollapse,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBBtn,
} from "mdbreact";
import "./common.css";
import SubHeader from "./sub-header";
import MegaSearchBox from "./mega-search-box";
import { withRouter } from "react-router-dom";
import AuthService from "../../services/authService";
import UserStore from "../../stores/userStore";
import Autocomplete from "../../newComponents/Autocomplete/autocomp";
import TransferStockService from "../../services/transferStockService";

class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      userData: {},
      defaultData: [],
      path: window.location.pathname
    };

  }

  async componentDidMount() {
    let userData = await UserStore.fetchSessionData();

    this.setState({ userData: userData });
    this.handleSubmitForDefault("")
  }

  toggleCollapse = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
  logOut = async () => {
    let userData = await UserStore.fetchSessionData();
    AuthService.logOut(userData);
    localStorage.removeItem('role')
    this.props.history.push("/");
  };

  handleSubmitForDefault = async (name) => {
    try {
      const res = await TransferStockService.searchProductListwithName(this.state.userData.sessionToken, name)
      this.setState({ defaultData: res })

    }
    catch (err) {
      console.log({ err })
    }
  }

  handleSLookupSearch = async (name) => {
    try {
      if (name?.length < 3) {
        this.handleSubmitForDefault("")
        return
      }
      // const res = await TransferStockService.searchProductListwithName(this.state.userData.sessionToken, name)

      // const res = await TransferStockService.searchProductListwithId(this.state.userData.sessionToken, name)
      // this.setState({ defaultData: res })

      const res2 = await TransferStockService.searchProductListwithIdV2(name)
      this.setState({ defaultData: res2?.data })

    }
    catch (err) {
      console.log({ err })
    }
  }
  handleRouting = (val, item) => {
    if (item?.id || item?._id) {
      this.handleSubmitForDefault("")
      this.props.history.push(`/product-searches/${item?.id ?? item?._id}`)
    }
    else this.props.history.push(`/dashboard`)
  }

  render() {
    return (
      <div className={"col-12 p-0"}>
        <MDBNavbar color="white" light expand="md" className={"border-bottom"}>
          <MDBNavbarBrand>
            <Link to={"/dashboard"}>
              <img alt={"Logo"} src={"/favicon.png"} width={30} height={30} />
            </Link>
          </MDBNavbarBrand>
          {/*<div className={'col header-search-container'}>*/}
          {/*    <MegaSearchBox></MegaSearchBox>*/}
          {/*</div>*/}
          <div className={"nav-menu"}>
            <a className={"mx-3"} href={"/view-online-orders"}>
              Order List
            </a>
            <a className={"mx-3"} href={"/add-stock"}>
              Add Stock
            </a>
            {/* <a className={"mx-3"} href={"/fetch-stock"}>
              Check Stock
            </a> */}
            <a className={"mx-3"} href={"/fetch-stock"}>
              Check Stock
            </a>
            <Link className={"mx-3"} to={`/search-customers`}>
              Search Customer
            </Link>
            <Link className={"mx-3"} to={`/search-stock-new/:page?`}>
            Search Stock
            </Link>
          </div>
          <div>
            <Autocomplete
              label="Enter Product Name"
              name="recipe"
              options={this.state.defaultData}
              onSelect={(val, item) => this.handleRouting(val, item)}
              onChange={(val) => { this.handleSLookupSearch(val) }} />
          </div>
          <MDBNavbarNav right>
            <MDBNavItem className={"mr-3"}>
              <MDBDropdown>
                <MDBDropdownToggle nav caret>
                  {this.state.userData.type === "platformAdmin" && (
                    <span>
                      {orgConfig.ORG_NAME_LABEL}
                      <br />
                      <b>Admin</b>
                    </span>
                  )}
                  {this.state.userData.type === "ORG_ADMIN" && (
                    <span>
                      {this.state.userData.orgDetails.length > 0 && (
                        <small>
                          {this.state.userData.orgDetails[0].orgName}
                          <br />
                        </small>
                      )}
                      <b>{this.state.userData.name}</b>
                    </span>
                  )}
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                  <MDBDropdownItem>
                    <Link to={"/change-password"}>Change Password</Link>
                  </MDBDropdownItem>
                  <MDBDropdownItem onClick={this.logOut}>
                    <Link to={""}>Sign Out</Link>
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavItem>
          </MDBNavbarNav>
        </MDBNavbar>
        <SubHeader></SubHeader>
      </div>
    );
  }
}

export default withRouter(Header);
