import React,{Suspense} from "react";
import {Link} from "react-router-dom";
import {
    MDBBreadcrumbItem,
    MDBBreadcrumb,
} from 'mdbreact';
//const ProductListCmp=React.lazy(() => import("../../components/product/manageProductCmp"));
import ProductListCmp from "../../components/product/manageProductCmp";
// import ProductListCmpNew from "../../components/product/manageProductCmpNew";

export default class ManageProduct extends React.Component {
    render() {
        return (
            <React.Fragment>
                     <MDBBreadcrumb>
                         <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                         <MDBBreadcrumbItem active>View Products</MDBBreadcrumbItem>
                     </MDBBreadcrumb>
                <Suspense fallback={null}>
                    <ProductListCmp/>
                </Suspense>
            </React.Fragment>
        );
    }
}
