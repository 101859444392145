import orgConfig from "../../orgConfig";

const dataTableCustomerInsights= {
    mostFrequentProducts:[
        {
            label: 'Product Name',
            field: 'handleProductName',
            sort: 'asc',
            width: 300
        },
        {
            label: 'Order Frequency',
            field: 'saleCount',
            sort:'asc',
            width: 50
        },
        {
            label: 'Total Amt',
            field: 'totalSaleAmount',
            sort:'asc',
            width: 50
        },
        {
            label: 'Total Qty',
            field: 'totalSaleQuantity',
            sort:'asc',
            width: 50
        }
    ],
    mostFrequentCustomers:[
        {
            label: 'Customer Name',
            field: 'handleCustomerName',
            sort: 'asc',
            width: 300
        },
        {
            label: 'Contact Number',
            field: 'handleContactNumber',
            sort: 'asc',
            width: 100
        },
        {
            label: 'Number of Orders',
            field: 'count',
            sort:'asc',
            width: 50
        },
    ],
    topCustomers:[
        {
            label: 'Customer Name',
            field: 'handleCustomerName',
            sort: 'asc',
            width: 300
        },
        {
            label: 'Contact Number',
            field: 'handleContactNumber',
            sort: 'asc',
            width: 100
        },
        {
            label: 'Total Purchase('+orgConfig.CURRENCY_SYMBOL+")",
            field: 'totalPurchase',
            sort:'asc',
            width: 50
        },
    ],
    orderHistory:[
        {
            label: 'Order Date',
            field: 'handleOrderDate',
            sort: 'asc',
            width: 300
        },

        {
            label: 'Order #',
            field: 'orderCode',
            sort: 'asc',
            width: 300
        },

        {
            label: 'Total Items',
            field: 'totalItems',
            sort: 'asc',
            width: 100
        },
        {
            label: 'Outstanding('+orgConfig.CURRENCY_SYMBOL+")",
            field: 'outstandingAmount',
            sort: 'asc',
            width: 100
        },
        {
            label: 'Type',
            field: 'orderMode',
            sort: 'asc',
            width: 100
        },
        // {
        //     label: 'Status',
        //     field: 'orderStatus',
        //     sort: 'asc',
        //     width: 100
        // },

        {
            label: 'Payment Mode',
            field: 'paymentMode',
            sort:'disabled',
            width: 50
        },
        // {
        //     label: 'Base Amount',
        //     field:'baseAmount',
        //     sort:'disabled',
        //     width: 50
        // },


        {
            label: 'Total('+orgConfig.CURRENCY_SYMBOL+")",
            field:'totalAmountFormated',
            sort:'asc',
            width: 50
        }
    ],
}
export default dataTableCustomerInsights;