import React from "react";
import {withRouter} from "react-router-dom";
import {
    MDBContainer, MDBRow, MDBInput, MDBBtn, MDBCardHeader, MDBFormInline, MDBCardBody, MDBCard, MDBCol
} from 'mdbreact';
import "./category.css";
import {toast} from "react-toastify";
import InventoryService from "../../services/inventoryService";
import UserStore from "../../stores/userStore";
import FileUpload from "../common/fileUpload/fileUpload";
import SimpleBackdrop from "../common/overlay";

class AddCategoryCmp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryName:'',
            progressStatus:false,
            progressStatusFileUpload:false,
            categoryNameError:false,
            imageUrl:{},
            resetForm:false,
        }
    }
    categoryChange=(val)=>{
        this.setState({categoryName:val});
    }
    addCategory=async ()=>{
        window.scrollTo(0, 0)
        let userData=await UserStore.fetchSessionData();
        //validations
        if(this.state.categoryName==='' || this.state.categoryName===null){
            this.setState({categoryNameError:true});
            return true;
        } else {
            this.setState({categoryNameError:false});
        }
        this.setState({progressStatus:true});
        let status=await InventoryService.addCategory(this.state,userData);
        this.setState({progressStatus:false});
        if(status===false){
            toast.error("Category already exists.");
        }else{
            this.setState({categoryName:""});
            toast.success("Category added successfully.");
            this.setState({resetForm:true})
        }
    }
    validateCategory=()=>{
        if(this.state.categoryName === ""){
            this.setState({categoryNameError:true});
        } else {
            this.setState({categoryNameError:false});
        }
    }
    fileUploadCallback = (uploadedFileObject) => {
        this.setState({ "imageUrl": uploadedFileObject.path, "progressStatusFileUpload":false})
    }
    initUploadCallback = () => {
        this.setState({"progressStatusFileUpload":true, resetForm:false})
    }
    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.progressStatus}/>
                <MDBContainer>
                    <MDBCard className={"mt-2"}>
                        <MDBCardHeader>
                            <div className={"float-left title"}>Add Category</div>
                        </MDBCardHeader>
                        <MDBCardBody>
                            <MDBRow className="d-flex justify-content-center">
                                <MDBCol>
                                    <MDBInput label="Category name" onBlur={this.validateCategory} className={this.state.categoryNameError ? "border-danger" :""} getValue={this.categoryChange} value={this.state.categoryName}/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <FileUpload buttonLabel={"Add Category Image"} initUploadCallback={this.initUploadCallback} fileUploadCallback={this.fileUploadCallback}  nextProps={this.state.resetForm}></FileUpload>
                                </MDBCol>
                            </MDBRow>
                            <div className="m-0 text-center">
                                <MDBBtn outline  size="md" onClick={() => this.props.history.push('/view-product-categories')} className="mr-auto" disabled={this.state.progressStatus}>
                                    Back
                                </MDBBtn>
                                <MDBBtn size="md"  onClick={this.addCategory} className="mr-auto"
                                        disabled={this.state.progressStatus || this.state.progressStatusFileUpload}>
                                    Save
                                </MDBBtn>
                            </div>
                        </MDBCardBody>
                    </MDBCard>
                </MDBContainer>
            </React.Fragment>
        );
    }
}
export default withRouter(AddCategoryCmp);


