import React, { useEffect, useRef, useState } from "react";
import {
  Document,
  Page,
  PDFViewer,
  Image,
  StyleSheet,
  Text,
  View,
  Font,
} from "@react-pdf/renderer";
import Barcode from "react-barcode";
import utility from "../../utility/utility";
import positraLogo from "../../images/positrarx_logo.png";
import ecommLogo from "../../images/ecom_logo.png";
import robotoRegular from "../../fonts/Roboto/Roboto-Regular.ttf";
import robotoBold from "../../fonts/Roboto/Roboto-Bold.ttf";
import Moments from "moment";
import right_arrow from "../../images/right_arrow.png"

Font.register({
  family: "Roboto",
  fonts: [
    { src: robotoRegular, fontWeight: "normal" },
    { src: robotoBold, fontWeight: "bold" },
  ],
});

const styles = StyleSheet.create({
  pdfViewer: {
    width: "100%",
    height: "625px",
  },
  page: {
    padding: "3px",
  },
  container: {
    border: 1,
    height: "100%",
  },
  topHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    // alignItems:'center',
    marginBottom: "2px",
    padding: "7px",
  },
  positraLogo: {
    width: "80px",
    objectFit: "contain",
  },
  main_header: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    textAlign: "center",
    paddingBottom: "4px",
    fontSize: 13,
  },

  section: {
    borderBottom: 1,
    borderTop: 1,
    paddingHorizontal: "7px",
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'

  },
  sectionWithoutBorder: {
    marginBottom: "5px",
    paddingHorizontal: "7px",
  },
  normalText: {
    fontFamily: "Roboto",
    fontWeight: "normal",
    fontSize: 9,
    marginVertical: 2,
  },
  bolderText: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 9,
    marginVertical: 2,
  },
  smallText: {
    fontFamily: "Roboto",
    fontWeight: "normal",
    fontSize: 9,
    marginVertical: 1,
  },
  smallerText: {
    fontFamily: "Roboto",
    fontWeight: "normal",
    fontSize: 6,
    marginVertical: 1,
  },
  biggerText: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 10,
    marginVertical: 2,
  },
  tableContainer: {
    paddingHorizontal: "7px",
    paddingVertical: "5px",
  },
  table: {
    width: "100%",
    fontSize: 9,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    borderTop: "1px solid #EEE",
  },
  header: {
    borderTop: "none",
  },
  bold: {
    fontWeight: "bold",
  },
  tableHeader: {
    borderTop: 1,
    borderBottom: 1,
    fontFamily: "Roboto",
    fontWeight: "bold",
  },
  tableData: {
    borderBottom: 1,
  },
  col1: {
    width: "40%",
    textAlign: "center",
    borderRight: 1,
    paddingTop: 2,
    paddingBottom: 2,
    borderLeft: 1,
  },
  col2: {
    width: "15%",
    textAlign: "center",
    borderRight: 1,
    paddingTop: 2,
    paddingBottom: 2,
  },
  col3: {
    width: "15%",
    textAlign: "center",
    borderRight: 1,
    paddingTop: 2,
    paddingBottom: 2,
  },
  col4: {
    width: "15%",
    textAlign: "center",
    borderRight: 1,
    paddingTop: 2,
    paddingBottom: 2,
  },
  col5: {
    width: "15%",
    textAlign: "center",
    borderRight: 1,
    paddingTop: 2,
    paddingBottom: 2,
  },
  processLog_container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems:'center',
    padding: "7px",
    flexWrap: 'wrap'

  },
  processLog_item: {
    paddingHorizontal: '2px',
    display: 'flex',
    flexDirection: 'column',
    alignItems:"center"
  }
});

const PrintOrderSlip = ({ data }) => {
  return (
    <>
      <PDFViewer style={styles.pdfViewer}>
        <Document>
          <Page wrap size="A5" style={styles.page}>
            <View style={styles.container}>
              <View style={styles.topHeader}>
                <Image
                  source={positraLogo}
                  style={styles.positraLogo}
                  resizeMode="contain"
                />
                <Text style={styles.biggerText}>{(data?.deliveryType === "DELIVER" && "Local Delivery") || (data?.deliveryType === "SHIP" && "Outside Guwahati Shipment")}</Text>
              </View>
              <Text style={styles.main_header}>ORDER SLIP</Text>
              <Text style={[styles.smallText, {paddingLeft:'7px'}]}>Estimated Delivery/Dispatch Date:</Text>
              <View style={styles.section}>
                <View style={{paddingRight: '6px', flex: 1}}>
                  <Text style={styles.normalText}>
                    <Text style={styles.bolderText}>Order ID: </Text>
                    {data?.orderCode}
                  </Text>
                  <Text style={styles.normalText}>
                    <Text style={styles.bolderText}>Order Date: </Text>
                    {Moments(data?.createdAt).format('lll')}
                  </Text>
                </View>
                <View style={{borderLeft: 1, height:'100%',paddingLeft: '6px', flex: 1}}>
                  <Text style={styles.bolderText}>{data?.shippingAddress?.name}</Text>
                  <Text style={styles.smallText}>
                    {data?.shippingAddress?.addressLine1},{" "}
                    {data?.shippingAddress?.addressLine2}
                  </Text>
                  <Text style={styles.smallText}>
                    {data?.shippingAddress?.city},{" "}
                    {data?.shippingAddress?.pinCode} -{" "}
                    {data?.shippingAddress?.state}
                  </Text>
                  <Text style={styles.smallText}>
                    Phone Number: {data?.shippingAddress?.phone}
                  </Text>
                </View>
              </View>

              {data?.processLog?.length>0 && 
                <View>
                <Text style={[styles.bolderText, {textAlign: 'center', paddingTop: '7px'}]}>Order Logs </Text>
                <View style={styles.processLog_container}>
                  {data?.processLog?.map((item,index)=> (
                    <>
                    <View style={styles.processLog_item} key={index}>
                      <Text style={styles.smallerText}>{item?.status}</Text>
                      <Text style={styles.smallerText}>{item?.createdBy?.name}</Text>
                      <Text style={styles.smallerText}> {Moments(item?.createdAt).format('ll')}</Text>
                    </View>
                    {index < data?.processLog?.length - 1 && (
                      <Image
                        style={{width: '12px'}}
                        src={right_arrow} 
                      />
                    )}
                    </>
                  ))}
                </View>
              </View>
              }
              
              {data?.cartData?.length > 0 && 
                 <View style={styles.tableContainer}>
                 <View style={styles.table}>
                   <View
                     style={[
                       styles.row,
                       styles.bold,
                       styles.header,
                       styles.tableHeader,
                     ]}
                   >
                     <Text style={styles.col1}>Product</Text>
                     <Text style={styles.col2}>Type</Text>
                     <Text style={styles.col3}>MRP ₹</Text>
                     <Text style={styles.col4}>Qty Ordered</Text>
                     <Text style={styles.col5}>Qty Short</Text>
                   </View>
 
                   {data?.cartData?.map((item) => (
                     <View
                       style={[styles.row, styles.tableData]}
                       wrap={false}
                       key={item?._id}
                     >
                       <Text style={styles.col1}>
                         <Text style={styles.bold}>{item?.productName}</Text>
                       </Text>
                       <Text style={styles.col2}>{item?.medicineType}</Text>
                       <Text style={styles.col3}>{item?.maxRetailPrice}</Text>
                       <Text style={styles.col4}>{item?.quantity}</Text>
                       <Text style={styles.col5}></Text>
                     </View>
                   ))}
                 </View>
               </View>
              
              
              }
             
              
              <View style={styles.sectionWithoutBorder}>
                <Text style={styles.bolderText}>Internal Comment: </Text>
                <Text style={styles.normalText}>{data?.internalComment}</Text>
              </View>
             
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </>
  );
};

export default PrintOrderSlip;
