import React, { Suspense } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { ToastContainer, Zoom } from "react-toastify";
import orgConfig from "./orgConfig";
import "./App.css";
import PrivateRoute from "./components/PrivateRoute";

import Footer from "./components/common/footer";
import Header from "./components/common/header";
import AddCategory from "./pages/categories/addCategories";
import ManageCategory from "./pages/categories/manageCategory";
import Suppliers from "./pages/supplier/index";
import NewSupplier from "./pages/supplier/newSupplierPage";
import EditSupplier from "./components/supplier/editSupplier";
import EditCategory from "./pages/categories/editCategory";
import AddRetailPackage from "./components/retail-package/addRetailPackage";
import EditRetailPackage from "./components/retail-package/editRetailPackage";
import ListRetailPackage from "./pages/retail-package/listRetailPackage";
import AddQuantityUnit from "./components/quantity-unit/addQuantityUnit";
import EditQuantityUnit from "./pages/quantity-unit/editQuantityUnit";
import ListQuantityUnit from "./pages/quantity-unit/listQuantityUnit";
import AddUser from "./components/users/addUser";
import EditUser from "./pages/user/updateUser";
import ListUsers from "./pages/user/listUserPage";
import EditManufacturer from "./pages/manufacturer/updateManufacturer";
import AddManufacturer from "./pages/manufacturer/addManufacturer";
import OrgUserListPage from "./pages/org-user/listOrgUserPage";
import EditOrgUser from "./pages/org-user/updateOrgUser";
import AddOrgUser from "./pages/org-user/addOrgUserPage";
import AddShipment from "./pages/shipments/addShipmentsPage";
import EditShipment from "./pages/shipments/updateShipmentsPage";
import ListShipments from "./pages/shipments/listShipmentsPage";
import AddInventory from "./pages/inventory/addInventoryPage";
import EditInventory from "./pages/inventory/updateInventoryPage";
import ListInventory from "./pages/inventory/listInventoryPage";
import AddProductRequestPage from "./pages/product-request/addProductRequest";
import EditProductRequestPage from "./pages/product-request/editProductRequest";
import ListProductRequestPage from "./pages/product-request/listProductRequest";
import Cms from "./pages/product/cms";
import FulfilOrder from "./pages/fulfil-order/index";
import FulfilOfflineOrder from "./pages/fulfil-order/fulfilOfflineOrder";
import FulfilOrderDetail from "./pages/fulfil-order/detail";
import ManageProduct from "./pages/product/manageProduct";
import AddProduct from "./pages/product/addProduct";
import Dashboard from "./pages/dashboard/dashboard";
import Login from "./pages/login/Login";
import ManufacturerListPage from "./pages/manufacturer/listManufacturerPage";
import EditProduct from "./pages/product/editPkgDetails";
import AddCustomerPage from "./pages/customer/addCustomer";
import ListCustomerPage from "./pages/customer/listCustomer";
import CustomerDetailPage from "./pages/customer/updateCustomer";
import ListOnlineOrdersPage from "./pages/order-history/listOnlineOrders";
import SubOrderDetailsPage from "./pages/order-history/subOrderDetail";
import ListInPersonOrdersPage from "./pages/order-history/listInPersonOrdersPage";
import AllStock from "./pages/inventory/allStock";
import ChangePassword from "./pages/user/changePassword";
import ProductSearch from "./pages/product/productSearch";
import AddDoctor from "./pages/doctor/addDoctor";
import ListDoctor from "./pages/doctor/listDoctors";
import EditDoctor from "./pages/doctor/editDoctor";
import ListSubOrdersPage from "./pages/order-history/listSubOrders";
import ListOnlinePrescriptionOrderPage from "./pages/online-prescription-order/listPrescriptionOrder";
import ProcessPrescriptionOrder from "./pages/online-prescription-order/processPrescriptionOrder";
import ListMasterSpecialityPage from "./pages/speciality/listMasterSpeciality";
import AddSpecialityGroupPage from "./pages/speciality/addSupplierGroup";
import ListSpecialityPage from "./pages/speciality/listSpeciality";
import AddSpecialityPage from "./pages/speciality/addListSpeciality";
import EditSpecialityGroup from "./components/specialityGroup/editSpecialityGroup";
import EditSpecialityGroupPage from "./pages/speciality/editSpecialityGroupPage";
import CreateReturnInwardPage from "./pages/return/createReturnInwardPage";
import ViewReturnInwardPage from "./pages/return/viewReturnInwardsPage";
import DownloadReports from "./pages/report/downloadReports";
import SalesIntelligence from "./pages/report/salesIntelligence";
import CustomerInsightsPage from "./pages/report/customerInsightsPage";
import ListProducts from "./pages/report/listProducts";
import DuplicateProducts from "./pages/report/duplicateProducts";
import DeleteHistory from "./pages/report/deleteHistory";
import ProductDetailsPage from "./pages/report/productDetails";
import ListOnlineOrders from "./pages/online-order/listOnlineOrders";
import ProcessOnlineOrders from "./pages/online-order/processOnlineOrders";
import CleanDataPage from "./pages/report/cleanData";
import AddPurchaseNote from "./pages/product-request/addPurchaseNote";
import ListPurchaseNote from "./pages/product-request/listPurchaseNote";
import ListStorageLocation from "./pages/storageLocation/listStorageLocation";
import AddStorageLocation from "./pages/storageLocation/addStorageLocation";
import ListFloors from "./pages/floors/listFloors";
import AddFloor from "./pages/floors/addFloor";
import ListRacks from "./pages/racks/listRacks";
import AddRack from "./pages/racks/addRack";
import ListBoxes from "./pages/boxes/listBoxes";
import AddBox from "./pages/boxes/addBox";
import AddProduct_new from "./pages/product/addProduct_new";
import Coupons from "./pages/coupons";
import Razorpay from "./pages/payments/razorpay";
import Substitute from "./pages/substitute/substitute";
//v2 routes
import AddProductV2 from "./pages/productsV2/addProductV2";
import SearchProductV3 from "./pages/inventory/v3/searchProduct";
import AllStockV2 from "./pages/inventory/showAllInv";
import ResetInventoryList from "./pages/inventory/resetInventoryList";
import ExpiredProducts from "./pages/inventory/v3/expiredProducts";
import ManageProductNew from "./pages/product/manageProductNew";
import BannedProducts from "./pages/product/bannedMedicinePage";
import AddVariant from "./pages/product/addVariant";
import EditProduct_New from "./pages/product/editProduct_new";
import PaymentRoute from "./pages/payments/paymentRoute";
import TaskList from "./pages/task-manager/taskList";
import AddTask from "./pages/task-manager/addTask";
import SearchProduct from "./pages/product/searchProduct";
import AddGroup from "./pages/productGrouping/addGroup";
import ListProductGroup from "./pages/productGrouping/listProductGroup";
import FeedbackList from "./pages/feedback/feedbackList";
import CreditReport from "./pages/report/v2/creditReport";
import ListProductsByCategory from "./pages/productGrouping/listProductsByCategory";

import OrderListV2 from "./components/v2/orders/listOrder";
import AddProductToCategory from "./pages/productGrouping/addProductToCategory";
import ProductSalesAnalysis from "./pages/report/v2/productSalesAnalysis";
import ViewCustomer from "./pages/customer/viewCustomer";
import CmsBanner from "./pages/cms/cmsBanner";
import NewOnlineOrder from "./pages/online-order/newOnlineOrder";
import ListSubscription from "./pages/subscription/listSubscription";
import RequestReport from "./pages/report/v2/requestReport";
import ShippingPerformance from "./components/ThirdPartyIntegration/shippingPerformance";
import ViewSubstitute from "./pages/substitute/viewSubstitute";
import mobileBanner from "./pages/cms/mobileBanner";
import OutOfStock from "./pages/out-of-stoct-list/outOfStock";
import CustomerDetailViewmorePage from "./pages/customer/CustomerDetailViewmore";
import SearchCustomerPage from "./pages/customer/searchCustomer";
import TrackStock from "./pages/inventory/trackStock";
import TransferStockPage from "./components/inventory/v3/transferStockPage";
import ReceiveStockPage from "./pages/receive-stock/receiveStockPage";
// import FetchStocks from "./pages/inventory/fetchStocks";
import EditStock from "./pages/inventory/editStock";
import AddStock from "./components/v3/addStock";
import ProductInfo from "./pages/inventory/productDetails";
import ReportList from "./components/reports/reportlist";
import CreateReport from "./components/reports/createReport/createReport";
import ReportScheduleList from "./components/reportSchedule/reportSchedule";
import JobHistory from "./components/reports/jobHistory/jobHistory";
import JobsCreation from "./components/reports/jobHistory/jobCreation";
import ProductSearching from "./pages/inventory/productSearch";
import ListOnlineOrderDetails from "./components/online-order/ListOnlineOrderDetails";
import UnfulfilledOrders from "./pages/UnfulfilledOrders";
import RelocateRack from "./pages/RelocateRack";
import RelocateRackByBar from "./pages/RelocateRackByBar";
import FetchStocksNew from "./pages/inventory/newFetchStocks";
import SearchStockComponent from "./components/inventory/v3/newSearchStocks";
import TempTransfer from "./pages/temp";
class App extends React.Component {
  render() {
    return (
      <React.Fragment>
        {this.props.location.pathname !== "/" && (
          <Suspense fallback={null}>
            <Header />
          </Suspense>
        )}
        <div
          className={
            "col-12 px-0 pb-5 min-vh-100 min-content-width grey lighten-5"
          }
        >
          <Suspense fallback={null}>
            <Switch>
            {orgConfig.LEVEL1_MENUS.indexOf("DOCTORS") >= 0 && (
                <PrivateRoute path="/transfers" component={TempTransfer} />
              )}

              {orgConfig.LEVEL1_MENUS.indexOf("DOCTORS") >= 0 && (
                <PrivateRoute
                  path="/edit-doctor/:doctorId"
                  component={EditDoctor}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("DOCTORS") >= 0 && (
                <PrivateRoute path="/view-doctors" component={ListDoctor} />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("DOCTORS") >= 0 && (
                <PrivateRoute path="/add-doctor" component={AddDoctor} />
              )}

              <PrivateRoute
                path="/change-password"
                component={ChangePassword}
              />

              {/*<PrivateRoute path="/inventory-no-quantity" component={zeroStock}/>*/}
              {/*<PrivateRoute path="/inventory-exp" component={expStock}/>*/}

              {/*{orgConfig.LEVEL1_MENUS.indexOf("ORDERS") >= 0 && <PrivateRoute path="/fulfil-order" component={FulfilOrder}/>}*/}
              {orgConfig.LEVEL1_MENUS.indexOf("ORDERS") >= 0 && (
                <PrivateRoute
                  path="/fulfil-order-detail/:id"
                  component={FulfilOfflineOrder}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("ORDERS") >= 0 && (
                <PrivateRoute
                  path="/fulfil-offline-order"
                  component={FulfilOfflineOrder}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("ORDERS") >= 0 && (
                <PrivateRoute
                  path="/view-in-person-orders"
                  component={ListInPersonOrdersPage}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("ORDERS") >= 0 && (
                <PrivateRoute
                  path="/online-orders"
                  component={ListOnlineOrdersPage}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("ORDERS") >= 0 && (
                <PrivateRoute
                  path="/sub-orders"
                  component={ListSubOrdersPage}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("ORDERS") >= 0 && (
                <PrivateRoute
                  path="/order/:orderId"
                  component={SubOrderDetailsPage}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("ORDERS") >= 0 && (
                <PrivateRoute path="/feedback" component={FeedbackList} />
              )}

              {orgConfig.LEVEL1_MENUS.indexOf("ORDERS") >= 0 && (
                <PrivateRoute
                  path="/view-return-inwards"
                  component={ViewReturnInwardPage}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("ORDERS") >= 0 && (
                <PrivateRoute
                  path="/create-return-inward/:id"
                  component={CreateReturnInwardPage}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("ORDERS") >= 0 && (
                <PrivateRoute
                  path="/create-return-inward"
                  component={CreateReturnInwardPage}
                />
              )}

              {orgConfig.LEVEL1_MENUS.indexOf("ORDERS") >= 0 && (
                <PrivateRoute
                  path="/view-subscriptions"
                  component={ListSubscription}
                />
              )}

              {orgConfig.LEVEL1_MENUS.indexOf("SUPPLIERS") >= 0 && (
                <PrivateRoute path="/view-suppliers" component={Suppliers} />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("SUPPLIERS") >= 0 && (
                <PrivateRoute path="/add-supplier" component={NewSupplier} />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("SUPPLIERS") >= 0 && (
                <PrivateRoute
                  path="/edit-supplier/:supplierId"
                  component={EditSupplier}
                />
              )}

              {orgConfig.LEVEL1_MENUS.indexOf("MANUFACTURERS") >= 0 && (
                <PrivateRoute
                  path="/view-manufacturers"
                  component={ManufacturerListPage}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("MANUFACTURERS") >= 0 && (
                <PrivateRoute
                  path="/edit-manufacturer/:manufacturerId"
                  component={EditManufacturer}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("MANUFACTURERS") >= 0 && (
                <PrivateRoute
                  path="/add-manufacturer"
                  component={AddManufacturer}
                />
              )}

              {orgConfig.LEVEL1_MENUS.indexOf("ORGANIZATIONS") >= 0 && (
                <PrivateRoute
                  path="/organizations"
                  component={OrgUserListPage}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("ORGANIZATIONS") >= 0 && (
                <PrivateRoute
                  path="/edit-organization/:userId"
                  component={EditOrgUser}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("ORGANIZATIONS") >= 0 && (
                <PrivateRoute
                  path="/create-organization"
                  component={AddOrgUser}
                />
              )}

              {orgConfig.LEVEL1_MENUS.indexOf("PHARMACY_USERS") >= 0 && (
                <PrivateRoute
                  path="/view-pharmacy-users/:orgId"
                  component={ListUsers}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("PHARMACY_USERS") >= 0 && (
                <PrivateRoute
                  path="/edit-pharmacy-user/:orgId/:userId/"
                  component={EditUser}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("PHARMACY_USERS") >= 0 && (
                <PrivateRoute path="/view-pharmacy-user" component={AddUser} />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("PHARMACY_USERS") >= 0 && (
                <PrivateRoute
                  path="/add-pharmacy-user/:orgId"
                  component={AddUser}
                />
              )}

              {orgConfig.LEVEL1_MENUS.indexOf("SHIPMENTS") > 0 && (
                <PrivateRoute
                  path="/view-incoming-shipments"
                  component={ListShipments}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("SHIPMENTS") > 0 && (
                <PrivateRoute
                  path="/edit-incoming-shipment/:shipmentId"
                  component={EditShipment}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("SHIPMENTS") > 0 && (
                <PrivateRoute
                  path="/add-incoming-shipment"
                  component={AddShipment}
                />
              )}

              {/*{(orgConfig.LEVEL1_MENUS.indexOf("STOCK") >= 0) && <PrivateRoute path="/search-all-stock" component={ListInventory}/> }*/}
              {orgConfig.LEVEL1_MENUS.indexOf("STOCK") >= 0 && (
                <PrivateRoute
                  path="/edit-stock/:inventoryId"
                  component={EditInventory}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("STOCK") >= 0 && (
                <PrivateRoute
                  path="/reset-stock"
                  component={ResetInventoryList}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("STOCK") >= 0 && (
                <PrivateRoute path="/add-stock" component={AddStock} />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("STOCK") >= 0 && (
                <PrivateRoute path="/view-stock" component={AllStock} />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("STOCK") >= 0 && (
                <PrivateRoute path="/track-stock" component={TrackStock} />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("STOCK") >= 0 && (
                <PrivateRoute path="/product-details" component={ProductInfo} />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("STOCK") >= 0 && (
                <PrivateRoute path="/relocate-rack" component={RelocateRack} />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("STOCK") >= 0 && (
                <PrivateRoute path="/relocate-rack-by-barcode" component={RelocateRackByBar} />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("STOCK") >= 0 && (
                <PrivateRoute
                  path="/product-searches/:name"
                  component={ProductSearching}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("STOCK") >= 0 && (
                <PrivateRoute
                  path="/transfer-stock"
                  component={TransferStockPage}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("STOCK") >= 0 && (
                //  <PrivateRoute path="/edit-stock" component={EditStock} />
                <PrivateRoute
                  path="/edit-stocks/:productId"
                  component={EditStock}
                />
              )}
              {/* {orgConfig.LEVEL1_MENUS.indexOf("STOCK") >= 0 && (
                <PrivateRoute path="/fetch-stock" component={FetchStocks} />
              )} */}
              {orgConfig.LEVEL1_MENUS.indexOf("STOCK") >= 0 && (
                <PrivateRoute path="/fetch-stock" component={FetchStocksNew} />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("PURCHASE_REQUESTS") >= 0 && (
                <PrivateRoute
                  path="/view-purchase-requests"
                  component={ListProductRequestPage}
                />
              )}
               {orgConfig.LEVEL1_MENUS.indexOf("STOCK") >= 0 && (
                <PrivateRoute path="/search-stock-new/:page?" component={SearchStockComponent} />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("PURCHASE_REQUESTS") >= 0 && (
                <PrivateRoute
                  path="/edit-purchase-request/:productRequestId"
                  component={EditProductRequestPage}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("PURCHASE_REQUESTS") >= 0 && (
                <PrivateRoute
                  path="/add-purchase-request"
                  component={AddProductRequestPage}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("PURCHASE_REQUESTS") >= 0 && (
                <PrivateRoute
                  path="/add-purchase-note"
                  component={AddPurchaseNote}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("PURCHASE_REQUESTS") >= 0 && (
                <PrivateRoute
                  path="/view-purchase-notes"
                  component={ListPurchaseNote}
                />
              )}

              {orgConfig.LEVEL1_MENUS.indexOf("CUSTOMERS") >= 0 && (
                <PrivateRoute
                  path="/add-customer"
                  component={AddCustomerPage}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("CUSTOMERS") >= 0 && (
                <PrivateRoute
                  path="/edit-customer/:customerId"
                  component={CustomerDetailPage}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("CUSTOMERS") >= 0 && (
                <PrivateRoute
                  path="/view-customers/:customerId"
                  component={ListCustomerPage}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("CUSTOMERS") >= 0 && (
                <PrivateRoute
                  path="/view-customers"
                  component={ListCustomerPage}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("CUSTOMERS") >= 0 && (
                <PrivateRoute
                  path="/search-customers"
                  component={SearchCustomerPage}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("CUSTOMERS") >= 0 && (
                <PrivateRoute
                  path="/view-call-summary"
                  component={ViewCustomer}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("CUSTOMERS") >= 0 && (
                <PrivateRoute
                  path="/customer-details-viewMore/:userId/:userType/:phone"
                  component={CustomerDetailViewmorePage}
                />
              )}

              {orgConfig.LEVEL1_MENUS.indexOf("TASK_MANAGER") >= 0 && (
                <PrivateRoute path="/task-list" component={TaskList} />
              )}

              {orgConfig.LEVEL1_MENUS.indexOf("TASK_MANAGER") >= 0 && (
                <PrivateRoute path="/add-task" component={AddTask} />
              )}

              {/*<Route path="/register" component={Register} />*/}
              {/*<Route path="/editor/:slug?" component={Editor} />*/}
              {/*<Route path="/article/:id" component={Article} />*/}
              {/*<PrivateRoute path="/settings" component={Settings} />*/}
              {/*<Route path="/@:username" component={Profile} />*/}
              {/*<Route path="/@:username/favorites" component={Profile} />*/}
              {/*<Route path="/" component={Home} />*/}
              {/*<PrivateRoute path="/manage-category/update/:categoryId" component={UpdateCategory} />*/}

              {orgConfig.LEVEL1_MENUS.indexOf("PRODUCT_CATEGORIES") >= 0 && (
                <PrivateRoute
                  path="/edit-category/:categoryId"
                  component={EditCategory}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("PRODUCT_CATEGORIES") >= 0 && (
                <PrivateRoute
                  path="/view-product-categories"
                  component={ManageCategory}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("PRODUCT_CATEGORIES") >= 0 && (
                <PrivateRoute
                  path="/add-product-category"
                  component={AddCategory}
                />
              )}

              {orgConfig.LEVEL1_MENUS.indexOf("RETAIL_PACKAGING") >= 0 && (
                <PrivateRoute
                  path="/edit-retail-package/:retailPackageId"
                  component={EditRetailPackage}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("RETAIL_PACKAGING") >= 0 && (
                <PrivateRoute
                  path="/view-retail-packaging"
                  component={ListRetailPackage}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("RETAIL_PACKAGING") >= 0 && (
                <PrivateRoute
                  path="/add-retail-packaging"
                  component={AddRetailPackage}
                />
              )}

              {orgConfig.LEVEL1_MENUS.indexOf("QUANTITY_UNITS") >= 0 && (
                <PrivateRoute
                  path="/edit-quantity-unit/:quantityUnitId"
                  component={EditQuantityUnit}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("QUANTITY_UNITS") >= 0 && (
                <PrivateRoute
                  path="/view-quantity-units"
                  component={ListQuantityUnit}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("QUANTITY_UNITS") >= 0 && (
                <PrivateRoute
                  path="/add-quantity-unit"
                  component={AddQuantityUnit}
                />
              )}

              {orgConfig.LEVEL1_MENUS.indexOf("PRODUCTS") >= 0 && (
                <PrivateRoute path="/product/cms/:productId" component={Cms} />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("PRODUCTS") >= 0 && (
                <PrivateRoute path="/view-products" component={ManageProduct} />
              )}
              {/*{orgConfig.LEVEL1_MENUS.indexOf("PRODUCTS") >= 0 &&  <PrivateRoute path="/add-product" component={AddProduct}/>}*/}
              {orgConfig.LEVEL1_MENUS.indexOf("PRODUCTS") >= 0 && (
                <PrivateRoute
                  path="/add-product-new"
                  component={AddProduct_new}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("PRODUCTS") >= 0 && (
                <PrivateRoute
                  path="/edit-product-new/:id"
                  component={EditProduct_New}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("PRODUCTS") >= 0 && (
                <PrivateRoute path="/add-variant/:id" component={AddVariant} />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("PRODUCTS") >= 0 && (
                <PrivateRoute
                  path="/product-search"
                  component={ProductSearch}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("PRODUCTS") >= 0 && (
                <PrivateRoute
                  path="/edit-product/:id"
                  component={EditProduct}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("PRODUCTS") >= 0 && (
                <PrivateRoute
                  path="/view-products-new"
                  component={ManageProductNew}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("PRODUCTS") >= 0 && (
                <PrivateRoute
                  path="/search-product"
                  component={SearchProduct}
                />
              )}

              {orgConfig.LEVEL1_MENUS.indexOf("ONLINE_PRESCRIPTION_ORDERS") >=
                0 && (
                  <PrivateRoute
                    path="/view-prescription-orders"
                    component={ListOnlinePrescriptionOrderPage}
                  />
                )}
              {orgConfig.LEVEL1_MENUS.indexOf("ONLINE_PRESCRIPTION_ORDERS") >=
                0 && (
                  <PrivateRoute
                    path="/process-prescription-order/:orderId"
                    component={ProcessPrescriptionOrder}
                  />
                )}
              {orgConfig.LEVEL1_MENUS.indexOf("REPORT") >= 0 && (
                <PrivateRoute
                  path="/sales-insights"
                  component={SalesIntelligence}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("REPORT") >= 0 && (
                <PrivateRoute
                  path="/download-reports"
                  component={DownloadReports}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("REPORT") >= 0 && (
                <PrivateRoute
                  path="/customer-insights/:customerId"
                  component={CustomerInsightsPage}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("REPORT") >= 0 && (
                <PrivateRoute
                  path="/filtered-products"
                  component={ListProducts}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("REPORT") >= 0 && (
                <PrivateRoute path="/my-task" component={CleanDataPage} />
              )}

              {orgConfig.LEVEL1_MENUS.indexOf("REPORT") >= 0 && (
                <PrivateRoute
                  path="/duplicate-products"
                  component={DuplicateProducts}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("REPORT") >= 0 && (
                <PrivateRoute
                  path="/delete-history"
                  component={DeleteHistory}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("REPORT") >= 0 && (
                <PrivateRoute
                  path="/product-details/:productId"
                  component={ProductDetailsPage}
                />
              )}

              {orgConfig.LEVEL1_MENUS.indexOf("ONLINE_ORDERS") >= 0 && (
                <PrivateRoute
                  path="/view-online-orders"
                  component={ListOnlineOrders}
                />
              )}


              {orgConfig.LEVEL1_MENUS.indexOf("ONLINE_ORDERS") >= 0 && (
                <PrivateRoute
                  path="/view-online-order/:id"
                  component={ListOnlineOrderDetails}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("ONLINE_ORDERS") >= 0 && (
                <PrivateRoute
                  path="/process-online-order/:orderId"
                  component={ProcessOnlineOrders}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("ONLINE_ORDERS") >= 0 && (
                <PrivateRoute
                  path="/new-online-order"
                  component={NewOnlineOrder}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("ONLINE_ORDERS") >= 0 && (
                <PrivateRoute
                  path="/unfulfilled-orders"
                  component={UnfulfilledOrders}
                />
              )}

              {orgConfig.LEVEL1_MENUS.indexOf("STORAGE_LOCATION") >= 0 && (
                <PrivateRoute
                  path="/view-storage-locations"
                  component={ListStorageLocation}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("STORAGE_LOCATION") >= 0 && (
                <PrivateRoute
                  path="/add-storage-location"
                  component={AddStorageLocation}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("FLOORS") >= 0 && (
                <PrivateRoute
                  path="/view-floors/:locId"
                  component={ListFloors}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("FLOORS") >= 0 && (
                <PrivateRoute path="/add-floor/:locId" component={AddFloor} />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("RACKS") >= 0 && (
                <PrivateRoute
                  path="/view-racks/:locId/:floorId"
                  component={ListRacks}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("RACKS") >= 0 && (
                <PrivateRoute
                  path="/add-racks/:locId/:floorId"
                  component={AddRack}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("BOXES") >= 0 && (
                <PrivateRoute
                  path="/view-boxes/:locId/:floorId/:rackId"
                  component={ListBoxes}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("BOXES") >= 0 && (
                <PrivateRoute
                  path="/add-boxes/:locId/:floorId/:rackId"
                  component={AddBox}
                />
              )}

              {orgConfig.LEVEL1_MENUS.indexOf("PRODUCT_GROUPING") >= 0 && (
                <PrivateRoute path="/add-product-group" component={AddGroup} />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("PRODUCT_GROUPING") >= 0 && (
                <PrivateRoute
                  path="/product-group-list"
                  component={ListProductGroup}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("PRODUCT_GROUPING") >= 0 && (
                <PrivateRoute
                  path="/product-list-by-category"
                  component={ListProductsByCategory}
                />
              )}
              {orgConfig.LEVEL1_MENUS.indexOf("PRODUCT_GROUPING") >= 0 && (
                <PrivateRoute
                  path="/add-product-to-category"
                  component={AddProductToCategory}
                />
              )}
              {/*<PrivateRoute path="/view-speciality" component={ListSpecialityPage} />*/}
              {/*<PrivateRoute path="/add-speciality" component={AddSpecialityPage} />*/}

              {/* v2 routes */}
              <PrivateRoute path="/cashbook" component={PaymentRoute} />
              <PrivateRoute
                path="/products/addproduct-v2"
                component={AddProductV2}
              />
              <PrivateRoute
                path="/searchProductv3"
                component={SearchProductV3}
              />
              <PrivateRoute
                path="/expired-products"
                component={ExpiredProducts}
              />

              <PrivateRoute
                path="/view-specialties"
                component={ListMasterSpecialityPage}
              />
              <PrivateRoute
                path="/add-speciality"
                component={AddSpecialityGroupPage}
              />
              <PrivateRoute
                path="/edit-speciality/:id"
                component={EditSpecialityGroupPage}
              />
              <PrivateRoute
                path="/banned-products"
                component={BannedProducts}
              />
              <PrivateRoute path="/coupons/list" component={Coupons} />
              <PrivateRoute path="/dashboard" component={Dashboard} />

              <PrivateRoute path="/orders/list" component={OrderListV2} />
              <PrivateRoute
                path="/reports/credit-report"
                component={CreditReport}
              />
              <PrivateRoute
                path="/reports/product-sales-analysis"
                component={ProductSalesAnalysis}
              />
              <PrivateRoute
                path="/reports/report-list"
                component={ReportList}
              />
              <PrivateRoute
                path="/reports/create-report"
                component={CreateReport}
              />
              <PrivateRoute
                path="/reports/reportschedule-report"
                component={ReportScheduleList}
              />
              <PrivateRoute
                path="/reports/jobexecutionhistory-report"
                component={JobHistory}
              />
              <PrivateRoute
                path="/reports/jobcreation-report"
                component={JobsCreation}
              />
              <PrivateRoute path="/cms/banner" component={CmsBanner} />
              <PrivateRoute
                path="/shipping-performance"
                component={ShippingPerformance}
              />
              <PrivateRoute path="/razorpay-tools" component={Razorpay} />
              <PrivateRoute
                path="/add-substitute-medicine"
                component={Substitute}
              />

              <PrivateRoute
                path="/view-substitute-medicine"
                component={ViewSubstitute}
              />

              <PrivateRoute
                path="/receiveStockPage"
                component={ReceiveStockPage}
              />

              {/* mobile banner config------ */}
              <PrivateRoute
                path="/cms/mobile-banner"
                component={mobileBanner}
              />

              {/* out of stock list------ */}
              <PrivateRoute path="/list-out-of-stock" component={OutOfStock} />

              <Route path="/" component={Login} />
            </Switch>
          </Suspense>
        </div>
        <ToastContainer
          id={"toast-container-se"}
          closeButton={false}
          transition={Zoom}
          hideProgressBar={true}
          style={{ bottom: "0" }}
          position={"bottom-center"}
        ></ToastContainer>
        {this.props.location.pathname !== "/" && (
          <Suspense fallback={null}>
            <Footer />
          </Suspense>
        )}
      </React.Fragment>
    );
  }
}
export default withRouter(App);
