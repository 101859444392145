import React, {Suspense} from "react";
import {MDBBreadcrumb, MDBBreadcrumbItem} from "mdbreact";
import ListProductsComponent from "../../components/report/listProducts";
import {Link} from "react-router-dom";

export default class ListProducts extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem><Link to={'/download-reports'}>Reports</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Filtered Products</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <Suspense fallback={null}>
                    <ListProductsComponent/>
                </Suspense>
            </React.Fragment>
        )
    }
}