import React from "react";
import {Link} from "react-router-dom";
import { withRouter } from "react-router-dom";
import {
    MDBFormInline,
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBBreadcrumb,
    MDBBreadcrumbItem,
    MDBBox,
    MDBRow,
    MDBCol,
    MDBBtnGroup,
    MDBDataTable, MDBModal, MDBModalHeader, MDBModalBody
} from 'mdbreact';
import UserStore from "../../stores/userStore";
import {toast} from "react-toastify";
import PharmacyService from "../../services/pharmacyService";
import dataTableInventory from "./dataTableInventory";
import Moments from "moment";
import SimpleBackdrop from "../common/overlay";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import utility from "../../utility/utility";
import config from "../../config";
import UpdateInventory from "./updateInventory";

class ListInventory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orgId:"",
            activeInventoryId:0,
            inventoryList:[],
            inventoryApiResultCame:false,
            progressStatus:false,
            data:{},
            modalInventoryDetails:false,
            statusActionName:"",
            activeInventoryTableData:{},
            startDate: Moments(),
            endDate: Moments(),
            mode: "Day",
            dayStartDate:Moments(),
            dayEndDate:Moments(),
            weekStartDate:Moments().startOf("week"),
            weekEndDate:Moments().endOf("week"),
            monthStartDate:Moments().startOf("month"),
            monthEndDate:Moments().endOf("month"),
            totalMrp:0,
            totalPurchasePrice:0,
            totalPurchasePriceInStock:0,
            totalMrpInStock:0,
            modalEdit:false,
            activeInventory:{
                _id:0
            },
            disableRetreatAfterSorting:false
        }
    }

    // This methods calls first
    async componentDidMount() {
        let userData = await UserStore.fetchSessionData();
        this.setState({userData: userData})
        if(userData.type === "platformAdmin"){
            this.setState({orgId: userData._id})
        } else if(userData.type === "ORG_ADMIN"){
            this.setState({orgId: userData.orgDetails[0]._id})
        }
        // We want to make sure that we call the inventory when this component loads.
        this.fetchMyInventory();
        // let data = await ReportService.fetchStockDetails(userData);
        // if(data ===false){
        //     toast.error("We are facing some internal issue while fetching the totals.");
        // }else{
        //     this.setState({
        //         totalMrp:data.totalMrp,
        //         totalPurchasePrice:data.totalPurchasePrice,
        //         totalPurchasePriceInStock:data.totalPurchasePriceInStock,
        //         totalMrpInStock:data.totalMrpInStock
        //     });
        // }
    }

    convertToReadbleDate = date =>{
        return Moments(date).format("MMM D, YYYY");
    }

    convertToReadbleMonthYear = date =>{
        return Moments(date).format("MMM YYYY");
    }

    convertToReadbleDateTime = date =>{
        return Moments(date).format("MMM D, YYYY");
    }
    filterData = (type, startDate, endDate) => {
        if (startDate > endDate) {
            toast.info("Start date is greater than the end date. Please adjust the date range again.")
            return false;
        }
        this.fetchMyInventory();
        return false
    }

    // This will fetch the inventory and store the data in state and initiate the MDBDataTable
    fetchMyInventory = async () => {
        await this.setState({disableRetreatAfterSorting:true});
        let userData=await UserStore.fetchSessionData();
        this.setState({progressStatus:true});

        let startDate = Moments();
        let endDate = Moments();
        if(this.state.mode === "Day"){
            startDate = this.state.dayStartDate;
            endDate = this.state.dayEndDate;
        } else if(this.state.mode === "Week"){
            startDate = this.state.weekStartDate;
            endDate = this.state.weekEndDate;
        } else if(this.state.mode === "Month"){
            startDate = this.state.monthStartDate;
            endDate = this.state.monthEndDate;
        } else if(this.state.mode === "Custom"){
            startDate = this.state.startDate;
            endDate = this.state.endDate;
        }

        let data = await PharmacyService.fetchMyInventory(userData,startDate,endDate);
        this.setState({inventoryApiResultCame:true})
        if(data){
            try{
                this.setState({progressStatus: false, inventoryList: data});
                let dataTablePayload={
                    columns:dataTableInventory.inventory,
                    rows:data.map((item, index) => {
                        let temp={};
                        temp["batch"]=item.batch;
                        temp["handleAddedDate"]=this.convertToReadbleDate(item.createdAt);
                        temp["handleInvoiceNumber"]=item.shipmentDetails.invoiceNumber;
                        temp["handleProductName"]=item.productDetails.productName;
                        temp["supplierName"]=item.shipmentDetails.supplierDetails.supplierName;
                        temp["handleMfgExp"]=<span>{this.convertToReadbleMonthYear(item.mfgDate)} / {this.convertToReadbleMonthYear(item.expDate)}</span>;
                        temp["handleMrp"]=parseFloat(item.mrp.toFixed("2"));
                        temp["handleDiscount"]=<span>{item.discount.toFixed("2")}%</span>;
                        temp["handlePurchasePrice"]=parseFloat(item.priceWgst.toFixed(2));
                        temp["quantity"]=item.quantityIn;
                        temp["totalQuantity"]=item.quantity;
                        try {
                            temp["handleManufacturer"] = item.productDetails.manufacturer[0].manufacturer;
                        }catch (e){
                            temp["handleManufacturer"] = "";
                        }
                        temp["handleEdit"]=<div color="primary" className={"blue-text btn-link cursor-pointer"} size="sm" onClick={()=>{this.onEdit(item)}}>Edit</div>;
                        temp["handleActions"]=<div>
                                <span color="primary" className={"p-1 blue-text btn-link cursor-pointer"} size="sm" onClick={()=>{this.onEdit(item)}}>
                                        Edit
                                    </span>
                                </div>;
                        return temp;
                    })
                };
                this.setState({data:dataTablePayload});

                //calculating total
                let totalMrp=0;
                let totalPurchasePrice=0;
                let totalPurchasePriceInStock=0;
                let totalMrpInStock=0;
                for(let item of data){
                    // let tempMrp=0;
                    // let tempPurPrice=0;
                    // let tempMrpInStock=0;
                    // let tempPurPriceInStock=0;
                    //
                    // if(item.productDetails.looseSaleAllowed==='Y'){
                    //     tempMrp=parseFloat(item.mrp)/parseFloat(item.productDetails.contentQuantity);
                    //     tempPurPrice=parseFloat(item.priceWgst)/parseFloat(item.productDetails.contentQuantity);
                    // }
                    // console.log("loose",tempMrp);
                    totalMrp=totalMrp+(parseFloat(item.mrp)); //*parseFloat(item.quantityIn));
                    totalPurchasePrice=totalPurchasePrice+(parseFloat(item.priceWgst)); //*parseFloat(item.quantityIn));
                    totalPurchasePriceInStock=totalPurchasePriceInStock+(parseFloat(item.priceWgst)); //*parseFloat(item.quantity));
                    totalMrpInStock=totalMrpInStock+(parseFloat(item.mrp)); //*parseFloat(item.quantity));
                }
                this.setState({
                    totalMrp:totalMrp,
                    totalPurchasePrice:totalPurchasePrice,
                    totalPurchasePriceInStock:totalPurchasePriceInStock,
                    totalMrpInStock:totalMrpInStock
                });
                await this.setState({disableRetreatAfterSorting:false});
            } catch (e) {
                toast.error("Something went wrong while fetching inventory.")
            }
            this.setState({progressStatus:false});
        }
    }

    toggleEdit = () => {
        this.setState({
            modalEdit: !this.state.modalEdit
        });
    }
    onEditComplete = (row) => {
        this.toggleEdit()
        this.fetchMyInventory();
    }
    // This triggers from MDBDataTable, aim is to redirect to edit page
    onEdit =  (row) => {
        this.setState({activeInventory: row})
        this.toggleEdit()
    }

    // This redirect to new inventory
    goToNewInventory = () =>{
        this.props.history.push('/add-stock');
    }
    handleStartDate = (e) => {
        this.setState({startDate: Moments(e),mode:"Custom"})
        //this.filterData(this.state.mode, Moments(e), this.state.endDate)

    };
    handleEndDate = (e) => {
        this.setState({endDate: Moments(e),mode:"Custom"})
        //this.filterData(this.state.mode, this.state.startDate, Moments(e))
    };

    searchGo = () =>{
        this.filterData(this.state.mode, this.state.startDate, this.state.endDate)
    }

    changeMode = (mode) => {
        this.setState({"mode": mode})
        if (mode === "Day") {
            this.filterData(mode, this.state.dayStartDate, this.state.dayEndDate);
        }
        if (mode === "Week") {
            this.filterData(mode, this.state.weekStartDate, this.state.weekEndDate);
        }

        if (mode === "Month") {
            this.filterData(mode, this.state.monthStartDate, this.state.monthEndDate);
        }

        if (mode === "Custom") {
            //this.setState({startDate: Moments(), endDate: Moments()});
            this.filterData(mode, this.state.startDate, this.state.endDate);
        }
    }

    nextDay = () => {
        let nextStartDate = Moments(this.state.dayStartDate).add(1, "days");
        this.filterData("Today", nextStartDate, nextStartDate);
        this.setState({dayStartDate:nextStartDate, dayEndDate:nextStartDate})
    }

    prevDay = () => {
        let nextStartDate = Moments(this.state.dayStartDate).subtract(1, "days");
        this.filterData("Today", nextStartDate, nextStartDate);
        this.setState({dayStartDate:nextStartDate, dayEndDate:nextStartDate})
    }

    nextWeek = () => {
        let nextStartDate = Moments(this.state.weekStartDate).add(1, "weeks");
        let nextEndDate = Moments(this.state.weekEndDate).add(1, "weeks");
        this.filterData("Today", nextStartDate, nextEndDate);
        this.setState({weekStartDate:nextStartDate, weekEndDate:nextEndDate})
    }

    prevWeek  = () => {
        let nextStartDate = Moments(this.state.weekStartDate).subtract(1, "weeks");
        let nextEndDate = Moments(this.state.weekEndDate).subtract(1, "weeks");
        this.filterData("Today", nextStartDate, nextEndDate);
        this.setState({weekStartDate:nextStartDate, weekEndDate:nextEndDate})
    }

    nextMonth = () => {
        let nextStartDate = Moments(this.state.monthStartDate).add(1, "months");
        let nextEndDate = Moments(nextStartDate).endOf( "month");
        this.filterData("Today", nextStartDate, nextEndDate);
        this.setState({monthStartDate:nextStartDate, monthEndDate:nextEndDate})
    }

    prevMonth = () => {
        let nextStartDate = Moments(this.state.monthStartDate).subtract(1, "months");
        let nextEndDate = Moments(nextStartDate).endOf( "month");
        this.filterData("Today", nextStartDate, nextEndDate);
        this.setState({monthStartDate:nextStartDate, monthEndDate:nextEndDate})
    }
    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.progressStatus}/>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>View Stock</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <MDBBox>
                    <MDBCard className={"mt-2"}>
                        <MDBCardHeader>
                            <span className={"float-left"}>View Stock</span>
                            <MDBFormInline className="float-right md-form mr-auto m-0">
                                <MDBBtn color="default" size="sm" onClick={this.goToNewInventory} className="mr-auto" >
                                    Add Stock
                                </MDBBtn>
                            </MDBFormInline>
                        </MDBCardHeader>
                        <MDBCardBody>
                            <MDBRow className={"mb-10"}>
                               <MDBCol>
                                   Total MRP: {config.CURRENCY_SYMBOL} {this.state.totalMrp.toFixed(2)}
                               </MDBCol>
                                <MDBCol>
                                    Total Purchase Price: {config.CURRENCY_SYMBOL} {this.state.totalPurchasePrice.toFixed(2)}
                                </MDBCol>
                                <MDBCol>
                                    In Stock MRP: {config.CURRENCY_SYMBOL} {this.state.totalMrpInStock.toFixed(2)}
                                </MDBCol>
                                <MDBCol>
                                    In Stock Purchase Price: {config.CURRENCY_SYMBOL} {this.state.totalPurchasePriceInStock.toFixed(2)}
                                </MDBCol>
                            </MDBRow>
                            <br/>
                            <MDBRow>
                                <MDBCol size={"4"}>
                                    <MDBBtnGroup size="sm" >
                                        <MDBBtn onClick={()=>this.changeMode("Day")} disabled={this.state.mode === "Day" || this.state.progressStatus === true}  color={this.state.mode === "Day" ? "default":""}>Day</MDBBtn>
                                        <MDBBtn onClick={()=>this.changeMode("Week")} disabled={this.state.mode === "Week" || this.state.progressStatus === true}  color={this.state.mode === "Week" ? "default":""}>Week</MDBBtn>
                                        <MDBBtn onClick={()=>this.changeMode("Month")} disabled={this.state.mode === "Month" || this.state.progressStatus === true}  color={this.state.mode === "Month" ? "default":""}>Month</MDBBtn>
                                        <MDBBtn onClick={()=>this.changeMode("Custom")} disabled={this.state.mode === "Custom" || this.state.progressStatus === true}  color={this.state.mode === "Custom" ? "default":""}>Custom</MDBBtn>

                                    </MDBBtnGroup>
                                </MDBCol>
                                {this.state.mode === "Custom" &&
                                <MDBCol size={"4"} style={{marginTop: "-25px"}}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            style={{"marginLeft": "1px", "marginRight": "1px", width: "9rem"}}

                                            format="MMM D, YYYY"
                                            margin="normal"
                                            id="startDate"
                                            label="Start Date"
                                            value={this.state.startDate}
                                            onChange={this.handleStartDate}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>

                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            style={{"marginLeft": "1px", "marginRight": "1px", width: "9rem"}}

                                            format="MMM D, YYYY"
                                            margin="normal"
                                            id="endDate"
                                            label="End Date"
                                            value={this.state.endDate}
                                            onChange={this.handleEndDate}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                    <MDBBtn onClick={this.searchGo}  style={{"marginTop": "30px", "paddingLeft": "1px", "paddingRight": "1px", width: "3rem"}} size="sm" color={"default"}>Go</MDBBtn>
                                </MDBCol>
                                }

                                {(this.state.mode === "Day") &&
                                <MDBCol size={"4"}>
                                    <MDBBtn disabled={this.state.progressStatus === true } size={"sm"} onClick={()=>this.prevDay()} color="default">&lt;</MDBBtn>
                                    <span> {utility.convertToReadbleDaySmart(this.state.dayStartDate)}</span>
                                    <MDBBtn disabled={this.state.progressStatus === true } size={"sm"}  onClick={()=>this.nextDay()} color="default">&gt;</MDBBtn>
                                </MDBCol>
                                }
                                {(this.state.mode === "Week" ) &&
                                <MDBCol size={"4"}>
                                    <MDBBtn disabled={this.state.progressStatus === true } size={"sm"} onClick={()=>this.prevWeek()} color="default">&lt;</MDBBtn>
                                    <span> {utility.convertToReadbleDaySmart(this.state.weekStartDate)}</span> - <span> {utility.convertToReadbleDaySmart(this.state.weekEndDate)}</span>
                                    <MDBBtn disabled={this.state.progressStatus === true } size={"sm"}  onClick={()=>this.nextWeek()} color="default">&gt;</MDBBtn>
                                </MDBCol>
                                }

                                {(this.state.mode === "Month" ) &&
                                <MDBCol size={"4"}>
                                    <MDBBtn disabled={this.state.progressStatus === true } size={"sm"} onClick={()=>this.prevMonth()} color="default">&lt;</MDBBtn>
                                    <span> {utility.convertToReadbleMonthSmart(this.state.monthStartDate)}</span>
                                    <MDBBtn disabled={this.state.progressStatus === true } size={"sm"}  onClick={()=>this.nextMonth()} color="default">&gt;</MDBBtn>
                                </MDBCol>
                                }
                                <MDBCol size={"2"}>
                                </MDBCol>
                                <MDBCol size={"2"}>
                                </MDBCol>
                            </MDBRow>
                            {(this.state.inventoryApiResultCame && this.state.inventoryList.length < 1) &&
                                <div className={'no-data-container'}>
                                    {this.state.mode === "Day" && <span>There are no stocks for the selected date.</span>}
                                    {this.state.mode === "Week" && <span>There are no stocks for the selected week.</span>}
                                    {this.state.mode === "Month" && <span>There are no stocks for the selected month.</span>}
                                    {this.state.mode === "Custom" && <span>There are no stocks for the selected date range.</span>}
                                </div>
                            }
                            {this.state.inventoryList.length > 0 &&
                            // <MDBDataTableV5
                            //     hover
                            //     entriesOptions={[10,15, 20, 25]}
                            //     entries={10}
                            //     pagesAmount={4}
                            //     data={this.state.data}
                            //     striped
                            //     barReverse
                            //     materialSearch
                            //     searchTop
                            //     searchBottom={false}
                            // />
                            <MDBDataTable
                                striped

                                data={this.state.data}
                                materialSearch
                                noBottomColumns
                                hover
                                disableRetreatAfterSorting={false}
                            />
                            }
                        </MDBCardBody>

                        <MDBModal isOpen={this.state.modalEdit} toggle={this.toggleEdit} size={"fluid"}>
                            <MDBModalHeader toggle={this.toggleEdit}></MDBModalHeader>
                            <MDBModalBody className={"float-center"}>
                                <UpdateInventory loadedOnPopup={true} onEditComplete={this.onEditComplete}  inventoryId={this.state.activeInventory._id}/>
                            </MDBModalBody>
                        </MDBModal>
                    </MDBCard>
                </MDBBox>
            </React.Fragment>
        );
    }
}
export default withRouter(ListInventory)
