import React from "react";
import Moments from "moment";
import config from "../config";
import utility, { createImageUrl } from "./utility";
import Barcode from "react-barcode";
import { MDBBox ,  MDBSelect,
  MDBSelectInput,
  MDBSelectOption,
  MDBSelectOptions, } from "mdbreact";


export const handleAddressForTable = (row) => {
  let address = (
    <span className={"text-capitalize"}>
      <b>{row.address?.name}</b> <br />
      {row.address?.addressLine1}
      {row.address?.addressLine2 && (
        <span>,&nbsp;{row.address?.addressLine2}</span>
      )}{" "}
      <br />
      {row.address?.city}, {row.address?.state}, {row.address?.pinCode}
      {row.address?.pincode}, {row.address?.country}
      <br />
      Phone No: {row.address?.phone}
    </span>
  );
  return address;
};

export const formatUnfulfilledOrderTable = (data, modalOpenHandler) => {
  const formattedData = data?.map((item) => ({
    orderCode: (
      <span
        className="font-weight-bold cursor-pointer py-2 text-decoration-underline"
        onClick={() => modalOpenHandler(item?.id)}
      >
        {item?.orderCode}
      </span>
    ),
    createdAt: Moments(item?.createdAt).format("lll"),
    shippingAddress: handleAddressForTable(item),
    name: item?.name,
    phoneNumber: item?.phone,
    deliveryType: item?.deliveryType,
    paymentMode: item?.paymentMode,
    status:
      item.status === "OUTFORDELIVERY" && item?.deliveryType === "SHIP"
        ? "SHIPPED"
        : item.status === "READYFORDELIVERY"
        ? "PACKED"
        : item?.status,
    amount: item?.totalAmount?.toFixed(2),
    orderSource: item.hasOwnProperty("orderSource") ? (
      <span className={"text-capitalize"}>{item.orderSource}</span>
    ) : (
      "WEB"
    ),
    isPaid: (
      <span>
        {item?.isPaid === true ? "PAID" : "PENDING"} <br />
      </span>
    ),
  }));
  return formattedData;
};

export const formatUnfulfilledProductTable = (
  data,
  getAddedQnty,
  getSubtotal
) => {
  const formattedData = data?.map((row, index) => ({
    slno: index + 1,
    medicine:
      row.hasOwnProperty("imageUrl") && row.imageUrl.length > 0 ? (
        <div className={"cursor-pointer"}>
          {createImageUrl(row.imageUrl[0].filePathThumb, 50, 50)}
        </div>
      ) : (
        <div>
          <img
            src={"/broken-img.jpg"}
            style={{ width: "60px", height: "60px" }}
            alt={"Med Image"}
          />
        </div>
      ),
    product: row.productName,
    type: (
      <>
        {row.hasOwnProperty("isMedicine") && !row.isMedicine && (
          <span>General</span>
        )}
        {row.hasOwnProperty("isMedicine") && row.isMedicine && (
          <span>
            {row.hasOwnProperty("medicineType") && (
              <span
                className={
                  row.medicineType === "Schedule H" ||
                  row.medicineType === "Schedule H1" ||
                  row.medicineType === "Schedule X" ||
                  row.medicineType === "PRESCRIPTION DRUGS" ||
                  row.medicineType === "PRESCRIPTION DRUG"
                    ? "red-text"
                    : "green-text"
                }
              >
                {row.medicineType === "PRESCRIPTION DRUGS" && (
                  <span>Prescription Drug</span>
                )}
                {row.medicineType !== "PRESCRIPTION DRUGS" && (
                  <span>{row.medicineType}</span>
                )}
              </span>
            )}
            {!row.hasOwnProperty("medicineType") && <span> Not Specified</span>}
          </span>
        )}
        {!row.hasOwnProperty("isMedicine") &&
          row.hasOwnProperty("medicineType") && (
            <span
              className={
                row.medicineType === "Schedule H" ||
                row.medicineType === "Schedule H1" ||
                row.medicineType === "Schedule X"
                  ? "red-text"
                  : "green-text"
              }
            >
              {" "}
              {row.medicineType}
            </span>
          )}
      </>
    ),
    mrp: (
      <>
        {" "}
        <span dangerouslySetInnerHTML={{ __html: config.CURRENCY_HTML }}></span>
        {row.maxRetailPrice.toFixed(2)}
      </>
    ),
    batchMrp: row.hasOwnProperty("inventory") ? (
      <>
        {row.inventory.map((item, index1) => (
          <small key={index1}>
            <b>
              {item.batch} -{" "}
              <span
                dangerouslySetInnerHTML={{
                  __html: config.CURRENCY_HTML,
                }}
              ></span>{" "}
              {item.mrp}
            </b>
          </small>
        ))}
      </>
    ) : (
      "N/A"
    ),
    quantity: getAddedQnty(row),
    discount: row.discount,
    subTotal: (
      <>
        {" "}
        <span dangerouslySetInnerHTML={{ __html: config.CURRENCY_HTML }}></span>
        {getSubtotal(row)}
      </>
    ),
  }));
  return formattedData;
};

export const formatUnfulfilledPaymentTable = (order) => {
  const formattedData = [
    {
      paidAmount: (
        <>
          <span
            dangerouslySetInnerHTML={{ __html: config.CURRENCY_HTML }}
          ></span>{" "}
          {order?.paidAmount?.toFixed(2)}
        </>
      ),
      paidOn:
        order?.paidOn !== ""
          ? utility.convertToReadbleDateTimeUtc(order?.paidOn)
          : "N/A",
      paymentMode: order?.paymentMode,
      balanceAmount: (
        <>
          <span
            dangerouslySetInnerHTML={{ __html: config.CURRENCY_HTML }}
          ></span>{" "}
          {(order?.totalAmount - order?.paidAmount)?.toFixed(2)}
        </>
      ),
      shippingCharge: (
        <>
          <span
            dangerouslySetInnerHTML={{ __html: config.CURRENCY_HTML }}
          ></span>{" "}
          {order?.shippingAmount?.toFixed(2)}
        </>
      ),
      couponCode: (
        <>
          <span
            dangerouslySetInnerHTML={{ __html: config.CURRENCY_HTML }}
          ></span>{" "}
          {order?.flatDiscount?.toFixed(2)}
        </>
      ),
    },
  ];
  return formattedData;
};

export const formatStockByLocation = (listData) => {
  const formattedData = listData?.map((row) => ({
    barcode: (
      <MDBBox
        className="d-flex justify-content-center align-items-center"
        style={{ width: "10em", height: "6em" }}
      >
        <Barcode value={row?.barCode}></Barcode>
      </MDBBox>
    ),
    productName: row.productName,
    locationName: row.locationName,
    floorName: row?.floorName,
    rackName: row?.rackName,
    boxName: row.boxName,
    quantity: row?.quantity?.toFixed(2),
    mrp: row?.mrp?.toFixed(2),
    batch: row?.batch,
  }));

  return formattedData;
};


export const formatStockByLocationTemp = (listData,rackInfo,handleRackChange,selectBox) => {
  console.log({rackInfo})
  const formattedData = listData?.map((row,index) => ({
    // barcode: (
    //   <MDBBox
    //     className="d-flex justify-content-center align-items-center"
    //     style={{ width: "10em", height: "6em" }}
    //   >
    //     <Barcode value={row?.barCode}></Barcode>
    //   </MDBBox>
    // ),
    productName: row.productName,
    locationName: "PositraRx Six Mile",
    floorName: "Six mile ground floor",
    rackName: (
      <MDBSelect
      getValue={(e) => {
        handleRackChange(e,index);
      }}
    >
      <MDBSelectInput selected="Select Rack" />
      <MDBSelectOptions>
        {rackInfo.map((item, idx) => (
          <MDBSelectOption key={item._id || idx} value={item}>
            {item.rackName}
          </MDBSelectOption>
        ))}
      </MDBSelectOptions>
    </MDBSelect>
    ),
    boxName: (
      <MDBSelect
      getValue={(e) => {
        selectBox(e,index);
      }}
    >
      <MDBSelectInput selected="Select Rack" />
      <MDBSelectOptions>
        {row?.boxInfo?.map((item, idx) => (
          <MDBSelectOption key={item._id || idx} value={item}>
            {item.boxName}
          </MDBSelectOption>
        ))}
      </MDBSelectOptions>
    </MDBSelect>
    ),
    quantity: row?.quantity?.toFixed(2),
    mrp: row?.mrp?.toFixed(2),
    batch: row?.batch,
  }));

  return formattedData;
};