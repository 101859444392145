import React, { Component } from "react";
import {
  MDBBox,
  MDBRow,
  MDBInput,
  MDBCol,
  MDBBtn,
  MDBSpinner,
  MDBTableHead,
  MDBTable,
  MDBTableBody,
  MDBListGroup,
  MDBListGroupItem,
  MDBBreadcrumbItem,
  MDBBreadcrumb,
  MDBIcon,
  MDBCard,
  MDBCardBody,
} from "mdbreact";

import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import UserStore from "../../stores/userStore";
import PharmacyService from "../../services/pharmacyService";
import InventoryService from "../../services/inventoryService";
import config from "../../config";
import util from "../../utility/utility";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));
export default class Substitute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      userData: {},
      substituteProductList: [],
      productData: [],
      barCodeData: [],
      productIdData: [],
      mainProduct: [],
      masterProductId: "",
      masterProductSelect: false,
      substituteProductSelect: false,
      substituteProductId: "",
      substituteProduct: [],
      substituteProductData: [],
      substituteProductBarCode: [],

      searchRadio: 1,
      substituteSearchRadio: 1,
      selectedSubstituteMedicine: false,
      term: "",
      term1: "",
      productId: "",
      barCode: "",
      barCode1: "",
    };
  }

  async componentDidMount() {
    let userData = await UserStore.fetchSessionData();
    this.setState({ userData });
    document.getElementById("barCode").focus();
  }

  // radio button for master medicine------------
  onClick = (radioVal) => {
    this.setState({ searchRadio: radioVal });
  };

  // radio button for substitute medicine---------
  onClick1 = (radioVal) => {
    this.setState({ substituteSearchRadio: radioVal });
  };

  // search master medicine by barcode------------
  searchByBarCode = async () => {
    await delay(500);
    if (this.state.barCode !== "" && this.state.isLoading === false) {
      this.setState({ isLoading: true });
      let data = await PharmacyService.fetchProductByBarCode(
        this.state.userData,
        this.state.barCode
      );

      this.setState({ isLoading: false });
      if (data === false) {
        toast.error(
          "We are facing some issue. Please contact your administrator."
        );
      } else {
        this.setState({
          barCodeData: data,
          productData: [],
          productIdData: [],
        });
      }
    }
  };

  // search substitute medicine by barcode------------
  substituteBarCodeSearch = async () => {
    await delay(500);

    if (this.state.barCode1 !== "" && this.state.isLoading === false) {
      this.setState({ isLoading: true });
      let data = await PharmacyService.fetchProductByBarCode(
        this.state.userData,
        this.state.barCode1
      );

      this.setState({ isLoading: false });
      if (data === false) {
        toast.error(
          "We are facing some issue. Please contact your administrator."
        );
      } else {
        this.setState({
          substituteProductBarCode: data,
          substituteProductData: [],
          term1: "",
        });
      }
    }
  };

  // search master medicine by medicine name-------------
  searchByTerm = async () => {
    if (this.state.term !== "") {
      this.setState({ isLoading: true });
      let data = await PharmacyService.fetchProductByName(
        this.state.userData.sessionToken,
        this.state.term
      );

      this.setState({ isLoading: false });
      if (data === false) {
        toast.error(
          "Something went wrong while fetching the data by product name."
        );
      } else {
        this.setState({
          productData: data,
          barCodeData: [],
          productIdData: [],
        });
      }
    }
  };

  handleEnter = async (e) => {
    if (e.keyCode === 13) {
      await this.refreshPage();
    }
  };

  // search for master medicine by medicine name-------------
  productChange = async () => {
    await delay(500);
    if (this.state.term !== "" && this.state.isLoading === false) {
      this.setState({ isLoading: true });
      let data = await PharmacyService.fetchProductByTerm(
        this.state.userData.sessionToken,
        this.state.term
      );

      this.setState({ isLoading: false });
      if (data === false) {
        toast.error(
          "We are facing some issue. Please contact your administrator."
        );
      } else {
        this.setState({
          productData: data,
          barCodeData: [],
          productIdData: [],
        });
      }
    }
  };

  // reset search data----------
  resetSearch = () => {
    this.setState({
      barCodeData: [],
      term: "",
      barCode: "",
      productId: "",
      productIdData: [],
      productData: [],
    });
  };

  // select master medicine--------
  selectProduct = async (item) => {
    await this.setState({
      masterProductSelect: true,
      mainProduct: [item.productDetails],
      masterProductId: item.productDetails._id,
      productData: [],
      barCodeData: [],
      productIdData: [],
      term: "",
      barCode: "",
      productId: "",
    });

    toast.success(`${item.productDetails.productName} Selected..`);
  };

  // reset selected medicine------------
  resetSelectedProduct = () => {
    this.setState({
      mainProduct: [],
      substituteProduct: [],
      substituteProductId: "",
      masterProductId: "",
      term1: "",
      barCode1: "",
    });
  };

  clearTerm1 = () => {
    this.setState({ term1: "", substituteProductData: [] });
  };

  // remove selected substitute medicine------
  deleteSubstituteProduct = (val) => {
    const removedSet = [...this.state.substituteProduct];
    const filterData = removedSet.filter((e) => {
      return e._id !== val._id;
    });
    this.setState({
      substituteProduct: filterData,
      substituteProductSelect: false,
      barCode1: "",
      term1: "",
    });
    toast.success(`${val.productName} Removed.`);
  };

  // Remove master medicine------
  deleteMainProduct = (val) => {
    this.setState({
      mainProduct: [],
      masterProductSelect: false,
      barCode1: "",
      term1: "",
    });
    toast.success(`${val.productName} Removed.`);
  };

  // Add substitute medicine----
  addSubstitute = async (masterId, substituteId) => {
    try {
      this.setState({ isLoading: true });
      let addSubstitude = await PharmacyService.addSubstituteProduct(
        masterId,
        substituteId,
        this.state.userData.sessionToken
      );

      this.setState({ isLoading: false });
      if (addSubstitude) {
        const fetchSubstitute = await PharmacyService.fetchSubstituteProduct(
          this.state.masterProductId,
          this.state.userData.sessionToken
        );
        if (fetchSubstitute) {
          this.setState({ substituteProductList: fetchSubstitute });
        }
        toast.success("Substitute added Successfully..");

        this.setState({
          mainProduct: [],
          substituteProduct: [],
          masterProductSelect: false,
          substituteProductSelect: false,

          substituteProductData: [],
          productData: [],
          substituteProductId: "",
          masterProductId: "",
          productId: "",
        });
      }
    } catch (error) {
      toast.error("Substitute already exists");
      this.setState({ isLoading: false });
    }
  };

  refreshPage = async () => {
    if (this.state.searchRadio === 1 && this.state.barCode !== "") {
      await this.searchByBarCode();
    } else if (this.state.searchRadio === 2 && this.state.term !== "") {
      await this.searchByTerm();
      //  await this.productChange();
    } else if (this.state.searchRadio === 3 && this.state.productId !== "") {
      await this.searchByProductId();
    }
  };

  // search by id-------------
  searchByProductId = async () => {
    if (this.state.productId !== "") {
      this.setState({ isLoading: true });
      let data = await InventoryService.fetchProductById(
        this.state.userData,
        this.state.productId
      );
      this.setState({ isLoading: false });
      if (data === false) {
        this.setState({ productData: [] });
      } else {
        let newData = { productDetails: data, _id: data._id };
        let productData = [];
        productData.push(newData);
        this.setState({
          productIdData: productData,
          productData: [],
          barCodeData: [],
          term: "",
          barCode: "",
        });
      }
    }
  };

  // search for substitute medicine-----------
  substituteChange = async (e) => {
    if (e.keyCode === 13) {
      if (this.state.term1 !== "") {
        this.setState({ isLoading: true });
        let data = await PharmacyService.fetchProductByName(
          this.state.userData.sessionToken,
          this.state.term1
        );

        this.setState({ isLoading: false });
        if (data === false) {
          toast.error(
            "Something went wrong while fetching the data by product name."
          );
        } else {
          this.setState({
            substituteProductData: data,
            substituteProductBarCode: [],
            barCode1: "",
          });
        }
      }
    }
  };

  substituteGo = async (e) => {
    if (this.state.term1 !== "") {
      this.setState({ isLoading: true });
      let data = await PharmacyService.fetchProductByName(
        this.state.userData.sessionToken,
        this.state.term1
      );

      this.setState({ isLoading: false });
      if (data === false) {
        toast.error(
          "Something went wrong while fetching the data by product name."
        );
      } else {
        this.setState({
          substituteProductData: data,
          substituteProductBarCode: [],
          barCode1: "",
        });
      }
    }
  };

  // selecting substitute medicine-----
  selectSubstituteProduct = async (item) => {
    await this.setState({
      substituteProduct: [item.productDetails],
      substituteProductId: item.productDetails._id,
      substituteProductSelect: true,
      term1: "",
      barCode1: "",
      substituteProductData: [],
      substituteProductBarCode: [],
    });

    toast.success(`${item.productDetails.productName} Selected..`);
  };

  // delete existing substitute medicine from list------
  deleteExistingSubstitute = async (id) => {
    let deleteSubstitute = await PharmacyService.deleteExistingSubstitute(
      id,
      this.state.userData.sessionToken
    );
    if (deleteSubstitute) {
      toast.success("Substitute deleted successfully");
      this.setState({
        substituteProductList: this.state.substituteProductList.filter(
          (item) => {
            return id !== item._id;
          }
        ),
      });
    } else {
      toast.error("Something went wrong !");
    }
  };

  render() {
    return (
      <React.Fragment>
        <MDBBreadcrumb>
          <MDBBreadcrumbItem>
            <Link to={"/dashboard"}>Dashboard</Link>
          </MDBBreadcrumbItem>
          <MDBBreadcrumbItem active>Substitute medicine list</MDBBreadcrumbItem>
        </MDBBreadcrumb>

        <div className="text-center">
          <h3 className="text-primary font-weight-normal">
            Please search a medicine for Substitution
          </h3>
        </div>

        {this.state.isLoading === true && (
          <div className={"PanelFloat"}>
            <MDBSpinner />
          </div>
        )}

        <MDBBox className={"min-vh-100 mx-5 mb-5 mt-4"}>
          <MDBRow>
            <MDBCol>
              <MDBRow>
                <MDBInput
                  onClick={() => {
                    this.onClick(1);
                  }}
                  checked={this.state.searchRadio === 1 ? true : false}
                  label="Search by barcode"
                  type="radio"
                  id="radio1"
                />{" "}
                &nbsp;
                <MDBInput
                  onClick={() => {
                    this.onClick(3);
                  }}
                  checked={this.state.searchRadio === 3 ? true : false}
                  label="Search by product Id"
                  type="radio"
                  id="radio5"
                />
                &nbsp;
                <MDBInput
                  onClick={() => {
                    this.onClick(2);
                  }}
                  checked={this.state.searchRadio === 2 ? true : false}
                  label="Search by medicine name"
                  type="radio"
                  id="radio2"
                />
              </MDBRow>
              <MDBRow className="mt-2">
                {this.state.searchRadio === 1 && (
                  <MDBCol>
                    <MDBRow>
                      <div className={"col-md-6 nav-search "}>
                        <MDBInput
                          label={"Search a master medicine"}
                          value={this.state.barCode}
                          placeholder="Search by barcode"
                          getValue={(e) => {
                            this.setState({ barCode: e });
                          }}
                          onChange={() => {
                            this.searchByBarCode();
                          }}
                          id={"barCode"}
                        />
                      </div>

                      <MDBCol className={"d-flex align-items-center"}>
                        {this.state.barCodeData.length > 0 &&
                          this.state.barCode !== "" && (
                            <MDBBtn
                              size={"md"}
                              color="danger"
                              onClick={() => {
                                this.resetSearch();
                              }}
                              disabled={this.state.isLoading}
                            >
                              Reset
                            </MDBBtn>
                          )}
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>
                )}

                {this.state.searchRadio === 2 && (
                  <MDBCol>
                    <MDBRow>
                      <div className={"col-md-6 nav-search"}>
                        <MDBInput
                          label={"Search a master medicine"}
                          placeholder="Search by product name"
                          value={this.state.term}
                          getValue={(e) => {
                            this.setState({ term: e });
                          }}
                          onKeyDown={this.handleEnter}
                          // onChange={() => {
                          //   this.productChange();
                          // }}
                        />
                      </div>
                      <MDBCol className={"d-flex align-items-center"}>
                        <MDBBtn
                          size={"md"}
                          onClick={() => {
                            this.searchByTerm();
                          }}
                          disabled={this.state.isLoading}
                          style={{ marginLeft: "-10px" }}
                        >
                          Go
                        </MDBBtn>
                        {this.state.productData.length > 0 &&
                          this.state.term !== "" && (
                            <MDBBtn
                              size={"md"}
                              color="danger"
                              onClick={() => {
                                this.resetSearch();
                              }}
                              disabled={this.state.isLoading}
                            >
                              Reset
                            </MDBBtn>
                          )}
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>
                )}

                {this.state.searchRadio === 3 && (
                  <MDBCol>
                    <MDBRow>
                      <div className={"col-md-6 nav-search"}>
                        <MDBInput
                          label={"Search a master medicine"}
                          placeholder="Search by product Id"
                          value={this.state.productId}
                          getValue={(e) => {
                            this.setState({ productId: e });
                          }}
                          onKeyDown={this.handleEnter}
                        />
                      </div>
                      <MDBCol className={"d-flex align-items-center"}>
                        <MDBBtn
                          size={"md"}
                          onClick={() => {
                            this.searchByProductId();
                          }}
                          disabled={this.state.isLoading}
                        >
                          Go
                        </MDBBtn>
                        {this.state.productIdData.length > 0 &&
                          this.state.productId !== "" && (
                            <MDBBtn
                              size={"md"}
                              color="danger"
                              onClick={() => {
                                this.resetSearch();
                              }}
                              disabled={this.state.isLoading}
                            >
                              Reset
                            </MDBBtn>
                          )}
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>
                )}
              </MDBRow>
            </MDBCol>
            <MDBCol>
              <div className="mt-2">
                {this.state.mainProduct.length > 0 && (
                  <div>
                    <span className="font-weight-bold px-5">
                      Selected Master Medicine
                    </span>
                    <ul>
                      {this.state.mainProduct.map((val, index) => {
                        return (
                          <li key={index}>
                            <div
                              className={"d-flex align-items-center px-5 mb-1"}
                            >
                              <span className="green px-5 py-1 text-white">
                                {val.productName} (master medicine)
                              </span>
                              <span
                                className="blue-text btn-link cursor-pointer font-weight-bold mx-1"
                                onClick={() => this.deleteMainProduct(val)}
                              >
                                Delete
                              </span>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}

                {this.state.substituteProduct.length > 0 && (
                  <div>
                    <span className="font-weight-bold px-5">
                      Selected Substitute Medicine
                    </span>
                    <ul>
                      {this.state.substituteProduct.map((val, index) => {
                        return (
                          <li key={index}>
                            <div
                              className={"d-flex align-items-center px-5 mb-1"}
                            >
                              <span className="orange px-5 py-1 text-white">
                                {val.productName} (substitute medicine)
                              </span>
                              <span
                                className="blue-text btn-link cursor-pointer font-weight-bold mx-1"
                                onClick={() =>
                                  this.deleteSubstituteProduct(val)
                                }
                              >
                                Delete
                              </span>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
                <div className="px-5 mx-5">
                  {this.state.mainProduct.length > 0 &&
                    this.state.substituteProduct.length > 0 && (
                      <MDBBtn
                        size={"md"}
                        onClick={() => {
                          this.addSubstitute(
                            this.state.masterProductId,
                            this.state.substituteProductId
                          );
                        }}
                        disabled={this.state.isLoading}
                      >
                        Link
                      </MDBBtn>
                    )}

                  {this.state.mainProduct.length > 0 &&
                    this.state.substituteProduct.length > 0 && (
                      <MDBBtn
                        size={"md"}
                        color="danger"
                        onClick={() => {
                          this.resetSelectedProduct();
                        }}
                        disabled={this.state.isLoading}
                      >
                        Reset
                      </MDBBtn>
                    )}
                </div>

                <div className="mt-3">
                  {this.state.mainProduct.length > 0 &&
                    this.state.masterProductSelect === true &&
                    this.state.substituteProductSelect === false && (
                      <div className="mt-5 px-5">
                        <MDBRow>
                          <MDBInput
                            onClick={() => {
                              this.onClick1(1);
                            }}
                            checked={
                              this.state.substituteSearchRadio === 1
                                ? true
                                : false
                            }
                            label="Search by barcode"
                            type="radio"
                            id="radio3"
                          />
                          &nbsp;
                          <MDBInput
                            onClick={() => {
                              this.onClick1(2);
                            }}
                            checked={
                              this.state.substituteSearchRadio === 2
                                ? true
                                : false
                            }
                            label="Search by product name"
                            type="radio"
                            id="radio4"
                          />
                        </MDBRow>
                        <MDBRow className="mt-2">
                          {this.state.substituteSearchRadio === 1 && (
                            <MDBCol className={"m-2"}>
                              <MDBRow>
                                <div className={"col-md-8 nav-search"}>
                                  <MDBInput
                                    label={"Search a substitute medicine"}
                                    placeholder="Search by barcode"
                                    value={this.state.barCode1}
                                    getValue={(e) => {
                                      this.setState({ barCode1: e });
                                    }}
                                    onChange={() => {
                                      this.substituteBarCodeSearch();
                                    }}
                                  />
                                </div>
                              </MDBRow>
                            </MDBCol>
                          )}
                          {this.state.substituteSearchRadio === 2 && (
                            <MDBCol className={"m-2"}>
                              <MDBRow>
                                <div className={"col-md-8 nav-search"}>
                                  <MDBInput
                                    label={"Search a substitute medicine"}
                                    placeholder="Search by product name and Press Enter"
                                    value={this.state.term1}
                                    getValue={(e) => {
                                      this.setState({ term1: e });
                                    }}
                                    onKeyDown={this.substituteChange}
                                  />
                                </div>
                                <MDBCol className={"d-flex align-items-center"}>
                                  <MDBBtn
                                    size={"md"}
                                    onClick={() => {
                                      this.substituteGo();
                                    }}
                                    disabled={this.state.isLoading}
                                    style={{ marginLeft: "-10px" }}
                                  >
                                    Go
                                  </MDBBtn>

                                  {this.state.substituteProductData.length >
                                    0 &&
                                    this.state.term1 !== "" && (
                                      <MDBBtn
                                        size={"md"}
                                        disabled={this.state.isLoading}
                                        style={{ marginLeft: "-10px" }}
                                        onClick={() => this.clearTerm1()}
                                        className="mx-3"
                                      >
                                        Clear
                                      </MDBBtn>
                                    )}
                                </MDBCol>
                              </MDBRow>
                            </MDBCol>
                          )}
                        </MDBRow>
                      </div>
                    )}
                  <MDBRow>
                    <MDBCol className="col-12 m-1 px-3">
                      {this.state.substituteProductData.length > 0 &&
                        this.state.term1 !== "" && (
                          <div>
                            <span className="font-weight-bold">
                              Please select a substitute medicine
                            </span>
                            <ul
                              className={
                                "mt-2 list-group list-group-flush doctor-list-dropdown border"
                              }
                            >
                              {this.state.substituteProductData.map(
                                (item, index) => {
                                  return (
                                    <li
                                      key={index}
                                      // onClick={() => {
                                      //   this.selectProduct(item);
                                      // }}
                                      className="list-group-item"
                                    >
                                      <div
                                        className={"col-12 row"}
                                        style={{ fontSize: "13px" }}
                                      >
                                        <div className="m-0 p-0 col-3">
                                          <div>
                                            {item.productDetails.imageUrl
                                              .length > 0 && (
                                              <img
                                                width={"100"}
                                                alt={""}
                                                src={
                                                  config.IMAGE_HOST +
                                                  item?.productDetails
                                                    ?.imageUrl[0].filePathThumb
                                                }
                                              />
                                            )}

                                            {item.productDetails.imageUrl
                                              .length === 0 && (
                                              <img
                                                alt={""}
                                                width={"100"}
                                                src={require("../../components/common/img/broken-img.jpg")}
                                              />
                                            )}
                                          </div>
                                        </div>
                                        <div className="m-0 py-3 col-5">
                                          <h6 style={{ fontSize: "13px" }}>
                                            <strong>
                                              {item.productDetails.productName}
                                            </strong>
                                          </h6>
                                          <div>
                                            MRP:{" "}
                                            <span
                                              dangerouslySetInnerHTML={{
                                                __html: config.CURRENCY_HTML,
                                              }}
                                            ></span>{" "}
                                            {util.customRound(item.mrp)}
                                          </div>
                                          <div>
                                            <b>
                                              Exp:{" "}
                                              {util.convertMfgOrExpiry(
                                                item.expDate
                                              )}
                                            </b>
                                          </div>
                                        </div>
                                        <div className="m-0 py-3 col-3">
                                          {item.productDetails
                                            .markAsoutOfStock === "N" && (
                                            <div>
                                              <span className={"green-text"}>
                                                In Stock
                                              </span>
                                            </div>
                                          )}
                                          {item.productDetails
                                            .markAsoutOfStock === "Y" && (
                                            <div>
                                              <span className={"red-text"}>
                                                Out of Stock
                                              </span>
                                            </div>
                                          )}
                                          <div>Batch: {item.batch}</div>

                                          <div>
                                            Barcode:{" "}
                                            {item.barCode
                                              ? item.barCode
                                              : "000"}
                                          </div>
                                        </div>
                                        <div className="text-right py-3 col-1">
                                          <button
                                            type="button"
                                            className="btn btn-primary btn-sm"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Add"
                                            onClick={() => {
                                              this.selectSubstituteProduct(
                                                item
                                              );
                                            }}
                                          >
                                            Add
                                          </button>
                                        </div>
                                      </div>
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          </div>
                        )}
                    </MDBCol>

                    <MDBCol className="col-12 m-1 px-3">
                      {this.state.substituteProductBarCode.length > 0 &&
                        this.state.barCode1 !== "" && (
                          <div>
                            <span className="font-weight-bold">
                              Please select a substitute medicine
                            </span>
                            <ul
                              className={
                                "mt-2 list-group list-group-flush doctor-list-dropdown border"
                              }
                            >
                              {this.state.substituteProductBarCode.map(
                                (item, index) => {
                                  return (
                                    <li
                                      key={index}
                                      // onClick={() => {
                                      //   this.selectProduct(item);
                                      // }}
                                      className="list-group-item"
                                    >
                                      <div
                                        className={"col-12 row"}
                                        style={{ fontSize: "13px" }}
                                      >
                                        <div className="m-0 p-0 col-3">
                                          <div>
                                            {item.productDetails.imageUrl
                                              .length > 0 && (
                                              <img
                                                width={"100"}
                                                alt={""}
                                                src={
                                                  config.IMAGE_HOST +
                                                  item?.productDetails
                                                    ?.imageUrl[0].filePathThumb
                                                }
                                              />
                                            )}

                                            {item.productDetails.imageUrl
                                              .length === 0 && (
                                              <img
                                                alt={""}
                                                width={"100"}
                                                src={require("../../components/common/img/broken-img.jpg")}
                                              />
                                            )}
                                          </div>
                                        </div>
                                        <div className="m-0 py-3 col-5">
                                          <h6 style={{ fontSize: "13px" }}>
                                            <strong>
                                              {item.productDetails.productName}
                                            </strong>
                                          </h6>
                                          <div>
                                            MRP:{" "}
                                            <span
                                              dangerouslySetInnerHTML={{
                                                __html: config.CURRENCY_HTML,
                                              }}
                                            ></span>{" "}
                                            {util.customRound(item.mrp)}
                                          </div>
                                          <div>
                                            <b>
                                              Exp:{" "}
                                              {util.convertMfgOrExpiry(
                                                item.expDate
                                              )}
                                            </b>
                                          </div>
                                        </div>
                                        <div className="m-0 py-3 col-3">
                                          {item.productDetails
                                            .markAsoutOfStock === "N" && (
                                            <div>
                                              <span className={"green-text"}>
                                                In Stock
                                              </span>
                                            </div>
                                          )}
                                          {item.productDetails
                                            .markAsoutOfStock === "Y" && (
                                            <div>
                                              <span className={"red-text"}>
                                                Out of Stock
                                              </span>
                                            </div>
                                          )}
                                          <div>Batch: {item.batch}</div>
                                          <div>
                                            Barcode:{" "}
                                            {item.barCode
                                              ? item.barCode
                                              : "000"}
                                          </div>
                                        </div>
                                        <div className="text-right py-3 col-1">
                                          <button
                                            type="button"
                                            className="btn btn-primary btn-sm"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Add"
                                            onClick={() => {
                                              this.selectSubstituteProduct(
                                                item
                                              );
                                            }}
                                          >
                                            Add
                                          </button>
                                        </div>
                                      </div>
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          </div>
                        )}
                    </MDBCol>
                  </MDBRow>

                  {/* <div className="px-5">
                    {this.state.substituteProductData.length > 0 &&
                      this.state.term1 !== "" && (
                        <div>
                          <span className="font-weight-bold">
                            Please select a substitute medicine
                          </span>
                          <ul className={"mt-2"}>
                            {this.state.substituteProductData.map(
                              (item, index) => {
                                return (
                                  <li
                                    key={index}
                                    onClick={() => {
                                      this.selectSubstituteProduct(item);
                                    }}
                                  >
                                    <div
                                      className={
                                        "d-flex justify-content-between"
                                      }
                                    >
                                      <MDBListGroup
                                        style={{
                                          minWidth: "22rem",
                                          cursor: "pointer",
                                        }}
                                        light
                                      >
                                        <MDBListGroupItem
                                          noBorders
                                          color="info"
                                          className="px-4 mb-2 rounded-3"
                                        >
                                          {item.productDetails.productName}
                                        </MDBListGroupItem>
                                      </MDBListGroup>
                                    </div>
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      )}
                  </div> */}
                </div>
              </div>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol size={"6"}>
              {this.state.productData.length > 0 && this.state.term !== "" && (
                <div>
                  <span className="font-weight-bold">
                    Please select a master medicine
                  </span>
                  <ul
                    className={
                      "mt-2 list-group list-group-flush doctor-list-dropdown border"
                    }
                  >
                    {this.state.productData.map((item, index) => {
                      return (
                        <li
                          key={index}
                          // onClick={() => {
                          //   this.selectProduct(item);
                          // }}
                          className="list-group-item"
                        >
                          <div
                            className={"col-12 row"}
                            style={{ fontSize: "13px" }}
                          >
                            <div className="m-0 p-0 col-3">
                              <div>
                                {item.productDetails.imageUrl.length > 0 && (
                                  <img
                                    width={"100"}
                                    alt={""}
                                    src={
                                      config.IMAGE_HOST +
                                      item?.productDetails?.imageUrl[0]
                                        .filePathThumb
                                    }
                                  />
                                )}

                                {item.productDetails.imageUrl.length === 0 && (
                                  <img
                                    alt={""}
                                    width={"100"}
                                    src={require("../../components/common/img/broken-img.jpg")}
                                  />
                                )}
                              </div>
                            </div>
                            <div className="m-0 py-3 col-5">
                              <h6 style={{ fontSize: "13px" }}>
                                <strong>
                                  {item.productDetails.productName}
                                </strong>
                              </h6>
                              <div>
                                MRP:{" "}
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: config.CURRENCY_HTML,
                                  }}
                                ></span>{" "}
                                {util.customRound(item.mrp)}
                              </div>
                              <div>
                                <b>
                                  Exp: {util.convertMfgOrExpiry(item.expDate)}
                                </b>
                              </div>
                            </div>
                            <div className="m-0 py-3 col-3">
                              {item.productDetails.markAsoutOfStock === "N" && (
                                <div>
                                  <span className={"green-text"}>In Stock</span>
                                </div>
                              )}
                              {item.productDetails.markAsoutOfStock === "Y" && (
                                <div>
                                  <span className={"red-text"}>
                                    Out of Stock
                                  </span>
                                </div>
                              )}
                              <div>Batch: {item.batch}</div>
                              <div>
                                Barcode: {item.barCode ? item.barCode : "000"}
                              </div>
                            </div>
                            <div className="text-right py-3 col-1">
                              <button
                                type="button"
                                className="btn btn-primary btn-sm"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Add"
                                onClick={() => {
                                  this.selectProduct(item);
                                }}
                              >
                                Add
                              </button>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}

              {this.state.barCodeData.length > 0 &&
                this.state.barCode !== "" && (
                  <div>
                    <span className="font-weight-bold">
                      Please select a master medicine
                    </span>
                    <ul
                      className={
                        "mt-2 list-group list-group-flush doctor-list-dropdown border"
                      }
                    >
                      {this.state.barCodeData.map((item, index) => {
                        return (
                          <li
                            key={index}
                            // onClick={() => {
                            //   this.selectProduct(item);
                            // }}
                            className="list-group-item"
                          >
                            <div
                              className={"col-12 row"}
                              style={{ fontSize: "13px" }}
                            >
                              <div className="m-0 p-0 col-3">
                                <div>
                                  {item.productDetails.imageUrl.length > 0 && (
                                    <img
                                      width={"100"}
                                      alt={""}
                                      src={
                                        config.IMAGE_HOST +
                                        item?.productDetails?.imageUrl[0]
                                          .filePathThumb
                                      }
                                    />
                                  )}

                                  {item.productDetails.imageUrl.length ===
                                    0 && (
                                    <img
                                      alt={""}
                                      width={"100"}
                                      src={require("../../components/common/img/broken-img.jpg")}
                                    />
                                  )}
                                </div>
                              </div>
                              <div className="m-0 py-3 col-5">
                                <h6 style={{ fontSize: "13px" }}>
                                  <strong>
                                    {item.productDetails.productName}
                                  </strong>
                                </h6>
                                <div>
                                  MRP:{" "}
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: config.CURRENCY_HTML,
                                    }}
                                  ></span>{" "}
                                  {util.customRound(item.mrp)}
                                </div>
                                <div>
                                  <b>
                                    Exp: {util.convertMfgOrExpiry(item.expDate)}
                                  </b>
                                </div>
                              </div>
                              <div className="m-0 py-3 col-3">
                                {item.productDetails.markAsoutOfStock ===
                                  "N" && (
                                  <div>
                                    <span className={"green-text"}>
                                      In Stock
                                    </span>
                                  </div>
                                )}
                                {item.productDetails.markAsoutOfStock ===
                                  "Y" && (
                                  <div>
                                    <span className={"red-text"}>
                                      Out of Stock
                                    </span>
                                  </div>
                                )}
                                <div>Batch: {item.batch}</div>
                                <div>
                                  Barcode: {item.barCode ? item.barCode : "000"}
                                </div>
                              </div>
                              <div className="text-right py-3 col-1">
                                <button
                                  type="button"
                                  className="btn btn-primary btn-sm"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Add"
                                  onClick={() => {
                                    this.selectProduct(item);
                                  }}
                                >
                                  Add
                                </button>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}

              {this.state.productIdData.length > 0 &&
                this.state.productId !== "" && (
                  <div>
                    <span className="font-weight-bold">
                      Please select a master medicine
                    </span>
                    <ul
                      className={
                        "mt-2 list-group list-group-flush doctor-list-dropdown border"
                      }
                    >
                      {this.state.productIdData.map((item, index) => {
                        return (
                          <li
                            key={index}
                            // onClick={() => {
                            //   this.selectProduct(item);
                            // }}
                            className="list-group-item"
                          >
                            <div
                              className={"col-12 row"}
                              style={{ fontSize: "13px" }}
                            >
                              <div className="m-0 p-0 col-3">
                                <div>
                                  {item.productDetails.imageUrl.length > 0 && (
                                    <img
                                      width={"100"}
                                      alt={""}
                                      src={
                                        config.IMAGE_HOST +
                                        item?.productDetails?.imageUrl[0]
                                          .filePathThumb
                                      }
                                    />
                                  )}

                                  {item.productDetails.imageUrl.length ===
                                    0 && (
                                    <img
                                      alt={""}
                                      width={"100"}
                                      src={require("../../components/common/img/broken-img.jpg")}
                                    />
                                  )}
                                </div>
                              </div>
                              <div className="m-0 py-3 col-5">
                                <h6 style={{ fontSize: "13px" }}>
                                  <strong>
                                    {item.productDetails.productName}
                                  </strong>
                                </h6>
                                <div>
                                  MRP:{" "}
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: config.CURRENCY_HTML,
                                    }}
                                  ></span>{" "}
                                  {util.customRound(item.mrp)}
                                </div>
                                <div>
                                  <b>
                                    Exp: {util.convertMfgOrExpiry(item.expDate)}
                                  </b>
                                </div>
                              </div>
                              <div className="m-0 py-3 col-3">
                                {item.productDetails.markAsoutOfStock ===
                                  "N" && (
                                  <div>
                                    <span className={"green-text"}>
                                      In Stock
                                    </span>
                                  </div>
                                )}
                                {item.productDetails.markAsoutOfStock ===
                                  "Y" && (
                                  <div>
                                    <span className={"red-text"}>
                                      Out of Stock
                                    </span>
                                  </div>
                                )}
                                <div>Batch: {item.batch}</div>
                                <div>
                                  Barcode: {item.barCode ? item.barCode : "000"}
                                </div>
                              </div>
                              <div className="text-right py-3 col-1">
                                <button
                                  type="button"
                                  className="btn btn-primary btn-sm"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Add"
                                  onClick={() => {
                                    this.selectProduct(item);
                                  }}
                                >
                                  Add
                                </button>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
            </MDBCol>
          </MDBRow>

          <MDBBox className="mt-4">
            {this.state.substituteProductList.length > 0 && (
              <MDBCard className="px-3">
                <MDBCardBody>
                  <MDBTable>
                    <MDBTableHead>
                      <tr>
                        <th>Master Medicine</th>
                        <th>Substitute Medicine</th>

                        <th>Action</th>
                      </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                      {this.state.substituteProductList.map((item, index) => {
                        return (
                          <tr key={item._id}>
                            <td className={"table-no-gap"}>
                              {item.masterMedicine.length === 0
                                ? ""
                                : item.masterMedicine[0].productName}
                            </td>
                            <td className={"table-no-gap"}>
                              {item.substitudeMedicine.length === 0
                                ? ""
                                : item.substitudeMedicine[0].productName}
                            </td>
                            <td className={"table-no-gap"}>
                              <span
                                color="primary"
                                className={
                                  "blue-text btn-link cursor-pointer font-weight-bold"
                                }
                                onClick={() =>
                                  this.deleteExistingSubstitute(item._id)
                                }
                              >
                                Delete
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </MDBTableBody>
                  </MDBTable>
                </MDBCardBody>
              </MDBCard>
            )}
          </MDBBox>
        </MDBBox>
      </React.Fragment>
    );
  }
}
