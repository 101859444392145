import React from "react";
import { withRouter } from "react-router-dom";
import {
    MDBContainer, MDBCardHeader, MDBFormInline, MDBBtn, MDBCardBody, MDBCard, MDBModalHeader,
    MDBModalBody, MDBModal, MDBModalFooter, MDBDataTable, MDBBox
} from 'mdbreact';
import UserStore from "../../stores/userStore";
import InventoryService from "../../services/inventoryService";
import {toast} from "react-toastify";
import dataTableConfig from "../../dataTableConfig";
import PharmacyService from "../../services/pharmacyService";
import SimpleBackdrop from "../common/overlay";
import Moments from "moment";
import UpdateProduct from "../../pages/product/editPkgDetails";
import UpdateProductConfig from "../../pages/product/cms"
import ProductView from "./productView";
import SellingHistoryByProduct from "../report/sellingHistoryByProduct";

class ProductListCmp extends React.Component {
    async componentDidMount() {
        await this.fetchData();
    }
    fetchData=async ()=>{
        await this.setState({disableRetreatAfterSorting:true});
        window.scrollTo(0, 0);
        if(!window.productList || window.productList===null) {
            let userData = await UserStore.fetchSessionData();
            this.setState({progressStatus: true});
            let data = await InventoryService.fetchProduct(userData, 0, 0, this.state.type);
            window.productList = data;
            this.setState({progressStatus: false, apiResultCame:true});
            if (data === false) {
                toast.error("We are facing some issue. Please try again later.");
            } else {
                this.setState({products: data});
                let dataTablePayload = {
                    columns: dataTableConfig.product,
                    rows: data.map((item, index) => {
                        let temp={};
                        let completionStat=true;
                        let readyForDispancing=true;
                        if(item.productName===''){
                            completionStat=false;
                            readyForDispancing=false;
                        }
                        if(item.categories.length===0){
                            completionStat=false;
                        }
                        // if(item.highlight.length===0){
                        //     completionStat=false;
                        // }
                        if(item.contentQuantity==='' || parseFloat(item.contentQuantity)===0){
                            completionStat=false;
                            readyForDispancing=false;
                        }
                        if(item.description===''){
                            completionStat=false;
                        }
                        if(item.howTouse===''){
                            completionStat=false;
                        }
                        if(item.imageUrl.length===0){
                            completionStat=false;
                        }
                        if(item.manufacturerId===''){
                            completionStat=false;
                            readyForDispancing=false;
                        }
                        if(item.maxRetailPrice==='' || parseFloat(item.maxRetailPrice)===0){
                            completionStat=false;
                        }
                        if(item.medicineType===''){
                            completionStat=false;
                        }
                        try {
                            if (item.productIngredients.length === 0) {
                                completionStat = false;
                            }
                        }catch(e){

                        }
                        if(item.productSideEffects===''){
                            completionStat=false;
                        }
                        if(item.productUses===''){
                            completionStat=false;
                        }
                        if(item.quantityUnit===''){
                            completionStat=false;
                            readyForDispancing=false;
                        }
                        if(item.retailPackage===''){
                            completionStat=false;
                            readyForDispancing=false;
                        }
                        // if(item.tags.length===0){
                        //     completionStat=false;
                        // }
                        // temp["confStatus"]=completionStat===true?'Completed':<span className={"text-warning font-weight-bold"}>Incomplete</span>;
                        temp["dispStatus"]=readyForDispancing===true?'Ready':<span className={"text-warning font-weight-bold"}>Not Ready</span>;
                        temp["handle"] = <div color="primary" className={"blue-text btn-link cursor-pointer"} size="sm"
                                              onClick={() => {
                                                  this.onEdit(item)
                                              }}>Update Config</div>;
                        temp["handleStatus"]=<div color="primary" className={"blue-text btn-link cursor-pointer"} size="sm" onClick={()=>{this.initChangeStatus(item)}}>{(item.isActive === "Y") ? "Deactivate" : "Activate"}</div>;
                        temp["handleEdit"] = <><div color="primary" className={"blue-text btn-link cursor-pointer"} size="sm"
                                              onClick={() => {
                                                  this.onEditDesc(item)
                                              }}>Edit</div>
                                                <div color="primary" className={"blue-text btn-link cursor-pointer"} size="sm"
                                                     onClick={() => {
                                                         this.onView(item)
                                                     }}>View</div>

                                                <div color="primary" className={"p-1 blue-text btn-link cursor-pointer"} size="sm"
                                                     onClick={() => {
                                                         this.onProductView(item)
                                                     }}>
                                                    Transactions
                                                </div>
                                                </>;
                        temp["productName"] = item["productName"];
                        temp["productNameSearch"] = item["productName"];
                        temp["_id"]=item["_id"];
                        temp["topSelling"]=item["topSelling"];
                        temp["retailPackage"]=item["retailPackage"];
                        temp["quantityUnit"]=item["quantityUnit"];
                        temp["medicineType"]=item.isMedicine===true?item["medicineType"]:"General";
                        temp["createdAt"]=this.convertToReadbleDate(item["createdAt"]);
                        temp["createdBy"]=item["createdBy"];
                        return temp;
                    })
                };
                this.setState({data: dataTablePayload});
            }
        }else{
            this.setState({products: window.productList});

            let dataTablePayload = {
                columns: dataTableConfig.product,
                rows: window.productList.map((item, index) => {
                    let temp={};
                    let completionStat=true;
                    let readyForDispancing=true;
                    if(item.productName===''){
                        completionStat=false;
                        readyForDispancing=false;
                    }
                    if(item.categories.length===0){
                        completionStat=false;
                    }
                    // if(item.highlight.length===0){
                    //     completionStat=false;
                    // }
                    if(item.contentQuantity==='' || parseFloat(item.contentQuantity)===0){
                        completionStat=false;
                        readyForDispancing=false;
                    }
                    if(item.description===''){
                        completionStat=false;
                    }
                    if(item.howTouse===''){
                        completionStat=false;
                    }
                    if(item.imageUrl.length===0){
                        completionStat=false;
                    }
                    if(item.manufacturerId===''){
                        completionStat=false;
                        readyForDispancing=false;
                    }
                    if(item.maxRetailPrice==='' || parseFloat(item.maxRetailPrice)===0){
                        completionStat=false;
                    }
                    if(item.medicineType===''){
                        completionStat=false;
                    }
                    if(item.productIngredients.length===0){
                        completionStat=false;
                    }
                    if(item.productSideEffects===''){
                        completionStat=false;
                    }
                    if(item.productUses===''){
                        completionStat=false;
                    }
                    if(item.quantityUnit===''){
                        completionStat=false;
                        readyForDispancing=false;
                    }
                    if(item.retailPackage===''){
                        completionStat=false;
                        readyForDispancing=false;
                    }
                    // if(item.tags.length===0){
                    //     completionStat=false;
                    // }
                    temp["confStatus"]=completionStat===true?'Completed':<span className={"text-warning font-weight-bold"}>Incomplete</span>;
                    temp["dispStatus"]=readyForDispancing===true?'Ready':<span className={"text-warning font-weight-bold"}>Not Ready</span>;
                    temp["handle"] = <div color="primary" className={"blue-text btn-link cursor-pointer"} size="sm"
                                          onClick={() => {
                                              this.onEdit(item)
                                          }}>Update Config</div>;
                    temp["handleStatus"]=<div color="primary" className={"blue-text btn-link cursor-pointer"} size="sm" onClick={()=>{this.initChangeStatus(item)}}>{(item.isActive === "Y") ? "Deactivate" : "Activate"}</div>;
                    temp["handleEdit"] = <div color="primary" className={"blue-text btn-link cursor-pointer"} size="sm"
                                              onClick={() => {
                                                  this.onEditDesc(item)
                                              }}>Edit</div>;
                    temp["productName"] = item["productName"];
                    temp["_id"]=item["_id"];
                    temp["topSelling"]=item["topSelling"];
                    temp["retailPackage"]=item["retailPackage"];
                    temp["quantityUnit"]=item["quantityUnit"];
                    temp["medicineType"]=item.isMedicine===true?item["medicineType"]:"General";
                    temp["createdAt"]=this.convertToReadbleDate(item["createdAt"]);
                    temp["createdBy"]=item["createdBy"];
                    return temp;
                })
            };
            this.setState({data: dataTablePayload});
            await this.setState({disableRetreatAfterSorting:false});
            //call product sync api
            this.syncProduct();
        }
        await this.setState({disableRetreatAfterSorting:false});
    }
    syncProduct=async ()=>{
        await this.setState({disableRetreatAfterSorting:true});
        let userData = await UserStore.fetchSessionData();
        let data = await InventoryService.fetchProduct(userData, 0, 0, this.state.type);
        window.productList = data;
        if (data === false) {
            toast.error("We are facing some issue while syncing product");
        } else {
            this.setState({products: data});
            let dataTablePayload = {
                columns: dataTableConfig.product,
                rows: data.map((item, index) => {
                    let temp={};
                    let completionStat=true;
                    let readyForDispancing=true;
                    if(item.productName===''){
                        completionStat=false;
                        readyForDispancing=false;
                    }
                    if(item.categories.length===0){
                        completionStat=false;
                    }
                    // if(item.highlight.length===0){
                    //     completionStat=false;
                    // }
                    if(item.contentQuantity==='' || parseFloat(item.contentQuantity)===0){
                        completionStat=false;
                        readyForDispancing=false;
                    }
                    if(item.description===''){
                        completionStat=false;
                    }
                    if(item.howTouse===''){
                        completionStat=false;
                    }
                    if(item.imageUrl.length===0){
                        completionStat=false;
                    }
                    if(item.manufacturerId===''){
                        completionStat=false;
                        readyForDispancing=false;
                    }
                    if(item.maxRetailPrice==='' || parseFloat(item.maxRetailPrice)===0){
                        completionStat=false;
                    }
                    if(item.medicineType===''){
                        completionStat=false;
                    }
                    if(item.productIngredients.length===0){
                        completionStat=false;
                    }
                    if(item.productSideEffects===''){
                        completionStat=false;
                    }
                    if(item.productUses===''){
                        completionStat=false;
                    }
                    if(item.quantityUnit===''){
                        completionStat=false;
                        readyForDispancing=false;
                    }
                    if(item.retailPackage===''){
                        completionStat=false;
                        readyForDispancing=false;
                    }
                    // if(item.stock<0){
                    //     completionStat=false;
                    // }
                    // if(item.tags.length===0){
                    //     completionStat=false;
                    // }
                    temp["confStatus"]=completionStat===true?'Completed':<span className={"text-warning font-weight-bold"}>Incomplete</span>;
                    temp["dispStatus"]=readyForDispancing===true?'Ready':<span className={"text-warning font-weight-bold"}>Not Ready</span>;
                    temp["handle"] = <div color="primary" className={"blue-text btn-link cursor-pointer"} size="sm"
                                          onClick={() => {
                                              this.onEdit(item)
                                          }}>Update Config</div>;
                    temp["handleStatus"]=<div color="primary" className={"blue-text btn-link cursor-pointer"} size="sm" onClick={()=>{this.initChangeStatus(item)}}>{(item.isActive === "Y") ? "Deactivate" : "Activate"}</div>;
                    temp["handleEdit"] = <div color="primary" className={"blue-text btn-link cursor-pointer"} size="sm"
                                              onClick={() => {
                                                  this.onEditDesc(item)
                                              }}>Edit</div>;
                    temp["productName"] = item["productName"];
                    temp["_id"]=item["_id"];
                    temp["topSelling"]=item["topSelling"];
                    temp["retailPackage"]=item["retailPackage"];
                    temp["quantityUnit"]=item["quantityUnit"];
                    temp["medicineType"]=item.isMedicine===true?item["medicineType"]:"General";
                    temp["createdAt"]=this.convertToReadbleDate(item["createdAt"]);
                    temp["createdBy"]=item["createdBy"];
                    return temp;
                })
            };
            this.setState({data: dataTablePayload});
            await this.setState({disableRetreatAfterSorting:false});
        }
        await this.setState({disableRetreatAfterSorting:false});
    }
    constructor(props) {
        super(props);
        this.state = {
            apiResultCame:false,
            progressStatus:false,
            type:"all",
            products:[],
            modalStatus:false,
            activeProductId:0,
            modalEdit:false,
            modalView:false,
            activeProduct:{

            },
            modalAction:"",
            disableRetreatAfterSorting:false
        }
    }
    convertToReadbleDate = date =>{
        if(date){
            return Moments(date).format("MMM D, YYYY hh:mm A");
        }
    }
    toggleView = () => {
        this.setState({
            modalView: !this.state.modalView
        });
    }

    toggleEdit = () => {
        this.setState({
            modalEdit: !this.state.modalEdit
        });
    }
    onEditComplete = (row) => {
        this.toggleEdit()
        this.fetchData();
    }
    // This triggers from MDBDataTable, aim is to redirect to edit page
    onEditDesc =  (row) => {
        this.setState({activeProduct: row,modalAction:"edit"})
        this.toggleEdit()
    }

    onEdit=(item)=>{
        // this.props.history.push('/product/cms/'+item._id);
        this.setState({activeProduct: item, modalAction:"updateConfig"})
        this.toggleEdit()
    }

    onView =  (row) => {
        this.setState({activeProduct: row})
        this.toggleView()
    }

    // onEditDesc=(item)=>{
    //     this.props.history.push('/edit-product/'+item._id);
    // }
    goToNewProduct=()=>{
        this.props.history.push('/add-product');
    }
    initChangeStatus = (row) => {
        this.setState({ activeProductId:row._id, statusAction: (row.isActive === "Y") ? "deactivate" : "activate", statusActionName: row.productName});
        this.toggleStatus();
    }
    updateStatus = async () => {
        let userData=await UserStore.fetchSessionData();
        this.setState({progressStatus:true});
        let status = (this.state.statusAction === "activate") ? "Y" : "N";
        let res =await PharmacyService.updateProductStatus(this.state,status,userData);
        this.setState({progressStatus:false});
        //calculate the status
        let temp=[];
        for(let item of this.state.products){
            if(item._id===this.state.activeProductId){
                item["isActive"]=status;
                temp.push(item);
            }else{
                temp.push(item);
            }
        }
        this.setState({products:temp});
        this.toggleStatus();
        this.setState({ activeProductId:0, statusAction: "", statusActionName: ""});
        if(res===false){
            toast.error("Something went wrong.");
            return true;
        }else{
            toast.success("Product status updated successfully.");
            this.fetchData();
        }
    }
    toggleStatus = () => {
        this.setState({
            modalStatus: !this.state.modalStatus
        });
    }


    toggleProductView = () => {
        this.setState({
            modalProductView: !this.state.modalProductView
        });
    }
    onProductView =  (row) => {
        console.log(row)
        this.setState({activeProduct: row})
        this.toggleProductView()
    }
    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.progressStatus}/>
                <MDBContainer className={'w-100 mw-100'}>
                    <MDBCard className={"mt-2"}>
                        <MDBCardHeader>
                            <span className={"float-left"}>View Products</span>
                            <MDBFormInline className="float-right md-form mr-auto m-0">
                                <MDBBtn color="default" size="sm" onClick={this.goToNewProduct} className="mr-auto" >
                                    Add Product
                                </MDBBtn>
                            </MDBFormInline>
                        </MDBCardHeader>
                        <MDBCardBody>
                            {(this.state.apiResultCame && this.state.products.length === 0) &&
                                <div className={"no-data-container"}>No Product found.</div>
                            }
                            {this.state.products.length > 0 &&
                            // <MDBDataTable
                            //     hover
                            //     entriesOptions={[10,15, 20, 25]}
                            //     entries={10}
                            //     pagesAmount={10}
                            //     data={this.state.data}
                            //     materialSearch
                            //     searchTop
                            //     searchBottom={false}
                            //     barReverse
                            //     striped
                            //     fullPagination
                            // />
                            <MDBDataTable
                                striped

                                data={this.state.data}
                                materialSearch
                                noBottomColumns
                                hover
                                disableRetreatAfterSorting={this.state.disableRetreatAfterSorting}
                            />
                            }
                        </MDBCardBody>
                    </MDBCard>
                    <MDBModal isOpen={this.state.modalStatus} toggle={this.toggleStatus}>
                        <MDBModalHeader toggle={this.toggleStatus}>Update Product Status</MDBModalHeader>
                        <MDBModalBody>
                            Are you sure you want to {this.state.statusAction} product {this.state.statusActionName}?
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn onClick={this.updateStatus} disabled={this.state.progressStatus}>{this.state.statusAction}</MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>

                    <MDBModal isOpen={this.state.modalEdit} toggle={this.toggleEdit} size={"fluid"}>
                        <MDBModalHeader toggle={this.toggleEdit}></MDBModalHeader>
                        <MDBModalBody className={"float-center"}>
                            {this.state.modalAction === "updateConfig" &&
                            <UpdateProductConfig loadedOnPopup={true} onEditComplete={this.onEditComplete}
                                           productId={this.state.activeProduct._id}/>
                            }
                            {this.state.modalAction === "edit" &&
                            <UpdateProduct loadedOnPopup={true} onEditComplete={this.onEditComplete}
                                           productId={this.state.activeProduct._id}/>
                            }
                        </MDBModalBody>
                    </MDBModal>

                    <MDBModal isOpen={this.state.modalView} toggle={this.toggleView} size={"fluid"}>
                        <MDBModalHeader toggle={this.toggleView}></MDBModalHeader>
                        <MDBModalBody className={"float-center"}>
                            <ProductView loadedOnPopup={true}
                                           productId={this.state.activeProduct._id} product={this.state.activeProduct}/>
                        </MDBModalBody>
                    </MDBModal>

                    <MDBModal isOpen={this.state.modalProductView} toggle={this.toggleProductView} size={"fluid"}>
                        <MDBModalHeader toggle={this.toggleProductView}></MDBModalHeader>
                        <MDBModalBody className={"float-center"}>
                            <SellingHistoryByProduct productId={this.state.activeProduct._id} />
                        </MDBModalBody>
                    </MDBModal>

                </MDBContainer>
            </React.Fragment>
        );
    }
}
export default withRouter(ProductListCmp)


