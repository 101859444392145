import React,{Suspense} from "react";
import {
    MDBBox
} from 'mdbreact';
//const OrgUserList=React.lazy(() => import("../../components/org-user/listOrgUsers"));
import OrgUserList from "../../components/org-user/listOrgUsers";

export default class OrgUserListPage extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MDBBox className={"min-vh-100"}>
                    <Suspense fallback={null}>
                        <OrgUserList/>
                    </Suspense>
                </MDBBox>
            </React.Fragment>
        );
    }
}
