import React, { useEffect, useState } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Select,
  MenuItem,
  Typography,
  Button,
} from "@material-ui/core";

import PharmacyService from "../../services/pharmacyService";
import Barcode from "react-barcode";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { CSVLink } from "react-csv";
import Moments from "moment";
import AutocompleteLocal from "../../newComponents/AutoCompleteLocal";
import { formatBoxList, formatRackList } from "../../utility/utility";

const DynamicShip = ({
  formData,
  productName,
  gatherPayload,
  errorDisplay,
  shipmentDetails,
  isEmptyLoc,
}) => {
  const [formDataFill, setFormDataFill] = useState(formData);
  const TABLE_HEADERS = [
    "Product Name",
    "Storage Location",
    "Floor",
    "Rack",
    "Box",
    "Barcode",
  ];
  const headers = [
    // { label: "Product Name", key: "productName" },
    { label: "Location", key: "selectedLoc" },
    { label: "Floor", key: "selectedFloor" },
    { label: "Rack", key: "selectedRack" },
    { label: "Box", key: "selectedBox" },
    { label: "Barcode", key: "barcode" },
  ];

  const handleLocChange = (selectedName, fieldName, index, indexInside) => {
    setFormDataFill((prevState) => {
      let updatedState = [...prevState];
      updatedState[index][fieldName] = selectedName;
      updatedState[index].selectedFloor = "";
      updatedState[index].selectedRack = "";
      updatedState[index].selectedBox = "";
      updatedState[index].locInfoToSend.locId =
        updatedState[index].storageLocation[indexInside]._id;
      updatedState[index].storageRack = [];
      updatedState[index].storageBox = [];
      return updatedState;
    });
    fetchFloor(formDataFill[index].storageLocation[indexInside]._id, index);
  };

  const fetchFloor = async (locId, index) => {
    try {
      const floorDetails = await PharmacyService.fetchFloorV2(locId);
      if (floorDetails) {
        setFormDataFill((prevState) => {
          let updatedState = [...prevState];
          updatedState[index].storageFloor = floorDetails;
          return updatedState;
        });
      }
    } catch (err) {
      console.log({ err });
    }
  };

  const handleFloorChange = (selectedName, fieldName, index, indexInside) => {
    setFormDataFill((prevState) => {
      let updatedState = [...prevState];
      updatedState[index][fieldName] = selectedName;
      updatedState[index].selectedRack = "";
      updatedState[index].selectedBox = "";
      updatedState[index].locInfoToSend.floorId =
        updatedState[index].storageFloor[indexInside]._id;
      updatedState[index].storageRack = [];
      updatedState[index].storageBox = [];
      return updatedState;
    });
    fetchRack(formDataFill[index].storageFloor[indexInside]._id, index);
  };

  const fetchRack = async (floorId, index) => {
    try {
      const rackDetails = await PharmacyService.fetchRackV2(floorId);
      if (rackDetails) {
        setFormDataFill((prevState) => {
          let updatedState = [...prevState];
          updatedState[index].storageRack = rackDetails;
          updatedState[index].storageBox = [];
          return updatedState;
        });
      }
    } catch (err) {
      console.log({ err });
    }
  };

  const handleRackChange = (selectedName, fieldName, index, indexInside) => {
    console.log({selectedName, fieldName, index, indexInside})
    setFormDataFill((prevState) => {
      let updatedState = [...prevState];
      updatedState[index][fieldName] = selectedName;
      updatedState[index].selectedBox = "";
      updatedState[index].locInfoToSend.rackId =
        updatedState[index].storageRack[indexInside]._id;
      updatedState[index].storageBox = [];
      return updatedState;
    });
    console.log(formDataFill[index].storageRack[indexInside])
    fetchBox(formDataFill[index].storageRack[indexInside]._id, index);
  };

  const fetchBox = async (rackId, index) => {
    try {
      const boxDetails = await PharmacyService.fetchBoxV2(rackId);
      if (boxDetails.length) {
        setFormDataFill((prevState) => {
          let updatedState = [...prevState];
          updatedState[index].storageBox = boxDetails;
          return updatedState;
        });
      }
    } catch (err) {
      console.log({ err });
    }
  };
  const handleBoxChange = (selectedName, fieldName, index, indexInside) => {
    setFormDataFill((prevState) => {
      let updatedState = [...prevState];
      updatedState[index][fieldName] = selectedName;
      updatedState[index].locInfoToSend.boxId =
        updatedState[index].storageBox[indexInside]._id;
      return updatedState;
    });
  };
  const placeHolderManage = (selected, placeholderValue) => {
    if (!selected) {
      return placeholderValue;
    }
    return selected;
  };

  useEffect(() => {
    setFormDataFill(formData);
  }, [formData]);

  return (
    <Grid container>
      <Grid
        container
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "0 .7em",
        }}
      >
        <CSVLink
          data={formDataFill}
          headers={headers}
          filename={
            "Barcodes_" + Moments(Date.now()).format("DD-MM-YYYY") + ".csv"
          }
        >
          <Button
            variant={"outlined"}
            style={{ background: "#3f51b5", color: "white" }}
          >
            Export data
          </Button>
        </CSVLink>
      </Grid>
      <Grid item xs={12} style={{ padding: ".5em" }}>
        <TableContainer component={Paper} style={{overflow: "visible"}}>
          <Table>
            <TableHead>
              <TableRow>
                {TABLE_HEADERS.map((HEADER_NAME, key) => (
                  <TableCell>{HEADER_NAME}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {formDataFill.map((row, key) => (
                <TableRow key={row.id}>
                  <TableCell>
                    <Typography variant="body1">{productName}</Typography>
                  </TableCell>
                  <TableCell>
                    <Select
                      value={row.selectedLoc}
                      displayEmpty
                      renderValue={(selected) =>
                        placeHolderManage(selected, "Select Location")
                      }
                      className="selectBox"
                      style={{
                        border:
                          errorDisplay &&
                          row.selectedLoc == "" &&
                          "2px solid red",
                      }}
                      required
                    >
                      {row.storageLocation?.map((item, index) => (
                        <MenuItem
                          value={item.name}
                          onClick={(event) =>
                            handleLocChange(
                              item.name,
                              "selectedLoc",
                              key,
                              index
                            )
                          }
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                  <TableCell>
                    <Select
                      value={row.selectedFloor || ""}
                      displayEmpty
                      renderValue={(selected) =>
                        placeHolderManage(selected, "Select Floor")
                      }
                      className="selectBox"
                      style={{
                        border:
                          errorDisplay &&
                          row.selectedFloor == "" &&
                          "2px solid red",
                      }}
                      required
                    >
                      {row.storageFloor?.map((item, index) => (
                        <MenuItem
                          value={item.floorName}
                          onClick={(event) =>
                            handleFloorChange(
                              item.floorName,
                              "selectedFloor",
                              key,
                              index
                            )
                          }
                        >
                          {item.floorName}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                  <TableCell>
                    <Select
                      value={row.selectedRack || ""}
                      displayEmpty
                      renderValue={(selected) =>
                        placeHolderManage(selected, "Select Rack")
                      }
                      className="selectBox"
                      style={{
                        border:
                          errorDisplay &&
                          row.selectedRack == "" &&
                          "2px solid red",
                      }}
                      required
                    >
                      {row.storageRack?.map((item, index) => (
                        <MenuItem
                          value={item.rackName}
                          onClick={(event) =>
                            handleRackChange(item.rackName, "selectedRack", key, index)
                          }
                        >
                          {item.rackName}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                  <TableCell>
                     <Select
                      value={row.selectedBox || ""}
                      displayEmpty
                      renderValue={(selected) =>
                        placeHolderManage(selected, "Select Box")
                      }
                      className="selectBox"
                      style={{
                        border:
                          errorDisplay &&
                          row.selectedBox == "" &&
                          "2px solid red",
                      }}
                      required
                    >
                      {row.storageBox?.map((item, index) => (
                        <MenuItem
                          value={item.boxName}
                          onClick={(e) =>
                            handleBoxChange(item.boxName, "selectedBox", key, index)
                          }
                        >
                          {item.boxName}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                  <TableCell className={"barco"}>
                    <Barcode value={row.barcode}></Barcode>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      {isEmptyLoc && (
        <Grid item xs={12}>
          <div
            style={{
              color: "red",
              fontStyle: "italic",
              fontSize: "1em",
              textAlign: "center",
            }}
          >
            Please enter storage details
          </div>
        </Grid>
      )}
      <Grid item xs={12}>
        <div className="subBtnDiv">
          <Button variant="outlined" className="back">
            <Link to="/fetch-stock">Back</Link>{" "}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            className="save"
            onClick={(event) => gatherPayload(formDataFill)}
            disabled={
              shipmentDetails?.length !== 1 ||
              shipmentDetails?.length > 1 ||
              productName?.length == 0
            }
          >
            Save
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export default DynamicShip;
