const dataTableConfig= {
    specialityGroup:[
        {
            label: 'Speciality',
            field: 'specialityGroup',
            sort: 'asc',
            width: 150
        },
        {
            label: 'Created By',
            field: 'createdBy',
            sort: 'asc',
            width: 150
        },
        {
            label: 'Created At',
            field: 'createdAt',
            sort: 'asc',
            width: 150
        },
        {
            label: '',
            field: 'delete',
            sort:'disabled',
            width: 150
        },
        {
            label: '',
            field: 'edit',
            sort:'disabled',
            width: 150
        }
    ]
}
export default dataTableConfig;