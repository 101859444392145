import React from "react";
import { withRouter } from "react-router-dom";
import {
    MDBContainer, MDBDataTable, MDBCardHeader, MDBFormInline, MDBBtn, MDBCardBody, MDBCard,
    MDBModalHeader, MDBModalBody, MDBModal, MDBModalFooter
} from 'mdbreact';
import UserStore from "../../stores/userStore";
import {toast} from "react-toastify";
import dataTableConfig from "../../dataTableConfig";
import PharmacyService from "../../services/pharmacyService";
import SimpleBackdrop from "../common/overlay";
import Moments from "moment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import InventoryService from "../../services/inventoryService";

class ProductSearchCmp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            progressStatus:false,
            type:"all",
            products:[],
            modalStatus:false,
            activeProductId:0,
            productData:[],
            productError:false
        }
    }
    convertToReadbleDate = date =>{
        if(date){
            return Moments(date).format("MMM D, YYYY hh:mm A");
        }
    }
    onEdit=(item)=>{
        this.props.history.push('/product/cms/'+item._id);
    }
    onEditDesc=(item)=>{
        this.props.history.push('/edit-product/'+item._id);
    }
    goToNewProduct=()=>{
        this.props.history.push('/add-product');
    }
    initChangeStatus = (row) => {
        this.setState({ activeProductId:row._id, statusAction: (row.isActive === "Y") ? "deactivate" : "activate", statusActionName: row.productName});
        this.toggleStatus();
    }
    updateStatus = async () => {
        let userData=await UserStore.fetchSessionData();
        this.setState({progressStatus:true});
        let status = (this.state.statusAction === "activate") ? "Y" : "N";
        let res =await PharmacyService.updateProductStatus(this.state,status,userData);
        this.setState({progressStatus:false});
        //calculate the status
        let temp=[];
        for(let item of this.state.products){
            if(item._id===this.state.activeProductId){
                item["isActive"]=status;
                temp.push(item);
            }else{
                temp.push(item);
            }
        }
        this.setState({products:temp});
        this.toggleStatus();
        this.setState({ activeProductId:0, statusAction: "", statusActionName: ""});
        if(res===false){
            toast.error("Something went wrong.");
            return true;
        }else{
            toast.success("Product status updated successfully.");
            this.fetchData();
        }
    }
    toggleStatus = () => {
        this.setState({
            modalStatus: !this.state.modalStatus
        });
    }
    fetchProduct = async (e) => {
        let term=e.target.value;
        try {
            if (term !== '' && term !== null && term !== 0 && term !== '0') {
                let userData = await UserStore.fetchSessionData();
                let data = await InventoryService.suggestProduct(term, userData);
                if(data===false){
                    toast.error("Something went wrong.");
                }else{
                    let temp = [];
                    for (let item of data) {
                        let str = {title: item.productName, value: item};
                        temp.push(str);
                    }
                    if(temp.length>0) {
                        this.setState({productData: temp});
                    }
                }
            }
        }catch (e){
            this.setState({productData:[]});
        }
    }
    selectProduct=async (event, values)=> {
        if ((values && values.hasOwnProperty("value") && values.value !== null)) {
            let data = values.value;
            this.setState({products:[data]});
            let completionStat=true;
            let readyForDispancing=true;
            if(data.productName===''){
                completionStat=false;
            }
            if(data.categories.length===0){
                completionStat=false;
            }
            if(data.highlight.length===0){
                completionStat=false;
            }
            if(data.contentQuantity==='' || parseFloat(data.contentQuantity)===0){
                completionStat=false;
                readyForDispancing=false;
            }
            if(data.description===''){
                completionStat=false;
            }
            if(data.howTouse===''){
                completionStat=false;
            }
            if(data.imageUrl.length===0){
                completionStat=false;
            }
            if(data.manufacturerId===''){
                completionStat=false;
                readyForDispancing=false;
            }
            if(data.maxRetailPrice==='' || parseFloat(data.maxRetailPrice)===0){
                completionStat=false;
                readyForDispancing=false;
            }
            if(data.medicineType===''){
                completionStat=false;
            }
            if(data.productIngredients===''){
                completionStat=false;
            }
            if(data.productSideEffects===''){
                completionStat=false;
            }
            if(data.productUses===''){
                completionStat=false;
            }
            if(data.quantityUnit===''){
                completionStat=false;
                readyForDispancing=false;
            }
            if(data.retailPackage===''){
                completionStat=false;
                readyForDispancing=false;
            }
            if(data.tags.length===0){
                completionStat=false;
            }
            let temp={
               handle:<div color="primary" className={"blue-text btn-link cursor-pointer"} size="sm"
                             onClick={() => {
                                 this.onEdit(data)
                             }}>Update Config</div>,
                handleStatus:<div color="primary" className={"blue-text btn-link cursor-pointer"} size="sm" onClick={()=>{this.initChangeStatus(data)}}>{(data.isActive === "Y") ? "Deactivate" : "Activate"}</div>,
                handleEdit:<div color="primary" className={"blue-text btn-link cursor-pointer"} size="sm"
                                          onClick={() => {
                                              this.onEditDesc(data)
                                          }}>Edit</div>,
                productName:data["productName"],
                _id:data["_id"],
                topSelling:data["topSelling"],
                retailPackage:data["retailPackage"],
                quantityUnit:data["quantityUnit"],
                medicineType:data["medicineType"],
                createdAt:this.convertToReadbleDate(data["createdAt"]),
                createdBy:data["createdBy"]
            }
            temp["dispStatus"]=readyForDispancing===true?'Ready':<span className={"text-warning font-weight-bold"}>Not Ready</span>;
            temp["confStatus"]=completionStat===true?'Completed':'Incomplete';

            let dataTablePayload = {
                columns: dataTableConfig.product,
                rows: [temp]
            }
            this.setState({data: dataTablePayload});
        }
    }
    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.progressStatus}/>
                <MDBContainer className={'w-100 mw-100'}>
                    <MDBCard className={"mt-2"}>
                        <MDBCardHeader>
                            <span className={"float-left title"}>Product Search</span>
                            <MDBFormInline className="float-right md-form mr-auto m-0">
                                <MDBBtn color="default" size="md" onClick={this.goToNewProduct} className="mr-auto" >
                                    New Product
                                </MDBBtn>
                            </MDBFormInline>
                        </MDBCardHeader>
                        <MDBCardBody>
                            <div style={{"width":"25%"}}>
                                <Autocomplete
                                    freeSolo
                                    onChange={this.selectProduct}
                                    id="combo-box-demo"
                                    options={this.state.productData}
                                    getOptionLabel={option => option.title}
                                    onInputChange={(e) => {
                                        this.fetchProduct(e)
                                    }}
                                    selectOnFocus={true}
                                    renderInput={params => (
                                        <TextField style={ this.state.productError ?  {"borderBottom":"1px solid RED"} : {}} {...params} placeholder="Search Product"
                                                   margin="normal" fullWidth/>
                                    )}
                                />
                            </div>
                            {this.state.products.length > 0 &&
                            // <MDBDataTableV5
                            //     hover
                            //     entriesOptions={[10,15, 20, 25]}
                            //     entries={10}
                            //     pagesAmount={44}
                            //     data={this.state.data}
                            //     materialSearch
                            //     searchTop
                            //     searchBottom={false}
                            //     barReverse
                            //     striped
                            // />
                            <MDBDataTable
                                striped

                                data={this.state.data}
                                materialSearch
                                noBottomColumns
                                hover
                            />
                            }
                        </MDBCardBody>
                    </MDBCard>
                    <MDBModal isOpen={this.state.modalStatus} toggle={this.toggleStatus}>
                        <MDBModalHeader toggle={this.toggleStatus}>Update Product Status</MDBModalHeader>
                        <MDBModalBody>
                            Are you sure you want to {this.state.statusAction} product {this.state.statusActionName}?
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color="default" onClick={this.updateStatus} disabled={this.state.progressStatus}>{this.state.statusAction}</MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>
                </MDBContainer>
            </React.Fragment>
        );
    }
}
export default withRouter(ProductSearchCmp)


