import React, { Suspense } from "react";
import { MDBBox, MDBBreadcrumb, MDBBreadcrumbItem } from "mdbreact";
import { Link } from "react-router-dom";
import ReceiveStockCmp from "../../components/receive-stock/receiveStockCmp";

export default class ReceiveStockPage extends React.Component {
  render() {
    return (
      <>
        <MDBBreadcrumb>
          <MDBBreadcrumbItem>
            <Link to={"/dashboard"}>Dashboard</Link>
          </MDBBreadcrumbItem>
          <MDBBreadcrumbItem active>Search Received Stock</MDBBreadcrumbItem>
        </MDBBreadcrumb>
        <MDBBox className={"min-vh-100"}>
          <ReceiveStockCmp />
        </MDBBox>
      </>
    );
  }
}
