import React from "react";
import {Link,withRouter} from "react-router-dom";
import {
    MDBContainer, MDBRow, MDBBox, MDBInput, MDBBtn, MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, MDBNavLink,
    MDBBreadcrumb, MDBBreadcrumbItem, MDBTooltip, MDBSelect,  MDBSelectInput, MDBSelectOptions, MDBSelectOption,
    MDBCardHeader, MDBCardBody, MDBCard, MDBCol, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBModal
} from 'mdbreact';
import UserStore from "../../stores/userStore";
import InventoryService from "../../services/inventoryService";
import Moment from 'react-moment';
import { toast } from 'react-toastify';
import config from "../../config";
import PharmacyService from "../../services/pharmacyService";
import SimpleBackdrop from "../../components/common/overlay";

class Cms extends React.Component {
    async componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({progressStatus: true});

        if(this.props.loadedOnPopup === true){
            let productId = this.props.productId;
            await this.setState({productId:productId, loadedOnPopup:true})
            await this.fetchData();
        } else {
            const { productId } = this.props.match.params;
            this.setState({productId:productId});
            await this.fetchData();
        }

        await this.fetAllCategories();
        this.setState({progressStatus: false});
    }
    fetAllCategories=async ()=>{
        let userData = await UserStore.fetchSessionData();
        let data = await InventoryService.fetAllCategories(userData);
        this.setState({categories:data});
    }
    fetchData=async ()=>{
        window.scrollTo(0, 0);
        let userData = await UserStore.fetchSessionData();
        let data = await InventoryService.fetchProductById(userData,this.state.productId);
        this.setState({productData: data,editedCategories:data.categories});
        try{
            this.setState({amount:data.discount});
        }catch (e) {
            console.log("No discount field added.");
        }
        try{
            this.setState({maxQnty:data.maxQuantity});
        }catch (e) {
            this.setState({maxQnty:0});
        }
        try{
            this.setState({minQnty:data.minQuantity});
        }catch (e) {
            this.setState({minQnty:0});
        }
        try{
            this.setState({reorderQnty:data.reorderThreshold});
        }catch (e) {
            this.setState({reorderQnty:0});
        }
        try{
            this.setState({pickupInstruction:data.shippingDetails.pickupInstruction,shippingInstruction:data.shippingDetails.shippingInstruction,inPersonInstruction:data.shippingDetails.inPersonInstruction,inPerson:data.shippingDetails.inPerson, ship:data.shippingDetails.ship, pickup:data.shippingDetails.pickUp});
        }catch (e) {
            this.setState({inPerson:'N', ship:'N', pickup:"N"});
        }
        this.setState({topSelling:data.topSelling});
        this.setState({mrp:data.maxRetailPrice});
        this.setState({outOfStock:data.outOfStock});
        try {
            if(data.imageUrl.length > 0) {
                this.setState({existingImageUrl: data.imageUrl});
                let lb = []
                for(let i = 0; i < data.imageUrl.length; i++){
                    let item = {
                        src: config.IMAGE_HOST+data.imageUrl[i].filePathOriginal
                    }
                    lb.push(item);
                }
                this.setState({"lightBoxImages":lb, "imageActionNeeded":"Update"})
            } else {
                this.setState({existingImageUrl:[]});
                this.setState({"imageActionNeeded":"Add"})
            }
        } catch (e) {
            this.setState({existingImageUrl:[]});
            this.setState({"imageActionNeeded":"Add"})
        }
    }
    constructor(props) {
        super(props);
        this.state = {
            productId:'',
            activeItem:'1',
            progressStatus:false,
            productData:{categories:[], imageUrl: []},
            categories:[],
            editedCategories:[],
            updateProgress:false,
            imageUrl:[],
            existingImageUrl:[],
            progressStatusFileUpload:false,
            imageActionNeeded:"",
            finishedSaving:"init",
            deleteMode:false,
            activeImage:{
                index:0,
                thumb:""
            },
            modalDelImage:false,
            modalDelete:false,
            selectedImageIndex:0,
            amount:0,
            topSelling:'N',
            mrp:0,
            outOfStock:'',
            maxQnty:0,
            minQnty:0,
            reorderQnty:0,
            inPerson:'N',
            ship:'N',
            pickup:"N",
            inPersonInstruction:"",
            shippingInstruction:"",
            pickupInstruction:"",
            loadedOnPopup:false,
        }
    }
    toggle = tab => e => {
        if (this.state.activeItem !== tab) {
            this.setState({
                activeItem: tab
            });
        }
    };
    toggleDelImage = () => {
        this.setState({
            modalDelImage: !this.state.modalDelImage
        });
    }
    isChecked=(item)=>{
        let status=false;
        for(let val of this.state.editedCategories){
            if(val._id===item._id){
                status=true;
                break;
            }
        }
        return status;
    }
    changeCheckBox=async (val)=>{
        let status=document.querySelector("#checkbox"+val).checked;
        if(status){
            let temp=this.state.editedCategories;
            temp.push(this.state.categories[val]);
            await this.setState({editedCategories:temp});
        }else{
            let temp=[];
            for(let i of this.state.editedCategories){
                if(i._id!==this.state.categories[val]._id){
                    temp.push(i);
                }
            }
            await this.setState({editedCategories:temp});
        }
    }
    updateCategory=async ()=>{
        let userData=await UserStore.fetchSessionData();
        //validations
        if(this.state.editedCategories.length===0){
            toast.error("No categories is selected.");
            return true;
        }
        this.setState({updateProgress: true});
        let status=await InventoryService.updateCategories(this.state.productId,userData,this.state.editedCategories);
        this.setState({updateProgress: false});
        if(status===false){
            toast.error("We are facing some issue. Please try again later.");
        }else{
            toast.success("Category mapping updated successfully.");
        }
    }
    updateProductImage = async (files)=>{
        let userData=await UserStore.fetchSessionData();
        this.setState({updateProgress: true});
        let status=await InventoryService.updateProductImage(this.state,userData,files);
        this.setState({updateProgress: false});
        if(status===false){
            toast.error("We are facing some issue. Please try again later.");
        } else {
            toast.success("Image updated successfully.")
            await this.setState({finishedSavingFile:"done"})
            this.fetchData()
        }
    }
    fileUploadCallback = () => {
        this.fetchData()
    }
    initUploadCallback = () => {
        this.setState({"progressStatusFileUpload":true})
    }
    initDeleteDoc = (index) => {
        let activeImage = {
            index: index,
            thumb: this.state.existingImageUrl[index].filePathThumb
        }
        this.setState({activeImage:activeImage})
        this.toggleDelImage();
    }
    confirmDeleteImage = () => {
        let existingList = this.state.existingImageUrl;
        existingList.splice(this.state.activeImage.index, 1);
        this.setState({"existingImageUrl": existingList})
        this.toggleDelImage();
        this.updateProductImage([])
    }
    initDelete = () => {
        this.toggleDelete()
    }
    confirmDelete = async () => {
        let userData=await UserStore.fetchSessionData();
        this.setState({progressStatus:true});
        let status=await PharmacyService.deleteProduct(this.state,userData);
        this.setState({progressStatus:false});
        if(status===false){
            toast.error("Something went wrong.");
            return true;
        }else{
            toast.success("Product deleted successfully.");
            this.toggleDelete();
            if(this.state.loadedOnPopup === true){
                this.props.onEditComplete()
            } else {
                this.props.history.push('/view-products');
            }

        }
    }
    toggleDelete = () => {
        this.setState({
            modalDelete: !this.state.modalDelete
        });
    }
    showHighlightedImage = (index) => {
        this.setState({selectedImageIndex:index})
    }
    handleAmount=(val)=>{
        this.setState({amount:val});
    }
    updateDiscount= async ()=>{
        let userData=await UserStore.fetchSessionData();
        this.setState({updateProgress: true});
        let status=await PharmacyService.updateDiscount(this.state.outOfStock,this.state.mrp,this.state.topSelling,this.state.amount,this.state.productId,userData);
        this.setState({updateProgress: false});
        if(status===false){
            toast.error("Something went wrong.");
            return true;
        }else{
            toast.success("Other config updated successfully.");
        }
    }
    changeTopSelling=(val)=>{
        this.setState({topSelling:val});
    }
    handleMRP=(val)=>{
        this.setState({mrp:val});
    }
    changeMarkOOS=(val)=>{
        this.setState({outOfStock:val});
    }
    handleMaxQnty=(val)=>{
        this.setState({maxQnty:val});
    }
    handleMinQnty=(val)=>{
        this.setState({minQnty:val});
    }
    handleReorderQnty=(val)=>{
        this.setState({reorderQnty:val});
    }
    updateQuanty= async ()=>{
        //validation
        if(this.state.productId===''){
            toast.error("Invalid productId.");
            return true;
        }
        if(this.state.maxQnty==='' || this.state.maxQnty===0){
            toast.error("Invalid Maximum Quantity.");
            return true;
        }
        if(this.state.minQnty==='' || this.state.minQnty===0){
            toast.error("Invalid Minimum Quantity.");
            return true;
        }
        if(this.state.reorderQnty==='' || this.state.reorderQnty===0){
            toast.error("Invalid Reorder Threshold Quantity.");
            return true;
        }
        let userData=await UserStore.fetchSessionData();
        this.setState({updateProgress: true});
        let status=await PharmacyService.updateQuanty(this.state.productId,this.state.maxQnty,this.state.minQnty,this.state.reorderQnty,userData);
        this.setState({updateProgress: false});
        if(status===false){
            toast.error("Something went wrong.");
            return true;
        }else{
            toast.success("Reorder config updated successfully.");
        }
    }
    handleInPerson=(val)=>{
        this.setState({inPerson:val===true?"Y":"N"});
    }
    handleInPersonInstruction=(val)=>{
        this.setState({inPersonInstruction:val});
    }
    handleShippingInstruction=(val)=>{
        this.setState({shippingInstruction:val});
    }
    handlePickupInstruction=(val)=>{
        this.setState({pickupInstruction:val});
    }
    handleShip=(val)=>{
        this.setState({ship:val===true?"Y":"N"});
    }
    handlePickup=(val)=>{
        this.setState({pickup:val===true?"Y":"N"});
    }
    updateShip=async ()=>{
        //validation
        if(this.state.inPerson==='N' && this.state.ship==='N' && this.state.pickup){
            toast.error("Please add atlest one shipping method.");
            return true;
        }
        let userData=await UserStore.fetchSessionData();
        this.setState({updateProgress: true});
        let status=await PharmacyService.updateProductShipping(this.state.inPersonInstruction,this.state.shippingInstruction,this.state.pickupInstruction,this.state.productId,this.state.inPerson,this.state.ship,this.state.pickup,userData);
        this.setState({updateProgress: false});
        if(status===false){
            toast.error("Something went wrong.");
            return true;
        }else{
            toast.success("Shipping details updated successfully.");
        }
    }
    render() {
        return (
            <React.Fragment>
                {this.state.loadedOnPopup === false &&
                    <>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem><Link to={'/view-products'}>Products</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>CMS</MDBBreadcrumbItem>
                </MDBBreadcrumb>

                </>
                }
                < SimpleBackdrop status={(this.state.progressStatus === true || this.state.updateProgress === true)?true:false}/>
                {this.state.progressStatus === false &&
                <MDBContainer>
                    <MDBCard className={"mt-2"}>
                        <MDBCardHeader>
                            <span className={"float-left"}>CMS</span>

                            <MDBBtn outline color="blue-grey" size="sm" onClick={this.initDelete} className="mr-auto float-right"
                                    disabled={this.state.progressStatus}
                            >
                                Delete
                            </MDBBtn>
                            {this.state.loadedOnPopup === false &&
                            <MDBBtn outline color="blue-grey" size="sm" onClick={() => this.props.history.goBack()}
                                    className="mr-auto float-right" disabled={this.state.progressStatus}>
                                Back
                            </MDBBtn>
                            }
                        </MDBCardHeader>
                        <MDBCardBody>
                    <MDBNav className="nav-tabs mt-5">
                        <MDBNavItem>
                            <MDBNavLink link to="#" active={this.state.activeItem === "1"} onClick={this.toggle("1")}
                                        role="tab">
                                Category Mapping
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink link to="#" active={this.state.activeItem === "2"} onClick={this.toggle("2")} role="tab">
                                Reorder Config
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink link to="#" active={this.state.activeItem === "3"} onClick={this.toggle("3")} role="tab">
                                Other Config
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink link to="#" active={this.state.activeItem === "4"} onClick={this.toggle("4")} role="tab">
                                Shipping Details
                            </MDBNavLink>
                        </MDBNavItem>
                    </MDBNav>
                    <MDBTabContent activeItem={this.state.activeItem}>
                        <MDBTabPane tabId="1" role="tabpanel">
                            <MDBRow>
                                <MDBBox><b>Categories</b>: </MDBBox>
                                <MDBBox>
                                    {this.state.categories.map((item, idx) => (
                                        <MDBInput key={idx} label={item.categoryName} checked={this.isChecked(item)} onChange={()=>{this.changeCheckBox(idx)}} id={"checkbox"+idx} type={"checkbox"}/>
                                    ))}
                                </MDBBox>
                            </MDBRow>
                            <MDBRow>
                                <MDBBtn color="default" onClick={this.updateCategory} disabled={this.state.updateProgress}>Update</MDBBtn>
                            </MDBRow>
                        </MDBTabPane>
                        <MDBTabPane tabId="2" role="tabpanel">
                            <MDBCard className={"mt-2"}>
                                <MDBCardBody>
                                    <MDBRow>
                                        <MDBCol size={"2"} className={"pt-2 mt-2"}>
                                            <MDBTooltip domElement tag="span" material>
                                                <span>Maximum Quantity </span>
                                                <span>Maximum quantity that we like to be present in the inventory.</span>
                                            </MDBTooltip>
                                        </MDBCol>
                                        <MDBCol>
                                            <MDBInput label="Maximum Quantity" value={this.state.maxQnty} type={'number'} getValue={this.handleMaxQnty}/>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol size={"2"} className={"pt-2 mt-2"}>
                                            <MDBTooltip domElement tag="span" material>
                                                <span>Minimum Quantity </span>
                                                <span>Minimum quantity that we like to be present in the inventory.</span>
                                            </MDBTooltip>
                                        </MDBCol>
                                        <MDBCol>
                                            <MDBInput label="Minimum Quantity" value={this.state.minQnty} type={'number'} getValue={this.handleMinQnty}/>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol size={"2"} className={"pt-2 mt-2"}>Reorder Threshold</MDBCol>
                                        <MDBCol>
                                            <MDBInput label="Reorder Quantity" value={this.state.reorderQnty} type={'number'} getValue={this.handleReorderQnty}/>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBBtn color="default" onClick={this.updateQuanty} disabled={this.state.updateProgress}>Update</MDBBtn>
                                    </MDBRow>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBTabPane>
                        <MDBTabPane tabId="3" role="tabpanel">
                            <MDBCard className={"mt-2"}>
                                <MDBCardBody>
                                    <MDBRow>
                                        <MDBCol size={"2"} className={"pt-2 mt-2"}>Discount<br/>(In percentage)</MDBCol>
                                        <MDBCol>
                                            <MDBInput label="Amount" value={this.state.amount} type={'number'} getValue={this.handleAmount}/>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol size={"2"} className={"pt-2 mt-2"}>Is Top Selling</MDBCol>
                                        <MDBCol>
                                            <MDBSelect getValue={this.changeTopSelling}>
                                                <MDBSelectInput selected="Select top selling" />
                                                <MDBSelectOptions>
                                                    <MDBSelectOption value="Y" selected={this.state.topSelling==='Y'?true:false}>Yes</MDBSelectOption>
                                                    <MDBSelectOption value="N" selected={this.state.topSelling==='N'?true:false}>No</MDBSelectOption>
                                                </MDBSelectOptions>
                                            </MDBSelect>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol size={"2"} className={"pt-2 mt-2"}>Override MRP</MDBCol>
                                        <MDBCol>
                                            <MDBInput label="MRP" value={this.state.mrp} type={'number'} getValue={this.handleMRP}/>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol size={"2"} className={"pt-2 mt-2"}>Mark as<br/>Out Of Stock</MDBCol>
                                        <MDBCol>
                                            <MDBSelect getValue={this.changeMarkOOS}>
                                                <MDBSelectInput/>
                                                <MDBSelectOptions>
                                                    <MDBSelectOption value="Y" selected={this.state.outOfStock==='Y'?true:false}>Yes</MDBSelectOption>
                                                    <MDBSelectOption value="N" selected={this.state.outOfStock==='N'?true:false}>No</MDBSelectOption>
                                                </MDBSelectOptions>
                                            </MDBSelect>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBBtn color="default" onClick={this.updateDiscount} disabled={this.state.updateProgress}>Update</MDBBtn>
                                    </MDBRow>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBTabPane>
                        <MDBTabPane tabId="4" role="tabpanel">
                            <MDBRow>
                                <MDBCol>
                                    <MDBInput label="In-Person Delivery" checked={this.state.inPerson==='Y'?true:false} type={'checkbox'} getValue={this.handleInPerson} id={"in-person-id"}/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className={"pl-2"}>
                                <MDBCol className={"pl-4 pr-4"}>
                                    <MDBInput  label="In-Person Delivery Instruction" value={this.state.inPersonInstruction} type={'textarea'} getValue={this.handleInPersonInstruction} />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBInput label="Ship" checked={this.state.ship==='Y'?true:false} type={'checkbox'} getValue={this.handleShip} id={"ship-check"}/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className={"pl-2"}>
                                <MDBCol className={"pl-4 pr-4"}>
                                    <MDBInput label="Shipping Instructions" value={this.state.shippingInstruction}  type={'textarea'} getValue={this.handleShippingInstruction} />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBInput label="Pickup" checked={this.state.pickup==='Y'?true:false} type={'checkbox'} getValue={this.handlePickup} id={"pickup-id"}/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className={"pl-2"}>
                                <MDBCol className={"pl-4 pr-4"}>
                                    <MDBInput label="Pickup Instructions" value={this.state.pickupInstruction} type={'textarea'} getValue={this.handlePickupInstruction} />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBBtn color="default" onClick={this.updateShip} disabled={this.state.updateProgress}>Update</MDBBtn>
                            </MDBRow>
                        </MDBTabPane>
                    </MDBTabContent>
                        </MDBCardBody>
                    </MDBCard>
                    <MDBModal isOpen={this.state.modalDelImage} toggle={this.toggleDelImage}>
                        <MDBModalHeader toggle={this.toggleDelImage}>Delete Image</MDBModalHeader>
                        <MDBModalBody>
                            Are you sure you want to delete this image?
                            {this.state.existingImageUrl.length > 0 &&
                                <p>
                                    <img
                                        src={config.IMAGE_HOST + this.state.activeImage.thumb}
                                        className={"float-left"} style={{maxWidth: "100px", maxHeight: "100px"}} alt={""} />
                                </p>
                            }
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn disabled={this.state.progressStatus} color="primary" onClick={this.confirmDeleteImage}>Delete</MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>
                    <MDBModal isOpen={this.state.modalDelete} toggle={this.toggleDelete}>
                        <MDBModalHeader toggle={this.toggleDelete}>Delete Product</MDBModalHeader>
                        <MDBModalBody>
                            Are you sure you want to delete this product?
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn onClick={this.confirmDelete} disabled={this.state.progressStatus}>Delete</MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>
                </MDBContainer>
                }
            </React.Fragment>
        );
    }
}
export default withRouter(Cms);
