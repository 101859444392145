import React from "react";
import {
    MDBBox,
    MDBBreadcrumb,
    MDBBreadcrumbItem,
    MDBCard, MDBCardBody,
    MDBCardHeader,
    MDBCol,
    MDBContainer,
    MDBDataTable,
    MDBRow
} from "mdbreact";
import {Link} from "react-router-dom";
import Moments from "moment";
import {toast} from "react-toastify";
import ReportService from "../../services/reports";
import UserStore from "../../stores/userStore";
import SimpleBackdrop from "../../components/common/overlay";
import GetDateRange from "../../components/common/getDateRange";
import utility from "../../utility/utility";
import dataTableConfig from "./dataTableSalesIntelligence"
import { withRouter } from "react-router-dom";

class SalesIntelligence extends React.Component {
    constructor() {
        super();
        this.state = {
            data: [],
            frequentProducts:[],
            frequentCustomers:[],
            topCustomers:[],
            headers: [],
            progressStatus: false,
            startDate:'',
            endDate:'',

        }
    }

    async componentDidMount() {
        this.getDateRangeCallback(Moments(new Date()).format("YYYY-MM-DD"), Moments(new Date()).format("YYYY-MM-DD"))
    }
    getDateRangeCallback = (startDate, endDate) => {
        if (startDate > endDate) {
            toast.info("Start date is greater than the end date. Please adjust the date range again.")
            return
        }
        this.getData(startDate, endDate).then(res => {
            if (res === false) {
                toast.error("We are facing some issue. Please try again later.");
            } else {
                this.formatData(res)
            }
        })
    }

    formatData = async (data) => {
        await this.setState({data: data[0], salesApiResultCame:true});
        let frequentProducts={
            columns:dataTableConfig.mostFrequentProducts,
            rows:data[0].mostFrequentProducts.map((item, index) => {
                item["handleProductName"]=item._id;
                return item;
            })
        };
        let frequentCustomers={
            columns:dataTableConfig.mostFrequentCustomers,
            rows:data[0].mostFrequentCustomers.map((item, index) => {
                item["handleCustomerName"]=item._id.name;
                item["handleContactNumber"]=item._id.contactNo;
                item["viewCustomerInsights"]=<div color="primary" className={"blue-text btn-link cursor-pointer"} size="sm" onClick={()=>{this.viewCustomerInsights(item)}}>View Insights</div>;

                return item;
            })
        };
        let topCustomers={
            columns:dataTableConfig.topCustomers,
            rows:data[0].topCustomers.map((item, index) => {
                item["handleCustomerName"]=item._id.name;
                item["handleContactNumber"]=item._id.contactNo;
                item["totalPurchase"]= item.totalPurchase ? item.totalPurchase.toFixed(2) : item.totalPurchase;
                item["viewCustomerInsights"]=<div color="primary" className={"blue-text btn-link cursor-pointer"} size="sm" onClick={()=>{this.viewCustomerInsights(item)}}>View Insights</div>;

                return item;
            })
        };
        console.log(frequentProducts)
        await this.setState({frequentProducts:frequentProducts, frequentCustomers:frequentCustomers, topCustomers: topCustomers });
    }
    getData = async (startDate, endDate) => {
        let userData = await UserStore.fetchSessionData();
        this.setState({progressStatus: true});
        let data = await ReportService.fetchSalesIntelligence(userData, Moments(startDate).format("YYYY-MM-DD"), Moments(endDate).format("YYYY-MM-DD"));
        this.setState({progressStatus: false});
        return data
    }

    viewCustomerInsights = (item) => {
        this.props.history.push('/customer-insights/'+item._id._id);
    }


    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.progressStatus}/>

                <MDBContainer >
                    <MDBRow className={"ml-1 mt-5 d-flex flex-column"}>
                        <MDBCol>
                            <GetDateRange getDateRangeCallback={this.getDateRangeCallback}/>
                        </MDBCol>
                        {/*{(this.state.data.length > 0) && !this.state.progressStatus &&(*/}
                        {/*    <MDBCol className={"mt-5"}>*/}
                        {/*        <span><h6>&nbsp;<b>Note:</b> Download report for the date range, from <b>{utility.convertToReadbleDate(this.state.startDate)}</b> to <b>{utility.convertToReadbleDate(this.state.endDate)}</b>.</h6></span>*/}
                        {/*    </MDBCol>*/}
                        {/*)}*/}

                        {this.state.data.length === 0 && !this.state.progressStatus &&(
                            <MDBCol className={"mt-5"}>
                                <h6><b>Note:</b> No data found for the date range, from <b>{ utility.convertToReadbleDate(this.state.startDate)}</b> to <b>{utility.convertToReadbleDate(this.state.endDate)}</b></h6>
                            </MDBCol>
                        )}
                    </MDBRow>
                    {!this.state.progressStatus &&(
                    <MDBRow>
                        <MDBCol>
                            <MDBCard className={"mt-2"}>
                                <MDBCardHeader>
                                    <span>Top Customers</span>
                                </MDBCardHeader>

                                <MDBCardBody>
                                    <MDBDataTable
                                        striped

                                        data={this.state.topCustomers}
                                        materialSearch
                                        noBottomColumns
                                        hover
                                        disableRetreatAfterSorting={false}
                                    />
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol>

                            <MDBCard className={"mt-2"}>
                                <MDBCardHeader>
                                    Frequent Customers
                                </MDBCardHeader>

                                <MDBCardBody>
                                    <MDBDataTable
                                        striped

                                        data={this.state.frequentCustomers}
                                        materialSearch
                                        noBottomColumns
                                        hover
                                        disableRetreatAfterSorting={false}
                                    />
                                </MDBCardBody>
                            </MDBCard>


                        </MDBCol>
                    </MDBRow>
                    )}
                    {!this.state.progressStatus &&(
                    <MDBRow>
                        <MDBCol>
                            <MDBCard className={"mt-2"}>
                                <MDBCardHeader>
                                    Frequent Buys
                                </MDBCardHeader>
                                <MDBCardBody>
                                    <MDBDataTable
                                        striped

                                        data={this.state.frequentProducts}
                                        materialSearch
                                        noBottomColumns
                                        hover
                                        disableRetreatAfterSorting={false}
                                    />
                                </MDBCardBody>
                            </MDBCard>


                        </MDBCol>

                    </MDBRow>
                    )}

                </MDBContainer>
            </React.Fragment>
        )
    }
}

export default withRouter(SalesIntelligence)