import {
  MDBSelect,
  MDBSelectInput,
  MDBSelectOption,
  MDBSelectOptions,
} from "mdbreact";
import React, { useEffect, useState, useCallback } from "react";
import PharmacyService from "../../services/pharmacyService";
import { toast } from "react-toastify";
import UserStore from "../../stores/userStore";

export default function SelectLocationCmp({ locationId,floorDetails, onDataFromChild }) {
  const [storageLoc, setStorageLoc] = useState("");
  const [floorId, setFloorId] = useState("");
  const [rackId, setRackID] = useState("");

  const [optionsFloor, setOptionsFloor] = useState([]);
  const [optionsRack, setOptionsRack] = useState([]);
  const [optionsBox, setOptionsBox] = useState([]);

  useEffect(() => {
    // fetchFloor(locationId);
    setStorageLoc(locationId);
  }, []);

  // const fetchFloor = async (locationId) => {
  //   let userData = await UserStore.fetchSessionData();
  //   let floorInfo = await PharmacyService.fetchFloorV3(locationId,userData);
  //   if (floorInfo === false) {
  //     toast.error("We are facing some server issue. Please try again later.");
  //     // console.log("We are facing some server issue. Please try again later.");
  //   } else {
  //     let temp = [];
  //     for (let item of floorInfo) {
  //       temp.push({ text: item.floorName, value: item });
  //     }
  //     setOptionsFloor(temp);
  //   }
  // };

  const fetchRack = async (FLOORID) => {
    let userData = await UserStore.fetchSessionData();
    let rackInfo = await PharmacyService.fetchRackV3(FLOORID,userData);
    if (rackInfo === false) {
      toast.error("We are facing some server issue. Please try again later.");
      // console.log("We are facing some server issue. Please try again later.");
    } else {
      let temp = [];
      for (let item of rackInfo) {
        temp.push({ text: item.rackName, value: item });
      }
      setOptionsRack(temp);
    }
  };

  const fetchBox = async (RACKID) => {
    let temp = [];
    let userData = await UserStore.fetchSessionData();
    let boxInfo = await PharmacyService.fetchBoxV3(RACKID,userData);
    if (boxInfo === false) {
      toast.error("We are facing some server issue. Please try again later.");
      // console.log("We are facing some server issue. Please try again later.");
    } else {
      if (boxInfo.length > 0) {
        for (let item of boxInfo) {
          temp.push({ text: item.boxName, value: item });
        }
        setOptionsBox(temp);
      } else {
        toast.error("Box configuration not found for this rack");
      }
    }
  };

  const handleFloorChange = (e) => {
    const selectedFloorId = e[0].value._id;
    fetchRack(selectedFloorId)
    setFloorId(selectedFloorId);
  };

  const handleRackChange = (e) => {
    const selectedRackId = e[0].value._id;
    fetchBox(selectedRackId)
    setRackID(selectedRackId);
  };

  const handleBoxChange = (e) => {
    const data={
      rackId:rackId,
      floorId:floorId,
      boxId:e[0].value._id,
  }
    onDataFromChild(data)   
  };
  return (
    <>
      <td>
        <MDBSelect
          getValue={(e) => {
            handleFloorChange(e);
          }}
        >
          <MDBSelectInput selected="Select Floor" />
          <MDBSelectOptions>
            {floorDetails?.map((item, idx) => (
              <MDBSelectOption key={item.value._id || idx} value={item}>
                {item.text}
              </MDBSelectOption>
            ))}
          </MDBSelectOptions>
        </MDBSelect>
      </td>
      <td>
        <MDBSelect
          getValue={(e) => {
            handleRackChange(e);
          }}
        >
          <MDBSelectInput selected="Select Rack" />
          <MDBSelectOptions>
            {optionsRack.map((item, idx) => (
              <MDBSelectOption key={item.value._id || idx} value={item}>
                {item.text}
              </MDBSelectOption>
            ))}
          </MDBSelectOptions>
        </MDBSelect>
      </td>
      <td>
        <MDBSelect
          // onFocus={fetchBox}
          getValue={(e) => {
            handleBoxChange(e);
          }}
        >
          <MDBSelectInput selected="Choose Box" />
          <MDBSelectOptions>
            {optionsBox.map((item, idx) => (
              <MDBSelectOption key={item.value._id || idx} value={item}>
                {item.text}
              </MDBSelectOption>
            ))}
          </MDBSelectOptions>
        </MDBSelect>
      </td>
    </>
  );
}
