import Moments from "moment";
import orgConfig from "../orgConfig";
import config from "../config";
import validDataSetConfig from "../validDataSetConfig";
import React from "react";
import { MDBIcon } from "mdbreact";

let validateEmail = function( email ) {
    const re = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
    if ( re.test(email)) {
        return true;
    }
    else {
        return false;
    }
}

let validateUrl= function( url ) {
    const re = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/g
    if ( re.test(url)) {
        return true;
    } else {
        return false;
    }
}

let checkPhone= function (phone) {
    let a = "";
    if(orgConfig.PHONE_NUMBER_LENGTH === 9){
        a = /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{3})$/.test(phone);
    } else {
        a = /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/.test(phone);
    }
    return a;
}
let checkPassword =  function(input){
    //let RE = /^.*(?=.{6,})(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>_-])(?=.*\d).*$/;      // /^.*(?=.{6,})(?=.*[A-z])(?=.*\d).*$/;
    //let RE = /^.*(?=.{6,}).*$/;
    let RE = /^.*(?=.{6,})(?=.*[A-z])(?=.*\d).*$/;
    return (RE.test(input))
}
let isCurrency=function (input){
    var RE = /^-{0,1}\d*\.{0,1}\d+$/;
    if(RE.test(input)===false){
        return false;
    }else{
        let splt=input.split(".");
        if(splt.length>1){
            if(splt[1].length>2){
                return false;
            }else{
                return true;
            }
        }else{
            return  true;
        }
    }
}
let isFloat = function (input) {
    var RE = /^-{0,1}\d*\.{0,1}\d+$/;
    return (RE.test(input));
}
let isNumeric = function (input) {
    var RE = /^-?\d*\.?\d*$/;
    return (RE.test(input));
}

let isNumericTwoDecimalPartial = function (input) {
    var RE = /^\d+(?:\.\d{0,2})?$/;
    return (RE.test(input));
}
let isNumericTwoDecimalFull = function (input) {
    var RE = /^\d+(?:\.\d{1,2})?$/;
    return (RE.test(input));
}

let isInt = function (input) {
    var RE = /^\d+$/
    return (RE.test(input));
}

let passwordLenth = function (input) {
    var RE = /^.*(?=.{6,}).*$/
    return (RE.test(input));
}
let passwordLetter = function (input) {
    var RE = /^.*(?=.*[a-zA-Z]).*$/
    return (RE.test(input));
}
let passwordDigit = function (input) {
    var RE = /^.*(?=.*\d).*$/
    return (RE.test(input));
}
let isEmpty=function (obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

let stringifyAddress = function(address) {
    let addressString = "";
    if(address.hasOwnProperty("address") && address.address){
        addressString = address.address.trim();
    }
    if(address.hasOwnProperty("street") && address.street){
        addressString = addressString + ", "+address.street.trim();
    }

    if(address.hasOwnProperty("area") && address.area){
        addressString = addressString + ", "+address.area.trim();
    }

    if(address.hasOwnProperty("city") && address.city){
        addressString = addressString + ", "+address.city.trim();
    }

    if(address.hasOwnProperty("state") && address.state){
        addressString = addressString + ", "+address.state.trim();
    }

    if(address.hasOwnProperty("country") && address.country){
        addressString = addressString + ", "+address.country.trim();
    }

    if(address.hasOwnProperty("pincode") && address.pincode){
        addressString = addressString + ", "+address.pincode.trim();
    }
    return addressString;
}

let convertToReadbleDateTime  = function (date) {
    if(date){
        return Moments(date).format("MMM D, YYYY hh:mm A");
    }
}

let convertToReadbleDateTimeUtc = date =>{
    if(date){
        return Moments.utc(date).local().format("MMM D, YYYY hh:mm A");
    }
}

let ecomDateFormat = date =>{
    if(date){
        return Moments.utc(date).local().format("DD-MMM-YYYY");
    }
}

let convertMfgOrExpiry = date =>{
    if(date){
        return Moments(date).format("MMM YYYY");
    }
    return ""
}

let tolowerCase= function (str){
    if(str){
        return str.toString().toLowerCase();
    } else {
        return true
    }
}


let ifFileIsDocument = (row)=>{
    if((row.indexOf(".pdf") >= 0) || (row.indexOf(".PDF") >= 0) || (row.indexOf(".doc") >= 0) || (row.indexOf(".DOC") >= 0) || (row.indexOf(".docx") >= 0) || ((row.indexOf(".DOCX") >= 0) || (row.indexOf(".txt") >= 0) || (row.indexOf(".TXT") >= 0))){
        return true;
    } else {
        return false;
    }
}

let ifFileIsImage = (row)=>{
    if((row.indexOf(".png") > 0) || (row.indexOf(".PNG") > 0 || row.indexOf(".jpg") > 0) || (row.indexOf(".JPG") > 0) || (row.indexOf(".jpeg") > 0) || (row.indexOf(".JPEG") > 0) || (row.indexOf(".gif") > 0) || (row.indexOf(".GIF") > 0) || (row.indexOf(".bmp") > 0) || (row.indexOf(".BMP") > 0)){
        return true;
    } else {
        return false;

    }
}

let secureFilePath = (sessionToken, userId, row)=>{
    if(row && userId && sessionToken){
        return config.UPLOADED_DOC_HOST+sessionToken+"/"+userId+"/"+ row
    } else {
        return false
    }
}

let checkAllowedDocumentType = (file)=>{
    try {
        if (config.allowedDocumentType.indexOf(file.type) >= 0) {
            return true;
        } else {
            return false
        }
    } catch (e) {
        return false
    }
}

let checkAllowedImageType = (file)=>{
    try {
        if (config.allowedImageType.indexOf(file.type) >= 0) {
            return true;
        } else {
            return false
        }
    } catch (e) {
        return false
    }
}

let checkMaxAllowedFileSize = (file)=>{
    try {
        if (file.size < config.MAX_FILE_SIZE) {
            return true;
        } else {
            return false
        }
    } catch (e) {
        return false
    }
}

let customRound = (num) => {
    if(!num){
        return 0;
    }
    return (Math.round(num * 100) / 100).toFixed(2);

}

let prepareArray = (list) => {
    let newList = []
    for(let i=0; i<list.length; i++){
        newList.push(list[i].value)
    }
    return newList
}


let calculatePerUnitPrice = (mrp, contentQuantity) => {
    if(mrp > 0) {
        return (mrp/contentQuantity).toFixed(3)
    }
    return 0
}
let calculateGSTAmount = (mrp, gstPercentage, quantity, contentQuantity, discount, looseSaleAllowed) => {
    if (looseSaleAllowed === "N") {
        contentQuantity = 1
    }
    if(mrp > 0 && gstPercentage > 0) {
        //GST Amount = mrp – [mrp x {100/(100+GST%)}], Base price = mrp – GST Amount.
        let taxableAmount = calculateSellingPrice(mrp, quantity, discount, contentQuantity)
        let totalGstAmount = taxableAmount - (taxableAmount * (100 / (100 + gstPercentage)))
        return totalGstAmount.toFixed(2)
    }
    return 0
}
let calculateDiscountAmount = (mrp, quantity, discount, contentQuantity) => {
    if(mrp > 0 && discount > 0){
        let subTotal = subTotalAmount(mrp, quantity, contentQuantity),
            discountAmount = subTotal * (discount / 100);
        return discountAmount.toFixed(2);
    }
    return 0;
}
let calculateSubTotal = (mrp, contentQuantity, quantity) => {
    if(mrp > 0){
        let subTotal = subTotalAmount(mrp, quantity, contentQuantity)
        return subTotal.toFixed(2)
    }
    return 0
}
let calculatePurchasePrice = (priceWgst, contentQuantity, quantity) => {
    if(priceWgst > 0) {
        let purchasePrice = subTotalAmount(priceWgst, quantity, contentQuantity)
        return purchasePrice.toFixed(2)
    }
    return 0
}

let calculateLooseQtyAndWholeStripsQty = (quantity, contentQuantity) => {
    let result = { looseQuantities: 0, noOfStrips: 0}
    result.looseQuantities = (quantity % contentQuantity) //the remainder will be the no. of loose quantities
    result.noOfStrips = Math.floor(quantity / contentQuantity) //the quotient will the no. of strips
    return result
}

let subTotalAmount = (mrp, quantity, contentQuantity) => {
    let subtotal = 0, perUnitPrice = 0, subtotalForLooseQuantities = 0, subtotalForWholeStrips = 0
    if(contentQuantity > 1) {
        let looseSaleAndWholeStrips = calculateLooseQtyAndWholeStripsQty(quantity, contentQuantity)
        if(looseSaleAndWholeStrips.looseQuantities > 0) {
            perUnitPrice = (mrp/contentQuantity)
            subtotalForLooseQuantities = looseSaleAndWholeStrips.looseQuantities * perUnitPrice
        }
        if (looseSaleAndWholeStrips.noOfStrips > 0) {
            subtotalForWholeStrips =  looseSaleAndWholeStrips.noOfStrips * mrp
        }
        subtotal = subtotalForLooseQuantities + subtotalForWholeStrips
    } else {
        subtotal = mrp * quantity
    }
    return subtotal
}

let calculateSellingPrice = (mrp, quantity, discount, contentQuantity) => {
    if(mrp > 0){
        let subtotal = subTotalAmount(mrp, quantity, contentQuantity)
        if(discount > 0) {
            subtotal = subtotal - (subtotal * (discount / 100))
            return subtotal.toFixed(2)
        }
        return subtotal.toFixed(2)
    }
    return 0;
}
let calculateContentQuantity = (contentQuantity, looseSaleAllowed) => {
    if(looseSaleAllowed === "Y") {
        return contentQuantity
    }
    return 1
}

let validateContentQuantity = (contentQuantity) => {
    if(isInt(contentQuantity) && contentQuantity > 0){
        return true
    } else {
        return false;
    }
}

let validateMrp= (mrp) => {
    if(isNumeric(mrp) && mrp > 0){
        return true
    } else {
        return false;
    }
}

let validateStock= (stock) => {
    if(isNumeric(stock) && stock > 0){
        return true
    } else {
        return false;
    }
}

let validateMedicineType = (isMedicine, medicineType) => {
    if(isMedicine){
        if(validDataSetConfig.MEDICINE_TYPE.indexOf(medicineType) >= 0 ){
            return true;
        }
    } else {
        return true
    }
}

let validateRetailPackage = (retailPackage) => {
    console.log(retailPackage)
    console.log(validDataSetConfig.RETAIL_PACKAGE)
    if(retailPackage){
        if(validDataSetConfig.RETAIL_PACKAGE.indexOf(retailPackage) >= 0 ){
            return true;
        }
    } else {
        return false
    }
}

let validateQuantityUnit = (quantityUnit) => {
    if(quantityUnit){
        if(validDataSetConfig.QUANTITY_UNIT.indexOf(quantityUnit) >= 0 ){
            return true;
        }
    } else {
        return false
    }
}

let validateImages = (imageUrls) => {
    if(imageUrls){
        if(imageUrls.length > 0 ){
            return true;
        }
    } else {
        return false
    }
}

let calculateGst=function (amount,gst){
    return (parseFloat(parseFloat(amount))+(parseFloat(gst)/100)*parseFloat(amount)).toFixed(2);
}

let getMedicineType = function (item){
    if (item.hasOwnProperty("type") && item.type === "N") {
        if (item.productType === "Medicine") {
            return item.medicineType
        } else {
            return item.productType
        }
    } else {
        return item.isMedicine === true ? item.medicineType : "General" ;
    }
}

let getState = function (abbriviation) {
    let stateArray = {
        "AN": "Andaman and Nicobar Islands",
        "AP": "Andhra Pradesh",
        "AR": "Arunachal Pradesh",
        "AS": "Assam",
        "BR": "Bihar",
        "CG": "Chandigarh",
        "CH": "Chhattisgarh",
        "DH": "Dadra and Nagar Haveli",
        "DD": "Daman and Diu",
        "DL": "Delhi",
        "GA": "Goa",
        "GJ": "Gujarat",
        "HR": "Haryana",
        "HP": "Himachal Pradesh",
        "JK": "Jammu and Kashmir",
        "JH": "Jharkhand",
        "KA": "Karnataka",
        "KL": "Kerala",
        "LD": "Lakshadweep",
        "MP": "Madhya Pradesh",
        "MH": "Maharashtra",
        "MN": "Manipur",
        "ML": "Meghalaya",
        "MZ": "Mizoram",
        "NL": "Nagaland",
        "OR": "Odisha",
        "PY": "Puducherry",
        "PB": "Punjab",
        "RJ": "Rajasthan",
        "SK": "Sikkim",
        "TN": "Tamil Nadu",
        "TS": "Telangana",
        "TR": "Tripura",
        "UP": "Uttar Pradesh",
        "UK": "Uttarakhand",
        "WB": "West Bengal"
    }
    return stateArray[abbriviation]
}
let convertToReadbleDate  = function (date) {
    if(date){
        return Moments(date).format("MMM D, YYYY");
    }
}
let convertToReadbleDaySmart= function (date) {
    return Moments(date).format("MMM D, YYYY");
}
let convertToReadbleMonthSmart= function (date) {
    return Moments(date).format("MMM YYYY");
}
let convertToReadbleDaySmart2= function (date) {
    return Moments(date).format("MMM D, YYYY");
}
let firstLetter=function (s) {
    return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
    // return s.replace(/^.{1}/g, s[0].toUpperCase());
}

let FirstLetterEachWord = function(s){
    if(!s){
        return ""
    }
    s = s.toLowerCase();
    const finalString = s.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    return finalString
}

let getTrackingLink = function (courier, awb) {
    if(!courier || !awb)
        return ""

    courier = courier.split(" ")[0]

    if(courier.toLowerCase() == "amazon"){
        return config.AMAZON_TRACKING_LINK+awb;
    }

    if(courier.toLowerCase() == "delhivery"){
        return config.DELHIVERY_TRACKING_LINK+awb;
    }

    if(courier.toLowerCase() == "ecom" || courier.toLowerCase() == "ecom express"){
        return config.ECOMEXPRESS_TRACKING_LINK+awb;
    }

    if(courier.toLowerCase() == "ekart"){
        return config.EKART_TRACKING_LINK+awb;
    }

    if(courier.toLowerCase() == "xpressbees"){
        return config.XPRESSBEES_TRACKING_LINK+awb;
    }

    if(courier.toLowerCase() == "dtdc"){
        return config.DTDC_TRACKING_LINK+awb;
    }

    if(courier.toLowerCase() == "bluedart"){
        return config.BLUEDART_TRACKING_LINK+awb;
    }

    return config.AFTERSHIP_LINK+"/"+courier.toLowerCase()+"/"+awb

}

let getTaxableAmount = function(cartData) {
    let taxableAmounts = [], gstAmount = 0, taxable = 0, cgst = 0, sellingPrice = 0

    let serialNoArray = [], k = 1, gstCollection = []
    for (let i = 0; i < cartData.length; i++) {
        if (cartData[i].hasOwnProperty("inventory")) {
            for (let j = 0; j < cartData[i].inventory.length; j++) {
                serialNoArray.push({index: i, index1: j, serialNo: k})
                ++k;
                //Getting the collection of available gsts in the order
                let cgst = (cartData[i].inventory[j].gst / 2)
                if (gstCollection.indexOf(cgst) === -1) {
                    gstCollection.push(cgst)
                }
            }
        }
    }
    //prepare table (gst, taxable, tax)
    for (let item of gstCollection) {
        taxableAmounts.push({gst: item, taxable: 0, tax: 0})
    }






    for (let row of cartData) {
        if (row.hasOwnProperty("inventory")) {
            for (let item of row.inventory) {
                if (row.discount > 0) {
                    sellingPrice = (item.mrp - (item.mrp * row.discount / 100)) * item.quantityOut
                } else {
                    sellingPrice = item.mrp * item.quantityOut
                }
                gstAmount = sellingPrice - (sellingPrice * (100 / (100 + item.gst)))
                taxable = sellingPrice - gstAmount
                cgst = (item.gst / 2)
                for (let i = 0; i < taxableAmounts.length; i++) {
                    if (taxableAmounts[i].gst === cgst) {
                        taxableAmounts[i].taxable = taxableAmounts[i].taxable + taxable
                    }
                }
            }
        }
    }
    for (let i = 0; i < taxableAmounts.length; i++) {
        taxableAmounts[i].tax = taxableAmounts[i].taxable * (taxableAmounts[i].gst / 100)
    }
    return taxableAmounts
}

let getTotalDiscount = function(cartData){
    let totalDiscount = 0, discount = 0
    for (let row of cartData) {
        if (row.hasOwnProperty("inventory")) {
            for (let item of row.inventory) {
                discount = this.calculateDiscountAmount(item.mrp, item.quantityOut, row.discount, 1)
                totalDiscount = totalDiscount + parseFloat(discount)
            }
        }
    }
    return totalDiscount
}

let getTotalCgst = function(taxableAmounts){
    let totalCgst = 0
    for (let item of taxableAmounts) {
        totalCgst = totalCgst + item.tax
    }
    return totalCgst
}

let getHSN = function (cartData) {
    for (let row of cartData) {
        if (row.hasOwnProperty("inventory")) {
            for (let item of row.inventory) {
                if(item.hasOwnProperty("hsn")){
                    return item.hsn
                }
            }
        }
    }
    return ""
}

let getNoOfItem = function (cartData) {
    let count = 0
    for (let row of cartData) {
        if (row.hasOwnProperty("inventory")) {
            for (let item of row.inventory) {
                count++;
            }
        }
    }
    return count
}

function convertSvgToPng(svgBase64, callback) {
    const NativeImage = window.Image;
    const img = new NativeImage();
    img.src = svgBase64;
  
    console.log(img.src);
    
    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
  
      canvas.width = img.width;
      canvas.height = img.height;
  
      ctx.drawImage(img, 0, 0);
  
      let pngDataUrl = canvas.toDataURL("image/png");
      callback(pngDataUrl);
    };
  }

  export function textFormatter(input) {
    if (input.includes("_")) {
      let words = input
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1));
      return words.join(" ");
    } else {
      return input;
    }
  }
  
  export const createImageUrl = (item, height=86,width=86) => {
    if (item.match(/.(jpg|jpeg|png|gif)$/i)) {
      return (
        <a href={config.IMAGE_HOST + item} target={"_blank"}>
          <img src={config.IMAGE_HOST + item} width={width} height={height} download />
        </a>
      );
    } else {
      return (
        <a href={config.UPLOADED_DOC_HOST + item} target={"_blank"} download>
          <MDBIcon size={"6x"} icon="file" />
        </a>
      );
    }
  };

  export const formatRackList = (data) => {
    const formattedData =  data.map((item)=> ({
        id: item._id,
        name: item?.rackName
    }))
    return formattedData
  }
  export const formatBoxList = (data) => {
    const formattedData =  data.map((item)=> ({
        id: item._id,
        name: item?.boxName
    }))
    return formattedData
  }




export default {
    firstLetter,
    calculateGst,
    isCurrency,
    validateEmail,
    checkPhone,
    checkPassword,
    isEmpty,
    isNumeric,
    isInt,
    isFloat,
    passwordLenth,
    passwordLetter,
    passwordDigit,
    validateUrl,
    isNumericTwoDecimalPartial,
    isNumericTwoDecimalFull, stringifyAddress,
    convertToReadbleDateTimeUtc, convertToReadbleDateTime, convertToReadbleDate, tolowerCase, ifFileIsDocument, ifFileIsImage, secureFilePath, checkAllowedDocumentType, checkAllowedImageType, checkMaxAllowedFileSize,
    customRound,prepareArray, calculatePerUnitPrice, calculateGSTAmount, calculateDiscountAmount, calculateSubTotal, calculatePurchasePrice, calculateSellingPrice, calculateContentQuantity, calculateLooseQtyAndWholeStripsQty, convertMfgOrExpiry,
    validateContentQuantity, validateMedicineType, validateRetailPackage, validateQuantityUnit, validateMrp, validateStock, validateImages, getMedicineType, getState,
    convertToReadbleDaySmart, convertToReadbleMonthSmart, convertToReadbleDaySmart2, getTrackingLink
    , getTaxableAmount, getTotalDiscount, getTotalCgst, getHSN, getNoOfItem, ecomDateFormat,
    FirstLetterEachWord,convertSvgToPng
}
