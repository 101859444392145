import React from "react";
import Moments from 'moment';
import {Button, Table} from "antd";
import SimpleBackdrop from "../../common/overlay";
import {
    MDBBox,
    MDBBtn,
    MDBDatePickerV5,
    MDBInput, MDBModal,
    MDBModalBody, MDBModalFooter,
    MDBModalHeader,
    MDBSelect,
    MDBSelectInput, MDBSelectOption, MDBSelectOptions
} from "mdbreact";
import {toast} from "react-toastify";
import utility from "../../../utility/utility";
import UserStore from "../../../stores/userStore";
import PharmacyService from "../../../services/pharmacyService";

export default class CustomerCouponList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {
                    title: 'Code',
                    dataIndex: 'code',
                    key: 'code'
                },
                {
                    title: 'Start Date',
                    dataIndex: 'startDate',
                    key: 'startDate',
                    render: startDate => {
                        return (Moments(startDate).format('ll'))
                    }
                },
                {
                    title: 'End Date',
                    dataIndex: 'endDate',
                    key: 'endDate',
                    render: endDate => {
                        return (Moments(endDate).format('ll'))
                    }
                },
                {
                    title: 'Max Total Uses',
                    dataIndex: 'maxTotalUsaes',
                    key: 'maxTotalUsaes'
                },
                {
                    title: 'Discount Type',
                    dataIndex: 'discountType',
                    key: 'discountType'
                },
                {
                    title: 'Minimum Total',
                    dataIndex: 'minimumTotal',
                    key: 'minimumTotal',
                    render: minimumTotal => {
                        return ("₹" + parseFloat(minimumTotal).toFixed(2))
                    }
                },
                {
                    title: 'Description',
                    dataIndex: 'description',
                    key: 'description',
                },
                {
                    title: 'Same User Use',
                    dataIndex: 'sameUserUse',
                    key: 'sameUserUse'
                },
                {
                    title: 'Status',
                    dataIndex: 'isDeleted',
                    key: 'isDeleted',
                    render: isDeleted => {
                        return (isDeleted === "Y" ? "Deleted" : "Active")
                    }
                },
                {
                    title: "",
                    dataIndex: "",
                    render: activeItem => {
                        return <a className={"pointer-cursor text-primary"}
                                  onClick={() => this.deleteCouponOperation(activeItem._id)}>Delete</a>
                    }
                }

            ],
            isLoading: false,
            modalAddCoupon: false, modalDeleteCoupon: false,
            couponCode: "",
            userData: {},
            couponData: [],
            couponId: null,

            maxTotalUses:"",
            description:"",
            startDate:"",
            endDate:"",
            amount:"",
            type:"",
            sameUserUse:1,
            minimumTotal:0

        }
    }
    deleteCouponOperation = (couponId) => {
        this.setState({couponId})
        this.toggle("DeleteCoupon")
    }

    async componentDidMount() {
        let userData = await UserStore.fetchSessionData();
        this.setState({userData})
        await this.fetchCoupons()
    }

    toggle = (section) => {
        this.setState({['modal' + section]: !this.state['modal' + section]})
    }

    generate = () => {
        let code = this.makeid(7);
        this.setState({couponCode: code});
    }

    makeid = (length) => {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }

    addCoupon = async () => {
        //validations
        if (this.state.couponCode === "") {
            toast.error("Invalid coupon code.");
            return false;
        }
        if (this.state.startDate === "") {
            toast.error("Invalid start date.");
            return false;
        }
        if (this.state.endDate === "") {
            toast.error("Invalid end date.");
            return false;
        }
        if (this.state.startDate > this.state.endDate) {
            toast.error("Invalid end date range. Start date is greater than End date");
            return false;
        }
        let amount = parseFloat(this.state.amount);
        if (utility.isFloat(amount) === false) {
            toast.error("Please enter valid amount.");
            return false;
        }
        let sameUserUse = parseInt(this.state.sameUserUse);
        if (utility.isInt(sameUserUse) === false) {
            toast.error("Please enter valid same user use.");
            return false;
        }
        let payload = {
            userId: this.props.activeItem._id,
            code: this.state.couponCode,
            description: this.state.description,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            maxTotalUsaes: this.state.maxTotalUses,
            discountType: this.state.type[0],
            amount: this.state.amount,
            sameUserUse: this.state.sameUserUse,
            minimumTotal: this.state.minimumTotal
        }
        this.setState({isLoading: true});
        let status = await PharmacyService.addCoupon(payload, this.state.userData.sessionToken);
        this.setState({isLoading: false});
        if (status === false) {
            toast.error("Coupon code already exists.");
        } else {
            toast.success("Coupon added successfully.");
            await this.fetchCoupons()
            this.toggle("AddCoupon")
        }
    }

    fetchCoupons = async () => {
        let data = await PharmacyService.fetchCustomerCoupons(this.props.activeItem._id, this.state.userData.sessionToken);
        if (data === false) {
            toast.error("We are facing some issue. Please try again later.");
        } else {
            this.setState({couponData: data});
        }
    }

    deleteCoupon = async () => {
        this.setState({isLoading: true});
        let status = await PharmacyService.deleteCoupon({_id: this.state.couponId}, this.state.userData);
        this.setState({isLoading: false});
        if (status === false) {
            toast.error("We are facing some issue. Please try again later.");
        } else {
            toast.success("Coupon deleted successfully.");
            this.setState({couponId: null})
            await this.fetchCoupons()
            this.toggle("DeleteCoupon")
        }
    }

    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.isLoading}/>
                <div className={"row mb-2"}>
                    <div className={"col"}>
                        <Button type="primary" ghost onClick={() => this.toggle('AddCoupon')}>Add Coupon</Button>
                    </div>
                </div>
                <div>
                    <Table columns={this.state.columns} dataSource={this.state.couponData}/>
                </div>
                <MDBModal isOpen={this.state.modalAddCoupon} toggle={() => this.toggle("AddCoupon")} size="fluid" centered>
                    <MDBModalHeader toggle={() => this.toggle("AddCoupon")}>Add coupon</MDBModalHeader>
                    <MDBModalBody>
                        <MDBBox>
                            <MDBInput label={"Coupon code"} value={this.state.couponCode} getValue={(e) => {
                                this.setState({couponCode: e})
                            }}/>

                            <MDBBtn onClick={() => {
                                this.generate()
                            }}>Generate</MDBBtn>
                            <MDBDatePickerV5 labelTitle={"Start date"} getValue={(e) => {
                                this.setState({startDate: e})
                            }}/>
                            <MDBDatePickerV5 labelTitle={"End date"} getValue={(e) => {
                                this.setState({endDate: e})
                            }}/>
                            <MDBInput label={"Maximum impressions"} value={this.state.maxTotalUses} getValue={(e) => {
                                this.setState({maxTotalUses: e})
                            }}/>
                            <MDBSelect label="Coupon type" getValue={(e) => {
                                this.setState({type: e})
                            }}>
                                <MDBSelectInput selected="Select type"/>
                                <MDBSelectOptions>
                                    <MDBSelectOption value="FLAT">Flat discount</MDBSelectOption>
                                    <MDBSelectOption value="PERCENTAGE">Percentage</MDBSelectOption>
                                </MDBSelectOptions>
                            </MDBSelect>
                            <MDBInput label={"Flat/Percentage amount"} value={this.state.amount} getValue={(e) => {
                                this.setState({amount: e})
                            }}/>
                            <MDBInput label={"Same user uses"} value={this.state.sameUserUse} getValue={(e) => {
                                this.setState({sameUserUse: e})
                            }}/>
                            <MDBInput label={"Minimum order total"} value={this.state.minimumTotal} getValue={(e) => {
                                this.setState({minimumTotal: e})
                            }}/>
                            <MDBInput label={"Description"} value={this.state.description} getValue={(e) => {
                                this.setState({description: e})
                            }}/>
                        </MDBBox>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn onClick={() => this.toggle("AddCoupon")}>Close</MDBBtn>
                        <MDBBtn onClick={this.addCoupon}>Create</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
                <MDBModal isOpen={this.state.modalDeleteCoupon} toggle={() => this.toggle("DeleteCoupon")}>
                    <MDBModalHeader toggle={() => this.toggle("DeleteCoupon")}>Update Payment
                        Status</MDBModalHeader>
                    <MDBModalBody>
                        <div className={"col-12 row mx-0 p-2"}>
                            <div className={"col-12 text-center p-2"}><h6>Do you want to delete the coupon?</h6></div>
                            <div className={"col-12 text-center"}>
                                <MDBBtn color="default" outline
                                        onClick={() => this.toggle("DeleteCoupon")}>Close</MDBBtn>
                                <MDBBtn color="default" onClick={() => this.deleteCoupon()}>Delete</MDBBtn>
                            </div>
                        </div>
                    </MDBModalBody>
                </MDBModal>
            </React.Fragment>
        );
    }
}