import React, { useEffect, useState, useCallback } from "react";
import {
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
} from "mdbreact";
import { toast } from "react-toastify";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import GetDateRangeFunc from "../../common/getDateRangeFunc";
import UserStore from "../../../stores/userStore";
import Moments from "moment";
import moment from "moment";
import TransferStockService from "../../../services/transferStockService";
import Barcode from "react-barcode";
import { Link } from "react-router-dom";
import SimpleBackdrop from "../../common/overlay";
import SearchStock from "../../../newComponents/searchDropdown/searchStock";
import config from "../../../config";
import ReviewComponent from "../../../newComponents/ReviewModal/review";
import PharmacyService from "../../../services/pharmacyService";
import StoargeComponent from "./storageDeatils";
import ResetComponent from "../../../newComponents/ReviewModal/resetStock";
import LandingPage from "./popUpForResetstock";
import PaggingFunc from "../../../newComponents/Pagination/PaggingFunc";
import NewTable from "../../../newComponents/NewTable/NewTable";
import NewPagination from "../../../newComponents/NewPagination/NewPagination";
import {
  Button,
} from "@material-ui/core";
import Selectlocation from "./selectLocationStocks";

const columns = [
  { header: "Barcode", width: "10%", accessor: "barCode" },
  { header: "Date", width: "8%", accessor: "createdAt", sortable: true },
  { header: "Batch", width: "5%", accessor: "batch" },
  { header: "Invoice Number", width: "5%", accessor: "invoiceNumber" },
  { header: "Product", width: "10%", accessor: "productName" },
  { header: "Supplier Name", width: "10%", accessor: "supplierName" },
  { header: "Qty", width: "5%", accessor: "quantity" },
  { header: "MRP", width: "5%", accessor: "mrp" },
  { header: "Disc(%)", width: "5%", accessor: "discount" },
  { header: "Discounted Price", width: "8%", accessor: "discountedPrice" },
  { header: "Mfg", width: "5%", accessor: "mfgDate" },
  { header: "Exp", width: "5%", accessor: "expDate" },
  { header: "Status", width: "10%", accessor: "status" },
  { header: "Entered By", width: "8%", accessor: "createdBy" },
  { header: "Storage", width: "8%", accessor: "storage" },
  { header: "Edit/Review", width: "8%", accessor: "editReview" },
  { header: "Reset/Orders", width: "8%", accessor: "reset" },
];

function ViewFetchedStocks1() {
  const [searchProductOpt, setSearchProductOpt] = useState(
    config.SEARCH_DROPDOWN
  );
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showLandingPage, setShowLandingPage] = React.useState(false);
  const [productInfo, setProductInfo] = useState({
    productId: null,
    quantity: null,
    productName: null,
  });
  const [state, setState] = useState({
    startD: Date.now(),
    endD: Date.now(),
  });

  const [pagging, setPagging] = useState({
    currentPage: 1,
    totalRecords: 0,
    pageSize: 10,
  });

  const [userData, setUserData] = useState({});

  const [reviewModalBox, setReviewModalBox] = useState(false);
  const [reviewObject, setReviewObject] = useState();
  const [isStoargeOpen, setStorageOpen] = useState(false);
  const [storageInfo, setStorageInfo] = useState([]);
  const [isResetOpen, setResetOpen] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [searchParams, setSearchParams] = useState({
    searchTerm: "",
    searchType: "",
  });
  const [selectedLocation, setSelectedLocation] = useState(
    localStorage.getItem('pharmacyLocation') || config.DEFAULT_PHARMACY_LOCATION
  );
  const [searchInput, setSearchInput] = useState("");
  const [isOrdersModalOpen, setOrdersModalOpen] = useState(false);
  const [ordersData, setOrdersData] = useState([]);
  const [loadingOrders, setLoadingOrders] = useState(false);

  const myStyle = { verticalAlign: "middle" };

  const getDateRangeCallback = useCallback(async (startDate, endDate, currentPage = 1) => {
    if (startDate > endDate) {
      toast.info(
        "Start date is greater than the end date. Please adjust the date range again."
      );
      return;
    }

    let user = await UserStore.fetchSessionData();
    setUserData(user);

    const payload = {
      page: currentPage,
      startDate: moment(startDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      endDate: moment(endDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      locationId: Array.isArray(selectedLocation) ? selectedLocation.join('&locationId=') : selectedLocation,
    };

    try {
      setLoading(true);
      const res = await TransferStockService.fetchStockList(
        payload,
        user.sessionToken
      );
      if (res) {
        setData(res.data);

        setPagging((prev) => ({
          ...prev,
          currentPage: res.currentPage,
          totalRecords: res.totalRecords,
          pageSize: res.recordPerPage,
        }));
      } else {
        setData([]);
        setPagging((prev) => ({
          ...prev,
          currentPage: 1,
          totalRecords: 0,
          pageSize: 10,
        }));
        console.log("Stock not found");
      }
      setState({ startD: startDate, endD: endDate });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [selectedLocation]);

  const handlePageChange = async (newPage) => {
    const pageNumber = newPage + 1;
    setPagging((prev) => ({ ...prev, currentPage: pageNumber }));
    try {
      setLoading(true);
      let res;
      if (searchParams.searchTerm) {
        const singleLocationId = Array.isArray(selectedLocation) ? selectedLocation.join('&locationIds=') : selectedLocation;
        res = await TransferStockService.searchStocksListNew(searchParams.searchTerm, userData.sessionToken, searchParams.searchType, pageNumber, singleLocationId);
      } else {
        const payload = {
          page: pageNumber,
          startDate: moment(state.startD).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
          endDate: moment(state.endD).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
          locationId: Array.isArray(selectedLocation) ? selectedLocation.join('&locationId=') : selectedLocation,
        };
        res = await TransferStockService.fetchStockList(payload, userData.sessionToken);
      }

      if (res && res.data) {
        setData(res.data);
        setPagging((prev) => ({
          ...prev,
          totalRecords: res.totalRecords || res.data.length,
          pageSize: res.recordPerPage || 10,
        }));
      } else {
        setData([]);
        setPagging((prev) => ({
          ...prev,
          currentPage: 1,
          totalRecords: 0,
          pageSize: 10,
        }));
      }
    } catch (error) {
      console.error("Error in handlePageChange:", error);
      toast.error("An error occurred while fetching data. Please try again.");
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    getDateRangeCallback(state.startD, state.endD);
  }, [getDateRangeCallback]);

  const handleSearchProd = async (searchTerm, searchType) => {
    try {
      setLoading(true);
      let res;

      if (searchTerm === "clear") {
        const payload = {
          page: 1,
          startDate: moment(state.startD).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
          endDate: moment(state.endD).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
          locationId: Array.isArray(selectedLocation) ? selectedLocation.join('&locationId=') : selectedLocation,
        };
        res = await TransferStockService.fetchStockList(payload, userData.sessionToken);
        setSearchParams({ searchTerm: "", searchType: "" });
      } else {
        res = await TransferStockService.searchStocksListNew(
          searchTerm,
          userData.sessionToken,
          searchType,
          1,
          Array.isArray(selectedLocation) ? selectedLocation.join('&locationIds=') : selectedLocation
        );
        setSearchParams({ searchTerm, searchType });
      }
      if (res && res.data) {
        setData(res.data);
        setPagging((prev) => ({
          ...prev,
          currentPage: res.currentPage || 1,
          totalRecords: res.totalRecords || res.data.length,
          pageSize: res.recordPerPage || 10,
        }));
        setSearchInput("");
      } else {
        setData([]);
        setPagging((prev) => ({
          ...prev,
          currentPage: 1,
          totalRecords: 0,
          pageSize: 10,
        }));
      }
    } catch (error) {
      console.error("Error in handleSearchProd:", error);
      toast.error("An error occurred while searching. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleReviewBox = (boolVal, dataObject) => {
    setReviewModalBox(boolVal);
    setReviewObject(dataObject);
  };
  const reviewImplement = async (id, comment, typeReq) => {
    try {
      let status;
      if (typeReq == "Accept") {
        status = await PharmacyService.approveStockV3(userData, id, comment);
      } else
        status = await PharmacyService.rejectStockV3(userData, id, comment);
      if (status === false) {
        toast.error(
          "We are facing some server issue. Please contact your system admin for further details."
        );
      } else {
        getDateRangeCallback(state.startD, state.endD, pagging.currentPage);
        toast.success(`${typeReq} performed successfully .`);
        setReviewModalBox(false);
        setReviewObject({});
      }
    } catch (err) {
      setLoading(false);
    }
  };
  const handleReset = (boolVal, dataObject) => {
    setResetOpen(boolVal);
    setReviewObject(dataObject);
  };
  const resetImplement = async (id, quantity) => {
    try {
      let status = await PharmacyService.resetStock(userData, id, quantity);
      console.log("status", status);
      if (status === false) {
        toast.error(
          "We are facing some server issue. Please contact your system admin for further details."
        );
      } else {
        getDateRangeCallback(state.startD, state.endD, pagging.currentPage);
        toast.success(`Reset was successfull.`);
        setResetOpen(false);
        setReviewObject({});
      }
    } catch (err) {
      console.log({ err });
    }
  };
  const toggleLandingPage = (productId, quantity, productName) => {
    setShowLandingPage((prevShowLandingPage) => !prevShowLandingPage);
    setProductInfo({ productId, quantity, productName });
  };
  const modelclose = () => {
    setShowLandingPage(false);
    getDateRangeCallback(state.startD, state.endD, pagging.currentPage);
  };
  const discountedPriceCalculate = (price, dicountPercent) => {
    let newPrice = price * (1 - dicountPercent / 100)
    return newPrice?.toFixed(2) ?? "NA"
  }
  const buttonStyle = {
    width: '100%',
    marginBottom: '5px',
  };

  const formatTableData = (data) => {
    return data.map((item) => ({
      barCode: (
        <div
          className="d-flex align-items-center"
          style={{ width: "12em", height: "8em" }}
        >
          <Barcode value={item?.barCode} />
        </div>
      ),
      createdAt: Moments(item.createdAt).format("DD-MMM-YYYY"),
      batch: item.batch,
      invoiceNumber: item.invoiceNumber,
      productName: item.productName,
      supplierName: item.supplierName,
      quantity: item.quantity.toFixed(0),
      mrp: item.mrp,
      discount: item.discount,
      discountedPrice: discountedPriceCalculate(item.mrp, item.discount),
      mfgDate: Moments(item.mfgDate).format("MMM YYYY"),
      expDate: Moments(item.expDate).format("MMM YYYY"),
      status: item.isReviewed ? (
        <div>
          <p style={{ margin: "0" }}><b>{item?.revieweDetails.isApproved ? "Approved" : "Rejected"}</b></p>
          <p style={{ margin: "0" }}>Comment: {item?.revieweDetails.comment}</p>
          <p style={{ margin: "0" }}>Reviewed by: {item?.revieweDetails.reviewedBy}</p>
          <p>On, {Moments(item.reviewedAt).format("DD-MMM-YYYY")}</p>
        </div>
      ) : "Not Reviewed",
      createdBy: item.createdBy,
      storage: (
        <div>
          <p style={{ margin: "0", fontWeight: "xl" }}>Location: {item.locName},</p>
          <p style={{ margin: "0", fontWeight: "xl" }}>Floor: {item.floorName},</p>
          <p style={{ margin: "0", fontWeight: "xl" }}>Rack: {item.rackName},</p>
          <p style={{ margin: "0", fontWeight: "xl" }}>Box: {item.boxName}</p>
        </div>
      ),
      editReview: (
        <div className="d-flex flex-column" style={{ width: '100%' }}>
          <Button
            size="small"
            variant="contained"
            color="default"
            onClick={() => handleReviewBox(true, item)}
            style={buttonStyle}
          >
            Review
          </Button>
          <Link to={`/edit-stocks/${item?.id}`} style={{ width: '100%' }}>
            <Button
              size="small"
              variant="contained"
              color="default"
              style={buttonStyle}
            >
              Edit
            </Button>
          </Link>
        </div>
      ),
      reset: (
        <div className="d-flex flex-column" style={{ width: '100%' }}>
          <Button
            size="small"
            variant="contained"
            color="default"
            onClick={() => handleReset(true, item)}
            style={buttonStyle}
          >
            Reset
          </Button>
          <Button
            size="small"
            variant="contained"
            color="default"
            onClick={() => handleOrders(true, item)}
            style={buttonStyle}
          >
            Orders
          </Button>
        </div>
      ),

    }));
  };
  const customSortHandler = (item) => {
    if (item !== sortBy) {
      setSortBy(item);
      setSortOrder("ASC");
    } else {
      if (sortOrder === "") {
        setSortOrder("ASC");
      } else if (sortOrder === "ASC") {
        setSortOrder("DESC");
      } else {
        setSortOrder("");
      }
    }
  };
  const selectLocationCallback = useCallback(async (newSelectedLocation) => {
    console.log("Previous Location:", selectedLocation);
    console.log("New Selected Location:", newSelectedLocation);
    setData([]);
    setSelectedLocation(newSelectedLocation);
  }, [selectedLocation]);

  // const handleOrders = async (boolVal, dataObject) => {
  //   if (!boolVal) {
  //     setOrdersData([]);
  //   }

  //   setOrdersModalOpen(boolVal);

  //   if (boolVal) {
  //     try {
  //       const orders = await PharmacyService.fetchOrdersByStockId(userData, dataObject.id);
  //       if (orders) {
  //         setOrdersData(orders);
  //       } else {
  //         setOrdersData([]);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching orders:", error);
  //       setOrdersData([]);
  //     }
  //   }
  // };

  const handleOrders = async (boolVal, dataObject) => {
    if (!boolVal) {
      setOrdersData([]);
    }
    setOrdersModalOpen(boolVal);
    if (boolVal) {
      setLoadingOrders(true);
      try {
        const orders = await PharmacyService.fetchOrdersByStockId(userData, dataObject.id);
        if (orders) {
          setOrdersData(orders);
        } else {
          setOrdersData([]);
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
        setOrdersData([]);
      } finally {
        setLoadingOrders(false);
      }
    }
  };

  return (
    <>
      {reviewObject && (
        <ReviewComponent
          key={Date.now()}
          reviewModalBox={reviewModalBox}
          setReviewModalBox={setReviewModalBox}
          reviewObject={reviewObject}
          reviewImplement={reviewImplement}
        />
      )}
      {isStoargeOpen && (
        <StoargeComponent
          isStoargeOpen={isStoargeOpen}
          setStorageOpen={setStorageOpen}
          reviewObject={reviewObject}
          storageInfo={storageInfo}
        />
      )}
      {isResetOpen && (
        <ResetComponent
          isResetOpen={isResetOpen}
          setResetOpen={setResetOpen}
          resetImplement={resetImplement}
          reviewObject={reviewObject}
        />
      )}
      <SimpleBackdrop status={loading} />
      <div className={"col-3 row mx-0"}>
        <Selectlocation
          selectLocationCallback={selectLocationCallback}
          selectedLocation={selectedLocation}
        />
      </div>
      <GetDateRangeFunc getDateRangeCallback={getDateRangeCallback} />
      <SearchStock
        OPTIONS={searchProductOpt}
        CONFIG={config}
        handleSearchProd={handleSearchProd}
        isProduct={true}
        inputValue={searchInput}
        onInputChange={(e) => setSearchInput(e.target.value)}
      />
      <div className="col-12 row mx-0 order-box mt-4">
        <NewTable
          columns={columns}
          data={formatTableData(data)}
          pagination={false}
          customSortHandler={customSortHandler}
        />
        {data && data.length > 0 && (
          <div className="my-3 d-flex flex-row justify-content-end w-100">
            <NewPagination
              page={pagging.currentPage - 1}
              pageLimit={pagging.pageSize}
              totalRecords={pagging.totalRecords}
              handleChange={handlePageChange}
              type="primary"
            />
          </div>
        )}
      </div>
      <MDBModal isOpen={isOrdersModalOpen} toggle={() => handleOrders(false, {})}>
        <MDBModalHeader toggle={() => handleOrders(false, {})}>
          Orders
        </MDBModalHeader>
        <MDBModalBody>
          {loadingOrders ? (
            <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: '100px' }}>
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <p className="mt-2">Loading orders, please wait...</p>
            </div>
          ) : (
            <NewTable
              columns={[
                { header: "Batch", accessor: "batch" },
                { header: "MRP", accessor: "mrp" },
                { header: "Order Code", accessor: "orderCode" },
                { header: "Order Mode", accessor: "orderMode" },
                { header: "Order Status", accessor: "orderStatus" },
                { header: "Quantity", accessor: "quantity" },
              ]}
              data={ordersData.map(order => ({
                batch: order.batch,
                mrp: order.mrp,
                orderCode: order.orderCode,
                orderMode: order.orderMode,
                orderStatus: order.orderStatus,
                quantity: order.quantity,
              }))}
              pagination={false}
            />
          )}
        </MDBModalBody>
        <MDBModalFooter>
          <Button color="default" onClick={() => handleOrders(false, {})}>
            Close
          </Button>
        </MDBModalFooter>
      </MDBModal>



    </>
  );
}

export default ViewFetchedStocks1;
