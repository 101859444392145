import React from "react";
export default class Footer extends React.Component {
    render() {
        return (
            <React.Fragment>
                <footer className="page-footer font-small blue">
                    <div className="footer-copyright text-center py-3">Powered By PositraRx. © 2020 Positra Healthcare Pvt. Ltd.
                    </div>
                </footer>
            </React.Fragment>
        );
    }
}


