import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import StockServiceV3 from "../../components/v3/stockService";
import SearchIcon from "@material-ui/icons/Search";
import CircularProgress from "@material-ui/core/CircularProgress";

const AutoCompleteSearch = ({
  searchTerm,
  handleSearchTerm,
  user,
  fetchData,
  setProductDetails,
  setDynamicFieldName,
  setContentQantity,
  setShowdiv,
  setViewDetails,
  clearForm,
  setClear,
  showDiv,
  isShipmentEmpty,
  productData,
  setProductData = () => {},
  
}) => {
  const [selected, setSelected] = useState(false);
  const [selectedText, setSelectedText] = useState("Select");
  const [isLoading, setLoading] = useState(false);
  const [isEnabledView, setIsEnabledView] = useState(false);

  const searchProductVal = async () => {
    try {
      if (/\S/.test(searchTerm)) {
        setLoading(true);
        setViewDetails({});
        setShowdiv(false);
        setClear(false);
        setIsEnabledView(false);
        if (productData?.length >= 1) {
          setProductData([]);
        }
        let product = await StockServiceV3.searchProductV3(searchTerm, user);
        if (product) {
          setSelected(false);
          setSelectedText("Select");
          setLoading(false);
          setProductData(product);
        }
      }
      // console.log({ product })
      // console.log("hi")
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  const selectProduct = (data) => {
    fetchData(data);
    const productSelected = productData.filter(
      (item) => item.productName == data.productName
    );
    setProductData(productSelected);
    setSelected(true);
    setSelectedText("Selected");
    setProductDetails(data);
    setDynamicFieldName(
      `No of ${data.retailPackage} [ ${data.contentQuantity} ${data.quantityUnit} each]`
    );
    setContentQantity(data.contentQuantity);
    setViewDetails(data);
    setIsEnabledView(true);
    setShowdiv(true);
  };
  useEffect(() => {
    if (!showDiv) {
      setProductData([]);
    }
  }, [showDiv]);
  return (
    <Grid container>
      <Grid item xs={6}>
        <div className="autoComDiv">
          <TextField
            variant="outlined"
            style={{ marginRight: "8px", width: "350px" }}
            value={searchTerm}
            onChange={(e) => handleSearchTerm(e.target.value)}
          />
          <IconButton
            onClick={searchTerm.length > 0 && searchProductVal}
            style={{
              backgroundColor: "#3f51b5",
              borderRadius: "4px",
              color: "white",
            }}
            disabled={!searchTerm.length > 0 || !isShipmentEmpty}
          >
            <SearchIcon />
          </IconButton>
        </div>
      </Grid>
      <Grid item xs={6} style={{ padding: "0 1em" }}>
        <div className="scrollableDiv">
          {!clearForm && productData.length ? (
            productData.map((item, key) => (
              <div className="optionMenu">
                <div>
                  <Typography variant="body1">
                    <b>{item?.productName}</b>
                  </Typography>
                  <div>
                    {/* {isEnabledView && <Typography variant='body1' className='view' onClick={()=>{viewToggle(item)}}>view details</Typography> } */}
                  </div>
                </div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => selectProduct(item)}
                  disabled={selected}
                >
                  {selectedText}
                </Button>
              </div>
            ))
          ) : isLoading ? (
            <div className="loader">
              <CircularProgress />
            </div>
          ) : (
            <div className="emptyScrollDiv">
              <Typography variant="h6" align="center">
                <b>Product is Empty !!</b>
              </Typography>
            </div>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default AutoCompleteSearch;
