import React,{Suspense} from "react";
import {Link} from "react-router-dom";
import {
    MDBBreadcrumbItem,
    MDBBreadcrumb,MDBBox
} from 'mdbreact';
//const QuantityUnit=React.lazy(() => import("../../components/quantity-unit/listQuantityUnit"));
import QuantityUnit from "../../components/quantity-unit/listQuantityUnit";

export default class QuantityUnitPage extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Product Quantity Units</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <MDBBox className={"min-vh-100"}>
                    <Suspense fallback={null}>
                        <QuantityUnit/>
                    </Suspense>
                </MDBBox>
            </React.Fragment>
        );
    }
}


