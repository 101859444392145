import React from "react";
import { MDBBreadcrumb, MDBBreadcrumbItem } from "mdbreact";
import { Link } from "react-router-dom";
import SearchCustomerViewMoreCmp from "../../components/customer/customerDetailsViewmore";
export default class SearchCustomer extends React.Component {
  render() {
    return (
      <React.Fragment>
        <MDBBreadcrumb>
          <MDBBreadcrumbItem>
            <Link to={"/dashboard"}>Dashboard</Link>
          </MDBBreadcrumbItem>
          <MDBBreadcrumbItem>
            <Link className={"mx-3"} to={`/search-customers`} >
              Search Customer Here
            </Link>
          </MDBBreadcrumbItem>
          <MDBBreadcrumbItem active> Customer Details</MDBBreadcrumbItem>
        </MDBBreadcrumb>
        <SearchCustomerViewMoreCmp />
      </React.Fragment>
    );
  }
}
