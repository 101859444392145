import {
  MDBBox,
  MDBBtn,
  MDBCard,
  MDBCol,
  MDBContainer,
  MDBInput,
} from "mdbreact";
import { makeStyles } from "@material-ui/core/styles";
import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import AuthService from "../../../services/authService";
import Barcode from "react-barcode";
import Moments from "moment";
import PharmacyService from "../../../services/pharmacyService";
import { useEffect } from "react";
import UserStore from "../../../stores/userStore";
import utility from "../../../utility/utility";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  formControl: { margin: theme.spacing(1), minWidth: 120 },
  select: {
    // Add your custom styles for the Select component here
    // For example:
    border: "0.1px solid rgb(200, 200, 200)",
    borderRadius: "5px",
    width: "200px",
    height: "40px",
    "&:focus": {
      borderColor: "#0056b3",
    },
  },
  label: {
    // Add your custom styles for the InputLabel component here
    // For example:
    color: "#007bff",
  },
}));
const EditStockPopUp = ({ productId, modelclose }) => {
  const path = window.location.pathname;
  const parts = path.split("/");
  const id = parts[parts.length - 1];
  const [dataId, setDataId] = useState(id);
  const [isLoading, setIsLoanding] = useState(false);
  const [editedStock, setEditedStock] = useState({});
  const classes = useStyles();
  const fetchProductIndividualAllData = async (dataId) => {
    setIsLoanding(true);
    try {
      let userDatavalues = await UserStore.fetchSessionData();

      const data = await AuthService.fetchProductIndividualAllData(
        dataId,
        userDatavalues
      );
      setEditedStock(data);
      setIsLoanding(false);
      if (data) {
        setSelectedExpDate(formatToMonthYear(data.expDate));
        setSelectedMfgDate(formatToMonthYear(data.mfgDate));
        setSelectedLoc(data.locId);
        setSelectedFloor(data.floorId);
        setSelectedRack(data.rackId);
        setSelectedBox(data.boxId);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoanding(false);
    }
  };

  const [barCode, setBarCode] = useState(editedStock.barCode);
  const [locSuggestion, setLocSuggestion] = useState([]);
  const [floorSuggestion, setFloorSuggestion] = useState([]);
  const [rackSuggestions, setRackSuggestions] = useState([]);
  const [boxSuggestions, setBoxSuggestions] = useState([]);
  const [selectedLoc, setSelectedLoc] = useState(""); // Initialize with locId from editedStock
  const [selectedFloor, setSelectedFloor] = useState(""); // Initialize with floorId from editedStock
  const [selectedRack, setSelectedRack] = useState(""); // Initialize with rackId from editedStock
  const [selectedBox, setSelectedBox] = useState(""); // Initialize with boxId from editedStock
  const [selectedExpDate, setSelectedExpDate] = useState("");
  const [selectedMfgDate, setSelectedMfgDate] = useState("");
  const [isValidDateRange, setIsValidDateRange] = useState(true);
  const [mfgDateErrorMessage, setMfgDateErrorMessage] = useState("");
  const [expDateErrorMessage, setExpDateErrorMessage] = useState("");
  const [isMfgDateValid, setIsMfgDateValid] = useState(true);
  const [isExpDateValid, setIsExpDateValid] = useState(true);
  const [batchError, setBatchError] = useState("");
  const [isBatchValid, setIsBatchValid] = useState(true);
  const [mrpError, setMrpError] = useState("");
  const [ismrpValid, setIsMrpValid] = useState(true);
  const [pwgError, setPwgError] = useState("");
  const [ispwgValid, setIspwgValid] = useState(true);
  const [discountError, setDiscountError] = useState("");
  const [isDiscoutValid, setIsDiscoutValid] = useState(true);
  const [gstError, setGstError] = useState("");
  const [isGstValid, setIsGstValid] = useState(true);
  const [isHsnValid, setIsHsnValid] = useState(true);
  const [hsnError, setHsnError] = useState("");
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "hsn") {
      const specialCharRegex = /[!@#$%^&*`~()_+\-=\[\]{};':"\\|,.<>\/?]+/;
      const spaceRegex = /\s/;
      if (value !== "") {
        if (
          specialCharRegex.test(value) ||
          spaceRegex.test(value) ||
          value.length < 6 ||
          value.length > 9
        ) {
          setHsnError("Invalid HSN/SAC code");
          setIsHsnValid(false);
        } else {
          setHsnError("");
          setIsHsnValid(true);
        }
      } else {
        setHsnError("");
        setIsHsnValid(false);
      }
    }
    setEditedStock((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === "batch") {
      if (value.trim() === "") {
        setBatchError("Batch cannot be empty");
        setIsBatchValid(false);
      } else {
        setBatchError("");
        setIsBatchValid(true);
      }
    }
  };

  const handleInputChangeInFloat = (e) => {
    const { name, value } = e.target;

    const floatRegex = /^[+-]?\d+(\.\d+)?$/;

    // Check if the input value matches the float regex
    const isValidFloat = floatRegex.test(value);

    if (!utility.isNumericTwoDecimalPartial(value) && value !== "") {
      return false;
    }

    setEditedStock((prevData) => ({
      ...prevData,
      [name]: value, // Store it as a string
    }));

    if (name === "mrp") {
      if (value.trim() === "") {
        setMrpError("mrp cannot be empty");
        setIsMrpValid(false);
      } else {
        const priceNogstValue = parseFloat(editedStock.priceNogst) || 0;
        const enteredValue = parseFloat(value) || 0;

        if (enteredValue < priceNogstValue) {
          setMrpError("MRP should be greater than or equal to Price (w/o GST)");
          setIsMrpValid(false);
        } else {
          setMrpError("");
          setIsMrpValid(true);
        }
      }
    } else if (name === "priceNogst") {
      if (value.trim() === "") {
        setPwgError("price without gst cannot be empty");
        setIspwgValid(false);
      } else {
        const mrpValue = parseFloat(editedStock.mrp) || 0;
        const enteredValue = parseFloat(value) || 0;

        if (enteredValue > mrpValue) {
          setPwgError("Price (w/o GST) cannot be more than MRP");
          setIspwgValid(false);
        } else {
          setPwgError("");
          setIspwgValid(true);
        }
      }
    } else if (name === "discount") {
      if (value.trim() === "") {
        setDiscountError("discount cannot be empty");
        setIsDiscoutValid(false);
      } else {
        const isValidValue =
          isValidFloat && parseFloat(value) >= 0 && parseFloat(value) <= 100;

        if (!isValidValue) {
          setDiscountError("Purchase Discount % should be between 0 and 100");
          setIsDiscoutValid(false);
        } else {
          setDiscountError("");
          setIsDiscoutValid(true);
        }
      }
    } else if (name === "gst") {
      if (value.trim() === "") {
        setGstError("gst cannot be empty");
        setIsGstValid(false);
      } else {
        setGstError("");
        setIsGstValid(true);
      }
      const isValidValue =
        isValidFloat && parseFloat(value) >= 0 && parseFloat(value) <= 28;

      if (!isValidValue) {
        setGstError("GST should be a valid number between 0 and 28");
        setIsGstValid(false);
      } else {
        setGstError("");
        setIsGstValid(true);
      }
    }
  };
  const handleInputChangeDate = (e) => {
    const { value, name } = e.target;
    if (value.length > 7) {
      return;
    }

    const regex = /^(0[1-9]|1[0-2])\/(19|20)\d\d$/; // MM/YYYY format
    const isValid = regex.test(value);

    if (name === "mfgDate") {
      setSelectedMfgDate(value);

      if (value.trim() !== "") {
        const momentMfgDate = Moments(value, "MM/YYYY");
        const momentExpDate = Moments(selectedExpDate, "MM/YYYY");
        const isValidDateRange = momentExpDate.isAfter(momentMfgDate);
        setIsValidDateRange(isValidDateRange);
      }
      setIsMfgDateValid(isValid);
      setMfgDateErrorMessage(
        isValid ? "" : "Invalid date format. Please use MM/YYYY format."
      );
    } else {
      setSelectedExpDate(value);
      const momentMfgDate = Moments(selectedMfgDate, "MM/YYYY");
      const momentExpDate = Moments(value, "MM/YYYY");
      const isValidDateRange = momentExpDate.isAfter(momentMfgDate);
      setIsValidDateRange(isValidDateRange);
      setIsExpDateValid(isValid);
      setExpDateErrorMessage(
        isValid ? "" : "Invalid date format. Please use MM/YYYY format."
      );
    }
  };

  const handleSave = async () => {
    try {
      const updatedStock = {
        id: editedStock.id,
        batch: editedStock.batch,
        priceNogst: parseFloat(editedStock.priceNogst),
        gst: parseFloat(editedStock.gst),
        priceWithGst: parseFloat(
          calculatePriceWithGST(editedStock.priceNogst, editedStock.gst)
        ),
        // discount: parseFloat(editedStock.discount) || 0,
        discount: parseFloat(editedStock.discount),
        mfgDate: convertToMomentDate(selectedMfgDate),
        expDate: convertToMomentDate(selectedExpDate),
        locId: selectedLoc, // assuming selectedLoc contains the correct locId
        floorId: selectedFloor,
        rackId: selectedRack,
        boxId: selectedBox,
        barCode: editedStock.barCode,
        commentStorage: editedStock.commentStorage,
        comment: editedStock.comment,
        mrp: parseFloat(editedStock.mrp),
        hsn: editedStock.hsn,
      };

      let userData = await UserStore.fetchSessionData();
      const res = await AuthService.saveEditedStockData(updatedStock, userData);

      if (res) {
        toast.success("Product updated Successfully.");
        fetchProductIndividualAllData(dataId);
        // modelclose();
        // const redirectTimeout = setTimeout(() => {
        //   window.location.href = "/fetch-stock";
        // }, 3000);
      }
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const fetchStorageLOC = async () => {
    let storageInfo = await PharmacyService.fetchStorageLocationV2();
    if (storageInfo === false) {
      toast.error("We are facing some server issue. Please try again later.");
    } else {
      setLocSuggestion(storageInfo);
    }
  };

  const handleSelectStorageLoc = (event) => {
    const selectedLocValue = event.target.value;
    setSelectedLoc(selectedLocValue);
    setEditedStock((prevData) => ({
      ...prevData,
      locId: selectedLocValue,
    }));
    setFloorSuggestion([]);
    setRackSuggestions([]);
    setBoxSuggestions([]);
    setSelectedFloor("");
    setSelectedRack("");
    setSelectedBox("");
  };

  const fetchFloor = async () => {
    if (selectedLoc !== "" && selectedLoc !== undefined) {
      let floorInfo = await PharmacyService.fetchFloorV2(selectedLoc);
      if (floorInfo === false) {
        toast.error("We are facing some server issue. Please try again later.");
      } else {
        if (floorInfo.length > 0) {
          setFloorSuggestion(floorInfo);
        } else {
          toast.error("Floor is not configured for this location.");
        }
      }
    }
  };

  const handleSelectFloor = (event) => {
    const selectedFloorValue = event.target.value;
    setSelectedFloor(selectedFloorValue);
    setEditedStock((prevData) => ({
      ...prevData,
      floorId: selectedFloorValue,
    }));
    setRackSuggestions([]);
    setBoxSuggestions([]);
    setSelectedRack("");
    setSelectedBox("");
  };

  const fetchRack = async () => {
    if (selectedFloor !== "" && selectedFloor !== undefined) {
      let rackInfo = await PharmacyService.fetchRackV2(selectedFloor);
      if (rackInfo === false) {
        toast.error("We are facing some server issue. Please try again later.");
      } else {
        if (rackInfo.length > 0) {
          setRackSuggestions(rackInfo);
        } else {
          toast.error("Rack configuration not found for this floor.");
        }
      }
    }
  };

  const handleSelectRack = (event) => {
    const selectedRackValue = event.target.value;
    setSelectedRack(selectedRackValue);
    setEditedStock((prevData) => ({
      ...prevData,
      rackId: selectedRackValue,
    }));
    setBoxSuggestions([]);
    setSelectedBox("");
  };

  const fetchBox = async () => {
    if (selectedRack !== "" && selectedRack !== undefined) {
      let boxInfo = await PharmacyService.fetchBoxV2(selectedRack);
      if (boxInfo === false) {
        toast.error("We are facing some server issue. Please try again later.");
      } else {
        if (boxInfo.length > 0) {
          setBoxSuggestions(boxInfo);
        } else {
          toast.error("Box configuration not found for this rack");
        }
      }
    }
  };

  const handleSelectBox = (event) => {
    const selectedBoxValue = event.target.value;
    setSelectedBox(selectedBoxValue);
    setEditedStock((prevData) => ({
      ...prevData,
      boxId: selectedBoxValue,
    }));
  };

  const resetBarCode = () => {
    const newBarcode = new Date().getTime() + 122;
    setBarCode(newBarcode.toString());
    setEditedStock((prevData) => ({
      ...prevData,
      barCode: newBarcode.toString(),
    }));
  };

  const toCopy = () => {
    navigator.clipboard.writeText(barCode.toString().trim());
    toast.success(
      "Barcode is copied successfully. Copied barcode is- " +
        barCode.toString().trim()
    );
  };

  const formatToMonthYear = (dateString) => {
    return Moments(dateString).format("MM/YYYY");
  };

  useEffect(() => {
    fetchProductIndividualAllData(dataId);
    fetchStorageLOC();
  }, []);

  function convertToMomentDate(input) {
    const dateString = `${input}/01`; // Add a day to make a complete date (e.g., "MM-YYYY-01")
    const momentDate = Moments(dateString, "MM-YYYY-DD");

    // Check if the momentDate is valid
    if (momentDate.isValid()) {
      const formattedDate = momentDate.format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ");
      return formattedDate;
    } else {
      console.error("Invalid date format");
      return null;
    }
  }
  const calculatePriceWithGST = (priceNogst, gst) => {
    const priceWithoutGST = parseFloat(priceNogst) || 0;
    const gstPercentage = parseFloat(gst) || 0;

    const gstAmount = (priceWithoutGST * gstPercentage) / 100;
    const priceWithGST = priceWithoutGST + gstAmount;

    return priceWithGST.toFixed(2); // Convert to string with 2 decimal places
  };
  useEffect(() => {
    if (selectedLoc !== "" && selectedLoc !== undefined) {
      fetchFloor();
    }
  }, [selectedLoc]);

  useEffect(() => {
    if (selectedFloor !== "" && selectedFloor !== undefined) {
      fetchRack();
    }
  }, [selectedFloor]);

  useEffect(() => {
    if (selectedRack !== "" && selectedRack !== undefined) {
      fetchBox();
    }
  }, [selectedRack]);

  return (
    <div>
      <MDBContainer className="container-of-edit-stock">
        {/* <MDBRow>
          <MDBCol md="6"> */}
        {!isLoading ? (
          <form className={"col-md-12 mx-0 p-1"}>
            <MDBCard className={"m-2 p-1"}>
              {editedStock !== null && (
                <>
                  <div className={"col-md-12 row mx-0 p-1"}>
                    <div className={"col m-1 p-1"}>
                      <MDBInput
                        label={
                          <span>
                            Batch <span style={{ color: "red" }}>*</span>
                          </span>
                        }
                        name="batch"
                        value={editedStock.batch}
                        getValue={(value) => {
                          handleInputChange({
                            target: { name: "batch", value },
                          });
                        }}
                        maxLength={40}
                      />
                      {batchError && (
                        <div style={{ color: "red" }}>{batchError}</div>
                      )}
                    </div>
                    <div className={"col m-1 p-1"}>
                      <MDBInput
                        label={"Product Name"}
                        name="productName"
                        value={editedStock.productName}
                        getValue={(value) => {
                          handleInputChange({
                            target: { name: "productName", value },
                          });
                        }}
                        disabled
                      />
                    </div>
                    <div className={"col m-1 p-1"}>
                      <MDBInput
                        label={
                          <span>
                            MRP <span style={{ color: "red" }}>*</span>
                          </span>
                        }
                        name="mrp"
                        value={editedStock.mrp}
                        getValue={(value) => {
                          handleInputChangeInFloat({
                            target: { name: "mrp", value },
                          });
                        }}
                        maxLength={50}
                      />
                      {mrpError && (
                        <div style={{ color: "red" }}>{mrpError}</div>
                      )}
                    </div>
                    <div className={"col m-1 p-1"}>
                      <MDBInput
                        label={
                          <span>
                            Price (w/o GST)
                            <span style={{ color: "red" }}>*</span>
                          </span>
                        }
                        name="priceNogst"
                        value={editedStock.priceNogst}
                        getValue={(value) => {
                          handleInputChangeInFloat({
                            target: { name: "priceNogst", value },
                          });
                        }}
                        maxLength={50}
                      />
                      {pwgError && (
                        <div style={{ color: "red" }}>{pwgError}</div>
                      )}
                    </div>
                  </div>
                  <div className={"col-md-12 row mx-0 p-1"}>
                    <div className={"col m-1 p-1"}>
                      <MDBInput
                        label={
                          <span>
                            GST %<span style={{ color: "red" }}>*</span>
                          </span>
                        }
                        name="gst"
                        value={editedStock.gst}
                        getValue={(value) => {
                          handleInputChangeInFloat({
                            target: { name: "gst", value },
                          });
                        }}
                        maxLength={50}
                      />
                      {gstError && (
                        <div style={{ color: "red" }}>{gstError}</div>
                      )}
                    </div>
                    <div className={"col m-1 p-1"}>
                      <MDBInput
                        label={"Price (w/ GST)"}
                        name="priceWithgst"
                        value={
                          editedStock.priceNogst || editedStock.gst
                            ? parseFloat(
                                calculatePriceWithGST(
                                  editedStock.priceNogst,
                                  editedStock.gst
                                )
                              )
                            : editedStock.priceWithgst
                        }
                        getValue={(value) => {
                          handleInputChangeInFloat({
                            target: { name: "priceWithgst", value },
                          });
                        }}
                        maxLength={50}
                      />
                    </div>
                    <div className={"col m-1 p-1"}>
                      <MDBInput
                        label={
                          <span>
                            Purchase Discount %
                            <span style={{ color: "red" }}>*</span>
                          </span>
                        }
                        name="discount"
                        value={editedStock.discount}
                        getValue={(value) => {
                          handleInputChangeInFloat({
                            target: { name: "discount", value },
                          });
                        }}
                        maxLength={50}
                      />
                      {discountError && (
                        <div style={{ color: "red" }}>{discountError}</div>
                      )}
                    </div>
                    <div className={"col m-1 p-1"}>
                      <MDBInput
                        label={
                          <span>
                            HSN/SAC<span style={{ color: "red" }}>*</span>
                          </span>
                        }
                        name="hsn"
                        value={editedStock.hsn}
                        getValue={(value) => {
                          handleInputChange({ target: { name: "hsn", value } });
                        }}
                        maxLength={50}
                      />
                      {hsnError && (
                        <div className="error-message">{hsnError}</div>
                      )}
                    </div>
                  </div>
                  <div className={"col-md-12 row mx-0 p-1"}>
                    <div className={"col m-1 p-1"}>
                      <MDBInput
                        label={
                          <span>
                            MFG Date (MM/YYYY)
                            <span style={{ color: "red" }}>*</span>
                          </span>
                        }
                        name="mfgDate"
                        value={selectedMfgDate}
                        getValue={(value) =>
                          handleInputChangeDate({
                            target: { name: "mfgDate", value },
                          })
                        }
                        maxLength={8}
                        placeholder="MM/YYYY"
                      />
                      {mfgDateErrorMessage && (
                        <div style={{ color: "red" }}>
                          {mfgDateErrorMessage}
                        </div>
                      )}
                    </div>
                    <div className={"col m-1 p-1"}>
                      <MDBInput
                        label={
                          <span>
                            EXP Date (MM/YYYY)
                            <span style={{ color: "red" }}>*</span>
                          </span>
                        }
                        name="expDate"
                        value={selectedExpDate}
                        getValue={(value) =>
                          handleInputChangeDate({
                            target: { name: "expDate", value },
                          })
                        }
                        maxLength={8}
                        placeholder="MM/YYYY"
                      />
                      {!isValidDateRange && (
                        <div style={{ color: "red" }}>
                          Mfg date should be smaller than Exp date
                        </div>
                      )}
                      {expDateErrorMessage && (
                        <div style={{ color: "red" }}>
                          {expDateErrorMessage}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={"col-md-12 mx-0 p-2"}>
                    <div className={"col-md-12 row mx-0 p-1"}>
                      <div
                        className={"col m-1 p-1   select-loc-rack-box-floor"}
                      >
                        <InputLabel htmlFor="select-loc">
                          <span>
                            <b>Select Location</b>
                            <span style={{ color: "red" }}>*</span>
                          </span>
                        </InputLabel>
                        <Select
                          labelId="select-loc-label"
                          id="select-loc"
                          value={selectedLoc}
                          onChange={handleSelectStorageLoc}
                          className={classes.select}
                        >
                          <MenuItem value="" disabled>
                            Choose your option
                          </MenuItem>
                          {locSuggestion.length === 0 ? (
                            <MenuItem>No locations available</MenuItem>
                          ) : (
                            locSuggestion.map((loc, idx) => (
                              <MenuItem key={idx} value={loc._id}>
                                {loc.name}
                              </MenuItem>
                            ))
                          )}
                        </Select>
                      </div>
                      <div className={"col m-1 p-1"}>
                        <InputLabel htmlFor="select-floor">
                          <span>
                            <b>Select Floor</b>
                            <span style={{ color: "red" }}>*</span>
                          </span>
                        </InputLabel>
                        <Select
                          labelId="select-floor-label"
                          id="select-floor"
                          value={selectedFloor}
                          onChange={handleSelectFloor}
                          className={classes.select}
                        >
                          {floorSuggestion.length > 0 ? (
                            floorSuggestion.map((floor, idx) => (
                              <MenuItem key={idx} value={floor._id}>
                                {floor.floorName}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem value="" disabled>
                              No floors available
                            </MenuItem>
                          )}
                        </Select>
                      </div>
                      <div className={"col m-1 p-1"}>
                        <InputLabel htmlFor="select-rack">
                          <span>
                            <b>Select Rack</b>
                            <span style={{ color: "red" }}>*</span>
                          </span>
                        </InputLabel>
                        <Select
                          labelId="select-rack-label"
                          id="select-rack"
                          value={selectedRack}
                          onChange={handleSelectRack}
                          className={classes.select}
                        >
                          {rackSuggestions.length > 0 ? (
                            rackSuggestions.map((rack, idx) => (
                              <MenuItem key={idx} value={rack._id}>
                                {rack.rackName}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem value="" disabled>
                              No Rack available
                            </MenuItem>
                          )}
                        </Select>
                      </div>
                      <div className={"col m-1 p-1"}>
                        <InputLabel htmlFor="select-box">
                          <span>
                            <b>Select Box</b>
                            <span style={{ color: "red" }}>*</span>
                          </span>
                        </InputLabel>
                        <Select
                          labelId="select-box-label"
                          id="select-box"
                          value={selectedBox}
                          onChange={handleSelectBox}
                          className={classes.select}
                        >
                          {boxSuggestions.length > 0 ? (
                            boxSuggestions.map((box, idx) => (
                              <MenuItem key={idx} value={box._id}>
                                {box.boxName}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem value="" disabled>
                              No Box available
                            </MenuItem>
                          )}
                        </Select>
                      </div>
                    </div>
                  </div>
                  {editedStock !== null && (
                    <>
                      <div className={"col m-1 p-1"}>
                        <MDBInput
                          value={editedStock.barCode}
                          label={
                            <span>
                              Barcode<span style={{ color: "red" }}>*</span>
                            </span>
                          }
                          onChange={(value) =>
                            handleInputChange(value, "barCode")
                          }
                          maxLength={30}
                        />
                        <MDBBtn onClick={resetBarCode} size={"sm"}>
                          Reset
                        </MDBBtn>
                        <MDBBtn onClick={toCopy} size={"sm"}>
                          Copy
                        </MDBBtn>
                      </div>
                      <div className={"col m-1 p-1"}>
                        {editedStock.barCode ? (
                          <MDBBox>
                            <Barcode value={editedStock.barCode}></Barcode>
                          </MDBBox>
                        ) : (
                          <div style={{ color: "red" }}>
                            Please Create a barCode.
                          </div>
                        )}
                      </div>
                    </>
                  )}
                  <div className={"col m-1 p-1"}>
                    <MDBInput
                      label={"Comment related to storage"}
                      name="commentStorage"
                      value={editedStock.commentStorage}
                      getValue={(value) => {
                        handleInputChange({
                          target: { name: "commentStorage", value },
                        });
                      }}
                    />
                  </div>
                  <div className={"col m-1 p-1"}>
                    <MDBInput
                      label={"Comment"}
                      name="comment"
                      value={editedStock.comment}
                      getValue={(value) => {
                        handleInputChange({
                          target: { name: "comment", value },
                        });
                      }}
                    />
                  </div>
                </>
              )}
            </MDBCard>

            <div className="container-of-edit-stock-button">
              <MDBBtn
                color="primary"
                onClick={handleSave}
                disabled={
                  !isValidDateRange ||
                  !editedStock.barCode ||
                  !selectedLoc ||
                  !selectedFloor ||
                  !selectedRack ||
                  !selectedBox ||
                  !isMfgDateValid ||
                  !isExpDateValid ||
                  mfgDateErrorMessage ||
                  expDateErrorMessage ||
                  !isBatchValid ||
                  !ismrpValid ||
                  !ispwgValid ||
                  !isDiscoutValid ||
                  !isGstValid ||
                  !isBatchValid ||
                  !isHsnValid
                }
              >
                Update
              </MDBBtn>
            </div>
          </form>
        ) : (
          <div>Loading...</div>
        )}
        {/* </MDBCol>
        </MDBRow> */}
      </MDBContainer>
    </div>
  );
};

export default EditStockPopUp;
