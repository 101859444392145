import React,{Suspense} from "react";
import {Link} from "react-router-dom";
import {
    MDBBreadcrumbItem,
    MDBBreadcrumb,MDBBox, MDBTable, MDBTableBody, MDBTableHead
} from 'mdbreact';
//const OrderHistory=React.lazy(() => import("../../components/order-history/subOrdersList"));
import OrderHistory from "../../components/order-history/subOrdersList";

export default class OrderHistoryPage extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Sub Orders</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <MDBBox className={"min-vh-100"}>
                    <Suspense fallback={null}>
                        <OrderHistory/>
                        {/*
                        <MDBTable>
                            <MDBTableHead>
                                <tr>
                                    <th>#</th>
                                    <th>Items</th>
                                    <th>Customer Details</th>
                                    <th>Customer Phone</th>
                                    <th>Payment Details</th>
                                    <th>Shipping Details</th>
                                    <th>Status</th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                <tr>
                                    <td>ORDER-123</td>
                                    <td>Crocin</td>
                                    <td>Uttam Dutta</td>
                                    <td>9176618472</td>
                                    <td>Paid | UPI</td>
                                    <td>#506, Block A, Sante Tirtha Apartment, Beltola Guwahati</td>
                                    <td>Delivered</td>
                                </tr>
                                <tr>
                                    <td>ORDER-124</td>
                                    <td>Liv 52</td>
                                    <td>Gautam Dutta</td>
                                    <td>9176618472</td>
                                    <td>Paid | UPI</td>
                                    <td>#506, Block A, Sante Tirtha Apartment, Beltola Guwahati</td>
                                    <td>In Transit</td>
                                </tr>


                            </MDBTableBody>
                        </MDBTable>
                         */}
                    </Suspense>
                </MDBBox>
            </React.Fragment>
        );
    }
}
