import React from "react";
import {Link, withRouter} from "react-router-dom";
import {
    MDBContainer, MDBRow, MDBInput, MDBFormInline, MDBBtn, MDBCol,
    MDBCard, MDBCardBody, MDBCardHeader, MDBBreadcrumb, MDBBreadcrumbItem, MDBProgress
} from 'mdbreact';
import UserStore from "../../stores/userStore";
import {toast} from "react-toastify";
import FileUpload from "../common/fileUpload/fileUpload";
import util from "../../utility/utility";
import AuthService from "../../services/authService";
import orgConfig from "../../orgConfig";

class AddOrgUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orgUser:"",
            description:"",
            isActive:"",
            imageUrl:{},
            progressStatus:false,
            progressStatusFileUpload:false,
            orgUserError:"",
            address: "",
            street: "",
            pincode:"",
            area: "",
            city: "",
            state: "",
            country: "",
            landmark: "",
            email:"",
            contactNo:""
        }
    }


    handleChangeContactNo=(e)=>{
        if(!util.isInt(e.target.value) && e.target.value !== ""){
            return false;
        }
        this.setState({contactNo:e.target.value})
    };
    handleChangeEmail=(e)=>{
        this.setState({email:e.target.value})
    };

    handleOrgUser=(e)=>{
        this.setState({orgUser:e.target.value})
    };



    validateOrgUser = () => {
        if(!this.state.orgUser || this.state.orgUser.trim() === ""){
            this.setState({"orgUserError": true})
        } else {
            this.setState({"orgUserError": false})
        }
    }

    validateEmail = () => {
        if(this.state.email && !util.validateEmail(this.state.email)){
            this.setState({"loadingEmail": true, "emailError": true})
        } else {
            this.setState({"loadingEmail": false, "emailError": false})
        }
    }

    validatePhone= () => {
        if(!util.checkPhone(this.state.contactNo)){
            this.setState({ "contactNoError": false})
        } else {
            this.setState({"contactNoError": false})
        }
    }

    addOrgUser =async () => {
        let userData=await UserStore.fetchSessionData();
        // Reset all the error first
        this.setState({"orgUserError": false})
        //validations
        this.validateOrgUser();
        this.validateEmail()
        this.validatePhone()

        if(this.state.orgUserError){
            return false;
        }

        this.setState({progressStatus:true});
        let status=await AuthService.addOrg(this.state,userData);
        this.setState({progressStatus:false});
        if(status===false){
            toast.error("Something went wrong.");
            return true;
        }else{
            toast.success("Organization created successfully.");
            this.setState({"orgUser":"", "description":"", "addressLine1":"", "addressLine2":"", "city":"",  "state":"", "country":"", "pincode":"",  "landmark":""})
        }
    }

    fileUploadCallback = (uploadedFileObject) => {
        this.setState({ "imageUrl": uploadedFileObject.path, "progressStatusFileUpload":false})
    }

    initUploadCallback = () => {
        this.setState({"progressStatusFileUpload":true})
    }

    handleAddress=(e)=>{
        this.setState({address:e.target.value})
    };
    handleStreet=(e)=>{
        this.setState({street:e.target.value})
    };
    handlePincode=(e)=>{
        if(!util.isInt(e.target.value) && e.target.value !== ""){
            return false;
        }
        this.setState({pincode:e.target.value})
    };
    handleArea=(e)=>{
        this.setState({area:e.target.value})
    };
    handleCity=(e)=>{
        this.setState({city:e.target.value})
    };
    handleState=(e)=>{
        this.setState({state:e.target.value})
    };
    handleCountry=(e)=>{
        this.setState({country:e.target.value})
    };
    handleLandmark=(e)=>{
        this.setState({landmark:e.target.value})
    };

    render() {
        return (
            <React.Fragment>
                {this.state.progressStatus === true &&
                <span className={'top-stick'}><MDBProgress material preloader color="cyan"/></span>
                }
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem ><Link to={'/organizations'}>Organizations</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Add Organization</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <MDBContainer>
                    <MDBCard className={"mt-2"}>
                        <MDBCardHeader>
                            <span className={"text-center title"}>Add Organization</span>
                        </MDBCardHeader>
                        <MDBCardBody>
                            <MDBRow>
                                <MDBCol>
                                    <MDBInput autoComplete={'off'} style={{"marginTop": "0px"}} onBlur={this.validateOrgUser}
                                              className={this.state.orgUserError ? "border-danger" : "mt-0 mb-0"}
                                              maxLength="100" type='text' label="Organization Name *"
                                              onChange={this.handleOrgUser}
                                              value={this.state.orgUser}/>
                                </MDBCol>
                                <MDBCol>
                                    <MDBInput onBlur={() => this.validateEmail()}
                                              autoComplete={'off'}
                                              className={this.state.emailError ? "border-danger" : ""}
                                              maxLength="50" type='text' label="E-mail"
                                              onChange={this.handleChangeEmail}
                                              value={this.state.email}/>
                                </MDBCol>
                                <MDBCol>
                                    <MDBInput onBlur={() => this.validatePhone()}
                                              autoComplete={'off'}
                                              className={this.state.contactNoError ? "border-danger" : ""}
                                              maxLength={orgConfig.PHONE_NUMBER_LENGTH} type='text' label="Phone *"
                                              onChange={this.handleChangeContactNo}
                                              value={this.state.contactNo}/>
                                </MDBCol>
                            </MDBRow>

                            <MDBRow>
                                <MDBCol size={6}>
                                    <MDBInput className={this.state.addressError ? "border-danger" : ""} maxLength="50"
                                              type='text' label="Address Line 1" onChange={this.handleAddress}
                                              value={this.state.address}/>
                                </MDBCol>
                                <MDBCol size={6}>
                                    <MDBInput className={this.state.streetError ? "border-danger" : ""} maxLength="50"
                                              type='text' label="Address Line 2" onChange={this.handleStreet}
                                              value={this.state.street}/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBInput className={this.state.pincodeError ? "border-danger" : ""} maxLength="6"
                                              type='text' label="Postal Code" onChange={this.handlePincode}
                                              value={this.state.pincode}/>
                                </MDBCol>
                                <MDBCol>
                                    <MDBInput className={this.state.areaError ? "border-danger" : ""} maxLength="35"
                                              type='text' label="Area/Locality" onChange={this.handleArea}
                                              value={this.state.area}/>
                                </MDBCol>
                                <MDBCol>
                                    <MDBInput   className={this.state.cityError ? "border-danger" : ""} maxLength="35"
                                              type='text' label="City" onChange={this.handleCity}
                                              value={this.state.city}/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBInput className={this.state.stateError ? "border-danger" : ""} maxLength="35"
                                              type='text' label="State" onChange={this.handleState}
                                              value={this.state.state}/>
                                </MDBCol>
                                <MDBCol>
                                    <MDBInput className={this.state.landmarkError ? "border-danger" : ""} maxLength="50"
                                              type='text' label="Landmark" onChange={this.handleLandmark}
                                              value={this.state.landmark}/>
                                </MDBCol>
                                <MDBCol>
                                    <MDBInput className={this.state.countryError ? "border-danger" : ""} maxLength="35"
                                              type='text' label="Country" onChange={this.handleCountry}
                                              value={this.state.country}/>
                                </MDBCol>
                            </MDBRow>

                            <MDBRow>
                                <MDBCol>
                                    <FileUpload buttonLabel={"Add Organization Logo"} initUploadCallback={this.initUploadCallback} fileUploadCallback={this.fileUploadCallback}></FileUpload>
                                </MDBCol>
                            </MDBRow>
                            <div className="m-0 text-center">
                                <MDBBtn  size="md" onClick={this.addOrgUser} className="mr-auto"
                                        disabled={this.state.orgUserError || this.state.progressStatus || this.state.progressStatusFileUpload}>
                                    Save
                                </MDBBtn>
                            </div>

                        </MDBCardBody>
                    </MDBCard>


                </MDBContainer>
            </React.Fragment>
        );
    }
}
export default withRouter(AddOrgUser)