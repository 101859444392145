import React from "react";
import {Link} from "react-router-dom";
import { withRouter } from "react-router-dom";
import {
    MDBContainer, MDBFormInline, MDBBtn,
    MDBCard, MDBCardBody, MDBCardHeader, MDBBreadcrumb, MDBBreadcrumbItem, MDBModalHeader, MDBModalBody,
    MDBModalFooter, MDBModal, MDBInput, MDBRow, MDBCol
} from 'mdbreact';
import UserStore from "../../stores/userStore";
import {toast} from "react-toastify";
import PharmacyService from "../../services/pharmacyService";
import dataTableCustomer from "./dataTableCustomer";
import config from "../../config";
import Moments from "moment"
import SimpleBackdrop from "../common/overlay";
import AuthService from "../../services/authService";
import CustomerInsights from "../report/customerInsghts";

class ListCustomer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userData:{},
            activeCustomerName:"",
            activeCustomerId:0,
            customerList:[],
            customerApiResultCame:false,
            progressStatus:false,
            data:{},
            activeCustomer:{
                imageUrl:{
                    filePathoriginal:""
                }
            },
            modalStatus:false,
            statusActionName:"",
            suggestedUser:[],
            user:{},
            allSuggestedUser:{},
            searchedCustomerText:"",
            selectedCustomer:{},
        }
    }
    // This methods calls first
    async componentDidMount  () {
        let userData=await UserStore.fetchSessionData();
        this.setState({userData:userData});
        if(this.props.queryString){
            if(this.props.queryString.hasOwnProperty("customerId")){
                this.setState({user:{_id:this.props.queryString.customerId}})
            }
        }
        // We want to make sure that we call the customer when this component loads.
       // this.fetchCustomer()
    }
    // This will fetch the customer and store the data in state and initiate the MDBDataTable
    fetchCustomer = async () => {
        let userData=await UserStore.fetchSessionData();
        this.setState({progressStatus:true});
        let data = await PharmacyService.fetchCustomer(userData);
        this.setState({customerApiResultCame:true})
        this.setState({progressStatus:false});
        if(data){
            try{
                this.setState({progressStatus: false, customerList: data});
                let dataTablePayload={
                    columns:dataTableCustomer.customer,
                    rows:data.map((item, index) => {
                        item["customerSince"]=this.convertToReadbleDate(item.memberSince)
                        item["handleEdit"]=<div color="primary" className={"blue-text btn-link cursor-pointer"} size="sm" onClick={()=>{this.onView(item)}}>Edit</div>;
                        return item;
                    })
                };
                this.setState({data:dataTablePayload});
            } catch (e) {
                toast.error("Something went wrong while fetching customer.")
            }
        }
    }
    convertToReadbleDate = date =>{
        return Moments(date).format("MMM D, YYYY");
    }
    // This toggle opens or closes the delete customer modal
    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    }
    // This toggle opens or closes the custom lightbox
    toggleLB = () => {
        this.setState({
            modalLB: !this.state.modalLB
        });
    }
    // This triggers from MDBDataTable, aim is to redirect to edit page
    onEdit=(row)=>{
        this.props.history.push('/edit-customer/'+row._id);
    }
    onView=(row)=>{
        this.props.history.push('/edit-customer/'+row._id);
    }
    onDownloadImage=(row)=>{
        console.log(row);
        this.setState({"activeCustomer":row})
        this.toggleLB()
    }
    // This redirect to new customer
    goToNewCustomer = () =>{
        this.props.history.push('/add-customer/');
    }
    initChangeStatus = (row) => {
        this.setState({ activeCustomerId:row._id, statusAction: (row.isActive === "Y") ? "deactivate" : "activate", statusActionName: row.customer});
        this.toggleStatus();
    }
    updateStatus = async () => {
        let userData=await UserStore.fetchSessionData();
        this.setState({progressStatus:true});
        let status = (this.state.statusAction === "activate") ? "Y" : "N";
        let res =await PharmacyService.updateCustomerStatus(this.state,status,userData);
        this.setState({progressStatus:false});
        this.toggleStatus()
        this.setState({ activeCustomerId:0, statusAction: "", statusActionName: ""});
        if(res===false){
            toast.error("Something went wrong.");
            return true;
        }else{
            toast.success("Customer status updated successfully.");
            this.fetchCustomer();
        }
    }
    toggleStatus = () => {
        this.setState({
            modalStatus: !this.state.modalStatus
        });
    }

    handleCustomerSearch = async (e)=> {
        this.setState({searchedCustomerText:e.target.value})
        if(e.target.value.length < 2){
            return false;
        }
        let resUser = await AuthService.suggestCustomer(e.target.value, this.state);
        let suggestedUser = [];
        for(let item of resUser){
            if((item.hasOwnProperty("name") && item.name ) ||(item.hasOwnProperty("first_name") && item.first_name )){
                //if(item.relation === "SELF"){
                suggestedUser.push(item)
                //}
            }
        }
        this.setState({allSuggestedUser: suggestedUser});
    }

    selectCustomer= async (row)=>{
        await this.setState({user:row})
        await this.setState({customerError:false})
        //this.fetchDependentsByUserId(row.userId)

        await this.setState({searchedCustomerText:""})
        this.props.history.push('/view-customers/'+row._id);

    }

    fetchDependentsByUserId = async (id) => {
        this.setState({progressStatus:true});
        let data =await AuthService.fetchDependentsByUserId(this.state, id);
        this.setState({progressStatus:false});
        if(data===false){
            toast.error("Something went wrong.");
            return true;
        }else{
            console.log("Data", data)
            this.setState({dependents:data})
            for(let item of data){
                if(item.relation === "SELF"){
                    this.setState({selectedDependent: item});

                }
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>View Customers</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <SimpleBackdrop status={(this.state.progressStatus === true || this.state.updateProgress === true)?true:false}/>
                <MDBContainer className={'w-100 mw-100'}>
                    <MDBCard className={"mt-2"}>
                        <MDBCardHeader>
                            <span className={"float-left title"}>View Customers</span>
                            <MDBFormInline className="float-right mr-auto m-0">
                                <MDBBtn color="default" size="sm" onClick={this.goToNewCustomer} className="mr-auto" >
                                    Add Customer
                                </MDBBtn>
                            </MDBFormInline>
                        </MDBCardHeader>
                        <MDBCardBody>

                            <MDBRow>
                                <MDBCol size={"4"} >
                                <MDBInput label="Search customer by name, phone or e-mail" outline={true} maxLength="50"
                                          type='text'  onChange={this.handleCustomerSearch}
                                          value={this.state.searchedCustomerText}  />


                                {(this.state.allSuggestedUser.length > 0 && this.state.searchedCustomerText ) &&
                                <div className="card" >
                                    <ul className="list-group list-group-flush">
                                        {this.state.allSuggestedUser.map((row, index) => (
                                            <li key={index} className="list-group-item">

                                                <MDBRow style={{"fontSize":"13px"}}>
                                                    <MDBCol size={"2"}>
                                                        {(row.hasOwnProperty("profilePic") && row.profilePic ) &&

                                                        <img width={"40"} alt={""}
                                                             src={config.JAVA_IMAGE_HOST + row.profilePic}/>

                                                        }
                                                        {(row.hasOwnProperty("profilePic") && !row.profilePic ) &&
                                                        <img width={"40"} alt={""} src={require('../../components/common/img/profile-pic-user.png')}/>
                                                        }
                                                    </MDBCol>
                                                    <MDBCol size={"6"}>
                                                        {row.hasOwnProperty("name") && row.name &&
                                                        <div>{row.name}</div>
                                                        }
                                                        {(!row.hasOwnProperty("name")  || (row.hasOwnProperty("name") && !row.name)) &&
                                                        <div>
                                                            {row.hasOwnProperty("first_name") &&
                                                            <span>{row.first_name}</span>
                                                            }

                                                            {row.hasOwnProperty("last_name") &&
                                                            <span> {row.last_name}</span>
                                                            }
                                                        </div>
                                                        }
                                                        {row.hasOwnProperty("email") && row.email &&
                                                        <div>{row.email}</div>
                                                        }

                                                        {(!row.hasOwnProperty("email")  || (row.hasOwnProperty("email") && !row.email)) &&
                                                        <b>
                                                            {row.hasOwnProperty("user_email") &&
                                                            <div>{row.user_email}</div>
                                                            }
                                                        </b>
                                                        }
                                                        {row.hasOwnProperty("name") && row.contactNo &&
                                                        <div>{row.contactNo}</div>
                                                        }
                                                        {(!row.hasOwnProperty("contactNo")  || (row.hasOwnProperty("contactNo") && !row.contactNo)) &&
                                                        <>
                                                            {row.hasOwnProperty("user_contact_no") &&
                                                            <div>{row.user_contact_no}</div>
                                                            }
                                                        </>
                                                        }
                                                    </MDBCol>
                                                    <MDBCol size={"3"}>
                                                        <MDBBtn size={"sm"} onClick={()=>this.selectCustomer(row)}>Select</MDBBtn>
                                                    </MDBCol>
                                                </MDBRow>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                }




                            </MDBCol>
                            </MDBRow>

                            <MDBRow className={"p-0 m-0"}>
                                <MDBCol className={"p-0 m-0"}>
                                    {(this.state.hasOwnProperty("user") && this.state.user.hasOwnProperty("_id")) &&

                                        <CustomerInsights key={this.state.user._id} queryString={this.state.user._id}/>

                                    }
                                </MDBCol>
                            </MDBRow>

                            {/*{(this.state.customerApiResultCame && this.state.customerList.length < 1) &&*/}
                            {/*    <div className={"no-data-container"}>No Customer found</div>*/}
                            {/*}*/}
                            {/*{this.state.customerList.length > 0 &&*/}

                            {/*<MDBDataTable*/}
                            {/*    striped*/}
                            {/*    */}
                            {/*    data={this.state.data}*/}
                            {/*    materialSearch*/}
                            {/*    noBottomColumns*/}
                            {/*    hover*/}
                            {/*/>*/}
                            {/*}*/}
                        </MDBCardBody>
                    </MDBCard>
                    <MDBModal isOpen={this.state.modalLB} toggle={this.toggleLB} size={"lg"}>
                        <MDBModalHeader toggle={this.toggleLB}></MDBModalHeader>
                        <MDBModalBody className={"float-center"}>
                            <div style={{"textAlign":"center"}}>
                                <img src={config.IMAGE_HOST + this.state.activeCustomer.imageUrl.filePathOriginal} className={"float-center"}
                                     style={{maxWidth: "440px", maxHeight: "440px"}} alt={"Active customer"}/>
                            </div>
                        </MDBModalBody>
                    </MDBModal>
                    <MDBModal isOpen={this.state.modalStatus} toggle={this.toggleStatus}>
                        <MDBModalHeader toggle={this.toggleStatus}>Update Customer Status</MDBModalHeader>
                        <MDBModalBody>
                            Are you sure you want to {this.state.statusAction} customer {this.state.statusActionName}?
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn onClick={this.updateStatus} disabled={this.state.progressStatus}>{this.state.statusAction}</MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>
                </MDBContainer>
            </React.Fragment>
        );
    }
}
export default withRouter(ListCustomer)
