import React, { useEffect, useRef, useState } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBInput,
  MDBBtn,
  MDBCardHeader,
  MDBFormInline,
  MDBCardBody,
  MDBCard,
  MDBCol,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBIcon,
} from "mdbreact";
import UserStore from "../../stores/userStore";
import InventoryService from "../../services/inventoryService";
import config from "../../config";
import styles from './ViewProductsDetails.module.css'
import SimpleBackdrop from "../../components/common/overlay"


const ViewProductsModal = ({ productId }) => {


  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [productDetails, setProductDetails] = useState([]);

  const fetchProductData = async () => {
    try {
      setLoading(true);
      let user = await UserStore.fetchSessionData();
      const res = await InventoryService.fetchProductById(
        user,
        productId
      );
      if (res) {
        setProductDetails(res);
        setLoading(false);
      } else {
        setProductDetails([]);
        setLoading(false);
      }
    } catch (err) {
      console.log(err)
    }
  };

  useEffect(() => {
    if (open === true) {
      fetchProductData()
    }
  }, [productId, open])


  return (
    <>
      <SimpleBackdrop status={loading} />
      <MDBBtn onClick={() => setOpen(!open)}>View</MDBBtn>
      {open &&
        <MDBModal isOpen={open} size={"lg"}>
          <MDBModalHeader>Product Details</MDBModalHeader>
          <MDBModalBody>
            <div className={styles.product_info}>
              <img src={`${config.IMAGE_HOST}${productDetails?.imageUrl?.[0]?.filePathOpt}`} className={styles.image} alt="product" />
              <div>
                <h3>{productDetails?.productName}</h3>
                <p>{productDetails?.description}</p>
                <p><b>How to use : </b> {productDetails?.howTouse}</p>
                <div>
                  {/* <div className={styles.product_info_item}>
                    <span><b>Max Retail Price</b></span>
                    <span>: {productDetails?.maxRetailPrice}</span>
                  </div> */}
                  <div className={styles.product_info_item}>
                    <span><b>Stock</b></span>
                    <span>: {productDetails?.stock}</span>
                  </div>
                  <div className={styles.product_info_item}>
                    <span><b>Manufacturer</b></span>
                    <span>: {productDetails?.manufacturer?.[0]?.manufacturer}</span>
                  </div>
                  <div className={styles.product_info_item}>
                    <span><b>Medicine Type</b></span>
                    <span>: {productDetails?.medicineType}</span>
                  </div>
                  {/* <div className={styles.product_info_item}>
                        <span><b>Maximum Quantity</b></span>
                        <span>: {productDetails?.maxQuantity}</span>
                    </div> */}
                  {/* <div className={styles.product_info_item}>
                    <span><b>Discount</b></span>
                    <span>: {productDetails?.discount}%</span>
                  </div> */}
                </div>

              </div>
            </div>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={() => setOpen(false)}>Close</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      }

    </>
  );
};

export default ViewProductsModal;
