const config = {
  APIHOST: "http://ec2-3-110-81-76.ap-south-1.compute.amazonaws.com:5000",
  APIHOST_2: "http://ec2-3-110-81-76.ap-south-1.compute.amazonaws.com:8000",

  //STGAPI: "https://stgadminapi.positrarx.com",
  // APIHOST: "https://stgadminapi.positrarx.com",
  APIHOST_FOR_SEARCH_CUSTOMER: "http://ec2-3-110-81-76.ap-south-1.compute.amazonaws.com:8000",
  APIHOST_3: "http://127.0.0.1:8000",

  WEBAPI_HOST: "https://api.positrarx.com",
  WEBHOST: "https://positrarx.com",
  IMAGE_HOST: "http://adminapi.positrarx.com/api/v1/fetchImage/",
  UPLOADED_DOC_HOST: "https://adminapi.positrarx.com/api/v1/fetchDoc/",
  JAVA_IMAGE_HOST: "https://s3.ap-south-1.amazonaws.com/prod-positra/",

  //APIHOST:"http://localhost:5001",
  // APIHOST:"http://api.healthorbit.com",
  LOGIN: "/api/v1/account/login",
  ADD_PRODUCT: "/api/v1/admin/addProduct",
  FETCH_PRODUCTS: "/api/v1/admin/fetchProducts",
  FETCH_PRODUCT_BY_ID: "/api/v1/admin/fetchProductById",
  FETCH_PRODUCTS_BY_INGREDIENTS: "/api/v1/admin/fetchProductByIngredients",
  FETCH_ALL_CATEGORIES: "/api/v1/admin/fetchAllCategories",
  UPDATE_CATEGORIES: "/api/v1/admin/updateCategories",
  ADD_CATEGORY: "/api/v1/admin/addCategory",
  FETCH_CATEGORIES: "/api/v1/admin/fetchCategories",
  ADD_SUPPLIER: "/api/v1/admin/addSupplier",
  FETCH_SUPPLIERS: "/api/v1/admin/fetchSupplier",
  FETCH_SUPPLIER_BY_ID: "/api/v1/admin/fetchSupplierById",
  EDIT_SUPPLIER: "/api/v1/admin/editSupplier",
  UPDATE_SUPPLIER_STATUS: "/api/v1/admin/updateStatus",
  FETCH_CATEGORY_BY_ID: "/api/v1/admin/fetchCategoryById",
  EDIT_CATEGORY_BY_ID: "/api/v1/admin/editCategory",
  FETCH_MANUFACTURERS: "/api/v1/admin/fetchManufacturer",
  ADD_MANUFACTURERS: "/api/v1/admin/addManufacturer",
  UPDATE_MANUFACTURERS: "/api/v1/admin/updateManufacturer",
  FETCH_MANUFACTURER_BY_ID: "/api/v1/admin/fetchManufacturerById",
  DELETE_MANUFACTURER: "/api/v1/admin/deleteManufacturer",
  UPLOAD_IMAGE: "/api/v1/uploadImage",
  UPLOAD_DOC: "/api/v1/positra/secure/uploadDoc",
  UPLOAD_PRESCRIPTION_TO_ACCOUNT: "/api/v3/uploadPrescriptionToAccount",
  DELETE_FILE: "/api/v1/deleteFile",

  FETCH_ALL_RETAIL_PACKAGE: "/api/v1/admin/fetchRetailPackage",
  FETCH_ALL_QUANTITY_UNIT: "/api/v1/admin/fetchQuantityUnit",

  FETCH_RETAIL_PACKAGES: "/api/v1/admin/fetchRetailPackage",
  ADD_RETAIL_PACKAGE: "/api/v1/admin/addRetailPackage",
  UPDATE_RETAIL_PACKAGE: "/api/v1/admin/editRetailPackage",
  FETCH_RETAIL_PACKAGE_BY_ID: "/api/v1/admin/fetchRetailPackageById",
  DELETE_RETAIL_PACKAGE: "/api/v1/admin/deleteRetailPackage",

  FETCH_QUANTITY_UNITS: "/api/v1/admin/fetchQuantityUnit",
  ADD_QUANTITY_UNIT: "/api/v1/admin/addQuantityUnit",
  UPDATE_QUANTITY_UNIT: "/api/v1/admin/editQuantityUnit",
  FETCH_QUANTITY_UNIT_BY_ID: "/api/v1/admin/fetchQuantityUnitById",
  DELETE_QUANTITY_UNIT: "/api/v1/admin/deleteQuantityUnit",
  LOGOUT: "/api/v1/account/logOut",
  UPDATE_PRODUCT_IMAGE: "/api/v1/admin/updatProductImage",

  DELETE_PRODUCT: "/api/v1/admin/deleteProduct",
  DELETE_CATEGORY: "/api/v1/admin/deleteCategory",
  DELETE_SUPPLIER: "/api/v1/admin/deleteSupplier",
  UPDATE_PRODUCT_STATUS: "/api/v1/admin/updateProductStatus",
  UPDATE_MANUFACTURER_STATUS: "/api/v1/admin/updateManufacturerStatus",
  UPDATE_CATEGORY_STATUS: "/api/v1/admin/updateCategoryStatus",
  UPDATE_QUANTITY_UNIT_STATUS: "/api/v1/admin/updateQuantityUnitStatus",
  UPDATE_RETAIL_PACKAGE_STATUS: "/api/v1/admin/updateRetailPackageStatus",

  FETCH_ORGS: "/api/v1/account/fetchOrg",
  ADD_ORG: "/api/v1/account/createOrg",
  UPDATE_ORG: "/api/v1/account/editOrg",
  UPDATE_ORG_STATUS: "/api/v1/account/updateOrgStatus",
  FETCH_ORG_BY_ID: "/api/v1/account/fetchOrgUserById",
  DELETE_ORG: "/api/v1/account/deleteOrgUser",

  FETCH_ORG_USERS: "/api/v1/account/fetchUsers",
  CREATE_ORG_USER: "/api/v1/account/createUser",
  UPDATE_ORG_USER: "/api/v1/account/editUser",
  UPDATE_ORG_USER_STATUS: "/api/v1/account/updateUserStatus",
  FETCH_ORG_USER_BY_ID: "/api/v1/account/fetchUserById",
  DELETE_ORG_USER: "/api/v1/account/deleteUser",

  SEARCH_SUPPLIER: "/api/v1/lookup/searchSupplier",
  SEARCH_USER: "/api/v1/lookup/searchUser",
  SEARCH_CUSTOMER: "/api/v1/lookup/searchCustomer",
  SEARCH_MANUFACTURER: "/api/v1/lookup/searchManufacture",
  SEARCH_CATEGORY: "/api/v1/lookup/searchCategory",
  SEARCH_SHIPMENT: "/api/v1/lookup/searchShipment",
  // SEARCH_PRODUCT:"/api/v1/lookup/searchProduct",
  SEARCH_PRODUCT: "/api/v1/lookup/searchProductInv",

  CREATE_SHIPMENT: "/api/v1/shipments/createShipment",
  EDIT_SHIPMENT: "/api/v1/shipments/editShipment",
  FETCH_SHIPMENTS: "/api/v1/shipments/fetchShipments",
  FETCH_SHIPMENT_BY_ID: "/api/v1/shipments/fetchShipmentById",
  APPROVE_SHIPMENT: "/api/v1/shipments/approveShipment",
  REJECT_SHIPMENT: "/api/v1/shipments/rejectShipment",
  ADD_PAYMENT_SHIPMENT: "/api/v1/shipments/addPayment",

  CREATE_INVENTORY: "/api/v1/shipments/createInventory",
  FETCH_MY_INVENTORY: "/api/v1/shipments/fetchInventory",
  FETCH_INVENTORY_BY_ID: "/api/v1/shipments/fetchInventoryById",
  EDIT_INVENTORY: "/api/v1/shipments/editInventory",
  DELETE_INVENTORY: "/api/v1/shipments/deleteInventory",
  FETCH_INVENTORY_GST_DATA: "/api/v1/shipments/showGSTData",

  CREATE_CUSTOMER: "/api/v1/account/createCustomer",
  CREATE_CART: "/api/v1/orders/createCart",
  CREATE_ORDER: "/api/v1/orders/createOrder",
  ADD_TO_CART: "/api/v1/orders/addToCart",
  REMOVE_CART_ITEM: "/api/v1/orders/removeCartItem",
  FETCH_CART: "/api/v1/orders/fetchCart",
  FETCH_INVENTORY_BY_PRODUCT_ID: "/api/v1/shipments/fetchInventoryByProductId",
  FETCH_SUBSCRIPTIONS: "/api/v3/fetchSubscriptions",
  DELETE_SUBSCRIPTION: "/api/v3/deleteSubscription",
  FETCH_SUBSCRIPTIONS_NEW: "/api/v3/subscription/fetch-subscriptions",
  CREATE_SUBSCRIPTIONS: "/api/v3/subscription/create-subscription",


  FETCH_PRODUCT_REQUEST_V3: "/api/v3/product/fetchProductRequestv3",
  FETCH_PRODUCT_REQUESTS: "/api/v1/orders/fetchProductRequest",
  FETCH_PRODUCT_REQUEST_BY_ID: "/api/v1/orders/fetchProductRequestById",
  CREATE_PRODUCT_REQUEST: "/api/v1/orders/createProductRequest",
  EDIT_PRODUCT_REQUEST: "/api/v1/orders/editProductRequest",
  DELETE_PRODUCT_REQUEST: "/api/v1/orders/deleteProductRequest",
  UPDATE_MULTIPLE_PRODUCT_REQUEST:
    "/api/v1/orders/updateMultipleProductRequest",

  LOOKUP_MANUFACTURER: "/api/v1/lookup/searchManufacturer",
  LOOKUP_MEDICINETYPE: "/api/v1/lookup/searchMedicineType",
  FETCHALL_MEDICINE_TYPE: "/api/v1/admin/fetchMedicineType",
  UPDATE_MED_DISCOUNT: "/api/v1/admin/updateDiscount",
  CREATE_PURCHASE_NOTE: "/api/v3/createPurchaseNote",
  FETCH_PURCHASE_NOTE: "/api/v3/fetchPurchaseNote",
  EDIT_PURCHASE_NOTE: "/api/v3/editPurchaseNote",

  JAVA_HOST: "https://java.positra.com",
  SIGNUP: "/healthcare/services/v1/user/signUp/",
  SUGGEST_CUSTOMER: "/api/v1/lookup/suggestCustomer",
  SEARCH_CUSTOMER_PYTHON: "/api/v1/lookup/searchCustomer",
  SEARCH_PROVIDER_PYTHON: "/api/v1/lookup/suggestDoctor",
  SEARCH_CUSTOMER_JAVA: "/healthcare/services/v1/common/searchPatient",
  UPDATE_USER_ADDRESS_POST:
    "/healthcare/services/v1/user/updateUserAddressPost",
  SET_DEFAULT_ADDRESS: "/healthcare/services/v1/user/defaultAddress",
  DELETE_ADDRESS: "/healthcare/services/v1/user/deleteAddress",
  CREATE_CUSTOMER_ADDRESS: "/api/v3/addAddress",

  CREATE_DOCTOR: "/api/v1/account/createDoctor",

  SEARCH_PROVIDER_HOSPITAL_JAVA:
    "/healthcare/services/v1/hospital/searchProvider",

  ADD_DEPENDENT: "/api/v3/addDependent",
  FETCH_DEPENDENTS_BY_USER_ID: "/api/v3/fetchDependents",
  CREATE_OFFLINE_ORDER: "/api/v1/orders/createOrderOffline",
  UPDATE_OFFLINE_ORDER: "/api/v1/orders/editOrderOffline",
  UPDATE_SHIPPING_ADDRESS_FOR_OFFLINE_ORDER:
    "/api/v1/orders/editShippingAddressForOrderOffline",

  DELETE_OFFLINE_ORDER: "/api/v1/orders/deleteOrderOffline",
  CANCEL_OFFLINE_ORDER: "/api/v1/orders/cancelOfflineOrder",
  PAY_OUTSTANDING: "/api/v1/orders/updateBalance",

  EDIT_OFFLINE_ORDER: "/api/v1/orders/editOrderOffline",
  FETCH_ORDERS: "/api/v1/orders/fetchOrders",
  FETCH_OFFLINE_ORDERS: "/api/v1/orders/fetchOrdersOffline",
  //FETCH_ONLINE_ORDERS: "/api/v1/orders/fetchOrdersOnline",
  FETCH_SUBORDER_BY_ID: "/api/v1/orders/fetchOrdersOnlineById",
  CREATE_ONLINE_ORDER: "/api/v3/createOnlineOrder",
  //FETCH_ONLINE_MASTER_ORDERS_BY_ORG_ID:"",
  UPDATE_ONLINE_ORDER_STATUS: "/api/v1/orders/updateStatus",
  FETCH_FEEDBACK: "/api/v3/fetchFeedback",
  ADD_CALL_LOG: "/api/v3/addCallLog",

  UPDATE_PRODUCT: "/api/v1/admin/editProduct",
  UPDATE_PRODUCT_SHORTCUT: "/api/v1/admin/editProductPart",
  FETCH_EDIT_DATA: "/api/v1/admin/fetchEditProductData",
  UPDATE_REORDER: "/api/v1/admin/productReorder",
  UPDATE_PRODUCT_SHIPPING: "/api/v1/admin/productShipping",
  FETCH_ALL_CUSTOMERS: "/api/v1/account/fetchCustomers",
  FETCH_CUSTOMER_BY_ID: "/api/v1/account/fetchCustomerById",

  ASSOCIATE_CUSTOMER: "/api/v1/account/associateCustomer",
  UPDATE_CUSTOMER: "/api/v1/account/editCustomer",
  FETCH_ALL_ADDRESSES: "/api/v3/fetchCustomerAddress",
  DELETE_CUSTOMER: "/api/v1/account/deleteCustomer",
  DELETE_ADDRESS_PYTHON: "/api/v3/deleteAddress",
  EDIT_ADDRESS_PYTHON: "/api/v3/editAddress",
  DELETE_DEPENDENT: "/api/v3/deleteDependent",
  SEARCH_PRODUCT_INV: "/api/v1/lookup/searchProductInv",
  FETCH_INV_BY_IDS: "/api/v1/shipments/fetchInventoryByIds",
  SEARCH_PRODUCT_INV_EXP: "/api/v1/lookup/searchProductInvExp",
  SEARCH_PRODUCT_INV_ZERO: "/api/v1/lookup/searchProductInvZero",
  SUGGEST_PRODUCT: "/api/v1/lookup/suggestProduct",
  WEEKLY_SALES_WIDGET: "/api/v1/reports/fetchMonthlySalesWidget",
  MONTHLY_SHIPMENT_WIDGET: "/api/v1/reports/fetchMonthlyShipmentsWidget",
  MONTHLY_EXPIRED_WIDGET: "/api/v1/reports/fetchMonthlyExpiredWidget",
  CHANGE_PASSWORD: "/api/v1/account/updatePassword",
  SEND_REQUEST_SMS: "/api/v1/orders/sendSms",
  LOOKUP_MED_ORDER: "/api/v1/lookup/searchProductInvOrder",
  FETCH_DOCTORS: "/api/v1/account/fetchDoctors",
  MONTHLY_COSTS: "/api/v1/reports/fetchMonthlyCost",
  CASH_CREDIT_WIDGET: "/api/v1/reports/cashCreditPie",
  MONTHLY_ORDER_PIE_WIDGET: "/api/v1/reports/fetchMonthlyOrderPie",
  REQUEST_CREATE_REPORT: "/api/v3/requestReport",
  FETCH_REQUEST_REPORT_LIST: "/api/v3/fetchRequestReportList",
  DELETE_REPORT_REQUEST: "/api/v3/deleteReportRequest",
  FETCH_DOCTOR_BY_ID: "/api/v1/account/fetchDoctorById",
  EDIT_DOCTOR: "/api/v1/account/editDoctor",
  DELETE_DOCTOR: "/api/v1/account/deleteDoctors",
  DELETE_SHIPMENT: "/api/v1/shipments/deleteShipment",
  UPDATE_SHIPMENT_DOCUMENT: "/api/v1/shipments/updateShipmentFile",

  OPTIONAL_STOCK_SEARCH: "/api/v1/lookup/searchStock",
  STOCK_COUNT: "/api/v1/reports/totalStock",
  SUGGEST_MFG: "/api/v1/lookup/suggestMfg",
  SUGGEST_SUPPLIER: "/api/v1/lookup/suggestSupplier",
  FETCH_PRESCRIPTION_ORDERS: "/api/v1/orders/fetchOrdersByPrescriptionUpload",
  FETCH_PRESCRIPTION_ORDER_BY_ID: "/api/v1/orders/fetchOrdersById",
  FETCH_ORDER_BY_ORDER_CODE: "/api/v1/orders/fetchOrderByOrderCode",
  CREATE_OFFLINE_ORDER_RETURN_INWARD:
    "/api/v1/orders/createOfflineOrderReturnInward",
  FETCH_OFFLINE_ORDERS_RETURN_INWARD:
    "/api/v1/orders/fetchOfflineOrdersReturnInward",
  UPDATE_PRESCRIPTION_ORDER_STATUS: "/api/v1/orders/updateStatus",
  PROCESS_PRESCRIPTION_ORDER: "/api/v1/orders/processPrescriptionOrder",
  FETCH_PRODUCTS_PAGINATED: "/api/v1/list/products",
  ADD_NEW_SPECIALITYGROUP: "/api/v1/speciality/addSpecialityGroup",
  FETCH_SPECIALITYGROUP: "/api/v1/speciality/fetchSpecialityGroup",
  DELETE_SPECIALITYGROUP: "/api/v1/speciality/deleteSpecialityGroup",
  LOOKUPSPECIALITY_GROUP: "/api/v1/lookup/suggestSpeciaityGroup",
  EDIT_SPECALITY_GROUP: "/api/v1/speciality/editSpecialityGroup",
  ADD_SPECIALITY: "/api/v1/speciality/addSpeciality",
  LOOKUP_SPECIALITY: "/api/v1/lookup/suggestSpeciaity",
  FETCH_SPECIALITY: "/api/v1/speciality/fetchSpeciality",
  FETCH_SPECIALITY_BY_ID: "/api/v1/speciality/fetchSpecialityById",
  DELETE_SPECIALITY: "/api/v1/speciality/deleteSpeciality",
  EDIT_SPECIALITY: "/api/v1/speciality/editSpeciality",
  CUSTOM_SALES_REPORT: "/api/v1/reports/fetchCustomSalesReport",
  FETCH_SALES_INTELLIGENCE: "/api/v1/reports/fetchSalesIntelligence",
  FETCH_CUSTOMER_INSIGHTS: "/api/v3/fetchAnalyticsOrder",
  FETCH_SPECIAL_DIET: "/api/v3/inventory/fetchSpecialDiet",
  FETCH_SIDE_EFFECTS: "/api/v3/inventory/fetchSideEffects",
  FETCH_PRODUCT_TYPE: "/api/v3/inventory/fetchProductType",
  FETCH_RETAIL_PKG: "/api/v3/inventory/fetchRetailPkg",
  FETCH_MATERIAL: "/api/v3/inventory/fetchMaterial",
  FETCH_TOY_FEATURE: "/api/v3/inventory/fetchToyFeature",
  FETCH_TOY_COLLECTION: "/api/v3/inventory/fetchToyCollection",
  FETCH_PRODUCT_SCENT: "/api/v3/inventory/fetchProductScent",
  FETCH_MEDICINE_SCHEDULE: "/api/v3/inventory/fetchMedicineSchedule",
  FETCH_GUARANTEED_ANALYSIS: "/api/v3/fetchGuranteedAnalysis",
  FETCH_STOCK_LIST: "/api/v3/stock/fetch-stock",
  FETCH_STOCK_BY_LOCATION: "/api/v3/stock/endpoint/location/fetch-stock",
  RELOCATE_RACK: "/api/v3/stock/endpoint/rack-relocate",

  FETCH_LIST_OF_PRODUCTS_WITH_COMPLETE_DATA:
    "/api/v2/positrarx/fetchAllProducts",
  FETCH_PRODUCTS_WITH_MARKED_FILTER:
    "/api/v2/positrarx/fetchProductsWithMarkedFilter",
  FETCH_DUPLICATE_PRODUCTS: "/api/v2/positrarx/fetchAllDuplicateProducts",
  MARK_PRODUCT: "/api/v1/reports/markProduct",
  UPDATE_STOCK_AVAILABILITY: "/api/v1/reports/updateStockAvailability",
  FETCH_PRODUCTS_TRANSACTION: "/api/v1/reports/fetchTransactionsForProduct",
  DELETE_PRODUCTS_IN_BULK: "/api/v1/reports/deleteProductsInBulk",
  FETCH_PRODUCT_DELETE_HISTORY: "/api/v1/reports/fetchProductDeleteHistory",
  RECOVER_DELETED_PRODUCTS_IN_BULK: "/api/v1/reports/recoverProductsInBulk",
  FETCH_PRODUCT_DELETE_HISTORY_BY_ID:
    "/api/v1/reports/fetchProductDeleteHistoryById",
  FETCH_PRODUCT_SALES_ANALYSIS: "/api/v1/reports/fetchProductSalesAnalysis",

  FETCH_ONLINE_ORDERS: "/api/v3/fetchOnlineOrders",
  FETCH_UNFULFILLED_ORDERS: "/api/v3/order/online/fetch-orders",
  FETCH_ONLINE_ORDERS_CALL_LOG: "/api/v3/lookup/fetch-customer-call-logs-by-order",
  FETCH_PRODUCT_INSIGHTS: "/api/v3/fetchAnalyticsProduct",
  FETCH_ONLINE_ORDERS_BY_ID: "/api/v3/fetchOnlineOrderById",
  FETCH_SEARCHED_UNFULFILLED_ORDERS: "/api/v3/order/online/search-orders",

  FETCH_ONLINE_ORDERS_INVENTORY_BY_ID: "/api/v3/fetchInventoryById",
  ACKNOWLEDGE_ONLINE_ORDER: "/api/v3/ackOrder",
  CANCEL_ONLINE_ORDER: "/api/v3/cancelOrder",
  APPROVE_ONLINE_ORDER: "/api/v3/approveOrder",
  UPLOAD_DOCUMENT: "/api/v3/uploadDocument",
  REQUEST_DOCUMENT: "/api/v3/requestDocument",
  READY_FOR_DELIVERY_ONLINE_ORDER: "/api/v3/markReadyForDelivery",
  FULFIL_ONLINE_ORDER: "/api/v3/fulfillOrder",
  CONVERT_ONLINE_ORDER: "/api/v3/convertOnlineOrder",
  UPDATE_ONLINE_PAYMENT_STATUS: "/api/v3/updateOnlinePaymentStatus",
  FETCH_DELIVERY_PERSON_DETAILS: "/api/v3/fetchDeliveryPersonDetails",
  OUT_FOR_DELIVERY_ONLINE_ORDER: "/api/v3/outForDelivery",
  MARK_AS_DELIVERED_ONLINE_ORDER: "/api/v3/markAsDelivered",
  FETCH_CUSTOMER_BY_ID_v3: "/api/v3/fetchCustomerById",
  ADD_CUSTOMER_V3: "/api/v3/createCustomer",
  FETCH_STORAGE_LOCATION: "/api/v3/fetchStorageLocations",
  ADD_STORAGE_LOCATION: "/api/v3/addStorageLocation",
  UPDATE_STORAGE_LOCATION: "/api/v3/updateStorageLocation",
  DELETE_STORAGE_LOCATION: "/api/v3/deleteStorageLocation",
  FETCH_STORAGE_LOCATION_BY_ID: "/api/v3/fetchStorageLocationById",
  FETCH_FLOORS: "/api/v3/fetchFloor",
  ADD_FLOORS: "/api/v3/addFloors",
  EDIT_FLOOR: "/api/v3/updateFloor",
  DELETE_FLOOR: "/api/v3/deleteFloor",
  FETCH_FLOORS_BY_ID: "/api/v3/fetchFloorById",
  FETCH_STORE_LOC: "/api/v3/fetchStorageLocations",
  FETCH_FLOOR: "/api/v3/fetchFloor",
  FETCH_FLOOR_NEW: "/api/v3/stock/fetch-floors",
  FETCH_RACK: "/api/v3/fetchRack",
  FETCH_RACK_NEW: "/api/v3/stock/fetch-racks",
  ADD_RACKS: "/api/v3/addRacks",
  EDIT_RACK: "/api/v3/updateRack",
  DELETE_RACK: "/api/v3/deleteRack",
  FETCH_BOX: "/api/v3/fetchBox",
  FETCH_BOX_NEW: "/api/v3/stock/fetch-boxes",
  ADD_BOXES: "/api/v3/addBoxes",
  EDIT_BOX: "/api/v3/updateBox",
  DELETE_BOX: "/api/v3/deleteBox",
  FETCH_RACK_BY_ID: "/api/v3/fetchRackById",
  FETCH_RACKS: "/api/v3/fetchRack",
  FETCH_BOXES: "/api/v3/fetchBox",
  FETCH_CATEGORY: "/api/v3/inventory/fetchCategory",
  FETCH_MFG: "/api/v3/inventory/fetchMfg",
  FETCH_UNIT: "/api/v3/inventory/fetchUnit",
  FETCH_LIFE_STAGE: "/api/v3/inventory/fetchLifeStage",
  FETCH_BREED_SIZE: "/api/v3/inventory/fetchBreedSize",
  FETCH_HEALTH_BENEFITS: "/api/v3/inventory/fetchHealthBenefits",
  FETCH_PRODUCT_FORM: "/api/v3/inventory/fetchProductForm",
  FETCH_FLAVOR: "/api/v3/inventory/fetchFlavor",
  FETCH_TEXTURE: "/api/v3/inventory/fetchTexture",
  FETCH_COLOR: "/api/v3/inventory/fetchColor",
  FETCH_INGREDIENT: "/api/v3/inventory/fetchIngredient",
  FETCH_PRODUCTS_BY_BATCH: "/api/v3/searchProductByBatch",
  FETCH_PRODUCTS_BY_INVOICE_ID: "/api/v3/searchProductByInvoiceId",
  FETCH_PRODUCT_BY_TERM: "/api/v3/searchProductByTerm",
  FETCH_PRODUCT_BY_NAME: "/api/v3/searchProductByName", //No duplicates
  DELETE_FROM_STOCK_RESET: "/api/v3/inventory/deleteFromStockReset",
  ADD_PRODUCT_V3: "/api/v3/addProduct",
  SAVE_RECEIVED_STOCK: "/api/v3/stock/save-received-stock",

  EDIT_PRODUCT_V3: "/api/v3/editProduct",
  DELETE_PRODUCT_V3: "/api/v3/deleteProduct",
  APPROVE_PRODUCT: "/api/v3/approveProduct",
  REJECT_PRODUCT: "/api/v3/rejectProduct",
  GET_PRODUCT_LOG: "/api/v3/product/getProductLog",
  ADD_PRODUCT_GROUP: "/api/v3/addProductGroup",
  EDIT_PRODUCT_GROUP: "/api/v3/editProductGroup",
  MRP_AND_STOCK_SYNC_BY_PRODUCT_ID: "/api/v3/stockMrpSyncByProductId",
  FETCH_PRODUCT_GROUPS: "/api/v3/fetchProductGroup",
  DELETE_PRODUCT_GROUP: "/api/v3/deleteProductGroup",
  FETCH_PRODUCTS_BY_CATEGORY_ID: "/api/v3/fetchProductsByCategoryId",
  DISASSOCIATE_PRODUCT_FROM_CATEGORY: "/api/v3/disassociateProduct",
  ADD_PRODUCT_TO_CATEGORY: "/api/v3/addProductToCategory",

  FETCH_TASK_LIST: "/api/v3/tasks/allTasks",
  MARK_TASK_AS_COMPLETE: "/api/v3/tasks/markTaskAsComplete",
  ADD_TASK: "/api/v3/tasks/addTask",
  ASSIGN_TASK: "/api/v3/assignTask",
  RESCHEDULE_TASK: "/api/v3/rescheduleTask",

  ORDER_WIDGET: "/api/v1/reports/fetchOrderCount",
  STOCK_WIDGET: "/api/v1/reports/stockDetails",
  CUSTOMER_WIDGET: "/api/v1/reports/userWidget",
  FETCH_INVENTORY_V3: "/api/v3/inventory/fetchInventory",
  ADD_STOCK_V3: "/api/v3/addStock",
  ADD_STOCK_V4: "/api/v3/stock/add-stock",
  RESET_STOCK_V3: "/api/v3/resetStock",
  FETCH_STORE_LOC_BY_STOCK_ID: "/api/v3/inventory/fetchStockStorageInfoById",
  APPROVE_STOCK: "/api/v3/inventory/approveStock",
  REJECT_STOCK: "/api/v3/inventory/rejectStock",
  FETCH_ORDER_BY_STOCK_ID: "/api/v3/inventory/fetchOrdersByStockId",
  FETCH_RECEIVE_STOCK: "/api/v3/stock/receive-stock",
  FETCH_DETAILS_BY_BARCODE: "/api/v3/stock/fetch-details-by-barcode",
  FETCH_PRODUCTS_BY_BARCODE_PRODUCTS: "/api/v3/searchProductByBarCodeProducts", //No duplicates
  FETCH_PRODUCTS_BY_BARCODE: "/api/v3/searchProductByBarCode", //No duplicates
  UPDATE_TRACKING_URL: "/api/v3/updateTrackingUrl",
  FETCH_EXPIRED_PRODUCT: "/api/v3/fetchExpProducts",
  UPDATE_EXP_STATE: "/api/v3/inventory/updateExpStatus",
  MARK_AS_PAYMENT_RECEIVED: "/api/v3/inventory/markExpReimbReceived",
  FETCH_BANNED_PRODUCTS: "/api/v3/fetchBannedProducts",
  UPDATE_BANNED_PRODUCTS: "/api/v3/updateBannedProduct",
  SEARCH_RODUCT: "/api/v3/searchProductAll",
  ADD_PAYMENT: "/api/v3/addPayment",
  FETCH_INWARD_CASH: "/api/v3/fetchPayments",
  FETCH_GENERAL_SUPPLIERS: "/api/v3/fetchGeneralSupplier",
  ADD_TO_BANK: "/api/v3/transferToBank",
  FETCH_ALL_ORDERS: "/api/v3/fetchOrders",
  CANCEL_ORDER_V3: "/api/v3/cancelOrderv3",
  EDIT_ORDER_V3: "/api/v3/editOrder",
  SEATCH_ORDER_V3: "/api/v3/searchOrder",
  ADD_COUPON: "/api/v3/addCoupon",
  FETCH_COUPONS: "/api/v3/fetchCupons",
  FETCH_CUSTOMER_COUPONS: "/api/v3/fetchCustomerCoupons",
  DELETE_COUPON: "/api/v3/deleteCoupon",
  RETURN_ITEM: "/api/v3/returnItem",
  FETCH_CREDIT_REPORT: "/api/v3/fetchCreditReport",
  MAX_FILE_SIZE: 20971520, // 20 MB
  allowedDocumentType: [
    "application/pdf",
    "text/plain",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.oasis.opendocument.text",
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/gif",
    "image/bmp",
  ],
  allowedImageType: [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/gif",
    "image/bmp",
  ],

  FETCH_CUSTOMER_ORDERS: "/api/v3/fetchCustomerOrders",
  ADD_CUSTOMER_CALL_LOG: "/api/v3/addCustomerCallLog",
  FETCH_CUSTOMER_CALL_LOG: "/api/v3/fetchCustomerCallLog",
  UPDATE_CUSTOMER_CALL_LOG: "/api/v3/updateCustomerCallLog",
  ADD_CUSTOMER_COUPON: "/api/v3/addCoupon",
  ADD_BANNER: "/api/v3/uploadBanner",
  EDIT_BANNER: "/api/v3/updateBanner",
  FETCH_BANNERS: "/api/v3/fetchBanners",
  DELETE_BANNER: "/api/v3/deleteBanner",
  ACTIVATE_BANNER: "/api/v3/activateBanner",
  DEACTIVATE_BANNER: "/api/v3/deActivateBanner",
  UPDATE_CHECKLIST: "/api/v3/updateChecklist",
  FETCH_CHECKLIST: "/api/v3/fetchChecklist",
  ASSIGN_ORDER: "/api/v3/assignOrder",
  ADD_QUICK_TIPS: "/api/v3/addQuickTips",
  ADDRESS_LOOKUP_BY_PHONE: "/api/v3/addressLookupByPhone",
  UPDATE_DISCOUNT: "/api/v3/updateDiscount",
  FETCH_PINCODE_DETAILS: "/api/v1/account/fetchPincodeDetails",
  CHECK_PIN_STATUS: "/api/v2/ship/checkPinStatus",
  SLOT_DATE: "/api/v2/checkout/checkDatesForSlot",
  FETCH_SLOTS: "/api/v2/checkout/fetchSlotsForDate",

  CURRENCY_HTML: "&#8377;",
  CURRENCY_SYMBOL: "\u20B9",
  CURRENCY_TEXT: "INR",

  AMAZON_TRACKING_LINK: "https://track.amazon.in/tracking/",
  DELHIVERY_TRACKING_LINK: "https://www.delhivery.com/track/package/",
  ECOMEXPRESS_TRACKING_LINK: "https://ecomexpress.in/tracking/?awb_field=",
  EKART_TRACKING_LINK: "https://ekartlogistics.com/shipmenttrack/",
  XPRESSBEES_TRACKING_LINK:
    "https://www.xpressbees.com/track?isawb=Yes&trackid=",
  DTDC_TRACKING_LINK: "https://www.aftership.com/track/dtdc/",
  BLUEDART_TRACKING_LINK: "https://www.aftership.com/track/bluedart/",
  AFTERSHIP_LINK: "https://www.aftership.com/track/",

  PICKRR_AUTH: "575cba4c439d05917405c9b5f6f59d26151253",

  // ECOM EXPRESS, POSITRARX PYTHON API
  FETCH_ECOM_AWB: "/api/v1/ecomexpress/fetch-ecom-awb",
  MANIFEST_ECOM_ORDER: "/api/v1/ecomexpress/manifest-ecom-order",
  CANCEL_ECOM_ORDER: "/api/v1/ecomexpress/cancel-ecom-awbs",
  FETCH_ECOM_SERVICE_AVAILABILITY:
    "/api/v1/ecomexpress/ecom-service-availability",
  TRACK_ECOM_ORDER: "/api/v1/ecomexpress/track-ecom-order",
  FETCH_SHIPPING_PERFORMANCE: "/api/v1/ecomexpress/fetch-shipping-performance",

  SEND_GOOGLE_REVIEW_SMS: "/api/v1/orders/sendGoogleReviewSms",
  ADD_RAZORPAY_DETAILS: "/api/v1/orders/addRazorpayDetails",
  VIEW_ORDER_FROM_ORDER_CODE: "/api/v1/orders/viewOrderDetailsFromOrderCode",

  //-------
  ADD_SUBSTITUTE_PRODUCT: "/api/v3/addSubstitute",
  FETCH_SUBSTITUTE_PRODUCT: "/api/v3/fetchSubstitute?masterProductId=",
  DELETE_SUBSTITUTE_PRODUCT: "/api/v3/deleteSubstitute",
  EDIT_PRODUCT_IMAGE_ALONE: "/api/v3/editProductImageAlone",

  DELETE_MASTER_PRODUCT: "/api/v3/substitute/deleteMaster",

  //-------------------
  FETCH_ALL_SUBSTITUTE_LIST: "/api/v3/listSubstitutes",

  // --------upload mobile banner ---------------------
  UPLOAD_MOBILE_BANNER: "/api/v3/uploadMobileBanner",
  EDIT_MOBILE_BANNER: "/api/v3/editMobileBanner",
  FETCH_MOBILE_BANNER: "/api/v3/fetchMobileBanners",
  DELETE_MOBILE_BANNER: "/api/v3/deleteMobileBanner",

  VISIT_OUT_OF_STOCK: "/api/v3/fetchOutOfStockProducts",
  UPDATE_INTERNAL_COMMENT: "/api/v3/updateInternalComment",

  FETCH_CUSTOMER_INDIVIDUAL_DATA_BY_SEARCH: "/api/v3/lookup/customer-details",
  FETCH_CUSTOMER_INDIVIDUAL_CALLLOGS_BY_SEARCH:
    "/api/v3/lookup/fetch-customer-call-logs",

  SAVE_EDIT_CUSTOMER_DETAILS: "/api/v3/lookup/edit-customer-profile",
  SAVE_EDIT_CUSTOMER_ADDRESS_DETAILS: "/api/v3/lookup/edit-customer-address",

  ADD_CALL_LOG_CUSTOMER: "/api/v3/lookup/add-call-log",
  FETCH_CUSTOMER_DATA_BY_SEARCH: "/api/v3/lookup/customer-search",
  FETCH_CUSTOMER_ANALYTICS_PRODUCT: "/api/v3/lookup/fetch-product-analysis",
  FETCH_CUSTOMER_ANALYTICS_ORDER: "/api/v3/lookup/fetch-orders",
  ADD_ADDRESS_CUSTOMER: "/api/v3/lookup/add-customer-address",
  FETCH_ORDERS_BY_USERID: "/api/v3/order/endpoint/online/fetch-orders-by-userid",



  FETCH_CUSTOMER_INDIVIDUAL_DATA_BY_SEARCH: "/api/v3/lookup/customer-details",
  FETCH_CUSTOMER_INDIVIDUAL_CALLLOGS_BY_SEARCH: "/api/v3/lookup/fetch-customer-call-logs",

  SAVE_EDIT_CUSTOMER_DETAILS: "/api/v3/lookup/edit-customer-profile",
  SAVE_EDIT_CUSTOMER_ADDRESS_DETAILS: "/api/v3/lookup/edit-customer-address",

  ADD_CALL_LOG_CUSTOMER: "/api/v3/lookup/add-call-log",
  FETCH_CUSTOMER_DATA_BY_SEARCH: "/api/v3/lookup/customer-search",
  //  FETCH_CUSTOMER_ANALYTICS_PRODUCT:"/api/v3/fetchAnalyticsProduct",
  //  FETCH_CUSTOMER_ANALYTICS_ORDER:"/api/v3/fetchAnalyticsOrder",


  //------- Transfer stock-------


  FETCH_PRODUCTS_DETAILS_BY_BARCODE: "/api/v3/stock/fetch-details-by-barcode",
  SAVE_TRANSFER_PRODUCT: "/api/v3/stock/transfer-stock",
  FETCH_LOCATIONS: "/api/v3/stock/fetch-locations",
  FETCH_TRANSFER_PACKAGE_LIST: "/api/v3/stock/fetch-product-transfers",
  SEARCH_PACKAGE_BY_BARCODE: "/api/v3/stock/fetch-product-transfer-barcode",
  STOCK_UPDATE: "/api/v3/stock/update-status-stock-transfer",



  FETCH_PRODUCTS_DETAILS_BY_BARCODE: "/api/v3/stock/fetch-details-by-barcode",
  SAVE_TRANSFER_PRODUCT: "/api/v3/stock/transfer-stock",
  FETCH_LOCATIONS: "/api/v3/stock/fetch-locations",
  FETCH_TRANSFER_PACKAGE_LIST: "/api/v3/stock/fetch-product-transfers",
  SEARCH_PACKAGE_BY_BARCODE: "/api/v3/stock/fetch-product-transfer-barcode",
  STOCK_UPDATE: "/api/v3/stock/update-status-stock-transfer",

  SAVE_EDIT_STOCK: "/api/v3/stock/edit-stock",
  SAVE_RESET_STOCK: "/api/v3/stock/reset-stock",
  FETCH_PRODUCT_BY_SEARCH_ID: "/api/v3/stock/fetch-stock-byid",
  TRANSFER_REVIEW: "/api/v3/stock/review-transfer",
  SEARCH_DROPDOWN: ["BARCODE", "BATCHNO", "INVOICENO", "PRODUCTNAME"],
  SEARCH_STOCK_INFO: [
    {
      // url :"api/v3/searchProductByBarCode?barCode",
      url: "api/v3/stock/search-stock?searchTerm",
      name: "Barcode",
      label: "BARCODE"
    },
    {
      // url :"api/v3/searchProductByBatch?batch",
      url: "api/v3/stock/search-stock?searchTerm",
      name: "Batch No.",
      label: "BATCHNO"
    },
    {
      // url :"api/v3/searchProductByInvoiceId?invoiceId",
      url: "api/v3/stock/search-stock?searchTerm",
      name: "Invoic No.",
      label: "INVOICENO"
    },
    {
      // url :"api/v3/searchProductByTerm?term",
      url: "api/v3/stock/search-stock?searchTerm",
      name: "Product",
      label: "PRODUCTNAME"
    }
  ],
  RESET_STOCK: "api/v3/stock/reset-stock",
  DELETE_STOCK: "/api/v3/stock/delete-transfer",
  SEARCH_DROPDOWN_PRODUCT: ["BARCODE", "PRODUCTNAME"],
  SEARCH_PRODUCT_INFO_BARCODE: "api/v3/stock/search-product-stock-barcode?barCode=",
  SEARCH_STOCK_ALLBYQUERY:"/api/v3/stock/endpoint/search-stock-all",
  LOCATION_WITH_PROD_ID: "api/v3/stock/fetch-stock-locations?productId=",
  FETCH_LIST_OF_PRODUCT_BY_NAME: "api/v3/stock/autosuggest-product?searchTerm=",
  FETCH_PRODUCT_WITH_NAME: "api/v3/stock/fetch-stock-locations?productId=",
  FETCH_STOCK_BYBARCODE_FOR_FULFILMENT_V2: "/api/v3/stock/fetch-product-details-barcode",
  //Report Module

  FETCHALLJOBS: "/api/getAllJobs",
  PUBLISH_JOB: "/api/publishJob",
  FETCH_JOB_HISTORY: "/api/getAllJobsHistory",
  FETCH_REPORT_SCHEDULE: "/api/getAllReportsSchedule",
  FETCH_ALL_REPORTS: "/api/getallReports",
  UPDATE_REPORT: "/api/updateReport",
  DELETE_REPORT: "/api/deleteReport",
  GENERATE_REPORT: "/api/publishReportBase",
  PUBLISH_REPORT: "/api/publishReportBase",
  CREATE_REPORT: "/api/reports",
  DOWNLOAD_REPORT: "/api/getReportFile",
  DOWNLOAD_JOBS: "/api/getJobFile",
  DEFAULT_PHARMACY_LOCATION: "60d07c4c353ce60289058c6b",

  FETCH_STOCK_BY_BARCODE_NEW: "/api/v3/stock/endpoint/location/search-stock-barcode?barCode=",
  RELOCATE_BY_BAR : "/api/v3/stock/endpoint/barcode-relocate"

};
export default config;
