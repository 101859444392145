import React from "react";
import {MDBBreadcrumb, MDBBreadcrumbItem} from "mdbreact";
import {Link} from "react-router-dom";
import CreditReportCmp from "../../../components/report/v2/creditReportCmp";

export default class CreditReport extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem>Reports</MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Credit Report</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <div className={"min-vh-100 p-0 m-0"}>
                    <CreditReportCmp/>
                </div>
            </React.Fragment>
        )
    }
}