import React from "react";
import {
    MDBContainer, MDBRow, MDBBtn, MDBCol, MDBIcon, MDBModal, MDBModalHeader, MDBModalBody
} from 'mdbreact';
import UserStore from "../../../stores/userStore";
import PharmacyService from "../../../services/pharmacyService";
import config from "../../../config";
import utility from "../../../utility/utility";
import {toast} from "react-toastify";

class MultipleDocsUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            manufacturer:"",
            description:"",
            isActive:"",
            imageUrl:"",
            progressStatus:false,
            manufacturerError:"",
            buttonLabel: (this.props.buttonLabel) ? this.props.buttonLabel : "Add Image",
            multiImage:(this.props.multiImage) ? this.props.multiImage : false,
            //
            filesList:[],
            totalCount: 0,
            orderItems:[],
            selectedFile: null,
            fileUploadedResObj:{},
            userData:{},
            modalLB:false,
            activeImage:"",
        }
        this.uploadPrescription = React.createRef();
    }

    async componentDidMount() {
        let userData=await UserStore.fetchSessionData();
        this.setState({userData: userData})
    }

    fileUploadCallback = (data) =>{
        this.props.fileUploadCallback(data)
    }

    onChangeHandler=event=>{
        console.log(event.target.files[0])

        if(!utility.checkAllowedDocumentType(event.target.files[0])){
            toast.error("Allowed file types are JPG/PNG/GIF/BMP/PDF/DOC/DOCX.");
            this.onClickHandlerCancel()
            return false;
        }
        if(!utility.checkMaxAllowedFileSize(event.target.files[0])){
            toast.error("File too big. Maximum file upload size is 20 MB");
            this.onClickHandlerCancel()
            return false;
        }
        this.setState({
            selectedFile: event.target.files[0],
            loaded: 0,
        })
    }

    onClickHandlerCancel = () => {
        this.setState({
            selectedFile: null,
        })
    }

    onClickHandler = async () => {
        if(this.state.selectedFile === "None"){
            this.onClickHandlerCancel()
            return false;
        }
        let userData=await UserStore.fetchSessionData();
        const data = new FormData()
        this.setState({loading:true, progressStatus:true});
        data.append('fileName', this.state.selectedFile);
        this.props.initUploadCallback();
        let fileUpload = await PharmacyService.uploadDoc(data,userData);
        this.setState({loading:false, progressStatus:false, selectedFile: null});
        if(fileUpload){
            this.setState({"fileUploadedResObj": fileUpload})
            let files= this.state.filesList;
            files.push(fileUpload.path)
            this.setState({filesList: files})
            this.fileUploadCallback(files)
        }
    }

    onInitFileHandler  = async () => {
        this.setState({"selectedFile":"None"})
        this.uploadPrescription.current.click();

    }

    initDeleteDoc = (index) => {
        let existingList = this.state.filesList;
        existingList.splice(index, 1);
        this.setState({"filesList": existingList})
    }

    updateProductImage = ()=>{
        this.props.updateProductImage(this.state.filesList)
        this.setState({filesList:[]})
    }
    showImg=(row)=>{
        let url=config.IMAGE_HOST+row.filePathOriginal;
        let img = '<img src="'+url+'" alt="">';
        let popup = window.open();
        popup.document.write(img);
        //popup.print();
    }

    onDownloadImage = (row) => {
        console.log(row);
        this.setState({activeImage: row})
        this.toggleLB()
    }

    // This toggle opens or closes the custom lightbox
    toggleLB = () => {
        this.setState({
            modalLB: !this.state.modalLB
        });
    }


    render() {
        console.log("finishedSaving", this.props.finishedSavingFile)
        return (
            <React.Fragment>
                <MDBContainer className={'p-0'} >
                    <form method="post" action="#" id="#">
                        <div className="form-group files">
                            <input type="file" style={{"display": "none"}} name="file" ref={this.uploadPrescription} onChange={this.onChangeHandler}/>
                            {!this.state.selectedFile &&
                            <MDBBtn outline size={"sm"}  onClick={this.onInitFileHandler}>
                                {this.state.filesList.length === 0 &&
                                <span>Add Document</span>
                                }
                                {this.state.filesList.length > 0 &&
                                <span>Add Another Document</span>
                                }
                            </MDBBtn>
                            }
                            {this.state.selectedFile &&
                            <div><MDBBtn outline={true} size={"sm"} className="btn btn-outline" onClick={this.onClickHandlerCancel}>Cancel</MDBBtn><MDBBtn size={"sm"} outline={true} className="btn btn-primary " onClick={this.onClickHandler}>Upload</MDBBtn>
                                {this.state.loading === true &&
                                <span> <img  style={{"width":"4rem"}} src={require('../../../components/common/img/loader_new.gif')} alt={"loading..."}/></span>
                                }
                                {this.state.selectedFile.name}</div>
                            }
                        </div>

                    </form>


                    <MDBRow>
                        <MDBCol>
                            <ul className="list-group list-group-horizontal">
                            {this.state.filesList.map((row, index) => (
                                <li key={index} className="list-group-item">
                                    {(utility.ifFileIsDocument(row)) &&
                                    <a className={'file-size float-left'}  href={utility.secureFilePath(this.state.userData.sessionToken,this.state.userData._id, row )}><MDBIcon style={{"fontSize":"3rem"}} icon="file"/></a>
                                    }
                                    {(utility.ifFileIsImage(row)) &&
                                    <img className={'file-size float-left'}   style={{cursor:"pointer", maxWidth:"100px", maxHeight:"100px"}}  onClick={()=>this.onDownloadImage(row)} src={utility.secureFilePath(this.state.userData.sessionToken,this.state.userData._id, row )}/>
                                    }
                                    <span className={"ml-1 mb-2 float-left"} style={{"cursor":"pointer", marginBottom:"-20px"}} onClick={()=>this.initDeleteDoc(index)}><MDBIcon icon="trash" /></span>
                                    <div style={{clear:"both"}}></div>
                                    <div className={"mt-1"}>File_{index+1}</div>
                                </li>
                            ))}
                            </ul>
                        </MDBCol>
                    </MDBRow>

                    <MDBModal isOpen={this.state.modalLB} toggle={this.toggleLB} size={"lg"}>
                        <MDBModalHeader toggle={this.toggleLB}></MDBModalHeader>
                        <MDBModalBody className={"float-center"}>
                            <div style={{"textAlign":"center"}}>
                                <img src={config.IMAGE_HOST + this.state.activeImage} className={"float-center"}
                                     style={{maxWidth: "440px", maxHeight: "440px"}} alt={""}/>
                            </div>
                        </MDBModalBody>
                    </MDBModal>
                </MDBContainer>
            </React.Fragment>
        );
    }
}
export default MultipleDocsUpload