import React,{Suspense} from "react";
import {Link} from "react-router-dom";
import {
    MDBBreadcrumbItem,
    MDBBreadcrumb,
} from 'mdbreact';
import ManageProductNewCmp from "../../components/product/manageProductNew";

export default class ManageProductNew extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>View Products</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <Suspense fallback={null}>
                    <ManageProductNewCmp/>
                </Suspense>
            </React.Fragment>
        );
    }
}
