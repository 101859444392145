import React from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import {
  MDBBtn,
  MDBModalHeader,
  MDBModalBody,
  MDBModal,
  MDBModalFooter,
  MDBInput,
} from "mdbreact";
import UserStore from "../../stores/userStore";
import { toast } from "react-toastify";
import PharmacyService from "../../services/pharmacyService";
import OrderService from "../../services/orderService";
import Moments from "moment";
import SimpleBackdrop from "../common/overlay";
import UpdateProductRequest from "./editProductRequest";
import { CSVLink } from "react-csv";
import MaterialTable from "material-table";
import ReportService from "../../services/reports";
import GetDateRange from "../common/getDateRange";
import config from "../../config";

const headers = [
  { label: "Created At", key: "createdAt" },
  { label: "Request Id", key: "_id" },
  { label: "Customer Name", key: "customerDetails.name" },
  { label: "Customer Phone", key: "customerPhone" },
  { label: "Product Name", key: "productName" },
  { label: "Expected Quantity", key: "quantity" },
  { label: "Request Status", key: "status" },
  { label: "Comments", key: "comments" },
];

const headersPharmarack = [
  { label: "productname", key: "productName" },
  { label: "qty", key: "quantity" },
];

const purchaseLogHelper = {
  CREATED: "Created",
  "IN PROCESS": "Processed",
  AVAILABLE: "Product Acquired",
  FULFILLED: "Fulfilled",
};

class ManageProductRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dt: Moments().format("YYYY-MM-DD"),
      startDate: Moments().format("YYYY-MM-DD"),
      endDate: Moments().format("YYYY-MM-DD"),
      orgId: 0,
      userData: {},
      progressStatus: false,
      type: "all",
      productRequests: [],
      filteredProductRequests: [],
      modal: false,
      activeProductRequest: {},
      activeProductRequestId: 0,
      modalStatus: false,
      modalEdit: false,
      modalView: false,
      modalPurchase: false,
      selectedRows: [],
      estimatedPrice: 0,

      updateStatusList: [
        "CREATED",
        "IN PROCESS",
        "AVAILABLE",
        "FULFILLED",
        "UNAVAILABLE IN MARKET",
        "DELETED",
      ],

      statusList: [
        "ALL",
        "CREATED",
        "IN PROCESS",
        "AVAILABLE",
        "FULFILLED",
        "UNAVAILABLE IN MARKET",
        "DELETED",
      ],

      currentStatusList: [
        "ALL",
        "CREATED",
        "IN PROCESS",
        "AVAILABLE",
        "FULFILLED",
        "UNAVAILABLE IN MARKET",
        "DELETED",
      ],
    };
  }

  async componentDidMount() {
    let userData = await UserStore.fetchSessionData();
    let dt = Moments();
    dt = dt.format("YYYY-MM-DD");

    this.setState({ userData });
    if (userData.type === "platformAdmin") {
      this.setState({ orgId: userData._id });
    } else if (userData.type === "ORG_ADMIN") {
      this.setState({ orgId: userData.orgDetails[0]._id });
    }
    await this.fetchData(this.state.startDate, this.state.endDate);
  }

  fetchData = async (startDate, endDate) => {
    this.setState({ progressStatus: true });
    let data = await OrderService.fetchProductRequestV3(
      this.state.orgId,
      this.state.userData.sessionToken,
      startDate,
      endDate
    );
    this.setState({ progressStatus: false });
    if (data === false) {
      toast.error("We are facing some issue. Please try again later.");
    } else {
      this.setState({ productRequests: data });
    }
    await this.filterOnStatus();
  };
  toggle = (action) => {
    this.setState({ ["modal" + action]: !this.state["modal" + action] });
  };
  onEditComplete = async () => {
    console.log(
      "insideEdit complete",
      this.state.startDate,
      this.state.endDate
    );
    this.toggle("Edit");
    await this.fetchData(
      Moments(this.state.startDate).format("YYYY-MM-DD"),
      Moments(this.state.endDate).format("YYYY-MM-DD")
    );
  };

  updateMultipleRecord = () => { };

  toggleSelect = async (row) => {
    let notFound = true;
    let selectedRows = this.state.selectedRows;
    let estimatedPrice = 0;
    for (let i = 0; i < selectedRows.length; i++) {
      if (
        row._id == selectedRows[i]._id &&
        row.productName == selectedRows[i].productName
      ) {
        selectedRows.splice(i, 1);
        await this.setState({ selectedRows });
        notFound = false;
      } else if (row.productName == selectedRows[i].productName) {
        notFound = false;
        toast.error(row.productName + " is already present.");
      }
    }
    if (notFound) {
      selectedRows = this.state.selectedRows;
      selectedRows.push(row);
      await this.setState({ selectedRows });
    }

    for (let i = 0; i < selectedRows.length; i++) {
      if (
        selectedRows[i].hasOwnProperty("purchasePriceAfterDiscount") &&
        selectedRows[i].purchasePriceAfterDiscount > 0
      ) {
        estimatedPrice =
          estimatedPrice +
          selectedRows[i].purchasePriceAfterDiscount * selectedRows[i].quantity;
      }
    }
    await this.setState({ estimatedPrice: estimatedPrice.toFixed("2") });
  };

  checkIfSelected = (row) => {
    let notFound = true;
    let selectedRows = this.state.selectedRows;
    for (let i = 0; i < selectedRows.length; i++) {
      if (row._id == selectedRows[i]._id) {
        notFound = false;
      } else if (row.productName == selectedRows[i].productName) {
        notFound = true;
      }
    }
    return notFound ? (
      <span>Select</span>
    ) : (
      <span class="green-text">
        <b>Selected</b>
      </span>
    );
  };

  goToNew = () => {
    this.props.history.push("/add-purchase-request");
  };
  prev = async () => {
    let date = Moments(this.state.dt);
    await this.fetchData(date.subtract(1, "month").format("YYYY-MM-DD"));
  };
  next = async () => {
    if (
      Moments(this.state.dt).format("DD-MM-YYYY") <
      Moments().format("DD-MM-YYYY")
    ) {
      await this.fetchData(
        Moments(this.state.dt).add(1, "month").format("YYYY-MM-DD")
      );
    }
  };

  getDateRangeCallback = async (startDate, endDate) => {
    // startDate = new Date(startDate)
    // endDate = new Date(endDate)
    if (startDate > endDate) {
      toast.info(
        "Start date is greater than the end date. Please adjust the date range again."
      );
      return;
    }
    await this.setState({ startDate, endDate });
    let endDateThreeMonthGap = Moments(startDate).add(2, "months");
    if (endDate > endDateThreeMonthGap) {
      let payload = {
        startDate: Moments(startDate).format("YYYY-MM-DD"),
        endDate: Moments(endDate).format("YYYY-MM-DD"),
        section: "ONLINE_ORDER",
      };
      //Create a record request
      this.setState({ progressStatus: true });
      let res = await ReportService.requestCreateReport(
        this.state.userData.sessionToken,
        payload
      );
      this.setState({ progressStatus: false });
      if (res) {
        toast.success(
          "A request has been raised to create a report for the specified time duration with the name, " +
          "ONLINE_ORDER_" +
          Moments(startDate).format("YYYY-MM-DD") +
          "_" +
          Moments(endDate).format("YYYY-MM-DD") +
          "_" +
          res._id +
          ".csv"
        );
      } else {
        toast.error(
          "Something went wrong while requesting the report for the duration " +
          Moments(startDate).format("YYYY-MM-DD") +
          " and " +
          Moments(endDate).format("YYYY-MM-DD")
        );
      }
      return false;
    }

    this.fetchData(
      Moments(startDate).format("YYYY-MM-DD"),
      Moments(endDate).format("YYYY-MM-DD")
    ).then((res) => {
      if (res) {
        // data fetched
      }
    });
  };

  changeStatus = async (status, statusList) => {
    if (status === "ALL") {
      if (statusList.length === 6) {
        statusList = [];
      } else {
        statusList = [
          "ALL",
          "CREATED",
          "IN PROCESS",
          "AVAILABLE",
          "FULFILLED",
          "DELETED",
        ];
      }
    } else {
      if (statusList.indexOf(status) >= 0) {
        statusList.splice(statusList.indexOf(status), 1);
      } else {
        statusList.push(status);
      }
    }

    console.log("Again==>");
    console.log(statusList);
    await this.setState({ currentStatusList: statusList });

    let productRequests = this.state.productRequests;
    let filteredProductRequests = [];
    for (let i = 0; i < productRequests.length; i++) {
      if (statusList.indexOf(productRequests[i].status) >= 0) {
        filteredProductRequests.push(productRequests[i]);
      }
    }

    await this.setState({ filteredProductRequests });
  };

  filterOnStatus = async () => {
    let statusList = this.state.statusList;
    let productRequests = this.state.productRequests;
    let filteredProductRequests = [];
    for (let i = 0; i < productRequests.length; i++) {
      if (statusList.indexOf(productRequests[i].status) >= 0) {
        filteredProductRequests.push(productRequests[i]);
      }
    }
    await this.setState({ filteredProductRequests });
  };

  updateMultipleProductRequest = async (item) => {
    await this.setState({ markStatus: item });
    this.setState({ progressStatus: true });
    let status = await OrderService.updateMultipleProductRequest(
      this.state.selectedRows,
      "status",
      item,
      this.state.userData.sessionToken
    );
    this.setState({ progressStatus: false });
    if (status === false) {
      toast.error("Something went wrong.");
    } else {
      toast.success("Purchase request updated successfully.");
      await this.fetchData(
        Moments(this.state.startDate).format("YYYY-MM-DD"),
        Moments(this.state.endDate).format("YYYY-MM-DD")
      );
    }
  };

  toggle = (action) => {
    this.setState({ ["modal" + action]: !this.state["modal" + action] });
  };

  toggle = (modalName) => {
    this.setState({ [`modal${modalName}`]: !this.state[`modal${modalName}`] });
  };

  handleDeleteRequest = () => {
    const { activeProductRequest } = this.state;

    axios
      .delete(`${config.APIHOST}${config.DELETE_PRODUCT_REQUEST}`, {
        data: { _id: activeProductRequest._id },
      })
      .then((response) => {
        if (response.status === 200) {
          this.setState({ modalCancel: false });
        } else {
          console.error("Unexpected response status:", response.status);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  render() {
    console.log(this.state.activeProductRequest);
    return (
      <React.Fragment>
        <SimpleBackdrop status={this.state.progressStatus} />
        <div className={"col-12 row mx-0 p-2"}>
          <div className={"col-md-6 mx-0"}>
            {/*<MDBBtn size={'md'} disabled={this.state.progressStatus} onClick={this.prev}>Prev</MDBBtn>*/}
            {/*{this.state.dt !== null &&*/}
            {/*<b>*/}
            {/*{Moments(this.state.dt).format("MMM, YYYY")}*/}
            {/*</b>*/}
            {/*}*/}
            {/*<MDBBtn size={'md'} disabled={this.state.progressStatus} onClick={this.next}>Next</MDBBtn>*/}

            <div className={"col-md-9 p-0"}>
              <GetDateRange getDateRangeCallback={this.getDateRangeCallback} />
            </div>
          </div>
          <div className={"col-md-6 mx-0 text-right"}>
            <CSVLink
              data={this.state.productRequests}
              headers={headers}
              filename={
                "purchase_req_list_" +
                Moments(this.state.dt).format("DD-MMM-YYYY") +
                ".csv"
              }
            >
              <MDBBtn size={"md"} disabled={this.state.isLoading}>
                Download CSV
              </MDBBtn>
            </CSVLink>
            <MDBBtn
              color="default"
              size="sm"
              onClick={this.goToNew}
              className="mr-auto"
            >
              Add Purchase Request
            </MDBBtn>
          </div>
        </div>

        <div className={"col-12 row mx-0 my-2 p-0"}>
          <div
            className={"col-12 btn-toolbar"}
            role="toolbar"
            aria-label="Toolbar with button groups"
          >
            <h5 className={"mr-4"}>
              <b>Status:</b>
            </h5>{" "}
            <div
              className="btn-group mr-2"
              role="group"
              aria-label="First group"
            >
              {this.state.statusList.map((item, index) => (
                <div className={"pr-4"}>
                  <MDBInput
                    className={"pr-4"}
                    checked={
                      item === "ALL" && this.state.currentStatusList.length == 8
                        ? true
                        : this.state.currentStatusList.indexOf(item) >= 0
                    }
                    label={
                      item === "OUTFORDELIVERY"
                        ? "OUTFORDELIVERY/SHIPPED"
                        : item === "READYFORDELIVERY"
                          ? "PACKED"
                          : item
                    }
                    type="checkbox"
                    id={"id_" + item}
                    getValue={() =>
                      this.changeStatus(item, this.state.currentStatusList)
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className={"col-12 row mx-0 p-0"}>
          <div className={"col-md-12"}>
            <div className={"m-2"}>
              {this.state.selectedRows.length > 1 && (
                <div>
                  {this.state.selectedRows.length} products selected. &nbsp;
                  <CSVLink
                    data={this.state.selectedRows}
                    headers={headersPharmarack}
                    filename={
                      "Order_" +
                      Moments(this.state.startDate).format("DD-MMM-YYYY") +
                      ".csv"
                    }
                  >
                    Click Here
                  </CSVLink>
                  &nbsp; to download the CSV file for the selected product.
                  <div>
                    Mark the selected row as
                    {this.state.updateStatusList.map((item, index) => (
                      <div className={"pr-4"}>
                        <MDBInput
                          className={"pr-4"}
                          checked={item === this.state.markStatus}
                          label={
                            item === "OUTFORDELIVERY"
                              ? "OUTFORDELIVERY/SHIPPED"
                              : item === "READYFORDELIVERY"
                                ? "PACKED"
                                : item
                          }
                          type="radio"
                          id={"id2_" + item}
                          getValue={() =>
                            this.updateMultipleProductRequest(item)
                          }
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {this.state.selectedRows.length == 1 && (
                <div>
                  {this.state.selectedRows.length} product selected. &nbsp;
                  <CSVLink
                    data={this.state.selectedRows}
                    headers={headersPharmarack}
                    filename={
                      "Order_" +
                      Moments(this.state.startDate).format("DD-MMM-YYYY") +
                      ".csv"
                    }
                  >
                    Click Here
                  </CSVLink>
                  &nbsp; to download the CSV file for the selected products.
                  <div>
                    Mark the selected rows as
                    {this.state.updateStatusList.map((item, index) => (
                      <div className={"pr-4"}>
                        <MDBInput
                          className={"pr-4"}
                          checked={item === this.state.markStatus}
                          label={
                            item === "OUTFORDELIVERY"
                              ? "OUTFORDELIVERY/SHIPPED"
                              : item === "READYFORDELIVERY"
                                ? "PACKED"
                                : item
                          }
                          type="radio"
                          id={"id2_" + item}
                          getValue={() =>
                            this.updateMultipleProductRequest(item)
                          }
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {this.state.estimatedPrice > 0 && (
                <span>
                  <b>Estimated Price: ₹{this.state.estimatedPrice}</b>
                </span>
              )}
            </div>

            <MaterialTable
              title=""
              columns={[
                {
                  title: "Select",
                  field: "",
                  render: (rowData) => (
                    <div
                      color="primary"
                      className={"blue-text btn-link cursor-pointer"}
                      size="sm"
                      onClick={() => {
                        //this.setState({activeProductRequest: rowData})
                        this.toggleSelect(rowData);
                      }}
                    >
                      {this.checkIfSelected(rowData)}
                    </div>
                  ),
                  width: "10%",
                },
                {
                  title: "Requested On",
                  field: "createdAt",
                  render: (rowData) =>
                    Moments(rowData.createdAt).format("MMM D, YYYY"),
                  width: "10%",
                },
                {
                  title: "Customer Name",
                  field: "customerDetails.name",
                  width: "10%",
                },
                {
                  title: "Customer Phone",
                  field: "customerPhone",
                  width: "10%",
                },
                { title: "Product", field: "productName", width: "20%" },
                { title: "Quantity", field: "quantity", width: "5%" },
                {
                  title: "Estimated Price",
                  field: "",
                  render: (rowData) =>
                    rowData.hasOwnProperty("purchasePriceAfterDiscount") &&
                      rowData.purchasePriceAfterDiscount > 0
                      ? "₹" +
                      (
                        rowData.quantity * rowData.purchasePriceAfterDiscount
                      ).toFixed("2")
                      : "",
                  width: "5%",
                },
                {
                  title: "Estimated Time",
                  field: "",
                  render: (rowData) =>
                    rowData.isDays
                      ? rowData.expectedDeliveryDays + " days"
                      : rowData.hasOwnProperty("expectedDeliveryHours")
                        ? rowData.expectedDeliveryHours + " hours"
                        : "NA",
                  width: "5%",
                },

                { title: "Priority", field: "priority", width: "10%" },
                { title: "Status", field: "status", width: "5%" },
                { title: "Created By", field: "createdBy", width: "5%" },
                {
                  title: "Purchase Logs",
                  field: "",
                  render: (rowData) => (
                    <div className="d-flex align-items-center">
                      <div
                        color="primary"
                        className="blue-text btn-link cursor-pointer mr-2"
                        size="sm"
                        onClick={() => {
                          this.setState({ activeProductRequest: rowData });
                          this.toggle("Purchase");
                        }}
                      >
                        View Purchase Logs
                      </div>
                    </div>
                  ),
                  width: "10%",
                },
                { title: "Comments", field: "comments", width: "10%" },
                {
                  title: "Action",
                  field: "",
                  render: (rowData) => (
                    <div className="d-flex align-items-center">
                      <div
                        color="primary"
                        className="blue-text btn-link cursor-pointer mr-2"
                        size="sm"
                        onClick={() => {
                          this.setState({ activeProductRequest: rowData });
                          this.toggle("Edit");
                        }}
                      >
                        Edit
                      </div>
                      <div
                        color="primary"
                        className="blue-text btn-link cursor-pointer mr-2"
                        size="sm"
                        onClick={() => {
                          this.setState({ activeProductRequest: rowData });
                          this.toggle("View");
                        }}
                      >
                        View
                      </div>
                      <div
                        color="primary"
                        className="blue-text btn-link cursor-pointer"
                        size="sm"
                        onClick={() => {
                          this.setState({ activeProductRequest: rowData });
                          this.toggle("Cancel");
                        }}
                      >
                        Cancel
                      </div>
                    </div>
                  ),
                  width: "10%",
                },

                {
                  title: "SMS Sent",
                  field: "",
                  render: (rowData) =>
                    rowData.hasOwnProperty("isSMSSent") &&
                      rowData.isSMSSent === "Y"
                      ? "Yes"
                      : "No",
                  width: "10%",
                },
              ]}
              data={this.state.filteredProductRequests}
              options={{
                showSelectAllCheckbox: true,
                showTitle: false,
                pageSize: 20, // make initial page size
                pageSizeOptions: [20, 30, 50, 100, 500], // rows selection options
                toolbarButtonAlignment: "left",
                padding: "dense",
              }}
            />
          </div>
        </div>
        <MDBModal
          isOpen={this.state.modalCancel}
          toggle={() => this.toggle("Cancel")}
          centered
        >
          <MDBModalHeader toggle={() => this.toggle("Cancel")}>
            Confirm Cancellation?
          </MDBModalHeader>
          <MDBModalBody>
            <p>Are you sure you want to cancel this purchase request?</p>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="primary" onClick={this.handleDeleteRequest}>
              Yes
            </MDBBtn>
            <MDBBtn color="primary" onClick={() => this.toggle("Cancel")}>
              No
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal
          isOpen={this.state.modalEdit}
          toggle={() => this.toggle("Edit")}
          size={"fluid"}
        >
          <MDBModalHeader toggle={() => this.toggle("Edit")}></MDBModalHeader>
          <MDBModalBody className={"float-center"}>
            <UpdateProductRequest
              loadedOnPopup={true}
              onEditComplete={this.onEditComplete}
              productRequestId={this.state.activeProductRequest._id}
            />
          </MDBModalBody>
        </MDBModal>
        <MDBModal
          isOpen={this.state.modalView}
          toggle={() => this.toggle("View")}
          size={"lg"}
          className="modal-dialog modal-dialog-centered"
        >
          <MDBModalHeader toggle={() => this.toggle("View")}>
            View Product Request
          </MDBModalHeader>
          <MDBModalBody className="p-4">
            <div className="container">
              <div className="row">
                <div className="col-md-6 mb-2">
                  <p>
                    <strong>Product:</strong>{" "}
                    {this.state.activeProductRequest.productName}
                  </p>
                </div>
                <div className="col-md-6 mb-2">
                  <p>
                    <strong>Quantity:</strong>{" "}
                    {this.state.activeProductRequest.quantity}
                  </p>
                </div>
                <div className="col-md-6 mb-2">
                  <p>
                    <strong>Priority:</strong>{" "}
                    {this.state.activeProductRequest.priority}
                  </p>
                </div>
                <div className="col-md-6 mb-2">
                  <p>
                    <strong>Status:</strong>{" "}
                    {this.state.activeProductRequest.status}
                  </p>
                </div>
                <div className="col-md-6 mb-2">
                  <p>
                    <strong>Customer Name:</strong>{" "}
                    {this.state.activeProductRequest.customerDetails?.name}
                  </p>
                </div>
                <div className="col-md-6 mb-2">
                  <p>
                    <strong>Customer Phone:</strong>{" "}
                    {this.state.activeProductRequest.customerPhone ||
                      "Not available"}
                  </p>
                </div>
                <div className="col-md-6 mb-2">
                  <p>
                    <strong>Processed At:</strong>{" "}
                    {Moments(this.state.activeProductRequest.createdAt).format(
                      "MMM D, YYYY"
                    )}
                  </p>
                </div>
                <div className="col-md-6 mb-2">
                  <p>
                    <strong>Created By:</strong>{" "}
                    {this.state.activeProductRequest.createdBy ||
                      "Not available"}
                  </p>
                </div>
                {(this.state.activeProductRequest.expectedDeliveryDays ||
                  this.state.activeProductRequest.expectedDeliveryHours) && (
                    <div className="col-md-6 mb-2">
                      <p>
                        <strong>Delivered By:</strong>{" "}
                        {this.state.activeProductRequest.isDays
                          ? this.state.activeProductRequest.expectedDeliveryDays +
                          " days" +
                          ` [${Moments(
                            this.state.activeProductRequest.createdAt
                          )
                            .add(
                              this.state.activeProductRequest
                                .expectedDeliveryDays,
                              "days"
                            )
                            .format("MMM D, YYYY")}]`
                          : this.state.activeProductRequest
                            .expectedDeliveryHours + " hours"}
                      </p>
                    </div>
                  )}
                <div className="col-md-12 mb-2">
                  <p>
                    <strong>Comments:</strong>{" "}
                    {this.state.activeProductRequest.comments ||
                      "No comments available"}
                  </p>
                </div>
              </div>
            </div>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="primary" onClick={() => this.toggle("View")}>
              Close
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
        <MDBModal
          isOpen={this.state.modalPurchase}
          toggle={() => this.toggle("Purchase")}
          size={"md"}
          className="modal-dialog modal-dialog-centered"
        >
          <MDBModalHeader toggle={() => this.toggle("Purchase")}>
            View Purchase Logs
          </MDBModalHeader>
          <MDBModalBody className="p-2">
            <div className="container">
              <section>
                <p>
                  {this.state.activeProductRequest?.purchaseLogs &&
                    this.state.activeProductRequest?.purchaseLogs.length > 0 ? (
                    <>
                      {this.state.activeProductRequest?.purchaseLogs?.map(
                        (item, index) => (
                          <div key={index} className="purchaseLogs">
                            <div className="my-2">
                              <p>
                                STATUS:{" "}
                                <span
                                  className={"float-right font-weight-bold"}
                                >
                                  {item?.status}{" "}
                                </span>
                                <br />
                                {purchaseLogHelper[item?.status] ??
                                  "Event occurred"}{" "}
                                At:{" "}
                                <span
                                  className={"float-right font-weight-bold"}
                                >
                                  {Moments(item?.createdAt).format(
                                    "MMM D, YYYY h:mm A"
                                  )}
                                </span>
                                <br />
                                {purchaseLogHelper[item?.status]} By:
                                <span
                                  className={"float-right font-weight-bold"}
                                >
                                  {" "}
                                  {item?.createdBy}
                                </span>{" "}
                                <br />
                                {item?.status === "AVAILABLE" &&
                                  this.state.activeProductRequest?.isSMSSent ===
                                  "Y" && (
                                    <>
                                      Notified By
                                      <span
                                        className={
                                          "float-right font-weight-bold"
                                        }
                                      >
                                        {" "}
                                        {item?.createdBy}
                                      </span>{" "}
                                      <br />
                                      Notified At
                                      <span
                                        className={
                                          "float-right font-weight-bold"
                                        }
                                      >
                                        {" "}
                                        {Moments(
                                          this.state.activeProductRequest
                                            ?.smsSentAt
                                        ).format("MMM D, YYYY h:mm A")}
                                      </span>{" "}
                                      <br />
                                    </>
                                  )}
                              </p>
                            </div>
                          </div>
                        )
                      )}
                    </>
                  ) : (
                    <div className={"no-data-container"}>No Logs found.</div>
                  )}
                </p>
              </section>
            </div>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="primary" onClick={() => this.toggle("Purchase")}>
              Close
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </React.Fragment>
    );
  }
}

export default withRouter(ManageProductRequest);
