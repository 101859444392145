import React, {Suspense} from "react";
import {Link, withRouter} from "react-router-dom";
import {
    MDBBox, MDBBreadcrumb, MDBBreadcrumbItem, MDBCol, MDBNav, MDBNavItem, MDBNavLink, MDBRow, MDBTabContent, MDBTabPane
} from "mdbreact";
import SimpleBackdrop from "../../components/common/overlay";
import AcknowledgeOrder from "../../components/online-prescription-order/acknowledgeOrder";
import FulfillPrescriptionOrder from "../../components/online-prescription-order/fulfillPrescriptionOrder";

class ProcessPrescriptionOrder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            progressStatus: false,
            orderId: (props.match.params.orderId) ? props.match.params.orderId : '',
            activeItem: this.getActiveItem(props.location.state),
            disableFulfilOrderTab: props.location.state === 'SENT TO FULFILMENT' ? false : true,
            disableShippingOrderTab: props.location.state === 'REQUEST_PAYMENT' ? false : true,
        }
    }

    toggle = tab => e => {
        if (this.state.activeItem !== tab) {
            this.setState({
                activeItem: tab
            });
        }
    };

    getActiveItem = (status) => {
        if (status === 'SENT TO FULFILMENT') {
            return '2'
        } else if (status === 'REQUEST_PAYMENT') {
            return '3'
        } else {
            return '1'
        }
    }
    render() {
        return (
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem><Link to={'/view-prescription-orders'}>View Prescription
                        Orders</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Process Prescription Order</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <SimpleBackdrop status={this.state.progressStatus}/>
                {this.state.progressStatus === false &&
                <MDBBox className={"mt-n2"}>
                    <Suspense fallback={null}>
                        <MDBRow>
                            <MDBCol md="2"
                                    className={window.innerWidth < 768 ? "min-vh-50 teal lighten-5" : "min-vh-100 teal lighten-5 pr-0"}>
                                <MDBNav pills className="flex-column mt-3">
                                    <MDBNavItem>
                                        <MDBNavLink link to="#" active={this.state.activeItem === "1"}
                                                    onClick={this.toggle("1")}
                                                    role="tab" color={"default"}>
                                            Acknowledge Order
                                        </MDBNavLink>
                                    </MDBNavItem>
                                    <MDBNavItem>
                                        <MDBNavLink link to="#" active={this.state.activeItem === "2"}
                                                    onClick={this.toggle("2")}
                                                    role="tab" color={"default"} disabled={this.state.disableFulfilOrderTab}>
                                            Fulfill Order
                                        </MDBNavLink>
                                    </MDBNavItem>
                                    <MDBNavItem>
                                        <MDBNavLink link to="#" active={this.state.activeItem === "3"}
                                                    onClick={this.toggle("3")}
                                                    role="tab" color={"default"} disabled={this.state.disableShippingOrderTab}>
                                            Shipping Order
                                        </MDBNavLink>
                                    </MDBNavItem>
                                </MDBNav>
                            </MDBCol>
                            <MDBCol md="10" className={"mt-n4"}>
                                <MDBTabContent activeItem={this.state.activeItem}>
                                    <MDBTabPane tabId="1">
                                        <AcknowledgeOrder/>
                                    </MDBTabPane>
                                    <MDBTabPane tabId="2">
                                        <FulfillPrescriptionOrder/>
                                    </MDBTabPane>
                                    <MDBTabPane tabId="3">
                                        <span>Shipping Prescription Order Process</span>
                                    </MDBTabPane>
                                </MDBTabContent>
                            </MDBCol>
                        </MDBRow>
                    </Suspense>
                </MDBBox>
                }
            </React.Fragment>
        )
    }
}

export default withRouter(ProcessPrescriptionOrder);