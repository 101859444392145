import React from "react";
import {Link,withRouter} from "react-router-dom";
import {
    MDBContainer, MDBRow, MDBInput, MDBFormInline, MDBBtn, MDBCol,
    MDBCard, MDBCardBody, MDBCardHeader, MDBBreadcrumb, MDBBreadcrumbItem, MDBChip
} from 'mdbreact';
import UserStore from "../../stores/userStore";
import {toast} from "react-toastify";
import SimpleBackdrop from "../common/overlay";
import SpecialityService from "../../services/specialityService";

class AddSpecialityGroup extends React.Component {
    validateMasterSpeciality=()=>{
        if(this.state.masterSpecialityGroup.trim()===''){
            this.setState({masterSpecialityError:true});
        }else{
            this.setState({masterSpecialityError:false});
        }
    }
    handleChange=(val)=>{
        this.setState({masterSpecialityGroup:val});
    }
    addMasterSpeciality=async ()=>{
        //validations
        if(this.state.masterSpecialityGroup.trim()===''){
            this.setState({masterSpecialityError:true});
            return false;
        }
        let userData=await UserStore.fetchSessionData();
        this.setState({progressStatus:true});
        let data=await SpecialityService.addSpecialityGroup(this.state.masterSpecialityGroup.trim(),userData);
        if(data===false){
            this.setState({progressStatus:false});
            toast.error("Speciality group already exists.");
        }else{
            this.setState({progressStatus:false,masterSpecialityGroup:'',masterSpecialityError:false});
            toast.success("Speciality Group added successfully.");
        }
    }
    constructor(props) {
        super(props);
        this.state = {
            masterSpecialityGroup:'',
            masterSpecialityError:false,
            progressStatus:false,
            suggestedList:[]
        }
    }
    fetchSuggestion=async()=>{
        try{
            if(this.state.masterSpecialityGroup!=='') {
                let userData=await UserStore.fetchSessionData();
                let data = await SpecialityService.lookupSpecialityGroup(this.state.masterSpecialityGroup,userData);
                if(data!==false){
                    this.setState({suggestedList:data});
                }
            }
        }catch (e){
            this.setState({suggestedList:[]});
        }
    }
    navigate=(item)=>{
        this.props.history.push('/edit-speciality/'+item._id);
    }
    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.progressStatus}/>
                {!this.state.isSubComponent &&
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem><Link to={'/view-specialties'}>Speciality</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Add Speciality</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                }
                <MDBContainer>
                    <MDBCard className={"mt-2"}>
                        <MDBCardHeader>
                            <div className={"float-left title"}>Add Speciality</div>
                        </MDBCardHeader>
                        <MDBCardBody>
                            <MDBRow className="d-flex justify-content-center">
                                <MDBCol>
                                    <MDBInput onChange={this.fetchSuggestion} label="Speciality" onBlur={this.validateMasterSpeciality} className={this.state.masterSpecialityError ? "border-danger" :""} getValue={this.handleChange} value={this.state.masterSpecialityGroup}/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                {this.state.suggestedList.map((item, idx) => (
                                    <span key={idx} onClick={()=>{this.navigate(item)}}><MDBChip bgColor="light-blue lighten-4" key={idx} waves>{item.specialityGroup}</MDBChip></span>
                                ))}
                            </MDBRow>
                            <div className="text-center">
                                {!this.state.isSubComponent &&
                                <MDBBtn outline size={'md'}
                                        onClick={() => this.props.history.push('/view-specialties')} className="mr-auto"
                                        disabled={this.state.progressStatus}>
                                    Back
                                </MDBBtn>
                                }
                                <MDBBtn size={'md'} onClick={this.addMasterSpeciality} className="mr-auto"
                                        disabled={this.state.progressStatus || this.state.masterSpecialityError}>
                                    Save
                                </MDBBtn>
                            </div>
                        </MDBCardBody>
                    </MDBCard>
                </MDBContainer>
            </React.Fragment>
        );
    }
}
export default withRouter(AddSpecialityGroup);
