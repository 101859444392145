import React,{Suspense} from "react";
import {Link} from "react-router-dom";
import {
    MDBBreadcrumbItem, MDBBreadcrumb, MDBBox
} from 'mdbreact';
//const ChangePasswordCmp=React.lazy(() => import("../../components/users/changePassword"));
import ChangePasswordCmp from "../../components/users/changePassword";

export default class ChangePassword extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Change Password</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <MDBBox className={"min-vh-100"}>
                    <Suspense fallback={null}>
                        <ChangePasswordCmp/>
                    </Suspense>
                </MDBBox>
            </React.Fragment>
        );
    }
}
