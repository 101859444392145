import React from "react";
import {Link, withRouter} from "react-router-dom";
import {
    MDBContainer, MDBRow, MDBInput, MDBBtn, MDBCol, MDBCard, MDBCardBody, MDBCardHeader, MDBBreadcrumb,
    MDBBreadcrumbItem, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBFormInline
} from 'mdbreact';
import UserStore from "../../stores/userStore";
import {toast} from "react-toastify";
import util from "../../utility/utility";
import AuthService from "../../services/authService";
import SimpleBackdrop from "../common/overlay";
import orgConfig from "../../orgConfig";

class UpdateOrgUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orgUser:"",
            description:"",
            isActive:"",
            orgId:"",
            orgUserId:"",
            imageUrl:{},
            orgUserObj:{},
            orgUserApiResultCame:false,
            progressStatus:false,
            progressStatusFileUpload:false,
            orgUserError:"",
            switch1:false,
            imageActionNeeded:"Init",
            lightBoxImages: [],
            address: "",
            street: "",
            pincode:"",
            area: "",
            city: "",
            state: "",
            country: "",
            landmark: "",
            fileUploadingInProgress:false,
            modalDelete:false,
            permissions:["ALL"],
            type:"ORG_ADMIN",
            nameError:false,
            userName:'',
            userNameError:false,
        }
    }
    componentDidMount() {
        const { userId } = this.props.queryString;
        const { orgId } = this.props.queryString;
        this.setState({orgUserId:userId, orgId:orgId})
        this.fetchOrgUserById()
    }
    fetchOrgUserById = async () => {
        let userData=await UserStore.fetchSessionData();
        this.setState({progressStatus:true});
        let res = await AuthService.fetchUserById(this.state, userData);
        this.setState({orgUserApiResultCame:true})
        this.setState({progressStatus:false});
        console.log(res);
        if(res){
            try{
                this.setState({"name": res.name, "userName": res.userName, "email": res.email,"contactNo": res.phone,  "isActive": res.isActive})
            } catch (e) {
                toast.error("Something went wrong while fetching user.")
            }
        }
    }
    handleChangeContactNo=(e)=>{
        if(!util.isInt(e.target.value) && e.target.value !== ""){
            return false;
        }
        this.setState({contactNo:e.target.value})
    };
    handleChangeEmail=(e)=>{
        this.setState({email:e.target.value})
    };
    handleName=(e)=>{
        this.setState({name:e.target.value})
    };
    handleChangeUsername=(event)=>{
        this.setState({
            userName: event.target.value
        })
    }
    validateEmail = () => {
        if(this.state.email && !util.validateEmail(this.state.email)){
            this.setState({"loadingEmail": true, "emailError": true})
        } else {
            this.setState({"loadingEmail": false, "emailError": false})
        }
    }
    validatePhone= () => {
        if(!util.checkPhone(this.state.contactNo)){
            this.setState({ "contactNoError": false})
        } else {
            this.setState({"contactNoError": false})
        }
    }
    updateUser =async () => {
        if(this.state.name===''){
            toast.error("Please enter a valid name.")
            this.setState({nameError: true})
            return true
        }else{
            this.setState({nameError: false})
        }
        if(this.state.userName===''){
            toast.error("Please enter a valid user name.")
            this.setState({userNameError: true})
            return true
        }else{
            this.setState({userNameError: false})
        }
        if(this.state.contactNo ===''){
            toast.error("Please enter a valid phone number.")
            this.setState({contactNoError: true})
            return true
        }else{
            this.setState({contactNoError: false})
        }
        let userData=await UserStore.fetchSessionData();
        // Reset all the error first
        this.setState({"orgUserError": false})
        //validations
        if(this.state.orgUserError){
            return false;
        }
        this.setState({progressStatus:true});
        let status=await AuthService.updateUser(this.state,userData);
        this.setState({progressStatus:false});
        if(status===false){
            toast.error("Something went wrong.");
            return true;
        }else{
            toast.success("User updated successfully.");
            this.fetchOrgUserById()
        }
    }
    initDelete = () => {
        this.toggleDelete()
    }
    confirmDelete = async () => {
        let userData=await UserStore.fetchSessionData();
        this.setState({progressStatus:true});
        let status=await AuthService.deleteOrgUser(this.state,userData);
        this.setState({progressStatus:false});
        if(status===false){
            toast.error("Something went wrong.");
            return true;
        }else{
            toast.success("User deleted successfully.");
            this.toggleDelete();
            this.props.history.push("/view-pharmacy-users/"+this.state.orgId);
        }
    }
    toggleDelete = () => {
        this.setState({
            modalDelete: !this.state.modalDelete
        });
    }
    validateName = () => {
        if(!this.state.name || this.state.name.trim() === ""){
            this.setState({"nameError": true})
        } else {
            this.setState({"nameError": false})
        }
    }
    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.progressStatus}/>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem ><Link to={'/view-pharmacy-users/'+this.state.orgId}>Pharmacy Users</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Edit User</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <MDBContainer>
                    <MDBCard className={"mt-2"}>
                        <MDBCardHeader>
                            <span className={"float-left title"}>Edit User</span>
                        </MDBCardHeader>
                        <MDBCardBody>
                            <MDBRow>
                                <MDBCol>
                                    <MDBInput autoComplete={'off'} style={{"marginTop": "0px"}} onBlur={this.validateName}
                                              className={this.state.nameError ? "border-danger" : "mt-0 mb-0"}
                                              maxLength="100" type='text' label="Name *"
                                              onChange={this.handleName}
                                              value={this.state.name}/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBInput id={"username_se"} autoComplete={'off'} className={this.state.userNameError ? "border-danger" :""} maxLength="50"  type='text' label="Username *" onChange={this.handleChangeUsername} value={this.state.userName}/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBInput onBlur={() => this.validateEmail()}
                                              autoComplete={'off'}
                                              className={this.state.emailError ? "border-danger" : ""}
                                              maxLength="50" type='text' label="E-mail"
                                              onChange={this.handleChangeEmail}
                                              value={this.state.email}/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBInput onBlur={() => this.validatePhone()}
                                              autoComplete={'off'}
                                              className={this.state.contactNoError ? "border-danger" : ""}
                                              maxLength={orgConfig.PHONE_NUMBER_LENGTH} type='text' label="Phone *"
                                              onChange={this.handleChangeContactNo}
                                              value={this.state.contactNo}/>
                                </MDBCol>
                            </MDBRow>
                            <div className="text-center col-12 p-2">
                                <MDBBtn outline  size="md" onClick={() => this.props.history.goBack()} className="mr-auto" disabled={this.state.progressStatus}>
                                    Back
                                </MDBBtn>
                                <MDBBtn   size="md" onClick={this.updateUser} className="mr-auto"
                                          disabled={this.state.progressStatus}>
                                    Save
                                </MDBBtn>
                                <MDBBtn outline size="md" onClick={this.initDelete}
                                        disabled={this.state.progressStatus}
                                >
                                    Delete
                                </MDBBtn>
                            </div>
                        </MDBCardBody>
                    </MDBCard>
                    <MDBModal isOpen={this.state.modalDelete} toggle={this.toggleDelete}>
                        <MDBModalHeader toggle={this.toggleDelete}>Delete User</MDBModalHeader>
                        <MDBModalBody>
                            Are you sure you want to delete this user?
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn disabled={this.state.progressStatus} color="primary" onClick={this.confirmDelete}>Delete</MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>
                </MDBContainer>
            </React.Fragment>
        );
    }
}
export default withRouter(UpdateOrgUser)