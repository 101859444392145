import React from "react";
import { withRouter } from "react-router-dom";
import {
  MDBBtn,
  MDBBox,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
} from "mdbreact";
import { toast } from "react-toastify";
import UserStore from "../../../stores/userStore";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Moments from "moment";
import GetDateRange from "../../common/getDateRange";
import SimpleBackdrop from "../../common/overlay";
import TransferStockService from "../../../services/transferStockService";
import OrderService from "../../../services/orderService";
import EditPackage from "./transferStatus";
import TransferStockTable from "./transferStockTable";
import Barcode from "react-barcode";
import GetDateRangeFunc from "../../common/getDateRangeFunc";

class TrackStockList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: {},
      startDate: new Date(),
      endDate: new Date(),
      modal: false,
      modalReview: false,
      isLoading: false,
      stockData: [],
      pageSize: 10,
      currentPage: 1,
      totalRecords: 0,
      packageData: [],
      packageItems: [],
      rowData: [],
      searchItem: "",
      statusChange: "",
      barCodeChange: "",
      isReviewOpen: false,
      stockReviewComment: "",
      deliveryPersonal:[],
      isCommentError: false,
      isModalOpen: false,
    };
  }

  async componentDidMount() {
    this.fetchTable();
    this.deliveryDetailsPersons()
  }

  fetchTable = async (currentPage = 1) => {
    try {
      this.setState({ isLoading: true });
      let userData = await UserStore.fetchSessionData();
      this.setState({ userData });
      console.log(this.state.currentPage);
      const startD = Moments(this.state.startDate).format("YYYY-MM-DD");
      const endD = Moments(this.state.endDate).format("YYYY-MM-DD");
      let transferList = await TransferStockService.trasferProductList(
        userData.sessionToken,
        startD,
        endD,
        this.state.pageSize,
        currentPage
      );
      if (transferList) {
        //sorting fetched data based on createdAt property from api in descending order
        const sortedData = transferList.data.sort((a, b) =>
          Moments(a.createdAt).isBefore(b.createdAt) ? -1 : 1
        );
        this.setState({
          packageData: sortedData,
          searchItem: "",
          isLoading: false,
          currentPage: transferList.currentPage,
          totalRecords: transferList.totalRecords,
        });
      } else {
        this.setState({
          isLoading: false,
          packageData: [],
        });
      }
    } catch (err) {
      this.setState({
        isLoading: false,
      });
    }
  };

  onReviewClick = (clickedOption, rowData) => {
    console.log({ rowData });
    this.setState({
      packageItems: rowData?.products,
      currentReviewRowId: rowData?.id,
      modalReview: clickedOption === "ReviewSub",
      barCodeChange: rowData?.barCode,
      isReviewOpen: clickedOption === "ReviewSub",
    });
    const operations = {
      review: {
        modalReview: true,
        statusChange: rowData?.status,
        barCodeChange: rowData?.barCode,
        isReviewOpen: false,
      },
      edit: {
        modalReview: true,
        statusChange: rowData?.status,
        barCodeChange: rowData?.barCode,
      },
      reject: { modalReview: false },
      ReviewSub: {
        modalReview: true,
        statusChange: rowData?.status,
        barCodeChange: rowData?.barCode,
        isReviewOpen: true,
      },
      close: () => {
        this.fetchTable();
        return { modalReview: false };
      },
    };
    this.setState(operations[clickedOption]);
  };

  handleChangeText = async (event) => {
    if (event.target.value.length == 0) {
      this.fetchTable();
    }
    this.setState({
      searchItem: event.target.value,
    });
  };
  handleSearch = async () => {
    try {
      this.setState({ isLoading: true });
      const searchValue =
        await TransferStockService.searchPackageByBarCodeProducts(
          this.state.userData.sessionToken,
          this.state.searchItem
        );
      if (searchValue) {
        this.setState({
          packageData: [searchValue],
          isLoading: false,
        });
      } else {
        this.setState({
          packageData: [],
          isLoading: false,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  getDateRangeCallback = async (startDate, endDate, currentPage = 1) => {
    console.log("current page:", this.state.currentPage);
    if (startDate > endDate) {
      toast.info(
        "Start date is greater than the end date. Please adjust the date range again."
      );
      return;
    }
    const isSingleDay = Moments(startDate).isSame(endDate, "day");

    if (isSingleDay) {
      this.fetchTable(currentPage);
      this.setState({ startDate, endDate });
    } else {
      const startD = Moments(startDate).format("YYYY-MM-DD");
      const endD = Moments(endDate).format("YYYY-MM-DD");
      this.setState({
        isLoading: true,
      });
      let transferList = await TransferStockService.trasferProductList(
        this.state.userData.sessionToken,
        startD,
        endD,
        this.state.pageSize,
        currentPage
      );
      if (transferList) {
        //sorting fetched data based on createdAt property from api in descending order
        const sortedData = transferList.data.sort((a, b) =>
          Moments(a.createdAt).isBefore(b.createdAt) ? 1 : -1
        );
        this.setState({
          packageData: sortedData,
          searchItem: "",
          isLoading: false,
          totalRecords: transferList.totalRecords,
          currentPage: transferList.currentPage,
        });
        this.setState({ startDate, endDate });
      } else {
        this.setState({
          isLoading: false,
          packageData: [],
        });
      }
    }
  };

  handleChangePagePar = async (newPage) => {
    this.getDateRangeCallback(
      this.state.startDate,
      this.state.endDate,
      newPage
    );
  };

  deleteStock = async (barcode) => {
    const deleteProduct = await TransferStockService.deleteProduct(
      this.state.userData.sessionToken,
      {"barCode":barcode}
    );
    console.log({deleteProduct})
    if (deleteProduct) {
      this.fetchTable();
      toast.success("Sucessfully deleted the record");
    }
    else{
      toast.error("Couldn't delete the record")
    }
  }

  deliveryDetailsPersons = async () =>{
    let userData = await UserStore.fetchSessionData();
    const data = await OrderService.fetchDeliveryPersonDetails(userData.sessionToken);
    this.setState({
      deliveryPersonal:data
    })
    console.log("delivery",data)
  }

  handleReviewSubmit = async () => {
    if (!this.state.stockReviewComment.trim()) {
      this.setState({ isCommentError: true });
      // toast.error("Enter valid comments");
      return;
    }

    this.setState({ isLoading: true });
    const payload = {
      barCode: this.state.barCodeChange,
      isReviewed: true,
      comment: this.state.stockReviewComment,
    };

    let reviewStock = await TransferStockService.reviewStockList(
      payload,
      this.state.userData.sessionToken
    );

    if (reviewStock?.code) {
      const updatedRows = this.state.rowData.map((row) => {
        if (row.id === this.state.currentReviewRowId) {
          return { ...row, isReviewed: true };
        }
        return row;
      });

      this.setState({
        rowData: updatedRows,
        isLoading: false,
        isModalOpen: false,
        stockReviewComment: "",
        modalReview: false,
        isCommentError: false,
      });
      toast.success("Successfully reviewed");
    } else {
      this.setState({ isLoading: false });
      toast.error("Review failed");
    }
  };

  handleCommentChange = (event) => {
    this.setState({
      stockReviewComment: event.target.value,
      isCommentError: !event.target.value.trim(),
    });
  };
  render() {
    const trackStockColumn = [
      {
        title: "Barcode",
        field: "barCode",
        width: "14%",
        render: (rowData) => (
          <MDBBox
            className="d-flex justify-content-left align-items-center"
            style={{ width: "12em", height: "8em" }}
          >
            <Barcode value={rowData?.barCode}></Barcode>
          </MDBBox>
        ),
      },
      {
        title: "Date",
        field: "createdAt",
        width: "14%",
        render: (rowData) => (
          <MDBBox className="d-flex justify-content-left align-items-center">
            {Moments(rowData.createdAt).format("MMM D, YYYY")}
          </MDBBox>
        ),
      },
      { title: "From", field: "fromLocationName", width: "14%" },
      { title: "To", field: "toLocationName", width: "14%" },
      {
        title: "Status",
        field: "status",
        width: "14%",
        render: (rowData) => (
          <div>
            {rowData?.status == "IN_TRANSIT" ? "IN TRANSIT" : rowData?.status}
          </div>
        ),
      },
      {
        title: "View Package",
        field: "status",
        width: "14%",
        render: (rowData) => (
          <MDBBox className="d-flex justify-content-left align-items-center">
            <MDBBtn
              size={"sm"}
              onClick={() => {
                this.onReviewClick("review", rowData);
              }}
            >
              <i className="far fa-eye"></i>&nbsp;View
            </MDBBtn>
          </MDBBox>
        ),
      },
      {
        title: "Review",
        field: "review",
        width: "10%",
        headerStyle: { textAlign: "center" },
        //
        render: (rowData) => (
          <MDBBox className="d-flex justify-content-center align-items-center">
            <MDBBtn
              size={"sm"}
              onClick={() => {
                this.onReviewClick("ReviewSub", rowData);
              }}
            >
              <i className="far fa-check-circle"></i>&nbsp;
              {rowData.isReviewed ? "Reviewed" : "Review"}
            </MDBBtn>
          </MDBBox>
        ),
      },
      {
        title: "Action",
        field: "action",
        width: "16%",
        headerStyle: { textAlign: "center" },
        render: (rowData) => (
          <MDBBox className="d-flex justify-content-center align-items-center">
            <MDBBtn size={"sm"} disabled={this.state.isLoading} onClick={() => this.deleteStock(rowData?.barCode)}>
              <i className="fas fa-trash"></i>&nbsp;Delete
            </MDBBtn>
          </MDBBox>
        ),
      },
    ];
    return (
      <React.Fragment>
        <SimpleBackdrop status={this.state.isLoading} />
        <div className={"col-12 row mx-0"}>
          <div className={"col-md-9 p-0"}>
            {/* <GetDateRange getDateRangeCallback={this.getDateRangeCallback} /> */}
            <GetDateRangeFunc
              getDateRangeCallback={this.getDateRangeCallback}
            />
          </div>
          <div className={"col-md-3 text-right p-0"}>
            <MDBBtn
              size={"md"}
              disabled={this.state.isLoading}
              onClick={() => {
                this.props.history.push("/transfer-stock");
              }}
            >
              Add
            </MDBBtn>
            <MDBBtn
              size={"md"}
              disabled={this.state.isLoading}
              onClick={() => {
                this.props.history.push("/receiveStockPage");
              }}
            >
              Received Stock
            </MDBBtn>
          </div>
        </div>
        {this.state.userData.hasOwnProperty("sessionToken") && (
          <div>
            <div
              className="d-flex justify-content-end align-items-center"
              style={{ padding: ".5em" }}
            >
              <MDBInput
                type="text"
                placeholder="Search by bar code"
                value={this.state.searchItem}
                onChange={this.handleChangeText}
              />
              <MDBBtn size={"lg"} onClick={this.handleSearch}>
                Search
              </MDBBtn>
            </div>
            <TransferStockTable
              packageTableData={this.state.packageData}
              columnData={trackStockColumn}
              pageSize={this.state.pageSize}
              currentPage={this.state.currentPage}
              totalRecords={this.state.totalRecords}
              handlePageChangePar={this.handleChangePagePar}
            />
          </div>
        )}
        {/* ||||||||||| REVIEW MODAL OPENER ||||||||||| */}
        <MDBModal
          size={"lg"}
          isOpen={this.state.modalReview}
          toggle={() => {
            this.onReviewClick("close");
          }}
        >
          <MDBModalHeader
            toggle={() => {
              this.onReviewClick("close");
            }}
          >
            Package View
          </MDBModalHeader>
          <MDBModalBody>
            {!this.state.isReviewOpen && (
              <div
                className="d-block"
                style={{
                  margin: "1.5em 0",
                  padding: "1em",
                  boxShadow: "2px 2px 5px 1px #c5c5c5",
                  borderRadius: "5px",
                  position: "relative",
                }}
              >
                <EditPackage
                  payload={{
                    status: this.state.statusChange,
                    barCode: this.state.barCodeChange,
                  }}
                  handleClose={() => this.onReviewClick("close")}
                  sessionToken={this.state.userData.sessionToken}
                  deliveryPersonal={this.state.deliveryPersonal}
                />
              </div>
            )}
            <MDBBox>
              {!this.state.isReviewOpen &&
                this.state.packageItems?.map((item, key) => (
                  <div
                    className="d-block"
                    style={{
                      margin: "1.5em 0",
                      padding: "1em",
                      boxShadow: "2px 2px 5px 1px #c5c5c5",
                      borderRadius: "5px",
                      position: "relative",
                    }}
                  >
                    <div
                      className="fs-2"
                      style={{
                        position: "absolute",
                        padding: ".3em 1em",
                        width: "8em",
                        background: "#232222",
                        color: "white",
                        top: "-8%",
                        borderRadius: "4px",
                      }}
                    >
                      Product - {key + 1}{" "}
                    </div>{" "}
                    <br />
                    <span className="fs-4">
                      Name : {item.productName}{" "}
                    </span>{" "}
                    <br />
                    <span className="fs-4">
                      Status :{" "}
                      {item.status == "IN_TRANSIT" ? "IN TRANSIT" : item.status}{" "}
                    </span>{" "}
                    <br />
                    <span className="fs-4">
                      Quantity : {item.quantity}{" "}
                    </span>{" "}
                    <br />
                    <span className="fs-4">
                      Reviewed :{" "}
                      {item.isReviewed ? (
                        <i
                          className="fas fa-check"
                          style={{ color: "green" }}
                        ></i>
                      ) : (
                        <i
                          className="fas fa-times"
                          style={{ color: "red" }}
                        ></i>
                      )}
                    </span>{" "}
                    <br />
                    <span className="fs-4">
                      From : {item.fromLocationName}
                    </span>{" "}
                    <br />
                    <span className="fs-4">
                      To : {item.toLocationName}
                    </span>{" "}
                    <br />
                  </div>
                ))}
              {this.state.isReviewOpen && (
                <div>
                  <MDBBox>Check if everything is correct?</MDBBox>
                  <br />
                  <MDBInput
                    value={this.state.stockReviewComment}
                    type={"textarea"}
                    label={
                      <>
                        Review comment if any{" "}
                        <span style={{ color: "red" }}>*</span>
                      </>
                    }
                    onChange={this.handleCommentChange}
                    getValue={(e) => {
                      this.setState({ stockReviewComment: e });
                    }}
                  >
                    {this.state.isCommentError && (
                      <div style={{ color: "red", marginLeft: "10px" }}>
                        Please enter some comments
                      </div>
                    )}
                  </MDBInput>
                  <MDBBtn size="lg" onClick={this.handleReviewSubmit}>
                    SUBMIT
                  </MDBBtn>
                </div>
              )}
            </MDBBox>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              onClick={() => {
                this.onReviewClick("close");
              }}
            >
              Close
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </React.Fragment>
    );
  }
}

export default withRouter(TrackStockList);
