import React, {Component} from 'react';
import UserStore from "../../../stores/userStore";
import ReportService from "../../../services/reports";
import {toast} from "react-toastify";
import Moments from "moment";
import config from "../../../config";
import SimpleBackdrop from "../../common/overlay";
import MaterialTable from "material-table";
import {MDBBtn, MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader} from "mdbreact";

class RequestReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: {},
            requestReportList: [],
            progressStatus: false,
            activeItem: null,
            modalDelete: false
        }
    }

    async componentDidMount() {
        let userData = await UserStore.fetchSessionData();
        this.setState({userData})
        await this.fetchReportList()
    }

    fetchReportList = async () => {
        this.setState({progressStatus: true})
        let res = await ReportService.fetchRequestReportList(this.state.userData.sessionToken)
        if (res) {
            this.setState({requestReportList: res})
        } else {
            toast.error("Something went wrong while fetching the report request list.")
        }
        this.setState({progressStatus: false})
    }
    downloadFile = (item) => {
        if (item.filePath !== "") {
            return <a href={config.UPLOADED_DOC_HOST + item.filePath}>Download</a>
        }
        return ""
    }
    delete = async () => {
        this.setState({progressStatus: true})
        let res = await ReportService.deleteReportRequest(this.state.userData.sessionToken, this.state.activeItem._id)
        if (res) {
            this.toggle("Delete")
            toast.success("Report deleted successfully.")
            await this.fetchReportList()
        } else {
            toast.error("Something went wrong while fetching the report request list.")
        }
        this.setState({progressStatus: false})
    }
    toggle = (section) => {
        this.setState({["modal" + section]: !this.state["modal" + section]})
    }
    deleteReport = (item) => {
        this.setState({activeItem: item})
        this.toggle("Delete")
    }

    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.progressStatus}/>
                <div className={"col-12 row p-0 m-0"}>
                    <div className={"col-12"}>
                        <MaterialTable title=""
                                       columns={[
                                           {
                                               title: "CreatedAt",
                                               field: "createdAt",
                                               render: rowData => Moments.utc(rowData.createdAt).local().format("lll"),
                                               width: "10%"
                                           },
                                           {
                                               title: "CreatedBy",
                                               field: "createdBy",
                                               render: rowData => <span
                                                   className={"text-capitalized"}>{rowData.createdBy.name}</span>,
                                               width: "10%"
                                           },
                                           {title: "Section", field: "section", width: "10%"},
                                           {
                                               title: "StartDate",
                                               field: "startDate",
                                               render: rowData => Moments(rowData.startDate).format("YYYY-MMM-DD"),
                                               width: "10%"
                                           },
                                           {
                                               title: "EndDate",
                                               field: "endDate",
                                               render: rowData => Moments(rowData.endDate).format("YYYY-MMM-DD"),
                                               width: "10%"
                                           },
                                           {title: "FileName", field: "fileName", width: "30%"},
                                           {
                                               title: "Status",
                                               field: "status",
                                               render: rowData => rowData.status === "COMPLETED" ? <span
                                                   className={"text-success"}>{rowData.status}</span> : "CREATED",
                                               width: "10%"
                                           },
                                           {
                                               title: "",
                                               field: "filePath",
                                               render: rowData => this.downloadFile(rowData),
                                               width: "10%"
                                           },
                                           {
                                               title: "",
                                               field: "",
                                               render: rowData => <span className={"text-primary"}
                                                   onClick={() => this.deleteReport(rowData)}>Delete</span>,
                                               width: "10%"
                                           },
                                       ]}
                                       data={this.state.requestReportList}
                                       options={{
                                           pageSize: 10,
                                           pageSizeOptions: [10, 50, 100],
                                       }}
                        />
                    </div>
                </div>
                <MDBModal isOpen={this.state.modalDelete} toggle={() => this.toggle("Delete")} size={"lg"}>
                    <MDBModalHeader toggle={() => this.toggle("Delete")}></MDBModalHeader>
                    <MDBModalBody className={"float-center"}>
                        {this.state.activeItem !== null &&
                        <div style={{"textAlign": "center"}}>
                            Do you want to delete the report request <br/><b>{this.state.activeItem.fileName}</b>?
                        </div>
                        }
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn outline color="secondary" onClick={() => this.toggle("Delete")}>Cancel</MDBBtn>
                        <MDBBtn onClick={this.delete}>Delete</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
            </React.Fragment>
        );
    }
}

export default RequestReport;