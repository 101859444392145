import React, { useEffect, useRef, useState } from "react";
import {
    MDBContainer,
    MDBRow,
    MDBInput,
    MDBBtn,
    MDBCardHeader,
    MDBFormInline,
    MDBCardBody,
    MDBCard,
    MDBCol,
    MDBModal,
    MDBModalHeader,
    MDBModalBody,
    MDBModalFooter,
    MDBIcon,
    MDBSelectInput,
    MDBSelectOptions,
    MDBSelectOption,
    MDBSelect
} from "mdbreact";
import SimpleBackdrop from "../common/overlay";
import InventoryService from "../../services/inventoryService";
import config from "../../config";
import styles from './index.module.css'
import AutocompleteMeds from "../../newComponents/AutocompleteMeds";
import TransferStockService from "../../services/transferStockService";
import AutocompleteUser from "../../newComponents/AutocompleteUser";
import AuthService from "../../services/authService";
import { toast } from "react-toastify";
import OrderService from "../../services/orderService";
 
 
const ViewProductsRequsetModal = ({ isProductOpen = false, toggleModal, userData, product=null, customerDetails=null, fetchData = ()=> {} }) => {
    const numberArray = [15, 30, 45, 60, 90];
    const [open, setOpen] = useState(isProductOpen);
    const [loading, setLoading] = useState(false);
    const [selectedProductDetails, setSelectedProductDetails] = useState({});
    const [productData, setProductdata] = useState([])
    const [customerList, setCustomerList] = useState([])
    const [frequency, setFrequency] = useState([])
 
    const [selectedCustomerInfo, setSelectedCustomerInfo] = useState({})
    const [selectedFreq, setSelectedFreq] = useState(null)
    const [productIInfo, setProductInfo] = useState(null);
    const [quantity, setQuantity] = useState(0)
 
    const fetchProductData = async (productId) => {
        try {
            setLoading(true);
            const res = await InventoryService.fetchProductById(
                userData,
                productId
            );
            if (res) {
                setLoading(false);
                setProductInfo(res);
            } else {
                setLoading(false);
                setProductInfo([]);
            }
        } catch (err) {
            setLoading(false);
            console.log(err)
        }
    };
 
    const handleSLookupSearch = async (name) => {
        try {
            const res = await TransferStockService.searchProductListwithIdV2(name)
            let temp = [];
            for (let item of res?.data) {
                let str = { title: item.productName, value: item };
                temp.push(str);
            }
            setProductdata(temp);
        }
        catch (err) {
            console.log({ err });
        }
    }
    const handleClose = () => {
        setProductdata([]);
        setProductInfo(null);
        setSelectedCustomerInfo({});
        setSelectedFreq(null);
        toggleModal(isProductOpen);
    }
 
    const handleCustomerSearch = async (value) => {
        let resUser = await AuthService.suggestCustomerNew(value, userData.sessionToken);
        if (resUser?.length) {
            setCustomerList(resUser)
        }
        else {
            setCustomerList([])
        }
    }
    const handleSelectProduct = (value, item) => {
        if (item) {
            setSelectedProductDetails(item)
            fetchProductData(item?.value?._id)
        }
        else {
            setSelectedProductDetails({})
            setProductInfo(null)
        }
    }
    const handleSelectCustomer = (val, item) => {
        if (item) {
            setSelectedCustomerInfo(item)
        }
        else {
            setSelectedCustomerInfo({})
        }
    }
 
    const handleSelectChangeFreq = (value) => {
        setSelectedFreq(value[0]);
    };
 
    const handleSave = async () => {
        const {_id } = customerDetails ? customerDetails : selectedCustomerInfo;
        if(!_id){
            return toast.error("Please select a customer!")
        }
        else if (!selectedCustomerInfo?.contactNo && !selectedCustomerInfo?.phone) {
            return toast.error("The customer doesnt have phone number registered")
        } else if (!quantity) {
            return toast.error("Please enter quantity");
        } else if (!selectedFreq) {
            return toast.error("Please enter frequency")
        } else if (!productIInfo?._id) {
            return toast.error("Please select a product")
        }
        const payload = {
            productId: productIInfo?._id,
            frequency: selectedFreq,
            userId: _id,
            quantity: quantity
        }
        setLoading(true)
        let res = await OrderService.createSubscription(
            userData.sessionToken,
            payload
          );
        if(res){
            toast.success("Product Request Successfully saved")
            fetchData()
            setLoading(false)
            setOpen(false)
            setProductInfo([])
            setProductdata([])
            setCustomerList([])
            setSelectedFreq(null)
            setSelectedCustomerInfo({})
            toggleModal(true)
        } else {
            toast.error("Something went wrong")
            setLoading(false)
        }
    }
 
    useEffect(()=> {
        setOpen(isProductOpen);
        if (product && product?._id) {
            fetchProductData(product._id)
        }
        if (customerDetails) {
           setSelectedCustomerInfo(customerDetails)
        }
    },[isProductOpen])
   
    return (
        <>
            <SimpleBackdrop status={loading} />
            <MDBModal isOpen={open} size={"lg"} toggle={handleClose}>
                <MDBModalHeader toggle={handleClose}>Add Subscription</MDBModalHeader>
                <MDBModalBody>
                    <div className={styles.mainContainer}>
                        <div>
                            {(product && product?.productName !== "") ?
                                <>
                                    <div className={"px-1"}>
                                        <b>Product Name</b>
                                    </div>
                                    <MDBCard style={{ width: "100%" }}>
                                        <MDBCardBody>
                                            <div className="ml-n2">{product.productName}</div>
                                        </MDBCardBody>
                                    </MDBCard>
                                </> :
                                <AutocompleteMeds
                                    label="Enter Product Name"
                                    name="product"
                                    options={productData}
                                    onSelect={(val, item) => handleSelectProduct(val, item)}
                                    currentProduct={""}
                                    selectedVal={""}
                                    onChange={(val) => { handleSLookupSearch(val) }} />}
                            {(customerDetails && customerDetails?.name !== "") ?
                                <div className="mt-2">
                                    <div className={"px-1"}>
                                        <b>Customer Name</b>
                                    </div>
                                    <MDBCard style={{ width: "100%" }}>
                                        <MDBCardBody>
                                            <div className="ml-n2">{customerDetails?.name}</div>
                                        </MDBCardBody>
                                    </MDBCard>
                                </div> :
                                <div className={styles.autoContain}>
                                    <AutocompleteUser
                                        label="Enter Customer Name or phone"
                                        name="customer"
                                        options={customerList}
                                        onSelect={(val, item) => handleSelectCustomer(val, item)}
                                        onChange={(val) => { handleCustomerSearch(val) }} />
                                </div>
                            }
                            <MDBInput id={"quantity"} getValue={(val)=>setQuantity(val)}  placeholder="Enter quantity" />
                            <div className={styles.autoContain}>
                                <div style={{ width: '300px' }}>
                                    <MDBSelect
                                        getValue={(value) => handleSelectChangeFreq(value)} // Triggered when selection is made
                                    >
                                        <MDBSelectInput selected={selectedFreq || "Select Frequency"} />
                                        <MDBSelectOptions>
                                            {numberArray.map((number) => (
                                                <MDBSelectOption key={number} value={number}>
                                                    {number} Days
                                                </MDBSelectOption>
                                            ))}
                                        </MDBSelectOptions>
                                    </MDBSelect>
                                </div>
                            </div>
                        </div>
                        <div style={{ width: "100%" }}>
                            {productIInfo?.imageUrl?.[0] && <div className={styles.imageContainer}>
                                <img src={`${config.IMAGE_HOST}${productIInfo?.imageUrl?.[0]?.filePathOpt}`} className={styles.image} alt="product" />
                            </div>}
                            <div className={`${styles.customerDetailscontainer} ${styles.autoContain}`}>
                                {Object.keys(selectedCustomerInfo)?.length > 0 &&
                                <div className="customerDropdown">
                                    <span><b>Customer details</b></span><br />
                                    <span>Name : {selectedCustomerInfo?.name}</span><br />
                                    <span>Phone : {selectedCustomerInfo?.contactNo?.length > 1 ? selectedCustomerInfo?.contactNo : selectedCustomerInfo?.phone ? selectedCustomerInfo?.phone : "Not Available"}</span><br />
                                    <span>Email : {selectedCustomerInfo?.email?.length > 1 ? selectedCustomerInfo?.email : "Not Available"}</span>
                                </div>}
                            </div>
                        </div>
                    </div>
                </MDBModalBody>
                <MDBModalFooter className="d-flex justify-content-center">
                    <MDBBtn onClick={handleSave}>Save</MDBBtn>
                    <MDBBtn onClick={handleClose}>Close</MDBBtn>
                </MDBModalFooter>
            </MDBModal>
 
        </>
    );
};
 
export default ViewProductsRequsetModal;
 
