import React,{Suspense} from "react";
import {Link} from "react-router-dom";
import { withRouter } from "react-router-dom";
import {
    MDBBreadcrumbItem,
    MDBBreadcrumb, MDBBox, MDBContainer,
} from 'mdbreact';
import CustomerInsights from "../../components/report/customerInsghts"
export default class CustomerInsightsPage extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Customer Insights</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <MDBContainer className={"min-vh-100"}>
                    <Suspense fallback={null}>
                        <CustomerInsights  queryString={this.props.match.params}/>
                    </Suspense>
                </MDBContainer>
            </React.Fragment>
        );
    }
}