import React from "react";
import Moments from "moment"
import { withRouter } from "react-router-dom";
import {
    MDBModal,    MDBModalBody,    MDBModalHeader,    MDBFormInline,    MDBRow,    MDBBox,  MDBInput,  MDBBtn,  MDBCol,  MDBTable,  MDBTableHead,    MDBTableBody,    MDBCardBody,    MDBCard,
    MDBIcon,    MDBStepper,    MDBStep,    MDBSelect} from 'mdbreact';
import PharmacyService from "../../services/pharmacyService";
import OrderService from "../../services/orderService"
import {toast} from "react-toastify";
import util from "../../utility/utility";
import UserStore from "../../stores/userStore";
import AuthService from "../../services/authService";
import MomentUtils from "@date-io/moment";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import AddAddress from "../common/addAddress";
import AddDoctor from "../common/AddDoctor";
import Dependents from "../Dependents/Dependents";
import AdvancedDocUpload from "../common/fileUpload/advanceDocUpload";
import EditProductShortcutComp from "../product/editProductShortcut";
import SimpleBackdrop from "../common/overlay";
import config from "../../config";
import {Page, Text, View, Document, StyleSheet,PDFViewer} from '@react-pdf/renderer';
import orgConfig from "../../orgConfig";
import MultipleDocsUpload from "../common/fileUpload/MultipleDocsUpload";

const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#E4E4E4'
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },


    table: { display: "table", width: "auto", borderStyle: "solid", borderWidth: 1, borderRightWidth: 0, borderBottomWidth: 0 },
    tableRow: { margin: "auto", flexDirection: "row" },
    tableCol1: { width: "100%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 },
    tableCol2: { width: "50%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 },
    tableCol4: { width: "25%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 },
    tableCol: { width: "8%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 },
    tableColP3: { width: "3%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 },
    tableColP4: { width: "4%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 },
    tableColP5: { width: "5%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 },
    tableColP6: { width: "6%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 },
    tableColP7: { width: "7%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 },
    tableColP8: { width: "8%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 },
    tableColP9: { width: "9%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 },
    tableColP10: { width: "10%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 },
    tableColProduct: { width: "18%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 },
    tableColP90:{ width: "90%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 },
    tableCell: { margin: "auto", marginTop: 5, marginBottom: 5, fontSize: 10 },
    tableCellTotal: { margin: "auto", marginTop: 5, marginBottom: 5, fontSize: 10, position: "absolute", right: 20 },

    tableCellH1: { margin: "auto", marginTop: 5,  marginBottom: 5, fontSize: 18, fontWeight: "bold" },
    tableCellH2: { margin: "auto", marginTop: 5, marginBottom: 5, fontSize: 14 },
    tableCellH3: { margin: "auto", marginTop: 5, marginBottom: 5, fontSize: 12 },
    tableCellH4: { margin: "auto", marginTop: 5, marginBottom: 5, fontSize: 10 },
    tableCellH5: { margin: "auto", marginTop: 5, fontSize: 9 },
    tableCellH6: { margin: "auto", marginTop: 5, fontSize: 8 },

});

class OnlineOrder extends React.Component {
    async componentDidMount() {
        let userData = await UserStore.fetchSessionData();
        this.setState({userData: userData})
        if(userData.type === "platformAdmin"){
            this.setState({orgId: userData._id})
            this.setState({numId: userData.numId})
        } else if(userData.type === "ORG_ADMIN"){
            this.setState({orgId: userData.orgDetails[0]._id})
            this.setState({numId: userData.orgDetails[0].numId})
        }
        let deliveryPerson=await OrderService.fetchDeliveryPersonDetails(userData.sessionToken);
        if(deliveryPerson===false){
            toast.error("We are facing some issue. Please try again later.");
        }else{
            let tempArr=[];
            for(let d of deliveryPerson){
                let temp={
                    text:d.name,
                    value:d._id,
                    checked:false
                }
               tempArr.push(temp);
            }
            this.setState({cashierList:tempArr});
        }
        console.log(this.state.userData)
    }
    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    }
    toggleProvider = () => {
        this.setState({
            modalProvider: !this.state.modalProvider
        });
    }

    addDoctorCompleted = () => {
        toast.success("Doctor Added.");
        this.toggleProvider()
    }

    toggleDependent = () => {
        this.setState({
            modalDependent: !this.state.modalDependent
        });
    }

    toggleEditProductShortcut = () => {
        this.setState({
            modalEditProductShortcut: !this.state.modalEditProductShortcut
        });
    }

    editProductCompleted = () => {
        this.toggleEditProductShortcut();
    }

    togglePdfInvoice  = () => {
        this.setState({
            modalPdfInvoice: !this.state.modalPdfInvoice
        });
    }

    changeGender = (val) => {
        this.setState({"gender":val});
    };

    editProductShortcut = (row) =>{
        console.log(row)
        this.setState({productShortcutTobeEdited:row})
        this.toggleEditProductShortcut()
    }

    constructor(props) {
        super(props);
        this.state = {
            assocData:"",
            theInputKey:"",
            selectedProviderAddress : "1st Floor, Lakhi Mandir Complex, Beltola, Guwahati, Assam, 781028.  Call: 03612302334",
            userId:"",
            tempCarts:[],
            dependentId:"",
            suggestedUser:[],
            user:{},
            allSuggestedUser:{},
            allSuggestedProvider:{},
            suggestedProvider:[],
            allSuggestedProviderCart:{},
            suggestedProviderCart:[],
            expandDependent:false,
            provider:{},
            userData:{},
            modal: false,
            modalDependent:false,
            dob:null,
            gender:"MALE",
            formActivePanel1: 1,
            formActivePanel1Changed: false,
            orders: [ ],
            carts: [],
            orderApiResultCame: false,
            stDate: Moments(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format("YYYY-MM-DD"),
            endDate: '',
            error: "",
            medicine: "",
            searchedProductList: [],
            customerError:false,
            prescriptionMedicineDetected:false,
            password:"",
            name:"",
            description:"",
            isActive:"",
            progressStatus:false,
            nameError:false,
            contactNoError:false,
            email:"",
            contactNo:"",
            userNameError:false,
            passwordError:false,
            passwordType:"password",
            orgId:"",
            numId:"",
            product:{},
            suggestedProduct:[],
            allSuggestedProduct:[],
            dependents:[],
            selectedDependent:{},
            paidAmount:0,
            balanceAmount:0,
            totalAmount:0,
            amountCollected:0,
            changeReturn:0,
            paymentMode:"CASH",
            orderMode:"IN-PERSON",
            paymentStatus:"COMPLETED",
            orderStatus:"CREATED",
            docs:[],
            activeDoc:{
              index:0,
            },
            orderDate:new Date(),
            apiResultCame:false,
            paymentModes: [{ text:"Cash", value:"CASH", checked:true },{ text:"Credit", value:"CREDIT" },{ text:"Card", value:"CARD" },{ text:"Netbanking", value:"NETBANKING" },{ text:"Wallet", value:"WALLET" }],
            orderModes: [{ text:"In person", value:"INPERSON", checked:true },{ text:"Telephonic", value:"TELEPHONIC" },{ text:"Online", value:"ONLINE" }],
            orderStatuses: [{ text:"Created", value:"CREATED", checked:true },{ text:"Returned", value:"RETURNED" },{ text:"Canceled", value:"CANCELED" },{ text:"Delivered", value:"DELIVERED" }],
            activeOrder:{
                docs:[],
                products:[],
            },
            isActiveOrderAPrescriptionDrug:false,
            searchedCustomerText:"",
            customerList:[],
            selectedCustomer:{},
            searchedProductText:"",
            searchedProviderText:"",
            modalEditProductShortcut:false,
            productShortcutTobeEdited:{},
            responseArray:[],
            cashierId:"",
            cashierList: [],
        }
    }

    calculateTotalAmount = async () => {
        // quantity means, quantity of a product user has added
        let existingCarts = this.state.carts;
        let totalAmount = 0;
        for(let item of existingCarts ){
            let contentQuantity = item.contentQuantity;
            if(item.looseSaleAllowed === "N"){
                contentQuantity = 1 // This could be the minimum sale quantity later
            }
            let mrp = (item.inventory.mrp / contentQuantity);
            let subTotal = mrp * item.quantity;
            // if any discount, apply it
            if(item.discount > 0){
                let discountAmount = subTotal * ((item.discount)/100)
                subTotal = subTotal - discountAmount;
            }
            totalAmount = totalAmount + subTotal;
        }
        await this.setState({totalAmount: util.customRound(totalAmount)})
        this.refreshAmountCollected()
    }

    addItemToCartLocal = async (inventoryData) => {
        // Check if the item already exist
        for(let index in this.state.carts){
            if(inventoryData.inventory._id === this.state.carts[index].inventory._id){
                let existingData = this.state.carts;
                let additionalQuantity = 0;
                let additionalMrp = 0
                if(existingData[index].looseSaleAllowed === "Y"){
                    if(existingData[index].contentQuantity === 1 ){
                        additionalQuantity = 1;
                        additionalMrp = existingData[index].inventory.mrp
                    } else if(existingData[index].contentQuantity > 1) {
                        additionalQuantity = existingData[index].contentQuantity;
                        additionalMrp = existingData[index].inventory.mrp / existingData[index].contentQuantity
                    } else {
                        additionalQuantity = 1;
                        additionalMrp = existingData[index].inventory.mrp
                    }
                } else {
                    additionalQuantity = 1;
                    additionalMrp = existingData[index].inventory.mrp
                }
                existingData[index].quantity = existingData[index].quantity + additionalQuantity;
                existingData[index].subTotal = existingData[index].quantity * additionalMrp
                this.setState({carts:existingData})
                this.calculateTotalAmount()
                return false;
            }
        }
        let newItem = inventoryData;
        if(newItem.looseSaleAllowed === "Y"){
            //newItem.inventory.mrp=parseFloat(newItem.inventory.mrp)/parseInt(newItem.contentQuantity);
            // Loose sale allowed
            if(newItem.contentQuantity === 1){
                newItem.quantity = 1;
                newItem.subTotal = newItem.inventory.mrp
            } else if(newItem.contentQuantity > 1){
                newItem.quantity = newItem.contentQuantity;
                if(newItem.inventory.quantity< newItem.contentQuantity) {
                    newItem.subTotal = (newItem.inventory.mrp / newItem.contentQuantity) * newItem.inventory.quantity;
                }else{
                    newItem.subTotal = (newItem.inventory.mrp / newItem.contentQuantity) * newItem.contentQuantity;
                }
            } else {
                toast.error("Invalid product details. Please update the data and then search and add to cart.")
                return false;
            }
        } else {
            // No loose sale
            newItem.quantity = 1;
            newItem.subTotal = newItem.inventory.mrp
        }
        // Check if the inventory has enough stock to add
        if(inventoryData.inventory.quantity < newItem.quantity){
            if(newItem.quantity === 1){
                toast.error("Not enough stock")
                return false;
            } else if(newItem.quantity > 1){
                newItem.quantity = inventoryData.inventory.quantity;
            }
        }

        newItem.suggestedProviderCart = {};
        newItem.AllSuggestedProviderCart = [];
        newItem.selectedDependent = this.state.selectedDependent;
        newItem.customerData = this.state.user;
        newItem.doctorDetails = this.state.provider;
        newItem.showChangePatientOptions = false
        newItem.showChangeProviderOptions = false;
        newItem.searchedProviderText = "";
        let inv = {
            _id: inventoryData._id
        }
        newItem.inventoryData = inv; // This is to satisfy the api
        let existingCarts = this.state.carts;
        existingCarts.push(newItem);
        this.setState({carts: existingCarts});
        console.log(existingCarts)
        this.calculateTotalAmount();
        this.setState({apiResultCame:true})
        this.closeSearch()
        if(inventoryData.hasOwnProperty("productName")){
            if(inventoryData.medicineType.trim() === "OTC" || inventoryData.medicineType.trim() === "Schedule H" || inventoryData.medicineType.trim() === "Schedule H1" || inventoryData.medicineType.trim() === "Schedule X" || inventoryData.medicineType.trim() === "PRESCRIPTION DRUGS" ){
                toast.info("This medicine is a prescription drug and prescription is mandatory.")
                this.setState({prescriptionMedicineDetected:true})
            }
        }
        // clear the search
        this.setState({searchedProductText:""})

    }

    // THis method might required later, here, we make actuall api call while adding to cart
    addItemToCart = async (item) => {
        if(!this.state.user.hasOwnProperty("name")){
            toast.error("Please enter customer name.");
            this.setState({customerNameError:true});
            return false
        }
        let addToCartRes = await OrderService.addItemToCart(item, this.state)
        if(addToCartRes.hasOwnProperty("inventoryData")){
            this.setState({ "cartId": addToCartRes._id })
            let newItem = addToCartRes.inventoryData;
            newItem.quantity = 1;
            newItem.suggestedProviderCart = {};
            newItem.AllSuggestedProviderCart = [];
            newItem.selectedDependent = this.state.selectedDependent;
            newItem.customerData = this.state.user;

            let existingCarts = this.state.carts;
            existingCarts.push(newItem);
            this.setState({carts: existingCarts});
            console.log(existingCarts)
            this.calculateTotalAmount();
        }
        this.setState({apiResultCame:true})
    }

    removeFromCart = async (index, item) => {
        let existingCarts = this.state.carts;
        if(existingCarts[index].productId === item.productId){
            existingCarts.splice(index, 1);
            this.setState({ "carts": existingCarts })
        }
        this.calculateTotalAmount();
    }

    placeOfflineOrder = async () => {
        if(!this.state.cashierId ){
            toast.error("Please select cashier.");
            return false;
        }
        if(!this.state.cashierId===""){
            toast.error("Please select cashier.");
            return false;
        }
        if(this.state.paidAmount <= 0 && this.state.paymentMode === "CASH"){
            toast.error("Paid amount is 0. If this is a credit order, please change the payment mode to 'credit'.");
            return false;
        } else if((this.state.totalAmount - this.state.paidAmount ) >=1 && this.state.paymentMode === "CASH"){
            toast.error("Paid amount is lesser than total amount. If this is a partial payment please change the payment mode to 'credit'.");
            return false;
        }
        this.setState({suggestedUser:[]})
        this.setState({progressStatus:true})
         let offlineOrderRes = await OrderService.createOfflineOrder(this.state)
         console.log(offlineOrderRes);
        this.setState({activeOrder:offlineOrderRes})
        this.setState({progressStatus:false})
         if(offlineOrderRes){
             for(let row of offlineOrderRes.products){
                 if(row.medicineType.trim() === "Schedule X" || row.medicineType.trim() === "Schedule H" || row.medicineType.trim() === "Schedule H1" || row.medicineType.trim() === "PRESCRIPTION DRUG" || row.medicineType.trim() === "PRESCRIPTION DRUGS"){
                     this.setState({isActiveOrderAPrescriptionDrug:true})
                 }
             }
             toast.success("Order created successfully");
             this.resetAllField();
             this.downloadInvoice()
         }
    }
    addDependentCompleted = (id) => {
        this.fetchDependentsByUserId(id)
        this.toggleDependent();
    }
    convertMfgOrExpiry = date =>{
        if(date){
            return Moments(date).format("MMM YYYY");
        } else {
            return "";
        }
    }
    handleEndDate = date => {
        this.setState({"stDate":Moments(date).format("YYYY-MM-DD")});
    };
    handleChangeMedicine = async (e)=>{
        this.setState({medicine:e})
        let res = await PharmacyService.autoSuggestInventory(e);
        if(res){
            this.setState({"searchedProductList":res})
        } else {
            this.setState({"searchedProductList":[]})
        }
    }
    closeSearch = () =>{
        this.setState({medicine:"", searchedProductList:[]})
    }
    handleChangeContactNo=(e)=>{
        if(!util.isInt(e.target.value) && e.target.value !== ""){
            return false;
        }
        this.setState({contactNo:e.target.value})
    };
    handleChangeEmail=(e)=>{
        this.setState({email:e.target.value})
    };

    handleName=(e)=>{
        this.setState({name:e.target.value})
    };

    handlePaidAmount =(e)=>{
        if(!util.isNumericTwoDecimalPartial(e.target.value) && e.target.value !== ""){
            return false;
        }
        this.setState({paidAmount:e.target.value})
        let paidAmount = e.target.value;
            if(this.state.amountCollected > 0 ){
                this.setState({changeReturn: util.customRound(this.state.amountCollected - paidAmount)})
            }
    };

    handleAmountCollected =(e)=>{
        if(!util.isNumericTwoDecimalPartial(e.target.value) && e.target.value !== ""){
            return false;
        }
        this.setState({amountCollected:e.target.value})
        let amountCollected = e.target.value;
        if(amountCollected && amountCollected >= 0){
            let ra = amountCollected - this.state.totalAmount;
            ra = (ra > 0) ? util.customRound(ra) : ra;
            this.setState({changeReturn:util.customRound(ra)})
        } else {
            this.setState({changeReturn:0})
        }

        if(parseFloat(amountCollected) >= parseFloat(this.state.totalAmount)){
            this.setState({paidAmount:util.customRound(this.state.totalAmount)})
        } else {
            this.setState({paidAmount:util.customRound(amountCollected)})
        }
    };

    refreshAmountCollected = () => {
        if(this.state.amountCollected && this.state.amountCollected >= 0){
            let ra = this.state.amountCollected - this.state.totalAmount;
            ra = (ra > 0) ? util.customRound(ra) : ra;
            this.setState({changeReturn:util.customRound(ra)})
        } else {
            this.setState({changeReturn:0})
        }

        if(parseFloat(this.state.amountCollected) >= parseFloat(this.state.totalAmount)){
            this.setState({paidAmount:util.customRound(this.state.totalAmount)})
        } else {
            this.setState({paidAmount:util.customRound(this.state.amountCollected)})
        }

        if(this.state.paymentMode === "CARD" || this.state.paymentMode === "NETBANKING" || this.state.paymentMode === "WALLET"){
            this.setState({paidAmount: util.customRound(this.state.totalAmount)})
        }
    }

    handleChangeReturn =(e)=>{
        if(!util.isNumericTwoDecimalPartial(e.target.value) && e.target.value !== ""){
            return false;
        }
        this.setState({changeReturn:e.target.value})
    };

    validateName = () => {
        if(!this.state.name || this.state.name.trim() === ""){
            this.setState({nameError: true})
        } else {
            this.setState({nameError: false})
        }
    }
    validateEmail = () => {
        if(this.state.email && !util.validateEmail(this.state.email)){
            this.setState({"loadingEmail": true, "emailError": true})
        } else {
            this.setState({"loadingEmail": false, "emailError": false})
        }
    }
    validatePhone= () => {
        if(!util.checkPhone(this.state.contactNo)){
            this.setState({ "contactNoError": false})
        } else {
            this.setState({"contactNoError": false})
        }
    }

    addUser =async () => {
        this.validateName();
        this.validatePhone()
        if(!this.state.name || this.state.name.trim() === ""){
            this.setState({"nameError": true})
           return false
        }
        if(!util.checkPhone(this.state.contactNo)){
            this.setState({ "contactNoError": false})
            return false
        }

        this.setState({progressStatus:true});

        let res=await AuthService.register(this.state);
        this.setState({progressStatus:false});
        if(res===false){
            toast.error("Something went wrong.");
            return true;
        }else{
            console.log(res)
            if(res.hasOwnProperty("code")){
                if(res.code === "10020"){
                    toast.error("User already exist.")
                    return false;
                } else {
                    toast.error("Something went wrong.")
                    return false;
                }
            }
            if (res.hasOwnProperty("patients")){
                this.setState({userId:res.patients[0].userId, dependentId: res.patients[0].dependentId })
                let user = {
                    user_id: res.patients[0].userId,
                    email: (res.patients[0].email) ? res.patients[0].email :"",
                    dependent_id: (res.patients[0].dependentId) ? res.patients[0].dependentId :"",
                    contact_no: (res.patients[0].contactNo) ? res.patients[0].contactNo :"",
                    name: res.patients[0].name
                }
                console.log(user)
                this.setState({user:user })

                console.log(this.state.user)
            }
            toast.success("User added successfully.");
            await this.associateUser();
            await this.fetchDependentsByUserId(this.state.user._id)
            this.handleNextPrevClick(2)
        }
    }
    associateUser = async () => {
        let userData=await UserStore.fetchSessionData();
        let res=await AuthService.associateUser(this.state, userData);
        this.setState({assocData:res,user:res});
    }
    changeDependent = (val) => {
        for(let item of this.state.dependents){
            if(item._id === val){
                this.setState({selectedDependent: item});
                // Also push it to cart items if it's required
                for(let index in this.state.carts){
                    if(this.state.carts[index].isMedicine === true){
                        let existingData = this.state.carts;
                        if(this.state.carts[index].medicineType.trim() === "Schedule H" || this.state.carts[index].medicineType.trim() === "Schedule H1" || this.state.carts[index].medicineType.trim() === "Schedule X" || this.state.carts[index].medicineType.trim() === "PRESCRIPTION DRUGS"){
                            existingData[index].selectedDependent = item
                            this.setState({carts:existingData})
                        }
                    }
                }
            }
        }
    };

    fetchDependentsByUserId = async (id) => {
        this.setState({progressStatus:true});
        let data =await AuthService.fetchDependentsByUserId(this.state, id);
        this.setState({progressStatus:false});
        if(data===false){
            toast.error("Something went wrong.");
            return true;
        }else{
            console.log("Data", data)
            this.setState({dependents:data})
            for(let item of data){
                if(item.relation === "SELF"){
                    this.setState({selectedDependent: item});
                    // Also push it to cart items if it's required
                    for(let index in this.state.carts){
                        if(this.state.carts[index].isMedicine === true){
                            let existingData = this.state.carts;
                            if(this.state.carts[index].medicineType.trim() === "OTC" || this.state.carts[index].medicineType.trim() === "Schedule H" || this.state.carts[index].medicineType.trim() === "Schedule H1" || this.state.carts[index].medicineType.trim() === "Schedule X" || this.state.carts[index].medicineType.trim() === "PRESCRIPTION DRUGS"){
                                existingData[index].selectedDependent = item
                                this.setState({carts:existingData})
                            }
                        }
                    }
                }
            }
        }
    }

    fetchInventoryByProductId = async (id) => {
        this.setState({progressStatus:true});
        let data =await OrderService.fetchInventoryByProductId(this.state, id);
        this.setState({progressStatus:false});
        if(data===false){
            toast.error("Something went wrong.");
            return true;
        }else{
            console.log("Data", data)
            if(data.length < 1){
                toast.error("Product not found in inventory.");
            }
            let existingProduct = this.state.searchedProductList;
            for(let item of data){
                console.log(item)
                existingProduct.push(item)
            }
            this.setState({
                searchedProductList: existingProduct
            })
        }
    }

    handleCustomerSearch = async (e)=> {
        this.setState({searchedCustomerText:e.target.value})
        if(e.target.value.length < 2){
            return false;
        }
        let resUser = await AuthService.suggestCustomer(e.target.value, this.state);
        let suggestedUser = [];
        for(let item of resUser){
            if((item.hasOwnProperty("name") && item.name ) ||(item.hasOwnProperty("first_name") && item.first_name )){
                //if(item.relation === "SELF"){
                    suggestedUser.push(item)
                //}
            }
        }
        this.setState({allSuggestedUser: suggestedUser});
    }

    selectCustomer=(row)=>{
        this.setState({user:row})
        this.setState({customerError:false})
        this.fetchDependentsByUserId(row._id)
        for(let index in this.state.carts){
            if(this.state.carts[index].isMedicine === true){
                let existingData = this.state.carts;
                if(this.state.carts[index].medicineType.trim() === "OTC" || this.state.carts[index].medicineType.trim() === "Schedule H" || this.state.carts[index].medicineType.trim() === "Schedule H1" || this.state.carts[index].medicineType.trim() === "Schedule X" || this.state.carts[index].medicineType.trim() === "PRESCRIPTION DRUGS"){
                    existingData[index].customerData = row;
                     this.setState({carts:existingData})
                }
            }
        }
        this.setState({searchedCustomerText:""})
    }
    selectProvider=(row)=>{
        this.setState({provider:row})
        for(let index in this.state.carts){
            if(this.state.carts[index].isMedicine === true){
                let existingData = this.state.carts;
                if(this.state.carts[index].medicineType.trim() === "OTC" || this.state.carts[index].medicineType.trim() === "Schedule H" || this.state.carts[index].medicineType.trim() === "Schedule H1" || this.state.carts[index].medicineType.trim() === "Schedule X" || this.state.carts[index].medicineType.trim() === "PRESCRIPTION DRUGS"){
                    existingData[index].doctorDetails = row;
                    this.setState({carts:existingData})
                }
            }
        }
        this.setState({searchedProviderText:""})
    }

    clearSelectedDoctor=()=>{
        this.setState({provider:{}})
    }

    clearSelectedCustomer=()=>{
        this.setState({user:{}})
    }

    selectProviderCart=(index, row)=>{
        let existingData = this.state.carts;
        existingData[index].doctorDetails = row;
        existingData[index].searchedProviderText = "";
        existingData[index].showChangeProviderOptions = false;
        this.setState({carts:existingData})
    }

    handleLineItemCustomerSearch = (index)=>(e)=> {
        if (!util.isInt(e.target.value) && e.target.value !== "") {
            return false;
        }
    }

    fetchProduct = async (e) => {
        this.setState({searchedProductList:[]})
        try {
            if (e.target.value !== 0 || e.target.value !== '0') {

                this.setState({searchedProductText:e.target.value})
                if(e.target.value.length < 2){
                    return false;
                }
                let responseArray = this.state.responseArray

                let suggestedProduct = await PharmacyService.searchProductOrder(e.target.value, this.state);
                //bugfix
                for(let item of suggestedProduct){
                    if(item["looseSaleAllowed"]==="Y"){
                        for(let inv of item["inventory"]){
                            if(inv.hasOwnProperty("looseQuantity")){
                                inv.quantity=inv.looseQuantity;
                            }
                        }
                    }
                }

                responseArray.push(suggestedProduct)
                await this.setState({responseArray})
                for (let i=0; i<this.state.responseArray.length; i++) {
                    let count=0;
                    for(let j=0; j<this.state.responseArray[i].length; j++){
                        if (responseArray[i][j].productName.toUpperCase().indexOf(e.target.value.toUpperCase()) > -1) {
                            ++count;
                        }
                    }
                    if(count === this.state.responseArray[i].length) {
                        await this.setState({allSuggestedProduct: responseArray[i]});
                        this.setState({searchedProductList: []})
                        let existingProduct = [] //this.state.searchedProductList;
                        for(let product of this.state.allSuggestedProduct){
                            for(let inventoryItem of product.inventory){
                                let target0 = {}
                                let newProductObj =  Object.assign(target0, product);
                                delete newProductObj.inventory;
                                let target = {}
                                newProductObj.inventory =  Object.assign(target, inventoryItem);
                                existingProduct.push(newProductObj)
                            }
                        }
                        this.setState({
                            searchedProductList: existingProduct, responseArray: []
                        })
                        break;
                    }
                }
            }
        }catch (e) {
            this.setState({suggestedProduct: [{title: "", value: ''}]});
        }
    };

    fetchProvider = async (e) => {
        console.log(e)
        try {
            if (e.target.value !== 0 || e.target.value !== '0') {
                this.setState({searchedProviderText:e.target.value})
                let suggestedProvider = await AuthService.searchProviderNew(e.target.value, this.state);
                this.setState({allSuggestedProvider: suggestedProvider});
            }
        }catch (e) {
            this.setState({suggestedProvider: [{title: "", value: ''}]});
        }
    };

    fetchProviderCart = (index)=>(e) => {
         this.searchProviderApi(index, e);
        console.log(e)
        if(!e){
            return false;
        } else if(!e.hasOwnProperty("target")){
            return false;
        } else if(!e.target.hasOwnProperty("value")){
            return false;
        }
        try {
            if (e.target.value !== 0 || e.target.value !== '0') {
                let existingData = this.state.carts;
                existingData[index].searchedProviderText = e.target.value
                this.setState({carts: existingData});
                console.log(existingData)
            }
        }catch (e) {
            this.setState({suggestedProviderCart: [{title: "", value: ''}]});
        }
    };

    searchProviderApi = async (index, e) => {
        let existingData = this.state.carts;
        existingData[index].searchedProviderText = e.target.value
        this.setState({carts: existingData});
        console.log(existingData)

        let suggestedProvider = await AuthService.searchProviderNew(e.target.value, this.state);
        this.setState({allSuggestedProviderCart: suggestedProvider});
    }

    onChangeProviderInCart = (index)=> (event, values) => {
        console.log(index);
        console.log(values);
        if((values && values.hasOwnProperty("value") && values.value!==null)) {
            let existingData = this.state.carts;
             existingData[index].doctorDetails = values.value
            // existingData[index].productIdError=false;
            this.setState({carts:existingData})
            this.setState({suggestedProviderCart: [], allSuggestedProviderCart: {}});
        }
    }

    onChangeProduct = (event, values) => {
        if((values && values.hasOwnProperty("value") && values.value!==null)) {
            this.setState({
                product: values.value
            });
            console.log(values)
            this.fetchInventoryByProductId(values.value._id)
        }
    }

    //Stepper
    handleNextPrevClick =  (param) => {
        this.setState({
            formActivePanel1: param
        });
    }
    calculateAutofocus = (a) => {
        if (this.state['formActivePanel' + a + 'Changed']) {
            return true
        }
    }
    openAddAddress = () =>{
        this.toggle()
    };
    fetchAllAddress = () => {
        console.log("FETCH ALL ADDRESS HOOK");
    }
    handleOrderMode = (e) => {
        this.setState({orderMode:e[0]})
    }
    handlePaymentMode = (e) => {
        this.setState({paymentMode:e[0]})
        if(e[0] === "WALLET" || e[0] === "NETBANKING" || e[0] === "CARD"){
            this.setState({paidAmount:this.state.totalAmount})
        }
    }
    handleCashier = (e) => {
        this.setState({cashierId:e[0]})
    }
    handleOrderDate =(e)=>{
        this.setState({orderDate:e})
    };
    handleDependentChange = (index, item) => {
        let existingCarts = this.state.carts;
        let selectedDependent = this.state.carts[index].selectedDependent;
        for(let i =0 ; i < this.state.dependents.length; i++ ){
            //let name = this.state.dependents[i].first_name ? (this.state.dependents[i].first_name + " " + this.state.dependents[i].last_name) : this.state.dependents[i].name;
            if( this.state.dependents[i]._id === item._id){ //this.state.dependents[i].dependent_id
                selectedDependent = item;
                console.log(this.state.dependents[i]._id);
            }
        }
        existingCarts[index].selectedDependent = selectedDependent;
        existingCarts[index].showChangePatientOptions = false;
        this.setState({carts:existingCarts});
        return false;
    }

    handleQuantity = (index)=>(e)=>{
        if(!util.isInt(e.target.value) && e.target.value !== ""){
            return false;
        }
        let existingData = this.state.carts;
        // This will prevent from entering quantity bigger than in stock
        if(parseInt(existingData[index].inventory.quantity) < parseInt(e.target.value)){
            return false;
        }
        let contentQuantity = existingData[index].contentQuantity;
        if(existingData[index].looseSaleAllowed === "N"){
            contentQuantity = 1 // This could be the minimum sale quantity later
        }
        let mrp = existingData[index].inventory.mrp / contentQuantity
        existingData[index].quantity = e.target.value
        existingData[index].subTotal = existingData[index].quantity * mrp
        // if any discount, apply it
        if(existingData[index].discount > 0){
            let discountAmount = existingData[index].subTotal * ((existingData[index].discount)/100)
            existingData[index].subTotal = existingData[index].subTotal - discountAmount;
        }
        this.setState({carts:existingData})
        this.calculateTotalAmount()
    };

    handleDiscount = (index)=>(e)=>{
        if(!util.isNumericTwoDecimalPartial(e.target.value) && e.target.value !== ""){
            return false;
        }
        let existingData = this.state.carts;
        let discount  = e.target.value;
        existingData[index].discount = e.target.value

        let contentQuantity = existingData[index].contentQuantity;
        if(existingData[index].looseSaleAllowed === "N"){
            contentQuantity = 1 // This could be the minimum sale quantity later
        }
        let mrp = existingData[index].inventory.mrp / contentQuantity
        existingData[index].subTotal = existingData[index].quantity * mrp
        // if any discount, apply it
        if(discount > 0){
            let discountAmount = existingData[index].subTotal * ((discount)/100)
            existingData[index].subTotal =  existingData[index].subTotal - discountAmount;
        }
        this.setState({carts:existingData})
        this.setState({carts:existingData})
        this.calculateTotalAmount()
    };

    initChangePatient = (index)=>{
        let existingData = this.state.carts;
        existingData[index].showChangePatientOptions = true;
        existingData[index].showChangeProviderOptions = false;
        this.setState({carts:existingData})
    };

    cancelChangePatient = (index)=>{
        let existingData = this.state.carts;
        existingData[index].showChangePatientOptions = false;
        this.setState({carts:existingData})
    };

    initChangeProvider = (index)=>{
        let existingData = this.state.carts;
        existingData[index].showChangeProviderOptions = true;
        existingData[index].showChangePatientOptions = false;
        this.setState({carts:existingData})
    };

    cancelChangeProvider = (index)=>{
        let existingData = this.state.carts;
        existingData[index].showChangeProviderOptions = false;
        this.setState({carts:existingData})
    };

    fileUploadCallback = (files) => {
        this.setState({ "docs": files, "progressStatusFileUpload":false})
    }

    initUploadCallback = () => {
        this.setState({"progressStatusFileUpload":true})
    }
    initDeleteDoc = () => {
        this.toggleDelImage();
    }
    resetAllField = () => {
        this.setState({
            cashierId:"",
            assocData:"",
            carts:[],
            totalAmount:0,
            userId:"",
            dependentId:"",
            suggestedUser:[],
            user:{},
            allSuggestedUser:{},
            allSuggestedProvider:{},
            suggestedProvider:[],
            allSuggestedProviderCart:{},
            suggestedProviderCart:[],
            provider:{},
            modal: false,
            modalDependent:false,
            dob:null,
            gender:"MALE",
            formActivePanel1: 1,
            orders: [ ],
            orderApiResultCame: false,
            stDate: Moments(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format("YYYY-MM-DD"),
            endDate: '',
            mode: "Month",
            error: "",
            medicine: "",
            searchedProductList: [],
            customerError:false,
            prescriptionMedicineDetected:false,
            password:"",
            name:"",
            description:"",
            isActive:"",
            progressStatus:false,
            nameError:"",
            email:"",
            contactNo:"",
            userNameError:false,
            product:{},
            suggestedProduct:[],
            allSuggestedProduct:[],
            dependents:[],
            selectedDependent:{},
            paidAmount:0,
            balanceAmount:0,
            paymentMode:"CASH",
            orderMode:"IN-PERSON",
            paymentStatus:"COMPLETED",
            orderStatus:"CREATED",
            docs:[],
            activeDoc:{
                index:0,
            },
            orderDate:new Date(),
            apiResultCame:false,
            paymentModes: [{ text:"Cash", value:"CASH", checked:true },{ text:"Credit", value:"CREDIT" },{ text:"Card", value:"CARD" },{ text:"Netbanking", value:"NETBANKING" },{ text:"Wallet", value:"WALLET" }],
            orderModes: [{ text:"In person", value:"INPERSON", checked:true },{ text:"Telephonic", value:"TELEPHONIC" },{ text:"Online", value:"ONLINE" }],
            orderStatuses: [{ text:"Created", value:"CREATED", checked:true },{ text:"Returned", value:"RETURNED" },{ text:"Canceled", value:"CANCELED" },{ text:"Delivered", value:"DELIVERED" }],
            amountCollected:0,
            changeReturn:0

        });
    }

    expandCollapse = () => {
        this.setState({
            expandDependent: !this.state.expandDependent
        });
    }

    calculateUnit=(addedQuantity, item)=>{
        try {
            // rp is retail package in lowercase
            let rp = item.retailPackage.toLowerCase();
            // rpu is the retail package unit, like strip, will assign the rp to rpu initially
            let rpu = rp;
            // cq is the content Quantity "10"
            let cq = item.contentQuantity;
            // qu quantity unit ex. capsule, tablets
            let qu = item.quantityUnit.toLowerCase();
            let quu = qu;

            // make sure to add a "s" in the end to pluralize it for these cases
            if(rp === "strip" || rp ==="bottle" || rp ==="packet" || rp ==="box" || rp ==="sachet" || rp ==="syringe" || rp ==="mask" || rp ==="bottle" || rp ==="pair" || rp ==="tube" || rp ==="device") {
                // if the user select more than one unit of the item, than make sure to pluralize it
                if (addedQuantity > 1) {
                    if(rp ==="box" ){
                        rpu = item.retailPackage.toLowerCase()+ "es";
                    } else {
                        rpu = item.retailPackage.toLowerCase()+ "s";
                    }
                } else {
                    rpu = item.retailPackage.toLowerCase();
                }
            }
            // make sure to pluralize the qunaitity unit by adding "s" in the end for these cases
            if(qu === "tablet" || qu ==="capsule" || qu ==="respule" || qu ==="syringe" || qu ==="sachet" || qu ==="kilogram" || qu ==="tube" || qu ==="device"  || qu ==="gram"  ) {
                if (item.contentQuantity > 1 || item.contentQuantity > 1) {
                    quu = (qu + "s").toLowerCase();
                }
            }
            // there are some exceptions
            else if(qu === "gm"){
                if (item.contentQuantity > 1 || item.contentQuantity > 1) {
                    quu = "grams"
                }
            } else if(qu === "kilo"){
                if (item.contentQuantity > 1 || item.contentQuantity > 1) {
                    quu = "kilograms"
                }
            }
            return rpu+ " of "+cq+" "+quu;

        }catch (e) {
            return ""
        }
    }

    convertMfgOrExpiry = date =>{
        if(date){
            return Moments(date).format("MMM YYYY");
        } else {
            return "";
        }
    }

    calculateSubTotal = (mrp, quantity, discount, contentQuantity, looseSaleAllowed) => {
        if(!contentQuantity){
            contentQuantity = 1;
        }
        if(mrp > 0){
            if(looseSaleAllowed === "N"){
                contentQuantity = 1 // This could be the minimum sale quantity later
            }
            let subTotal = (mrp/contentQuantity) * quantity;
            if(discount > 0){
                let discountedAmount = subTotal * (discount / 100);
                subTotal = subTotal - discountedAmount
            }
            return util.customRound(subTotal); // subTotal.toFixed("2");
        } else {
            return 0;
        }

    }

    calculateDiscountedAmount = (mrp, quantity, discount, contentQuantity, looseSaleAllowed) => {
        if(!contentQuantity){
            contentQuantity = 1;
        }
        if(mrp > 0){
            if(looseSaleAllowed === "N"){
                contentQuantity = 1 // This could be the minimum sale quantity later
            }
            let subTotal = (mrp/contentQuantity) * quantity;
            if(discount > 0){
                let discountedAmount = subTotal * (discount / 100);
                return util.customRound(discountedAmount); // discountedAmount.toFixed("2");
            } else {
                return 0
            }
        } else {
            return 0;
        }
    }

    calculateGSTAmountFromMRPAndGSTPercentage = (mrp, gstPercentage, quantity, contentQuantity, looseSaleAllowed) => {
        if(!quantity){
            quantity = 1;
        }
        if(!contentQuantity){
            contentQuantity = 1;
        }
        if(looseSaleAllowed === "N"){
            contentQuantity = 1 // This could be the minimum sale quantity later
        }

        if(mrp > 0){
            if(gstPercentage > 0){
                // commenting out the old GST calculation on 16th Oct 2020
                // let basePrice = (mrp - ((100+gstPercentage)/100))/contentQuantity;
                // let gstAmount = basePrice - (basePrice - basePrice*(gstPercentage/100))
                // gstAmount = gstAmount * quantity;
                // gstAmount =  Math.round(gstAmount * 100) / 100
                // return util.customRound(gstAmount); //gstAmount.toFixed("2");

                let perUnitPriceIncludingGST = mrp / contentQuantity,
                    gstAmountPerUnit = perUnitPriceIncludingGST - (perUnitPriceIncludingGST * (100 / (100 + gstPercentage))),
                    totalGstAmount = gstAmountPerUnit * quantity
                return totalGstAmount.toFixed(2)


            } else {
                return 0;
            }
        } else {
            return 0
        }
    }

    convertToReadbleDate = date =>{
        if(date){
            return Moments.utc(date).local().format("MMM D, YYYY hh:mm A");
        }
    }
    downloadInvoice = () => {
        this.togglePdfInvoice();
        this.setState((prevState) => ({
            ready: false
        }), () => {     // THIS IS THE HACK
            setTimeout(() => {
                this.setState({ ready: true });
            }, 1);
        });
    }
    goToNewCustomer=()=>{
        this.props.history.push("/add-customer");
    }
    goToNewDependant=()=>{
        this.props.history.push("/view-customers/"+this.state.user._id);
    }
    render() {
        const { ready } = this.state;
        return (
            <React.Fragment>
                <MDBBox>
                    <SimpleBackdrop status={(this.state.progressStatus === true || this.state.updateProgress === true)?true:false}/>
                    <MDBRow>
                        <MDBCol>
                            <MDBSelect outline={true} className={'form-control'}
                                       label={"Order Mode"} getValue={this.handleOrderMode}
                                       options={this.state.orderModes}></MDBSelect>
                        </MDBCol>
                        <MDBCol>
                            <MDBSelect outline={true} className={'form-control'}
                                       label={"Payment Mode"} getValue={this.handlePaymentMode}
                                       options={this.state.paymentModes}></MDBSelect>
                        </MDBCol>
                        <MDBCol>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker format="MMM D, YYYY" margin="normal"
                                                    id="receiving_date"  className={'form-control'}
                                                    label="Order Date" value={this.state.orderDate} onChange={this.handleOrderDate}
                                                    KeyboardButtonProps={{
                                                        "aria-label": "change date",
                                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </MDBCol>
                        <MDBCol>
                            <MDBSelect outline={true} className={'form-control'}
                                       label={"Cashier"} getValue={this.handleCashier}
                                       options={this.state.cashierList}></MDBSelect>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol >
                            <div className="input-group mb-3">
                                <MDBInput label="Search doctor by name, phone or e-mail"
                                          maxLength="100"
                                          background size={'lg'}
                                          type='text' onChange={this.fetchProvider}
                                          className={'form-control col grey-text p-0 font-weight-bold'}
                                          value={this.state.searchedProviderText}/>
                                <div className="input-group-append">
                                    <MDBBtn onClick={this.toggleProvider}>New Doctor</MDBBtn>
                                </div>
                            </div>
                            {(this.state.allSuggestedProvider.length > 0 && this.state.searchedProviderText) &&
                            <ul className="list-group list-group-flush">
                                {this.state.allSuggestedProvider.map((row, index) => (
                                    <li key={index} className="list-group-item">
                                        <MDBRow style={{"fontSize": "13px"}}>
                                            <MDBCol size={"6"}>
                                                {row.hasOwnProperty("name") && row.name &&
                                                <div>{row.name}</div>
                                                }
                                                {row.hasOwnProperty("email") && row.email &&
                                                <div>{row.email}</div>
                                                }
                                                {row.hasOwnProperty("name") && row.contactNo &&
                                                <div>{row.contactNo}</div>
                                                }
                                            </MDBCol>
                                            <MDBCol size={"3"}>
                                                <MDBBtn size={"sm"}
                                                        onClick={() => this.selectProvider(row)}>Select</MDBBtn>
                                            </MDBCol>
                                        </MDBRow>
                                    </li>
                                ))}
                            </ul>
                            }


                            {this.state.provider.hasOwnProperty("name") &&
                            <MDBCard style={{"width":"100%"}}>
                                <MDBCardBody>
                                    <MDBRow>
                                        <MDBCol size={"11"} className={"float-left"}>
                                            <div>Doctor Name: {this.state.provider.name}</div>
                                            {this.state.provider.contactNo &&
                                            <div>Doctor Phone: {this.state.provider.contactNo}</div>
                                            }
                                            {this.state.provider.email &&
                                            <div>Doctor Email: {this.state.provider.email}</div>
                                            }
                                        </MDBCol>
                                        <MDBCol size={"1"} className={"float-right"}>
                                            <span onClick={this.clearSelectedDoctor} className={"blue-text"} style={{"cursor":"pointer"}}>X</span>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCardBody>
                            </MDBCard>
                            }

                        </MDBCol>

                        <MDBCol size={"4"} >
                            <MDBInput label="Search customer by name, phone or e-mail" outline={true} maxLength="50"
                                      type='text'  onChange={this.handleCustomerSearch}
                                      value={this.state.searchedCustomerText}  />
                            {(this.state.allSuggestedUser.length > 0 && this.state.searchedCustomerText ) &&
                            <div className="card" >
                                <ul className="list-group list-group-flush">
                                    {this.state.allSuggestedUser.map((row, index) => (
                                        <li key={index} className="list-group-item">

                                            <MDBRow style={{"fontSize":"13px"}}>
                                                <MDBCol size={"2"}>
                                                    {(row.hasOwnProperty("profilePic") && row.profilePic ) &&

                                                                <img width={"40"} alt={""}
                                                                     src={config.JAVA_IMAGE_HOST + row.profilePic}/>

                                                    }
                                                    {(row.hasOwnProperty("profilePic") && !row.profilePic ) &&
                                                    <img width={"40"} alt={""} src={require('../../components/common/img/profile-pic-user.png')}/>
                                                    }
                                                </MDBCol>
                                                <MDBCol size={"6"}>
                                                    {row.hasOwnProperty("name") && row.name &&
                                                    <div>{row.name}</div>
                                                    }
                                                    {(!row.hasOwnProperty("name")  || (row.hasOwnProperty("name") && !row.name)) &&
                                                        <div>
                                                            {row.hasOwnProperty("first_name") &&
                                                            <span>{row.first_name}</span>
                                                            }

                                                            {row.hasOwnProperty("last_name") &&
                                                            <span> {row.last_name}</span>
                                                            }
                                                        </div>
                                                    }
                                                    {row.hasOwnProperty("email") && row.email &&
                                                    <div>{row.email}</div>
                                                    }

                                                    {(!row.hasOwnProperty("email")  || (row.hasOwnProperty("email") && !row.email)) &&
                                                    <b>
                                                        {row.hasOwnProperty("user_email") &&
                                                        <div>{row.user_email}</div>
                                                        }
                                                    </b>
                                                    }
                                                    {row.hasOwnProperty("name") && row.contactNo &&
                                                    <div>{row.contactNo}</div>
                                                    }
                                                    {(!row.hasOwnProperty("contactNo")  || (row.hasOwnProperty("contactNo") && !row.contactNo)) &&
                                                    <>
                                                        {row.hasOwnProperty("user_contact_no") &&
                                                        <div>{row.user_contact_no}</div>
                                                        }
                                                    </>
                                                    }
                                                </MDBCol>
                                                <MDBCol size={"3"}>
                                                    <MDBBtn size={"sm"} onClick={()=>this.selectCustomer(row)}>Select</MDBBtn>
                                                </MDBCol>
                                            </MDBRow>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            }

                            {this.state.user.hasOwnProperty("name") &&
                            <MDBCard style={{"width":"100%"}}>
                                <MDBCardBody>
                                    <MDBRow>
                                        <MDBCol className={"float-left"}>
                                            <div>Customer Name: {this.state.user.name}</div>
                                            {this.state.user.contactNo &&
                                            <div>Customer Phone: {this.state.user.contactNo}</div>
                                            }
                                            {(!this.state.expandDependent && this.state.selectedDependent.hasOwnProperty("relation")) &&
                                            <div>
                                                Patient: {(this.state.selectedDependent.first_name ? (this.state.selectedDependent.first_name+" "+this.state.selectedDependent.last_name) : (this.state.selectedDependent.name)) + " (" +this.state.selectedDependent.relation + ")"}
                                            </div>
                                            }
                                            {this.state.expandDependent &&
                                            <div>
                                                {this.state.dependents.length > 0 &&
                                                <MDBFormInline className={"mb-2"}>
                                                    {this.state.dependents.map((item, index) => (
                                                        <>
                                                            {item.relation === "SELF" &&
                                                            <MDBInput key={index}
                                                                      onClick={() => this.changeDependent(item.dependent_id)}
                                                                      checked={this.state.selectedDependent.dependent_id === item.dependent_id ? true : false}
                                                                      label={(item.first_name ? (item.first_name + " " + item.last_name) : (item.name))}
                                                                      type='radio' id={index} containerClass='mr-5'/>
                                                            }
                                                        </>
                                                    ))}
                                                </MDBFormInline>
                                                }
                                                Dependents:
                                                {this.state.dependents.length > 0 &&
                                                <MDBFormInline className={"mb-4"}>
                                                    {this.state.dependents.map((item, index) => (
                                                        <>
                                                            {item.relation !== "SELF" &&
                                                            <MDBInput key={index}
                                                                      onClick={() => this.changeDependent(item._id)}
                                                                      checked={this.state.selectedDependent._id === item._id ? true : false}
                                                                      label={(item.first_name ? (item.first_name + " " + item.last_name) : (item.name)) + " (" + item.relation + ")"}
                                                                      type='radio' id={index} containerClass='mr-5'/>
                                                            }
                                                        </>
                                                    ))}
                                                </MDBFormInline>
                                                }
                                            </div>
                                            }
                                        </MDBCol>
                                        {this.state.dependents.length > 1 &&
                                        <MDBCol size={"1"}>
                                            <span style={{"cursor": "pointer"}} onClick={this.expandCollapse}>
                                                {!this.state.expandDependent &&
                                                <MDBIcon icon="angle-double-down"/>
                                                }
                                                {this.state.expandDependent &&
                                                <MDBIcon icon="angle-double-up"/>
                                                }
                                            </span>
                                        </MDBCol>
                                        }
                                        <MDBCol size={"1"} className={"float-right"}>
                                            <span onClick={this.clearSelectedCustomer} className={"blue-text"} style={{"cursor":"pointer"}}>X</span>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol>
                                            <MDBBtn size={"sm"} color={"primary"} onClick={()=>{this.goToNewDependant()}}>Addd Dependent</MDBBtn>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCardBody>
                            </MDBCard>
                            }
                        </MDBCol>
                        <MDBCol size={"2"}><MDBBtn onClick={this.goToNewCustomer} size={"md"} color={"default"} className={"float-left mt-4"}>New Customer</MDBBtn></MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol className={"p-1 ml-3 mr-3"}>
                            <MDBInput label="Product" outline={false} maxLength="100"
                                      type='text'  onChange={this.fetchProduct}
                                      value={this.state.searchedProductText}  />
                            {(this.state.searchedProductList.length > 0 && this.state.searchedProductText) &&
                            <div className="card">
                                <ul className="list-group list-group-flush">
                                    {this.state.searchedProductList.map((row, index) => (
                                        <li key={index} className="list-group-item">
                                            <MDBRow style={{"fontSize":"13px"}}>
                                                    <MDBCol>
                                                        {(row.hasOwnProperty("imageUrl") && row.imageUrl.length > 0) &&
                                                        <>
                                                            {row.imageUrl.map((innerrow, index) => (
                                                                <>
                                                                    <img width={"100"} alt={""}
                                                                         src={config.IMAGE_HOST + innerrow.filePathThumb}/>
                                                                </>
                                                            ))}
                                                        </>
                                                        }
                                                        {((row.hasOwnProperty("imageUrl") && row.imageUrl.length === 0)) &&
                                                            <img alt={""} width={"100"} src={require('../../components/common/img/broken-img.jpg')}/>
                                                        }
                                                    </MDBCol>
                                                <MDBCol size={"4"}><b>{row.productName}</b> <span className={"blue-text"} style={{"cursor":"pointer"}} onClick={()=>this.editProductShortcut(row)}>Quick Edit</span>
                                                    <div>Mfg: {this.convertMfgOrExpiry(row.inventory.mfgDate)} - Exp: {this.convertMfgOrExpiry(row.inventory.expDate)}</div>
                                                    <div>Contains: 1 {this.calculateUnit(1, row )}</div>
                                                    <div>Loose Sale Allowed?:  {row.looseSaleAllowed}</div>

                                                    {row.looseSaleAllowed === "Y" &&
                                                    <div>Minimum Sale
                                                        Quantity: {row.minimumLooseSaleQuantity} {row.quantityUnit}</div>
                                                    }

                                                    {/*{(row.hasOwnProperty("productIngredients") && row.productIngredients.length > 0 ) &&*/}
                                                    {/*<div>Ingredients:&nbsp;*/}
                                                    {/*    {row.productIngredients.map((innerrow, index) => (*/}
                                                    {/*        <>*/}
                                                    {/*            <span>{index > 0 && <span>, </span>}{innerrow}</span>*/}
                                                    {/*        </>*/}
                                                    {/*    ))}*/}
                                                    {/*</div>*/}
                                                    {/*}*/}

                                                    {(row.hasOwnProperty("mfg") ) &&
                                                    <div>Manufacturer:&nbsp;<span>{row.mfg.manufacturer}</span></div>
                                                    }

                                                </MDBCol>
                                                <MDBCol size={"3"}>
                                                    {row.outOfStock === "N" &&
                                                    <div>
                                                        <span className={"green-text"}>In Stock</span>
                                                    </div>
                                                    }
                                                    {row.outOfStock === "Y" &&
                                                    <div>
                                                        <span className={"red-text"}>Out of Stock</span>
                                                    </div>
                                                    }
                                                    <div>
                                                        MRP: <span dangerouslySetInnerHTML={{__html: config.CURRENCY_HTML}}></span> { util.customRound(row.inventory.mrp)}
                                                    </div>
                                                    <div>
                                                        Qty: {row.inventory.quantity}
                                                    </div>
                                                    <div>
                                                        Batch: {row.inventory.batch}
                                                    </div>
                                                </MDBCol>
                                                <MDBCol size={"2"}>
                                                    <MDBBtn size={"sm"} onClick={()=>this.addItemToCartLocal(row)}>Add To Cart</MDBBtn>
                                                    <span style={{"cursor":"pointer"}} className={"p-2 mt-3"} onClick={this.closeSearch}><MDBIcon icon="times-circle" /></span>
                                                </MDBCol>
                                            </MDBRow>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            }
                        </MDBCol>
                    </MDBRow>
                    <div className="card" >
                        <div className="card-body" style={{"padding":"5px"}}>
                            <MDBTable small={true}>
                                <MDBTableHead>
                                    <tr>
                                        <th>Product</th>
                                        <th>Batch</th>
                                        <th>Type</th>
                                        <th>Expiry date</th>
                                        <th>MRP per unit</th>
                                        <th>GST (%)</th>
                                        <th>Quantity</th>
                                        <th>Discount (%)</th>
                                        <th>Subtotal</th>
                                        {this.state.prescriptionMedicineDetected &&
                                        <th>Patient</th>
                                        }
                                        {this.state.prescriptionMedicineDetected &&
                                        <th>Doctor</th>
                                        }
                                        <th>Action</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {this.state.carts.map((row, index) => (
                                        <tr key={index+"_cart"} className={"pt-1 mt-1"}>

                                            <td>{row.productName}</td>
                                            <td>{row.inventory.batch}</td>
                                            <td>
                                                {(row.hasOwnProperty("isMedicine") &&  !row.isMedicine) &&
                                                <span>General</span>
                                                }
                                                {(row.hasOwnProperty("isMedicine") &&  row.isMedicine) &&
                                                <span>
                                                    {row.hasOwnProperty("medicineType") &&
                                                    <span className={(row.medicineType.trim() === "Schedule H" || row.medicineType.trim() === "Schedule H1" || row.medicineType.trim() === "Schedule X" || row.medicineType.trim() === "PRESCRIPTION DRUGS" || row.medicineType.trim() === "PRESCRIPTION DRUG") ? "red-text" : "green-text" }>
                                                        {row.medicineType.trim() === "PRESCRIPTION DRUGS" &&
                                                        <span>Prescription Drug</span>
                                                        }
                                                        {row.medicineType.trim() !== "PRESCRIPTION DRUGS" &&
                                                        <span>{row.medicineType}</span>
                                                        }
                                                    </span>
                                                    }
                                                    {!row.hasOwnProperty("medicineType") &&
                                                    <span> | Not Specified</span>
                                                    }
                                                </span>
                                                }
                                                {(!row.hasOwnProperty("isMedicine")) &&
                                                <span>
                                                    {row.hasOwnProperty("medicineType") &&
                                                    <span className={(row.medicineType.trim() === "Schedule H" || row.medicineType.trim() === "Schedule H1" || row.medicineType.trim() === "Schedule X") ? "red-text" : "green-text" }> | {row.medicineType}</span>
                                                    }
                                                </span>
                                                }
                                            </td>
                                            <td>{this.convertMfgOrExpiry(row.inventory.expDate)}</td>
                                            {row.looseSaleAllowed === "Y" &&
                                            <td><span dangerouslySetInnerHTML={{__html: config.CURRENCY_HTML}}></span> { util.customRound(row.inventory.mrp / row.contentQuantity)}</td>
                                            }
                                            {row.looseSaleAllowed === "N" &&
                                            <td><span dangerouslySetInnerHTML={{__html: config.CURRENCY_HTML}}></span> {util.customRound(row.inventory.mrp)}</td>
                                            }
                                            <td>{row.inventory.gst}</td>
                                            <td>
                                                <div style={{"marginTop":"-20px"}}>
                                                <MDBInput style={{"width":"3rem"}} outline={true}  maxLength="5"
                                                          type='text'  onChange={this.handleQuantity(index)}
                                                          value={this.state.carts[index].quantity} />
                                                </div>
                                            </td>
                                            <td>
                                                <div style={{"marginTop":"-20px"}}>
                                                    <MDBInput style={{"width":"5rem"}} outline={true}  maxLength="5"  type='text'  onChange={this.handleDiscount(index)}  value={this.state.carts[index].discount} />
                                                </div>
                                            </td>
                                            <td><span dangerouslySetInnerHTML={{__html: config.CURRENCY_HTML}}></span> {util.customRound(row.subTotal)}</td>

                                            {(row.hasOwnProperty("medicineType")) &&
                                            <td size={"2"}>
                                                {(row.hasOwnProperty("medicineType") && (row.medicineType.trim() === "OTC" || row.medicineType.trim() === "Schedule H" || row.medicineType.trim() === "Schedule H1" || row.medicineType.trim() === "Schedule X"  || row.medicineType.trim() === "PRESCRIPTION DRUGS"))  &&
                                                <div>
                                                    {this.state.apiResultCame &&
                                                    <div>
                                                        {(this.state.carts[index].selectedDependent.hasOwnProperty("name") && this.state.carts[index].selectedDependent.name) &&
                                                        <div>
                                                            {this.state.carts[index].selectedDependent.name}
                                                            {!this.state.carts[index].showChangePatientOptions &&
                                                            <div className={"blue-text cursor-on p-1"}  style={{cursor: "pointer"}}
                                                                 onClick={() => this.initChangePatient(index)}><b>Change</b></div>
                                                            }
                                                            {this.state.carts[index].showChangePatientOptions &&
                                                            <div className={"blue-text cursor-on p-1"}  style={{cursor: "pointer"}}
                                                                 onClick={() => this.cancelChangePatient(index)}><b>Close</b></div>
                                                            }
                                                        </div>
                                                        }
                                                        {(this.state.carts[index].selectedDependent.hasOwnProperty("name") && !this.state.carts[index].selectedDependent.name) &&
                                                        <div >
                                                            {(this.state.carts[index].selectedDependent.hasOwnProperty("first_name") && this.state.carts[index].selectedDependent.first_name) &&
                                                            <span>
                                                                {this.state.carts[index].selectedDependent.first_name}
                                                            </span>
                                                            }
                                                            {(this.state.carts[index].selectedDependent.hasOwnProperty("last_name") && this.state.carts[index].selectedDependent.last_name) &&
                                                            <span>
                                                                {this.state.carts[index].selectedDependent.last_name}
                                                            </span>
                                                            }
                                                            {!this.state.carts[index].showChangePatientOptions &&
                                                            <div className={"blue-text cursor-on p-1"} style={{cursor: "pointer"}}
                                                                 onClick={() => this.initChangePatient(index)}><b>Change</b></div>
                                                            }
                                                            {this.state.carts[index].showChangePatientOptions &&
                                                            <div className={"blue-text cursor-on p-1"} style={{cursor: "pointer"}}
                                                                 onClick={() => this.cancelChangePatient(index)}><b>Close</b></div>
                                                            }
                                                        </div>
                                                        }
                                                        {this.state.carts[index].showChangePatientOptions &&
                                                        <div className={"card"} style={{position:"absolute", "zIndex":1, maxHeight:"10rem", overflowY:"scroll"}}>
                                                                {this.state.dependents.map((itemDL, indexDL) => (
                                                                    <div>
                                                                        <MDBInput key={indexDL} onClick={() => this.handleDependentChange(index, itemDL)}
                                                                                  checked={this.state.selectedDependent._id === itemDL._id ? true : false}
                                                                                  label={(itemDL.first_name ? (itemDL.first_name + " " + itemDL.last_name) : (itemDL.name))}  type='radio' id={indexDL} containerClass='mr-5'/>
                                                                    </div>
                                                                ))}
                                                        </div>
                                                        }
                                                    </div>
                                                    }
                                                </div>
                                                }
                                                {(!row.hasOwnProperty("medicineType")) &&
                                                <span>N/A</span>
                                                }
                                            </td>
                                            }
                                            {(row.hasOwnProperty("medicineType")) &&
                                            <td size={"2"}>
                                                {(row.hasOwnProperty("medicineType") && ( row.medicineType.trim() === "OTC" || row.medicineType.trim() === "Schedule H" || row.medicineType.trim() === "Schedule H1" || row.medicineType.trim() === "Schedule X" || row.medicineType.trim() === "PRESCRIPTION DRUGS")) &&
                                                <div>
                                                    {row.doctorDetails.name}

                                                    {(!this.state.carts[index].showChangeProviderOptions && row.doctorDetails.name) &&
                                                    <div className={"blue-text cursor-on p-1"} style={{cursor: "pointer"}}
                                                         onClick={() => this.initChangeProvider(index)}><b>Change</b></div>
                                                    }
                                                    {this.state.carts[index].showChangeProviderOptions &&
                                                    <div className={"blue-text cursor-on p-1"} style={{cursor: "pointer"}}
                                                         onClick={() => this.cancelChangeProvider(index)}><b>Close</b></div>
                                                    }

                                                    {this.state.carts[index].showChangeProviderOptions &&
                                                    <div className={"card"} style={{position:"absolute", "zIndex":1}}>

                                                        <MDBInput autoFocus={"true"} label="Search Doctor" outline={true}
                                                                  type='text' onChange={this.fetchProviderCart(index)}
                                                                  value={this.state.carts[index].searchedProviderText}/>

                                                        {(this.state.allSuggestedProviderCart.length > 0 && this.state.carts[index].searchedProviderText) &&
                                                        <div className="card">

                                                            <ul className="list-group list-group-flush">
                                                                {this.state.allSuggestedProviderCart.map((row, indexPr) => (
                                                                    <li key={indexPr} className="list-group-item">

                                                                        <MDBRow style={{"fontSize": "13px"}}>
                                                                            <MDBCol class={"col-md-9"}>

                                                                                {row.hasOwnProperty("name") && row.name &&
                                                                                <div>{row.name}</div>
                                                                                }
                                                                                {row.hasOwnProperty("email") && row.email &&
                                                                                <div>{row.email}</div>
                                                                                }
                                                                                {row.hasOwnProperty("name") && row.contactNo &&
                                                                                <div>{row.contactNo}</div>
                                                                                }
                                                                            </MDBCol>
                                                                            <MDBCol class={"col-md-3"}>
                                                                                <MDBBtn size={"sm"}
                                                                                        onClick={() => this.selectProviderCart(index, row)}>Select</MDBBtn>
                                                                                {/*<span style={{"cursor":"pointer"}} className={"p-2 mt-3"} onClick={this.closeSearch}><MDBIcon icon="times-circle" /></span>*/}
                                                                            </MDBCol>
                                                                        </MDBRow>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                        }

                                                    </div>
                                                            }

                                                </div>
                                                }
                                                {(!row.hasOwnProperty("medicineType")) &&
                                                <span>N/A</span>
                                                }
                                            </td>
                                            }
                                            <td>
                                                <div style={{"cursor":"pointer"}} onClick={()=>this.removeFromCart(index, row)}><MDBIcon icon="trash" /></div>
                                            </td>
                                        </tr>
                                    ))}
                                </MDBTableBody>
                            </MDBTable>
                        </div>
                    </div>

                    {this.state.carts.length > 0 &&
                    <MDBRow>
                        <MDBCol size={"4"}>
                            {(this.state.paymentMode === "CASH" || this.state.paymentMode === "CREDIT") &&
                            <MDBInput
                                disabled={(this.state.paymentMode === "CARD" || this.state.paymentMode === "NETBANKING" || this.state.paymentMode === "WALLET")}
                                label={"Amount Collected"} value={this.state.amountCollected}
                                onChange={this.handleAmountCollected}></MDBInput>
                            }
                        </MDBCol>
                        <MDBCol size={"4"}>
                            {(this.state.paymentMode === "CASH" || this.state.paymentMode === "CREDIT") &&
                            <MDBInput
                                disabled={(this.state.paymentMode === "CARD" || this.state.paymentMode === "NETBANKING" || this.state.paymentMode === "WALLET")}
                                label={"Change Return"} value={this.state.changeReturn}
                                onChange={this.handleChangeReturn}></MDBInput>
                            }
                        </MDBCol>
                        <MDBCol size={"4"}>
                            <MDBInput
                                disabled={(this.state.paymentMode === "CARD" || this.state.paymentMode === "NETBANKING" || this.state.paymentMode === "WALLET")}
                                label={"Paid Amount"} value={this.state.paidAmount}
                                onChange={this.handlePaidAmount}></MDBInput>
                        </MDBCol>
                    </MDBRow>
                    }

                    {this.state.carts.length > 0 &&
                    <MDBRow>
                        <MDBCol >
                            <MDBBtn disabled={this.state.totalAmount < 1} color="primary" className="float-right mr-2 ml-2" onClick={this.placeOfflineOrder}>
                                Place order
                            </MDBBtn>
                            <span className="float-right mt-3"><b>Total Amount: <span dangerouslySetInnerHTML={{__html: config.CURRENCY_HTML}}></span>  {util.customRound(this.state.totalAmount)}</b></span>
                        </MDBCol>
                    </MDBRow>
                    }
                    <MDBRow className={"p-2"}>
                        <MDBCol>
                            <MultipleDocsUpload buttonLabel={"Attach Prescription"}
                                               initUploadCallback={this.initUploadCallback}
                                               fileUploadCallback={this.fileUploadCallback}
                                               finishedSavingFile={this.state.finishedSaving}></MultipleDocsUpload>
                        </MDBCol>
                    </MDBRow>

                    <MDBModal isOpen={this.state.modalProvider} toggle={this.toggleProvider} size={"lg"}>
                        <MDBModalHeader toggle={this.toggleProvider}>Doctor</MDBModalHeader>
                        <MDBModalBody>
                            <MDBRow>
                                <AddDoctor addDoctorCompleted={this.addDoctorCompleted}/>
                            </MDBRow>
                        </MDBModalBody>
                    </MDBModal>

                    <MDBModal isOpen={this.state.modal} toggle={this.toggle} size={"lg"}>
                        <MDBModalHeader toggle={this.toggle}>Customer</MDBModalHeader>
                        <MDBModalBody>
                            <MDBRow>
                                <MDBCol className={"p-3"}>
                                    <MDBStepper form>
                                        <MDBStep form>
                                            <MDBBtn color={ this.state.formActivePanel1===1 ? "primary" : "default" } circle>
                                                1
                                            </MDBBtn>
                                            <p>Basic Info</p>
                                        </MDBStep>
                                        <MDBStep form>
                                            <MDBBtn color={ this.state.formActivePanel1===2 ? "primary" : "default" } circle>
                                                2
                                            </MDBBtn>
                                            <p>Address</p>
                                        </MDBStep>
                                    </MDBStepper>
                                    <form action="" method="post">
                                        <MDBRow>
                                            {this.state.formActivePanel1 === 1 && (
                                                <MDBCol md="12">
                                                    <MDBRow>
                                                        <MDBCol>
                                                            <MDBInput autoComplete={'off'} style={{"marginTop": "0px"}} onBlur={this.validateName}
                                                                      className={this.state.nameError ? "border-danger" : "mt-0 mb-0"}
                                                                      maxLength="100" type='text' label="Name *" autoFocus={this.calculateAutofocus(1)}
                                                                      onChange={this.handleName}
                                                                      value={this.state.name}/>
                                                        </MDBCol>
                                                    </MDBRow>
                                                    <MDBRow>
                                                        <MDBCol>
                                                            <MDBInput onBlur={() => this.validatePhone()}
                                                                      autoComplete={'off'}
                                                                      className={this.state.contactNoError ? "border-danger" : ""}
                                                                      maxLength={orgConfig.PHONE_NUMBER_LENGTH} type='text' label="Phone *"
                                                                      onChange={this.handleChangeContactNo}
                                                                      value={this.state.contactNo}/>
                                                        </MDBCol>
                                                    </MDBRow>
                                                    <MDBRow>
                                                        <MDBCol>
                                                            <MDBInput onBlur={() => this.validateEmail()}
                                                                      autoComplete={'off'}
                                                                      className={this.state.emailError ? "border-danger" : ""}
                                                                      maxLength="50" type='text' label="E-mail"
                                                                      onChange={this.handleChangeEmail}
                                                                      value={this.state.email}/>
                                                        </MDBCol>
                                                    </MDBRow>
                                                    <MDBFormInline className={"mb-4"}>
                                                        <MDBInput onClick={()=>this.changeGender("MALE")} checked={this.state.gender === "MALE" ? true : false}
                                                                  label='Male' type='radio' id='radio1' containerClass='mr-5'/>
                                                        <MDBInput onClick={()=>this.changeGender("FEMALE")} checked={this.state.gender === "FEMALE" ? true : false}
                                                                  label='Female' type='radio' id='radio2' containerClass='mr-5'/>
                                                        <MDBInput onClick={()=>this.changeGender("OTHER")} checked={this.state.gender === "OTHER" ? true : false}
                                                                  label='Other' type='radio' id='radio3' containerClass='mr-5'/>
                                                    </MDBFormInline>
                                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                                        <KeyboardDatePicker style={{"margintTop":"5px","marginLeft":"1px", "marginRight":"1px"}}

                                                                            format="MMM D, YYYY"
                                                                            margin="normal"
                                                                            id="receiving_date"
                                                                            label="Date of birth"
                                                                            value={this.state.dob}
                                                                            onChange={this.handleDOB}
                                                                            KeyboardButtonProps={{
                                                                                "aria-label": "change date",
                                                                            }}
                                                        />
                                                    </MuiPickersUtilsProvider>

                                                    <MDBBtn color="default" className="float-right" onClick={this.addUser}>
                                                        Create User
                                                    </MDBBtn>
                                                </MDBCol>
                                            )}

                                            {this.state.formActivePanel1 === 2 && (
                                                <AddAddress userId={this.state.user.user_id} user={this.state.user} dependentId={this.state.selectedDependent.dependent_id} openAddAddress={this.openAddAddress} fetchAllAddress={this.fetchAllAddress} />
                                            )}
                                        </MDBRow>
                                    </form>
                                </MDBCol>
                            </MDBRow>
                        </MDBModalBody>
                    </MDBModal>

                    <MDBModal isOpen={this.state.modalEditProductShortcut} toggle={this.toggleEditProductShortcut} size={"fluid"}>
                        <MDBModalHeader toggle={this.toggleEditProductShortcut}>Edit Product</MDBModalHeader>
                        <MDBModalBody>
                            <MDBRow>
                                <MDBCol className={"p-3"}>
                                    <EditProductShortcutComp user={this.state.user} editProductCompleted={this.editProductCompleted}  product={this.state.productShortcutTobeEdited} />
                                </MDBCol>
                            </MDBRow>
                        </MDBModalBody>
                    </MDBModal>

                    <MDBModal isOpen={this.state.modalDependent} toggle={this.toggleDependent} size={"fluid"}>
                        <MDBModalHeader toggle={this.toggleDependent}>Family & Friends</MDBModalHeader>
                        <MDBModalBody>
                            <MDBRow>
                                <MDBCol className={"p-3"}>
                                    <Dependents user={this.state.user} addDependentCompleted={this.addDependentCompleted}  dependentId={this.state.selectedDependent.dependent_id} />
                                </MDBCol>
                            </MDBRow>
                        </MDBModalBody>
                    </MDBModal>

                    <MDBModal isOpen={this.state.modalPdfInvoice} toggle={this.togglePdfInvoice} size={"lg"}>
                        <MDBModalHeader toggle={this.togglePdfInvoice}>Print Invoice</MDBModalHeader>
                        <MDBModalBody className={"d-flex justify-content-center"}>

                            {ready && (
                                <PDFViewer width={"700"} height={"900"}>

                                    <Document>
                                        <Page size="A4" style={{border: '1 solid #000', backgroundColor: 'white'}}>
                                            <View style={styles.table}>
                                                <View style={styles.tableRow}>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCellH1}>TAX INVOICE</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <View style={styles.tableCol2}>
                                                        <Text style={styles.tableCellH2}>Positra Pharmacy</Text>
                                                    </View>
                                                    <View style={styles.tableCol2}>
                                                        <Text style={styles.tableCell}>{this.state.selectedProviderAddress}</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <View style={styles.tableCol4}>
                                                        <Text style={styles.tableCell}>GSTIN</Text>
                                                        <Text style={styles.tableCell}>18AAICP6875B1Z5</Text>
                                                    </View>
                                                    <View style={styles.tableCol4}>
                                                        <Text style={styles.tableCell}>Invoice #</Text>
                                                        <Text style={styles.tableCell}>{this.state.activeOrder.orderCode}</Text>
                                                    </View>
                                                    <View style={styles.tableCol4}>
                                                        <Text style={styles.tableCell}>Invoice Date</Text>
                                                        <Text style={styles.tableCell}>{this.convertToReadbleDate(this.state.activeOrder.orderDate)}</Text>
                                                    </View>
                                                    <View style={styles.tableCol4}>
                                                        <Text style={styles.tableCell}>Transaction Mode</Text>
                                                        <Text style={styles.tableCell}>{this.state.activeOrder.paymentMode}</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCellH3}>Details of Customer</Text>
                                                    </View>
                                                </View>

                                                <View style={styles.tableRow}>
                                                    <View style={styles.tableCol1}>
                                                        {this.state.activeOrder.hasOwnProperty("customerDetails") &&

                                                        <Text style={{fontSize: "12", paddingLeft:5, marginTop:5, marginBottom:5}}>
                                                            Name: {this.state.activeOrder.customerDetails.name}
                                                        </Text>
                                                        }
                                                    </View>
                                                </View>
                                                {this.state.isActiveOrderAPrescriptionDrug === true &&
                                                <View style={styles.tableRow}>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCellH3}>Patient and Doctor Details</Text>
                                                    </View>
                                                </View>
                                                }

                                                {this.state.isActiveOrderAPrescriptionDrug === true &&
                                                <View style={styles.tableRow}>
                                                    <View style={styles.tableCol4}>
                                                        <Text style={styles.tableCell}>Medicine Name</Text>
                                                    </View>
                                                    <View style={styles.tableCol4}>
                                                        <Text style={styles.tableCell}>Medicine Type</Text>
                                                    </View>
                                                    <View style={styles.tableCol4}>
                                                        <Text style={styles.tableCell}>Patient Name</Text>
                                                    </View>
                                                    <View style={styles.tableCol4}>
                                                        <Text style={styles.tableCell}>Prescribed By</Text>
                                                    </View>
                                                </View>
                                                }
                                                {this.state.isActiveOrderAPrescriptionDrug === true &&
                                                <View>
                                                    {this.state.activeOrder.products.map((row, index) => (
                                                        <>
                                                            {(row.medicineType.trim === "Schedule H" || row.medicineType.trim() === "Schedule H1" || row.medicineType.trim() === "Schedule X" || row.medicineType.trim() === "PRESCRIPTION DRUGS" ||  row.medicineType.trim() === "PRESCRIPTION DRUG") &&
                                                            <View style={styles.tableRow}>
                                                                <View style={styles.tableCol4}>
                                                                    <Text
                                                                        style={styles.tableCell}>{row.productName}</Text>
                                                                </View>
                                                                <View style={styles.tableCol4}>
                                                                    <Text style={styles.tableCell}>
                                                                        {row.medicineType === "PRESCRIPTION DRUGS" &&
                                                                        <Text>Prescription Drug</Text>
                                                                        }
                                                                        {row.medicineType !== "PRESCRIPTION DRUGS" &&
                                                                        <Text>{row.medicineType}</Text>
                                                                        }
                                                                    </Text>
                                                                </View>
                                                                <View style={styles.tableCol4}>
                                                                    <Text style={styles.tableCell}>
                                                                        {row.patientDetails.name &&
                                                                        <Text>{row.patientDetails.name}</Text>
                                                                        }
                                                                        {(!row.patientDetails.name && row.patientDetails.first_name) &&
                                                                        <Text>{row.patientDetails.first_name + " " + row.patientDetails.last_name} </Text>
                                                                        }
                                                                    </Text>
                                                                </View>
                                                                <View style={styles.tableCol4}>
                                                                    <Text style={styles.tableCell}>
                                                                        {(row.hasOwnProperty("doctorDetails") && row.doctorDetails.hasOwnProperty("name")) &&
                                                                        <Text>{row.doctorDetails.name}</Text>
                                                                        }
                                                                    </Text>
                                                                </View>
                                                            </View>
                                                            }
                                                        </>
                                                    ))}
                                                </View>
                                                }
                                                <View style={styles.tableRow}>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCellH3}>Product Details</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <View style={styles.tableColP4}>
                                                        <Text style={styles.tableCell}>No.</Text>
                                                    </View>
                                                    <View style={styles.tableColProduct}>
                                                        <Text style={styles.tableCell}>Description of Goods</Text>
                                                    </View>
                                                    <View style={styles.tableColP5}>
                                                        <Text style={styles.tableCell}>HSN</Text>
                                                    </View>
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Expires</Text>
                                                    </View>
                                                    <View style={styles.tableColP4}>
                                                        <Text style={styles.tableCell}>Qty</Text>
                                                    </View>
                                                    <View style={styles.tableColP10}>
                                                        <Text style={styles.tableCell}>Batch</Text>
                                                    </View>
                                                    <View style={styles.tableColP10}>
                                                        <Text style={styles.tableCell}>MRP</Text>
                                                    </View>
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Discount Rate</Text>
                                                    </View>
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Discount Amount</Text>
                                                    </View>
                                                    <View style={styles.tableColP6}>
                                                        <Text style={styles.tableCell}>GST %</Text>
                                                    </View>
                                                    <View style={styles.tableColP9}>
                                                        <Text style={styles.tableCell}>GST Amount</Text>
                                                    </View>
                                                    <View style={styles.tableColP10}>
                                                        <Text style={styles.tableCell}>Amount</Text>
                                                    </View>
                                                </View>

                                                {this.state.activeOrder.products.map((row, index) => (
                                                    <View style={styles.tableRow}>
                                                        <View style={styles.tableColP4}>
                                                            <Text style={styles.tableCell}>
                                                                {index+1}
                                                            </Text>
                                                        </View>
                                                        <View style={styles.tableColProduct}>
                                                            <Text style={styles.tableCell}>{row.productName}</Text>
                                                        </View>
                                                        <View style={styles.tableColP5}>
                                                            <Text style={styles.tableCell}>{row.inventory.hsn}</Text>
                                                        </View>
                                                        <View style={styles.tableCol}>
                                                            <Text style={styles.tableCell}>{this.convertMfgOrExpiry(row.inventory.expDate)}</Text>
                                                        </View>
                                                        <View style={styles.tableColP4}>
                                                            <Text style={styles.tableCell}>{row.quantity}</Text>
                                                        </View>
                                                        <View style={styles.tableColP10}>
                                                            <Text style={styles.tableCell}>{row.inventory.batch}</Text>
                                                        </View>
                                                        <View style={styles.tableColP10}>
                                                            {row.looseSaleAllowed === "Y" &&
                                                            <Text style={styles.tableCell}>{config.CURRENCY_TEXT} {util.customRound(row.inventory.mrp / row.contentQuantity)}</Text>
                                                            }
                                                            {row.looseSaleAllowed === "N" &&
                                                            <Text style={styles.tableCell}>{config.CURRENCY_TEXT} {util.customRound(row.inventory.mrp)}</Text>
                                                            }
                                                        </View>
                                                        <View style={styles.tableCol}>
                                                            <Text style={styles.tableCell}>
                                                                {row.discount}
                                                            </Text>
                                                        </View>
                                                        <View style={styles.tableCol}>
                                                            <Text style={styles.tableCell}>{config.CURRENCY_TEXT} {this.calculateDiscountedAmount(row.inventory.mrp, row.quantity, row.discount, row.contentQuantity, row.looseSaleAllowed)}</Text>
                                                        </View>
                                                        <View style={styles.tableColP6}>
                                                            <Text style={styles.tableCell}>{row.inventory.gst}</Text>
                                                        </View>
                                                        <View style={styles.tableColP9}>
                                                            <Text style={styles.tableCell}>{config.CURRENCY_TEXT} {util.calculateGSTAmount(row.inventory.mrp, row.inventory.gst, row.quantity, row.contentQuantity, row.discount, row.looseSaleAllowed)}</Text>
                                                        </View>
                                                        <View style={styles.tableColP10}>
                                                            <Text style={styles.tableCell}>{config.CURRENCY_TEXT} {this.calculateSubTotal(row.inventory.mrp, row.quantity, row.discount, row.contentQuantity, row.looseSaleAllowed)}</Text>
                                                        </View>
                                                    </View>
                                                ))}

                                                <View style={styles.tableRow}>
                                                    <View style={styles.tableColP90}>
                                                        <Text style={styles.tableCellTotal}>Total</Text>
                                                    </View>
                                                    <View style={styles.tableColP10}>
                                                        {this.state.activeOrder.totalAmount &&
                                                        <Text
                                                            style={styles.tableCell}>{config.CURRENCY_TEXT} {util.customRound(this.state.activeOrder.totalAmount)}
                                                        </Text>
                                                        }
                                                    </View>
                                                </View>
                                            </View>

                                            <View style={{"paddingTop":10, width:"98%", position:"absolute", bottom:30, marginLeft:5, marginRight:5,  borderTop:"1 solid #000", }}>
                                                <Text style={{fontSize: "9"}}>
                                                    Notes:
                                                </Text>
                                                <Text style={{fontSize:"9"}}>
                                                    * MRP is inclusive of GST.
                                                </Text>
                                                <Text style={{fontSize:"9"}}>
                                                    * Goods once sold will not be taken back or exchanged.
                                                </Text>
                                                <Text style={{fontSize:"9"}}>
                                                    * Thank you for your business.
                                                </Text>
                                            </View>
                                        </Page>
                                    </Document>
                                </PDFViewer>
                            )}
                        </MDBModalBody>
                    </MDBModal>
                </MDBBox>
            </React.Fragment>
        );
    }
}
export default withRouter(OnlineOrder);
