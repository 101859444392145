import React from "react";
import {Link,withRouter} from "react-router-dom";
import {
    MDBContainer, MDBRow, MDBCardHeader, MDBCardBody, MDBCard, MDBCol,  MDBListGroup, MDBListGroupItem
} from 'mdbreact';
import UserStore from "../../stores/userStore";
import Moment from 'react-moment';
import config from "../../config";
import SimpleBackdrop from "../../components/common/overlay";

class ProductView extends React.Component {
    async componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({progressStatus: true});

        if(this.props.loadedOnPopup === true){
            let productId = this.props.productId;
            await this.setState({productId:productId, loadedOnPopup:true})
            await this.fetchData();
        } else {
            const { productId } = this.props.match.params;
            this.setState({productId:productId});
            await this.fetchData();
        }

        this.setState({progressStatus: false});
    }
    showHighlightedImage = (index) => {
        this.setState({selectedImageIndex:index})
    }

    fetchData=async ()=>{
        window.scrollTo(0, 0);
        let userData = await UserStore.fetchSessionData();
        //let data = await InventoryService.fetchProductById(userData,this.state.productId);
        let data = this.props.product
        this.setState({productData: data,editedCategories:data.categories});
        try{
            this.setState({amount:data.discount});
        }catch (e) {
            console.log("No discount field added.");
        }
        try{
            this.setState({maxQnty:data.maxQuantity});
        }catch (e) {
            this.setState({maxQnty:0});
        }
        try{
            this.setState({minQnty:data.minQuantity});
        }catch (e) {
            this.setState({minQnty:0});
        }
        try{
            this.setState({reorderQnty:data.reorderThreshold});
        }catch (e) {
            this.setState({reorderQnty:0});
        }
        try{
            this.setState({pickupInstruction:data.shippingDetails.pickupInstruction,shippingInstruction:data.shippingDetails.shippingInstruction,inPersonInstruction:data.shippingDetails.inPersonInstruction,inPerson:data.shippingDetails.inPerson, ship:data.shippingDetails.ship, pickup:data.shippingDetails.pickUp});
        }catch (e) {
            this.setState({inPerson:'N', ship:'N', pickup:"N"});
        }
        this.setState({topSelling:data.topSelling});
        this.setState({mrp:data.maxRetailPrice});
        this.setState({outOfStock:data.outOfStock});
        try {
            if(data.imageUrl.length > 0) {
                this.setState({existingImageUrl: data.imageUrl});
                let lb = []
                for(let i = 0; i < data.imageUrl.length; i++){
                    let item = {
                        src: config.IMAGE_HOST+data.imageUrl[i].filePathOriginal
                    }
                    lb.push(item);
                }
                this.setState({"lightBoxImages":lb, "imageActionNeeded":"Update"})
            } else {
                this.setState({existingImageUrl:[]});
                this.setState({"imageActionNeeded":"Add"})
            }
        } catch (e) {
            this.setState({existingImageUrl:[]});
            this.setState({"imageActionNeeded":"Add"})
        }
    }
    constructor(props) {
        super(props);
        this.state = {
            productId:'',
            activeItem:'1',
            progressStatus:false,
            productData:{categories:[], imageUrl: []},
            categories:[],
            editedCategories:[],
            updateProgress:false,
            imageUrl:[],
            existingImageUrl:[],
            progressStatusFileUpload:false,
            imageActionNeeded:"",
            finishedSaving:"init",
            deleteMode:false,
            activeImage:{
                index:0,
                thumb:""
            },
            modalDelImage:false,
            modalDelete:false,
            selectedImageIndex:0,
            amount:0,
            topSelling:'N',
            mrp:0,
            outOfStock:'',
            maxQnty:0,
            minQnty:0,
            reorderQnty:0,
            inPerson:'N',
            ship:'N',
            pickup:"N",
            inPersonInstruction:"",
            shippingInstruction:"",
            pickupInstruction:"",
            loadedOnPopup:false,
        }
    }

    render() {
        return (
            <React.Fragment>

                < SimpleBackdrop status={(this.state.progressStatus === true || this.state.updateProgress === true)?true:false}/>
                {this.state.progressStatus === false &&
                <MDBContainer>
                    <MDBCard className={"mt-2"}>
                        <MDBCardHeader>
                            <span className={"float-left"}>Product Details</span>


                        </MDBCardHeader>
                        <MDBCardBody>

                                <MDBContainer >
                                    <MDBRow>
                                        <MDBCol size={"12"}>
                                            <h3>{this.state.productData.productName}</h3>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol size={"1"}>
                                            {this.state.existingImageUrl.map((row, index) => (
                                                <div className={"float-left m-1"}>
                                                    {((row.filePathThumb.indexOf(".png") > 0) || (row.filePathThumb.indexOf(".jpg") > 0) || (row.filePathThumb.indexOf(".jpeg") > 0)) &&
                                                    <div className={(this.state.selectedImageIndex === index) ? "border-info center" : "center"} style={{"border":"2px solid #CCC",width: "55px", height: "55px"}}>
                                                        <img  onClick={()=>this.showHighlightedImage(index)} src={config.IMAGE_HOST + row.filePathThumb} className={"float-left"} style={{maxWidth: "50px", maxHeight: "50px"}} alt={""}/>
                                                    </div>
                                                    }
                                                </div>
                                            ))}
                                        </MDBCol>
                                        <MDBCol size={"6"}>
                                            {(this.state.existingImageUrl.length > 0) &&
                                            <img src={config.IMAGE_HOST + this.state.existingImageUrl[this.state.selectedImageIndex].filePathOriginal} className={"float-left"} style={{maxWidth: "500px", maxHeight: "500px"}} alt={""}/>
                                            }
                                        </MDBCol>

                                    </MDBRow>
                                </MDBContainer>

                            <MDBListGroup style={{ width: "100%" }}>
                                <MDBListGroupItem>
                                    <span><b>Description</b>: </span>
                                    <span dangerouslySetInnerHTML={{__html: this.state.productData.description}}></span>

                                </MDBListGroupItem>
                                <MDBListGroupItem>
                                    <span><b>Highlights</b>: </span>
                                    <span dangerouslySetInnerHTML={{__html: this.state.productData.highlight}}></span>

                                </MDBListGroupItem>
                                <MDBListGroupItem>
                                    <span><b>How to use</b>: </span>
                                    <span dangerouslySetInnerHTML={{__html: this.state.productData.howTouse}}></span>

                                </MDBListGroupItem>
                                <MDBListGroupItem>
                                    <span><b>Ingredients</b>: </span>
                                    <span dangerouslySetInnerHTML={{__html: this.state.productData.productIngredients}}/>
                                </MDBListGroupItem>
                                <MDBListGroupItem>
                                    <span><b>Side effects</b>: </span>
                                    <span dangerouslySetInnerHTML={{__html: this.state.productData.productSideEffects}}/>
                                </MDBListGroupItem>
                                <MDBListGroupItem>
                                    <span><b>Uses</b>: </span>
                                    <span dangerouslySetInnerHTML={{__html: this.state.productData.productUses}}>
                                        </span>
                                </MDBListGroupItem>
                                <MDBListGroupItem>
                                    <span><b>Categories</b>: </span>
                                    <span>
                                            {this.state.productData.categories.length < 1 &&
                                            <span>Not yet linked</span>
                                            }
                                        {this.state.productData.categories.map((item, idx) => (
                                            <div key={idx}>{item.categoryName}</div>
                                        ))}
                                        </span>
                                </MDBListGroupItem>
                                <MDBListGroupItem>
                                    <span><b>Status</b>: </span>
                                    <span>
                                            {this.state.productData.isActive==='Y'?"Active":'Disabled'}
                                        </span>
                                </MDBListGroupItem>
                                <MDBListGroupItem>
                                    <span><b>Is it top selling?</b>: </span>
                                    <span>
                                            {this.state.productData.topSelling==='Y'?"Yes":'No'}
                                        </span>
                                </MDBListGroupItem>
                                <MDBListGroupItem>
                                    <span><b>Created By</b>: </span>
                                    <span>
                                            {this.state.productData.createdBy}
                                        </span>
                                </MDBListGroupItem>
                                <MDBListGroupItem>
                                    <span><b>Created on</b>: </span>
                                    <span>
                                            <Moment format="MMM D, YYYY" withTitle>
                                                {this.state.productData.createdAt}
                                            </Moment>
                                        </span>
                                </MDBListGroupItem>

                            </MDBListGroup>

                        </MDBCardBody>
                    </MDBCard>
                </MDBContainer>
                }
            </React.Fragment>
        );
    }
}
export default withRouter(ProductView);
