import {
  MDBBox,
  MDBBtn,
  MDBCol,
  MDBInput,
  MDBRow,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOption,
  MDBSelectOptions,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBCard,
} from "mdbreact";
import React from "react";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import UserStore from "../../../stores/userStore";
import Barcode from "react-barcode";
import { InputLabel } from "@material-ui/core";
import { Select, MenuItem, FormControl } from "@material-ui/core";
import TransferStockService from "../../../services/transferStockService";
import "../../../App.css";
const delay = (ms) => new Promise((res) => setTimeout(res, ms));

class TransferStockCmp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      barCode: "",
      isLoading: false,
      userData: {},
      productData: [],
      getBarCode: (new Date().getTime() + 122).toString(),
      centerFrom: "",
      centerTo: "",
      locationList: [],
      filteredLocationList: [],
      filtered: false,
    };
  }
  async componentDidMount() {
    let userData = await UserStore.fetchSessionData();
    this.setState({ userData });

    if (this.state.isLoading === false) {
      this.setState({ isLoading: true });
      let list = await TransferStockService.fetchLocations(
        userData.sessionToken
      );

      this.setState({ isLoading: false });
      if (list === false) {
        toast.error("Something went wrong while fetching the locations list.");
      } else {
        this.setState({ locationList: list, filteredLocationList: list });
      }
    }
  }

  searchByBarCode = async () => {
    await delay(500);
    if (this.state.barCode !== "" && this.state.isLoading === false) {
      this.setState({ isLoading: true });
      let data = await TransferStockService.fetchDetailsByBarCodeProducts(
        this.state.userData.sessionToken,
        this.state.barCode
      );

      this.setState({ isLoading: false });
      if (data === false) {
        toast.error(
          "Something went wrong while fetching the data by barCode number."
        );
      } else {
        this.barodeExistenceAndAddToList(data);
      }
    }
  };

  // async componentDidMount() {

  // };

  barodeExistenceAndAddToList = async (item) => {
    let arrayData = [...this.state.productData];
    if (item != undefined && item?.quantity == 1) {
      arrayData.push(item);
      this.setState({ productData: arrayData, barCode: "" });
    }
    if (item != undefined && item?.quantity == 0) {
      return toast.error("Please reset the product");
    }
    if (item != undefined && item?.quantity > 1) {
      return toast.error("This can't be processed as the barcode has more than 1 product associated with it");
    }
    if (item != undefined && item?.quantity < 0) {
      return toast.error("This product is not available");
    }
  };

  removeItemFromList = (index) => {
    let newProducData = [...this.state.productData];
    newProducData.splice(index, 1);
    this.setState({ productData: newProducData });
  };

  toCopy = (getBarCode) => {
    navigator.clipboard.writeText(this.state.getBarCode.toString().trim());
    toast.success(
      "Barcode is copied successfully. Copied barcode is- " +
        this.state.getBarCode.toString().trim()
    );
  };

  resetBarCode = () => {
    let newBarcode = new Date().getTime() + 122;
    this.setState({ getBarCode: newBarcode.toString() });
  };

  transferStock = async () => {
    if (this.state.getBarCode === "") {
      toast.error('Please generate "Barcode".');
      return false;
    }
    if (this.state.centerFrom === "") {
      toast.error('Please select "Fulfillment Center From"');
      return false;
    }
    if (this.state.centerTo === "") {
      toast.error('Please select "Fulfillment Center To"');
      return false;
    }
    let payload = {
      barCode: this.state.getBarCode.toString(),
      locationId: this.state.centerTo,
      fromLocationId: this.state.centerFrom,
      inventoryIds: this.state.productData.map((item) => ({
        inventoryId: item._id,
        quantity: 1,
      })),
    };
    let data = await TransferStockService.transferProductSave(
      this.state.userData.sessionToken,
      payload
    );
    this.setState({ progressStatus: false });
    if (data) {
      toast.success("The product transfered successfully.");
      this.setState({ productData: [] });

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      toast.error("Something went wrong while tranferring the product.");
    }
  };

  handleCenterFromChange = (e) => {
    const selectedCenterFrom = e.target.value;
    const filteredLocationList = this.state.locationList.filter(
      (location) => location._id !== selectedCenterFrom
    );
    this.setState({
      centerFrom: selectedCenterFrom,
      centerTo: "",
      filteredLocationList,
    });
  };

  handleCenterToChange = (e) => {
    const selectedCenterTo = e.target.value;
    this.setState({ centerTo: selectedCenterTo });
  };

  render() {
    return (
      <React.Fragment>
        <MDBBox className={"min-vh-100 mx-5 mb-5"}>
          <MDBRow>
            <MDBCol className={"m-2"}>
              <MDBRow>
                <div className={"col-md-3"}>
                  <MDBInput
                    label={"Scan barcode"}
                    value={this.state.barCode}
                    getValue={(e) => {
                      this.setState({ barCode: e });
                    }}
                    onChange={() => {
                      this.searchByBarCode();
                    }}
                    id={"barCode"}
                  />
                </div>
              </MDBRow>
            </MDBCol>
          </MDBRow>
          <MDBCard>
            <MDBBox>
              <MDBTable>
                <MDBTableHead>
                  <tr>
                    <th>Product Name</th>
                    <th>Batch</th>
                    <th>Qty</th>
                    <th>Mfg</th>
                    <th>Exp</th>
                    <th>MRP(₹)</th>
                    <th>Price without gst(₹)</th>
                    <th>Price with gst(₹)</th>
                    <th>GST(%)</th>
                    <th></th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody search={true}>
                  {this.state.productData.length === 0 ? (
                    <tr>
                      <td colSpan="12" style={{ textAlign: "center" }}>
                        No data
                      </td>
                    </tr>
                  ) : (
                    this.state.productData.map((item, index) => (
                      <tr key={index}>
                        <td className={"table-no-gap"}>{item.productName}</td>

                        <td className={"table-no-gap"}>{item.batch}</td>
                        <td className={"table-no-gap"}>{item.quantity}</td>
                        <td className={"table-no-gap"}>{item.mfgDate}</td>
                        <td className={"table-no-gap"}>{item.expDate}</td>
                        <td className={"table-no-gap"}>{item.mrp}</td>
                        <td className={"table-no-gap"}>{item.priceNogst}</td>
                        <td className={"table-no-gap"}>{item.priceWgst}</td>
                        <td className={"table-no-gap"}>{item.gst}</td>
                        <td className={"table-no-gap"} style={{ width: "2%" }}>
                          <button
                            onClick={() => this.removeItemFromList(index)}
                          >
                            Remove
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </MDBTableBody>
              </MDBTable>
            </MDBBox>
            {this.state.productData.length > 0 && (
              <MDBBox>
                <MDBRow>
                  <div className={"col-3 ml-2 mt-1"}>
                    <FormControl fullWidth>
                      <InputLabel>Select Fulfillment Center From</InputLabel>
                      <Select
                        value={this.state.centerFrom}
                        onChange={(e) => this.handleCenterFromChange(e)}
                        className="selectBox"
                        style={{ marginTop: "22px" }}
                      >
                        {this.state.locationList.map((location, index) => (
                          <MenuItem key={index} value={location._id}>
                            {location.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                  <div className={"col-3 mt-1"}>
                    <FormControl fullWidth>
                      <InputLabel>Select Fulfillment Center To</InputLabel>
                      <Select
                        value={this.state.centerTo}
                        onChange={(e) => this.handleCenterToChange(e)}
                        className="selectBox"
                        style={{ marginTop: "22px" }}
                      >
                        {this.state.filteredLocationList.map(
                          (location, index) => (
                            <MenuItem key={index} value={location._id}>
                              {location.name}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </div>

                  <div className={"col-md"}>
                    <MDBInput
                      value={this.state.getBarCode}
                      label={"Bar code"}
                      maxLength={30}
                      style={{ padding: "15px 15px" }}
                    />
                    <MDBBtn
                      onClick={() => {
                        this.resetBarCode();
                      }}
                      size={"sm"}
                    >
                      Reset
                    </MDBBtn>
                    <MDBBtn
                      onClick={() => {
                        this.toCopy(this.state.getBarCode);
                      }}
                      size={"sm"}
                    >
                      Copy
                    </MDBBtn>
                  </div>
                  <div className={"col-md"}>
                    <MDBBox ref={(el) => (this.componentRef = el)}>
                      <Barcode value={this.state.getBarCode}></Barcode>
                    </MDBBox>
                  </div>
                </MDBRow>
                <MDBRow>
                  <div
                    className="d-grid  mx-auto"
                    style={{ marginTop: "30px", marginBottom: "20px" }}
                  >
                    <MDBBtn onClick={this.transferStock}>Save</MDBBtn>
                  </div>
                </MDBRow>
              </MDBBox>
            )}
          </MDBCard>
        </MDBBox>
      </React.Fragment>
    );
  }
}

export default withRouter(TransferStockCmp);
