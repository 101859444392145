import React from "react";
import { withRouter } from "react-router-dom";
import {
    MDBContainer,
    MDBCardHeader,
    MDBFormInline,
    MDBBtn,
    MDBCardBody,
    MDBCard,
    MDBModalHeader,
    MDBModalBody,
    MDBModal,
    MDBModalFooter,
    MDBDataTable
} from 'mdbreact';
import UserStore from "../../stores/userStore";
import {toast} from "react-toastify";
import dataTableConfig from "../quantity-unit/dataTableQuantityUnit";
import PharmacyService from "../../services/pharmacyService";
import SimpleBackdrop from "../common/overlay";
import UpdateQuantityUnit from "../../components/quantity-unit/editQuantityUnit"

class ManageQuantityUnit extends React.Component {
    async componentDidMount() {
        await this.fetchData();
    }
    fetchData=async ()=>{
        await this.setState({disableRetreatAfterSorting:true});
        window.scrollTo(0, 0);
        let userData = await UserStore.fetchSessionData();
        let data=[];
        if(!window.qntyUnits || window.qntyUnits===null) {
            this.setState({progressStatus: true});
            data = await PharmacyService.fetchQuantityUnits(userData, this.state.page, this.state.pageSize, this.state.type);
            window.qntyUnits=data;
        }else{
            data=window.qntyUnits;
        }
        this.setState({progressStatus: false});
        if(data===false){
            toast.error("We are facing some issue. Please try again later.");
        }else{
            this.setState({quantityUnits: data, quantityUnitApiResultCame:true});
            let dataTablePayload={
                columns:dataTableConfig.quantityUnit,
                rows:data.map((item, index) => {
                    item["handleEdit"]=<div color="primary" className={"blue-text btn-link cursor-pointer"} size="sm" onClick={()=>{this.onEdit(item)}}>Edit</div>;
                    item["handleStatus"]=<div color="primary" className={"blue-text btn-link cursor-pointer"} size="sm" onClick={()=>{this.initChangeStatus(item)}}>{(item.status === "Y") ? "Deactivate" : "Activate"}</div>;
                    return item;
                })
            };
            this.setState({data:dataTablePayload});
        }
        this.syncData();
        await this.setState({disableRetreatAfterSorting:false});
    }
    syncData=async()=>{
        await this.setState({disableRetreatAfterSorting:true});
        let userData = await UserStore.fetchSessionData();
        let data=[];
        data = await PharmacyService.fetchQuantityUnits(userData, this.state.page, this.state.pageSize, this.state.type);
        window.qntyUnits=data;
        if(data===false){
            toast.error("We are facing some issue. Please try again later.");
        }else{
            this.setState({quantityUnits: data, quantityUnitApiResultCame:true});
            let dataTablePayload={
                columns:dataTableConfig.quantityUnit,
                rows:data.map((item, index) => {
                    item["handleEdit"]=<div color="primary" className={"blue-text btn-link cursor-pointer"} size="sm" onClick={()=>{this.onEdit(item)}}>Edit</div>;
                    item["handleStatus"]=<div color="primary" className={"blue-text btn-link cursor-pointer"} size="sm" onClick={()=>{this.initChangeStatus(item)}}>{(item.status === "Y") ? "Deactivate" : "Activate"}</div>;
                    return item;
                })
            };
            this.setState({data:dataTablePayload});
        }
        await this.setState({disableRetreatAfterSorting:false});
    }
    constructor(props) {
        super(props);
        this.state = {
            progressStatus:false,
            type:"all",
            page:1,
            pageSize:10,
            quantityUnits:[],
            data: {},
            modal:false,
            activeQuantityUnit:{},
            activeQuantityUnitId:0,
            quantityUnitApiResultCame:false,
            modalStatus:false,
            statusActionName:"",
            modalEdit:false,
            disableRetreatAfterSorting:false
        }
    }
    toggleEdit = () => {
        this.setState({
            modalEdit: !this.state.modalEdit
        });
    }
    onEditComplete = (row) => {
        this.toggleEdit()
        this.fetchData();
    }
    // This triggers from MDBDataTable, aim is to redirect to edit page
    onEdit =  (row) => {
        this.setState({activeQuantityUnit: row})
        this.toggleEdit()
    }
    onDelete=(row)=>{
        this.setState({"activeQuantityUnit":row,"activeQuantityUnitId":row._id})
        this.toggle();
    }
    onUpdate=(item)=>{
        this.props.history.push('/update-quantity-unit/'+item._id);
    }
    goToNew =()=>{
        this.props.history.push('/add-quantity-unit');
    }
    // This toggle opens or closes the custom lightbox
    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    }
    deleteQuantityUnit= async () => {
        let userData=await UserStore.fetchSessionData();
        this.setState({progressStatus:true});
        let res =await PharmacyService.deleteQuantityUnit(this.state,userData);
        this.setState({progressStatus:false});
        this.toggle()
        this.setState({ activeQuantityUnitId:0,  activeQuantityUnit: ""});
        if(res===false){
            toast.error("Something went wrong.");
            return true;
        }else{
            toast.success("Deleted successfully.");
            this.fetchData();
        }
    }
    initChangeStatus = (row) => {
        this.setState({ activeQuantityUnitId:row._id, statusAction: (row.status === "Y") ? "deactivate" : "activate", statusActionName: row.quantityUnit});
        this.toggleStatus();
    }
    updateStatus = async () => {
        let userData=await UserStore.fetchSessionData();
        this.setState({progressStatus:true});
        let status = (this.state.statusAction === "activate") ? "Y" : "N";
        let res =await PharmacyService.updateQuantityUnitStatus(this.state,status,userData);
        this.setState({progressStatus:false});
        this.toggleStatus()
        this.setState({ activeQuantityUnitId:0, statusAction: "", statusActionName: ""});
        if(res===false){
            toast.error("Something went wrong.");
            return true;
        }else{
            toast.success("Category status updated successfully.");
            this.fetchData();
        }
    }
    toggleStatus = () => {
        this.setState({
            modalStatus: !this.state.modalStatus
        });
    }
    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.progressStatus}/>
                <MDBContainer className={'w-100 mw-100'}>
                    <MDBCard className={"mt-2"}>
                        <MDBCardHeader>
                            <span className={"float-left title"}>Product Quantity Units</span>
                            <MDBFormInline className="float-right md-form mr-auto m-0">
                                <MDBBtn color="default" size="sm" onClick={this.goToNew} className="mr-auto" >
                                    Add Quantity Unit
                                </MDBBtn>
                            </MDBFormInline>
                        </MDBCardHeader>
                        <MDBCardBody>
                            {(this.state.quantityUnitApiResultCame && this.state.quantityUnits.length < 1) &&
                            <div className={"no-data-container"}>No Quantity Unit found.</div>
                            }
                            {this.state.quantityUnits.length > 0 &&
                            // <MDBDataTableV5
                            //     hover
                            //     entriesOptions={[10,15, 20, 25]}
                            //     entries={10}
                            //     pagesAmount={4}
                            //     data={this.state.data}
                            //     materialSearch
                            //     searchTop
                            //     searchBottom={false}
                            //     barReverse
                            //     striped
                            // />
                            <MDBDataTable
                                striped

                                data={this.state.data}
                                materialSearch
                                noBottomColumns
                                hover
                                disableRetreatAfterSorting={this.state.disableRetreatAfterSorting}
                            />
                            }
                        </MDBCardBody>
                    </MDBCard>
                    <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
                        <MDBModalHeader toggle={this.toggle}>Delete Quantity Unit</MDBModalHeader>
                        <MDBModalBody>
                            Are you sure you want to delete retail package {this.state.activeQuantityUnit}?
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn disabled={this.state.progressStatus} color="primary" onClick={this.deleteQuantityUnit}>Delete</MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>
                    <MDBModal isOpen={this.state.modalStatus} toggle={this.toggleStatus}>
                        <MDBModalHeader toggle={this.toggleStatus}>Update Quantity Unit Status</MDBModalHeader>
                        <MDBModalBody>
                            Are you sure you want to {this.state.statusAction} quantity unit {this.state.statusActionName}?
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn onClick={this.updateStatus} disabled={this.state.progressStatus}>{this.state.statusAction}</MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>
                    <MDBModal isOpen={this.state.modalEdit} toggle={this.toggleEdit} size={"fluid"}>
                        <MDBModalHeader toggle={this.toggleEdit}></MDBModalHeader>
                        <MDBModalBody className={"float-center"}>
                            <UpdateQuantityUnit loadedOnPopup={true} onEditComplete={this.onEditComplete}  quantityUnitId={this.state.activeQuantityUnit._id}/>
                        </MDBModalBody>
                    </MDBModal>
                </MDBContainer>
            </React.Fragment>
        );
    }
}
export default withRouter(ManageQuantityUnit)


