import React from "react";
import InventoryService from "../../services/inventoryService";
import UserStore from "../../stores/userStore";
import {
    MDBBtn, MDBSelect, toast, MDBModal, MDBModalBody
} from "mdbreact";
import Moments from "moment";
import utility from "../../utility/utility";
import {withRouter} from "react-router-dom";
import MaterialTable from "material-table";
import SimpleBackdrop from "../common/overlay";

class ListProductsByCategory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryList: [], productList: [],
            userData: [],
            selectedCategory: [],
            isLoading: false,
            pageSize: 10,
            modalCategory: false,
            activeItem: null,
        }
    }

    async componentDidMount() {
        let userData = UserStore.fetchSessionData()
        this.setState({userData})
        let categoryList = []

        if (window.productCategoryList && window.productCategoryList !== null) {
            categoryList = window.productCategoryList
        } else {
            categoryList = await InventoryService.fetchGetData(userData.sessionToken, "FETCH_CATEGORY") //ignoring else case
            window.productCategoryList = categoryList
        }
        this.prepareList(categoryList)
    }

    prepareList = (productCategoryList) => {
        let tempList = []
        for (let item of productCategoryList) {
            tempList.push({text: item.categoryName, value: item._id})
        }
        this.setState({categoryList: tempList})
    }
    fetchProductsByCategory = async () => {
        this.setState({isLoading: true})
        let res = await InventoryService.fetchProductsByCategoryById(this.state.selectedCategory[0].toString())
        if (res) {
            this.setState({productList: res});
        } else {
            toast.error("Something went wrong while fetching the products by category.")
        }
        this.setState({isLoading: false})
    }
    handleCategory = async (val) => {
        await this.setState({selectedCategory: val})
        if (this.state.selectedCategory.length > 0) {
            await this.fetchProductsByCategory()
        }
    }
    setActiveItem = (item, section) => {
        this.setState({activeItem: item})
        this.toggle(section)
    }
    toggle = (section) => {
        this.setState({["modal" + section]: !this.state["modal" + section]})
    }
    disassociateProductFromCat = async () => {
        if (this.state.activeItem !== null) {
            this.setState({isLoading: true})
            let res = await InventoryService.disassociateProductFromCategory(this.state.userData.sessionToken, this.state.activeItem._id, this.state.selectedCategory[0])
            this.setState({isLoading: false})
            if (res) {
                toast.success("Product disassociated successfully.")
                this.toggle("Category")
                await this.fetchProductsByCategory()
            } else {
                toast.error("Something went wrong while disassociating a product from a category.")
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.isLoading}/>
                <div className={"col-12 row mx-0"}>
                    <div className={"col-md-12 row m-0 p-0"}>
                        <div className={"col-md-3 p-2"}>
                            <b>Category</b>
                            <MDBSelect search selected="Select Category"
                                       options={this.state.categoryList}
                                       getValue={this.handleCategory}
                                       className={' col grey-text p-0 font-weight-bold'}
                            />
                        </div>
                        <div className={"col-md-3"}></div>
                        <div className={"col-md-6 text-right"}>
                            <MDBBtn size={'md'} disabled={this.state.isLoading} className={"mt-5"}
                                    onClick={() => {
                                        this.props.history.push("/add-product-to-category")
                                    }}>
                                Add
                            </MDBBtn>
                        </div>
                    </div>
                    <MaterialTable
                        columns={[
                            {title: 'Product Name', field: 'productName'},
                            {title: 'Top Selling', field: 'topSelling'},
                            {title: 'Retail Package', field: 'retailPackage'},
                            {title: 'Package Quantity', field: 'contentQuantity'},
                            {title: 'Packaging Unit', field: 'quantityUnit'},
                            {title: 'Loose', field: 'looseSaleAllowed'},
                            {title: 'Loose Sale Qty', field: 'minimumLooseSaleQuantity'},
                            {title: 'Medicine Type', field: '', render: rowData => utility.getMedicineType(rowData)},
                            {
                                title: 'Created On', field: '',
                                render: rowData => Moments(rowData.createdAt).format("MMM D, YYYY hh:mm A")
                            },
                            {
                                title: 'Action', field: '',
                                render: rowData => <span onClick={() => this.setActiveItem(rowData, "Category")}
                                                         className={"text-primary cursor-pointer"}>Disassociate</span>
                            }
                        ]}
                        data={this.state.productList}
                        options={{
                            padding: "dense",
                            showTitle: false,
                            pageSize: this.state.pageSize,
                            pageSizeOptions: [10, 50, 100],
                            tableLayout: "fixed",
                        }}
                    />
                </div>
                {this.state.activeItem !== null &&
                <MDBModal isOpen={this.state.modalCategory} toggle={() => this.toggle("Category")}>
                    <MDBModalBody>
                        <p><h6>Are you sure, you want to disassociate <b>"{this.state.activeItem.productName}"</b> from
                            this
                            category ?</h6></p>
                        <br/>
                        <div className={"row text-center"}>
                            <div className={"col"}><MDBBtn color="primary" outline
                                                           onClick={() => this.toggle("Category")}>No</MDBBtn></div>
                            <div className={"col"}><MDBBtn color="primary"
                                                           onClick={() => this.disassociateProductFromCat()}>Yes</MDBBtn>
                            </div>
                        </div>
                    </MDBModalBody>
                </MDBModal>
                }
            </React.Fragment>
        );
    }
}

export default withRouter(ListProductsByCategory);