import React from "react";
import ThirdPartyService from "../../services/thirdPartyService"
import {MDBBtn, MDBFormInline, MDBInput, toast, MDBRow, MDBCol} from "mdbreact";
import OrderService from "../../services/orderService";
// import utility from "../../utility/utility";
import utility from "../../utility/utility";
import UserStore from "../../stores/userStore";
import config from "../../config";


class CreateShippingOrder extends React.Component{
    constructor(props) {
        super(props);
        this.state={
            orderDetails: this.props.orderDetails,
            aggregator: this.props.aggregator,
            nimbusToken:this.props.nimbusToken,
            nimbusRateCard:[],
            courierId:"",
            courierName:"",
            height:7,
            length:19,
            breadth:16,
            weight:500,
            selectedCourier:{},
            chargeableWeight:500,
            showCourierList:true,

            orderAmount: this.props.orderDetails.totalAmount,
            paymentMode: (this.props.orderDetails.paymentMode.toLowerCase() == "cod") ? "cod":"prepaid",
            // Customer details
            name: this.props.orderDetails.customerDetails.name,
            phone: this.props.orderDetails.customerDetails.phone,
            // Shipping Address
            addressLine1: this.props.orderDetails.shippingAddress.addressLine1,
            addressLine2: this.props.orderDetails.shippingAddress.addressLine2,
            city: this.props.orderDetails.shippingAddress.city,
            state: this.props.orderDetails.shippingAddress.state,
            state_code:"",
            pinCode: this.props.orderDetails.shippingAddress.pinCode,
            warehouse_name:"Positra Healthcare",
            wh_name: "Positra Pharmacy",
            wh_addressLine1: "1st Floor, Lakhi Mandir Market Complex",
            wh_addressLine2: "Beltola",
            wh_city: "Guwahati",
            wh_state: "Assam",
            wh_pinCode: "781028",
            wh_phone: "8638018213",
            wh_gstin:"18AAICP6875B1Z5",

            nimbusCartItems:[],
            // Pickrr
            pickrrRateCard:[],
            deliveryMode:"express", // For pickrr
            pickrrAuthToken:config.PICKRR_AUTH,
            pickrrCartItems:[],
            pickrrCartItemsString:"",
            pickrrShipmentResult:{},
            nimbusShipmentResult:{},
            loadingCourier:false,
            shippingExpense:0,

            // state variable related to ecom express
            ecomServiceAvailable: false,
            ecom_awb_number:0,
            ecomShipmentResult:""


        }
    }

    async componentDidMount() {

        let userData = await UserStore.fetchSessionData();
        this.setState({userData})
        if(this.props.aggregator === "Nimbus") {
            // if no nimbus token, thne please login
            if (!this.props.nimbusToken) {
                this.loginToNimbus()
            } else {
                this.fetchNimbusRateCard()
            }
            let nimbusCartItems = []
            for (let item of this.props.orderDetails.cartData) {
                let nimbusItem = {
                    "name": item.productName,
                    "qty": item.quantity,
                    "price": item.maxRetailPrice,
                    // "sku": ""
                }
                nimbusCartItems.push(nimbusItem)
            }
            this.setState({nimbusCartItems: nimbusCartItems})
        }
        if(this.props.aggregator === "Pickrr") {
            this.fetchPickrrRateCard()

            let pickrrCartItems = []
            let pickrrCartItemsString = ""
            for (let item of this.props.orderDetails.cartData) {
                let pickrrItem = {
                    "item_name": item.productName,
                    "quantity": item.quantity,
                    "price": item.maxRetailPrice,
                    // "sku": "",
                    // "item_tax_percentage":"12"
                }
                pickrrCartItems.push(pickrrItem)
                pickrrCartItemsString = (pickrrCartItemsString) ? (pickrrCartItemsString+", ") : (pickrrCartItemsString) + item.productName + " X " + item.quantity
            }
            this.setState({pickrrCartItems: pickrrCartItems, pickrrCartItemsString: pickrrCartItemsString})
        }

        if(this.props.aggregator === "Ecom Express Direct") {
            this.fetchEcomServiceAvailability()
        }
    }

    fetchEcomServiceAvailability = async () => {
        let res = await ThirdPartyService.fetchEcomServiceAvailability(this.state.pinCode)
        if(res){
            await this.setState({ecomServiceAvailable:true})
            if(res.hasOwnProperty("state_code")){
                await this.setState({state_code: res.state_code})
            }
        } else {
            await this.setState({ecomServiceAvailable:false})
        }
    }

    fetchEcomAwb = async (type) => {
        let res = await ThirdPartyService.fetchEcomAwb(type)
        if(res){
            if(res.hasOwnProperty("awb")){
                await this.setState({ecom_awb_number: res.awb})
                return res.awb
            } else {
                return ""
            }
        } else {
            return ""
        }
    }

    createEcomShippingOrder = async () => {
        if(this.state.ecomServiceAvailable == false){
            toast.error("Ecom express is not providing service to the pincode.")
            return false
        }
        let type =this.state.paymentMode === "prepaid" ? "PPD" : this.state.paymentMode.toUpperCase()
        let awb = await this.fetchEcomAwb(type)
        console.log(this.props.orderDetails.cartData)
        let taxableAmount =  utility.getTaxableAmount(this.props.orderDetails.cartData)
        let cgst_rate = 0, sgst_rate = 0, igst_rate = 0, cgst = 0, sgst = 0, igst = 0, gst_tax_base = 0
            if(taxableAmount.length > 0 && taxableAmount[0].hasOwnProperty("gst")){
            if(this.state.state_code == "AS"){
                cgst_rate = taxableAmount[0].gst
                sgst_rate = taxableAmount[0].gst
                igst_rate = 0
                cgst = taxableAmount[0].tax
                sgst = taxableAmount[0].tax
                igst = 0
                gst_tax_base = cgst + sgst
            } else {
                cgst_rate = 0
                sgst_rate = 0
                igst_rate = 2*(taxableAmount[0].gst)
                cgst = 0
                sgst = 0
                igst = 2 * (taxableAmount[0].tax)
                gst_tax_base = igst
            }
        }
        let totalDiscount =  utility.getTotalDiscount(this.props.orderDetails.cartData)

        let totalCgst =  utility.getTotalCgst(taxableAmount)
        let gst_hsn = utility.getHSN(this.props.orderDetails.cartData)
        this.setState({submitting:true})

        let pieces = utility.getNoOfItem(this.props.orderDetails.cartData)


        let payload = {
            "AWB_NUMBER":awb?awb:this.state.ecom_awb_number,
            "ORDER_NUMBER": this.props.orderDetails.orderCode,
            "PRODUCT": this.state.paymentMode == "cod" ? "COD" : "PPD",
            "CONSIGNEE": this.state.name,
            "CONSIGNEE_ADDRESS1": this.state.addressLine1,
            "CONSIGNEE_ADDRESS2": this.state.addressLine2,
            "CONSIGNEE_ADDRESS3": "",
            "DESTINATION_CITY": this.state.city,
            "PINCODE": this.state.pinCode,
            "STATE": this.state.state_code ? this.state.state_code : this.state.state,
            "MOBILE": (this.state.phone).toString(),
            "TELEPHONE": (""),
            "ITEM_DESCRIPTION": "MEDICINE DESCRIPTION",
            "PIECES":pieces,
            "COLLECTABLE_VALUE": this.state.paymentMode == "cod" ? this.state.orderAmount :0,
            "DECLARED_VALUE": this.state.orderAmount,
            "ACTUAL_WEIGHT": this.state.weight/1000,
            "VOLUMETRIC_WEIGHT": this.state.weight/1000,
            "LENGTH": this.state.length,
            "BREADTH": this.state.breadth,
            "HEIGHT": this.state.height,
            "PICKUP_NAME": this.state.warehouse_name,
            "PICKUP_ADDRESS_LINE1": this.state.wh_addressLine1,
            "PICKUP_ADDRESS_LINE2": this.state.wh_addressLine2,
            "PICKUP_PINCODE": this.state.wh_pinCode,
            "PICKUP_PHONE": this.state.wh_phone,
            "PICKUP_MOBILE": this.state.wh_phone,
            "RETURN_NAME": this.state.warehouse_name,
            "RETURN_ADDRESS_LINE1": this.state.wh_addressLine1,
            "RETURN_ADDRESS_LINE2": this.state.wh_addressLine2,
            "RETURN_PINCODE": this.state.wh_pinCode,
            "RETURN_PHONE": this.state.wh_phone,
            "RETURN_MOBILE": this.state.wh_phone,
            // "ADDONSERVICE": [""],
            "DG_SHIPMENT": "false",
            "ADDITIONAL_INFORMATION": {
                "essentialProduct":"Y",
                "OTP_REQUIRED_FOR_DELIVERY":"N",
                "DELIVERY_TYPE": "",
                "SELLER_TIN": "",
                "INVOICE_NUMBER": this.props.orderDetails.invoiceId,
                "INVOICE_DATE": utility.ecomDateFormat(this.props.orderDetails.invoiceDate),
                "ESUGAM_NUMBER": "",
                "ITEM_CATEGORY": "MEDICINE",
                "PACKING_TYPE": "Box",
                "PICKUP_TYPE": "WH",
                "RETURN_TYPE": "WH",
                "CONSIGNEE_ADDRESS_TYPE": "",
                "PICKUP_LOCATION_CODE": "",
                "SELLER_GSTIN": this.state.wh_gstin,
                "GST_HSN": gst_hsn,
                "GST_ERN": "",
                "GST_TAX_NAME": (this.state.state_code == "AS") ? "AS SGST and AS CGST" : this.state.state_code+" IGST",

                "GST_TAX_BASE": gst_tax_base,
                "DISCOUNT": totalDiscount,
                "GST_TAX_RATE_CGSTN": cgst_rate,
                "GST_TAX_RATE_SGSTN": sgst_rate,
                "GST_TAX_RATE_IGSTN": igst_rate,
                "GST_TAX_TOTAL": cgst+sgst+igst,
                "GST_TAX_CGSTN": cgst,
                "GST_TAX_SGSTN": sgst,
                "GST_TAX_IGSTN": igst
            }
        }

        let res = await ThirdPartyService.createEcomShipment(payload)
        this.setState({submitting:false})
        console.log(res)

        if(res == true){
            console.log(res)
            toast.success("Shipment order created.")
            let trackingUrl = utility.getTrackingLink("Ecom Express", awb)
            console.log(trackingUrl)
            await this.setState({ecomShipmentResult:awb})
            await this.injectTrackingDetailsToOrder(trackingUrl, awb, "Ecom Express", "", "Ecom Express Direct")
            await this.props.CreateShippingOrderSuccess(res.data)
        } else {
            console.log(res)
        }
    }




    fetchNimbusRateCard= async ()=>{
        this.setState({loadingCourier:true})
        let payload = {
            "origin" : this.state.wh_pinCode,
            "destination" : this.state.pinCode,
            "payment_type" : this.state.paymentMode,
            "order_amount" : this.state.orderAmount,
            "weight" : this.state.weight,
            "length" : this.state.length,
            "breadth" : this.state.breadth,
            "height" : this.state.height
        }
        let res = await ThirdPartyService.fetchNimbusRateAndServiceAvailability(payload, this.state.nimbusToken)
        this.setState({loadingCourier:false})
        if(res){

            let couriers = res.data
            couriers.sort((a, b) => {
                return a.chargeable_weight - b.chargeable_weight;
            });
            couriers.sort((a, b) => {
                return a.total_charges - b.total_charges;
            });
            this.setState({nimbusRateCard: couriers})
        }

    }

    loginToNimbus= async ()=>{
        let res = await ThirdPartyService.loginToNimbus()
        if(res){
            this.setState({nimbusToken: res.data})
            this.props.updateNimbusToken(res.data)
            this.fetchNimbusRateCard(res.data)

        }

    }

    createNimbusShipment = async () => {
        this.setState({submitting:true})
        let payload = {
            "order_number": this.props.orderDetails.orderCode,
            "shipping_charges": 0,
            "discount": 0,
            "cod_charges": 0,
            "payment_type": this.state.paymentMode,
            "order_amount": this.state.orderAmount,
            "package_weight": this.state.weight,
            "package_length": this.state.length,
            "package_breadth": this.state.breadth,
            "package_height": this.state.height,
            "consignee": {
                "name": this.state.name,
                "address": this.state.addressLine1,
                "address_2": this.state.addressLine2,
                "city": this.state.city,
                "state": this.state.state,
                "pincode": this.state.pinCode,
                "phone": this.state.phone,
            },
            "pickup": {
                "warehouse_name": this.state.warehouse_name,
                "name" : this.state.wh_name,
                "address": this.state.wh_addressLine1,
                "address_2": this.state.wh_addressLine2,
                "city": this.state.wh_city,
                "state": this.state.wh_state,
                "pincode": this.state.wh_pinCode,
                "phone": this.state.wh_phone
            },
            "order_items": this.state.nimbusCartItems,
            "request_auto_pickup":"yes",
            "courier_id":this.state.courierId
        }

        let res = await ThirdPartyService.createNimbusShipment(payload, this.state.nimbusToken)
        this.setState({submitting:false})
        if(res){
            console.log(res)
            toast.success("Shipment order created.")
            await this.setState({nimbusShipmentResult:res.data})
            let trackingUrl = utility.getTrackingLink(res.data.courier_name, res.data.awb_number)
            await this.injectTrackingDetailsToOrder(trackingUrl, res.data.awb_number, res.data.courier_name, res.data.label, "Nimbus")
            await this.props.CreateShippingOrderSuccess(res.data)
        }

    }

    selectNimbusCourier = async (selectedCourier) => {
        await this.setState({selectedCourier})
        let shippingExpense = selectedCourier.total_charges
        shippingExpense = shippingExpense.toFixed("2")
        await this.setState({courierId: selectedCourier.id, shippingExpense:shippingExpense})
        this.toggleCourierList()
    }

    selectPickrrCourier = async (selectedCourier) => {
        await this.setState({selectedCourier})
        let shippingExpense = selectedCourier.delivered_charges + (selectedCourier.delivered_charges * 0.18)
        shippingExpense = shippingExpense.toFixed("2")
        await this.setState({courierId: selectedCourier.courier_id, shippingExpense:shippingExpense})

        this.toggleCourierList()
    }

    toggleCourierList = async () => {
        await this.setState({
            showCourierList : !this.state.showCourierList
        })
    }

    // Pickrr starts

    fetchPickrrRateCard= async ()=>{
        this.setState({loadingCourier:true})
        let payload = {
            "origin" : this.state.wh_pinCode,
            "destination" : this.state.pinCode,
            "payment_type" : this.state.paymentMode,
            "order_amount" : this.state.orderAmount,
            "weight" : this.state.weight/1000,
            "length" : this.state.length,
            "breadth" : this.state.breadth,
            "height" : this.state.height,
            "delivery_mode": this.state.deliveryMode,
        }
        let res = await ThirdPartyService.fetchPickrrRateAndServiceAvailability(payload, this.state.pickrrAuthToken)
        this.setState({loadingCourier:false})
        if(res){
            let couriers = res.rate_list
            couriers.sort((a, b) => {
                return a.delivered_charges - b.delivered_charges;
            });
            // couriers.sort((a, b) => {
            //     return a.total_charges - b.total_charges;
            // });
            this.setState({pickrrRateCard: couriers})
        }

    }

    createPickrrShipment = async () => {
        this.setState({submitting:true})
        let payload = {
            "auth_token": this.state.pickrrAuthToken,
            "item_name": this.state.pickrrCartItemsString,
            "item_list": null, //this.state.pickrrCartItems,
            "from_name": this.state.wh_name,
            "from_phone_number": this.state.wh_phone,
            "from_address": this.state.wh_addressLine1+", "+this.state.wh_addressLine2+", "+this.state.wh_city+", "+this.state.wh_state,
            "from_pincode": this.state.wh_pinCode,
            "pickup_gstin": this.state.wh_gstin,
            "to_name": this.state.name,
            //"to_email": "clark@gmail.com",
            "to_phone_number": this.state.phone.toString(),
            "to_pincode": this.state.pinCode,
            "to_address": this.state.addressLine1+", "+this.state.addressLine2+", "+this.state.city+", "+this.state.state,
            "quantity": 1,
            "invoice_value": this.state.orderAmount,
            "client_order_id": this.props.orderDetails.orderCode,
            "item_breadth": this.state.breadth,
            "item_length": this.state.length,
            "item_height": this.state.height,
            "item_weight": this.state.weight/1000,
            "is_reverse": false,
            "invoice_number":this.props.orderDetails.orderCode,
            "total_discount": 0,
            "shipping_charge": 0,
            "transaction_charge": 0,
            "giftwrap_charge": 0,
            "courier_id":this.state.courierId
        }

        // IF COD orders
        if(this.state.paymentMode == "cod"){
            payload.cod_amount = this.state.orderAmount
        }

        let res = await ThirdPartyService.createPickrrShipment(payload)
        this.setState({submitting:false})
        if(res){
            console.log(res)
            toast.success("Shipment order created.")
            await this.setState({pickrrShipmentResult:res})
            let trackingUrl = utility.getTrackingLink(res.courier, res.tracking_id)
            await this.injectTrackingDetailsToOrder(trackingUrl, res.tracking_id, res.courier, res.manifest_link_pdf, "Pickrr")
            this.props.CreateShippingOrderSuccess(res)
        }

    }


    // Pickrr ends

    injectTrackingDetailsToOrder = async (trackingUrl, trackingId,carrierName, courierLabel, aggregator) => {
        let payload = {
            orderId: this.props.orderDetails._id,
            url: trackingUrl,
            urlId: trackingId,
            carrierName: carrierName,
            aggregator:aggregator,
            courierLabel: courierLabel,
            shippingExpense:this.state.shippingExpense
        }
        // this.setState({progressStatus: true})
        let status = await OrderService.updateTrackingUrl(this.state.userData, payload);
        this.setState({progressStatus: false})
        if (status === false) {
            toast.error("We could not update the tracking URL.");
        } else {
            toast.success("Tracking URL updated successfully.");
        }
    }

    // form

    selectPaymentMode = async (paymentMode) => {
        await this.setState({paymentMode})
    }

    handlePin = async (e) => {
        if(!utility.isNumeric(e.target.value)){
            return false
        }
        await this.setState({
            pinCode:e.target.value
        })

    }


    render() {
        return (
            <React.Fragment>

                <div className={"col-12 d-flex justify-content"}>
                    <div className={"col-6"}>
                        <div className={'col-md-12 rgba-blue-grey-slight rounded border my-3 p-2'}>
                            <MDBRow>
                                <MDBCol><h4><b>Shipment Details</b></h4></MDBCol>
                            </MDBRow>.

                        <MDBRow>
                            <MDBCol >
                                <MDBFormInline className={"d-flex  flex-wrap"}>
                                    <MDBInput onClick={() => this.selectPaymentMode("prepaid")}
                                                      checked={this.state.paymentMode === "prepaid" ? true : false}
                                                      label={"Prepaid"}
                                                      type='radio' id={"prepaid_radio"} containerClass='mr-5'/>
                                    <MDBInput onClick={() => this.selectPaymentMode("cod")}
                                              checked={this.state.paymentMode === "cod" ? true : false}
                                              label={"COD"}
                                              type='radio' id={"cod_radio"} containerClass='mr-5'/>

                                </MDBFormInline>
                            </MDBCol>

                            <MDBCol>
                                <MDBInput label={"Total Amount"} value={this.state.orderAmount}
                                          onChange={(val) => {
                                              this.setState({orderAmount: val.target.value})
                                          }} maxLength={10}/>
                            </MDBCol>


                        </MDBRow>

                        <MDBRow>
                            <MDBCol className={"col-md-3"}>
                                <MDBInput label={"Length (in cm) *"} value={this.state.length}
                                          getValue={(val) => {
                                              if(!utility.isNumeric(val)){
                                                  return false
                                              }
                                              this.setState({length: val})
                                          }} />
                            </MDBCol>
                            <MDBCol className={"col-md-3"}>
                                <MDBInput label={"Breadth (in cm) *"} value={this.state.breadth}
                                          getValue={(val) => {
                                              if(!utility.isNumeric(val)){
                                                  return false
                                              }
                                              this.setState({breadth: val})
                                          }} />
                            </MDBCol>
                            <MDBCol className={"col-md-3"}>
                                <MDBInput label={"Height (in cm) *"} value={this.state.height}
                                          getValue={(val) => {
                                              if(!utility.isNumeric(val)){
                                                  return false
                                              }
                                              this.setState({height: val})
                                          }} />
                            </MDBCol>
                            <MDBCol className={"col-md-3"}>
                                <MDBInput label={"Weight(in grams) *"} value={this.state.weight}
                                          getValue={(val) => {
                                              if(!utility.isNumeric(val)){
                                                  return false
                                              }
                                              this.setState({weight: val})
                                          }} />
                            </MDBCol>
                        </MDBRow>

                            <MDBRow>
                                <MDBCol className={"col-md-6"}>
                                    {this.props.aggregator === "Pickrr" &&
                                    <MDBBtn onClick={this.fetchPickrrRateCard}>Fetch couriers and rate</MDBBtn>
                                    }
                                    {this.props.aggregator === "Nimbus" &&
                                    <MDBBtn onClick={this.fetchNimbusRateCard}>Fetch couriers and rate</MDBBtn>
                                    }

                                    {this.props.aggregator === "Ecom Express Direct" &&
                                    <MDBBtn onClick={this.fetchEcomServiceAvailability}>Fetch couriers and rate</MDBBtn>
                                    }
                                </MDBCol>
                            </MDBRow>

                        </div>

                        <div className={'col-md-12 rgba-blue-grey-slight rounded border my-3 p-2'}>
                            <MDBRow>
                                <MDBCol><h4><b>Select Courier</b></h4></MDBCol>
                            </MDBRow>.

                            {this.state.loadingCourier &&
                                (<div className="spinner-border spinner-border-sm fast" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>)
                            }

                            <MDBRow>

                                {this.props.aggregator == "Nimbus" &&
                                    <div className={"d-flex m-0 align-content-stretch flex-wrap col-12"}>
                                        {this.state.nimbusRateCard.map((item, index) => (
                                            <div className={"green-text col-4"} >
                                                <MDBInput className={"green-text col-12"} onClick={() => this.selectNimbusCourier(item)}
                                                          checked={this.state.courierId === item.id ? true : false}
                                                          label={item.name +  "  " + " (₹"+item.total_charges+")"}
                                                          type='radio' id={item.id + "_radio"} />
                                            </div>

                                        ))}
                                    </div>
                                }

                                {this.props.aggregator == "Pickrr" &&
                                <div className={"d-flex m-0 align-content-stretch flex-wrap col-12"}>
                                    {this.state.pickrrRateCard.map((item, index) => (
                                        <div className={"green-text col-4"} >
                                            <MDBInput className={"green-text col-12"}  onClick={() => this.selectPickrrCourier(item)}
                                                      checked={this.state.courierId === item.courier_id ? true : false}
                                                      label={item.courier +  " " + " (₹" + (item.delivered_charges + (item.delivered_charges * 0.18)).toFixed(2)+")"}
                                                      type='radio' id={item.courier_id + "_radio"} />
                                        </div>
                                    ))}
                                </div>
                                }
                            </MDBRow>
                        </div>
                    </div>
                    <div className={"col-6"}> <div className={'col-md-12 rgba-blue-grey-slight rounded border my-3 p-2'}>
                        <MDBRow>
                            <MDBCol><h4><b>Customer Details</b></h4></MDBCol>
                        </MDBRow>.
                        <MDBRow>
                            <MDBCol className={"col-md-6"}>
                                <MDBInput label={"Name *"} value={this.state.name}
                                          getValue={(val) => {
                                              this.setState({name: val})
                                          }} maxLength={255}/>
                            </MDBCol>
                            <MDBCol className={"col-md-6"}>
                                <MDBInput label={"Phone number"} value={this.state.phone}
                                          onChange={(val) => {
                                              this.setState({phone: val.target.value})
                                          }} maxLength={10}/>
                            </MDBCol>
                        </MDBRow>

                        <MDBRow>
                            <MDBCol className={"col-12"}>
                                <MDBInput label={"Pin Code *"} value={this.state.pinCode} onChange={this.handlePin}
                                          maxLength={6} onKeyDown={this.handleEnter} />
                                {this.state.loadingPincode === true &&
                                <div className="spinner-border spinner-border-sm position-absolute" role="status" style={{right:"19px",top:"36px"}}>
                                    <span className="sr-only">Loading...</span>
                                </div>
                                }
                            </MDBCol>

                        </MDBRow>

                        <MDBInput label={"Address Line 1 *"} value={this.state.addressLine1}
                                  onChange={(val) => {
                                      this.setState({addressLine1: val.target.value})
                                  }} maxLength={255}/>
                        <MDBInput label={"Address Line 2"} value={this.state.addressLine2}
                                  onChange={(val) => {
                                      this.setState({addressLine2: val.target.value})
                                  }} maxLength={255}/>
                        <MDBRow>

                            <MDBCol className={"col-md-6 "}>
                                <MDBInput label={"City/District *"} value={this.state.city}
                                          getValue={(val) => {
                                              this.setState({city: val})
                                          }} maxLength={255}/>
                            </MDBCol>

                            <MDBCol className={"col-md-6 "}>
                                <MDBInput label={"State *"} value={this.state.state}
                                          onChange={(val) => {
                                              this.setState({state: val.target.value})
                                          }} maxLength={255}/>
                            </MDBCol>
                        </MDBRow>


                        {this.state.courierId && !this.state.nimbusShipmentResult.hasOwnProperty("label") && this.props.aggregator === "Nimbus"  &&
                        <MDBBtn className={'btn-block m-0'} onClick={this.createNimbusShipment}
                                disabled={this.state.submitting}>
                            {this.state.submitting ?
                                (<div className="spinner-border spinner-border-sm fast" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>) : (<>Submit to {this.props.aggregator}</>)
                            }


                        </MDBBtn>
                        }

                        {this.state.courierId && !this.state.nimbusShipmentResult.hasOwnProperty("label") &&this.props.aggregator === "Pickrr" &&
                        <MDBBtn className={'btn-block m-0'} onClick={this.createPickrrShipment}
                                disabled={this.state.submitting}>
                            {this.state.submitting ?
                                (<div className="spinner-border spinner-border-sm fast" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>) : (<>Submit to {this.props.aggregator}</>)
                            }

                        </MDBBtn>
                        }


                        {(this.state.ecomServiceAvailable && this.state.ecomShipmentResult == "") &&
                        <MDBBtn className={'btn-block m-0'} onClick={this.createEcomShippingOrder}
                                disabled={this.state.submitting}>
                            {this.state.submitting ?
                                (<div className="spinner-border spinner-border-sm fast" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>) : (<>Submit to {this.props.aggregator}</>)
                            }

                        </MDBBtn>
                        }

                        {this.state.nimbusShipmentResult.hasOwnProperty("label") &&
                        <a className={"mt-3 p-2"} target={"_blank"} href={this.state.nimbusShipmentResult.label}>Download Courier Label  </a>
                        }

                        {this.state.pickrrShipmentResult.hasOwnProperty("manifest_link_pdf") &&
                        <a className={"mt-3 p-2"} target={"_blank"}  href={this.state.pickrrShipmentResult.manifest_link_pdf}>Download Courier Label</a>
                        }

                        {(this.state.ecomShipmentResult) &&
                        <span className={"mt-3 p-2"}>Ecom shipment created with AWB: {this.state.ecomShipmentResult}</span>
                        }






                    </div></div>


                </div>




            </React.Fragment>
        )
    }

}

export default CreateShippingOrder