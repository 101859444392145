import orgConfig from "../../orgConfig";

const dataTableSalesIntelligence= {
    mostFrequentProducts:[
        {
            label: 'Product Name',
            field: 'handleProductName',
            sort: 'asc',
            width: 300
        },
        {
            label: 'Number of Times Ordered',
            field: 'count',
            sort:'asc',
            width: 50
        },
    ],
    mostFrequentCustomers:[
        {
            label: 'Customer Name',
            field: 'handleCustomerName',
            sort: 'asc',
            width: 300
        },
        {
            label: 'Contact Number',
            field: 'handleContactNumber',
            sort: 'asc',
            width: 100
        },
        {
            label: 'Number of Orders',
            field: 'count',
            sort:'asc',
            width: 50
        },
        {
            label: 'Insights',
            field: 'viewCustomerInsights',
            sort:'disabled',
            width: 50
        },

    ],
    topCustomers:[
        {
            label: 'Customer Name',
            field: 'handleCustomerName',
            sort: 'asc',
            width: 300
        },
        {
            label: 'Contact Number',
            field: 'handleContactNumber',
            sort: 'asc',
            width: 100
        },
        {
            label: 'Total Purchase('+orgConfig.CURRENCY_SYMBOL+")",
            field: 'totalPurchase',
            sort:'asc',
            width: 50
        },
        {
            label: 'Insights',
            field: 'viewCustomerInsights',
            sort:'disabled',
            width: 50
        },
    ]

}
export default dataTableSalesIntelligence;