import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
} from "mdbreact";
import React, { useEffect, useState } from "react";
import OrderService from "../../services/orderService";
import {
  PRODUCT_TABLE_COLUMNS,
  UNFULFILLED_PAYMENT_TABLE_COLUMNS,
} from "../../utility/constants";
import OrderDetails from "./OrderDetails";
import NewTable from "../../newComponents/NewTable/NewTable";
import {
  formatUnfulfilledPaymentTable,
  formatUnfulfilledProductTable,
} from "../../utility/tableUtils";
import { Link } from "react-router-dom";

const ViewOrderModal = (props) => {
  const [order, setOrder] = useState({});
  const { isModalOpen, setIsModalOpen, rowId, userData, dateData, page, filter, searchTerm, showSearchValues,orderAmount } =
    props;

  const toggle = () => {
    setIsModalOpen(!isModalOpen);
  };
  console.log({isModalOpen,dateData, page})

  async function fetchData() {
    let data = await OrderService.fetchOnlineOrderById(
      userData.sessionToken,
      rowId
    );
    if (data) {
      setOrder(data);
    } else {
      console.log("Something went wrong while getting order details.");
    }
  }

  const getAddedQnty = (item) => {
    let addedQnty = 0;
    if (item.hasOwnProperty("inventory")) {
      for (let i of item.inventory) {
        addedQnty = addedQnty + i.quantityOut;
      }
      return addedQnty;
    }
    return item.quantity;
  };
  const getSubtotal = (item) => {
    let subtotal = 0;
    if (item.hasOwnProperty("inventory")) {
      for (let i of item.inventory) {
        subtotal = subtotal + i.mrp * i.quantityOut;
      }
      subtotal = subtotal - subtotal * (item.discount / 100);
    } else {
      subtotal =
        item.maxRetailPrice * item.quantity -
        (item.maxRetailPrice * item.quantity * item.discount) / 100;
    }
    return subtotal.toFixed(2);
  };


  useEffect(() => {
    if (userData && rowId) {
      fetchData();
    }
  }, [rowId, userData]);

  return (
    <>
      <MDBModal isOpen={isModalOpen} toggle={() => toggle()} size="xl">
        <MDBModalHeader toggle={() => toggle()}>ORDER DETAILS</MDBModalHeader>
        <MDBModalBody className={"float-center"}>
          <OrderDetails order={order} />
          {Object.keys(order).length > 0 && (
            <NewTable
              columns={PRODUCT_TABLE_COLUMNS}
              data={formatUnfulfilledProductTable(
                order?.cartData,
                getAddedQnty,
                getSubtotal
              )}
              pagination={false}
            />
          )}
          <div className="mt-4">
            {Object.keys(order).length > 0 && (
              <NewTable
                columns={UNFULFILLED_PAYMENT_TABLE_COLUMNS}
                data={formatUnfulfilledPaymentTable(order)}
                pagination={false}
              />
            )}
          </div>
        </MDBModalBody>

        <MDBModalFooter>
          <MDBBtn
            color="primary"
            outline
            onClick={() => {
              toggle();
            }}
            size={"sm"}
          >
            Close
          </MDBBtn>
          <Link 
          to={{
            pathname: `/process-online-order/${rowId}`,
            state: { 
              startDate: dateData?.startDate?.format('YYYY-MM-DD'), 
              endDate: dateData?.endDate?.format('YYYY-MM-DD'), 
              mode: dateData?.mode, 
              page: page, 
              isModalOpen: isModalOpen,
              path: '/unfulfilled-orders',
              filter: filter,
              searchTerm: searchTerm,
              showSearchValues:showSearchValues,
              orderAmount: orderAmount
            }
          }}
          >
            <MDBBtn color="default" size={"sm"}>
              Process Order
            </MDBBtn>
          </Link>
        </MDBModalFooter>
      </MDBModal>
    </>
  );
};

export default ViewOrderModal;
