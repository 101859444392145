import React from "react";
import {MDBCol, MDBIcon, MDBNav, MDBNavItem, MDBNavLink, MDBTabContent, MDBTabPane} from "mdbreact";
import Moments from "moment";
import CustomerOrders from "./customerOrders";
import ListCallLog from "./listCallLog";
import CustomerCouponList from "./coupon/list";

class CustomerDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activePill: "feedback"
        }
    }

    togglePills = (tab) => {
        if (this.state.activePill !== tab) {
            this.setState({activePill: tab});
        }
    };
    getName = () => {
        if (this.props.activeItem.hasOwnProperty("name")) {
            return this.props.activeItem.name
        } else if (this.props.activeItem.hasOwnProperty("first_name")) {
            return this.props.activeItem.first_name
        } else {
            return ""
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className={"col-12 row mx-0 p-1"}>
                    <div className={"col-md-12 m-auto p-2"}>
                        <p>
                            Name: <b>{this.getName()}</b> <br/>
                            {this.props.activeItem.hasOwnProperty("contactNo") && this.props.activeItem.contactNo !== "" &&
                            <>Contact No: <b>{this.props.activeItem.contactNo}</b> <br/></>
                            }
                            {this.props.activeItem.hasOwnProperty("dob") && this.props.activeItem.dob !== "" &&
                            <>DOB: <b>{this.props.activeItem.dob !== "" ? Moments(this.props.activeItem.dob).format("ll") : ""}</b><br/></>
                            }
                            {this.props.activeItem.hasOwnProperty("email") && this.props.activeItem.email !== "" &&
                            <>E-mail: <b>{this.props.activeItem.email}</b> <br/></>
                            }
                            {this.props.activeItem.hasOwnProperty("gender") && this.props.activeItem.gender !== "" &&
                            <>Gender: <b>{this.props.activeItem.gender}</b> <br/></>
                            }
                            Member Since: <b>{Moments(this.props.activeItem.memberSince).format("ll")}</b>
                        </p>
                    </div>
                    <div className={"col-md-12 m-auto p-0"}>
                        <MDBTabPane tabId="1" role="tabpanel">
                            <div className={'sticky-top'}>
                                <MDBNav tabs>
                                    <MDBNavItem>
                                        <MDBNavLink link to="#"
                                                    active={this.state.activePill === "feedback"}
                                                    onClick={() => this.togglePills("feedback")}>
                                            Call Log/Feedback<MDBIcon icon="comments" className="ml-2" size={'1x'}/>
                                        </MDBNavLink>
                                    </MDBNavItem>
                                    <MDBNavItem>
                                        <MDBNavLink link to="#"
                                                    active={this.state.activePill === "coupon"}
                                                    onClick={() => this.togglePills("coupon")}>
                                            Coupon<MDBIcon icon="candy-cane" className="ml-2" size={'1x'}/>
                                        </MDBNavLink>
                                    </MDBNavItem>
                                    {/*<MDBNavItem>*/}
                                    {/*    <MDBNavLink link to="#"*/}
                                    {/*                active={this.state.activePill === "orders"}*/}
                                    {/*                onClick={() => this.togglePills("orders")}>*/}
                                    {/*        View Orders<MDBIcon icon="user" className="ml-2"/>*/}
                                    {/*    </MDBNavLink>*/}
                                    {/*</MDBNavItem>*/}
                                </MDBNav>
                            </div>
                        </MDBTabPane>
                    </div>
                    <MDBCol className={'col-12 p-0'}>
                        <MDBTabContent activeItem={this.state.activePill}>
                            <MDBTabPane tabId="feedback">
                                {this.props.activeItem !== null &&
                                <ListCallLog activeItem={this.props.activeItem}/>
                                }
                            </MDBTabPane>
                            <MDBTabPane tabId="coupon">
                                {this.props.activeItem !== null &&
                                <CustomerCouponList activeItem={this.props.activeItem}/>
                                }
                            </MDBTabPane>
                            {/*<MDBTabPane tabId="orders" className={"fullfill-order-sticky"}>*/}
                            {/*    {this.props.activeItem !== null &&*/}
                            {/*    <CustomerOrders activeItem={this.props.activeItem}/>*/}
                            {/*    }*/}
                            {/*</MDBTabPane>*/}
                        </MDBTabContent>
                    </MDBCol>
                </div>
            </React.Fragment>
        );
    }
}

export default CustomerDetails;
