import React, { Suspense } from "react";
import { Link } from "react-router-dom";
import { MDBBreadcrumbItem, MDBBreadcrumb } from "mdbreact";
import ViewSubstituteNew from "./viewSubstituteNew";
export default class ViewSubstitute extends React.Component {
  render() {
    return (
      <React.Fragment>
        <MDBBreadcrumb>
          <MDBBreadcrumbItem>
            <Link to={"/dashboard"}>Dashboard</Link>
          </MDBBreadcrumbItem>
          <MDBBreadcrumbItem active>View Substitute</MDBBreadcrumbItem>
        </MDBBreadcrumb>
        <Suspense fallback={null}>
          <ViewSubstituteNew />
        </Suspense>
      </React.Fragment>
    );
  }
}
