import React from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBSpinner,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdbreact";
import Moments from "moment";

function ViewOrdersFunc({ orderModal, orders, toggleOrder }) {
  return (
    <>
      <MDBModal
        size={"lg"}
        isOpen={orderModal}
        toggle={() => {
          toggleOrder(null);
        }}
      >
        <MDBModalHeader
          toggle={() => {
            toggleOrder(null);
          }}
        >
          View Associated orders
        </MDBModalHeader>
        <MDBModalBody>
          {orders === null && <MDBSpinner />}
          {orders !== null && (
            <>
              <MDBTable>
                <MDBTableHead>
                  <tr>
                    <th>#</th>
                    <th>Batch</th>
                    <th>Order Code</th>
                    <th>Order Date</th>
                    <th>Order Mode</th>
                    <th>Quantity</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  {orders.length === 0 && (
                    <tr>
                      <td align={"center"} colSpan={6}>
                        <div className={"no-data-container"}>
                          No Records found.
                        </div>
                      </td>
                    </tr>
                  )}
                  {orders.map((row, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{row.batch}</td>
                      <td>{row.orderCode}</td>
                      <td>{Moments(row.orderDate).format("MMM D, YYYY")}</td>
                      <td>{row.orderMode}</td>
                      <td>{row.quantity}</td>
                    </tr>
                  ))}
                </MDBTableBody>
              </MDBTable>
            </>
          )}
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn
            size={"md"}
            onClick={() => {
              toggleOrder(null);
            }}
          >
            Close
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    </>
  );
}

export default ViewOrdersFunc;
