const dataTableFloors = {
    floors:[
        {
            label: 'Floor Name',
            field: 'floorName',
            sort: 'asc',
            width: 400
        },
        {
            label: '',
            field: 'listRacks',
            sort: 'asc',
            width: 50
        },
        {
            label: '',
            field: 'edit',
            sort: 'asc',
            width: 50
        },
        {
            label: '',
            field: 'delete',
            sort: 'asc',
            width: 50
        },
    ]
}
export default dataTableFloors;