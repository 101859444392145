import React from "react";
import { withRouter } from "react-router-dom";
import {
    MDBBox,
    MDBBtn,
    MDBTableHead,
    MDBTableBody,
    MDBTable,
    MDBRow,
    MDBCol,
    MDBInput,
    MDBSelect,
    MDBSelectInput,
    MDBSelectOptions,
    MDBSelectOption,
    MDBContainer,
    MDBIcon,
    MDBModalHeader,
    MDBModalBody,
    MDBModal
} from 'mdbreact';
import UserStore from "../../stores/userStore";
import {toast} from "react-toastify";
import dataTableConfig from "../order-history/dataTableOrderHistory";
import OrderService from "../../services/orderService";
import Moments from "moment";
import config from "../../config";
import {Page, Text, View, Document, StyleSheet, PDFViewer} from '@react-pdf/renderer';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import utility from "../../utility/utility";
import util from "../../utility/utility"
import SimpleBackdrop from "../common/overlay";
import PharmacyService from "../../services/pharmacyService";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import AddAddress from "../common/addAddress";



class EditOrder extends React.Component {
    async componentDidMount() {
        console.log(this.state.activeOrder)
        let userData = await UserStore.fetchSessionData();
        this.setState({userData: userData})
        this.setState({
            activeOrder: this.props.activeOrder
        })

        if(userData.type === "platformAdmin"){
            this.setState({orgId: userData._id})
        } else if(userData.type === "ORG_ADMIN"){
            this.setState({orgId: userData.orgDetails[0]._id})
        }

        this.fetchAllAddress()
    }

     onChangeUser  = async (event, values) => {
        if(values.value!==null) {
            this.setState({
                user: values.value
            }, () => {


                let activeOrder = this.state.activeOrder;
                activeOrder.deliveryPerson = this.state.user
                this.setState({activeOrder:activeOrder})

                this.setState({receivedByError:false})
            });
        }
    }
    resetKey() {
        let randomString = Math.random().toString(36);
        this.setState({
            randomKey: randomString
        });
    }

    fetchUser = async (e) => {
        try {
            if (e.target.value !== 0 || e.target.value !== '0') {
                let suggestedUser = await PharmacyService.searchUser(e.target.value, this.state);
                await this.setState({allSuggestedUser: suggestedUser});
                let temp = [];
                for (let item of suggestedUser) {
                    let str = {title: item.name, value: item};
                    temp.push(str);
                }
                if(temp.length>0) {
                    this.setState({suggestedUser: temp});
                }else{
                    this.setState({suggestedUser: [{title: "", value: ''}]});
                }
            }
        }catch (e) {
            this.setState({suggestedUser: [{title: "", value: ''}]});
        }
    };



    constructor(props) {
        super(props);
        this.state = {

            orgId:0,
            userData:{},
            progressStatus:false,
            orders:[],
            activeOrder:{
                cart:[
                    {
                        cartData:[]
                    }
                ],
                docs:[],
                products:[],
                deliveryPerson:{},
                customerDetails:{}
            },
            isActiveOrderAPrescriptionDrug:false,


            paymentModes: [{ text:"Cash", value:"CASH", checked:true },{ text:"Card", value:"CARD" },{ text:"Netbanking", value:"NETBANKING" },{ text:"Wallet", value:"WALLET" }],
            paymentMode:"",
            modalEditOrder:false,

            allSuggestedUser:[],
            suggestedUser:[],
            user:{},
            randomKey:"",
            receivedByError:false,
            receivedBy:"",

            addresses:[],
            initEditAddress:false,
            activeAddress:{}

        }
    }


    convertMfgOrExpiry = date =>{
        if(date){
            return Moments(date).format("MMM YYYY");
        } else {
            return "";
        }
    }

    calculateSubTotal = (mrp, quantity, discount, contentQuantity, looseSaleAllowed) => {
        if(!contentQuantity){
            contentQuantity = 1;
        }
        if(mrp > 0){
            if(looseSaleAllowed === "N"){
                contentQuantity = 1 // This could be the minimum sale quantity later
            }
            let subTotal = (mrp/contentQuantity) * quantity;
            if(discount > 0){
                let discountedAmount = subTotal * (discount / 100);
                subTotal = subTotal - discountedAmount
            }
            return subTotal.toFixed("2");
        } else {
            return 0;
        }
    }

    calculateDiscountedAmount = (mrp, quantity, discount, contentQuantity, looseSaleAllowed) => {
        if(!contentQuantity){
            contentQuantity = 1;
        }
        if(mrp > 0){
            if(looseSaleAllowed === "N"){
                contentQuantity = 1 // This could be the minimum sale quantity later
            }
            let subTotal = (mrp/contentQuantity) * quantity;
            if(discount > 0){
                let discountedAmount = subTotal * (discount / 100);
                return discountedAmount.toFixed("2");
            } else {
                return 0
            }
        } else {
            return 0;
        }
    }

    calculateGSTAmountFromMRPAndGSTPercentage = (mrp, gstPercentage, quantity, contentQuantity, looseSaleAllowed) => {
        if(!quantity){
            quantity = 1;
        }
        if(!contentQuantity){
            contentQuantity = 1;
        }
        if(looseSaleAllowed === "N"){
            contentQuantity = 1 // This could be the minimum sale quantity later
        }

        if(mrp > 0){
            if(gstPercentage > 0){
                let basePrice = (mrp/contentQuantity) - ((100+gstPercentage)/100)
                let gstAmount = basePrice - (basePrice - basePrice*(gstPercentage/100))
                gstAmount = gstAmount * quantity;
                gstAmount =  Math.round(gstAmount * 100) / 100
                return gstAmount.toFixed("2");
            } else {
                return 0;
            }
        } else {
            return 0
        }
    }

    updateOfflineOrder = async () => {
        if(this.state.paidAmount <= 0 && this.state.paymentMode === "CASH"){
            toast.error("Paid amount is 0. If this is a credit order, please change the payment mode to 'credit'.");
            return false;
        } else if((this.state.totalAmount - this.state.paidAmount ) >=1 && this.state.paymentMode === "CASH"){
            toast.error("Paid amount is lesser than total amount. If this is a partial payment please change the payment mode to 'credit'.");
            return false;
        }
        this.setState({suggestedUser:[]})
        this.setState({progressStatus:true})
        let offlineOrderRes = await OrderService.updateOfflineOrder(this.state)
        console.log(offlineOrderRes);
        if(offlineOrderRes){
            toast.success("Order updated successfully.");
            this.props.onActionComplete();
        }
    }

    updateShippingAddressForOfflineOrder = async (address) => {
        let offlineOrderRes = await OrderService.updateShippingAddressOfflineOrder(address, this.state)

        if(offlineOrderRes){
            toast.success("Shipping address updated successfully.");
            let order = this.state.activeOrder;
            order.shippingAddress = address
            await this.setState({activeOrder: order})
            await this.props.onActionComplete();
        }
    }

    handlePaymentMode = (e) => {
        let activeOrder = this.state.activeOrder;
        activeOrder.paymentMode = e[0]
        this.setState({activeOrder:activeOrder})
    }

    fetchAllAddress = async() => {
        let userData=await UserStore.fetchSessionData();
        let addresses =await PharmacyService.fetchAllAddress(this.state.activeOrder.customerDetails._id,userData);
        console.log(addresses);
        if(!addresses){
            toast.error("Something went wrong while fetching addresses.")
        } else if( addresses.length > 0){
            this.setState({addresses:addresses});
        }
    }

    openAddAddress = () =>{
        this.toggle()
    };
    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
        this.setState({"activeAddress": {
                name: "",
                phone:"",
                address:"",
                street:"",
                pincode:"",
                area: "",
                city: "",
                state: "",
                country: "",
                landmark: "",
            }});
    }

    intEditAdd = (row) => {
        console.log("Addressid", row.id);
        this.toggle()
        this.setState({"activeAddress": row, initEditAddress : true});
    }

    render() {
        return (
            <React.Fragment>

                <MDBBox>
                    <MDBRow className={'my-4'}>
                        <MDBCol>
                            <MDBSelect outline={true} getValue={this.handlePaymentMode}>
                                <MDBSelectInput selected={this.state.activeOrder.paymentMode }/>
                                <MDBSelectOptions>
                                    {this.state.paymentModes.map((item, idx) => (
                                        <MDBSelectOption value={item.value}
                                                         key={idx}
                                                         selected={this.state.activeOrder.paymentMode === item.value ? true : false}>{item.text}</MDBSelectOption>
                                    ))}
                                </MDBSelectOptions>
                            </MDBSelect>
                        </MDBCol>
                        <MDBCol>
                            <MDBBtn size={"sm"} disabled={this.state.totalAmount < 1}  onClick={this.updateOfflineOrder}>
                                Update initial payment mode
                            </MDBBtn>
                        </MDBCol>
                    </MDBRow>

                            <MDBTable small={true}>
                                <MDBTableHead>
                                    <tr>
                                        <th>Product</th>
                                        <th>Batch No</th>
                                        <th>Type</th>
                                        <th>Expiry Date</th>
                                        <th>MRP</th>
                                        <th>GST (%)</th>
                                        <th>Quantity</th>
                                        <th>Discount (%)</th>
                                        <th>Subtotal</th>
                                        <th>Patient</th>
                                        <th>Doctor</th>
                                    </tr>
                                </MDBTableHead>
                                {this.state.activeOrder.hasOwnProperty("products") &&
                                <MDBTableBody>
                                    {this.state.activeOrder.products.map((row, index) => (
                                        <tr key={index + "_cart"} className={"pt-1 mt-1"}>
                                            <td>{row.productName}</td>
                                            <td>{row.inventory.batch}</td>
                                            <td>
                                                {(row.hasOwnProperty("isMedicine") && !row.isMedicine) &&
                                                <span>General</span>
                                                }
                                                {(row.hasOwnProperty("isMedicine") && row.isMedicine) &&
                                                <span>
                                                    {row.hasOwnProperty("medicineType") &&
                                                    <span
                                                        className={(row.medicineType === "Schedule H" || row.medicineType === "Schedule H1" || row.medicineType === "Schedule X" || row.medicineType === "PRESCRIPTION DRUGS" || row.medicineType === "PRESCRIPTION DRUG") ? "red-text" : "green-text"}>
                                                        {row.medicineType === "PRESCRIPTION DRUGS" &&
                                                        <span>Prescription Drug</span>
                                                        }
                                                        {row.medicineType !== "PRESCRIPTION DRUGS" &&
                                                        <span>{row.medicineType}</span>
                                                        }
                                                    </span>
                                                    }
                                                    {!row.hasOwnProperty("medicineType") &&
                                                    <span>  Not Specified</span>
                                                    }
                                                </span>
                                                }
                                                {(!row.hasOwnProperty("isMedicine")) &&
                                                <span>
                                                    {row.hasOwnProperty("medicineType") &&
                                                    <span
                                                        className={(row.medicineType === "Schedule H" || row.medicineType === "Schedule H1" || row.medicineType === "Schedule X") ? "red-text" : "green-text"}> {row.medicineType}</span>
                                                    }
                                                </span>
                                                }
                                            </td>
                                            <td>{this.convertMfgOrExpiry(row.inventory.expDate)}</td>
                                            {row.looseSaleAllowed === "Y" &&
                                            <td><span dangerouslySetInnerHTML={{__html: config.CURRENCY_HTML}}></span> {(row.inventory.mrp/row.contentQuantity).toFixed("2")}</td>
                                            }
                                            {row.looseSaleAllowed === "N" &&
                                            <td><span dangerouslySetInnerHTML={{__html: config.CURRENCY_HTML}}></span> {(row.inventory.mrp).toFixed("2")}</td>
                                            }
                                            <td>{row.inventory.gst}</td>
                                            <td>
                                                {row.quantity}
                                            </td>
                                            <td>
                                                <div>
                                                    {row.discount}
                                                </div>
                                            </td>
                                            <td><span dangerouslySetInnerHTML={{__html: config.CURRENCY_HTML}}></span> {this.calculateSubTotal(row.inventory.mrp, row.quantity, row.discount, row.contentQuantity, row.looseSaleAllowed)}</td>
                                            <td size={"2"}>
                                                {(row.medicineType.trim() === "Schedule X" || row.medicineType.trim() === "Schedule H" || row.medicineType.trim() === "Schedule H1" || row.medicineType.trim() === "PRESCRIPTION DRUG" || row.medicineType.trim() === "PRESCRIPTION DRUGS") &&
                                                <>
                                                    {(row.hasOwnProperty("patientDetails") && row.patientDetails.hasOwnProperty("name")) &&
                                                    <span>
                                                    {row.patientDetails.name &&
                                                    <span>{row.patientDetails.name}</span>
                                                    }
                                                        {(!row.patientDetails.name && row.patientDetails.first_name) &&
                                                        <span>{row.patientDetails.first_name + " " + row.patientDetails.last_name} </span>
                                                        }
                                                </span>
                                                    }
                                                </>
                                                }
                                            </td>
                                            <td size={"2"}>
                                                {(row.medicineType.trim() === "Schedule X" || row.medicineType.trim() === "Schedule H" || row.medicineType.trim() === "Schedule H1" || row.medicineType.trim() === "PRESCRIPTION DRUG" || row.medicineType.trim() === "PRESCRIPTION DRUGS") &&
                                                <>
                                                    {(row.hasOwnProperty("doctorDetails") && row.doctorDetails.hasOwnProperty("name")) &&
                                                    <span>{row.doctorDetails.name}</span>
                                                    }
                                                </>
                                                }
                                            </td>
                                        </tr>
                                    ))}
                                </MDBTableBody>
                                }
                            </MDBTable>
                            <MDBRow>
                                {this.state.activeOrder.totalAmount &&
                                <MDBCol>
                                    <b>Total Amount: <span dangerouslySetInnerHTML={{__html: config.CURRENCY_HTML}}></span> {this.state.activeOrder.totalAmount.toFixed("2")}</b>
                                </MDBCol>
                                }
                            </MDBRow>
                            {(this.state.activeOrder.hasOwnProperty("docs") ) &&
                            <div className={"mt-5"}>
                                {this.state.activeOrder.docs.map((row, index) => (
                                    <div style={{"textAlign": "center"}}>
                                        {row.hasOwnProperty("filePathOriginal") &&
                                        <img alt={""} src={config.IMAGE_HOST + row.filePathOriginal}
                                             className={"float-center"}
                                             style={{maxWidth: "240px", maxHeight: "500px"}}/>
                                        }
                                    </div>
                                ))}
                            </div>
                            }

                    {this.state.activeOrder.orderMode === "TELEPHONIC" &&
                    <MDBRow className={"m-1 mt-3 mb-5"}>

                        <MDBCol className={"col-4 p-0"}>
                            {this.state.activeOrder.hasOwnProperty("deliveryPerson") && this.state.activeOrder.deliveryPerson.hasOwnProperty("name") &&
                            <div>Delivery Person: {this.state.activeOrder.deliveryPerson.name}</div>
                            }
                            <Autocomplete key={"user" + this.state.randomKey}
                                          freeSolo
                                          onChange={this.onChangeUser}
                                          id="combo-box-demo"
                                          options={this.state.suggestedUser}
                                          getOptionLabel={option => option.title}
                                          className="header-search"
                                          onInputChange={(e) => {
                                              this.fetchUser(e)
                                          }}
                                          selectOnFocus={true}
                                          renderInput={params => (
                                              <TextField
                                                  style={this.state.receivedByError ? {"borderBottom": "1px solid RED"} : {}} {...params}
                                                  placeholder="Delivery Person"
                                                  margin="normal" fullWidth/>
                                          )}
                            />


                        </MDBCol>
                        <MDBCol className={"col-4"}>
                            {this.state.activeOrder.hasOwnProperty("shippingAddress") && this.state.activeOrder.shippingAddress.hasOwnProperty("name") &&
                            <div className={'mh-100 small white border rounded p-3 m-3'}>
                                <div>Delivery Address</div>
                                <div className={'col-md-12 pl-0 '}>
                                    <strong>{this.state.activeOrder.shippingAddress.name}</strong>
                                </div>
                                <div className={'col-md-12 pl-0 '}>
                                    {this.state.activeOrder.shippingAddress.address}, {this.state.activeOrder.shippingAddress.street}, {this.state.activeOrder.shippingAddress.area}<br/>
                                    {this.state.activeOrder.shippingAddress.city}, {this.state.activeOrder.shippingAddress.state}
                                    <br/>
                                    {this.state.activeOrder.shippingAddress.pincode}, {this.state.activeOrder.shippingAddress.country}<br/>
                                    {this.state.activeOrder.shippingAddress.landmark}<br/>
                                </div>
                                <div className={'col-md-12 pl-0 '}>
                                    <strong>Phone number:</strong> {this.state.activeOrder.shippingAddress.contact_no}
                                </div>
                            </div>
                            }
                        </MDBCol>

                    </MDBRow>
                    }

                    {this.state.activeOrder.orderMode === "TELEPHONIC" &&
                    <MDBRow>
                        <MDBCol sm="4" className={'p-1 grey-text d-flex align-items-stretch'}>
                            <MDBBtn flat className={'col-md-12 m-0 mh-100 small white border rounded p-3 text-center'}
                                    onClick={this.toggle}>
                                <MDBIcon icon="plus"/><br/>Add Address
                            </MDBBtn>
                        </MDBCol>


                        {this.state.addresses.map((row, index) => (
                            <MDBCol key={"Add-" + index} sm="4" className={'p-1 d-flex align-items-stretch'}>
                                <MDBCol className={'mh-100 small white border rounded p-3'}>
                                    <div className={"col-md-12 pl-0 "}>
                                        <MDBBtn size={"sm"}
                                                onClick={() => this.updateShippingAddressForOfflineOrder(row)}>Set this
                                            as delivery address for this order</MDBBtn>
                                    </div>
                                    <div className={'col-md-12 pl-0 float-left'}>
                                        <strong>{row.name}</strong>
                                    </div>
                                    <div className={'col-md-12 pl-0 float-left'}>
                                        {row.address}, {row.street}, {row.area}<br/>
                                        {row.city}, {row.state} <br/>
                                        {row.pincode}, {row.country}<br/>
                                        {row.landmark}<br/>
                                    </div>
                                    <div className={'col-md-12 pl-0 float-left'}>
                                        <strong>Phone number:</strong> {row.contact_no}
                                    </div>
                                    <div className={'col-md-12 pl-0 float-left'}>
                                        {/*<MDBBtn flat className={'p-0 m-0 mt-3 light-blue-text'}*/}
                                        {/*        onClick={() => this.intEditAdd(row)}>Edit</MDBBtn>*/}
                                        {/*<MDBBtn flat className={'p-0 m-0 mt-3 ml-3 light-blue-text'}*/}
                                        {/*        onClick={() => this.initDeleteAddress(row)}>Delete</MDBBtn>*/}
                                    </div>
                                </MDBCol>
                            </MDBCol>
                        ))}
                    </MDBRow>
                    }




                    <MDBModal isOpen={this.state.modal} toggle={this.toggle} size={"lg"}>
                        <MDBModalHeader toggle={this.toggle}>Address</MDBModalHeader>
                        <MDBModalBody>
                            <MDBRow>
                                <MDBCol className={"p-3"}>
                                    {this.state.initEditAddress === false &&
                                    <AddAddress userId={this.state.activeOrder.customerDetails.userId}
                                                user={this.state.activeOrder.customerDetails}
                                                dependentId={this.state.activeOrder.customerDetails.dependentId}
                                                openAddAddress={this.openAddAddress}
                                                fetchAllAddress={this.fetchAllAddress}/>
                                    }
                                    {/*{this.state.initEditAddress === true &&*/}
                                    {/*<AddAddress userId={this.state.activeOrder.customerDetails.userId}*/}
                                    {/*            activeAddress={this.state.activeAddress} user={this.state.activeOrder.customerDetails}*/}
                                    {/*            dependentId={this.state.activeOrder.customerDetails.dependentId}*/}
                                    {/*            openAddAddress={this.openAddAddress}*/}
                                    {/*            fetchAllAddress={this.fetchAllAddress}/>*/}
                                    {/*}*/}
                                </MDBCol>
                            </MDBRow>
                        </MDBModalBody>
                    </MDBModal>


                </MDBBox>
            </React.Fragment>
        );
    }
}
export default withRouter(EditOrder)

