import React from "react";
import { withRouter } from "react-router-dom";
import {
    MDBContainer,
    MDBBox,
    MDBProgress,
    MDBDataTableV5,
    MDBCardHeader,
    MDBFormInline,
    MDBBtn,
    MDBCardBody,
    MDBCard,
    MDBModalHeader,
    MDBModalBody,
    MDBModal,
    MDBModalFooter,
    MDBTableHead,
    MDBTableBody,
    MDBInput,
    MDBSelect,
    MDBSelectInput,
    MDBSelectOptions,
    MDBSelectOption,
    MDBIcon, MDBTable, MDBRow, MDBCol
} from 'mdbreact';
import UserStore from "../../stores/userStore";
import {toast} from "react-toastify";
import dataTableConfig from "../order-history/dataTableOrderHistory";
import PharmacyService from "../../services/pharmacyService";
import OrderService from "../../services/orderService";
import Moments from "moment";
import config from "../../config";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {Document, Page, PDFViewer,PDFDownloadLink, Text, View} from "@react-pdf/renderer";

class ManageOnlineOrders extends React.Component {
    async componentDidMount() {
        let userData = await UserStore.fetchSessionData();

        this.setState({userData: userData})
        if(userData.type === "platformAdmin"){
            this.setState({orgId: userData._id})
        } else if(userData.type === "ORG_ADMIN"){
            this.setState({orgId: userData.orgDetails[0]._id})
        }
        await this.fetchData();
    }
    fetchData=async ()=>{
        window.scrollTo(0, 0);
        let userData = await UserStore.fetchSessionData();
        this.setState({progressStatus: true});
        let data=await OrderService.fetchOnlineOrders(this.state);
        this.setState({progressStatus: false});
        if(data===false){
            toast.error("We are facing some issue. Please try again later.");
        }else{
            this.setState({orders: data, orderHistoryApiResultCame:true});
            let dataTablePayload={
                columns:dataTableConfig.subOrders,
                rows:data.map((item, index) => {
                    item["action"]=<div color="primary" className={"blue-text btn-link cursor-pointer"} size="sm" onClick={()=>{this.onEdit(item)}}>View</div>;;
                    item["createdAt"]=this.convertToReadbleDate(item.createdAt);
                    // item["orderDate"]=this.convertToReadbleDate(item.orderDate);
                    item["customerName"]=item.customerDetails.name;
                    item["customerPhone"]=item.customerDetails.contact_no;
                    item["shipping"]=this.stringifyAddress(item.shippingAddress);
                    // item["totalItems"]=item.cart[0].cartData.length;
                    item["totalAmount"]=item.totalAmount.toFixed("2");
                    item["handleEdit"]=<div color="primary" className={"blue-text btn-link cursor-pointer"} size="sm" onClick={()=>{this.onEdit(item)}}>Edit</div>;
                    item["handleStatus"]=<div color="primary" className={"blue-text btn-link cursor-pointer"} size="sm" onClick={()=>{this.initChangeStatus(item)}}>{(item.status === "Y") ? "Deactivate" : "Activate"}</div>;

                    return item;
                })
            };
            this.setState({data:dataTablePayload});
        }
    }
    constructor(props) {
        super(props);
        this.state = {
            ready:false,
            orgId:0,
            userData:{},
            progressStatus:false,
            type:"all",
            page:1,
            pageSize:10,
            orders:[],
            data: {},
            modal:false,
            activeOrderHistory:"",
            activeOrderHistoryId:0,
            orderHistoryApiResultCame:false,
            modalStatus:false,
            statusActionName:"",
            modalOrderDetails:false,
            activeOrder:{
                cart:[
                    {cartItemDetails:[]},
                    {
                        cartData:[]
                    }
                ],
                docs:[],
            }
        }
    }
    onEdit=(item)=>{
        this.props.history.push('/order/'+item._id);
    }

    onView=(item)=>{
        console.log(item)
        this.setState({activeOrder:item})
        this.toggleOrderDetails()
    }

    convertToReadbleDate = date =>{
        console.log(date)
        return Moments(date).format("MMM D, YYYY");
    }

    // This toggle opens or closes the delete shipment modal
    toggleOrderDetails = () => {
        this.setState({
            modalOrderDetails: !this.state.modalOrderDetails
        });
    }
    convertMfgOrExpiry = date =>{
        if(date){
            return Moments(date).format("MMM YYYY");
        } else {
            return "";
        }
    }

    stringifyAddress = (address) => {
        let addressString = "";
        if(address.hasOwnProperty("address") && address.address){
            addressString = address.address.trim();
        }
        if(address.hasOwnProperty("street") && address.street){
            addressString = addressString + ", "+address.street.trim();
        }

        if(address.hasOwnProperty("area") && address.area){
            addressString = addressString + ", "+address.area.trim();
        }

        if(address.hasOwnProperty("city") && address.city){
            addressString = addressString + ", "+address.city.trim();
        }

        if(address.hasOwnProperty("state") && address.state){
            addressString = addressString + ", "+address.state.trim();
        }

        if(address.hasOwnProperty("country") && address.country){
            addressString = addressString + ", "+address.country.trim();
        }

        if(address.hasOwnProperty("pincode") && address.pincode){
            addressString = addressString + ", "+address.pincode.trim();
        }
        return addressString;
    }

    toggle() {
        this.setState((prevState) => ({
            ready: false
        }), () => {     // THIS IS THE HACK
            setTimeout(() => {
                this.setState({ ready: true });
            }, 1);
        });
    }


    render() {




        return (
            <React.Fragment>
                {this.state.progressStatus === true &&
                <span className={'top-stick'}><MDBProgress material preloader color="cyan"/></span>
                }
                <MDBBox>
                    <MDBCard className={"mt-2"}>
                        <MDBCardHeader>
                            <span className={"float-left"}>Orders</span>
                        </MDBCardHeader>
                        <MDBCardBody>

                            {!this.state.orderHistoryApiResultCame &&
                            <div style={{"text-align":"center"}}>
                                <img style={{"width":"4rem"}} src={require('../../components/common/img/loader_new.gif')}></img>
                            </div>
                            }

                            {(this.state.orderHistoryApiResultCame && this.state.orders.length < 1) &&
                            <div className={'no-data-container'}>No orders yet.</div>
                            }

                            {this.state.orders.length > 0 &&
                            <MDBDataTableV5
                                hover
                                entriesOptions={[10,15, 20, 25]}
                                entries={10}
                                pagesAmount={4}
                                data={this.state.data}
                                materialSearch
                                searchTop
                                searchBottom={false}
                                barReverse
                            />
                            }
                        </MDBCardBody>
                    </MDBCard>



                    <MDBModal isOpen={this.state.modalOrderDetails} toggle={this.toggleOrderDetails} size={"fluid"}>
                        <MDBModalHeader toggle={this.toggleOrderDetails}></MDBModalHeader>
                        <MDBModalBody className={"float-center"}>
                            <MDBTable small={true}>
                                <MDBTableHead>
                                    <tr>
                                        <th>Product</th>
                                        <th>Batch No</th>
                                        <th>Type</th>
                                        <th>Expiry Date</th>
                                        <th>MRP</th>
                                        <th>GST (%)</th>
                                        <th>Quantity</th>
                                        <th>Discount (%)</th>
                                        <th>Subtotal</th>
                                        <th>Patient</th>
                                        <th>Doctor</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {this.state.activeOrder.cart[0].cartItemDetails.map((row, index) => (
                                        <tr key={index+"_cart"} className={"pt-1 mt-1"}>

                                            <td>{row.productName}</td>
                                            <td>{row.batch}</td>
                                            <td>
                                                {(row.hasOwnProperty("isMedicine") &&  !row.isMedicine) &&
                                                <span>General</span>
                                                }
                                                {(row.hasOwnProperty("isMedicine") &&  row.isMedicine) &&
                                                <span>
                                                    {row.hasOwnProperty("medicineType") &&
                                                    <span className={(row.medicineType === "Schedule H" || row.medicineType === "Schedule H1" || row.medicineType === "Schedule X") ? "red" : "green" }> | {row.medicineType}</span>
                                                    }
                                                    {!row.hasOwnProperty("medicineType") &&
                                                    <span> | Not Specified</span>
                                                    }
                                                </span>
                                                }

                                                {(!row.hasOwnProperty("isMedicine")) &&
                                                <span>
                                                    {row.hasOwnProperty("medicineType") &&
                                                    <span className={(row.medicineType === "Schedule H" || row.medicineType === "Schedule H1" || row.medicineType === "Schedule X") ? "red" : "green" }> | {row.medicineType}</span>
                                                    }
                                                </span>
                                                }

                                            </td>
                                            <td>{this.convertMfgOrExpiry(row.expDate)}</td>
                                            <td>&#8377; {row.maxRetailPrice.toFixed("2")}</td>
                                            <td>{row.gst}</td>
                                            <td>
                                                {row.quantity}
                                            </td>

                                            <td>
                                                <div >
                                                    {row.discount}
                                                </div>
                                            </td>
                                            <td>&#8377; {row.subTotal}</td>


                                            <td size={"2"}>
                                                {(row.hasOwnProperty("selectedDependent") && row.selectedDependent.hasOwnProperty("name")) &&
                                                <span>
                                                    {row.selectedDependent.name &&
                                                    <span>{row.customerData.name} </span>
                                                    }
                                                    {(!row.selectedDependent.name && row.selectedDependent.first_name) &&
                                                    <span>{row.selectedDependent.first_name+ " " +row.selectedDependent.last_name} </span>
                                                    }
                                                </span>
                                                }
                                            </td>


                                            <td size={"2"}>
                                                {(row.hasOwnProperty("doctorDetails") && row.doctorDetails.hasOwnProperty("name")) &&
                                                <span>{row.doctorDetails.name}</span>
                                                }
                                            </td>



                                        </tr>
                                    ))}

                                </MDBTableBody>
                            </MDBTable>

                            <MDBRow>
                                <MDBCol>
                                    <b>Total Amount: &#8377; {this.state.activeOrder.totalAmount}</b>
                                </MDBCol>
                            </MDBRow>

                            {(this.state.activeOrder.hasOwnProperty("docs") ) &&
                            <div className={"mt-5"}>
                                {this.state.activeOrder.docs.map((row, index) => (
                                    <div style={{"textAlign": "center"}}>
                                        {row.hasOwnProperty("filePathOriginal") &&
                                        <img src={config.IMAGE_HOST + row.filePathOriginal}
                                             className={"float-center"}
                                             style={{maxWidth: "240px", maxHeight: "500px"}}/>
                                        }
                                    </div>
                                ))}
                            </div>
                            }

                        </MDBModalBody>
                    </MDBModal>
                </MDBBox>
            </React.Fragment>
        );
    }
}
export default withRouter(ManageOnlineOrders)


