import React from "react";
import {Link, withRouter} from "react-router-dom";
import {
    MDBContainer, MDBIcon,   MDBRow,    MDBBreadcrumb,    MDBBreadcrumbItem,    MDBInput,    MDBBtn,    MDBCardHeader,    MDBFormInline,    MDBCardBody,    MDBCard,    MDBBox,    MDBCol,    MDBSelect,
    MDBSelectInput,    MDBSelectOptions,    MDBSelectOption,    MDBChipsInput,    MDBChip,    MDBModalHeader, MDBModalBody, MDBModal
} from 'mdbreact';
import "./product.css";
import InventoryService from "../../services/inventoryService";
import { toast } from 'react-toastify';
import UserStore from "../../stores/userStore";
import AddProductImage from "../../components/common/fileUpload/addProductImage";
import SimpleBackdrop from "../../components/common/overlay";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import AddRetailPackage from "../../components/retail-package/addRetailPackage";
import AddQuantityUnit from "../../components/quantity-unit/addQuantityUnit";
import AddManufacturer from "../../components/manufacturer/addManufacturer";
import ProductSpecifications from "../../components/product/ProductSpecifications";
import Utility from "../../utility/utility";

class AddProduct extends React.Component {
    async componentDidMount() {
        this.setState({progressStatus:true});
        let userData=await UserStore.fetchSessionData();
        let medType=[];
        let retailPkgs=[];
        let qntyUnits=[];

        this.setState({userData: userData})
        console.log(this.state.userData)

        if(!window.retailPkgs || window.retailPkgs===null) {
            retailPkgs = await InventoryService.fetchAllRetailPackage(userData);
            window.retailPkgs=retailPkgs;
        }else{
            retailPkgs= window.retailPkgs
        }
        if(!window.qntyUnits || window.qntyUnits===null) {
            qntyUnits = await InventoryService.fetchAllQuantityUnit(userData);
            window.qntyUnits=qntyUnits;
        }else{
            qntyUnits=window.qntyUnits;
        }
        if(!window.medType || window.medType===null) {
            medType = await InventoryService.fetchAllMedicineType(userData);
            window.medType=medType;
        }else{
            medType=window.medType;
        }
        console.log(window)
        let retailPackaging = []
        for (let i of retailPkgs) {
            let item = {text: i.retailPackage, value: i.retailPackage}
            retailPackaging.push(item)
        }
        let quantityUnits = []
        for (let i of qntyUnits) {
            let item = {text: i.quantityUnit, value: i.quantityUnit}
            quantityUnits.push(item)
        }
        this.setState({retailPkgs:retailPkgs,qntyUnits:qntyUnits,progressStatus:false,medType:medType, retailPackaging, quantityUnits});
        //update add data
        this.updateData();
    }
    updateData=async()=>{
        let userData=await UserStore.fetchSessionData();
        let medType=[];
        let retailPkgs=[];
        let qntyUnits=[];
        retailPkgs = await InventoryService.fetchAllRetailPackage(userData);
        window.retailPkgs=retailPkgs;

        qntyUnits = await InventoryService.fetchAllQuantityUnit(userData);
        window.qntyUnits=qntyUnits;

        medType = await InventoryService.fetchAllMedicineType(userData);
        window.medType=medType;

        this.setState({retailPkgs:retailPkgs,qntyUnits:qntyUnits,progressStatus:false,medType:medType});
    }
    constructor(props) {
        super(props);
        this.state = {
            userData: {},
            highlight:[],
            description:"",
            howTouse:"",
            productIngredients:[],
            productSideEffects:[],
            productUses:[],
            productName:"",
            progressStatus:false,
            retailPkgs:[],
            qntyUnits:[],
            pkgQnty:'',
            pakgValSel:'',
            qntyUnitSel:'',
            imageUrl:[],
            suggestedManufacturer:[],
            mfgError:false,
            selectedMfg:'',
            suggestedMedicineType:[],
            selectedMedType:'',
            isDrug:false,
            medType:[],
            tags:[],
            resetForm:false,
            suggestedData:[],
            modalRetailPackaging:false,
            modalPackagingUnit:false,
            modalManufacturer:false,
            minimumLooseSaleQuantity:0,
            looseSaleAllowed:'N',
            keyRP:"",
            keyQU:"",
            ingredientList:[],
            tagList:[],
            highlightList:[],
            sideEffectList:[],
            productUseList:[],
            retailPackaging:[],
            quantityUnits:[],
        }
    }

    addMed =async () => {
        window.scrollTo(0, 0);
        let userData=await UserStore.fetchSessionData();

        let productIngredients = Utility.prepareArray(this.state.ingredientList),
            tags = Utility.prepareArray(this.state.tagList),
            highlight = Utility.prepareArray(this.state.highlightList),
            productSideEffects = Utility.prepareArray(this.state.sideEffectList),
            productUses = Utility.prepareArray(this.state.productUseList);
        this.setState({productIngredients:productIngredients, tags:tags, highlight:highlight,
            productSideEffects:productSideEffects, productUses:productUses})

        //validations
        if(this.state.pakgValSel==='' || this.state.pakgValSel===null){
            toast.error("Please select retail packaging.");
            return true;
        }
        if(this.state.qntyUnitSel==='' || this.state.qntyUnitSel===null){
            toast.error("Please select packaging unit.");
            return true;
        }
        if(this.state.productName==='' || this.state.productName===null){
            toast.error("Please enter valid product name.");
            return true;
        }
        if(this.state.highlight==='' || this.state.highlight===null || this.state.highlight==='Highlight'){
            toast.error("Please enter valid highlight.");
            return true;
        }
        if(this.state.description==='' || this.state.description===null || this.state.description==='Description'){
            toast.error("Please enter valid description.");
            return true;
        }
        if(this.state.howTouse==='' || this.state.howTouse===null || this.state.howTouse==='How to Use'){
            toast.error("Please enter valid how to use.");
            return true;
        }
        if(this.state.productIngredients==='' || this.state.productIngredients===null || this.state.productIngredients==='Ingredients'){
            toast.error("Please enter valid ingredients.");
            return true;
        }
        if(this.state.productSideEffects==='' || this.state.productSideEffects===null || this.state.productSideEffects==='Side Effects'){
            toast.error("Please enter valid side effects.");
            return true;
        }
        if(this.state.productUses==='' || this.state.productUses===null || this.state.productUses==='Product Uses'){
            toast.error("Please enter valid product uses.");
            return true;
        }
        if(isNaN(parseFloat(this.state.pkgQnty))) {
            toast.error("Please enter valid packaging quantity.");
            return true;
        }
        if(this.state.selectedMfg==='' || this.state.selectedMfg===null){
            toast.error("Please enter valid manufacturer details.");
            return true;
        }
        if(this.state.isDrug) {
            if (this.state.selectedMedType === '' || this.state.selectedMedType === null) {
                toast.error("Please enter valid medicine type.");
                return true;
            }
        }else{
            this.setState({selectedMedType:''});
        }
        if(this.state.looseSaleAllowed === "Y" && (isNaN(parseFloat(this.state.minimumLooseSaleQuantity)) || this.state.minimumLooseSaleQuantity < 1)) {
            toast.error("Please enter minimum loose sale quantity.");
            return true;
        }

        this.setState({progressStatus:true});
        let status=await InventoryService.createProduct(this.state.looseSaleAllowed,this.state.minimumLooseSaleQuantity,this.state.isDrug,this.state.tags,this.state.selectedMedType,this.state.selectedMfg,this.state,this.state.pkgQnty,this.state.pakgValSel[0],this.state.qntyUnitSel[0],this.state.productName,userData,this.state.highlight,this.state.description,this.state.howTouse,this.state.productIngredients,this.state.productSideEffects,this.state.productUses);
        this.setState({progressStatus:false});
        if(status===false){
            toast.error("Product name already exists.");
            return true;
        }else{
            toast.success("Product added successfully.");
            await this.setState({
                highlight:[],
                description:"",
                howTouse:[],
                productIngredients:[],
                productSideEffects:[],
                productUses:[],
                productName:"",
                pakgValSel:'',
                qntyUnitSel:"",
                pkgQnty:'',
                selectedMfg:'',
                selectedMedType:'',
                isDrug:false,
                tags:[],
                minimumLooseSaleQuantity:'',
                looseSaleAllowed:'N',
                suggestedData:[],
                ingredientList:[],
                tagList:[],
                highlightList:[],
                sideEffectList:[],
                productUseList:[]
            });
            this.setState({resetForm:true})
            this.finishedSavingFile();
            this.props.history.push('/product/cms/'+status._id);
        }
    }

    medName=(event)=>{
        if(event.target.value!=='') {
            this.setState({
                productName: event.target.value
            });
            this.productSuggest();
        }else{
            this.setState({suggestedData:[],productName:''});
        }
    }
    pkgQnty=(val)=>{
        this.setState({pkgQnty: val});
    }
    pakgValChange=(val)=>{
        this.setState({pakgValSel:val});
    }
    qntyUnitsChange=(val)=>{
        this.setState({qntyUnitSel:val});
    }

    fileUploadCallback = (files) => {
        this.setState({ "imageUrl": files, "progressStatusFileUpload":false})
    }

    initUploadCallback = () => {
        this.setState({"progressStatusFileUpload":true,  resetForm:false})
    }

    finishedSavingFile = () => {
        console.log("Done")
    }

    onChangeManufacturer= (event, values)=>{
        this.setState({selectedMfg:values});
    }
    fetchManufacturer = async (e) => {
        if(e.target.value!=='') {
            let manufacturer = await InventoryService.lookupManufacturer(e.target.value);
            if(manufacturer===false){
                toast.error("We are facing some issue. Please try again later.");
            }else{
                let temp = [];
                for (let item of manufacturer) {
                    let str = {title: item.manufacturer, value: item};
                    temp.push(str);
                }
                this.setState({suggestedManufacturer:temp});
            }
        }
    }
    isDrug=()=>{
        this.setState({
            isDrug: !this.state.isDrug
        });
    }
    qntyTypeChange=(val)=>{
        this.setState({selectedMedType:val[0]});
    }

    productSuggest=async()=>{
        let userData=await UserStore.fetchSessionData();
        let suggestedData = await InventoryService.suggestProduct(this.state.productName,userData);
        if(this.state.productName!=='') {
            this.setState({suggestedData: suggestedData});
        }
    }
    navigate=(item)=>{
        this.props.history.push('/edit-product/'+item._id);
    }

    toggleAddRetailPackaging = () => {
        this.setState({"modalRetailPackaging": !this.state.modalRetailPackaging})
    }
    toggleAddPackagingUnit = () => {
        this.setState({"modalPackagingUnit": !this.state.modalPackagingUnit})
    }
    toggleAddManufacturer= () => {
        this.setState({"modalManufacturer": !this.state.modalManufacturer})
    }
    retailPackageAdded = (res) => {
        this.toggleAddRetailPackaging();
        this.state.retailPkgs.push(res)
        this.resetKey()
    }
    packagingUnitAdded = (res) => {
        this.toggleAddPackagingUnit()
        this.state.qntyUnits.push(res)
        this.resetKey()
    }
    manufacturerAdded = (res) => {
        this.toggleAddManufacturer();
    }
    handleSellableUnit=(val)=>{
        this.setState({minimumLooseSaleQuantity:val});
    }
    looseSaleAllowedSell=(val)=>{
        if(val===true){
            this.setState({"looseSaleAllowed":"Y"});
        }else{
            this.setState({"looseSaleAllowed":"N"});
        }
    }
    calIsLoose=()=>{
        if(this.state.looseSaleAllowed==='Y'){
            return true;
        }else{
            return false;
        }
    }
    resetKey() {
        let randomString = Math.random().toString(36);
        this.setState({
            keyRP: randomString, keyQU: randomString,
        });
    }
    getIngredientListCallback = (list) => {
        this.setState({ingredientList:list})
    }
    getTagListCallback = (list) => {
        this.setState({tagList:list})
    }
    getHighlightListCallback = (list) => {
        this.setState({highlightList:list})
    }
    getSideEffectListCallback = (list) => {
        this.setState({sideEffectList:list})
    }
    getProductUseListCallback = (list) => {
        this.setState({productUseList:list})
    }
    render() {
        return (
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem><Link to={'/view-products'}>Products</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Add Product</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <SimpleBackdrop status={this.state.progressStatus}/>

                <div className={'col-md-10 mx-auto white rounded-sm border p-0'}>
                    <div className={'col-12 p-2 grey lighten-4 border-bottom d-flex justify-content-between align-items-center'}>
                        <b className={"font-weight-bold"}>Add Product</b>
                        <div className={'row mx-0'}>
                            <MDBBtn outline onClick={() =>this.props.history.push('/view-products')}
                                    disabled={this.state.progressStatus}>Back</MDBBtn>
                            <MDBBtn onClick={this.addMed}
                                    disabled={this.state.progressStatus}>Save</MDBBtn>
                        </div>
                    </div>
                    <table className={'table table-bordered p-3'}>
                        <tr>
                            <td className={'font-weight-bold'}>Product Name
                                <MDBInput id={"add_product_name_se"} background size="sm"
                                          value={this.state.productName} onChange={this.medName}/>
                            </td>
                            <td colSpan={'2'} className={'font-weight-bold'}>Manufacturer
                                <div className="input-group mb-3">
                                <Autocomplete freeSolo
                                              className={'col p-0'}
                                              onChange={this.onChangeManufacturer}
                                              id="combo-box-demo"
                                              options={this.state.suggestedManufacturer}
                                              getOptionLabel={option => option.title}
                                              onInputChange={(e) => {
                                                  this.fetchManufacturer(e)
                                              }}
                                              selectOnFocus={true}
                                              renderInput={params => (
                                                  <TextField background
                                                             style={ this.state.mfgError ?  {"borderBottom":"1px solid RED"} : {}} {...params}
                                                             placeholder="Search Manufacturer"/>
                                              )}
                                />
                                {(this.state.userData.type === "platformAdmin") &&
                                <div className="input-group-append">
                                    <MDBBtn className={'btn-sm m-0'} onClick={this.toggleAddManufacturer} alt="Add Manufacturer">
                                        <MDBIcon icon="plus" />
                                    </MDBBtn>
                                </div>
                                }
                            </div>
                            </td>
                        </tr>
                        {this.state.suggestedData.length > 0 &&
                        <tr>
                            <td colSpan={'3'} className={'border-top-0'}>
                                <b>Update existing products instead:<br/></b>
                                {this.state.suggestedData.map((item, idx) => (
                                    <MDBChip bgColor="light-blue lighten-4" key={idx} waves
                                                 onClick={()=>{this.navigate(item)}}>{item.productName}</MDBChip>
                                ))}
                            </td>
                        </tr>
                        }
                        <tr>
                            <td>
                                <b className={'font-weight-bold'}>Retail Packaging</b><br/>
                                <div className="input-group mb-3">
                                    <MDBSelect search hint="Select Retail Packaging"
                                               options={this.state.retailPackaging}
                                               getValue={this.pakgValChange}
                                               className={'form-control col grey-text p-0 font-weight-bold'}/><br/>
                                    {(this.state.userData.type === "platformAdmin") &&
                                    <div className="input-group-append">
                                        <MDBBtn className={'btn-sm m-0'} onClick={this.toggleAddRetailPackaging} alt="Add Retail Packaging">
                                            <MDBIcon icon="plus" />
                                        </MDBBtn>
                                    </div>
                                    }
                                </div>
                            </td>
                            <td  className={'font-weight-bold'}>
                                <b className={'font-weight-bold'}>Packaging Quantity</b><br/>
                                <MDBInput background className={'btn-sm m-0'} value={this.state.pkgQnty} getValue={this.pkgQnty}/>
                            </td>
                            <td>
                                <b className={'font-weight-bold'}>Packaging Unit</b><br/>
                                <div className="input-group mb-3">
                                    <MDBSelect search hint="Select Packaging Unit"
                                               options={this.state.quantityUnits}
                                               getValue={this.qntyUnitsChange}
                                               className={'form-control col grey-text p-0 font-weight-bold'}/>
                                    {(this.state.userData.type === "platformAdmin") &&
                                        <div className="input-group-append">
                                            <MDBBtn className={'btn-sm m-0'} onClick={this.toggleAddPackagingUnit} alt="Add Packaging Unit">
                                                <MDBIcon icon="plus" />
                                            </MDBBtn>
                                        </div>
                                    }
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td colSpan={'3'}>
                                <div className={'col-12 row mx-0 p-0'}>
                                    <div className={'col-md-3 pl-0'}>
                                        <b className={'font-weight-bold'}>Is Medicine</b>
                                        <div className={'row ml-n1 mr-0 p-0'}>
                                            <MDBInput onClick={this.isDrug} className={'p-0'}
                                                      checked={!this.state.isDrug} label="General"
                                                      type="radio" id="radio3" />
                                            <MDBInput onClick={this.isDrug} className={'p-0'}
                                                      checked={this.state.isDrug} label="Medicine"
                                                      type="radio" id="radio2" />
                                        </div>
                                    </div>
                                    {this.state.isDrug === true &&
                                    <div className={'col-md-3 pl-0'}>
                                        <b className={'font-weight-bold'}>Medicine Type</b>
                                        <div className="input-group mb-3">
                                            <MDBSelect getValue={this.qntyTypeChange} className={'form-control'}>
                                                <MDBSelectInput selected="Select Medicine Type"/>
                                                <MDBSelectOptions>
                                                    <MDBSelectOption value="" disabled selected>Select Medicine Type</MDBSelectOption>
                                                    {this.state.medType.map((item, idx) => (
                                                        <MDBSelectOption value={item.typeName}
                                                                         key={idx}>{item.typeName+" ("+item.typeDescription+")"}</MDBSelectOption>
                                                    ))}
                                                </MDBSelectOptions>
                                            </MDBSelect>
                                        </div>
                                    </div>
                                    }
                                    <div className={'col-md-3 pl-0'}>
                                        <b className={'font-weight-bold'}>Loose Sale Allowed</b>
                                        <MDBInput label=" " type="checkbox" id="checkbox_loose"
                                                  checked={this.calIsLoose()} getValue={this.looseSaleAllowedSell}/>
                                    </div>
                                    {this.state.looseSaleAllowed === 'Y' &&
                                    <div className={'col-md-3 font-weight-bold pl-0'}>
                                        <b className={'font-weight-bold'}>Minimum Loose Sale Quantity</b>
                                        <MDBInput id={"sellable_units_se"} background size="sm" type={"number"}
                                                  value={this.state.minimumLooseSaleQuantity}
                                                  getValue={this.handleSellableUnit}/>
                                    </div>
                                    }
                                </div>
                            </td>
                        </tr>


                        <tr>
                            <td className={'font-weight-bold'}>Tags</td>
                            <td colSpan={'2'}><ProductSpecifications section={'tag'} getListCallback={this.getTagListCallback} /></td>
                        </tr>
                        <tr>
                            <td className={'font-weight-bold'}>Description</td>
                            <td colSpan={'2'}>
                                <MDBInput type="textarea" background size="sm"
                                          rows="5" value={this.state.description}
                                          getValue={(val)=>{this.setState({description:val});}}/>
                            </td>
                        </tr>
                        <tr>
                            <td className={'font-weight-bold'}>Highlights</td>
                            <td colSpan={'2'}><ProductSpecifications section={'highlight'} getListCallback={this.getHighlightListCallback} /></td>
                        </tr>
                        <tr>
                            <td className={'font-weight-bold'}>How to Use</td>
                            <td colSpan={'2'}>
                                <MDBInput type="textarea" background size="sm"
                                          rows="5" value={this.state.howTouse}
                                          getValue={(val)=>{this.setState({howTouse:val});}}/>
                            </td>
                        </tr>
                        <tr>
                            <td className={'font-weight-bold'}>Ingredients</td>
                            <td colSpan={'2'}><ProductSpecifications section={'ingredient'} getListCallback={this.getIngredientListCallback} /></td>
                        </tr>
                        <tr>
                            <td className={'font-weight-bold'}>Side Effects</td>
                            <td colSpan={'2'}><ProductSpecifications section={'side effect'} getListCallback={this.getSideEffectListCallback} /></td>
                        </tr>
                        <tr>
                            <td className={'font-weight-bold'}>Product Uses</td>
                            <td colSpan={'2'}><ProductSpecifications section={'product use'} getListCallback={this.getProductUseListCallback} /></td>
                        </tr>
                        <tr>
                            <td colSpan={'3'}>
                                <div className={'col-12 add-img-min-height border amber lighten-5 rounded-sm mt-2'}>
                                    <AddProductImage buttonLabel={"Add Product Image"}
                                                     initUploadCallback={this.initUploadCallback}
                                                     fileUploadCallback={this.fileUploadCallback}
                                                     finishedSavingFile={this.finishedSavingFile}
                                                     nextProps={this.state.resetForm}></AddProductImage>
                                </div>
                            </td>
                        </tr>

                    </table>
                    <div className={'col-12 p-2 grey lighten-4 border-top d-flex justify-content-between align-items-center'}>
                        <b className={"font-weight-bold"}>Add Product</b>
                        <div className={'row mx-0'}>
                            <MDBBtn outline onClick={() =>this.props.history.push('/view-products')}
                                    disabled={this.state.progressStatus}>Back</MDBBtn>
                            <MDBBtn onClick={this.addMed}
                                    disabled={this.state.progressStatus}>Save</MDBBtn>
                        </div>
                    </div>
                </div>

                <MDBModal isOpen={this.state.modalRetailPackaging} toggle={this.toggleAddRetailPackaging} size={"lg"}>
                    <MDBModalHeader toggle={this.toggleAddRetailPackaging}>Add Retail Packaging</MDBModalHeader>
                    <MDBModalBody>
                        <AddRetailPackage subComponentActionPerformed={this.retailPackageAdded} isSubComponent={true}/>
                    </MDBModalBody>
                </MDBModal>
                <MDBModal isOpen={this.state.modalPackagingUnit} toggle={this.toggleAddPackagingUnit} size={"lg"}>
                    <MDBModalHeader toggle={this.toggleAddPackagingUnit}>Add Packaging Unit</MDBModalHeader>
                    <MDBModalBody>
                        <AddQuantityUnit subComponentActionPerformed={this.packagingUnitAdded}  isSubComponent={true}/>
                    </MDBModalBody>
                </MDBModal>
                <MDBModal isOpen={this.state.modalManufacturer} toggle={this.toggleAddManufacturer} size={"lg"}>
                    <MDBModalHeader toggle={this.toggleAddManufacturer}>Add Manufacturer</MDBModalHeader>
                    <MDBModalBody>
                        <AddManufacturer subComponentActionPerformed={this.manufacturerAdded} isSubComponent={true}/>
                    </MDBModalBody>
                </MDBModal>
            </React.Fragment>
        );
    }
}
export default withRouter(AddProduct)


