import React, {Suspense} from "react";
import {MDBBreadcrumb, MDBBreadcrumbItem} from "mdbreact";
import {Link} from "react-router-dom";
import RazorpayTools from "../../components/ThirdPartyIntegration/razorpayTools"

export default class Razorpay extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Razorpay Tools</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <Suspense fallback={null}>
                    <RazorpayTools/>
                </Suspense>
            </React.Fragment>
        )
    }
}