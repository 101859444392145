import axios from "axios";
import config from "../config";

const OrderService = {
  fetchCart: async function (data) {
    try {
      const headers = {
        headers: { Authorization: data.userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST + config.FETCH_CART,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  fetchInventoryByProductId: async function (data, productId) {
    try {
      const headers = {
        headers: { Authorization: data.userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST +
        config.FETCH_INVENTORY_BY_PRODUCT_ID +
        "?_id=" +
        productId +
        "&orgId=" +
        data.orgId,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  fetchOrderByOrderCode: async function (data) {
    try {
      const headers = {
        headers: { Authorization: data.userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST +
        config.FETCH_ORDER_BY_ORDER_CODE +
        "?orderCode=" +
        data.orderCode +
        "&orgId=" +
        data.orgId,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  fetchCallLogs: async function (sessionToken, orderId, page = 1) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.get(
        `${config.APIHOST_2 + config.FETCH_ONLINE_ORDERS_CALL_LOG}?orderId=${orderId}&page=${page}`,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  fetchProductRequestById: async function (data) {
    try {
      const headers = {
        headers: { Authorization: data.userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST +
        config.FETCH_PRODUCT_REQUEST_BY_ID +
        "?_id=" +
        data.productRequestId +
        "&orgId=" +
        data.orgId,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  returnOfflineOrderInward: async function (data) {
    try {
      const headers = {
        headers: { Authorization: data.userData.sessionToken },
      };

      let payload = data.order;
      payload.totalReturnedAmount = data.totalReturnedAmount;
      payload.returnedProducts = data.returnedProducts;
      const response = await axios.post(
        config.APIHOST + config.CREATE_OFFLINE_ORDER_RETURN_INWARD,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  createOfflineOrder: async function (data) {
    try {
      const headers = {
        headers: { Authorization: data.userData.sessionToken },
      };
      let paidAmount = data.paidAmount ? data.paidAmount : 0;

      let products = [];
      for (let product of data.carts) {
        let item = {};
        if (product.quantity > 0) {
          item._id = product._id;
          item.quantity = product.quantity;
          item.contentQuantity = product.contentQuantity;
          item.numberOfSellableUnits = product.numberOfSellableUnits;
          item.looseSaleAllowed = product.looseSaleAllowed;
          item.minimumLooseSaleQuantity = product.minimumLooseSaleQuantity;

          item.discount = product.discount;
          item.inventory = product.inventory;
          item.patientDetails = product.selectedDependent;
          item.doctorDetails = product.doctorDetails;
          item.productName = product.productName;
          item.medicineType = product.medicineType;
          item.isMedicine = product.isMedicine;
          products.push(item);
        }
      }
      let payload = {
        orgId: data.orgId,
        orderMode: data.orderMode,
        //orderStatus:data.orderStatus,
        paymentMode: data.paymentMode,
        //status:"CREATED",
        //cartId:data.cartId,
        paidAmount: paidAmount,
        balanceAmount: data.totalAmount - paidAmount,
        totalAmount: parseFloat(data.totalAmount),
        customerDetails: data.user,
        docs: data.docs,
        orderDate: data.orderDate,
        products: products,
        casierId: data.cashierId,
      };
      const response = await axios.post(
        config.APIHOST + config.CREATE_OFFLINE_ORDER,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  createOfflineOrderNew: async function (payload, sessionToken) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.post(
        config.APIHOST + config.CREATE_OFFLINE_ORDER,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  updateOfflineOrder: async function (data) {
    try {
      const headers = {
        headers: { Authorization: data.userData.sessionToken },
      };

      let payload = data.activeOrder;
      const response = await axios.post(
        config.APIHOST + config.UPDATE_OFFLINE_ORDER,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  updateShippingAddressOfflineOrder: async function (address, data) {
    try {
      const headers = {
        headers: { Authorization: data.userData.sessionToken },
      };

      let payload = {
        shippingAddress: address,
        _id: data.activeOrder._id,
      };
      const response = await axios.post(
        config.APIHOST + config.UPDATE_SHIPPING_ADDRESS_FOR_OFFLINE_ORDER,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  deleteOfflineOrder: async function (data) {
    try {
      const headers = {
        headers: { Authorization: data.userData.sessionToken },
      };
      let payload = {
        orgId: data.orgId,
        _id: data.orderToBeDeleted._id,
      };
      const response = await axios.post(
        config.APIHOST + config.DELETE_OFFLINE_ORDER,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  cancelOfflineOrder: async function (data) {
    try {
      const headers = {
        headers: { Authorization: data.userData.sessionToken },
      };
      let payload = {
        orgId: data.orgId,
        _id: data.orderToBeDeleted._id,
      };
      const response = await axios.post(
        config.APIHOST + config.CANCEL_OFFLINE_ORDER,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  payOutStanding: async function (data) {
    try {
      const headers = {
        headers: { Authorization: data.userData.sessionToken },
      };
      let payload = {
        orgId: data.orgId,
        _id: data.orderToBePaid._id,
        paymentMode: data.paymentMode,
        amount: parseFloat(data.paidAmount),
        isPaymentPending:
          data.orderToBePaid.balanceAmount - data.paidAmount < 1 ? "N" : "Y",
      };
      const response = await axios.post(
        config.APIHOST + config.PAY_OUTSTANDING,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  addItemToCart: async function (inventoryData, data, quantity) {
    try {
      const headers = {
        headers: { Authorization: data.userData.sessionToken },
      };
      let payload = {
        customerId: data.user.dependent_id, // Note JAVA API has different format
        inventoryData: inventoryData,
        quantity: !quantity ? 1 : quantity,
        _id: inventoryData.productDetails._id,
        discount: inventoryData.discount,
        doctorDetails: data.provider,
        customerData: data.user,
        cartId: data.cartId ? data.cartId : "",
      };
      payload.customerData.dependent = data.selectedDependent;
      const response = await axios.post(
        config.APIHOST + config.ADD_TO_CART,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  removeItemFromCart: async function (item, data) {
    try {
      const headers = {
        headers: { Authorization: data.userData.sessionToken },
      };
      let payload = {
        customerId: data.user.dependentId,
        quantity: 1,
        _id: item._id,
      };
      const response = await axios.post(
        config.APIHOST + config.REMOVE_CART_ITEM,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  updateOrderStatus: async function (data, payloaddata) {
    try {
      const headers = {
        headers: { Authorization: data.userData.sessionToken },
      };
      let payload = {
        orderStatus: payloaddata.status,
        _id: data.orderId,
        comment: payloaddata.description,
      };
      const response = await axios.post(
        config.APIHOST + config.UPDATE_ONLINE_ORDER_STATUS,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  updateOnlinePaymentStatus: async function (payload, sessionToken) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.post(
        config.APIHOST + config.UPDATE_ONLINE_PAYMENT_STATUS,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  fetchProductRequest: async function (data) {
    try {
      const headers = {
        headers: { Authorization: data.userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST + config.FETCH_PRODUCT_REQUESTS + "?orgId=" + data.orgId,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  fetchProductRequestV3: async function (
    orgId,
    sessionToken,
    startDate,
    endDate
  ) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.get(
        config.APIHOST +
        config.FETCH_PRODUCT_REQUEST_V3 +
        "?orgId=" +
        orgId +
        "&date=" +
        startDate +
        "&endDate=" +
        endDate,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  // This is deprecated, order history pulls both online and offline order
  fetchOrderHistory: async function (data) {
    try {
      const headers = {
        headers: { Authorization: data.userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST + config.FETCH_ORDERS + "?orgId=" + data.orgId,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  fetchOfflineOrder: async function (data, startDate, endDate) {
    try {
      const headers = {
        headers: { Authorization: data.userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST +
        config.FETCH_OFFLINE_ORDERS +
        "?orgId=" +
        data.orgId +
        "&startDate=" +
        startDate +
        "&endDate=" +
        endDate +
        "&location=" +
        data.selectedLocation,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  fetchOfflineReturnOrdersInward: async function (data, startDate, endDate) {
    try {
      const headers = {
        headers: { Authorization: data.userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST +
        config.FETCH_OFFLINE_ORDERS_RETURN_INWARD +
        "?orgId=" +
        data.orgId +
        "&startDate=" +
        startDate +
        "&endDate=" +
        endDate,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  fetchOnlineOrders: async function (
    sessionToken,
    startDate,
    endDate,
    status,
    deliveryTypeList
  ) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      let response = [];
      if (status !== undefined && status !== "ALL") {
        response = await axios.get(
          config.APIHOST +
          config.FETCH_ONLINE_ORDERS +
          "?startDate=" +
          startDate +
          "&endDate=" +
          endDate +
          "&status=" +
          status +
          "&deliveryTypeList=" +
          deliveryTypeList,
          headers
        );
      } else {
        response = await axios.get(
          config.APIHOST +
          config.FETCH_ONLINE_ORDERS +
          "?startDate=" +
          startDate +
          "&endDate=" +
          endDate,
          headers
        );
      }

      return response.data;
    } catch (e) {
      return false;
    }
  },

  fetchUnfulfilledOrders: async function (
    sessionToken,
    startDate,
    endDate,
    pageNo = 0,
    sortBy,
    sortOrder,
    status,
    orderAmount
  ) {
    try {
      console.log(sessionToken)
      const headers = {
        headers: { Authorization: sessionToken },
      };

      let url =
        config.APIHOST_2 +
        config.FETCH_UNFULFILLED_ORDERS +
        "?startDate=" +
        startDate +
        "&endDate=" +
        endDate +
        "&pageNo=" +
        pageNo+
        "&orderType=USER_CART&orderType=PRESCRIPTION"+
        "&isZeroValue="+
        orderAmount
      

      if (sortBy && sortOrder) {
        url += "&sortBy=" + sortBy + "&sortOrder=" + sortOrder;
      }
      if(status.length === 0){
        return false;
      }
      if (status.length > 0) {
        status.forEach((item) => (url += "&statusFilter=" + item));
      }
      // if (orderType.length > 0) {
      //   orderType.forEach((item) => (url += "&orderType=" + item));
      // }

      let response = await axios.get(url, headers);

      return response.data;
    } catch (e) {
      return false;
    }
  },

  fetchSearchedUnfulfilledOrders: async function (sessionToken, term,page=1) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.get(
        config.APIHOST_2 + config.FETCH_SEARCHED_UNFULFILLED_ORDERS + "?term=" + term + "&pageNo="+page,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  fetchOnlineOrderById: async function (sessionToken, orderId) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.get(
        config.APIHOST + config.FETCH_ONLINE_ORDERS_BY_ID + "?_id=" + orderId,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  fetchInventoryById: async function (sessionToken, productId) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.get(
        config.APIHOST +
        config.FETCH_ONLINE_ORDERS_INVENTORY_BY_ID +
        "?productId=" +
        productId,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  ackOnlineOrder: async function (sessionToken, orderId) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.get(
        config.APIHOST +
        config.ACKNOWLEDGE_ONLINE_ORDER +
        "?orderId=" +
        orderId,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  uploadDocument: async function (sessionToken, payload) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.post(
        config.APIHOST + config.UPLOAD_DOCUMENT,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  requestDocument: async function (sessionToken, payload) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.post(
        config.APIHOST + config.REQUEST_DOCUMENT,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  cancelOnlineOrder: async function (sessionToken, payload) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.post(
        config.APIHOST + config.CANCEL_ONLINE_ORDER,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  approveOnlineOrder: async function (sessionToken, payload) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.post(
        config.APIHOST + config.APPROVE_ONLINE_ORDER,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  readyForDeliveryOnlineOrder: async function (sessionToken, payload) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.post(
        config.APIHOST + config.READY_FOR_DELIVERY_ONLINE_ORDER,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  fulfilOnlineOrder: async function (sessionToken, payload) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.post(
        config.APIHOST + config.FULFIL_ONLINE_ORDER,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  fetchDeliveryPersonDetails: async function (sessionToken) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.get(
        config.APIHOST + config.FETCH_DELIVERY_PERSON_DETAILS,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  outForDeliveryOnlineOrder: async function (sessionToken, payload) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.post(
        config.APIHOST + config.OUT_FOR_DELIVERY_ONLINE_ORDER,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  markAsDeliveredOnlineOrder: async function (sessionToken, payload) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.post(
        config.APIHOST + config.MARK_AS_DELIVERED_ONLINE_ORDER,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  fetchSubOrderDetail: async function (data) {
    try {
      const headers = {
        headers: { Authorization: data.userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST +
        config.FETCH_SUBORDER_BY_ID +
        "?orgId=" +
        data.orgId +
        "&_id=" +
        data.orderId,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  addNotifyProductAvailability: async function (data) {
    try {
      const headers = {
        headers: { Authorization: data.userData.sessionToken },
      };
      let payload = {
        // orgId: data.orgId,
        productData: data.productRequestData,
        priority: data.priority,
        status: data.status,
        contactNo: data.contactNo,
        email: data.email,
        name: data.name,
      };
      const response = await axios.post(
        config.APIHOST + config.CREATE_NOTIFY_AVAILABILITY,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  convertOnlineOrder: async function (payload, sessionToken) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.post(
        config.APIHOST + config.CONVERT_ONLINE_ORDER,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  editProductRequest: async function (data) {
    try {
      const headers = {
        headers: { Authorization: data.userData.sessionToken },
      };
      let payload = {
        _id: data.productRequestId,
        orgId: data.orgId,
        productId: data.productId,
        productName: data.productName,
        quantity: data.quantity,
        priority: data.priority,
        status: data.status,
        customerDetails: { _id: "", name: data.name },
        customerPhone: data.contactNo,
        comments: data.comments,
        isDays: data.isDays,
        expectedDeliveryDays: data.expectedDeliveryDays,
        expectedDeliveryHours: data.expectedDeliveryHours,
      };
      const response = await axios.post(
        config.APIHOST + config.EDIT_PRODUCT_REQUEST,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  updateMultipleProductRequest: async function (items, key, val, sessionToken) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      let payload = {
        items: items,
        key: key,
        val: val,
      };
      const response = await axios.post(
        config.APIHOST + config.UPDATE_MULTIPLE_PRODUCT_REQUEST,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  fetchPrescriptionOrders: async function (userData) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST +
        config.FETCH_PRESCRIPTION_ORDERS +
        "?orgId=" +
        userData.org[0]._id,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  fetchPrescriptionOrderById: async function (userData, orderId) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.get(
        config.APIHOST +
        config.FETCH_PRESCRIPTION_ORDER_BY_ID +
        "?orgId=" +
        userData.org[0]._id +
        "&_id=" +
        orderId,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  updatePrescriptionOrderStatus: async function (userData, data) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      let payload = {
        _id: data.orderId,
        orderStatus: data.orderStatus,
        comment: data.statusComment,
      };
      const response = await axios.post(
        config.APIHOST + config.UPDATE_PRESCRIPTION_ORDER_STATUS,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  processPrescriptionOrder: async function (userData, data) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      let payload = data;
      const response = await axios.post(
        config.APIHOST + config.PROCESS_PRESCRIPTION_ORDER,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  addProductRequest: async function (payload, sessionToken) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.post(
        config.APIHOST + config.CREATE_PRODUCT_REQUEST,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  addPurchaseNote: async function (payload, sessionToken) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.post(
        config.APIHOST + config.CREATE_PURCHASE_NOTE,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  editPurchseNote: async function (payload, sessionToken) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.post(
        config.APIHOST + config.EDIT_PURCHASE_NOTE,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  fetchPurchaseNote: async function (sessionToken, orgId, date) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.get(
        config.APIHOST +
        config.FETCH_PURCHASE_NOTE +
        "?orgId=" +
        orgId +
        "&date=" +
        date,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  fetchStorageLocation: async function (sessionToken) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.get(
        config.APIHOST + config.FETCH_STORAGE_LOCATION,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  addStorageLocation: async function (sessionToken, payload) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.post(
        config.APIHOST + config.ADD_STORAGE_LOCATION,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  updateStorageLocation: async function (sessionToken, payload) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.post(
        config.APIHOST + config.UPDATE_STORAGE_LOCATION,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  deleteStorageLocation: async function (sessionToken, storeId) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.get(
        config.APIHOST + config.DELETE_STORAGE_LOCATION + "?storeId=" + storeId,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  fetchStorageLocationById: async function (sessionToken, locId) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.get(
        config.APIHOST +
        config.FETCH_STORAGE_LOCATION_BY_ID +
        "?locId=" +
        locId,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  fetchFloors: async function (sessionToken, locId) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.get(
        config.APIHOST + config.FETCH_FLOORS + "?locId=" + locId,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  addFloors: async function (sessionToken, payload) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.post(
        config.APIHOST + config.ADD_FLOORS,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  editFloor: async function (sessionToken, payload) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.post(
        config.APIHOST + config.EDIT_FLOOR,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  deleteFloor: async function (sessionToken, floorId) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.get(
        config.APIHOST + config.DELETE_FLOOR + "?floorId=" + floorId,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  fetchFloorById: async function (sessionToken, floorId) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.get(
        config.APIHOST + config.FETCH_FLOORS_BY_ID + "?floorId=" + floorId,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  addRacks: async function (sessionToken, payload) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.post(
        config.APIHOST + config.ADD_RACKS,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  editRack: async function (sessionToken, payload) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.post(
        config.APIHOST + config.EDIT_RACK,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  deleteRack: async function (sessionToken, rackId) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.get(
        config.APIHOST + config.DELETE_RACK + "?rackId=" + rackId,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  fetchRackById: async function (sessionToken, rackId) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.get(
        config.APIHOST + config.FETCH_RACK_BY_ID + "?rackId=" + rackId,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  fetchRacks: async function (sessionToken, floorId) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.get(
        config.APIHOST + config.FETCH_RACKS + "?floorId=" + floorId,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  fetchBoxes: async function (sessionToken, rackId) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.get(
        config.APIHOST + config.FETCH_BOXES + "?rackId=" + rackId,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  addBoxes: async function (sessionToken, payload) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.post(
        config.APIHOST + config.ADD_BOXES,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  editBox: async function (sessionToken, payload) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.post(
        config.APIHOST + config.EDIT_BOX,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  deleteBox: async function (sessionToken, boxId) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.get(
        config.APIHOST + config.DELETE_BOX + "?boxId=" + boxId,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  updateTrackingUrl: async function (userData, payload) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      const response = await axios.post(
        config.APIHOST + config.UPDATE_TRACKING_URL,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  fetchFeedback: async function (
    sessionToken,
    startDate,
    endDate,
    rating,
    message
  ) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.get(
        config.APIHOST +
        config.FETCH_FEEDBACK +
        "?startDate=" +
        startDate +
        "&endDate=" +
        endDate +
        "&rating=" +
        rating +
        "&message=" +
        message,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  fetchAllOrders: async function (sessionToken, startDate, endDate) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.get(
        config.APIHOST +
        config.FETCH_ALL_ORDERS +
        "?startDate=" +
        startDate +
        "&endDate=" +
        endDate,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  cancelOrderV3: async function (userData, orderId, comment) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      let payload = {
        orderId: orderId,
        comment: comment,
      };
      const response = await axios.post(
        config.APIHOST + config.CANCEL_ORDER_V3,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  editOrderV3: async function (
    userData,
    collectedAmount,
    paymentMode,
    orderId
  ) {
    try {
      const headers = {
        headers: { Authorization: userData.sessionToken },
      };
      let payload = {
        orderId: orderId,
        collectedAmount: collectedAmount,
        paymentMode: paymentMode,
      };
      const response = await axios.post(
        config.APIHOST + config.EDIT_ORDER_V3,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  searchOrder: async function (sessionToken, term) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.get(
        config.APIHOST + config.SEATCH_ORDER_V3 + "?searchTerm=" + term,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  addCallLog: async function (payload, sessionToken) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.post(
        config.APIHOST + config.ADD_CALL_LOG,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  returnItem: async function (payload, sessionToken) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.post(
        config.APIHOST + config.RETURN_ITEM,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  fetchCustomerOrders: async function (sessionToken, customerId) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.get(
        config.APIHOST +
        config.FETCH_CUSTOMER_ORDERS +
        "?customerId=" +
        customerId,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  fetchFilteredOnlineOrder: async function (
    sessionToken,
    status,
    startDate,
    endDate
  ) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.get(
        config.APIHOST +
        config.FETCH_FILTERED_ONLINE_ORDERS +
        "?status=" +
        status +
        "&startDate=" +
        startDate +
        "&endDate=" +
        endDate,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  updateChecklist: async function (sessionToken, orderId, payload) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.post(
        config.APIHOST + config.UPDATE_CHECKLIST + "?orderId=" + orderId,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  fetchChecklist: async function (sessionToken, orderId) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.get(
        config.APIHOST + config.FETCH_CHECKLIST + "?orderId=" + orderId,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  assignOrder: async function (sessionToken, payload) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.post(
        config.APIHOST + config.ASSIGN_ORDER,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  createOnlineOrder: async function (sessionToken, payload) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.post(
        config.APIHOST + config.CREATE_ONLINE_ORDER,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  fetchSubscriptionList: async function (sessionToken) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.get(
        config.APIHOST + config.FETCH_SUBSCRIPTIONS,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  fetchSubscriptionListNew: async function (sessionToken, sortBy, sortOrder, page=1) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };

      let url = config.APIHOST_2 + config.FETCH_SUBSCRIPTIONS_NEW + "?pageNo=" + page;

      if (sortBy && sortOrder) {
        url += "&sortBy=" + sortBy + "&sortOrder=" + sortOrder;
      }
      const response = await axios.get(
        url,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  createSubscription: async function (sessionToken, payload) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };

      let url = config.APIHOST_2 + config.CREATE_SUBSCRIPTIONS

      const response = await axios.post(
        url,
        payload,
        headers
      );
      return response;
    } catch (e) {
      return false;
    }
  },
  deleteSubscription: async function (sessionToken, payload) {
    console.log(sessionToken,payload)
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.post(
        config.APIHOST + config.DELETE_SUBSCRIPTION,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
  sendGoogleReviewSMS: async function (phone, feedbackId) {
    console.log(phone);
    console.log(feedbackId);
    try {
      // const headers = {
      //     headers: {Authorization: sessionToken}
      // };
      const response = await axios.get(
        config.APIHOST +
        config.SEND_GOOGLE_REVIEW_SMS +
        "?customerPhone=" +
        phone +
        "&feedbackId=" +
        feedbackId +
        "&forcefullySend=false"
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  addRazorpayDetails: async function (payload, sessionToken) {
    try {
      const headers = {
        headers: { Authorization: sessionToken },
      };
      const response = await axios.post(
        config.APIHOST + config.ADD_RAZORPAY_DETAILS,
        payload,
        headers
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },

  viewOrderFromOrderCode: async function (orderCode) {
    try {
      const response = await axios.get(
        config.APIHOST +
        config.VIEW_ORDER_FROM_ORDER_CODE +
        "?orderCode=" +
        orderCode
      );
      return response.data;
    } catch (e) {
      return false;
    }
  },
};

export default OrderService;
