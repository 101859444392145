import React,{Suspense} from "react";
import {
    MDBBox
} from 'mdbreact';
// const UpdateCustomer=React.lazy(() => import("../../components/customer/updateCustomer"));
import UpdateCustomer from "../../components/customer/updateCustomer";

export default class UpdateCustomerPage extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MDBBox className={"min-vh-100"}>
                    <Suspense fallback={null}>
                        <UpdateCustomer  queryString={this.props.match.params}/>
                    </Suspense>
                </MDBBox>
            </React.Fragment>
        );
    }
}
