import React,{Suspense} from "react";
import {Link} from "react-router-dom";
import {
    MDBBreadcrumbItem, MDBBreadcrumb, MDBBox
} from 'mdbreact';
// const AddCategoryCmp=React.lazy(() => import("../../components/categories/addCategoryCmp"));
import AddCategoryCmp from "../../components/categories/addCategoryCmp";


export default class AddCategory extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem><Link to={'/view-product-categories'}>Product Categories</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Add Product Category</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <MDBBox className={"min-vh-100"}>
                    <Suspense fallback={null}>
                        <AddCategoryCmp/>
                    </Suspense>
                </MDBBox>
            </React.Fragment>
        );
    }
}


