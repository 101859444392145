import React, {Suspense} from "react";
import {MDBBreadcrumb, MDBBreadcrumbItem} from "mdbreact";
import OnlinePrescriptionOrder from "../../components/online-prescription-order/listPrescriptionOrder"
import {Link} from "react-router-dom";

export default class ManageOnlinePrescriptionOrder extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>View Prescription Orders</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <Suspense fallback={null}>
                    <OnlinePrescriptionOrder/>
                </Suspense>
            </React.Fragment>
        )
    }
}