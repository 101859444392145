import React from "react";
import {
    MDBBtn, MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCol,
    MDBDataTable, MDBIcon, MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader,
    MDBRow
} from "mdbreact";
import Moments from "moment";
import {toast} from "react-toastify";
import ReportService from "../../services/reports";
import UserStore from "../../stores/userStore";
import SimpleBackdrop from "../../components/common/overlay";
import utility from "../../utility/utility";
import dataTableConfig from "./dataTableCustomerInsights"
import { withRouter } from "react-router-dom";
import config from "../../config";
import PharmacyService from "../../services/pharmacyService";
import AuthService from "../../services/authService";
import Dependents from "../Dependents/Dependents";
import AddAddress from "../common/addAddress";
import UpdateCustomer from "../customer/updateCustomer";

class CustomerInsights extends React.Component {
    constructor() {
        super();
        this.state = {
            memberSince:"",
            customerId:"",
            data: [],
            frequentProducts:[],
            frequentCustomers:[],
            topCustomers:[],
            headers: [],
            progressStatus: false,
            startDate:'',
            endDate:'',
            topCustomerKey:"",
            dataTablePayload:{},
            orders:[],
            ordersTableData:[],
            totalFilteredTransactions:0,
            totalFilteredTransactionsAmount:0,
            totalFilteredCreditTransactions:0,
            totalFilteredTransactionsAmountPending:0,
            customerInsightsApiResultCame:false,

            name:"",
            contactNo:"",
            email:"",
            age:"",
            dob:"",
            gender:"",
            addresses:[],
            dependents:[],

            modalDependent: false,
            modal:false,
            modalDeleteAddress:false,
            selectedAddress:{},
            selectedDependent:{},
            initEditAddress:false,
            user:{},
            orgId:"",
            modalEditCustomer:false,
            totalAmount:"",
            totalOrders:0,
            creditOrders:0,
            totalOutstanding:0,
            productData:[]
        }
    }

    async componentDidMount() {
        let customerId  = this.props.queryString;

        await this.setState({customerId:customerId});

        let userData = await UserStore.fetchSessionData();
        this.setState({userData: userData})
        if(userData.type === "platformAdmin"){
            this.setState({orgId: userData._id})
            this.setState({numId: userData.numId})
        } else if(userData.type === "ORG_ADMIN"){
            this.setState({orgId: userData.orgDetails[0]._id})
            this.setState({numId: userData.orgDetails[0].numId})
        }

        await this.fetchCustomerById()
        this.getDateRangeCallback(Moments(new Date()).format("YYYY-MM-DD"), Moments(new Date()).format("YYYY-MM-DD"))
    }
    getDateRangeCallback = (startDate, endDate) => {
        if (startDate > endDate) {
            toast.info("Start date is greater than the end date. Please adjust the date range again.")
            return
        }
        this.getData(startDate, endDate).then(res => {
            if (res === false) {
                toast.error("We are facing some issue. Please try again later.");
            } else {
                this.formatData(res)
            }
        })
    }

    fetchCustomerById = async () => {
        let userData=await UserStore.fetchSessionData();
        this.setState({progressStatus:true});
        let res = await PharmacyService.fetchCustomerById(this.state, userData);
        if(res){
            try{
                this.setState({user:res})
                this.setState({customerApiResultCame:true})
                this.setState({progressStatus:false});
                await this.setState({name: res.name, contactNo: res.contactNo, email:res.email, dob:res.dob, gender:res.gender,memberSince:res.memberSince})
                if(!res.dob){
                    this.setState({dob:null})
                }
                this.fetchAllAddress()
                this.fetchDependentsByUserId(res._id)
            } catch (e) {
                toast.error("Something went wrong while fetching customer by id.")
           }
        }
    }

    fetchAllAddress = async() => {
        let userData=await UserStore.fetchSessionData();
        let addresses =await PharmacyService.fetchAllAddress(this.state.user._id,userData);
        console.log(addresses);
        if(!addresses){
            toast.error("Something went wrong while fetching addresses.")
        } else if( addresses.length > 0){
            this.setState({addresses:addresses});
        }
    }
    fetchDependentsByUserId = async (id) => {
        this.setState({progressStatus:true});
        let data =await AuthService.fetchDependentsByUserId(this.state, id);
        this.setState({progressStatus:false});
        if(data===false){
            toast.error("Something went wrong...");
            return true;
        }else{
            this.setState({dependents:data})
        }
    }

    formatData = async (data) => {
        this.setState({
            salesApiResultCame:true,
            customerInsightsApiResultCame:true,
            totalAmount:data.totalAmount,
            totalOrders:data.totalOrders,
            creditOrders:data.creditOrders,
            totalOutstanding:data.totalOutstanding
        })
        // await this.setState({data: data[0], salesApiResultCame:true});
        // let frequentProducts={
        //     columns:dataTableConfig.mostFrequentProducts,
        //     rows:data[0].mostFrequentProducts.map((item, index) => {
        //         item["handleProductName"]=item._id;
        //         return item;
        //     })
        // };
        //
        // await this.setState({frequentProducts:frequentProducts, topCustomers: data[0].topCustomers });
        // await this.setState({topCustomerKey:1})
        //
        // // Order history for the customer
        //
        let dataTablePayload={
            columns:dataTableConfig.orderHistory,
            rows:data.orders.map((item, index) => {
                item["handleOrderDate"]=utility.convertToReadbleDate(item.orderDate);
                item["totalItems"]= item.hasOwnProperty("itemCount") ? item.itemCount : "NA";
                item["outstandingAmount"]= parseFloat(item.balanceAmount.toFixed("2"))
                item["totalAmountFormated"]= parseFloat(item.orderAmount.toFixed("2"));
                return item;
            })
        };
        this.setState({ordersTableData:dataTablePayload, orders:data.orders});
        // let subtotal = 0;
        // let totalOrder = 0;
        // let creditOrders = 0;
        // let pendingAmount = 0
        // for(let order of this.state.orders) {
        //     subtotal = subtotal + order.totalAmount;
        //     totalOrder++;
        //     if(order.paymentMode === "CREDIT"){
        //         creditOrders++;
        //         pendingAmount = pendingAmount + order.totalAmount
        //     }
        // }
        // this.setState({totalFilteredTransactions:totalOrder, totalFilteredTransactionsAmount:subtotal, totalFilteredCreditTransactions:creditOrders, totalFilteredTransactionsAmountPending: pendingAmount  })
        // this.setState({customerInsightsApiResultCame:true})
        // await this.setState({disableRetreatAfterSorting:false});

        //fetch most frequesnt products
        let userData = await UserStore.fetchSessionData();
        let productData = await ReportService.fetchProductInsights(userData, this.state.contactNo);
        let frequentProducts={
            columns:dataTableConfig.mostFrequentProducts,
            rows:productData.map((item, index) => {
                item["handleProductName"]=item.medicineName;
                item["totalSaleAmount"]=item.totalSaleAmount.toFixed(2)
                return item;
            })
        };
        await this.setState({frequentProducts:frequentProducts,productData:productData});
    }
    getData = async (startDate, endDate) => {
        let userData = await UserStore.fetchSessionData();
        this.setState({progressStatus: true});
        let data = await ReportService.fetchCustomerInsights(userData, this.state.contactNo);
        this.setState({progressStatus: false});
        return data
    }

    openAddAddress = () =>{
        this.toggle()
    };
    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
        this.setState({"activeAddress": {
                name: "",
                phone:"",
                address:"",
                street:"",
                pincode:"",
                area: "",
                city: "",
                state: "",
                country: "",
                landmark: "",
            }});
    }
    addDependentCompleted = (id) => {
        this.fetchDependentsByUserId(id)
        this.toggleDependent();
    }

    editCustomerCompleted = () => {
        this.toggleEditCustomer()
        this.fetchCustomerById()
    }

    deleteCustomerCompleted = () => {
        this.toggleEditCustomer()
        this.resetCustomer()
    }

    resetCustomer = () => {
        this.setState({
            customerId:"",
            data: [],
            frequentProducts:[],
            frequentCustomers:[],
            topCustomers:[],
            headers: [],
            progressStatus: false,
            startDate:'',
            endDate:'',
            topCustomerKey:"",
            dataTablePayload:{},
            orders:null,
            ordersTableData:[],
            totalFilteredTransactions:0,
            totalFilteredTransactionsAmount:0,
            totalFilteredCreditTransactions:0,
            totalFilteredTransactionsAmountPending:0,
            customerInsightsApiResultCame:false,

            name:"",
            contactNo:"",
            email:"",
            age:"",
            dob:"",
            gender:"",
            addresses:[],
            dependents:[],

            modalDependent: false,
            modal:false,
            modalDeleteAddress:false,
            selectedAddress:{},
            selectedDependent:{},
            initEditAddress:false,
            user:{},
            modalEditCustomer:false
        })
    }

    intEditAdd = (row) => {
        console.log("Addressid", row.id);
        this.toggle()
        this.setState({"activeAddress": row, initEditAddress : true});
    }
    initDeleteAddress = (address) => {
        this.setState({
            "modalDeleteAddress": !this.state.modalDeleteAddress
        });
        this.setState({"selectedAddress": address})
    }
    toggleDeleteAddress = () => {
        this.setState({
            modalDeleteAddress: !this.state.modalDeleteAddress
        });
    }

    toggleEditCustomer = () => {
        this.setState({
            modalEditCustomer: !this.state.modalEditCustomer
        });
    }

    deleteAddress = async() => {
        let userData=await UserStore.fetchSessionData();
        let deleteAddress =await AuthService.deleteCustomerAddress(this.state, userData);
        if(!deleteAddress){
            toast.error("Something went wrong while setting deleting address.")
        } else {
            this.setState({"selectedAddress": {}})
            toast.success("Address deleted");
            this.setState({
                "modalDeleteAddress": !this.state.modalDeleteAddress
            });
            this.setState({addresses:[]});
            this.fetchAllAddress();
        }
    }
    intEdit = (row) => {
        console.log("Addressid", row.addressId);
        this.setState({
            'modalAddress': !this.state.modalAddress
        });
        this.setState({"activeAddress": row});
        console.log(this.state)
    }

    toggleDependent = () => {
        this.setState({
            modalDependent: !this.state.modalDependent
        });
    }

    toggleDelDependent = () => {
        this.setState({
            modalDelDependent: !this.state.modalDelDependent
        });
    }

    initDeleteDependent = (row) => {
        this.setState({selectedDependent:row})
        this.toggleDelDependent();
    }
    confirmDeleteDependent = async () => {
        let userData=await UserStore.fetchSessionData();
        this.setState({progressStatus:true});
        let status=await AuthService.deleteDependent(this.state,userData);
        this.setState({progressStatus:false});
        if(status===false){
            toast.error("Something went wrong.");
            return true;
        }else{
            this.toggleDelDependent()
            this.fetchDependentsByUserId(this.state.user._id)
        }
    }

    initEdit = () => {
        this.toggleEditCustomer()
    }

    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.progressStatus}/>

                <MDBRow>
                    <MDBCol>
                        {this.state.name &&
                        <div>
                            {this.state.name &&
                            <div><b>Customer Name:</b> {this.state.name}</div>
                            }
                            {this.state.contactNo &&
                            <div><b>Phone:</b> {this.state.contactNo}</div>
                            }
                            {this.state.dob &&
                            <div><b>Date of birth</b> {utility.convertToReadbleDate(this.state.dob)}</div>
                            }
                            {this.state.memberSince!=="" &&
                            <div><b>Customer Since:</b> {utility.convertToReadbleDate(this.state.memberSince)}</div>
                            }
                        </div>
                        }
                        {this.state.name &&
                        <MDBBtn size={"sm"} onClick={this.initEdit}>Edit</MDBBtn>
                        }

                    </MDBCol>
                    <MDBCol>
                        <MDBCard style={{"width":"100%"}}>
                            <MDBCardBody>
                                <MDBRow>
                                    <MDBCol className={"float-left"}>

                                        <div>
                                            Dependents:
                                            {this.state.dependents.length > 0 &&
                                            <div className={"mb-4"}>
                                                {this.state.dependents.map((item, index) => (
                                                    <div key={"Dep-"+index}>
                                                        {item.relation !== "SELF" &&
                                                        <div>
                                                                <span>
                                                                {item.name + " (" + item.relation + ")"}
                                                                </span>
                                                            <span className={"float-right cursor-pointer"}
                                                                  onClick={() => this.initDeleteDependent(item)}>
                                                                    <MDBIcon icon="trash"/>
                                                                </span>
                                                        </div>
                                                        }
                                                    </div>
                                                ))}
                                            </div>
                                            }
                                            {this.state.dependents.length ===0 &&
                                            <span> No dependents added</span>
                                            }
                                        </div>
                                    </MDBCol>
                                    {this.state.dependents.length > 1 &&
                                    <MDBCol size={"1"}>
                                    </MDBCol>
                                    }
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol>
                                        <MDBBtn size={"sm"} color={"default"} onClick={this.toggleDependent}>Add Dependent</MDBBtn>
                                    </MDBCol>
                                </MDBRow>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>

                <MDBRow>
                    <MDBCol sm="4" className={'p-1 grey-text d-flex align-items-stretch'}>
                        <MDBBtn flat className={'col-md-12 m-0 mh-100 small white border rounded p-3 text-center'}
                                onClick={this.toggle}>
                            <MDBIcon icon="plus" /><br/>Add Address
                        </MDBBtn>
                    </MDBCol>
                    {this.state.addresses.map((row, index) => (
                        <MDBCol key={"Add-"+index} sm="4" className={'p-1 d-flex align-items-stretch'}>
                            <MDBCol className={'mh-100 small white border rounded p-3'}>
                                <div className={'col-md-12 pl-0 float-left'}>
                                    <strong>{row.name}</strong>
                                </div>
                                <div className={'col-md-12 pl-0 float-left'}>
                                    {row.addressLine1}, {row.addressLine2}, {row.area}<br/>
                                    {row.city}, {row.state} <br/>
                                    {row.pinCode}, {row.country}<br/>
                                    {row.landmark}<br/>
                                </div>
                                <div className={'col-md-12 pl-0 float-left'}>
                                    <strong>Phone number:</strong> {row.contact_no}
                                </div>
                                <div className={'col-md-12 pl-0 float-left'}>
                                    <MDBBtn flat className={'p-0 m-0 mt-3 light-blue-text'} onClick={()=>this.intEditAdd(row)}>Edit</MDBBtn>
                                    <MDBBtn flat className={'p-0 m-0 mt-3 ml-3 light-blue-text'} onClick={()=>this.initDeleteAddress(row)}>Delete</MDBBtn>
                                </div>
                            </MDBCol>
                        </MDBCol>
                    ))}
                </MDBRow>


                    <MDBCard className={"mt-2"}>
                        <MDBCardHeader>
                            {(this.state.customerInsightsApiResultCame) &&
                            <MDBRow>
                                <MDBCol className={"ml-2"}>
                                    <div>Total Orders: {this.state.totalOrders}</div>
                                </MDBCol>
                                <MDBCol className={"ml-2"}>
                                    <div>Total Amount: <span dangerouslySetInnerHTML={{__html: config.CURRENCY_HTML}}></span> {this.state.totalAmount.toFixed("2")}</div>
                                </MDBCol>
                                <MDBCol className={"ml-2"}>
                                    <div>Orders on Credit: {this.state.creditOrders}</div>
                                </MDBCol>
                                <MDBCol className={"ml-2"}>
                                    <div>Credit Pending: <span dangerouslySetInnerHTML={{__html: config.CURRENCY_HTML}}></span> {this.state.totalOutstanding.toFixed("2")}</div>
                                </MDBCol>
                            </MDBRow>
                            }
                        </MDBCardHeader>
                        <MDBCardBody>

                            {(!this.state.orders===null && this.state.orders.length===0) &&(
                                <MDBRow>
                                    No order found for the selected customer!
                                </MDBRow>
                            )}

                            {(!this.state.progressStatus && this.state.orders.length>0) &&(
                            <MDBRow>
                                {/*<MDBCol>*/}
                                {/*    {this.state.topCustomers.length > 0 &&*/}
                                {/*    <div key={this.state.topCustomerKey}>*/}
                                {/*        {this.state.topCustomers[0]._id.name &&*/}
                                {/*        <div><b>Customer Name:</b> {this.state.topCustomers[0]._id.name}</div>*/}
                                {/*        }*/}
                                {/*        {this.state.topCustomers[0].totalPurchase &&*/}
                                {/*        <div><b>Total Purchase:</b> {orgConfig.CURRENCY_SYMBOL} { this.state.topCustomers[0].totalPurchase.toFixed(2)}</div>*/}
                                {/*        }*/}
                                {/*        {this.state.topCustomers[0]._id.contactNo &&*/}
                                {/*        <div><b>Contact No:</b> {this.state.topCustomers[0]._id.contactNo}</div>*/}
                                {/*        }*/}
                                {/*        {this.state.topCustomers[0]._id.dob &&*/}
                                {/*        <div><b>Date of birth</b> {utility.convertToReadbleDate(this.state.topCustomers[0]._id.dob)}</div>*/}
                                {/*        }*/}
                                {/*        {this.state.topCustomers[0]._id.memberSince &&*/}
                                {/*        <div><b>Member Since:</b> {utility.convertToReadbleDate(this.state.topCustomers[0]._id.memberSince)}</div>*/}
                                {/*        }*/}
                                {/*    </div>*/}
                                {/*    }*/}
                                {/*</MDBCol>*/}




                                <MDBCol className={"col-8"}>
                                    <b>Order History</b>
                                    <MDBDataTable
                                        striped

                                        data={this.state.ordersTableData}
                                        materialSearch
                                        noBottomColumns
                                        hover
                                    />
                                </MDBCol>


                                <MDBCol className={"col-4"}>
                                    <b>Purchase Insights</b>
                                    {this.state.productData.length > 0 &&
                                    <MDBDataTable
                                        striped

                                        data={this.state.frequentProducts}
                                        materialSearch
                                        noBottomColumns
                                        hover
                                        disableRetreatAfterSorting={false}
                                    />
                                    }
                                </MDBCol>
                            </MDBRow>
                            )}




                        </MDBCardBody>
                    </MDBCard>

                <MDBModal isOpen={this.state.modalDelDependent} toggle={this.toggleDelDependent}>
                    <MDBModalHeader toggle={this.toggleDelDependent}>Delete Dependent</MDBModalHeader>
                    <MDBModalBody>
                        Are you sure you want to delete {(this.state.selectedDependent.first_name ? (this.state.selectedDependent.first_name + " " + this.state.selectedDependent.last_name) : (this.state.selectedDependent.name))}?
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn disabled={this.state.progressStatus} color="primary" onClick={this.confirmDeleteDependent}>Delete</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
                <MDBModal isOpen={this.state.modalDependent} toggle={this.toggleDependent} size={"lg"}>
                    <MDBModalHeader toggle={this.toggleDependent}>Family & Friends</MDBModalHeader>
                    <MDBModalBody>
                        <MDBRow>
                            <MDBCol className={"p-3"}>
                                <Dependents user={this.state.user} addDependentCompleted={this.addDependentCompleted}   />
                            </MDBCol>
                        </MDBRow>
                    </MDBModalBody>
                </MDBModal>

                <MDBModal isOpen={this.state.modal} toggle={this.toggle} size={"lg"}>
                    <MDBModalHeader toggle={this.toggle}>Address</MDBModalHeader>
                    <MDBModalBody>
                        <MDBRow>
                            <MDBCol className={"p-3"}>
                                {this.state.initEditAddress === false &&
                                <AddAddress userId={this.state.customerId}
                                            user={this.state.user}
                                            dependentId={this.state.user.dependentId}
                                            openAddAddress={this.openAddAddress}
                                            fetchAllAddress={this.fetchAllAddress}/>
                                }
                                {this.state.initEditAddress === true &&
                                <AddAddress userId={this.state.customerId}
                                            activeAddress={this.state.activeAddress} user={this.state.user}
                                            dependentId={this.state.user.dependentId}
                                            openAddAddress={this.openAddAddress}
                                            fetchAllAddress={this.fetchAllAddress} />
                                }
                            </MDBCol>
                        </MDBRow>
                    </MDBModalBody>
                </MDBModal>


                <MDBModal isOpen={this.state.modalDeleteAddress} toggle={this.toggleDeleteAddress}>
                    <MDBModalHeader toggle={this.toggleDeleteAddress} className={'black-text'}>Delete Address</MDBModalHeader>
                    <MDBModalBody>
                        Are you sure you want to delete this address?
                    </MDBModalBody>
                    <MDBModalFooter>
                        {/*<MDBBtn rounded={true} className={"btn-sm"} color={"btn-outline-info"} onClick={this.toggleDeleteAddress}>Cancel</MDBBtn>*/}
                        <MDBBtn onClick={()=>this.deleteAddress()}>Delete</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>

                <MDBModal isOpen={this.state.modalEditCustomer} toggle={this.toggleEditCustomer} size={"lg"}>
                    <MDBModalHeader toggle={this.toggleEditCustomer}>Edit Customer</MDBModalHeader>
                    <MDBModalBody>
                        <MDBRow>
                            <MDBCol className={"p-3"}>
                                <UpdateCustomer user={this.state.user} customerId={this.state.customerId} editCustomerCompleted={this.editCustomerCompleted} deleteCustomerCompleted={this.deleteCustomerCompleted}   />
                            </MDBCol>
                        </MDBRow>
                    </MDBModalBody>
                </MDBModal>






            </React.Fragment>
        )
    }
}

export default withRouter(CustomerInsights)