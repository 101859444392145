import React, {Suspense} from "react";
import {MDBBox, MDBBreadcrumb, MDBBreadcrumbItem} from "mdbreact";
import {Link} from "react-router-dom";
import ListStorageLocationCmp from "../../components/storageLocation/listStorageLocation";
class ListStorageLocation extends React.Component {
    render() {
        return(
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Storage Location</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <MDBBox className={"min-vh-100"}>
                    <Suspense fallback={null}>
                        <ListStorageLocationCmp/>
                    </Suspense>
                </MDBBox>
            </React.Fragment>
        )
    }
}
export default ListStorageLocation;