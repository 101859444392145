import React from "react";
import SimpleBackdrop from "../common/overlay";
import {
    MDBBtn,
    MDBCol, MDBInput,
    MDBModal,
    MDBModalBody, MDBModalFooter,
    MDBModalHeader, MDBRow,
} from "mdbreact";
import UserStore from "../../stores/userStore";
import PharmacyService from "../../services/pharmacyService";
import {toast} from "react-toastify";
import {CSVLink} from "react-csv";
import Moments from "moment";
import MaterialTable from "material-table";

const delay = ms => new Promise(res => setTimeout(res, ms));

const headers = [
    {label: 'Product name', key: 'productName'},
    {label: 'Manufacturer', key: 'mfgDetails.manufacturer'}
]

class BannedMedicine extends React.Component {
    async componentDidMount() {
        let userData = await UserStore.fetchSessionData();
        this.setState({userData})
        await this.fetchBannedProducts()
    }

    fetchBannedProducts = async () => {
        this.setState({isLoading: true})
        let banned = await PharmacyService.fetchBannedProducts(this.state.userData);
        this.setState({isLoading: false})
        if (banned === false) {
            toast.error("We are facing some issue. Please try gain later.");
        } else {
            this.setState({banned});
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            banned: [],
            modal: false,
            radio: 1,
            barCode: "",
            productData: [],
            productName: "",
            columns: [
                {title: 'Product name', field: 'productName'},
                {title: 'Manufacturer', field: 'mfgDetails.manufacturer'},
                {
                    title: '',
                    field: '',
                    render: rowData => <span className={"cursor-pointer primary-text"} onClick={() => {
                        this.update(rowData)
                    }}>Allow online sale</span>
                }
            ]
        }
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal, barCode: "", productData: [], productName: ""
        });
    }
    searchByBarCode = async () => {
        await delay(500);
        if (this.state.barCode !== "" && this.state.isLoading === false) {
            let val = this.state.barCode;
            this.setState({isLoading: true});
            let userData = await UserStore.fetchSessionData();
            let data = await PharmacyService.fetchProductByBarCode(userData, val);
            this.setState({isLoading: false});
            if (data === false) {
                toast.error("We are facing some issue. Please contact your administrator.");
            } else {
                let temp = [];
                for (let item of data) {
                    temp.push(item.productDetails);
                }
                this.setState({productData: temp});
            }
        }
    }
    calculateBanned = (item) => {
        if (item.hasOwnProperty("isBanned")) {
            if (item.isBanned === "Y") {
                return <span>Banned</span>
            } else {
                return <span className={"cursor-pointer primary-text"} onClick={() => {
                    this.markAsBanned(item)
                }}>Mark as banned</span>
            }
        } else {
            return <span className={"cursor-pointer primary-text"} onClick={() => {
                this.markAsBanned(item)
            }}>Mark as banned</span>
        }
    }
    markAsBanned = async (item) => {
        await this.update(item)
        this.toggle()
    }
    update = async (item) => {
        this.setState({isLoading: false});
        let status = await PharmacyService.updateBannedProduct(this.state.userData, {productId: item._id});
        if (status === false) {
            toast.error("We are facing some issue. Please try again later.");
        } else {
            toast.success("Banned status successfully updated.");
            await this.fetchBannedProducts()
        }
        this.setState({isLoading: false})
    }
    searchProduct = async () => {
        if (this.state.term !== "") {
            this.setState({isLoading: true});
            let data = await PharmacyService.fetchProductByTermAll(this.state.userData, this.state.productName);
            this.setState({isLoading: false});
            if (data === false) {
                toast.error("Something went wrong while fetching the data by product name.");
            } else {
                this.setState({productData: data});
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.isLoading}/>
                <div className={"col-12 row mx-0"}>
                    <div className={'col-md-12 text-right p-0'}>
                        <CSVLink data={this.state.banned} headers={headers} target="_blank"
                                 filename={"BannedProducts_" + Moments(new Date()).format("DD-MM-YYYY") + ".csv"}>
                            <MDBBtn size={'md'} disabled={this.state.isLoading}><i
                                className="fas fa-download"></i>&nbsp;Export</MDBBtn>
                        </CSVLink>
                        <MDBBtn size={'md'} disabled={this.state.isLoading} onClick={this.toggle}>Ban online
                            sale</MDBBtn>
                    </div>
                    <MaterialTable
                        columns={[
                            {title: '#', field: '', render: rowData => rowData.tableData.id + 1},
                            {title: 'Product name', field: 'productName'},
                            {title: 'Manufacturer', field: 'mfgDetails.manufacturer'},
                            {
                                title: '',
                                field: '',
                                render: rowData => <span className={"cursor-pointer primary-text"} onClick={() => {
                                    this.update(rowData)
                                }}>Allow online sale</span>
                            }
                        ]}
                        data={this.state.banned}
                        options={{
                            showTitle: false,
                            pageSize: 10,
                            pageSizeOptions: [10, 50, 100],
                            emptyRowsWhenPaging: false,
                            tableLayout: 'fixed',
                        }}
                    />
                </div>
                <MDBModal size={"lg"} isOpen={this.state.modal} toggle={this.toggle}>
                    <MDBModalHeader toggle={this.toggle}>Ban online sale</MDBModalHeader>
                    <MDBModalBody>
                        <MDBRow>
                            <MDBCol>
                                <MDBInput gap onClick={() => {
                                    this.setState({radio: 1})
                                }} checked={this.state.radio === 1 ? true : false} label="Scan barcode" type="radio"
                                          id="radio1"/>
                            </MDBCol>
                            <MDBCol>
                                <MDBInput gap onClick={() => {
                                    this.setState({radio: 2})
                                }} checked={this.state.radio === 2 ? true : false} label="Search by product name"
                                          type="radio" id="radio2"/>
                            </MDBCol>
                        </MDBRow>
                        {this.state.radio === 1 &&
                        <MDBRow>
                            <MDBCol>
                                <MDBInput label={"Barcode"} value={this.state.barCode} getValue={(e) => {
                                    this.setState({barCode: e, productName: ""})
                                }} onChange={() => this.searchByBarCode()} id={"barCode"}/>
                            </MDBCol>
                            <MDBCol className={'d-flex align-items-center'}>
                                <MDBBtn size={'md'} disabled={this.state.isLoading}>Search</MDBBtn>
                            </MDBCol>
                        </MDBRow>
                        }
                        {this.state.radio === 2 &&
                        <MDBRow>
                            <MDBCol>
                                <MDBInput label={"Product name"} value={this.state.productName} getValue={(e) => {
                                    this.setState({barCode: "", productName: e})
                                }}/>
                            </MDBCol>
                            <MDBCol className={'d-flex align-items-center'}>
                                <MDBBtn size={'md'} onClick={() => {
                                    this.searchProduct()
                                }}>Search</MDBBtn>
                            </MDBCol>
                        </MDBRow>
                        }
                        {this.state.productData.length > 0 &&
                        <MaterialTable
                            columns={[
                                {title: '#', field: '', render: rowData => rowData.tableData.id + 1},
                                {title: 'Product name', field: 'productName'},
                                {title: '', field: '', render: rowData => this.calculateBanned(rowData)}
                            ]}
                            data={this.state.productData}
                            options={{
                                showTitle: false,
                                pageSize: 10,
                                pageSizeOptions: [10, 50, 100],
                                emptyRowsWhenPaging: false,
                                tableLayout: 'fixed',
                            }}
                        />
                        }
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn size={'md'} onClick={this.toggle} disabled={this.state.isLoading}>Close</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
            </React.Fragment>
        )
    }
}

export default BannedMedicine;