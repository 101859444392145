import React from "react";
import {Link, withRouter} from "react-router-dom";
import {
    MDBContainer, MDBRow, MDBInput, MDBFormInline, MDBBtn, MDBCol, MDBCard, MDBCardBody, MDBCardHeader, MDBBreadcrumb, MDBBreadcrumbItem,
} from 'mdbreact';
import UserStore from "../../stores/userStore";
import {toast} from "react-toastify";
import util from "../../utility/utility";
import AuthService from "../../services/authService";
import SimpleBackdrop from "../common/overlay";
import orgConfig from "../../orgConfig";

class AddUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userName:"",
            password:"",
            name:"",
            description:"",
            isActive:"",
            progressStatus:false,
            nameError:"",
            email:"",
            contactNo:"",
            userNameError:false,
            passwordError:false,
            passwordType:"password",
            orgId:"",
            permissions:["ALL"],
            type:"ORG_ADMIN",
            userError:false
        }
    }
    componentDidMount() {
        let orgId = this.props.match.params.orgId;
        this.setState({orgId:orgId})
    }
    handleChangeContactNo=(e)=>{
        if(!util.isInt(e.target.value) && e.target.value !== ""){
            return false;
        }
        this.setState({contactNo:e.target.value})
    };
    handleChangeEmail=(e)=>{
        this.setState({email:e.target.value})
    };
    handleName=(e)=>{
        this.setState({name:e.target.value})
    };
    handleChangeUsername=(event)=>{
        this.setState({
            userName: event.target.value
        })
    }
    handleChangePassword=(event)=>{
        this.setState({
            password: event.target.value
        })
    }
    resetField=()=>{
        this.setState({name:"", email:"", contactNo:"", password:"", userName:""})
    };
    showHide=(e)=>{
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            passwordType: this.state.passwordType === 'password' ? 'text' : 'password'
        })
    }
    validateName = () => {
        if(!this.state.name || this.state.name.trim() === ""){
            this.setState({"nameError": true})
        } else {
            this.setState({"nameError": false})
        }
    }
    validateEmail = () => {
        if(this.state.email && !util.validateEmail(this.state.email)){
            this.setState({"loadingEmail": true, "emailError": true})
        } else {
            this.setState({"loadingEmail": false, "emailError": false})
        }
    }
    validatePhone= () => {
        if(!util.checkPhone(this.state.contactNo)){
            this.setState({ "contactNoError": false})
        } else {
            this.setState({"contactNoError": false})
        }
    }
    addUser =async () => {
        //validations
        if(this.state.userName===''){
            toast.error("Please enter valid user name.");
            return true;
        }
        if(this.state.name===''){
            toast.error("Please enter valid name.");
            return true;
        }
        if(this.state.password===''){
            toast.error("Please enter valid password.");
            return true;
        }
        if(util.checkPhone(this.state.contactNo)===false){
            toast.error("Please enter valid phone.");
            return true;
        }
        let userData=await UserStore.fetchSessionData();
        this.setState({progressStatus:true});
        let status=await AuthService.createOrgUser(this.state,userData);
        this.setState({progressStatus:false});
        if(status===false){
            toast.error("Something went wrong.");
            return true;
        }else{
            toast.success("User added successfully.");
            this.resetField()
        }
    }
    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.progressStatus}/>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem ><Link to={'/view-pharmacy-users/'+this.state.orgId}>Pharmacy Users</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Add Pharmacy User</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <MDBContainer>
                    <MDBCard className={"mt-2"}>
                        <MDBCardHeader>
                            <span className={"float-left title"}>Add Pharmacy User</span>
                        </MDBCardHeader>
                        <MDBCardBody>

                            <MDBInput autoComplete={'off'} style={{"marginTop": "0px"}} onBlur={this.validateName}
                                              className={this.state.nameError ? "border-danger" : "mt-0 mb-0"}
                                              maxLength="100" type='text' label="Name *"
                                              onChange={this.handleName}
                                              value={this.state.name}/>

                            <MDBInput id={"username_se"} autoComplete={'off'} className={this.state.userNameError ? "border-danger" :""} maxLength="50"  type='text' label="Username *" onChange={this.handleChangeUsername} value={this.state.userName}/>

                            <MDBInput id={"password_se"} type={this.state.passwordType} onKeyDown={this.handleKeyDown} label="Password *" maxLength="50"
                                          className={this.state.passwordError ? "border-danger" : ""}
                                          onChange={this.handleChangePassword} value={this.state.password}/>
                                {this.state.password &&
                                <span style={{"marginTop": "-50px", "position":"absolute", "right": "25px", "cursor": "pointer"}} onClick={this.showHide}
                                      className={this.state.passwordType === 'text' ? 'fa fa-fw fa-eye-slash field-icon' : 'fa fa-fw fa-eye field-icon'}></span>
                                }
                            <MDBInput onBlur={() => this.validateEmail()}
                                              autoComplete={'off'}
                                              className={this.state.emailError ? "border-danger" : ""}
                                              maxLength="50" type='text' label="E-mail"
                                              onChange={this.handleChangeEmail}
                                              value={this.state.email}/>

                            <MDBInput onBlur={() => this.validatePhone()}
                                              autoComplete={'off'}
                                              className={this.state.contactNoError ? "border-danger" : ""}
                                              maxLength={orgConfig.PHONE_NUMBER_LENGTH} type='text' label="Phone *"
                                              onChange={this.handleChangeContactNo}
                                              value={this.state.contactNo}/>

                        </MDBCardBody>

                        <div className="col-12 p-2 text-center">
                            <MDBBtn outline  size="md" onClick={() => this.props.history.push('/view-pharmacy-users/'+this.props.match.params.orgId)} className="mr-auto" disabled={this.state.progressStatus}>
                                Back
                            </MDBBtn>
                            <MDBBtn size="md" onClick={this.addUser} className="mr-auto"
                                    disabled={this.state.userError || this.state.progressStatus || this.state.progressStatusFileUpload}>
                                Save
                            </MDBBtn>
                        </div>
                    </MDBCard>
                </MDBContainer>
            </React.Fragment>
        );
    }
}
export default withRouter(AddUser)