import React from "react";
import {Link, withRouter} from "react-router-dom";
import {
    MDBContainer, MDBRow, MDBInput, MDBFormInline, MDBBtn, MDBCol, MDBCard, MDBCardBody, MDBCardHeader, MDBBreadcrumb,
    MDBBreadcrumbItem, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBModal, MDBSelect
} from 'mdbreact';
import UserStore from "../../stores/userStore";
import {toast} from "react-toastify";
import util from "../../utility/utility";
import AuthService from "../../services/authService";
import SimpleBackdrop from "../common/overlay";
import {isEmptyValue} from "enzyme/src/Utils";
import orgConfig from "../../orgConfig";
import SpecialityService from "../../services/specialityService";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Moments from "moment";

class EditDoctor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId:"",
            dependentId:"",
            contactNo:"",
            name:"",
            address: "",
            street: "",
            pincode: "",
            area:"",
            city: "",
            state: "",
            country: "India",
            landmark : "",
            altPhone : "",
            default:true,
            addressId: "",
            regNo:"",
            regNoError:false,
            contactNoError:false,
            nameError:false,
            addressError:false,
            streetError:false,
            pincodeError:false,
            areaError:false,
            cityError:false,
            stateError:false,
            countryError:false,
            landmarkError:false,
            altPhoneError:false,
            speciality:"",
            specialityError:false,
            email:"",
            doctorId:'',
            orgId:"",
            loading:false,
            loadingEmail:false,
            phoneError:false,
            emailError:"",
            modalDelete:false,
            loadedOnPopup:false,
            specialityList:[],
            specialityId:'',
            doctorSince: Moments()
        }
    }
    async componentDidMount() {
        //let doctorId=this.props.match.params.doctorId
        let userData = await UserStore.fetchSessionData();
        this.setState({userData: userData})
        this.setState({loading:true});

        if(this.props.loadedOnPopup === true){
            let doctorId = this.props.doctorId;
            this.setState({doctorId:doctorId, loadedOnPopup:true})
            this.fetchDoctorById()
        } else {
            const { doctorId } = this.props.queryString;
            this.setState({doctorId:doctorId})
            this.fetchDoctorById()
        }
        let res=await SpecialityService.fetchMasterSpeciality(userData);
        let temp=[];
        for(let item of res){
            let t={
                text: item.specialityGroup,
                value: item._id+":"+item.specialityGroup
            }
            temp.push(t);
        }
        this.setState({specialityList:temp});
    }

    fetchDoctorById = async () =>{
        let data = await AuthService.fetchDoctorById(this.state.doctorId,this.state.userData)
        this.setState({loading:false});
        this.setState({
            contactNo:data.contactNo,
            name:  data.name,
            address: data.address,
            street: !isEmptyValue(data.street) ? data.street : "",
            pincode:!isEmptyValue(data.pincode) ? data.pincode : "",
            area:data.area,
            city: data.city,
            state: data.state,
            country: "India",
            landmark : !isEmptyValue(data.landmark) ? data.landmark: "",
            altPhone : data.altPhone,
            regNo:data.regNo,
            orgId:data.orgId,
            speciality:data.speciality,
            email:data.email ? data.email : '',
            doctorSince:data.doctorSince
        });
        if(this.state.userData.type === "platformAdmin"){
            this.setState({orgId: this.state.userData._id})
            this.setState({numId: this.state.userData.numId})
        } else if(this.state.userData.type === "ORG_ADMIN"){
            this.setState({orgId: this.state.userData.orgDetails[0]._id})
            this.setState({numId: this.state.userData.orgDetails[0].numId})
        }
        try{
            this.setState({"default": true})
        } catch (e) {
            toast.error("Error in setting default address");
        }

        let userData = await UserStore.fetchSessionData();
        let res=await SpecialityService.fetchMasterSpeciality(userData);
        let temp=[];
        for(let item of res){
            if(data.speciality===item.specialityGroup){
                let t = {
                    checked: true,
                    text: item.specialityGroup,
                    value: item._id + ":" + item.specialityGroup
                }
                temp.push(t);
            }else {
                let t = {
                    text: item.specialityGroup,
                    value: item._id + ":" + item.specialityGroup
                }
                temp.push(t);
            }
        }
        this.setState({specialityList:temp});
    }

    handleChangeContactNo=(e)=>{
        if(!util.isInt(e.target.value) && e.target.value !== ""){
            return false;
        }
        this.setState({contactNo:e.target.value})
    };
    handleChangeEmail=(e)=>{
        this.setState({email:e.target.value})
    };
    handleName=(e)=>{
        this.setState({name:e.target.value})
    };
    handleRegNo=(e)=>{
        this.setState({regNo:e.target.value})
    };
    handleAddress=(e)=>{
        this.setState({address:e.target.value})
    };
    handleStreet=(e)=>{
        this.setState({street:e.target.value})
    };
    handlePincode=(e)=>{
        if(!util.isInt(e.target.value) && e.target.value !== ""){
            return false;
        }
        this.setState({pincode:e.target.value})
    };
    handleArea=(e)=>{
        this.setState({area:e.target.value})
    };
    handleCity=(e)=>{
        this.setState({city:e.target.value})
    };
    handleState=(e)=>{
        this.setState({state:e.target.value})
    };
    handleCountry=(e)=>{
        this.setState({country:e.target.value})
    };
    handleLandmark=(e)=>{
        this.setState({landmark:e.target.value})
    };
    handleSpeciality=(value)=>{
        this.setState({speciality:value})
    };
    handleContactNo=(e)=>{
        if(!util.isInt(e.target.value) && e.target.value !== ""){
            return false;
        }
        this.setState({contactNo:e.target.value})
    };
    handleAltphone=(e)=>{
        if(!util.isInt(e.target.value) && e.target.value !== ""){
            return false;
        }
        this.setState({altPhone:e.target.value})
    };
    validateEmail = () => {
        if(this.state.email && !util.validateEmail(this.state.email)){
            this.setState({"loadingEmail": true, "emailError": true})
        } else {
            this.setState({"loadingEmail": false, "emailError": false})
        }
    }
    addDoctor = async () => {
        if(this.state.name && this.state.name.trim() === ""){
            this.setState({nameError:true});
            toast.error("Please enter your full name.")
            return true;
        } else {
            this.setState({nameError:false});
        }
        if(this.state.speciality.length===0){
            this.setState({specialityError: true})
            toast.error("Please enter speciality.")
            return
        }else{
            this.setState({specialityError: false})
        }
        if(this.state.address && this.state.address.trim() === ""){
            this.setState({addressError:true});
            toast.error("Please enter address line 1.")
            return true;
        } else {
            this.setState({addressError:false});
        }
        if (this.state.pincode==="") {
            this.setState({pincodeError:true});
            toast.error("Please enter ZIP.")
            return
        } else {
            this.setState({pincodeError:false});
        }
        if(this.state.city && this.state.city.trim() === ""){
            this.setState({cityError:true});
            toast.error("Please enter city.")
            return true;
        } else {
            this.setState({cityError:false});
        }
        if(this.state.state && this.state.state.trim() === ""){
            this.setState({stateError:true});
            toast.error("Please enter state.");
            return true;
        } else {
            this.setState({stateError:false});
        }
        if(this.state.country && this.state.country.trim() === ""){
            this.setState({countryError:true});
            toast.error("Please enter country.");
            return true;
        } else {
            this.setState({countryError:false});
        }
        if(this.state.contactNo && this.state.contactNo.trim() !== "" && !util.checkPhone(this.state.contactNo)){
            this.setState({contactNoError:true});
            toast.error("Please enter a valid 10 digit phone number.");
            return true;
        } else {
            this.setState({contactNoError:false});
        }
        if(this.state.altPhone && this.state.altPhone !== "" && !util.checkPhone(this.state.altPhone)){
            this.setState({altPhoneError:true});
            toast.error("Please enter a 10 digit alternate phone number.");
            return true;
        } else {
            this.setState({altPhoneError:false});
        }
        if(this.state.email!==''){
            if(!util.validateEmail(this.state.email)){
                this.setState({emailError: true})
                toast.error("Please enter a valid email.")
                return true
            } else {
                this.setState({emailError: false})
            }
        } else {
            this.setState({emailError: false})
        }
        this.setState({"loading": true});
        let addressResult = await AuthService.editDoctor(this.state);
        this.setState({"loading": false});
        if(!addressResult){
            toast.error("Something went wrong.");
        } else {
            toast.success("Doctor details successfully updated.");
            if(this.state.loadedOnPopup === true){
                this.props.onEditComplete()
            }
        }
    }
    validateName=()=>{
        if(this.state.name.trim() === ''){
            return true;
        } else {
            this.setState({nameError: false});
        }
    };
    validateAddress=()=>{
        if(this.state.address.trim() === ''){
            return true;
        } else {
            this.setState({addressError: false});
        }
    };
    validatePincode=()=>{
        if(this.state.pincode.trim() === ''){
            return true;
        } else {
            this.setState({pincodeError: false});
        }
    };
    validateArea=()=>{
        if(this.state.area === ''){
            return true;
        } else {
            this.setState({areaError: false});
        }
    };
    validateCity=()=>{
        if(this.state.city.trim() === ''){
            return true;
        } else {
            this.setState({cityError: false});
        }
    };
    validateState=()=>{
        if(this.state.state.trim() === ''){
            return true;
        } else {
            this.setState({stateError: false});
        }
    };
    initDeleteDoctor = (row) => {
        this.toggleDelete();
    }
    confirmDeleteDoctor = async () => {
        this.setState({progressStatus:true});
        let res =await AuthService.deleteDoctor(this.state);
        this.setState({progressStatus:false});
        this.toggleDelete()
        if(res===false){
            toast.error("Something went wrong.");
            return true;
        }else{
            if(this.state.loadedOnPopup === true){
                this.props.onEditComplete()
            } else {
                this.props.history.push('/view-doctors');
            }
            toast.success("Doctor deleted successfully.");

        }
    }
    toggleDelete = () => {
        this.setState({
            modalDelete: !this.state.modalDelete
        });
    }

    render() {
        return (
            <React.Fragment>
                {this.state.loadedOnPopup === false &&
                <>
                    <MDBBreadcrumb>
                        <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                        <MDBBreadcrumbItem><Link to={'/view-doctors'}>Doctors</Link></MDBBreadcrumbItem>
                        <MDBBreadcrumbItem active>Edit Doctor</MDBBreadcrumbItem>
                    </MDBBreadcrumb>
                </>
                }
                <SimpleBackdrop status={(this.state.loading === true)?true:false}/>
                <MDBContainer>
                    <MDBCard className={"mt-2"}>
                        <MDBCardHeader>
                            <span className={"float-left title"}>Edit Doctor</span>
                        </MDBCardHeader>
                        <MDBCardBody>
                            <MDBRow >
                                <div className={'col-md-6'}>
                                    <MDBInput onBlur={()=>this.validateName()} className={this.state.nameError ? "border-danger" :""} maxLength="50"  type='text' label="Full Name *" onChange={this.handleName} value={this.state.name}/>
                                </div>
                                <div className={'col-md-6'}>
                                    <MDBInput className={this.state.contactNoError ? "border-danger" :""} maxLength={orgConfig.PHONE_NUMBER_LENGTH}  type='text' label="Phone" onChange={this.handleContactNo} value={this.state.contactNo}/>
                                </div>
                            </MDBRow>
                            <MDBRow >
                                <MDBCol >
                                    <b>Speciality *</b>
                                    <MDBSelect
                                        search
                                        options={this.state.specialityList}
                                        selected="Choose Speciality"
                                        getValue={this.handleSpeciality}
                                        color="primary"
                                        className={this.state.specialityError ? "border-danger" :""} /*this doesn't work, no way to set border-color*/
                                    />
                                </MDBCol>
                                <MDBCol>
                                    <b>Doctor Since</b>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <DatePicker className={"col-12"}
                                            format="MMM D, YYYY"
                                            maxDate={new Date()}
                                            margin="normal"
                                            id="doctorSince"
                                            value={this.state.doctorSince}
                                            onChange={(e) => this.setState({doctorSince: new Date(e)})}
                                            openTo="year"
                                        />
                                    </MuiPickersUtilsProvider>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow >
                                <MDBCol size={"6"}>
                                    <MDBInput className={this.state.regNoError ? "border-danger" :""} maxLength="50"  type='text' label="Reg No." onChange={this.handleRegNo} value={this.state.regNo}/>
                                </MDBCol>
                                <MDBCol size={"6"}>
                                    <MDBInput onBlur={() => this.validateEmail()}
                                              autoComplete={'off'}
                                              className={this.state.emailError ? "border-danger" : ""}
                                              maxLength="50" type='text' label="E-mail"
                                              onChange={this.handleChangeEmail}
                                              value={this.state.email}/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow >
                                <MDBCol size={"6"}>
                                    <MDBInput onBlur={()=>this.validateAddress()} className={this.state.addressError ? "border-danger" :""} maxLength="50"  type='text' label="Address Line 1 *" onChange={this.handleAddress} value={this.state.address}/>
                                </MDBCol>
                                <MDBCol  size={"6"}>
                                    <MDBInput className={this.state.streetError ? "border-danger" :""} maxLength="50"  type='text' label="Address Line 2" onChange={this.handleStreet} value={this.state.street}/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow >
                                <MDBCol size={"6"}>
                                    <MDBInput onBlur={()=>this.validatePincode()} className={this.state.pincodeError ? "border-danger" :""} maxLength="6"  type='text' label="PIN *" onChange={this.handlePincode} value={this.state.pincode}/>
                                </MDBCol>
                                <MDBCol  size={"6"}>
                                    <MDBInput  className={this.state.areaError ? "border-danger" :""} maxLength="35"  type='text' label="Area/Locality" onChange={this.handleArea} value={this.state.area}/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow >
                                <MDBCol size={"6"}>
                                    <MDBInput onBlur={()=>this.validateCity()} className={this.state.cityError ? "border-danger" :""} maxLength="35"  type='text' label="City *" onChange={this.handleCity} value={this.state.city}/>
                                </MDBCol>
                                <MDBCol  size={"6"}>
                                    <MDBInput onBlur={()=>this.validateState()} className={this.state.stateError ? "border-danger" :""} maxLength="35"  type='text' label="State *" onChange={this.handleState} value={this.state.state}/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow >
                                <MDBCol size={"6"}>
                                    <MDBInput className={this.state.landmarkError ? "border-danger" :""} maxLength="50"  type='text' label="Landmark" onChange={this.handleLandmark} value={this.state.landmark}/>
                                </MDBCol>
                                <MDBCol  size={"6"}>
                                    <MDBInput className={this.state.altPhoneError ? "border-danger" :""} maxLength={orgConfig.PHONE_NUMBER_LENGTH}  type='text' label="Alternate Phone" onChange={this.handleAltphone} value={this.state.altPhone}/>
                                </MDBCol>
                            </MDBRow>
                            <div className="text-center col-12 p-2">
                                {this.state.loadedOnPopup === false &&
                                <MDBBtn outline  size="md"
                                        onClick={() => this.props.history.push('/view-doctors')}
                                        disabled={this.state.loading}>
                                    Back
                                </MDBBtn>
                                }
                                <MDBBtn outline  size="md" onClick={this.initDeleteDoctor}  >
                                    Delete
                                </MDBBtn>
                                <MDBBtn size="md" onClick={this.addDoctor}
                                        disabled={this.state.nameError || this.state.loading || this.state.phoneError}>
                                    Save
                                </MDBBtn>
                            </div>
                        </MDBCardBody>
                    </MDBCard>
                    <MDBModal isOpen={this.state.modalDelete} toggle={this.toggleDelete}>
                        <MDBModalHeader toggle={this.toggleDelete}>Delete Doctor</MDBModalHeader>
                        <MDBModalBody>
                            Are you sure you want to delete?
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn onClick={this.confirmDeleteDoctor} disabled={this.state.progressStatus}>Delete</MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>
                </MDBContainer>
            </React.Fragment>
        );
    }
}
export default withRouter(EditDoctor)
