import React from "react";
import {Link, withRouter} from "react-router-dom";
import {
    MDBContainer,
    MDBRow,
    MDBInput,
    MDBBtn,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBBreadcrumb,
    MDBBreadcrumbItem,
    MDBModal,
    MDBModalHeader,
    MDBModalBody,
    MDBModalFooter,
    MDBIcon,
    MDBChip, MDBBox
} from 'mdbreact';
import UserStore from "../../stores/userStore";
import {toast} from "react-toastify";
import PharmacyService from "../../services/pharmacyService";
import FileUpload from "../common/fileUpload/fileUpload";
import config from "../../config";
import util from "../../utility/utility";
import SimpleBackdrop from "../common/overlay";

class UpdateManufacturer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            manufacturer:"",
            description:"",
            descriptionError:"",
            isActive:"",
            manufacturerId:"",
            imageUrl:{},
            manufacturerObj:{},
            manufacturerApiResultCame:false,
            progressStatus:false,
            progressStatusFileUpload:false,
            manufacturerError:"",
            switch1:false,
            imageActionNeeded:"Init",
            lightBoxImages: [],
            address: "",
            street: "",
            pincode:"",
            area: "",
            city: "",
            state: "",
            country: "",
            landmark: "",
            fileUploadingInProgress:false,
            modalDelete:false,
            modalDelImage:false,
            resetForm:false,
            suggestedData:[]
        }
    }

    componentDidMount() {
        if(this.props.loadedOnPopup === true){
            let manufacturerId = this.props.manufacturerId;
            this.setState({manufacturerId:manufacturerId, loadedOnPopup:true})
        } else {
            const { manufacturerId } = this.props.queryString;
            this.setState({manufacturerId:manufacturerId})
        }
        this.fetchManufacturerById()
    }
    fetchManufacturerById = async () => {
        let userData=await UserStore.fetchSessionData();
        this.setState({progressStatus:true});
        let res = await PharmacyService.fetchManufacturerById(this.state.manufacturerId, userData);
        this.setState({manufacturerApiResultCame:true})
        this.setState({progressStatus:false});
        console.log(res);
        if(res){
            try{
                if(!res.hasOwnProperty("description") || !res.description){
                    this.setState({"description": ""})
                } else {
                    this.setState({"description": res.description})
                }
                this.setState({"manufacturer": res.manufacturer, "isActive": res.isActive, "imageUrl": res.imageUrl, address: res.addressLine1, street: res.addressLine2, pincode:res.pinCode, area: res.area, city: res.city, state: res.state,
                    country: res.country, landmark: res.landmark  })
                if(res.imageUrl.hasOwnProperty("filePathOriginal")){
                    this.setState({"imageActionNeeded":"Update"})
                    let lb = [{
                        src: config.IMAGE_HOST+res.imageUrl.filePathOriginal
                    }]
                    this.setState({"lightBoxImages":lb})
                } else {
                    this.setState({"imageActionNeeded":"Add"})
                }
            } catch (e) {
                toast.error("Something went wrong while fetching manufacturer by id.")
            }
        }
    }
    handleDescription=(e)=>{
        this.setState({description:e.target.value})
    };
    handleManufacturer=async (e)=>{
        this.setState({manufacturer:e.target.value});

        //suggestion part
        let userData=await UserStore.fetchSessionData();
        let mfgSuggest=await PharmacyService.suggestMfg(this.state.manufacturer,userData);
        this.setState({suggestedData:mfgSuggest});
    };
    validateManufacturer = () => {
        if(!this.state.manufacturer || this.state.manufacturer.trim() === ""){
            this.setState({"manufacturerError": true})
        } else {
            this.setState({"manufacturerError": false})
        }
    }
    updateManufacturer =async () => {
        let userData=await UserStore.fetchSessionData();
        // Reset all the error first
        this.setState({"manufacturerError": false})
        //validations
        this.validateManufacturer();
        if(this.state.manufacturerError){
            return false;
        }
        this.setState({progressStatus:true});
        let status=await PharmacyService.updateManufacturer(this.state,userData);
        this.setState({progressStatus:false});
        if(status===false){
            toast.error("Something went wrong.");
            return true;
        }else{
            toast.success("Manufacturer updated successfully.");
            this.setState({resetForm:true})
            if(this.state.loadedOnPopup === true){
                this.props.onEditComplete()
            } else {
                this.fetchManufacturerById()
            }

        }
    }
    fileUploadCallback = (uploadedFileObject) => {
        this.setState({ "imageUrl": uploadedFileObject.path, fileUploadingInProgress:true, "progressStatusFileUpload":false})
    }
    initUploadCallback = () => {
        this.setState({"progressStatusFileUpload":true,  resetForm:false})
    }
    handleAddress=(e)=>{
        this.setState({address:e.target.value})
    };
    handleStreet=(e)=>{
        this.setState({street:e.target.value})
    };
    handlePincode=(e)=>{
        if(!util.isInt(e.target.value) && e.target.value !== ""){
            return false;
        }
        this.setState({pincode:e.target.value})
    };
    handleArea=(e)=>{
        this.setState({area:e.target.value})
    };
    handleCity=(e)=>{
        this.setState({city:e.target.value})
    };
    handleState=(e)=>{
        this.setState({state:e.target.value})
    };
    handleCountry=(e)=>{
        this.setState({country:e.target.value})
    };
    handleLandmark=(e)=>{
        this.setState({landmark:e.target.value})
    };
    initDelete = () => {
        this.toggleDelete()
    }
    confirmDelete = async () => {
        let userData=await UserStore.fetchSessionData();
        this.setState({progressStatus:true});
        let status=await PharmacyService.deleteManufacturer(this.state,userData);
        this.setState({progressStatus:false});
        if(status===false){
            toast.error("Something went wrong.");
            return true;
        }else{
            toast.success("Manufacturer deleted successfully.");
            this.toggleDelete();
            if(this.state.loadedOnPopup === true){
                this.props.onEditComplete()
            } else {
                this.props.history.push('/view-manufacturers');
            }
        }
    }
    toggleDelete = () => {
        this.setState({
            modalDelete: !this.state.modalDelete
        });
    }
    toggleDelImage = () => {
        this.setState({
            modalDelImage: !this.state.modalDelImage
        });
    }
    initDeleteDoc = () => {
        this.toggleDelImage();
    }
    confirmDeleteImage = () => {
        this.setState({"imageUrl": {}})
        this.toggleDelImage();
        this.updateManufacturer()
    }
    navigate=(item)=>{
        this.props.history.push('/edit-manufacturer/'+item._id);
    }
    render() {
        return (
            <React.Fragment>
                {this.state.loadedOnPopup === false &&
                <>
                    <SimpleBackdrop status={this.state.progressStatus}/>
                    <MDBBreadcrumb>
                        <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                        <MDBBreadcrumbItem><Link to={'/view-manufacturers'}>Manufactures</Link></MDBBreadcrumbItem>
                        <MDBBreadcrumbItem active>Update Manufacturer</MDBBreadcrumbItem>
                    </MDBBreadcrumb>
                </>
                }
                <MDBContainer>
                    <MDBCard className={"mt-2"}>
                        <MDBCardHeader>
                            <span className={"float-left title"}>Update Manufacturer</span>
                            {this.state.loadedOnPopup === false &&
                            <MDBBtn outline color="blue-grey" size="sm"
                                    onClick={() => this.props.history.push('/view-manufacturers')}
                                    className="mr-auto float-right" disabled={this.state.progressStatus}>
                                Back
                            </MDBBtn>
                            }
                        </MDBCardHeader>
                        <MDBCardBody>
                            <MDBRow>
                                <MDBCol>
                                    <MDBInput autoComplete={'off'} style={{"marginTop": "0px"}} onBlur={this.validateManufacturer}
                                              className={this.state.manufacturerError ? "border-danger" : "mt-0 mb-0"}
                                              maxLength="100" type='text' label="Manufacturer"
                                              onChange={this.handleManufacturer} value={this.state.manufacturer}/>
                                    <MDBBox>
                                        {this.state.suggestedData.length > 0 &&
                                        <div>
                                            <h6>Update existing manufacturers:</h6>
                                            <br/>
                                        </div>
                                        }
                                        {this.state.suggestedData.map((item, idx) => (
                                            <span onClick={()=>{this.navigate(item)}}><MDBChip bgColor="light-blue lighten-4" key={idx} waves>{item.manufacturer}</MDBChip></span>
                                        ))}
                                    </MDBBox>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBInput autoComplete={'off'} style={{"marginTop": "0px"}}
                                              className={this.state.descriptionError ? "border-danger" : "mt-0 mb-0"}
                                              maxLength="100" type='textarea' label="Description"
                                              onChange={this.handleDescription} value={this.state.description}/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol size={"6"}>
                                    <MDBInput className={this.state.addressError ? "border-danger" : ""} maxLength="50"
                                              type='text' label="Address Line 1" onChange={this.handleAddress}
                                              value={this.state.address}/>
                                </MDBCol>
                                <MDBCol size={"6"}>
                                    <MDBInput className={this.state.streetError ? "border-danger" : ""} maxLength="50"
                                              type='text' label="Address Line 2" onChange={this.handleStreet}
                                              value={this.state.street}/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBInput className={this.state.pincodeError ? "border-danger" : ""} maxLength="6"
                                              type='text' label="Postal Code" onChange={this.handlePincode}
                                              value={this.state.pincode}/>
                                </MDBCol>
                                <MDBCol>
                                    <MDBInput className={this.state.areaError ? "border-danger" : ""} maxLength="35"
                                              type='text' label="Area/Locality" onChange={this.handleArea}
                                              value={this.state.area}/>
                                </MDBCol>
                                <MDBCol>
                                    <MDBInput className={this.state.cityError ? "border-danger" : ""} maxLength="35" type='text' label="City" onChange={this.handleCity}  value={this.state.city}/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBInput className={this.state.stateError ? "border-danger" : ""} maxLength="35"  type='text' label="State" onChange={this.handleState}  value={this.state.state}/>
                                </MDBCol>
                                <MDBCol>
                                    <MDBInput className={this.state.landmarkError ? "border-danger" : ""} maxLength="50"  type='text' label="Landmark" onChange={this.handleLandmark}  value={this.state.landmark}/>
                                </MDBCol>
                                <MDBCol>
                                    <MDBInput className={this.state.countryError ? "border-danger" : ""} maxLength="35"  type='text' label="Country" onChange={this.handleCountry} value={this.state.country}/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    {(this.state.imageUrl.hasOwnProperty("filePathThumb") && this.state.imageActionNeeded === "Update") &&
                                    <div>
                                        <img src={config.IMAGE_HOST + this.state.imageUrl.filePathThumb} className={"float-left"} style={{maxWidth: "100px", maxHeight: "100px"}} alt={""} />
                                        <span style={{"cursor":"pointer"}} className={"p-1"} onClick={()=>this.initDeleteDoc()}><MDBIcon icon="trash" /></span>
                                    </div>
                                    }
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    {this.state.imageActionNeeded === "Add" &&
                                    <FileUpload buttonLabel={"Add Manufacturer Logo"} initUploadCallback={this.initUploadCallback}
                                                fileUploadCallback={this.fileUploadCallback}></FileUpload>
                                    }
                                    {this.state.imageActionNeeded === "Update" &&
                                    <FileUpload buttonLabel={"Update Manufacturer Logo"} initUploadCallback={this.initUploadCallback}
                                                fileUploadCallback={this.fileUploadCallback}></FileUpload>
                                    }
                                </MDBCol>
                            </MDBRow>
                            <div className={'text-center'}>
                            <MDBBtn outline size="md" onClick={this.initDelete}
                                    disabled={this.state.progressStatus}>
                                Delete
                            </MDBBtn>
                             <MDBBtn   size="md" onClick={this.updateManufacturer}
                                          disabled={this.state.manufacturerError || this.state.progressStatus || this.state.progressStatusFileUpload}>
                                    Save
                             </MDBBtn>
                            </div>
                        </MDBCardBody>
                        <MDBModal isOpen={this.state.modalDelete} toggle={this.toggleDelete}>
                            <MDBModalHeader toggle={this.toggleDelete}>Delete Manufacturer</MDBModalHeader>
                            <MDBModalBody>
                                Are you sure you want to delete this manufacturer?
                            </MDBModalBody>
                            <MDBModalFooter>
                                <MDBBtn disabled={this.state.progressStatus} color="primary" onClick={this.confirmDelete}>Delete</MDBBtn>
                            </MDBModalFooter>
                        </MDBModal>
                        <MDBModal isOpen={this.state.modalDelImage} toggle={this.toggleDelImage}>
                            <MDBModalHeader toggle={this.toggleDelImage}>Delete Manufacturer Logo</MDBModalHeader>
                            <MDBModalBody>
                                Are you sure you want to delete the manufacturer logo?
                            </MDBModalBody>
                            <MDBModalFooter>
                                <MDBBtn disabled={this.state.progressStatus} color="primary" onClick={this.confirmDeleteImage}>Delete</MDBBtn>
                            </MDBModalFooter>
                        </MDBModal>
                    </MDBCard>
                </MDBContainer>
            </React.Fragment>
        );
    }
}
export default withRouter(UpdateManufacturer)
