import React from "react";
import utility from "../../utility/utility";
import { toast } from "react-toastify";
import config from "../../config";
import { MDBBtn } from "mdbreact";
import EditInvoice from "./EditInvoice";

class Invoice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            order: props.activeItem,
            serialNoArray: [],
            totalDiscount: 0,
            taxableAmounts: [],
            totalCgst: 0,
            isEdit: false
        }
    }

    async componentDidMount() {
        if (this.state.order) {
            console.log(this.state.order);
            let serialNoArray = [], k = 1, gstCollection = [], taxableAmounts = []
            for (let i = 0; i < this.state.order.cartData.length; i++) {
                if (this.state.order.cartData[i].hasOwnProperty("inventory")) {
                    for (let j = 0; j < this.state.order.cartData[i].inventory.length; j++) {
                        serialNoArray.push({ index: i, index1: j, serialNo: k })
                        ++k;
                        //Getting the collection of available gsts in the order
                        let cgst = (this.state.order.cartData[i].inventory[j].gst / 2)
                        if (gstCollection.indexOf(cgst) === -1) {
                            gstCollection.push(cgst)
                        }
                    }
                }
            }
            //prepare table (gst, taxable, tax)
            for (let item of gstCollection) {
                taxableAmounts.push({ gst: item, taxable: 0, tax: 0 })
            }
            await this.setState({ serialNoArray, taxableAmounts })
            await this.getTotalDiscount()
            await this.getTaxableAmount()
            await this.getTotalCgst()
            await this.getSubTotal()
        } else {
            toast.error("Something went wrong while fetching the order details.")
        }
    }

    getTaxableAmount = () => {
        let taxableAmounts = this.state.taxableAmounts, gstAmount = 0, taxable = 0, cgst = 0, sellingPrice = 0
        for (let row of this.state.order.cartData) {
            if (row.hasOwnProperty("inventory")) {
                for (let item of row.inventory) {
                    if (row.discount > 0) {
                        sellingPrice = (item.mrp - (item.mrp * row.discount / 100)) * item.quantityOut
                    } else {
                        sellingPrice = item.mrp * item.quantityOut
                    }
                    gstAmount = sellingPrice - (sellingPrice * (100 / (100 + item.gst)))
                    taxable = sellingPrice - gstAmount
                    cgst = (item.gst / 2)
                    for (let i = 0; i < taxableAmounts.length; i++) {
                        if (taxableAmounts[i].gst === cgst) {
                            taxableAmounts[i].taxable = taxableAmounts[i].taxable + taxable
                        }
                    }
                }
            }
        }
        for (let i = 0; i < taxableAmounts.length; i++) {
            taxableAmounts[i].tax = taxableAmounts[i].taxable * (taxableAmounts[i].gst / 100)
        }
        this.setState({ taxableAmounts })
    }
    getSerialNo = (index, index1) => {
        for (let item of this.state.serialNoArray) {
            if (index === item.index && index1 === item.index1) {
                return item.serialNo
            }
        }
    }
    //discount is calculated on MRP
    getTotalDiscount = () => {
        let totalDiscount = 0, discount = 0
        for (let row of this.state.order.cartData) {
            if (row.hasOwnProperty("inventory")) {
                for (let item of row.inventory) {
                    discount = utility.calculateDiscountAmount(item.mrp, item.quantityOut, row.discount, 1)
                    totalDiscount = totalDiscount + parseFloat(discount)
                }
            }
        }
        this.setState({ totalDiscount })
    }
    getTotalCgst = () => {
        let totalCgst = 0
        for (let item of this.state.taxableAmounts) {
            totalCgst = totalCgst + item.tax
        }
        this.setState({ totalCgst })
    }
    //Subtotal = Total + Discount + Additional Discount - CGST - SGST - shipping
    getSubTotal = () => {
        let totalDiscount = parseFloat(this.state.totalDiscount.toFixed(2)),
            totalCgst = parseFloat(this.state.totalCgst.toFixed(2)) * 2,
            totalAmount = parseFloat(this.state.order.totalAmount.toFixed(2)),
            subTotal = this.state.order.hasOwnProperty("couponDiscount") ? (totalAmount + totalDiscount + this.state.order.flatDiscount + this.state.order.couponDiscount - totalCgst - this.state.order.shippingAmount) :
                (totalAmount + totalDiscount + this.state.order.flatDiscount - totalCgst - this.state.order.shippingAmount)

        this.setState({ subTotal: subTotal.toFixed(2) })
    }
    //mrp - discount
    getAmount = (mrp, disc) => {
        return mrp - (mrp * disc / 100)
    }

    render() {
        const { order } = this.state;

        return (
            <div className="z-depth-1 p-3 my-2">
                {this.state.isEdit ? 
                <EditInvoice data={this.state.order} taxableAmounts={this.state.taxableAmounts} totalCgst={this.state.totalCgst} totalDiscount={this.state.totalDiscount} subTotal={this.state.subTotal} serialNoArray={this.state.serialNoArray}/> :
                    <div>
                        <div className={"col-12 row mx-0 text-center"}>
                            <div className={"col-md-12 m-auto"}><h4 >{this.state.order.status == "CANCELLED" ? (<label style={{ color: "red" }}>Order Has Been Cancelled : <b>Invalid Invoice</b></label>) : "Tax Invoice"}</h4></div>
                        </div>
                        <div className={"col-12 row mx-0 my-2"}>
                            <div className={"col-9 m-0 p-0"}>
                                <img src={"/positra_rx_logo.svg"} width={'150px'} />
                                <p className="mt-2">
                                    <span style={{ fontSize: "calc(100% + 2px)" }}> 1st Floor<br />
                                        Lakhimandir Market Complex, Beltola <br />
                                        Guwahati, Assam, 781028, India <br />
                                        Drug licence number: D/OL/KMP/18760 & D/OL/KMP/18761<br />
                                        Pharmacist: Sahidul Alam(15080)
                                    </span>
                                </p>
                            </div>
                            <div className={"col-3 m-0 p-0 text-left"}>
                                <p style={{ fontSize: "calc(100% + 2px)" }}>
                                    <b style={{ fontSize: "calc(100% + 2px)" }}>Invoice Date:</b> <span style={{ fontSize: "calc(100% + 2px)" }}> {order.hasOwnProperty("invoiceDate") ? utility.convertToReadbleDateTimeUtc(order.invoiceDate) : ""} </span>
                                    <br />
                                    <b style={{ fontSize: "calc(100% + 2px)" }}>GSTIN :</b> <span style={{ fontSize: "calc(100% + 2px)" }}>18AAICP6875B1Z5</span> <br />
                                    <b style={{ fontSize: "calc(100% + 2px)" }}>Invoice #:</b> <span style={{ fontSize: "calc(100% + 2px)" }}
                                        className={"text-uppercase"}>{order.hasOwnProperty("invoiceId") ? order.invoiceId : ""}</span>
                                    <br />
                                    <b style={{ fontSize: "calc(100% + 2px)" }}>Transaction Mode : </b>
                                    <span style={{ fontSize: "calc(100% + 2px)" }}>
                                        {order.paymentMode === "COD" ? "CASH" : "Online Payment"}
                                    </span>
                                    <br />
                                    <b style={{ fontSize: "calc(100% + 2px)" }}>Order Id:</b> <span style={{ fontSize: "calc(100% + 2px)" }} className={"text-uppercase"}>{this.state.order.orderCode}</span>
                                </p>
                            </div>
                        </div>
                        <section className="dark-grey-text">
                            <div className={"col-12 row mx-0 p-0"}>
                                <div className="col-4 mb-md-0 mb-5">
                                    <div className="row">
                                        <div className="col-lg-10 col-md-11 col-11">
                                            <h6 style={{ fontSize: "calc(100% + 2px)" }} className="font-weight-bold">Customer Details</h6>
                                            <p>
                                                <b style={{ fontSize: "calc(100% + 2px)" }}>Name:</b> <span style={{ fontSize: "calc(100% + 2px)" }} className={"text-capitalize"}>{order.customerDetails.name}</span>
                                                <br />
                                                <b style={{ fontSize: "calc(100% + 2px)" }}>Phone Number:</b> <span style={{ fontSize: "calc(100% + 2px)" }} > {order.shippingAddress.phone}</span> <br />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {(order.deliveryType.toUpperCase() !== "PICKUP") &&
                                    <div className="col-4">
                                        <div className="row">
                                            <div className="col-lg-10 col-md-11 col-11">
                                                <h6 className="font-weight-bold" style={{ fontSize: "calc(100% + 2px)" }} >Shipping From</h6>
                                                <p >
                                                    <span style={{ fontSize: "calc(100% + 2px)" }}> 1st Floor<br />
                                                        Lakhimandir Market Complex, Beltola <br />
                                                        Guwahati, Assam, 781028, India <br />
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="col-4">
                                    <div className="row">
                                        <div className="col-lg-10 col-md-11 col-11">
                                            {(order.deliveryType.toUpperCase() === "SHIP" || order.deliveryType.toUpperCase() === "DELIVER") &&
                                                <>
                                                    <h6 style={{ fontSize: "calc(100% + 2px)" }} className="font-weight-bold">Shipping To</h6>
                                                    <p style={{ fontSize: "calc(100% + 2px)" }}>
                                                        {(order.hasOwnProperty("shippingAddress") && order.shippingAddress !== "") ?
                                                            (<>
                                                                <span style={{ fontSize: "calc(100% + 2px)" }}
                                                                    className={"text-capitalize"}>{order.shippingAddress.name}</span>
                                                                <br />
                                                                {order.shippingAddress.addressLine1}{order.shippingAddress.addressLine2 &&
                                                                    <span style={{ fontSize: "calc(100% + 2px)" }}>,&nbsp;{order.shippingAddress.addressLine2}</span>}
                                                                <br />
                                                                <span style={{ fontSize: "calc(100% + 2px)" }}> {order.shippingAddress.city}, {order.shippingAddress.state}, {order.shippingAddress.pinCode}, {order.shippingAddress.country}<br /></span>
                                                                {order.shippingAddress.phone !== "" && <> <span style={{ fontSize: "calc(100% + 2px)" }}>Phone
                                                                    No: </span> {order.shippingAddress.phone}</>}
                                                            </>) : (
                                                                <div className={'no-data-container'}>No shipping address found.</div>
                                                            )
                                                        }
                                                    </p>
                                                </>
                                            }
                                            {(order.deliveryType.toUpperCase() === "PICKUP") &&
                                                <>
                                                    <h6 className="font-weight-bold">Pickup Address</h6>
                                                    <p style={{ fontSize: "calc(100% + 2px)" }}>{(order.hasOwnProperty("pickUpAddress") && order.pickUpAddress !== "") ?
                                                        (<>
                                                            <span className={"text-capitalize"}>{order.pickUpAddress.name}</span>
                                                            <br />
                                                            {order.pickUpAddress.point.addressLine1}{order.pickUpAddress.point.addressLine2 &&
                                                                <span>,&nbsp;{order.pickUpAddress.point.addressLine2}</span>}
                                                            <br />
                                                            {order.pickUpAddress.point.city}, {order.pickUpAddress.point.state}, {order.pickUpAddress.point.pin}, {order.pickUpAddress.point.country}<br />
                                                            Phone No: {order.pickUpAddress.phone}
                                                        </>) : (
                                                            <div className={'no-data-container'}>No pickup address found.</div>
                                                        )
                                                    }</p>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section>
                            <div className="table-responsive p-3">
                                <table className="table table-sm" cellSpacing="0">
                                    <thead>
                                        <tr>
                                            <th className="font-weight-bold" style={{ fontSize: "calc(100% + 2px)" }}>Sl.</th>
                                            <th className="px-0 font-weight-bold">
                                                <strong style={{ fontSize: "calc(100% + 2px)" }}>Product Description</strong>
                                            </th>
                                            <th className="px-0 font-weight-bold">
                                                <strong style={{ fontSize: "calc(100% + 2px)" }}>HSN</strong>
                                            </th>
                                            <th className="px-0 font-weight-bold">
                                                <strong style={{ fontSize: "calc(100% + 2px)" }}>Batch</strong>
                                            </th>
                                            <th className="px-0 font-weight-bold">
                                                <strong style={{ fontSize: "calc(100% + 2px)" }}>Exp.</strong>
                                            </th>
                                            <th className="px-0 font-weight-bold">
                                                <strong style={{ fontSize: "calc(100% + 2px)" }}>M.R.P (<span
                                                    dangerouslySetInnerHTML={{ __html: config.CURRENCY_HTML }}></span>)</strong>
                                            </th>
                                            <th className="px-0 font-weight-bold">
                                                <strong style={{ fontSize: "calc(100% + 2px)" }}>Qty.</strong>
                                            </th>
                                            <th className="px-0 font-weight-bold">
                                                <strong style={{ fontSize: "calc(100% + 2px)" }}>Disc. %</strong>
                                            </th>
                                            <th className="px-0 font-weight-bold">
                                                <strong style={{ fontSize: "calc(100% + 2px)" }}>GST %</strong>
                                            </th>
                                            <th className="px-0 font-weight-bold">
                                                <strong style={{ fontSize: "calc(100% + 2px)" }}>Amount (<span
                                                    dangerouslySetInnerHTML={{ __html: config.CURRENCY_HTML }}></span>)</strong>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className={"text-dark"}>
                                        {this.state.order.cartData.map((row, index) => (
                                            row.hasOwnProperty("inventory") && row.inventory.map((item, index1) => (
                                                <tr key={index + "_" + index1}>
                                                    <td>{this.getSerialNo(index, index1)}</td>
                                                    <td className={"px-0"}>{row.productName}</td>
                                                    <td className={"px-0"}>{item.hsn}</td>
                                                    <td className={"px-0"}>{item.batch}</td>
                                                    <td className={"px-0"}>{utility.convertMfgOrExpiry(item.expDate)}</td>
                                                    <td className={"px-0"}>{item.mrp.toFixed(2)}</td>
                                                    <td className={"px-0"}><span className="qty">{item.quantityOut}</span></td>
                                                    <td className={"px-0"}>{row.discount}</td>
                                                    <td className={"px-0"}>{item.gst}</td>
                                                    <td className={"px-0"}>{(this.getAmount(item.mrp, row.discount) * item.quantityOut).toFixed(2)}</td>
                                                </tr>
                                            ))
                                        ))}
                                    </tbody>
                                </table>
                                <table className="table table-sm mt-n3" cellSpacing="0">
                                    <thead>
                                        <tr>
                                            <th><strong style={{ fontSize: "calc(100% + 2px)" }}>SGST</strong></th>
                                            <th><strong style={{ fontSize: "calc(100% + 2px)" }}> CGST</strong></th>
                                            <th></th>
                                            <th></th>
                                            <th><strong style={{ fontSize: "calc(100% + 2px)" }}>TOTALS</strong></th>
                                        </tr>
                                    </thead>
                                    <tbody className={"text-dark"}>
                                        <tr>
                                            <td style={{ "paddingLeft": "0px" }}>
                                                <table className={"table table-sm table-borderless mb-n2 mt-n1"} cellSpacing={"0"}>
                                                    <thead style={{ fontSize: "calc(100% + 2px)" }}>
                                                        <tr>
                                                            <th className={"text-left"}>GST Rate %</th>
                                                            <th className={"text-center"}>Taxable (<span
                                                                dangerouslySetInnerHTML={{ __html: config.CURRENCY_HTML }}></span>)
                                                            </th>
                                                            <th className={"text-right"}>Tax (<span
                                                                dangerouslySetInnerHTML={{ __html: config.CURRENCY_HTML }}></span>)
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.taxableAmounts.map((i, index) => (
                                                            <tr key={index}>
                                                                <td className={"text-left"}>{i.gst}</td>
                                                                <td className={"text-center"}>{i.taxable.toFixed(2)}</td>
                                                                <td className={"text-right"}>{i.tax.toFixed(2)}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td>
                                                <table className={"table table-sm table-borderless mb-n2 mt-n1"} cellSpacing={"0"}>
                                                    <thead style={{ fontSize: "calc(100% + 2px)" }} className={"grey lighten-4"}>
                                                        <tr>
                                                            <th className={"text-left"}>GST Rate %</th>
                                                            <th className={"text-center"}>Taxable (<span
                                                                dangerouslySetInnerHTML={{ __html: config.CURRENCY_HTML }}></span>)
                                                            </th>
                                                            <th className={"text-right"}>Tax (<span
                                                                dangerouslySetInnerHTML={{ __html: config.CURRENCY_HTML }}></span>)
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.taxableAmounts.map((i, index) => (
                                                            <tr key={index}>
                                                                <td className={"text-left"}>{i.gst}</td>
                                                                <td className={"text-center"}>{i.taxable.toFixed(2)}</td>
                                                                <td className={"text-right"}>{i.tax.toFixed(2)}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td colSpan={"2"} className={"border-0"}></td>
                                            <td style={{ "paddingRight": "0px" }}>
                                                <table className={"table table-sm table-borderless mt-n2 mb-n2"} cellSpacing={"0"}>
                                                    <tbody >
                                                        <tr>
                                                            <td className={"text-left border-right-0"}>
                                                                <strong style={{ fontSize: "calc(100% + 2px)" }}>SUB TOTAL</strong><br />
                                                                <strong style={{ fontSize: "calc(100% + 2px)" }}>Discount</strong><br />
                                                                {/*<strong>SGST</strong><br/>*/}
                                                                {/*<strong>CGST</strong><br/>*/}
                                                                <strong style={{ fontSize: "calc(100% + 2px)" }}>Shipping</strong><br />
                                                                {(order.hasOwnProperty("couponDiscount") && order.couponDiscount !== 0) && <>
                                                                    <strong>Coupon Discount</strong><br />
                                                                </>}
                                                                <strong style={{ fontSize: "calc(100% + 2px)" }}>Additional Discount</strong><br />
                                                            </td>
                                                            <td className={"text-right border-left-0"}>
                                                                {(parseFloat(this.state.subTotal) + parseFloat(this.state.totalCgst) + parseFloat(this.state.totalCgst)).toFixed(2)}<br />
                                                                {this.state.totalDiscount.toFixed(2)}<br />
                                                                {/*{this.state.totalCgst.toFixed(2)}<br/>*/}
                                                                {/*{this.state.totalCgst.toFixed(2)}<br/>*/}
                                                                {order.shippingAmount.toFixed(2)}<br />
                                                                {(order.hasOwnProperty("couponDiscount") && order.couponDiscount !== 0) && <>
                                                                    {order.couponDiscount.toFixed(2)} <br />
                                                                </>}
                                                                {order.flatDiscount.toFixed(2)}<br />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span style={{ fontSize: "calc(100% + 2px)" }} className={"float-left"}><strong >Total</strong></span>
                                                <span className={"float-right"}><strong><span style={{ fontSize: "calc(100% + 2px)" }}
                                                    dangerouslySetInnerHTML={{ __html: config.CURRENCY_HTML }}></span> {this.state.totalCgst.toFixed(2)}</strong></span>
                                            </td>
                                            <td>
                                                <span style={{ fontSize: "calc(100% + 2px)" }} className={"float-left"}><strong >Total</strong></span>
                                                <span className={"float-right"}><strong><span style={{ fontSize: "calc(100% + 2px)" }}
                                                    dangerouslySetInnerHTML={{ __html: config.CURRENCY_HTML }}></span> {this.state.totalCgst.toFixed(2)}</strong></span>
                                            </td>
                                            <td colSpan={"2"} className={"border-0"}></td>
                                            <td>
                                                <span style={{ fontSize: "calc(100% + 2px)" }} className={"float-left"}><h5 >Grand Total</h5></span>
                                                <span className={"float-right"}><h5><span
                                                    dangerouslySetInnerHTML={{ __html: config.CURRENCY_HTML }}></span> {this.state.order.totalAmount.toFixed(2)}</h5></span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </section>
                        {!order.hasOwnProperty("couponDiscount") && (this.state.totalDiscount + order.flatDiscount) > 0 &&
                            <div className={"text-center"}><h5>* You saved <span
                                dangerouslySetInnerHTML={{ __html: config.CURRENCY_HTML }}></span> {(this.state.totalDiscount + order.flatDiscount).toFixed(2)} on
                                this order.</h5></div>
                        }
                        {order.hasOwnProperty("couponDiscount") && (this.state.totalDiscount + order.flatDiscount + order.couponDiscount) > 0 &&
                            <div className={"text-center"}><h5>* You saved <span
                                dangerouslySetInnerHTML={{ __html: config.CURRENCY_HTML }}></span> {(this.state.totalDiscount + order.flatDiscount + order.couponDiscount).toFixed(2)} on
                                this order.</h5></div>
                        }
                    </div>
                }
                {/* <div className={"col-12 d-flex justify-content-between mt-2"}>
                        <MDBBtn color='warning' onClick={()=> this.setState({isEdit: !this.state.isEdit})}>{this.state.isEdit? 'Stop Editing':'Edit Invoice'}</MDBBtn>
                </div> */}
            </div>
        );
    }
}

export default Invoice;