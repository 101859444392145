import React from "react";
import { MDBContainer, MDBRow, MDBInput, MDBProgress, MDBCardHeader, MDBCardBody, MDBCard, MDBCol, MDBSelect, MDBSelectInput, MDBSelectOptions, MDBSelectOption } from 'mdbreact';
import { toast } from "react-toastify";
import UserStore from "../../stores/userStore";
import AuthService from "../../services/authService";
import OrderService from "../../services/orderService";
import util from "../../utility/utility";
import { withRouter } from "react-router-dom";
import TableProductRequest from "../../newComponents/productSuggestTable";
import AutocompleteUser from "../../newComponents/AutocompleteUser";

class AddProductRequest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            resetKey: 0,
            orgId: 0,
            userData: {},
            progressStatus: false,
            customer: "POSITRA", 
            productRequestData: [],
            contactNo: "",
            priority: "HIGH",
            status: "CREATED",
            expectedDeliveryHours: null,
            expectedDeliveryDays: null,
            isDays: true,
            deliveryDropdown: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            currentProductIndex: 0,
            quantityVal: 5,
            customerToSend: {
                _id: "",
                name: ""
            },
            productName: "",
            customerList: [],
            selectedCustomerInfo: {}
        }
    }

    async componentDidMount() {
        let userData = await UserStore.fetchSessionData();
        this.setState({ userData: userData })
        if (userData.type === "platformAdmin") {
            this.setState({ orgId: userData._id })
        } else if (userData.type === "ORG_ADMIN") {
            this.setState({ orgId: userData.orgDetails[0]._id })
        }
        try {
            if (this.props.product) {
                let product = this.props.product;
                let productRequestData = [];
                let productDetails = 
                    {   productName: product.productName,
                        priceWgst: product.maxRetailPrice,
                        discount: product.discount,
                        purchasePriceAfterDiscount: product.maxRetailPrice - ((product.maxRetailPrice * product.discount)/100),
                        quantity: product.quantity,
                        quantityError: product.quantityError,
                        productId: product._id,
                        productNameError: false,
                        totalAmount: (product.maxRetailPrice - ((product.maxRetailPrice * product.discount)/100)) * product.quantity
                    };
                productRequestData.push(productDetails);
                this.setState({
                    productName: product.productName,
                    quantityVal: product.quantity,
                    productRequestData
                })
            }
        }  catch (e) {
            toast.error("Something went wrong while adding the product.");
        }
    }

    handleDays = (value) => {
        if (value == "Days") {
            this.setState(({
                deliveryDropdown: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                isDays: true
            }))
        }
        else if (value == "Hours") {
            this.setState(({
                deliveryDropdown: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
                isDays: false
            }))
        }

    }

    handleTime = (value) => {
        if (this.state.isDays) {
            this.setState(({
                expectedDeliveryDays: value,
                expectedDeliveryHours: null
            }))
        }
        else {
            this.setState(({
                expectedDeliveryHours: value,
                expectedDeliveryDays: null
            }))
        }
    }

    handleName = (e) => {
        this.setState({ name: e.target.value })
    };

    clearState = async () => {
        this.setState({
            resetKey: this.state.resetKey + 1,
            orgId: 0,
            productRequest: '',
            progressStatus: false,
            name: "",
            customer: "", // Default
            productRequestData: [],
            contactNo: "",
            priority: "HIGH",
            status: "CREATED",
            quantityVal:5,
            expectedDeliveryHours: null,
            expectedDeliveryDays: null,
            isDays: true,
            deliveryDropdown: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            customerToSend: {
                _id: "",
                name: ""
            }

        })
        let userData = await UserStore.fetchSessionData();
        this.setState({ userData: userData })
        if (userData.type === "platformAdmin") {
            this.setState({ orgId: userData._id })
        } else if (userData.type === "ORG_ADMIN") {
            this.setState({ orgId: userData.orgDetails[0]._id })
        }
    }

    addProductRequest = async () => {
        window.scrollTo(0, 0)
        if(this.state.customerToSend.name !== "POSITRA DRUG STORE" && !this.state.customerToSend.contactNo) {
            toast.error("Please select a valid customer")
            return
        }
        if (!this.state.expectedDeliveryDays && !this.state.expectedDeliveryHours) {
            toast.error("Please select expected delivery time");
            this.setState({ progressStatus: false });
            return;
        }
        for (let index = 0; index < this.state.productRequestData.length; index++) {
            if (!this.state.productRequestData[index].productName) {
                toast.error("Product name not found.")
                return false;
            } 
            if (!this.state.productRequestData[index].quantity || this.state.productRequestData[index].quantity <= 0) {
                toast.error("Quantity can not be empty or zero.")
                return false;
            }
        }
        this.setState({ progressStatus: true });
        const payload = {
            orgId: this.state.orgId,
            expectedDeliveryHours: this.state.expectedDeliveryHours,
            expectedDeliveryDays: this.state.expectedDeliveryDays,
            isDays: this.state.isDays,
            productData: this.state.productRequestData,
            priority: this.state.priority,
            status: this.state.status,
            customerDetails: this.state.customerToSend,
            customerPhone: this.state.contactNo === "" ? this.state.customerToSend?.contactNo : this.state.contactNo,
            comments: ""
        }
        let status = await OrderService.addProductRequest(payload, this.state.userData.sessionToken);
        this.setState({ progressStatus: false });
        if (status === false) {
            toast.error("Something went wrong.");
        } else {
            this.setState({ productRequest: "" });
            toast.success("Purchase Request added successfully.");
            this.props?.togglePurchaseRqst && this.props.togglePurchaseRqst();
            this.clearState()
        }
    }

    handleQuantity = (index) => (e) => {
        if (!util.isInt(e.target.value) && e.target.value !== "") {
            return false;
        }
        let existingData = this.state.productRequestData;
        if (existingData?.[this.state.currentProductIndex]?.totalAmount) {
            let amount =
            existingData[this.state.currentProductIndex].totalAmount = (e.target.value * existingData[this.state.currentProductIndex]?.purchasePriceAfterDiscount)?.toFixed(2) ?? 0
            existingData[index].quantity = e.target.value
            this.setState({ productRequestData: existingData, quantityVal: e.target.value })
        }
        else {
            this.setState({ quantityVal: e.target.value })
        }
    };

    onClick = (nr) => {
        this.setState({
            priority: nr
        });
    }

    selectCustomer = async (customer) => {
        this.setState({
            customer: customer,
            name: (customer == "POSITRA" ? "Positra Drug Store" : ""),
            contactNo: (customer == "POSITRA" ? "6900702871" : "")
        })
        if (customer == "POSITRA") {
            this.setState({
                customerToSend: {
                    _id: "",
                    name: "POSITRA DRUG STORE"
                }
            })
        }
    }

    editProduct = (index) => {}

    deleteProduct = (index) => {}

    handleSelect = (val, item) => {
        if (item) {
            this.setState({ selectedCustomerInfo: item, customerToSend: item })
        }
        else {
            this.setState({ selectedCustomerInfo: {} })
        }
    }
    handleCustomerSearch = async (value) => {
        // this.setState({searchedCustomerText: e.target.value})
        if (value < 2) {
            return false;
        }

        let resUser = await AuthService.suggestCustomerNew(value, this.state.userData.sessionToken);
        if (resUser?.length) {
            this.setState({
                customerList: resUser
            })
        }
        else {
            this.setState({
                customerList: []
            })
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.state.progressStatus === true &&
                    <span className={'top-stick'}><MDBProgress material preloader color="cyan" /></span>
                }
                <MDBContainer>
                    <MDBCard className={"mt-2"}>
                        <MDBCardHeader>
                            <div className={"title float-left"}>Add Purchase Request</div>
                        </MDBCardHeader>

                        <MDBRow className={"mx-4 mt-2"}>
                            <MDBCol>
                                <div className={"px-2"}>
                                    <b>Select Customer</b>
                                </div>
                                <div style={{ width: '200px' }}>
                                    <MDBSelect getValue={(val) => this.selectCustomer(val[0])} key={this.state.resetKey} >
                                        <MDBSelectInput selected={this.state.customer || "Select Customer"} />
                                        <MDBSelectOptions>
                                            <MDBSelectOption value="POSITRA" selected={"true"}>Positra Drug Store</MDBSelectOption>
                                            <MDBSelectOption value="OTHER">Other Customer</MDBSelectOption>
                                        </MDBSelectOptions>
                                    </MDBSelect>
                                </div>
                                <div className="d-flex justify-content-start">
                                    <div>
                                        <div class="btn-group" role="group" aria-label="Basic example">
                                            <button onClick={() => this.handleDays("Days")} type="button" class={this.state.isDays ? "btn btn-primary" : "btn"} >Days</button>
                                            <button onClick={() => this.handleDays("Hours")} type="button" class={!this.state.isDays ? "btn btn-primary" : "btn"}>Hours</button>
                                        </div>
                                    </div>
                                    <div style={{ width: '100px' }}>
                                        <MDBSelect key={this.state.resetKey} getValue={(val) => this.handleTime(val[0])}>
                                            <MDBSelectInput selected={this.state.isDays ? this.state.expectedDeliveryDays ? this.state.expectedDeliveryDays : 'Select Time' : this.state.expectedDeliveryHours ? this.state.expectedDeliveryHours : "Select Time"} />
                                            <MDBSelectOptions>
                                                {this.state.deliveryDropdown.map((item) =>
                                                    <MDBSelectOption value={item}>{item} {this.state.isDays ? "Days" : "Hours"}</MDBSelectOption>
                                                )}
                                            </MDBSelectOptions>
                                        </MDBSelect>
                                    </div>
                                </div>
                            </MDBCol>
                            <MDBCol col={"3"}>
                                <div className={"px-2"}>
                                    <b>Priority</b>
                                </div>
                                <div class="btn-group" role="group" aria-label="Basic example">
                                    <button onClick={() => this.onClick("LOW")} type="button" class={this.state.priority == 'LOW' ? "btn btn-primary" : "btn"} >Low</button>
                                    <button onClick={() => this.onClick("MEDIUM")} type="button" class={this.state.priority == 'MEDIUM' ? "btn btn-primary" : "btn"}>Medium</button>
                                    <button onClick={() => this.onClick("HIGH")} type="button" class={this.state.priority == 'HIGH' ? "btn btn-primary" : "btn"}>High</button>
                                </div>
                            </MDBCol>
                            <MDBCol>
                                {this.state.customer === "OTHER" &&
                                    <MDBRow>
                                        <MDBCol style={{ marginTop: "1.2em" }}>
                                            <AutocompleteUser
                                                label="Enter Customer Name or phone"
                                                name="customer"
                                                options={this.state.customerList}
                                                onSelect={(val, item) => this.handleSelect(val, item)}
                                                onChange={(val) => { this.handleCustomerSearch(val) }} />
                                            {Object.keys(this.state.selectedCustomerInfo)?.length > 0 && <div className="customerDropdown">
                                                <span>Name : {this.state.selectedCustomerInfo?.name}</span><br />
                                                <span>Phone :{this.state.selectedCustomerInfo?.contactNo?.length > 1 ? this.state.selectedCustomerInfo?.contactNo : "Not Available"}</span><br />
                                                <span>Email :{this.state.selectedCustomerInfo?.email?.length > 1 ? this.state.selectedCustomerInfo?.email : "Not Available"}</span>
                                            </div>}
                                        </MDBCol>
                                    </MDBRow>
                                }
                            </MDBCol>
                        </MDBRow>
                        <MDBCardBody>
                            <MDBRow className={'mx-0'}>
                                <MDBCol size={"5"} className={"mt-1"}>
                                    {this.state.hasOwnProperty("productName") && (
                                        <>
                                        <div className={"px-2"}>
                                            <b>Product Name</b>
                                        </div>
                                        <MDBCard style={{ width: "100%" }}>
                                        <MDBCardBody>
                                            <MDBRow>
                                            <MDBCol className={"float-left"}>
                                                <div>{this.state.productName}</div>
                                            </MDBCol>
                                            </MDBRow>
                                        </MDBCardBody>
                                        </MDBCard>
                                        </>
                                    )}
                                </MDBCol>
                                <MDBCol className={"col-1"}>
                                    <div className={"p-0 m-0 col-12"}>

                                        <MDBInput outline={true} className={this.state.productRequestData?.[this.state.currentProductIndex]?.quantityError ? "border-danger" : ""} maxLength="15"
                                            type='text' label="Quantity" onChange={this.handleQuantity(this.state.currentProductIndex)}
                                            value={this.state.quantityVal} />
                                    </div>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                    {this.state.productRequestData.length > 0 &&
                     <TableProductRequest data={[...this.state.productRequestData]} editProduct={this.editProduct} deleteProduct={this.deleteProduct} fromOnlineOrder={true}/>}
                    <MDBRow className={"mx-12 mt-12"}>
                        <MDBCol className={"col-12"} style={{ display: "flex", justifyContent: "center",marginTop:".5em" }}>
                            <div class="btn-group" role="group" aria-label="Basic example">
                                <button disabled={this.state.progressStatus} onClick={this.addProductRequest} type="button" class={"btn btn-primary"}>Save</button>
                            </div>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </React.Fragment>
        );
    }
}
export default withRouter(AddProductRequest);