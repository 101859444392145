import React from 'react';
import {MDBBox, MDBBtn, MDBCol, MDBContainer, MDBInput, MDBModalBody, MDBRow, MDBSelect, toast} from "mdbreact";
import AuthService from "../../services/authService";
import UserStore from "../../stores/userStore";
import util from "../../utility/utility";
import orgConfig from "../../orgConfig";
import SpecialityService from "../../services/specialityService";
import MomentUtils from "@date-io/moment";
import {KeyboardDatePicker, DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import Moments from "moment";
export default  class AddDoctor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId:"",
            dependentId:"",
            contactNo:"",
            name:  "",
            address: "",
            street: "",
            pincode: "",
            area:"",
            city: "",
            state: "",
            country: "India",
            landmark : "",
            altPhone : "",
            default:true,
            addressId: "",
            regNo:"",
            regNoError:false,
            contactNoError:false,
            nameError:false,
            addressError:false,
            streetError:false,
            pincodeError:false,
            areaError:false,
            cityError:false,
            stateError:false,
            countryError:false,
            landmarkError:false,
            altPhoneError:false,
            specialityError:false,
            email:"",
            speciality:"",
            specialityList:[],
            specialityListOriginal:'',
            doctorSince: Moments()
        }
    }
    async componentDidMount() {
        let userData = await UserStore.fetchSessionData();
        this.setState({userData: userData})
        if(userData.type === "platformAdmin"){
            this.setState({orgId: userData._id})
            this.setState({numId: userData.numId})
        } else if(userData.type === "ORG_ADMIN"){
            this.setState({orgId: userData.orgDetails[0]._id})
            this.setState({numId: userData.orgDetails[0].numId})
        }
        try{
            this.setState({"default": true})
        } catch (e) {
            toast.error("Error in setting default address");
        }

        //this.setState({progressStatus:true});
        let res=await SpecialityService.fetchMasterSpeciality(userData);
        let temp=[];
        for(let item of res){
            let t={
                text: item.specialityGroup,
                value: item._id+":"+item.specialityGroup
            }
            temp.push(t);
        }
        this.setState({specialityList:temp,specialityListOriginal:JSON.stringify(temp)});
    }
    handleChangeContactNo=(e)=>{
        if(!util.isInt(e.target.value) && e.target.value !== ""){
            return false;
        }
        this.setState({contactNo:e.target.value})
    };
    handleChangeEmail=(e)=>{
        this.setState({email:e.target.value})
    };
    handleName=(e)=>{
        this.setState({name:e.target.value})
    };
    handleRegNo=(e)=>{
        this.setState({regNo:e.target.value})
    };
    handleSpeciality=(value)=>{
        this.setState({speciality:value})
    };
    handleAddress=(e)=>{
        this.setState({address:e.target.value})
    };
    handleStreet=(e)=>{
        this.setState({street:e.target.value})
    };
    handlePincode=(e)=>{
        if(!util.isInt(e.target.value) && e.target.value !== ""){
            return false;
        }
        this.setState({pincode:e.target.value})
    };
    handleArea=(e)=>{
        this.setState({area:e.target.value})
    };
    handleCity=(e)=>{
        this.setState({city:e.target.value})
    };
    handleState=(e)=>{
        this.setState({state:e.target.value})
    };
    handleCountry=(e)=>{
        this.setState({country:e.target.value})
    };
    handleLandmark=(e)=>{
        this.setState({landmark:e.target.value})
    };
    handleContactNo=(e)=>{
        if(!util.isInt(e.target.value) && e.target.value !== ""){
            return false;
        }
        this.setState({contactNo:e.target.value})
    };
    handleAltphone=(e)=>{
        if(!util.isInt(e.target.value) && e.target.value !== ""){
            return false;
        }
        this.setState({altPhone:e.target.value})
    };
    validateAltPhone=()=>{
        if(this.state.altPhone.trim() !== "" && !util.checkPhone(this.state.altPhone)){
            this.setState({altPhoneError:true});
            toast.error("Please enter a 10 digit alternate phone number.");
            return true;
        } else {
            this.setState({altPhoneError:false});
        }
    }
    validateEmail = () => {
        if(this.state.email && !util.validateEmail(this.state.email)){
            this.setState({"loadingEmail": true, "emailError": true})
        } else {
            this.setState({"loadingEmail": false, "emailError": false})
        }
    }
    addDoctor = async () => {
        if(this.state.name.trim() === ""){
            this.setState({nameError:true});
            toast.error("Please enter your full name.")
            return true;
        } else {
            this.setState({nameError:false});
        }
        this.setState({"loading": true});
        let addressResult = await AuthService.addDoctor(this.state);
        this.setState({"loading": false});
        if(!addressResult){
            toast.error("Something went wrong.");
        } else {
            this.props.addDoctorCompleted();
            let tempList=this.state.specialityListOriginal;
            this.setState({specialityList:JSON.parse(tempList)});
        }
    }
    validateName=()=>{
        if(this.state.name.trim() === ''){
            return true;
        } else {
            this.setState({nameError: false});
        }
    };
    validateAddress=()=>{
        if(this.state.address.trim() === ''){
            return true;
        } else {
            this.setState({addressError: false});
        }
    };
    validatePincode=()=>{
        if(this.state.pincode.trim() === ''){
            return true;
        } else {
            this.setState({pincodeError: false});
        }
    };
    validateArea=()=>{
        if(this.state.area === ''){
            return true;
        } else {
            this.setState({areaError: false});
        }
    };
    validateCity=()=>{
        if(this.state.city.trim() === ''){
            return true;
        } else {
            this.setState({cityError: false});
        }
    };
    validateState=()=>{
        if(this.state.state.trim() === ''){
            return true;
        } else {
            this.setState({stateError: false});
        }
    };
    render() {
        return (
            <MDBBox>
                <MDBRow >
                    <div className={'col-md-6'}>
                        <MDBInput onBlur={()=>this.validateName()} className={this.state.nameError ? "border-danger" :""} maxLength="50"  type='text' label="Full Name *" onChange={this.handleName} value={this.state.name}/>
                    </div>

                    <div className={'col-md-6'}>
                        <MDBInput className={this.state.regNoError ? "border-danger" :""} maxLength="50"  type='text' label="Reg No." onChange={this.handleRegNo} value={this.state.regNo}/>
                    </div>
                </MDBRow>
                <MDBRow >
                    <div className={'col-md-6'}>
                        <b>Speciality</b>
                        <MDBSelect
                            search
                            options={this.state.specialityList}
                            selected="Choose Speciality"
                            getValue={this.handleSpeciality}
                            className={this.state.specialityError ? "border-danger" :""}
                            color="primary"
                        />
                        {/*<MDBInput className={this.state.specialityError ? "border-danger" :""} maxLength="100"  type='text' label="Speciality" onChange={this.handleSpeciality} value={this.state.speciality}/>*/}
                    </div>
                    <div className={"mt-2 col-md-6"}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker className={"col-12"}
                                format="MMM D, YYYY"
                                maxDate={new Date()}
                                margin="normal"
                                id="doctorSince"
                                label="Doctor Since"
                                value={this.state.doctorSince}
                                onChange={(e) => this.setState({doctorSince: new Date(e)})}
                                openTo="year"
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                </MDBRow>
                <MDBRow >
                    <div className={'col-md-6'}>
                        <MDBInput className={this.state.contactNoError ? "border-danger" :""} maxLength={orgConfig.PHONE_NUMBER_LENGTH}  type='text' label="Phone" onChange={this.handleContactNo} value={this.state.contactNo}/>
                    </div>
                    <div className={'col-md-6'}>
                        <MDBInput onBlur={() => this.validateEmail()}
                                  autoComplete={'off'}
                                  className={this.state.emailError ? "border-danger" : ""}
                                  maxLength="50" type='text' label="E-mail"
                                  onChange={this.handleChangeEmail}
                                  value={this.state.email}/>
                    </div>
                </MDBRow>
                <MDBRow >
                    <div className={'col-md-6'}>
                        <MDBInput onBlur={()=>this.validateAddress()} className={this.state.addressError ? "border-danger" :""} maxLength="50"  type='text' label="Address Line 1" onChange={this.handleAddress} value={this.state.address}/>
                    </div>
                    <div className={'col-md-6'}>
                        <MDBInput className={this.state.streetError ? "border-danger" :""} maxLength="50"  type='text' label="Address Line 2" onChange={this.handleStreet} value={this.state.street}/>
                    </div>
                </MDBRow>
                <MDBRow >
                    <div className={'col-md-6'}>
                        <MDBInput onBlur={()=>this.validatePincode()} className={this.state.pincodeError ? "border-danger" :""} maxLength="6"  type='text' label="PIN" onChange={this.handlePincode} value={this.state.pincode}/>
                    </div>
                    <div className={'col-md-6'}>
                        <MDBInput  className={this.state.areaError ? "border-danger" :""} maxLength="35"  type='text' label="Area/Locality" onChange={this.handleArea} value={this.state.area}/>
                    </div>
                </MDBRow>
                <MDBRow >
                    <div className={'col-md-6'}>
                        <MDBInput onBlur={()=>this.validateCity()} className={this.state.cityError ? "border-danger" :""} maxLength="35"  type='text' label="City" onChange={this.handleCity} value={this.state.city}/>
                    </div>
                    <div className={'col-md-6'}>
                        <MDBInput onBlur={()=>this.validateState()} className={this.state.stateError ? "border-danger" :""} maxLength="35"  type='text' label="State" onChange={this.handleState} value={this.state.state}/>
                    </div>
                </MDBRow>
                <MDBRow >
                    <div className={'col-md-6'}>
                        <MDBInput className={this.state.landmarkError ? "border-danger" :""} maxLength="50"  type='text' label="Landmark" onChange={this.handleLandmark} value={this.state.landmark}/>
                    </div>
                    <div className={'col-md-6'}>
                        <MDBInput onBlur={()=>this.validateAltPhone()} className={this.state.altPhoneError ? "border-danger" :""} maxLength={orgConfig.PHONE_NUMBER_LENGTH}  type='text' label="Alternate Phone" onChange={this.handleAltphone} value={this.state.altPhone}/>
                    </div>
                </MDBRow>
                <MDBRow className={"mt-2 d-flex justify-content-center"}>
                    <MDBBtn size={'md'} disabled={this.state.loading}  color={"default"} onClick={() => this.addDoctor()}>Save</MDBBtn>
                </MDBRow>
            </MDBBox>
        );
    }
}

