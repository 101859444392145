import React, { useEffect, useState } from "react";
import TransferStockService from "../../services/transferStockService";
import UserStore from "../../stores/userStore";
import { toast } from "react-toastify";
import NewTable from "../../newComponents/NewTable/NewTable";
import { STOCK_TABLE_WITH_LOCATION_TABLE } from "../../utility/constants";
import { formatStockByLocation } from "../../utility/tableUtils";
import SimpleBackdrop from "../../components/common/overlay";
import NewPagination from "../../newComponents/NewPagination/NewPagination";
import TextField from "../../newComponents/Textfield";
import { Button } from "@material-ui/core";
import RelocateModal from "../RelocateRack/RelocateModal";

const RelocateRackByBar = () => {
    const [filter, setFilter] = useState({
        locationId: "",
        floorId: "",
        rackId: "",
        boxId: "",
    });
    const [userData, setUserData] = useState({});
    const [listData, setListData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [perPage, setPerPage] = useState(0);
    const [page, setPage] = useState(0);
    const [barcode, setBarcode] = useState("")
    const [isModalOpen, setIsModalOpen] = useState(false);

    const filterHandler = async () => {
        if(barcode.length>=12){
            setLoading(true);
            const listData = await TransferStockService.fetchStockByBarcodeNew(
                barcode,
                page + 1,
                userData?.sessionToken
            );
            if (listData?.data?.length) {
                setListData(listData?.data);
                setTotalRecords(listData?.totalRecords);
                setPerPage(listData?.recordPerPage);
            } else {
                toast.error(listData?.response?.data?.message ?? "Something went wrong");
                setListData([]);
                setTotalRecords(0);
                setPerPage(0);
            }
            setLoading(false);
        }
    };

    useEffect(() => {
        if (barcode.length < 13) {
            setListData([]);
            setTotalRecords(0);
            setPerPage(0);
        }
    }, [barcode]);

    useEffect(() => {
        const fetchUserData = async () => {
            let userData = await UserStore.fetchSessionData();;
            setUserData(userData);
        };
        fetchUserData();
    }, []);

    const relocateStock = async (storageInfo) => {
        setLoading(true)
        const { boxId, floorId, locationId, rackId } = storageInfo
        const payload = {
            barCode: barcode,
            locationIdTo: locationId,
            floorIdTo: floorId,
            rackIdTo: rackId,
            boxIdTo: boxId
        }
        const relocateStock = await TransferStockService.barcodeRelocate(userData?.sessionToken, payload)
        if (relocateStock?.message) {
            setLoading(false)
            toast.success(relocateStock?.message)
            setBarcode("")
            setListData([]);
            setTotalRecords(0);
            setPerPage(0);
            setIsModalOpen(false)
        }
        else {
            setLoading(false)
            toast.error("Something went wrong . ")
        }

    }
    useEffect(() => {
        filterHandler()
    }, [page])
    return (
        <>
            <SimpleBackdrop status={loading} />
            <div className="m-4">
                <div className="d-flex justify-content-flex-start">
                    <TextField type="text" placeholder="Enter barcode" value={barcode} onChange={(e) => setBarcode(e.target.value)} />
                    <Button variant="outlined" style={{ marginLeft: ".5em" }} color="primary" onClick={filterHandler} disabled={barcode?.length<12}>Submit</Button>
                </div>

                <div className={"mt-4 d-flex justify-content-end"}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setIsModalOpen(true)}
                        size="small"
                        disabled={!(listData.length > 0)}
                    >
                        Relocate Rack
                    </Button>
                </div>

                <div className="mt-4">
                    <NewTable
                        columns={STOCK_TABLE_WITH_LOCATION_TABLE}
                        data={formatStockByLocation(listData)}
                        pagination={false}
                    />
                    {totalRecords / perPage > 1 && (
                        <div className="my-3 d-flex flex-row justify-content-end w-100">
                            <NewPagination
                                page={page}
                                pageLimit={perPage}
                                totalRecords={totalRecords}
                                handleChange={(index) => setPage(index)}
                                type="primary"
                                color="#5374B3"
                            />
                        </div>
                    )}
                </div>
            </div>
            {isModalOpen && (
                <RelocateModal
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    relocateHandler={relocateStock}
                />
            )}
        </>
    );
};

export default RelocateRackByBar;
