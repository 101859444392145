import React from "react";
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBContainer,
    MDBFormInline,
    MDBInput,
    MDBProgress, MDBSelect, MDBSelectInput, MDBSelectOption, MDBSelectOptions
} from "mdbreact";
import orgConfig from "../../orgConfig";
import util from "../../utility/utility";
import {toast} from "react-toastify";
import UserStore from "../../stores/userStore";
import OrderService from "../../services/orderService";
import {withRouter} from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PharmacyService from "../../services/pharmacyService";

class EditPurchaseNote extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            progressStatus: false,
            name: props.purchageNote.customerDetails.name,
            nameError: false,
            contactNo: props.purchageNote.customerPhone,
            contactNoError: false,
            userData: {},
            note: props.purchageNote.purchaseNote,
            quantity: props.purchageNote.quantity,
            priority: props.purchageNote.priority,
            noteError: "",
            quantityError: "",
            productId: "", productName: "",
            suggestedProduct:[],
            productNameError: false,
        }
        this.handleFields = this.handleFields.bind(this)
    }

    async componentDidMount() {
        let userData = await UserStore.fetchSessionData(), orgId = "";
        if (userData.type === "platformAdmin") {
            orgId = userData._id
        } else if (userData.type === "ORG_ADMIN") {
            orgId = userData.orgDetails[0]._id
        }
        this.setState({userData, orgId})
    }

    editPurchaseNote = async (button) => {
        if (button === "moveToPurchaseRequest" && (this.state.productId === "" || this.state.productName === "")) {
            this.setState({productNameError: true})
            toast.error("Please select a product.")
            return
        } else {
            this.setState({productNameError: false})
        }
        if (this.state.name === "") {
            this.setState({nameError: true})
            toast.error("Please enter the customer name.")
            return
        } else {
            this.setState({nameError: false})
        }
        if (this.state.contactNo === "" || !util.checkPhone(this.state.contactNo)) {
            this.setState({contactNoError: true})
            toast.error("Please enter a valid phone no.")
            return
        } else {
            this.setState({contactNoError: false})
        }
        if (this.state.note === "") {
            this.setState({noteError: true})
            toast.error("Please enter a valid note.")
            return
        } else {
            this.setState({noteError: false})
        }
        if (this.state.quantity === "") {
            this.setState({quantityError: true})
            toast.error("Please enter quantity.")
            return
        } else {
            this.setState({quantityError: false})
        }
        let payload = {
            noteId : this.props.purchageNote._id,
            purchaseNote: this.state.note,
            orgId: this.state.orgId,
            productData: button === "save" ? [{productId: "", productName: "", quantity: this.state.quantity}] : [{productId: this.state.productId, productName: this.state.productName, quantity: this.state.quantity}],
            priority: (this.state.priority).toUpperCase(),
            status: this.props.purchageNote.status,
            customerDetails: {_id: "", name: this.state.name},
            customerPhone: this.state.contactNo
        }
        this.setState({progressStatus: true})
        let res = await OrderService.editPurchseNote(payload, this.state.userData.sessionToken)
        this.setState({progressStatus: false})
        if (res) {
            toast.success("The purchase note updated successfully.")
            this.props.onEditComplete()
        } else {
            toast.error("Something went wrong while updating purchase note.")
        }
    }
    handlePriority = e => {
        this.setState({priority: e[0]})
    }
    validatePhone = () => {
        (!util.checkPhone(this.state.contactNo)) ? this.setState({"contactNoError": true}) : this.setState({"contactNoError": false})
    }
    handleFields = e => {
        if ((e.target.name === "quantity" || e.target.name === "contactNo") && !util.isInt(e.target.value) && e.target.value !== "") {
            return false;
        }
        this.setState({[e.target.name] : e.target.value})
    }
    addProduct = (event, values) => {
        if ((values && values.hasOwnProperty("value") && values.value!==null)) {
            let productId = values.value._id;
            let productName = values.value.productName;
            this.setState({productId, productName})
        } else {
            this.setState({productId: "", productName: ""})
        }
    }
    fetchProduct = async (e) => {
        try {
            if (e.target.value !== 0 || e.target.value !== '0') {
                let suggestedProduct = await PharmacyService.searchProduct(e.target.value, this.state);
                let temp = [];
                for (let item of suggestedProduct) {
                    let str = {title: item.productName, value: item};
                    temp.push(str);
                }
                if (temp.length > 0) {
                    this.setState({suggestedProduct: temp});
                } else {
                    this.setState({suggestedProduct: [{title: "", value: ''}]});
                }
            }
        } catch (e) {
            this.setState({suggestedProduct: [{title: "", value: ''}]});
        }
    }
    disablePurchaseReqBtn = () => {
        if (this.state.productId === "" || this.state.productName === "") {
            return true
        } else if (this.state.progressStatus === true) {
            return true
        }
        return false
    }
    disableSaveButton = () => {
        if (this.state.productId !== "" || this.state.productName !== "") {
            return true
        } else if (this.state.progressStatus === true) {
            return true
        }
        return false
    }
    render() {
        return (
            <React.Fragment>
                {this.state.progressStatus === true &&
                <span className={'top-stick ml-n2'}><MDBProgress material preloader color="cyan"/></span>
                }
                <MDBContainer className={"my-3"}>
                    <MDBCard>
                        <MDBCardHeader>
                            <span className={"float-left title"}>Edit Purchase Note</span>
                        </MDBCardHeader>
                        <MDBCardBody>
                            <div className={"col-12 row m-0 p-0"}>
                                <div className={"col-md-12  p-1"}>
                                    <Autocomplete
                                        freeSolo
                                        onChange={this.addProduct}
                                        id="combo-box-demo"
                                        options={this.state.suggestedProduct}
                                        getOptionLabel={option => option.title}
                                        onInputChange={(e) => {
                                            this.fetchProduct(e)
                                        }}
                                        selectOnFocus={true}
                                        renderInput={params => (
                                            <TextField style={ this.state.productNameError ?  {"borderBottom":"1px solid RED"} : {}} {...params} placeholder="Select Product"
                                                       margin="normal" fullWidth/>
                                        )}
                                    />
                                </div>
                            </div>
                            <div className={"col-12 row m-0 p-0"}>
                                <div className={"col-md-6 mt-3 p-1"}>
                                    <MDBInput autoComplete={'off'} style={{"marginTop": "0px"}}
                                              onBlur={() => {
                                                  this.state.name !== "" ? this.setState({nameError: false}) : this.setState({nameError: true})
                                              }} name="name"
                                              className={this.state.nameError ? "border-danger" : "mt-0 mb-0"}
                                              maxLength="100" type='text' label="Customer Name *"
                                              onChange={this.handleFields}
                                              value={this.state.name} outline={true}/>
                                </div>
                                <div className={"col-md-6 mt-3 p-1"}>
                                    <MDBInput onBlur={() => this.validatePhone()}
                                              autoComplete={'off'} name="contactNo"
                                              className={this.state.contactNoError ? "border-danger" : ""}
                                              maxLength={orgConfig.PHONE_NUMBER_LENGTH} type='text'
                                              label="Customer Phone *"
                                              onChange={this.handleFields}
                                              value={this.state.contactNo} outline={true}/>
                                </div>
                            </div>
                            <div className={"col-12 row m-0 p-0"}>
                                <div className={"col-md-12  p-1"}>
                                    <MDBInput outline={true}
                                              onBlur={() => {
                                                  this.state.note !== "" ? this.setState({noteError: false}) : this.setState({noteError: true})
                                              }}
                                              className={this.state.noteError ? "border-danger" : ""}
                                              maxLength="50" name="note"
                                              type='text' label="Note *" onChange={this.handleFields}
                                              value={this.state.note}/>
                                </div>
                            </div>
                            <div className={"col-12 row m-0 p-0"}>
                                    <div className={"col-md-6  p-1"}>
                                        <MDBInput outline={true}
                                                  onBlur={() => {
                                                      this.state.quantity !== "" ? this.setState({quantityError: false}) : this.setState({quantityError: true})
                                                  }}
                                                  className={this.state.quantityError ? "border-danger" : ""}
                                                  maxLength="15" name="quantity"
                                                  type='text' label="Quantity *" onChange={this.handleFields}
                                                  value={this.state.quantity}/>
                                    </div>
                                    <div className={"col-md-6 p-1"}>
                                        <b>Request Priority</b>
                                        <MDBSelect getValue={this.handlePriority}
                                                   outline={true}>
                                            <MDBSelectInput selected="Choose Priority"/>
                                            <MDBSelectOptions>
                                                <MDBSelectOption value="LOW"
                                                                 selected={this.state.priority.toUpperCase() === "LOW"}>Low</MDBSelectOption>
                                                <MDBSelectOption value="MEDIUM"
                                                                 selected={this.state.priority.toUpperCase() === "MEDIUM"}>Medium</MDBSelectOption>
                                                <MDBSelectOption value="HIGH"
                                                                 selected={this.state.priority.toUpperCase() === "HIGH"}>High</MDBSelectOption>
                                            </MDBSelectOptions>
                                        </MDBSelect>
                                    </div>
                                </div>
                            <div className="text-center  col-12 m-0">
                                <MDBBtn outline  size="md"
                                        onClick={()=>{this.props.toggleEdit()}}
                                        className="mr-auto" disabled={this.state.progressStatus}>
                                    Cancel
                                </MDBBtn>
                                <MDBBtn outline  size="md" onClick={()=>this.editPurchaseNote("save")}
                                        className="mr-auto"
                                        disabled={this.disableSaveButton()}>
                                    Save
                                </MDBBtn>
                                <MDBBtn color="default" size="md" onClick={()=>this.editPurchaseNote("moveToPurchaseRequest")}
                                        className="mr-auto"
                                        disabled={this.disablePurchaseReqBtn()}>
                                    Save & Move to Purchase Request
                                </MDBBtn>
                            </div>
                        </MDBCardBody>
                    </MDBCard>
                </MDBContainer>
            </React.Fragment>
        )
    }
}

export default withRouter(EditPurchaseNote);