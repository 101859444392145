import React from "react";
import {Link, withRouter} from "react-router-dom";
import SimpleBackdrop from "../common/overlay";
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCol,
    MDBContainer,
    MDBDataTable,
    MDBInput, MDBModal, MDBModalBody, MDBModalHeader,
    MDBRow, MDBBadge, MDBSelect, MDBTable, MDBTableHead, MDBTableBody, MDBModalFooter
} from "mdbreact";
import UserStore from "../../stores/userStore";
import {toast} from "react-toastify";
import dataTableConfig from "./dataTableProducts";
import ReportService from "../../services/reports";
import utility from "../../utility/utility";
import UpdateProductConfig from "../../pages/product/cms";
import UpdateProduct from "../../pages/product/editPkgDetails";
import ProductView from "../product/productView";
import config from "../../config";
import InventoryService from "../../services/inventoryService";
import PharmacyService from "../../services/pharmacyService";
import StockAvailability from "./stockAvailability";

class SimilarProducts extends React.Component {
    constructor() {
        super();
        this.state = {
            _id:"",
            progressStatus: false,
            type: "all",
            comments:"",
            product: {
                verificationLog:[]
            },
            suggestedData:[],
            tableData: {columns: [], rows: []},

            modalStatus:false,
            activeProductId:0,
            modalEdit:false,
            modalView:false,
            activeProduct:{

            },
            modalAction:"",
            disableRetreatAfterSorting:false,
            verificationStatus:"MANUALLY_VERIFIED",
            verificationStatuses: [{ text:"Manually Verified", value:"MANUALLY_VERIFIED", checked:true },
                { text:"Duplicate", value:"DUPLICATE" },
                { text:"Junk", value:"JUNK" },
                { text:"Incomplete", value:"INCOMPLETE" },
                { text:"Incorrect", value:"INCORRECT" },
                { text:"Ready to publish", value:"READY_TO_PUBLISH"}
            ],
            modalDelete:false,
            hideSimilarProduct:false,
            apiResultCame: false,
        }
    }

    async componentDidMount () {
        let product = this.props.product;
        console.log(product);
        let hideSimilarProduct = this.props.hasOwnProperty("hideSimilarProduct") ? this.props.hideSimilarProduct : false;
        await this.setState({_id:product._id, product:product, loadedOnPopup:true, hideSimilarProduct:hideSimilarProduct})
        await this.productSuggest()

    }

    getProductsList = async () => {
        let userData = await UserStore.fetchSessionData();
        this.setState({progressStatus: true});
        let data = await ReportService.fetchDuplicateProducts(userData, this.state);
        this.setState({progressStatus: false});
        return data
    }

    toggleView = () => {
        this.setState({
            modalView: !this.state.modalView
        });
    }



    productSuggest=async()=>{
        let userData=await UserStore.fetchSessionData();
        let term = this.state.product.productName.split(" ")
        let searchterm = "";
        if(term.length >= 5){
            searchterm = term[0]+" "+term[1]
            if(searchterm.length < 6){
                searchterm = term[0]+" "+term[1]+" "+term[2]
            }
        } else if(term.length === 4){
            searchterm = term[0]+" "+term[1]
            if(searchterm.length < 6){
                searchterm = term[0]+" "+term[1]+" "+term[2]
            }
        } else if (term.length === 3){
            searchterm = term[0]
            if(searchterm.length < 6){
                searchterm = term[0]+" "+term[1]
            }
        }
        else if (term.length === 2){
            searchterm = term[0]
        } else {
            searchterm = this.state.product.productName
        }
        let suggestedData = await InventoryService.suggestProduct(searchterm,userData);
        if(this.state.product.productName!=='') {
            this.setState({suggestedData: suggestedData, apiResultCame:true});
        }
    }

    toggleDelete = () => {
        this.setState({
            modalDelete: !this.state.modalDelete
        });
    }

    initDelete = async (id) =>{
        this.setState({activeProductId:id})
        this.toggleDelete()
    }
    confirmDelete = async () => {
        let userData=await UserStore.fetchSessionData();
        this.setState({progressStatus:true});
        let status=await PharmacyService.deleteProduct({productId:this.state.activeProductId},userData);
        this.setState({progressStatus:false});
        if(status===false){
            toast.error("Something went wrong.");
            return true;
        }else{
            toast.success("Product deleted successfully.");
            this.toggleDelete();
            if(this.state.loadedOnPopup === true){
                this.props.onEditComplete()
            } else {
                this.props.history.push('/view-products');
            }

        }
    }

    // Edit products
    toggleView = () => {
        this.setState({
            modalView: !this.state.modalView
        });
    }

    toggleEdit = () => {
        this.setState({
            modalEdit: !this.state.modalEdit
        });
    }
    onEditComplete = (row) => {
        this.toggleEdit()
        this.fetchData();
    }
    // This triggers from MDBDataTable, aim is to redirect to edit page
    onEditDesc =  (row) => {
        this.setState({activeProduct: row,modalAction:"edit"})
        this.toggleEdit()
    }

    onEdit=(item)=>{
        // this.props.history.push('/product/cms/'+item._id);
        this.setState({activeProduct: item, modalAction:"updateConfig"})
        this.toggleEdit()
    }

    onMark=(item)=>{

        // this.props.history.push('/product/cms/'+item._id);
        this.setState({activeProduct: item, modalAction:"mark"})
        this.toggleEdit()
    }

    onUpdateStockAvailability=(item)=>{
        // this.props.history.push('/product/cms/'+item._id);
        this.setState({activeProduct: item, modalAction:"stockAvailability"})
        this.toggleEdit()
    }

    onView = (item) => {
        this.props.history.push('/product-details/'+item._id);
    }


    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.progressStatus}/>
                <MDBContainer className={'w-100 mw-100'}>

                    <MDBRow className={"mt-5"}>
                        {(this.state.suggestedData.length > 1) &&
                        <MDBCol>
                            <MDBTable className={"col-12"}>
                                <MDBTableHead>
                                    <tr>
                                        <th>#</th>
                                        <th>Product Name</th>
                                        <th>Medicine Type</th>
                                        <th>Retail Package</th>
                                        <th>Quantity Unit</th>
                                        <th>Content Quantity</th>
                                        <th>MRP</th>
                                        <th>Stock</th>
                                        <th>Mark</th>
                                        <th>Product Details</th>
                                        {/*<th>Edit</th>*/}
                                        {/*<th>Config</th>*/}
                                        {/*<th>Delete</th>*/}
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {this.state.suggestedData.map((row, index) => (
                                        <>
                                            {row._id !== this.state._id &&
                                            <tr style={{color: (row.productName === this.state.product.productName) ? "red" : ""}}
                                                key={"duplicateData" + index}>
                                                <td>{index + 1}</td>
                                                <td>{row.productName}</td>
                                                <td><span
                                                    className={utility.validateMedicineType(true, row.medicineType) ? "green-text" : "red-text"}>{row.medicineType}</span>
                                                </td>
                                                <td><span
                                                    className={utility.validateRetailPackage(row.retailPackage) ? "green-text" : "red-text"}>{row.retailPackage}</span>
                                                </td>
                                                <td><span
                                                    className={utility.validateQuantityUnit(row.quantityUnit) ? "green-text" : "red-text"}>{row.quantityUnit}</span>
                                                </td>
                                                <td><span
                                                    className={utility.validateContentQuantity(row.contentQuantity) ? "green-text" : "red-text"}>{row.contentQuantity}</span>
                                                </td>
                                                <td><span
                                                    className={utility.validateMrp(row.mrp) ? "green-text" : "red-text"}>{row.mrp}</span>
                                                </td>
                                                <td><span
                                                    className={utility.validateStock(row.stock) ? "green-text" : "red-text"}>{row.stock}</span>
                                                </td>


                                                <td>
                                                    <div>
                                                        {row.hasOwnProperty("verificationStatus") &&
                                                        <div style={{cursor: "pointer"}}>
                                                            {row.verificationStatus === "READY_TO_PUBLISH" &&
                                                            <div style={{color: "green"}}
                                                                 onClick={() => this.onMark(row)}>
                                                                <div style={{"fontSize": "17px"}}><b>Ready To
                                                                    Publish</b></div>
                                                                <div
                                                                    style={{"fontSize": "12px"}}>{utility.convertToReadbleDaySmart2(row.verificationLog[row.verificationLog.length - 1].verifiedOn)}</div>
                                                                <div
                                                                    style={{"fontSize": "12px"}}>{row.verificationLog[row.verificationLog.length - 1].verifiedByUserName}</div>
                                                            </div>
                                                            }
                                                            {row.verificationStatus === "MANUALLY_VERIFIED" &&
                                                            <div style={{color: "green"}}
                                                                 onClick={() => this.onMark(row)}>
                                                                <div style={{"fontSize": "17px"}}>
                                                                    <b>Verified</b></div>
                                                                <div
                                                                    style={{"fontSize": "12px"}}>{utility.convertToReadbleDaySmart2(row.verificationLog[row.verificationLog.length - 1].verifiedOn)}</div>
                                                                <div
                                                                    style={{"fontSize": "12px"}}>{row.verificationLog[row.verificationLog.length - 1].verifiedByUserName}</div>
                                                            </div>
                                                            }
                                                            {row.verificationStatus === "JUNK" &&
                                                            <div style={{color: "red"}}
                                                                 onClick={() => this.onMark(row)}>
                                                                <div style={{"fontSize": "17px"}}><b>Junk</b>
                                                                </div>
                                                                <div
                                                                    style={{"fontSize": "12px"}}>{utility.convertToReadbleDaySmart2(row.verificationLog[row.verificationLog.length - 1].verifiedOn)}</div>
                                                                <div
                                                                    style={{"fontSize": "12px"}}>{row.verificationLog[row.verificationLog.length - 1].verifiedByUserName}</div>
                                                            </div>
                                                            }
                                                            {row.verificationStatus === "DUPLICATE" &&
                                                            <div style={{color: "orange"}}
                                                                 onClick={() => this.onMark(row)}>
                                                                <div style={{"fontSize": "17px"}}>
                                                                    <b>Duplicate</b></div>
                                                                <div
                                                                    style={{"fontSize": "12px"}}>{utility.convertToReadbleDaySmart2(row.verificationLog[row.verificationLog.length - 1].verifiedOn)}</div>
                                                                <div
                                                                    style={{"fontSize": "12px"}}>{row.verificationLog[row.verificationLog.length - 1].verifiedByUserName}</div>
                                                            </div>
                                                            }
                                                            {row.verificationStatus === "INCOMPLETE" &&
                                                            <div style={{color: "orange"}}
                                                                 onClick={() => this.onMark(row)}>
                                                                <div style={{"fontSize": "17px"}}>
                                                                    <b>Incomplete</b></div>
                                                                <div
                                                                    style={{"fontSize": "12px"}}>{utility.convertToReadbleDaySmart2(row.verificationLog[row.verificationLog.length - 1].verifiedOn)}</div>
                                                                <div
                                                                    style={{"fontSize": "12px"}}>{row.verificationLog[row.verificationLog.length - 1].verifiedByUserName}</div>
                                                            </div>
                                                            }
                                                            {row.verificationStatus === "INCORRECT" &&
                                                            <div style={{color: "orange"}}
                                                                 onClick={() => this.onMark(row)}>
                                                                <div style={{"fontSize": "17px"}}>
                                                                    <b>Incorrect</b></div>
                                                                <div
                                                                    style={{"fontSize": "12px"}}>{utility.convertToReadbleDaySmart2(row.verificationLog[row.verificationLog.length - 1].verifiedOn)}</div>
                                                                <div
                                                                    style={{"fontSize": "12px"}}>{row.verificationLog[row.verificationLog.length - 1].verifiedByUserName}</div>
                                                            </div>
                                                            }
                                                        </div>
                                                        }
                                                        {!row.hasOwnProperty("verificationStatus") &&
                                                        <div><span color="primary"
                                                                   className={"blue-text btn-link cursor-pointer"}
                                                                   size="sm"
                                                                   onClick={() => {
                                                                       this.onMark(row)
                                                                   }}>Mark</span></div>
                                                        }
                                                    </div>


                                                </td>

                                                <td>
                                                    {/*<span color="primary"*/}
                                                    {/*      className={"blue-text btn-link cursor-pointer"}*/}
                                                    {/*      size="sm"*/}
                                                    {/*      onClick={() => {*/}
                                                    {/*          this.onView(row)*/}
                                                    {/*      }}> Product Detail</span>*/}


                                                    <a className={"blue-text btn-link cursor-pointer"} href={'/product-details/'+row._id} >Product Details</a>
                                                </td>


                                                {/*<td><span color="primary"*/}
                                                {/*          className={"blue-text btn-link cursor-pointer"}*/}
                                                {/*          size="sm"*/}
                                                {/*          onClick={() => {*/}
                                                {/*              this.onEditDesc(row)*/}
                                                {/*          }}> Edit</span></td>*/}
                                                {/*<td><span color="primary"*/}
                                                {/*          className={"blue-text btn-link cursor-pointer"}*/}
                                                {/*          size="sm"*/}
                                                {/*          onClick={() => {*/}
                                                {/*              this.onEdit(row)*/}
                                                {/*          }}> Config</span></td>*/}

                                                {/*<td><span color="primary"*/}
                                                {/*          className={"blue-text btn-link cursor-pointer"}*/}
                                                {/*          size="sm"*/}
                                                {/*          onClick={() => {*/}
                                                {/*              this.initDelete(row._id)*/}
                                                {/*          }}> Delete</span></td>*/}


                                            </tr>
                                            }

                                            {row._id === this.state._id &&
                                            <tr style={{color: "blue"}} key={"duplicateData" + index}>
                                                <td><b>{index + 1}</b></td>
                                                <td><b>{row.productName}</b></td>
                                                <td><b>{row.medicineType}</b></td>
                                                <td><b>{row.retailPackage}</b></td>
                                                <td><b>{row.quantityUnit}</b></td>
                                                <td><b>{row.contentQuantity}</b></td>
                                                <td><b>{row.mrp}</b></td>
                                                <td><b>{row.stock}</b></td>
                                                <td></td>
                                                <td><a className={"blue-text btn-link cursor-pointer"} href={'/product-details/'+row._id} >Product Details</a></td>


                                                {/*<td><span color="primary"*/}
                                                {/*          className={"blue-text btn-link cursor-pointer"}*/}
                                                {/*          size="sm"*/}
                                                {/*          onClick={() => {*/}
                                                {/*              this.onEditDesc(row)*/}
                                                {/*          }}> Edit</span></td>*/}
                                                {/*<td><span color="primary"*/}
                                                {/*          className={"blue-text btn-link cursor-pointer"}*/}
                                                {/*          size="sm"*/}
                                                {/*          onClick={() => {*/}
                                                {/*              this.onEdit(row)*/}
                                                {/*          }}> Config</span></td>*/}
                                                {/*<td></td>*/}
                                            </tr>
                                            }
                                        </>
                                    ))}
                                </MDBTableBody>
                            </MDBTable>


                        </MDBCol>
                        }

                        {(this.state.suggestedData.length < 2 && this.state.apiResultCame) &&
                            <div className={'no-data-container'}>No similar products found.</div>
                        }
                    </MDBRow>


                </MDBContainer>
            </React.Fragment>
        )
    }
}

export default withRouter(SimilarProducts)