import React,{Suspense} from "react";
import {
    MDBBox
} from 'mdbreact';
//const AddManufacturer=React.lazy(() => import("../../components/manufacturer/addManufacturer"));
import AddManufacturer from "../../components/manufacturer/addManufacturer";

export default class AddManufacturerPage extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MDBBox className={"min-vh-100"}>
                    <Suspense fallback={null}>
                        <AddManufacturer></AddManufacturer>
                    </Suspense>
                </MDBBox>
            </React.Fragment>
        );
    }
}
