import React from "react";
import UserStore from "../../stores/userStore";
import { Line,Bar } from "react-chartjs-2";
import ReportService from "../../services/reports";

export default class MonthlySalesWidget extends React.Component {
    componentDidMount() {
        this.fetchData();
    }
    fetchData=async ()=>{
        let userData=await UserStore.fetchSessionData();
        //widgets data
        let monthlyReport=await ReportService.fetchMonthlySalesWidget(userData);
        if(monthlyReport===false){
            monthlyReport=[];
        }
        let monthlyDataDates=[];
        let monthlyTotal=[]
        for(let item of monthlyReport){
            var d = new Date(item.dates[0]);
            monthlyDataDates.push(d.toLocaleDateString());
            monthlyTotal.push(item.total.toFixed(2));
        }
        let databar= {
            labels: monthlyDataDates,
            datasets: [
                {
                    label: "Counter Sales",
                    backgroundColor: "rgba(144, 238,144)",
                    borderColor: "rgb(205, 130, 158)",
                    borderCapStyle: "butt",
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: "miter",
                    pointBorderColor: "rgb(205, 130,1 58)",
                    pointBackgroundColor: "rgb(255, 255, 255)",
                    pointBorderWidth: 10,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: "rgb(0, 0, 0)",
                    pointHoverBorderColor: "rgba(220, 220, 220,1)",
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 9,
                    data: monthlyTotal,
                    barPercentage: 1
                }
            ],
        }
        this.setState({dataBar:databar});
    }
    constructor(props) {
        super(props);
        this.state = {
            dataBar:{},
            options: {
                responsive: true,
                scales: {
                    yAxes: [{
                        ticks: {
                            // OR //
                            beginAtZero: true   // minimum value will be 0.
                        }
                    }]
                }
            }
        }
    }
    render() {
        return (
            <React.Fragment>
                <div className={'white rounded-sm p-2'}>
                    <Bar data={this.state.dataBar} options={this.state.options} />
                </div>
            </React.Fragment>
        );
    }
}


