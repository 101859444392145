import React from "react";
import {MDBBreadcrumb, MDBBreadcrumbItem} from "mdbreact";
import {Link} from "react-router-dom";
import SearchCustomerCmp from "../../components/customer/customerSearch";
export default class SearchCustomer extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Search Customer Here</MDBBreadcrumbItem>
                </MDBBreadcrumb>
               <SearchCustomerCmp/>
            </React.Fragment>
        );
    }
}