import React, { useState } from "react";
import Moments from "moment";
import moment from "moment";
import { MDBBtn, MDBBtnGroup, MDBCol, MDBRow } from "mdbreact";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

function GetDateRangeFunc({ getDateRangeCallback }) {
  const [state, setState] = useState({
    mode: "Day",
    startDate: moment(),
    endDate: moment(),
    dayStartDate: moment(),
    dayEndDate: moment(),
    weekStartDate: moment().startOf("week"),
    weekEndDate: moment().endOf("week"),
    monthStartDate: moment().startOf("month"),
    monthEndDate: moment().endOf("month"),
  });

  const setDateRange = (mode, startDate, endDate) => {
    setState((prevState) => ({ ...prevState, mode: mode }));
    // console.log("in getRangeCallback function", userSessionToken)
    handleDateRangeCallback(startDate, endDate);
  };
  const handleDateRangeCallback = (startDate, endDate) => {
    getDateRangeCallback(startDate, endDate);
  };
  const handleStartDate = (e) => {
    setState((prevState) => ({ ...prevState, startDate: new Date(e) }));
  };
  const handleEndDate = (e) => {
    setState((prevState) => ({ ...prevState, endDate: new Date(e) }));
  };

  const prevDay = () => {
    const nextStartDate = Moments(state.dayStartDate).subtract(1, "days");
    setDateRange("Day", nextStartDate, nextStartDate);
    setState({
      ...state,
      dayStartDate: nextStartDate,
      dayEndDate: nextStartDate,
    });
  };

  const prevWeek = () => {
    const nextStartDate = Moments(state.weekStartDate).subtract(1, "weeks");
    const nextEndDate = Moments(state.weekEndDate).subtract(1, "weeks");
    setDateRange("Week", nextStartDate, nextEndDate);
    setState({
      ...state,
      weekStartDate: nextStartDate,
      weekEndDate: nextEndDate,
    });
  };

  const prevMonth = () => {
    const nextStartDate = Moments(state.monthStartDate).subtract(1, "months");
    const nextEndDate = Moments(nextStartDate).endOf("month");
    setDateRange("Month", nextStartDate, nextEndDate);
    setState({
      ...state,
      monthStartDate: nextStartDate,
      monthEndDate: nextEndDate,
    });
  };

  const nextDay = () => {
    let nextStartDate = Moments(state.dayStartDate).add(1, "days");
    if (nextStartDate <= new Date()) {
      setDateRange("Day", nextStartDate, nextStartDate);
      setState({
        ...state,
        dayStartDate: nextStartDate,
        dayEndDate: nextStartDate,
      });
    }
  };

  const nextWeek = () => {
    const nextStartDate = Moments(state.weekStartDate).add(1, "weeks");
    const nextEndDate = Moments(state.weekEndDate).add(1, "weeks");
    if (nextStartDate <= new Date()) {
      setDateRange("Week", nextStartDate, nextEndDate);
      setState({
        ...state,
        weekStartDate: nextStartDate,
        weekEndDate: nextEndDate,
      });
    }
  };

  const nextMonth = () => {
    const nextStartDate = Moments(state.monthStartDate).add(1, "months");
    const nextEndDate = Moments(nextStartDate).endOf("month");
    if (nextStartDate <= new Date()) {
      setDateRange("Month", nextStartDate, nextEndDate);
      setState({
        ...state,
        monthStartDate: nextStartDate,
        monthEndDate: nextEndDate,
      });
    }
  };

  return (
    <>
      <MDBRow className={"mx-0"}>
        <MDBBtnGroup size="sm">
          <MDBBtn
            onClick={() =>
              setDateRange("Day", state.dayStartDate, state.dayEndDate)
            }
            className={state.mode == "day" ? "default-btn" : ""}
            color={
              state.mode === "Day"
                ? "btn-md btn-default"
                : "btn-md btn-outline-default"
            }
          >
            Day
          </MDBBtn>
          <MDBBtn
            onClick={() =>
              setDateRange("Week", state.weekStartDate, state.weekEndDate)
            }
            className={state.mode === "Week" ? "default-btn" : ""}
            color={
              state.mode === "Week"
                ? "btn-md btn-default"
                : "btn-md btn-outline-default"
            }
          >
            Week
          </MDBBtn>
          <MDBBtn
            onClick={() =>
              setDateRange("Month", state.monthStartDate, state.monthEndDate)
            }
            className={state.mode === "Month" ? "default-btn" : ""}
            color={
              state.mode === "Month"
                ? "btn-md btn-default"
                : "btn-md btn-outline-default"
            }
          >
            Month
          </MDBBtn>
          <MDBBtn
            onClick={() =>
              setDateRange("Custom", state.startDate, state.endDate)
            }
            className={state.mode === "Custom" ? "default-btn" : ""}
            color={
              state.mode === "Custom"
                ? "btn-md btn-default"
                : "btn-md btn-outline-default"
            }
          >
            Custom
          </MDBBtn>
        </MDBBtnGroup>

        {state.mode === "Custom" && (
          <div style={{ marginTop: "-15px" }} className={"col-md-8 float-left"}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                style={{
                  marginLeft: "1px",
                  marginRight: "1px",
                  width: "11rem",
                }}
                format="MMM D, YYYY"
                maxDate={new Date()}
                margin="normal"
                id="startDate"
                label="Start Date"
                value={state.startDate}
                onChange={handleStartDate}
                InputProps={{ readOnly: true }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>

            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                style={{
                  marginLeft: "1px",
                  marginRight: "1px",
                  width: "11rem",
                }}
                format="MMM D, YYYY"
                maxDate={new Date()}
                margin="normal"
                id="endDate"
                label="End Date"
                value={state.endDate}
                InputProps={{ readOnly: true }}
                onChange={handleEndDate}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
            <MDBBtn
              onClick={() =>
                setDateRange("Custom", state.startDate, state.endDate)
              }
              style={{
                marginTop: "20px",
                paddingLeft: "1px",
                paddingRight: "1px",
                width: "3rem",
              }}
              size="md"
              color={"default"}
            >
              Go
            </MDBBtn>
          </div>
        )}
        {state.mode === "Day" && (
          <div>
            <MDBBtn size={"md"} onClick={() => prevDay()} color="default">
              &lt;
            </MDBBtn>
            <span>{Moments(state.dayStartDate).format("MMM D, YYYY")}</span>
            <MDBBtn size={"md"} onClick={() => nextDay()} color="default">
              &gt;
            </MDBBtn>
          </div>
        )}
        {state.mode === "Week" && (
          <div>
            <MDBBtn size={"md"} onClick={() => prevWeek()} color="default">
              &lt;
            </MDBBtn>
            <span> {Moments(state.weekStartDate).format("MMM D, YYYY")}</span> -{" "}
            <span> {Moments(state.weekEndDate).format("MMM D, YYYY")}</span>
            <MDBBtn size={"md"} onClick={() => nextWeek()} color="default">
              &gt;
            </MDBBtn>
          </div>
        )}
        {state.mode === "Month" && (
          <div>
            <MDBBtn size={"md"} onClick={() => prevMonth()} color="default">
              &lt;
            </MDBBtn>
            <span> {Moments(state.monthStartDate).format("MMM YYYY")}</span>
            <MDBBtn size={"md"} onClick={() => nextMonth()} color="default">
              &gt;
            </MDBBtn>
          </div>
        )}
      </MDBRow>
    </>
  );
}

export default GetDateRangeFunc;
