import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import OrderService from "../../services/orderService";
import { toast } from "react-toastify";
import UserStore from "../../stores/userStore";
import {
  MDBBtn,
  MDBCarousel,
  MDBCarouselInner,
  MDBCarouselItem,
  MDBContainer,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBView,
  MDBModalFooter
} from "mdbreact";
import utility from "../../utility/utility";
import moment from "moment";
import config from "../../config";
import AuthService from "../../services/authService";
import NewTable from "../../newComponents/NewTable/NewTable";


const ListOnlineOrderDetails = (props) => {
  const { id } = props.match.params;
  const [order, setOrder] = useState([]);
  const [userData, setUserData] = useState([]);
  const [prescriptionList, setPrescriptionList] = useState([]);
  const [medImage, setMedImage] = useState({
    modalMedicineImage: false,
    medImageCollection: [],
  });
  const [addCallLogModalOpen, setAddCallLogModalOpen] = useState(false);
  const [viewCallLogModalOpen, setViewCallLogModalOpen] = useState(false);
  const [subject, setSubject] = useState("");
  const [summary, setSummary] = useState("");
  const [subjectError, setSubjectError] = useState(false);
  const [summaryError, setSummaryError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [callLogs, setCallLogs] = useState([]);
  const [sessionToken, setSessionToken] = useState('');
  const [userId, setUserId] = useState('');
  const { startDate, endDate, mode, page, pageSize, listData, teamList, isDataAvailable, } =
    props.location.state || {};

  const cancelledBy = (order) => {
    try {
      let name = "";
      for (let item of order?.processLog) {
        if (item.status === "CANCELLED") {
          name = "by " + item.createdBy.name;
        }
      }
      return name;
    } catch (e) {
      return "";
    }
  };
  const processDocsForDisplay = (prescriptions) => {
    let displayDocs = [];
    for (let item of prescriptions) {
      let createdBy = "";
      if (item.hasOwnProperty("createdBy")) {
        createdBy = item.createdBy.name;
      }
      if (item.prescriptionUrl.match(/.(jpg|jpeg|png|gif)$/i)) {
        displayDocs.push({
          path: item.prescriptionUrl,
          type: "image",
          createdBy: createdBy,
        });
      } else {
        displayDocs.push({
          path: item.prescriptionUrl,
          type: "text",
          createdBy: createdBy,
        });
      }
    }
    return displayDocs;
  };

  const orderLogFormat = {
    ACKNOWLEDGED: "ACKNOWLEDGED",
    APPROVED: "APPROVED",
    READYFORDELIVERY: "READY FOR DELIVERY",
    OUTFORDELIVERY: "OUT FOR DELIVERY",
    COMPLETED: "COMPLETED",
  };
  function textFormatter(input) {
    if (input.includes("_")) {
      let words = input
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1));
      return words.join(" ");
    } else {
      return input;
    }
  }

  useEffect(() => {
    async function fetchUserData() {
      let data = await UserStore.fetchSessionData();
      if (data) {
        setUserData(data);
        setSessionToken(data.sessionToken);
        setUserId(data.userId);
      } else {
        toast.error("Something went wrong");
      }
    }
    fetchUserData();
  }, []);

  const getAddedQnty = (item) => {
    let addedQnty = 0;
    if (item.hasOwnProperty("inventory")) {
      for (let i of item.inventory) {
        addedQnty = addedQnty + i.quantityOut;
      }
      return addedQnty;
    }
    return item.quantity;
  };
  const getSubtotal = (item) => {
    let subtotal = 0;
    if (item.hasOwnProperty("inventory")) {
      for (let i of item.inventory) {
        subtotal = subtotal + i.mrp * i.quantityOut;
      }
      subtotal = subtotal - subtotal * (item.discount / 100);
    } else {
      subtotal =
        item.maxRetailPrice * item.quantity -
        (item.maxRetailPrice * item.quantity * item.discount) / 100;
    }
    return subtotal.toFixed(2);
  };

  const toggle = (val) => () => {
    setMedImage((prev) => ({
      ...prev,
      ["modal" + val]: !medImage?.modalMedicineImage,
    }));
  };

  const goToOrderDetails = async () => {
    // props.history.push("/process-online-order/" + id);
    props.history.push({
      pathname: `/process-online-order/${id}`,
      state: {
        startDate: startDate,
        endDate: endDate,
        mode: mode,
        page: page,
        pageSize: pageSize,
      },
    });
  };
  const createImageUrl = (item) => {
    if (item.match(/.(jpg|jpeg|png|gif)$/i)) {
      return (
        <a href={config.IMAGE_HOST + item} target={"_blank"}>
          <img src={config.IMAGE_HOST + item} width={86} height={86} download />
        </a>
      );
    } else {
      return (
        <a href={config.UPLOADED_DOC_HOST + item} target={"_blank"} download>
          <MDBIcon size={"6x"} icon="file" />
        </a>
      );
    }
  };
  const goToPreviousLocation = () => {
    props.history.push({
      pathname: `/view-online-orders`,
      state: {
        startDatePreserved: startDate,
        endDatePreserved: endDate,
        modePreserved: mode,
        page: page,
        pageSize: pageSize,
        listData: listData,
        teamList: teamList,
        userData: userData,
        isDataAvailable: isDataAvailable
      },
    });
  };
  // useEffect(() => {
  //   async function fetchData() {
  //     let data = await OrderService.fetchOnlineOrderById(
  //       userData.sessionToken,
  //       id
  //     );
  //     if (data) {
  //       setOrder(data);
  //     } else {
  //       console.log("Something went wrong while getting order details.");
  //     }
  //   }
  //   if (userData) {
  //     fetchData();
  //   }
  // }, [id, userData]);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await OrderService.fetchOnlineOrderById(userData.sessionToken, id);
        if (data) {
          setOrder(data);
          setUserId(data.userId);
          // console.log("User ID from Order:", data.userId);  
        } else {
          console.log("Something went wrong while getting order details.");
        }
      } catch (error) {
        console.error("Error fetching order:", error);
      }
    }
    if (userData) fetchData();
  }, [id, userData]);

  useEffect(() => {
    if (
      order?.hasOwnProperty("prescriptions") &&
      order?.prescriptions.length > 0
    ) {
      let displayPrescriptionList = processDocsForDisplay(
        order?.prescriptions
      );
      setPrescriptionList(displayPrescriptionList);
    }
  }, [order]);

  const addCustomerCallLog = async () => {
    let hasError = false;

    if (!subject.trim()) {
      setSubjectError(true);
      hasError = true;
    }
    if (!summary.trim()) {
      setSummaryError(true);
      hasError = true;
    }

    if (hasError) {
      return;
    }
    setIsSubmitting(true);
    try {
      const payload = {
        customerCarePersonID: userData._id,
        subject,
        summary,
        userId,
        orderId: id
      };

      const response = await AuthService.saveAddCallLog(payload);

      if (response) {
        toast.success('Call log added successfully!');
        setAddCallLogModalOpen(false);
        setSubject('');
        setSummary('');
        setSubjectError(false);
        setSummaryError(false);
        setIsSubmitting(false);
      }
    } catch (error) {
      console.error('Error adding call log:', error);
      toast.error('Error adding call log. Please try again.');
      setIsSubmitting(false);
    }
  };

  const fetchCustomerCallLog = async () => {
    try {
      const response = await OrderService.fetchCallLogs(userData.sessionToken, id);

      if (response && response.data) {
        setCallLogs(response.data);
      } else {
        setCallLogs([]);
        toast.warning('Unexpected data format received from server');
      }
    } catch (error) {
      console.error('Error fetching call logs:', error);
      toast.error('Error fetching call logs. Please try again.');
      setCallLogs([]);
    }
  };

  const openViewCallLogModal = () => {
    setViewCallLogModalOpen(true);
    fetchCustomerCallLog();
  };

  const formatTableData = (data) => {
    return data.map((log, index) => ({
      slNo: index + 1,
      subject: log.subject,
      summary: log.summary,
      createdBy: log.customerCarePersonName,
      createdAt: moment(log.createdAt).format("DD-MMM-YYYY, h:mm A"),
    }));
  };

  const columns = [
    { header: "Sl. No", accessor: "slNo" },
    { header: "Subject", accessor: "subject" },
    { header: "Summary", accessor: "summary" },
    { header: "Created By", accessor: "createdBy" },
    { header: "Created At", accessor: "createdAt" },
  ];

  return (
    <>
      <div className={"col-12 pt-2"}>
        <MDBBtn size={"md"} onClick={goToPreviousLocation}>
          Go Back
        </MDBBtn>
      </div>
      <div className="col-12 p-2 row mx-0 online-order-text sticky-view-order-detail white cash-box">
        <section>
          <h6 className="font-weight-bold title">Order details</h6>
          <p>
            Order Id:{" "}
            <span className={"float-right font-weight-bold"}>
              {order?.orderCode}{" "}
            </span>
            <br />
            Order Placed On:{" "}
            <span className={"float-right font-weight-bold"}>
              {utility.convertToReadbleDateTimeUtc(order?.createdAt)}
            </span>
            <br />
            Delivery Type:
            <span className={"float-right font-weight-bold"}>
              {" "}
              {order?.deliveryType}
            </span>{" "}
            <br />
            Order Status:{" "}
            <span className={"float-right font-weight-bold"}>
              {" "}
              {order?.status === "OUTFORDELIVERY" &&
                order?.deliveryType === "SHIP"
                ? "SHIPPED"
                : order?.status}{" "}
              <span className={"text-capitalize"}>{cancelledBy(order)}</span>
            </span>{" "}
            <br />
            {order?.hasOwnProperty("cancellationNote") &&
              order?.cancellationNote !== "" && (
                <>
                  Cancellation Note:{" "}
                  <span className={"float-right font-weight-bold"}>
                    {order?.cancellationNote}
                  </span>
                  <br />
                </>
              )}
            Payment Mode:{" "}
            <span className={"float-right font-weight-bold"}>
              {order?.paymentMode}
            </span>{" "}
            <br />
            Payment Status:{" "}
            <span className={"float-right font-weight-bold"}>
              {order?.isPaid == "N" ? (
                <span style={{ color: "red" }}>NOT PAID</span>
              ) : (
                <span style={{ color: "green" }}>PAID</span>
              )}
            </span>{" "}
            <br />
            {order?.isPaid !== "N" && (
              <span>
                {" "}
                Paid amount:{" "}
                <span className={"float-right font-weight-bold"}>
                  {order?.paidAmount}
                </span>{" "}
                <br />
              </span>
            )}
            Order Source:{" "}
            <span className={"float-right font-weight-bold"}>
              {order?.hasOwnProperty("orderSource")
                ? order?.orderSource
                : "WEB"}
            </span>{" "}
            <br />
            {order?.isPrescriptionRequired === "Y" && (
              <span className={"text-danger"}>
                <MDBIcon icon="file-prescription" />{" "}
                <b>Prescription Required</b>
              </span>
            )}
            {prescriptionList?.length > 0 && (
              <div className={"col-12 p-0 my-3"}>
                <ul
                  className="list-group list-group-horizontal-lg text-lg-center"
                  style={{ clear: "both", display: "block", content: "" }}
                >
                  {prescriptionList?.map((row, index) => (
                    <li
                      key={index}
                      className="list-group-item"
                      style={{ float: "left" }}
                    >
                      {createImageUrl(row.path)}
                      {row?.createdBy !== "" && (
                        <div className={"text-center"}>
                          Uploaded By: <br />
                          {row?.createdBy}
                          <br />
                        </div>
                      )}
                      <div className={"d-flex justify-content-center my-1"}>
                        File_{index + 1}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </p>
        </section>
        <section>
          <h6 className="font-weight-bold title">Customer Details</h6>
          <p>
            Name:{" "}
            <span className={"text-capitalize float-right font-weight-bold"}>
              {order?.customerDetails?.name}
            </span>{" "}
            <br />
            Phone Number:{" "}
            <span className={"float-right font-weight-bold"}>
              {order?.shippingAddress?.phone}
            </span>{" "}
            <br />
            {order?.deliveryType == "PICKUP" ||
              (order?.deliveryType == "DELIVER" && (
                <div>
                  Delivery date:
                  <span className={"float-right font-weight-bold"}>
                    {moment(order?.window?.date).format("ll")}
                  </span>
                  <br />
                  Delivery slot:
                  <span className={"float-right font-weight-bold"}>
                    {" "}
                    {order?.window?.start}-{order?.window?.end}
                  </span>
                  <br />
                </div>
              ))}
            Order Remarks:
            <span className={"float-right font-weight-bold"}>
              {" "}
              {order?.comments?.trim() === "" ? "None" : order?.comments}
            </span>
          </p>
        </section>
        <section>
          <h6 className="font-weight-bold title">Shipping Address</h6>
          <p>
            {order?.hasOwnProperty("shippingAddress") &&
              order?.shippingAddress !== "" ? (
              <>
                <span className={"text-capitalize"}>
                  <b>{order?.shippingAddress?.name}</b>
                </span>
                <br />
                {order?.shippingAddress?.addressLine1}
                {order?.shippingAddress?.addressLine2 && (
                  <span>,&nbsp;{order?.shippingAddress?.addressLine2}</span>
                )}
                <br />
                {order?.shippingAddress?.city}, {order?.shippingAddress?.state},{" "}
                {order?.shippingAddress?.pinCode},{" "}
                {order?.shippingAddress?.country}
                <br />
                {order?.shippingAddress?.phone !== "" && (
                  <>Phone No: {order?.shippingAddress?.phone}</>
                )}
              </>
            ) : (
              <div className={"no-data-container"}>
                No shipping address found.
              </div>
            )}
          </p>
        </section>
        <section>
          <h6 className="font-weight-bold title">Pickup Address</h6>
          <p>
            {order?.hasOwnProperty("pickUpAddress") &&
              order?.pickUpAddress !== "" ? (
              <>
                <span className={"text-capitalize"}>
                  {order?.pickUpAddress.name}
                </span>{" "}
                <br />
                {order?.pickUpAddress?.point?.addressLine1}
                {order?.pickUpAddress?.point?.addressLine2 && (
                  <span>
                    ,&nbsp;{order?.pickUpAddress?.point?.addressLine2}
                  </span>
                )}
                <br />
                {order?.pickUpAddress?.point?.city},{" "}
                {order?.pickUpAddress?.point?.state},{" "}
                {order?.pickUpAddress?.point?.pin},{" "}
                {order?.pickUpAddress?.point?.country}
                <br />
                Phone No: {order?.pickUpAddress?.phone}
              </>
            ) : (
              <div className={"no-data-container"}>
                No pickup address found.
              </div>
            )}
          </p>
        </section>
        <section>
          <h6 className="font-weight-bold title">Order Logs</h6>
          <p>
            {order?.hasOwnProperty("processLog") &&
              order?.processLog?.length > 0 ? (
              <>
                {order?.processLog?.map((item, index) => (
                  <div key={index}>
                    <div className="my-2">
                      <p>
                        STATUS:{" "}
                        <span className={"float-right font-weight-bold"}>
                          {orderLogFormat[item?.status]}{" "}
                        </span>
                        <br />
                        Order Created:{" "}
                        <span className={"float-right font-weight-bold"}>
                          {moment(item?.createdAt).format(
                            "MMM Do YYYY, h:mm a"
                          )}
                        </span>
                        <br />
                        Created By:
                        <span className={"float-right font-weight-bold"}>
                          {" "}
                          {textFormatter(item?.createdBy?.name)}
                        </span>{" "}
                        <br />
                      </p>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div className={"no-data-container"}>No Logs found.</div>
            )}
          </p>
        </section>
        <section className="d-flex justify-content-center align-items-end" style={{ height: '100%' }}>
          <div className="text-center">
            <button
              size={"md"}
              className="btn btn-outline-blue waves-effect btn-md"
              onClick={() => setAddCallLogModalOpen(true)}
            >
              Record Call
            </button>
            <button
              size={"md"}
              className="btn btn-outline-blue waves-effect btn-md ml-2"
              onClick={openViewCallLogModal}
            >
              View Call Log
            </button>
          </div>
        </section>
      </div>

      <table className={"table-view-order-sticky table-striped col-12 p-0"}>
        <thead>
          <tr>
            <th>#</th>
            <th>View Medicine</th>
            <th>Product</th>
            <th>Type</th>
            <th>MRP</th>
            <th>Batch - MRP</th>
            <th>Quantity</th>
            <th>Discount (%)</th>
            <th>Subtotal</th>
          </tr>
        </thead>
        <tbody>
          {order?.cartData?.map((row, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td scope="row">
                {row.hasOwnProperty("imageUrl") && row.imageUrl.length > 0 ? (
                  <div
                    className={"cursor-pointer"}
                    onClick={() => {
                      setMedImage({
                        modalMedicineImage: true,
                        medImageCollection: row.imageUrl,
                      });
                    }}
                  >
                    <img
                      src={config.IMAGE_HOST + row.imageUrl[0].filePathThumb}
                      style={{ width: "50px", height: "50px" }}
                      alt={"Med Image"}
                    />
                  </div>
                ) : (
                  <div>
                    <img
                      src={"/broken-img.jpg"}
                      style={{ width: "60px", height: "60px" }}
                      alt={"Med Image"}
                    />
                  </div>
                )}
              </td>
              <td>{row.productName}</td>
              <td>
                {row.hasOwnProperty("isMedicine") && !row.isMedicine && (
                  <span>General</span>
                )}
                {row.hasOwnProperty("isMedicine") && row.isMedicine && (
                  <span>
                    {row.hasOwnProperty("medicineType") && (
                      <span
                        className={
                          row.medicineType === "Schedule H" ||
                            row.medicineType === "Schedule H1" ||
                            row.medicineType === "Schedule X" ||
                            row.medicineType === "PRESCRIPTION DRUGS" ||
                            row.medicineType === "PRESCRIPTION DRUG"
                            ? "red-text"
                            : "green-text"
                        }
                      >
                        {row.medicineType === "PRESCRIPTION DRUGS" && (
                          <span>Prescription Drug</span>
                        )}
                        {row.medicineType !== "PRESCRIPTION DRUGS" && (
                          <span>{row.medicineType}</span>
                        )}
                      </span>
                    )}
                    {!row.hasOwnProperty("medicineType") && (
                      <span> Not Specified</span>
                    )}
                  </span>
                )}
                {!row.hasOwnProperty("isMedicine") &&
                  row.hasOwnProperty("medicineType") && (
                    <span
                      className={
                        row.medicineType === "Schedule H" ||
                          row.medicineType === "Schedule H1" ||
                          row.medicineType === "Schedule X"
                          ? "red-text"
                          : "green-text"
                      }
                    >
                      {" "}
                      {row.medicineType}
                    </span>
                  )}
              </td>
              <td>
                <span
                  dangerouslySetInnerHTML={{ __html: config.CURRENCY_HTML }}
                ></span>
                {row.maxRetailPrice.toFixed(2)}
              </td>
              <td className={"text-left"}>
                {row.hasOwnProperty("inventory") ? (
                  <>
                    {row.inventory.map((item, index1) => (
                      <small key={index1}>
                        <b>
                          {item.batch} -{" "}
                          <span
                            dangerouslySetInnerHTML={{
                              __html: config.CURRENCY_HTML,
                            }}
                          ></span>{" "}
                          {item.mrp}
                        </b>
                      </small>
                    ))}
                  </>
                ) : (
                  "N/A"
                )}
              </td>
              <td>{getAddedQnty(row)}</td>
              <td>{row.discount}</td>
              <td>
                <span
                  dangerouslySetInnerHTML={{ __html: config.CURRENCY_HTML }}
                ></span>
                {getSubtotal(row)}
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="8" className={"text-right"}>
              Total Amount
            </td>
            <td className="text-right">
              <span
                dangerouslySetInnerHTML={{ __html: config.CURRENCY_HTML }}
              ></span>{" "}
              {order?.amount?.toFixed(2)}
            </td>
          </tr>
        </tfoot>
      </table>

      <div className="col-12 p-0 table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th className="font-weight-bold">Paid Amount</th>
              <th className="font-weight-bold">Paid On</th>
              <th className="font-weight-bold">Payment Mode</th>
              <th className="font-weight-bold">Balance Amount</th>
              <th className="font-weight-bold">Shipping Charge</th>
              {order.isOrderFulfilled === true && (
                <th className="font-weight-bold">Flat Discount</th>
              )}
              {order.hasOwnProperty("couponData") &&
                order.couponData !== "" && (
                  <th className="font-weight-bold">Coupon Discount</th>
                )}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <span
                  dangerouslySetInnerHTML={{ __html: config.CURRENCY_HTML }}
                ></span>{" "}
                {order?.paidAmount?.toFixed(2)}
              </td>
              <td>
                {order?.paidOn !== ""
                  ? utility.convertToReadbleDateTimeUtc(order?.paidOn)
                  : "N/A"}
              </td>
              <td>{order?.paymentMode}</td>
              <td>
                <span
                  dangerouslySetInnerHTML={{ __html: config.CURRENCY_HTML }}
                ></span>{" "}
                {(order?.totalAmount - order?.paidAmount)?.toFixed(2)}
              </td>
              <td>
                <span
                  dangerouslySetInnerHTML={{ __html: config.CURRENCY_HTML }}
                ></span>{" "}
                {order?.shippingAmount?.toFixed(2)}
              </td>
              {order.isOrderFulfilled === true && (
                <td>
                  <span
                    dangerouslySetInnerHTML={{ __html: config.CURRENCY_HTML }}
                  ></span>{" "}
                  {order?.flatDiscount?.toFixed(2)}
                </td>
              )}
              {order.hasOwnProperty("couponData") &&
                order.couponData !== "" && (
                  <td>
                    {order?.couponData?.value}
                    {order?.couponData?.discountType === "PERCENTAGE"
                      ? "%"
                      : "INR"}
                  </td>
                )}
            </tr>
          </tbody>
        </table>
      </div>

      <div className={"col-12 text-center sticky-bottom border-top pt-3 pb-3"}>
        <MDBBtn size={"md"} onClick={goToOrderDetails}>
          Process Order
        </MDBBtn>
      </div>

      <MDBModal
        isOpen={medImage?.modalMedicineImage}
        toggle={toggle("MedicineImage")}
        size={"lg"}
      >
        <MDBModalHeader
          toggle={toggle("MedicineImage")}
          className={"border-0"}
        ></MDBModalHeader>
        <MDBModalBody className={""}>
          <div className={"col-12 row mx-0"}>
            <MDBContainer>
              <MDBCarousel
                activeItem={1}
                length={medImage?.medImageCollection?.length}
                showControls={true}
                showIndicators={false}
                className="z-depth-1"
                slide
              >
                <MDBCarouselInner>
                  {medImage?.medImageCollection?.map((item, index) => (
                    <MDBCarouselItem itemId={index + 1}>
                      <MDBView>
                        <img
                          className="d-block w-100"
                          src={config.IMAGE_HOST + item.filePathOpt}
                          alt={index + " slide"}
                        />
                      </MDBView>
                    </MDBCarouselItem>
                  ))}
                </MDBCarouselInner>
              </MDBCarousel>
            </MDBContainer>
          </div>
        </MDBModalBody>
      </MDBModal>
      <MDBModal
        isOpen={addCallLogModalOpen}
        toggle={() => setAddCallLogModalOpen(false)}
      >
        <MDBModalHeader toggle={() => setAddCallLogModalOpen(false)}>
          Record Call
        </MDBModalHeader>
        <MDBModalBody>
          <div className="form-group">
            <label htmlFor="subject">Subject</label>
            <input
              type="text"
              className={`form-control ${subjectError ? 'is-invalid' : ''}`}
              id="subject"
              value={subject}
              onChange={(e) => {
                setSubject(e.target.value);
                setSubjectError(false);
              }}
              placeholder="Enter subject here..."
            />
            {subjectError && <div className="invalid-feedback">Please fill the subject field.</div>}
          </div>
          <div className="form-group">
            <label htmlFor="summary">Summary</label>
            <textarea
              className={`form-control ${summaryError ? 'is-invalid' : ''}`}
              id="summary"
              rows="3"
              value={summary}
              onChange={(e) => {
                setSummary(e.target.value);
                setSummaryError(false);
              }}
              placeholder="Enter summary here..."
              style={{ backgroundColor: "white", border: "1px solid grey" }}
            />
            {summaryError && <div className="invalid-feedback">Please fill the summary field.</div>}
          </div>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color="default" onClick={() => setAddCallLogModalOpen(false)}>Cancel</MDBBtn>
          <MDBBtn
            color="default"
            onClick={addCustomerCallLog}
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Saving...' : 'Save'}
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>

      <MDBModal
        isOpen={viewCallLogModalOpen}
        toggle={() => setViewCallLogModalOpen(false)}
      >
        <MDBModalHeader toggle={() => setViewCallLogModalOpen(false)}>
          View Call Logs
        </MDBModalHeader>
        <MDBModalBody>
          {callLogs.length > 0 ? (
            <NewTable
              columns={columns}
              data={formatTableData(callLogs)}
              pagination={false}
            />
          ) : (
            <p>No call logs available</p>
          )}
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color="default" onClick={() => setViewCallLogModalOpen(false)}>Close</MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    </>
  );
};

export default ListOnlineOrderDetails;
