import axios from "axios";
import config from "../config";

const SpecialityService= {
    fetchMasterSpeciality: async function (userData) {
        try {
            const headers = {
                headers: {Authorization: userData.sessionToken}
            };
            const response = await axios.get(config.APIHOST + config.FETCH_SPECIALITYGROUP + "?orgId=" + userData.org[0]._id, headers);
            return response.data
        } catch (e) {
            return false;
        }
    },
    addSpecialityGroup: async function(specialityGroup,userData){
        try{
            const headers = {
                headers: { Authorization: userData.sessionToken}
            };
            let payload={
                specialityGroup:specialityGroup,
                orgId:userData.org[0]._id
            };
            const response = await axios.post(config.APIHOST+config.ADD_NEW_SPECIALITYGROUP, payload,headers);
            return response.data
        } catch (e) {
            return false;
        }
    },
    deleteSpecialityGroup: async function(_id,userData){
        try{
            const headers = {
                headers: { Authorization: userData.sessionToken}
            };
            let payload={
                _id:_id,
                orgId:userData.org[0]._id
            };
            const response = await axios.post(config.APIHOST+config.DELETE_SPECIALITYGROUP, payload,headers);
            return response.data
        } catch (e) {
            return false;
        }
    },
    lookupSpecialityGroup: async function (term,userData) {
        try {
            const headers = {
                headers: {Authorization: userData.sessionToken}
            };
            const response = await axios.get(config.APIHOST + config.LOOKUPSPECIALITY_GROUP+"?term="+term, headers);
            return response.data
        } catch (e) {
            return false;
        }
    },
    editSpecialityGroup: async function(_id,specialityGroup,userData){
        try{
            const headers = {
                headers: { Authorization: userData.sessionToken}
            };
            let payload={
                specialityGroup:specialityGroup,
                orgId:userData.org[0]._id,
                _id:_id
            };
            const response = await axios.post(config.APIHOST+config.EDIT_SPECALITY_GROUP, payload,headers);
            return response.data
        } catch (e) {
            return false;
        }
    },
    addSpeciality: async function(speciality,specialityGroupId,userData){
        try{
            const headers = {
                headers: { Authorization: userData.sessionToken}
            };
            let payload={
                speciality:speciality,
                orgId:userData.org[0]._id,
                specialityGroupId:specialityGroupId
            };
            const response = await axios.post(config.APIHOST+config.ADD_SPECIALITY, payload,headers);
            return response.data
        } catch (e) {
            return false;
        }
    },
    lookupSpeciality: async function (term,userData) {
        try {
            const headers = {
                headers: {Authorization: userData.sessionToken}
            };
            const response = await axios.get(config.APIHOST + config.LOOKUP_SPECIALITY+"?term="+term, headers);
            return response.data
        } catch (e) {
            return false;
        }
    },
    fetchSpeciality: async function (userData) {
        try {
            const headers = {
                headers: {Authorization: userData.sessionToken}
            };
            const response = await axios.get(config.APIHOST + config.FETCH_SPECIALITY + "?orgId=" + userData.org[0]._id, headers);
            return response.data
        } catch (e) {
            return false;
        }
    },

    fetchSpecialityById: async function (data, userData) {
        try {
            const headers = {
                headers: {Authorization: userData.sessionToken}
            };
            const response = await axios.get(config.APIHOST + config.FETCH_SPECIALITY_BY_ID + "?orgId=" + userData.org[0]._id+"&_id="+data._id, headers);
            return response.data
        } catch (e) {
            return false;
        }
    },


    deleteSpeciality: async function(_id,userData){
        try{
            const headers = {
                headers: { Authorization: userData.sessionToken}
            };
            let payload={
                _id:_id,
                orgId:userData.org[0]._id
            };
            const response = await axios.post(config.APIHOST+config.DELETE_SPECIALITY, payload,headers);
            return response.data
        } catch (e) {
            return false;
        }
    },
    editSpeciality: async function(_id,speciality,userData,specialityGroupId){
        try{
            const headers = {
                headers: { Authorization: userData.sessionToken}
            };
            let payload={
                speciality:speciality,
                orgId:userData.org[0]._id,
                _id:_id,
                specialityGroupId:specialityGroupId
            };
            const response = await axios.post(config.APIHOST+config.EDIT_SPECIALITY, payload,headers);
            return response.data
        } catch (e) {
            return false;
        }
    },
}
export default SpecialityService;