import React from "react";
import {
    MDBContainer, MDBRow, MDBInput, MDBBtn, MDBCardHeader, MDBFormInline, MDBCardBody, MDBCard,
    MDBCol, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBModal
} from 'mdbreact';
import {toast} from "react-toastify";
import UserStore from "../../stores/userStore";
import PharmacyService from "../../services/pharmacyService";
import {withRouter} from "react-router-dom";
import SimpleBackdrop from "../common/overlay";

class EditQuantityUnitCmp extends React.Component {
    async componentDidMount() {


        if(this.props.loadedOnPopup === true){
            let quantityUnitId = this.props.quantityUnitId;
            await this.setState({quantityUnitId:quantityUnitId, loadedOnPopup:true})
            await this.fetchData();
        } else {
            const { quantityUnitId } = this.props.queryString;
            await this.setState({quantityUnitId:quantityUnitId});
            await this.fetchData();
        }

    }
    fetchData= async ()=>{
        let userData = await UserStore.fetchSessionData();
        this.setState({progressStatus: true});
        let data=await PharmacyService.fetchQuantityUnitById(this.state.quantityUnitId, userData);
        this.setState({progressStatus: false});
        if(data===false){
            toast.error("We are facing some issue. Please try again later.");
        }else{
            this.setState({quantityUnitData:data,quantityUnit:data.quantityUnit});
            if(data.status==='Y'){
                this.setState({status:true});
            }else{
                this.setState({status:false});
            }
        }
    }
    constructor(props) {
        super(props);
        this.state = {
            quantityUnitId:'',
            progressStatus:false,
            progressStatusFileUpload:false,
            quantityUnitData:{},
            quantityUnit:'',
            status:false,
            featured:false,
            quantityUnitError:false,
            imageUrl:{},
            deleteModal:false,
            loadedOnPopup:false,
        }
    }
    quantityUnitChange=(val)=>{
        this.setState({quantityUnit:val.toLowerCase()});
    }
    updateQuantityUnit=async ()=>{
        let userData = await UserStore.fetchSessionData();
        //validations
        if(this.state.quantityUnit==='' || this.state.quantityUnit===null){
            this.setState({quantityUnitError:true});
            return true;
        } else {
            this.setState({quantityUnitError:false});
        }
        this.setState({progressStatus: true});
        let data=await PharmacyService.updateQuantityUnit(this.state,userData);
        this.setState({progressStatus: false});
        if(data===false){
            toast.error("We are facing some issue. Please try again later.");
        }else{
            if(this.state.loadedOnPopup === true){
                this.props.onEditComplete()
            }
            toast.success("Quantity unit updated successfully.");
        }
    }
    getStatus=(val)=>{
        this.setState({status:val});
    }
    validateQuantityUnit=()=>{
        if(this.state.quantityUnit === ""){
            this.setState({quantityUnitError:true});
        } else {
            this.setState({quantityUnitError:false});
        }
    }
    initDelete = () => {
        this.toggleDelete()
    }
    confirmDelete = async () => {
        let userData=await UserStore.fetchSessionData();
        this.setState({progressStatus:true});
        let status=await PharmacyService.deleteQuantityUnit(this.state,userData);
        this.setState({progressStatus:false});
        if(status===false){
            toast.error("Something went wrong.");
            return true;
        }else{
            toast.success("Quantity unit deleted successfully.");
            this.toggleDelete();
            if(this.state.loadedOnPopup === true){
                this.props.onEditComplete()
            } else {
                this.props.history.push('/view-quantity-units');
            }

        }
    }
    toggleDelete = () => {
        this.setState({
            modalDelete: !this.state.modalDelete
        });
    }
    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.progressStatus}/>
                <MDBContainer>
                    <MDBCard className={"mt-2"}>
                        <MDBCardHeader>
                            <span className={"float-left"}>Edit Quantity Unit</span>
                            <MDBFormInline className="float-right md-form mr-auto m-0">
                                {this.state.loadedOnPopup === false &&
                                <MDBBtn outline color="blue-grey" size="sm"
                                        onClick={() => this.props.history.push('/view-quantity-units')}
                                        className="mr-auto" disabled={this.state.progressStatus}>
                                    Back
                                </MDBBtn>
                                }
                                <MDBBtn outline color="blue-grey" size="sm" onClick={this.initDelete} className="mr-auto"
                                        disabled={this.state.progressStatus}
                                >
                                    Delete
                                </MDBBtn>

                                <MDBBtn outline color="blue-grey" size="sm" onClick={this.updateQuantityUnit} className="mr-auto"
                                        disabled={this.state.progressStatus || this.state.progressStatusFileUpload}>
                                    Save
                                </MDBBtn>
                            </MDBFormInline>
                        </MDBCardHeader>
                        <MDBCardBody>
                            <MDBRow className="d-flex justify-content-left">
                                <MDBCol>
                                    <MDBInput label="Quantity Unit e.g. ml, kg" onBlur={this.validateQuantityUnit} className={this.state.quantityUnitError ? "border-danger" :""} getValue={this.quantityUnitChange} value={this.state.quantityUnit} disabled={this.state.progressStatus}/>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                        <MDBModal isOpen={this.state.modalDelete} toggle={this.toggleDelete}>
                            <MDBModalHeader toggle={this.toggleDelete}>Delete Quantity Unit</MDBModalHeader>
                            <MDBModalBody>
                                Are you sure you want to delete this quantity unit?
                            </MDBModalBody>
                            <MDBModalFooter>
                                <MDBBtn disabled={this.state.progressStatus} color="primary" onClick={this.confirmDelete}>Delete</MDBBtn>
                            </MDBModalFooter>
                        </MDBModal>
                    </MDBCard>
                </MDBContainer>
            </React.Fragment>
        );
    }
}
export default withRouter(EditQuantityUnitCmp)
