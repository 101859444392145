import React,{Suspense} from "react";
import {Link} from "react-router-dom";
import {
    MDBBox,
    MDBBreadcrumb, MDBBreadcrumbItem
} from 'mdbreact';
// const EditCategoryCmp=React.lazy(() => import("../../components/categories/editCategoryCmp"));
import EditCategoryCmp from "../../components/categories/editCategoryCmp";

export default class EditCategory extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem><Link to={'/view-product-categories'}>Product Categories</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Edit Category</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <MDBBox className={"min-vh-100"}>
                    <Suspense fallback={null}>
                        <EditCategoryCmp queryString={this.props.match.params}/>
                    </Suspense>
                </MDBBox>
            </React.Fragment>
        );
    }
}


