import React from "react";
// import ReactToPrint from 'react-to-print';
import {Link, withRouter} from "react-router-dom";
import {
    MDBRow,
    MDBInput,
    MDBCol,
    MDBCard,
    MDBBox,
    MDBTreeview,
    MDBTreeviewList,
    MDBTreeviewItem,
    MDBBtn,
    MDBIcon,
    MDBSelect,
    MDBSelectInput,
    MDBSelectOptions,
    MDBSelectOption,
    MDBTooltip,
    MDBTable,
    MDBTableHead,
    MDBTableBody
} from 'mdbreact';
import UserStore from "../../stores/userStore";
import {toast} from "react-toastify";
import PharmacyService from "../../services/pharmacyService";
import Moments from 'moment';
import utility from "../../utility/utility";
import config from "../../config";
import Add from "./add";

var Barcode = require('react-barcode');

const lineItem = {
    productName: "",
    batch: "",
    mrp: "",
    priceNoGst: "",
    gst: "",
    priceWithGst: "",
    purchaseDiscount: "",
    hsnSac: "",
    mfgDate: "",
    expdate: "",
    expiringAfterMonth: "",
    quantity: "",
    freeQuantity: "",
    // totalQuantity: quantity + freeQuantity,
    
    
    barCode: new Date().getTime() + 122,
    productSuggestion: [],
    selectedProduct: null,
    productSearchTerm: "",
    packageQnty:0,
    freePackageQnty:0,
    floor:"",
    locSuggestion:[],
    selectedLoc:"",
    floorSuggestion:[],
    selectedFloor:"",
    rackSuggestions:[],
    selectedRack:"",
    boxSuggestions:[],
    selectedBox:"",
    commentStorage:"",
    comment:"",
};

class AddInventory extends React.Component {
    //v2 code
    async componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
        let userData = await UserStore.fetchSessionData();
        this.setState({userData: userData});
    }

    constructor(props) {
        super(props);
        this.escFunction = this.escFunction.bind(this);
        this.state = {
            userData: {},
            searchedShipments: [],
            selectedShipment: null,
            shipmentSearchValue: "",
            lineItems: [lineItem],
            floorKey:1,
            rackKey:1,
            boxKey:1,
            storageKey:1
        }
    }

    escFunction(event) {
        if (event.keyCode === 27) {
            this.clearProductSuggestions();
        }
    }

    clearProductSuggestions = () => {
        this.setState({searchedShipments: []});
        let temp = [];
        for (let item of this.state.lineItems) {
            item["productSuggestion"] = [];
            item["productSearchTerm"] = "";
            temp.push(item);
        }
        this.setState({lineItems: temp});
    }
    searchShipment = async (term) => {
        if (term !== "") {
            let suggestedShipment = await PharmacyService.searchShipmentV2(term, this.state.userData);
            if (suggestedShipment === false) {
                toast.error("We are facing some issue please try again later.");
            } else {
                this.setState({searchedShipments: suggestedShipment});
            }
        }
    }
    selectShipment = (item) => {
        this.setState({selectedShipment: item, searchedShipments: [], shipmentSearchValue: ""});
    }
    addRow = () => {
        this.clearProductSuggestions();
        let temp = this.state.lineItems;
        let temp2 = JSON.parse(JSON.stringify(lineItem));
        temp2["barCode"] = new Date().getTime() + 122;
        temp.push(temp2);
        this.setState({lineItems: temp});
    }
    deleteRow = (index) => {
        this.clearProductSuggestions();
        let temp = [];
        for (let indx in this.state.lineItems) {
            if (parseInt(indx) !== parseInt(index)) {
                temp.push(this.state.lineItems[indx]);
            }
        }
        this.setState({lineItems: temp});
    }
    handleChange = (e, index, key) => {
        //validations
        if ((key === "mrp" || key === "priceNoGst" || key === "gst" || key === "priceWithGst" || key === "purchaseDiscount") && e !== "") {
            if (e.substr(e.length - 1) !== ".") {
                if (utility.isCurrency(e) === false) {
                    return false;
                }
            }
        }
        if (key === "purchaseDiscount") {
            if (parseFloat(e) > 100) {
                return false;
            }
        }
        if (key === "priceWithGst" || key === "expiringAfterMonth") {
            return false;
        }

        let temp = this.state.lineItems;
        let temp2 = JSON.parse(JSON.stringify(temp[index]));

        if (key === "mfgDate" || key === "expdate") {
            if (temp2[key].length < e.length) {
                if (e.length === 2) {
                    e = e + "/";
                }
            }
            if (e.length > 7) {
                return false;
            }
        }
        if(key==="packageQnty"){
            if(utility.isFloat(e)) {
                if (temp2.selectedProduct.looseSaleAllowed === "Y") {
                    temp2["quantity"] = parseInt(e) * parseInt(temp2.selectedProduct.contentQuantity);
                    temp2["packageQnty"]= parseInt(e);
                } else if (temp2.selectedProduct.looseSaleAllowed === "N") {
                    temp2["quantity"] = parseInt(e);
                    temp2["packageQnty"]= parseInt(e);

                }
            }else{
                if(e!=="") {
                    toast.error("Please enter valid quantity.");
                    return false;
                }else{
                    temp2["quantity"]="";
                }
            }
        }
        temp2[key] = e;

        if(key==="freePackageQnty"){
            if(utility.isFloat(e)) {
                if (temp2.selectedProduct.looseSaleAllowed === "Y") {
                    temp2["freeQuantity"] = parseInt(e) * parseInt(temp2.selectedProduct.contentQuantity);
                } else if (temp2.selectedProduct.looseSaleAllowed === "N") {
                    temp2["freeQuantity"] = parseInt(e);
                }
            }else{
                temp2["freeQuantity"]="";
            }
        }


        //calculating manupulated fields
        if (temp2.gst !== "" && temp2.priceNoGst !== "") {
            temp2.priceWithGst = utility.calculateGst(temp2.priceNoGst, temp2.gst)
        }
        //calculation expiration
        if (temp2.expdate !== "") {
            let today = Moments();
            let tempExp = "27/" + temp2.expdate
            let exp = Moments(tempExp, 'DD/MM/YYYY', true);
            let diff = exp.diff(today, 'months', true);
            if (diff < 0) {
                toast.error("Please enter valid Mfg date and Exp date.");
                temp2.expdate = "";
            } else {
                if (!isNaN(diff)) {
                    temp2.expiringAfterMonth = Math.floor(diff);
                }
            }
        }

        temp[index] = temp2;
        this.setState({lineItems: temp});
        if (key === "productSearchTerm" && e !== "") {
            setTimeout(
                function () {
                    this.getProductList(e, index);
                }.bind(this), 1000
            );

        }
    }
    validateDate = (idx, field) => {
        let temp = this.state.lineItems;
        let temp2 = JSON.parse(JSON.stringify(temp[idx]));
        let check = false;
        if (field === "Mfg") {
            if (temp2.mfgDate === "") {
                return true;
            }
            let tempMfg = "01/" + temp2.mfgDate;
            check = Moments(tempMfg, 'DD/MM/YYYY', true);
        } else {
            if (temp2.expdate === "") {
                return true;
            }
            let tempExp = "27/" + temp2.expdate
            check = Moments(tempExp, 'DD/MM/YYYY', true);
        }
        if (check.isValid() === false) {
            toast.error("Please enter valid " + field + " date.")
            if (field === "Mfg") {
                temp2.mfgDate = "";
            } else {
                temp2.expdate = "";
            }
            temp[idx] = temp2;
            this.setState({lineItems: temp});
        }
    }
    getProductList = async (e, index) => {
        let temp = this.state.lineItems;
        let temp2 = JSON.parse(JSON.stringify(temp[index]));
        if (this.state.lineItems[index].productSearchTerm === e) {
            let suggestedProduct = await PharmacyService.searchProductV2(e, this.state.userData);
            if (suggestedProduct === false) {
                toast.error("We are facing some server issue. Please try again later.");
            } else {
                if (this.state.lineItems[index].productSearchTerm === e) {
                    temp2["productSuggestion"] = suggestedProduct;
                    temp[index] = temp2;
                    this.setState({lineItems: temp});
                } else {
                    temp2["productSuggestion"] = [];
                    temp[index] = temp2;
                    this.setState({lineItems: temp});
                }
            }
        } else {
            temp2["productSuggestion"] = [];
            temp[index] = temp2;
            this.setState({lineItems: temp});
        }
    }
    resetBarCode = (index) => {
        let temp = this.state.lineItems;
        let temp2 = JSON.parse(JSON.stringify(temp[index]));
        temp2["barCode"] = new Date().getTime() + 122 ;
        temp[index] = temp2;
        this.setState({lineItems: temp});
    }
    selectProduct = (product, index) => {
        let temp = this.state.lineItems;
        let temp2 = JSON.parse(JSON.stringify(lineItem));
        temp2["selectedProduct"] = product;
        // if(temp2.quantity!=="") {
        //     if (temp2.selectedProduct.looseSaleAllowed === "Y") {
        //         temp2.quantity = parseInt(temp2.quantity) * parseInt(temp2.selectedProduct.contentQuantity);
        //     } else if (temp2.selectedProduct.looseSaleAllowed === "N") {
        //         temp2.quantity = parseInt(temp2.packageQnty);
        //     }
        // }
        //temp2["quantity"]="";
        //temp2["packageQnty"]="";
        temp[index] = temp2;
        this.setState({lineItems: temp});
        this.clearProductSuggestions();

    }
    // save=async ()=>{
    //     //validations
    //     if(this.state.selectedShipment===null || this.state.selectedShipment===""){
    //         toast.error("Please select a shipment.");
    //         return false;
    //     }
    //     let status=true;
    //     let index="";
    //     let payload=[];
    //     let count=1;
    //     for(let item of this.state.lineItems){
    //         let temp={};
    //         if(item.selectedProduct===null || item.selectedProduct===""){
    //             status=false;
    //             index=count;
    //             break;
    //         }
    //         temp["productId"]=item.selectedProduct._id;
    //         if(item.batch===""){
    //             status=false;
    //             index=count;
    //             break;
    //         }
    //         temp["batch"]=item.batch;
    //         if(item.mrp===null || item.mrp===""){
    //             status=false;
    //             index=count;
    //             break;
    //         }
    //         temp["mrp"]=parseFloat(item.mrp);
    //         if(item.priceNoGst==="" || item.priceNoGst===null){
    //             status=false;
    //             index=count;
    //             break;
    //         }
    //         temp["priceNoGst"]=parseFloat(item.priceNoGst);
    //         if(item.gst==="" || item.gst===null){
    //             status=false;
    //             index=count;
    //             break;
    //         }
    //         temp["gst"]=parseFloat(item.gst);
    //         if(item.priceWithGst==="" || item.priceWithGst===null){
    //             status=false;
    //             index=count;
    //             break;
    //         }
    //         temp["priceWithGst"]=parseFloat(item.priceWithGst);
    //         if(item.purchaseDiscount==="" || item.purchaseDiscount===null){
    //             temp["discount"]=0;
    //         }else{
    //             temp["discount"]=parseFloat(item.purchaseDiscount);
    //         }

    //         if(item.hsnSac==="" || item.hsnSac===null){
    //             status=false;
    //             index=count;
    //             break;
    //         }
    //         temp["hsn"]=item.hsnSac;

    //         let tempMfg = "01/" + item.mfgDate;
    //         temp["mfgDate"]=Moments(tempMfg, 'DD/MM/YYYY', true);

    //         let tempExp = "27/" + item.expdate;
    //         temp["expDate"]=Moments(tempExp, 'DD/MM/YYYY', true);

    //         if(item.packageQnty==="" || item.packageQnty===null){
    //             status=false;
    //             index=count;
    //             break;
    //         }
    //         temp["quantity"]=parseFloat(item.packageQnty);
    //         if(item.selectedLoc==="" || item.selectedLoc===null){
    //             status=false;
    //             index=count;
    //             break;
    //         }

    //         temp["freeQuantity"]=parseFloat(item.freePackageQnty);
            
    //         temp["locId"]=item.selectedLoc._id;

    //         if(item.selectedFloor==="" || item.selectedFloor===null){
    //             status=false;
    //             index=count;
    //             break;
    //         }
    //         temp["floorId"]=item.selectedFloor._id;

    //         if(item.selectedRack==="" || item.selectedRack===null){
    //             status=false;
    //             index=count;
    //             break;
    //         }
    //         temp["rackId"]=item.selectedRack._id;

    //         if(item.selectedBox==="" || item.selectedBox===null){
    //             status=false;
    //             index=count;
    //             break;
    //         }
    //         temp["boxId"]=item.selectedBox._id;

    //         temp["commentStorage"]=item.commentStorage
    //         if(item.barCode==="" || item.barCode===null){
    //             status=false;
    //             index=count;
    //             break;
    //         }
    //         temp["barCode"]=item.barCode;
    //         temp["comment"]=item.comment;
    //         temp["shipmentId"]=this.state.selectedShipment._id;
    //         payload.push(temp);
    //     }
    //     if(status===false){
    //         toast.error("Wrong stock entry for record. Please check the values.");
    //         return false;
    //     }else{
    //         let userData=await UserStore.fetchSessionData();
    //         let status=await PharmacyService.createInventoryV3(userData,payload);
    //         if(status===false){
    //             toast.error("We are facing some issue. Please check your input values and try again. If inputs are valid please change the barcode.");
    //         }else{
    //             toast.success("Stock added successfully.");
    //             let temp=lineItem;
    //             temp["selectedProduct"]=null;
    //             temp["barCode"]=new Date().getTime() + 122;
    //             this.setState({lineItems:[temp],storageKey:this.state.storageKey+1,rackKey:this.state.rackKey+1,floorKey:this.state.floorKey+1,boxKey:this.state.boxKey+1});
    //         }
    //     }
    // }
    fetchFloor=async (index)=>{
        if(this.state.lineItems[index].selectedLoc!=="") {
            let floorInfo = await PharmacyService.fetchFloorV2(this.state.lineItems[index].selectedLoc._id);
            if (floorInfo === false) {
                toast.error("We are facing some server issue. Please try again later.");
            } else {
                if(floorInfo.length>0) {
                    let temp = [];
                    for (let item of floorInfo) {
                        temp.push({text: item.floorName, value: item});
                    }
                    let tempLine = JSON.parse(JSON.stringify(this.state.lineItems));
                    let temp2 = JSON.parse(JSON.stringify(tempLine[index]));
                    temp2["floorSuggestion"] = temp;
                    tempLine[index] = temp2;
                    this.setState({lineItems: tempLine});
                }else{
                    toast.error("Floor is not configured for this location.");
                }
            }
        }
    }
    selectValFloor=(e,index)=>{
        if(e.length>0) {
            this.state.lineItems[index].selectedFloor = e[0];
            this.state.lineItems[index].rackSuggestions=[]
            this.state.lineItems[index].selectedRack='';
            this.state.lineItems[index].boxSuggestions=[];
            this.state.lineItems[index].selectedBox="";
            this.setState({rackKey:this.state.rackKey+1});
            this.setState({boxKey:this.state.boxKey+1});
        }
    }
    fetchStorageLOC=async (index)=>{
        let storageInfo=await PharmacyService.fetchStorageLocationV2();
        if(storageInfo===false){
            toast.error("We are facing some server issue. Please try again later.");
        }else{
            let temp=[];
            for(let item of storageInfo){
                temp.push({text:item.name,value:item});
            }
            let tempLine = JSON.parse(JSON.stringify(this.state.lineItems));
            let temp2 = JSON.parse(JSON.stringify(tempLine[index]));
            temp2["locSuggestion"]=temp;
            tempLine[index] = temp2;
            this.setState({lineItems: tempLine});
        }
    }
    selectValStorageLoc=(e,index)=>{
        if(e.length>0) {
            this.state.lineItems[index].selectedLoc = e[0];
            this.state.lineItems[index].selectedFloor="";
            this.state.lineItems[index].floorSuggestion=[];
            this.state.lineItems[index].rackSuggestions=[]
            this.state.lineItems[index].selectedRack='';
            this.state.lineItems[index].boxSuggestions=[];
            this.state.lineItems[index].selectedBox="";
            this.setState({floorKey:this.state.floorKey+1});
            this.setState({rackKey:this.state.rackKey+1});
            this.setState({boxKey:this.state.boxKey+1});
        }
    }
    fetchRack=async (index)=>{
        if(this.state.lineItems[index].selectedFloor!=="") {
            let rackInfo = await PharmacyService.fetchRackV2(this.state.lineItems[index].selectedFloor._id);
            if (rackInfo === false) {
                toast.error("We are facing some server issue. Please try again later.");
            } else {
                if (rackInfo.length > 0) {
                    let temp = [];
                    for (let item of rackInfo) {
                        temp.push({text: item.rackName, value: item});
                    }
                    //this.state.lineItems[index].rackSuggestions=temp;
                    let tempLine = JSON.parse(JSON.stringify(this.state.lineItems));
                    let temp2 = JSON.parse(JSON.stringify(tempLine[index]));
                    temp2["rackSuggestions"] = temp;
                    tempLine[index] = temp2;
                    this.setState({lineItems: tempLine});
                }else{
                    toast.error("Rack configuration not found for this floor.");
                }
            }
        }
    }
    selectValRack=(e,index)=>{
        if(e.length>0) {
            this.state.lineItems[index].selectedRack = e[0];
            this.state.lineItems[index].boxSuggestions=[];
            this.state.lineItems[index].selectedBox="";
            this.setState({boxKey:this.state.boxKey+1});
        }
    }
    fetchBox=async (index)=>{
        if(this.state.lineItems[index].selectedRack!=="") {
            let boxInfo = await PharmacyService.fetchBoxV2(this.state.lineItems[index].selectedRack._id);
            if (boxInfo === false) {
                toast.error("We are facing some server issue. Please try again later.");
            } else {
                if(boxInfo.length>0) {
                    let temp = [];
                    for (let item of boxInfo) {
                        temp.push({text: item.boxName, value: item});
                    }
                    let tempLine = JSON.parse(JSON.stringify(this.state.lineItems));
                    let temp2 = JSON.parse(JSON.stringify(tempLine[index]));
                    temp2["boxSuggestions"] = temp;
                    tempLine[index] = temp2;
                    this.setState({lineItems: tempLine});
                }else{
                    toast.error("Box configuration not found for this rack");
                }
            }
        }
    }
    selectValBox=(e,index)=>{
        if(e.length>0) {
            this.state.lineItems[index].selectedBox = e[0];
        }
    }
    generateBarCode=()=>{
        let str=[];
        for(let count=0;count<this.state.lineItems[0].packageQnty;count++) {
            str.push(<Barcode value={this.state.lineItems[0].barCode}></Barcode>);
        }
        return str;
    }
    toCopy=(barCode)=>{
        navigator.clipboard.writeText(barCode.toString().trim());
        toast.success("Barcode is copied successfully. Copied barcode is- "+barCode.toString().trim());
    }


    render() {
        return (
            <React.Fragment>
                {/* V2 */}
                <MDBBox>
                <Add />
                    {/* <div className={'col-10 mx-auto'}>
                    <MDBCard className={"mx-2 mb-2 p-3"}>
                        <MDBRow>
                            <div className={'col-md-6'}>
                                <MDBInput value={this.state.shipmentSearchValue} getValue={(e) => {
                                    this.setState({shipmentSearchValue: e})
                                }} label={"Enter shipment code or invoice code"} size={"lg"}
                                          onChange={(e) => {
                                              this.searchShipment(e.target.value)
                                          }}/>
                                <MDBBox
                                    className={"autosuggest-dropdown" + (this.state.searchedShipments.length === 0 ? 'd-none' : '')}>
                                    {this.state.searchedShipments.map((item, index) => (
                                        <MDBBox className={" p-3 mb-2 border border-light"} key={index}>
                                            <MDBBox>
                                                Shipment code: {item.shipmentCode}
                                            </MDBBox>
                                            <MDBBox>
                                                Invoice no: {item.invoiceNumber}
                                            </MDBBox>
                                            <MDBBox>
                                                <MDBTreeview
                                                    theme='animated'
                                                    className={"tree-no-border"}
                                                >
                                                    <MDBTreeviewList icon='money' title='Details' far open>
                                                        <MDBTreeviewItem icon={false}
                                                                         title={"Invoice date: " + Moments(item.invoiceDate).format("DD/MM/YYYY")}
                                                                         far></MDBTreeviewItem>
                                                        <MDBTreeviewItem icon={false}
                                                                         title={"Receiving date: " + Moments(item.receivingDate).format("DD/MM/YYYY")}
                                                                         far></MDBTreeviewItem>
                                                        <MDBTreeviewItem icon={false}
                                                                         title={"Total: " + item.amount.toFixed(2)}
                                                                         far></MDBTreeviewItem>
                                                        <MDBTreeviewItem icon={false}
                                                                         title={"Discount: " + item.discountAmount.toFixed(2)}
                                                                         far></MDBTreeviewItem>
                                                        <MDBTreeviewItem icon={false}
                                                                         title={"Total tax: " + item.totalTaxAmount}
                                                                         far></MDBTreeviewItem>
                                                        <MDBTreeviewItem icon={false}
                                                                         title={"Receiving place: " + item.supplyPlace}
                                                                         far></MDBTreeviewItem>
                                                        <MDBTreeviewItem icon={false}
                                                                         title={"Supplier: " + item.supplierDetails.supplierName}
                                                                         far></MDBTreeviewItem>
                                                        <MDBTreeviewItem icon={false}
                                                                         title={"Entered by: " + item.createdByDetails.name}
                                                                         far></MDBTreeviewItem>
                                                        <MDBTreeviewItem icon={false}
                                                                         title={"Received by: " + item.receivedByDetails.name}
                                                                         far></MDBTreeviewItem>
                                                    </MDBTreeviewList>
                                                </MDBTreeview>
                                            </MDBBox>
                                            <MDBBox>
                                                <MDBBtn size={"sm"} onClick={() => {
                                                    this.selectShipment(item)
                                                }}>Select</MDBBtn>
                                            </MDBBox>
                                        </MDBBox>
                                    ))}
                                </MDBBox>
                            </div>
                            <MDBCol>
                                {this.state.selectedShipment !== null &&
                                <>
                                    <MDBBox>
                                        Shipment code: {this.state.selectedShipment.shipmentCode}
                                    </MDBBox>
                                    <MDBBox>
                                        Invoice no: {this.state.selectedShipment.invoiceNumber}
                                    </MDBBox>
                                    <MDBTreeview
                                        theme='animated'
                                        className={"tree-no-border"}
                                    >
                                        <MDBTreeviewList icon='money' title='Details' far open>
                                            <MDBTreeviewItem icon={false}
                                                             title={"Invoice date: " + Moments(this.state.selectedShipment.invoiceDate).format("DD/MM/YYYY")}
                                                             far></MDBTreeviewItem>
                                            <MDBTreeviewItem icon={false}
                                                             title={"Receiving date: " + Moments(this.state.selectedShipment.receivingDate).format("DD/MM/YYYY")}
                                                             far></MDBTreeviewItem>
                                            <MDBTreeviewItem icon={false}
                                                             title={"Total: " + this.state.selectedShipment.amount.toFixed(2)}
                                                             far></MDBTreeviewItem>
                                            <MDBTreeviewItem icon={false}
                                                             title={"Discount: " + this.state.selectedShipment.discountAmount.toFixed(2)}
                                                             far></MDBTreeviewItem>
                                            <MDBTreeviewItem icon={false}
                                                             title={"Total tax: " + this.state.selectedShipment.totalTaxAmount}
                                                             far></MDBTreeviewItem>
                                            <MDBTreeviewItem icon={false}
                                                             title={"Receiving place: " + this.state.selectedShipment.supplyPlace}
                                                             far></MDBTreeviewItem>
                                            <MDBTreeviewItem icon={false}
                                                             title={"Supplier: " + this.state.selectedShipment.supplierDetails.supplierName}
                                                             far></MDBTreeviewItem>
                                            <MDBTreeviewItem icon={false}
                                                             title={"Entered by: " + this.state.selectedShipment.createdByDetails.name}
                                                             far></MDBTreeviewItem>
                                            <MDBTreeviewItem icon={false}
                                                             title={"Received by: " + this.state.selectedShipment.receivedByDetails.name}
                                                             far></MDBTreeviewItem>
                                            <MDBTreeviewList icon={false} title='Payment log' far open>
                                                {this.state.selectedShipment.paymentLog.map((log, idx) => (
                                                    <>
                                                        <MDBCard className={"mt-2"}>
                                                            <MDBTreeviewItem icon={false}
                                                                             title={'Amount: ' + log.paymentAmount.toFixed(2)}
                                                                             far/>
                                                            <MDBTreeviewItem icon={false}
                                                                             title={'Payment mode: ' + log.paymentMode}
                                                                             far/>
                                                            <MDBTreeviewItem icon={false}
                                                                             title={'Payment reference: ' + log.paymentReference}
                                                                             far/>
                                                            <MDBTreeviewItem icon={false}
                                                                             title={'Payment date: ' + Moments(log.paymentDate).format("DD/MM/YYYY")}
                                                                             far/>
                                                            <MDBTreeviewItem icon={false}
                                                                             title={'Entered by: ' + log.enteredBy.name}
                                                                             far/>
                                                        </MDBCard>
                                                    </>
                                                ))}
                                            </MDBTreeviewList>
                                        </MDBTreeviewList>
                                    </MDBTreeview>
                                </>
                                }
                            </MDBCol>
                        </MDBRow>
                    </MDBCard>
                    </div>

                    {this.state.lineItems.map((item, index) => (
                        <div className={'col-10 mx-auto'}>
                        <MDBCard className={"m-2 p-3"} key={index}> */}
                            {/* <MDBRow>
                                <div className={'col-md'}>
                                    <MDBInput value={item.productSearchTerm} label={"Product"} getValue={(e) => {
                                        this.handleChange(e, index, "productSearchTerm")
                                    }} className={"inv-input-width"} maxLength={50}/>
                                    <MDBBox
                                        className={"autosuggest-dropdown" + (item.productSuggestion.length === 0 ? 'd-none' : '')}>
                                        {item.productSuggestion.map((product, idx) => (
                                            <MDBBox className={"p-3 mb-2 border border-light"} key={idx}>
                                                <MDBBox>
                                                    Product name: {product.productName}
                                                </MDBBox>
                                                <MDBBox>
                                                    Retail packaging: {product.retailPackage}
                                                </MDBBox>
                                                <MDBBox>
                                                    Content quantity: {product.contentQuantity}
                                                </MDBBox>
                                                <MDBBox>
                                                    Quantity unit: {product.quantityUnit}
                                                </MDBBox>
                                                <MDBBox>
                                                    MRP: {product.mrp.toFixed(2)}
                                                </MDBBox>
                                                <MDBBox>
                                                    Stock: {product.stock}
                                                </MDBBox>
                                                <MDBBox>
                                                    Mfg: {product.mfg.manufacturer}
                                                </MDBBox>
                                                <MDBBox>
                                                    <MDBBtn size={"sm"} onClick={() => {
                                                        this.selectProduct(product, index)
                                                    }}>Select</MDBBtn>
                                                </MDBBox>
                                            </MDBBox>
                                        ))}
                                    </MDBBox>
                                </div>
                                <MDBCol> */}
                                {/* <Add /> */}
                                    {/* {item.selectedProduct !== null &&
                                    <MDBCard className={"p-2"}>
                                        <MDBRow>
                                            <MDBCol>
                                                <MDBBox>
                                                    Product name: {item.selectedProduct.productName}
                                                </MDBBox>
                                                <MDBBox>
                                                    <MDBTreeview theme='animated' className={"tree-no-border"}>
                                                        <MDBTreeviewList icon='false' title='Details' far open>
                                                            <MDBTreeviewItem icon={false}
                                                                             title={'Retail packaging: ' + item.selectedProduct.retailPackage}
                                                                             far/>
                                                            <MDBTreeviewItem icon={false}
                                                                             title={'Content quantity: ' + item.selectedProduct.contentQuantity}
                                                                             far/>
                                                            <MDBTreeviewItem icon={false}
                                                                             title={'Quantity unit: ' + item.selectedProduct.quantityUnit}
                                                                             far/>
                                                            <MDBTreeviewItem icon={false}
                                                                             title={'MRP: ' + item.selectedProduct.mrp.toFixed(2)}
                                                                             far/>
                                                            <MDBTreeviewItem icon={false}
                                                                             title={'Stock: ' + item.selectedProduct.stock}
                                                                             far/>
                                                            <MDBTreeviewItem icon={false}
                                                                             title={'Mfg: ' + item.selectedProduct.mfg.manufacturer}
                                                                             far/>
                                                            <MDBTreeviewItem icon={false}
                                                                             title={'Schedule: ' + item.selectedProduct.medicineType}
                                                                             far/>
                                                            <MDBTreeviewItem icon={false}
                                                                             title={'Loose sale: ' + item.selectedProduct.looseSaleAllowed}
                                                                             far/>
                                                            <MDBTreeviewItem icon={false}
                                                                             title={'Reorder threshold : ' + item.selectedProduct.reorderThreshold}
                                                                             far/>
                                                        </MDBTreeviewList>
                                                    </MDBTreeview>
                                                </MDBBox>
                                            </MDBCol>
                                            <MDBCol>
                                                {(item.selectedProduct.imageUrl !== "" && item.selectedProduct.imageUrl.length > 0) &&
                                                <MDBBox>
                                                    <img
                                                        src={config.IMAGE_HOST + item.selectedProduct.imageUrl[0].filePathThumb}/>
                                                </MDBBox>
                                                }
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBCard>

                                    }
                                </MDBCol>
                                <MDBCol> */}
                                    {/*<MDBBtn className={"float-right"} onClick={() => {*/}
                                    {/*    this.deleteRow(index)*/}
                                    {/*}}>Delete</MDBBtn>*/}
                                {/* </MDBCol> */}
                            {/* </MDBRow> */}
                            
                            

                             {/* <MDBRow> 
                                <div className={'col-md'}>
                                    <MDBInput label={"Batch"} value={item.batch} getValue={(e) => {
                                        this.handleChange(e, index, "batch")
                                    }} maxLength={50}/>
                                </div>
                                <div className={'col-md'}>
                                    <MDBInput label={"MRP"} value={item.mrp} getValue={(e) => {
                                        this.handleChange(e, index, "mrp")
                                    }} maxLength={50}/>
                                </div>
                                <div className={'col-md'}>
                                    <MDBInput label={"Price (w/o GST)"} value={item.priceNoGst} getValue={(e) => {
                                        this.handleChange(e, index, "priceNoGst")
                                    }} maxLength={50}/>
                                </div>
                                <div className={'col-md'}>
                                    <MDBInput label={"GST %"} value={item.gst} getValue={(e) => {
                                        this.handleChange(e, index, "gst")
                                    }} maxLength={50}/>
                                </div>
                                <div className={'col-md'}>
                                    <MDBInput label={"Price (w/ GST)"} value={item.priceWithGst} getValue={(e) => {
                                        this.handleChange(e, index, "priceWithGst")
                                    }} maxLength={50}/>
                                </div>
                                <div className={'col-md'}>
                                    <MDBInput label={"Purchase discount %"} value={item.purchaseDiscount}
                                              getValue={(e) => {
                                                  this.handleChange(e, index, "purchaseDiscount")
                                              }} maxLength={50}/>
                                </div>
                                <div className={'col-md'}>
                                    <MDBInput label={"HSN/SAC"} value={item.hsnSac} getValue={(e) => {
                                        this.handleChange(e, index, "hsnSac")
                                    }} maxLength={50}/>
                                </div>
                            </MDBRow>  */}
                            {/* <MDBRow>
                                <div className={'col-md'}>
                                    <MDBInput label={"Mfg date (MM/YYYY)"} value={item.mfgDate} getValue={(e) => {
                                        this.handleChange(e, index, "mfgDate")
                                    }} maxLength={50} onBlur={() => {
                                        this.validateDate(index, "Mfg")
                                    }}/>
                                </div>
                                <div className={'col-md'}>
                                    <MDBInput label={"Exp date (MM/YYYY)"} value={item.expdate} getValue={(e) => {
                                        this.handleChange(e, index, "expdate")
                                    }} maxLength={50} onBlur={() => {
                                        this.validateDate(index, "Exp")
                                    }}/>
                                </div>
                                <div className={'col-md'}>
                                    <MDBInput label={"Exp after months"} value={item.expiringAfterMonth}
                                              getValue={(e) => {
                                                  this.handleChange(e, index, "expiringAfterMonth")
                                              }} maxLength={50}/>
                                </div>
                                <div className={'col-md'}>
                                    {item.selectedProduct !== null &&
                                        <>
                                            <MDBInput value={item.packageQnty} label={"No of "+item.selectedProduct.retailPackage+" X "+item.selectedProduct.contentQuantity+" "+item.selectedProduct.quantityUnit} maxLength={50} getValue={(e) => {this.handleChange(e, index, "packageQnty")}}/>
                                        </>
                                    }
                                </div>
                                <div className={'col-md'}>
                                    <MDBInput label={"Quantity"} value={item.quantity} key={item.quantity}/>
                                </div>
                                <div className={'col-md'}>
                                    <MDBInput label={"Free Quantity"} value={item.freePackageQnty} key={item.freePackageQnty} getValue={(e) => {this.handleChange(e, index, "freePackageQnty")}}/>
                                    <span>Total Free items: {item.freeQuantity ? item.freeQuantity : 0}</span>
                                </div>

                                
                            </MDBRow> */}
                            
                            {/* <MDBRow>
                                


                                <div className={'col-md-2'}>
                                    <MDBInput type={"textarea"} background size="sm" label={"Comment related to storage"}  value={item.commentStorage} getValue={(e) => {
                                        this.handleChange(e, index, "commentStorage")
                                    }}/>
                                </div> */}
                                {/* <div className={'col-md'}>
                                    <MDBInput value={item.barCode} label={"Bar code"} getValue={(e) => {
                                        this.handleChange(e, index, "barCode")
                                    }} maxLength={30}/>
                                    <MDBBtn onClick={() => {
                                        this.resetBarCode(index)
                                    }} size={"sm"}>Reset</MDBBtn>
                                    <MDBBtn onClick={() => {this.toCopy(item.barCode)}} size={"sm"}>Copy</MDBBtn>
                                </div>
                                <div className={'col-md'}>
                                    <MDBBox ref={el => (this.componentRef = el)}>
                                        <Barcode value={item.barCode}></Barcode>
                                    </MDBBox>
                                </div> */}
                                {/* <div className={'col-md'}>
                                    <MDBInput type={"textarea"} background size="sm" label={"Comment"} value={item.comment} getValue={(e) => {
                                        this.handleChange(e, index, "comment")
                                    }}/>
                                </div>

                            </MDBRow> */}
                            {/* <MDBRow className="justify-content-center">
                                

                                <MDBTable className="mx-auto">
                                <MDBTableHead>
                                    <tr>
                                    <th>Product Name</th>
                                    <th>Select storage location</th>
                                    <th>Select floor</th>
                                    <th>Select Rack</th>
                                    <th>Select Box</th>
                                    <th>Barcode</th>
                                    </tr> 
                                </MDBTableHead>
                                {item.selectedProduct !== null &&(
                                <MDBTableBody search={true}>
                                  {Array.from({ length: item.selectedProduct.quantity }, (_, index) => (

                                    <tr key={index}>
                                
                                    <td className="text-center align-middle">{item.selectedProduct.quantity} - {item.selectedProduct.productName}</td>
                                    <td className=""><MDBSelect key={this.state.storageKey}  onFocus={()=>{this.fetchStorageLOC(index)}} onClick={(e) => handleSelectStorageLoc(loc._id)}onClick={(e) => handleSelectStorageLoc(loc._id)}={(e)=>{this.selectValStorageLoc(e,index)}}>
                                                        <MDBSelectInput selected="Choose your option" />
                                                        <MDBSelectOptions>
                                                            {item.locSuggestion.map((loc, idx) => (
                                                                <MDBSelectOption key={idx} value={loc.value}>{loc.text}</MDBSelectOption>
                                                            ))}
                                                        </MDBSelectOptions>
                                                    </MDBSelect></td>
                                    <td className=""><MDBSelect key={this.state.floorKey} onFocus={()=>{this.fetchFloor(index)}} getValue={(e)=>{this.selectValFloor(e,index)}}>
                                                        <MDBSelectInput selected="Choose your option" />
                                                        <MDBSelectOptions>
                                                            {item.floorSuggestion.map((floor, idx) => (
                                                                <MDBSelectOption key={idx} value={floor.value}>{floor.text}</MDBSelectOption>
                                                            ))}
                                                        </MDBSelectOptions>
                                                    </MDBSelect></td>
                                    <td className=""><MDBSelect key={this.state.rackKey}  onFocus={()=>{this.fetchRack(index)}} getValue={(e)=>{this.selectValRack(e,index)}}>
                                                        <MDBSelectInput selected="Choose your option" />
                                                        <MDBSelectOptions>
                                                            {item.rackSuggestions.map((rack, idx) => (
                                                                <MDBSelectOption key={idx} value={rack.value}>{rack.text}</MDBSelectOption>
                                                            ))}
                                                        </MDBSelectOptions>
                                                    </MDBSelect></td>
                                    <td className=""><MDBSelect key={this.state.boxKey} onFocus={()=>{this.fetchBox(index)}} getValue={(e)=>{this.selectValBox(e,index)}}>
                                                        <MDBSelectInput selected="Choose your option" />
                                                        <MDBSelectOptions>
                                                            {item.boxSuggestions.map((box, idx) => (
                                                                <MDBSelectOption key={idx} value={box.value}>{box.text}</MDBSelectOption>
                                                            ))}
                                                        </MDBSelectOptions>
                                                    </MDBSelect></td>

                                    <td>
                                    <div className="">
                                    <MDBInput value={item.barCode+index} label={"Bar code"} getValue={(e) => {
                                        this.handleChange(e, index, "barCode")
                                    }} maxLength={30}/> */}
                                    {/* <MDBBtn onClick={() => {
                                        this.resetBarCode(index)
                                    }} size={"sm"}>Reset</MDBBtn> */}
                                    {/* <MDBBtn onClick={() => {this.toCopy(item.barCode+index)}} size={"sm"}>Copy</MDBBtn>
                                </div>
                                <div className="">
                                    <MDBBox ref={el => (this.componentRef = el)}>
                                        <Barcode value={item.barCode + index}></Barcode>
                                    </MDBBox>
                                </div>
                                    </td>
                                    
                                    </tr>
                                    ))};
                                </MDBTableBody>
                               ) }
                                </MDBTable>

                                

                                <div onClick={()=>
                                {console.log(item.selectedProduct.quantity
                                )}
                                } >
                                    Hello
                                    
                                </div> */}
                                
                            
                                            {/* <div className={'col-md'}>
                                                    <b>Select storage location</b>
                                                    <MDBSelect key={this.state.storageKey}  onFocus={()=>{this.fetchStorageLOC(index)}} getValue={(e)=>{this.selectValStorageLoc(e,index)}}>
                                                        <MDBSelectInput selected="Choose your option" />
                                                        <MDBSelectOptions>
                                                            {item.locSuggestion.map((loc, idx) => (
                                                                <MDBSelectOption key={idx} value={loc.value}>{loc.text}</MDBSelectOption>
                                                            ))}
                                                        </MDBSelectOptions>
                                                    </MDBSelect>
                                                </div>
                                                <div className={'col-md'}>
                                                    <b>Select floor</b>
                                                    <MDBSelect key={this.state.floorKey} onFocus={()=>{this.fetchFloor(index)}} getValue={(e)=>{this.selectValFloor(e,index)}}>
                                                        <MDBSelectInput selected="Choose your option" />
                                                        <MDBSelectOptions>
                                                            {item.floorSuggestion.map((floor, idx) => (
                                                                <MDBSelectOption key={idx} value={floor.value}>{floor.text}</MDBSelectOption>
                                                            ))}
                                                        </MDBSelectOptions>
                                                    </MDBSelect>
                                                </div>
                                            <div className={'col-md'}>
                                                    <b>Select Rack</b>
                                                    <MDBSelect key={this.state.rackKey}  onFocus={()=>{this.fetchRack(index)}} getValue={(e)=>{this.selectValRack(e,index)}}>
                                                        <MDBSelectInput selected="Choose your option" />
                                                        <MDBSelectOptions>
                                                            {item.rackSuggestions.map((rack, idx) => (
                                                                <MDBSelectOption key={idx} value={rack.value}>{rack.text}</MDBSelectOption>
                                                            ))}
                                                        </MDBSelectOptions>
                                                    </MDBSelect>
                                                </div>
                                                <div className={'col-md'}>
                                                    <b>Select Box</b>
                                                    <MDBSelect key={this.state.boxKey} onFocus={()=>{this.fetchBox(index)}} getValue={(e)=>{this.selectValBox(e,index)}}>
                                                        <MDBSelectInput selected="Choose your option" />
                                                        <MDBSelectOptions>
                                                            {item.boxSuggestions.map((box, idx) => (
                                                                <MDBSelectOption key={idx} value={box.value}>{box.text}</MDBSelectOption>
                                                            ))}
                                                        </MDBSelectOptions>
                                                    </MDBSelect>
                                                </div> */}
                                 
                            {/* </MDBRow> */}
                            {/* <div className="m-0 text-center">
                            <MDBBtn onClick={() => {
                                        this.resetBarCode(index)
                                    }} size={"sm"}>Reset Barcode</MDBBtn>
                                <MDBBtn size={'md'} outline>Back</MDBBtn>
                                <MDBBtn size={'md'}  onClick={()=>{this.save()}}>Save</MDBBtn>
                            </div> */}
                        {/* </MDBCard> */}
                        {/* </div> */}
                    {/* ))} */}
                    {/*<div ref={el => (this.componentRef = el)}>*/}
                    {/*    {parseInt(this.state.lineItems[0].packageQnty) > 0 &&*/}
                    {/*    <div id={"kk"}>*/}
                    {/*        {Array(parseInt(this.state.lineItems[0].packageQnty)).fill(null).map((value, index) => (*/}
                    {/*            <>*/}
                    {/*                {index % 2 === 0 &&*/}
                    {/*                <div>*/}
                    {/*                    <Barcode value={this.state.lineItems[0].barCode} fontSize={14} ></Barcode>*/}
                    {/*                    &nbsp;&nbsp;<Barcode value={this.state.lineItems[0].barCode} fontSize={14}></Barcode>*/}
                    {/*                </div>*/}
                    {/*                }*/}
                    {/*            </>*/}
                    {/*        ))}*/}
                    {/*    </div>*/}
                    {/*    }*/}
                    {/*</div>*/}
                    {/*<div id="kk">*/}
                    {/*    <h1>Print me</h1>*/}
                    {/*</div>*/}
                    {/*<MDBBtn onClick={this.test}>Print</MDBBtn>*/}
                    {/*<MDBRow>*/}
                    {/*    <MDBCol className={"m-2 p-3"}>*/}
                    {/*        <span style={{"cursor": "pointer"}} className={"p-1"} onClick={() => {*/}
                    {/*            this.addRow()*/}
                    {/*        }}><MDBIcon size={"2x"} icon="plus-circle"/></span>*/}
                    {/*    </MDBCol>*/}
                    {/*</MDBRow>*/}
                </MDBBox>
            </React.Fragment>
        );
    }
}

export default withRouter(AddInventory)
