import React from "react";
import {Link, withRouter} from "react-router-dom";
import MomentUtils from '@date-io/moment';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
    MDBRow, MDBInput, MDBFormInline, MDBBtn, MDBCol, MDBCard, MDBCardBody, MDBCardHeader, MDBBreadcrumb,
    MDBBreadcrumbItem, MDBBox, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBModal
} from 'mdbreact';
import UserStore from "../../stores/userStore";
import {toast} from "react-toastify";
import PharmacyService from "../../services/pharmacyService";
import util from "../../utility/utility";
import Moments from "moment";
import SimpleBackdrop from "../common/overlay";

class UpdateInventory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalDelete:false,
            userData:{},
            shipment:{},
            product:{},
            inventoryId:"",
            suggestedShipment:[],
            allSuggestedShipment:[],
            suggestedProduct:[],
            allSuggestedProduct:[],
            orgId:"",
            progressStatus:false,
            productId:"",
            batch:"",
            mrp:"",
            gst:"",
            priceNogst:"",
            priceWgst:"",
            discount:"",
            hsn:"",
            mfgDate:null,
            expDate:null,
            expAfterMonth:"",
            quantity:"",
            freeQuantity: "",
            packagingQuantity:0
        }
    }
    handleBatch=(e)=>{
        this.setState({batch:e.target.value})
    };
    handleMrp=(e)=>{
        if(!util.isNumericTwoDecimalPartial(e.target.value) && e.target.value !== ""){
            return false;
        }
        this.setState({mrp:e.target.value})

        if(this.state.gst > 0 && this.state.priceNogst > 0 ){

            let priceWgst = parseFloat(this.state.priceNogst) + parseFloat( (this.state.priceNogst * (this.state.gst/100)))
            this.setState({priceWgst:util.customRound(priceWgst)})
        }
    };
    handlePriceNoGST= async (e)=>{
        if(!util.isNumericTwoDecimalPartial(e.target.value) && e.target.value !== ""){
            return false;
        }
        await this.setState({priceNogst:e.target.value})
        if(this.state.gst > 0 && this.state.priceNogst > 0 ){
            let priceWgst = parseFloat(this.state.priceNogst) + parseFloat( (this.state.priceNogst * (this.state.gst/100)))
            this.setState({priceWgst:util.customRound(priceWgst)})
        }
    };
    handlePriceWGST=(e)=>{
        if(!util.isNumericTwoDecimalPartial(e.target.value) && e.target.value !== ""){
            return false;
        }
        this.setState({priceWgst:e.target.value})
    };
    handleDiscount=(e)=>{
        if(!util.isNumericTwoDecimalPartial(e.target.value) && e.target.value !== ""){
            return false;
        }
        this.setState({discount:e.target.value})
    };
    handleHSN=(e)=>{
        this.setState({hsn:e.target.value})
    };
    handleGST= async (e)=>{
        if(!util.isNumericTwoDecimalPartial(e.target.value) && e.target.value !== ""){
            return false;
        }
        await this.setState({gst:e.target.value})
        if(this.state.gst > 0 && this.state.priceNogst > 0 ){
            let priceWgst = parseFloat(this.state.priceNogst) + parseFloat( (this.state.priceNogst * (this.state.gst/100)))
            this.setState({priceWgst:util.customRound(priceWgst)})
        }


    };
    handleExpiryDate=(date)=>{
        this.setState({expDate:date})
        let a = Moments(date);
        let b = Moments(this.state.mfgDate);
        let months = a.diff(b, 'months');
        if(months){
            this.setState({expDate:date, expAfterMonth:months})
        }

    };
    handleManufacturerDate = (date) => {
        let a = Moments(this.state.expDate);
        let b = Moments(date);
        let months = a.diff(b, 'months');
        if(months) {
            this.setState({mfgDate: date, expAfterMonth: months})
        }
    };
    handleExpAfterMonth = (e)=>{
        if(!util.isInt(e.target.value) && e.target.value !== ""){
            return false;
        }
        this.setState({expAfterMonth:e.target.value})
    };
    handleQuantity = (e)=>{
        if(!util.isInt(e.target.value) && e.target.value !== ""){
            return false;
        }
        this.setState({quantity:e.target.value})
    };

    handleFreeQuantity = (e)=>{
        if(!util.isInt(e.target.value) && e.target.value !== ""){
            return false;
        }
        this.setState({freeQuantity:e.target.value})
    };


    handlePackagingQuantity = (e)=>{
        if(!util.isInt(e.target.value) && e.target.value !== ""){
            return false;
        }
        this.setState({packagingQuantity:e.target.value})
        this.setState({quantity:(e.target.value * this.state.product.contentQuantity)})
    };


    fetchInventoryById = async () => {
        this.setState({progressStatus:true});
        let res = await PharmacyService.fetchInventoryById(this.state);
        this.setState({manufacturerApiResultCame:true})
        this.setState({progressStatus:false});
        if(res){
            try{
                this.setState({
                    "inventoryId":res._id,
                    "productId":res.productId,
                    "batch":res.batch,
                    "mrp":res.mrp,
                    "gst":res.gst,
                    "priceNogst":parseFloat(res.priceNogst).toFixed(2),
                    "priceWgst":parseFloat(res.priceWgst).toFixed(2),
                    "discount":res.discount,
                    "hsn":res.hsn,
                    "mfgDate":res.mfgDate,
                    "expDate":res.expDate,
                    "expAfterMonth":res.expAfterMonth,
                    "quantity":res.quantity,
                    "freeQuantity": res.freeQuantity,
                    "product":res.productDetails,
                    "shipment":res.shipmentDetails,
                    packagingQuantity:res.quantity/res.productDetails.contentQuantity,
                    loadedOnPopup:false,
                })
            } catch (e) {
                toast.error("Something went wrong while fetching inventory by id.")
            }
        }
    }
    fetchShipment = async (e) => {
        console.log(e)
        try {
            if (e.target.value !== 0 || e.target.value !== '0') {
                let suggestedShipment = await PharmacyService.searchShipment(e.target.value, this.state);
                this.setState({allSuggestedShipment: suggestedShipment});
                let temp = [];
                for (let item of suggestedShipment) {
                    let str = {title: item.shipmentCode, value: item};
                    temp.push(str);
                }
                if(temp.length>0) {
                    this.setState({suggestedShipment: temp});
                }else{
                    this.setState({suggestedShipment: [{title: "", value: ''}]});
                }
            }
        }catch (e) {
            this.setState({suggestedShipment: [{title: "", value: ''}]});
        }
    };
    fetchProduct = async (e) => {
        console.log(e)
        try {
            if (e.target.value !== 0 || e.target.value !== '0') {
                let suggestedProduct = await PharmacyService.searchProduct(e.target.value, this.state);
                this.setState({allSuggestedProduct: suggestedProduct});
                let temp = [];
                for (let item of suggestedProduct) {
                    let str = {title: item.productName, value: item};
                    temp.push(str);
                }
                if(temp.length>0) {
                    this.setState({suggestedProduct: temp});
                }else{
                    this.setState({suggestedProduct: [{title: "", value: ''}]});
                }
            }
        }catch (e) {
            this.setState({suggestedProduct: [{title: "", value: ''}]});
        }
    };
    async componentDidMount() {
        let userData = await UserStore.fetchSessionData();
        this.setState({userData: userData})
        if(userData.type === "platformAdmin"){
            this.setState({orgId: userData._id})
        } else if(userData.type === "ORG_ADMIN"){
            this.setState({orgId: userData.orgDetails[0]._id})
        }


        if(this.props.loadedOnPopup === true){
            let inventoryId = this.props.inventoryId;
            this.setState({inventoryId:inventoryId, loadedOnPopup:true})

            this.fetchInventoryById()
        } else {
            const { inventoryId } = this.props.queryString;
            this.setState({inventoryId:inventoryId})
            this.fetchInventoryById()
        }

    }
    onChangeShipment = (event, values) => {
        if((values && values.hasOwnProperty("value") && values.value!==null)) {
            this.setState({
                shipment: values.value
            }, () => {
                console.log(this.state.shipment)
                this.setState({supplierError:false})
            });
        }

    }
    onChangeProduct = (event, values) => {
        if((values && values.hasOwnProperty("value") && values.value!==null)) {
            this.setState({
                product: values.value
            }, () => {
                console.log(this.state.product)
                this.setState({receivedByError:false})
            });
        }
    }
    validateSupplier = () => {
        if(!this.state.supplier.hasOwnProperty("_id")){
            this.setState({"supplierError": true})
        } else {
            this.setState({"supplierError": false})
        }
    }
    validateReceivedBy = () => {
        if(!this.state.user.hasOwnProperty("_id")){
            this.setState({"receivedByError": true})
        } else {
            this.setState({"receivedByError": false})
        }
    }
    validateBatch = () => {
        if(!this.state.batch || !this.state.batch.trim()){
            this.setState({"batchError": true})
        } else {
            this.setState({"batchError": false})
        }
    }
    validateAmount = () => {
        if(!this.state.amount || !util.isNumericTwoDecimalFull(this.state.amount)){
            this.setState({"amountError": true})
        } else {
            this.setState({"amountError": false})
        }
    }
    validatePaymentAmount  = () => {
        if(this.state.paymentAmount && !util.isNumericTwoDecimalFull(this.state.paymentAmount)){
            this.setState({"paidAmountError": true})
        } else {
            this.setState({"paidAmountError": false})
        }
    }
    updateInventory =async () => {
        if(!this.state.productId) {
            this.setState({productIdError:true})
            return false;
        } else {
            this.setState({productIdError:false})
        }
        if(!this.state.batch) {
            this.setState({batchError:true})
            return false;
        } else {
            this.setState({batchError:false})
        }
        if(!this.state.mrp) {
            this.setState({mrpError:true})
            return false;
        } else {
            this.setState({mrpError:false})
        }
        if(!this.state.priceNogst) {
            this.setState({priceNogstError:true})
            return false;
        } else {
            this.setState({priceNogstError:false})
        }
        if(!this.state.priceWgst) {
            this.setState({priceWgstError:true})
            return false;
        } else {
            this.setState({priceWgstError:false})
        }
        if(!this.state.hsn) {
            this.setState({hsnError:true})
            return false;
        } else {
            this.setState({hsnError:false})
        }
        if(!this.state.expAfterMonth) {
            this.setState({expAfterMonthError:true})
            return false;
        } else {
            this.setState({expAfterMonthError:false})
        }
        if(!this.state.quantity) {
            this.setState({quantityError:true})
            return false;
        } else {
            this.setState({quantityError:false})
        }


        // Modify mfg and exp
        let expYear = Moments(this.state.expDate,"MM/YY").year()
        let expMonth = Moments(this.state.expDate,"MM/YY").month()+1
        let expDateString = expYear+"-"+expMonth+"-02";

        let mfgYear = Moments(this.state.mfgDate,"MM/YY").year()
        let mfgMonth = Moments(this.state.mfgDate,"MM/YY").month()+1
        let mfgDateString = mfgYear+"-"+mfgMonth+"-02";
        let expDate = Moments(expDateString)
        let mfgDate = Moments(mfgDateString)
        this.setState({expDate:expDate,mfgDate:mfgDate })

        this.setState({progressStatus:true});
        let status=await PharmacyService.updateInventory(this.state);
        this.setState({progressStatus:false});
        if(status===false){
            toast.error("Something went wrong.");
            return true;
        }else{
            //this.fetchInventoryById()
            toast.success("Inventory updated successfully.");
            this.props.onEditComplete()
        }
    }
    initDelete = () => {
        this.toggleDelete()
    }
    confirmDelete = async () => {
        this.setState({progressStatus:true});
        let status=await PharmacyService.deleteInventory(this.state);
        this.setState({progressStatus:false});
        if(status===false){
            toast.error("Something went wrong. This might be due to duplicate entry.");
            return true;
        }else{
            toast.success("Inventory deleted successfully.");
            this.toggleDelete();
            this.props.onEditComplete()
            //this.props.history.push('/view-stock');
        }
    }
    toggleDelete = () => {
        this.setState({
            modalDelete: !this.state.modalDelete
        });
    }
    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.progressStatus}/>

                {/*{this.state.loadedOnPopup === false &&*/}
                {/*<>*/}
                {/*    <MDBBreadcrumb>*/}
                {/*        <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>*/}
                {/*        <MDBBreadcrumbItem><Link to={'/view-stock'}>Stock</Link></MDBBreadcrumbItem>*/}
                {/*        <MDBBreadcrumbItem active>Edit Stock</MDBBreadcrumbItem>*/}
                {/*    </MDBBreadcrumb>*/}
                {/*</>*/}
                {/*}*/}
                <MDBBox>
                    <MDBCard className={"mt-2"}>
                        <MDBCardHeader>
                            <div className={"float-left"} style={{"width":"25%"}}>
                                <Autocomplete
                                    freeSolo
                                    onChange={this.onChangeShipment}
                                    id="combo-box-demo-1"
                                    options={this.state.suggestedShipment}
                                    getOptionLabel={option => option.title}
                                    onInputChange={(e) => {
                                        this.fetchShipment(e)
                                    }}
                                    selectOnFocus={true}
                                    renderInput={params => (
                                        <TextField style={ this.state.shipmentError ?  {"borderBottom":"1px solid RED"} : {}} {...params} placeholder="Search Shipment"
                                                   margin="normal" fullWidth/>
                                    )}
                                />
                            </div>
                            <div style={{"width":"25%"}} className={"float-left ml-5"}>
                                {this.state.shipment.hasOwnProperty("supplierDetails") &&
                                <MDBCard style={{"width":"100%"}}>
                                    <MDBCardBody>
                                        <MDBRow>
                                            <MDBCol className={"float-left"}>
                                                <div>Shipment Code: {this.state.shipment.shipmentCode}</div>
                                                <div>Supplier Name: {this.state.shipment.supplierDetails.supplierName}</div>
                                                <div>Invoiced Amount: {this.state.shipment.amount.toFixed("2")}</div>
                                                <div>Received By: {this.state.shipment.receivedByDetails.name}</div>
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBCardBody>
                                </MDBCard>
                                }
                            </div>
                            <MDBFormInline className="float-right md-form mr-auto m-0">
                                <MDBBtn outline color="blue-grey" size="sm" onClick={this.updateInventory} className="mr-auto"
                                        disabled={this.state.progressStatus}
                                >
                                    Save
                                </MDBBtn>
                                <MDBBtn outline color="blue-grey" size="sm" onClick={this.initDelete} className="mr-auto float-right"
                                        disabled={this.state.progressStatus}
                                >
                                    Delete
                                </MDBBtn>
                            </MDBFormInline>
                        </MDBCardHeader>
                        <MDBCardBody>
                            <MDBRow>
                                <MDBCol size={"3"}>
                                    <Autocomplete
                                        freeSolo
                                        onChange={this.onChangeProduct}
                                        id="combo-box-demo-2"
                                        options={this.state.suggestedProduct}
                                        getOptionLabel={option => option.title}
                                        onInputChange={(e) => {
                                            this.fetchProduct(e)
                                        }}
                                        selectOnFocus={true}
                                        renderInput={params => (
                                            <TextField style={ this.state.shipmentError ?  {"borderBottom":"1px solid RED"} : {}} {...params} placeholder="Product"
                                                       margin="normal" fullWidth/>
                                        )}
                                    />
                                </MDBCol>
                                <MDBCol>
                                    {this.state.product.hasOwnProperty("productName") &&
                                    <MDBCard style={{"width":"50%"}}>
                                        <MDBCardBody>
                                            <MDBRow>
                                                <MDBCol className={"float-left"}>
                                                    <div>Product Name: {this.state.product.productName}</div>
                                                    {this.state.product.contentQuantity &&
                                                    <div>Content Quantity &
                                                        Unit: {this.state.product.contentQuantity} {this.state.product.quantityUnit}</div>
                                                    }
                                                    {this.state.product.retailPackage &&
                                                        <div>Retail Packaging: {this.state.product.retailPackage}</div>
                                                    }
                                                </MDBCol>
                                            </MDBRow>
                                        </MDBCardBody>
                                    </MDBCard>
                                    }
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBInput size={"sm"} outline={false} className={this.state.batchError ? "border-danger" : ""} maxLength="30"
                                              type='text' label="Batch Number *" onChange={this.handleBatch}
                                              value={this.state.batch} />
                                </MDBCol>
                                <MDBCol>
                                    <MDBInput size={"sm"} outline={false} className={this.state.mrpError ? "border-danger" : ""} maxLength="15"
                                              type='text' label="MRP *" onChange={this.handleMrp}
                                              value={this.state.mrp} />
                                </MDBCol>
                                <MDBCol>
                                    <MDBInput size={"sm"} outline={false} className={this.state.priceNogstError ? "border-danger" : ""} maxLength="15"
                                              type='text' label="Price(w/o GST)" onChange={this.handlePriceNoGST}
                                              value={this.state.priceNogst} />
                                </MDBCol>
                                <MDBCol>
                                    <MDBInput size={"sm"} outline={false} className={this.state.gstError ? "border-danger" : ""} maxLength="15"
                                              type='text' label="GST % *" onChange={this.handleGST}
                                              value={this.state.gst} />
                                </MDBCol>

                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBInput size={"sm"} outline={false} className={this.state.priceWgstError ? "border-danger" : ""} maxLength="30"
                                              type='text' label="Price(w/ GST) *" onChange={this.handlePriceWGST}
                                              value={this.state.priceWgst} />
                                </MDBCol>
                                <MDBCol>
                                    <MDBInput size={"sm"} outline={false} className={this.state.discountError ? "border-danger" : ""} maxLength="15"
                                              type='text' label="Discount(%)" onChange={this.handleDiscount}
                                              value={this.state.discount} />
                                </MDBCol>
                                <MDBCol>
                                    <MDBInput size={"sm"} outline={false} className={this.state.hsnError ? "border-danger" : ""} maxLength="15"
                                              type='text' label="HSN/SAC" onChange={this.handleHSN}
                                              value={this.state.hsn} />
                                </MDBCol>
                                {this.state.product.looseSaleAllowed === "Y" &&
                                <MDBCol>
                                    <span>
                                    <MDBInput size={"sm"} outline={false} style={{width: "7rem"}} className={this.state.quantityError ? "border-danger float-left" : "float-left"} maxLength="15"
                                              type='text' label={"Number of "+this.state.product.retailPackage} onChange={this.handlePackagingQuantity}
                                              value={this.state.packagingQuantity} /></span>
                                    <span style={{fontSize:"12px"}} className={"float-left"}> X {this.state.product.contentQuantity}  {this.state.product.quantityUnit}</span>
                                </MDBCol>
                                }
                                {this.state.product.looseSaleAllowed === "N" &&
                                <MDBCol>
                                    <span>
                                    <MDBInput size={"sm"} outline={false} style={{width: "7rem"}}  className={this.state.quantityError ? "border-danger float-left" : "float-left"} maxLength="15"
                                              type='text' label={"Number of "+this.state.product.retailPackage} onChange={this.handleQuantity}
                                              value={this.state.quantity} /></span>
                                    <span style={{fontSize:"12px"}} className={"float-left"}> X {this.state.product.contentQuantity}  {this.state.product.quantityUnit}</span>
                                </MDBCol>
                                }

                                <MDBCol>
                                    <MDBInput size={"sm"} outline={false} className={this.state.quantityError ? "border-danger" : ""} maxLength="15"
                                              type='text' label={"Purchase Quantity "}  onChange={this.handleQuantity}
                                              value={this.state.quantity} />
                                </MDBCol>
                                <MDBCol>
                                    <MDBInput size={"sm"} outline={false} maxLength="15"
                                              type='text' label={"Free Quantity "}  onChange={this.handleFreeQuantity}
                                              value={this.state.freeQuantity} />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol >
                                    <MuiPickersUtilsProvider  utils={MomentUtils}>
                                        <KeyboardDatePicker style={{"marginTop":"5px","marginLeft":"1px", "marginRight":"1px"}}
                                                            format="MM/YY"
                                                            margin="normal"
                                                            id="invoice_date"
                                                            label="Mfg Date(MM/YY)"
                                                            value={this.state.mfgDate}
                                                            onChange={this.handleManufacturerDate}
                                                            KeyboardButtonProps={{
                                                                "aria-label": "change date",
                                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </MDBCol>
                                <MDBCol >
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker style={{"marginTop":"5px","marginLeft":"1px", "marginRight":"1px"}}

                                                            format="MM/YY"
                                                            margin="normal"
                                                            id="receiving_date"
                                                            label="Exp Date(MM/YY)"
                                                            value={this.state.expDate}
                                                            onChange={this.handleExpiryDate}
                                                            KeyboardButtonProps={{
                                                                "aria-label": "change date",
                                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </MDBCol>
                                <MDBCol>
                                    <MDBInput size={"sm"} outline={false} className={this.state.expAfterMonthError ? "border-danger" : ""} maxLength="15"
                                              type='text' label="Expiring After Months" onChange={this.handleExpAfterMonth}
                                              value={this.state.expAfterMonth} />
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                    <MDBModal isOpen={this.state.modalDelete} toggle={this.toggleDelete}>
                        <MDBModalHeader toggle={this.toggleDelete}>Delete Inventory</MDBModalHeader>
                        <MDBModalBody>
                            Are you sure you want to delete this inventory?
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn disabled={this.state.progressStatus} color="primary" onClick={this.confirmDelete}>Delete</MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>
                </MDBBox>
            </React.Fragment>
        );
    }
}
export default withRouter(UpdateInventory)
