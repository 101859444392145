import React,{Suspense} from "react";
import {Link} from "react-router-dom";
import {
    MDBBreadcrumbItem, MDBBreadcrumb
} from 'mdbreact';
import AddProductV2Comp from "../../components/productsv2/addProductV2Comp";

export default class AddProductV2 extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>View Products</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <Suspense fallback={null}>
                    <AddProductV2Comp/>
                </Suspense>
            </React.Fragment>
        );
    }
}