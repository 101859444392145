import React from "react";
import Moments from "moment"
import {
   MDBRow, MDBBox, MDBInput, MDBBtn, MDBCol, MDBTable, MDBTableHead, MDBTableBody,
} from 'mdbreact';
import PharmacyService from "../../services/pharmacyService";
export default class OnlineOrder extends React.Component {
    componentDidMount() {
    }
    state = {
        orders:[
            {
                "paymentStatus":"CREATED",
                "totalAmount":675.0,
                "userType":"CONSUMER",
                "vendorName":null,
                "vendorDetails":null,
                "onlineOrderRequest":null,
                "paymentSignature":null,
                "prescriptionNeeded":false,
                "createdBy":32,
                "deleteFlag":false,
                "vendorId":0,
                "vendorAddressId":0,
                "onlineOrderId":0,
                "updatedBy":null,
                "totalQuantity":0.0,
                "customerId":32,
                "customerAddressId":89,
                "shippingAddressId":89,
                "customerName":"Uttam",
                "orderType":"CART",
                "paymentMode":"ONLINE",
                "finalAmount":675.0,
                "orderNo":"OD1586026723103",
                "paymentGatewayOrderId":"order_EaW2tilQWJMFpF",
                "attachmentUrl":[
                ],
                "orderStatus":"CREATED",
                "refundReference":null,
                "paymentReference":"",
                "pharmacyOrderId":0,
                "orderItems":[
                    {
                        "userType":"CONSUMER",
                        "createdBy":32,
                        "deleteFlag":false,
                        "updatedBy":0,
                        "productCgst":0.0,
                        "productSgst":0.0,
                        "productBasePrice":0.0,
                        "purchasePrice":0.0,
                        "rackId":0,
                        "quantity":3.0,
                        "paymentMode":null,
                        "finalAmount":125.0,
                        "orderNo":null,
                        "orderStatus":"CREATED",
                        "productMrp":125.0,
                        "discountPercentage":0.0,
                        "onlineProductId":null,
                        "batchNo":null,
                        "mfgDate":0,
                        "inventoryId":0,
                        "productName":"LIV 52 200ML SUSP",
                        "updatedOn":0,
                        "productId":81286,
                        "createdOn":1586026723103,
                        "gstAmount":0.0,
                        "discountAmount":0.0,
                        "orderDate":0,
                        "productGst":0.0,
                        "contentQuantity":0.0,
                        "hsnSac":null,
                        "expDate":0,
                        "orderId":100,
                        "expPeriod":0.0,
                        "createdOnString":null,
                        "expDateString":null,
                        "orderDateString":null,
                        "updatedOnString":null,
                        "mfgDateString":null,
                        "typeName":"OTC",
                        "id":298
                    },
                    {
                        "userType":"CONSUMER",
                        "createdBy":32,
                        "deleteFlag":false,
                        "updatedBy":0,
                        "productCgst":0.0,
                        "productSgst":0.0,
                        "productBasePrice":0.0,
                        "purchasePrice":0.0,
                        "rackId":0,
                        "quantity":4.0,
                        "paymentMode":null,
                        "finalAmount":75.0,
                        "orderNo":null,
                        "orderStatus":"CREATED",
                        "productMrp":75.0,
                        "discountPercentage":0.0,
                        "onlineProductId":null,
                        "batchNo":null,
                        "mfgDate":0,
                        "inventoryId":0,
                        "productName":"LIV 52 100ML SUSP",
                        "updatedOn":0,
                        "productId":81939,
                        "createdOn":1586026723103,
                        "gstAmount":0.0,
                        "discountAmount":0.0,
                        "orderDate":0,
                        "productGst":0.0,
                        "contentQuantity":0.0,
                        "hsnSac":null,
                        "expDate":0,
                        "orderId":100,
                        "expPeriod":0.0,
                        "createdOnString":null,
                        "expDateString":null,
                        "orderDateString":null,
                        "updatedOnString":null,
                        "mfgDateString":null,
                        "typeName":"OTC",
                        "id":299
                    }
                ],
                "discountPercentage":0.0,
                "customerPhone":null,
                "updatedOn":1591044641248,
                "comments":null,
                "createdOn":1586026723103,
                "prescribedbyId":0,
                "prescribedbyName":null,
                "customerBillingName":"Uttam Dutta",
                "customerBillingAddress":"Kachalu khowa, Milanjyoti Path, Kachalukhowa, Nagaon, Assam-782003",
                "customerBillingPhone":"9176618472",
                "customerShippingName":"Uttam Dutta",
                "customerShippingAddress":"Kachalu khowa, Milanjyoti Path, Kachalukhowa, Nagaon, Assam-782003",
                "customerShippingPhone":"9176618472",
                "customerEmail":null,
                "vendorAddress":null,
                "vendorEmail":null,
                "vendorPhone":null,
                "baseAmount":0.0,
                "sgstAmount":0.0,
                "cgstAmount":0.0,
                "gstAmount":0.0,
                "totalItems":2.0,
                "discountAmount":0.0,
                "deliveryType":"SHIP",
                "deliveryReference":null,
                "deliveredBy":null,
                "deliveredOn":0,
                "cancelledBy":null,
                "cancelledOn":0,
                "moduleType":"PATIENT",
                "vendorGstin":null,
                "amountPaid":0.0,
                "orderDate":1586026723093,
                "id":100
            }
        ],
        carts:[],
        orderApiResultCame:false,
        stDate: Moments(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format("YYYY-MM-DD"),
        endDate:'',
        mode:"Month",
        currentDWM:Moments(new Date()).format("MMM"),
        error:"",
        medicine:"",
        searchedMedicineList:[],
    }
    addItemToCart = (item) => {
        let existingCarts = this.state.carts;
        item.totalPrice = item.maxRetailPrice;
        item.quantity = 1;
        existingCarts.push(item);
        this.setState({ "carts": existingCarts })
    }
    convertNonUnixToReadbleDate = date =>{
        if(date){
            return Moments(date).format("MMM D");
        } else {
            return "";
        }
    }
    convertMfgOrExpiry = date =>{
        if(date){
            return Moments.unix(date).format("MMM YYYY");
        } else {
            return "";
        }
    }
    convertToReadbleDate = date =>{
        if(date){
            return Moments.unix(date).format("MMM D, YYYY hh:mm A");
        } else {
            return "";
        }
    }
    convertToReadbleDateDD = date =>{
        if(date){
            return Moments.unix(date).format("D");
        } else {
            return "";
        }
    }
    convertToReadbleDateYYYY = date =>{
        if(date){
            return Moments.unix(date).format("YYYY");
        } else {
            return "";
        }
    }
    convertToReadbleDateHMA = date =>{
        if(date){
            return Moments.unix(date).format("hh:mm A");
        } else {
            return "";
        }
    }
    handleEndDate = date => {
        this.setState({"stDate":Moments(date).format("YYYY-MM-DD")});
    };
    handleChangeMedicine = async (e)=>{
        this.setState({medicine:e})
        let res = await PharmacyService.autoSuggestInventory(e);
        if(res){
            this.setState({"searchedMedicineList":res})
        } else {
            this.setState({"searchedMedicineList":[]})
        }
    }
    closeSearch = () =>{
        this.setState({medicine:"", searchedMedicineList:[]})
    }
    render() {
        return (
            <React.Fragment>
                <MDBBox>
                    <MDBRow>
                        <MDBCol>
                            <MDBBtn>Acknowledge Order</MDBBtn>
                            <MDBBtn>Generate Invoice and ask to pay</MDBBtn>
                            <MDBBtn>Cancel Order</MDBBtn>
                            <MDBBtn>Postpone/Delay Order</MDBBtn>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol className={"p-1 m-3"}>
                            <MDBInput label="Search Medicine" getValue={this.handleChangeMedicine}
                                      value={this.state.medicine}/>
                            {this.state.searchedMedicineList.length > 0 &&
                            <div className="card">
                                <div className="card-header" style={{"padding":"5px"}}>
                                    <MDBBtn className={"float-right"} color={"primary"} size={"sm"} onClick={this.closeSearch}>Close</MDBBtn>
                                </div>
                                <ul className="list-group list-group-flush">
                                    {this.state.searchedMedicineList.map((row, index) => (
                                        <li className="list-group-item">
                                            <MDBRow>
                                                <MDBCol>{row.medicineName} {row.quantityUnit}
                                                    <div>{this.convertMfgOrExpiry(row.mfgDate/1000)} - {this.convertMfgOrExpiry(row.expiryDate/1000)}</div>
                                                </MDBCol>
                                                <MDBCol>In Stock</MDBCol>
                                                <MDBCol>Rs. {row.maxRetailPrice}</MDBCol>
                                                <MDBCol><MDBBtn size={"sm"} onClick={()=>this.addItemToCart(row)}>Add To Cart</MDBBtn></MDBCol>
                                            </MDBRow>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            }
                            {this.state.searchedMedicineList.map((row, index) => (
                                <MDBRow>
                                    <MDBCol>{row.medicineName} {row.quantityUnit}
                                        <div>{this.convertMfgOrExpiry(row.mfgDate/1000)} - {this.convertMfgOrExpiry(row.expiryDate/1000)}</div>
                                    </MDBCol>
                                    <MDBCol>In Stock</MDBCol>
                                    <MDBCol>Rs. {row.maxRetailPrice}</MDBCol>
                                    <MDBCol><MDBBtn onClick={()=>this.addItemToCart(row)}>Add To Cart</MDBBtn></MDBCol>
                                </MDBRow>
                            ))}
                        </MDBCol>
                    </MDBRow>
                    <MDBTable>
                        <MDBTableHead>
                            <tr>
                                <th>Name</th>
                                <th>Contains</th>
                                <th>Mfg Date</th>
                                <th>Exp Date</th>
                                <th>MRP</th>
                                <th>Batch No</th>
                                <th>Unit</th>
                                <th>Subtotal</th>
                                <th></th>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            {this.state.carts.map((row, index) => (
                                <tr>
                                    <td>{row.medicineName}</td>
                                    <td>{row.contentQuantity} {row.quantityUnit}</td>
                                    <td>{this.convertMfgOrExpiry(row.mfgDate/1000)}</td>
                                    <td>{this.convertMfgOrExpiry(row.expiryDate/1000)}</td>
                                    <td>Rs. {row.maxRetailPrice}</td>
                                    <td>{row.batchNo}</td>
                                    <td>{row.quantity}</td>
                                    <td>Rs. {row.totalPrice}</td>
                                    <td></td>
                                </tr>
                            ))}
                        </MDBTableBody>
                    </MDBTable>
                </MDBBox>
            </React.Fragment>
        );
    }
}