import axios from "axios";
import config from "../config";
import Cookies from 'universal-cookie';
import utility from "../utility/utility";
import Moments from "moment";


const ThirdPartyService= {
    fetchNimbusRateAndServiceAvailability : async function (payload, nimbusToken) {
       try {
           const headers = {
               headers: {Authorization: "Token "+nimbusToken}
           };

           const response = await axios.post("https://api.nimbuspost.com/v1/courier/serviceability", payload, headers);
           return response.data
       } catch (e) {
           return false;
       }
    },

    loginToNimbus : async function () {
        try {
            let loginUrl = "https://api.nimbuspost.com/v1/users/login"
            let payload={"email": "support@positra.com", "password": "Password@1"}
            const response = await axios.post(loginUrl, payload);
            return response.data
        } catch (e) {
            return false;
        }
    },

    createNimbusShipment : async function (payload, nimbusToken) {
        try {
            const headers = {
                headers: {Authorization: "Token "+nimbusToken}
            };

            const response = await axios.post("https://api.nimbuspost.com/v1/shipments", payload, headers);
            return response.data
        } catch (e) {
            return false;
        }
    },

    fetchPickrrRateAndServiceAvailability : async function (payload, authToken) {
        try {
            let params = "shipment_type=forward&pickup_pincode="+payload.origin+"&drop_pincode="+payload.destination+"&delivery_mode="+payload.delivery_mode+"&length="+payload.length+"&breadth="+payload.breadth+"&height="+payload.height+"&weight="+payload.weight+"&payment_mode="+payload.payment_type

            if(payload.payment_type == "cod"){
                params = params+"&cod_amount="+payload.order_amount
            }
            const response = await axios.get("https://pickrr.com/api-v2/client/fetch-price-calculator-generic/?auth_token="+authToken+"&"+params);
            return response.data
        } catch (e) {
            return false;
        }
    },

    createPickrrShipment : async function (payload) {
        try {
            const response = await axios.post("https://pickrr.com/api/place-order/", payload);
            return response.data
        } catch (e) {
            return false;
        }
    },

    fetchEcomAwb: async function (type) {
        try {
            const response = await axios.get(config.APIHOST+config.FETCH_ECOM_AWB+"?type="+type);
            return response.data
        } catch (e) {
            return false;
        }
    },

    fetchEcomServiceAvailability: async function (pincode) {
        try {
            const response = await axios.get(config.APIHOST+config.FETCH_ECOM_SERVICE_AVAILABILITY+"?pincode="+pincode);
            return response.data
        } catch (e) {
            return false;
        }
    },

    createEcomShipment: async function (payload) {
        try {
            const response = await axios.post(config.APIHOST+config.MANIFEST_ECOM_ORDER, payload);
            return response.data
        } catch (e) {
            return false;
        }
    },

    cancelEcomOrder: async function (awb) {
        try {
            const response = await axios.get(config.APIHOST+config.CANCEL_ECOM_ORDER+"?awb="+awb);
            return response.data
        } catch (e) {
            return false;
        }
    },

    trackAwb: async function (awb) {
        try {
            const response = await axios.get(config.APIHOST+config.TRACK_ECOM_ORDER+"?awb="+awb);
            return response.data
        } catch (e) {
            return false;
        }
    },

    fetchShippingPerformance : async function(){
        try {
            const response = await axios.get(config.APIHOST+config.FETCH_SHIPPING_PERFORMANCE);
            return response.data
        } catch (e) {
            return false;
        }
    }

}

export default ThirdPartyService