import React from "react"
import UserStore from "../../stores/userStore";
import InventoryService from "../../services/inventoryService";
import {toast} from "react-toastify";
import utility from "../../utility/utility";
import SimpleBackdrop from "../common/overlay";
import {withRouter} from "react-router-dom"
import {
    MDBBtn, MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader
} from "mdbreact";
import ProductSpecifications from "./ProductSpecifications";
import MultiPicDirectUpload from "../common/fileUpload/multiPicDirectUpload";
import ProductDetailsPreview from "./productDetailsPreview";

class EditProductImage extends React.Component {
    async componentDidMount() {
        window.scrollTo(0, 0)
        let userData = await UserStore.fetchSessionData();
        const {id} = this.props.match.params;
        let productId = ""
        if (this.props.hasOwnProperty("productId")) {
            productId = this.props.productId
        } else {
            productId = id
        }
        await this.setState({id: productId, userData});
        this.setState({progressStatus: true})
        await this.fetchData()
        this.setState({progressStatus: false})
    }

    constructor(props) {
        super(props);
        this.state = {
            progressStatus: false,
            productName: "",
            userData: {},
            modalPreview: false,

            uploadedFiles: [],

            id: "",
            productDetails: [],
        }
    }

    fetchData = async () => {
        if (this.state.id !== "") {
            let data = await InventoryService.fetchProductById(this.state.userData, this.state.id)
            if (data) {
                await this.setState({
                    productName: data.productName,
                    uploadedFiles: (data.hasOwnProperty("imageUrl") && data.imageUrl !== null && data.imageUrl.length > 0) ? data.imageUrl : [],

                    productDetails: data,
                })


            } else {
                toast.error("Something went wrong while fetching the product data.")
            }
        }
    }


    toggle = item => {
        this.setState({["modal" + item]: !this.state["modal" + item]})
    }

    resetKeyGAT = () => {
        let randomString = Math.random().toString(36);
        this.setState({keyGAT: randomString});
    }

    getUploadedFiles = async (files) => {
        if (files.length === 0) {
            await this.setState({uploadedFiles: []})
        } else {
            await this.setState({uploadedFiles: files})
        }
    }

    // Edit Product Image
    editProduct = async () => {
        if (this.state.uploadedFiles.length === 0) {
            toast.error('Please add at least one product image.')
            return false
        }
        let payload = {

            imageUrl: this.state.uploadedFiles,
            //masterProductId: this.state.productDetails.hasOwnProperty("masterProductId") && this.state.productDetails.masterProductId !== "" ? this.state.productDetails.masterProductId : "",
            _id: this.state.id
        }
        this.setState({progressStatus: true})
        let data = await InventoryService.editProductImageAloneV3(this.state.userData.sessionToken, payload)
        this.setState({progressStatus: false})
        if (data) {
            toast.success("The product image is updated successfully.")

        } else {
            toast.error("Something went wrong while updating the product image.")
        }
    }

    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.progressStatus}/>


                {this.state.progressStatus === false && <>

                    <div className={'col-md-11 mx-auto white rounded-sm border p-0 mt-2 add-product-img-button-position-main-box'}>
                        <div className={'col-12 p-2 grey lighten-4 border-bottom d-flex justify-content-between align-items-center'}>
                            <b className={"font-weight-bold"}>Product Images</b>
                        </div>
                        {this.state.uploadedFiles !== null ?
                            <MultiPicDirectUpload getUploadedFiles={this.getUploadedFiles}
                                                  fileList={this.state.uploadedFiles}/> :
                            <MultiPicDirectUpload getUploadedFiles={this.getUploadedFiles}/>
                        }
                    </div>
                    {/*<div className={'col-md-11 mx-auto white rounded-sm border p-0'}>*/}
                    {/*  <div className={'col-12 p-2 grey lighten-4 d-flex justify-content-between align-items-center'}>*/}
                    <div className={'col-12 text-center p-2'}>
                        <MDBBtn outline size={'md'} onClick={() => this.toggle("Preview")}
                                disabled={this.state.progressStatus}>Preview</MDBBtn>
                        <MDBBtn outline  size={'md'} onClick={() => this.props.onEditComplete()}
                                disabled={this.state.progressStatus}>Close</MDBBtn>
                        <MDBBtn  size={'md'} onClick={this.editProduct}
                                 disabled={this.state.progressStatus}>Save</MDBBtn>
                    </div>
                    {/* <div className={'row mx-0'}>
                                <MDBBtn size={'md'} outline onClick={this.props.close}
                                        disabled={this.state.progressStatus}>Close</MDBBtn>
                                <MDBBtn size={'md'} onClick={this.editProduct}
                                        disabled={this.state.progressStatus}>Save</MDBBtn>
                            </div>*/}
                    {/*  </div>*/}
                    {/*    </div>*/}
                </>}

                <MDBModal isOpen={this.state.modalPreview} toggle={() => this.toggle("Preview")} size="fluid">
                    <MDBModalHeader toggle={() => this.toggle("Preview")}>Product Details Preview</MDBModalHeader>
                    <MDBModalBody>
                        <ProductDetailsPreview productDetails={this.state}/>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="secondary" onClick={this.props.close}>Close</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
            </React.Fragment>
        )
    }
}

export default withRouter(EditProductImage);