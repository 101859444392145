const dataTableSuppliers= {
    suppliers:[
        {
            label: 'Supplier',
            field: 'supplierName',
            sort: 'asc',
            width: 300
        },
        {
            label: 'Contact Name',
            field: 'contactName',
            sort: 'asc',
            width: 300
        },
        {
            label: 'Address',
            field: 'handleAddress',
            sort: 'disabled',
            width: 300
        },
        {
            label: 'Phone',
            field: 'phone',
            sort: 'asc',
            width: 100
        },
        {
            label: 'Email',
            field: 'email',
            sort:'disabled',
            width: 50
        },
        {
            label: '',
            field: 'handleEdit',
            sort:'disabled',
            width: 50
        },
        {
            label: '',
            field: 'handleDelete',
            sort:'disabled',
            width: 50
        }
    ]
}
export default dataTableSuppliers;