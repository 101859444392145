import React from "react";
import {Link, withRouter} from "react-router-dom";
import {
    MDBContainer,
    MDBRow,
    MDBInput,
    MDBBtn,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBBreadcrumb,
    MDBBreadcrumbItem,
    MDBProgress,
    MDBModal,
    MDBModalHeader, MDBModalBody, MDBModalFooter, MDBIcon
} from 'mdbreact';
import UserStore from "../../stores/userStore";
import {toast} from "react-toastify";
import FileUpload from "../common/fileUpload/fileUpload";
import config from "../../config";
import util from "../../utility/utility";
import AuthService from "../../services/authService";
import orgConfig from "../../orgConfig";

class UpdateOrgUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orgName:"",
            description:"",
            isActive:"",
            orgUserId:"",
            imageUrl:{},
            orgUserObj:{},
            orgUserApiResultCame:false,
            progressStatus:false,
            progressStatusFileUpload:false,
            orgUserError:"",
            switch1:false,
            imageActionNeeded:"Init",
            lightBoxImages: [],
            address: "",
            street: "",
            pincode:"",
            area: "",
            city: "",
            state: "",
            country: "",
            landmark: "",
            fileUploadingInProgress:false,
            modalDelete:false,
            modalDelImage:false,
            email:"",
            contactNo:""
        }
    }

    componentDidMount() {
        const { userId } = this.props.queryString;
        this.setState({orgUserId:userId})
        this.fetchOrgUserById()
    }

    fetchOrgUserById = async () => {
        let userData=await UserStore.fetchSessionData();
        this.setState({progressStatus:true});
        let res = await AuthService.fetchOrgById(this.state.orgUserId, userData);

        this.setState({orgUserApiResultCame:true})
        this.setState({progressStatus:false});
        // let res = {"imageUrl":{}};
        // if(data.length > 0){
        //     res = data[0]
        // }
        console.log(res);
        if(res){
            try{
                this.setState({"orgName": res.orgName, "email": res.orgEmail, "contactNo": res.orgPhone, "description": res.description, "isActive": res.isActive, "imageUrl": res.imageUrl, address: res.addressLine1, street: res.addressLine2, pincode:res.pinCode, area: res.area, city: res.city, state: res.state,
                    country: res.country, landmark: res.landmark  })
                if(res.imageUrl.hasOwnProperty("filePathOriginal")){
                    this.setState({"imageActionNeeded":"Update"})
                    let lb = [{
                        src: config.IMAGE_HOST+res.imageUrl.filePathOriginal
                    }]
                    this.setState({"lightBoxImages":lb})
                } else {
                    this.setState({"imageActionNeeded":"Add"})
                }
            } catch (e) {
                toast.error("Something went wrong while fetching organization.")
            }
        }
    }

    handleDescription=(e)=>{
        this.setState({description:e.target.value})
    };

    handleOrgName=(e)=>{
        this.setState({orgName:e.target.value})
    };

    validateEmail = () => {
        if(this.state.email && !util.validateEmail(this.state.email)){
            this.setState({"loadingEmail": true, "emailError": true})
        } else {
            this.setState({"loadingEmail": false, "emailError": false})
        }
    }

    validatePhone= () => {
        if(!util.checkPhone(this.state.contactNo)){
            this.setState({ "contactNoError": false})
        } else {
            this.setState({"contactNoError": false})
        }
    }

    validateOrgName = () => {
        if(!this.state.orgName || this.state.orgName.trim() === ""){
            this.setState({"orgUserError": true})
        } else {
            this.setState({"orgUserError": false})
        }
    }

    handleChangeContactNo=(e)=>{
        if(!util.isInt(e.target.value) && e.target.value !== ""){
            return false;
        }
        this.setState({contactNo:e.target.value})
    };
    handleChangeEmail=(e)=>{
        this.setState({email:e.target.value})
    };

    updateOrgUser =async () => {
        let userData=await UserStore.fetchSessionData();
        // Reset all the error first
        this.setState({"orgUserError": false})
        //validations
        this.validateOrgName();

        if(this.state.orgNameError){
            return false;
        }

        this.setState({progressStatus:true});
        let status=await AuthService.updateOrgUser(this.state,userData);
        this.setState({progressStatus:false});
        if(status===false){
            toast.error("Something went wrong.");
            return true;
        }else{
            toast.success("Organization updated successfully.");
            this.fetchOrgUserById()
        }
    }

    handleStatusChange  = () => {
        let status = (this.state.isActive === "Y") ? "N" : "Y";
        this.setState({
            isActive: status
        });
    }

    fileUploadCallback = (uploadedFileObject) => {
        this.setState({ "imageUrl": uploadedFileObject.path, fileUploadingInProgress:true, "progressStatusFileUpload":false})
    }
    initUploadCallback = () => {
        this.setState({"progressStatusFileUpload":true})
    }

    handleAddress=(e)=>{
        this.setState({address:e.target.value})
    };
    handleStreet=(e)=>{
        this.setState({street:e.target.value})
    };
    handlePincode=(e)=>{
        if(!util.isInt(e.target.value) && e.target.value !== ""){
            return false;
        }
        this.setState({pincode:e.target.value})
    };
    handleArea=(e)=>{
        this.setState({area:e.target.value})
    };
    handleCity=(e)=>{
        this.setState({city:e.target.value})
    };
    handleState=(e)=>{
        this.setState({state:e.target.value})
    };
    handleCountry=(e)=>{
        this.setState({country:e.target.value})
    };
    handleLandmark=(e)=>{
        this.setState({landmark:e.target.value})
    };

    initDelete = () => {
        this.toggleDelete()
    }

    confirmDelete = async () => {
        let userData=await UserStore.fetchSessionData();
        this.setState({progressStatus:true});
        let status=await AuthService.deleteOrg(this.state,userData);
        this.setState({progressStatus:false});
        if(status===false){
            toast.error("Something went wrong.");
            return true;
        }else{
            toast.success("Organization deleted successfully.");
            this.toggleDelete();
            this.props.history.push('/organizations');
        }
    }

    toggleDelete = () => {
        this.setState({
            modalDelete: !this.state.modalDelete
        });
    }

    toggleDelImage = () => {
        this.setState({
            modalDelImage: !this.state.modalDelImage
        });
    }

    initDeleteDoc = () => {
        this.toggleDelImage();
    }

    confirmDeleteImage = () => {
        this.setState({"imageUrl": {}})
        this.toggleDelImage();
        this.updateOrgUser()
    }




    render() {
        return (
            <React.Fragment>
                {this.state.progressStatus === true &&
                <span className={'top-stick'}><MDBProgress material preloader color="cyan"/></span>
                }
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem ><Link to={'/organizations'}>Organizations</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Update Organization</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <MDBContainer>
                    <MDBCard className={"mt-2"}>
                        <MDBCardHeader>
                            <span className={"float-left"}>Update Organization</span>



                            <MDBBtn outline color="blue-grey" size="sm" onClick={this.updateOrgUser} className="mr-auto float-right"
                                disabled={this.state.orgNameError || this.state.progressStatus || this.state.progressStatusFileUpload}>
                                    Save
                                </MDBBtn>

                            <MDBBtn outline color="blue-grey" size="sm" onClick={this.initDelete} className="mr-auto float-right"
                                    disabled={this.state.progressStatus}
                            >
                                Delete
                            </MDBBtn>

                        </MDBCardHeader>
                        <MDBCardBody>
                            <MDBRow>
                                <MDBCol>
                                    <MDBInput autoComplete={'off'} style={{"marginTop": "0px"}} onBlur={this.validateOrgUser}
                                              className={this.state.orgNameError ? "border-danger" : "mt-0 mb-0"}
                                              maxLength="100" type='text' label="Organization Name *"
                                              onChange={this.handleOrgName} value={this.state.orgName}/>
                                </MDBCol>

                                <MDBCol>
                                    <MDBInput onBlur={() => this.validateEmail()}
                                              autoComplete={'off'}
                                              className={this.state.emailError ? "border-danger" : ""}
                                              maxLength="50" type='text' label="E-mail"
                                              onChange={this.handleChangeEmail}
                                              value={this.state.email}/>
                                </MDBCol>
                                <MDBCol>
                                    <MDBInput onBlur={() => this.validatePhone()}
                                              autoComplete={'off'}
                                              className={this.state.contactNoError ? "border-danger" : ""}
                                              maxLength={orgConfig.PHONE_NUMBER_LENGTH} type='text' label="Phone *"
                                              onChange={this.handleChangeContactNo}
                                              value={this.state.contactNo}/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBInput autoComplete={'off'} style={{"marginTop": "0px"}}
                                              className={this.state.descriptionError ? "border-danger" : "mt-0 mb-0"}
                                              maxLength="100" type='textarea' label="Description"
                                              onChange={this.handleDescription} value={this.state.description}/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol size={6}>
                                    <MDBInput className={this.state.addressError ? "border-danger" : ""} maxLength="50"
                                              type='text' label="Address Line 1" onChange={this.handleAddress}
                                              value={this.state.address}/>
                                </MDBCol>
                                <MDBCol size={6}>
                                    <MDBInput className={this.state.streetError ? "border-danger" : ""} maxLength="50"
                                              type='text' label="Address Line 2" onChange={this.handleStreet}
                                              value={this.state.street}/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBInput className={this.state.pincodeError ? "border-danger" : ""} maxLength="6"
                                              type='text' label="Postal Code" onChange={this.handlePincode}
                                              value={this.state.pincode}/>
                                </MDBCol>
                                <MDBCol>
                                    <MDBInput className={this.state.areaError ? "border-danger" : ""} maxLength="35"
                                              type='text' label="Area/Locality" onChange={this.handleArea}
                                              value={this.state.area}/>
                                </MDBCol>
                                <MDBCol>
                                    <MDBInput   className={this.state.cityError ? "border-danger" : ""} maxLength="35"
                                                type='text' label="City" onChange={this.handleCity}
                                                value={this.state.city}/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBInput className={this.state.stateError ? "border-danger" : ""} maxLength="35"
                                              type='text' label="State" onChange={this.handleState}
                                              value={this.state.state}/>
                                </MDBCol>
                                <MDBCol>
                                    <MDBInput className={this.state.landmarkError ? "border-danger" : ""} maxLength="50"
                                              type='text' label="Landmark" onChange={this.handleLandmark}
                                              value={this.state.landmark}/>
                                </MDBCol>
                                <MDBCol>
                                    <MDBInput className={this.state.countryError ? "border-danger" : ""} maxLength="35"
                                              type='text' label="Country" onChange={this.handleCountry}
                                              value={this.state.country}/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    {(this.state.imageUrl.hasOwnProperty("filePathThumb") && this.state.imageActionNeeded === "Update") &&
                                    <div>
                                        <img src={config.IMAGE_HOST + this.state.imageUrl.filePathThumb} className={"float-left"} style={{maxWidth: "100px", maxHeight: "100px"}} alt={""}/>
                                        <span style={{"cursor":"pointer"}} className={"p-1"} onClick={()=>this.initDeleteDoc()}><MDBIcon icon="trash" /></span>
                                    </div>
                                    }
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>

                                    {this.state.imageActionNeeded === "Add" &&
                                    <FileUpload buttonLabel={"Add Organization Logo"} initUploadCallback={this.initUploadCallback}
                                                fileUploadCallback={this.fileUploadCallback}></FileUpload>
                                    }
                                    {this.state.imageActionNeeded === "Update" &&
                                    <FileUpload buttonLabel={"Update Organization Logo"} initUploadCallback={this.initUploadCallback}
                                                fileUploadCallback={this.fileUploadCallback}></FileUpload>
                                    }
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                        <MDBModal isOpen={this.state.modalDelete} toggle={this.toggleDelete}>
                            <MDBModalHeader toggle={this.toggleDelete}>Delete organization</MDBModalHeader>
                            <MDBModalBody>
                                Are you sure you want to delete this organization?
                            </MDBModalBody>
                            <MDBModalFooter>
                                <MDBBtn disabled={this.state.progressStatus} color="primary" onClick={this.confirmDelete}>Delete</MDBBtn>
                            </MDBModalFooter>
                        </MDBModal>

                        <MDBModal isOpen={this.state.modalDelImage} toggle={this.toggleDelImage}>
                            <MDBModalHeader toggle={this.toggleDelImage}>Delete organization Logo</MDBModalHeader>
                            <MDBModalBody>
                                Are you sure you want to delete the orgUser logo?

                            </MDBModalBody>
                            <MDBModalFooter>
                                <MDBBtn disabled={this.state.progressStatus} color="primary" onClick={this.confirmDeleteImage}>Delete</MDBBtn>
                            </MDBModalFooter>
                        </MDBModal>


                    </MDBCard>
                </MDBContainer>


            </React.Fragment>
        );
    }
}

export default withRouter(UpdateOrgUser)