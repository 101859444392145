import React from "react";
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardHeader, MDBCol,
    MDBContainer,
    MDBFormInline, MDBIcon,
    MDBInput,
    MDBProgress, MDBSelect, MDBSelectInput, MDBSelectOption, MDBSelectOptions
} from "mdbreact";
import orgConfig from "../../orgConfig";
import util from "../../utility/utility";
import {toast} from "react-toastify";
import UserStore from "../../stores/userStore";
import OrderService from "../../services/orderService";
import {withRouter} from "react-router-dom";

class AddPurchaseNote extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            progressStatus: false,
            purchaseNoteData: [{
                note: "",
                quantity: "",
                priority: "LOW",
                noteError: false,
                quantityError: false,
            }],
            name: "", nameError: false,
            contactNo: "", contactNoError: false,
            userData: {},
        }
        this.handleNoteQnty = this.handleNoteQnty.bind(this)
        this.handleNameContactNo = this.handleNameContactNo.bind(this)
    }

    async componentDidMount() {
        let userData = await UserStore.fetchSessionData(), orgId = "";
        if (userData.type === "platformAdmin") {
            orgId = userData._id
        } else if (userData.type === "ORG_ADMIN") {
            orgId = userData.orgDetails[0]._id
        }
        this.setState({userData, orgId})
    }

    addPurchaseNote = async () => {
        if (this.state.name === "") {
            this.setState({nameError: true})
            toast.error("Please enter the customer name.")
            return
        } else {
            this.setState({nameError: false})
        }
        if (this.state.contactNo === "" || !util.checkPhone(this.state.contactNo)) {
            this.setState({contactNoError: true})
            toast.error("Please enter a valid phone no.")
            return
        } else {
            this.setState({contactNoError: false})
        }
        let rowsToBeAdded = []
        for (let l = 0; l < this.state.purchaseNoteData.length; l++) {
            (this.state.purchaseNoteData[l].note === "") ? this.state.purchaseNoteData[l].noteError = true : this.state.purchaseNoteData[l].noteError = false;
            (this.state.purchaseNoteData[l].quantity === "") ? this.state.purchaseNoteData[l].quantityError = true : this.state.purchaseNoteData[l].quantityError = false;
            if (this.state.purchaseNoteData[l].note !== "" || this.state.purchaseNoteData[l].quantity !== "") {
                rowsToBeAdded.push(this.state.purchaseNoteData[l])
            }
        }
        if (rowsToBeAdded.length === 0) {
            rowsToBeAdded.push({
                "note": "",
                "quantity": "",
                "priority": "LOW",
                "noteError": true,
                "quantityError": true
            })
        }
        await this.setState({purchaseNoteData: rowsToBeAdded})
        for (let item of this.state.purchaseNoteData) {
            if (item.noteError === true || item.quantityError === true) {
                return
            }
        }
        let payload = {
            orgId: this.state.orgId,
            purchaseNotes: this.state.purchaseNoteData,
            status: "CREATED",
            customerPhone: this.state.contactNo,
            customerDetails: {_id: "", name: this.state.name},
        }
        this.setState({progressStatus: true})
        let data = await OrderService.addPurchaseNote(payload, this.state.userData.sessionToken)
        this.setState({progressStatus: false})
        if (data) {
            toast.success("Purchase note added successfully.")
            this.setState({
                purchaseNoteData: [{
                    note: "",
                    quantity: "",
                    priority: "LOW",
                    noteError: false,
                    quantityError: false
                }]
                , name: "", nameError: false, contactNo: "", contactNoError: false
            })
        } else {
            toast.error("Something went wrong while adding purchase note.")
        }
    }
    handlePriority = index => e => {
        let purchaseNoteData = this.state.purchaseNoteData
        purchaseNoteData[index].priority = e[0]
        this.setState({purchaseNoteData})
    }
    deleteNote = (index) => {
        let purchaseNoteData = this.state.purchaseNoteData
        purchaseNoteData.splice(index, 1)
        this.setState({purchaseNoteData})
    }
    validatePhone = () => {
        (!util.checkPhone(this.state.contactNo)) ? this.setState({"contactNoError": true}) : this.setState({"contactNoError": false})
    }
    addRow = () => {
        let purchaseNoteData = this.state.purchaseNoteData
        purchaseNoteData.push({
            "note": "",
            "quantity": "",
            "priority": "LOW",
            "noteError": false,
            "quantityError": false
        })
        this.setState({purchaseNoteData})
    }
    handleNoteQnty = index => e => {
        if (e.target.name === "quantity" && !util.isInt(e.target.value) && e.target.value !== "") {
            return false;
        }
        let purchaseNoteData = this.state.purchaseNoteData
        purchaseNoteData[index][e.target.name] = e.target.value
        this.setState({purchaseNoteData})
    }

    handleNameContactNo = e => {
        if (e.target.name === "contactNo" && !util.isInt(e.target.value) && e.target.value !== "") {
            return false;
        }
        this.setState({[e.target.name]: e.target.value})
    }

    render() {
        return (
            <React.Fragment>
                {this.state.progressStatus === true &&
                <span className={'top-stick'}><MDBProgress material preloader color="cyan"/></span>
                }
                <MDBContainer className={"my-3"}>
                    <MDBCard>
                        <MDBCardHeader>
                            <div className={"float-left title"}>Add Purchase Note</div>
                        </MDBCardHeader>
                        <MDBCardBody>
                            <div className={"col-12 row m-0 p-0"}>
                                <div className={"col-md-6"}>
                                    <MDBInput autoComplete={'off'} style={{"marginTop": "0px"}}
                                              onBlur={() => {
                                                  this.state.name !== "" ? this.setState({nameError: false}) : this.setState({nameError: true})
                                              }} name="name"
                                              className={this.state.nameError ? "border-danger" : "mt-0 mb-0"}
                                              maxLength="100" type='text' label="Customer Name *"
                                              onChange={this.handleNameContactNo}
                                              value={this.state.name} outline={true}/>
                                </div>
                                <div className={"col-md-6"}>
                                    <MDBInput onBlur={() => this.validatePhone()}
                                              autoComplete={'off'} name="contactNo"
                                              className={this.state.contactNoError ? "border-danger" : ""}
                                              maxLength={orgConfig.PHONE_NUMBER_LENGTH} type='text'
                                              label="Customer Phone *"
                                              onChange={this.handleNameContactNo}
                                              value={this.state.contactNo} outline={true}/>
                                </div>
                            </div>
                            {this.state.purchaseNoteData.map((item, index) => (
                                <div className={"col-12 row m-0 p-0"} key={index}>
                                    <div className={"col-md-5"}>
                                        <MDBInput outline={true}
                                                  className={this.state.purchaseNoteData[index].noteError ? "border-danger" : ""}
                                                  maxLength="50" name="note"
                                                  type='text' label="Note *" onChange={this.handleNoteQnty(index)}
                                                  value={this.state.purchaseNoteData[index].note}/>
                                    </div>
                                    <div className={"col-md-3"}>
                                        <MDBInput outline={true}
                                                  className={this.state.purchaseNoteData[index].quantityError ? "border-danger" : ""}
                                                  maxLength="15" name="quantity"
                                                  type='text' label="Quantity *" onChange={this.handleNoteQnty(index)}
                                                  value={this.state.purchaseNoteData[index].quantity}/>
                                    </div>
                                    <div className={"col-md-3"}>
                                        <b>Request Priority</b>
                                        <MDBSelect  getValue={this.handlePriority(index)}
                                                   outline={true}>
                                            <MDBSelectInput selected="Choose Priority"/>
                                            <MDBSelectOptions>
                                                <MDBSelectOption value="LOW"
                                                                 selected={this.state.purchaseNoteData[index].priority === "LOW"}>Low</MDBSelectOption>
                                                <MDBSelectOption value="MEDIUM"
                                                                 selected={this.state.purchaseNoteData[index].priority === "MEDIUM"}>Medium</MDBSelectOption>
                                                <MDBSelectOption value="HIGH"
                                                                 selected={this.state.purchaseNoteData[index].priority === "HIGH"}>High</MDBSelectOption>
                                            </MDBSelectOptions>
                                        </MDBSelect>
                                    </div>
                                    {index !== 0 ?
                                        (<div className={"col-md-1 mx-0 mt-2 cursor-pointer d-flex align-items-center"}
                                              onClick={() => this.deleteNote(index)}><i className="fas fa-times-circle"></i></div>) :
                                        (<div className={'col p-0 m-0 text-right d-flex align-items-center'}>
                                            <MDBBtn outline size={'sm'} onClick={this.addRow}>
                                            Add
                                            </MDBBtn>
                                        </div>)
                                    }
                                </div>
                            ))}
                            <div className="m-0 text-center">
                                <MDBBtn outline  size="md"
                                        onClick={() => this.props.history.push('/view-purchase-notes')}
                                        className="mr-auto" disabled={this.state.progressStatus}>
                                    Back
                                </MDBBtn>
                                <MDBBtn size="md" onClick={this.addPurchaseNote}
                                        className="mr-auto"
                                        disabled={this.state.progressStatus}>
                                    Save
                                </MDBBtn>
                            </div>
                        </MDBCardBody>
                    </MDBCard>
                </MDBContainer>
            </React.Fragment>
        )
    }
}

export default withRouter(AddPurchaseNote);