import React, { Component } from "react";
import SimpleBackdrop from "../../components/common/overlay";

import { toast } from "react-toastify";
import PharmacyService from "../../services/pharmacyService";
import UserStore from "../../stores/userStore";
import {
  MDBFooter,
  MDBTable,
  MDBTableBody,
  MDBTableFoot,
  MDBTableHead,
} from "mdbreact";

export default class ViewSubstituteNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productList: [],
      totalPages: "",
      currentPage: "",
      userData: {},
      data1: {},
      paginatedProductDetails: [],
      pageSize: 10,
      page: 1,
      today: new Date(),
      length: 0,
      isLoading: false,
    };
  }

  async componentDidMount() {
    let userData = await UserStore.fetchSessionData();

    this.setState({ userData });

    // fetch all listing substitute-----
    await this.fetchAllSubstitute(this.state.page);
    // this.setState({ isLoading: true });
  }

  fetchAllSubstitute = async (pageNo) => {
    const listSubstitutes = await PharmacyService.fetchAllSubstituteProduct(
      pageNo,
      this.state.userData
    );

    if (listSubstitutes) {
      this.setState({
        productList: listSubstitutes.data,
        isLoading: false,
        totalPages: listSubstitutes.totalPages,
        currentPage: listSubstitutes.currentPage,
      });
    }
  };

  onPageChange = async (val) => {
    // console.log(val);
    this.setState({ page: val });
    await this.fetchAllSubstitute(val);
  };
  previousChange = async () => {
    await this.setState({ page: this.state.page - 1 });
    await this.fetchAllSubstitute(this.state.page - 1);
  };

  nextChange = async () => {
    await this.setState({ page: this.state.page + 1 });
    await this.fetchAllSubstitute(this.state.page + 1);
  };

  // delete each item from all list substitute product---
  deleteListSubstitute = async (id) => {
    let deleteSubstitute = await PharmacyService.deleteExistingSubstitute(
      id,
      this.state.userData.sessionToken
    );
    if (deleteSubstitute) {
      toast.success("Substitute deleted successfully");
      //  window.location.reload();
      await this.fetchAllSubstitute(this.state.page);
    } else {
      toast.error("Something went wrong !");
    }
  };

  // delete master product from all list of substitution---
  deleteListMaster = async (id) => {
    this.setState({ isLoading: true });
    let deleteSubstitute = await PharmacyService.deleteMaster(
      id,
      this.state.userData.sessionToken
    );
    this.setState({ isLoading: false });
    if (deleteSubstitute) {
      toast.success(" Deleted successfully");

      await this.fetchAllSubstitute(this.state.page);
    } else {
      toast.error("Something went wrong !");
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.state.isLoading === true && (
          <SimpleBackdrop status={this.state.isLoading} />
        )}

        <div className="p-5">
          <MDBTable className="table align-middle mb-0 bg-white">
            <MDBTableHead>
              <tr className="table-dark">
                <th className="p-3" style={{ width: "40%" }}>
                  Master Medicine
                </th>
                <th className="py-3" style={{ width: "40%" }}>
                  Substitute Medicine
                </th>

                <th className="py-3" style={{ width: "20%" }}>
                  Action
                </th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {this.state.productList.map((item) => {
                return (
                  <tr key={item._id}>
                    <td className="px-3 text-dark">
                      {item.masterMedicine.productName}
                    </td>
                    <td className="text-dark">
                      {item.substitudeMedicine.map((val) => {
                        return (
                          <div className="d-flex justify-content-between">
                            <ul key={val._id}>
                              <li className="mb-2 text-dark">
                                {val.productName}{" "}
                                <span
                                  className="blue-text btn-link cursor-pointer mx-2"
                                  onClick={() =>
                                    this.deleteListSubstitute(
                                      item.subscriptionId
                                    )
                                  }
                                >
                                  Delete
                                </span>
                              </li>
                            </ul>
                          </div>
                        );
                      })}
                    </td>

                    <td className="align-bottom">
                      <span
                        color="primary"
                        className={
                          "blue-text btn-link cursor-pointer font-weight-bold"
                        }
                        onClick={() => this.deleteListMaster(item._id)}
                      >
                        Delete
                      </span>
                    </td>
                  </tr>
                );
              })}
            </MDBTableBody>
          </MDBTable>
          <div className="d-flex justify-content-center mt-2 ">
            {this.state.productList.length > 0 && (
              <div>
                <nav aria-label="...">
                  <ul className="pagination d-flex justify-content-center">
                    <li
                      className={`page-item ${
                        this.state.page === 1 && "disabled"
                      }`}
                      onClick={() => this.previousChange()}
                    >
                      <a className="page-link">Previous</a>
                    </li>
                    {[...Array(this.state.totalPages).keys()].map(
                      (val, index) => {
                        return (
                          <li
                            key={index}
                            className={`page-item ${
                              this.state.page === index + 1 && "active"
                            }`}
                            onClick={() => this.onPageChange(index + 1)}
                          >
                            <a className="page-link" href="#">
                              {index + 1}
                            </a>
                          </li>
                        );
                      }
                    )}

                    <li
                      className={`page-item ${
                        this.state.page === this.state.totalPages && "disabled"
                      }`}
                      onClick={() => this.nextChange()}
                    >
                      <a className="page-link" href="#">
                        Next
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
