import React from "react";
import {
  MDBContainer,
  MDBRow,
  MDBInput,
  MDBBtn,
  MDBCardHeader,
  MDBFormInline,
  MDBCardBody,
  MDBCard,
  MDBCol,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOption,
  MDBSelectOptions,
} from "mdbreact";
import { toast } from "react-toastify";
import UserStore from "../../stores/userStore";
import PharmacyService from "../../services/pharmacyService";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import util from "../../utility/utility";
import OrderService from "../../services/orderService";
import SimpleBackdrop from "../common/overlay";
import { withRouter } from "react-router-dom";
import orgConfig from "../../orgConfig";
import AutocompleteUser from "../../newComponents/AutocompleteUser";
import AuthService from "../../services/authService";
import close_icon from '../../images/close.png'


class EditProductRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      smsFlag: false,
      isSmsSent: false,
      customerList: [],
      productRequestId: "",
      smsSent: "",
      name: "",
      nameError: false,
      orgId: 0,
      userData: {},
      productRequest: "",
      progressStatus: false,
      productRequestNameError: false,
      product: {},
      suggestedProduct: [],
      allSuggestedProduct: [],
      productName: "",
      productId: "",
      quantity: "",
      quantityError: false,
      productNameError: false,
      contactNo: "",
      email: "",
      priority: "HIGH",
      status: "",
      comments: "",
      apiResultCame: false,
      isDays: false,
      expectedDeliveryDays: null,
      expectedDeliveryHours: null,
      isRerender: false,
      statuses: [
        {
          text: "CREATED",
          value: "CREATED",
        },
        {
          text: "IN PROCESS",
          value: "IN PROCESS",
        },
        {
          text: "AVAILABLE",
          value: "AVAILABLE",
        },
        {
          text: "FULFILLED",
          value: "FULFILLED",
        },
      ],
      priorities: [
        {
          text: "LOW",
          value: "LOW",
        },
        {
          text: "MEDIUM",
          value: "MEDIUM",
        },
        {
          text: "HIGH",
          value: "HIGH",
        },
      ],
      deliveryDropdown: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      loadedOnPopup: false,
    };
  }
  handleDays = (value) => {
    this.setState({
      expectedDeliveryDays: null,
      expectedDeliveryHours: null,
      isRerender: !this.state.isRerender,
    });
    if (value == "Days") {
      this.setState({
        deliveryDropdown: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        isDays: true,
      });
    } else if (value == "Hours") {
      this.setState({
        deliveryDropdown: [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24,
        ],
        isDays: false,
      });
    }
  };
  handleTime = (value) => {
    if (this.state.isDays) {
      this.setState({
        expectedDeliveryDays: value,
        expectedDeliveryHours: null,
      });
    } else {
      this.setState({
        expectedDeliveryHours: value,
        expectedDeliveryDays: null,
      });
    }
  };

  async componentDidMount() {
    let userData = await UserStore.fetchSessionData();
    this.setState({ userData: userData });
    if (userData.type === "platformAdmin") {
      this.setState({ orgId: userData._id });
    } else if (userData.type === "ORG_ADMIN") {
      this.setState({ orgId: userData.orgDetails[0]._id });
    }
    if (this.props.loadedOnPopup === true) {
      let productRequestId = this.props.productRequestId;
      this.setState({
        productRequestId: productRequestId,
        loadedOnPopup: true,
      });
      this.fetchProductRequestById();
    } else {
      const { productRequestId } = this.props.queryString;
      this.setState({ productRequestId: productRequestId });
      this.fetchProductRequestById();
    }
  }

  fetchProductRequestById = async () => {
    this.setState({ progressStatus: true });
    let res = await OrderService.fetchProductRequestById(this.state);

    this.setState({ manufacturerApiResultCame: true });
    this.setState({ progressStatus: false });
    let sms = "N";
    if (res[0].hasOwnProperty("isSMSSent")) {
      if (res[0].isSMSSent === "Y") {
        sms = "Y";
        this.setState({
          isSmsSent: true,
          smsFlag: true,
        });
      } else {
        this.setState({
          isSmsSent: false,
          smsFlag: false,
        });
      }
    }
    if (res.length > 0) {
      try {
        this.setState({
          productId: res[0].productId,
          productName: res[0].productName,
          name: res[0].customerDetails.name,
          contactNo: res[0].customerPhone,
          quantity: res[0].quantity,
          priority: res[0].priority,
          status: res[0].status,
          smsSent: sms,
          oriStatus: res[0].status,
          isDays: res[0].isDays,
          expectedDeliveryDays: res[0]?.expectedDeliveryDays,
          expectedDeliveryHours: res[0]?.expectedDeliveryHours,
        });
        this.setState({ apiResultCame: true });
      } catch (e) {
        toast.error(
          "Something went wrong while fetching purchase request by id."
        );
      }
    }
  };

  handleChangeContactNo = (e) => {
    if (!util.isInt(e.target.value) && e.target.value !== "") {
      return false;
    }
    this.setState({ contactNo: e.target.value });
  };

  handleName = (e) => {
    this.setState({ name: e.target.value });
  };

  validateName = () => {
    if (!this.state.name || this.state.name.trim() === "") {
      this.setState({ nameError: true });
    } else {
      this.setState({ nameError: false });
    }
  };

  validatePhone = () => {
    if (!util.checkPhone(this.state.contactNo)) {
      this.setState({ contactNoError: false });
    } else {
      this.setState({ contactNoError: false });
    }
  };

  editProductRequest = async () => {
    if (this.state.smsFlag) {
      this.initSMS();
    }
    window.scrollTo(0, 0);
    if (!this.state.productName) {
      this.setState({ productNameError: true });
      return false;
    } else {
      this.setState({ productNameError: false });
    }

    if (!this.state.quantity) {
      this.setState({ quantityError: true });
      return false;
    } else {
      this.setState({ quantityError: false });
    }
    this.validateName();
    if (this.state.nameError) {
      return false;
    }
    this.setState({ progressStatus: true });
    let status = await OrderService.editProductRequest(this.state);
    this.setState({ progressStatus: false });
    if (status === false) {
      toast.error("Something went wrong.");
    } else {
      this.setState({ status: status.status, oriStatus: status.status });
      this.setState({ productRequest: "" });
      toast.success("Purchase Request updated successfully.");
      if (this.state.loadedOnPopup === true) {
        this.props.onEditComplete();
      }
    }
  };

  handleComments = (e) => {
    this.setState({ comments: e.target.value });
  };

  handleQuantity = (e) => {
    if (!util.isInt(e.target.value) && e.target.value !== "") {
      return false;
    }
    this.setState({ quantity: e.target.value });
  };

  handlePriority = (e) => {
    this.setState({ priority: e[0] });
  };

  handleStatus = (e) => {
    this.setState({ status: e[0] });
  };

  onChangeProduct = (event, values) => {
    if (values && values.hasOwnProperty("value") && values.value !== null) {
      this.setState({
        productId: values.value._id,
        productName: values.value.productName,
        productNameError: false,
        productIdError: false,
      });
    }
  };

  fetchProduct = async (e) => {
    try {
      if (e.target.value !== 0 || e.target.value !== "0") {
        let suggestedProduct = await PharmacyService.searchProduct(
          e.target.value,
          this.state
        );
        this.setState({ allSuggestedProduct: suggestedProduct });
        let temp = [];
        for (let item of suggestedProduct) {
          let str = { title: item.productName, value: item };
          temp.push(str);
        }
        if (temp.length > 0) {
          this.setState({ suggestedProduct: temp });
        } else {
          this.setState({ suggestedProduct: [{ title: "", value: "" }] });
        }
      }
    } catch (e) {
      this.setState({ suggestedProduct: [{ title: "", value: "" }] });
    }
  };

  statusProcessing = () => {
    let existingState = this.state.statuses;
    for (let i = 0; i < this.state.statuses.length; i++) {
      if (this.state.statuses[i].value === this.state.status) {
        existingState[i].checked = true;
      }
    }
    if (this.state.status == "AVAILABLE") {
    }
    return existingState;
  };

  priorityProcessing = () => {
    let existingState = this.state.priorities;
    for (let i = 0; i < this.state.priorities.length; i++) {
      if (this.state.priorities[i].value === this.state.priority) {
        existingState[i].checked = true;
      }
    }
    return existingState;
  };
  initSMS = async () => {
    let userData = await UserStore.fetchSessionData();
    this.setState({ progressStatus: true });
    let res = await PharmacyService.sendSMS(
      this.state.productRequestId,
      userData
    );
    this.setState({ progressStatus: false });
    if (res === false) {
      toast.error("We could not sent the SMS.");
    } else {
      toast.success("SMS delivered.");
      this.setState({ smsSent: "Y" });
    }
  };

  handleCustomerSearch = async (value) => {
    // this.setState({searchedCustomerText: e.target.value})
    if (value < 2) {
      return false;
    }

    let resUser = await AuthService.suggestCustomerNew(value, this.state.userData.sessionToken);
    console.log({ resUser })
    if (resUser?.length) {
      this.setState({
        customerList: resUser
      })
    }
    else {
      this.setState({
        customerList: []
      })
    }
    // let suggestedUser = [];
    // for (let item of resUser) {
    //     if ((item.hasOwnProperty("name") && item.name) || (item.hasOwnProperty("first_name") && item.first_name)) {
    //         suggestedUser.push(item)
    //     }
    // }
    // this.setState({allSuggestedUser: suggestedUser});
  }

  handleSelect = (val, item) => {
    if (item) {
      this.setState({ name: item?.name, contactNo: item?.contactNo, email: item?.email })
    }

  }

  resetInputValue = () => {
    this.setState({ name: "", contactNo: "", email: "" })
  }
  render() {
    return (
      <React.Fragment>
        <SimpleBackdrop status={this.state.progressStatus} />
        <MDBContainer>
          <MDBCard className={"mt-2"}>
            <MDBCardHeader>
              <span className={"float-left title"}>Edit Purchase Request</span>
            </MDBCardHeader>
            <MDBCardBody>
              <MDBRow>
                <MDBCol size={"4"} className={"mt-1"}>
                  <Autocomplete
                    freeSolo
                    onChange={this.onChangeProduct}
                    id="combo-box-demo"
                    options={this.state.suggestedProduct}
                    getOptionLabel={(option) => option.title}
                    onInputChange={(e) => {
                      this.fetchProduct(e);
                    }}
                    selectOnFocus={true}
                    renderInput={(params) => (
                      <TextField
                        style={
                          this.state.productNameError
                            ? { borderBottom: "1px solid RED" }
                            : {}
                        }
                        {...params}
                        placeholder="Product"
                        margin="normal"
                        fullWidth
                      />
                    )}
                  />
                  {this.state.hasOwnProperty("productName") && (
                    <MDBCard style={{ width: "100%" }}>
                      <MDBCardBody>
                        <MDBRow>
                          <MDBCol className={"float-left"}>
                            <div>Product Name: {this.state.productName}</div>
                          </MDBCol>
                        </MDBRow>
                      </MDBCardBody>
                    </MDBCard>
                  )}
                </MDBCol>
                <MDBCol className={"p-0 m-0"}>
                  <MDBInput
                    outline={true}
                    className={this.state.quantityError ? "border-danger" : ""}
                    maxLength="15"
                    type="text"
                    label="Quantity"
                    onChange={this.handleQuantity}
                    value={this.state.quantity}
                  />
                </MDBCol>

                <MDBCol>
                  <b>Priority</b>
                  {this.state.apiResultCame && (
                    <MDBSelect
                      outline={true}
                      getValue={this.handlePriority}
                      options={this.priorityProcessing()}
                      selected=""
                    />
                  )}
                </MDBCol>
                <MDBCol>
                  <b>Status</b>
                  {this.state.apiResultCame && (
                    <MDBSelect
                      outline={true}
                      getValue={this.handleStatus}
                      options={this.statusProcessing()}
                    />
                  )}
                </MDBCol>
              </MDBRow>
              {this.state.name === "POSITRA DRUG STORE" ? (
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      autoComplete={"off"}
                      style={{ marginTop: "0px" }}
                      onBlur={this.validateName}
                      className={
                        this.state.nameError ? "border-danger" : "mt-0 mb-0"
                      }
                      maxLength="100"
                      type="text"
                      label="Customer Name *"
                      onChange={this.handleName}
                      value={this.state.name}
                    />
                  </MDBCol>
                  <MDBCol>
                    <MDBInput
                      onBlur={() => this.validatePhone()}
                      autoComplete={"off"}
                      className={
                        this.state.contactNoError ? "border-danger" : ""
                      }
                      maxLength={orgConfig.PHONE_NUMBER_LENGTH}
                      type="text"
                      label="Customer Phone"
                      onChange={this.handleChangeContactNo}
                      value={this.state.contactNo}
                    />
                  </MDBCol>
                </MDBRow>
              ) : (
                <MDBRow className="my-4">
                  <MDBCol size="3">
                    <AutocompleteUser
                      label="Enter Customer Name or phone"
                      name="customer"
                      options={this.state.customerList}
                      onSelect={(val, item) => this.handleSelect(val, item)}
                      resetInputValue={this.resetInputValue}
                      onChange={(val) => {
                        this.handleCustomerSearch(val);
                      }}

                    />
                  </MDBCol>
                  <MDBCol size="2" className="offset-1">
                    {this.state.name && (
                      <div className="customerDropdown">
                        <span>
                          Name : {this.state?.name}
                        </span>
                        <br />
                        <span>
                          Phone :
                          {this.state?.contactNo
                            ? this.state?.contactNo
                            : "Not Available"}
                        </span>
                        <br />
                        <span>
                          Email :
                          {this.state?.email
                            ? this.state?.email
                            : "Not Available"}
                        </span>
                      </div>
                    )}
                  </MDBCol>
                </MDBRow>
              )}

              {(this.state.isDays === true || this.state.isDays === false) && (
                <div className="d-flex justify-content-start">
                  <div>
                    <div
                      class="btn-group"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button
                        onClick={() => this.handleDays("Days")}
                        type="button"
                        class={this.state.isDays ? "btn btn-primary" : "btn"}
                      >
                        Days
                      </button>
                      <button
                        onClick={() => this.handleDays("Hours")}
                        type="button"
                        class={!this.state.isDays ? "btn btn-primary" : "btn"}
                      >
                        Hours
                      </button>
                    </div>
                  </div>
                  <div style={{ width: "100px" }}>
                    <MDBSelect
                      getValue={(val) => this.handleTime(val[0])}
                      key={this.state.isRerender}
                    >
                      <MDBSelectInput
                        selected={
                          this.state.isDays
                            ? this.state.expectedDeliveryDays
                              ? this.state.expectedDeliveryDays + " days"
                              : "Select Time"
                            : this.state.expectedDeliveryHours
                              ? this.state.expectedDeliveryHours + " hours"
                              : "Select Time"
                        }
                      />
                      <MDBSelectOptions>
                        {this.state.deliveryDropdown.map((item) => (
                          <MDBSelectOption value={item}>
                            {item} {this.state.isDays ? "Days" : "Hours"}
                          </MDBSelectOption>
                        ))}
                      </MDBSelectOptions>
                    </MDBSelect>
                  </div>
                </div>
              )}

              {this.state.status === "AVAILABLE" && (
                <div className="d-flex justify-content-center m-4">
                  <MDBFormInline>
                    <MDBInput
                      label="Send SMS"
                      type="checkbox"
                      id="checkbox1"
                      checked={this.state.smsFlag}
                      onChange={() =>
                        this.setState({
                          smsFlag: !this.state.smsFlag,
                        })
                      }
                      disabled={this.state.isSmsSent}
                      containerClass="mr-1"
                    />
                  </MDBFormInline>
                </div>
              )}
              <div className="text-center col-12 m-0">
                {this.state.loadedOnPopup === false && (
                  <MDBBtn
                    outline
                    size="md"
                    onClick={() =>
                      this.props.history.push("/view-purchase-requests")
                    }
                    className="mr-auto"
                    disabled={this.state.progressStatus}
                  >
                    Back
                  </MDBBtn>
                )}
                <MDBBtn
                  outline
                  size="md"
                  onClick={this.editProductRequest}
                  className="mr-auto"
                  disabled={this.state.progressStatus}
                >
                  Save
                </MDBBtn>
                {/* {this.state.smsSent === 'N' && this.state.oriStatus === "AVAILABLE" &&
                                    <MDBBtn outline size="md" onClick={this.initSMS}
                                        className="mr-auto"
                                        disabled={this.state.progressStatus}>
                                        Send SMS
                                    </MDBBtn>
                                } */}
                {this.state.smsSent === "Y" && (
                  <MDBBtn outline size="md" className="mr-auto" disabled={true}>
                    SMS Already Sent
                  </MDBBtn>
                )}
              </div>
            </MDBCardBody>
          </MDBCard>
        </MDBContainer>
      </React.Fragment>
    );
  }
}
export default withRouter(EditProductRequest);
