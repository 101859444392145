const dataTableCustomer= {
    customer:[
        {
            label: 'Customer',
            field: 'name',
            sort: 'asc',
            width: 300
        },
        {
            label: 'Email',
            field: 'email',
            sort: 'asc',
            width: 300
        },
        {
            label: 'Phone',
            field: 'contactNo',
            sort: 'asc',
            width: 100
        },
        {
            label: 'Customer Since',
            field: 'customerSince',
            sort:'disabled',
            width: 50
        },
        {
            label: '',
            field: 'handleEdit',
            sort:'disabled',
            width: 50
        },
        {
            label: '',
            field: 'handleStatus',
            sort:'disabled',
            width: 50
        }
    ]
}
export default dataTableCustomer;