import orgConfig from "../../orgConfig";

const dataTableShipment= {
    inventory:[
         {
             label: 'Date Added',
             field: 'handleAddedDate',
             sort: 'asc',
             // width: 300
        },
        {
            label: 'Invoice Number',
            field: 'handleInvoiceNumber',
            sort: 'asc',
            // width: 300
        },
        {
            label: 'Batch Number',
            field: 'batch',
            sort: 'asc',
            // width: 300
        },
        {
            label: 'Product Name',
            field: 'handleProductName',
            sort: 'asc',
            // width: 300
        },
        {
            label: 'Supplier Name',
            field: 'supplierName',
            sort: 'asc',
            // width: 300
        },
        {
            label: 'MFG/EXP',
            field: 'handleMfgExp',
            sort: 'asc',
            // width: 100
        },
        {
            label: 'MRP('+orgConfig.CURRENCY_SYMBOL+")",
            field: 'handleMrp',
            sort: 'asc',
            // width: 100
        },
        {
            label: 'Purchase Price('+orgConfig.CURRENCY_SYMBOL+")",
            field: 'handlePurchasePrice',
            sort: 'asc',
            // width: 100
        },
        {
            label: 'Discount',
            field: 'handleDiscount',
            sort: 'asc',
            // width: 100
        },

        {
            label: 'Purchase Quantity',
            field: 'quantity',
            sort: 'asc',
            // width: 100
        },
        {
            label: 'Stock',
            field: 'totalQuantity',
            sort: 'asc',
            // width: 100
        },

        // {
        //     label: 'Manufacturer',
        //     field: 'handleManufacturer',
        //     sort: 'asc',
        //     width: 100
        // },

        {
            label: 'Actions',
            field: 'handleActions',
            sort: 'asc',
            // width: 100
        },


    ],

    paymentLog:[
        {
            label: 'Payment Date',
            field: 'paymentDate',
            sort: 'asc',
            width: 300
        },
        {
            label: 'Paid Amount',
            field: 'paidAmount',
            sort: 'asc',
            width: 300
        },
        {
            label: 'Payment Mode',
            field: 'paymentMode',
            sort: 'asc',
            width: 300
        },
        {
            label: 'Payment Reference',
            field: 'paymentReference',
            sort: 'asc',
            width: 300
        },
        {
            label: 'Entered By',
            field: 'enteredBy',
            sort: 'asc',
            width: 300
        },
        {
            label: 'Entered On',
            field: 'enteredOn',
            sort: 'asc',
            width: 100
        },



    ]
}
export default dataTableShipment;
