import React from "react";
import AddTaskCmp from "../../components/task-manager/addTask";
import {MDBBreadcrumb, MDBBreadcrumbItem} from "mdbreact";
import {Link} from "react-router-dom";
class AddTask extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem><Link to={'/task-list'}>Task List</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Add Task</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <AddTaskCmp/>
            </React.Fragment>
        )
    }
}
export default AddTask;