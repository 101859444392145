import React from "react";
import {
  MDBBox,
  MDBRow,
  MDBInput,
  MDBCol,
  MDBBtn,
  MDBSpinner,
  MDBTableHead,
  MDBTable,
  MDBTableBody,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBBreadcrumbItem,
  MDBBreadcrumb,
} from "mdbreact";
import PharmacyService from "../../../services/pharmacyService";
import UserStore from "../../../stores/userStore";
import { toast } from "react-toastify";
import Moments from "moment";
import ViewBarCode from "../../../components/inventory/v3/viewBarCode";
import ViewStorageInfo from "../../../components/inventory/v3/viewStorageInfo";
import ViewReview from "../../../components/inventory/v3/viewReview";
import ViewOrders from "../../../components/inventory/v3/viewOrders";
import EditInventory from "../../../components/inventory/v3/editInventory";
import { Link } from "react-router-dom";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));
export default class SearchProductV3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      searchRadio: 1,
      barCode: "",
      productData: [],
      barCodeModal: false,
      selectedBarCode: "0",
      storageModal: false,
      selectedId: "",
      userData: [],
      location: null,
      stockDetails: [],
      reviewModal: false,
      orderModal: false,
      orders: null,
      batch: "",
      invoiceId: "",
      term: "",
      editInvModal: false,
      stockId: null,
    };
  }

  async componentDidMount() {
    let userData = await UserStore.fetchSessionData();
    this.setState({ userData });
    document.getElementById("barCode").focus();
  }

  onClick = (radioVal) => {
    this.setState({ searchRadio: radioVal });
  };
  searchByBarCode = async () => {
    await delay(500);
    if (this.state.barCode !== "" && this.state.isLoading === false) {
      this.setState({ isLoading: true });
      let data = await PharmacyService.fetchProductByBarCode(
        this.state.userData,
        this.state.barCode
      );
      this.setState({ isLoading: false });
      if (data === false) {
        toast.error(
          "We are facing some issue. Please contact your administrator."
        );
      } else {
        this.setState({ productData: data });
      }
    }
  };
  searchByBatch = async () => {
    if (this.state.batch !== "") {
      this.setState({ isLoading: true });
      let data = await PharmacyService.fetchProductByBatch(
        this.state.userData.sessionToken,
        this.state.batch
      );
      this.setState({ isLoading: false });
      if (data === false) {
        toast.error(
          "Something went wrong while fetching the data by batch no."
        );
      } else {
        this.setState({ productData: data });
      }
    }
  };
  searchByInvoiceId = async () => {
    if (this.state.invoiceId !== "") {
      this.setState({ isLoading: true });
      let data = await PharmacyService.fetchProductByInvoiceId(
        this.state.userData.sessionToken,
        this.state.invoiceId
      );
      this.setState({ isLoading: false });
      if (data === false) {
        toast.error(
          "Something went wrong while fetching the data by invoice id."
        );
      } else {
        this.setState({ productData: data });
      }
    }
  };

  searchByTerm = async () => {
    if (this.state.term !== "") {
      this.setState({ isLoading: true });
      let data = await PharmacyService.fetchProductByTerm(
        this.state.userData.sessionToken,
        this.state.term
      );
      this.setState({ isLoading: false });
      if (data === false) {
        toast.error(
          "Something went wrong while fetching the data by product name."
        );
      } else {
        this.setState({ productData: data });
      }
    }
  };

  reviewStatus = (item) => {
    let reviewStatus = "";
    if (item.hasOwnProperty("revieweDetails")) {
      reviewStatus =
        item.revieweDetails.isApproved === "Y" ? "Accepted" : "Rejected";
      return (
        <span onClick={() => this.toggleReview(item)}>{reviewStatus}</span>
      );
    } else {
      return <span onClick={() => this.toggleReview(item)}>Review</span>;
    }
  };
  toggleBarcode = (item) => {
    this.setState({ barCodeModal: false });
    if (item !== null) {
      this.setState({ selectedBarCode: item.barCode });
      this.setState({ barCodeModal: true });
    } else {
      this.setState({ barCodeModal: false });
    }
  };
  toggleStorage = async (stockId) => {
    if (stockId || stockId !== null) {
      this.setState({ storageModal: true });
      let location = await PharmacyService.fetchStoreLocById(
        this.state.userData,
        stockId
      );
      if (location) {
        this.setState({ location });
      } else {
        toast.error(
          "Storage info might not been configured for this product. Please contact your system administrator."
        );
        this.setState({ storageModal: false });
      }
    } else {
      this.setState({ storageModal: false });
    }
  };
  toggleReview = (stockDetails) => {
    if (stockDetails === null) {
      this.setState({ reviewModal: false });
    } else {
      this.setState({ stockDetails, reviewModal: true });
    }
  };
  toggleOrder = async (stockId) => {
    if (stockId !== null) {
      this.setState({ orderModal: true });
      let orders = await PharmacyService.fetchOrdersByStockId(
        this.state.userData,
        stockId
      );
      if (orders === false) {
        toast.error(
          "Currently we are facing some issue. Please contact your administrator."
        );
      } else {
        this.setState({ orders });
      }
    } else {
      this.setState({ orderModal: false });
    }
  };
  refreshPage = async () => {
    if (this.state.searchRadio === 1 && this.state.barCode !== "") {
      await this.searchByBarCode();
    } else if (this.state.searchRadio === 2 && this.state.batch !== "") {
      await this.searchByBatch();
    } else if (this.state.searchRadio === 3 && this.state.invoiceId !== "") {
      await this.searchByInvoiceId();
    } else if (this.state.searchRadio === 4 && this.state.term !== "") {
      await this.searchByTerm();
    }
  };
  handleEnter = async (e) => {
    if (e.keyCode === 13) {
      await this.refreshPage();
    }
  };
  toggleEditInvModal = async (stockId, action) => {
    if (stockId !== null) {
      this.setState({ stockId, editInvModal: true });
    } else {
      this.setState({ editInvModal: false });
      if (action === "saved") {
        await this.refreshPage();
      }
    }
  };
  render() {
    return (
      <React.Fragment>
        <MDBBreadcrumb>
          <MDBBreadcrumbItem>
            <Link to={"/dashboard"}>Dashboard</Link>
          </MDBBreadcrumbItem>
          <MDBBreadcrumbItem active>Search Stock</MDBBreadcrumbItem>
        </MDBBreadcrumb>
        {this.state.isLoading === true && (
          <div className={"PanelFloat"}>
            <MDBSpinner />
          </div>
        )}
        <MDBBox className={"min-vh-100 mx-5 mb-5"}>
          <MDBRow>
            <MDBInput
              onClick={() => {
                this.onClick(1);
              }}
              checked={this.state.searchRadio === 1 ? true : false}
              label="Search by barcode"
              type="radio"
              id="radio1"
            />
            <MDBInput
              onClick={() => {
                this.onClick(2);
              }}
              checked={this.state.searchRadio === 2 ? true : false}
              label="Search by batch no"
              type="radio"
              id="radio2"
            />
            <MDBInput
              onClick={() => {
                this.onClick(3);
              }}
              checked={this.state.searchRadio === 3 ? true : false}
              label="Search by invoice no"
              type="radio"
              id="radio3"
            />
            <MDBInput
              onClick={() => {
                this.onClick(4);
              }}
              checked={this.state.searchRadio === 4 ? true : false}
              label="Search by product name"
              type="radio"
              id="radio4"
            />
          </MDBRow>
          <MDBRow>
            {this.state.searchRadio === 1 && (
              <MDBCol className={"m-2"}>
                <MDBRow>
                  <MDBCol size={"3"}>
                    <MDBInput
                      label={"Scan barcode"}
                      value={this.state.barCode}
                      getValue={(e) => {
                        this.setState({ barCode: e });
                      }}
                      onChange={() => {
                        this.searchByBarCode();
                      }}
                      id={"barCode"}
                    />
                  </MDBCol>
                  <MDBCol className={"d-flex align-items-center"}>
                    <MDBBtn
                      size={"md"}
                      onClick={() => {
                        this.searchByBarCode();
                      }}
                      disabled={this.state.isLoading}
                    >
                      Submit
                    </MDBBtn>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            )}
            {this.state.searchRadio === 2 && (
              <MDBCol className={"m-2"}>
                <MDBRow>
                  <MDBCol size={"3"}>
                    <MDBInput
                      label={"Batch No"}
                      value={this.state.batch}
                      getValue={(e) => {
                        this.setState({ batch: e });
                      }}
                      onKeyDown={this.handleEnter}
                    />
                  </MDBCol>
                  <MDBCol className={"d-flex align-items-center"}>
                    <MDBBtn
                      size={"md"}
                      onClick={() => {
                        this.searchByBatch();
                      }}
                      disabled={this.state.isLoading}
                    >
                      Submit
                    </MDBBtn>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            )}
            {this.state.searchRadio === 3 && (
              <MDBCol className={"m-2"}>
                <MDBRow>
                  <MDBCol size={"3"}>
                    <MDBInput
                      label={"Invoice No"}
                      value={this.state.invoiceId}
                      getValue={(e) => {
                        this.setState({ invoiceId: e });
                      }}
                      onKeyDown={this.handleEnter}
                    />
                  </MDBCol>
                  <MDBCol className={"d-flex align-items-center"}>
                    <MDBBtn
                      size={"md"}
                      onClick={() => {
                        this.searchByInvoiceId();
                      }}
                      disabled={this.state.isLoading}
                    >
                      Submit
                    </MDBBtn>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            )}
            {this.state.searchRadio === 4 && (
              <MDBCol className={"m-2"}>
                <MDBRow>
                  <MDBCol size={"3"}>
                    <MDBInput
                      label={"Product Name"}
                      value={this.state.term}
                      getValue={(e) => {
                        this.setState({ term: e });
                      }}
                      onKeyDown={this.handleEnter}
                    />
                  </MDBCol>
                  <MDBCol className={"d-flex align-items-center"}>
                    <MDBBtn
                      size={"md"}
                      onClick={() => {
                        this.searchByTerm();
                      }}
                      disabled={this.state.isLoading}
                    >
                      Submit
                    </MDBBtn>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            )}
          </MDBRow>
          <MDBBox>
            {this.state.productData.length > 0 && (
              <MDBTable>
                <MDBTableHead>
                  <tr>
                    <th>Date</th>
                    <th>Invoice</th>
                    <th>Batch</th>
                    <th>Product</th>
                    <th>Supplier</th>
                    <th>Mfg</th>
                    <th>Exp</th>
                    <th>Mrp (₹)</th>
                    <th>Price</th>
                    <th>Disc</th>
                    <th>Quantity</th>
                    <th>Stock</th>
                    <th>Status</th>
                    <th>Entered By</th>
                    <th>Barcode</th>
                    <th>Storage</th>
                    <th>Reviewed By</th>
                    <th></th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody search="true">
                  {this.state.productData.map((item, index) => (
                    <tr key={index} className={""}>
                      <td>{Moments(item.createdAt).format("MMM DD, YYYY")}</td>
                      <td>{item.shipmentDetail.invoiceNumber}</td>
                      <td>{item.batch}</td>
                      <td>{item.productDetails.productName}</td>
                      <td>{item.supplierDetail.supplierName}</td>
                      <td>{Moments(item.mfgDate).format("MMM DD, YYYY")}</td>
                      <td>{Moments(item.expDate).format("MMM DD, YYYY")}</td>
                      <td>{item.mrp.toFixed(2)}</td>
                      <td>{item.priceWgst.toFixed(2)}</td>
                      <td>{item.discount.toFixed(2)}</td>
                      <td>
                        Pkg qnty- {item.quantityIn} (
                        {item.productDetails.retailPackage})<br />
                        Loose qnty- {item.looseQuantityIn} (
                        {item.productDetails.quantityUnit})
                      </td>
                      <td>
                        Pkg qnty- {item.quantity.toFixed(2)} (
                        {item.productDetails.retailPackage})<br />
                        Loose qnty- {item.looseQuantity} (
                        {item.productDetails.quantityUnit})
                      </td>
                      <td>
                        {Moments(item.expDate).isSameOrBefore(Moments())
                          ? "Expired"
                          : "Active"}
                      </td>
                      <td>{item.createdBy.name}</td>
                      <td>
                        <span
                          onClick={() => {
                            this.toggleBarcode(item);
                          }}
                          className={"cursor-pointer text-primary"}
                        >
                          View
                        </span>
                      </td>
                      <td>
                        <span
                          onClick={() => {
                            this.toggleStorage(item._id);
                          }}
                          className={"cursor-pointer text-primary"}
                        >
                          View
                        </span>
                      </td>
                      <td className={"cursor-pointer text-primary"}>
                        {this.reviewStatus(item)}
                      </td>
                      <td>
                        <div className={"cursor-pointer text-success"}>
                          <span
                            onClick={() => this.toggleEditInvModal(item._id)}
                          >
                            Edit
                          </span>
                        </div>
                        <div className={"cursor-pointer text-secondary"}>
                          <span onClick={() => this.toggleOrder(item._id)}>
                            Orders
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </MDBTableBody>
              </MDBTable>
            )}
            {this.state.productData.length === 0 &&
              this.state.isLoading === false &&
              (this.state.barCode !== "" ||
                this.state.batch !== "" ||
                this.state.invoiceId !== "" ||
                this.state.term !== "") && (
                <div className={"no-data-container"}>No stock found.</div>
              )}
          </MDBBox>
        </MDBBox>
        <ViewBarCode
          barCodeModal={this.state.barCodeModal}
          toggleBarcode={this.toggleBarcode}
          selectedBarCode={this.state.selectedBarCode}
        />
        <ViewStorageInfo
          storageModal={this.state.storageModal}
          location={this.state.location}
          toggleStorage={this.toggleStorage}
        />
        <ViewReview
          stockDetails={this.state.stockDetails}
          reviewModal={this.state.reviewModal}
          toggleReview={this.toggleReview}
          refreshPage={this.refreshPage}
        />
        <ViewOrders
          orderModal={this.state.orderModal}
          orders={this.state.orders}
          toggleOrder={this.toggleOrder}
        />
        <MDBModal
          isOpen={this.state.editInvModal}
          toggle={() => this.toggleEditInvModal(null)}
          size="fluid"
        >
          <MDBModalHeader toggle={() => this.toggleEditInvModal(null)}>
            Edit Stock
          </MDBModalHeader>
          <MDBModalBody>
            <EditInventory
              loadedOnPopup={true}
              stockId={this.state.stockId}
              toggleEditInvModal={this.toggleEditInvModal}
            />
          </MDBModalBody>
        </MDBModal>
      </React.Fragment>
    );
  }
}
