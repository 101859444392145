import React from "react";
import {MDBBreadcrumb, MDBBreadcrumbItem} from "mdbreact";
import {Link} from "react-router-dom";
import EditStockCmp from "../../components/inventory/v3/editStock";
export default class EditStock extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Edit Stock</MDBBreadcrumbItem>
                </MDBBreadcrumb>
               <EditStockCmp/>
            </React.Fragment>
        );
    }
}