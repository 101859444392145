import React,{Suspense} from "react";
import {
    MDBBox, MDBBreadcrumb, MDBBreadcrumbItem
} from 'mdbreact';
//const AddInventory=React.lazy(() => import("../../components/inventory/addInventory"));
import AddInventory from "../../components/inventory/addInventory";
import {Link} from "react-router-dom";
import Add from "../../components/inventory/add";

export default class AddInventoryPage extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem><Link to={'/view-stock'}>View Stock</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Add Stock</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <MDBBox className={"min-vh-100"}>
                    <Suspense fallback={null}>
                        {/* <AddInventory></AddInventory> */}
                        <Add></Add>
                    </Suspense>
                </MDBBox>
            </React.Fragment>
        );
    }
}
