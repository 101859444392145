import React, { useState } from 'react';
import {
    MDBModal,
    MDBModalHeader,
    MDBModalBody,
    MDBModalFooter,
    MDBBtn,
    MDBBox,
    MDBInput,
} from 'mdbreact';

const ResetComponent = ({ isResetOpen, setResetOpen ,reviewObject, resetImplement }) => {
    const [stockQuantity, setStockQuantity] = useState(1);
    const [validate, setValidate] = useState(false);

    const toggleReview = () => {
        setResetOpen(false);
    };

    const acceptReview = () => {
        if(stockQuantity<1){
            setValidate(true)
            setTimeout(()=>{
                setValidate(false)
            },3000)
        }
      else  resetImplement(reviewObject.id,stockQuantity)
    };


    return (
        <MDBModal isOpen={isResetOpen} toggle={toggleReview} size="lg">
            <MDBModalHeader toggle={toggleReview}>Reset Stock</MDBModalHeader>
            <MDBModalBody>
                <>
                    <MDBBox>Do you want to reset stock?</MDBBox>
                    <MDBBox>
                        <br />
                        <br />
                        <MDBInput
                            value={stockQuantity}
                            type="number"
                            label="Reset Stock"
                            onChange={(e) => setStockQuantity(e.target.value)}
                        />
                    </MDBBox>
                   {validate &&  <div style={{display:"block", padding:"1em 0" ,color:"red"}}>Quantity should be atleast 1</div>}
                </>
            </MDBModalBody>
            <MDBModalFooter>
                <MDBBtn outline size="md" onClick={() => setResetOpen(false) && setStockQuantity('')}>
                    Close
                </MDBBtn>
                <MDBBtn size="md" onClick={ acceptReview}>
                    Reset
                </MDBBtn>
            </MDBModalFooter>
        </MDBModal>
    );
};

export default ResetComponent;
