import React from "react";
import {withRouter} from "react-router-dom";
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCol,
    MDBContainer,
    MDBFormInline, MDBIcon,
    MDBInput,
    MDBProgress, MDBRow
} from "mdbreact";
import OrderService from "../../services/orderService";
import {toast} from "react-toastify";
import UserStore from "../../stores/userStore";

class AddFloor extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            locId: "",
            progressStatus: false,
            storageLocDetails: null,
            userData: {},
            floors: [{floorName: "", floorNameError: false}]
        }
    }
    async componentDidMount() {
        this.setState({progressStatus: true})
        let userData = await UserStore.fetchSessionData()
        const {locId} = this.props.match.params
        this.setState({locId, userData})

        let storageLocDetails = await OrderService.fetchStorageLocationById(userData.sessionToken, locId)
        if (storageLocDetails) {
            this.setState({storageLocDetails: storageLocDetails[0]})
        } else {
            toast.error("Something went wrong while fetching the storage location details.")
        }
        this.setState({progressStatus: false})
    }
    addFloors = async () => {
        let filteredFloors = []
        for (let item of this.state.floors) {
            if (item.floorName.trim() !== "") {
                filteredFloors.push(item)
            }
        }
        if (filteredFloors.length === 0) {
            filteredFloors.push(
                {floorName: "", floorNameError: true}
            )
            this.setState({floors: filteredFloors})
            toast.error("Please enter at least one floor name.")
            return
        }
        this.setState({floors: filteredFloors})
        let payLoad = {
            locId: this.state.locId,
            floors: filteredFloors
        }
        this.setState({progressStatus: true})
        let res = await OrderService.addFloors(this.state.userData.sessionToken, payLoad)
        if (res) {
            toast.success("Floors added successfully to the storage location named: " + this.state.storageLocDetails.name)
            this.props.history.goBack()
        } else {
            toast.error("Something went wrong, while adding the floors to the storage location named: " + this.state.storageLocDetails.name)
        }
        this.setState({progressStatus: false})
    }
    validateFloorName = (index) => {
        let floors = this.state.floors
        if (floors[index].floorName === "") {
            floors[index].floorNameError = true
        } else {
            floors[index].floorNameError = false
        }
        this.setState({floors})
    }
    addFloor = () => {
        let floors = this.state.floors
        floors.push({floorName: "", floorNameError: false})
        this.setState({floors})
    }
    deleteFloor = (index) => {
        let floors = this.state.floors
        floors.splice(index, 1)
        this.setState({floors})
    }
    handleFloorName = index => e => {
        let floors = this.state.floors
        floors[index].floorName = e.target.value
        this.setState({floors})
    }
    render() {
        return(
            <React.Fragment>
                <MDBContainer className={'w-50 mw-50'}>
                    <MDBCard className={"mt-2"}>
                        <MDBCardHeader>
                            <span className={"float-left"}>Add Floors</span>
                            <MDBFormInline className="float-right md-form mr-auto m-0">
                                <MDBBtn outline color="blue-grey" size="sm"
                                        onClick={()=>this.props.history.goBack()}
                                        className="mr-auto"
                                        disabled={this.state.progressStatus}>
                                    Back
                                </MDBBtn>
                                <MDBBtn outline color="blue-grey" size="sm" onClick={this.addFloors}
                                        className="mr-auto"
                                        disabled={this.state.progressStatus}>
                                    Save
                                </MDBBtn>
                            </MDBFormInline>
                        </MDBCardHeader>
                        {this.state.progressStatus === true &&
                        <span className={'top-stick'}><MDBProgress material preloader color="cyan"/></span>
                        }
                        <MDBCardBody>
                            {this.state.storageLocDetails && <>
                            <MDBRow className={"my-1 p-3"}>
                                <MDBCol className={"mt-n4"}>
                                    <MDBInput id={"name"} label="Storage Location Name" disabled value={this.state.storageLocDetails.name} maxLength={"40"}/>
                                </MDBCol>
                            </MDBRow>

                            {this.state.floors.map((item, index) => (
                                <MDBRow className={"p-3"}>
                                    <MDBCol size={"10"} className={"mt-n4"}>
                                        <MDBInput id={"floorName"} label={"Floor Name "+(index+1)+" *"} value={this.state.floors[index].floorName} onChange={this.handleFloorName(index)}
                                                  className={this.state.floors[index].floorNameError ? "border-danger" : ""} maxLength={"40"} onBlur={()=>this.validateFloorName(index)}/>
                                    </MDBCol>
                                    <MDBCol size={"2"}>
                                        {index === 0 ?
                                            (
                                                <MDBIcon size={"2x"} icon="plus-circle" onClick={this.addFloor}/>
                                            ) : (
                                                <MDBIcon icon="trash" onClick={()=>this.deleteFloor(index)}/>
                                            )}
                                    </MDBCol>
                                </MDBRow>
                            ))}
                            </>}
                        </MDBCardBody>
                    </MDBCard>
                </MDBContainer>
            </React.Fragment>
        )
    }
}
export default withRouter(AddFloor);