import React, {Suspense} from 'react';
import {
    MDBBox,
    MDBRow,
    MDBBreadcrumbItem,
    MDBBtn,
    MDBCol,
    MDBInput,
    MDBTable,
    MDBTableHead,
    MDBTableBody, MDBModal, MDBModalHeader, MDBModalBody, MDBSpinner
} from "mdbreact";
import Moments from "moment";
import PharmacyService from "../../services/pharmacyService";
import {toast} from "react-toastify";
import UserStore from "../../stores/userStore";
import EditProductNew from "./editProductNew";
import AuditLog from "./auditLog";
import InventoryService from "../../services/inventoryService";
import utility from "../../utility/utility";
const delay = ms => new Promise(res => setTimeout(res, ms));
class SearchProduct extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchRadio: 1,
            barCode: "",
            invoiceId: "",
            productId: "",
            term: "",
            productData: [],
            isLoading: false,
            modalEdit: false, modalAuditLog: false,
            activeProduct: null,
        }
    }
    async componentDidMount() {
        let userData = await UserStore.fetchSessionData();
        this.setState({userData})
        document.getElementById("barCode").focus();
    }
    onClick = (radioVal) => {
        this.setState({searchRadio: radioVal});
    }
    searchByBarCode = async () => {
        await delay(500);
        if (this.state.barCode !== "" && this.state.isLoading===false) {
            this.setState({isLoading: true});
            let data = await PharmacyService.fetchProductByBarCodeProducts(this.state.userData.sessionToken, this.state.barCode);
            this.setState({isLoading: false});
            if (data === false) {
                toast.error("Something went wrong while fetching the data by barCode number.");
            } else {
                this.setState({productData: data});
            }
        }
    }
    searchByTerm = async () => {
        if (this.state.term !== "") {
            this.setState({isLoading: true});
            let data = await PharmacyService.fetchProductByName(this.state.userData.sessionToken, this.state.term);
            this.setState({isLoading: false});
            if (data === false) {
                toast.error("Something went wrong while fetching the data by product name.");
            } else {
                this.setState({productData: data});
            }
        }
    }
    searchByProductId = async () => {
        if (this.state.productId !== "") {
            this.setState({isLoading: true});
            let data = await InventoryService.fetchProductById(this.state.userData, this.state.productId)
            this.setState({isLoading: false});
            if (data === false) {
                this.setState({productData: []});
            } else {
                let newData = {productDetails: data, _id: data._id}
                let productData = []
                productData.push(newData)
                this.setState({productData});
            }
        }
    }
    handleEnter = async (e) => {
        if (e.keyCode === 13) {
            await this.refreshPage()
        }
    }
    refreshPage = async () => {
        if (this.state.searchRadio === 1 && this.state.barCode !== "") {
            await this.searchByBarCode()
        } else if (this.state.searchRadio === 2 && this.state.productId !== "") {
            await this.searchByProductId()
        } else if (this.state.searchRadio === 4 && this.state.term !== "") {
            await this.searchByTerm()
        }
    }
    openModal = (action, item) => {
        this.setState({["modal" + action]: true, activeProduct: item})
    }
    toggle = (section) => {
        this.setState({["modal" + section]: !this.state["modal" + section]})
    }
    onEditComplete = async () => {
        this.toggle("Edit")
        await this.refreshPage();
    }
    render() {
        return (
            <React.Fragment>
                {(this.state.isLoading === true) &&
                <div className={"PanelFloat"}>
                    <MDBSpinner/>
                </div>
                }
                <MDBBox className={"min-vh-100 mx-5 mb-5"}>
                    <MDBRow>
                        <MDBInput onClick={() => {
                            this.onClick(1)
                        }} checked={this.state.searchRadio === 1 ? true : false} label="Search by barcode"
                                  type="radio" id="radio1"/>
                        <MDBInput onClick={() => {
                            this.onClick(2)
                        }} checked={this.state.searchRadio === 2 ? true : false} label="Search by product Id"
                                  type="radio" id="radio2"/>
                        <MDBInput onClick={() => {
                            this.onClick(4)
                        }} checked={this.state.searchRadio === 4 ? true : false} label="Search by product name"
                                  type="radio" id="radio4"/>
                    </MDBRow>
                    <MDBRow>
                        {this.state.searchRadio === 1 &&
                        <MDBCol className={"m-2"}>
                            <MDBRow >
                                <div className={'col-md-3'}>
                                    <MDBInput label={"Scan barcode"} value={this.state.barCode} getValue={(e) => {
                                        this.setState({barCode: e})
                                    }} onChange={()=>{this.searchByBarCode()}} id={"barCode"}/>
                                </div>
                                <MDBCol className={'d-flex align-items-center'}>
                                    <MDBBtn size={'md'} onClick={() => {
                                        this.searchByBarCode()
                                    }} disabled={this.state.isLoading}>Submit</MDBBtn>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        }
                        {this.state.searchRadio === 2 &&
                        <MDBCol className={"m-2"}>
                            <MDBRow>
                                <div className={'col-md-3'}>
                                    <MDBInput label={"Product Id"} value={this.state.productId} getValue={(e) => {
                                        this.setState({productId: e})
                                    }} onKeyDown={this.handleEnter}/>
                                </div>
                                <MDBCol className={'d-flex align-items-center'}>
                                    <MDBBtn size={'md'} onClick={() => {
                                        this.searchByProductId()
                                    }} disabled={this.state.isLoading}>Submit</MDBBtn>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        }
                        {this.state.searchRadio === 4 &&
                        <MDBCol className={"m-2"}>
                            <MDBRow>
                                <div className={'col-md-3'}>
                                    <MDBInput label={"Product Name"} value={this.state.term} getValue={(e) => {
                                        this.setState({term: e})
                                    }} onKeyDown={this.handleEnter}/>
                                </div>
                                <MDBCol className={'d-flex align-items-center'}>
                                    <MDBBtn size={'md'} onClick={() => {
                                        this.searchByTerm()
                                    }} disabled={this.state.isLoading}>Submit</MDBBtn>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        }
                    </MDBRow>
                    <MDBBox>
                        {(this.state.productData.length > 0) &&
                        <MDBTable>
                            <MDBTableHead>
                                <tr>
                                    <th>Product Name</th>
                                    <th>Retail Package</th>
                                    <th>Package Quantity</th>
                                    <th>Packaging Unit</th>
                                    <th>Loose</th>
                                    <th>Loose Sale Qty</th>
                                    <th>Medicine Type</th>
                                    <th>Created On</th>
                                    <th>Audit Log</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody search={true}>
                                {this.state.productData.map((item, index) => (
                                    <tr key={index}>
                                        <td className={"table-no-gap"}>{item.productDetails.productName}</td>
                                        <td className={"table-no-gap"}>{item.productDetails.retailPackage}</td>
                                        <td className={"table-no-gap"}>{item.productDetails.contentQuantity}</td>
                                        <td className={"table-no-gap"}>{item.productDetails.quantityUnit}</td>
                                        <td className={"table-no-gap"}>{item.productDetails.looseSaleAllowed}</td>
                                        <td className={"table-no-gap"}>{item.productDetails.minimumLooseSaleQuantity}</td>
                                        <td className={"table-no-gap"}>{utility.getMedicineType(item)}</td>
                                        <td className={"table-no-gap"}>{Moments(item.createdAt).format("MMM D, YYYY hh:mm A")}</td>
                                        <td><span color="primary"
                                                  className={"blue-text btn-link cursor-pointer font-weight-normal"} onClick={()=>this.openModal("AuditLog", item)}>Audit Log</span></td>
                                        <td>
                                            <span color="primary"
                                                  className={"blue-text btn-link cursor-pointer font-weight-normal"}
                                                  onClick={() => {
                                                      this.openModal("Edit", item)
                                                  }}>&nbsp;Edit
                                            </span>
                                        </td>
                                        <td></td>
                                    </tr>
                                ))}
                            </MDBTableBody>
                        </MDBTable>
                        }
                        {(this.state.productData.length === 0 && this.state.isLoading === false && (this.state.barCode !== '' ||
                            this.state.batch !== '' || this.state.invoiceId !== '' || this.state.term !== '')) &&
                        <MDBRow className={"text-center"}><MDBCol> No product found.</MDBCol></MDBRow>
                        }
                    </MDBBox>
                </MDBBox>
                <MDBModal isOpen={this.state.modalEdit} toggle={() => this.toggle("Edit")} size={"fluid"}>
                    <MDBModalHeader toggle={() => this.toggle("Edit")}></MDBModalHeader>
                    <MDBModalBody className={"float-center"}>
                        {(this.state.activeProduct || this.state.activeProduct !== null) &&
                        <EditProductNew loadedOnPopup={true} onEditComplete={this.onEditComplete}
                                        productId={this.state.activeProduct.productDetails._id} close={()=>this.toggle("Edit")}/>
                        }
                    </MDBModalBody>
                </MDBModal>
                <MDBModal isOpen={this.state.modalAuditLog} toggle={() => this.toggle("AuditLog")} size={"lg"}>
                    <MDBModalHeader toggle={() => this.toggle("AuditLog")}>Audit Logs</MDBModalHeader>
                    <MDBModalBody className={"float-center"}>
                        {(this.state.activeProduct || this.state.activeProduct !== null) &&
                        <AuditLog productId={this.state.activeProduct._id}/>
                        }
                    </MDBModalBody>
                </MDBModal>
            </React.Fragment>
        )
    }
}
export default SearchProduct;