import React from "react";
import { withRouter } from "react-router-dom";
import {
    MDBContainer,
    MDBCardHeader,
    MDBFormInline,
    MDBBtn,
    MDBCardBody,
    MDBCard,
    MDBModalHeader,
    MDBModalBody,
    MDBModal,
    MDBModalFooter,
    MDBDataTable
} from 'mdbreact';
import UserStore from "../../stores/userStore";
import {toast} from "react-toastify";
import SimpleBackdrop from "../common/overlay";
import ThirdPartyService from "../../services/thirdPartyService";
import Moments from "moment";
import MaterialTable from "material-table";
import utility from "../../utility/utility";
import OrderService from "../../services/orderService";

class ShippingPerformance extends React.Component {
    async componentDidMount() {
        await this.fetchData();
    }
    fetchData=async ()=>{
        await this.setState({disableRetreatAfterSorting:true});
        window.scrollTo(0, 0);
        let userData = await UserStore.fetchSessionData();
        let data=[];
        if(!window.shippingPerformnace || window.shippingPerformnace===null) {
            this.setState({progressStatus: true});
            data = await ThirdPartyService.fetchShippingPerformance(userData);
            console.log(data)
            this.setState({data})
            window.shippingPerformnace=data;
        }else{
            data=window.shippingPerformnace;
        }
        this.setState({progressStatus: false});
        if(data===false){
            toast.error("We are facing some issue. Please try again later.");
        }else{
            this.setState({data: data, apiResultCame:true});
            let dataTablePayload={
                columns: [
                    {
                        label: 'Order Code',
                        field: 'orderCode',
                        sort: 'asc',
                        width: 300
                    },
                    {
                        label: 'State',
                        field: 'state',
                        sort: 'asc',
                        width: 300
                    },
                    {
                        label: 'City',
                        field: 'city',
                        sort: 'asc',
                        width: 100
                    },
                    {
                        label: 'Days taken from Order',
                        field: 'daysFromOrderDate',
                        sort:'asc',
                        width: 50
                    },
                    {
                        label: 'Days taken from pickup',
                        field:'daysFromPickupDate',
                        sort:'asc',
                        width: 50
                    },
                    {
                        label: 'Carrier',
                        field:'carrierName',
                        sort:'asc',
                        width: 50
                    },
                    {
                        label: 'Status',
                        field:'status',
                        sort:'asc',
                        width: 50
                    }


                ],
                rows:data
            };
            this.setState({dataTablePayload:dataTablePayload});
        }
        this.syncData();
        await this.setState({disableRetreatAfterSorting:false});
    }
    syncData=async()=>{
        await this.setState({disableRetreatAfterSorting:true});
        let userData = await UserStore.fetchSessionData();
        let data=[];
        data = await ThirdPartyService.fetchShippingPerformance(userData);
        // window.qntyUnits=data;
        if(data===false){
            toast.error("We are facing some issue. Please try again later.");
        }else{
            this.setState({data: data, apiResultCame:true});
            let dataTablePayload={
                columns: [
                    {
                        label: 'Order Code',
                        field: 'orderCode',
                        sort: 'asc',
                        width: 300
                    },
                    {
                        label: 'State',
                        field: 'state',
                        sort: 'asc',
                        width: 300
                    },
                    {
                        label: 'City',
                        field: 'city',
                        sort: 'asc',
                        width: 100
                    },
                    {
                        label: 'order Date',
                        field: 'orderDate',
                        sort: 'asc',
                        width: 100
                    },
                    {
                        label: 'Delivery Date',
                        field: 'deliveryDate',
                        sort: 'asc',
                        width: 100
                    },
                    {
                        label: 'Days taken from Order',
                        field: 'daysFromOrderDate',
                        sort:'asc',
                        width: 50
                    },
                    {
                        label: 'Days taken from pickup',
                        field:'daysFromPickupDate',
                        sort:'asc',
                        width: 50
                    },
                    {
                        label: 'Carrier',
                        field:'carrierName',
                        sort:'asc',
                        width: 50
                    },
                    {
                        label: 'Aggregator',
                        field:'aggregator',
                        sort:'asc',
                        width: 50
                    },
                    {
                        label: 'Status',
                        field:'status',
                        sort:'asc',
                        width: 50
                    },
                    {
                        title: 'Google Review SMS',
                        field: '',
                        render: rowData => this.sendGoogleMessageRender(rowData)
                    }

                ],
                rows:data
            };
            this.setState({dataTablePayload:dataTablePayload});
        }
        await this.setState({disableRetreatAfterSorting:false});
    }
    constructor(props) {
        super(props);
        this.state = {
            progressStatus:false,
            type:"all",
            page:1,
            pageSize:10,
            data: {},
            disableRetreatAfterSorting:false
        }
    }


    sendGoogleSMS = async (rowData) => {
        let phone = rowData.customer_phone
        let res = await OrderService.sendGoogleReviewSMS(phone, "")
        console.log(res)
        if(res && res.hasOwnProperty("code")){
            if(res.code == "6920"){
                toast.success("We sent him a google review message previously.")
            } else if(res.code == "6921"){
                toast.success("Google business review SMS sent. ")
            }
            let data = this.state.data
            for(let i =0; i<data.length; i++){
                if(rowData._id == data[i]._id){
                    data[i].isSMSSent = "Y"
                    data[i].smsSentAt = (new Date())
                    break;
                }
            }
            await this.setState({data})
        }

    }

    sendGoogleMessageRender = (rowData) => {
        if(rowData.hasOwnProperty("isSMSSent") && rowData.isSMSSent == "Y"){
            return <span className={"text-primary green-text"}
            >SMS Sent on {utility.convertToReadbleDateTimeUtc( rowData.smsSentAt )} </span>
        } else {
            return <a className={"text-primary"}
                      onClick={() => this.sendGoogleSMS(rowData)}>Send SMS</a>
        }
    }

    showCharts = () => {

    }

    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.progressStatus}/>
                <MDBContainer className={'w-100 mw-100'}>
                    <MDBCard className={"mt-2"}>
                        {/*<MDBCardHeader>*/}
                        {/*    <span className={"float-left title"}>Shipping Performance</span>*/}
                        {/*    <MDBFormInline className="float-right md-form mr-auto m-0">*/}
                        {/*        <MDBBtn color="default" size="sm" onClick={this.showCharts} className="mr-auto" >*/}
                        {/*            View Charts*/}
                        {/*        </MDBBtn>*/}
                        {/*    </MDBFormInline>*/}
                        {/*</MDBCardHeader>*/}
                        <MDBCardBody>
                            {/*{(this.state.apiResultCame && this.state.data.length < 1) &&*/}
                            {/*<div className={"no-data-container"}>No Quantity Unit found.</div>*/}
                            {/*}*/}
                            {/*{this.state.data.length > 0 &&*/}

                            {/*<MDBDataTable*/}
                            {/*    striped*/}

                            {/*    data={this.state.dataTablePayload}*/}
                            {/*    materialSearch*/}
                            {/*    noBottomColumns*/}
                            {/*    hover*/}
                            {/*    disableRetreatAfterSorting={this.state.disableRetreatAfterSorting}*/}
                            {/*/>*/}
                            {/*}*/}

                            {this.state.data.length > 0 &&
                            <MaterialTable title="Shipping Performance"
                                           columns= {[
                                                {
                                                title: 'Order Code',
                                                field: 'orderCode',
                                                sort: 'asc',

                                                },
                                               {
                                                   title: 'Pin',
                                                   field: 'pincode',
                                                   sort: 'asc',

                                               },
                                               {
                                                   title: 'City',
                                                   field: 'city',
                                                   sort: 'asc',

                                               },
                                                {
                                                title: 'State',
                                                field: 'state',
                                                sort: 'asc',

                                                },


                                                {
                                                title: 'Order Date',
                                                field: 'orderDate',
                                                sort: 'asc',

                                                    render: rowData => utility.convertToReadbleDateTime(rowData.orderDate),
                                                },
                                               {
                                                   title: 'Pickup Date',
                                                   field: 'pickDate',
                                                   sort: 'asc',

                                                   render: rowData => utility.convertToReadbleDate(rowData.pickupDate),
                                               },
                                                {
                                                title: 'Delivery Date',
                                                field: 'deliveryDate',
                                                render: rowData => utility.convertToReadbleDateTime(rowData.deliveryDate),
                                                sort: 'asc',

                                                },

                                                {
                                                title: 'Days Taken From Order',
                                                field: 'daysFromOrderDate',
                                                sort:'asc',

                                                },
                                                {
                                                title: 'Days Taken From Pickup',
                                                field:'daysFromPickupDate',
                                                sort:'asc',

                                                },
                                                {
                                                title: 'Carrier',
                                                field:'carrierName',
                                                sort:'asc',

                                                },
                                                {
                                                title: 'Aggregator',
                                                field:'aggregator',
                                                sort:'asc',

                                                },
                                               {
                                                   title: 'Google Review SMS',
                                                   field: '',
                                                   render: rowData => this.sendGoogleMessageRender(rowData)
                                               }

                                            ]}

                                           data={this.state.data}
                                           options={{
                                               showTitle: true,
                                               pageSize: 100,
                                               pageSizeOptions: [100, 200, 400, 1000, 1500, 2000],
                                               emptyRowsWhenPaging: false,
                                           }}
                            />
                            }

                        </MDBCardBody>
                    </MDBCard>

                </MDBContainer>
            </React.Fragment>
        );
    }
}
export default withRouter(ShippingPerformance)
