import React from "react";
import {MDBBox, MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBInput, MDBRow, MDBSpinner} from "mdbreact";
import PharmacyService from "../../services/pharmacyService";
import config from "../../config";
import UserStore from "../../stores/userStore";
import {toast} from "react-toastify";
import CmsService from "../../services/cmsService";
import SimpleBackdrop from "../common/overlay";

class AddBanner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: {},
            bannerName: "", bannerText: "", bannerLink: "",
            docs: "", docsTab: "", docsMob: "",
            mrp: "", discount: "",
            webBannerSpinner: false, tabBannerSpinner: false, mobileBannerSpinner: false,
            isLoading: false,
            activeBanner: this.props.banner !== null ? this.props.banner : null
        }
    }

    async componentDidMount() {
        let userData = await UserStore.fetchSessionData();
        this.setState({userData});
        if (this.state.activeBanner !== null) {
            this.setState({
                bannerName: this.state.activeBanner.bannerName,
                bannerText: this.state.activeBanner.bannerText,
                bannerLink: this.state.activeBanner.bannerLink,
                docs: this.state.activeBanner.image,
                docsTab: this.state.activeBanner.imageTab,
                docsMob: this.state.activeBanner.imageMob,
                mrp: this.state.activeBanner.mrp,
                discount: this.state.activeBanner.discount
            })
        }
    }
    getWebBanner = async (event) => {
        const data = new FormData()
        data.append('fileName', event.target.files[0]);
        this.setState({webBannerSpinner: true});
        let fileUpload = await PharmacyService.uploadDoc(data, this.state.userData);
        if (fileUpload) {
            this.setState({webBannerSpinner: false, docs: fileUpload.path});
        }
    }
    getTabBanner = async (event) => {
        const data = new FormData()
        data.append('fileName', event.target.files[0]);
        this.setState({tabBannerSpinner: true});
        let fileUpload = await PharmacyService.uploadDoc(data, this.state.userData);
        if (fileUpload) {
            this.setState({tabBannerSpinner: false, docsTab: fileUpload.path});
        }
    }
    getMobBanner = async (event) => {
        const data = new FormData()
        data.append('fileName', event.target.files[0]);
        this.setState({mobileBannerSpinner: true});
        let fileUpload = await PharmacyService.uploadDoc(data, this.state.userData);
        if (fileUpload) {
            this.setState({mobileBannerSpinner: false, docsMob: fileUpload.path});
        }
    }
    save = async () => {
        if (this.state.bannerLink === "") {
            toast.error("Please enter a valid banner link.");
            return false;
        }
        if (this.state.mrp === "") {
            this.setState({mrp: 0});
        }
        if (this.state.discount === "") {
            this.setState({discount: 0});
        }
        if (this.state.docs === "") {
            toast.error("Please upload an image for web.");
            return false;
        }
        if (this.state.docsTab === "") {
            toast.error("Please upload an image for tab.");
            return false;
        }
        if (this.state.docsMob === "") {
            toast.error("Please upload an image for mobile.");
            return false;
        }
        this.setState({isLoading: true})
        let status = false
        if (this.state.activeBanner !== null) {
            let payload = {
                _id: this.state.activeBanner._id,
                bannerName: this.state.bannerName,
                bannerText: this.state.bannerText,
                bannerLink: this.state.bannerLink,
                image: this.state.docs,
                mrp: this.state.mrp,
                discount: this.state.discount,
                imageTab: this.state.docsTab,
                imageMob: this.state.docsMob
            }
            status = await CmsService.saveBanner(this.state.userData, payload, "EDIT");
        } else {
            let payload = {
                bannerName: this.state.bannerName,
                bannerText: this.state.bannerText,
                bannerLink: this.state.bannerLink,
                image: this.state.docs,
                mrp: this.state.mrp,
                discount: this.state.discount,
                imageTab: this.state.docsTab,
                imageMob: this.state.docsMob
            }
            status = await CmsService.saveBanner(this.state.userData, payload, "ADD");
        }
        this.setState({isLoading: false})
        if (status === false) {
            toast.error("We are facing some server issue. Please try again later.");
        } else {
            toast.success("Banner configured successfully.");
            this.props.toggle()
            await this.props.refreshPage()
        }
    }

    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.isLoading}/>
                <div>

                        {/*<div className={'main-title p-2'}></div>*/}
                        <div className={'m-2'}>
                            <div className={'row col-12 mx-0 m-2 p-0'}>
                                <div className={'col-3 img-outlet text-center p-3 mx-2'}>
                                    {/*<button type="button" className="close pr-2" aria-label="Close">*/}
                                    {/*    <span aria-hidden="true" onClick={this.toggle}>×</span>*/}
                                    {/*</button>*/}
                                    {this.state.webBannerSpinner === true &&
                                    <MDBSpinner small/>
                                    }
                                    <div  className={'banner-type'}>Website</div>
                                    {(this.state.docs !== "" && this.state.webBannerSpinner === false) &&
                                    <img className={'my-4'} src={config.IMAGE_HOST + this.state.docs} width="300" height="70"/>
                                    }
                                    {(this.state.docs === "" && this.state.webBannerSpinner === false) &&
                                    <img className={'my-4'} src={require('../common/img/add_image.svg')}/>
                                    }
                                    <div className={'text-center'}>
                                    <MDBInput className={'px-2'} type={"file"} onChange={this.getWebBanner}></MDBInput>
                                    </div>
                                </div>
                                <div className={'col-3 img-outlet text-center p-3 mx-2'}>
                                    {/*<button type="button" className="close pr-2" aria-label="Close">*/}
                                    {/*    <span aria-hidden="true" onClick={this.toggle}>×</span>*/}
                                    {/*</button>*/}
                                    {this.state.tabBannerSpinner === true &&
                                    <MDBSpinner small/>
                                    }
                                    <div  className={'banner-type'}>Tablet</div>
                                    {(this.state.docsTab !== "" && this.state.tabBannerSpinner === false) &&
                                    <img className={'my-4'} src={config.IMAGE_HOST + this.state.docsTab} width={250} height={60}/>
                                    }
                                    {(this.state.docsTab === "" && this.state.tabBannerSpinner === false) &&
                                    <img className={'my-4'} src={require('../common/img/add_image.svg')}/>
                                    }
                                    <MDBInput className={'px-2'} type={"file"} onChange={this.getTabBanner}></MDBInput>
                                </div>
                                <div className={'col-3 img-outlet text-center p-3 mx-2'}>
                                    {/*<button type="button" className="close pr-2" aria-label="Close">*/}
                                    {/*    <span aria-hidden="true" onClick={this.toggle}>×</span>*/}
                                    {/*</button>*/}
                                    {this.state.mobileBannerSpinner === true &&
                                    <MDBSpinner small/>
                                    }
                                    <div  className={'banner-type'}>Mobile</div>
                                    {(this.state.docsMob !== "" && this.state.mobileBannerSpinner === false) &&
                                    <img className={'my-4'} src={config.IMAGE_HOST + this.state.docsMob} width={200} height={60}/>
                                    }
                                    {(this.state.docsMob === "" && this.state.mobileBannerSpinner === false) &&
                                    <img className={'my-4'} src={require('../common/img/add_image.svg')}/>
                                    }
                                    <MDBInput className={'px-2'} type={"file"} onChange={this.getMobBanner}></MDBInput>
                                </div>
                            </div>
                            <MDBRow>
                                <MDBCol>
                                    <MDBInput label={"Banner name"} getValue={(e) => {
                                        this.setState({"bannerName": e})
                                    }} value={this.state.bannerName}></MDBInput>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBInput label={"Banner text"} getValue={(e) => {
                                        this.setState({bannerText: e})
                                    }} value={this.state.bannerText}></MDBInput>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBInput label={"Banner link"} getValue={(e) => {
                                        this.setState({bannerLink: e})
                                    }} value={this.state.bannerLink}></MDBInput>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBInput label={"MRP"} getValue={(e) => {
                                        this.setState({mrp: e})
                                    }} value={this.state.mrp}></MDBInput>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBInput label={"Discount in percentage"} getValue={(e) => {
                                        this.setState({discount: e})
                                    }} value={this.state.discount}></MDBInput>
                                </MDBCol>
                            </MDBRow>
                        </div>
                        <div className={'text-center'}>
                            <MDBBtn size={'md'} outline onClick={this.props.toggle}>Cancel</MDBBtn>
                            <MDBBtn size={'md'} onClick={this.save}
                                    disabled={(this.state.webBannerSpinner === true || this.state.tabBannerSpinner === true || this.state.mobileBannerSpinner === true) ? true : false}>Save</MDBBtn>
                        </div>

                </div>
            </React.Fragment>
        );
    }
}

export default AddBanner;