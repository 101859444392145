import React from "react";
import { MDBContainer, MDBRow, MDBInput, MDBBtn, MDBProgress, MDBCardHeader, MDBFormInline, MDBCardBody, MDBCard, MDBCol, MDBIcon, MDBSelect, MDBSelectInput, MDBSelectOptions, MDBSelectOption } from 'mdbreact';
import { toast } from "react-toastify";
import UserStore from "../../stores/userStore";
import PharmacyService from "../../services/pharmacyService";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import util from "../../utility/utility";
import OrderService from "../../services/orderService";
import { withRouter } from "react-router-dom";
import orgConfig from "../../orgConfig";
import AutocompleteUser from "../../newComponents/AutocompleteUser";
import AuthService from "../../services/authService";
import AutocompleteMeds from "../../newComponents/AutocompleteMeds";
import TableProductRequest from "../../newComponents/productSuggestTable";

class AddProductRequest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            resetKey: 0,
            orgId: 0,
            userData: {},
            productRequest: '',
            progressStatus: false,
            progressStatusFileUpload: false,
            productRequestNameError: false,
            product: {},
            suggestedProduct: [],
            allSuggestedProduct: [],
            name: "",
            nameError: false,
            customer: "", // Default
            productRequestData: [],
            contactNo: "",
            priority: "HIGH",
            status: "CREATED",
            comments: "",
            priorities: "LOW",
            //            priorities:[ {
            //                text: "LOW",
            //                value: "LOW"
            //            }, {
            //                text: "MEDIUM",
            //                value: "MEDIUM",
            //
            //            } , {
            //                text: "HIGH",
            //                value: "HIGH",
            //            }],
            expectedDeliveryHours: null,
            expectedDeliveryDays: null,
            isDays: true,
            deliveryDropdown: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            clearAutocompMed: false,
            currentProductIndex: 0,
            quantityVal: 5,
            selectedProdAuto: "",
            isEditable: false,

            customerList: [],
            selectedCustomerInfo: {},
            customerToSend: {
                _id: "",
                name: ""
            }

        }
    }
    handleDays = (value) => {
        if (value == "Days") {
            this.setState(({
                deliveryDropdown: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                isDays: true
            }))
        }
        else if (value == "Hours") {
            this.setState(({
                deliveryDropdown: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
                isDays: false
            }))
        }

    }
    handleTime = (value) => {
        if (this.state.isDays) {
            this.setState(({
                expectedDeliveryDays: value,
                expectedDeliveryHours: null
            }))
        }
        else {
            this.setState(({
                expectedDeliveryHours: value,
                expectedDeliveryDays: null
            }))
        }
    }
    handleCustomerSearch = async (value) => {
        // this.setState({searchedCustomerText: e.target.value})
        if (value < 2) {
            return false;
        }

        let resUser = await AuthService.suggestCustomerNew(value, this.state.userData.sessionToken);
        if (resUser?.length) {
            this.setState({
                customerList: resUser
            })
        }
        else {
            this.setState({
                customerList: []
            })
        }
    }
    handleSelect = (val, item) => {
        if (item) {
            this.setState({ selectedCustomerInfo: item, customerToSend: item })
        }
        else {
            this.setState({ selectedCustomerInfo: {} })
        }
    }

    async componentDidMount() {
        let userData = await UserStore.fetchSessionData();
        this.setState({ userData: userData })
        if (userData.type === "platformAdmin") {
            this.setState({ orgId: userData._id })
        } else if (userData.type === "ORG_ADMIN") {
            this.setState({ orgId: userData.orgDetails[0]._id })
        }
    }

    handleChangeContactNo = (e) => {
        if (!util.isInt(e.target.value) && e.target.value !== "") {
            return false;
        }
        this.setState({ contactNo: e.target.value })
    };

    handleName = (e) => {
        this.setState({ name: e.target.value })
    };

    deleteRow = (index) => {
        let existingData = this.state.productRequestData;
        existingData.splice(index, 1);
        this.setState({ productRequestData: existingData })
    }

    addRow = () => {
        this.setState({
            isEditable: false,
            clearAutocompMed: true,
            currentProductIndex: this.state.currentProductIndex + 1,
            quantityVal: 5
        })
    }

    validateName = () => {
        // console.log("HIII",this.state.name)
        // if (!this.state.name || this.state.name.trim() === "") {
        //     this.setState({ nameError: true })
        // } else {
        //     this.setState({ nameError: false })
        // }
    }

    validatePhone = () => {
        if (!util.checkPhone(this.state.contactNo)) {
            this.setState({ "contactNoError": false })
        } else {
            this.setState({ "contactNoError": false })
        }
    }

    clearState = async () => {
        this.setState({
            resetKey: this.state.resetKey + 1,
            orgId: 0,
            productRequest: '',
            progressStatus: false,
            progressStatusFileUpload: false,
            productRequestNameError: false,
            product: {},
            suggestedProduct: [],
            allSuggestedProduct: [],
            name: "",
            nameError: false,
            customer: "", // Default
            productRequestData: [],
            contactNo: "",
            priority: "HIGH",
            status: "CREATED",
            comments: "",
            priorities: "LOW",
            clearAutocompMed:true,
            quantityVal:5,
            //            priorities:[ {
            //                text: "LOW",
            //                value: "LOW"
            //            }, {
            //                text: "MEDIUM",
            //                value: "MEDIUM",
            //
            //            } , {
            //                text: "HIGH",
            //                value: "HIGH",
            //            }],
            expectedDeliveryHours: null,
            expectedDeliveryDays: null,
            isDays: true,
            deliveryDropdown: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],


            customerList: [],
            selectedCustomerInfo: {},
            customerToSend: {
                _id: "",
                name: ""
            }

        })
        let userData = await UserStore.fetchSessionData();
        this.setState({ userData: userData })
        if (userData.type === "platformAdmin") {
            this.setState({ orgId: userData._id })
        } else if (userData.type === "ORG_ADMIN") {
            this.setState({ orgId: userData.orgDetails[0]._id })
        }
    }

    addProductRequest = async () => {
        window.scrollTo(0, 0)
        for (let i = 0; i < this.state.productRequestData.length; i++) {
            if (!this.state.productRequestData[i].productName && !this.state.productRequestData[i].quantity) {
                // This is just empty row and delete it before validation
                this.deleteRow(i)
            }
        }
        if (this.state.productRequestData.length < 1) {
            toast.error("Please enter at least one product details.")
            this.addRow()
            return false;
        }
        if(this.state.customerToSend.name !== "POSITRA DRUG STORE" && !this.state.customerToSend.contactNo) {
            toast.error("Please select a valid customer")
            return
        }
        // this.validateName();
        // if (this.state.nameError) {
        //     console.log("Hellow 1")
        //     return false;

        // }

        for (let index = 0; index < this.state.productRequestData.length; index++) {
            if (!this.state.productRequestData[index].productName) {
                let existingData = this.state.productRequestData;
                existingData[index].productNameError = true;
                this.setState({ productRequestData: existingData })
                return false;
            } else {
                let existingData = this.state.productRequestData;
                existingData[index].productNameError = false;
                this.setState({ productRequestData: existingData })
            }
            if (!this.state.productRequestData[index].quantity) {
                let existingData = this.state.productRequestData;
                existingData[index].quantityError = true;
                this.setState({ productRequestData: existingData })
                return false;
            } else {
                let existingData = this.state.productRequestData;
                existingData[index].quantityError = false;
                this.setState({ productRequestData: existingData })
            }
        }
        this.setState({ progressStatus: true });
        const payload = {
            orgId: this.state.orgId,
            expectedDeliveryHours: this.state.expectedDeliveryHours,
            expectedDeliveryDays: this.state.expectedDeliveryDays,
            isDays: this.state.isDays,
            productData: this.state.productRequestData,
            priority: this.state.priority,
            status: this.state.status,
            customerDetails: this.state.customerToSend,
            customerPhone: this.state.contactNo,
            comments: this.state.comments,
        }
        if (!this.state.expectedDeliveryDays && !this.state.expectedDeliveryHours) {
            toast.error("Please select expected delivery time");
            this.setState({ progressStatus: false });
            return;
        }
        let status = await OrderService.addProductRequest(payload, this.state.userData.sessionToken);
        this.setState({ progressStatus: false });
        if (status === false) {
            toast.error("Something went wrong.");
        } else {
            this.setState({ productRequest: "" });
            toast.success("Purchase Request added successfully.");
            // for (let i = 0; i < this.state.productRequestData.length; i++) {
            //     this.deleteRow(i)
            // }
            // this.addRow();
            this.clearState()
        }
    }

    handleComments = (e) => {
        this.setState({ comments: e.target.value })
    };

    handleQuantity = (index) => (e) => {
        if (!util.isInt(e.target.value) && e.target.value !== "") {
            return false;
        }
        let existingData = this.state.productRequestData;
        if (existingData?.[this.state.currentProductIndex]?.totalAmount) {
            let amount =
            existingData[this.state.currentProductIndex].totalAmount = (e.target.value * existingData[this.state.currentProductIndex]?.purchasePriceAfterDiscount)?.toFixed(2) ?? 0
            existingData[index].quantity = e.target.value
            this.setState({ productRequestData: existingData, quantityVal: e.target.value })
        }
        else {
            this.setState({ quantityVal: e.target.value })
        }
    };

    handlePriority = (index) => (e) => {
        let existingData = this.state.productRequestData;
        existingData[index].priority = e[0]
        this.setState({ productRequestData: existingData })
    };

    onChangeProduct = (index) => (event, values) => {
        if ((values && values.hasOwnProperty("value") && values.value !== null)) {
            let existingData = this.state.productRequestData;
            existingData[index].productId = values.value._id;
            existingData[index].productName = values.value.productName;
            existingData[index].priceWgst = (values.value.inventory.length > 0) ? values.value.inventory[values.value.inventory.length - 1].priceWgst : 0;
            existingData[index].discount = (values.value.inventory.length > 0) ? values.value.inventory[values.value.inventory.length - 1].discount : 0;


            if (existingData[index].priceWgst > 0 && existingData[index].discount > 0) {
                existingData[index].purchasePriceAfterDiscount = existingData[index].priceWgst - (existingData[index].priceWgst * (existingData[index].discount / 100))
            } else if (existingData[index].priceWgst > 0 && existingData[index].discount == 0) {
                existingData[index].purchasePriceAfterDiscount = existingData[index].priceWgst
            } else if (existingData[index].priceWgst > 0 && existingData[index].discount < 0) {
                existingData[index].purchasePriceAfterDiscount = existingData[index].priceWgst
            }



            existingData[index].productIdError = false;
            this.setState({ productRequestData: existingData })
        }
    }

    fetchProduct = async (value) => {
        if (value?.length > 2) {
            try {
                if (value !== 0 || value !== '0') {
                    let suggestedProduct = await PharmacyService.searchProduct(value, this.state);
                    this.setState({ allSuggestedProduct: suggestedProduct });
                    let temp = [];
                    for (let item of suggestedProduct) {
                        let str = { title: item.productName, value: item };
                        temp.push(str);
                    }
                    if (temp.length > 0) {
                        this.setState({ suggestedProduct: temp });
                    } else {
                        this.setState({ suggestedProduct: [] });
                    }
                }
            } catch (e) {
                this.setState({ suggestedProduct: [] });
            }
        }

    };


    onClick = async (nr) => {
        await this.setState({
            priorities: nr
        });
    }

    selectCustomer = async (customer) => {
        this.setState({
            customer: customer,
            name: (customer == "POSITRA" ? "Positra Drug Store" : ""),
            contactNo: (customer == "POSITRA" ? "6900702871" : "")
        })
        if (customer == "POSITRA") {
            this.setState({
                customerToSend: {
                    _id: "",
                    name: "POSITRA DRUG STORE"
                }
            })
        }
    }

    handleSelectProduct = (val, item) => {
        if (item) {
            const isDuplicate = this.state.productRequestData.some(item2 => item2.productName === item.title);
            if (isDuplicate) {
                toast.error("The product has already been added")
                return
            }
            this.setState({
                clearAutocompMed: false
            })
            const { discount, productName,_id } = item.value
            let discountAmount
            if (discount > 0 && item.value.inventory?.[0]?.priceWgst) {
                discountAmount = item.value.inventory?.[0]?.priceWgst - (item.value.inventory?.[0]?.priceWgst * discount) / 100
            }
            else {
                discountAmount = 0
            }
            let existingData = this.state.productRequestData;
            let amount = (this.state.quantityVal * discountAmount).toFixed(2)
            
            let newData = {
                "productName": item.title,
                "priceWgst": item.value.inventory?.[0]?.priceWgst?.toFixed(2),
                "discount": discount,
                "purchasePriceAfterDiscount": discountAmount?.toFixed(2),
                "productId": _id,
                "quantity": "5",
                "priority": "",
                "status": "CREATED",
                "quantityError": false,
                "productNameError": false,
                "totalAmount": amount 
            }
            if (this.state.isEditable) {
                existingData[this.state.currentProductIndex] = newData
            }
            else {
                existingData.push(newData)
            }
            this.setState({ productRequestData: existingData })
        }
        else {
            this.setState({
                suggestedProduct: []
            })
        }
    }

    editProduct = (index) => {
        this.setState({
            isEditable: true,
            selectedProdAuto: this.state.productRequestData[index],
            currentProductIndex: index,
            quantityVal: this.state.productRequestData[index]?.quantity
        })
    }

    deleteProduct = (index) => {
        let existingData = this.state.productRequestData
        existingData.splice(index, 1)
        this.setState({
            productRequestData: existingData
        })
    }

    render() {
        return (
            <React.Fragment>
                {this.state.progressStatus === true &&
                    <span className={'top-stick'}><MDBProgress material preloader color="cyan" /></span>
                }
                <MDBContainer>
                    <MDBCard className={"mt-2"}>
                        <MDBCardHeader>
                            <div className={"title float-left"}>Add Purchase Request</div>
                        </MDBCardHeader>

                        <MDBRow className={"mx-4 mt-2"}>
                            <MDBCol>
                                <div className={"px-2"}>
                                    <b>Select Customer</b>
                                </div>
                                <div style={{ width: '200px' }}>
                                    <MDBSelect getValue={(val) => this.selectCustomer(val[0])} key={this.state.resetKey} >
                                        <MDBSelectInput selected={this.state.customer || "Select Customer"} />
                                        <MDBSelectOptions>
                                            <MDBSelectOption value="POSITRA">Positra Drug Store</MDBSelectOption>
                                            <MDBSelectOption value="OTHER">Other Customer</MDBSelectOption>
                                        </MDBSelectOptions>
                                    </MDBSelect>
                                </div>
                                <div className="d-flex justify-content-start">
                                    <div>
                                        <div class="btn-group" role="group" aria-label="Basic example">
                                            <button onClick={() => this.handleDays("Days")} type="button" class={this.state.isDays ? "btn btn-primary" : "btn"} >Days</button>
                                            <button onClick={() => this.handleDays("Hours")} type="button" class={!this.state.isDays ? "btn btn-primary" : "btn"}>Hours</button>
                                        </div>
                                    </div>
                                    <div style={{ width: '100px' }}>
                                        <MDBSelect key={this.state.resetKey} getValue={(val) => this.handleTime(val[0])}>
                                            <MDBSelectInput selected={this.state.isDays ? this.state.expectedDeliveryDays ? this.state.expectedDeliveryDays : 'Select Time' : this.state.expectedDeliveryHours ? this.state.expectedDeliveryHours : "Select Time"} />
                                            <MDBSelectOptions>
                                                {this.state.deliveryDropdown.map((item) =>
                                                    <MDBSelectOption value={item}>{item} {this.state.isDays ? "Days" : "Hours"}</MDBSelectOption>
                                                )}
                                            </MDBSelectOptions>
                                        </MDBSelect>
                                    </div>
                                </div>
                            </MDBCol>
                            <MDBCol col={"3"}>
                                <div className={"px-2"}>
                                    <b>Priority</b>
                                </div>

                                <div class="btn-group" role="group" aria-label="Basic example">
                                    <button onClick={() => this.onClick("LOW")} type="button" class={this.state.priorities == 'LOW' ? "btn btn-primary" : "btn"} >Low</button>
                                    <button onClick={() => this.onClick("MEDIUM")} type="button" class={this.state.priorities == 'MEDIUM' ? "btn btn-primary" : "btn"}>Medium</button>
                                    <button onClick={() => this.onClick("HIGH")} type="button" class={this.state.priorities == 'HIGH' ? "btn btn-primary" : "btn"}>High</button>
                                </div>
                            </MDBCol>
                            <MDBCol>
                                {this.state.customer === "OTHER" &&
                                    <MDBRow>
                                        <MDBCol style={{ marginTop: "1.2em" }}>
                                            <AutocompleteUser
                                                label="Enter Customer Name or phone"
                                                name="customer"
                                                options={this.state.customerList}
                                                onSelect={(val, item) => this.handleSelect(val, item)}
                                                onChange={(val) => { this.handleCustomerSearch(val) }} />
                                            {Object.keys(this.state.selectedCustomerInfo)?.length > 0 && <div className="customerDropdown">
                                                <span>Name : {this.state.selectedCustomerInfo?.name}</span><br />
                                                <span>Phone :{this.state.selectedCustomerInfo?.contactNo?.length > 1 ? this.state.selectedCustomerInfo?.contactNo : "Not Available"}</span><br />
                                                <span>Email :{this.state.selectedCustomerInfo?.email?.length > 1 ? this.state.selectedCustomerInfo?.email : "Not Available"}</span>
                                            </div>}
                                        </MDBCol>
                                    </MDBRow>
                                }
                            </MDBCol>
                        </MDBRow>




                        <MDBCardBody>
                            <MDBRow className={'mx-0'}>
                                <MDBCol className={"col-5"}>

                                    <div className={"ml-3"}>
                                        <b>Product Name</b>
                                    </div>

                                    <div className={"d-flex justify-content-start col-12"}>
                                        <AutocompleteMeds
                                            label="Enter Product Name"
                                            name="product"
                                            options={this.state.suggestedProduct}
                                            onSelect={(val, item) => this.handleSelectProduct(val, item, this.state.currentProductIndex)}
                                            currentProduct={this.state.selectedProdAuto}
                                            selectedVal={this.state.clearAutocompMed}
                                            onChange={(val) => { this.fetchProduct(val) }} />

                                        <button
                                            disabled={this.state.progressStatus}
                                            onClick={this.addRow}
                                            type="button"
                                            class={"btn btn-primary"}><b>Add More</b></button>
                                    </div>
                                </MDBCol>
                                <MDBCol className={"col-1"}>
                                    <div className={"p-0 m-0 col-12"}>

                                        <MDBInput outline={true} className={this.state.productRequestData?.[this.state.currentProductIndex]?.quantityError ? "border-danger" : ""} maxLength="15"
                                            type='text' label="Quantity" onChange={this.handleQuantity(this.state.currentProductIndex)}
                                            value={this.state.quantityVal} />


                                    </div>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                    {this.state.productRequestData.length > 0 &&
                     <TableProductRequest data={[...this.state.productRequestData]} editProduct={this.editProduct} deleteProduct={this.deleteProduct} />}
                    <MDBRow className={"mx-12 mt-12"}>
                        <MDBCol className={"col-12"} style={{ display: "flex", justifyContent: "center",marginTop:".5em" }}>
                            <div class="btn-group" role="group" aria-label="Basic example">
                                <button disabled={this.state.progressStatus} onClick={this.addProductRequest} type="button" class={"btn btn-primary"}>Save</button>
                            </div>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </React.Fragment>
        );
    }
}
export default withRouter(AddProductRequest);

