import React from "react";
import {
    MDBBox,
    MDBBtn,
    MDBCol,
    MDBInput, MDBModal,
    MDBModalBody,
    MDBModalHeader,
    MDBRow,
    MDBTable,
    MDBTableBody,
    MDBTableHead
} from "mdbreact";
import SimpleBackdrop from "../common/overlay";
import AuthService from "../../services/authService";
import UserStore from "../../stores/userStore";
import Moments from "moment";
import ViewOrder from "../online-order/viewOrder";
import CustomerDetails from "./customerDetails";

class ViewCustomer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            term: "",
            isLoading: false,
            userData: {},
            suggestedUser: [],
            activeItem: null,
            modalCustomerDetails: false,
        }
    }

    async componentDidMount() {
        let userData = await UserStore.fetchSessionData();
        this.setState({userData});
    }

    handleEnter = async (e) => {
        if (e.keyCode === 13) {
            await this.searchByTerm()
        }
    }
    searchByTerm = async () => {
        if (this.state.term !== "") {
            this.setState({isLoading: true})
            let resUser = await AuthService.suggestCustomerNew(this.state.term, this.state.userData.sessionToken);
            this.setState({isLoading: false})
            let suggestedUser = [];
            for (let item of resUser) {
                if ((item.hasOwnProperty("name") && item.name) || (item.hasOwnProperty("first_name") && item.first_name)) {
                    suggestedUser.push(item)
                }
            }
            this.setState({suggestedUser})
        }
    }
    getName = (customerDetails) => {
        if (customerDetails.hasOwnProperty("name")) {
            return customerDetails.name
        } else if (customerDetails.hasOwnProperty("first_name")) {
            return customerDetails.first_name
        } else {
            return ""
        }
    }
    setActiveItem = (item) => {
        this.setState({activeItem: item})
        this.toggle("CustomerDetails")
    }
    toggle = (section) => {
        this.setState({["modal" + section]: !this.state["modal" + section]})
    }

    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.isLoading}/>
                <MDBRow className={"mx-0"}>
                    <MDBCol size={"3"}>
                        <MDBInput label={"Customer Name/Phone/E-mail"} value={this.state.term} getValue={(e) => {
                            this.setState({term: e})
                        }} onKeyDown={this.handleEnter}/>
                    </MDBCol>
                    <MDBCol className={'d-flex align-items-center'}>
                        <MDBBtn size={'md'} onClick={() => {
                            this.searchByTerm()
                        }} disabled={this.state.isLoading}>Submit</MDBBtn>
                    </MDBCol>
                </MDBRow>
                <MDBRow className={"mx-0 p-2"}>
                    <MDBCol>
                        <MDBBox>
                            {(this.state.suggestedUser.length > 0) &&
                            <MDBTable>
                                <MDBTableHead>
                                    <tr>
                                        <th></th>
                                        <th>Name</th>
                                        <th>Contact No</th>
                                        <th>DOB</th>
                                        <th>E-mail</th>
                                        <th>Gender</th>
                                        <th>Member Since</th>
                                        <th></th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody search="true">
                                    {this.state.suggestedUser.map((item, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{this.getName(item)}</td>
                                            <td>{item.contactNo}</td>
                                            <td>{item.dob !== "" ? Moments(item.dob).format("ll") : ""}</td>
                                            <td>{item.email}</td>
                                            <td>{item.gender}</td>
                                            <td>{Moments(item.memberSince).format("ll")}</td>
                                            <td><a onClick={() => this.setActiveItem(item)}
                                                   className="blue-text">View</a></td>
                                        </tr>
                                    ))}
                                </MDBTableBody>
                            </MDBTable>
                            }
                        </MDBBox>
                    </MDBCol>
                </MDBRow>
                <MDBModal isOpen={this.state.modalCustomerDetails} toggle={()=>this.toggle("CustomerDetails")}
                          size={"fluid"} fullHeight={true} position={"top"}>
                    <MDBModalHeader toggle={()=>this.toggle("CustomerDetails")}>Customer Details</MDBModalHeader>
                    <MDBModalBody className={'view-order-sticky'}>
                        <CustomerDetails activeItem={this.state.activeItem}/>
                    </MDBModalBody>
                </MDBModal>
            </React.Fragment>
        );
    }
}

export default ViewCustomer;