import React from "react";
import SimpleBackdrop from "../common/overlay";
import {
    MDBBox,
    MDBBtn,
    MDBInput,
    MDBModal,
    MDBModalBody,
    MDBModalFooter,
    MDBModalHeader,
    MDBSelect,
    MDBSelectInput,
    MDBSelectOptions,
    MDBSelectOption, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBDropdown,
} from "mdbreact";
import Moments from "moment";
import {MDBDatePickerV5} from 'mdbreact';
import {toast} from "react-toastify";
import utility from "../../utility/utility";
import UserStore from "../../stores/userStore";
import PharmacyService from "../../services/pharmacyService";
import MaterialTable from "material-table";
import {CSVLink} from "react-csv";

const headers = [
    {label: 'Code', key: 'code'},
    {label: 'startDate', key: 'startDate'},
    {label: 'endDate', key: 'startDate'},
    {label: 'Maximum Impressions', key: 'maxTotalUsaes'},
    {label: 'Coupon Type', key: 'discountType'},
    {label: 'Discount Amount', key: 'value'},
    {label: 'Minimum Order Amount', key: 'minimumTotal'},
    {label: 'Description', key: 'description'},
    {label: 'Same User Use', key: 'sameUserUse'},
    {label: 'Status', key: 'isDeleted'},
]

class CouponV2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: {},
            isLoading: true,
            modalAdd: false, modalDelete: false,
            couponCode: "",
            maxTotalUsaes: 1,
            description: "",
            startDate: "",
            endDate: "",
            amount: "",
            type: "",
            coupons: [],
            sameUserUse: 1,
            activeItem: null,
            minimumTotal: 0
        }
    }

    async componentDidMount() {
        let userData = await UserStore.fetchSessionData();
        this.setState({userData})
        await this.refreshPage()
    }

    refreshPage = async () => {
        let data = await PharmacyService.fetchCoupon(this.state.userData);
        if (data === false) {
            toast.error("We are facing some issue. Please try again later.");
        } else {
            this.setState({coupons: data, isLoading: false});
        }
    }
    generate = () => {
        let code = this.makeid(7);
        this.setState({couponCode: code});
    }
    makeid = (length) => {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }
    addCoupon = async () => {
        //validations
        if (this.state.couponCode === "") {
            toast.error("Invalid coupon code.");
            return false;
        }
        if (this.state.startDate === "") {
            toast.error("Invalid start date.");
            return false;
        }
        if (this.state.endDate === "") {
            toast.error("Invalid end date.");
            return false;
        }
        if (this.state.startDate > this.state.endDate) {
            toast.error("Invalid date range, start-date smaller than the end-date");
            return false
        }

        if (this.state.type === "") {
            toast.error("Please select the coupon type.")
            return false
        }
        let amount = parseFloat(this.state.amount);
        if (utility.isFloat(amount) === false) {
            toast.error("Please enter valid Flat/Percentage Amount.");
            return false;
        }
        let sameUserUse = parseInt(this.state.sameUserUse);
        if (utility.isInt(sameUserUse) === false) {
            toast.error("Please enter valid same user use.");
            return false;
        }
        let payload = {
            code: this.state.couponCode,
            description: this.state.description,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            maxTotalUsaes: this.state.maxTotalUsaes,
            discountType: this.state.type[0],
            amount: this.state.amount,
            sameUserUse: this.state.sameUserUse,
            minimumTotal: this.state.minimumTotal
        }
        this.setState({isLoading: true});
        let status = await PharmacyService.addCoupon(payload, this.state.userData.sessionToken);
        if (status === false) {
            toast.error("Coupon code already exists.");
        } else {
            toast.success("Coupon added successfully.");
            this.toggle('Add')
            await this.refreshPage()
        }
        this.setState({isLoading: false});
    }
    setActiveItem = (item, section) => {
        this.setState({activeItem: item})
        this.toggle('Delete')
    }
    toggle = (section) => {
        this.setState({['modal' + section]: !this.state['modal' + section]})
    }
    deleteCoupon = async () => {
        this.setState({isLoading: true});
        let status = await PharmacyService.deleteCoupon({_id: this.state.activeItem._id}, this.state.userData);
        if (status === false) {
            toast.error("We are facing some issue. Please try again later.");
        } else {
            toast.success("Coupon deleted successfully.");
            await this.refreshPage()
            this.setState({isLoading: false});
            this.toggle('Delete')
        }
    }

    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.isLoading}/>
                <div className={'float-right row mx-0 pr-3'}>
                    <MDBBtn size={'md'} onClick={() => this.toggle('Add')}> <i className="fas fa-plus-circle"></i> Add Coupon</MDBBtn>
                    <CSVLink data={this.state.coupons} headers={headers} target="_blank"
                             filename={"coupons_" + Moments(new Date()).format("DD-MM-YYYY") + ".csv"}>
                        <MDBBtn size={'md'} disabled={this.state.isLoading}><i
                            className="fas fa-download"></i>&nbsp;Export</MDBBtn>
                    </CSVLink>
                </div>
                <div className={"col-12 row p-0 m-0"}>
                    <div className={"col-12"}>
                        <MaterialTable title="Coupons"
                                       columns={[
                                           {title: 'No', field: '', render: rowData => rowData.tableData.id + 1},
                                           {title: 'Code', field: 'code'},
                                           {
                                               title: 'startDate',
                                               field: 'startDate',
                                               render: rowData => Moments(rowData.startDate).format("MM/DD/YYYY")
                                           },
                                           {
                                               title: 'endDate',
                                               field: 'startDate',
                                               render: rowData => Moments(rowData.endDate).format("MM/DD/YYYY")
                                           },
                                           {title: 'Maximum Impressions', field: 'maxTotalUsaes'},
                                           {title: 'Coupon Type', field: 'discountType'},
                                           {title: 'Discount Amount', field: 'value'},
                                           {
                                               title: 'Minimum Order Amount',
                                               field: 'minimumTotal',
                                               render: rowData => (rowData.minimumTotal).toFixed(2)
                                           },
                                           {title: 'Description', field: 'description'},
                                           {title: 'Same User Use', field: 'sameUserUse'},
                                           {
                                               title: 'Status',
                                               field: 'isDeleted',
                                               render: rowData => rowData.isDeleted === "N" ? 'Active' : 'Deleted'
                                           },
                                           {
                                               title: '',
                                               field: '',
                                               render: rowData => <span
                                                   className={"cursor-pointer"}
                                                   onClick={() => this.setActiveItem(rowData, 'Delete')}><i
                                                   className="fas fa-trash fa-lg"></i></span>
                                           }
                                       ]}
                                       data={this.state.coupons}
                                       options={{
                                           showTitle: false,
                                           pageSize: 10,
                                           pageSizeOptions: [10, 50, 100],
                                           emptyRowsWhenPaging: false,
                                       }}
                        />
                    </div>
                </div>
                {/*Add coupon*/}
                <MDBModal isOpen={this.state.modalAdd} toggle={() => this.toggle('Add')} centered size="lg">
                    <MDBModalHeader toggle={() => this.toggle('Add')}>Add coupon</MDBModalHeader>
                    <MDBModalBody>
                        <MDBBox>
                            <MDBInput label={"Coupon code"} value={this.state.couponCode} getValue={(e) => {
                                this.setState({couponCode: e})
                            }}/>
                            <div className={'text-center'}>
                            <MDBBtn size={'md'} onClick={() => {
                                this.generate()
                            }}>Generate</MDBBtn>
                            </div>
                            <MDBDatePickerV5 labelTitle={"Start date"} getValue={(e) => {
                                this.setState({startDate: e})
                            }}/>
                            <MDBDatePickerV5 labelTitle={"End date"} getValue={(e) => {
                                this.setState({endDate: e})
                            }}/>
                            <MDBInput label={"Maximum impressions"} value={this.state.maxTotalUsaes} getValue={(e) => {
                                this.setState({maxTotalUsaes: e})
                            }}/>
                            <MDBSelect label="Coupon type" getValue={(e) => {
                                this.setState({type: e})
                            }}>
                                <MDBSelectInput selected="Select type"/>
                                <MDBSelectOptions>
                                    <MDBSelectOption value="FLAT">Flat discount</MDBSelectOption>
                                    <MDBSelectOption value="PERCENTAGE">Percentage</MDBSelectOption>
                                </MDBSelectOptions>
                            </MDBSelect>
                            <MDBInput label={"Flat/Percentage amount"} value={this.state.amount} getValue={(e) => {
                                this.setState({amount: e})
                            }}/>
                            <MDBInput label={"Same user uses"} value={this.state.sameUserUse} getValue={(e) => {
                                this.setState({sameUserUse: e})
                            }}/>
                            <MDBInput label={"Minimum order total"} value={this.state.minimumTotal} getValue={(e) => {
                                this.setState({minimumTotal: e})
                            }}/>
                            <MDBInput label={"Description"} value={this.state.description} getValue={(e) => {
                                this.setState({description: e})
                            }}/>
                        </MDBBox>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn outline size={'md'} onClick={() => this.toggle('Add')}>Close</MDBBtn>
                        <MDBBtn size={'md'}  onClick={this.addCoupon}>Create</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
                {/* Delete modal */}
                <MDBModal isOpen={this.state.modalDelete} toggle={() => this.toggle('Delete')}>
                    <MDBModalHeader toggle={() => this.toggle('Delete')}>Delete coupon</MDBModalHeader>
                    <MDBModalBody>
                        Are you sure?
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button type="button" className="btn btn-outline-success"
                                onClick={() => this.toggle('Delete')}>Close
                        </button>
                        <button type="button" className="btn btn-success" onClick={this.deleteCoupon}>Delete</button>
                    </MDBModalFooter>
                </MDBModal>
            </React.Fragment>
        )
    }
}

export default CouponV2;
