import React from "react";
import UserStore from "../../stores/userStore";
import InventoryService from "../../services/inventoryService";
import {toast} from "react-toastify";
import {
    MDBBox, MDBBtn, MDBCol, MDBRow, MDBTable, MDBTableBody, MDBTableHead,
    MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBInput, MDBIcon
} from "mdbreact";
import Moments from "moment";
import {withRouter} from "react-router-dom";
import SimpleBackdrop from "../common/overlay";
import PharmacyService from "../../services/pharmacyService";
import config from "../../config";
import MaterialTable from "material-table";

class ListProductGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeItem: null,
            modalDelete: false,
            isLoading: true,
            modalEditGroup: false,
            term: "",
            searchResult: [], searchLocalResult: [],
            groupName: "",
            simItemList: [],
            pageNo: 1, groupList: [], paginatedGroupList: [],
            pageSize: 10,
            searchKeyWord: ""
        }
    }

    async componentDidMount() {
        let userData = await UserStore.fetchSessionData();
        this.setState({userData})
        await this.fetchProductGroups()
    }

    fetchProductGroups = async () => {
        let res = await InventoryService.fetchProductGroups()
        if (res) {
            this.setState({groupList: res});
        } else {
            toast.error("Something went wrong while fetching product groups.")
        }
        this.setState({isLoading: false})
    }
    setActiveItem = (item, section) => {
        if (section === "EditGroup") {
            this.setState({simItemList: item.products, groupName: item.groupName})
        }
        this.setState({activeItem: item})
        this.toggle(section)
    }
    toggle = (section) => {
        this.setState({["modal" + section]: !this.state["modal" + section]})
    }
    deleteGroup = async () => {
        this.setState({isLoading: true})
        let res = await InventoryService.deleteProductGroup(this.state.userData.sessionToken, this.state.activeItem._id)
        this.setState({isLoading: false})
        if (res) {
            toast.success("Group deleted successfully.")
            this.setState({activeItem: null})
            this.toggle("Delete")
            await this.fetchProductGroups()
        } else {
            toast.error("Something went wrong while deleting the group.")
        }
    }
    editGroup = async () => {
        if (this.state.simItemList.length < 2) {
            toast.error("Please select at least two products to form a group.")
            return true
        }
        let products = [], payload = {}
        for (let item of this.state.simItemList) {
            products.push(item._id)
        }
        payload = {
            groupName: this.state.groupName.toUpperCase(),
            productIds: products,
            groupId: this.state.activeItem._id
        }
        this.setState({isLoading: true});
        let res = await InventoryService.editProductGroup(this.state.userData.sessionToken, payload)
        this.setState({isLoading: false});
        if (res) {
            toast.success("The product group updated successfully.")
            this.toggle("EditGroup")
            await this.fetchProductGroups()
        } else {
            toast.error("Something went wrong while updating the product group.")
        }
    }
    handleEnter = () => async (e) => {
        if (e.keyCode === 13) {
            await this.searchByTerm()
        }
    }
    searchByTerm = async () => {
        if (this.state.term !== "") {
            this.setState({isLoading: true});
            let result = await PharmacyService.fetchProductByName(this.state.userData.sessionToken, this.state.term);
            this.setState({isLoading: false});
            if (result === false) {
                toast.error("Something went wrong while fetching the data by product name.");
            } else {
                this.setState({searchResult: result});
            }
        }
    }
    addToList = (item) => {
        let simItemList = this.state.simItemList
        if (this.checkAvailability(item) === true) {
            toast.error("Added item is already available in the final product list.")
            return false
        }
        simItemList.push(item.productDetails)
        this.setState({simItemList})
    }
    checkAvailability = (item) => {
        for (let i of this.state.simItemList) {
            if (i.productName === item.productDetails.productName) {
                return true
            }
        }
        return false
    }
    removeItem = (index) => {
        let simItemList = this.state.simItemList
        simItemList.splice(index, 1)
        this.setState({simItemList})
    }
    viewProduct = (item) => {
        let productName = item.productName
        if (item.productName.indexOf("/") > -1) {
            productName = productName.replaceAll("/", "-")
        }
        productName = productName.replaceAll(" ", "-")
        return config.WEBHOST + "/product/" + item._id + "/" + productName
    }
    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.isLoading}/>
                <div className={"col-12 row mx-0"}>
                    <div className={'col-md-12 text-right p-0'}>
                        <MDBBtn size={'md'} disabled={this.state.isLoading}
                                onClick={() => this.props.history.push("/add-product-group")}>Add</MDBBtn>
                    </div>
                    <MaterialTable
                        columns={[
                            {
                                title: 'Date',
                                field: 'createdAt',
                                render: rowData => Moments(rowData.createdAt).format("MMM DD, YYYY")
                            },
                            {title: 'Group Name', field: 'groupName'},
                            {
                                title: 'Products', field: '',
                                render: rowData => <>{rowData.products.map((product, i) => (
                                    <span key={i}>{i + 1}. {product.productName} <a
                                        className={"blue-text btn-link cursor-pointer font-weight-normal ml-1"}
                                        href={this.viewProduct(product)} alt={""} target={"blank"}>View</a><br/>
                            1 {product.retailPackage} of {product.contentQuantity} {product.quantityUnit}
                                        <br/><br/>
                        </span>
                                ))}</>
                            },
                            {
                                title: '', field: '',
                                render: rowData => <a className={'primary-text'} data-toggle="tooltip"
                                                      data-placement="top"
                                                      onClick={() => this.setActiveItem(rowData, "EditGroup")}>Edit</a>
                            },
                            {
                                title: '', field: '',
                                render: rowData => <a className={'primary-text'}
                                                      data-toggle="tooltip" data-placement="top"
                                                      onClick={() => this.setActiveItem(rowData, "Delete")}>Delete</a>
                            }
                        ]}
                        data={this.state.groupList}
                        options={{
                            padding: "dense",
                            showTitle: false,
                            pageSize: this.state.pageSize,
                            pageSizeOptions: [10, 50, 100],
                            tableLayout: "fixed",
                        }}
                    />
                </div>
                {this.state.activeItem && this.state.activeItem !== null && <>
                    <MDBModal isOpen={this.state.modalDelete} toggle={() => this.toggle("Delete")}>
                        <MDBModalHeader toggle={() => this.toggle("Delete")}>Delete Group</MDBModalHeader>
                        <MDBModalBody>
                            <h6>Do you want to delete the group: <b>{this.state.activeItem.groupName}</b> ?</h6>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color="secondary" onClick={() => this.toggle("Delete")}>Cancel</MDBBtn>
                            <MDBBtn color="primary" onClick={this.deleteGroup}>Delete</MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>
                    <MDBModal isOpen={this.state.modalEditGroup} toggle={() => this.toggle("EditGroup")} size="fluid">
                        <MDBModalHeader toggle={() => this.toggle("EditGroup")}>Edit Group</MDBModalHeader>
                        <MDBModalBody>
                            <>
                                <MDBRow className={"mx-0"}>
                                    <MDBCol className={"col-md-4"}>
                                        <MDBInput label={"Search"} value={this.state.term} getValue={(e) => {
                                            this.setState({term: e})
                                        }} onKeyDown={this.handleEnter()}/>
                                    </MDBCol>
                                    <MDBCol className={"col-md-2 d-flex align-items-center"}>
                                        <MDBBtn onClick={() => this.searchByTerm()}
                                                disabled={this.state.isLoading}>Submit</MDBBtn>
                                    </MDBCol>
                                    <MDBCol className={"col-md-6"}>
                                        <MDBInput label={"Group Name *"} size="sm" value={this.state.groupName}
                                                  maxLength="200" getValue={(val) => this.setState({groupName: val})}/>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className={"p-2"}>
                                    <MDBCol>
                                        <MDBBox>
                                            <h6 className={"text-center font-weight-bold"}>PRODUCT SEARCH LIST</h6>
                                            <MDBTable>
                                                <MDBTableHead>
                                                    <tr>
                                                        <th>Date</th>
                                                        <th>Product</th>
                                                        <th>Packaging</th>
                                                        <th>Mrp (₹)</th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>
                                                </MDBTableHead>
                                                {(this.state.searchResult.length > 0) &&
                                                <MDBTableBody search="true">
                                                    {this.state.searchResult.map((item, index) => (
                                                        <tr key={index} className={""}>
                                                            <td>{Moments(item.createdAt).format("MMM DD, YYYY")}</td>
                                                            <td>{item.productDetails.productName}</td>
                                                            <td> 1 {item.productDetails.retailPackage} of {item.productDetails.contentQuantity} {item.productDetails.quantityUnit}</td>
                                                            <td>{item.mrp.toFixed(2)}</td>
                                                            <td>
                                                                <a className={'primary-text'}
                                                                   data-toggle="tooltip" data-placement="top"
                                                                   onClick={() => this.addToList(item)}>
                                                                    Select</a>
                                                            </td>
                                                            <td></td>
                                                        </tr>
                                                    ))}
                                                </MDBTableBody>
                                                }
                                                {(this.state.searchResult.length === 0) &&
                                                <div className={'no-data-container'}>No products to display.</div>
                                                }
                                            </MDBTable>
                                        </MDBBox>
                                    </MDBCol>
                                    <MDBCol>
                                        <MDBBox>
                                            <h6 className={"text-center font-weight-bold"}>THE FINAL PRODUCT LIST</h6>
                                            <MDBTable>
                                                <MDBTableHead>
                                                    <tr>
                                                        <th></th>
                                                        <th>Date</th>
                                                        <th>Product</th>
                                                        <th>Packaging</th>
                                                        <th>Mrp (₹)</th>
                                                        <th></th>
                                                    </tr>
                                                </MDBTableHead>
                                                {(this.state.simItemList.length > 0) &&
                                                <MDBTableBody search="true">
                                                    {this.state.simItemList.map((item, index) => (
                                                        <tr key={index} className={""}>
                                                            <td>{index + 1}</td>
                                                            <td>{Moments(item.createdAt).format("MMM DD, YYYY")}</td>
                                                            <td>{item.productName}</td>
                                                            <td> 1 {item.retailPackage} of {item.contentQuantity} {item.quantityUnit}</td>
                                                            <td>{item.mrp.toFixed(2)}</td>
                                                            <td>
                                                                <div style={{"cursor": "pointer"}}
                                                                     onClick={() => this.removeItem(index)}><MDBIcon
                                                                    icon="trash"/>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </MDBTableBody>
                                                }
                                            </MDBTable>
                                        </MDBBox>
                                    </MDBCol>
                                </MDBRow>
                            </>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn size={'md'} outline onClick={() => this.toggle("EditGroup")}>Close</MDBBtn>
                            <MDBBtn size={'md'}  onClick={() => {
                                this.editGroup()
                            }} disabled={this.state.simItemList.length === 1}>Update</MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>
                </>}

            </React.Fragment>
        )
    }
}

export default withRouter(ListProductGroup);