import React,{Suspense} from "react";
import {Link} from "react-router-dom";
import {
    MDBBreadcrumbItem, MDBBreadcrumb, MDBRow
} from 'mdbreact';
import AddPurchaseNoteComp from "../../components/productRequest/addPurchaseNote";

export default class AddPurchaseNote extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem><Link to={'/view-purchase-notes'}>Purchase Notes</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Add Purchase Note</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <MDBRow className={"min-vh-100"}>
                    <Suspense fallback={null}>
                        <AddPurchaseNoteComp/>
                    </Suspense>
                </MDBRow>
            </React.Fragment>
        );
    }
}
