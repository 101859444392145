import React from "react";
import UserStore from "../../stores/userStore";
import InventoryService from "../../services/inventoryService";
import { toast } from "react-toastify";
import Moments from "moment";
import {
  MDBBox,
  MDBBtn,
  MDBInput,
  MDBModalFooter,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBSelect,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBSelectInput,
  MDBSelectOptions,
  MDBSelectOption,
} from "mdbreact";
import ProductView from "./productView";
import SellingHistoryByProduct from "../report/sellingHistoryByProduct";
import { withRouter } from "react-router-dom";
import EditProductNew from "./editProductNew";
import EditProductImage from "./editProductImage";
import config from "../../config";
import SimpleBackdrop from "../common/overlay";
import AuditLog from "./auditLog";
import utility from "../../utility/utility";
import ProductSpecifications from "./ProductSpecifications";
import Utility from "../../utility/utility";
import MaterialTable from "material-table";
import { CSVLink } from "react-csv";

const headers = [
  { label: "Product Name", key: "productName" },
  { label: "Top Selling", key: "topSelling" },
  { label: "Retail Package", key: "retailPackage" },
  { label: "Package Quantity", key: "contentQuantity" },
  { label: "Packaging Unit", key: "quantityUnit" },
  { label: "Loose", key: "looseSaleAllowed" },
  { label: "Loose Sale Qty", key: "minimumLooseSaleQuantity" },
  { label: "Medicine Type", key: "medicineType" },
  { label: "Created On", key: "createdAt" },
  { label: "Discount", key: "discount" },
];

class ManageProductNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: [],
      productList: [],
      paginatedProductDetails: [],
      today: new Date(),
      length: 0,
      isLoading: false,
      activeProduct: null,
      modalEdit: false,
      modalView: false,
      modalProductView: false,
      modalQuickTips: false,
      modalStatus: false,
      searchKeyWord: "",
      keyPT: 0,
      modalReview: false,
      productReviewComment: "",
      modalAfterReview: false,
      modalUpdateDiscount: false,
      modalAuditLog: false,
      searchResults: [],
      pageSize: 10,
      page: 1,
      type: "paginated",
      quickTips: [],
      quickTipsList: [],
      quickTipsEdited: false,
      discount: "",

      productIngredientList: [],
      selectedIngredients: [],
      keyIngredient: 0,
      ingredientList: [],
      packagingUnitList: [],
      searchData: [],
      key: "",
      ingredients: "",
    };
  }

  async componentDidMount() {
    let userData = await UserStore.fetchSessionData();
    this.setState({ userData });
  }
  openModal = (action, item) => {
    this.setState({ ["modal" + action]: true, activeProduct: item });
  };
  updateQuickTips = async (item) => {
    await this.setState({
      quickTips: item["metaData"].quickTips,
      quickTipsList: this.prepareList(item["metaData"].quickTips),
    });
    this.openModal("QuickTips", item);
  };
  prepareList = (list) => {
    let tempList = [];
    for (let item of list) {
      tempList.push({ value: item });
    }
    return tempList;
  };
  toggle = (section) => {
    this.setState({ productReviewComment: "", discount: "" });
    this.setState({ ["modal" + section]: !this.state["modal" + section] });
  };
  onEditComplete = async () => {
    this.toggle("Edit");
    this.tableRef.current.onQueryChange();
  };
  goToNewProduct = () => {
    this.props.history.push("/add-product-new");
  };
  resetKeyPT = () => {
    let randomString = Math.random().toString(36);
    this.setState({ keyPT: randomString });
  };
  deleteProduct = async () => {
    let payload = {
      _id: this.state.activeProduct._id,
    };
    let res = await InventoryService.deleteProductV3(
      this.state.userData.sessionToken,
      payload
    );
    if (res) {
      toast.success("The product got deleted successfully.");
      this.toggle("Delete");
      this.tableRef.current.onQueryChange();
    } else {
      toast.error("Something went wrong while deleting the product.");
    }
  };

  syncMrpAndStock = async (id) => {
    let payload = {
      _id: id,
    };
    let res = await InventoryService.syncMrpAndStockByProductIdV3(
      this.state.userData.sessionToken,
      payload
    );
    if (res) {
      toast.success("MRP and Stock has been synced with inventory..");
    } else {
      toast.error("Something went wrong while syncing the product MRP.");
    }
  };

  review = async (action) => {
    let payload = {
      productId: this.state.activeProduct._id,
      comment: this.state.productReviewComment,
    };
    this.setState({ isLoading: true });
    if (action === "Approve") {
      let res = await InventoryService.approveProduct(
        this.state.userData.sessionToken,
        payload
      );
      if (res) {
        toast.success("Product approved successfully.");
        this.toggle("Review");
        this.tableRef.current.onQueryChange();
      } else {
        toast.error("Something went wrong while approving the product.");
      }
    } else {
      let res = await InventoryService.rejectProduct(
        this.state.userData.sessionToken,
        payload
      );
      if (res) {
        toast.success("Product rejected successfully.");
        this.toggle("Review");
        this.tableRef.current.onQueryChange();
      } else {
        toast.error("Something went wrong while rejecting the product.");
      }
    }
    this.setState({ isLoading: false });
  };
  viewProduct = (item) => {
    let productName = item.productName;
    if (item.productName.indexOf("/") > -1) {
      productName = productName.replaceAll("/", "-");
    }
    productName = productName.replaceAll(" ", "-");
    return config.WEBHOST + "/product/" + item._id + "/" + productName;
  };
  reviewStatus = (item) => {
    if (item.hasOwnProperty("isReviewed") && item.isReviewed === "Y") {
      return (
        <span onClick={() => this.openModal("AfterReview", item)}>
          {item.revieweDetails.isApproved === "Y" ? (
            <span className={"text-success font-weight-bold"}>Approved</span>
          ) : (
            <span className={"text-danger font-weight-bold"}>Rejected</span>
          )}
        </span>
      );
    } else {
      return <span onClick={() => this.openModal("Review", item)}>Review</span>;
    }
  };
  saveQuickTips = async () => {
    let quickTips = [];
    if (
      this.state.activeProduct.hasOwnProperty("metaData") &&
      this.state.activeProduct.metaData.hasOwnProperty("quickTips")
    ) {
      quickTips =
        this.state.quickTipsEdited === true
          ? utility.prepareArray(this.state.quickTipsList)
          : this.state.quickTips;
    } else {
      quickTips = Utility.prepareArray(this.state.quickTipsList);
    }
    if (quickTips.length === 0) {
      toast.error('Please enter "Quick Tips"');
      return false;
    }
    this.setState({ isLoading: true });
    let res = await InventoryService.addQuickTips(
      this.state.userData.sessionToken,
      this.state.activeProduct._id,
      quickTips
    );
    this.setState({ isLoading: false });
    if (res) {
      toast.success("Quick Tips added successfully.");
      this.toggle("QuickTips");
      this.tableRef.current.onQueryChange();
    } else {
      toast.error("Something went wrong while adding quick tips.");
    }
    this.setState({ quickTipsEdited: false });
  };
  saveDiscount = async () => {
    let payload = {
      _id: this.state.activeProduct._id,
      discount: this.state.discount,
    };
    if (this.state.discount === "") {
      toast.error("Please enter a discount.");
      return false;
    }
    this.setState({ isLoading: true });
    let res = await InventoryService.updateDiscount(
      this.state.userData.sessionToken,
      payload
    );
    if (res) {
      toast.success("Discount updated successfully.");
      this.toggle("UpdateDiscount");
      this.tableRef.current.onQueryChange();
    } else {
      toast.error("Something went wrong while updating discount.");
    }
    this.setState({ isLoading: false });
  };
  tableRef = React.createRef();

  // Ingredient
  getIngredientList = async () => {
    if (this.state.productIngredientList.length > 0) {
      return false;
    }
    let rawList = [],
      tempList = [];
    if (
      !window.productIngredientList ||
      window.productIngredientList === null
    ) {
      let data = await InventoryService.fetchGetData(
        this.state.userData.sessionToken,
        "FETCH_INGREDIENT"
      );
      if (data) {
        rawList = data;
        window.productIngredientList = data;
      } else {
        toast.error("Something went wrong while fetching the ingredient list.");
      }
    } else {
      rawList = window.productIngredientList;
    }
    if (rawList.length > 0) {
      for (let i = 0; i < rawList.length; i++) {
        tempList.push({
          text: rawList[i].name,
          value: i + "_" + rawList[i].name,
          unit: "",
          qnty: 0,
          selected: false,
        });
      }
      this.setState({ productIngredientList: tempList });
    }
  };
  setIngredients = (val) => {
    let productIngredientList = this.state.productIngredientList;
    let ingredientList = []; //this.state.ingredientList
    for (let item of productIngredientList) {
      item.selected = false;
    }
    for (let i = 0; i < val.length; i++) {
      let arrayWithData = val[i].split("_");
      let index = arrayWithData[0];
      productIngredientList[index].selected = true;
      productIngredientList[index].displayIndex = i;
      ingredientList.push(productIngredientList[index].text);
    }
    for (let item of productIngredientList) {
      if (item.selected === false) {
        item.unit = "";
        item.qnty = 0;
      }
    }
    this.setState({ productIngredientList, ingredientList });
    this.resetKeyIngredient();
  };
  handleIngredientQnty = (index) => (e) => {
    let productIngredientList = this.state.productIngredientList;
    productIngredientList[index].qnty = e.target.value;
    this.setState({ productIngredientList });
  };
  handleIngredientUnit = (index) => (e) => {
    let productIngredientList = this.state.productIngredientList;
    productIngredientList[index].unit = e[0];
    this.setState({ productIngredientList });
  };
  resetKeyIngredient = () => {
    let randomString = Math.random().toString(36);
    this.setState({ keyIngredient: randomString });
  };
  getList = async (
    listName,
    apiName,
    fieldName,
    section,
    secondaryFieldName
  ) => {
    if (this.state[listName].length > 0) {
      return false;
    }
    let rawList = [],
      preparedList = [];
    if (!window[listName] || window[listName] === null) {
      let data = await InventoryService.fetchGetData(
        this.state.userData.sessionToken,
        apiName
      );
      if (data) {
        rawList = data;
        window[listName] = data;
      } else {
        toast.error(
          "Something went wrong while getting the " + section + " list."
        );
      }
    } else {
      rawList = window[listName];
    }
    if (rawList.length > 0) {
      if (
        listName === "productCategoryList" ||
        listName === "manufacturerList"
      ) {
        preparedList = this.getCategoryManufacturerList(rawList, fieldName);
      } else {
        preparedList = this.getPreparedList(
          rawList,
          fieldName,
          secondaryFieldName
        );
      }
    }
    this.setState({ [listName]: preparedList });
  };

  getPreparedList = (dataList, fieldName, secondaryFieldName) => {
    let tempList = [];
    for (let item of dataList) {
      if (secondaryFieldName !== undefined) {
        tempList.push({
          text: item[fieldName] + " ( " + item[secondaryFieldName] + " ) ",
          value: item[fieldName],
        });
      } else {
        tempList.push({ text: item[fieldName], value: item[fieldName] });
      }
    }
    return tempList;
  };

  searchIngredient = async () => {
    if (this.state.ingredients.trim() != "") {
      await this.setState({
        ingredientList: this.state.ingredients.split(","),
      });
    }

    let ingredientList = [];
    for (let i = 0; i < this.state.ingredientList.length; i++) {
      if (this.state.ingredientList[i]) {
        ingredientList.push(this.state.ingredientList[i]);
      }
    }
    if (ingredientList.length === 0) {
      let productIngredientList = this.state.productIngredientList;
      for (let i = 0; i < productIngredientList.length; i++) {
        if (productIngredientList[i].selected == true) {
          ingredientList.push(productIngredientList[i].text);
        }
      }
      if (ingredientList.length === 0) {
        toast.error("Please enter at least one ingredient.");
        return false;
      }
    }
    // this.setState({isLoading: true})
    let res = await InventoryService.fetchProductByIngredient(
      this.state.userData.sessionToken,
      ingredientList
    );
    if (res) {
      await this.setState({ searchData: res });
      if (res.length > 0) {
        toast.success(res.length + " matches found");
      } else {
        toast.error("No matches found");
      }
    }
    console.log(res);
  };

  clearSearchResults = () => {
    this.setState({ searchData: [], ingredients: "" });
    this.tableRef.current.onQueryChange({});
  };

  handleIngredients = (e) => {
    let ingredients = e.target.value;
    this.setState({
      ingredients: ingredients,
      ingredientList: ingredients.split(","),
    });
  };

  clearProductIngredientList = async () => {
    let productIngredientList = this.state.productIngredientList;
    for (let i = 0; i < productIngredientList.length; i++) {
      if (productIngredientList[i].selected == true) {
        productIngredientList[i].selected = false;
      }
    }
    await this.setState({ productIngredientList, key: new Date().getTime() });
  };

  clearIngredientList = () => {
    this.setState({ ingredientList: [] });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.isLoading === true && (
          <SimpleBackdrop status={this.state.isLoading} />
        )}

        <div className={"col-12 row mx-0"}>
          <div className={"col-md-12 white rounded-sm border p-0 mt-2"}>
            <div
              className={
                "col-12 p-2 grey lighten-4 border-bottom d-flex justify-content-between align-items-center"
              }
            >
              <b className={"font-weight-bold"}>Ingredients Search</b>
            </div>

            <div className="d-flex justify-content-around">
              <div className="col-4 mt-4 mb-3">
                <MDBSelect
                  disabled={this.state.ingredients.length > 0}
                  key={this.state.key}
                  search
                  selected="Select Ingredients"
                  options={this.state.productIngredientList}
                  getValue={this.setIngredients}
                  className={"col grey-text p-0 font-weight-bold"}
                  onFocus={this.getIngredientList}
                  multiple
                />
              </div>
              <div className={"pt-4 margin-auto"}>
                <span className={"margin-auto"}>Or</span>
              </div>
              <div className="col-4 mt-1  mb-3">
                <MDBInput
                  aria-describedby="button-addon3"
                  label={"Enter ingredients: Ex. Telmisartan,Amlodipine"}
                  onChange={this.handleIngredients}
                  value={this.state.ingredients}
                />
              </div>
              <div className={"col-3 pt-4"}>
                <MDBBtn onClick={this.searchIngredient}>Search</MDBBtn>
                {this.state.searchData.length > 0 && (
                  <MDBBtn onClick={this.clearSearchResults}>
                    Clear Results
                  </MDBBtn>
                )}
              </div>
            </div>
          </div>
          <div className={"col-md-12 text-right p-0"}>
            <CSVLink
              data={this.state.productList}
              headers={headers}
              target="_blank"
              filename={
                "Product_List_" +
                Moments(new Date()).format("DD-MM-YYYY") +
                ".csv"
              }
            >
              <MDBBtn size={"md"} disabled={this.state.isLoading}>
                <i className="fas fa-download"></i>&nbsp;Export
              </MDBBtn>
            </CSVLink>
            <MDBBtn
              size={"md"}
              disabled={this.state.isLoading}
              onClick={this.goToNewProduct}
            >
              Add
            </MDBBtn>
          </div>
          <MaterialTable
            tableRef={this.tableRef}
            title="Product List"
            columns={[
              { title: "Product Name", field: "productName" },
              { title: "Top Selling", field: "topSelling" },
              { title: "Retail Package", field: "retailPackage" },
              { title: "Package Quantity", field: "contentQuantity" },
              { title: "Packaging Unit", field: "quantityUnit" },
              { title: "Loose", field: "looseSaleAllowed" },
              { title: "Loose Sale Qty", field: "minimumLooseSaleQuantity" },
              {
                title: "Medicine Type",
                field: "productType",
                render: (rowData) => utility.getMedicineType(rowData),
              },
              {
                title: "Created On",
                field: "createdAt",
                render: (rowData) =>
                  Moments(rowData.createdAt).format("MMM D, YYYY hh:mm A"),
              },
              {
                title: "Discount",
                field: "discount",
                render: (rowData) => (
                  <span
                    color="primary"
                    className={
                      "blue-text btn-link cursor-pointer font-weight-normal"
                    }
                    onClick={() => this.openModal("UpdateDiscount", rowData)}
                  >
                    {rowData.discount}
                  </span>
                ),
              },
              {
                title: "Quick Tips",
                field: "",
                render: (rowData) =>
                  rowData.hasOwnProperty("metaData") &&
                  rowData.metaData.hasOwnProperty("quickTips") ? (
                    <span
                      color="primary"
                      className={
                        "blue-text btn-link cursor-pointer font-weight-normal"
                      }
                      onClick={() => this.updateQuickTips(rowData)}
                    >
                      Update
                    </span>
                  ) : (
                    <span
                      color="primary"
                      className={
                        "blue-text btn-link cursor-pointer font-weight-normal"
                      }
                      onClick={() => this.openModal("QuickTips", rowData)}
                    >
                      Add
                    </span>
                  ),
              },
              {
                title: "Audit Log",
                field: "",
                render: (rowData) => (
                  <span
                    color="primary"
                    className={
                      "blue-text btn-link cursor-pointer font-weight-normal"
                    }
                    onClick={() => this.openModal("AuditLog", rowData)}
                  >
                    Audit Log
                  </span>
                ),
              },
              {
                title: "Review",
                field: "",
                render: (rowData) => (
                  <span
                    color="primary"
                    className={
                      "blue-text btn-link cursor-pointer font-weight-normal"
                    }
                  >
                    {this.reviewStatus(rowData)}
                  </span>
                ),
              },
              {
                title: "Operations",
                field: "",
                render: (rowData) => (
                  <>
                    <span
                      color="primary"
                      className={
                        "blue-text btn-link cursor-pointer font-weight-normal"
                      }
                      onClick={() => {
                        this.props.history.push("/add-variant/" + rowData._id);
                      }}
                    >
                      &nbsp;Add Variant
                    </span>
                    <br />
                    <span
                      color="primary"
                      className={
                        "blue-text btn-link cursor-pointer font-weight-normal"
                      }
                      onClick={() => {
                        this.openModal("Edit", rowData);
                      }}
                    >
                      &nbsp;Edit
                    </span>
                    <br />
                    <a
                      className={
                        "blue-text btn-link cursor-pointer font-weight-normal ml-1"
                      }
                      href={this.viewProduct(rowData)}
                      alt={""}
                      target={"blank"}
                    >
                      View
                    </a>
                    <br />
                    <span
                      color="primary"
                      className={
                        "p-1 blue-text btn-link cursor-pointer font-weight-normal"
                      }
                      onClick={() => {
                        this.openModal("ProductView", rowData);
                      }}
                    >
                      Transactions
                    </span>

                    <div
                      color="primary"
                      className={
                        "p-1 blue-text btn-link cursor-pointer font-weight-normal"
                      }
                      onClick={() => this.syncMrpAndStock(rowData._id)}
                    >
                      Sync MRP
                    </div>

                    <div
                      color="primary"
                      className={
                        "p-1 blue-text btn-link cursor-pointer font-weight-normal"
                      }
                      onClick={() => {
                        this.openModal("EditImage", rowData);
                      }}
                    >
                      Edit Images
                    </div>
                  </>
                ),
              },
              {
                title: "Delete",
                field: "",
                render: (rowData) => (
                  <span
                    color="primary"
                    className={"blue-text btn-link cursor-pointer"}
                    onClick={() => {
                      this.openModal("Delete", rowData);
                    }}
                  >
                    Delete
                  </span>
                ),
              },
            ]}
            data={
              this.state.searchData.length > 0
                ? this.state.searchData
                : (query) =>
                    new Promise((resolve, reject) => {
                      this.setState({ pageSize: query.pageSize });
                      let url = config.APIHOST + config.FETCH_PRODUCTS + "?";
                      if (query.search) {
                        url += `q=${query.search}`;
                      }
                      url += `&page=${query.page + 1}`;
                      url += `&pageSize=${query.pageSize}`;
                      url += `&type=paginated`;
                      fetch(url)
                        .then((resp) => resp.json())
                        .then((resp) => {
                          resolve({
                            data: resp.data, // your data array
                            page: query.page, // current page number
                            totalCount: resp.totalRecord, // total row number
                          });
                          this.setState({ productList: resp.data });
                        })
                        .catch((error) => {
                          toast.error(
                            "Something went wrong while fetching the product list, please try again after a while."
                          );
                          resolve({
                            data: [],
                            page: query.page,
                            totalCount: 0,
                          });
                        });
                    })
            }
            options={{
              showTitle: false,
              padding: "dense",
              pageSize: this.state.pageSize,
              pageSizeOptions: [10, 50, 100],
              tableLayout: "fixed",
              debounceInterval: 800,
            }}
          />
        </div>
        <MDBModal
          isOpen={this.state.modalEdit}
          toggle={() => this.toggle("Edit")}
          size={"fluid"}
        >
          <MDBModalHeader toggle={() => this.toggle("Edit")}></MDBModalHeader>
          <MDBModalBody className={"float-center"}>
            {(this.state.activeProduct ||
              this.state.activeProduct !== null) && (
              <EditProductNew
                loadedOnPopup={true}
                onEditComplete={this.onEditComplete}
                productId={this.state.activeProduct._id}
                close={() => this.toggle("Edit")}
              />
            )}
          </MDBModalBody>
        </MDBModal>

        <MDBModal
          isOpen={this.state.modalEditImage}
          toggle={() => this.toggle("EditImage")}
          size={"fluid"}
        >
          <MDBModalHeader
            toggle={() => this.toggle("EditImage")}
          ></MDBModalHeader>
          <MDBModalBody className={"float-center"}>
            {(this.state.activeProduct ||
              this.state.activeProduct !== null) && (
              <EditProductImage
                loadedOnPopup={true}
                onEditComplete={this.onEditComplete}
                productId={this.state.activeProduct._id}
                close={() => this.toggle("EditImage")}
              />
            )}
          </MDBModalBody>
        </MDBModal>

        <MDBModal
          isOpen={this.state.modalView}
          toggle={() => this.toggle("View")}
          size={"fluid"}
        >
          <MDBModalHeader toggle={() => this.toggle("View")}></MDBModalHeader>
          <MDBModalBody className={"float-center"}>
            {(this.state.activeProduct ||
              this.state.activeProduct !== null) && (
              <ProductView
                loadedOnPopup={true}
                productId={this.state.activeProduct._id}
                product={this.state.activeProduct}
              />
            )}
          </MDBModalBody>
        </MDBModal>
        <MDBModal
          isOpen={this.state.modalProductView}
          toggle={() => this.toggle("ProductView")}
          size={"fluid"}
        >
          <MDBModalHeader
            toggle={() => this.toggle("ProductView")}
          ></MDBModalHeader>
          <MDBModalBody className={"float-center"}>
            {(this.state.activeProduct ||
              this.state.activeProduct !== null) && (
              <SellingHistoryByProduct
                productId={this.state.activeProduct._id}
              />
            )}
          </MDBModalBody>
        </MDBModal>
        <MDBModal
          isOpen={this.state.modalDelete}
          toggle={() => this.toggle("Delete")}
        >
          <MDBModalHeader toggle={() => this.toggle("Delete")}></MDBModalHeader>
          <MDBModalBody className={"float-center"}>
            {(this.state.activeProduct ||
              this.state.activeProduct !== null) && (
              <div>
                Do you want to delete the product "
                {this.state.activeProduct.productName}" ?
              </div>
            )}
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn size={"md"} outline onClick={() => this.toggle("Delete")}>
              Close
            </MDBBtn>
            <MDBBtn size={"md"} onClick={this.deleteProduct}>
              Delete
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
        <MDBModal
          size={"lg"}
          isOpen={this.state.modalReview}
          toggle={() => {
            this.toggle("Review");
          }}
        >
          <MDBModalHeader
            toggle={() => {
              this.toggle("Review");
            }}
          >
            Product Review
          </MDBModalHeader>
          <MDBModalBody>
            <MDBBox>
              <br />
              <MDBInput
                value={this.state.productReviewComment}
                type={"textarea"}
                label={"Review comment if any"}
                getValue={(e) => {
                  this.setState({ productReviewComment: e });
                }}
              ></MDBInput>
            </MDBBox>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              onClick={() => {
                this.toggle("Review");
              }}
            >
              Close
            </MDBBtn>
            <MDBBtn
              onClick={() => {
                this.review("Reject");
              }}
            >
              Reject
            </MDBBtn>
            <MDBBtn
              onClick={() => {
                this.review("Approve");
              }}
            >
              Approve
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
        <MDBModal
          isOpen={this.state.modalAfterReview}
          toggle={() => {
            this.toggle("AfterReview");
          }}
        >
          <MDBModalHeader
            toggle={() => {
              this.toggle("AfterReview");
            }}
          >
            Review Details
          </MDBModalHeader>
          <MDBModalBody>
            {this.state.activeProduct !== null &&
              this.state.activeProduct.hasOwnProperty("revieweDetails") && (
                <>
                  <div className={"my-2"}>
                    Reviewed By:{" "}
                    {this.state.activeProduct.revieweDetails.reviewedBy}
                  </div>
                  <div className={"my-2"}>
                    Review Status:{" "}
                    {this.state.activeProduct.revieweDetails.isApproved === "Y"
                      ? "Approved"
                      : "Rejected"}
                  </div>
                  <div className={"my-2"}>
                    Review Comment:{" "}
                    {this.state.activeProduct.revieweDetails.comment}
                  </div>
                  <div className={"my-2"}>
                    Reviewed At:{" "}
                    {Moments(
                      this.state.activeProduct.revieweDetails.reviewedAt
                    ).format("MMM DD, YYYY")}
                  </div>
                </>
              )}
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              onClick={() => {
                this.toggle("AfterReview");
              }}
            >
              Close
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
        <MDBModal
          isOpen={this.state.modalAuditLog}
          toggle={() => this.toggle("AuditLog")}
          size={"lg"}
        >
          <MDBModalHeader toggle={() => this.toggle("AuditLog")}>
            Audit Logs
          </MDBModalHeader>
          <MDBModalBody className={"float-center"}>
            {(this.state.activeProduct ||
              this.state.activeProduct !== null) && (
              <AuditLog productId={this.state.activeProduct._id} />
            )}
          </MDBModalBody>
        </MDBModal>
        <MDBModal
          isOpen={this.state.modalQuickTips}
          toggle={() => this.toggle("QuickTips")}
          size="lg"
        >
          <MDBModalHeader toggle={() => this.toggle("QuickTips")}>
            Quick Tips
          </MDBModalHeader>
          <MDBModalBody>
            {this.state.activeProduct !== null &&
            this.state.activeProduct.hasOwnProperty("metaData") &&
            this.state.activeProduct.metaData.hasOwnProperty("quickTips") ? (
              <div className={"col-12 row m-0 p-0"}>
                <div className={"col p-2"}>
                  <b>Quick tips</b>
                  <br />
                  <ProductSpecifications
                    section={"Quick tips"}
                    getListCallback={(list) => {
                      this.setState({
                        quickTipsList: list,
                        quickTipsEdited: true,
                      });
                    }}
                    sendListCallback={this.state.quickTips}
                  />
                </div>
              </div>
            ) : (
              <div className={"col-12 row m-0 p-0"}>
                <div className={"col p-2"}>
                  <b>Quick tips</b>
                  <br />
                  <ProductSpecifications
                    section={"Quick tips"}
                    getListCallback={(list) => {
                      this.setState({ quickTipsList: list });
                    }}
                  />
                </div>
              </div>
            )}
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              size={"md"}
              outline
              onClick={() => this.toggle("QuickTips")}
            >
              Close
            </MDBBtn>
            <MDBBtn size={"md"} onClick={this.saveQuickTips}>
              Save
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
        <MDBModal
          isOpen={this.state.modalUpdateDiscount}
          toggle={() => this.toggle("UpdateDiscount")}
        >
          <MDBModalHeader toggle={() => this.toggle("UpdateDiscount")}>
            Update Discount
          </MDBModalHeader>
          <MDBModalBody className={"float-center"}>
            <div className={"col-12 row p-1 m-0"}>
              <div className={"col-3 p-0 m-0"}>Product Name:</div>
              <div className={"col-9 p-0 m-0"}>
                <h6>
                  <b>
                    {this.state.activeProduct !== null &&
                      this.state.activeProduct.productName}
                  </b>
                </h6>
              </div>
            </div>
            <div className={"col-12 row p-1 m-0"}>
              <div className={"col-3 p-0 m-0"}>Previous Discount:</div>
              <div className={"col-9 p-0 m-0"}>
                <h6>
                  <b>
                    {this.state.activeProduct !== null &&
                      this.state.activeProduct.discount}
                  </b>
                </h6>
              </div>
            </div>
            <div className={"col-12 row p-1 m-0"}>
              <div className={"col-3 p-0 m-0"}>New Discount:</div>
              <div className={"col-9 p-0 m-0"}>
                <MDBInput
                  value={this.state.discount}
                  type={"number"}
                  getValue={(e) => {
                    this.setState({ discount: e });
                  }}
                ></MDBInput>
              </div>
            </div>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              color="primary"
              outline
              onClick={() => this.toggle("UpdateDiscount")}
            >
              Close
            </MDBBtn>
            <MDBBtn color="primary" onClick={this.saveDiscount}>
              Save
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </React.Fragment>
    );
  }
}

export default withRouter(ManageProductNew);
