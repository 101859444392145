import React,{Suspense} from "react";
import {
    MDBBox
} from 'mdbreact';
//const UpdateShipment=React.lazy(() => import("../../components/shipments/updateShipments"));
import UpdateShipment from "../../components/shipments/updateShipments";

export default class UpdateShipmentPage extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MDBBox className={"min-vh-100"}>
                    <Suspense fallback={null}>
                        <UpdateShipment  queryString={this.props.match.params}/>
                    </Suspense>
                </MDBBox>
            </React.Fragment>
        );
    }
}
