const orgConfig = {
    ORG_NAME_LABEL: "PositraRx",
    BRAND_LOGO:"",
    FAVICON:"",
    LEVEL1_MENUS:["SPECIALITY","SPECIALITY_GROUP","ORDERS", "PURCHASE_REQUESTS", "STOCK", "DOCTORS", "SUPPLIERS", "MANUFACTURERS", "ORGANIZATIONS", "PHARMACY_USERS", "SHIPMENTS", "STOCK", "PURCHASE_REQUESTS", "CUSTOMERS", "PRODUCT_CATEGORIES", "RETAIL_PACKAGING", "QUANTITY_UNITS", "PRODUCTS", "ONLINE_PRESCRIPTION_ORDERS", "REPORT", "ONLINE_ORDERS", "STORAGE_LOCATION", "FLOORS", "RACKS", "BOXES", "TASK_MANAGER", "PRODUCT_GROUPING","REPORT_V2"],
    LEVEL2_MENUS:[],
    CURRENCY_HTML:'&#8377;',
    CURRENCY_SYMBOL:"\u20B9",
    CURRENCY_TEXT:"INR",
    MEDICINE_TYPE:[],
    PHONE_NUMBER_LENGTH:10,
}
export default orgConfig;


