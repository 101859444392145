import React, { useEffect, useState } from "react";
import config from "../../config";
import { MDBIcon } from "mdbreact";
import moment from "moment";
import utility, { textFormatter } from "../../utility/utility";
import { ORDER_LOG_FORMAT } from "../../utility/constants";

const OrderDetails = ({ order }) => {
  const [prescriptionList, setPrescriptionList] = useState([]);

  const cancelledBy = (order) => {
    try {
      let name = "";
      for (let item of order?.processLog) {
        if (item.status === "CANCELLED") {
          name = "by " + item.createdBy.name;
        }
      }
      return name;
    } catch (e) {
      return "";
    }
  };

  const processDocsForDisplay = (prescriptions) => {
    let displayDocs = [];
    for (let item of prescriptions) {
      let createdBy = "";
      if (item.hasOwnProperty("createdBy")) {
        createdBy = item.createdBy.name;
      }
      if (item.prescriptionUrl.match(/.(jpg|jpeg|png|gif)$/i)) {
        displayDocs.push({
          path: item.prescriptionUrl,
          type: "image",
          createdBy: createdBy,
        });
      } else {
        displayDocs.push({
          path: item.prescriptionUrl,
          type: "text",
          createdBy: createdBy,
        });
      }
    }
    return displayDocs;
  };

  const createImageUrl = (item) => {
    if (item.match(/.(jpg|jpeg|png|gif)$/i)) {
      return (
        <a href={config.IMAGE_HOST + item} target={"_blank"}>
          <img src={config.IMAGE_HOST + item} width={86} height={86} download />
        </a>
      );
    } else {
      return (
        <a href={config.UPLOADED_DOC_HOST + item} target={"_blank"} download>
          <MDBIcon size={"6x"} icon="file" />
        </a>
      );
    }
  };

  useEffect(() => {
    if (
      order?.hasOwnProperty("prescriptions") &&
      order?.prescriptions.length > 0
    ) {
      let displayPrescriptionList = processDocsForDisplay(order?.prescriptions);
      setPrescriptionList(displayPrescriptionList);
    }
  }, [order]);
  return (
    <div className="col-12 p-2 row mx-0 online-order-text sticky-view-order-detail white cash-box">
      <section>
        <h6 className="font-weight-bold title">Order details</h6>
        <p>
          Order Id:{" "}
          <span className={"float-right font-weight-bold"}>
            {order?.orderCode}{" "}
          </span>
          <br />
          Order Placed On:{" "}
          <span className={"float-right font-weight-bold"}>
            {utility.convertToReadbleDateTimeUtc(order?.createdAt)}
          </span>
          <br />
          Delivery Type:
          <span className={"float-right font-weight-bold"}>
            {" "}
            {order?.deliveryType}
          </span>{" "}
          <br />
          Order Status:{" "}
          <span className={"float-right font-weight-bold"}>
            {" "}
            {order?.status === "OUTFORDELIVERY" &&
            order?.deliveryType === "SHIP"
              ? "SHIPPED"
              : order?.status}{" "}
            <span className={"text-capitalize"}>{cancelledBy(order)}</span>
          </span>{" "}
          <br />
          {order?.hasOwnProperty("cancellationNote") &&
            order?.cancellationNote !== "" && (
              <>
                Cancellation Note:{" "}
                <span className={"float-right font-weight-bold"}>
                  {order?.cancellationNote}
                </span>
                <br />
              </>
            )}
          Payment Mode:{" "}
          <span className={"float-right font-weight-bold"}>
            {order?.paymentMode}
          </span>{" "}
          <br />
          Payment Status:{" "}
          <span className={"float-right font-weight-bold"}>
            {order?.isPaid == "N" ? (
              <span style={{ color: "red" }}>NOT PAID</span>
            ) : (
              <span style={{ color: "green" }}>PAID</span>
            )}
          </span>{" "}
          <br />
          {order?.isPaid !== "N" && (
            <span>
              {" "}
              Paid amount:{" "}
              <span className={"float-right font-weight-bold"}>
                {order?.paidAmount}
              </span>{" "}
              <br />
            </span>
          )}
          Order Source:{" "}
          <span className={"float-right font-weight-bold"}>
            {order?.hasOwnProperty("orderSource") ? order?.orderSource : "WEB"}
          </span>{" "}
          <br />
          {order?.isPrescriptionRequired === "Y" && (
            <span className={"text-danger"}>
              <MDBIcon icon="file-prescription" /> <b>Prescription Required</b>
            </span>
          )}
          {prescriptionList?.length > 0 && (
            <div className={"col-12 p-0 my-3"}>
              <ul
                className="list-group list-group-horizontal-lg text-lg-center"
                style={{ clear: "both", display: "block", content: "" }}
              >
                {prescriptionList?.map((row, index) => (
                  <li
                    key={index}
                    className="list-group-item"
                    style={{ float: "left" }}
                  >
                    {createImageUrl(row.path)}
                    {row?.createdBy !== "" && (
                      <div className={"text-center"}>
                        Uploaded By: <br />
                        {row?.createdBy}
                        <br />
                      </div>
                    )}
                    <div className={"d-flex justify-content-center my-1"}>
                      File_{index + 1}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </p>
      </section>
      <section>
        <h6 className="font-weight-bold title">Customer Details</h6>
        <p>
          Name:{" "}
          <span className={"text-capitalize float-right font-weight-bold"}>
            {order?.customerDetails?.name}
          </span>{" "}
          <br />
          Phone Number:{" "}
          <span className={"float-right font-weight-bold"}>
            {order?.shippingAddress?.phone}
          </span>{" "}
          <br />
          {order?.deliveryType == "PICKUP" ||
            (order?.deliveryType == "DELIVER" && (
              <div>
                Delivery date:
                <span className={"float-right font-weight-bold"}>
                  {moment(order?.window?.date).format("ll")}
                </span>
                <br />
                Delivery slot:
                <span className={"float-right font-weight-bold"}>
                  {" "}
                  {order?.window?.start}-{order?.window?.end}
                </span>
                <br />
              </div>
            ))}
          Order Remarks:
          <span className={"float-right font-weight-bold"}>
            {" "}
            {order?.comments?.trim() === "" ? "None" : order?.comments}
          </span>
        </p>
      </section>
      <section>
        <h6 className="font-weight-bold title">Shipping Address</h6>
        <p>
          {order?.hasOwnProperty("shippingAddress") &&
          order?.shippingAddress !== "" ? (
            <>
              <span className={"text-capitalize"}>
                <b>{order?.shippingAddress?.name}</b>
              </span>
              <br />
              {order?.shippingAddress?.addressLine1}
              {order?.shippingAddress?.addressLine2 && (
                <span>,&nbsp;{order?.shippingAddress?.addressLine2}</span>
              )}
              <br />
              {order?.shippingAddress?.city}, {order?.shippingAddress?.state},{" "}
              {order?.shippingAddress?.pinCode},{" "}
              {order?.shippingAddress?.country}
              <br />
              {order?.shippingAddress?.phone !== "" && (
                <>Phone No: {order?.shippingAddress?.phone}</>
              )}
            </>
          ) : (
            <div className={"no-data-container"}>
              No shipping address found.
            </div>
          )}
        </p>
      </section>
      <section>
        <h6 className="font-weight-bold title">Pickup Address</h6>
        <p>
          {order?.hasOwnProperty("pickUpAddress") &&
          order?.pickUpAddress !== "" ? (
            <>
              <span className={"text-capitalize"}>
                {order?.pickUpAddress.name}
              </span>{" "}
              <br />
              {order?.pickUpAddress?.point?.addressLine1}
              {order?.pickUpAddress?.point?.addressLine2 && (
                <span>,&nbsp;{order?.pickUpAddress?.point?.addressLine2}</span>
              )}
              <br />
              {order?.pickUpAddress?.point?.city},{" "}
              {order?.pickUpAddress?.point?.state},{" "}
              {order?.pickUpAddress?.point?.pin},{" "}
              {order?.pickUpAddress?.point?.country}
              <br />
              Phone No: {order?.pickUpAddress?.phone}
            </>
          ) : (
            <div className={"no-data-container"}>No pickup address found.</div>
          )}
        </p>
      </section>
      <section>
        <h6 className="font-weight-bold title">Order Logs</h6>
        <p>
          {order?.hasOwnProperty("processLog") &&
          order?.processLog?.length > 0 ? (
            <>
              {order?.processLog?.map((item, index) => (
                <div key={index}>
                  <div className="my-2">
                    <p>
                      STATUS:{" "}
                      <span className={"float-right font-weight-bold"}>
                        {ORDER_LOG_FORMAT[item?.status]}{" "}
                      </span>
                      <br />
                      Order Created:{" "}
                      <span className={"float-right font-weight-bold"}>
                        {moment(item?.createdAt).format("MMM Do YYYY, h:mm a")}
                      </span>
                      <br />
                      Created By:
                      <span className={"float-right font-weight-bold"}>
                        {" "}
                        {textFormatter(item?.createdBy?.name)}
                      </span>{" "}
                      <br />
                    </p>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div className={"no-data-container"}>No Logs found.</div>
          )}
        </p>
      </section>
    </div>
  );
};

export default OrderDetails;
