import React,{Suspense} from "react";
import {
    MDBBox, MDBBreadcrumb, MDBBreadcrumbItem
} from 'mdbreact';
import ListInventoryV2 from "../../components/inventory/v3/listInv";
import {Link} from "react-router-dom";

export default class AllStock extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>View Stocks</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <MDBBox className={"min-vh-100"}>
                    <ListInventoryV2/>
                </MDBBox>
            </React.Fragment>
        );
    }
}
