import React from "react";
import ViewOrder from "./viewOrder";
import ManageStatus from "./manageStatus";
import { Link, withRouter } from "react-router-dom";
import {
  MDBCol,
  MDBIcon,
  MDBTabPane,
  MDBTabContent,
  MDBBreadcrumbItem,
  MDBBreadcrumb,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBBtn,
  MDBModalFooter
} from "mdbreact";
import FulfilOnlineOrder from "./fulfilOnlineOrder";
import OrderService from "../../services/orderService";
import UserStore from "../../stores/userStore";
import SimpleBackdrop from "../common/overlay";
import { toast } from "react-toastify";
import ReactToPrint from "react-to-print";
import Invoice from "./invoice";
import Moments from "moment";
import AuthService from "../../services/authService";
import NewTable from "../../newComponents/NewTable/NewTable";


class ProcessOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderId: props.hasOwnProperty("orderId") ? props.orderId : "",
      orderStatus: "CREATED",
      messageAck: "",
      messageApproved: "",
      messageReadyForHld: "",
      messageReadyForPickUp: "",
      messageDelivered: "",
      activeItem: null,
      activeOrderPills: "viewOrder",
      processLogs: [],
      trackLogToggle: false,
      addCallLogModalOpen: false,
      viewCallLogModalOpen: false,
      subject: '',
      summary: '',
      subjectError: false,
      summaryError: false,
      isSubmitting: false,
      callLogs: [],
      userId: '',
      customerCarePersonID: '',
    };
  }

  toggleOrderPills = (tab) => (e) => {
    if (this.state.activeOrderPills !== tab) {
      this.setState({
        activeOrderPills: tab,
      });
    }
  };
  async componentDidMount() {
    await this.updateOrder();
    const userData = await UserStore.fetchSessionData();
    console.log('User data fetched:', userData);
    if (userData) {
      this.setState({
        customerCarePersonID: userData._id,
        sessionToken: userData.sessionToken,
      });
    }
  }

  fetchOnlineOrderById = async (sessionToken, orderId) => {
    this.setState({ progressStatus: true });
    let data = await OrderService.fetchOnlineOrderById(sessionToken, orderId);
    this.setState({ progressStatus: false });
    return data;
  };
  updateOrder = async () => {
    let userData = await UserStore.fetchSessionData();
    let result = await this.fetchOnlineOrderById(
      userData.sessionToken,
      this.state.orderId
    );
    if (result) {
      this.setState({
        processLogs: result?.processLog,
        userId: result.userId,
      });
      if (result.hasOwnProperty("internalComment") === false) {
        result["internalComment"] = "";
      }
      //The bellow step is done to rerender all the components
      await this.setState({ activeItem: null });
      await this.setState({ activeItem: result });
      this.setState({ activeOrderPills: "taxInvoice" });
    } else {
      toast.error(
        "Something went wrong while trying to get the order details."
      );
    }
  };
  orderLogFormat = {
    ACKNOWLEDGED: "ACKNOWLEDGED",
    APPROVED: "APPROVED",
    READYFORDELIVERY: "READY FOR DELIVERY",
    OUTFORDELIVERY: "OUT FOR DELIVERY",
    COMPLETED: "COMPLETED",
  };
  textFormatter(input) {
    if (input.includes("_")) {
      let words = input
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1));
      return words.join(" ");
    } else {
      return input;
    }
  }
  goToPreviousLocation = () => {
    if(this.props.location.state?.path === '/unfulfilled-orders'){
      this.props.history.push({
        pathname: `/unfulfilled-orders`,
        state: {
          startDatePreserved: this.props.location.state?.startDate,
          endDatePreserved: this.props.location.state?.endDate,
          modePreserved: this.props.location.state?.mode,
          pagePreserved: this.props.location.state?.page,
          filterPreserved: this.props.location.state?.filter,
          searchTermPreserved: this.props.location.state?.searchTerm,
          showSearchValuePreserved: this.props.location.state?.showSearchValues,
          orderAmountPreserved: this.props.location.state?.orderAmount
        },
      });
    }else {
      this.props.history.push({
        pathname: `/view-online-order/${this.props.match.params.orderId}`,
        state: {
          startDate: this.props.location.state?.startDate,
          endDate: this.props.location.state?.endDate,
          mode: this.props.location.state?.mode,
          page: this.props.location.state?.page,
          pageSize: this.props.location.state?.pageSize
        },
      });
    }
  };

  addCustomerCallLog = async () => {
    const { subject, summary } = this.state;
    let hasError = false;

    if (!subject.trim()) {
      this.setState({ subjectError: true });
      hasError = true;
    }
    if (!summary.trim()) {
      this.setState({ summaryError: true });
      hasError = true;
    }

    if (hasError) {
      return;
    }
    this.setState({ isSubmitting: true });
    try {
      const { userId, customerCarePersonID, orderId } = this.state;

      const payload = {
        customerCarePersonID,
        subject,
        summary,
        userId,
        orderId
      };

      const response = await AuthService.saveAddCallLog(payload);

      if (response) {
        toast.success('Call log added successfully!', 'success');

        this.setState({
          addCallLogModalOpen: false,
          subject: '',
          summary: '',
          subjectError: false,
          summaryError: false,
          isSubmitting: false,
        });
      }
    } catch (error) {
      console.error('Error adding call log:', error);
      toast.error('Error adding call log. Please try again.', 'error');
    }
  };

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      [`${name}Error`]: false
    });
  };

  showToast = (message, type) => {
    if (type === "success") {
      toast.success(message);
    } else if (type === "error") {
      toast.error(message);
    }
  };

  fetchCustomerCallLog = async () => {
    try {
      const { sessionToken, orderId } = this.state;

      const response = await OrderService.fetchCallLogs(sessionToken, orderId);


      if (response && response.data) {
        this.setState({ callLogs: response.data });
      } else {
        this.setState({ callLogs: [] });
        toast.warning('Unexpected data format received from server');
      }
    } catch (error) {
      console.error('Error fetching call logs:', error);
      toast.error('Error fetching call logs. Please try again.');
      this.setState({ callLogs: [] });
    }
  };

  openViewCallLogModal = () => {
    this.setState({ viewCallLogModalOpen: true }, this.fetchCustomerCallLog);
  };

  formatTableData = (data) => {
    return data.map((log, index) => ({
      slNo: index + 1,
      subject: log.subject,
      summary: log.summary,
      createdBy: log.customerCarePersonName,
      createdAt: Moments(log.createdAt).format("DD-MMM-YYYY, h:mm A"),
    }));
  };
  render() {
    const columns = [
      { header: "Sl. No", accessorKey: "slNo" },
      { header: "Subject", accessorKey: "subject" },
      { header: "Summary", accessorKey: "summary" },
      { header: "Created By", accessorKey: "createdBy" },
      { header: "Created At", accessorKey: "createdAt" },
    ];

    return (
      <React.Fragment>
        <SimpleBackdrop status={this.state.progressStatus} />
        <MDBBreadcrumb>
          <MDBBreadcrumbItem>
            <Link to={"/dashboard"}>Dashboard</Link>
          </MDBBreadcrumbItem>
          <MDBBreadcrumbItem>
            <Link to={"/view-online-orders"}>View Online Orders</Link>
          </MDBBreadcrumbItem>
          {this.state.activeItem !== null && (
            <MDBBreadcrumbItem active>
              {this.state.activeItem.orderCode}
            </MDBBreadcrumbItem>
          )}
        </MDBBreadcrumb>
        <div className={"col-12"} style={{ marginTop: '-30px' }}>
          <MDBBtn size={"md"} onClick={this.goToPreviousLocation}>
            Go Back
          </MDBBtn>
        </div>
        <div className={"col-12 row mx-0 p-1"}>
          <div className={"col-md-12 m-auto p-0"}>
            {this.state.activeItem !== null && (
              <ManageStatus
                activeItem={this.state.activeItem}
                updateOrder={this.updateOrder}
              />
            )}
            <div className={"col-md-12 m-auto p-0"}>
              <button
                type="button"
                className="btn btn-outline-green waves-effect btn-md"
                onClick={() => this.setState({ trackLogToggle: true })}
              >
                <b>Track order logs</b>
              </button>
              <button
                type="button"
                className="btn btn-outline-blue waves-effect btn-md"
                onClick={() => this.setState({ addCallLogModalOpen: true })}
              >
                <b>Record Call</b>
              </button>
              <button
                type="button"
                className="btn btn-outline-blue waves-effect btn-md ml-2"
                onClick={this.openViewCallLogModal}
              >
                <b>View Call Log</b>
              </button>
            </div>

            <MDBTabPane className={"col-12"} tabId="1" role="tabpanel">
              <div className={"sticky-top"}>
                <div className={"col-12 d-flex p-0"}>
                  <a
                    className={
                      this.state.activeOrderPills === "viewOrder"
                        ? "header-tab-btn active"
                        : "header-tab-btn"
                    }
                    onClick={this.toggleOrderPills("viewOrder")}
                  >
                    VIEW ORDER DETAILS <MDBIcon icon="user" className="ml-2" />
                  </a>
                  <a
                    className={
                      this.state.activeOrderPills === "fulfillOrder"
                        ? "header-tab-btn active"
                        : "header-tab-btn"
                    }
                    onClick={this.toggleOrderPills("fulfillOrder")}
                  >
                    FULFILL ORDER
                    <MDBIcon icon="heart" className="ml-2" />
                  </a>
                  <a
                    className={
                      this.state.activeOrderPills === "taxInvoice"
                        ? "header-tab-btn active"
                        : "header-tab-btn"
                    }
                    onClick={this.toggleOrderPills("taxInvoice")}
                  >
                    TAX INVOICE
                    <MDBIcon icon="address-card" className="ml-2" />
                  </a>
                </div>
              </div>

              <MDBCol className={"col-12 p-0"}>
                <MDBTabContent
                  className={"p-0"}
                  activeItem={this.state.activeOrderPills}
                >
                  <MDBTabPane
                    tabId="viewOrder"
                    className={"fullfill-order-sticky"}
                  >
                    {this.state.activeItem !== null && (
                      <ViewOrder activeItem={this.state.activeItem} />
                    )}
                  </MDBTabPane>
                  <MDBTabPane tabId="fulfillOrder">
                    {this.state.activeItem !== null && (
                      <FulfilOnlineOrder
                        activeItem={this.state.activeItem}
                        updateOrder={this.updateOrder}
                      />
                    )}
                  </MDBTabPane>
                  <MDBTabPane tabId="taxInvoice">
                    <div className={"text-right"}>
                      <ReactToPrint
                        trigger={() => {
                          return (
                            <a href="#" className="primary-text">
                              Print the Invoice
                            </a>
                          );
                        }}
                        content={() => this.componentRef}
                      />
                    </div>

                    {this.state.activeItem !== null && (
                      <Invoice
                        ref={(el) => (this.componentRef = el)}
                        activeItem={this.state.activeItem}
                      />
                    )}
                  </MDBTabPane>
                </MDBTabContent>
              </MDBCol>
            </MDBTabPane>
          </div>
        </div>
        <MDBModal isOpen={this.state.trackLogToggle} position="center">
          <MDBModalHeader
            toggle={() => {
              this.setState({ trackLogToggle: false });
              //   this.toggle("AddPrescription");
            }}
          >
            Order Logs
          </MDBModalHeader>
          <MDBModalBody>
            {!this.state.processLogs ? (
              <span className="">No Order Logs Found</span>
            ) : (
              this.state.processLogs?.map((item) => (
                <div
                  className={
                    "col-12  mx-0 mb-4 p-0 py-2 text-center shadow-sm rounded"
                  }
                >
                  <h6>
                    STATUS: <b>{this.orderLogFormat[item.status]}</b>
                  </h6>
                  <span>
                    {" "}
                    Order Created:{" "}
                    {Moments(item.createdAt).format("MMM Do YYYY, h:mm a")}
                  </span>{" "}
                  <br />
                  <span>
                    {" "}
                    Created By: {this.textFormatter(item.createdBy.name)}
                  </span>{" "}
                  <br />
                </div>
              ))
            )}
          </MDBModalBody>
        </MDBModal>

        <MDBModal
          isOpen={this.state.addCallLogModalOpen}
          toggle={() => this.setState({ addCallLogModalOpen: false })}
        >
          <MDBModalHeader toggle={() => this.setState({ addCallLogModalOpen: false })}>
            Record Call
          </MDBModalHeader>
          <MDBModalBody>
            <div className="form-group">
              <label htmlFor="subject">Subject</label>
              <input
                id="subject"
                className={`form-control ${this.state.subjectError ? 'is-invalid' : ''}`}
                type="text"
                value={this.state.subject}
                onChange={(e) => this.setState({ subject: e.target.value, subjectError: false })}
                placeholder="Enter subject here..."
              />
              {this.state.subjectError && <div className="invalid-feedback">Please fill the subject field.</div>}
            </div>
            <div className="form-group">
              <label htmlFor="summary">Summary</label>
              <textarea
                id="summary"
                className={`form-control ${this.state.summaryError ? 'is-invalid' : ''}`}
                rows="3"
                value={this.state.summary}
                onChange={(e) => this.setState({ summary: e.target.value, summaryError: false })}
                placeholder="Enter summary here..."
                style={{ backgroundColor: "white", border: "1px solid grey" }}
              />
              {this.state.summaryError && <div className="invalid-feedback">Please fill the summary field.</div>}
            </div>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="default" onClick={() => this.setState({ addCallLogModalOpen: false })}>Cancel</MDBBtn>
            <MDBBtn
              color="default"
              onClick={this.addCustomerCallLog}
              disabled={this.state.isSubmitting} s
            >
              {this.state.isSubmitting ? 'Saving...' : 'Save'}
            </MDBBtn>

          </MDBModalFooter>
        </MDBModal>

        <MDBModal
          isOpen={this.state.viewCallLogModalOpen}
          toggle={() => this.setState({ viewCallLogModalOpen: false })}
        >
          <MDBModalHeader toggle={() => this.setState({ viewCallLogModalOpen: false })}>
            View Call Logs
          </MDBModalHeader>
          <MDBModalBody>
            {this.state.callLogs.length > 0 ? (
              <NewTable
                columns={columns}
                data={this.formatTableData(this.state.callLogs)}
                pagination={false}
              />
            ) : (
              <p>No call logs available</p>
            )}
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="default" onClick={() => this.setState({ viewCallLogModalOpen: false })}>Close</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </React.Fragment>
    );
  }
}

export default withRouter(ProcessOrder);