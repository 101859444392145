import React from "react";
import { MDBBox, MDBBreadcrumb, MDBBreadcrumbItem } from "mdbreact";
import { Link } from "react-router-dom";
import ViewFetchedStocks1 from "../../components/inventory/v3/newViewFetchedStocks";
function FetchStocksNew() {
    return (
        <>
            <MDBBreadcrumb>
                <MDBBreadcrumbItem>
                    <Link to={"/dashboard"}>Dashboard</Link>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem active>Fetch Stocks</MDBBreadcrumbItem>
            </MDBBreadcrumb>
            <MDBBox className={"min-vh-100"}>
                {/* <ListInventoryV2/> */}
                <ViewFetchedStocks1 />
            </MDBBox>
        </>
    );
}

export default FetchStocksNew;
