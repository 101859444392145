import React from "react";
import {MDBBreadcrumb, MDBBreadcrumbItem} from "mdbreact";
import {Link} from "react-router-dom";
import ListProductGroupCmp from "../../components/productGrouping/listProductGroup";
class ListProductGroup extends React.Component {
    render() {
        return(
            <React.Fragment>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>List Group</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <ListProductGroupCmp/>
            </React.Fragment>
        )
    }
}
export default ListProductGroup;