import React from "react";
import {withRouter} from "react-router-dom";
import SimpleBackdrop from "../common/overlay";
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCol,
    MDBContainer,
    MDBDataTable, MDBDataTableV5,
    MDBInput, MDBModal, MDBModalBody, MDBModalHeader,
    MDBRow, MDBBadge, MDBSelectInput, MDBSelectOptions, MDBSelectOption, MDBSelect
} from "mdbreact";
import UserStore from "../../stores/userStore";
import {toast} from "react-toastify";
import dataTableConfig from "./dataTableProducts";
import ReportService from "../../services/reports";
import utility from "../../utility/utility";
import UpdateProductConfig from "../../pages/product/cms";
import UpdateProduct from "../../pages/product/editPkgDetails";
import VerifyProduct from "./verifyProduct";
import ProductView from "../product/productView";
import StockAvailability from "./stockAvailability";
import config from "../../config";
import InventoryService from "../../services/inventoryService";

class MyTask extends React.Component {
    constructor() {
        super();
        this.state = {
            progressStatus: false,
            type: "all",
            products: [],
            tableData: {columns: [], rows: []},
            missingField:false,

            productName : true,
            medicineType : false,
            retailPackage : false,
            quantityUnit : false,
            contentQuantity: false,
            mrp:false,
            stock: false,
            description:false,
            manufacturer:false,
            highlights:false,
            tags:false,
            howToUse:false,
            ingredients:false,
            sideEffects:false,
            productUses:false,
            images:false,


            productNameMissing : false,
            medicineTypeMissing : false,
            retailPackageMissing : false,
            quantityUnitMissing : false,
            contentQuantityMissing: false,
            mrpMissing:false,
            stockMissing: false,
            descriptionMissing:false,
            manufacturerMissing:false,
            highlightsMissing:false,
            tagsMissing:false,
            howToUseMissing:false,
            ingredientsMissing:false,
            sideEffectsMissing:false,
            productUsesMissing:false,
            imagesMissing:false,

            modalStatus:false,
            activeProductId:0,
            modalEdit:false,
            modalView:false,
            activeProduct:{

            },
            modalAction:"",
            disableRetreatAfterSorting:false,
            medType:[{"_id":"5e75ea4e389b6a6026622a91","createdAt":"","createdBy":"","enabled":"Y","typeDescription":"No prescription required","typeName":"OTC","updatedAt":"","updatedby":"","verified":"Y"},{"_id":"5e75ea44389b6a6026622a90","createdAt":"","createdBy":"","enabled":"Y","typeDescription":"Prescription required","typeName":"Schedule H","updatedAt":"","updatedby":"","verified":"Y"},{"_id":"5e75ea54389b6a6026622a92","createdAt":"","createdBy":"","enabled":"Y","typeDescription":"Prescription required","typeName":"Schedule X","updatedAt":"","updatedby":"","verified":"Y"},{"_id":"5e75ea22389b6a6026622a8f","createdAt":"","createdBy":"","enabled":"Y","typeDescription":"Prescription required","typeName":"Schedule H1","updatedAt":"","updatedby":"","verified":"Y"}],
            recentOTC:[],
            recentH1:[],
            recentH:[],
            recentX:[],
        }
    }

    async componentDidMount() {
        this.fetchData()

    }

    ifRecentOTC = (id) =>{
        console.log(id)
        if(this.state.recentOTC.indexOf(id) >= 0){
           return true
        }
    }

    ifRecentH = (id) =>{
        console.log(id)
        if(this.state.recentH.indexOf(id) >= 0){
            return true
        }
    }

    ifRecentH1 = (id) =>{
        if(this.state.recentH1.indexOf(id) >= 0){
            return true
        }
    }

    ifRecentX = (id) =>{
        if(this.state.recentX.indexOf(id) >= 0){
            return true
        }
    }

    qntyTypeChange=(val)=>{
        this.setState({selectedMedType:val[0]});
    }
    handleChangeMedicineType = (e) => {
        alert(e)
       // alert(index)
        if (e.length > 0) {
            alert(e[0])
           // this.filterBreedByClass(e[0])
            //let pet = this.state.pet
           // pet.petClass = e[0]
           // this.setState({pet: pet, enableBreedList: false})
        }
    };

    changeToOtc = (row) => {
        let otcList = this.state.recentOTC;
        otcList.push(row._id)
        this.setState({recentOTC: otcList})
        this.updateMedicineType(row, "OTC")
        console.log(otcList)
    }
    changeToScheduleH= (row) => {
        let hList = this.state.recentH;
        hList.push(row._id)
        this.setState({recentH: hList})
        this.updateMedicineType(row, "Schedule H")
    }
    changeToScheduleH1= (row) => {
        let h1List = this.state.recentH1;
        h1List.push(row._id)
        this.setState({recentH1: h1List})
        this.updateMedicineType(row, "Schedule H1")
    }
    changeToScheduleX= (row) => {
        let xList = this.state.recentX;
        xList.push(row._id)
        this.setState({recentH1: xList})
        this.updateMedicineType(row, "Schedule X")
    }

    changeToScheduleGen = (row) => {

        this.updateMedicineType(row, "")
    }

    updateMedicineType = async (row, type) => {
        console.log(row)
        let userData=await UserStore.fetchSessionData();
        let status = await InventoryService.updateMedicineType(userData, row._id, type)

        if(status===false){
            toast.error("We are facing some issue. Please try again later.");

            return true;
        }else{
            //this.setState({resetForm:true})
            type = !type ? "Gen Product" : type;
            toast.success(row.productName + " changed to " + type);
            //this.fetchData()

            // window.productList=null;
            // if(this.state.loadedOnPopup === true){
            //     this.props.onEditComplete()
            // }
        }


        // let status=await InventoryService.updateProduct(this.state.looseSaleAllowed,this.state.minimumLooseSaleQuantity,this.state._id,this.state.isDrug,this.state.tags,this.state.selectedMedType,mfgId,this.state,this.state.pkgQnty,this.state.pakgValSel[0],this.state.qntyUnitSel[0],this.state.productName,userData,this.state.highlight,this.state.description,this.state.howTouse,this.state.productIngredients,this.state.productSideEffects,this.state.productUses);
        // this.setState({progressStatus:false});
        // if(status===false){
        //     toast.error("We are facing some issue. Please try again later.");
        //     return true;
        // }else{
        //     this.setState({resetForm:true})
        //     toast.success("Product edited successfully.");
        //     window.productList=null;
        //     if(this.state.loadedOnPopup === true){
        //         this.props.onEditComplete()
        //     }
        // }
    }

    fetchData = () =>{
        this.getProductsList().then(res => {
            if (res === false) {
                toast.error("We are facing some issue, please try again later.")
            } else {
                this.setState({products: res})
                let temp = this.state.products,
                    dataTablePayload = {
                        columns: dataTableConfig.assignProduct,
                        rows:this.state.products.map((item, index) => {
                            item["viewProductDetails"]=<span color="primary" className={"blue-text btn-link cursor-pointer"} size="sm"
                                                             onClick={() => {
                                                                 this.navigateToProductDetails(item)
                                                             }}> Product Details </span>
                            item["retailPackage"]=<span className={utility.validateRetailPackage(item.retailPackage) ? "green-text":"red-text"}>{item.retailPackage}</span>;
                            item["quantityUnit"]=<span className={utility.validateQuantityUnit(item.quantityUnit) ? "green-text":"red-text"}>{item.quantityUnit}</span>;
                            item["medicineTypeFormatted"]=<div><span className={utility.validateMedicineType(true, item.medicineType) ? "green-text":"red-text"}></span>

                                {this.state.medType.map((row, idx) => (
                                    <>
                                        {row.typeName === "OTC" &&
                                        <MDBBtn className={"px-3"} size={"sm"} onClick={()=>this.changeToOtc(item)} color={(item.medicineType === "OTC" || this.ifRecentOTC(item._id)) ? "primary" :"light"}>OTC</MDBBtn>
                                        }
                                        {row.typeName === "Schedule H" &&
                                        <MDBBtn className={"px-3"} onClick={()=>this.changeToScheduleH(item)} color={(item.medicineType === "Schedule H" || this.ifRecentH(item._id) ) ? "primary" :"light"} size={"sm"}>H</MDBBtn>
                                        }
                                        {row.typeName === "Schedule H1" &&
                                        <MDBBtn className={"px-3"} onClick={()=>this.changeToScheduleH1(item)} color={(item.medicineType === "Schedule H1" || this.ifRecentH1(item._id)) ? "primary" :"light"} size={"sm"}>H1</MDBBtn>
                                        }
                                        {row.typeName === "Schedule X" &&
                                        <MDBBtn className={"px-3"} onClick={()=>this.changeToScheduleX( item)} color={(item.medicineType === "Schedule X" || this.ifRecentX(item._id) ) ? "primary" :"light"} size={"sm"}>X</MDBBtn>
                                        }

                                    </>
                                ))}
                                <MDBBtn className={"px-3"} color={(item.medicineType === "PRESCRIPTION DRUGS" || item.medicineType === "PRESCRIPTION DRUG") ? "primary" :"light"} size={"sm"}>PD(H/H1/X)</MDBBtn>
                                <MDBBtn className={"px-3"} onClick={()=>this.changeToScheduleGen(item)} color={(item.medicineType === ""  ) ? "primary" :"light"} size={"sm"}>Gen</MDBBtn>


                            </div>;
                            item["contentQuantity"]=<span className={utility.validateContentQuantity( item.contentQuantity) ? "green-text":"red-text"}>{item.contentQuantity}</span>;
                            item["mrp"]=<span className={utility.validateMrp( item.mrp) ? "green-text":"red-text"}>{item.mrp}</span>;
                            item["stock"]=<span className={utility.validateStock( item.stock) ? "green-text":"red-text"}>{item.stock}</span>;


                            // item["handleCreatedAt"]=utility.convertToReadbleDate(item.createdAt);
                            item["images"]=<div>
                                {item.imageUrl.map((row, index) => (
                                    <div className={"float-left m-1"}>

                                        {(((row.filePathThumb.indexOf(".png") > 0) || (row.filePathThumb.indexOf(".jpg") > 0) || (row.filePathThumb.indexOf(".jpeg") > 0)) && index < 3) &&
                                        <div className={(this.state.selectedImageIndex === index) ? "border-info center" : "center"} style={{"border":"2px solid #CCC",width: "45px", height: "45px"}}>
                                            <img src={config.IMAGE_HOST + row.filePathThumb} className={"float-left"} style={{maxWidth: "40px", maxHeight: "40px"}} alt={""}/>
                                        </div>
                                        }
                                        {index > 2 &&
                                        <div
                                            className={(this.state.selectedImageIndex === index) ? "border-info center" : "center"}
                                            style={{"border": "2px solid #CCC", width: "45px", height: "45px"}}>
                                            <div style={{"fontSize":"20px", "margin":"5px", "paddingLeft":"5px"}} ><b> +{item.imageUrl.length - 3}</b></div>
                                        </div>
                                        }

                                    </div>
                                ))}
                            </div>;

                            item["handleStockAvailability"]=<div>
                                {item.hasOwnProperty("stockAvailability") &&
                                <div style={{cursor:"pointer"}}>
                                    {item.stockAvailability === "DEFAULT" &&
                                    <div style={{color:"green"}} onClick={()=>this.onUpdateStockAvailability(item)} >
                                        <div style={{"fontSize":"17px"}}><b>Default</b></div>

                                    </div>
                                    }
                                    {item.stockAvailability === "ALWAYS_AVAILABLE" &&
                                    <div style={{color:"green"}} onClick={()=>this.onUpdateStockAvailability(item)} >
                                        <div style={{"fontSize":"17px"}}><b>Always Available</b></div>

                                    </div>
                                    }
                                    {item.stockAvailability === "EASILY_AVAILABLE" &&
                                    <div style={{color:"green"}} onClick={()=>this.onUpdateStockAvailability(item)} >
                                        <div style={{"fontSize":"17px"}}><b>Easily Available</b></div>

                                    </div>
                                    }
                                    {item.stockAvailability === "DIFFICULT_TO_PROCURE" &&
                                    <div style={{color:"orange"}} onClick={()=>this.onUpdateStockAvailability(item)} >
                                        <div style={{"fontSize":"17px"}}><b>Difficult to Procure</b></div>

                                    </div>
                                    }

                                    {item.verificationStatus === "TEMPORARILY_UNAVAILABLE" &&
                                    <div style={{color:"red"}} onClick={()=>this.onUpdateStockAvailability(item)} >
                                        <div style={{"fontSize":"17px"}}><b>Temporarily Unavailable</b></div>

                                    </div>
                                    }
                                    {item.verificationStatus === "PERMANENTLY_UNAVAILABLE" &&
                                    <div style={{color:"red"}} onClick={()=>this.onUpdateStockAvailability(item)} >
                                        <div style={{"fontSize":"17px"}}><b>Permanently Unavailable</b></div>

                                    </div>
                                    }
                                </div>
                                }
                                {!item.hasOwnProperty("stockAvailability") &&
                                <div><span color="primary" className={"blue-text btn-link cursor-pointer"} size="sm"
                                           onClick={() => {
                                               this.onUpdateStockAvailability(item)
                                           }}>Update Stock</span></div>
                                }
                            </div>

                            item["handleVerification"]=<div>
                                {item.hasOwnProperty("verificationStatus") &&
                                <div style={{cursor:"pointer"}}>
                                    {item.verificationStatus === "READY_TO_PUBLISH" &&
                                    <div style={{color:"green"}} onClick={()=>this.onMark(item)} >
                                        <div style={{"fontSize":"17px"}}><b>Ready To Publish</b></div>
                                        <div style={{"fontSize":"12px"}}>{utility.convertToReadbleDaySmart2(item.verificationLog[item.verificationLog.length -1].verifiedOn)}</div>
                                        <div style={{"fontSize":"12px"}}>{item.verificationLog[item.verificationLog.length -1].verifiedByUserName}</div>
                                    </div>
                                    }
                                    {item.verificationStatus === "MANUALLY_VERIFIED" &&
                                    <div style={{color:"green"}} onClick={()=>this.onMark(item)} >
                                        <div style={{"fontSize":"17px"}}><b>Verified</b></div>
                                        <div style={{"fontSize":"12px"}}>{utility.convertToReadbleDaySmart2(item.verificationLog[item.verificationLog.length -1].verifiedOn)}</div>
                                        <div style={{"fontSize":"12px"}}>{item.verificationLog[item.verificationLog.length -1].verifiedByUserName}</div>
                                    </div>
                                    }
                                    {item.verificationStatus === "JUNK" &&
                                    <div style={{color:"red"}} onClick={()=>this.onMark(item)} >
                                        <div style={{"fontSize":"17px"}}><b>Junk</b></div>
                                        <div style={{"fontSize":"12px"}}>{utility.convertToReadbleDaySmart2(item.verificationLog[item.verificationLog.length -1].verifiedOn)}</div>
                                        <div style={{"fontSize":"12px"}}>{item.verificationLog[item.verificationLog.length -1].verifiedByUserName}</div>
                                    </div>
                                    }
                                    {item.verificationStatus === "DUPLICATE" &&
                                    <div style={{color:"orange"}} onClick={()=>this.onMark(item)} >
                                        <div style={{"fontSize":"17px"}}><b>Duplicate</b></div>
                                        <div style={{"fontSize":"12px"}}>{utility.convertToReadbleDaySmart2(item.verificationLog[item.verificationLog.length -1].verifiedOn)}</div>
                                        <div style={{"fontSize":"12px"}}>{item.verificationLog[item.verificationLog.length -1].verifiedByUserName}</div>
                                    </div>
                                    }
                                    {item.verificationStatus === "INCOMPLETE" &&
                                    <div style={{color:"orange"}} onClick={()=>this.onMark(item)} >
                                        <div style={{"fontSize":"17px"}}><b>Incomplete</b></div>
                                        <div style={{"fontSize":"12px"}}>{utility.convertToReadbleDaySmart2(item.verificationLog[item.verificationLog.length -1].verifiedOn)}</div>
                                        <div style={{"fontSize":"12px"}}>{item.verificationLog[item.verificationLog.length -1].verifiedByUserName}</div>
                                    </div>
                                    }
                                    {item.verificationStatus === "INCORRECT" &&
                                    <div style={{color:"orange"}} onClick={()=>this.onMark(item)} >
                                        <div style={{"fontSize":"17px"}}><b>Incorrect</b></div>
                                        <div style={{"fontSize":"12px"}}>{utility.convertToReadbleDaySmart2(item.verificationLog[item.verificationLog.length -1].verifiedOn)}</div>
                                        <div style={{"fontSize":"12px"}}>{item.verificationLog[item.verificationLog.length -1].verifiedByUserName}</div>
                                    </div>
                                    }
                                </div>
                                }
                                {!item.hasOwnProperty("verificationStatus") &&
                                <div><span color="primary" className={"blue-text btn-link cursor-pointer"} size="sm"
                                           onClick={() => {
                                               this.onMark(item)
                                           }}>Mark</span></div>
                                }
                            </div>
                            // item["handleAction"] = <div>
                            //                     <span color="primary" className={"blue-text btn-link cursor-pointer"} size="sm"
                            //                           onClick={() => {
                            //                               this.onView(item)
                            //                           }}> View </span>
                            //                     <span color="primary" className={"blue-text btn-link cursor-pointer"} size="sm"
                            //                          onClick={() => {
                            //                              this.onEditDesc(item)
                            //                          }}> | Edit</span>
                            //
                            //                     <span color="primary" className={"blue-text btn-link cursor-pointer"} size="sm"
                            //                           onClick={() => {
                            //                               this.onEdit(item)
                            //                           }}> | Config</span>


                            // </div>;

                            return item;
                        })

                        // rows: this.state.products
                    }
                this.setState({tableData: dataTablePayload})
            }
        })
    }

    getProductsList = async () => {
        let userData = await UserStore.fetchSessionData();
        this.setState({progressStatus: true});
        let data = await ReportService.fetchProductWithCompleteData(userData, 0, 0, this.state.type, this.state);
        this.setState({progressStatus: false});
        return data
    }

    toggleView = () => {
        this.setState({
            modalView: !this.state.modalView
        });
    }

    toggleEdit = () => {
        this.setState({
            modalEdit: !this.state.modalEdit
        });
    }
    onEditComplete = (row) => {
        this.toggleEdit()
        this.fetchData();
    }
    // This triggers from MDBDataTable, aim is to redirect to edit page
    onEditDesc =  (row) => {
        this.setState({activeProduct: row,modalAction:"edit"})
        this.toggleEdit()
    }

    onEdit=(item)=>{
        // this.props.history.push('/product/cms/'+item._id);
        this.setState({activeProduct: item, modalAction:"updateConfig"})
        this.toggleEdit()
    }

    onMark=(item)=>{
        // this.props.history.push('/product/cms/'+item._id);
        this.setState({activeProduct: item, modalAction:"mark"})
        this.toggleEdit()
    }

    onUpdateStockAvailability=(item)=>{
        // this.props.history.push('/product/cms/'+item._id);
        this.setState({activeProduct: item, modalAction:"stockAvailability"})
        this.toggleEdit()
    }

    onView =  (row) => {
        this.setState({activeProduct: row})
        this.toggleView()
    }

    navigateToProductDetails = (row) => {
        this.props.history.push('/product-details/'+row._id);
    }

    handleMissingField = (val) => {
        this.setState({missingField:val})
    }

    handleProductName = (val) => {
        if(this.state.productNameMissing === true){
            toast.error("You can't select same field for both group.")
            return false;
        }
        this.setState({productName:val})
    }
    handleMedicineType = (val) => {
        if(this.state.medicineTypeMissing === true){
            toast.error("You can't select same field for both group.")
            return false;
        }
        this.setState({medicineType:val})
    }
    handleRetailPackage = (val) => {
        if(this.state.retailPackageMissing === true){
            toast.error("You can't select same field for both group.")
            return false;
        }
        this.setState({retailPackage:val})
    }
    handleQuantityUnit = (val) => {
        if(this.state.quantityUnitMissing === true){
            toast.error("You can't select same field for both group.")
            return false;
        }
        this.setState({quantityUnit:val})
    }
    handleContentQuantity = (val) => {
        if(this.state.contentQuantityMissing === true){
            toast.error("You can't select same field for both group.")
            return false;
        }
        this.setState({contentQuantity:val})
    }
    handleMrp = (val) => {
        if(this.state.mrpMissing === true){
            toast.error("You can't select same field for both group.")
            return false;
        }
        this.setState({mrp:val})
    }
    handleStock = (val) => {
        if(this.state.stockMissing === true){
            toast.error("You can't select same field for both group.")
            return false;
        }
        this.setState({stock:val})
    }

    handleDescription = (val) => {
        if(this.state.descriptionMissing === true){
            toast.error("You can't select same field for both group.")
            return false;
        }
        this.setState({description:val})
    }
    handleManufacturer = (val) => {
        if(this.state.manufacturerMissing === true){
            toast.error("You can't select same field for both group.")
            return false;
        }
        this.setState({manufacturer:val})
    }
    handleHighlights = (val) => {
        if(this.state.highlightsMissing === true){
            toast.error("You can't select same field for both group.")
            return false;
        }
        this.setState({highlights:val})
    }
    handleTags = (val) => {
        if(this.state.tagsMissing === true){
            toast.error("You can't select same field for both group.")
            return false;
        }
        this.setState({tags:val})
    }
    handleHowToUse = (val) => {
        if(this.state.howToUseMissing === true){
            toast.error("You can't select same field for both group.")
            return false;
        }
        this.setState({howToUse:val})
    }
    handleIngredients = (val) => {
        if(this.state.ingredientsMissing === true){
            toast.error("You can't select same field for both group.")
            return false;
        }
        this.setState({ingredients:val})
    }

    handleSideEffects = (val) => {
        if(this.state.sideEffectsMissing === true){
            toast.error("You can't select same field for both group.")
            return false;
        }
        this.setState({sideEffects:val})
    }
    handleProductUses = (val) => {
        if(this.state.productUsesMissing === true){
            toast.error("You can't select same field for both group.")
            return false;
        }
        this.setState({productUses:val})
    }
    handleImages = (val) => {
        if(this.state.imagesMissing === true){
            toast.error("You can't select same field for both group.")
            return false;
        }
        this.setState({images:val})
    }

    // Missing fields
    handleProductNameMissing = (val) => {
        if(this.state.productName === true){
            toast.error("You can't select same field for both group.")
            return false;
        }
        this.setState({productNameMissing:val})
    }
    handleMedicineTypeMissing = (val) => {
        if(this.state.medicineType === true){
            toast.error("You can't select same field for both group.")
            return false;
        }
        this.setState({medicineTypeMissing:val})
    }
    handleRetailPackageMissing = (val) => {
        if(this.state.retailPackage === true){
            toast.error("You can't select same field for both group.")
            return false;
        }
        this.setState({retailPackageMissing:val})
    }
    handleQuantityUnitMissing = (val) => {
        if(this.state.quantityUnit === true){
            toast.error("You can't select same field for both group.")
            return false;
        }
        this.setState({quantityUnitMissing:val})
    }
    handleContentQuantityMissing = (val) => {
        if(this.state.contentQuantity === true){
            toast.error("You can't select same field for both group.")
            return false;
        }
        this.setState({contentQuantityMissing:val})
    }
    handleMrpMissing = (val) => {
        if(this.state.mrp === true){
            toast.error("You can't select same field for both group.")
            return false;
        }
        this.setState({mrpMissing:val})
    }
    handleStockMissing = (val) => {
        if(this.state.stock === true){
            toast.error("You can't select same field for both group.")
            return false;
        }
        this.setState({stockMissing:val})
    }

    handleDescriptionMissing = (val) => {
        if(this.state.description === true){
            toast.error("You can't select same field for both group.")
            return false;
        }
        this.setState({descriptionMissing:val})
    }
    handleManufacturerMissing = (val) => {
        if(this.state.manufacturer === true){
            toast.error("You can't select same field for both group.")
            return false;
        }
        this.setState({manufacturerMissing:val})
    }
    handleHighlightsMissing = (val) => {
        if(this.state.highlights === true){
            toast.error("You can't select same field for both group.")
            return false;
        }
        this.setState({highlightsMissing:val})
    }
    handleTagsMissing = (val) => {
        if(this.state.tags === true){
            toast.error("You can't select same field for both group.")
            return false;
        }
        this.setState({tagsMissing:val})
    }
    handleHowToUseMissing = (val) => {
        if(this.state.howToUse === true){
            toast.error("You can't select same field for both group.")
            return false;
        }
        this.setState({howToUseMissing:val})
    }
    handleIngredientsMissing = (val) => {
        if(this.state.ingredients === true){
            toast.error("You can't select same field for both group.")
            return false;
        }
        this.setState({ingredientsMissing:val})
    }

    handleSideEffectsMissing = (val) => {
        if(this.state.sideEffects === true){
            toast.error("You can't select same field for both group.")
            return false;
        }
        this.setState({sideEffectsMissing:val})
    }
    handleProductUsesMissing = (val) => {
        if(this.state.productUses === true){
            toast.error("You can't select same field for both group.")
            return false;
        }
        this.setState({productUsesMissing:val})
    }
    handleImagesMissing = (val) => {
        if(this.state.images === true){
            toast.error("You can't select same field for both group.")
            return false;
        }
        this.setState({imagesMissing:val})
    }

    showLogs2 = (e) => {
        console.log(e)
    }

    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.progressStatus}/>
                <MDBContainer className={'w-100 mw-100'}>
                    <MDBCard className={"mt-2"}>
                        <MDBCardHeader>
                            <MDBRow >
                                <MDBCol>
                                    <div style={{fontSize:"13px"}}><b>Please select the fields as filter criteria.</b></div>
                                    <div style={{fontSize:"11px"}}><b>Note:</b> Selecting all the fields will return only the products which has all the fields are filled.</div>
                                    <div style={{fontSize:"11px"}}><b>Note:</b> Leaving all the fields unchecked will return all the products. </div>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardHeader>
                        <MDBCardBody>

                            <MDBRow style={{fontSize:"11px"}}>
                                <MDBCol className={"m-0 p-0"}>
                                    <MDBInput  checked={this.state.productName} label="Product Name" type="checkbox" id="productName" getValue={this.handleProductName} />
                                </MDBCol>
                                <MDBCol className={"m-0 p-0"}>
                                    <MDBInput  checked={this.state.medicineType} label="Medicine Type" type="checkbox" id="medicineType" getValue={this.handleMedicineType} />
                                </MDBCol >
                                <MDBCol className={"m-0 p-0"}>
                                    <MDBInput checked={this.state.retailPackage} label="Retail Package" type="checkbox" id="retailPackage" getValue={this.handleRetailPackage}  />
                                </MDBCol>
                                <MDBCol className={"m-0 p-0"}>
                                    <MDBInput checked={this.state.quantityUnit} label="Quantity Unit" type="checkbox" id="quantityUnit" getValue={this.handleQuantityUnit} />
                                </MDBCol>
                                <MDBCol className={"m-0 p-0"}>
                                    <MDBInput   checked={this.state.contentQuantity} label="Content Quantity" type="checkbox" id="contentQuantity" getValue={this.handleContentQuantity} />
                                </MDBCol>
                                <MDBCol className={"m-0 p-0"}>
                                    <MDBInput  checked={this.state.mrp} label="MRP" type="checkbox" id="mrp" getValue={this.handleMrp} />
                                </MDBCol>
                                <MDBCol className={"m-0 p-0"}>
                                    <MDBInput  checked={this.state.stock} label="Stock" type="checkbox" id="stock" getValue={this.handleStock} />
                                </MDBCol>
                                <MDBCol className={"m-0 p-0"}>
                                    <MDBInput  checked={this.state.description} label="Description" type="checkbox" id="Description" getValue={this.handleDescription} />
                                </MDBCol>

                            </MDBRow>
                            <MDBRow style={{fontSize:"11px"}}>

                                <MDBCol className={"m-0 p-0"}>
                                    <MDBInput size={1} checked={this.state.manufacturer} label="Manufacturer" type="checkbox" id="manufacturer" getValue={this.handleManufacturer} />
                                </MDBCol>
                                <MDBCol className={"m-0 p-0"}>
                                    <MDBInput size={1} checked={this.state.highlights} label="Highlights" type="checkbox" id="highlights" getValue={this.handleHighlights}  />
                                </MDBCol>
                                <MDBCol className={"m-0 p-0"}>
                                    <MDBInput size={1}  checked={this.state.tags} label="Tags" type="checkbox" id="tags" getValue={this.handleTags} />
                                </MDBCol>
                                <MDBCol className={"m-0 p-0"}>
                                    <MDBInput size={1}  checked={this.state.howToUse} label="How To Use" type="checkbox" id="howTouse" getValue={this.handleHowToUse} />
                                </MDBCol>
                                <MDBCol className={"m-0 p-0"}>
                                    <MDBInput size={1}  checked={this.state.ingredients} label="Ingredients" type="checkbox" id="ingredients" getValue={this.handleIngredients} />
                                </MDBCol>
                                <MDBCol className={"m-0 p-0"}>
                                    <MDBInput size={1}  checked={this.state.sideEffects} label="Side Effects" type="checkbox" id="sideEffects" getValue={this.handleSideEffects} />
                                </MDBCol>
                                <MDBCol className={"m-0 p-0"}>
                                    <MDBInput size={1}  checked={this.state.productUses} label="Product Uses" type="checkbox" id="productUses" getValue={this.handleProductUses} />
                                </MDBCol>
                                <MDBCol className={"m-0 p-0"}>
                                    <MDBInput size={1}  checked={this.state.images} label="Images" type="checkbox" id="Images" getValue={this.handleImages} />
                                </MDBCol>

                            </MDBRow>

                            {this.state.missingField === true &&
                            <MDBRow style={{fontSize:"13px"}}>
                                <MDBCol className={"mt-1 mb-1"}>
                                    <b>Please select the appropriate checkboxes below to find which fields are missing/incorrect data.</b>
                                </MDBCol>
                            </MDBRow>
                            }
                            {this.state.missingField === true &&
                            <MDBRow style={{fontSize: "11px"}}>
                                <MDBCol className={"m-0 p-0"}>
                                    <MDBInput checked={this.state.productNameMissing} label="Product Name"
                                              type="checkbox" id="productNameMissing"
                                              getValue={this.handleProductNameMissing}/>
                                </MDBCol>
                                <MDBCol className={"m-0 p-0"}>
                                    <MDBInput checked={this.state.medicineTypeMissing} label="Medicine Type"
                                              type="checkbox" id="medicineTypeMissing"
                                              getValue={this.handleMedicineTypeMissing}/>
                                </MDBCol>
                                <MDBCol className={"m-0 p-0"}>
                                    <MDBInput checked={this.state.retailPackageMissing} label="Retail Package"
                                              type="checkbox" id="retailPackageMissing"
                                              getValue={this.handleRetailPackageMissing}/>
                                </MDBCol>
                                <MDBCol className={"m-0 p-0"}>
                                    <MDBInput checked={this.state.quantityUnitMissing} label="Quantity Unit"
                                              type="checkbox" id="quantityUnitMissing"
                                              getValue={this.handleQuantityUnitMissing}/>
                                </MDBCol>
                                <MDBCol className={"m-0 p-0"}>
                                    <MDBInput checked={this.state.contentQuantityMissing} label="Content Quantity"
                                              type="checkbox" id="contentQuantityMissing"
                                              getValue={this.handleContentQuantityMissing}/>
                                </MDBCol>
                                <MDBCol className={"m-0 p-0"}>
                                    <MDBInput checked={this.state.mrpMissing} label="MRP" type="checkbox"
                                              id="mrpMissing" getValue={this.handleMrpMissing}/>
                                </MDBCol>
                                <MDBCol className={"m-0 p-0"}>
                                    <MDBInput checked={this.state.stockMissing} label="Stock" type="checkbox"
                                              id="stockMissing" getValue={this.handleStockMissing}/>
                                </MDBCol>
                                <MDBCol className={"m-0 p-0"}>
                                    <MDBInput checked={this.state.descriptionMissing} label="Description"
                                              type="checkbox" id="DescriptionMissing"
                                              getValue={this.handleDescriptionMissing}/>
                                </MDBCol>

                            </MDBRow>
                            }
                            {this.state.missingField === true &&
                            <MDBRow style={{fontSize: "11px"}}>

                                <MDBCol className={"m-0 p-0"}>
                                    <MDBInput size={1} checked={this.state.manufacturerMissing} label="Manufacturer"
                                              type="checkbox" id="manufacturerMissing"
                                              getValue={this.handleManufacturerMissing}/>
                                </MDBCol>
                                <MDBCol className={"m-0 p-0"}>
                                    <MDBInput size={1} checked={this.state.highlightsMissing} label="Highlights"
                                              type="checkbox" id="highlightsMissing"
                                              getValue={this.handleHighlightsMissing}/>
                                </MDBCol>
                                <MDBCol className={"m-0 p-0"}>
                                    <MDBInput size={1} checked={this.state.tagsMissing} label="Tags" type="checkbox"
                                              id="tagsMissing" getValue={this.handleTagsMissing}/>
                                </MDBCol>
                                <MDBCol className={"m-0 p-0"}>
                                    <MDBInput size={1} checked={this.state.howToUseMissing} label="How To Use"
                                              type="checkbox" id="howTouseMissing"
                                              getValue={this.handleHowToUseMissing}/>
                                </MDBCol>
                                <MDBCol className={"m-0 p-0"}>
                                    <MDBInput size={1} checked={this.state.ingredientsMissing} label="Ingredients"
                                              type="checkbox" id="ingredientsMissing"
                                              getValue={this.handleIngredientsMissing}/>
                                </MDBCol>
                                <MDBCol className={"m-0 p-0"}>
                                    <MDBInput size={1} checked={this.state.sideEffectsMissing} label="Side Effects"
                                              type="checkbox" id="sideEffectsMissing"
                                              getValue={this.handleSideEffectsMissing}/>
                                </MDBCol>
                                <MDBCol className={"m-0 p-0"}>
                                    <MDBInput size={1} checked={this.state.productUsesMissing} label="Product Uses"
                                              type="checkbox" id="productUsesMissing"
                                              getValue={this.handleProductUsesMissing}/>
                                </MDBCol>
                                <MDBCol className={"m-0 p-0"}>
                                    <MDBInput size={1} checked={this.state.imagesMissing} label="Images" type="checkbox"
                                              id="ImagesMissing" getValue={this.handleImagesMissing}/>
                                </MDBCol>

                            </MDBRow>
                            }

                            <MDBRow style={{fontSize:"13px"}}>
                                <MDBCol >
                                    {(this.state.products.length === 0 && !this.state.progressStatus) &&
                                    <div className={"no-data-container"}>No products found.</div>
                                    }
                                </MDBCol>
                                <MDBCol size={"2"} className={"mt-2"} >
                                    <MDBInput className={"floatLeft"} size={1} checked={this.state.missingField} label="Find missing fields" type="checkbox" id="findMissingFields" getValue={this.handleMissingField} />
                                </MDBCol>
                                <MDBCol size={"2"} style={{floatRight:"right"}} >
                                    <MDBBtn size={"sm"} style={{floatRight:"right"}} disabled={this.state.progressStatus} onClick={this.fetchData}>Search</MDBBtn>
                                </MDBCol>
                            </MDBRow>

                            {this.state.products.length > 0 &&
                            <MDBDataTable
                                striped

                                data={this.state.tableData}
                                materialSearch
                                noBottomColumns
                                hover
                                checkbox

                                getValueCheckBox={(e) => {
                                    this.showLogs2(e);
                                }}
                                getValueAllCheckBoxes={(e) => {
                                    this.showLogs2(e);
                                }}
                                multipleCheckboxes


                                checkbox
                                headCheckboxID='id6'
                                bodyCheckboxID='checkboxes6'

                                multipleCheckboxes

                                //disableRetreatAfterSorting={this.state.disableRetreatAfterSorting}
                            />
                            }
                        </MDBCardBody>
                    </MDBCard>


                    <MDBModal isOpen={this.state.modalEdit} toggle={this.toggleEdit} size={"fluid"}>
                        <MDBModalHeader toggle={this.toggleEdit}></MDBModalHeader>
                        <MDBModalBody className={"float-center"}>
                            {this.state.modalAction === "updateConfig" &&
                            <UpdateProductConfig loadedOnPopup={true} onEditComplete={this.onEditComplete}
                                                 productId={this.state.activeProduct._id}/>
                            }
                            {this.state.modalAction === "edit" &&
                            <UpdateProduct loadedOnPopup={true} onEditComplete={this.onEditComplete}
                                           productId={this.state.activeProduct._id}/>
                            }
                            {this.state.modalAction === "mark" &&
                            <VerifyProduct loadedOnPopup={true} onEditComplete={this.onEditComplete}
                                           product={this.state.activeProduct}/>
                            }
                            {this.state.modalAction === "stockAvailability" &&
                            <StockAvailability loadedOnPopup={true} onEditComplete={this.onEditComplete}
                                               product={this.state.activeProduct}/>
                            }

                        </MDBModalBody>
                    </MDBModal>

                    <MDBModal isOpen={this.state.modalView} toggle={this.toggleView} size={"fluid"}>
                        <MDBModalHeader toggle={this.toggleView}></MDBModalHeader>
                        <MDBModalBody className={"float-center"}>
                            <ProductView loadedOnPopup={true}
                                         productId={this.state.activeProduct._id} product={this.state.activeProduct}/>
                        </MDBModalBody>
                    </MDBModal>
                    <MDBModal isOpen={this.state.modalLB} toggle={this.toggleLB} size={"lg"}>
                        <MDBModalHeader toggle={this.toggleLB}></MDBModalHeader>
                        <MDBModalBody className={"float-center"}>
                            <div style={{"textAlign":"center"}}>
                                {/*<img src={config.IMAGE_HOST + this.state.activeImageUrl.filePathOriginal} className={"float-center"}*/}
                                {/*     style={{maxWidth: "440px", maxHeight: "440px"}} alt={""}/>*/}
                            </div>
                        </MDBModalBody>
                    </MDBModal>
                </MDBContainer>
            </React.Fragment>
        )
    }
}

export default withRouter(MyTask)