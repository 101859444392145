import React from "react";
import SimpleBackdrop from "../common/overlay";
import {
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBBtn,
  MDBModalFooter,
  MDBInput,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOptions,
  MDBSelectOption,
  MDBRow,
  MDBCol,
} from "mdbreact";
import OrderService from "../../services/orderService";
import { withRouter } from "react-router-dom";
import ViewOrder from "./viewOrder";
import UserStore from "../../stores/userStore";
import { toast } from "react-toastify";
import GetDateRange from "../common/getDateRange";
import Moments from "moment";
import { CSVLink } from "react-csv";
import config from "../../config";
import MaterialTable from "material-table";
import ReportService from "../../services/reports";
import utility from "../../utility/utility";
import DownloadLabel from "../../newComponents/DownloadLabel/DownloadLabel";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PrintOrderSlip from "../../newComponents/PrintOrderSlip/PrintOrderSlip";

const headers = [
  { label: "Order Id", key: "orderCode" },
  { label: "Order Date", key: "createdAt" },
  { label: "Customer Name", key: "customerDetails.name" },
  { label: "Shipping City", key: "shippingAddress.city" },
  { label: "Shipping State", key: "shippingAddress.state" },
  { label: "Phone No", key: "customerDetails.phone" },
  { label: "Delivery Type", key: "deliveryType" },
  { label: "Payment Mode", key: "paymentMode" },
  { label: "Order Status", key: "csvStatus" },
  { label: "Order Amount", key: "amount" },
];

class ListOnlineOrdersCmp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: props.location.state?.page ?? 0,
      pageSize: props.location.state?.pageSize ?? 10,
      orderList: [],
      data: {},
      progressStatus: false,
      modalViewOrder: false,
      modalUpdatePaymentStatus: false,
      modalChecklist: false,
      modalAssignOrder: false,
      modalDownloadLabel: false,
      modalPrintOrderSlip: false,
      activeItem: null,
      userData: this.props.location.state?.userData || null,
      listData: this.props.location.state?.listData ||  null,
      isDataAvailable: this.props.location.state?.isDataAvailable || false,
      modalTrackingUrl: false,
      trackingUrl: "",
      trackingId: "",
      selectedRow: null,
      startDate: this.props.location.state?.startDatePreserved,
      endDate: this.props.location.state?.endDatePreserved,
      mode: this.props.location.state?.modePreserved ?? null,
      totalOrders: 0,
      cancelledOrders: 0,
      totalAmount: 0,
      totalPrepayOrders: 0,
      totalPrepayPaidAmount: 0,
      totalPrepayPendingAmount: 0,
      totalPrepayPaidOrderCount: 0,
      totalPrepayPendingOrderCount: 0,
      totalCODPaidOrderCount: 0,
      totalCODPendingOrderCount: 0,
      totalCODOrders: 0,
      totalCODPaidAmount: 0,
      totalCODPendingAmount: 0,
      totalCancelOrderAmount: 0,
      statusList: [
        "ALL",
        "CREATED",
        "ACKNOWLEDGED",
        "APPROVED",
        "REQUESTDOC",
        "READYFORDELIVERY",
        "OUTFORDELIVERY",
        "COMPLETED",
        "CANCELLED",
      ],

      currentStatusList: [
        "CREATED",
        "ACKNOWLEDGED",
        "APPROVED",
        "REQUESTDOC",
        "READYFORDELIVERY",
        "OUTFORDELIVERY",
        "COMPLETED",
      ],

      deliveryTypeList: ["SHIP", "DELIVER", "PICKUP"],
      currentDeliveryTypeList: ["SHIP", "DELIVER", "PICKUP"],

      checklist: [
        {
          message: "Confirmed with customer",
          checked: false,
          createdBy: "",
          createdAt: "",
          disabled: false,
        },
        {
          message: "Product in stock",
          checked: false,
          createdBy: "",
          createdAt: "",
          disabled: false,
        },
        {
          message: "Shipping order created",
          checked: false,
          createdBy: "",
          createdAt: "",
          disabled: false,
        },
        {
          message: "Packaging done",
          checked: false,
          createdBy: "",
          createdAt: "",
          disabled: false,
        },
      ],
      assignedTo: "",
      keyQU: "",
      teamList: this.props.location.state?.teamList || [],
      paymentModeList: [
        { text: "Cash", value: "CASH", checked: true },
        { text: "Card", value: "CARD" },
        { text: "Netbanking", value: "NETBANKING" },
        { text: "Wallet", value: "WALLET" },
      ],
      pymntMode: "CASH",
      amount: 0,
      telephonicOrders: 0,
      subscriptionOrders: 0,
      quickOrders: 0,
      botOrders: 0,
      webOrders: 0,
      whatsAppOrders: 0,
      contactUsOrders: 0,
      telephonicOrderCount: 0,
      subscriptionOrderCount: 0,
      quickOrderCount: 0,
      botOrderCount: 0,
      webOrderCount: 0,
      whatsAppOrderCount: 0,
      contactUsOrderCount: 0,
      carrierName: "",
      carrierList: [
        { text: "Delhivery", value: "Delhivery" },
        { text: "Ecom Express", value: "Ecom Express" },
        { text: "Ekart", value: "Ekart" },
        { text: "Xpressbees", value: "Xpressbees" },
        { text: "DTDC", value: "DTDC" },
        { text: "Bluedart", value: "Bluedart" },
        { text: "India Post", value: "India Post" },
        { text: "Amazon Shipping", value: "Amazon Shipping" },
        { text: "Positra Delivery", value: "Positra Delivery" },
        { text: "Other", value: "Other" },
      ],
      aggregator: "",
      aggregatorList: [
        { text: "Pickrr", value: "Pickrr" },
        { text: "Nimbus", value: "Nimbus" },
        { text: "Ecom Express Direct", value: "Ecom Express Direct" },
        { text: "Delhivery Direct", value: "Delhivery Direct" },
        { text: "ATPL", value: "ATPL" },
        { text: "Other", value: "Other" },
      ],
      shippingExpense: 0,
      paymentType: [
        {
          name: "COD",
          status: false,
        },
        {
          name: "PREPAY",
          status: false,
        },
      ],
      tableDataWithoutPaymentFilter: [],
    };
  }

  async componentDidMount() {
    if (!this.state.isDataAvailable) {
      let userData = await UserStore.fetchSessionData();
      this.setState({isDataAvailable: true})
      this.setState({ userData });
      this.fetchData("ALL").then((res) => {
        if (res) {
          this.setState({ orderList: res });
          this.setState({ tableDataWithoutPaymentFilter: res });
          this.calculateOrderAmount(res);
        }
      });
      await this.fetchTeamList();
    } else {
      let userData = this.state.userData;
      let listData = this.state.listData;
      let teamList = this.state.teamList;
      this.setState({ userData });
      this.setState({ orderList: listData });
      this.setState({ tableDataWithoutPaymentFilter: listData });
      this.calculateOrderAmount(listData);
      this.setState({ teamList });
    }
  }
  componentWillUnmount() {
    const { history } = this.props;
    const currentRoute = history.location.pathname;

    if (!currentRoute.startsWith("/view-online-order/")) {
      this.setState({isDataAvailable: false})
    }
  }

  changeAggregator = (e) => {
    this.setState({ aggregator: e[0] });
    if (e[0] == "Ecom Express Direct") {
      this.setCarrierName("Ecom Express");
    }
    if (e[0] == "Delhivery Direct") {
      this.setCarrierName("Delhivery");
    }
  };

  changeCarrier = (e) => {
    this.setState({ carrierName: e[0] });
  };

  setCarrierName = async (carrierName) => {
    let carrierList = this.state.carrierList;
    await this.setState({ carrierName: carrierName });
    for (let i = 0; i < carrierList.length; i++) {
      if (carrierList[i].value == carrierName) {
        carrierList[i].checked = true;
      } else {
        carrierList[i].checked = false;
      }
    }
    await this.setState({
      carrierList: carrierList,
    });
    this.setState({ key: new Date().getTime() });
  };

  setAggregator = async (aggregator) => {
    let aggregatorList = this.state.aggregatorList;
    await this.setState({ aggregator: aggregator });
    for (let i = 0; i < aggregatorList.length; i++) {
      if (aggregatorList[i].value == aggregator) {
        aggregatorList[i].checked = true;
      } else {
        aggregatorList[i].checked = false;
      }
    }
    await this.setState({
      aggregatorList: aggregatorList,
    });
    this.setState({ key: new Date().getTime() });
  };

  addTrackingUrl = async (val, action) => {
    if (val === null) {
      await this.setState({
        modalTrackingUrl: false,
        selectedRow: null,
        trackingUrl: "",
        trackingId: "",
        carrierName: "",
        aggregator: "",
        shippingExpense: 0,
      });
      this.setCarrierName("");
      this.setAggregator("");
    } else {
      if (action === "ADD") {
        this.setState({
          modalTrackingUrl: true,
          selectedRow: val,
          trackingUrl: "",
          trackingId: "",
          carrierName: "",
          aggregator: "",
          shippingExpense: 0,
        });
        this.setCarrierName("");
        this.setAggregator("");
      } else {
        this.setState({
          modalTrackingUrl: true,
          selectedRow: val,
          trackingUrl: val.trackingUrl,
          trackingId: val.trackingId,
          carrierName: val.carrierName,
          aggregator: val.aggregator,
          shippingExpense: val.shippingExpense,
        });
        await this.setCarrierName(val.carrierName);
        await this.setAggregator(val.aggregator);
      }
    }
  };

  saveTrackingUrl = async () => {
    if (this.state.aggregator === "") {
      toast.error("Please enter aggregator.");
      return false;
    }
    if (this.state.carrierName === "") {
      toast.error("Please enter carrier name.");
      return false;
    }
    if (this.state.trackingUrl === "") {
      toast.error("Please enter tracking url.");
      return false;
    }
    if (this.state.trackingId === "") {
      toast.error("Please enter tracking Id.");
      return false;
    }
    let payload = {
      orderId: this.state.selectedRow._id,
      url: this.state.trackingUrl,
      urlId: this.state.trackingId,
      carrierName: this.state.carrierName,
      aggregator: this.state.aggregator,
      shippingExpense: this.state.shippingExpense
        ? this.state.shippingExpense
        : 0,
      courierLabel: "",
    };
    this.setState({ progressStatus: true });
    let status = await OrderService.updateTrackingUrl(
      this.state.userData,
      payload
    );
    this.setState({ progressStatus: false });
    if (status === false) {
      toast.error("We could not update the tracking URL.");
    } else {
      toast.success("Tracking URL updated successfully.");
      this.setState({ modalTrackingUrl: false, selectedRow: null });
      this.fetchData("ALL").then((res) => {
        if (res) {
          this.setState({ orderList: res });
          this.setState({ tableDataWithoutPaymentFilter: res });
          this.calculateOrderAmount(res);
        }
      });
    }
  };
  getTrackUrl = (row) => {
    let trackingUrl = "";
    if (
      !row.hasOwnProperty("trackingUrl") ||
      (row.hasOwnProperty("trackingUrl") && row.trackingUrl === "")
    ) {
      trackingUrl = (
        <span
          onClick={() => {
            this.addTrackingUrl(row, "ADD");
          }}
          className="blue-text cursor-pointer"
        >
          Add
        </span>
      );
    } else {
      trackingUrl = (
        <>
          <a href={row.trackingUrl} target="_blank" className="blue-text">
            Track
          </a>{" "}
          <br />{" "}
          <span
            onClick={() => {
              this.addTrackingUrl(row, "EDIT");
            }}
            className="green-text cursor-pointer"
          >
            Edit
          </span>
          <br />{" "}
          <span
            onClick={() => {
              this.downloadLabel(row);
            }}
            className="green-text cursor-pointer"
          >
            Print Label
          </span>
        </>
      );
    }
    return trackingUrl;
  };

  getPaidAmount = (row) => {
    if (row.hasOwnProperty("razorpayPaymentDetails")) {
      if (row.razorpayPaymentDetails.hasOwnProperty("amount")) {
        let str = "";
        if (row.totalAmount - row.razorpayPaymentDetails.amount / 100 <= 1) {
          str = (
            <span className="green-text cursor-pointer">
              {row.razorpayPaymentDetails.amount / 100}
            </span>
          );
        } else if (
          row.totalAmount - row.razorpayPaymentDetails.amount / 100 >
          1
        ) {
          str = (
            <span className="red-text cursor-pointer">
              <b>{row.razorpayPaymentDetails.amount / 100}</b>
            </span>
          );
        } else {
          str = row.razorpayPaymentDetails.amount / 100;
        }
        return str;
      } else {
        return "";
      }
    } else {
      return "";
    }
    return "";
  };

  changePaymentStatus = async () => {
    let payload = {
      orderId: this.state.activeItem._id,
      paymentMode: this.state.pymntMode,
      amount: this.state.amount,
    };
    this.setState({ progressStatus: true });
    let res = await OrderService.updateOnlinePaymentStatus(
      payload,
      this.state.userData.sessionToken
    );
    this.setState({ progressStatus: false });
    if (res) {
      toast.success("The payment status updated successfully.");
      this.toggle("UpdatePaymentStatus")();
      this.fetchData("ALL").then((res) => {
        if (res) {
          this.setState({
            orderList: res,
            tableDataWithoutPaymentFilter: res,
            paymentModeList: [
              { text: "Cash", value: "CASH", checked: true },
              { text: "Card", value: "CARD" },
              { text: "Netbanking", value: "NETBANKING" },
              { text: "Wallet", value: "WALLET" },
            ],
          });
          this.calculateOrderAmount(res);
        }
      });
    } else {
      toast.error("Something went wrong while updating payment status.");
    }
  };
  selectChecklist = async (row) => {
    this.setState({ activeItem: row, progressStatus: true });
    let res = await OrderService.fetchChecklist(
      this.state.userData.sessionToken,
      row._id
    );
    if (res) {
      let checklist = [
        {
          message: "Confirmed with customer",
          checked: false,
          createdBy: "",
          createdAt: "",
          disabled: false,
        },
        {
          message: "Product in stock",
          checked: false,
          createdBy: "",
          createdAt: "",
          disabled: false,
        },
        {
          message: "Shipping order created",
          checked: false,
          createdBy: "",
          createdAt: "",
          disabled: false,
        },
        {
          message: "Packaging done",
          checked: false,
          createdBy: "",
          createdAt: "",
          disabled: false,
        },
      ];
      if (res.length > 0) {
        for (let a of res) {
          for (let b of checklist) {
            if (a.message === b.message) {
              b.checked = true;
              b.createdBy = a.createdBy.name;
              b.createdAt = a.createdAt;
              b.disabled = true;
            }
          }
        }
      }
      this.setState({ checklist });
    } else {
      toast.error("something went wrong while fetching the checklist.");
    }
    this.setState({ progressStatus: false });
    this.toggle("Checklist")();
  };

  downloadLabel = (val) => {
    this.setState({
      modalDownloadLabel: true,
      selectedRow: val,
    });
  };
  printOrderSlip = (val) => {
    this.setState({
      modalPrintOrderSlip: true,
      selectedRow: val,
    });
  };
  assignTo = (row) => {
    this.setState({ activeItem: row });
    this.resetKey();
    this.toggle("AssignOrder")();
  };
  getAssignee = (row) => {
    let assignee = [];
    if (row.hasOwnProperty("orderAssignment") === true) {
      if (row.orderAssignment.length > 0) {
        assignee = row.orderAssignment;
        let temp = assignee[assignee.length - 1].assignedTo;
        return temp.name;
      }
    }
  };
  calculateOrderAmount = (res) => {
    let totalOrders = 0,
      cancelledOrders = 0,
      totalAmount = 0,
      totalCancelOrderAmount = 0,
      totalPrepayOrders = 0,
      totalPrepayPaidAmount = 0,
      totalPrepayPendingAmount = 0,
      totalPrepayPaidOrderCount = 0,
      totalPrepayPendingOrderCount = 0,
      totalCODPaidOrderCount = 0,
      totalCODPendingOrderCount = 0,
      totalCODOrders = 0,
      totalCODPaidAmount = 0,
      totalCODPendingAmount = 0,
      telephonicOrders = 0,
      subscriptionOrders = 0,
      quickOrders = 0,
      botOrders = 0,
      webOrders = 0,
      whatsAppOrders = 0,
      contactUsOrders = 0,
      telephonicOrderCount = 0,
      subscriptionOrderCount = 0,
      quickOrderCount = 0,
      botOrderCount = 0,
      webOrderCount = 0,
      whatsAppOrderCount = 0,
      contactUsOrderCount = 0;

    if (res.length !== undefined) {
      totalOrders = res.length;
    }
    for (let item of res) {
      if (item.status !== "CANCELLED") {
        totalAmount = totalAmount + item.amount;
      } else {
        totalCancelOrderAmount = totalCancelOrderAmount + item.amount;
        ++cancelledOrders;
      }
      if (item.paymentMode === "PREPAY" && item.status !== "CANCELLED") {
        ++totalPrepayOrders;
        if (item.isPaid === "Y") {
          totalPrepayPaidAmount = totalPrepayPaidAmount + item.amount;
          totalPrepayPaidOrderCount++;
        } else {
          totalPrepayPendingAmount = totalPrepayPendingAmount + item.amount;
          totalPrepayPendingOrderCount++;
        }
      } else if (item.paymentMode === "COD" && item.status !== "CANCELLED") {
        ++totalCODOrders;
        if (item.isPaid === "Y") {
          totalCODPaidAmount = totalCODPaidAmount + item.amount;
          totalCODPaidOrderCount++;
        } else {
          totalCODPendingAmount = totalCODPendingAmount + item.amount;
          totalCODPendingOrderCount++;
        }
      }
      if (item.status === "OUTFORDELIVERY" && item.deliveryType === "SHIP") {
        item.csvStatus = "SHIPPED";
      } else {
        item.csvStatus = item.status;
      }

      if (
        item.hasOwnProperty("orderSource") &&
        item.orderSource === "TELEPHONIC"
      ) {
        telephonicOrders = telephonicOrders + item.amount;
        telephonicOrderCount++;
      } else if (
        item.hasOwnProperty("orderSource") &&
        item.orderSource === "subscription"
      ) {
        subscriptionOrders = subscriptionOrders + item.amount;
        subscriptionOrderCount++;
      } else if (
        item.hasOwnProperty("orderSource") &&
        item.orderSource === "QUICK_ORDER"
      ) {
        quickOrders = quickOrders + item.amount;
        quickOrderCount++;
      } else if (
        item.hasOwnProperty("orderSource") &&
        item.orderSource === "BOT"
      ) {
        botOrders = botOrders + item.amount;
        botOrderCount++;
      } else if (
        !item.hasOwnProperty("orderSource") ||
        item.orderSource === "WEB"
      ) {
        webOrders = webOrders + item.amount;
        webOrderCount++;
      } else if (
        item.hasOwnProperty("orderSource") &&
        item.orderSource === "WHATSAPP"
      ) {
        whatsAppOrders = whatsAppOrders + item.amount;
        whatsAppOrderCount++;
      } else if (
        item.hasOwnProperty("orderSource") &&
        item.orderSource === "CONTACT_US"
      ) {
        contactUsOrders = contactUsOrders + item.amount;
        contactUsOrderCount++;
      }
    }
    this.setState({
      totalOrders,
      cancelledOrders,
      totalAmount,
      totalPrepayOrders,
      totalPrepayPaidAmount,
      totalPrepayPendingAmount,
      totalCODOrders,
      totalCODPaidAmount,
      totalCODPendingAmount,
      totalCancelOrderAmount,
      telephonicOrders,
      subscriptionOrders,
      quickOrders,
      botOrders,
      webOrders,
      whatsAppOrders,
      contactUsOrders,
      telephonicOrderCount,
      subscriptionOrderCount,
      quickOrderCount,
      botOrderCount,
      webOrderCount,
      contactUsOrderCount,
      whatsAppOrderCount,
      totalPrepayPaidOrderCount,
      totalPrepayPendingOrderCount,
      totalCODPaidOrderCount,
      totalCODPendingOrderCount,
    });
  };
  fetchData = async (status) => {
    status = status.toString();
    let deliveryTypeList = this.state.currentDeliveryTypeList.toString();
    this.setState({ progressStatus: true, currentStatus: status });
    console.log("apicall", this.state.startDate, this.state.endDate);
    let data = await OrderService.fetchOnlineOrders(
      this.state.userData.sessionToken,
      Moments(this.state.startDate).format("YYYY-MM-DD"),
      Moments(this.state.endDate).format("YYYY-MM-DD"),
      status,
      deliveryTypeList
    );
    this.setState({ progressStatus: false });
    if (data) {
      this.setState({listData:data})
    }
    return data;
  };
  goToOrderDetails = async () => {
    await this.setState({ modalViewOrder: false });
    this.props.history.push(
      "/process-online-order/" + this.state.activeItem._id
    );
  };
  processOrder = async (orderId) => {
    this.props.history.push({
      pathname: `/view-online-order/${orderId}`,
      state: {
        startDate: Moments(this.state.startDate).format("YYYY-MM-DD"),
        endDate: Moments(this.state.endDate).format("YYYY-MM-DD"),
        mode: this.state.mode,
        page: this.state.page,
        pageSize: this.state.pageSize,
        listData: this.state.listData,
        teamList: this.state.teamList,
        isDataAvailable: this.state.isDataAvailable
      },
    });

  };
  toggle = (val) => () => {
    this.setState({ ["modal" + val]: !this.state["modal" + val] });
  };
  fulfillOrder = () => {
    this.toggle("ViewOrder")();
  };
  getDateRangeCallback = async (startDate, endDate) => {
    if (startDate > endDate) {
      toast.info(
        "Start date is greater than the end date. Please adjust the date range again."
      );
      return;
    }
    await this.setState({ startDate, endDate });
    let endDateThreeMonthGap = Moments(startDate).add(2, "months");
    if (endDate > endDateThreeMonthGap) {
      let payload = {
        startDate: Moments(startDate).format("YYYY-MM-DD"),
        endDate: Moments(endDate).format("YYYY-MM-DD"),
        section: "ONLINE_ORDER",
      };
      //Create a record request
      this.setState({ progressStatus: true });
      let res = await ReportService.requestCreateReport(
        this.state.userData.sessionToken,
        payload
      );
      this.setState({ progressStatus: false });
      if (res) {
        toast.success(
          "A request has been raised to create a report for the specified time duration with the name, " +
            "ONLINE_ORDER_" +
            Moments(startDate).format("YYYY-MM-DD") +
            "_" +
            Moments(endDate).format("YYYY-MM-DD") +
            "_" +
            res._id +
            ".csv"
        );
      } else {
        toast.error(
          "Something went wrong while requesting the report for the duration " +
            Moments(startDate).format("YYYY-MM-DD") +
            " and " +
            Moments(endDate).format("YYYY-MM-DD")
        );
      }
      return false;
    }
    this.fetchData(this.state.currentStatusList).then((res) => {
      if (res) {
        this.setState({
          orderList: res,
          tableDataWithoutPaymentFilter: res,
          totalOrders: 0,
          cancelledOrders: 0,
          totalAmount: 0,
          prescriptionOrders: 0,
          prescriptionOrderAmount: 0,
          totalPrepayOrders: 0,
          totalPrepayPaidAmount: 0,
          totalPrepayPendingAmount: 0,
          totalCODOrders: 0,
          totalCODPaidAmount: 0,
          totalCODPendingAmount: 0,
          totalCancelOrderAmount: 0,
        });
        this.calculateOrderAmount(res);
      }
    });
  };
  changeStatus = async (status, statusList) => {
    console.log(status);
    console.log(statusList);
    if (status === "ALL") {
      if (statusList.length === 8) {
        statusList = [];
      } else {
        statusList = [
          "CREATED",
          "ACKNOWLEDGED",
          "APPROVED",
          "REQUESTDOC",
          "READYFORDELIVERY",
          "OUTFORDELIVERY",
          "COMPLETED",
          "CANCELLED",
        ];
      }
    } else {
      if (statusList.indexOf(status) >= 0) {
        statusList.splice(statusList.indexOf(status), 1);
      } else {
        statusList.push(status);
      }
    }

    console.log("Again==>");
    console.log(statusList);
    await this.setState({ currentStatusList: statusList });

    this.fetchData(statusList).then((res) => {
      if (res) {
        this.setState({ orderList: res });
        this.setState({ tableDataWithoutPaymentFilter: res });
        this.calculateOrderAmount(res);
      }
    });
  };

  changeOrderType = async (deliveryType, deliveryTypeList) => {
    if (deliveryType === "ALL") {
      if (deliveryTypeList.length === 3) {
        deliveryTypeList = [];
      } else {
        deliveryTypeList = ["SHIP", "DELIVER", "PICKUP"];
      }
    } else {
      if (deliveryTypeList.indexOf(deliveryType) >= 0) {
        deliveryTypeList.splice(deliveryTypeList.indexOf(deliveryType), 1);
      } else {
        deliveryTypeList.push(deliveryType);
      }
    }

    await this.setState({ currentDeliveryTypeList: deliveryTypeList });

    this.fetchData(this.state.currentStatusList).then((res) => {
      if (res) {
        this.setState({ orderList: res });
        this.setState({ tableDataWithoutPaymentFilter: res });
        this.calculateOrderAmount(res);
      }
    });
  };

  updateChecklist = (index) => {
    let checklist = this.state.checklist;
    checklist[index].checked = !checklist[index].checked;
    this.setState({ checklist });
  };
  saveChecklist = async () => {
    let checkedItems = 0;
    for (let item of this.state.checklist) {
      if (item.checked === true && item.disabled === false) {
        ++checkedItems;
        break;
      }
    }
    if (checkedItems === 0) {
      toast.error("Please select at least one checkbox.");
      return false;
    }
    let payload = [];
    for (let item of this.state.checklist) {
      if (item.checked === true && item.disabled !== true) {
        payload.push(item.message);
      }
    }
    this.setState({ progressStatus: true });
    let res = await OrderService.updateChecklist(
      this.state.userData.sessionToken,
      this.state.activeItem._id,
      payload
    );
    this.setState({ progressStatus: false });
    if (res) {
      toast.success("Checklist updated successfully.");
      this.toggle("Checklist")();
    } else {
      toast.error("Something went wrong while updating the checklist.");
    }
  };
  fetchTeamList = async () => {
    let teamList = await OrderService.fetchDeliveryPersonDetails(
      this.state.userData.sessionToken
    );
    if (teamList) {
      this.setState({ teamList });
      // localStorage.setItem("teamList", JSON.stringify(teamList));
    } else {
      toast.error("Something went wrong while getting the team list.");
    }
  };
  orderAssignment = (item) => {
    this.setState({ assignedTo: item[0] });
  };
  resetKey = () => {
    let randomString = Math.random().toString(36);
    this.setState({
      keyQU: randomString,
    });
  };
  assignOrder = async () => {
    if (this.state.assignedTo === "") {
      toast.error("Please select an assignee.");
      return false;
    }
    let temp = this.state.assignedTo.split("_");
    let payload = {
      orderId: this.state.activeItem._id,
      assignedTo: {
        userId: temp[0],
        name: temp[1],
      },
    };
    this.setState({ progressStatus: true });
    let res = await OrderService.assignOrder(
      this.state.userData.sessionToken,
      payload
    );
    this.setState({ progressStatus: false });
    if (res) {
      toast.success("Order assigned successfully.");
      this.toggle("AssignOrder")();
      this.fetchData(this.state.currentStatusList).then((res) => {
        if (res) {
          this.setState({ orderList: res });
          this.setState({ tableDataWithoutPaymentFilter: res });
          this.calculateOrderAmount(res);
        }
      });
    } else {
      toast.error("Something went wrong while assigning the order.");
    }
  };
  activeTabChange = (tab) => (e) => {
    if (this.state.activeTabView !== tab) {
      this.setState({
        activeTabView: tab,
      });
    }
  };
  handleAddress = (row) => {
    let address = (
      <span className={"text-capitalize"}>
        {/*<span><b>Name:</b> {row.customerDetails.name}</span> <br/><br/>*/}
        <b>{row.shippingAddress.name}</b> <br />
        {row.shippingAddress.addressLine1}
        {row.shippingAddress.addressLine2 && (
          <span>,&nbsp;{row.shippingAddress.addressLine2}</span>
        )}{" "}
        <br />
        {row.shippingAddress.city}, {row.shippingAddress.state},{" "}
        {row.shippingAddress.pinCode}
        {row.shippingAddress.pincode}, {row.shippingAddress.country}
        <br />
        Phone No: {row.shippingAddress.phone}
      </span>
    );
    return address;
  };

  formTrackingLink = async () => {
    if (this.state.carrierName && this.state.trackingId) {
      await this.setState({
        trackingUrl: utility.getTrackingLink(
          this.state.carrierName,
          this.state.trackingId
        ),
      });
    }
  };
  changepayment = async (type, value, index) => {
    let newPayStatus = this.state.paymentType;
    newPayStatus[index].status = value;
    if (index == 0 && value) {
      newPayStatus[1].status = !value;
    } else if (index == 1 && value) {
      newPayStatus[0].status = !value;
    }
    this.setState({
      paymentType: newPayStatus,
    });
    let valueMain = this.state.tableDataWithoutPaymentFilter?.filter(
      (item) => item.paymentMode == type
    );
    if (value) {
      this.setState({
        orderList: valueMain,
      });
    } else if (!value) {
      this.setState({
        orderList: this.state.tableDataWithoutPaymentFilter,
      });
    }
  };
  updateModeFromChild = (childData) => {
    this.setState({ mode: childData });
  };
  // componentDidUpdate(prevProps) {
  //   const { startDatePreserved, endDatePreserved, modePreserved } = this.props.location.state || {};
  //   if ((startDatePreserved && (startDatePreserved !== this.state.startDate)) || (endDatePreserved && (endDatePreserved !== this.state.endDate))) {
  //     this.setState({
  //       startDate: startDatePreserved || new Date(),
  //       endDate: endDatePreserved || new Date(),
  //       mode: modePreserved || 'Day'
  //     }, () => {
  //       console.log("Updated state:", this.state.startDate, this.state.endDate);
  //     });
  //   }
  //   console.log("gggg", startDatePreserved, endDatePreserved);
  // }
  handlePageChange = (event, newPage) => {
    this.setState({ page: event });
  };

  handlePageSizeChange = (event) => {
    this.setState({ pageSize: parseInt(event, 10), page: 0 });
  };

  render() {
    return (
      <React.Fragment>
        <SimpleBackdrop status={this.state.progressStatus} />
        <div className={"col-12 row mx-0 order-box"}>
          <section>
            <div className={"title"}>Total Orders</div>
            <div className={"order"} style={{ color: "#74B353" }}>
              {this.state.totalOrders}
            </div>
            <div className={"row mx-0"}>
              <div className={"col-12 p-0 m-auto"}>
                Total Amount(Prepaid + COD): &nbsp;
                <span
                  dangerouslySetInnerHTML={{ __html: config.CURRENCY_HTML }}
                ></span>
                {this.state.totalAmount.toFixed("2")}
              </div>
            </div>
          </section>
          <section>
            <div className={"title"}>Total PREPAID Orders</div>
            <div className={"order"} style={{ color: "#0094FF" }}>
              {this.state.totalPrepayOrders}
            </div>
            <div>
              Total Paid Amount({this.state.totalPrepayPaidOrderCount}/
              {this.state.totalPrepayOrders}):
              <span className={"float-right"}>
                <span
                  dangerouslySetInnerHTML={{ __html: config.CURRENCY_HTML }}
                ></span>{" "}
                {this.state.totalPrepayPaidAmount.toFixed("2")}
              </span>
            </div>
            <div>
              Total Pending Amount({this.state.totalPrepayPendingOrderCount}/
              {this.state.totalPrepayOrders}):
              <span className={"float-right"}>
                <span
                  dangerouslySetInnerHTML={{ __html: config.CURRENCY_HTML }}
                ></span>{" "}
                {this.state.totalPrepayPendingAmount.toFixed("2")}
              </span>
            </div>
          </section>
          <section>
            <div className={"title"}>Total COD Orders</div>
            <div
              className={"order"}
              style={{ color: "#FF008A", marginLeft: "2px" }}
            >
              {this.state.totalCODOrders}
            </div>
            <div>
              Total Paid Amount({this.state.totalCODPaidOrderCount}/
              {this.state.totalCODOrders}):
              <span className={"float-right"}>
                <span
                  dangerouslySetInnerHTML={{ __html: config.CURRENCY_HTML }}
                ></span>{" "}
                {this.state.totalCODPaidAmount.toFixed("2")}
              </span>
            </div>
            <div>
              Total Pending Amount({this.state.totalCODPendingOrderCount}/
              {this.state.totalCODOrders}):
              <span className={"float-right"}>
                <span
                  dangerouslySetInnerHTML={{ __html: config.CURRENCY_HTML }}
                ></span>{" "}
                {this.state.totalCODPendingAmount.toFixed("2")}
              </span>
              <br />
            </div>
          </section>
          <section>
            <div className={"title"}>Orders From Various Sources</div>
            <div>
              Web({this.state.webOrderCount}/{this.state.totalOrders}):
              <span className={"float-right"}>
                <span
                  dangerouslySetInnerHTML={{ __html: config.CURRENCY_HTML }}
                ></span>{" "}
                {this.state.webOrders.toFixed(2)}
              </span>
            </div>
            <div>
              Telephonic({this.state.telephonicOrderCount}/
              {this.state.totalOrders}):
              <span className={"float-right"}>
                <span
                  dangerouslySetInnerHTML={{ __html: config.CURRENCY_HTML }}
                ></span>{" "}
                {this.state.telephonicOrders.toFixed(2)}
              </span>
            </div>
            <div>
              Subscription({this.state.subscriptionOrderCount}/
              {this.state.totalOrders}):
              <span className={"float-right"}>
                <span
                  dangerouslySetInnerHTML={{ __html: config.CURRENCY_HTML }}
                ></span>{" "}
                {this.state.subscriptionOrders.toFixed(2)}
              </span>
            </div>
            <div>
              Whatsapp({this.state.whatsAppOrderCount}/{this.state.totalOrders}
              ):
              <span className={"float-right"}>
                <span
                  dangerouslySetInnerHTML={{ __html: config.CURRENCY_HTML }}
                ></span>{" "}
                {this.state.whatsAppOrders.toFixed(2)}
              </span>
            </div>
            <div>
              Quick({this.state.quickOrderCount}/{this.state.totalOrders}):
              <span className={"float-right"}>
                <span
                  dangerouslySetInnerHTML={{ __html: config.CURRENCY_HTML }}
                ></span>{" "}
                {this.state.quickOrders.toFixed(2)}
              </span>
            </div>
            <div>
              ContactUs({this.state.contactUsOrderCount}/
              {this.state.totalOrders}):
              <span className={"float-right"}>
                <span
                  dangerouslySetInnerHTML={{ __html: config.CURRENCY_HTML }}
                ></span>{" "}
                {this.state.contactUsOrders.toFixed(2)}
              </span>
            </div>

            <div>
              Bot({this.state.botOrderCount}/{this.state.totalOrders}):
              <span className={"float-right"}>
                <span
                  dangerouslySetInnerHTML={{ __html: config.CURRENCY_HTML }}
                ></span>{" "}
                {this.state.botOrders.toFixed(2)}
              </span>
            </div>
          </section>
          <section>
            <div className={"title"}>Cancelled Orders</div>
            <div className={"order"} style={{ color: "#FF0000" }}>
              {this.state.cancelledOrders}
            </div>
            <div>
              Cancelled Order Amount:
              <span className={"float-right"}>
                <span
                  dangerouslySetInnerHTML={{ __html: config.CURRENCY_HTML }}
                ></span>{" "}
                {this.state.totalCancelOrderAmount.toFixed(2)}
              </span>
            </div>
          </section>
        </div>
        <div className={"col-12 row mx-0 my-4"}>
          <div className={"col-md-9 p-0"}>
            <GetDateRange
              key={this.state.startDate}
              getDateRangeCallback={this.getDateRangeCallback}
              startDatePreserved={this.state.startDate}
              endDatePreserved={this.state.endDate}
              mode={this.state.mode}
              sendModeToParent={this.updateModeFromChild}
            />
          </div>
          <div className={"col-md-3 text-right p-0"}>
            <CSVLink
              data={this.state.orderList}
              headers={headers}
              target="_blank"
              filename={
                "online_orders_" +
                Moments(this.state.startDate).format("MMM, YYYY") +
                "_" +
                Moments(this.state.endDate).format("MMM, YYYY") +
                ".csv"
              }
            >
              <MDBBtn size={"md"} disabled={this.state.progressStatus}>
                <i className="fas fa-download"></i>&nbsp;Export
              </MDBBtn>
            </CSVLink>
            <MDBBtn
              size={"md"}
              disabled={this.state.progressStatus}
              onClick={() => {
                this.props.history.push("/new-online-order");
              }}
            >
              Add
            </MDBBtn>
          </div>
        </div>
        <div className={"col-12 row mx-0 my-2 p-0"}>
          <div
            className={"col-12 btn-toolbar"}
            role="toolbar"
            aria-label="Toolbar with button groups"
          >
            <h5 className={"mr-4"}>
              <b>Order Status:</b>
            </h5>{" "}
            <div
              className="btn-group mr-2"
              role="group"
              aria-label="First group"
            >
              {this.state.statusList.map((item, index) => (
                <div className={"pr-4"}>
                  <MDBInput
                    className={"pr-4"}
                    checked={
                      item === "ALL" && this.state.currentStatusList.length == 8
                        ? true
                        : this.state.currentStatusList.indexOf(item) >= 0
                    }
                    label={
                      item === "OUTFORDELIVERY"
                        ? "OUTFORDELIVERY/SHIPPED"
                        : item === "READYFORDELIVERY"
                        ? "PACKED"
                        : item
                    }
                    type="checkbox"
                    id={"id_" + item}
                    getValue={() =>
                      this.changeStatus(item, this.state.currentStatusList)
                    }
                  />
                </div>
              ))}
            </div>
          </div>

          <div
            className={"col-12 btn-toolbar"}
            role="toolbar"
            aria-label="Toolbar with button groups"
          >
            <h5 className={"mr-4"}>
              <b>Order Type:</b>
            </h5>{" "}
            <div
              className="btn-group mr-2"
              role="group"
              aria-label="First group"
            >
              {this.state.deliveryTypeList.map((item, index) => (
                <div className={"pr-4"}>
                  <MDBInput
                    className={"pr-4"}
                    checked={
                      item === "ALL" &&
                      this.state.currentDeliveryTypeList.length == 2
                        ? true
                        : this.state.currentDeliveryTypeList.indexOf(item) >= 0
                    }
                    label={item}
                    type="checkbox"
                    id={"id_delivery type_" + item}
                    getValue={() =>
                      this.changeOrderType(
                        item,
                        this.state.currentDeliveryTypeList
                      )
                    }
                  />
                </div>
              ))}
            </div>
          </div>

          <div
            className={"col-12 btn-toolbar"}
            role="toolbar"
            aria-label="Toolbar with button groups"
          >
            <h5 className={"mr-4"}>
              <b>Payment Type:</b>
            </h5>
            {this.state.paymentType.map((item, key) => (
              <div className={"pr-4"}>
                <MDBInput
                  className={"pr-4"}
                  checked={item.status}
                  label={item.name}
                  type="checkbox"
                  id={"paymentType" + key}
                  getValue={() => {
                    this.changepayment(item.name, !item.status, key);
                  }}
                />
              </div>
            ))}
          </div>
        </div>
        <div className={"col-12"}>
          {this.state.progressStatus === false &&
            this.state.orderList.length === 0 && (
              <div className={"no-data-container"}>No Order found.</div>
            )}
          {this.state.orderList.length > 0 && (
            <MaterialTable
              columns={[
                {
                  title: "Order Id",
                  field: "orderCode",
                  width: "5%",
                },
                {
                  title: "Date",
                  field: "createdAt",
                  width: "5%",
                  render: (rowData) => Moments(rowData.createdAt).format("lll"),
                },
                {
                  title: "Shipping Address",
                  field: "customerDetails.name",
                  width: "25%",
                  render: (rowData) => this.handleAddress(rowData),
                },
                {
                  title: "Phone No",
                  field: "customerDetails.phone",
                  width: "5%",
                  render: (rowData) => rowData.customerDetails.phone,
                },
                {
                  title: "Delivery Type",
                  field: "deliveryType",
                  width: "5%",
                },
                {
                  title: "Payment Mode",
                  field: "paymentMode",
                  width: "5%",
                },
                {
                  title: "Order Status",
                  field: "status",
                  width: "5%",
                  render: (rowData) =>
                    rowData.status === "OUTFORDELIVERY" &&
                    rowData.deliveryType === "SHIP"
                      ? "SHIPPED"
                      : rowData.status === "READYFORDELIVERY"
                      ? "PACKED"
                      : rowData.status,
                },
                {
                  title: "Order Amount",
                  field: "amount",
                  width: "5%",
                  render: (rowData) => rowData.amount.toFixed(2),
                },
                {
                  title: "Order Source",
                  field: "orderSource",
                  width: "5%",
                  render: (rowData) =>
                    rowData.hasOwnProperty("orderSource") ? (
                      <span className={"text-capitalize"}>
                        {rowData.orderSource}
                      </span>
                    ) : (
                      "WEB"
                    ),
                },
                {
                  title: "Is Paid?",
                  field: "",
                  width: "5%",
                  render: (rowData) => (
                    <span>
                      {rowData.isPaid === "Y" ? "PAID" : "PENDING"} <br />
                      {/*/!* {rowData.status === "COMPLETED" &&*/}
                      {rowData.isPaid === "N" && (
                        <a
                          onClick={() => {
                            this.setState({ activeItem: rowData });
                            this.toggle("UpdatePaymentStatus")();
                          }}
                          className="blue-text"
                        >
                          UpdateStatus
                        </a>
                      )}
                      {/*   <a*/}
                      {/*  onClick={() => {*/}
                      {/*    this.setState({ activeItem: rowData });*/}
                      {/*    this.toggle("UpdatePaymentStatus")();*/}
                      {/*  }}*/}
                      {/*  className="blue-text"*/}
                      {/*>*/}
                      {/*  UpdateStatus*/}
                      {/*</a>*/}
                    </span>
                  ),
                },
                {
                  title: "Razorpay Amount",
                  field: "",
                  render: (rowData) => this.getPaidAmount(rowData),
                  width: "5%",
                },
                {
                  title: "Internal Comment",
                  field: "internalComment",
                  render: (rowData) => (
                    <div>
                      {rowData.internalComment && (
                        <textarea
                          style={{ height: "70px", width: "200px" }}
                          value={rowData.internalComment}
                        ></textarea>
                      )}
                    </div>
                  ),
                  width: "10%",
                },
                {
                  title: "Local Delivery Slot",
                  field: "",
                  render: (rowData) => (
                    <div>
                      {rowData.window && rowData.deliveryType == "DELIVER" ? (
                        <>
                          <label>
                            {Moments(rowData.window.date).format("ll")}
                          </label>
                          <label>
                            {rowData.window.start} - {rowData.window.end}
                          </label>
                        </>
                      ) : (
                        <label>Not Local Delivery</label>
                      )}
                    </div>
                  ),
                  width: "5%",
                },
                {
                  title: "Tracking Url",
                  field: "",
                  render: (rowData) => this.getTrackUrl(rowData),
                  width: "5%",
                },
                // {
                //     title: 'Assigned To', field: '', width: "10%",
                //     render: rowData => rowData.hasOwnProperty("orderAssignment") === true &&
                //         <span>{this.getAssignee(rowData)}</span>
                // },
                {
                  title: "Order Slip",
                  field: "",
                  width: "10%",
                  render: (rowData) => (
                    <>
                      <a
                        onClick={() => {
                          this.printOrderSlip(rowData);
                        }}
                        className="blue-text"
                      >
                        Print Slip
                      </a>
                    </>
                  ),
                },
                {
                  title: "Action",
                  field: "",
                  width: "10%",
                  render: (rowData) => (
                    <>
                      <a
                        onClick={() => this.processOrder(rowData._id)}
                        className="blue-text"
                      >
                        View
                      </a>
                      <br />
                      <a
                        onClick={() => this.selectChecklist(rowData)}
                        className="purple-text"
                      >
                        Checklist
                      </a>{" "}
                      <br />
                      <a
                        onClick={() => this.assignTo(rowData)}
                        className="blue-text"
                      >
                        Assign To
                      </a>
                    </>
                  ),
                },
              ]}
              data={this.state.orderList}
              options={{
                showTitle: false,
                pageSize: this.state.pageSize,
                pageSizeOptions: [10, 50, 100],
                emptyRowsWhenPaging: false,
                initialPage: this.state.page,
              }}
              page={this.state.page}
              onChangeRowsPerPage={this.handlePageSizeChange}
              onChangePage={this.handlePageChange}
            />
          )}
        </div>
        <MDBModal
          isOpen={this.state.modalViewOrder}
          toggle={this.toggle("ViewOrder")}
          size={"fluid"}
          fullHeight={true}
          position={"top"}
        >
          <MDBModalHeader toggle={this.toggle("ViewOrder")}>
            View Order
          </MDBModalHeader>
          <MDBModalBody className={"view-order-sticky"}>
            <ViewOrder activeItem={this.state.activeItem} />

            <div className={"col-12 text-center sticky-bottom border-top pt-5"}>
              <MDBBtn size={"md"} onClick={this.goToOrderDetails}>
                Process Order
              </MDBBtn>
            </div>
          </MDBModalBody>
        </MDBModal>
        <MDBModal
          isOpen={this.state.modalTrackingUrl}
          size={"lg"}
          toggle={() => {
            this.addTrackingUrl(null);
          }}
        >
          <MDBModalHeader
            toggle={() => {
              this.addTrackingUrl(null);
            }}
          >
            Tracking details
          </MDBModalHeader>
          <MDBModalBody>
            <MDBRow className={"my-2"}>
              <MDBCol className={"my-2"}>
                <MDBSelect
                  outline={true}
                  key={"aggregator" + this.state.key}
                  label={"Carrier Aggregator"}
                  getValue={(e) => this.changeAggregator(e)}
                  options={this.state.aggregatorList}
                ></MDBSelect>
              </MDBCol>
            </MDBRow>

            <MDBRow className={"my-2"}>
              <MDBCol className={"my-2"}>
                <MDBSelect
                  outline={true}
                  key={"carrier" + this.state.key}
                  label={"Carrier Name"}
                  getValue={(e) => this.changeCarrier(e)}
                  options={this.state.carrierList}
                ></MDBSelect>
              </MDBCol>
            </MDBRow>

            {/*<MDBRow className={"my-2"}>*/}
            {/*    <MDBCol className={"my-2"}>*/}
            {/*        <MDBSelect outline={true}*/}
            {/*                   label={"Carrier Name"} getValue={(e) => this.setState({carrierName: e[0]})}*/}
            {/*                   options={this.state.carrierList}  ></MDBSelect>*/}
            {/*    </MDBCol>*/}
            {/*</MDBRow>*/}

            <MDBRow>
              <MDBCol>
                <MDBInput
                  onBlur={this.formTrackingLink}
                  label={"Tracking Id"}
                  value={this.state.trackingId}
                  getValue={(e) => {
                    this.setState({ trackingId: e });
                  }}
                />
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol>
                <MDBInput
                  onBlur={this.formTrackingLink}
                  label={"Shipping Expense (₹)"}
                  value={this.state.shippingExpense}
                  onChange={(e) => {
                    if (
                      !utility.isNumericTwoDecimalPartial(e.target.value) &&
                      e.target.value !== ""
                    ) {
                      return false;
                    }
                    this.setState({ shippingExpense: e.target.value });
                  }}
                />
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol>
                <MDBInput
                  label={"Tracking URL"}
                  value={this.state.trackingUrl}
                  getValue={(e) => {
                    this.setState({ trackingUrl: e });
                  }}
                />
              </MDBCol>
            </MDBRow>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              size={"md"}
              outline
              onClick={() => {
                this.addTrackingUrl(null);
              }}
            >
              Close
            </MDBBtn>
            <MDBBtn
              size={"md"}
              onClick={() => {
                this.saveTrackingUrl();
              }}
            >
              Save
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
        <MDBModal
          isOpen={this.state.modalUpdatePaymentStatus}
          toggle={this.toggle("UpdatePaymentStatus")}
        >
          <MDBModalHeader toggle={this.toggle("UpdatePaymentStatus")}>
            Update Payment Status
          </MDBModalHeader>
          <MDBModalBody>
            <div className={"col-12 row mx-0 p-2"}>
              <div className={"col-12 text-center p-2"}>
                <h6>Do you want to update payment status ?</h6>
              </div>
              <div className={"col-12 p-2 my-1"}>
                <MDBSelect
                  outline={true}
                  label={"Payment Mode"}
                  getValue={(e) => this.setState({ pymntMode: e[0] })}
                  options={this.state.paymentModeList}
                ></MDBSelect>
              </div>
              <div className={"col-12 px-2 mb-1"}>
                <MDBInput
                  label={"Amount"}
                  value={this.state.amount}
                  type="number"
                  onChange={(e) => {
                    this.setState({ amount: e.target.value });
                  }}
                  outline
                />
              </div>
              <div className={"col-12 text-center"}>
                <MDBBtn
                  color="default"
                  outline
                  onClick={this.toggle("UpdatePaymentStatus")}
                >
                  Close
                </MDBBtn>
                <MDBBtn
                  color="default"
                  onClick={() => this.changePaymentStatus()}
                >
                  Update
                </MDBBtn>
              </div>
            </div>
          </MDBModalBody>
        </MDBModal>
        <MDBModal
          isOpen={this.state.modalChecklist}
          toggle={this.toggle("Checklist")}
        >
          <MDBModalHeader toggle={this.toggle("Checklist")}>
            Checklist
          </MDBModalHeader>
          <MDBModalBody>
            {this.state.activeItem !== null && (
              <div className={"col-12 row m-0 p-2 card"}>
                <p>
                  OrderId: {this.state.activeItem.orderCode}
                  <br />
                  Customer Name: {this.state.activeItem.customerDetails.name}
                </p>
                <div className={"col-9 text-left card-text"}>
                  {this.state.checklist.map((item, index) =>
                    item.disabled === true ? (
                      <p key={index}>
                        <MDBInput
                          label={item.message}
                          type="checkbox"
                          id={"checkbox" + index}
                          checked={item.checked}
                          disabled
                        />
                        {Moments(item.createdAt).format("LL")} at{" "}
                        {Moments(item.createdAt).format("LT")},
                        <span className={"text-capitalize"}>
                          {" "}
                          {item.createdBy}
                        </span>
                      </p>
                    ) : (
                      <p key={index}>
                        <MDBInput
                          label={item.message}
                          type="checkbox"
                          id={"checkbox" + index}
                          checked={item.checked}
                          onChange={() => this.updateChecklist(index)}
                        />
                      </p>
                    )
                  )}
                </div>
              </div>
            )}
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              size={"md"}
              outline
              onClick={() => {
                this.toggle("Checklist")();
              }}
            >
              Cancel
            </MDBBtn>
            <MDBBtn
              size={"md"}
              onClick={() => {
                this.saveChecklist();
              }}
            >
              Save
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
        <MDBModal
          isOpen={this.state.modalAssignOrder}
          toggle={this.toggle("AssignOrder")}
        >
          <MDBModalHeader toggle={this.toggle("AssignOrder")}>
            Assign Order
          </MDBModalHeader>
          <MDBModalBody>
            {this.state.activeItem !== null && (
              <div className={"col-12 row mx-0 p-2"}>
                <div className={"col-12"}>
                  <p className={"font-weight-bold"}>
                    {this.state.activeItem.orderCode} order, will be assigned
                    to:
                  </p>
                  <div>
                    <MDBSelect
                      key={this.state.keyQU}
                      getValue={this.orderAssignment}
                    >
                      <MDBSelectInput selected="Select Assignee" />
                      <MDBSelectOptions search={true}>
                        <MDBSelectOption value="" disabled selected>
                          Select assignee
                        </MDBSelectOption>
                        {this.state.teamList.map((item, idx) => (
                          <MDBSelectOption
                            value={item._id + "_" + item.name}
                            key={idx}
                            selected={this.state.assignedTo === item._id}
                          >
                            {item.name} [
                            <span className={"text-danger"}>
                              {" "}
                              {item.phone}{" "}
                            </span>
                            ]
                          </MDBSelectOption>
                        ))}
                      </MDBSelectOptions>
                    </MDBSelect>
                  </div>
                </div>
              </div>
            )}
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              size={"md"}
              outline
              onClick={() => {
                this.toggle("AssignOrder")();
              }}
            >
              Cancel
            </MDBBtn>
            <MDBBtn
              size={"md"}
              onClick={() => {
                this.assignOrder();
              }}
            >
              Save
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
        <MDBModal
          isOpen={this.state.modalDownloadLabel}
          toggle={this.toggle("DownloadLabel")}
          size="lg"
        >
          <MDBModalHeader toggle={this.toggle("DownloadLabel")}>
            Download Label
          </MDBModalHeader>
          <MDBModalBody>
            <DownloadLabel data={this.state.selectedRow} />
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              size={"md"}
              outline
              onClick={() => {
                this.toggle("DownloadLabel")();
              }}
            >
              Close
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal
          isOpen={this.state.modalPrintOrderSlip}
          toggle={this.toggle("PrintOrderSlip")}
          size="lg"
        >
          <MDBModalHeader toggle={this.toggle("PrintOrderSlip")}>
           Print Order Slip
          </MDBModalHeader>
          <MDBModalBody>
            <PrintOrderSlip data={this.state.selectedRow}/>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              size={"md"}
              outline
              onClick={() => {
                this.toggle("PrintOrderSlip")();
              }}
            >
              Close
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </React.Fragment>
    );
  }
}

export default withRouter(ListOnlineOrdersCmp);
