import React from "react";
import { CSVLink } from "react-csv";
import { withRouter } from "react-router-dom";
import {
  MDBBtn,
  MDBBox,
  MDBSpinner,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdbreact";
import UserStore from "../../../stores/userStore";
import { toast } from "react-toastify";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import PharmacyService from "../../../services/pharmacyService";
import Moments from "moment";
import GetDateRange from "../../common/getDateRange";
import SimpleBackdrop from "../../common/overlay";
import MaterialTable from "material-table";
import config from "../../../config";
import ReportService from "../../../services/reports";

import EditInventory from "../../../components/inventory/v3/editInventory";

import GetDateRangeFunc from "../../common/getDateRangeFunc";

let Barcode = require("react-barcode");

const headers = [
  { label: "Internal Stock Id", key: "_id" },
  { label: "Product Name", key: "productName" },
  { label: "Invoice Number", key: "invoiceNumber" },
  { label: "Shipment Code", key: "shipmentCode" },
  { label: "Batch", key: "batch" },
  { label: "Retail Package", key: "retailPackage" },
  { label: "Content Quantity", key: "contentQuantity" },
  { label: "Packaging Unit", key: "quantityUnit" },
  { label: "Loose Sale Allowed", key: "looseSaleAllowed" },
  { label: "Supplier Name", key: "supplierName" },
  { label: "Mfg Date", key: "mfgDate" },
  { label: "Exp Date", key: "expDate" },
  { label: "MRP", key: "mrp" },
  { label: "Price With GST", key: "priceWgst" },
  { label: "Discount", key: "discount" },
  { label: "Purchase Quantity", key: "quantityIn" },
  { label: "Stock", key: "quantity" },
  { label: "Loose Stock", key: "looseQuantity" },
  { label: "Stock Status", key: "stockStatus" },
  { label: "Bar Code", key: "barCode" },
  { label: "Entered By", key: "enteredBy" },
  { label: "Reviewed By", key: "reviewedBy" },
  { label: "Reviewed Time", key: "reviewedTime" },
  { label: "Review Status", key: "reviewStatus" },
];

class ListInventoryV2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: {},
      orders: [],
      orderModal: false,
      modalLoading: false,
      modalReviewDisp: false,
      acceptedData: null,
      stockReviewComment: "",
      loc: null,
      startDate: new Date(),
      endDate: new Date(),
      selectedBarCode: "",
      modal: false,
      editInvModal: false,
      modalStorage: false,
      modalReview: false,
      totalInstockPriceWgst: 0,
      totalInstockMrp: 0,
      totalPriceWgst: 0,
      totalMrp: 0,
      isLoading: false,
      stockData: [],
      pageSize: 10,
    };
  }

  async componentDidMount() {
    let userData = await UserStore.fetchSessionData();
    this.setState({ userData });
    console.log(userData.sessionToken)
    this.tableRef.current.onQueryChange();
  }

  filterData = (stocks) => {
    let temp = [];
    // let totalMrp = 0;
    // let totalPriceWgst = 0;
    // let totalInstockMrp = 0;
    // let totalInstockPriceWgst = 0;
    for (let item of stocks) {
      let stockStatus = "Active";
      if (Moments(item.expDate).isSameOrBefore(Moments())) {
        stockStatus = "Expired";
      }
      let barCode = "";
      if (item.hasOwnProperty("barCode")) {
        barCode = item.barCode;
      } else {
        barCode = 0;
      }
      let reviewedBy = "";
      let reviewStatus = "";
      let reviewTime = "";
      if (item.hasOwnProperty("revieweDetails")) {
        reviewedBy = item.revieweDetails.reviewedBy;
        reviewStatus =
          item.revieweDetails.isApproved === "Y" ? "Accepted" : "Rejected";
        reviewTime = Moments(item.revieweDetails.reviewedAt).format(
          "MMM DD, YYYY"
        );
      }
      let t = {
        createdAt: Moments(item.createdAt).format("MMM DD, YYYY"),
        retailPackage: item.productDetails.retailPackage,
        contentQuantity: item.productDetails.contentQuantity,
        quantityUnit: item.productDetails.quantityUnit,
        looseSaleAllowed: item.productDetails.looseSaleAllowed,
        invoiceNumber: item.shipmentDetail.invoiceNumber,
        productName: item.productDetails.productName,
        batch: item.batch,
        supplierName: item.supplierDetail.supplierName,
        mfgDate: Moments(item.mfgDate).format("MMM YYYY"),
        mrp: parseFloat(item.mrp).toFixed(2),
        priceWgst: parseFloat(item.priceWgst).toFixed(2),
        discount: parseFloat(item.discount.toFixed(2)),
        quantityIn: parseFloat(item.quantityIn),
        quantity: parseFloat(item.quantity),
        expDate: Moments(item.expDate).format("MMM YYYY"),
        _id: item._id,
        stockStatus: stockStatus,
        enteredBy: item.createdBy.name,
        barCode: barCode,
        shipmentCode: item.shipmentDetail.shipmentCode,
        reviewedBy: reviewedBy,
        reviewedTime: reviewTime,
        reviewStatus: reviewStatus,
        looseQuantity: item.looseQuantity,
      };
      // totalMrp = totalMrp + (parseFloat(item.mrp) * parseFloat(item["quantityIn"]));
      // totalPriceWgst = totalPriceWgst + (parseFloat(item.priceWgst) * parseFloat(item["quantityIn"]));
      //
      // //calculating total in stock mrp
      // if (parseFloat(item["quantity"]) > 0) {
      //     totalInstockMrp = totalInstockMrp + (parseFloat(item["quantity"]) * parseFloat(item.mrp));
      //     totalInstockPriceWgst = totalInstockPriceWgst + (parseFloat(item["quantity"]) * parseFloat(item.priceWgst));
      // }
      temp.push(t);
    }
    this.setState({
      stockData: temp,
      // totalMrp: totalMrp,
      // totalPriceWgst: totalPriceWgst,
      // totalInstockMrp: totalInstockMrp,
      // totalInstockPriceWgst: totalInstockPriceWgst
    });
    return temp;
  };
  toggle = (row) => {
    if (row) {
      this.setState({ selectedBarCode: row.barCode });
    }
    this.setState({
      modal: !this.state.modal,
    });
  };
  toggleStorage = async (row) => {
    this.setState({ loc: null });
    if (row !== null) {
      this.setState({ modalStorage: true });
      let loc = await PharmacyService.fetchStoreLocById(
        this.state.userData,
        row._id
      );
      if (loc !== false) {
        await this.setState({ loc });
      } else {
        toast.error(
          "Storage info might not been configured for this product. Please contact your system administrator."
        );
      }
    } else {
      this.setState({ modalStorage: false });
    }
  };
  toggleReview = async (row) => {
    this.setState({ loc: null, stockReviewComment: "", modalLoading: true });
    if (row !== null) {
      this.setState({ modalReview: true });
      let loc = await PharmacyService.fetchStoreLocById(
        this.state.userData,
        row._id
      );
      if (loc !== false) {
        await this.setState({ loc, modalLoading: false });
      } else {
        toast.error(
          "Storage info might not been configured for this product. Please contact your system administrator."
        );
        this.setState({ modalLoading: false });
      }
    } else {
      this.setState({ modalReview: false });
    }
  };
  acceptReview = async (rec) => {
    this.setState({ modalLoading: true });
    let status = await PharmacyService.approveStockV3(
      this.state.userData,
      rec._id,
      this.state.stockReviewComment
    );
    this.setState({ modalLoading: false });
    if (status === false) {
      toast.error(
        "We are facing some server issue. Please contact your system admin for further details."
      );
    } else {
      toast.success("Stock successfully approved.");
      this.setState({ modalReview: false });
      this.tableRef.current.onQueryChange();
    }
  };
  rejectReview = async (rec) => {
    this.setState({ modalLoading: true });
    let status = await PharmacyService.rejectStockV3(
      this.state.userData,
      rec._id,
      this.state.stockReviewComment
    );
    this.setState({ modalLoading: false });
    if (status === false) {
      toast.error(
        "We are facing some server issue. Please contact your system admin for further details."
      );
    } else {
      toast.success("Stock successfully rejected.");
      this.setState({ modalReview: false });
      this.tableRef.current.onQueryChange();
    }
  };
  toggleAccept = (row) => {
    this.setState({ modalLoading: true });
    this.setState({ acceptedData: null });
    if (row !== null) {
      this.setState({ modalReviewDisp: true });
      this.setState({ acceptedData: row });
    } else {
      this.setState({ modalReviewDisp: false });
    }
  };
  toggleOrder = async (row) => {
    this.setState({ orders: [], modalLoading: true });
    if (row !== null) {
      this.setState({ orderModal: true });
      let orders = await PharmacyService.fetchOrdersByStockId(
        this.state.userData,
        row._id
      );
      if (orders === false) {
        toast.error(
          "Currently we are facing some issue. Please contact your administrator."
        );
      } else {
        this.setState({ orders: orders, modalLoading: false });
      }
    } else {
      this.setState({ orderModal: false, modalLoading: false });
    }
  };
  toggleEditInvModal = async (stockId, action) => {
    if (stockId !== null) {
      this.setState({ stockId, editInvModal: true });
    } else {
      this.setState({ editInvModal: false });
    }
  };
  getDateRangeCallback = async (startDate, endDate) => {
    if (startDate > endDate) {
      toast.info(
        "Start date is greater than the end date. Please adjust the date range again."
      );
      return;
    }
    let endDateThreeMonthGap = Moments(startDate).add(2, "months");
    if (endDate > endDateThreeMonthGap) {
      let payload = {
        startDate: Moments(startDate).format("YYYY-MM-DD"),
        endDate: Moments(endDate).format("YYYY-MM-DD"),
        section: "STOCK_LIST",
      };
      //Create a record request
      this.setState({ progressStatus: true });
      let res = await ReportService.requestCreateReport(
        this.state.userData.sessionToken,
        payload
      );
      this.setState({ progressStatus: false });
      if (res) {
        toast.success(
          "A request has been raised to create a report for the specified time duration with the name, " +
            "ONLINE_ORDER_" +
            Moments(startDate).format("YYYY-MM-DD") +
            "_" +
            Moments(endDate).format("YYYY-MM-DD") +
            "_" +
            res._id +
            ".csv"
        );
      } else {
        toast.error(
          "Something went wrong while requesting the report for the duration " +
            Moments(startDate).format("YYYY-MM-DD") +
            " and " +
            Moments(endDate).format("YYYY-MM-DD")
        );
      }
      return false;
    }
    await this.setState({ startDate, endDate });
    this.tableRef.current.onQueryChange();

  };
  tableRef = React.createRef();

  render() {
    return (
      <React.Fragment>
        <SimpleBackdrop status={this.state.isLoading} />
        <div className={"col-12 row mx-0"}>
          <div className={"col-md-9 p-0"}>
            {/* <GetDateRange getDateRangeCallback={this.getDateRangeCallback}/> */}
            <GetDateRangeFunc getDateRangeCallback={this.getDateRangeCallback} />
          </div>
          <div className={"col-md-3 text-right p-0"}>
            <CSVLink
              data={this.state.stockData}
              headers={headers}
              target="_blank"
              filename={
                "stock_" +
                Moments(this.state.startDate).format("MMM, YYYY") +
                "_" +
                Moments(this.state.endDate).format("MMM, YYYY") +
                ".csv"
              }
            >
              <MDBBtn size={"md"} disabled={this.state.isLoading}>
                <i className="fas fa-download"></i>&nbsp;Export
              </MDBBtn>
            </CSVLink>
            <MDBBtn
              size={"md"}
              disabled={this.state.isLoading}
              onClick={() => {
                this.props.history.push("/add-stock");
              }}
            >
              Add
            </MDBBtn>
          </div>
        </div>
        {this.state.userData.hasOwnProperty("sessionToken") && (
          <MaterialTable
            tableRef={this.tableRef}
            columns={[
              { title: "Date", field: "createdAt", width: "5%" },
              { title: "Pkg", field: "retailPackage", width: "5%" },
              { title: "Pkg Qty", field: "contentQuantity", width: "5%" },
              { title: "Unit", field: "quantityUnit", width: "5%" },
              { title: "Loose", field: "looseSaleAllowed", width: "3%" },
              { title: "Invoice", field: "invoiceNumber", width: "5%" },
              { title: "Batch", field: "batch", width: "5%" },
              { title: "Product", field: "productName", width: "10%" },
              { title: "Sup", field: "supplierName", width: "15%" },
              {
                title: "Qty",
                field: "quantity",
                width: "5%",
                render: (rowData) => (
                  <div className={"d-block"}>
                    Qnty-{" "}
                    {Number.isInteger(rowData.quantity)
                      ? rowData.quantity
                      : rowData.quantity.toFixed(2)}
                    <br />
                    Loose Qnty- {rowData.looseQuantity}
                  </div>
                ),
              },
              { title: "Mfg", field: "mfgDate", width: "3%" },
              { title: "Exp", field: "expDate", width: "3%" },
              { title: "MRP(₹)", field: "mrp", width: "3%" },
              { title: "Price(₹)", field: "priceWgst", width: "3%" },
              { title: "Disc(%)", field: "discount", width: "3%" },
              { title: "Qty", field: "quantityIn", width: "3%" },
              {
                title: "Stock",
                field: "quantity",
                width: "3%",
                render: (rowData) => rowData.quantity.toFixed(2),
              },
              { title: "Status", field: "stockStatus", width: "3%" },
              { title: "Entry", field: "enteredBy", width: "3%" },
              {
                title: "",
                field: "",
                width: "5%",
                render: (rowData) => (
                  <div className={"d-block"}>
                    <MDBBtn
                      size={"sm"}
                      onClick={() => {
                        this.toggle(rowData);
                      }}
                    >
                      Barcode
                    </MDBBtn>
                    <br />
                    <MDBBtn
                      size={"sm"}
                      onClick={() => {
                        this.toggleStorage(rowData);
                      }}
                    >
                      Storage
                    </MDBBtn>
                    <br />
                    <>
                      {rowData.reviewedBy === "" ? (
                        <MDBBtn
                          size={"sm"}
                          onClick={() => {
                            this.toggleReview(rowData);
                          }}
                        >
                          Review
                        </MDBBtn>
                      ) : (
                        <MDBBtn
                          size={"sm"}
                          onClick={() => {
                            this.toggleAccept(rowData);
                          }}
                        >
                          {rowData.reviewStatus}
                        </MDBBtn>
                      )}
                    </>
                    <br />
                    <MDBBtn
                      size={"sm"}
                      onClick={() => this.toggleEditInvModal(rowData._id)}
                    >
                      Edit
                    </MDBBtn>
                    <br />
                  </div>
                ),
              },
              {
                title: "",
                field: "",
                width: "5%",
                render: (rowData) => (
                  <span>
                    <div
                      className={"cursor-pointer text-primary"}
                      onClick={() => {
                        this.toggleOrder(rowData);
                      }}
                    >
                      Orders
                    </div>
                  </span>
                ),
              },
            ]}
            data={(query) =>
              new Promise((resolve, reject) => {
                let url =
                  config.APIHOST +
                  config.FETCH_INVENTORY_V3 +
                  "?startDate=" +
                  Moments(this.state.startDate).format("YYYY-MM-DD") +
                  "&endDate=" +
                  Moments(this.state.endDate).format("YYYY-MM-DD");
                this.setState({ pageSize: query.pageSize });
                if (query.search) {
                  url += `&q=${query.search}`;
                }
                url += `&page=${query.page + 1}`;
                url += `&pageSize=${query.pageSize}`;
                url += `&type=paginated`;
                const headers = {
                  method: "GET",
                  headers: { Authorization: this.state.userData.sessionToken },
                };
                fetch(url, headers)
                  .then((resp) => resp.json())
                  .then((resp) => {
                    resolve({
                      data: this.filterData(resp.data), // your data array
                      page: query.page, // current page number
                      totalCount: resp.totalRecord, // total row number
                    });
                  })
                  .catch((error) => {
                    // toast.error("Something went wrong while fetching the stock list, please try again after a while.")
                    resolve({
                      data: [],
                      page: query.page,
                      totalCount: 0,
                    });
                  });
              })
            }
            options={{
              showTitle: false,
              pageSize: this.state.pageSize,
              pageSizeOptions: [10, 50, 100],
              emptyRowsWhenPaging: false,
              tableLayout: "fixed",
              rowStyle: (rowData) => {
                const style = {};
                if (rowData.stockStatus === "Expired") {
                  style.backgroundColor = "#FF7F7F";
                  style.color = "white";
                } else if (rowData.quantity <= 0) {
                  style.backgroundColor = "#ffffba";
                }
                return style;
              },
              debounceInterval: 800,
            }}
          />
        )}
        <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
          <MDBModalHeader toggle={this.toggle}>Bar Code</MDBModalHeader>
          <MDBModalBody>
            {this.state.selectedBarCode !== 0 && (
              <Barcode value={this.state.selectedBarCode}></Barcode>
            )}
            {this.state.selectedBarCode === 0 && (
              <div className={"no-data-container"}>No barcode found.</div>
            )}
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn size={"md"} onClick={this.toggle}>
              Close
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
        <MDBModal
          isOpen={this.state.modalStorage}
          toggle={() => {
            this.toggleStorage(null);
          }}
        >
          <MDBModalHeader
            toggle={() => {
              this.toggleStorage(null);
            }}
          >
            Storage Info
          </MDBModalHeader>
          <MDBModalBody>
            {this.state.loc !== null && (
              <>
                <MDBBox>Location: {this.state.loc.storageLoc.name}</MDBBox>
                <MDBBox>Floor: {this.state.loc.floor.floorName}</MDBBox>
                <MDBBox>Rack: {this.state.loc.rack.rackName}</MDBBox>
                <MDBBox>Box: {this.state.loc.box.boxName}</MDBBox>
              </>
            )}
            {this.state.loc === null && <MDBSpinner />}
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              size={"md"}
              onClick={() => {
                this.toggleStorage(null);
              }}
            >
              Close
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal
          size={"lg"}
          isOpen={this.state.modalReview}
          toggle={() => {
            this.toggleReview(null);
          }}
        >
          <MDBModalHeader
            toggle={() => {
              this.toggleReview(null);
            }}
          >
            Review Checklist
          </MDBModalHeader>
          <MDBModalBody>
            {this.state.loc !== null && (
              <>
                <MDBBox>Check if product name is right?</MDBBox>
                <MDBBox>Check if batch is right?</MDBBox>
                <MDBBox>Check if invoice no is right?</MDBBox>
                <MDBBox>Check if mfg date is right?</MDBBox>
                <MDBBox>Check if exp date is right?</MDBBox>
                <MDBBox>Check if MRP is right?</MDBBox>
                <MDBBox>Check if Purchase price is right?</MDBBox>
                <MDBBox>Check if Purchase Quantity is right?</MDBBox>
                <MDBBox>Check if Barcode is right?</MDBBox>
                <MDBBox>Check if storage information is right?</MDBBox>
                <MDBBox>
                  <br />
                  <br />
                  <MDBInput
                    value={this.state.stockReviewComment}
                    type={"textarea"}
                    label={"Review comment if any"}
                    getValue={(e) => {
                      this.setState({ stockReviewComment: e });
                    }}
                  ></MDBInput>
                </MDBBox>
              </>
            )}
            {this.state.loc === null && <MDBSpinner />}
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              outline
              size={"md"}
              onClick={() => {
                this.toggleReview(null);
              }}
              disabled={this.state.modalLoading}
            >
              Close
            </MDBBtn>
            <MDBBtn
              outline
              size={"md"}
              onClick={() => {
                this.rejectReview(this.state.loc);
              }}
              disabled={this.state.modalLoading}
            >
              Reject
            </MDBBtn>
            <MDBBtn
              size={"md"}
              onClick={() => {
                this.acceptReview(this.state.loc);
              }}
              disabled={this.state.modalLoading}
            >
              Accept
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal
          isOpen={this.state.modalReviewDisp}
          toggle={() => {
            this.toggleAccept(null);
          }}
        >
          <MDBModalHeader
            toggle={() => {
              this.toggleAccept(null);
            }}
          >
            Review Details
          </MDBModalHeader>
          <MDBModalBody>
            {this.state.acceptedData !== null && (
              <>
                <div>Reviewed By: {this.state.acceptedData.reviewedBy}</div>
                <div>Review Status: {this.state.acceptedData.reviewStatus}</div>
                <div>
                  Reviewed At:{" "}
                  {Moments(this.state.acceptedData.reviewedTime).format(
                    "MMM DD, YYYY"
                  )}
                </div>
              </>
            )}
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              onClick={() => {
                this.toggleAccept(null);
              }}
            >
              Close
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal
          isOpen={this.state.editInvModal}
          toggle={() => this.toggleEditInvModal(null)}
          size="fluid"
        >
          <MDBModalHeader toggle={() => this.toggleEditInvModal(null)}>
            Edit Stock
          </MDBModalHeader>
          <MDBModalBody>
            <EditInventory
              loadedOnPopup={true}
              stockId={this.state.stockId}
              toggleEditInvModal={this.toggleEditInvModal}
            />
          </MDBModalBody>
        </MDBModal>

        <MDBModal
          size={"lg"}
          isOpen={this.state.orderModal}
          toggle={() => {
            this.toggleOrder(null);
          }}
        >
          <MDBModalHeader
            toggle={() => {
              this.toggleOrder(null);
            }}
          >
            View Associated orders
          </MDBModalHeader>
          <MDBModalBody>
            {this.state.modalLoading === true && <MDBSpinner />}
            {this.state.modalLoading === false && (
              <>
                <MDBTable>
                  <MDBTableHead>
                    <tr>
                      <th>#</th>
                      <th>Batch</th>
                      <th>Order Code</th>
                      <th>Order Date</th>
                      <th>Order Mode</th>
                      <th>Quantity</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {this.state.orders.length === 0 && (
                      <tr>
                        <td align={"center"} colSpan={6}>
                          <div className={"no-data-container"}>
                            No records found.
                          </div>
                        </td>
                      </tr>
                    )}
                    {this.state.orders.map((row, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{row.batch}</td>
                        <td>{row.orderCode}</td>
                        <td>{Moments(row.orderDate).format("MMM D, YYYY")}</td>
                        <td>{row.orderMode}</td>
                        <td>{row.quantity}</td>
                      </tr>
                    ))}
                  </MDBTableBody>
                </MDBTable>
              </>
            )}
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              size={"md"}
              onClick={() => {
                this.toggleOrder(null);
              }}
            >
              Close
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </React.Fragment>
    );
  }
}

export default withRouter(ListInventoryV2);
