import React, { useState, useEffect } from "react";
import {
    MDBBox,
    MDBBreadcrumb,
    MDBBreadcrumbItem
} from 'mdbreact';
import { Link } from "react-router-dom";
import { Grid, Typography,Button } from "@material-ui/core";
import config from "../../config";
import SearchStock from "../../newComponents/searchDropdown/searchStock";
import TableProduct from "../../newComponents/ProductTable";
import UserStore from "../../stores/userStore";
import TransferStockService from "../../services/transferStockService";
import SimpleBackdrop from "../../components/common/overlay"
import DeleteIcon from '@material-ui/icons/Delete';

const AllStock = () => {
    const { SEARCH_DROPDOWN_PRODUCT } = config

    const [userData, setUserData] = useState({});
    const [productData, setProductData] = useState([])
    const [productListNames, setProductListNames] = useState([])
    const [loading, setLoading] = useState(false)
    const [isEmpty, setEmpty] = useState(false)
    const [selected, setSelected] = useState(false)
    const [showNameDiv, setShowNameDiv] = useState(false)
    

    const handleSearchProd = (name, type) => {
        console.log({ type })
        if (type == "BARCODE"){
            setShowNameDiv(false)
            setProductData([])
            handleSubmitWithBar(name)
        }
        else if(name=="clear"){
            setShowNameDiv(false)
            setProductData([])
        }
        else {
            setShowNameDiv(true)
            setProductData([])
            handleSubmitWithName(name)
        }
    }

    useEffect(() => {
        fetchUser()
    }, [])

    const fetchUser = async () => {
        let user = await UserStore.fetchSessionData();
        setUserData(user);
    }
    const handleSubmitWithBar = async (name) => {
        try {
            setLoading(true)
            setSelected(false)
            const res = await TransferStockService.searchProductList(userData.sessionToken, name)
            if (res.length) {
                const resLoc = await TransferStockService.locationWithProductId(userData.sessionToken, res[0]?.id)
                setProductData(resLoc)
            }
            else {
                setProductData([])
                setEmpty(true)
            }
            console.log({ res })
            setLoading(false)
        }
        catch (err) {
            console.log({ err })
        }
    }
    const handleSubmitWithName = async (name) => {
        try {
            setLoading(true)
            setSelected(false)
            setEmpty(false)
            const res = await TransferStockService.searchProductListwithName(userData.sessionToken, name)
            // console.log(res)
            setProductListNames(res)
            setProductData([])
            setLoading(false)
        }
        catch (err) {
            console.log({ err })
        }
    }

    const selectProdName = async(product) =>{
        setProductListNames([product])
        setSelected(true)
        try{
            setLoading(true)
            const resLoc = await TransferStockService.locationWithProductId(userData.sessionToken, product.id)
            if (resLoc.length) {
                setProductData(resLoc)
                setLoading(false) 
            }
            else {
                setProductData([])
                setEmpty(true)
                setLoading(false)
            }

        }
        catch(err){
            console.log(err)
        }
       
    }
    const emptyData = () =>{
        setProductListNames([])
        setProductData([])
        setShowNameDiv(false)
    }
    return (
        <><SimpleBackdrop status={loading} />
            <MDBBreadcrumb>
                <MDBBreadcrumbItem><Link to={'/dashboard'}>Dashboard</Link></MDBBreadcrumbItem>
                <MDBBreadcrumbItem active>Product Details</MDBBreadcrumbItem>
            </MDBBreadcrumb>
            <MDBBox className={"min-vh-100"}>
                <Grid container className="productPageContainer">
                    <Grid item xs={12} className="searchComponent">
                        <SearchStock
                            OPTIONS={SEARCH_DROPDOWN_PRODUCT}
                            CONFIG={config}
                            handleSearchProd={handleSearchProd}
                            isProduct={true}
                            isDropDown={true}
                        />
                    </Grid>
                    <Grid item xs={4}>
                      {showNameDiv &&  <div className="scrollableDivProd">
                            {productListNames?.map((item, key) =>
                                <div className="optionMenu">
                                    <Typography variant="body1">   {item.productName}</Typography>
                                    <div style={{display:"flex",justifyContent:"space-around",alignItems:"center"}}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => !selected && selectProdName(item)}
                                    >
                                        {selected ?"SELECTED":"SELECT"}
                                    </Button>
                                    
                                    {selected && <DeleteIcon fontSize="large" className="deleteIcon" onClick={emptyData} />}
                                    </div>
                                </div>
                            )}
                        </div>}
                    </Grid>
                    <Grid item xs={12} className="productTableContainer">
                        <TableProduct
                         data={productData} 
                         isEmpty={isEmpty} 
                         productId={productListNames?.[0]?.id}
                         />
                    </Grid>
                </Grid>
            </MDBBox>
        </>
    );
};

export default AllStock;
