import React, { Component } from "react";
import SimpleBackdrop from "../common/overlay";
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBTabPane,
  MDBTabContent,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
} from "mdbreact";
import { CSVLink } from "react-csv";
import Moments from "moment";
import AddMobileBanner from "./addMobileBanner";
import UserStore from "../../stores/userStore";
import CmsService from "../../services/cmsService";
import { toast } from "react-toastify";
import config from "../../config";

export default class MobileBannerCmp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      userData: {},
      banners: [],
      activeTabView: "active",
      modalAddBanner: false,
      activeBanner: null,
    };
  }

  async componentDidMount() {
    let userData = await UserStore.fetchSessionData();
    this.setState({ userData });
    await this.refreshPage();
  }

  // refresh page--------------------

  refreshPage = async () => {
    this.setState({ isLoading: true });
    let banners = await CmsService.fetchMobBanners(this.state.userData);
    this.setState({ isLoading: false });
    if (banners) {
      this.setState({ banners });
    } else {
      toast.error("We are facing some server issue. Please try again later.");
    }
  };

  toggle = (nr) => () => {
    let modalNumber = "modal" + nr;
    this.setState({
      [modalNumber]: !this.state[modalNumber],
    });
  };

  activeTabChange = (tab) => (e) => {
    if (this.state.activeTabView !== tab) {
      this.setState({
        activeTabView: tab,
      });
    }
  };

  editBanner = (item) => {
    this.setState({ activeBanner: item });
    this.toggle("AddBanner")();
  };

  addBanner = () => {
    this.setState({ activeBanner: null });
    this.toggle("AddBanner")();
  };

  // deactivate = async (row) => {
  //   this.setState({ isLoading: true });
  //   let status = await CmsService.deActivateBanner(this.state.userData, {
  //     _id: row._id,
  //   });
  //   this.setState({ isLoading: false });
  //   if (status) {
  //     toast.success("Banner deactivated successfully.");
  //     await this.refreshPage();
  //   } else {
  //     toast.error("We are facing some server issue. Please try again later.");
  //   }
  // };

  // activate = async (row) => {
  //   alert("active");
  //   // this.setState({isLoading: true})
  //   // let status = await CmsService.activateBanner(this.state.userData, {_id: row._id});
  //   // this.setState({isLoading: false})
  //   // if (status) {
  //   //     toast.success("Banner activated successfully.");
  //   //     await this.refreshPage()
  //   // } else {
  //   //     toast.error("We are facing some server issue. Please try again later.");
  //   // }
  // };

  delete = async (item) => {
    console.log(item);
    this.setState({ isLoading: true });
    let status = await CmsService.deleteMobBanner(
      this.state.userData,
      item._id
    );
    this.setState({ isLoading: false });
    if (status) {
      toast.success("Banner configuration deleted successfully.");
      await this.refreshPage();
    } else {
      toast.error("We are facing some server issue. Please try again later.");
    }
  };
  render() {
    return (
      <React.Fragment>
        <SimpleBackdrop status={this.state.isLoading} />
        <div className={"px-4"}>
          <div className={"float-right"}>
            <MDBBtn size={"md"} onClick={this.addBanner}>
              {" "}
              <i className="fas fa-plus-circle"></i> Add banner
            </MDBBtn>
            {/* <CSVLink
              data={this.state.banners}
              headers={headers}
              filename={
                "Banners_" + Moments(new Date()).format("DD-MM-YYYY") + ".csv"
              }
            >
              <MDBBtn size={"md"} disabled={this.state.isLoading}>
                <i className="fas fa-download"></i> &nbsp;Export
              </MDBBtn>
            </CSVLink> */}
          </div>

          <div className={"d-flex"}>
            <a
              className={
                this.state.activeTabView === "active"
                  ? "header-tab-btn active"
                  : "header-tab-btn"
              }
              onClick={this.activeTabChange("active")}
              role="tab"
            >
              <div className={"col-12"}>Active</div>
            </a>
            {/* <a
              className={
                this.state.activeTabView === "deactive"
                  ? "header-tab-btn active"
                  : "header-tab-btn"
              }
              onClick={this.activeTabChange("deactive")}
              role="tab"
            >
              <div className={"col-12"}>Deactive</div>
            </a> */}
          </div>
        </div>

        <div className={"white"}>
          <MDBTabContent activeItem={this.state.activeTabView}>
            <MDBTabPane tabId="active" role="tabpanel">
              <MDBCard className={"mx-3 mb-3"}>
                <MDBCardBody className={"p-0"}>
                  <MDBTable bordered>
                    <MDBTableHead>
                      <tr style={{ textAlign: "center" }}>
                        <th>Sl</th>
                        <th>Banner</th>
                        <th>Action</th>
                      </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                      {this.state.banners.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td style={{ textAlign: "center" }}>
                              <div className={"main-title p-2"}>
                                {index + 1}
                              </div>
                            </td>
                            <td>
                              <MDBCard className={"mx-3 mb-3"}>
                                <MDBCardBody className={"p-0"}>
                                  <div
                                    className={"col-12  text-center p-3 mx-2"}
                                  >
                                    <div className={"banner-type"}>
                                      {" "}
                                      Mobile{" "}
                                    </div>
                                    <img
                                      className={"mt-4"}
                                      src={config.IMAGE_HOST + item.imageLink}
                                      // src={require("../common/img/add_image.svg")}
                                      width="350"
                                      height="120"
                                    />
                                  </div>
                                </MDBCardBody>
                              </MDBCard>
                            </td>
                            <td className="align-middle ">
                              <div className={"d-flex justify-content-center "}>
                                {/* <MDBBtn
                                    size={"md"}
                                    outline
                                    href="#"
                                    onClick={() => this.deactivate(item)}
                                  >
                                    Deactivate
                                  </MDBBtn> */}
                                <MDBBtn
                                  size={"md"}
                                  outline
                                  color="primary"
                                  href="#"
                                  className="mx-2"
                                  onClick={() => this.editBanner(item)}
                                >
                                  Edit
                                </MDBBtn>
                                <MDBBtn
                                  size={"md"}
                                  outline
                                  color="danger"
                                  href="#"
                                  onClick={() => this.delete(item)}
                                >
                                  Delete
                                </MDBBtn>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </MDBTableBody>
                  </MDBTable>
                </MDBCardBody>
              </MDBCard>
            </MDBTabPane>

            {/* ---------deactive tab------- */}
            {/* <MDBTabPane tabId="deactive" role="tabpanel">
              <MDBCard className={"mx-3 mb-3"}>
                <MDBCardBody className={"p-0"}>
                  <MDBTable bordered>
                    <MDBTableHead>
                      <tr style={{ textAlign: "center" }}>
                        <th>Sl</th>
                        <th>Banner</th>
                        <th>Action</th>
                      </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                      <tr>
                        <td style={{ textAlign: "center" }}>
                          <div className={"main-title p-2"}>1</div>
                        </td>
                        <td>
                          <MDBCard className={"mx-3 mb-3"}>
                            <MDBCardBody className={"p-0"}>
                              <div className={"col-12  text-center p-3 mx-2"}>
                                <div className={"banner-type"}> Mobile </div>
                                <img
                                  className={"mt-4"}
                                  // src={config.IMAGE_HOST + item.imageTab}
                                  src={require("../common/img/add_image.svg")}
                                  width="320"
                                  height="120"
                                />
                              </div>
                            </MDBCardBody>
                          </MDBCard>
                        </td>
                        <td className="align-middle ">
                          <div className={"d-flex justify-content-center "}>
                            <MDBBtn
                              size={"md"}
                              outline
                              href="#"
                              onClick={() => this.activate("item")}
                            >
                              Activate
                            </MDBBtn>
                            <MDBBtn
                              size={"md"}
                              outline
                              color="primary"
                              href="#"
                              className="mx-2"
                              onClick={() => this.editBanner("item")}
                            >
                              Edit
                            </MDBBtn>
                            <MDBBtn
                              size={"md"}
                              outline
                              color="danger"
                              href="#"
                              onClick={() => this.delete("item")}
                            >
                              Delete
                            </MDBBtn>
                          </div>
                        </td>
                      </tr>
                    </MDBTableBody>
                  </MDBTable>
                </MDBCardBody>
              </MDBCard>
            </MDBTabPane> */}
          </MDBTabContent>
        </div>

        <MDBModal
          isOpen={this.state.modalAddBanner}
          toggle={this.toggle("AddBanner")}
          size="fluid"
        >
          <MDBModalHeader toggle={this.toggle("AddBanner")}>
            {this.state.activeBanner !== null
              ? "Edit banner"
              : "Add new banner"}
          </MDBModalHeader>
          <MDBModalBody>
            <AddMobileBanner
              toggle={this.toggle("AddBanner")}
              refreshPage={this.refreshPage}
              banner={this.state.activeBanner}
            />
          </MDBModalBody>
        </MDBModal>
      </React.Fragment>
    );
  }
}
