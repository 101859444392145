import React from 'react';
import {MDBBtn, MDBCol, MDBContainer, MDBInput, MDBRow, toast} from "mdbreact";
import AuthService from "../../services/authService";
import UserStore from "../../stores/userStore";
import util from "../../utility/utility";
import orgConfig from "../../orgConfig";
export default  class AddAddress extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId:"",
            dependentId:"",
            contactNo:(this.props.hasOwnProperty("activeAddress") && this.props.activeAddress.contact_no) ? this.props.activeAddress.contact_no : (this.props.user.hasOwnProperty("contactNo") ? this.props.user.contactNo : ""),
            name: (this.props.user.name) ? this.props.user.name : "",
            addressLine1:(this.props.activeAddress && this.props.activeAddress.hasOwnProperty("addressLine1")) ? this.props.activeAddress.addressLine1 : "",
            addressLine2:(this.props.activeAddress && this.props.activeAddress.hasOwnProperty("addressLine2")) ? this.props.activeAddress.addressLine2 : "",
            pinCode:(this.props.activeAddress && this.props.activeAddress.hasOwnProperty("pinCode")) ? this.props.activeAddress.pinCode : "",
            area:(this.props.activeAddress && this.props.activeAddress.hasOwnProperty("area")) ? this.props.activeAddress.area : "",
            city:(this.props.activeAddress && this.props.activeAddress.hasOwnProperty("city")) ? this.props.activeAddress.city : "",
            state:(this.props.activeAddress && this.props.activeAddress.hasOwnProperty("state")) ? this.props.activeAddress.state : "",
            country: "India", //(this.props.activeAddress) ? this.props.activeAddress.country : "",
            landmark:(this.props.activeAddress && this.props.activeAddress.hasOwnProperty("landmark")) ? this.props.activeAddress.landmark : "",
            altPhone:(this.props.activeAddress && this.props.activeAddress.hasOwnProperty("alt_phone")) ? this.props.activeAddress.alt_phone : "",
            default:true, //(this.props.activeAddress) ? this.props.activeAddress.default : false,
            addressId: (this.props.activeAddress && this.props.activeAddress.hasOwnProperty("_id")) ? this.props.activeAddress._id : "",
            contactNoError:false,
            nameError:false,
            addressLine1Error:false,
            addressLine2Error:false,
            pinCodeError:false,
            areaError:false,
            cityError:false,
            stateError:false,
            countryError:false,
            landmarkError:false,
            altPhoneError:false,
        }
    }
    async componentDidMount() {
        let userData = await UserStore.fetchSessionData();
        this.setState({userData: userData})
        if(this.props.user.hasOwnProperty("userId")){
            this.setState({userId: this.props.user._id, dependentId: this.props.user.dependentId})
        } else {
            this.setState({userId: this.props._id, dependentId: this.props.dependentId})
        }
        if(userData.type === "platformAdmin"){
            this.setState({orgId: userData._id})
            this.setState({numId: userData.numId})
        } else if(userData.type === "ORG_ADMIN"){
            this.setState({orgId: userData.orgDetails[0]._id})
            this.setState({numId: userData.orgDetails[0].numId})
        }
        try{
            this.setState({"default": true})
        } catch (e) {
            toast.error("Error in setting default address");
        }
    }
    handleChangeContactNo=(e)=>{
        if(!util.isInt(e.target.value) && e.target.value !== ""){
            return false;
        }
        this.setState({contactNo:e.target.value})
    };
    handleName=(e)=>{
        this.setState({name:e.target.value})
    };
    handleAddress=(e)=>{
        this.setState({addressLine1:e.target.value})
    };
    handleStreet=(e)=>{
        this.setState({addressLine2:e.target.value})
    };
    handlePinCode=(e)=>{
        if(!util.isInt(e.target.value) && e.target.value !== ""){
            return false;
        }
        this.setState({pinCode:e.target.value})
    };
    handleArea=(e)=>{
        this.setState({area:e.target.value})
    };
    handleCity=(e)=>{
        this.setState({city:e.target.value})
    };
    handleState=(e)=>{
        this.setState({state:e.target.value})
    };
    handleCountry=(e)=>{
        this.setState({country:e.target.value})
    };
    handleLandmark=(e)=>{
        this.setState({landmark:e.target.value})
    };
    handleContactNo=(e)=>{
        if(!util.isInt(e.target.value) && e.target.value !== ""){
            return false;
        }
        this.setState({contactNo:e.target.value})
    };
    handleAltphone=(e)=>{
        if(!util.isInt(e.target.value) && e.target.value !== ""){
            return false;
        }
        this.setState({altPhone:e.target.value})
    };
    validateAltPhone=()=>{
        if(this.state.altPhone.trim() !== "" && !util.checkPhone(this.state.altPhone)){
            toast.error("Please enter a 10 digit alternate phone number.");
            this.setState({altPhoneError:true});
            return true;
        } else {
            this.setState({altPhoneError:false});
        }
    }
    addAddress = async () => {
        if(this.state.name.trim() === ""){
            this.setState({nameError:true});
            toast.error("Please enter your full name.")
            return true;
        } else {
            this.setState({nameError:false});
        }

        if(this.state.addressLine1.trim() === ""){
            this.setState({addressLine1Error:true});
            toast.error("Please enter address line 1.")
            return true;
        } else {
            this.setState({addressLine1Error:false});
        }
        if(this.state.pinCode.trim() === ""){
            this.setState({pinCodeError:true});
            toast.error("Please enter ZIP.")
            return true;
        } else {
            this.setState({pinCodeError:false});
        }
        if(this.state.city.trim() === ""){
            this.setState({cityError:true});
            toast.error("Please enter city.")
            return true;
        } else {
            this.setState({cityError:false});
        }
        if(this.state.state.trim() === ""){
            this.setState({stateError:true});
            toast.error("Please enter state.");
            return true;
        } else {
            this.setState({stateError:false});
        }
        if(this.state.country.trim() === ""){
            this.setState({countryError:true});
            toast.error("Please enter country.");
            return true;
        } else {
            this.setState({countryError:false});
        }
        if(!util.checkPhone(this.state.contactNo)){
            this.setState({contactNoError:true});
            toast.error("Please enter a 10 digit phone number.");
            return true;
        } else {
            this.setState({contactNoError:false});
        }
        // if(this.state.altPhone!==""){
        //     if(!util.checkPhone(this.state.altPhone)){
        //         this.setState({altPhoneError:true});
        //         toast.error("Please enter a valid 10 digit alternate phone number.");
        //         return true
        //     }
        // } else {
        //     this.setState({altPhoneError:false});
        // }
        this.setState({"loading": true});

        let addressResult = await AuthService.updateUserAddress(this.props.userId,this.state.addressId, this.state.default, this.state.name, this.state.addressLine1, this.state.addressLine2, this.state.pinCode, this.state.area, this.state.city, this.state.state, this.state.country,this.state.landmark, this.state.altPhone, this.state.contactNo, this.state);
        this.setState({"loading": false});
        if(!addressResult){
            toast.error("Something went wrong.");
        } else {
            if(this.state.addressId === ""){
                toast.success("Address added.");
                this.props.fetchAllAddress();
            } else {
                toast.success("Address updated.");
                this.props.fetchAllAddress();
                this.setState({
                    contactNo:  "",
                    name: "",
                    addressLine1:"",
                    addressLine2:"",
                    pinCode:"",
                    area: "",
                    city: "",
                    state: "",
                    country: "India",
                    landmark: "",
                    altPhone: "",
                    default: false,
                    addressId: "",
                })
            }
            this.props.openAddAddress()
        }
    }
    validateName=()=>{
        if(this.state.name.trim() === ''){
            return true;
        } else {
            this.setState({nameError: false});
        }
    };
    validateAddress=()=>{
        if(this.state.addressLine1.trim() === ''){
            return true;
        } else {
            this.setState({addressLine1Error: false});
        }
    };
    validatePinCode=()=>{
        if(this.state.pinCode.trim() === ''){
            return true;
        } else {
            this.setState({pinCodeError: false});
        }
    };
    validateArea=()=>{
        if(this.state.area === ''){
            return true;
        } else {
            this.setState({areaError: false});
        }
    };
    validateCity=()=>{
        if(this.state.city.trim() === ''){
            return true;
        } else {
            this.setState({cityError: false});
        }
    };
    validateState=()=>{
        if(this.state.state.trim() === ''){
            return true;
        } else {
            this.setState({stateError: false});
        }
    };
    render() {
        return (
            <MDBContainer>
                <MDBRow >
                    <MDBCol size={"6"}>
                        <MDBInput onBlur={()=>this.validateName()} className={this.state.nameError ? "border-danger" :""} maxLength="50"  type='text' label="Full Name *" onChange={this.handleName} value={this.state.name}/>
                    </MDBCol>
                    <MDBCol size={"6"}>
                        <MDBInput className={this.state.contactNoError ? "border-danger" :""} maxLength={orgConfig.PHONE_NUMBER_LENGTH}  type='text' label="Phone*" onChange={this.handleContactNo} value={this.state.contactNo}/>
                    </MDBCol>
                </MDBRow>
                <MDBRow >
                    <MDBCol size={"6"}>
                        <MDBInput onBlur={()=>this.validateAddress()} className={this.state.addressLine1Error ? "border-danger" :""} maxLength="50"  type='text' label="Address Line 1 *" onChange={this.handleAddress} value={this.state.addressLine1}/>
                    </MDBCol>
                    <MDBCol  size={"6"}>
                        <MDBInput className={this.state.addressLine2Error ? "border-danger" :""} maxLength="50"  type='text' label="Address Line 2" onChange={this.handleStreet} value={this.state.addressLine2}/>
                    </MDBCol>
                </MDBRow>
                <MDBRow >
                    <MDBCol size={"6"}>
                        <MDBInput onBlur={()=>this.validatePinCode()} className={this.state.pinCodeError ? "border-danger" :""} maxLength="6"  type='text' label="PIN *" onChange={this.handlePinCode} value={this.state.pinCode}/>
                    </MDBCol>
                    <MDBCol  size={"6"}>
                        <MDBInput  className={this.state.areaError ? "border-danger" :""} maxLength="35"  type='text' label="Area/Locality" onChange={this.handleArea} value={this.state.area}/>
                    </MDBCol>
                </MDBRow>
                <MDBRow >
                    <MDBCol size={"6"}>
                        <MDBInput onBlur={()=>this.validateCity()} className={this.state.cityError ? "border-danger" :""} maxLength="35"  type='text' label="City *" onChange={this.handleCity} value={this.state.city}/>
                    </MDBCol>
                    <MDBCol  size={"6"}>
                        <MDBInput onBlur={()=>this.validateState()} className={this.state.stateError ? "border-danger" :""} maxLength="35"  type='text' label="State *" onChange={this.handleState} value={this.state.state}/>
                    </MDBCol>
                </MDBRow>
                <MDBRow >
                    <MDBCol size={"6"}>
                        <MDBInput className={this.state.landmarkError ? "border-danger" :""} maxLength="50"  type='text' label="Landmark" onChange={this.handleLandmark} value={this.state.landmark}/>
                    </MDBCol>
                    {/*<MDBCol  size={"6"}>*/}
                    {/*    <MDBInput onBlur={()=>this.validateAltPhone()} className={this.state.altPhoneError ? "border-danger" :""} maxLength={orgConfig.PHONE_NUMBER_LENGTH}  type='text' label="Alternate Phone" onChange={this.handleAltphone} value={this.state.altPhone}/>*/}
                    {/*</MDBCol>*/}
                </MDBRow>
                <MDBRow className={"mt-2 d-flex justify-content-center"}>
                    <MDBBtn disabled={this.state.loading}  color={"default"} onClick={() => this.addAddress()}>Save</MDBBtn>
                </MDBRow>
            </MDBContainer>
        );
    }
}

