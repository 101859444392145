import React from "react";
import { withRouter } from "react-router-dom";
import {
    MDBBox,
    MDBCardHeader,
    MDBBtn,
    MDBCardBody,
    MDBCard,
    MDBModalHeader,
    MDBModalBody,
    MDBModal,
    MDBModalFooter,
    MDBTableHead,
    MDBTableBody,
    MDBBtnGroup,
    MDBTable,
    MDBRow,
    MDBCol,
    MDBInput,
    MDBSelect, MDBIcon, MDBContainer, MDBDataTable
} from 'mdbreact';
import UserStore from "../../stores/userStore";
import {toast} from "react-toastify";
import dataTableConfig from "../order-history/dataTableOrderHistory";
import OrderService from "../../services/orderService";
import Moments from "moment";
import config from "../../config";
import {Page, Text, View, Document, StyleSheet, PDFViewer} from '@react-pdf/renderer';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import utility from "../../utility/utility";
import util from "../../utility/utility"
import SimpleBackdrop from "../common/overlay";


const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#E4E4E4'
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    table: { display: "table", width: "auto", borderStyle: "solid", borderWidth: 1, borderRightWidth: 0, borderBottomWidth: 0 },
    tableRow: { margin: "auto", flexDirection: "row" },
    tableCol1: { width: "100%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 },
    tableCol2: { width: "50%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 },
    tableCol4: { width: "25%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 },
    tableCol: { width: "8%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 },
    tableColP3: { width: "3%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 },
    tableColP4: { width: "4%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 },
    tableColP5: { width: "5%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 },
    tableColP6: { width: "6%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 },
    tableColP7: { width: "7%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 },
    tableColP8: { width: "8%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 },
    tableColP9: { width: "9%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 },
    tableColP10: { width: "10%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 },
    tableColProduct: { width: "18%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 },
    tableColP90:{ width: "90%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 },
    tableCell: { margin: "auto", marginTop: 5, marginBottom: 5, fontSize: 10 },
    tableCellTotal: { margin: "auto", marginTop: 5, marginBottom: 5, fontSize: 10, position: "absolute", right: 20 },

    tableCellH1: { margin: "auto", marginTop: 5,  marginBottom: 5, fontSize: 18, fontWeight: "bold" },
    tableCellH2: { margin: "auto", marginTop: 5, marginBottom: 5, fontSize: 14 },
    tableCellH3: { margin: "auto", marginTop: 5, marginBottom: 5, fontSize: 12 },
    tableCellH4: { margin: "auto", marginTop: 5, marginBottom: 5, fontSize: 10 },
    tableCellH5: { margin: "auto", marginTop: 5, fontSize: 9 },
    tableCellH6: { margin: "auto", marginTop: 5, fontSize: 8 },
});

class ViewReturnInwards extends React.Component {
    async componentDidMount() {
        let userData = await UserStore.fetchSessionData();
        this.setState({userData: userData})
        if(userData.type === "platformAdmin"){
            this.setState({orgId: userData._id})
        } else if(userData.type === "ORG_ADMIN"){
            this.setState({orgId: userData.orgDetails[0]._id})
        }
        let startDate =  Moments(new Date()).format("YYYY-MM-DD");
        let endDate = Moments(new Date()).format("YYYY-MM-DD");
        await this.fetchData(startDate, endDate);
    }
    fetchData=async (startDate, endDate)=>{
        window.scrollTo(0, 0);
        this.setState({progressStatus: true});
        let data=await OrderService.fetchOfflineReturnOrdersInward(this.state, startDate, endDate);
        this.setState({progressStatus: false});
        if(data===false){
            toast.error("We are facing some issue. Please try again later.");
        }else{
            this.setState({orders: data, orderHistoryApiResultCame:true});
            let dataTablePayload={
                columns:dataTableConfig.orderHistory,
                rows:data.map((item, index) => {
                    if( !item.hasOwnProperty("legacy") || (item.hasOwnProperty("legacy") && item.legacy==='N')){
                        item["action"]=<div><div color="primary" className={"blue-text btn-link cursor-pointer"} size="sm" onClick={()=>{this.onView(item)}}>View</div>


                        </div>;
                    }
                    // item["handleCreatedAt"]=this.convertToReadbleDate(item.createdOn);
                    item["handleOrderDate"]=this.convertToReadbleDate(item.orderDate);
                    item["customerName"]=item.customerDetails.name;
                    item["customerPhone"]=item.customerDetails.contact_no ? item.customerDetails.contact_no : item.customerDetails.contactNo ;
                    // item["gst"]=item.hasOwnProperty("products") ? (item.products.inventory.hasOwnProperty("gst") ? item.products.inventory) : "NA";
                    item["totalItems"]= item.hasOwnProperty("products") ? item.products.length : "NA";
                    item["outstandingAmount"]= <div><span><span dangerouslySetInnerHTML={{__html: config.CURRENCY_HTML}}></span> {item.balanceAmount.toFixed("2")}</span>

                        {(item.balanceAmount > 0.09 && item.isPaymentPending === "Y") &&
                        <div color="default" className={"blue-text btn-link cursor-pointer"} size="sm" onClick={()=>{this.initPayOutstanding(item)}}>Receive Payment</div>
                        }
                        {(item.balanceAmount > 0 && item.balanceAmount < 1 && item.isPaymentPending === "Y") &&
                        <div color="default" className={"blue-text btn-link cursor-pointer"} size="sm" onClick={()=>{this.initPayOutstanding(item)}}>Mark as paid</div>
                        }


                    </div>;
                    item["totalAmountFormated"]= <span><span dangerouslySetInnerHTML={{__html: config.CURRENCY_HTML}}></span> {item.totalAmount.toFixed("2")}</span>;
                    item["handleEdit"]=<div color="primary" className={"blue-text btn-link cursor-pointer"} size="sm" onClick={()=>{this.onEdit(item)}}>Edit</div>;
                    item["handleStatus"]=<div color="primary" className={"blue-text btn-link cursor-pointer"} size="sm" onClick={()=>{this.initChangeStatus(item)}}>{(item.status === "Y") ? "Deactivate" : "Activate"}</div>;

                    return item;
                })
            };
            this.setState({data:dataTablePayload});
            let subtotal = 0;
            let totalOrder = 0;
            let creditOrders = 0;
            let pendingAmount = 0
            for(let order of this.state.orders) {
                subtotal = subtotal + order.totalReturnedAmount;
                totalOrder++;
                if(order.paymentMode === "CREDIT"){
                    creditOrders++;
                    pendingAmount = pendingAmount + order.totalReturnedAmount
                }
            }
            this.setState({totalFilteredTransactions:totalOrder, totalFilteredTransactionsAmount:subtotal, totalFilteredCreditTransactions:creditOrders, totalFilteredTransactionsAmountPending: pendingAmount  })
        }
    }

    filterData = (type, startDate, endDate)=>{
        if(startDate > endDate){
            toast.info("Start date is greater than the end date. Please adjust the date range again.")
            return false;
        }
        this.fetchData(Moments(startDate).format("YYYY-MM-DD"), Moments(endDate).format("YYYY-MM-DD"))
        return false
    }

    constructor(props) {
        super(props);
        this.state = {
            selectedProviderAddress : "1st Floor, Lakhi Mandir Complex, Beltola, Guwahati, Assam, 781028.  Call: 03612302334",
            orgId:0,
            userData:{},
            progressStatus:false,
            type:"Today",
            page:1,
            pageSize:10,
            orders:[],
            data: {},
            modal:false,
            activeOrderHistory:"",
            activeOrderHistoryId:0,
            orderHistoryApiResultCame:false,
            modalStatus:false,
            statusActionName:"",
            modalOrderDetails:false,
            activeOrder:{
                cart:[
                    {
                        cartData:[]
                    }
                ],
                docs:[],
                products:[],
                paymentLog:[]
            },
            isActiveOrderAPrescriptionDrug:false,
            orderToBeDeleted:{},
            modalDeleteOrder:false,
            orderToBePaid:{
                paymentLog: []
            },
            modalPayOutstanding:false,
            startDate:new Date(),
            endDate:new Date(),
            stDate: Moments(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format("YYYY-MM-DD"),
            //endDate: Moments().endOf('month').format("YYYY-MM-DD"),
            mode: "Day",
            dayStartDate:Moments(),
            dayEndDate:Moments(),
            weekStartDate:Moments().startOf("week"),
            weekEndDate:Moments().endOf("week"),
            monthStartDate:Moments().startOf("month"),
            monthEndDate:Moments().endOf("month"),
            currentDWM:Moments(new Date()).format("MMM"),
            totalFilteredTransactions:0,
            totalFilteredTransactionsAmount:0,
            totalFilteredCreditTransactions:0,
            totalFilteredTransactionsAmountPending:0,
            totalAmount:0,
            amountCollected:0,
            changeReturn:0,
            paymentModes: [{ text:"Cash", value:"CASH", checked:true },{ text:"Card", value:"CARD" },{ text:"Netbanking", value:"NETBANKING" },{ text:"Wallet", value:"WALLET" }],
            paymentMode:"CASH",
            modalEditOrder:false,
            modalLB:false,
            activeImage:"",

        }
    }
    onEdit=(item)=>{
        //this.props.history.push('/edit-order/'+item._id);
        this.setState({activeOrder:item})
        this.toggleEditOrder()
    }

    onReturn=(item)=>{
        this.props.history.push('/create-return-inward/'+item.orderCode);

    }

    toggleEditOrder=()=>{
        this.setState({
            modalEditOrder: !this.state.modalEditOrder
        });
    }

    onEditComplete = () => {
        this.toggleEditOrder()
    }

    onView=(item)=>{
        console.log(item)
        this.setState({activeOrder:item})

        this.setState({isActiveOrderAPrescriptionDrug:false})
        for(let row of item.products){
            if(row.medicineType.trim() === "Schedule X" || row.medicineType.trim() === "Schedule H" || row.medicineType.trim() === "Schedule H1" || row.medicineType.trim() === "PRESCRIPTION DRUG" || row.medicineType.trim() === "PRESCRIPTION DRUGS"){
                this.setState({isActiveOrderAPrescriptionDrug:true})
            }
        }
        this.toggleOrderDetails()
    }

    initDelete = (item) => {
        this.setState({orderToBeDeleted:item})
        this.toggleDeleteOrder()

    }

    initPayOutstanding = (item) => {
        this.setState({orderToBePaid:item})
        this.togglePayOutstanding()

    }

    confirmDelete = async () => {
        this.setState({progressStatus:true})
        let data=await OrderService.deleteOfflineOrder(this.state);
        this.setState({progressStatus:false})
        if(data){
            toast.success("Order deleted.")
            this.toggleDeleteOrder()

            let startDate = Moments();
            let endDate = Moments();
            if(this.state.mode === "Day"){
                startDate = this.state.dayStartDate;
                endDate = this.state.dayEndDate;
            } else if(this.state.mode === "Week"){
                startDate = this.state.weekStartDate;
                endDate = this.state.weekEndDate;
            } else if(this.state.mode === "Month"){
                startDate = this.state.monthStartDate;
                endDate = this.state.monthEndDate;
            } else if(this.state.mode === "Custom"){
                startDate = this.state.startDate;
                endDate = this.state.endDate;
            }
            this.fetchData(Moments(startDate).format("YYYY-MM-DD"), Moments(endDate).format("YYYY-MM-DD"))
        } else {
            toast.success("Something went wrong.")
        }
    }


    confirmPayOutstanding = async () => {
        if(!this.state.paidAmount || this.state.paidAmount <= 0){
            toast.error("Please enter the paid amount.")
            return false;
        }
        this.setState({progressStatus:true})
        let data=await OrderService.payOutStanding(this.state);
        this.setState({progressStatus:false})
        if(data){
            toast.success("Order updated.")
            this.togglePayOutstanding()
            let startDate = Moments();
            let endDate = Moments();
            if(this.state.mode === "Day"){
                startDate = this.state.dayStartDate;
                endDate = this.state.dayEndDate;
            } else if(this.state.mode === "Week"){
                startDate = this.state.weekStartDate;
                endDate = this.state.weekEndDate;
            } else if(this.state.mode === "Month"){
                startDate = this.state.monthStartDate;
                endDate = this.state.monthEndDate;
            } else if(this.state.mode === "Custom"){
                startDate = this.state.startDate;
                endDate = this.state.endDate;
            }
            this.fetchData(Moments(startDate).format("YYYY-MM-DD"), Moments(endDate).format("YYYY-MM-DD"))
            await this.setState({paidAmount:0, amountCollected:0, changeReturn: 0})
        } else {
            toast.error("Something went wrong.")
        }
    }

    convertToReadbleDate = date =>{
        if(date){
            return Moments.utc(date).local().format("MMM D, YYYY hh:mm A");
        }
    }

    // This toggle opens or closes the delete shipment modal
    toggleOrderDetails = () => {
        this.setState({
            modalOrderDetails: !this.state.modalOrderDetails
        });
    }

    toggleDeleteOrder  = () => {
        this.setState({
            modalDeleteOrder: !this.state.modalDeleteOrder
        });
    }

    togglePayOutstanding  = () => {
        this.setState({
            modalPayOutstanding: !this.state.modalPayOutstanding
        });
    }

    convertMfgOrExpiry = date =>{
        if(date){
            return Moments(date).format("MMM YYYY");
        } else {
            return "";
        }
    }

    calculateSubTotal = (mrp, quantity, discount, contentQuantity, looseSaleAllowed) => {
        if(!contentQuantity){
            contentQuantity = 1;
        }
        if(mrp > 0){
            if(looseSaleAllowed === "N"){
                contentQuantity = 1 // This could be the minimum sale quantity later
            }
            let subTotal = (mrp/contentQuantity) * quantity;
            if(discount > 0){
                let discountedAmount = subTotal * (discount / 100);
                subTotal = subTotal - discountedAmount
            }
            return subTotal.toFixed("2");
        } else {
            return 0;
        }
    }

    calculateDiscountedAmount = (mrp, quantity, discount, contentQuantity, looseSaleAllowed) => {
        if(!contentQuantity){
            contentQuantity = 1;
        }
        if(mrp > 0){
            if(looseSaleAllowed === "N"){
                contentQuantity = 1 // This could be the minimum sale quantity later
            }
            let subTotal = (mrp/contentQuantity) * quantity;
            if(discount > 0){
                let discountedAmount = subTotal * (discount / 100);
                return discountedAmount.toFixed("2");
            } else {
                return 0
            }
        } else {
            return 0;
        }
    }

    calculateGSTAmountFromMRPAndGSTPercentage = (mrp, gstPercentage, quantity, contentQuantity, looseSaleAllowed) => {
        if(!quantity){
            quantity = 1;
        }
        if(!contentQuantity){
            contentQuantity = 1;
        }
        if(looseSaleAllowed === "N"){
            contentQuantity = 1 // This could be the minimum sale quantity later
        }

        if(mrp > 0){
            if(gstPercentage > 0){
                let basePrice = ((mrp) - ((100+gstPercentage)/100))/contentQuantity

                let gstAmount = basePrice - (basePrice - basePrice*(gstPercentage/100))
                gstAmount = gstAmount * quantity;
                gstAmount =  Math.round(gstAmount * 100) / 100
                return gstAmount.toFixed("2");
            } else {
                return 0;
            }
        } else {
            return 0
        }
    }
    // Filter

    searchGo = () =>{
        this.filterData(this.state.mode, this.state.startDate, this.state.endDate)
    }

    changeMode = (mode) => {
        this.setState({"mode": mode})
        if (mode === "Day") {
            this.filterData(mode, this.state.dayStartDate, this.state.dayEndDate);
        }
        if (mode === "Week") {
            this.filterData(mode, this.state.weekStartDate, this.state.weekEndDate);
        }
        if (mode === "Month") {
            this.filterData(mode, this.state.monthStartDate, this.state.monthEndDate);
        }
        if (mode === "Custom") {
            this.filterData(mode, this.state.startDate, this.state.endDate);
        }
    }

    nextDay = () => {
        let nextStartDate = Moments(this.state.dayStartDate).add(1, "days");
        this.filterData("Today", nextStartDate, nextStartDate);
        this.setState({dayStartDate:nextStartDate, dayEndDate:nextStartDate})
    }

    prevDay = () => {
        let nextStartDate = Moments(this.state.dayStartDate).subtract(1, "days");
        this.filterData("Today", nextStartDate, nextStartDate);
        this.setState({dayStartDate:nextStartDate, dayEndDate:nextStartDate})
    }

    nextWeek = () => {
        let nextStartDate = Moments(this.state.weekStartDate).add(1, "weeks");
        let nextEndDate = Moments(this.state.weekEndDate).add(1, "weeks");
        this.filterData("Today", nextStartDate, nextEndDate);
        this.setState({weekStartDate:nextStartDate, weekEndDate:nextEndDate})
    }

    prevWeek  = () => {
        let nextStartDate = Moments(this.state.weekStartDate).subtract(1, "weeks");
        let nextEndDate = Moments(this.state.weekEndDate).subtract(1, "weeks");
        this.filterData("Today", nextStartDate, nextEndDate);
        this.setState({weekStartDate:nextStartDate, weekEndDate:nextEndDate})
    }

    nextMonth = () => {
        let nextStartDate = Moments(this.state.monthStartDate).add(1, "months");
        let nextEndDate = Moments(nextStartDate).endOf( "month");
        this.filterData("Today", nextStartDate, nextEndDate);
        this.setState({monthStartDate:nextStartDate, monthEndDate:nextEndDate})
    }

    prevMonth = () => {
        let nextStartDate = Moments(this.state.monthStartDate).subtract(1, "months");
        let nextEndDate = Moments(nextStartDate).endOf( "month");
        this.filterData("Today", nextStartDate, nextEndDate);
        this.setState({monthStartDate:nextStartDate, monthEndDate:nextEndDate})
    }

    handleStartDate =(e)=>{
        this.setState({startDate:new Date(e)})
    };
    handleEndDate =(e)=>{
        this.setState({endDate:new Date(e)})
    };

    handlePaidAmount = async (e)=>{
        if(!util.isNumericTwoDecimalPartial(e.target.value) && e.target.value !== ""){
            return false;
        }
        await this.setState({paidAmount:e.target.value})
        //let paidAmount = e;

        if(this.state.amountCollected > 0 ){
            this.setState({changeReturn:(this.state.amountCollected - this.state.paidAmount)})
        }
    };

    handleAmountCollected =(e)=>{
        if(!util.isNumericTwoDecimalPartial(e.target.value) && e.target.value !== ""){
            return false;
        }
        this.setState({amountCollected:e.target.value})
        let amountCollected = e.target.value;
        let balanceAmount = this.state.orderToBePaid.balanceAmount
        if(amountCollected && amountCollected >= 0){
            let ra = amountCollected - balanceAmount;
            if(ra > 0){
                this.setState({changeReturn:ra.toFixed("2")})
            }
        } else {
            this.setState({changeReturn:0})
        }
        if(amountCollected >= balanceAmount){
            this.setState({paidAmount:balanceAmount.toFixed("2")})
        } else {
            if(amountCollected > 0){
                this.setState({paidAmount:parseFloat(amountCollected).toFixed("2")})
            }
        }
    };

    handleChangeReturn =(e)=>{
        if(!util.isNumericTwoDecimalPartial(e.target.value) && e.target.value !== ""){
            return false;
        }
        this.setState({changeReturn:e.target.value})
    };

    handlePaymentMode = (e) => {
        this.setState({paymentMode:e[0]})
        if(e[0] === "WALLET" || e[0] === "NETBANKING" || e[0] === "CARD"){
            this.setState({paidAmount:this.state.totalAmount})
        }
    }

    onDownloadImage = (row) => {
        console.log(row);
        this.setState({activeImage: row})
        this.toggleLB()
    }

    // This toggle opens or closes the custom lightbox
    toggleLB = () => {
        this.setState({
            modalLB: !this.state.modalLB
        });
    }

    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={!this.state.orderHistoryApiResultCame}/>
                <MDBContainer>
                    <MDBCard className={"mt-2"}>
                        <MDBCardHeader>
                            {(this.state.orderHistoryApiResultCame) &&
                            <MDBRow>
                                <MDBCol className={"ml-2"}>
                                    <div>Total Return Orders: {this.state.totalFilteredTransactions}</div>
                                </MDBCol>
                                <MDBCol className={"ml-2"}>
                                    <div>Total Return Amount: <span dangerouslySetInnerHTML={{__html: config.CURRENCY_HTML}}></span> {this.state.totalFilteredTransactionsAmount.toFixed("2")}</div>
                                </MDBCol>
                                {/*<MDBCol className={"ml-2"}>*/}
                                {/*    <div>Orders on Credit: {this.state.totalFilteredCreditTransactions}</div>*/}
                                {/*</MDBCol>*/}
                                {/*<MDBCol className={"ml-2"}>*/}
                                {/*    <div>Credit Pending: <span dangerouslySetInnerHTML={{__html: config.CURRENCY_HTML}}></span> {this.state.totalFilteredTransactionsAmountPending.toFixed("2")}</div>*/}
                                {/*</MDBCol>*/}
                            </MDBRow>
                            }
                        </MDBCardHeader>
                        <MDBCardBody>
                            {(this.state.orderHistoryApiResultCame) &&
                            <MDBRow>
                                <MDBCol size={"4"}>
                                    <MDBBtnGroup size="sm" >
                                        <MDBBtn onClick={()=>this.changeMode("Day")} disabled={this.state.mode === "Day" }  color={this.state.mode === "Day" ? "default":""}>Day</MDBBtn>
                                        <MDBBtn onClick={()=>this.changeMode("Week")} disabled={this.state.mode === "Week" }  color={this.state.mode === "Week" ? "default":""}>Week</MDBBtn>
                                        <MDBBtn onClick={()=>this.changeMode("Month")} disabled={this.state.mode === "Month" }  color={this.state.mode === "Month" ? "default":""}>Month</MDBBtn>
                                        <MDBBtn onClick={()=>this.changeMode("Custom")} disabled={this.state.mode === "Custom" }  color={this.state.mode === "Custom" ? "default":""}>Custom</MDBBtn>

                                    </MDBBtnGroup>
                                </MDBCol>
                                {this.state.mode === "Custom" &&
                                <MDBCol size={"4"} style={{marginTop: "-25px"}}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            style={{"marginLeft": "1px", "marginRight": "1px", width: "9rem"}}

                                            format="MMM D, YYYY"
                                            margin="normal"
                                            id="startDate"
                                            label="Start Date"
                                            value={this.state.startDate}
                                            onChange={this.handleStartDate}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>

                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            style={{"marginLeft": "1px", "marginRight": "1px", width: "9rem"}}

                                            format="MMM D, YYYY"
                                            margin="normal"
                                            id="endDate"
                                            label="End Date"
                                            value={this.state.endDate}
                                            onChange={this.handleEndDate}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                    <MDBBtn onClick={this.searchGo}  style={{"marginTop": "30px", "paddingLeft": "1px", "paddingRight": "1px", width: "3rem"}} size="sm" color={"default"}>Go</MDBBtn>
                                </MDBCol>
                                }
                                {(this.state.mode === "Day") &&
                                <MDBCol size={"4"}>
                                    <MDBBtn size={"sm"} onClick={()=>this.prevDay()} color="default">&lt;</MDBBtn>
                                    <span> {utility.convertToReadbleDaySmart(this.state.dayStartDate)}</span>
                                    <MDBBtn size={"sm"}  onClick={()=>this.nextDay()} color="default">&gt;</MDBBtn>
                                </MDBCol>
                                }
                                {(this.state.mode === "Week" ) &&
                                <MDBCol size={"4"}>
                                    <MDBBtn size={"sm"} onClick={()=>this.prevWeek()} color="default">&lt;</MDBBtn>
                                    <span> {utility.convertToReadbleDaySmart(this.state.weekStartDate)}</span> - <span> {utility.convertToReadbleDaySmart(this.state.weekEndDate)}</span>
                                    <MDBBtn size={"sm"}  onClick={()=>this.nextWeek()} color="default">&gt;</MDBBtn>
                                </MDBCol>
                                }
                                {(this.state.mode === "Month" ) &&
                                <MDBCol size={"4"}>
                                    <MDBBtn size={"sm"} onClick={()=>this.prevMonth()} color="default">&lt;</MDBBtn>
                                    <span> {utility.convertToReadbleMonthSmart(this.state.monthStartDate)}</span>
                                    <MDBBtn size={"sm"}  onClick={()=>this.nextMonth()} color="default">&gt;</MDBBtn>
                                </MDBCol>
                                }
                                <MDBCol size={"2"}>
                                </MDBCol>
                                <MDBCol size={"2"}>
                                </MDBCol>
                            </MDBRow>
                            }
                            {(this.state.orderHistoryApiResultCame && this.state.orders.length < 1) &&
                            <div className={"no-data-container"}>
                                {this.state.mode === "Day" && <span>There are no orders for the selected date.</span>}
                                {this.state.mode === "Week" && <span>There are no orders for the selected week.</span>}
                                {this.state.mode === "Month" && <span>There are no orders for the selected month.</span>}
                                {this.state.mode === "Custom" && <span>There are no orders for the selected date range.</span>}
                            </div>
                            }
                            {this.state.orders.length > 0 &&
                            // <MDBDataTableV5
                            //     hover
                            //     entriesOptions={[10,15, 20, 25]}
                            //     entries={10}
                            //     pagesAmount={4}
                            //     data={this.state.data}
                            //     materialSearch
                            //     searchTop
                            //     searchBottom={false}
                            //     barReverse
                            // />
                            <MDBDataTable
                                striped

                                data={this.state.data}
                                materialSearch
                                noBottomColumns
                                hover
                                disableRetreatAfterSorting={true}
                            />
                            }
                        </MDBCardBody>
                    </MDBCard>
                    <MDBModal isOpen={this.state.modalOrderDetails} toggle={this.toggleOrderDetails} size={"fluid"}>
                        <MDBModalHeader toggle={this.toggleOrderDetails}></MDBModalHeader>
                        <MDBModalBody className={"float-center"}>
                            <MDBTable small={true}>
                                <MDBTableHead>
                                    <tr>
                                        <th>Product</th>
                                        <th>Batch No</th>
                                        <th>Type</th>
                                        <th>Expiry Date</th>
                                        <th>MRP</th>
                                        <th>GST (%)</th>
                                        <th>Quantity</th>
                                        <th>Discount (%)</th>
                                        <th>Subtotal</th>
                                        <th>Patient</th>
                                        <th>Doctor</th>
                                    </tr>
                                </MDBTableHead>
                                {this.state.activeOrder.hasOwnProperty("products") &&
                                <MDBTableBody>
                                    {this.state.activeOrder.products.map((row, index) => (
                                        <tr key={index + "_cart"} className={"pt-1 mt-1"}>
                                            <td>{row.productName}</td>
                                            <td>{row.inventory.batch}</td>
                                            <td>
                                                {(row.hasOwnProperty("isMedicine") && !row.isMedicine) &&
                                                <span>General</span>
                                                }
                                                {(row.hasOwnProperty("isMedicine") && row.isMedicine) &&
                                                <span>
                                                    {row.hasOwnProperty("medicineType") &&
                                                    <span
                                                        className={(row.medicineType === "Schedule H" || row.medicineType === "Schedule H1" || row.medicineType === "Schedule X" || row.medicineType === "PRESCRIPTION DRUGS" || row.medicineType === "PRESCRIPTION DRUG") ? "red-text" : "green-text"}>
                                                        {row.medicineType === "PRESCRIPTION DRUGS" &&
                                                        <span>Prescription Drug</span>
                                                        }
                                                        {row.medicineType !== "PRESCRIPTION DRUGS" &&
                                                        <span>{row.medicineType}</span>
                                                        }
                                                    </span>
                                                    }
                                                    {!row.hasOwnProperty("medicineType") &&
                                                    <span>  Not Specified</span>
                                                    }
                                                </span>
                                                }
                                                {(!row.hasOwnProperty("isMedicine")) &&
                                                <span>
                                                    {row.hasOwnProperty("medicineType") &&
                                                    <span
                                                        className={(row.medicineType === "Schedule H" || row.medicineType === "Schedule H1" || row.medicineType === "Schedule X") ? "red-text" : "green-text"}> {row.medicineType}</span>
                                                    }
                                                </span>
                                                }
                                            </td>
                                            <td>{this.convertMfgOrExpiry(row.inventory.expDate)}</td>
                                            {row.looseSaleAllowed === "Y" &&
                                            <td><span dangerouslySetInnerHTML={{__html: config.CURRENCY_HTML}}></span> {(row.inventory.mrp/row.contentQuantity).toFixed("2")}</td>
                                            }
                                            {row.looseSaleAllowed === "N" &&
                                            <td><span dangerouslySetInnerHTML={{__html: config.CURRENCY_HTML}}></span> {(row.inventory.mrp).toFixed("2")}</td>
                                            }
                                            <td>{row.inventory.gst}</td>
                                            <td>
                                                {row.quantity}
                                            </td>
                                            <td>
                                                <div>
                                                    {row.discount}
                                                </div>
                                            </td>
                                            <td><span dangerouslySetInnerHTML={{__html: config.CURRENCY_HTML}}></span> {this.calculateSubTotal(row.inventory.mrp, row.quantity, row.discount, row.contentQuantity, row.looseSaleAllowed)}</td>
                                            <td size={"2"}>
                                                {(row.medicineType.trim() === "Schedule X" || row.medicineType.trim() === "Schedule H" || row.medicineType.trim() === "Schedule H1" || row.medicineType.trim() === "PRESCRIPTION DRUG" || row.medicineType.trim() === "PRESCRIPTION DRUGS") &&
                                                <>
                                                    {(row.hasOwnProperty("patientDetails") && row.patientDetails.hasOwnProperty("name")) &&
                                                    <span>
                                                    {row.patientDetails.name &&
                                                    <span>{row.patientDetails.name}</span>
                                                    }
                                                        {(!row.patientDetails.name && row.patientDetails.first_name) &&
                                                        <span>{row.patientDetails.first_name + " " + row.patientDetails.last_name} </span>
                                                        }
                                                </span>
                                                    }
                                                </>
                                                }
                                            </td>
                                            <td size={"2"}>
                                                {(row.medicineType.trim() === "Schedule X" || row.medicineType.trim() === "Schedule H" || row.medicineType.trim() === "Schedule H1" || row.medicineType.trim() === "PRESCRIPTION DRUG" || row.medicineType.trim() === "PRESCRIPTION DRUGS") &&
                                                <>
                                                    {(row.hasOwnProperty("doctorDetails") && row.doctorDetails.hasOwnProperty("name")) &&
                                                    <span>{row.doctorDetails.name}</span>
                                                    }
                                                </>
                                                }
                                            </td>
                                        </tr>
                                    ))}
                                </MDBTableBody>
                                }
                            </MDBTable>
                            <MDBRow>
                                {this.state.activeOrder.totalAmount &&
                                <MDBCol>
                                    <b>Total Amount: <span dangerouslySetInnerHTML={{__html: config.CURRENCY_HTML}}></span> {this.state.activeOrder.totalAmount.toFixed("2")}</b>
                                </MDBCol>
                                }
                            </MDBRow>

                            {this.state.activeOrder.hasOwnProperty("paymentLog") &&
                            <MDBRow className={"m-1 mt-3"}>
                                Payment History: {this.state.activeOrder.paymentLog.length === 0 &&
                                <div className={'no-data-container'}>No payment log found.</div>}
                            </MDBRow>
                            }


                            {(this.state.activeOrder.hasOwnProperty("paymentLog") && this.state.activeOrder.paymentLog.length > 0) &&
                            <MDBTable className={"col-6"}>
                                <MDBTableHead>
                                    <tr>
                                        <th>#</th>
                                        <th>Amount</th>
                                        <th>Paid On</th>
                                        <th>Payment Mode</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {this.state.activeOrder.paymentLog.map((row, index) => (
                                        <tr key={"paymentlog_"+index}>
                                            <td>{index+1}</td>
                                            <td><span dangerouslySetInnerHTML={{__html: config.CURRENCY_HTML}}></span> {row.amount.toFixed("2")}</td>
                                            <td>{this.convertToReadbleDate(row.paidAt)}</td>
                                            <td>{row.paymentMode}</td>
                                        </tr>
                                    ))}
                                </MDBTableBody>
                            </MDBTable>
                            }

                            <MDBRow>
                                <MDBCol>
                                    {(this.state.activeOrder.hasOwnProperty("docs") ) &&
                                    <ul className="list-group list-group-horizontal">
                                        {this.state.activeOrder.docs.map((row, index) => (
                                            <li key={index} className="list-group-item">
                                                {(utility.ifFileIsDocument(row)) &&
                                                <a className={'file-size float-left'}
                                                   href={utility.secureFilePath(this.state.userData.sessionToken, this.state.userData._id, row)}><MDBIcon
                                                    style={{"fontSize": "3rem"}} icon="file"/></a>
                                                }
                                                {(utility.ifFileIsImage(row)) &&
                                                <img className={'file-size float-left'}
                                                     style={{cursor: "pointer", maxWidth: "100px", maxHeight: "100px"}}
                                                     onClick={() => this.onDownloadImage(row)}
                                                     src={utility.secureFilePath(this.state.userData.sessionToken, this.state.userData._id, row)}/>
                                                }
                                                <div style={{clear: "both"}}></div>
                                                <div className={"mb-1"}>Prescription_{index + 1}</div>
                                            </li>
                                        ))}
                                    </ul>
                                    }
                                </MDBCol>
                            </MDBRow>

                            {(this.state.activeOrder.hasOwnProperty("docs") ) &&
                            <MDBRow className={"mt-5"}>
                                {this.state.activeOrder.docs.map((row, index) => (
                                    <div style={{"textAlign": "center"}}>
                                        {row.hasOwnProperty("filePathOriginal") &&
                                        <img alt={""} src={config.IMAGE_HOST + row.filePathOriginal}
                                             className={"float-center"}
                                             style={{maxWidth: "240px", maxHeight: "500px"}}/>
                                        }
                                    </div>
                                ))}
                            </MDBRow>
                            }
                        </MDBModalBody>
                    </MDBModal>
                    <MDBModal isOpen={this.state.modalDeleteOrder} toggle={this.toggleDeleteOrder}>
                        <MDBModalHeader toggle={this.toggleDeleteOrder}>Delete Order</MDBModalHeader>
                        <MDBModalBody>
                            <div className={"mb-3"}>
                                Are you sure you want to delete this order?
                            </div>
                            <div>
                                Order#: {this.state.orderToBeDeleted.orderCode}
                            </div>
                            {this.state.orderToBeDeleted.hasOwnProperty("totalAmount") &&
                            <div>
                                Total Amount: <span dangerouslySetInnerHTML={{__html: config.CURRENCY_HTML}}></span> {this.state.orderToBeDeleted.totalAmount}
                            </div>
                            }
                            {this.state.orderToBeDeleted.hasOwnProperty("orderDate") &&
                            <div>
                                Order Date: {this.convertToReadbleDate(this.state.orderToBeDeleted.orderDate)}
                            </div>
                            }
                            {(this.state.orderToBeDeleted.hasOwnProperty("customerDetails") && this.state.orderToBeDeleted.customerDetails.hasOwnProperty("name")) &&
                            <div>
                                Customer Name: {this.state.orderToBeDeleted.customerDetails.name}
                            </div>
                            }
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn disabled={this.state.progressStatus} color="primary" onClick={this.confirmDelete}>Delete</MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>
                    <MDBModal isOpen={this.state.modalPayOutstanding} toggle={this.togglePayOutstanding}>
                        <MDBModalHeader toggle={this.togglePayOutstanding}>Pay Outstanding</MDBModalHeader>
                        <MDBModalBody>
                            <div className={"mb-3"}>
                                Order Details:
                            </div>
                            <div>
                                Order#: {this.state.orderToBePaid.orderCode}
                            </div>
                            {this.state.orderToBePaid.hasOwnProperty("orderDate") &&
                            <div>
                                Order Date: {this.convertToReadbleDate(this.state.orderToBePaid.orderDate)}
                            </div>
                            }
                            {(this.state.orderToBePaid.hasOwnProperty("customerDetails") && this.state.orderToBePaid.customerDetails.hasOwnProperty("name")) &&
                            <div>
                                Customer Name: {this.state.orderToBePaid.customerDetails.name}
                            </div>
                            }
                            {this.state.orderToBePaid.hasOwnProperty("totalAmount") &&
                            <div>
                                Total Amount: <span dangerouslySetInnerHTML={{__html: config.CURRENCY_HTML}}></span> {this.state.orderToBePaid.totalAmount}
                            </div>
                            }
                            {this.state.orderToBePaid.hasOwnProperty("balanceAmount") &&
                            <div>
                                Outstanding Amount: <span dangerouslySetInnerHTML={{__html: config.CURRENCY_HTML}}></span> {this.state.orderToBePaid.balanceAmount.toFixed("2")}
                            </div>
                            }


                            {this.state.orderToBePaid.paymentLog.length > 0 &&

                            <MDBTable className={"col-12"}>
                                <MDBTableHead>
                                    <tr>
                                        <th>#</th>
                                        <th>Amount</th>
                                        <th>Paid On</th>
                                        <th>Payment Mode</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {this.state.orderToBePaid.paymentLog.map((row, index) => (
                                        <tr key={"orderTobePaid"+index}>
                                            <td>{index+1}</td>
                                            <td><span dangerouslySetInnerHTML={{__html: config.CURRENCY_HTML}}></span> {row.amount.toFixed("2")}</td>
                                            <td>{this.convertToReadbleDate(row.paidAt)}</td>
                                            <td>{row.paymentMode}</td>
                                        </tr>
                                    ))}
                                </MDBTableBody>
                            </MDBTable>
                            }






                            <MDBSelect outline={true} label={"Payment Mode"} getValue={this.handlePaymentMode} options={this.state.paymentModes}></MDBSelect>


                            <MDBRow>

                                <MDBCol size={"4"}>
                                    <MDBInput  label={"Amount Collected"} value={this.state.amountCollected} onChange={this.handleAmountCollected}></MDBInput>
                                </MDBCol>
                                <MDBCol size={"4"}>
                                    <MDBInput label={"Change Returned"} value={this.state.changeReturn} onChange={this.handleChangeReturn}></MDBInput> </MDBCol>
                                <MDBCol size={"4"}>
                                    <MDBInput label={"Amount Paid"} value={this.state.paidAmount} onChange={this.handlePaidAmount}></MDBInput>
                                </MDBCol>
                            </MDBRow>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn disabled={this.state.progressStatus} color="default" onClick={this.confirmPayOutstanding}>Update Payment</MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>



                    <MDBModal isOpen={this.state.modalLB} toggle={this.toggleLB} size={"lg"}>
                        <MDBModalHeader toggle={this.toggleLB}></MDBModalHeader>
                        <MDBModalBody className={"float-center"}>
                            <div style={{"textAlign":"center"}}>
                                <img src={config.IMAGE_HOST + this.state.activeImage} className={"float-center"}
                                     style={{maxWidth: "440px", maxHeight: "440px"}} alt={""}/>
                            </div>
                        </MDBModalBody>
                    </MDBModal>

                </MDBContainer>
            </React.Fragment>
        );
    }
}
export default withRouter(ViewReturnInwards)


