import React, { useCallback, useEffect, useRef, useState } from 'react'
import styles from './index.module.css'
import downArrow from '../../images/arrow_down.png'
import upArrow from '../../images/arrow_up.png'
import close_icon from '../../images/close.png'



const AutocompleteUser = ({ options, fetchOptions, onSelect, onChange, label, name , resetInputValue = ()=>{} }) => {
    const [data, setData] = useState([]);
    const [inputVal, setInputVal] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [isSelected, setIsSelected] = useState(false)
    const wrapperRef = useRef(null);

    const inputChangeHandler = (e) => {
        setInputVal(e.target.value)
        onChange(e.target.value)
        setIsOpen(true)
    }

    const getSuggestions = async (query) => {
        let result;
        try {
            if (options) {
                const hasNumber = (str) => /\d/.test(str);
                result = options?.filter(item =>
                    hasNumber(inputVal) ?  item?.name: item?.name?.toLowerCase().includes(inputVal?.toLowerCase())
                );
                
        console.log("suf",result)

            } else if (fetchOptions) {
                result = await fetchOptions(query)
            }
            setData(result)
        } catch (error) {
            setData([])
        }
    }


    useEffect(() => {
        const timeout = setTimeout(() => {
            getSuggestions(inputVal)
        }, 300)
        return () => clearTimeout(timeout)
    }, [inputVal, options])




    const selectItemHandler = (val, item) => {
        console.log({ val, item })
        setInputVal(val)
        onSelect(val, item)
        setIsSelected(true)
        setIsOpen(false)
    }

    const unSelectHandler = () => {
        onSelect("")
        setIsSelected(false)
        setInputVal("")
        resetInputValue()
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const highlightText = (input, fullText, item) => {
        const index = fullText?.toLowerCase().indexOf(input?.toLowerCase());
        if (index === -1) return (
            <>
                <span style={{ paddingRight: ".2em" }}>{fullText}</span><br/>
                <span style={{ paddingRight: ".2em" }}>{item?.contactNo}</span><br/>
                <span style={{ paddingRight: ".2em" }}>{item?.email}</span><br/>
            </>
        
        )
        return (
            <div>
                <span>{fullText.slice(0, index)}</span>
                <span style={{ fontWeight: 700, color: '#6366F1' }}>{fullText.slice(index, index + input.length)}</span>
                <span style={{ paddingRight: ".2em" }}>{fullText.slice(index + input.length)}</span><br/>
                <span style={{ paddingRight: ".2em" }}>{item?.contactNo?.length>1 ?item?.contactNo:"NA" }</span><br/>
                <span style={{ paddingRight: ".2em" }}>{item?.email?.length>1 ?item?.email:"NA" }</span><br/>
           </div>
        );
    }


    return (
        <div className={styles.container} ref={wrapperRef}>
            <div className={styles.inputHead}>
                <input type="text" onClick={() => setIsOpen(true)} placeholder={`${label ? label : "Enter here"}`} name={name} value={inputVal} className={styles.input} onChange={inputChangeHandler} />
                <div className={styles.icons}>
                    {isSelected && <img src={close_icon} className={styles.cross_icon} onClick={unSelectHandler} alt="" />}
                    <img onClick={() => setIsOpen(!isOpen)} src={isOpen ? upArrow : downArrow} alt="" />
                </div>
            </div>
            <div className={`${styles.autocompleteBody} ${isOpen ? styles.open : styles.closed}`}>
            {    console.log({data})}
                {data?.length > 0 ? data?.map((item, index) => (
                    <div className={styles.autocompleteItem} key={index} onClick={() => selectItemHandler(item?.name, item)}>{highlightText(inputVal, item?.name, item)}</div>
                )) : <span className={styles.not_found}>No option found!</span>}
            </div>
        </div>
    )
}

export default AutocompleteUser