import React from "react";
import {
    MDBBtn,MDBCard,MDBCardBody,MDBCardHeader,MDBContainer,MDBFormInline,MDBInput,MDBRow,MDBCol,MDBSelect,MDBProgress
} from "mdbreact";
import {withRouter} from "react-router-dom";
import { toast } from 'react-toastify';
import util from "../../utility/utility";
import orgConfig from "../../orgConfig";
import UserStore from "../../stores/userStore";
import OrderService from "../../services/orderService";

class AddStorageLocation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            storageLocation: this.props.hasOwnProperty("storageLocation") ? this.props.storageLocation : null,
            progressStatus: false,
            stLcName: this.props.hasOwnProperty("storageLocation") && this.props.storageLocation !== null ? this.props.storageLocation.name : "",
            stLcNameError: false,
            phoneNo: this.props.hasOwnProperty("storageLocation") && this.props.storageLocation !== null ? this.props.storageLocation.phone : "",
            phoneNoError: false,
            addressLine1: this.props.hasOwnProperty("storageLocation") && this.props.storageLocation !== null ? this.props.storageLocation.addressLine1 : "",
            addressLine1Error: false,
            addressLine2: this.props.hasOwnProperty("storageLocation") && this.props.storageLocation !== null ? this.props.storageLocation.addressLine2 : "",
            pinCode: this.props.hasOwnProperty("storageLocation") && this.props.storageLocation !== null ? this.props.storageLocation.pinCode : "",
            pinCodeError: false,
            area: this.props.hasOwnProperty("storageLocation") && this.props.storageLocation !== null ? this.props.storageLocation.area : "",
            city: this.props.hasOwnProperty("storageLocation") && this.props.storageLocation !== null ? this.props.storageLocation.city : "",
            cityError: false,
            state: this.props.hasOwnProperty("storageLocation") && this.props.storageLocation !== null ? this.props.storageLocation.state : "",
            stateError: false,
            country: this.props.hasOwnProperty("storageLocation") && this.props.storageLocation !== null ? this.props.storageLocation.country : "",
            countryError: false,
            countryList: [],
            countries: ["India", "USA", "Canada", "South Africa"],
            modalEdit: false,
            userData: {},
        }
    }
    async componentDidMount() {
        let userData = await UserStore.fetchSessionData();
        let countryList = [], item = {}
        for (let i of this.state.countries) {
            if (this.state.storageLocation !== null && this.state.storageLocation.country === i) {
                item = {text: i, value: i, checked: true}
            } else {
                item = {text: i, value: i, checked: false}
            }
            countryList.push(item)
        }
        this.setState({countryList, userData})
    }

    addStorageLocation = async () => {
        let item  = ""
        item = this.validate("stLcName")
        if (item === true) {
            toast.error("Please enter the storage name.")
            return
        }
        await this.validatePhoneNo()
        if (this.state.phoneNoError === true) {
            toast.error("Please enter a valid phone no.")
            return
        }
        item = this.validate("addressLine1")
        if (item === true) {
            toast.error("Please enter the address line 1.")
            return
        }
        item = this.validate("pinCode")
        if (item === true) {
            toast.error("Please enter the pin code.")
            return
        }
        item = this.validate("city")
        if (item === true) {
            toast.error("Please enter the city.")
            return
        }
        item = this.validate("state")
        if (item === true) {
            toast.error("Please enter the state.")
            return
        }
        item = this.validate("country")
        if (item === true) {
            toast.error("Please select the country.")
            return
        }
        let payload = {
            addressLine1: this.state.addressLine1,
            addressLine2: this.state.addressLine2,
            area: this.state.area,
            city: this.state.city,
            country: this.state.country[0],
            name: this.state.stLcName,
            phone: this.state.phoneNo,
            pinCode: this.state.pinCode,
            state: this.state.state,
        }
        this.setState({progressStatus: true})
        if (this.props.storageLocation && this.props.storageLocation !== null) {
            payload.storageId = this.props.storageLocation._id
            let res = await OrderService.updateStorageLocation(this.state.userData.sessionToken, payload)
            if (res) {
                toast.success("The storage location updated successfully.")
                this.props.toggleEdit()
            } else {
                toast.error("Something went wrong while updating the storage location.")
            }
        } else {
            let res = await OrderService.addStorageLocation(this.state.userData.sessionToken, payload)
            if (res) {
                toast.success("The storage location added successfully.")
                this.props.history.push("/view-storage-locations")
            } else {
                toast.error("Something went wrong while adding the storage location.")
            }
        }
        this.setState({progressStatus: false})
    }
    validate = (fieldName) => {
        if (this.state[fieldName] === "") {
            this.setState({[fieldName + "Error"]: true})
            return true
        } else {
            this.setState({[fieldName + "Error"]: false})
            return false
        }
    }
    handlePhoneNo=(e)=>{
        if(!util.isInt(e.target.value) && e.target.value !== ""){
            return false;
        }
        this.setState({phoneNo:e.target.value})
    };
    handlePinCode=(e)=>{
        if(!util.isInt(e.target.value) && e.target.value !== ""){
            return false;
        }
        this.setState({pinCode:e.target.value})
    };
    validatePhoneNo = async () => {
        (this.state.phoneNo === "" || !util.checkPhone(this.state.phoneNo)) ? this.setState({"phoneNoError": true}) : this.setState({"phoneNoError": false})
    }
    render() {
        return (
            <React.Fragment>
                <MDBContainer>
                    <MDBCard className={"mt-2"}>
                        <MDBCardHeader>
                            <div className={"float-left title"}>{this.state.storageLocation ? "Edit Storage Location" : "Add Storage Location"}</div>
                        </MDBCardHeader>
                        {this.state.progressStatus === true &&
                        <span className={'top-stick'}><MDBProgress material preloader color="cyan"/></span>
                        }
                        <MDBCardBody>
                            <MDBRow className={"my-1 p-3"}>
                                <MDBCol className={"mt-n4"}>
                                    <MDBInput id={"name"} label="Storage Location Name *" value={this.state.stLcName} getValue={(val)=>{this.setState({stLcName: val})}}
                                              className={this.state.stLcNameError ? "border-danger" : ""} maxLength={"40"} onBlur={()=>this.validate("stLcName")}/>
                                </MDBCol>
                                <MDBCol className={"mt-n4"}>
                                    <MDBInput id={"phoneNo"} label="Phone Number *" value={this.state.phoneNo} onChange={this.handlePhoneNo}
                                              className={this.state.phoneNoError ? "border-danger" : ""} maxLength={orgConfig.PHONE_NUMBER_LENGTH} onBlur={()=>this.validatePhoneNo()}/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className={"my-1 p-3"}>
                                <MDBCol className={"mt-n4"}>
                                    <MDBInput id={"addressLine1"} label="Address Line 1 *" value={this.state.addressLine1} getValue={(val)=>{this.setState({addressLine1: val})}}
                                              className={this.state.addressLine1Error ? "border-danger" : ""} onBlur={()=>this.validate("addressLine1")}/>
                                </MDBCol>
                                <MDBCol className={"mt-n4"}>
                                    <MDBInput id={"addressLine2"} label="Address Line 2" value={this.state.addressLine2} getValue={(val)=>{this.setState({addressLine2: val})}}/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className={"my-1 p-3"}>
                                <MDBCol className={"mt-n4"}>
                                    <MDBInput id={"pin"} label="Pin *" value={this.state.pinCode} onChange={this.handlePinCode}
                                              className={this.state.pinCodeError ? "border-danger" : ""} maxLength="6" onBlur={()=>this.validate("pinCode")}/>
                                </MDBCol>
                                <MDBCol className={"mt-n4"}>
                                    <MDBInput id={"area"} label="Area/Locality" value={this.state.area} getValue={(val)=>{this.setState({area: val})}}/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className={"my-1 p-3"}>
                                <MDBCol className={"mt-n4"}>
                                    <MDBInput id={"city"} label="City *" value={this.state.city} getValue={(val)=>{this.setState({city: val})}}
                                              className={this.state.cityError ? "border-danger" : ""} onBlur={()=>this.validate("city")}/>
                                </MDBCol>
                                <MDBCol className={"mt-n4"}>
                                    <MDBInput id={"state"} label="State *" value={this.state.state} getValue={(val)=>{this.setState({state: val})}}
                                              className={this.state.stateError ? "border-danger" : ""} onBlur={()=>this.validate("state")}/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className={"my-1 p-3"}>
                                <MDBCol size={"6"} className={"mt-n4"}>
                                    <MDBSelect
                                        //search
                                        options={this.state.countryList}
                                        selected="Select a country"
                                        label="Country List"
                                        getValue={(val)=>{this.setState({country: val})}}
                                        className={this.state.countryError ? "border-danger" :""}
                                        color="primary"
                                        selectAllValue={this.state.country[0]}
                                    />
                                </MDBCol>
                            </MDBRow>
                            <div className="text-center">
                                {!this.state.storageLocation &&
                                <MDBBtn size={'md'} outline
                                        onClick={() => this.props.history.push('/view-storage-locations')}
                                        className="mr-auto"
                                        disabled={this.state.progressStatus}>
                                    Back
                                </MDBBtn>
                                }
                                <MDBBtn size={'md'} onClick={this.addStorageLocation}
                                        className="mr-auto"
                                        disabled={this.state.progressStatus}>
                                    {this.state.storageLocation ? "Update" : "Save"}
                                </MDBBtn>
                            </div>
                        </MDBCardBody>
                    </MDBCard>
                </MDBContainer>
            </React.Fragment>
        );
    }
}

export default withRouter(AddStorageLocation);