import React from "react";
import {
    MDBContainer, MDBRow, MDBInput, MDBBtn, MDBCardHeader, MDBFormInline, MDBCardBody, MDBCard, MDBCol, MDBModalHeader,
    MDBModalBody, MDBModalFooter, MDBModal
} from 'mdbreact';
import {toast} from "react-toastify";
import UserStore from "../../stores/userStore";
import PharmacyService from "../../services/pharmacyService";
import {withRouter} from "react-router-dom";
import SimpleBackdrop from "../common/overlay";

class EditRetailPackageCmp extends React.Component {
    async componentDidMount() {

        if(this.props.loadedOnPopup === true){
            let retailPackageId = this.props.retailPackageId;
            await this.setState({retailPackageId:retailPackageId, loadedOnPopup:true})
            await this.fetchData();
        } else {
            let retailPackageId  = this.props.match.params.retailPackageId;
            await this.setState({retailPackageId:retailPackageId});
            await this.fetchData();
        }


    }
    fetchData= async ()=>{
        let userData = await UserStore.fetchSessionData();
        this.setState({progressStatus: true});
        let data=await PharmacyService.fetchRetailPackageById(this.state.retailPackageId, userData);
        this.setState({progressStatus: false});
        if(data===false){
            toast.error("We are facing some issue. Please try again later.");
        }else{
            this.setState({retailPackageData:data,retailPackage:data.retailPackage});
            if(data.status==='Y'){
                this.setState({status:true});
            }else{
                this.setState({status:false});
            }
        }
    }
    constructor(props) {
        super(props);
        this.state = {
            retailPackageId:'',
            progressStatus:false,
            progressStatusFileUpload:false,
            retailPackageData:{},
            retailPackage:'',
            status:false,
            featured:false,
            retailPackageError:false,
            imageUrl:{},
            loadedOnPopup:false,
        }
    }
    retailPackageChange=(val)=>{
        this.setState({retailPackage:val.toLowerCase()});
    }
    updateRetailPackage=async ()=>{
        let userData = await UserStore.fetchSessionData();
        //validations
        if(this.state.retailPackage==='' || this.state.retailPackage===null){
            this.setState({retailPackageError:true});
            return true;
        } else {
            this.setState({retailPackageError:false});
        }
        this.setState({progressStatus: true});
        let data=await PharmacyService.updateRetailPackage(this.state,userData);
        this.setState({progressStatus: false});
        if(data===false){
            toast.error("We are facing some issue. Please try again later.");
        }else{
            toast.success("Retail package updated successfully.");
            if(this.state.loadedOnPopup === true){
                this.props.onEditComplete()
            } else {
                this.props.history.push('/view-retail-packaging');
            }

        }
    }
    getStatus=(val)=>{
        this.setState({status:val});
    }
    validateRetailPackage=()=>{
        if(this.state.retailPackage === ""){
            this.setState({retailPackageError:true});
        } else {
            this.setState({retailPackageError:false});
        }
    }
    initDelete = () => {
        this.toggleDelete()
    }
    confirmDelete = async () => {
        let userData=await UserStore.fetchSessionData();
        this.setState({progressStatus:true});
        let status=await PharmacyService.deleteRetailPackage(this.state,userData);
        this.setState({progressStatus:false});
        if(status===false){
            toast.error("Something went wrong.");
            return true;
        }else{
            toast.success("Retail packaging unit deleted successfully.");
            this.toggleDelete();
            if(this.state.loadedOnPopup === true){
                this.props.onEditComplete()
            } else {
                this.props.history.push('/view-retail-packaging');
            }
        }
    }
    toggleDelete = () => {
        this.setState({
            modalDelete: !this.state.modalDelete
        });
    }
    render() {
        return (
            <React.Fragment>
                <SimpleBackdrop status={this.state.progressStatus}/>
                <MDBContainer>
                    <MDBCard className={"mt-2"}>
                        <MDBCardHeader>
                            <span className={"float-left"}>Edit Retail Packaging</span>
                            <MDBFormInline className="float-right md-form mr-auto m-0">
                                {this.state.loadedOnPopup === false &&
                                <MDBBtn outline color="blue-grey" size="sm"
                                        onClick={() => this.props.history.push('/view-retail-packaging')}
                                        className="mr-auto" disabled={this.state.progressStatus}>
                                    Back
                                </MDBBtn>
                                }
                                <MDBBtn outline color="blue-grey" size="sm" onClick={this.initDelete} className="mr-auto"
                                        disabled={this.state.progressStatus}
                                >
                                    Delete
                                </MDBBtn>
                                <MDBBtn outline color="blue-grey" size="sm" onClick={this.updateRetailPackage} className="mr-auto"
                                        disabled={this.state.progressStatus || this.state.progressStatusFileUpload}>
                                    Save
                                </MDBBtn>
                            </MDBFormInline>
                        </MDBCardHeader>
                        <MDBCardBody>
                            <MDBRow className="d-flex justify-content-left">
                                <MDBCol>
                                    <MDBInput label="RetailPackage name" onBlur={this.validateRetailPackage} className={this.state.retailPackageError ? "border-danger" :""} getValue={this.retailPackageChange} value={this.state.retailPackage} disabled={this.state.progressStatus}/>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                    <MDBModal isOpen={this.state.modalDelete} toggle={this.toggleDelete}>
                        <MDBModalHeader toggle={this.toggleDelete}>Delete Retail Packaging Unit</MDBModalHeader>
                        <MDBModalBody>
                            Are you sure you want to delete this retail packaging unit?
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn disabled={this.state.progressStatus} color="primary" onClick={this.confirmDelete}>Delete</MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>
                </MDBContainer>
            </React.Fragment>
        );
    }
}
export default withRouter(EditRetailPackageCmp)


